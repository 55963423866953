import { Nillable, Renderable } from "../base/@types"
import { ColorName } from "../base/constants/color.enum"
import { DefaultColorName, getColorHexByName } from "../base/utils/colors.utils"
import { toTitleCase } from "../base/utils/string.utils"
import { PercentageBarDataItem } from "../modules/Admin/AdminStatistics/_components/PercentageBar/PercentageBar"

export enum ApplicationTypeOption {
  'PaidOneToOne' = 'paid-one-to-one',
  'OneToOne' = 'one-to-one',
  'Couples' = 'couples',
  'YoungPeople' = 'young-people',
}
export enum CounsellingType {
  'PaidOneToOne' = 'paid-one-to-one',
  'OneToOne' = 'one-to-one',
  'Couples' = 'couples',
  'YoungPeople1214' = 'young-people-12-14',
  'YoungPeople1517' = 'young-people-15-17',
}
export const isSameCounsellingType = (a: Nillable<ApplicationTypeOption | CounsellingType>, b: Nillable<ApplicationTypeOption | CounsellingType>) => (a + '') === (b + '');

export type CounsellingDisplayTypeDescriptor = {
  value: ApplicationTypeOption;
  displayName: string;
  applicationInformation: Renderable;
  colorName: ColorName;
  disabled?: boolean,
}

export const applicationTypeOptionDescriptors: Record<ApplicationTypeOption, CounsellingDisplayTypeDescriptor> = {
  [ApplicationTypeOption.PaidOneToOne]: {
    value: ApplicationTypeOption.PaidOneToOne,
    displayName: 'One to One',
    applicationInformation: 'One to one individual counselling for adults (18 years old and above). The sessions can be conducted via video or text chat based on your preference.',
    colorName: ColorName.brightPurple,
  },
  [ApplicationTypeOption.OneToOne]: {
    value: ApplicationTypeOption.OneToOne,
    displayName: 'One to One',
    applicationInformation: 'One to one individual counselling for adults (18 years old and above). The sessions can be conducted via video or text chat based on your preference.',
    colorName: ColorName.brightPurple,
  },
  [ApplicationTypeOption.Couples]: {
    value: ApplicationTypeOption.Couples,
    displayName: 'Couples',
    applicationInformation: 'Counselling designed for couples. Both you and your partner will need to have a turn2me account. Sessions are conducted via video calls between you, your partner and your counsellor.',
    colorName: ColorName.Firebrick,
    // disabled: true,
  },
  [ApplicationTypeOption.YoungPeople]: {
    value: ApplicationTypeOption.YoungPeople,
    displayName: 'Young People',
    applicationInformation: 'Counselling designed for your child (Age 12-17). The sessions will be conducted via video calls. You must be the legal guardian of the child to apply.',
    colorName: ColorName.Yellow,
    // disabled: true,
  }
} as const

export type CounsellingTypeDescriptor = {
  value: CounsellingType,
  name: string,
  altName?: string,
  displayName: string,
  altDisplayName?: string,
  abbr: string,
  altAbbr?: string,
  colorName: DefaultColorName,
  color: string,
  altColor?: string,
}

export const CounsellingTypeDescriptors: CounsellingTypeDescriptor[] = [
  {
    value: CounsellingType.OneToOne,
    name: 'One to One',
    altName: 'One to One',
    displayName: 'One to One',
    altDisplayName: 'One to One',
    abbr: '1-to-1',
    colorName: 'brightPurple',
    color: getColorHexByName('brightPurple'),
    altColor: getColorHexByName('blueGreen'),
  },
  {
    value: CounsellingType.YoungPeople1214,
    name: 'Young People 12–14',
    displayName: 'Young People 12–14',
    abbr: 'YP 12–14',
    colorName: 'yellow',
    color: getColorHexByName('yellow'),
  },
  {
    value: CounsellingType.YoungPeople1517,
    name: 'Young People 15–17',
    displayName: 'Young People 15–17',
    abbr: 'YP 15–17',
    colorName: 'orange',
    color: getColorHexByName('orange'),
  },
  {
    value: CounsellingType.Couples,
    name: 'Couples',
    displayName: 'Couples',
    abbr: 'Couples',
    colorName: 'firebrick',
    color: getColorHexByName('firebrick'),
  },
  {
    value: CounsellingType.PaidOneToOne,
    name: 'Paid One to One',
    altName: 'One to One',
    displayName: 'Paid One to One',
    altDisplayName: 'One to One',
    abbr: 'PAID 1-to-1',
    altAbbr: '1-to-1',
    colorName: 'brightPurple',
    color: getColorHexByName('brightPurple'),
    altColor: getColorHexByName('skyblue'),
  },
]

export const makeDefaultCounsellingDataGroup = <T>() => ({
  'paid-one-to-one': [],
  'one-to-one': [],
  'young-people-12-14': [],
  'young-people-15-17': [],
  'couples': [],
}) as Record<CounsellingType, T[]>;

export const makeCounsellingCounterGroup = () => ({
  'paid-one-to-one': 0,
  'one-to-one': 0,
  'young-people-12-14': 0,
  'young-people-15-17': 0,
  'couples': 0,
}) as Record<CounsellingType, number>;

export const counsellingTypes: CounsellingType[] = Object.values(CounsellingType);
export const counsellingTypeNames = Object.keys(makeCounsellingCounterGroup()) as CounsellingType[];

export const getCounsellingTypeName = (value: string, useAlt?: boolean) => {
  const desc = CounsellingTypeDescriptors.find(d => d.value === value);
  return (useAlt ? desc?.altName ?? desc?.name : desc?.name) ?? toTitleCase(value)
};
export const getCounsellingTypeAbbr = (value: string) => CounsellingTypeDescriptors.find(d => d.value === value)?.abbr ?? toTitleCase(value);

export const getCounsellingTypeColorName = (value?: string | null) => CounsellingTypeDescriptors.find(d => d.value === value)?.colorName;
export const getCounsellingTypeColorHex = (value?: string | null, useAlt?: boolean) => {
  const desc = CounsellingTypeDescriptors.find(d => d.value === value);
  return useAlt ? desc?.altColor ?? desc?.color : desc?.color;
}

export const makeCounsellingTypePercentageBarDataItemSet = <T extends object>() => {
  const result = {} as Record<CounsellingType, PercentageBarDataItem<T>>;
  counsellingTypeNames.forEach(key => {
    result[key as CounsellingType] = {
      color: getCounsellingTypeColorHex(key) ?? getColorHexByName('skyblue'),
      value: 0,
      details: '',
      entries: [],
    }
  })
  return result;
}


