import { action, when } from "mobx";
import { CountryDescriptors } from "../base/constants/countries.constants";
import { useOnMount } from "../base/hooks/lifecycle.hooks";
import { useControllers } from "../base/hooks/useRootController.hook";
import { isArray } from "../base/utils/typeChecks.utils";
import { SHOULD_LOG } from "../env";

export function prependPhoneAreaCode(
  dialCode: string,
  prependToString: string,
) {
  const regex = new RegExp(`^\\${dialCode}`);
  if (!regex.test(prependToString)) {
    return dialCode + prependToString;
  }
  return prependToString;
}

export function useGetPhoneAreaCode<T extends object>(
  observable: T,
  key: keyof T,
  onRetrieve?: (areaCode: string) => void,
) {
  const { COMMON } = useControllers();
  useOnMount(() => {
    when(() => !!COMMON.countryCode, action(() => {
      let dialCodeDef = CountryDescriptors[COMMON.countryCode]?.dialCode;
      let dialCode = dialCodeDef === null ? '' : isArray(dialCodeDef) ? dialCodeDef[0] : dialCodeDef;
      dialCode = [dialCode[0] === '+' ? '' : '+', dialCode].join('');
      if (!dialCode) {
        SHOULD_LOG() && console.warn('Unable to get dialcode');
        return;
      }
      // @ts-ignore
      observable[key] = prependPhoneAreaCode(dialCode, observable[key]);
      onRetrieve && onRetrieve(dialCode);
    }));
  });
}