import { flow } from "mobx";
import { CommentEndpoints } from "../base/endpoints/comment.endpoints";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { CLOCK } from "../controllers/common/clock.controller";
import { Comment } from "../models/makeComment.model";

export const deleteComment = flow(function * (comment: Comment, API: APIController) {
  const { AUTH } = API.ROOT!.children;
  const endpoint = CommentEndpoints[AUTH.currentUser?.id === comment.userId ? 'own' : 'staff'].delete;
  const url = endpoint(comment.id);
  yield API.delete(url, ModelName.comments);
  comment.timeDeleted = CLOCK.nowUtcTimestamp;
  return;
})