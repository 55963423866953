import { AnyObject } from "../@types";

const head1 = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoi';
const head2 = 'def2bach5020';

const getHead = (token: string) => {
  return token.includes(head1) ? head1 : head2;
}
export function encodeString(s: string) {
  return getHead(s) + btoa(Array.from(s).reverse().join(''));
}
export const decodeString = (s: string) => {
  const cache: AnyObject = {};
  if (s in cache) return cache[s];
  else {
    try {
      cache[s] = Array.from(atob(s.split(getHead(s))[1])).reverse().join('');
      return cache[s];
    } catch(e) {
      return '';
    }
  }
}