import { AxiosRequestConfig, AxiosResponse } from "axios"
import deepmerge from "deepmerge"
import { flow, observable, toJS, when } from "mobx"
import moment from "moment"
import { FunctionOnOTPFail, FunctionOnOTPVerified } from "../@types/mfa.types"
import { Nillable, Nullable } from "../base/@types"
import { AuthEndpoints } from "../base/endpoints/auth.endpoints"
import { UserEndpoints } from "../base/endpoints/user.endpoints"
import { keepTruthy } from "../base/utils/array.utils"
import { decodeString, encodeString } from "../base/utils/encoder.utils"
import { getErrorMessageFromRequest, reportError, setErrorHandlerContext } from "../base/utils/errors.utils"
import { AlwaysTrueFn } from "../base/utils/ramdaEquivalents.utils"
import { getNowTimestampUtc } from "../base/utils/time.utils"
import { getTrustToken } from "../base/utils/trust.utils"
import { getUrlParams } from "../base/utils/urlParams.utils"
import tick, { runAfter } from "../base/utils/waiters.utils"
import { ModelName } from "../constants/modelNames.enum"
import { AppPermission, isDeveloper, makePermissionChecker, PermissionChecker, VisitorPermissionChecker } from "../constants/permissions.constants"
import { AuthTokenKey } from "../constants/storageKeys.constants"
import { isOfficialSite, IS_DEV, SHOULD_LOG } from "../env"
import { User, UserPreferences } from "../models/makeUser.model"
import { LogoutPageRoute } from "../modules/Auth/PageLogout/Logout.page.route"
import { promptOTPVerification, promptOTPVerificationOverlay } from "../modules/Auth/_components/OverlayOTPVerification/OverlayOTPVerification"
import { saveCurrentUser } from "../requests/user.requests"
import { AnalyticsController } from "./analytics.controller"
import { APIController, baseHeaderPartial, makeAuthHeaderObject } from "./api.controller"
import { assignRandomColorIfNull } from "./auth/assignRandomColorIfNull"
import { makePermissionLookupTree } from "./auth/makePermissionLookupTree"
import { checkUserVerification } from "./auth/verificationChecker"
import { verifyAge } from "./auth/verifyAge"
import { NavigatorController } from "./navigator.controller"
import { StorageController } from "./storage.controller"
import { makeControllerBase, makeRootControllerChildInitFn } from "./_root.controller"

export const tempInvitationStorageKey = ['invitation'];

/**
 * AuthController is responsible for authentication, basic data & permissions of the current user.
 */
export type AuthController = ReturnType<typeof makeAuthController>;

export const makeAuthController = () => {

  const _private = observable({
    // disable2FAPrompts: IS_DEV && true,
    disable2FAPrompts: (IS_DEV || !isOfficialSite) && true,
    initialized: false,
    currentUser: null as Nillable<User>,
    // get role(): AppStaffRole {
    //   return _private.currentUser?.staffRole as unknown as AppStaffRole;
    // },
    get permissions(): AppPermission[] {
      const p = keepTruthy(decodeString(_private.currentUser?.permissionString ?? '').split('_'));
      // console.log('PERMISSIONS UDPATED:', p);
      return p as unknown as AppPermission[];
    },
    get isDeveloper() {
      return isDeveloper(c.currentUser);
    },
    /**
    * a function to check a certain permission.
    * @see the 'can' method
    * */
    get hasPermission(): PermissionChecker {
      if (!_private.initialized) return VisitorPermissionChecker;
      if (_private.isDeveloper) return AlwaysTrueFn;
      return makePermissionChecker(_private.permissions);
    },
    get hasAnyPermissions() {
      if (_private.isDeveloper) return true;
      return _private.permissions.length > 0;
    },
  })

  const storeToken = async (t: Nullable<string>) => {
    if (!t) {
      SHOULD_LOG() && console.warn('!! [AUTH] removing token ||');
      // debugger;
      c.STORAGE.remove(AuthTokenKey);
      return;
    }
    const token = t.includes('|') ? t.split('|')[1] : t;
    const encodedToken = encodeString(token);
    await c.STORAGE.set(AuthTokenKey, encodedToken);
  }

  const permissionLookupTree = makePermissionLookupTree(_private);

  const c = observable({

    ...makeControllerBase('AUTH'),

    get ANALYTICS(): AnalyticsController {
      return c.ROOT!.children.ANALYTICS;
    },
    get API(): APIController {
      return c.ROOT!.children.API;
    },
    get NAVIGATOR(): NavigatorController {
      return c.ROOT!.children.NAVIGATOR;
    },
    get STORAGE(): StorageController {
      return c.ROOT!.children.STORAGE;
    },

    get currentUser() { return _private.currentUser },
    set currentUser(user: Nillable<User>) {
      _private.currentUser = user;
    },

    /**
     * a tree-structure permission map, useful for quickly pinpointing a nested permission
     */
    get can() {
      return permissionLookupTree;
    },

    get login() {
      return (username: string, password: string) => new Promise<{ response: string }>(async (resolve, reject) => {
        await c.STORAGE.remove(AuthTokenKey);
        c.currentUser = null;
        try {
          const url = AuthEndpoints.login();
          const payload = { username, password };
          const response: AxiosResponse<string> = await c.API.postRaw<string>(url, payload);
          const { data: token } = response;
          const onOTPVerified: FunctionOnOTPVerified = async (doNotPromptOTPSetup?: boolean) => {
            if (token) await c.runAfterTokenRetrieval(token, { doNotPromptOTPSetup });
            c.checkTempInvitationLink();
            resolve({ response: "Passed 2FA" });
          }
          const onOTPFail: FunctionOnOTPFail = async (error) => {
            reject({ response: error?.message ? error.message : "Failed 2FA" });
          }
          if (token) {
            await c.runOTPChecker(token, onOTPVerified, onOTPFail, true);
          }
          onOTPFail();
        } catch (e) {
          reject(e);
        }
      })
    },

    get checkTempInvitationLink() {
      return async () => {
        const tempInvitationStored = await c.STORAGE.get(tempInvitationStorageKey);
        if (tempInvitationStored) {
          c.NAVIGATOR.navigateTo(tempInvitationStored);
          return tempInvitationStored;
        }
        return null;
      }
    },

    get runOTPChecker() {
      return async (
        token: string,
        onOTPVerified: FunctionOnOTPVerified,
        onOTPFail: FunctionOnOTPFail,
        doNotFetchUserOnVerified: boolean,
      ) => {
        const url = UserEndpoints.own.get({
          include: [
            'permissions',
            'roles',
          ],
        });
        const headers = {
          headers: {
            ...makeAuthHeaderObject(token),
            ...baseHeaderPartial,
          }
        } as AxiosRequestConfig;
        try {
          const user = await c.API.get<User>(url, ModelName.users, { replaceExisting: false, headers });
          if (!user) return;
          if (_private.disable2FAPrompts || !user.isStaff) return await onOTPVerified();
          return await promptOTPVerificationOverlay(c, user, onOTPVerified, onOTPFail, headers, doNotFetchUserOnVerified);
        } catch (e: any) {
          onOTPFail(getErrorMessageFromRequest(e));
          reportError(e);
          if (e.message === 'Network Error') {
            SHOULD_LOG() && console.log('Network error happened while getting current user info.');
          } else {
            SHOULD_LOG() && console.log(e.message);
            SHOULD_LOG() && console.log('Error while getting current user info');
          }
        }
      }
    },

    get runAfterTokenRetrieval() {
      return async (token: string, options?: { doNotPromptOTPSetup?: boolean }) => {
        await storeToken(token);
        c.API.configure(getTrustToken());
        const user = await c.fetchCurrentUserInfo();
        if (user) await verifyAge(c);
        if (!_private.disable2FAPrompts && !options?.doNotPromptOTPSetup && user && user.isStaff && !user.preferences.has2FA) promptOTPVerification(c, user);
        c.redirectBasedOnAuthState();
        // c.updateUserDetectedCountry();
      }
    },

    loggingOut: false,
    get logout() {
      return async function (options?: { showWarning?: boolean, redirectTo?: string}) {
        c.loggingOut = true;
        if (c.isAuthenticated) {
          c.currentUser = null;
          SHOULD_LOG() && console.info('logging out...');
          c.ROOT?.children.UI.DIALOG.reset();
          c.ROOT?.children.UI.OVERLAY.reset();
          c.ROOT?.children.UI.TOAST.reset();
          const url = AuthEndpoints.logout();
          try {
            await c.API.postRaw<string>(url);
          } catch (e) {
            // currently ignores errors from logout endpoint
          }
          c.NAVIGATOR.navigateTo(options?.redirectTo ?? '/auth/login');
          await c.ROOT!.reset();
          setErrorHandlerContext(null);
          if (options?.showWarning) {
            c.ROOT?.children.UI.TOAST.attention('Your session had expired. Please log in again.');
          }
        }
        c.loggingOut = false;
        return;
      }
    },

    get isStaff(): boolean {
      return c.currentUser?.isStaff ?? false;
    },
    get isAdmin(): boolean {
      return c.currentUser?.isAdmin ?? false;
    },
    get isAnalyst(): boolean {
      return c.currentUser?.isAnalyst ?? false;
    },
    get isFinancialAdministrator(): boolean {
      return c.currentUser?.isFinancialAdministrator ?? false;
    },
    get isCounsellor(): boolean {
      return c.can.provideCounsellingFor_.someUserGroups;
    },
    get canViewPIIAsCounsellor(): boolean {
      return c.isCounsellor;
    },
    get isModerator(): boolean {
      return c.canModerate
    },
    get isFacilitator(): boolean {
      return c.canFacilitate;
    },
    get canViewPIIAsFacilitator(): boolean {
      return c.isFacilitator;
    },
    get canViewPIIAsCounsellorFacilitator(): boolean {
      return c.canViewPIIAsCounsellor || c.canViewPIIAsFacilitator;
    },
    get canFacilitate(): boolean {
      return c.can.supportGroups_.facilitate_.someUserGroups || c.isCounsellor;
    },
    get canFacilitateChat(): boolean {
      return c.can.chat_.startChatWith_.staff || c.can.chat_.startChatWith_.client;
      // return c.can.chat_.startChatWith_.allUsers || c.can.chat_.startChatWith_.allClients || c.can.chat_.startChatWith_.someClients || c.can.chat_.startChatWith_.someUser || c.can.chat_.startChatWith_.staff || c.can.chat_.startChatWith_.client;
    },
    get canModerate(): boolean {
      return c.can.thoughtCatcher_.moderate_.someAgeGroups;
    },
    get canViewOtherUserDetails(): boolean {
      return c.isFacilitator || c.isCounsellor || c.isModerator || c.can.manage_.clients_.accessPersonalInfo || c.can.manage_.clients_.all;
    },

    get reset() {
      return async (invokedFromRoot?: boolean) => {
        SHOULD_LOG() && console.log('-- [AUTH] resetting auth --');
        await c.STORAGE.set(['DEBUG_AUTH_RESET'], getNowTimestampUtc())
        await c.STORAGE.remove(AuthTokenKey);
        c.currentUser = null;
        c.lastFetchedCurrentUserInfo = null;
        c.hasNotifiedAboutEmailVerification = false;
        c.hasNotifiedAboutMobilePhoneVerification = false;
        c.redirectBasedOnAuthState();
      }
    },

    /** @deprecated Previously used for patch user's detected country after each login. However, it seems API also does some country detection. */
    updateUserDetectedCountry: flow(function * () {
      yield tick(1);
      when(() => c.ANALYTICS.ready, async () => {
        await tick(5000); // hacky wait until checkUserLocation() in Analytics is done.
        const countryDetectedId = c.ANALYTICS.detected.countryCode;
        saveCurrentUser(c.API, {...c.currentUser!.$getSnapshot(), countryDetectedId})
      })
    }),

    lastFetchedCurrentUserInfo: null as moment.Moment | null,
    get fetchCurrentUserInfo() {
      return async (options?: { skipVerification?: boolean }) => {
        const { timeVerifiedEmail, timeVerifiedMobileNumber } = c.currentUser || {};
        const url = UserEndpoints.own.get({
          include: [
            'permissions',
            'roles',
            'addresses',
            // 'primaryAddress',
            'emergencyContacts',
            // 'primaryEmergencyContact',
            'company',
            'subscriptions',
          ],
          append: '_wasCounsellor',
        });
        let networkError = false;
        const user = await c.API.get<User>(url, ModelName.users).catch(e => {
          console.error(e);
          if (e.message === 'Network Error') {
            SHOULD_LOG() && console.log('Network happened while getting current user info.');
            networkError = true;
            return;
          } else {
            SHOULD_LOG() && console.log(e.message);
            SHOULD_LOG() && console.log('Error while getting current user info');
            c.logout({ showWarning: true });
            c.reset();
          }
        });
        if (networkError) return;
        if (!user) {
          c.reset();
          return;
        }
        if (!!user.timeDeleted) {
          c.reset();
          runAfter(() => {
            c.ROOT?.children.UI.DIALOG.error({
              heading: 'Failed to log in.',
              body: 'Please contact us if you are having trouble accessing your account.',
            })
          }, 100);
          return;
        }
        if (!!user.timeSuspended) {
          c.reset();
          runAfter(() => {
            c.ROOT?.children.UI.DIALOG.error({
              heading: 'This account has been suspended.',
              body: 'Please contact our customer service if you would like to submit a dispute or if you believe there has been an error.',
            })
          }, 100);
          return;
        }
        // console.log('current user info retrieved', user);
        c.currentUser = user;
        const { timeVerifiedEmail: updatedTimeVerifiedEmail, timeVerifiedMobileNumber: updatedTimeVerifiedMobileNumber } = c.currentUser || {};
        if (!options?.skipVerification) checkUserVerification(c);
        setErrorHandlerContext(c.currentUser);
        await assignRandomColorIfNull(user, c);
        if (timeVerifiedEmail !== updatedTimeVerifiedEmail) {
          c.hasNotifiedAboutEmailVerification = false;
        }
        if (timeVerifiedMobileNumber !== updatedTimeVerifiedMobileNumber) {
          c.hasNotifiedAboutMobilePhoneVerification = false;
        }
        c.lastFetchedCurrentUserInfo = moment();
        return c.currentUser;
      }
    },

    get fullCurrentUserInfoRetrieved(): boolean {
      return Boolean(c.currentUser?.id && c.currentUser?.preferences)
    },

    get saveCurrentUser() {
      return async () => await saveCurrentUser(c.API, c.currentUser?.$getSnapshot())
    },

    get isAuthenticated(): boolean {
      return Boolean(c.currentUser?.id);
    },
    get hasAnyPermissions() {
      return _private.hasAnyPermissions;
    },

    /** this method retrieves current user info before updating it. */
    get mergeIntoUserPreference() {
      return (value: Partial<UserPreferences>) => flow(function* () {
        const user = yield c.fetchCurrentUserInfo({ skipVerification: true });
        if (!user) {
          SHOULD_LOG() && console.warn('saveUserPreference attempted to retrieve current user info, but the user is returned. Is there a user logged in?')
          return null;
        }
        const overwriteMerge = (destinationArray: [], sourceArray: [], options: any) => sourceArray;
        user.preferences = deepmerge(toJS(user.preferences), value, { arrayMerge: overwriteMerge });
        yield c.saveCurrentUser();
      })()
    },

    get redirectBasedOnAuthState() {
      return async function () {
        await when(() => c.ROOT!.ready);
        const pathname = window.location.pathname;
        const fullPath = pathname + window.location.search;
        const isInvitationPage = pathname.match(/^\/app\/(invitation)/);
        if (isInvitationPage) c.STORAGE.setPublic(tempInvitationStorageKey, fullPath);
        const params = getUrlParams();
        const isInAuthArea = pathname.match(/^\/auth/);
        const isOnLogoutPage = pathname.match(/^\/auth\/(logout)/);
        const isOnVerifyEmailPage = pathname.match(/^\/auth\/(verify-email)/);
        const hasTempInvitationLink = await c.checkTempInvitationLink();
        if (isOnVerifyEmailPage) return;
        if (isOnLogoutPage) return;
        if (c.isAuthenticated) {
          const redirectedFrom = params.redirectedFrom !== LogoutPageRoute.urlFactory() ? params.redirectedFrom : undefined;
          if (redirectedFrom) console.log(`Redirected from ${redirectedFrom}`);
          if (isInAuthArea && !hasTempInvitationLink) {
            const destination = c.can.access_.adminPanels ? '/admin/dashboard' : '/app/explore';
            const to = redirectedFrom ? decodeURIComponent(redirectedFrom) : destination;
            c.NAVIGATOR.navigateTo(to);
          }
        } else {
          SHOULD_LOG() && console.log('reaction: not authenticated');
          if (isInAuthArea) return;
          c.NAVIGATOR.navigateTo(AuthEndpoints.login());
        }
      }
    },

    hasNotifiedAboutEmailVerification: false,
    hasNotifiedAboutMobilePhoneVerification: false,

  });

  const getStoredToken = async () => {
    return await c.ROOT!.children.STORAGE.get(AuthTokenKey) || null;
  }

  const checkExistingToken = flow(function * () {
    const encodedToken = yield getStoredToken();
    if (!encodedToken) {
      SHOULD_LOG() && console.log('-- [AUTH] token not found. --');
      when(() => c.ready, c.redirectBasedOnAuthState);
      return;
    }
    const decodedToken = decodeString(encodedToken);
    yield c.runAfterTokenRetrieval(decodedToken);
    return;
  })

  c.init = makeRootControllerChildInitFn(
    c,
    flow(function * () {
      _private.initialized = true;
      window.addEventListener('focus', () => {
        const lastCheckedMoreThanAMinuteAgo = moment().diff(c.lastFetchedCurrentUserInfo, 'minutes', true) > 1;
        if (c.isAuthenticated && (lastCheckedMoreThanAMinuteAgo || !c.currentUser?.hasVerifiedEmailOrMobileNumber)) c.fetchCurrentUserInfo();
      })
      try {
        yield checkExistingToken();
      } catch(e) {
        reportError(e);
      } finally {
        c.ready = true;
      }
    })
  )

  return c;
}
