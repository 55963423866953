import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import LoadingBlocker from '../../base/components/LoadingBlocker/LoadingBlocker';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useStore } from '../../base/utils/mobx.utils';
import tick from '../../base/utils/waiters.utils';
import './IFrameView.scss';

type IFrameViewProps = {
  /** a title is required. */
  title: string,
  src: HTMLIFrameElement['src'],
  delay?: number,
}

const IFrameView: React.FC<IFrameViewProps> = props => {
  const s = useStore(() => ({
    shouldRender: props.delay ? false : true,
  }))
  useOnMount(() => {
    flow(function * () {
      if (props.delay) {
        yield tick(props.delay);
        s.shouldRender = true;
      }
    })();
  })
  return <Observer children={() => (
    <div className="IFrameView">
      { s.shouldRender ? <iframe src={props.src} frameBorder={0} title={props.title} /> : <LoadingBlocker /> }
    </div>
  )} />
}

export default IFrameView;