import Color from "color";
import { SHOULD_LOG } from "../../env";
import { Nillable } from "../@types";
import { ContextColor } from "../constants/color.enum";
import { getRandomItemFromArray } from "./random.utils";
import { toLowerCase } from "./string.utils";
// import { getRandomInteger } from "./random";

export const DefaultColorPalette = {
  red: '#F7472F',
  crimson: '#AA0E0E',
  firebrick: '#C65D4F',
  redOrange: '#FF5E19',
  orange: '#FF9519',
  yellow: '#F0AE1E',
  gold: '#CEA046',
  brown: '#A65A2F',
  grassGreen: '#A2CF20',
  matcha: '#7B9939',
  green: '#2DC333',
  emerald: '#007931',
  neonGreen: '#1BE9B8',
  blueGreen: '#04A789',
  teal: '#28CFCF',
  lightBlue: '#76C7C2',
  skyblue: '#009FE3',
  skyblueLighter: '#2FCCFD',
  persianBlue: '#096891',
  blue: '#1F78FF',
  navy: '#1E46AE',
  brightPurple: '#5E5BE5',
  purple: '#6464AF',
  deepPurple: '#4A4181',
  lavender: '#A897EC',
  violet: '#7F44DF',
  royalPurple: '#431D82',
  magenta: '#F51B5C',
  plum: '#8E3163',
  pink: '#DE7EA1',
  white: '#ffffff',
  lightGray: '#D2DDE1',
  mediumGray: '#7D8389',
  steel: '#566A83',
  darkGray: '#363F47',
  shadow: '#0E0D35',
  black: '#000000',
}

export const defaultUserColors = [
  DefaultColorPalette.red,
  DefaultColorPalette.firebrick,
  DefaultColorPalette.crimson,
  DefaultColorPalette.redOrange,
  DefaultColorPalette.orange,
  DefaultColorPalette.yellow,
  DefaultColorPalette.gold,
  DefaultColorPalette.brown,
  DefaultColorPalette.grassGreen,
  DefaultColorPalette.matcha,
  DefaultColorPalette.green,
  DefaultColorPalette.blueGreen,
  DefaultColorPalette.emerald,
  DefaultColorPalette.teal,
  DefaultColorPalette.skyblue,
  DefaultColorPalette.persianBlue,
  DefaultColorPalette.blue,
  DefaultColorPalette.navy,
  DefaultColorPalette.brightPurple,
  DefaultColorPalette.purple,
  DefaultColorPalette.royalPurple,
  DefaultColorPalette.plum,
  DefaultColorPalette.steel,
]

export type DefaultColorName = keyof typeof DefaultColorPalette

export const CSSColorKeywords = ['inherit', 'transparent', 'currentColor'];
export const isCSSColorKeyword = (s?: Nillable<string>) => {
  if (!s) return false;
  return CSSColorKeywords.includes(s);
}

export const withOpacity = (color: Nillable<string>, opacity: number) => {
  if (!color) return undefined;
  if (isCSSColorKeyword(color)) return color;
  try {
    return Color(color).alpha(opacity).rgb().string();
  } catch (e) {
    SHOULD_LOG() && console.warn(`The color ${color} supplied to withOpacity() is not in a valid color format.`);
    return color;
  }
};

export const getColorHexByName = (colorName?: DefaultColorName | string, fallback?: string) => (colorName ? DefaultColorPalette[colorName as DefaultColorName] : colorName) ?? fallback ?? colorName ?? DefaultColorPalette.skyblue;

export const isBrightColor = (colorHex?: string | null) => colorHex ? Color(colorHex || '#000').luminosity() >= .48 : false;

export function getContrastColorHex(colorHex?: string | null) {
  return isBrightColor(colorHex) ? DefaultColorPalette.deepPurple : DefaultColorPalette.white;
}

export const checkIfShouldInvertStyle = (ref: React.RefObject<HTMLElement | null>): boolean => {
  let result = false;
  const el = ref?.current;
  if (!el) result = false;
  else {
    try {
      const { color } = getComputedStyle(el);
      result = Color(color || '').isLight();
    } catch (e) {
      result = false;
    }
  }
  return result;
}

export function isHexCode(input?: string | null) {
  if (!input) return false;
  return /^#[0-9A-F]{6}$/i.test(input) || /^#[0-9A-F]{3}$/i.test(input) || /^#[0-9A-F]{8}$/i.test(input);
}

export function getRandomColorHexFromPalette() {
  return getRandomItemFromArray(Object.values(DefaultColorPalette));
}

export function getContextColorStyle(contextColorName?: ContextColor, color?: string | null, namespace: string = 'app'): React.CSSProperties {
  if (!contextColorName || !color) {
    return {};
  }
  const colorName = toLowerCase(contextColorName);
  if (isHexCode(color)) {
    const colorObj = Color(color);
    const h = colorObj.hue();
    const s = colorObj.saturationl() + '%';
    const l = colorObj.lightness() + '%';
    const contrastColor = Color(getContrastColorHex(color));
    const ch = contrastColor.hue();
    const cs = contrastColor.saturationl() + '%';
    const cl = contrastColor.lightness() + '%';
    return {
      [`--${namespace}-c-${colorName}-h`]: h,
      [`--${namespace}-c-${colorName}-s`]: s,
      [`--${namespace}-c-${colorName}-l`]: l,
      [`--${namespace}-c-${colorName}-hsl`]: [h,s,l].join(','),
      [`--${namespace}-c-${colorName}_h`]: ch,
      [`--${namespace}-c-${colorName}_s`]: cs,
      [`--${namespace}-c-${colorName}_l`]: cl,
      [`--${namespace}-c-${colorName}_hsl`]: [ch,cs,cl].join(','),
    }
  } else {
    return {
      [`--${namespace}-c-${colorName}-h`]: `var(--${namespace}-c-${color}-h)`,
      [`--${namespace}-c-${colorName}-s`]: `var(--${namespace}-c-${color}-s)`,
      [`--${namespace}-c-${colorName}-l`]: `var(--${namespace}-c-${color}-l)`,
      [`--${namespace}-c-${colorName}-hsl`]: `var(--${namespace}-c-${color}-hsl)`,
      [`--${namespace}-c-${colorName}_h`]: `var(--${namespace}-c-${color}_h)`,
      [`--${namespace}-c-${colorName}_s`]: `var(--${namespace}-c-${color}_s)`,
      [`--${namespace}-c-${colorName}_l`]: `var(--${namespace}-c-${color}_l)`,
      [`--${namespace}-c-${colorName}_hsl`]: `var(--${namespace}-c-${color}_hsl)`,
    }
  }
}