export enum ModelName {
	'addresses' = 'addresses',
	'alerts' = 'alerts',
	'assignments' = 'assignments',
	'companies' = 'companies',
	'chatMessages' = 'chatMessages',
	'chatParticipants' = 'chatParticipants',
	'chatThreads' = 'chatThreads',
	'comments' = 'comments',
	'configurations' = 'configurations',
	'contacts' = 'contacts',
	'contactForms' = 'contactForms',
	'counsellingApplications' = 'counsellingApplications',
	'counsellingAvailabilities' = 'counsellingAvailabilities',
	'counsellingSessions' = 'counsellingSessions',
	'fees' = 'fee',
	'feedback' = 'feedback',
	'flags' = 'flags',
	'invitations' = 'invitations',
	'mediaItems' = 'mediaItems',
	'moderatedTerms' = 'moderatedTerms',
	'payments' = 'payments',
	'reactions' = 'reactions',
	'invoices' = 'invoices',
	'invoiceItems' = 'invoiceItems',
	'subscriptions' = 'subscriptions',
	'supportGroupReservations' = 'supportGroupReservations',
	'supportGroups' = 'supportGroups',
	'supportGroupTopics' = 'supportGroupTopics',
	'supportTickets' = 'supportTickets',
	'surveysGAD7' = 'surveysGAD7',
	'surveysGeneral' = 'surveysGeneral',
	'surveysGoalSheet' = 'surveysGoalSheet',
	'surveysPHQ9' = 'surveysPHQ9',
	'surveysSatisfaction' = 'surveysSatisfaction',
	'surveysSupportGroupNonAttendance' = 'surveysSupportGroupNonAttendance',
	'surveysSupportGroupSatisfaction' = 'surveysSupportGroupSatisfaction',
	'thoughts' = 'thoughts',
	'users' = 'users',
}
