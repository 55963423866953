import { action, flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { ActionConfig } from '../../../controllers/ui/ui.controller.types';
import { Nullable, Renderable } from '../../@types';
import { useControllers } from '../../hooks/useRootController.hook';
import joinClassName from '../../utils/className.utils';
import { renderRenderable } from '../../utils/components.utils';
import { getScrollParent } from '../../utils/dom.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import tick from '../../utils/waiters.utils';
import BaseIcon from '../BaseIcon/BaseIcon';
import ClickOutside from '../ClickOutside/ClickOutside';
import './MenuToggle.scss';

interface MenuToggleProps {
  actions: ActionConfig[],
  onMenuOpen?: () => void,
  onMenuClose?: () => void,
  dataCy?: string;
  alignToRight?: boolean,
  buttonContentComponent?: Renderable,
}

const MenuToggle: React.FC<MenuToggleProps> = props => {
  const p = useProps(props);
  const { UI } = useControllers();
  const { PORTAL } = UI;
  const buttonRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const s = useStore(() => ({
    shouldDisplayMenu: false,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    buttonHeight: 0,
    menuWidth: 0,
    menuHeight: 0,
    isExiting: false,
    getPositions: action(() => {
      const boundingBox = buttonRef.current?.getBoundingClientRect();
      s.left = boundingBox?.left || 0;
      s.right = UI.appWidth - ((boundingBox?.left || 0) + (boundingBox?.width || 0));
      s.top = boundingBox?.top || 0;
      s.bottom = boundingBox?.bottom || 0;
      s.buttonHeight = boundingBox?.height || 0;
      const parent = getScrollParent(buttonRef.current);
      const parentBoundingBox = parent?.getBoundingClientRect();
      const parentTop = (parentBoundingBox?.top || 0) - 28;
      const parentBottom = (parentBoundingBox?.bottom || UI.appHeight) + 28;
      if (s.top < parentTop || s.top > parentBottom) {
        s.closeMenu();
      }
    }),
    getMenuMeasurements: action(() => {
      const menuBoundingBox = menuRef.current?.getBoundingClientRect();
      s.menuWidth = menuBoundingBox?.width || 0;
      s.menuHeight = menuBoundingBox?.height || 0;
    }),
    watchScrollParent: action(() => {
      if (!buttonRef.current) return;
      const parent = getScrollParent(buttonRef.current);
      if (!parent) return;
      const handler = async () => {
        s.getPositions();
        await tick();
        s.getMenuMeasurements();
      };
      parent.addEventListener('scroll', handler);
      s.disposeWatchScrollParent = () => parent.removeEventListener('scroll', handler);
    }),
    disposeWatchScrollParent: null as Nullable<Function>,
    handleMenuButtonClick: flow(function * () {
      s.getPositions();
      s.shouldDisplayMenu = true;
      p.onMenuOpen && p.onMenuOpen();
      yield tick();
      s.getMenuMeasurements();
      s.isExiting = false;
      s.watchScrollParent();
    }),
    performAction: (a: ActionConfig) => () => {
      if (s.isExiting) return;
      a.action();
      s.closeMenu();
      s.disposeWatchScrollParent?.();
    },
    closeMenu: flow(function * () {
      s.isExiting = true;
      p.onMenuClose && p.onMenuClose();
      yield tick(100);
      s.shouldDisplayMenu = false;
      s.isExiting = false;
    }),
    get menuStyle() {
      const positionOnLeftWouldOverflow = (s.left + s.menuWidth) > UI.appWidth;
      const positionOnBottomWouldOverflow = (s.top + s.buttonHeight + s.menuHeight) > UI.appHeight;
      const left = positionOnLeftWouldOverflow || p.alignToRight ? undefined : s.left;
      const right = positionOnLeftWouldOverflow || p.alignToRight ? s.right : undefined;
      const top = positionOnBottomWouldOverflow ? s.top - s.menuHeight : s.top + s.buttonHeight;
      return { top, left, right }
    }
  }))
  return <Observer children={() => (
    <div className={joinClassName('MenuToggle', s.isExiting && 'exiting')} ref={buttonRef} data-cy={p.dataCy}>
      <div onClick={s.handleMenuButtonClick}>
        {props.buttonContentComponent !== undefined ? renderRenderable(props.buttonContentComponent) : <BaseIcon name="more" /> }
      </div>
      { s.shouldDisplayMenu && PORTAL.render(
        <ClickOutside onClickOutside={s.closeMenu} className={joinClassName(s.isExiting && 'exiting')}>
          <div className="MenuToggleMenu" style={s.menuStyle} ref={menuRef}>
            <ul>
              {p.actions.map(a => <li
                key={a.id || a.name || a.label}
                color={a.color}
                className={joinClassName(a.colorCodedState && `state-${a.colorCodedState}`)}
              >
                <button
                  onClick={s.performAction(a)}
                  data-cy={a.dataCy}
                  name={a.name ?? a.label}
                  title={a.label ?? a.name}
                >
                  <BaseIcon name={a.icon || 'blank'} />
                  <span>{a.label}</span>
                </button>
              </li>)}
            </ul>
          </div>
        </ClickOutside>
      )}
    </div>
  )} />
}

export default MenuToggle;