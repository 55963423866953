import { observable } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import AppPage from '../../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../../base/components/AppPageHeader/AppPageHeader';
import BackButton from '../../../../../base/components/BackButton/BackButton';
import BaseSpacer from '../../../../../base/components/BaseSpacer/BaseSpacer';
import IndexDirectory from '../../../../../base/components/IndexDirectory/IndexDirectory';
import { IndexDirectoryViewMode } from '../../../../../base/components/IndexDirectory/indexDirectory.types';
import IndexDirectoryState from '../../../../../base/components/IndexDirectory/IndexDirectoryState';
import UIBlock from '../../../../../base/components/UIBlock/UIBlock';
import { PaymentEndpoints } from '../../../../../base/endpoints/payment.endpoints';
import { useControllers } from '../../../../../base/hooks/useRootController.hook';
import { makeLaravelIndexDataFetcher } from '../../../../../base/utils/api.utils';
import { reportError } from '../../../../../base/utils/errors.utils';
import { useStore } from '../../../../../base/utils/mobx.utils';
import PaymentEntry from '../../../../../components/PaymentEntry/PaymentEntry';
import { ApiModelName } from '../../../../../constants/ApiModels.enum';
import { ModelName } from '../../../../../constants/modelNames.enum';
import { Payment } from '../../../../../models/makePayment.model';
import './AccountPageReceipts.scss';

type AccountPageReceiptsProps = {}

const AccountPageReceipts: React.FC<AccountPageReceiptsProps> = props => {

  const { API } = useControllers();

  const s = useStore(() => ({
    indexDirectoryState: new IndexDirectoryState<Payment>(observable({
      viewMode: 'list' as IndexDirectoryViewMode,
      searchable: false,
    })),
  }));

  const getPaymentData = makeLaravelIndexDataFetcher<Payment>(
    API,
    ModelName.payments,
    PaymentEndpoints.own.index,
    {
      sort: '-timeCreated',
      include: ['model'],
      filter: {
        whereEquals: {
          key: 'modelType',
          values: [ApiModelName.COUNSELLING_SESSION]
        },
      }
    },
    {
      onError: (e) => reportError(e),
    }
  )

  return <Observer children={() => (
    <AppPage className="AccountPageReceipts"
      accommodateTitleBarSpace
      color="purple"
    >
      <AppPageHeader
        title="Receipts"
        afterTitle="View your online counselling payment history"
        startSlot={<BackButton destination="up" />}
        // endSlot={<PrintButton rounded />}
      />
      <AppPageContent>
        <UIBlock padded>
          <IndexDirectory<Payment>
            state={s.indexDirectoryState}
            dataFetcher={getPaymentData}
            listEntrySeparator={<BaseSpacer size=".5em" />}
            listEntryRenderer={p => <PaymentEntry payment={p} showSessionId showReceiptOverlayButton />}
          />
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
}

export default AccountPageReceipts;