import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useControllers } from '../../hooks/useRootController.hook';
// import './ClickOrTap.scss';

interface ClickOrTapProps {
  capitalized?: boolean,
}

const ClickOrTap: React.FC<ClickOrTapProps> = props => {
  const { UI } = useControllers();
  return <Observer children={() => (
    <>
      {
        props.capitalized ? (
          UI.canTouch ? 'Tap' : 'Click'
        ) : (
            UI.canTouch ? 'tap' : 'click'
          )
      }
    </>
  )} />
}

export default ClickOrTap;