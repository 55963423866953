import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseHeading, { BaseHeadingProps } from '../BaseHeading/BaseHeading';
import './BaseHeader.scss';

interface BaseHeaderProps extends BaseHeadingProps {
  endSlot?: React.ReactChild | React.FC | React.ComponentClass | boolean,
}

const BaseHeader: React.FC<BaseHeaderProps> = props => {
  return <Observer children={() => (
    <div className="BaseHeader">
      <div className="BaseHeaderContent">
        <BaseHeading {...props} />
        { props.children }
      </div>
      <div className="BaseHeaderEndSlot">
        {typeof props.endSlot === 'function' ? <props.endSlot /> : props.endSlot}
      </div>
    </div>
  )} />
}

export default BaseHeader;