import { Alert } from "../../@types/alert.types";
import { CommentEndpoints } from "../../base/endpoints/comment.endpoints";
import { ThoughtEndpoints } from "../../base/endpoints/thought.endpoints";
import { reportError } from "../../base/utils/errors.utils";
import { isNil } from "../../base/utils/ramdaEquivalents.utils";
import { AlertTypeName } from "../../constants/alertType.enum";
import { ModelName } from "../../constants/modelNames.enum";
import { FlaggableModelTrait } from "../../traits/isFlaggable.trait";
import { getModelNameFromApiModelName } from "../../utils/models.utils";
import { AlertsController } from "../alerts.controller";

export const cleanUpStaleAlerts = async (
  ALERT: AlertsController
) => {
  const { alerts } = ALERT;
  const { LOCALDB, API } = ALERT.ROOT!.children;
  const toDismiss = [] as Alert[];
  for (let alert of alerts) {
    switch (alert.type) {
      case AlertTypeName.Staff__NewModerationFlagRaised: {
        const type = getModelNameFromApiModelName(alert.data.flaggableType);
        const id = alert.data.flaggableId;
        if (!type || !id) {
          toDismiss.push(alert);
          break;
        }
        const endpoint = type === ModelName.thoughts ? ThoughtEndpoints.staff.get(id) : CommentEndpoints.staff.get(id);
        try {
          const flaggable = (LOCALDB.get(type, id) || await API.get(endpoint, type)) as FlaggableModelTrait | null;
          if (!flaggable) break;
          if (!isNil(flaggable.isSafe) || !!flaggable.timeHidden) {
            toDismiss.push(alert);
          }
          break;
        } catch(e) {
          reportError(e);
        }
        break;
      }

      default: {
        return;
      }
    }
  }
  if (toDismiss.length > 0) console.log(`found ${toDismiss.length} stale alerts to dismiss`);
  toDismiss.forEach(a => ALERT.dismiss(a));
}