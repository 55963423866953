import nlp from 'compromise';

/**
 * Create a word matcher for fuzzy matching given set of words, with the `nlp` package
 * @param wordSets
 */
export const makeWordMatcher = (wordSets: string[][]) => (input?: string) => {
  const result = {
    matchedWords: new Set<string>(),
    matched: false,
  };
  if (!input) return result;
  const doc = nlp(input);
  wordSets.forEach(set => {
    set.forEach(w => {
      if (doc.has(w)) result.matchedWords.add(w);
    });
  })
  result.matched = Array.from(result.matchedWords).length > 0;
  return result;
}

/**
 * Word counter, with the `nlp` package
 */
export const countWords = (input: string) => {
  return nlp(input).wordCount();
}