import moment from "moment";
import { StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { ChatType } from "../base/mediators/chat.mediator";
import { createUTCMoment } from "../base/utils/time.utils";
import { ApiModelName } from "../constants/ApiModels.enum";
import { ModelName } from "../constants/modelNames.enum";
import { CLOCK } from "../controllers/common/clock.controller";
import { hasTimestamps } from "../traits/hasTimestamps.trait";
import { ChatMessage } from "./makeChatMessage.model";
import { ChatParticipant } from "./makeChatParticipant.model";
import { CounsellingSession } from "./makeCounsellingSession.model";
import { SupportGroup } from "./makeSupportGroup.model";

export type ChatThreadSnapshot = ReturnType<typeof makeChatThreadSnapshotBase>;
export type ChatThreadAssociatedModel = CounsellingSession | SupportGroup;
export type ChatThreadRelatedModels<T extends ChatThreadAssociatedModel = ChatThreadAssociatedModel> = {
  participants: ChatParticipant[],
  messages: ChatMessage[],
  model?: T,
}
export type ChatThreadAssociatedModelName =
  | typeof ApiModelName.COUNSELLING_SESSION
  | typeof ApiModelName.SUPPORT_GROUP
;

export type ChatThreadExtendedProperties = {
  readonly type: ChatType,
  readonly duration: number,
};

export function makeChatThreadSnapshotBase () {
  return {
    id: '',
    uuid: '',
    modelType: '' as ChatThreadAssociatedModelName | null | '',
    modelId: '',
    participantIds: [] as string[],
    messageIds: [] as string[],
    timeStarted: '',
    timeEnded: '',
    timeVideoStarted: '',
    timeVideoEnded: '',
    ...hasTimestamps(),
  }
}

export function getChatThreadType(chatThread: ChatThread): ChatType {
  const { modelType } = chatThread;
  if (!modelType) return 'default';
  if (modelType === ApiModelName.COUNSELLING_SESSION) return 'counselling-session';
  if (modelType === ApiModelName.SUPPORT_GROUP) return 'support-group';
  return 'default';
}

export type ChatThread = StandardModel<ChatThreadSnapshot, ChatThreadRelatedModels, ChatThreadExtendedProperties>

export const makeChatThread = createStandardModelFactory<ChatThread, ChatThreadRelatedModels, ChatThreadExtendedProperties>({

  name: ModelName.chatThreads,

  snapshotFactory: makeChatThreadSnapshotBase,

  relationshipsSchemaFactory: s => ({
    participants: { modelName: ModelName.chatParticipants, has: "many" },
    messages: { modelName: ModelName.chatMessages, has: "many" },
    model: s?.modelType === ApiModelName.SUPPORT_GROUP ? ModelName.supportGroups : ModelName.counsellingSessions,
  }),

  extendedPropertiesFactory: m => ({
    get type() {
      return getChatThreadType(m);
    },  
    get duration() {
      return m.timeStarted ? createUTCMoment(m.timeStarted).diff(createUTCMoment(m.timeEnded ?? moment(CLOCK.localNowMoment).utc())) : 0;
    },
  })

})