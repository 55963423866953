import Color from "color";
import { HasColor } from "../@types/traits.types";
import { ContextColor } from "../constants/color.enum";
import { getContrastColorHex } from "../utils/colors.utils";

export const useColorCSSCustomPropertyStyle = (obj?: Partial<HasColor>, asContextColor: ContextColor = ContextColor.Primary): React.CSSProperties => {
  if (!obj || !obj.color) return {};
  const color = Color(obj.color);
  const hsl = color.hsl();
  const h = hsl.hue();
  const s = hsl.saturationl() + '%';
  const l = hsl.lightness() + '%';
  const contrastColor = Color(getContrastColorHex(obj.color));
  const ch = contrastColor.hue();
  const cs = contrastColor.saturationl() + '%';
  const cl = contrastColor.lightness() + '%';
  return {
    [`--app-c-${asContextColor}-h`]: h,
    [`--app-c-${asContextColor}-s`]: s,
    [`--app-c-${asContextColor}-l`]: l,
    [`--app-c-${asContextColor}-hsl`]: `${h},${s},${l}`,
    [`--app-c-${asContextColor}_h`]: ch,
    [`--app-c-${asContextColor}_s`]: cs,
    [`--app-c-${asContextColor}_l`]: cl,
    [`--app-c-${asContextColor}_hsl`]: `${ch},${cs},${cl}`,
  }
}