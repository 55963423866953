import deepmerge from "deepmerge";
import { flow, toJS } from "mobx";
import { UserEndpointParams, UserEndpoints } from "../base/endpoints/user.endpoints";
import { reportError } from "../base/utils/errors.utils";
import { copyWithJSON } from "../base/utils/object.utils";
import { formatDateOfBirth } from "../base/utils/time.utils";
import { ModelName } from "../constants/modelNames.enum";
import { APIController, APIGetRequestOptions } from "../controllers/api.controller";
import { User, UserSnapshot } from "../models/makeUser.model";

export const getUser = flow(function * (id: string, API: APIController, options?: APIGetRequestOptions & { params?: UserEndpointParams }) {
  const endpoint = UserEndpoints.client.get;
  const url = endpoint(id, deepmerge(options?.params || {}, { include: ['permissions', 'roles']}));
  try {
    const user: User = yield API.get(url, ModelName.users, options);
    return user;
  } catch (e) {
    reportError(e);
    return null;
  }
})

export const saveCurrentUser = flow(function * (API: APIController, snapshot?: Partial<UserSnapshot>) {
  const { AUTH, UI } = API.ROOT!.children;
  const { currentUser } = AUTH;
  if (!currentUser) {
    UI.TOAST.attention('Cannot save current user. No user is currently authenticated.');
    return;
  }
  const oldUserCopy = copyWithJSON(currentUser.$getSnapshot());
  if (snapshot) {
    const { id, ...rest } = snapshot;
    currentUser.$patch(rest);
  }
  const payload = currentUser.$getSnapshot();
  const url = UserEndpoints.own.update();
  if (!!payload.dateOfBirth) {
    payload.dateOfBirth = formatDateOfBirth(payload.dateOfBirth)
  }

  Reflect.deleteProperty(payload, 'timeVerifiedEmail');
  Reflect.deleteProperty(payload, 'timeVerifiedMobileNumber');
  Reflect.deleteProperty(payload, 'ipAddress');
  try {
    const user: User = yield API.patch(url, ModelName.users, toJS(payload));
    return user;
  } catch(e) {
    currentUser.$patch(oldUserCopy);
    throw e;
  }
})

export const checkIfShouldResetEmailVerification = (prev: Partial<UserSnapshot>, next: Partial<UserSnapshot>) => {
  const { email, timeVerifiedEmail } = prev || {};
  if (email && timeVerifiedEmail) {
    const { email: newEmail } = next || {};
    if (email !== newEmail) return true;
    return false;
  }
  return false;
}

export const checkIfShouldResetMobileNumberVerification = (prev: Partial<UserSnapshot>, next: Partial<UserSnapshot>) => {
  const { mobileNumber, timeVerifiedMobileNumber } = prev || {};
  if (mobileNumber && timeVerifiedMobileNumber) {
    const { mobileNumber: newMobileNumber } = next || {};
    if (mobileNumber !== newMobileNumber) return true;
    return false;
  }
  return false;
}