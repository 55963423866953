import { Identifier, Nullable } from "../base/@types";
import { DefaultSupportGroupIncludes, SupportGroupEndpointParams, SupportGroupEndpoints } from "../base/endpoints/supportGroup.endpoints";
import { useControllers } from "../base/hooks/useRootController.hook";
import { ModelName } from "../constants/modelNames.enum";
import { SupportGroup } from "../models/makeSupportGroup.model";
import { useAutoGet, UseAutoGetFactoryOptions } from "../utils/useAutoGet.utils";

export function useGetSupportGroup<ObservableType extends object = object>(
  options: UseAutoGetFactoryOptions<SupportGroup, ObservableType>,
  id?: Nullable<Identifier>,
  params?: SupportGroupEndpointParams,
) {
  const { AUTH } = useControllers();
  const canUseAdminEndpoint = AUTH.can.supportGroups_.manage_.groups;
  const _params = params || {
    include: DefaultSupportGroupIncludes
  }
  useAutoGet<SupportGroup, ObservableType>({
    ...options,
    url: id ? SupportGroupEndpoints[canUseAdminEndpoint ? 'staff' : 'client'].get(id, _params) : undefined,
    modelName: ModelName.supportGroups,
  });
}