import { Assignment, AssignmentRelatedModels } from "../../models/makeAssignment.model";
import { StringKeyOf } from "../@types";
import { DefaultIndexParamSet } from "../utils/api.utils";
import { makeApiEndpointConfig, makeApiGetEndpointConfig } from "../utils/endpoints.utils";
import { makeUrl } from "../utils/url.utils";
import { prefixOwn, prefixStaff } from "./namespacePrefixes";

export type AssignmentEndpointParams = DefaultIndexParamSet<Assignment, AssignmentRelatedModels> & {
}

const ownUrlBase = prefixOwn('assignments');
const staffUrlBase = prefixStaff('assignments');

export const AssignmentEndpoints = {
  own: {
    index: makeApiEndpointConfig<AssignmentEndpointParams>(ownUrlBase),
    get: makeApiGetEndpointConfig<AssignmentEndpointParams>(ownUrlBase),
  },
  staff: {
    index: makeApiEndpointConfig<AssignmentEndpointParams>(staffUrlBase),
    get: makeApiGetEndpointConfig<AssignmentEndpointParams>(staffUrlBase),
    create: () => staffUrlBase,
    update: (id: string) => makeUrl(staffUrlBase, id),
    delete: (id: string) => makeUrl(staffUrlBase, id),
  },
}

export const AssignmentGetEndpointDefaultIncludes: StringKeyOf<AssignmentRelatedModels>[] = [
  'assignedToUser',
  'associated',
  'target'
]
export const AssignmentGetEndpointDefaultIncludesForAdmin: StringKeyOf<AssignmentRelatedModels>[] = [
  'assignedByUser',
  'assignedToUser',
  'associated',
  'target'
]