import { Observer } from 'mobx-react-lite';
import React from 'react';
import ColorTag from '../../base/components/ColorTag/ColorTag';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { ChatMediator } from '../../base/mediators/chat.mediator';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { autoPluralize } from '../../base/utils/string.utils';
import { SupportGroup } from '../../models/makeSupportGroup.model';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import './ChatTitleDisplayer.scss';

interface ChatTitleDisplayerProps {
  chat: ChatMediator
}

function ChatTitleDisplayer(props: React.PropsWithChildren<ChatTitleDisplayerProps>) {
  const { AUTH } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    get chat() {
      return p.chat;
    },
    get participants() {
      return s.chat.participants;
    },
    get currentUser() {
      return AUTH.currentUser;
    },
    get currentUserIsInChat() {
      return s.participants.length !== s.otherPeople.length;
    },
    get otherPeople() {
      return s.participants?.filter(p => p.user?.id + '' !== s.currentUser?.id + '') || [];
    },
    get associatedModelTitle() {
      return (s.chat.associatedModel as SupportGroup)?.title
    },
    get title() {
      if (s.associatedModelTitle) {
        return s.associatedModelTitle;
      }
      const { length } = s.otherPeople;
      if (length < 1) return 'Chat Session';
      if (length <= 2) {
        const nameTags = s.otherPeople.map(participant => <UsernameRenderer user={participant.user} userId={participant.userId} key={participant.id} />);
        const result = nameTags.map((t, i) => [t, i === length - 2 ? ' and ' : ',']).flat(1);
        result.pop(); // removing the trailing comma
        return <>
          {s.currentUserIsInChat ? 'With ' : ''}
          {result.map(r => r)}
        </>
      }
      return `Chat Session (${autoPluralize(length + 1, 'participant')})`;
    },
    get hasEnded() {
      return p.chat.hasEnded
    }
  }))
  return <Observer children={() => (
    <div className="ChatTitleDisplayer">
      {s.title} {s.hasEnded && <ColorTag color="positive">Ended</ColorTag>}
    </div>
  )} />
}

export default ChatTitleDisplayer;