import React from 'react';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import './LoadingBlocker.scss';

interface LoadingBlockerProps {
  message?: React.ReactElement,
}

const LoadingBlocker: React.FC<LoadingBlockerProps> = props => {
  return <div className="LoadingBlocker">
    <div className="LoadingBlockerBackdrop" />
    <div className="LoadingBlockerInner">
      <LoadingIndicator />
      {
        props.message && (
          <div className="LoadingBlockerMessage">
            {props.message}
          </div>
        )
      }
    </div>
  </div>
}

export default LoadingBlocker;