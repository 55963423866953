import { AddressEndpointParams, AddressEndpoints } from "../base/endpoints/address.endpoints";
import { isFunction } from "../base/utils/typeChecks.utils";
import { ModelName } from "../constants/modelNames.enum";
import { Address } from "../models/makeAddress.model";
import { useAutoGet, UseAutoGetFactoryOptions } from "../utils/useAutoGet.utils";

export function useGetAddresses<ObservableType extends object = object>(
  options: UseAutoGetFactoryOptions<Address[], ObservableType> & {
    own?: boolean,
  },
  params?: AddressEndpointParams | (() => AddressEndpointParams),
) {
  const { when = () => true } = options || {};
  useAutoGet<Address[], ObservableType>({
    ...options,
    url: when() ? AddressEndpoints[options.own ? 'own' : 'staff'].index(
      isFunction(params) ? params() : params
    ) : undefined,
    asIndex: true,
    modelName: ModelName.addresses,
  });
}