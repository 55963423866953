import { Identifier, Nillable } from "../base/@types";
import { hasTimestamps } from "./hasTimestamps.trait";

export const assignable = () => ({
	id: '' as Identifier,
	assignmentId: null as Nillable<Identifier>,
	assignedToUserId: null as Nillable<Identifier>,
	completedByUserId: null as Nillable<Identifier>,
	relatedCounsellorId: null as Nillable<Identifier>,
	...hasTimestamps(),
})