import Color from 'color';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Nillable } from '../../base/@types';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import { getColorHexByName } from '../../base/utils/colors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { PreviewSupportGroup, SupportGroup } from '../../models/makeSupportGroup.model';
import LGBTQFlag from '../LGBTQFlag/LGBTQFlag';
import './SupportGroupIcon.scss';

interface SupportGroupIconProps {
  supportGroup?: Nillable<SupportGroup | PreviewSupportGroup>,
  size?: number;
}

const SupportGroupIcon: React.FC<SupportGroupIconProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get icon() {
      switch (true) {
        case (p.supportGroup?.topic?.slug || p.supportGroup?.topic?.displayName)?.toLowerCase().includes('lgbt'):
          return <LGBTQFlag />
        default:
          return <BaseIcon icon="people" variant="filled" size={p.size ? p.size * .5 : 24}/>
      }
    },
    get color() {
      return p.supportGroup?.topic?.color || getColorHexByName('blueGreen');
    },
    get backgroundImage() {
      return `radial-gradient(257.85% 97.17% at 34.88% 2.83%, ${s.color} 0%, ${Color(s.color).lighten(.19).hex()} 100%)`
    }
  }))
  return <Observer children={() => (
    <div className="SupportGroupIcon" style={{
      backgroundImage: s.backgroundImage,
      width: props.size,
      height: props.size,
    }}>
      { s.icon }
    </div>
  )} />
}

export default SupportGroupIcon;