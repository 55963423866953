import { Nillable, Validator } from "../@types";

export const validateEmail: Validator = (
  value?: string
) => {
  const isValid = value?.split('').filter(x => x === '@').length === 1 && !value?.match(/[<>,;()|:\\/]/);
  const message = value ? (
    `${value?.toString()} is not a valid email address.`
  ) : (
    'Please provide a valid email address.'
  );
  return isValid || {
    isValid,
    message
  }
}

export const hasValidEmail = (email: Nillable<string>) => {
  return !!email && /^.+@.+\..+$/.test(email) && validateEmail(email) === true;
}