import { Observer } from "mobx-react-lite";
import React from "react";
import { UIController } from "../controllers/ui.controller";
import { CounsellingApplication } from "../models/makeCounsellingApplication.model";
import OverlayApplicationManager from "../modules/Admin/_components/OverlayApplicationManager/OverlayApplicationManager";

export const openOverlayApplicationManager = (
  UI: UIController,
  applicationId?: string,
  application?: CounsellingApplication,
) => {
  UI.OVERLAY.present({
    id: `OverlayApplicationManager#${applicationId ?? application?.id}`,
    name: `OverlayApplicationManager`,
    component: <Observer children={() => <OverlayApplicationManager application={application} applicationId={applicationId ?? application?.id} />} />,
    width: '75vw',
    duplicateStrategy: 'abort',
    noBackdrop: true,
  })
}