import { FourLevelNumber } from "../base/@types";

export const difficultyLabels = [
	'Not difficult at all',
	'Somewhat difficult',
	'Very difficult',
	'Extremely difficult',
] as const

export const frequencyLabels = [
	'Not at all',
	'Several days',
	'More than half the days',
	'Nearly every day'
] as const

export const satisfactionLabels = [
	'Poor',
	'Good',
	'Very Good',
	'Excellent',
] as const

export const renderSatisfactionSurveyQuestionScore = (value: FourLevelNumber | null) => value === null ? 'N/A' : satisfactionLabels[value as any];

export const reasonRadioOptions = [
	{ label: 'Forgot', value: 'Forgot' },
	{ label: 'Changed your mind', value: 'Changed your mind' },
	{ label: 'Unsure it was for me', value: 'Unsure it was for me' },
	{ label: 'Would have been late', value: 'Would have been late' },
	{ label: 'Other', value: 'Other' },
]

export const wouldHelpRadioOptions = [
	{ label: 'Yes', value: 'Yes' },
	{ label: 'No', value: 'No' },
	{ label: 'Maybe', value: 'Maybe' },
]

export const additionalReminderRadioOptions = [
	{ label: '24 hours in advance', value: '24 hours in advance' },
	{ label: '12 hours in advance', value: '12 hours in advance' },
	{ label: '4 hours in advance', value: '4 hours in advance' },
]
