import { Observer } from 'mobx-react-lite';
import React from 'react';
import './OptionsPanelSection.scss';

export interface OptionsPanelSectionProps {
  title?: string;
  name: string;
  children: React.ReactElement;
}

const OptionsPanelSection: React.FC<OptionsPanelSectionProps> = props => {
  return <Observer children={() => (
    <section className="OptionsPanelSection">
      <header className="OptionsPanelSectionHeader">
        <h4>{props.title || 'Options'}</h4>
      </header>
      <div className="OptionsPanelSectionBody">
        {props.children}
      </div>
    </section>
  )} />
}

export default OptionsPanelSection;