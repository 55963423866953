import { action, flow, observable, reaction } from "mobx"
import moment from "moment"
import { Nillable } from "../base/@types"
import { CounsellingApplicationEndpoints, DefaultCounsellingApplicationIncludesWithSessionDetails } from "../base/endpoints/counsellingApplication.endpoints"
import { CounsellingAvailabilityEndpoints } from "../base/endpoints/counsellingAvailability.endpoints"
import { reportError } from "../base/utils/errors.utils"
import { uniq } from "../base/utils/ramdaEquivalents.utils"
import { createUTCMoment, minutes } from "../base/utils/time.utils"
import { doEvery } from "../base/utils/waiters.utils"
import { ApplicationTypeOption, CounsellingType } from "../constants/counsellingTypes.descriptors"
import { ModelName } from "../constants/modelNames.enum"
import { CounsellingApplication } from "../models/makeCounsellingApplication.model"
import { CounsellingAvailability } from "../models/makeCounsellingAvailability.model"
import { CounsellingSession } from "../models/makeCounsellingSession.model"
import { User } from "../models/makeUser.model"
import { isOfCounsellingTypeYoungPeople } from "../utils/counsellingApplication.utils"
import { makeControllerBase, makeRootControllerChildInitFn } from "./_root.controller"
import { APIGetManyResponse } from "./api.controller"
import { AuthController } from "./auth.controller"
import { CLOCK } from "./common/clock.controller"
import { LocalDBController } from "./localDB.controller"

/**
 * Manages current user's counselling applications and sessions.
 */
export type CounsellingController = ReturnType<typeof makeCounsellingController>;

export const makeCounsellingController = () => {
	const c = observable({
		...makeControllerBase('COUNSELLING'),
		get AUTH(): AuthController {
			return c.ROOT!.children.AUTH;
		},
		get LOCALDB(): LocalDBController {
			return c.ROOT!.children.LOCALDB;
		},
		get currentUser(): Nillable<User> {
			return c.AUTH.currentUser;
		},
		get applications(): CounsellingApplication[] {
			return c.currentUser?.id ? Array.from(c.LOCALDB.data.counsellingApplications.values()).filter(a => a.applicantId === c.currentUser!.id || a.inviteeId === c.currentUser!.id) : [];
		},
		get pendingApplications(): CounsellingApplication[] {
			return c.applications.filter(a => a.isPending);
		},
		get approvedApplications(): CounsellingApplication[] {
			return c.applications.filter(a => a.isApproved && !a.isCompleted);
		},
		get justCompletedApplications(): CounsellingApplication[] {
			return c.applications.filter(a => a.justCompleted);
		},
		get activeApplications(): CounsellingApplication[] {
			return uniq([
				...c.ongoingApplications,
				...c.pendingApplications,
				...c.justCompletedApplications,
			]).sort((a, b) => {
				const at = a.nextSession?.timeScheduled ?? a.timeCreated;
				const bt = b.nextSession?.timeScheduled ?? b.timeCreated;
				return createUTCMoment(bt).diff(createUTCMoment(at));
			});
		},
		get ongoingApplications(): CounsellingApplication[] {
			return c.applications.filter(a => a.isOngoing);
		},
		get ongoingPaidOneToOneApplications(): CounsellingApplication[] {
			return c.ongoingApplications.filter(a => a.type === CounsellingType.PaidOneToOne);
		},
		get ongoingOneToOneApplications(): CounsellingApplication[] {
			return c.ongoingApplications.filter(a => a.type === CounsellingType.OneToOne);
		},
		get ongoingCouplesApplications(): CounsellingApplication[] {
			return c.ongoingApplications.filter(a => a.type === CounsellingType.Couples);
		},
		get ongoingYPApplications(): CounsellingApplication[] {
			return c.ongoingApplications.filter(a => isOfCounsellingTypeYoungPeople(a.type));
		},
		get pendingPaidOneToOneApplications(): CounsellingApplication[] {
			return c.pendingApplications.filter(a => a.type === CounsellingType.PaidOneToOne);
		},
		get pendingOneToOneApplications(): CounsellingApplication[] {
			return c.pendingApplications.filter(a => a.type === CounsellingType.OneToOne);
		},
		get pendingCouplesApplications(): CounsellingApplication[] {
			return c.pendingApplications.filter(a => a.type === CounsellingType.Couples);
		},
		get pendingYPApplications(): CounsellingApplication[] {
			return c.pendingApplications.filter(a => isOfCounsellingTypeYoungPeople(a.type));
		},
		reset: action(() => {
			c.firstDataLoaded = false;
		}),
		firstDataLoaded: false,
		errorGettingApplications: null as Error | null | unknown,
		getCurrentUserApplications: () => flow(function * () {
			c.errorGettingApplications = null;
			const urlAsApplicant = CounsellingApplicationEndpoints.own.index({
				include: DefaultCounsellingApplicationIncludesWithSessionDetails,
				filter: { whereNull: ['timeArchived'] },
				perPage: Infinity,
			});
			try {
				yield c.ROOT!.children.API.getMany(urlAsApplicant, ModelName.counsellingApplications);
				c.firstDataLoaded = true;
			} catch(e) {
				reportError(e);
				c.errorGettingApplications = e;
			}
		})(),
		get futureSessions(): CounsellingSession[] {
			return c.ongoingApplications.reduce((accu, a) => [...accu, ...a.sortedSessions], [] as CounsellingSession[]).filter(i => i && i.isPending);
		},
		get upcomingSessions(): CounsellingSession[] {
			return c.ongoingApplications.map(a => a.nextSession).filter(i=>i) as CounsellingSession[];
		},
		get completedFreeSessions(): CounsellingSession[] {
			return c.applications.reduce((accu, a) => [...accu, ...a.sortedSessions.filter(sess => sess.isCompleted && !sess.isPaidSession)], [] as CounsellingSession[]).filter(i => i);
		},
		get completedFreeSessionsThisYear(): CounsellingSession[] {
			return c.completedFreeSessions.filter(sess => sess.timeScheduled && moment(sess.timeScheduled).isSameOrAfter(moment().startOf('year')));
		},
		isLoadingCounsellingAvailabilities: false,
		getCounsellingAvailabilities: () => flow(function * () {
			c.isLoadingCounsellingAvailabilities = true;
			const canUseAdminEndpoint = c.AUTH.can.coordinate_.counsellingAvailabilities || c.AUTH.can.provideCounsellingFor_.someUserGroups;
			const url = CounsellingAvailabilityEndpoints[canUseAdminEndpoint ? 'staff' : 'client'].index({
				filter: {
					dateRange: {
						key: 'timeStart',
						startDate: moment(CLOCK.localNowMoment).startOf('week').format('YYYY-MM-DD'),
						endDate: moment(CLOCK.localNowMoment).add(1, 'month').endOf('month').format('YYYY-MM-DD'),
					},
				},
				perPage: Infinity,
			});
			const { entries: availabilities } = (yield c.ROOT!.children.API.getMany(url, ModelName.counsellingAvailabilities)) as APIGetManyResponse<CounsellingAvailability>;
			c.isLoadingCounsellingAvailabilities = false;
			return availabilities.filter(a => !a.applicationId);
		})(),
		get loadedAvailabilities(): CounsellingAvailability[] {
			return Array.from(c.ROOT!.children.LOCALDB.data.counsellingAvailabilities.values());
		},
		get availableSlots(): CounsellingAvailability[] {
			return c.loadedAvailabilities.filter(slot => !slot.applicationId && moment.utc(slot.timeStart).isAfter());
		},
		get currentUserCanApplyForTypes() {
			const result: ApplicationTypeOption[] = [];
			if (!c.AUTH.currentUser) return result;
			if (c.AUTH.currentUser.isOver18) {
				if (c.ongoingPaidOneToOneApplications.length === 0 && c.pendingPaidOneToOneApplications.length === 0) result.push(ApplicationTypeOption.PaidOneToOne);
				if (c.ongoingOneToOneApplications.length === 0 && c.pendingOneToOneApplications.length === 0) result.push(ApplicationTypeOption.OneToOne);
				if (c.ongoingCouplesApplications.length === 0 && c.pendingCouplesApplications.length === 0) result.push(ApplicationTypeOption.Couples);
				if (c.ongoingYPApplications.length === 0 && c.pendingYPApplications.length === 0) result.push(ApplicationTypeOption.YoungPeople);
			}
			return result;
		},
		get availablePaidOneToOneSlots(): CounsellingAvailability[] {
			return c.availableSlots.filter(a => !a.typesAllowed || a.typesAllowed.length === 0 || a.typesAllowed.includes(CounsellingType.PaidOneToOne));
		},
		get availableOneToOneSlots(): CounsellingAvailability[] {
			return c.availableSlots.filter(a => !a.typesAllowed || a.typesAllowed.length === 0 || a.typesAllowed.includes(CounsellingType.OneToOne));
		},
		get availableCouplesSlots(): CounsellingAvailability[] {
			return c.availableSlots.filter(a => !a.typesAllowed || a.typesAllowed.length === 0 || a.typesAllowed.includes(CounsellingType.Couples));
		},
		get availableYP1214Slots(): CounsellingAvailability[] {
			return c.availableSlots.filter(a => !a.typesAllowed || a.typesAllowed.length === 0 || a.typesAllowed.includes(CounsellingType.YoungPeople1214));
		},
		get availableYP1517Slots(): CounsellingAvailability[] {
			return c.availableSlots.filter(a => !a.typesAllowed || a.typesAllowed.length === 0 || a.typesAllowed.includes(CounsellingType.YoungPeople1517));
		},
		get availableYPSlots(): CounsellingAvailability[] {
			return uniq([...c.availableYP1214Slots, ...c.availableYP1517Slots]);
		},
	})
	c.init = makeRootControllerChildInitFn(
		c,
		() => {
			const disposers = [] as Function[];
			reaction(
				() => c.currentUser?.id,
				id => {
					if (id) disposers.push(doEvery(c.getCurrentUserApplications, minutes(5), { fireImmediately: true }));
					else disposers.forEach(fn => fn());
				},
				{ fireImmediately: true }
			);
		}
	);
	return c;
}
