import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import BaseButton from '../../../base/components/BaseButton/BaseButton';
import BaseInput from '../../../base/components/BaseInput/BaseInput';
import BaseSpacer from '../../../base/components/BaseSpacer/BaseSpacer';
import ErrorRenderer from '../../../base/components/ErrorRenderer/ErrorRenderer';
import LinkGroup from '../../../base/components/LinkGroup/LinkGroup';
import WindowTitle from '../../../base/components/WindowTitle/WindowTitle';
import { AuthEndpoints } from '../../../base/endpoints/auth.endpoints';
import { useControllers } from '../../../base/hooks/useRootController.hook';
import { useUpdatePageBackground } from '../../../base/hooks/useSetPageBackground.hook';
import { makeActionConfig } from '../../../base/utils/actionConfig.utils';
import { useStore } from '../../../base/utils/mobx.utils';
import Turn2MeLogo from '../../../components/Turn2MeLogo/Turn2MeLogo';
import AuthForm from '../_components/AuthForm/AuthForm';
import AuthPage from '../_components/AuthPage/AuthPage';
import './PageForgotPassword.scss';

interface PageForgotPasswordGetLinkProps {}

const PageForgotPassword: React.FC<PageForgotPasswordGetLinkProps> = props => {

  const { API, UI, NAVIGATOR } = useControllers();

  const s = useStore(() => ({
    form: {
      username: '',
    },
    get formValid() {
      return Boolean(s.form.username);
    },
    submit: async () => {
      const url = AuthEndpoints.password.getLink();
      try {
        await API.postRaw(url, s.form);
        UI.DIALOG.success({
          heading: 'Reset Password',
          body: <>
            <p>Thank you! If this is your turn2me username, you should be receiving either an email or an SMS with instructions for the next step.</p>
            <p>If you do not receive anything in a few minutes and it's not in your junk mailbox, please get in touch and we will help you out.</p>
          </>,
          actions: [
            makeActionConfig('Log in', () => NAVIGATOR.navigateTo('/auth/login'))
          ]
        })
      } catch(e) {
        UI.DIALOG.error({
          heading: 'Oops! An error occurred and we could not complete your request; please try again later.',
          body: <ErrorRenderer error={(e as any).response} />
        })
      }
      return;
    },
  }));

  useUpdatePageBackground('#0151ca', 'radial-gradient(204.06% 99.27% at 51.45% 117.53%, rgba(55, 218, 255, 0.863) 1.04%, rgba(55, 218, 255, 0) 100%)');

  return <Observer children={() => (

    <AuthPage className="PageForgotPassword">

      <header className="PageForgotPasswordHeader">
        <Turn2MeLogo version="full" />
        <WindowTitle title="Reset Password" />
        <h1>Forgot Password</h1>
      </header>

      <div className="PageForgotPasswordContent">
        <AuthForm>
          <p>Forgot your password? Don't worry, enter your username to reset a password. We will send a link to your associated email or mobile number.</p>
          <BaseSpacer size="0.1em"/>
          <BaseInput form={s.form} field="username" label="Username" onEnter={s.submit} autoCapitalize="off" autoCorrect="off" autoFocus />
          <BaseSpacer size="0.1em"/>
          <p><em>* If you don't remember your username, please get in touch and we will help you out.</em></p>
          <BaseButton className="AuthFormSubmit" onClick={s.submit} size="lg" icon="arrow" name="get-reset-password-link" circle disabled={!s.formValid}/>
        </AuthForm> 
      </div>

      <footer className="PageForgotPasswordFooter">
        <LinkGroup allCaps noUnderscore direction="column">
          <Link to="/auth/login" title="Log in">→ Log in</Link>
          <Link to="/auth/register" title="Create free account">→ Create free account</Link>
        </LinkGroup>
      </footer>

    </AuthPage>

  )} />
  
}

export default PageForgotPassword;