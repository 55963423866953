import {
  Company,
  CompanyRelatedModels
} from "../../models/makeCompany.model"
import { DefaultIndexParamSet } from "../utils/api.utils"
import {
  makeApiEndpointConfig,
  makeApiGetEndpointConfig
} from "../utils/endpoints.utils"
import { makeUrl } from "../utils/url.utils"
import { prefixOwn, prefixStaff } from "./namespacePrefixes"

export type CompanyEndpointParams = DefaultIndexParamSet<
  Company,
  CompanyRelatedModels
> & {
  include?: (keyof CompanyRelatedModels | string)[]
}
const ownUrlBase = prefixOwn("companies")
const staffUrlBase = prefixStaff("companies")

export const CompanyEndpoints = {
  own: {
    index: makeApiEndpointConfig<CompanyEndpointParams>(ownUrlBase),
    get: makeApiGetEndpointConfig<CompanyEndpointParams>(ownUrlBase),
    update: () => ownUrlBase,
  },
  staff: {
    index: makeApiEndpointConfig<CompanyEndpointParams>(staffUrlBase),
    companySelectorIndex: makeApiEndpointConfig<CompanyEndpointParams>(makeUrl(staffUrlBase, 'companySelectorIndex')),
    get: makeApiGetEndpointConfig<CompanyEndpointParams>(staffUrlBase),
    create: () => staffUrlBase,
    update: (id: string) => makeUrl(staffUrlBase, id),
    delete: (id: string) => makeUrl(staffUrlBase, id),
  },
}

export const DefaultCompanyIncludes = ['contactUser', 'employees', 'supportGroups', 'availabilities', 'applications'];