import { flow } from "mobx";
import { ContactEndpoints } from "../base/endpoints/contact.endpoints";
import { getSnapshot } from "../base/utils/snapshot.utils";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { Contact } from "../models/makeContact.model";

export const saveContact = flow(function* (
  c: Partial<Contact>,
  API: APIController,
  options?: {
    isStaff: boolean, // make it false, it seems there is no /staff/contact/{id} endpoint.
  }
) {
  const urlType = options?.isStaff ? 'staff' : 'own';
  const endpoint = c.id ? ContactEndpoints[urlType].update : ContactEndpoints[urlType].create;
  const url = endpoint(c.id!);
  const savedContact = c.id ? (
    yield API.patch(url, ModelName.contacts, getSnapshot(c))
  ) : (
    yield API.post(url, ModelName.contacts, getSnapshot(c))
  );
  return savedContact;
})