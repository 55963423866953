import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseLabel from '../../base/components/BaseLabel/BaseLabel';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import './PaymentCardForm.scss';

interface PaymentCardFormProps {
  onChange?: (e?: StripeCardElementChangeEvent) => unknown,
  hideLabel?: boolean,
}

const cardElementOptions = {
  style: {
    base: {
      fontSize: '16px',
      fontFamily: '"DM Sans", sans-serif',
      color: '#424770',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
}


const PaymentCardForm: React.FC<PaymentCardFormProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    lastEvent: undefined as StripeCardElementChangeEvent | undefined,
    get error() {
      return s.lastEvent?.error;
    },
    get empty() {
      return s.lastEvent?.empty ?? true;
    },
    get complete() {
      return s.lastEvent?.complete;
    },
  }));

  const handleChange = action((e?: StripeCardElementChangeEvent) => {
    s.lastEvent = e;
    p.onChange && p.onChange(e);
  })

  return <Observer children={() => (
    <div className={
      joinClassName(
        'PaymentCardForm',
        s.empty && 'isEmpty',
        s.complete && 'isComplete',
        s.error && 'hasError',
      )
    }>
      {!p.hideLabel && <BaseLabel>Enter Card Details:</BaseLabel>}
      <CardElement options={cardElementOptions} onChange={handleChange} />
    </div>
  )} />
}

export default PaymentCardForm;