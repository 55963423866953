import {
  ContactForm,
  ContactFormRelatedModels,
} from "../../models/makeContactForm.model"
import { DefaultIndexParamSet } from "../utils/api.utils"
import {
  makeApiEndpointConfig,
  makeApiGetEndpointConfig,
} from "../utils/endpoints.utils"
import { makeUrl } from "../utils/url.utils"
import { prefixOwn, prefixStaff } from "./namespacePrefixes"

export type ContactFormEndpointParams = DefaultIndexParamSet<
  ContactForm,
  ContactFormRelatedModels
> & {
  include?: (keyof ContactFormRelatedModels | string)[]
}
const ownUrlBase = prefixOwn("contact-forms")
const staffUrlBase = prefixStaff("contact-forms")

export const ContactFormEndpoints = {
  own: {
    create: () => ownUrlBase,
  },
  staff: {
    index: makeApiEndpointConfig<ContactFormEndpointParams>(staffUrlBase),
    get: makeApiGetEndpointConfig<ContactFormEndpointParams>(staffUrlBase),
    create: () => staffUrlBase,
    update: (id: string) => makeUrl(staffUrlBase, id),
    delete: (id: string) => makeUrl(staffUrlBase, id),
  },
}
