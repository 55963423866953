import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useProps, useStore } from '../../../../../base/utils/mobx.utils';
import { mean } from '../../../../../base/utils/ramdaEquivalents.utils';
import { autoPluralize, __are } from '../../../../../base/utils/string.utils';
import { calculateSatisfactionSurveyScoreExcludeOptional, SurveySatisfactionSnapshot } from '../../../../../models/makeSurveySatisfaction.model';
import StatDataItem from '../StatDataItem/StatDataItem';
import StatDataItemGrid from '../StatDataItemGrid/StatDataItemGrid';
import StatSection from '../StatSection/StatSection';
// import './StatSectionSatisfactionSurveys.scss';

interface StatSectionSatisfactionSurveysProps {
  satisfactionSurveys: SurveySatisfactionSnapshot[],
  showContentByDefault?: boolean,
}

const StatSectionSatisfactionSurveys: React.FC<StatSectionSatisfactionSurveysProps> = props => {

  const p = useProps(props);
  const s = useStore(() => ({

    get validSurveys() {
      return p.satisfactionSurveys.filter(s => s.ratingOfCounsellingExperience !== null)
    },

    getRatingAverage: (key: keyof SurveySatisfactionSnapshot) => {
      const value = mean(s.validSurveys
        .filter(survey => survey[key] !== null)
        .map(survey => +(survey[key] || 0) as number))
      if (isNaN(value)) return '-';
      return value.toFixed(2);
    },

    get avgExperienceRating() {
      return s.getRatingAverage('ratingOfCounsellingExperience');
    },
    get avgProgressRating() {
      return s.getRatingAverage('ratingSelfProgress');
    },
    get avgRelationshipWithCounsellorRating() {
      return s.getRatingAverage('ratingOfCounsellorRelationship');
    },
    get avgAppUXRating() {
      return s.getRatingAverage('ratingUx');
    },
    get avgOnlineVSOfflineRating() {
      return s.getRatingAverage("ratingOfOnlineVsOfflineCounselling");
    },
    get avgTotalRating() {
      const value = mean(s.validSurveys
        .map(s => calculateSatisfactionSurveyScoreExcludeOptional(s)))
      if (isNaN(value)) return '-';
      return value.toFixed(2);
    }
  }));

  return <Observer children={() => (
    <StatSection className="StatSectionSatisfactionSurveys"
      title="Counselling Satisfaction Surveys"
      subtitle={`Average ratings to each question and the total. There ${__are(s.validSurveys.length)} ${autoPluralize(s.validSurveys.length, 'survey')} with ratings in the selected timeframe.`}
      showContentByDefault={p.showContentByDefault}
    >
      <StatDataItemGrid>
        <StatDataItem
          title="Avg. Counselling Experience Rating"
          value={s.avgExperienceRating}
          secondValue="3"
          description='"How was your experience of Online Counselling with turn2me?"'
        />
        <StatDataItem
          title="Avg. Self Progress Rating"
          value={s.avgProgressRating}
          secondValue="3"
          description='"How would you rate your progress in counselling? (if applicable)"'
        />
        <StatDataItem
          title="Avg. Relationship with Counsellor Rating"
          value={s.avgRelationshipWithCounsellorRating}
          secondValue="3"
          description='"How would you describe your relationship with your Counsellor?"'
        />
        <StatDataItem
          title="Avg. Website UX Rating"
          value={s.avgAppUXRating}
          secondValue="3"
          description='"How was your experience of using our website?"'
        />
        <StatDataItem
          title="Avg. Online v.s. Offline Counselling Experience Comparison Rating (optional)"
          value={s.avgOnlineVSOfflineRating}
          secondValue="3"
          description='"How would you rate your experience of Online Counselling with your experience of Face-to-Face Counselling?"'
        />
        <StatDataItem
          title="Avg. Total Rating"
          value={s.avgTotalRating}
          secondValue="3"
          description="An average of the first four questions. The last question is optional and is not considered part of the total score."
        />
      </StatDataItemGrid>
    </StatSection>
  )} />

}

export default StatSectionSatisfactionSurveys;