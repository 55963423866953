import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseSpacer from '../../../base/components/BaseSpacer/BaseSpacer';
import NavBarHeightSpacer from '../../../base/components/NavBarHeightSpacer/NavBarHeightSpacer';
import ShadedBlock from '../../../base/components/ShadedBlock/ShadedBlock';
import UIBlock from '../../../base/components/UIBlock/UIBlock';
import { useOnMount } from '../../../base/hooks/lifecycle.hooks';
import { useOnRouteFocus } from '../../../base/hooks/navigator.hooks';
import { useControllers } from '../../../base/hooks/useRootController.hook';
import { useStore } from '../../../base/utils/mobx.utils';
import BlogPostCard from '../../../components/BlogPostCard/BlogPostCard';
import GuideVideoCard from '../../../components/GuideVideoCard/GuideVideoCard';
import SupportGroupSummaryCard from '../../../components/SupportGroupSummaryCard/SupportGroupSummaryCard';
import ThoughtCatcherDisabledNoticeCard from '../../../components/ThoughtCatcherDisabledNoticeCard/ThoughtCatcherDisabledNoticeCard';
import ThoughtFeed, { ThoughtFeedInsert } from '../../../components/ThoughtFeed/ThoughtFeed';
import { Priority } from '../../../constants/priority.enum';
import ClientAppSubmodule from '../_components/ClientAppSubmodule/ClientAppSubmodule';
import { ExploreModuleRouteDef } from './Explore.module.routes';
import './ModuleExplore.scss';
import DateAndWeatherGreeting from './_components/DateAndWeatherGreeting/DateAndWeatherGreeting';
import ExploreGroupRecommendations from './_components/ExploreGroupRecommendations/ExploreGroupRecommendations';
import ExploreServices from './_components/ExploreServices/ExploreServices';
import ExploreThoughtCatcher from './_components/ExploreThoughtCatcher/ExploreThoughtCatcher';
import VerifyEmailCard from './_components/VerifyEmailCard/VerifyEmailCard';
import VerifyMobileNumberCard from './_components/VerifyMobileNumberCard/VerifyMobileNumberCard';

interface ModuleExploreProps { }

const ModuleExplore: React.FC<
  ModuleExploreProps
> = props => {

  const { CONFIGURATIONS, ANALYTICS, UI, AUTH, SUPPORT_GROUPS, CONTENT, THOUGHT_CATCHER } = useControllers();

  const s = useStore(() => ({
    countryCode: "",
    get groupRecommendationsSection() {
      return <ExploreGroupRecommendations />
    },
    get individualGroupRecommendationsCards() {
      return SUPPORT_GROUPS.recommendations.map(g => ({
        id: `SupportGroupSummaryCard_${g.id}`,
        el: <SupportGroupSummaryCard supportGroup={g} key={`group-${g.id}`} />,
      }))
    },
    get canShowFeed() {
      return Boolean(AUTH.currentUser?.dateOfBirth || AUTH.currentUser?.isOver18);
    },
    get shouldShowEmailVerificationCard() {
      return AUTH.currentUser?.email && !AUTH.currentUser.hasVerifiedEmail;
    },
    get shouldShowPhoneVerificationCard() {
      return AUTH.currentUser?.mobileNumber && !AUTH.currentUser.hasVerifiedMobileNumber;
    },
    get shouldShowThoughtCatcherYoungPersonNotice() {
      return AUTH.currentUser?.age && AUTH.currentUser.isYoungPerson;
    },
    get shouldShowThoughtCatcherServiceInCountryNotice() {
      // checks if detected user location is in service countries.
      return !!s.countryCode && !CONFIGURATIONS.configurations.SERVICE_OPEN_IN_COUNTRY_CODES.includes(s.countryCode)
    },
    get posts() {
      return CONTENT.posts;
    },
    get postsRendered() {
      return s.posts.map(p => ({
        el: <BlogPostCard post={p} key={p.id} />,
        id: `WPPostCard_${p.id}`
      }))
    },
    get shouldShowGettingStartedVideo() {
      return !AUTH.currentUser?.preferences?.hasViewedGettingStartedVideo
    },
    get thoughtCatcherDisabledNoticeCard() {
      return THOUGHT_CATCHER.serviceStatusForCurrentUser === 'on' ? undefined : { el: <ThoughtCatcherDisabledNoticeCard isNotInServicedCountries={s.shouldShowThoughtCatcherServiceInCountryNotice} isYoungPerson={s.shouldShowThoughtCatcherYoungPersonNotice} />, id: 'ThoughtCatcherDisabledNoticeCard', priority: Priority.high };
    },
    get feedInserts() {
      return [
        s.thoughtCatcherDisabledNoticeCard,
        ...s.shouldShowPhoneVerificationCard ? [{ id: 'VerifyMobileNumberCard', el: <VerifyMobileNumberCard />, priority: Priority.high }] : [],
        ...s.shouldShowEmailVerificationCard ? [{ id: 'VerifyEmailCard', el: <VerifyEmailCard />, priority: Priority.high }] : [],
        // ...s.wpWelcomePostRendered,
        ...s.postsRendered,
        ...AUTH.currentUser?.isAdult ? [{ id: 'ExploreServices', el: <ExploreServices /> }] : [],
        ...UI.uptoTabletLg ? s.individualGroupRecommendationsCards : [],
      ].filter(i => i) as ThoughtFeedInsert[];
    },
  }))

  useOnMount(() => {
    if (!s.countryCode) {
      ANALYTICS.getUserCountryCode().then(countryId => {
        s.countryCode = countryId ?? "";
      })
    }
  })

  useOnRouteFocus(
    ExploreModuleRouteDef,
    () => {
      SUPPORT_GROUPS.getSupportGroupsForComingWeek();
    },
  )

  return <Observer children={() => (
    <ClientAppSubmodule name="Explore" stringTitle="Explore">

      {
        UI.fromTablet && <header className="ModuleExploreHeader">
          <h1><span>Explore <strong>turn2me</strong></span></h1>
        </header>
      }

      <div className="ModuleExploreContent">

        <section className="ModuleExploreMainColumn">

          {UI.onlyPhones && <DateAndWeatherGreeting isFirstTimer={s.shouldShowGettingStartedVideo} />}

          {s.shouldShowGettingStartedVideo && <GuideVideoCard />}

          {THOUGHT_CATCHER.serviceStatusForCurrentUser === 'on' && <ExploreThoughtCatcher />}

          {
            AUTH.isModerator && <UIBlock>
              <ShadedBlock spaceChildren colorIntensity="strong">
                <h3>You have logged in with a turn2me moderator account.</h3>
                <p>You will be able to see private and hidden thoughts from all clients.</p>
              </ShadedBlock>
              <BaseSpacer />
            </UIBlock>
          }

          {s.canShowFeed && <ThoughtFeed inserts={s.feedInserts} insertAfterEveryNthItem={UI.onlyPhones ? 4 : 5} />}

          <BaseSpacer />

          <NavBarHeightSpacer />

        </section>

        {UI.fromTabletLg && <aside className="ModuleExploreAside">
          <h2>Upcoming support groups</h2>
          <p>Free to join, no waiting lists!</p>
          <BaseSpacer />
          {s.groupRecommendationsSection}
        </aside>}

      </div>

    </ClientAppSubmodule>
  )} />

}

export default ModuleExplore
