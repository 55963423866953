import { ModelName } from "../../constants/modelNames.enum";
import { makeAddress } from "../../models/makeAddress.model";
import { makeAlert } from "../../models/makeAlert";
import { makeAssignment } from "../../models/makeAssignment.model";
import { makeChatMessage } from "../../models/makeChatMessage.model";
import { makeChatParticipant } from "../../models/makeChatParticipant.model";
import { makeChatThread } from "../../models/makeChatThread.model";
import { makeComment } from "../../models/makeComment.model";
import { makeCompany } from "../../models/makeCompany.model";
import { makeConfiguration } from "../../models/makeConfiguration.model";
import { makeContact } from "../../models/makeContact.model";
import { makeContactForm } from "../../models/makeContactForm.model";
import { makeCounsellingApplication } from "../../models/makeCounsellingApplication.model";
import { makeCounsellingAvailability } from "../../models/makeCounsellingAvailability.model";
import { makeCounsellingSession } from "../../models/makeCounsellingSession.model";
import { makeFee } from "../../models/makeFee.model";
import { makeFeedback } from "../../models/makeFeedback.model";
import { makeFlag } from "../../models/makeFlag.model";
import { makeInvitation } from "../../models/makeInvitation.model";
import { makeInvoice } from "../../models/makeInvoice.model";
import { makeInvoiceItem } from "../../models/makeInvoiceItem.models";
import { makeMediaItem } from "../../models/makeMediaItem.model";
import { makeModeratedTerm } from "../../models/makeModeratedTerm.model";
import { makePayment } from "../../models/makePayment.model";
import { makeReaction } from "../../models/makeReaction.model";
import { makeSubscription } from "../../models/makeSubscription.model";
import { makeSupportGroup } from "../../models/makeSupportGroup.model";
import { makeSupportGroupReservation } from "../../models/makeSupportGroupReservation.model";
import { makeSupportGroupTopic } from "../../models/makeSupportGroupTopic.model";
import { makeSupportTicket } from "../../models/makeSupportTicket.model";
import { makeSurveyGAD7 } from "../../models/makeSurveyGAD7.model";
import { makeSurveyGeneral } from "../../models/makeSurveyGeneral.model";
import { makeSurveyGoalSheet } from "../../models/makeSurveyGoalSheet.model";
import { makeSurveyPHQ9 } from "../../models/makeSurveyPHQ9.model";
import { makeSurveySatisfaction } from "../../models/makeSurveySatisfaction.model";
import { makeSurveySupportGroupNonAttendance } from "../../models/makeSurveySupportGroupNonAttendance";
import { makeSurveySupportGroupSatisfaction } from "../../models/makeSurveySupportGroupSatisfaction";
import { makeThought } from "../../models/makeThought.model";
import { makeUser } from "../../models/makeUser.model";

export const ModelFactoryMap = {
	[ModelName.addresses]: makeAddress,
	[ModelName.alerts]: makeAlert,
	[ModelName.assignments]: makeAssignment,
	[ModelName.companies]: makeCompany,
	[ModelName.chatMessages]: makeChatMessage,
	[ModelName.chatParticipants]: makeChatParticipant,
	[ModelName.chatThreads]: makeChatThread,
	[ModelName.comments]: makeComment,
	[ModelName.configurations]: makeConfiguration,
	[ModelName.counsellingApplications]: makeCounsellingApplication,
	[ModelName.counsellingAvailabilities]: makeCounsellingAvailability,
	[ModelName.counsellingSessions]: makeCounsellingSession,
	[ModelName.contacts]: makeContact,
	[ModelName.contactForms]: makeContactForm,
	[ModelName.fees]: makeFee,
	[ModelName.feedback]: makeFeedback,
	[ModelName.flags]: makeFlag,
	[ModelName.invitations]: makeInvitation,
	[ModelName.invoiceItems]: makeInvoiceItem,
	[ModelName.invoices]: makeInvoice,
	[ModelName.mediaItems]: makeMediaItem,
	[ModelName.moderatedTerms]: makeModeratedTerm,
	[ModelName.payments]: makePayment,
	[ModelName.reactions]: makeReaction,
	[ModelName.subscriptions]: makeSubscription,
	[ModelName.supportGroupReservations]: makeSupportGroupReservation,
	[ModelName.supportGroups]: makeSupportGroup,
	[ModelName.supportGroupTopics]: makeSupportGroupTopic,
	[ModelName.supportTickets]: makeSupportTicket,
	[ModelName.surveysGAD7]: makeSurveyGAD7,
	[ModelName.surveysGeneral]: makeSurveyGeneral,
	[ModelName.surveysGoalSheet]: makeSurveyGoalSheet,
	[ModelName.surveysPHQ9]: makeSurveyPHQ9,
	[ModelName.surveysSupportGroupNonAttendance]: makeSurveySupportGroupNonAttendance,
	[ModelName.surveysSupportGroupSatisfaction]: makeSurveySupportGroupSatisfaction,
	[ModelName.surveysSatisfaction]: makeSurveySatisfaction,
	[ModelName.thoughts]: makeThought,
	[ModelName.users]: makeUser,
}