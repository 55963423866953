import { Observer } from "mobx-react-lite";
import React from "react";
import { staffOpenOverlayAddressEditor } from '../../actions/staffOpenOverlayAddressEditor';
import { Nillable } from "../../base/@types";
import BaseButton from "../../base/components/BaseButton/BaseButton";
import BaseGrid from "../../base/components/BaseGrid/BaseGrid";
import BaseInput from "../../base/components/BaseInput/BaseInput";
import BaseSpacer from "../../base/components/BaseSpacer/BaseSpacer";
import BooleanRenderer from "../../base/components/BooleanRenderer/BooleanRenderer";
import CountryRenderer from "../../base/components/CountryRenderer/CountryRenderer";
import CurrencyRenderer from "../../base/components/CurrencyRenderer/CurrencyRenderer";
import DatePicker from "../../base/components/DatePicker/DatePicker";
import DateRenderer from "../../base/components/DateRenderer/DateRenderer";
import EmailLinkRenderer from "../../base/components/EmailLinkRenderer/EmailLinkRenderer";
import InfoTable from "../../base/components/InfoTable/InfoTable";
import TelInput from "../../base/components/TelInput/TelInput";
import TelLinkRenderer from "../../base/components/TelLinkRenderer/TelLinkRenderer";
import { useControllers } from "../../base/hooks/useRootController.hook";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { isNil } from "../../base/utils/ramdaEquivalents.utils";
import { equalByString } from "../../base/utils/string.utils";
import { ApiModelName } from "../../constants/ApiModels.enum";
import { renderSatisfactionSurveyQuestionScore } from "../../constants/survey.constants";
import { AddressSnapshot } from "../../models/makeAddress.model";
import { AssignmentSatisfaction } from "../../models/makeAssignment.model";
import { ContactSnapshot } from "../../models/makeContact.model";
import { CounsellingApplication, CounsellingApplicationSnapshot } from "../../models/makeCounsellingApplication.model";
import { CounsellingAvailability } from "../../models/makeCounsellingAvailability.model";
import { InvitationSnapshot } from "../../models/makeInvitation.model";
import { SurveyGoalSheet } from "../../models/makeSurveyGoalSheet.model";
import { UserSnapshot } from "../../models/makeUser.model";
import { getAgeFromDateOfBirth } from "../../utils/ageAndDateOfBirth.utils";
import { isFreeCounselling, isOfCounsellingTypeYoungPeople } from "../../utils/counsellingApplication.utils";
import AddressRenderer from "../AddressRenderer/AddressRenderer";
import ColorTagCounsellingType from "../ColorTagCounsellingType/ColorTagCounsellingType";
import CommunicationTypeRenderer from "../CommunicationTypeRenderer/CommunicationTypeRenderer";
import CommunicationTypeSelector from "../CommunicationTypeSelector/CommunicationTypeSelector";
import CounsellorSelector from "../CounsellorSelector/CounsellorSelector";
import DateOfBirthRenderer from "../DateOfBirthRenderer/DateOfBirthRenderer";
import GenderRenderer from "../GenderRenderer/GenderRenderer";
import SexualityRenderer from "../SexualityRenderer/SexualityRenderer";
import UserFullNameRenderer from "../UserFullNameRenderer/UserFullNameRenderer";
import UsernameRenderer from "../UsernameRenderer/UsernameRenderer";
import './CounsellingApplicationInfoTable.scss';

interface CounsellingApplicationInfoTableProps {
  application?: CounsellingApplication,
  applicationSnapshot: CounsellingApplicationSnapshot,
  applicantSnapshot?: UserSnapshot,
  doNotShowApplicantInfo?: boolean,
  applicantAddressSnapshot?: Nillable<AddressSnapshot>,
  applicantEmergencyContactSnapshot?: Nillable<ContactSnapshot>,
  availability?: Nillable<CounsellingAvailability>,
  invitationSnapshot?: InvitationSnapshot,
  inviteeSnapshot?: UserSnapshot,
  editable?: boolean,
}

const CounsellingApplicationInfoTable: React.FC<CounsellingApplicationInfoTableProps> = props => {

  const { STAFF, AUTH, UI } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    get applicationSnapshot() { return p.applicationSnapshot },
    get applicantSnapshot() { return p.applicantSnapshot },
    get emergencyContactSnapshot() { return p.applicantEmergencyContactSnapshot },
    get invitationSnapshot() { return p.invitationSnapshot },
    get inviteeSnapshot() { return p.inviteeSnapshot },
    get addressSnapshot() { return p.applicantAddressSnapshot },
    get hasSubmitted() {
      return Boolean(p.applicationSnapshot.id)
    },
    get goalSheetAssignments() {
      return (props.application?.assignments || []).filter(a => a.targetType === ApiModelName.SURVEY_GOAL_SHEET);
    },
    get goalSheets() {
      return s.goalSheetAssignments.filter(a => !!a.target).map(a => a.target as SurveyGoalSheet);
    },
    get satisfactionAssignments() {
      return (props.application?.assignments || []).filter(a => a.targetType === ApiModelName.SURVEY_SATISFACTION && !!a.target) as AssignmentSatisfaction[];
    },
    get type() {
      return p.applicationSnapshot.type;
    },

    get applicantAge() {
      return getAgeFromDateOfBirth(s.applicantSnapshot?.dateOfBirth);
    },

    get canViewCounsellor() {
      return true
    },
    get canEditCounsellor() {
      return AUTH.can.coordinate_.counsellingApplications && p.application?.id && p.editable;
    },
    get canEditAddress() {
      return (STAFF.currentUserIsCounsellor || AUTH.can.coordinate_.counsellingApplications) && p.application?.id && p.application?.address;
    },

    get currentUserIsApplicant() {
      return Boolean(AUTH.currentUser?.id && equalByString(AUTH.currentUser?.id, s.applicantSnapshot?.id || s.applicationSnapshot.applicantId));
    },
    get currentUserIsInvitee() {
      return Boolean(AUTH.currentUser?.id && equalByString(AUTH.currentUser?.id, s.inviteeSnapshot?.id || s.applicationSnapshot.invitationId));
    },
    get canViewSensitiveInfo() {
      return true;
    }
  }));

  const editApplicationAddress = () => {
    if (s.canEditAddress) {
      staffOpenOverlayAddressEditor(UI, p.application!.address!);
    }
  }

  return <Observer children={() => {

    const { invitationSnapshot: invitation, inviteeSnapshot: invitee } = p;

    const {
      firstName, lastName, dateOfBirth, gender, sexuality,
      email, mobileNumber
    } = s.applicantSnapshot || {};

    const {
      type, communicationTypePreference, timePreferredForFirstSession,
      counsellorId,
      healthcareProviderName, healthcareProviderPhone,
      hasExternalCounselling, previousCounsellingExperience, previousDiagnosis, medication,
      clientAgreedDonationAmountPerSession,
      hasPrivateHealthCare,
      isInReceiptOfMedicalCard,
      ownSymptomsOrFeelingsMeasurement,
      hasAttemptedSuicide,
      isAtRisk,
    } = p.applicationSnapshot;

    const isForYP = isOfCounsellingTypeYoungPeople(type);

    const _timePreferredForFirstSession = timePreferredForFirstSession || p.availability?.timeStart;

    const { editable } = p;

    return <div className="CounsellingApplicationInfoTable">

      <InfoTable>

        <thead><tr><th colSpan={2}>Application</th></tr></thead>

        <tbody>

          <tr><th>Application Type</th><td><strong><ColorTagCounsellingType type={type} /></strong></td></tr>

          <tr><th>Preferred means of communication</th><td>{
            editable ? (
              <CommunicationTypeSelector application={p.applicationSnapshot} />
            ) :
            <CommunicationTypeRenderer value={communicationTypePreference} />}
          </td></tr>
          <tr><th>Preferred session time</th><td>
            <DateRenderer value={_timePreferredForFirstSession} markIfToday={true} markIfTomorrow={true} />
          </td></tr>
          {
            (!isNil(clientAgreedDonationAmountPerSession) && isFreeCounselling(p.applicationSnapshot.type)) ? (
              <tr>
                <th>Client-agreed donation amount per session {editable ? '(€)' : ''}</th>
                <td>{editable ? <BaseInput form={p.applicationSnapshot} field="clientAgreedDonationAmountPerSession" type="number" min={1} step={0.01} /> : <CurrencyRenderer value={clientAgreedDonationAmountPerSession!} />}</td>
              </tr>
            ) : undefined
          }
          {
            s.canViewCounsellor ? <tr>
              <th>Counsellor</th>
              <td>
                {
                  editable && AUTH.can.coordinate_.counsellingApplications && p.application ? <CounsellorSelector form={p.application} field="counsellorId" label="" disabled={!s.canEditCounsellor} />
                    : <UserFullNameRenderer userId={counsellorId} />
                }
              </td>
            </tr> : undefined
          }
        </tbody>

        {
          s.applicantSnapshot && !p.doNotShowApplicantInfo && <>
            <thead><tr><th colSpan={2}>Applicant Information</th></tr></thead>
            <tbody>
              <tr><th>Username</th><td><UsernameRenderer user={s.applicantSnapshot} /></td></tr>
              <tr><th>Name</th><td>{
                editable ? <BaseGrid columns="2">
                  <BaseInput label="First Name" form={s.applicantSnapshot} field="firstName" />
                  <BaseInput label="Last Name" form={s.applicantSnapshot} field="lastName" />
                </BaseGrid> : (
                    <strong>{firstName} {lastName}</strong>
                  )}</td></tr>
              <tr><th>Date of Birth</th><td>
                {editable ? <>
                  <DatePicker form={s.applicantSnapshot} field="dateOfBirth" shouldUseSystemUIOnMobile />
                  <BaseSpacer size=".25em" />
                  <p>(Age: {getAgeFromDateOfBirth(s.applicantSnapshot.dateOfBirth)})</p>
                </>
                  : <DateOfBirthRenderer value={dateOfBirth} />}
              </td></tr>
              <tr><th>Country</th><td>
                <CountryRenderer countryId={s.applicantSnapshot.countryProvidedId} defaultValue="Unknown Country" />
              </td></tr>
              {s.canViewSensitiveInfo && gender ? <tr><th>Gender</th><td><GenderRenderer value={gender} /></td></tr> : null}
              {s.canViewSensitiveInfo && sexuality ? <tr><th>Sexuality</th><td><SexualityRenderer value={sexuality} /></td></tr> : null}
            </tbody>
          </>
        }

        <thead><tr><th colSpan={2}>Contact Info</th></tr></thead>

        {
          s.applicantSnapshot && <tbody>
            <tr><th>Email</th><td>
              {editable ? <BaseInput form={s.applicantSnapshot} field="email" type="email" /> : <TelLinkRenderer value={email} />}
            </td></tr>
            <tr><th>Phone</th><td>
              {editable ? <TelInput form={s.applicantSnapshot} field="mobileNumber" /> : <TelLinkRenderer value={mobileNumber} />}
            </td></tr>
            {
              (s.canViewSensitiveInfo && s.addressSnapshot) ? (
                <tr><th>Address</th><td className='ApplicantContactAddress'>
                  <AddressRenderer address={s.addressSnapshot} withLineBreaks/>
                  {editable && s.canEditAddress && <BaseButton onClick={editApplicationAddress} size="xs" className="subtle">Edit</BaseButton>}
                </td></tr>
              ) : undefined
            }
            {
              (s.canViewSensitiveInfo && s.emergencyContactSnapshot) ? <>
                <tr><th>Emergency Contact Name</th><td>{
                  editable ? <BaseInput form={s.emergencyContactSnapshot} field="name" /> : s.emergencyContactSnapshot.name ?? <em>–</em>
                }</td></tr>
                <tr><th>Emergency Contact Number</th><td>{
                  editable ? <BaseInput form={s.emergencyContactSnapshot} field="phone" /> : <TelLinkRenderer value={s.emergencyContactSnapshot.phone} />
                }</td></tr>
                <tr><th>Emergency Contact Email</th><td>{
                  editable ? <BaseInput form={s.emergencyContactSnapshot} field="email" /> : s.emergencyContactSnapshot.email ?? <em>–</em>
                }</td></tr>
              </> : undefined
            }
          </tbody>
        }

        {
          s.canViewSensitiveInfo && <>
            <thead><tr><th colSpan={2}>Counselling History {isForYP ? '(The Child)' : ''}</th></tr></thead>
            <tbody>
              <tr><th>Currently receiving counselling or psychotherapy elsewhere</th><td><BooleanRenderer value={hasExternalCounselling} /></td></tr>
              <tr><th>Previous mental health diagnosis</th><td>{
                editable ? <BaseInput type="textarea" rows="2" form={p.applicationSnapshot} field="previousDiagnosis" /> : (previousDiagnosis ?? <em>–</em>)
              }</td></tr>
              <tr><th>Previous counselling or psychotherapy experience</th><td>{
                editable ? <BaseInput type="textarea" rows="2" form={p.applicationSnapshot} field="previousCounsellingExperience" /> : (previousCounsellingExperience ?? <em>–</em>)
              }</td></tr>

              <tr><th>Has private healthcare</th><td><BooleanRenderer value={hasPrivateHealthCare} /></td></tr>
              <tr><th>Is in receipt of a medical card</th><td><BooleanRenderer value={isInReceiptOfMedicalCard} /></td></tr>

              <tr><th>Medications</th><td>{
                editable ? <BaseInput type="textarea" rows="2" form={p.applicationSnapshot} field="medication" /> : (medication ?? <em>–</em>)
              }</td></tr>
              <tr><th>Healthcare Provider</th><td>{
                editable ? <BaseInput type="textarea" rows="2" form={p.applicationSnapshot} field="healthcareProviderName" /> : (healthcareProviderName ?? <em>–</em>)
              }</td></tr>
              <tr><th>Healthcare Provider Phone Number</th><td>{
                editable ? <BaseInput type="textarea" rows="2" form={p.applicationSnapshot} field="healthcareProviderPhone" /> : (healthcareProviderPhone ?? <em>–</em>)
              }</td></tr>

              <tr><th>Own Symptoms/Feelings Measurement</th><td><BooleanRenderer value={ownSymptomsOrFeelingsMeasurement} /></td></tr>
              <tr><th>Has attempted suicide before</th><td><BooleanRenderer value={hasAttemptedSuicide} /></td></tr>

              <tr><th>Considers themself at risk of self-harm</th><td><BooleanRenderer value={isAtRisk} /></td></tr>

            </tbody>
          </>
        }

        { type === 'couples' && (
          s.inviteeSnapshot ? <>
            <thead><tr><th colSpan={2}>Invited Partner Information</th></tr></thead>
            <tbody>
              <tr><th>Name</th><td><strong><UsernameRenderer user={s.inviteeSnapshot} /></strong></td></tr>
              <tr><th>Email</th><td>{invitee?.email ? <EmailLinkRenderer value={invitee.email} /> : 'To be provided'}</td></tr>
              {invitee?.gender ? <tr><th>Gender</th><td><GenderRenderer value={invitee?.gender} /></td></tr> : null}
            </tbody>
          </> : (s.invitationSnapshot && <>
            <thead><tr><th colSpan={2}>Invited Partner Information</th></tr></thead>
            <tbody>
              <tr><th>Name</th><td><strong>{invitation?.name || 'To be provided'}</strong></td></tr>
              <tr><th>Email</th><td>{invitation?.email ? <EmailLinkRenderer value={invitation.email} /> : 'To be provided'}</td></tr>
              {invitation?.gender ? <tr><th>Gender</th><td><GenderRenderer value={invitation?.gender} /></td></tr> : null}
            </tbody>
          </>)
        ) }

        {isOfCounsellingTypeYoungPeople(type) && (
          s.inviteeSnapshot ? <>
            <thead><tr><th colSpan={2}>Child Information</th></tr></thead>
            <tbody>
              <tr><th>Name</th><td><strong><UsernameRenderer user={s.inviteeSnapshot} /></strong></td></tr>
              <tr><th>Email</th><td>{invitee?.email ? <EmailLinkRenderer value={invitee.email} /> : 'To be provided'}</td></tr>
              <tr><th>Date of Birth</th><td><DateOfBirthRenderer showAge value={invitee?.dateOfBirth} /></td></tr>
              {invitee?.gender && <tr><th>Gender</th><td><GenderRenderer value={invitee?.gender} /></td></tr>}
              {invitee?.sexuality && <tr><th>Sexuality</th><td><SexualityRenderer value={invitee?.sexuality} /></td></tr>}
            </tbody>
          </> : (s.invitationSnapshot && <>
            <thead><tr><th colSpan={2}>Child Information</th></tr></thead>
            <tbody>
              <tr><th>Name</th><td><strong>{s.invitationSnapshot.name}</strong></td></tr>
              <tr><th>Email</th><td>{invitation?.email ? <EmailLinkRenderer value={invitation.email} /> : 'To be provided'}</td></tr>
              <tr><th>Date of Birth</th><td><DateOfBirthRenderer showAge value={invitation?.dateOfBirth} /></td></tr>
              {invitation?.gender && <tr><th>Gender</th><td><GenderRenderer value={invitation?.gender} /></td></tr>}
              {invitation?.sexuality && <tr><th>Sexuality</th><td><SexualityRenderer value={invitation?.sexuality} /></td></tr>}
            </tbody>
          </>)
        ) }

      {
        (AUTH.isStaff || AUTH.isCounsellor) && s.goalSheets.map((gs, i) => <React.Fragment key={gs.assignmentId}>
          <thead><tr><th colSpan={2}>Goal Sheet completed by <UsernameRenderer userId={gs.completedByUserId} /></th></tr></thead>
          <tbody>
            <tr><th>Goal 1</th><td data-cy="goalOne">{gs.goalOne}</td></tr>
            <tr><th>Goal 2</th><td data-cy="goalTwo">{gs.goalTwo}</td></tr>
            <tr><th>Goal 3</th><td data-cy="goalThree">{gs.goalThree}</td></tr>
          </tbody>
        </React.Fragment>)
      }

      {
        (AUTH.isStaff) && s.satisfactionAssignments.map((ass, i) => <React.Fragment key={ass.id}>
          <thead><tr><th colSpan={2}>Satisfaction Survey completed by <UsernameRenderer userId={ass.target!.completedByUserId} /></th></tr></thead>
          <tbody>
            <tr><th>How was your experience of Online Counselling with Turn2Me?</th><td>{renderSatisfactionSurveyQuestionScore(ass.target!.ratingOfCounsellingExperience)}</td></tr>
            <tr><th>How would you rate your progress in counselling?</th><td>{renderSatisfactionSurveyQuestionScore(ass.target!.ratingSelfProgress)}</td></tr>
            <tr><th>How would you describe your relationship with your Counsellor?</th><td>{renderSatisfactionSurveyQuestionScore(ass.target!.ratingOfCounsellorRelationship)}</td></tr>
            <tr><th>How was your experience of using our website?</th><td>{renderSatisfactionSurveyQuestionScore(ass.target!.ratingUx)}</td></tr>
            <tr><th>How would you rate your experience of Online Counselling with your experience of Face-to-Face Counselling?</th><td>{renderSatisfactionSurveyQuestionScore(ass.target!.ratingOfOnlineVsOfflineCounselling)}</td></tr>
          </tbody>
        </React.Fragment>)
      }

      </InfoTable>

    </div>

  }} />
}

export default CounsellingApplicationInfoTable;