import React from "react";
import { makeIconDef } from "./makeIconDef";

export const NestedViewIconDef = {
  'nested-view': makeIconDef('nested-view', {
    regular: () => <>
      <path fillRule="evenodd" clipRule="evenodd" d="M20 6H4V18H20V6ZM4 5H3V6V18V19H4H20H21V18V6V5H20H4Z" fill="currentColor" />
      <rect x="12" y="12" width="6" height="4" fill="currentColor" />
    </>,
    filled: () => <>
      <path fillRule="evenodd" clipRule="evenodd" d="M21 5H3V19H21V5ZM19 12H12V17H19V12Z" fill="currentColor" />
    </>
  })
}