import { ColorName } from "../base/constants/color.enum";

export const Gradients: Partial<Record<ColorName, string>> = {
  [ColorName.SkyBlue]: 'radial-gradient(122.97% 151.94% at 53.51% 104.08%, rgba(18, 212, 255, 0.7) 0%, #0096D6 100%)',
  [ColorName.Red]: 'radial-gradient(124.54% 124.54% at 71.36% 116.67%, #FF7C6A 2.78%, #DC032A 100%)',
  [ColorName.BlueGreen]: 'radial-gradient(100.09% 121.05% at 97.37% 121.05%, #7DDFB6 0%, #10A782 100%)',
  [ColorName.Orange]: 'radial-gradient(118.8% 118.8% at 100% 122.56%, #FF9519 0%, #ED6401 100%)',
  [ColorName.brightPurple]: 'radial-gradient(189.1% 152.18% at 124.34% 100%, rgba(150, 150, 252, 0.71) 0%, #4E4BE0 100%)',
  [ColorName.Firebrick]: 'radial-gradient(135.73% 97.74% at 110.56% 105.51%, #E8806A 0%, #C15B4D 100%)',
  [ColorName.Yellow]: 'radial-gradient(118.8% 118.8% at 100% 122.56%, #FFCD1B 0%, #FFA800 100%)',
}