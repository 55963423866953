import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseIcon from '../../../../../../base/components/BaseIcon/BaseIcon';
import BaseSpacer from '../../../../../../base/components/BaseSpacer/BaseSpacer';
import SimpleCard from '../../../../../../base/components/SimpleCard/SimpleCard';
import { CommunicationType, communicationTypeDescriptors } from '../../../../../../constants/communicationTypes.descriptors';
import { Gradients } from '../../../../../../constants/gradients';
import './CommunicationTypeSelectorOptionCard.scss';

interface CommunicationTypeSelectorOptionCardProps {
  forType: CommunicationType
  onClick: (t: CommunicationType) => void;
  isSelected?: boolean;
}

const CommunicationTypeSelectorOptionCard: React.FC<CommunicationTypeSelectorOptionCardProps> = p => {
  return <Observer children={() => {
    const descriptor = communicationTypeDescriptors[p.forType];
    return <SimpleCard
      className="CommunicationTypeSelectorOptionCard"
      onClick={() => { p.onClick(p.forType) }}
      background={p.isSelected ? Gradients[descriptor.colorName] : undefined}
      primaryColorName={descriptor.colorName}
      data-selected={p.isSelected}
      padding="1em"
    >
      <BaseIcon icon={descriptor.icon} variant={p.isSelected ? 'filled' : 'color'} />
      <BaseSpacer inline size=".38em" />
      <strong>{descriptor.name}</strong>
    </SimpleCard>
  }} />
}

export default CommunicationTypeSelectorOptionCard;