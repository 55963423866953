import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { uniqById } from '../../base/utils/ramdaEquivalents.utils';
import { Comment } from '../../models/makeComment.model';
import { Thought } from '../../models/makeThought.model';
import CommentEntry from '../CommentEntry/CommentEntry';
import './CommentList.scss';

interface CommentListProps {
  comments: Comment[],
  thought: Thought,
  showModeratorControls?: boolean,
  disableInteractions?: boolean,
}

const CommentList: React.FC<CommentListProps> = props => {
  const p = useProps(props);
  const { AUTH } = useControllers();
  const s = useStore(() => ({
    get uniqueComments() {
      return uniqById(p.comments);
    },
    get visibleComments() {
      if (AUTH.isModerator) return s.uniqueComments;
      return s.uniqueComments.filter(c => ((!c.isUnsafe && !c.timeHidden) || (AUTH.currentUser && c.userId === AUTH.currentUser.id)) && !c.timeDeleted);
    }
  }))
  return <Observer children={() => (
    <div className="CommentList">
      {s.visibleComments.map(c => <CommentEntry
        key={c.id}
        comment={c}
        thought={props.thought}
        showModeratorControls={props.showModeratorControls}
        disableInteractions={props.disableInteractions}
      />)}
    </div>
  )} />
}

export default CommentList;