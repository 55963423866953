import { Observer } from 'mobx-react-lite';
import React from 'react';
import joinClassName from '../../utils/className.utils';
import { useProps } from '../../utils/mobx.utils';
import './AppPageContent.scss';

type AppPageContentProps = {
  className?: string
  padSections?: boolean,
}

const AppPageContent: React.FC<AppPageContentProps> = props => {

  const p = useProps(props);

  return <Observer children={() => (
    <div className={joinClassName(
      'AppPageContent',
      p.className,
      p.padSections && 'padSections'
    )}>
      { props.children }
    </div>
  )} />
}

export default AppPageContent;