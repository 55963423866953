import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import RouterStack from '../../base/components/RouterStack/RouterStack';
import SplashScreen from '../../base/components/SplashScreen/SplashScreen';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useClearPageBackground } from '../../base/hooks/useSetPageBackground.hook';
import { useStore } from '../../base/utils/mobx.utils';
import AppNavigation, { NavTabConfig } from '../../components/AppNavigation/AppNavigation';
import PreSessionActionWatcher from '../../components/PreSessionActionWatcher/PreSessionActionWatcher';
import SurveyGeneralWatcher from '../../components/SurveyGeneralWatcher/SurveyGeneralWatcher';
import SurveySupportGroupNonAttendanceWatcher from '../../components/SurveySupportGroupNonAttendanceWatcher/SurveySupportGroupNonAttendanceWatcher';
import Turn2MeLogo from '../../components/Turn2MeLogo/Turn2MeLogo';
import { TopLevelModuleName } from '../../constants/topLevelModuleName.enum';
import { useRegisterTopLevelModule } from '../../hooks/useRegisterTopLevelModule';
import { AccountModuleRouteDef } from '../shared/Account/Account.module.routes';
import { ChatModuleRouteDef } from '../shared/Chat/Chat.module.routes';
import { ClientModuleChildRoutes, ClientModuleRouteDef } from './Client.module.routes';
import { CounsellingModuleRouteDef } from './Counselling/Counselling.module.routes';
import { ExploreModuleRouteDef } from './Explore/Explore.module.routes';
import './ModuleClient.scss';
import { SupportGroupsModuleRouteDef } from './SupportGroups/SupportGroups.module.routes';

interface ModuleClientProps {}

export const ModuleClientRouterStackName = 'ModuleClientRouterStack';

const ModuleClient: React.FC<ModuleClientProps> = React.memo(props => {

  const { UI, NAVIGATOR } = useControllers();

  useClearPageBackground();
  useRegisterTopLevelModule(TopLevelModuleName.client);

  const s = useStore(() => ({
    hasLoaded: false,
    navTabs: [
      { def: ExploreModuleRouteDef, icon: 'explore' },
      { def: AccountModuleRouteDef, icon: 'person-circle', label: 'You' },
      { def: CounsellingModuleRouteDef, icon: 'counselling' },
      { def: SupportGroupsModuleRouteDef, icon: 'people', label: 'Groups' },
      {
        def: ChatModuleRouteDef,
        icon: 'chat',
      },
    ] as NavTabConfig[],
    shouldShowSplashscreen: NAVIGATOR.locationHistory.length > 1,
  }))

  useOnMount(action(() => {
    s.hasLoaded = true;
  }))

  return <Observer children={() => (
    <div className="ModuleClient">
      <div className="ModuleClientInner">
        {
          UI.onlyPhones && <AppNavigation tabConfigs={s.navTabs} prefix={ClientModuleRouteDef.urlFactory()} />
        }
        <RouterStack
          routes={ClientModuleChildRoutes}
          prefix={ClientModuleRouteDef.urlFactory()}
          name={ModuleClientRouterStackName}
        />
      </div>
      <SurveySupportGroupNonAttendanceWatcher />
      <PreSessionActionWatcher />
      <SurveyGeneralWatcher />
      {s.shouldShowSplashscreen && <SplashScreen
        identifier="ModuleClient"
        className="ClientSplashScreen"
        children={<Turn2MeLogo version="full"/>}
        contentHasLoaded={s.hasLoaded}
      />}
    </div>
  )} />

})

export default ModuleClient;