import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AppStaffRole } from '../../../constants/staffRoles.constants';
import { User } from '../../../models/makeUser.model';
import { Nillable } from '../../@types';
import { useProps, useStore } from '../../utils/mobx.utils';
import ColorTag from '../ColorTag/ColorTag';

interface ColorTagCounsellorProps {
  user?: Nillable<User>
  userRoles?: AppStaffRole[],
}

const ColorTagCounsellor: React.FC<ColorTagCounsellorProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get config() {
      switch (true) {
        case p.user?.isAccreditedCounsellor || p.userRoles?.includes(AppStaffRole.accreditedCounsellor):
          return {
            color: '#5E5CE6',
            title: 'Accr. ',
          }
        case p.user?.isPreAccreditedCounsellor || p.userRoles?.includes(AppStaffRole.preAccreditedCounsellor):
          return {
            color: '#5E5CE6',
            title: 'Pre Accr. ',
          }
        case p.user?.isPlacementCounsellor || p.userRoles?.includes(AppStaffRole.placementCounsellor):
          return {
            color: '#44C468',
            title: 'Placement ',
          }
        case p.user?.isCounsellor:
        default:
          return {
            color: 'brightPurple',
            title: '',
          }
      }
    },
  }))
  return <Observer children={() =>
    <ColorTag className="ColorTagCounsellor" color={s.config.color} appearance="translucent">{s.config.title}Counsellor</ColorTag>
  } />
}

export default ColorTagCounsellor;