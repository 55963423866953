import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import AssignmentListForAdmin, { AssignmentListForAdminProps } from '../../../../components/AssignmentListForAdmin/AssignmentListForAdmin';
// import './SurveyAssignmentListForAdmin.scss';

interface SurveyAssignmentListForAdminProps extends AssignmentListForAdminProps {}

const SurveyAssignmentListForAdmin: React.FC<SurveyAssignmentListForAdminProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get surveyAssignments() {
      return p.assignments?.filter(a => a.targetType?.includes('Survey'));
    }
  }));
  return <Observer children={() => (
    <div className="SurveyAssignmentListForAdmin">
      <AssignmentListForAdmin assignments={s.surveyAssignments} application={p.application} />
    </div>
  )} />
}

export default SurveyAssignmentListForAdmin;