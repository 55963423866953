
import { FeedbackEndpoints } from "../base/endpoints/feedback.endpoints";
import { getSnapshot } from "../base/utils/snapshot.utils";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { Feedback, FeedbackSnapshot } from "../models/makeFeedback.model";

export function submitFeedback(API: APIController, feedback: Feedback | FeedbackSnapshot) {
  return new Promise<Feedback>(async (resolve, reject) => {
    try {
      const url = FeedbackEndpoints.own.create();
      const {id, ...payload} = getSnapshot(feedback);
      const savedFeedback = await API.post<Feedback>(url, ModelName.feedback, payload);
      if (!savedFeedback) throw Error('Failed to submit feedback');
      resolve(savedFeedback);
    } catch(e) {
      reject(e);
    }
  })
}