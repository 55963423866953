import { action, observable } from "mobx";
import { AnyObject, TypeIrrelevant, Untypable, Validator, ValidatorResult } from "../@types";
import { replaceArrayContent } from "../utils/array.utils";
import { equalByJsonSnapshot } from "../utils/equality.utils";
import { copyWithJSON, mergeIntoObjectWithDescriptor } from "../utils/object.utils";
import { isArray } from "../utils/typeChecks.utils";

export type FormField<
  T extends AnyObject = AnyObject,
  K extends keyof T = keyof T
> = {
  // value: T[K],
  value: Untypable,
  keyName: K,
  originalValue: Untypable,
  validator?: Validator,
  validity: ValidatorResult,
  touched: boolean,
  changed: boolean,
  markAsTouched: () => void,
  reset: () => void,
  clear: () => void,
}

export type SourceofForm<SourceTyoe> = SourceTyoe extends Form<infer FormSource> ? FormSource : object;

export type FormFieldSet<T extends AnyObject> = Record<keyof T, FormField<T, keyof T>>;
export type Form<T extends AnyObject> = {
  fields: FormFieldSet<T>,
  validity: Record<keyof T, ValidatorResult>,
  clear: () => void,
  reset: (resetSource?: Partial<T>) => void,
  value: T,
  get: (field: keyof T) => any,
  set: (field: keyof T, value?: any) => any,
  touched: boolean,
  checkIfHasChanges: () => boolean,
  hasChanges: boolean,
  originalSource: T,
  editableFieldNames: (keyof T)[],
  editableFields: FormField<T>[],
  markAsSaved: () => void,
}

export type FormControllerOptions<T extends AnyObject> = {
  validators?: Partial<Record<keyof T, Validator>>,
  editableFields?: (keyof T)[],
}


export function makeFormField<T extends AnyObject, K extends keyof T = keyof T>(
  originalSource: Partial<T>,
  source: Partial<T>,
  key: K, 
  options?: FormControllerOptions<T>,
): FormField<Partial<T>, K> {
  const field: FormField<Partial<T>, K> = observable({
    keyName: key,
    get originalValue() {
      return originalSource[key]
    },
    get value() {
      return source[key] as Untypable;
    },
    set value(v: Untypable) {
      if (isArray(source[key]) && isArray(v)) {
        replaceArrayContent(source[key] as any[], v);
      } else {
        source[key] = v;
      }
    },
    get validator() {
      return options?.validators ? options?.validators[key] : undefined;
    },
    get validity() {
      return field.validator ? field.validator(field.value) : true;
    },
    get changed() {
      return !equalByJsonSnapshot(field.originalValue, field.value);
    },
    touched: false,
    markAsTouched: action(() => field.touched = true),
    reset: action(() => field.value = field.originalValue),
    clear: action(() => field.value = undefined),
  });
  return field;
}

export function makeForm<EntryType extends AnyObject>(
  object: EntryType, 
  options?: FormControllerOptions<EntryType>
): Form<EntryType> {

  let originalSource: EntryType = observable(copyWithJSON(object) as EntryType);
  let source: EntryType = observable(copyWithJSON(object) as EntryType);

  let fields: FormFieldSet<EntryType> = observable({}) as unknown as FormFieldSet<Partial<EntryType>>;

  Object.keys(source).forEach((key: keyof EntryType) => {
    fields[key] = makeFormField<EntryType, typeof key>(originalSource, source, key, options);
  })

  function mapFields<R extends TypeIrrelevant>(
    callback: (field: FormField<TypeIrrelevant>) => R
  ) {
    let result: AnyObject = {};
    Object.entries(fields).forEach(e => {
      result[e[0]] = callback(e[1]);
    })
    return result as Record<keyof EntryType, R>;
  }
  function iterator(callback: (field: FormField<TypeIrrelevant>) => any) {
    Object.values(fields).forEach(callback);
  }

  const editableFieldNames = options?.editableFields ?? Object.keys(fields);
  const editableFields = observable(editableFieldNames.map(n => fields[n]) as FormField<EntryType>[]);

  const form: Form<EntryType> = observable({
    fields,
    get validity() {
      return mapFields(f => f.validity);
    },
    clear: () => iterator(f => f.clear()),
    reset: (resetSource?: Partial<EntryType>) => {
      mergeIntoObjectWithDescriptor(source, copyWithJSON((resetSource ?? source) as Partial<EntryType>));
      mergeIntoObjectWithDescriptor(originalSource, copyWithJSON(source));
    },
    get value() {
      return source;
    },
    get(field: keyof EntryType) {
      return fields[field]?.value;
    },
    set: action((field: keyof EntryType, value?: any) => {
      const formField = fields[field];
      return formField && (formField.value = value);
    }),
    get originalSource() {
      return copyWithJSON(originalSource);
    },
    editableFieldNames,
    editableFields,
    get touched() {
      return editableFields.some(f => f.touched);
    },
    checkIfHasChanges() {
      return editableFields.some(f => f.changed);
    },
    get hasChanges() {
      return editableFields.some(f => f.changed);
    },
    markAsSaved() {
      mergeIntoObjectWithDescriptor(originalSource, copyWithJSON(form.value));
    },
  })

  return form;
}