import React from "react";
import { makeIconDef } from "./makeIconDef";

export const MicrophoneIconDefs = {
	'microphone': makeIconDef('microphone', {
		regular: () => <path fillRule="evenodd" clipRule="evenodd" d="M9 5C9 3.34315 10.3431 2 12 2C13.6569 2 15 3.34315 15 5V11C15 12.6569 13.6569 14 12 14C10.3431 14 9 12.6569 9 11V5ZM6 9C6.27614 9 6.5 9.22386 6.5 9.5V11C6.5 14.0376 8.96243 16.5 12 16.5C15.0376 16.5 17.5 14.0376 17.5 11V9.5C17.5 9.22386 17.7239 9 18 9C18.2761 9 18.5 9.22386 18.5 9.5V11C18.5 14.5899 15.5899 17.5 12 17.5C8.41015 17.5 5.5 14.5899 5.5 11V9.5C5.5 9.22386 5.72386 9 6 9ZM12 17.5H12.5V20H17V21H7V20H11.5V17.5H12Z" fill="currentColor" />,
		filled: () => <path fillRule="evenodd" clipRule="evenodd" d="M9 5C9 3.34315 10.3431 2 12 2C13.6569 2 15 3.34315 15 5V11C15 12.6569 13.6569 14 12 14C10.3431 14 9 12.6569 9 11V5ZM6 8.5C6.55228 8.5 7 8.94772 7 9.5V11C7 13.7614 9.23858 16 12 16C14.7614 16 17 13.7614 17 11V9.5C17 8.94772 17.4477 8.5 18 8.5C18.5523 8.5 19 8.94772 19 9.5V11C19 14.5265 16.3923 17.4439 13 17.9291V19.5H17V21.5H7V19.5H11V17.9291C7.60771 17.4439 5 14.5265 5 11V9.5C5 8.94772 5.44772 8.5 6 8.5Z" fill="currentColor" />,
	}),
	'microphone-off': makeIconDef('microphone-off', {
		regular: () => <path fillRule="evenodd" clipRule="evenodd" d="M11.5 17.4811C10.5124 17.4059 9.58644 17.1101 8.77142 16.6428L9.5092 15.905C10.257 16.2855 11.1034 16.5 12 16.5C15.0375 16.5 17.5 14.0376 17.5 11V9.5C17.5 9.22386 17.7238 9 18 9C18.2761 9 18.5 9.22386 18.5 9.5V11C18.5 14.4216 15.8562 17.2257 12.5 17.4811V20H17V21H6.99998V20H11.5V17.4811ZM12 14C11.8164 14 11.6367 13.9835 11.4622 13.9519L15 10.4142V11C15 12.6569 13.6568 14 12 14ZM15 7.29289L19.6464 2.64645L20.3535 3.35355L4.35353 19.3536L3.64642 18.6464L7.06376 15.2291C6.08893 14.0923 5.49998 12.6149 5.49998 11V9.5C5.49998 9.22386 5.72383 9 5.99998 9C6.27612 9 6.49998 9.22386 6.49998 9.5V11C6.49998 12.3387 6.97829 13.5658 7.77334 14.5195L9.5546 12.7383C9.20537 12.2479 8.99998 11.6479 8.99998 11V5C8.99998 3.34315 10.3431 2 12 2C13.6568 2 15 3.34315 15 5V7.29289Z" fill="currentColor" />,
		filled: () => <>
			<path d="M11 17.9291C10.1516 17.8078 9.35231 17.5343 8.63058 17.1372L10.1294 15.6383C10.7073 15.8716 11.3386 16 12 16C14.7614 16 17 13.7614 17 11V9.5C17 8.94772 17.4477 8.5 18 8.5C18.5523 8.5 19 8.94772 19 9.5V11C19 14.5265 16.3923 17.4439 13 17.9291V19.5H17V21.5H7.00001V19.5H11V17.9291Z" fill="currentColor" />
			<path d="M12 14C11.9247 14 11.85 13.9972 11.776 13.9918L15 10.7678V11C15 12.6569 13.6569 14 12 14Z" fill="currentColor" />
			<path d="M15 6.58579L19.2929 2.29289L20.7071 3.70711L4.70712 19.7071L3.29291 18.2929L6.39363 15.1922C5.51842 14.0236 5.00001 12.5723 5.00001 11V9.5C5.00001 8.94772 5.44773 8.5 6.00001 8.5C6.5523 8.5 7.00001 8.94772 7.00001 9.5V11C7.00001 12.0191 7.30489 12.967 7.82841 13.7574L9.29239 12.2934C9.10497 11.9018 9.00001 11.4631 9.00001 11V5C9.00001 3.34315 10.3432 2 12 2C13.6569 2 15 3.34315 15 5V6.58579Z" fill="currentColor" />
		</>
	})
}