import { SHOULD_LOG } from "../../env";
import { Nillable } from "../@types/utilities.types";
import { element, ValidSelectorOrElement } from "./element.utils";

export const disableScroll = (el: Nillable<ValidSelectorOrElement> = document.documentElement) => {
  SHOULD_LOG() && console.log('disabling scroll on el', el);
  element(el).addClass('no-scroll');
}
export const enableScroll = (el: Nillable<ValidSelectorOrElement> = document.documentElement) => {
  element(el).removeClass('no-scroll');
}

export const disableSelect = (el: Nillable<ValidSelectorOrElement> = document.documentElement) => {
  element(el).addClass('no-select');
}
export const enableSelect = (el: Nillable<ValidSelectorOrElement> = document.documentElement) => {
  element(el).removeClass('no-select');
}