import React from 'react';
import './InfoDisplayList.scss';

interface InfoDisplayListProps {}

const InfoDisplayList: React.FC<InfoDisplayListProps> = props => {
  return <ul className="InfoDisplayList">
    {props.children}
  </ul>
}

export default InfoDisplayList;