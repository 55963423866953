

export enum AppStaffRole {
	// staff roles.
	'admin' = 'admin',
	'counsellor' = 'counsellor',
	'preAccreditedCounsellor' = 'preAccreditedCounsellor',
	'accreditedCounsellor' = 'accreditedCounsellor',
	'placementCounsellor' = 'placementCounsellor',
	'coordinator' = 'coordinator',
	'facilitator' = 'facilitator',
	'moderator' = 'moderator',
	'analyst' = 'analyst',
	'financialAdministrator' = 'financialAdministrator',
}

export const StaffRoleSet: AppStaffRole[] = [AppStaffRole.admin, AppStaffRole.counsellor, AppStaffRole.preAccreditedCounsellor, AppStaffRole.accreditedCounsellor, AppStaffRole.placementCounsellor, AppStaffRole.coordinator, AppStaffRole.facilitator, AppStaffRole.moderator, AppStaffRole.analyst, AppStaffRole.financialAdministrator]

export const isStaffRole = (staffRole: AppStaffRole) => {
	return StaffRoleSet.includes(staffRole as any)
}