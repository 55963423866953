import { always } from "../../../../../base/utils/ramdaEquivalents.utils";
import { makeObservableRouteDef } from "../../../../../base/utils/routes.utils";
import AccountPageDonations from "./AccountPageDonations.page";

export const AccountPageDonationsRouteDef = makeObservableRouteDef({
  identifier: 'account-donations',
  urlFactory: always('donations'),
  composeTitle: always('Donations'),
  component: AccountPageDonations,
  icon: 'heart',
  primaryColorName: 'red',
})