import { Observer } from 'mobx-react-lite';
import React from 'react';
import joinClassName from '../../utils/className.utils';
import { useProps } from '../../utils/mobx.utils';
import { ValidRatingInputValue } from './RatingInput';
import './RatingInputStar.scss';

interface RatingInputStarProps {
  inputValue: ValidRatingInputValue,
  forValue: ValidRatingInputValue,
  onMouseEnter: (ratingValue: ValidRatingInputValue) => unknown,
  onClick: (ratingValue: ValidRatingInputValue) => unknown,
}

const RatingInputStar: React.FC<RatingInputStarProps> = props => {
  const p = useProps(props);
  return <Observer children={() => (
    <span className={
      joinClassName(
        'RatingInputStar',
        p.inputValue >= p.forValue && 'isFull',
      )
    }
      onClick={() => p.onClick(p.forValue)}
      onMouseEnter={() => p.onMouseEnter(p.forValue)}
    >
      <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="m11.311 1.9534c0.2604-0.60447 1.1174-0.60447 1.3777 0l2.5632 5.9519c0.1086 0.25213 0.3462 0.4248 0.6196 0.45015l6.4526 0.59846c0.6553 0.06078 0.9202 0.87589 0.4257 1.3102l-4.8685 4.2769c-0.2062 0.1812-0.297 0.4606-0.2367 0.7284l1.4248 6.3217c0.1447 0.6421-0.5486 1.1458-1.1145 0.8098l-5.5721-3.3086c-0.236-0.1401-0.5298-0.1401-0.7658 0l-5.5721 3.3086c-0.5659 0.336-1.2593-0.1677-1.1146-0.8098l1.4248-6.3217c0.06036-0.2678-0.03042-0.5472-0.23666-0.7284l-4.8685-4.2769c-0.49444-0.43436-0.2296-1.2495 0.42573-1.3102l6.4526-0.59846c0.27335-0.02535 0.511-0.19802 0.61958-0.45015l2.5631-5.9519z" />
      </svg>
    </span>
  )} />
}

export default RatingInputStar;