import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject } from '../../base/@types';
import BaseSelector, { BaseSelectorProps } from '../../base/components/BaseSelector/BaseSelector';
import { sortArray } from '../../base/utils/array.utils';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { Company } from '../../models/makeCompany.model';
import { useGetCompanies } from '../../requests/useGetCompanies.request';

export interface CompanySelectorProps extends Omit<BaseSelectorProps<AnyObject, Company, string | undefined>, 'options'> { }

const CompanySelector: React.FC<CompanySelectorProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({

    companies: [] as Company[],
    loading: true as boolean,

    get sortedCompanies() {
      const arr = sortArray(s.companies, {
        transformer: c => s.optionRenderer(c)
      });
      return arr;
    },
    get disabled() {
      return p.disabled ?? s.loading;
    },
    get placeholder() {
      return s.loading ? 'Loading companies...' : p.placeholder ?? 'Open to everyone';
    },
    get label() {
      return p.label ?? 'Assign a company';
    },
    get optionRenderer() {
      return (c: Company) => `${c.name} (#${c.id})`;
    },
  }));

  useGetCompanies({
    observable: s,
    key: 'companies',
    finally: action(() => s.loading = false)
  });

  return <Observer children={() => (
    <BaseSelector<AnyObject, Company, string | undefined>
      {...p}
      className={joinClassName(
        'CompanySelector',
        p.className
      )}
      appearance="system"
      options={s.sortedCompanies}
      valueGetter={o => o?.id}
      valueEqualityChecker={(a, b) => a === b}
      label={s.label}
      emptyValue=""
      placeholder={s.placeholder}
      disabled={s.disabled}
      optionLabelRenderer={s.optionRenderer}
    />
  )} />
}

export default CompanySelector;