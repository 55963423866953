import { action, flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { submitFlag } from '../../actions/submitFlag.action';
import { ColorCodedState } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseButtonGroup from '../../base/components/BaseButtonGroup/BaseButtonGroup';
import BodyCopyRenderer from '../../base/components/BodyCopyRenderer/BodyCopyRenderer';
import ColorTagHidden from '../../base/components/ColorTag/ColorTagHidden';
import ColorTagSafe from '../../base/components/ColorTag/ColorTagSafe';
import ColorTagUnsafe from '../../base/components/ColorTag/ColorTagUnsafe';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import FlagCounter from '../../base/components/FlagCounter/FlagCounter';
import FormInput from '../../base/components/FormInput/FormInput';
import MenuToggle from '../../base/components/MenuToggle/MenuToggle';
import { IconName } from '../../base/components/Symbols/iconDefs';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { makeForm } from '../../base/mediators/form.mediator';
import joinClassName from '../../base/utils/className.utils';
import { equalByString } from '../../base/utils/equality.utils';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { mergeIntoObjectWithDescriptor } from '../../base/utils/object.utils';
import { getUrlParams, removeUrlParam, setUrlParam } from '../../base/utils/urlParams.utils';
import { ApiModelName } from '../../constants/ApiModels.enum';
import { CLOCK } from '../../controllers/common/clock.controller';
import { ActionConfig } from '../../controllers/ui/ui.controller.types';
import { Comment } from '../../models/makeComment.model';
import { Thought } from '../../models/makeThought.model';
import FlaggableItemManager from '../../modules/Admin/_components/FlaggableItemManager/FlaggableItemManager';
import { deleteComment } from '../../requests/deleteComment.request';
import { saveComment } from '../../requests/saveComment.request';
import UserCountryRenderer from '../UserCountryRenderer/UserCountryRenderer';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import './CommentEntry.scss';

interface CommentEntryProps {
  comment: Comment,
  thought: Thought,
  showModeratorControls?: boolean,
  disableInteractions?: boolean,
  onMenuOpen?: () => void,
  onMenuClose?: () => void,
}

const CommentEntry: React.FC<CommentEntryProps> = props => {

  const p = useProps(props);

  const { API, UI, AUTH, NAVIGATOR } = useControllers();

  const s = useStore(() => ({
    get comment() {
      return p.comment;
    },
    get body() {
      return s.comment.body;
    },
    get displayBody() {
      if (s.comment.timeDeleted) return `${AUTH.canModerate ? (s.comment.body || '[Empty Comment]') : ''} <em class="CommentEntryUnavailableNotice">[This comment has been deleted]</em>`;
      if (s.comment.timeHidden) return `${AUTH.canModerate || s.isOwnComment ? (s.comment.body || '[Empty Comment]') : ''} <em class="CommentEntryUnavailableNotice">[This comment has been hidden]</em>`;
      return s.body;
    },
    get flagsLength() {
      return s.comment.flags?.length || 0;
    },
    get isFlagged() {
      return s.flagsLength > 0;
    },
    get isSafe() {
      return Boolean(s.comment.isSafe);
    },
    get isUnsafe() {
      return Boolean(s.comment.isUnsafe);
    },
    get isHidden() {
      return Boolean(s.comment.timeHidden);
    },
    get shouldShowFlag() {
      return !!s.flagsLength;
    },
    get showDirectEditButton() {
      return AUTH.canModerate && NAVIGATOR.isInAdminArea && !s.editMode && !s.comment.timeDeleted;
    },
    editMode: false,
    form: makeForm(p.comment.$snapshot),
    awaitingEditResponse: false,
    get isOwnComment() {
      return AUTH.currentUser?.id && s.comment.userId === AUTH.currentUser?.id;
    },
    manageThought: () => {
      if (!AUTH.isModerator) return;
      setUrlParam('manageThoughtId', p.thought.id, NAVIGATOR);
    },
    get isInManageOverlay() {
      return getUrlParams().manageThoughtId === p.thought.id;
    },
    get menuActions(): ActionConfig[] {
      return [
        ...AUTH.canModerate ? [
          {
            name: 'edit',
            label: 'Edit Comment',
            icon: 'pencil' as IconName,
            action: action(() => {
              s.editMode = true;
            })
          }
        ] : [],
        ...(s.isOwnComment || AUTH.canModerate) ? [
          {
            name: 'delete',
            label: 'Delete Comment',
            icon: 'delete' as IconName,
            colorCodedState: ColorCodedState.alert,
            action: flow(function * () {
              const confirm = yield UI.DIALOG.attention({ heading: 'Are you sure you want to delete this comment?', defaultActions: ['negative', 'positive'] })
              if (!confirm) return;
              try {
                yield deleteComment(p.comment, API);
                p.comment.timeDeleted = CLOCK.nowUtcTimestamp;
              } catch(e) {
                reportError(e);
                UI.DIALOG.error({ heading: 'Failed to delete comment :(', error: e});
              }
            })
          }
        ] : [],
        ...!s.isOwnComment ? [
          {
            name: 'flag',
            label: 'Flag Comment…',
            icon: 'flag' as IconName,
            colorCodedState: ColorCodedState.alert,
            action: () => {
              submitFlag(p.comment, API, p.thought);
            }
          },
        ] : [],
        ...(AUTH.canModerate && !s.isInManageOverlay) ? [
          {
            name: 'manage',
            label: 'Manage',
            icon: 'cog' as IconName,
            action: () => {
              s.manageThought();
            }
          }
        ] : [],
      ]
    },
    menuIsOpen: false,
    onMenuOpen: action(() => {
      s.menuIsOpen = true;
      props.onMenuOpen?.();
    }),
    onMenuClose: action(() => {
      s.menuIsOpen = false;
      props.onMenuClose?.();
    }),
    get shouldUseBlockLevelLayout() {
      return AUTH.canModerate || s.comment.originalComment;
    }
  }));

  useOnMount(action(() => {
    const params = getUrlParams();
    const shouldEdit = equalByString(params.editCommentId, s.comment.id);
    if (shouldEdit) {
      s.editMode = true;
      removeUrlParam('editCommentId');
    }
  }))

  const toggleEditMode = action(() => s.editMode = !s.editMode);
  const saveChanges = flow(function* () {
    if (s.comment.timeDeleted) return;
    s.awaitingEditResponse = true;
    const comment = yield saveComment(API, s.form.value).catch(e => {
      UI.DIALOG.error({
        heading: "Failed to save comment",
        body: <ErrorRenderer error={(e as any).response} />,
      })
    }).finally(action(() => {
      s.awaitingEditResponse = false;
    }));
    mergeIntoObjectWithDescriptor(s.comment, comment);
    toggleEditMode();
  })
  const revertChanges = () => {
    toggleEditMode();
    s.form.reset(p.comment);
  }

  return <Observer children={() => (
    <section className={
      joinClassName(
        'CommentEntry',
        // s.isFlagged && 'flagged',
        s.isSafe && 'safe',
        s.isHidden && 'hidden',
        s.editMode && 'edit',
        s.menuIsOpen && 'menuOpen',
        s.comment.timeDeleted && 'deleted',
        s.shouldUseBlockLevelLayout && 'block'
      )
    } data-id={`Comment#${s.comment.id}`}>

      <div className="CommentEntryContent">
        
        <article className="CommentEntryContentInner">
          <h3>
            {s.isHidden ? <ColorTagHidden /> : null}
            { AUTH.canModerate && <>
              {s.isSafe ? <ColorTagSafe/> : null}
              {s.isUnsafe ? <ColorTagUnsafe/> : null}
            </> }
            <strong><UsernameRenderer user={s.comment.user} userId={s.comment.userId} /></strong>
            {AUTH.canModerate && s.comment.user && <UserCountryRenderer user={s.comment.user} />}
            {s.shouldUseBlockLevelLayout && <span className="CommentEntryTimestamp"> posted on <DateRenderer value={s.comment.timeCreated} timeOnlyIfToday /></span>}
          </h3>
          <div className="CommentEntryBody">
            {
              s.editMode ? <FormInput
                form={s.form}
                field="body"
                type="textarea"
                disabled={s.awaitingEditResponse}
                autoFocus
                resize="vertical"
              /> : s.displayBody ? <BodyCopyRenderer className="CommentEntryBodyContent" source={s.displayBody} /> : <code>[Empty Comment]</code>
            }
            { AUTH.isModerator && s.comment.originalComment && s.comment.originalComment.body !== s.comment.body && <div className="CommentEntryOriginalCopy"><BodyCopyRenderer source={`[Original Copy]: ${s.comment.originalComment.body}`} /></div> }
            { !s.shouldUseBlockLevelLayout && <DateRenderer className="CommentEntryTimestamp" value={s.comment.timeCreated} timeOnlyIfToday /> }
          </div>
        </article>

        {!p.disableInteractions && <div data-cy="CommentEntryControls" className="CommentEntryControls">
          {s.shouldShowFlag && <FlagCounter count={s.flagsLength} />}
          {s.showDirectEditButton && <BaseButton className="subtle" key="modify" name="modify" size="xs" onClick={toggleEditMode}>Edit</BaseButton>}
          {s.editMode && <BaseButtonGroup>
            <BaseButton key="save" name="save" size="xs" color="green" onClick={saveChanges} disabled={s.awaitingEditResponse}>Save</BaseButton>
            <BaseButton key="cancel" name="cancel" size="xs" color="median" className="subtle" onClick={revertChanges} disabled={s.awaitingEditResponse}>Cancel</BaseButton>
          </BaseButtonGroup>}
          {AUTH.isAuthenticated && !s.comment.timeDeleted && !p.thought.timeDeleted && (
            <MenuToggle actions={s.menuActions} onMenuOpen={s.onMenuOpen} onMenuClose={s.onMenuClose} />
          )}
        </div>}

      </div>

      {props.showModeratorControls && <FlaggableItemManager flaggable={s.comment} modelType={ApiModelName.COMMENT} compact disabled={Boolean(s.comment.timeDeleted || p.thought.timeDeleted)}/>}
      
    </section>
  )} />

}

export default CommentEntry;