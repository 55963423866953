import React from 'react';
import ColorTag from './ColorTag';

interface ColorTagPaidSessionProps {
  useAbbr?: boolean
}

const ColorTagPaidSession: React.FC<ColorTagPaidSessionProps> = props => {
  return <ColorTag className="ColorTagPaidSession" color="skyblue">
    Paid
  </ColorTag>
}

export default ColorTagPaidSession;