import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Nullable, Undefinable } from '../../base/@types';
import { ContextColor } from '../../base/constants/color.enum';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { ChatMediator } from '../../base/mediators/chat.mediator';
import joinClassName from '../../base/utils/className.utils';
import { getContextColorStyle } from '../../base/utils/colors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { CLOCK } from '../../controllers/common/clock.controller';
import { SupportGroup } from '../../models/makeSupportGroup.model';
import './ChatWindow.scss';
import ChatWindowBody from './ChatWindowBody';
import ChatWindowHeader from './ChatWindowHeader';
import { ChatWindowStackMode } from './ChatWindowStack';

export interface ChatWindowProps {
  mode?: ChatWindowStackMode,
  chat: ChatMediator;
}

export type ChatWindowState = ReturnType<typeof useChatWindowState>;

const useChatWindowState = (p: ChatWindowProps) => {
  const { UI } = useControllers();
  const s = useStore(() => ({
    get chat() {
      return p.chat;
    },
    get videoOn() {
      return !!s.chat.videoChatRoom;
    },
    get style() {
      let color = '';
      switch (s.chat.type) {
        case 'counselling-session': color = 'brightPurple'; break;
        case 'support-group': color = (s.chat.associatedModel as Undefinable<SupportGroup>)?.color ?? 'blueGreen'; break;
        default: break;
      }
      return getContextColorStyle(ContextColor.Primary, color);
    },
    showParticipantList: UI.displayMode !== 'phone',
    timeLastReceivedFocus: CLOCK.nowUtcTimestamp,
    hasFocus: false,
    handleFocus: action(() => {
      s.hasFocus = true;
      s.timeLastReceivedFocus = CLOCK.nowUtcTimestamp;
    }),
    handleBlur: action(() => {
      s.hasFocus = false;
      s.timeLastReceivedFocus = CLOCK.nowUtcTimestamp;
    })
  }));
  return s;
}

function ChatWindow(props: React.PropsWithChildren<ChatWindowProps>) {

  const { UI } = useControllers();

  const p = useProps(props);
  const s = useChatWindowState(p);
  
  const toggleParticipantList = action(() => {
    s.showParticipantList = !s.showParticipantList;
  })
  
  useOnMount(() => {
    p.chat.registerChatWindow(s);
    if (p.mode === 'docked') {
      const stackEl = document.querySelector('.ChatWindowStack') as Nullable<HTMLElement>;
      UI.focusOnElementInPortal(stackEl);
    }
    const refreshData = () => {
      p.chat.refresh()
    }
    window.addEventListener('focus', refreshData);
    window.addEventListener('online', refreshData);
    return () => {
      window.removeEventListener('focus', refreshData)
      window.addEventListener('online', refreshData);
    }
  });

  return <Observer children={() => (
    <div 
      className={joinClassName(
        'ChatWindow',
        p.mode === 'docked' && p.chat.shouldOpenInDock && '--opened',
        p.mode === 'docked' && !p.chat.shouldOpenInDock && '--closed',
        s.videoOn && 'videoOn',
        s.chat.timeStarted && !s.chat.hasEnded && 'live',
        s.chat.hasEnded && 'ended',
        s.hasFocus && 'focus',
        (p.mode !== 'docked' || p.chat.shouldOpenInDock) && 'bodyVisible',
        s.chat.selfParticipant?.user?.isStaff ? 'isStaff' : 'isClient',
      )} 
      style={s.style}
      data-chat-id={p.chat.id}
      data-mode={p.mode}
      tabIndex={0}
      onFocus={s.handleFocus}
      onBlur={s.handleBlur}
      data-time-last-received-focus={s.timeLastReceivedFocus}
    >
      <ChatWindowHeader {...p} onToggleParticipantList={toggleParticipantList} />
      <ChatWindowBody mode={p.mode} chat={s.chat} showParticipantList={s.showParticipantList} />
    </div>
  )} />
}

export default ChatWindow;