import { Observer } from 'mobx-react-lite';
import React from 'react';
import './HeadingAllcaps.scss';

interface HeadingAllcapsProps {}

const HeadingAllcaps: React.FC<HeadingAllcapsProps> = props => {
  return <Observer children={() => (
    <p className="HeadingAllcaps">
      <strong>{props.children}</strong>
    </p>
  )} />
}

export default HeadingAllcaps;