import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { ColorCodedState, Nullable, TimezoneMode } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseButtonGroup from '../../base/components/BaseButtonGroup/BaseButtonGroup';
import BaseCheckbox from '../../base/components/BaseCheckbox/BaseCheckbox';
import BaseGrid from '../../base/components/BaseGrid/BaseGrid';
import BaseGridCell from '../../base/components/BaseGrid/BaseGridCell';
import BaseInput from '../../base/components/BaseInput/BaseInput';
import DatePicker from '../../base/components/DatePicker/DatePicker';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import DurationRenderer from '../../base/components/DurationRenderer/DurationRenderer';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import InfoBanner from '../../base/components/InfoBanner/InfoBanner';
import InfoDisplayItem from '../../base/components/InfoDisplayList/InfoDisplayItem';
import InfoDisplayList from '../../base/components/InfoDisplayList/InfoDisplayList';
import RenderIf from '../../base/components/RenderIf/RenderIf';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import TimeInput from '../../base/components/TimeInput/TimeInput';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { navigateToChatPage } from '../../base/utils/chat.utils';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { copyWithJSON } from '../../base/utils/object.utils';
import {
  YYYYMMDDHHmm,
  createMomentFn,
  createUTCMoment,
} from "../../base/utils/time.utils";
import { removeUrlParam } from "../../base/utils/urlParams.utils";
import { ApiModelName } from "../../constants/ApiModels.enum";
import { CommunicationType } from "../../constants/communicationTypes.descriptors";
import { CounsellingType } from "../../constants/counsellingTypes.descriptors";
import { getWordPressLink } from "../../env";
import { ChatThread } from "../../models/makeChatThread.model";
import { CounsellingApplication } from "../../models/makeCounsellingApplication.model";
import {
  CounsellingSession,
  CounsellingSessionSnapshot,
  makeCounsellingSession,
} from "../../models/makeCounsellingSession.model";
import { User } from "../../models/makeUser.model";
import { getCounsellingApplication } from "../../requests/getCounsellingApplication.request";
import { saveCounsellingSession } from "../../requests/saveCounsellingSession.request";
import CommunicationTypeRenderer from "../CommunicationTypeRenderer/CommunicationTypeRenderer";
import CommunicationTypeSelector from "../CommunicationTypeSelector/CommunicationTypeSelector";
import CounsellingApplicationCompositeStatusColorTag from "../CounsellingApplicationCompositeStatusColorTag/CounsellingApplicationCompositeStatusColorTag";
import CounsellingSessionParticipantsSelector from "../CounsellingSessionParticipantsSelector/CounsellingSessionParticipantsSelector";
import CounsellorSelector from "../CounsellorSelector/CounsellorSelector";
import IssueSelector from "../IssueSelector/IssueSelector";
import OverlayCounsellingSessionManager from "../OverlayCounsellingSessionManager/OverlayCounsellingSessionManager";
import UsernameRenderer from "../UsernameRenderer/UsernameRenderer";
import "./CounsellingSessionEditor.scss";

interface CounsellingSessionEditorProps {
  session: CounsellingSession;
  application?: CounsellingApplication;
  client?: User;
  onSaveSuccess?: (s: CounsellingSession) => unknown;
  inputTimezoneMode?: TimezoneMode;
  outputTimezoneMode?: TimezoneMode;
}

const CounsellingSessionEditor: React.FC<CounsellingSessionEditorProps> = (
  props
) => {
  const p = useProps(props);
  const _createMomentForInput = createMomentFn(p.inputTimezoneMode ?? "auto");

  const { COMMON, UI, API, AUTH, MESSENGER, NAVIGATOR } = useControllers();

  const s = useStore(() => ({
    get isCounsellor() {
      return AUTH.isCounsellor;
    },
    originalCopy: copyWithJSON(p.session.$getSnapshot()),
    get application() {
      return p.application || p.session.application;
    },
    get applicationIsArchived() {
      return Boolean(s.application?.timeArchived);
    },
    get applicationIsCompleted() {
      return Boolean(s.application?.timeCompleted);
    },
    get applicationIsArchivedOrCompleted() {
      return s.applicationIsArchived || s.applicationIsCompleted;
    },
    get sessionId() {
      return p.session?.id;
    },
    get applicationId() {
      return s.application?.id;
    },
    get clientPreferredSessionTime() {
      return s.application?.availability?.timeStart;
    },
    get clientPreferredSessionTimeMoment() {
      return s.clientPreferredSessionTime
        ? _createMomentForInput(s.clientPreferredSessionTime)
        : undefined;
    },
    get mode() {
      return p.session.id ? "edit" : "create";
    },
    get scheduledAt() {
      return p.session.timeScheduled;
    },
    get scheduledAtMoment() {
      return s.scheduledAt ? _createMomentForInput(s.scheduledAt) : undefined;
    },
    get scheduledDateIsInThePast() {
      return s.scheduledAtMoment
        ? s.scheduledAtMoment.isBefore(COMMON.CLOCK.localNowMoment)
        : false;
    },
    get hoursTillScheduledAt() {
      if (!s.scheduledAtMoment) return null;
      return moment
        .duration(s.scheduledAtMoment.diff(COMMON.CLOCK.localNowMoment))
        .asHours();
    },
    get isPaidSessionWithLimitedClientPaymentTime() {
      if (s.hasClientPaid || s.hoursTillScheduledAt === null) return false;
      if (s.sessionId && !s.scheduleDateHasChanged) return false;
      return p.session.isPaidSession && s.hoursTillScheduledAt < 48;
    },

    get assignments() {
      return p.session.assignments;
    },
    get assignment() {
      return (
        s.assignments &&
        s.assignments.find((a) => a.targetType === ApiModelName.PAYMENT)
      );
    },
    get paymentId() {
      return s.assignment && s.assignment.targetId;
    },
    get hasClientPaid() {
      return !!s.paymentId;
    },

    get startedAt() {
      return p.session.timeStarted;
    },
    get startedAtMoment() {
      return s.startedAt ? _createMomentForInput(s.startedAt) : null;
    },
    get endedAt() {
      return p.session.timeEnded;
    },
    get endedAtMoment() {
      return s.endedAt
        ? _createMomentForInput(s.endedAt)
        : COMMON.CLOCK.localNowMoment;
    },
    get duration() {
      return s.startedAtMoment ? s.endedAtMoment.diff(s.startedAtMoment) : null;
    },
    get hasStarted() {
      return !!s.startedAt;
    },
    get hasEnded() {
      return !!s.endedAt;
    },
    get isOnGoing() {
      return s.hasStarted && !s.hasEnded;
    },
    get isInThePast() {
      return !!(
        s.endedAt && COMMON.CLOCK.localNowMoment.diff(s.endedAtMoment) < 0
      );
    },
    get canEditScheduledDate() {
      return !s.hasStarted;
    },
    get scheduleDateHasChanged() {
      if (!p.session.id) return false;
      else {
        if (!s.originalCopy.timeScheduled && !p.session.timeScheduled)
          return false;
        if (!s.originalCopy.timeScheduled && p.session.timeScheduled)
          return true;
      }
      if (s.originalCopy.timeScheduled && p.session?.timeScheduled) {
        // console.log(s.originalCopy.timeScheduled, p.session.timeScheduled);
        return !createUTCMoment(s.originalCopy.timeScheduled).isSame(
          createUTCMoment(p.session.timeScheduled),
          "minute"
        );
      }
      return false;
    },
    get sessionHasValidData() {
      return p.session.timeScheduled && p.session.type;
    },
    get shouldDisableForm() {
      return s.hasStarted || s.applicationIsArchivedOrCompleted;
    },
    get threads() {
      return p.session.threads || ([] as ChatThread[]);
    },
    get canViewChatHistory() {
      return Boolean(s.threads.length > 0 && s.threads[0].id);
    },
    useUserPreferredTimeToFillForm: action(() => {
      const preferredTime = s.application?.availability?.timeStart;
      if (preferredTime) {
        p.session.timeScheduled = preferredTime;
      }
    }),
    get canOnlyUseVideo() {
      return (
        s.application?.type &&
        s.application.type !== CounsellingType.OneToOne &&
        s.application.communicationTypePreference === CommunicationType.Video
      );
    },
  }));

  const confirmSaveChanges = () =>
    new Promise(function (resolve, reject) {
      const action = async () => {
        try {
          const result = await saveChanges();
          p.onSaveSuccess && p.onSaveSuccess(result);
          Object.assign(s.originalCopy, result.$getSnapshot());
          // refetch application, so app.session.length is updated.
          getCounsellingApplication(result.applicationId, API, undefined, true);
          resolve(result);
        } catch (e) {
          reportError(e);
          reject(e);
        }
      };
      action();
    });

  const saveChanges = () => {
    if (s.application?.timeArchived)
      throw Error(
        "The application has been archived; it is no longer possible to edit the session."
      );
    // ? if allowModelPatch not false, surveys not populated on create.
    return saveCounsellingSession(API, p.session, UI, {
      allowModelPatch: false,
    });
  };

  const viewChatHistory = async () => {
    const { id = undefined } =
      (p.session.threads ? p.session.threads[0] || {} : {}) || {};
    if (!id) return;
    try {
      const chat = await MESSENGER.loadChatById(id);
      if (!chat) {
        UI.DIALOG.attention({
          heading: "Hmm, we weren't able to load the chat thread.",
          body: "If you believe this should not have happened, please contact the development team for assistence.",
        });
        return;
      }
      if (UI.onlyPhones) navigateToChatPage(NAVIGATOR, chat.id);
      else {
        chat.keepInDock();
        const stackEl = document.querySelector(
          ".ChatWindowStack"
        ) as Nullable<HTMLElement>;
        UI.focusOnElementInPortal(stackEl);
      }
    } catch (e) {
      reportError(e);
      UI.DIALOG.error({
        heading: "Failed to retrieve the chat thread.",
        body: <ErrorRenderer error={(e as any).response} />,
      });
    }
  };

  const scheduleNewSession = async () => {
    UI.OVERLAY.dismiss();
    const thisSession = p.session.$getSnapshot();
    const newSessionSnapshot: Partial<CounsellingSessionSnapshot> = {
      type: thisSession.type,
      timeScheduled: createUTCMoment(thisSession.timeScheduled)
        .add(1, "week")
        .format(YYYYMMDDHHmm),
      applicantId: thisSession.applicantId,
      applicationId: thisSession.applicationId,
      applicationType: thisSession.applicationType,
      counsellorId: thisSession.counsellorId,
      clientIds: thisSession.clientIds,
      amountPayable: thisSession.amountPayable,
    };
    const newSession = makeCounsellingSession(newSessionSnapshot);
    removeUrlParam("manageSessionId");
    UI.OVERLAY.present({
      id: `OverlayCounsellingSessionManager`,
      name: `OverlayCounsellingSessionManager`,
      component: (
        <OverlayCounsellingSessionManager
          session={newSession}
          application={p.session.application}
          applicationId={p.session.applicationId}
          client={p.session.applicant ?? p.session.application?.applicant}
        />
      ),
      width: "66.67em",
      duplicateStrategy: "abort",
      noBackdrop: true,
    });
  };

  // log changes on each input change

  return (
    <Observer
      children={() => (
        <div className='CounsellingSessionEditor'>
          <BaseGrid columns={1}>
            {s.canOnlyUseVideo && !s.sessionId && (
              <BaseGridCell columns='all'>
                <InfoBanner icon='info' color='red'>
                  <p>This session will be conducted as a video session.</p>
                </InfoBanner>
              </BaseGridCell>
            )}

            <BaseGridCell>
              <ShadedBlock
                className='CounsellingSessionEditorDateSection'
                spaceChildren
              >
                <RenderIf
                  if={
                    s.canEditScheduledDate &&
                    !s.applicationIsArchivedOrCompleted
                  }
                  component={() => (
                    <Observer
                      children={() => (
                        <>
                          <h4>Schedule a date and time</h4>
                          <p>
                            When you save any changes on the date and time of
                            this session, the client will be notified
                            immediately.
                          </p>
                          <BaseGrid columns={UI.fromTablet ? 3 : 1}>
                            <BaseGridCell columns={UI.fromTablet ? 2 : 1}>
                              <DatePicker
                                form={p.session}
                                field='timeScheduled'
                                label='Date'
                                inputTimezoneMode={p.inputTimezoneMode}
                                outputTimezoneMode={p.outputTimezoneMode}
                              />
                            </BaseGridCell>
                            <TimeInput
                              form={p.session}
                              field='timeScheduled'
                              label='Time'
                              inputTimezoneMode={p.inputTimezoneMode}
                              outputTimezoneMode={p.outputTimezoneMode}
                            />
                          </BaseGrid>
                          {s.scheduledDateIsInThePast && (
                            <InfoBanner
                              colorCodedState={ColorCodedState.attention}
                              icon='warning'
                            >
                              <p>The selected time is in the past.</p>
                            </InfoBanner>
                          )}
                          {s.isPaidSessionWithLimitedClientPaymentTime && (
                            <InfoBanner
                              colorCodedState={ColorCodedState.error}
                              icon='warning'
                            >
                              <p>
                                The selected time is approximately{" "}
                                {s.hoursTillScheduledAt
                                  ? Math.round(s.hoursTillScheduledAt)
                                  : "unknown"}{" "}
                                hours away.
                              </p>
                              <p>
                                Paid sessions should be scheduled 48+ hours from
                                now to allow clients to complete session
                                payment, or to allow clients to cancel the
                                session in accordance with turn2me's{" "}
                                <a
                                  href={getWordPressLink(
                                    "/terms-and-conditions"
                                  )}
                                  title='Terms and Conditions'
                                  target='_blank'
                                  rel='noreferrer'
                                >
                                  cancellation policy
                                </a>
                                .
                              </p>
                            </InfoBanner>
                          )}
                        </>
                      )}
                    />
                  )}
                />

                <RenderIf
                  if={!s.hasStarted && s.applicationIsArchivedOrCompleted}
                  component={() => (
                    <>
                      <p>
                        <strong>
                          Application status:{" "}
                          <CounsellingApplicationCompositeStatusColorTag
                            application={s.application!}
                          />
                        </strong>
                      </p>
                    </>
                  )}
                />

                <RenderIf
                  if={s.hasStarted}
                  component={() => (
                    <>
                      <InfoDisplayList>
                        <InfoDisplayItem size='normal' label='Started at'>
                          <DateRenderer
                            value={s.startedAt}
                            timezoneMode={p.inputTimezoneMode}
                            format='llll'
                          />
                        </InfoDisplayItem>
                        {s.endedAt && (
                          <InfoDisplayItem size='normal' label='Ended at'>
                            <DateRenderer
                              value={s.endedAt}
                              timezoneMode={p.inputTimezoneMode}
                              format='llll'
                            />
                          </InfoDisplayItem>
                        )}
                        <InfoDisplayItem size='normal' label='Duration'>
                          <DurationRenderer
                            startTime={s.startedAt}
                            endTime={s.endedAt}
                            timezoneMode={p.inputTimezoneMode}
                          />
                        </InfoDisplayItem>
                      </InfoDisplayList>
                    </>
                  )}
                />

                {s.isCounsellor && (
                  <RenderIf
                    if={s.hasEnded || s.canViewChatHistory}
                    component={() => (
                      <BaseButtonGroup>
                        {s.applicationId &&
                          s.hasEnded &&
                          !s.applicationIsArchived &&
                          !s.application?.isCompleted && (
                            <BaseButton
                              icon='plus'
                              onClick={scheduleNewSession}
                            >
                              Schedule new session
                            </BaseButton>
                          )}
                        {s.canViewChatHistory &&
                          (UI.displayMode === "phone" ? (
                            <p>
                              Please open this page on your desktop PC or a
                              bigger tablet to view chat history.
                            </p>
                          ) : (
                            <BaseButton
                              icon='chat'
                              onClick={viewChatHistory}
                              label={
                                s.hasEnded
                                  ? "View chat history"
                                  : "Open chat window"
                              }
                            />
                          ))}
                      </BaseButtonGroup>
                    )}
                  />
                )}
              </ShadedBlock>
            </BaseGridCell>

            <RenderIf
              if={s.hasStarted}
              component={() => (
                <ShadedBlock spaceChildren>
                  <h4>Scheduled start time</h4>
                  <BaseGrid columns={2}>
                    <DatePicker
                      form={p.session}
                      disabled={
                        s.hasEnded ||
                        s.application?.isCompleted ||
                        s.application?.isArchived
                      }
                      field='timeScheduled'
                      label='Date'
                      inputTimezoneMode={p.inputTimezoneMode}
                      outputTimezoneMode={p.outputTimezoneMode}
                    />
                    <TimeInput
                      form={p.session}
                      disabled={
                        s.hasEnded ||
                        s.application?.isCompleted ||
                        s.application?.isArchived
                      }
                      field='timeScheduled'
                      label='Time'
                      inputTimezoneMode={p.inputTimezoneMode}
                      outputTimezoneMode={p.outputTimezoneMode}
                    />
                  </BaseGrid>
                </ShadedBlock>
              )}
            />

            <BaseGridCell>
              <BaseGrid columns={2}>
                {p.session.application?.type === CounsellingType.OneToOne && (
                  <BaseGridCell columns='all'>
                    <ShadedBlock spaceChildren>
                      <h4>
                        Communication Type
                        {s.hasStarted && (
                          <>
                            {" "}
                            :{" "}
                            <CommunicationTypeRenderer value={p.session.type} />
                          </>
                        )}
                      </h4>
                      {s.hasStarted || (
                        <CommunicationTypeSelector
                          application={s.application}
                          form={p.session}
                          field='type'
                          disabled={s.shouldDisableForm}
                        />
                      )}
                    </ShadedBlock>
                  </BaseGridCell>
                )}

                <BaseGridCell columns='all'>
                  <ShadedBlock spaceChildren>
                    <h4>
                      Counsellor{" "}
                      {s.hasStarted && (
                        <>
                          {" "}
                          : <UsernameRenderer userId={p.session.counsellorId} />
                        </>
                      )}
                    </h4>
                    {s.hasStarted || (
                      <CounsellorSelector
                        form={p.session}
                        field='counsellorId'
                        disabled={!AUTH.isStaff || s.shouldDisableForm}
                        label=''
                      />
                    )}
                  </ShadedBlock>
                </BaseGridCell>

                {p.session && s.application && (
                  <BaseGridCell columns='all'>
                    <CounsellingSessionParticipantsSelector
                      session={p.session}
                      application={s.application}
                    />
                  </BaseGridCell>
                )}

                <BaseGridCell columns='all'>
                  <ShadedBlock spaceChildren>
                    <h4>Session Notes</h4>
                    <BaseGrid columns={2}>
                      <IssueSelector
                        form={p.session}
                        field='primaryIssue'
                        label='Primary Issue'
                        onChange={() => confirmSaveChanges()}
                        disabled={s.applicationIsArchived || !s.isCounsellor}
                      />
                      <IssueSelector
                        form={p.session}
                        field='secondaryIssue'
                        label='Secondary Issue'
                        onChange={() => confirmSaveChanges()}
                        disabled={s.applicationIsArchived || !s.isCounsellor}
                      />
                    </BaseGrid>
                    <BaseInput
                      type='textarea'
                      label='Notes'
                      form={p.session}
                      field='sessionNotes'
                      optional
                      rows={9}
                      onBlur={() => confirmSaveChanges()}
                      disabled={s.applicationIsArchived || !s.isCounsellor}
                    />
                    <BaseInput
                      type='textarea'
                      label='Did the session experience any issues, either technical or other problems?'
                      form={p.session}
                      field='technicalOrOtherIssues'
                      optional
                      rows={4}
                      onBlur={() => confirmSaveChanges()}
                      disabled={s.applicationIsArchived || !s.isCounsellor}
                    />
                    <BaseCheckbox
                      form={p.session}
                      dataCy='hasAnyClientAttended'
                      field='hasAnyClientAttended'
                      disabled={
                        !!p.session.timeArchived ||
                        s.application?.isCompleted ||
                        s.application?.isArchived ||
                        !s.isCounsellor
                      }
                    >
                      User attended session
                    </BaseCheckbox>
                  </ShadedBlock>
                </BaseGridCell>

                {s.isCounsellor && !s.application?.timeArchived && (
                  <BaseGridCell columns='all'>
                    <BaseButton
                      onClick={confirmSaveChanges}
                      disabled={
                        !s.sessionHasValidData ||
                        s.isPaidSessionWithLimitedClientPaymentTime
                      }
                      fullWidth
                      size='lg'
                      dataCy='confirmSaveChanges'
                      label={s.sessionId ? "Save Changes" : "Create session"}
                    />
                  </BaseGridCell>
                )}
              </BaseGrid>
            </BaseGridCell>
          </BaseGrid>
        </div>
      )}
    />
  );
};

export default CounsellingSessionEditor;