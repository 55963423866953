
import { StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { ModelName } from "../constants/modelNames.enum";
import { hasTimestamps } from "../traits/hasTimestamps.trait";
import { CounsellingApplicationApplicantType } from "./makeCounsellingApplication.model";
import { User } from "./makeUser.model";

export type InvitationSnapshot = ReturnType<typeof makeInvitationSnapshotBase>;
export type InvitationRelatedModels = {
  user?: User,
};
export type InvitationExtendedProperties = {};
/**
 * An invitation is found in a counselling application involving a second co-applicant (invitee).
 * This is typically couples counselling or young people counselling.
 * An invitation is generated for this invitee, and the application then makes an invitation link with relevant information including the UUID inside.
 */
export type Invitation = StandardModel<
  InvitationSnapshot,
  InvitationRelatedModels,
  InvitationExtendedProperties
>;

export function makeInvitationSnapshotBase() {

  const beforeCreation = {
    userId: '',
    username: '',
    email: '',
    name: '',
    dateOfBirth: '',
    gender: '',
    sexuality: '',
  }

  const afterCreation = {
    id: '',
    timeAccepted: '',
    actingAs: null as CounsellingApplicationApplicantType | null,
    applicationId: '',
    uuid: '',
    ...hasTimestamps(),
  }

  return {
    ...beforeCreation,
    ...afterCreation
  }
}

export const makeInvitation = createStandardModelFactory<Invitation, InvitationRelatedModels, InvitationExtendedProperties>({
  name: ModelName.invitations,
  snapshotFactory: makeInvitationSnapshotBase,
})
