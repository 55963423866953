import { User } from "@sentry/react";
import { Nillable, StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { ModelName } from "../constants/modelNames.enum";
import { hasTimestamps } from "../traits/hasTimestamps.trait";
import { AgeGroupFilterType } from "../utils/ageAndDateOfBirth.utils";

export type SupportGroupTopicSnapshot = ReturnType<typeof makeSupportGroupTopicSnapshotBase>;
export type SupportGroupTopicRelatedModels = {
	defaultFacilitator?: User,
}

export type SupportGroupTopicExtendedProperties = {};

export type SupportGroupTopic = StandardModel<
	SupportGroupTopicSnapshot,
	SupportGroupTopicRelatedModels,
	SupportGroupTopicExtendedProperties
>

export const makeSupportGroupTopicSnapshotBase = () => ({
	id: '',
	slug: '',
	displayName: '',
	color: '',
	icon: '',
	internalDescription: '',
	defaultGroupTitle: '',
	defaultGroupSubtitle: '',
	defaultPublicDescription: '',
	defaultScheduledDurationInMinutes: 60,
	defaultMaxParticipants: 20,
	defaultFacilitatorId: '',
	defaultAgeGroups: [] as AgeGroupFilterType[],
	supportGroupCount: 0,
	statistics: makeSupportGroupTopicStat() as SupportGroupTopicStatType | undefined,
  timeScheduled: '' as Nillable<string>, // not in model, but needed for query in admin stats.
	...hasTimestamps(),
})

export const makeSupportGroupTopicStat = () => ({
	"avgAttendeeCount": 0,
	"numberOfSupportGroups": 0,
	"reservationUserIds": [] as string[],
	"supportGroupIds": [] as string[],
	"totalAttendeeCount": 0,
});

export type SupportGroupTopicStatType = ReturnType<typeof makeSupportGroupTopicStat>;

export const makeSupportGroupTopic = createStandardModelFactory<SupportGroupTopic, SupportGroupTopicRelatedModels, SupportGroupTopicExtendedProperties>({
	name: ModelName.supportGroupTopics,
	snapshotFactory: makeSupportGroupTopicSnapshotBase,
	relationshipsSchema: {
		defaultFacilitator: ModelName.users,
	}
})