import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Undefinable } from '../../base/@types';
import { getGreetingTimeOfDay } from '../../base/bots/responses/greetings.botResponses';
import AppPage from '../../base/components/AppPage/AppPage';
import AppPageContent from '../../base/components/AppPageContent/AppPageContent';
import AppPageContentFooter from '../../base/components/AppPageContentFooter/AppPageContentFooter';
import AppPageHeader from '../../base/components/AppPageHeader/AppPageHeader';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import OverlayCloseButton from '../../base/components/OverlayCloseButton/OverlayCloseButton';
import UIBlock from '../../base/components/UIBlock/UIBlock';
import { SurveySupportGroupSatisfactionEndpoints } from '../../base/endpoints/survey.endpoints';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { getSnapshot } from '../../base/utils/snapshot.utils';
import { getNowTimestampUtc } from '../../base/utils/time.utils';
import { isNumber } from '../../base/utils/typeChecks.utils';
import { ModelName } from '../../constants/modelNames.enum';
import { Assignment, AssignmentSupportGroupSatisfaction } from '../../models/makeAssignment.model';
import { makeSurveySupportGroupSatisfaction, SurveySupportGroupSatisfactionSnapshot } from '../../models/makeSurveySupportGroupSatisfaction';
import SurveyEditorSupportGroupSatisfaction from '../SurveyEditorSupportGroupSatisfaction/SurveyEditorSupportGroupSatisfaction';
import Turn2MeLogo from '../Turn2MeLogo/Turn2MeLogo';
import './OverlaySurveySupportGroupSatisfaction.scss';

export const ID_OverlaySurveySupportGroupSatisfaction = 'OverlaySurveySupportGroupSatisfaction';

type OverlaySurveySupportGroupSatisfactionProps = {
  assignment?: AssignmentSupportGroupSatisfaction,
  assignmentId?: string,
  onComplete?: (a?: AssignmentSupportGroupSatisfaction) => unknown,
  groupId?: string,
  readonly?: boolean,
}

const OverlaySurveySupportGroupSatisfaction: React.FC<OverlaySurveySupportGroupSatisfactionProps> = props => {
  const { API, AUTH, UI } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    _assignment: undefined as Undefinable<Assignment>,
    get assignment() {
      return p.assignment || s._assignment;
    },
    get assignmentId() {
      return s.assignment?.id || p.assignmentId || ''
    },
    survey: makeSurveySupportGroupSatisfaction(p.assignment?.target),
    get formIsComplete() {
      return isNumber(s.survey.ratingOfExperience) &&
        isNumber(s.survey.ratingOfOtherUsersRelationship) &&
        isNumber(s.survey.ratingOfWebsiteExperience);
    },
  }))
  const submit = flow(function* () {
    dismiss();
    try {
      const url = SurveySupportGroupSatisfactionEndpoints.own.create();
      const payload: SurveySupportGroupSatisfactionSnapshot = getSnapshot(s.survey);
      payload.groupId = p.groupId ?? '';
      payload.assignmentId = s.assignment?.id;
      payload.assignedToUserId = s.assignment?.assignedToUserId;
      payload.completedByUserId = AUTH.currentUser?.id;
      payload.timeCreated = getNowTimestampUtc();
      delete payload.timeDeleted;
      delete payload.timeUpdated;
      yield API.post(url, ModelName.surveysSupportGroupSatisfaction, payload);
      UI.DIALOG.success({
        name: 'support-group-satisfaction-survey-submission-success',
        heading: 'Your survey was submitted successfully.',
        body: 'Thanks for your participation! The surveys will help us improve our services for all of our clients. You can now continue to use the service.',
      });
      if (p.assignment) {
        p.assignment.timeCompleted = getNowTimestampUtc();
      }
      p.onComplete && p.onComplete(p.assignment);
      dismiss();
    } catch (e) {
      reportError(e);
      UI.DIALOG.error({
        heading: 'Sorry! Your form was not submitted successfully.',
        body: <ErrorRenderer error={(e as any).response} />,
      })
    }
  })
  const dismiss = () => {
    UI.OVERLAY.dismiss(ID_OverlaySurveySupportGroupSatisfaction);
  }
  useOnMount(() => {
    if (!p.readonly) {
      s.survey.assignmentId = s.assignmentId;
    }
  });
  return <Observer children={() => (
    <AppPage className="OverlaySurveySupportGroupSatisfaction">
      <AppPageHeader
        title={<div className="OverlaySurveySupportGroupSatisfactionTitle">Support Group <br />Satisfaction Survey</div>}
        endSlot={p.readonly ? <OverlayCloseButton /> : <Turn2MeLogo version="full" />}
        spaceChildren
      />
      <AppPageContent padSections>
        {
          !p.readonly && <UIBlock>
            <p>{getGreetingTimeOfDay()} As you have just completed online support group with us, please spare 2 minutes to rate your support group experience with us.</p>
          </UIBlock>
        }
        <UIBlock>
          <SurveyEditorSupportGroupSatisfaction survey={s.survey} readonly={p.readonly} />
        </UIBlock>
        <AppPageContentFooter padded="all">
          {
            p.readonly ? <BaseButton fullWidth size="lg" onClick={dismiss}>Dismiss</BaseButton> : (
              <BaseButton fullWidth size="lg" onClick={submit} disabled={!s.formIsComplete} color="green" dataCy="Submit Survey">Submit Survey</BaseButton>
            )
          }
        </AppPageContentFooter>
      </AppPageContent>
    </AppPage>
  )} />
}

export default OverlaySurveySupportGroupSatisfaction;