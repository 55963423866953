import { StandardModel } from "../base/@types";
import { CountryCode } from "../base/constants/countries.constants";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { ModelName } from "../constants/modelNames.enum";
import { hasTimestamps } from "../traits/hasTimestamps.trait";

export const makeSurveyGeneralSnapshot = () => {

	return {

		id: '',
		version: null as number | null,
		age: null as number | null,

		gender: '' as string | null,
		sexuality: '' as string | null,
		ethnicity: '' as string | null,
		countryId: '' as CountryCode | null,
		region: '' as string | null,
		employmentStatus: '' as string | null,
		profession: '' as string | null,
		supportType: [] as string[],
		referredBy: [] as string[],
		
		// new in V2 forms:
		maritalStatus: '' as string | null,
		collegeReferenceInput: '' as string | null,
		hasPrivateHealthCare: null as boolean | null,
		inWaitingListOfAnotherOrganisation: '' as string | null,
		ownSymptomsOrFeelingsMeasurement: '' as string | null,

		...hasTimestamps(),

	}

}

export type SurveyGeneralSnapshot = ReturnType<typeof makeSurveyGeneralSnapshot>;
export type SurveyGeneralRelatedModels = {}
export type SurveyGeneralExtendedProperties = {};

export type SurveyGeneral = StandardModel<SurveyGeneralSnapshot>;

export const makeSurveyGeneral = createStandardModelFactory<SurveyGeneral, SurveyGeneralRelatedModels>({
	name: ModelName.surveysGeneral,
	snapshotFactory: makeSurveyGeneralSnapshot,
})