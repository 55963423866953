const API_KEYS = {
  "app.turn2me.ie": {
    PUSHER_APP_KEY: "dd4b3cc334a8c6d759cb",
    PUSHER_APP_CLUSTER: "eu",
    STRIPE_KEY:
      "pk_live_51GcuOmKDEmPif3VtH1Xe6ZVpsIvIBbVv1FsCCkk09EpAOwVj6ZyhH9Re0we38uG2rqjeqpiLPO7HCvHaG9kPefs600mEGIZsQE",
    GOOGLE_RECAPTCHA: "6LfrSSIbAAAAALgKs-fPlZDoZAD2vd0XF87L9_81",
  },
  "preflight.turn2me.ie": {
    PUSHER_APP_KEY: "91ed56bee869d71f801e",
    PUSHER_APP_CLUSTER: "eu",
    STRIPE_KEY:
      "pk_test_80o5DUnEogasKoB4ufzAxGrx00H9fiQ3TQ",
    GOOGLE_RECAPTCHA: "6Lc9xQcoAAAAAIZZ9wROO1zMMCUTesI1Qspwf0RX",
  },
  "uat-app.turn2me.ie": {
    PUSHER_APP_KEY: "02159d17b6ad7bdcad33",
    PUSHER_APP_CLUSTER: "eu",
    STRIPE_KEY:
      "pk_test_80o5DUnEogasKoB4ufzAxGrx00H9fiQ3TQ",
    GOOGLE_RECAPTCHA: "6Ldhxh8oAAAAAMiIXYRL_St8yNhugO3yDsb3Vbfl",
  },
  "qa-app.turn2me.ie": {
    PUSHER_APP_KEY: "9b94bd9c6648ffa5d4c4",
    PUSHER_APP_CLUSTER: "eu",
    STRIPE_KEY:
      "pk_test_80o5DUnEogasKoB4ufzAxGrx00H9fiQ3TQ",
    GOOGLE_RECAPTCHA: "6Ldfxh8oAAAAAJWcCKQ1A8hGwt4nJyY8ytTbPc4j",
  },
  localhost: {
    PUSHER_APP_KEY: "8b909b8d4630f3d0ca8a",
    PUSHER_APP_CLUSTER: "eu",
    STRIPE_KEY:
      "pk_test_51Jwl6GGv7VC4NkBPpHy4K92fWETdKanED80foU8MY2Yrj59w11wSQ2gUzbbOuRocMPfPcdsNJV3w6UqArWHRcmcR00ghTyHjQO",
    GOOGLE_RECAPTCHA: "6Ldfxh8oAAAAAJWcCKQ1A8hGwt4nJyY8ytTbPc4j",
  },
  default: {
    PUSHER_APP_KEY: "",
    PUSHER_APP_CLUSTER: "",
    STRIPE_KEY:
      "",
    GOOGLE_RECAPTCHA: "",
  },
};

export type ApiKeyName =
  | 'PUSHER_APP_KEY'
  | 'PUSHER_APP_CLUSTER'
  | 'STRIPE_KEY'
  | 'GOOGLE_RECAPTCHA'

export function getApiKey(keyName: ApiKeyName) {
  const { hostname } = window.location;
  try {
    // @ts-ignore
    return API_KEYS[hostname][keyName] || API_KEYS['default'][keyName];
  } catch (e) {
    try {
      // @ts-ignore
      return API_KEYS['default'][keyName];
    } catch (e) {
      return 'NO_KEY_FOUND';
    }
  }
}
