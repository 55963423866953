import { Observer } from 'mobx-react-lite';
import React from 'react';
import './VerticalSeparator.scss';

type VerticalSeparatorProps = {
  width?: string | number;
}

const VerticalSeparator: React.FC<VerticalSeparatorProps> = props => {
  return <Observer children={() => (
    <div className="VerticalSeparator" style={{ borderLeftWidth: props.width }}/>
  )} />
}

export default VerticalSeparator;