import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { uniqById } from '../../utils/ramdaEquivalents.utils';
import { BaseCalendarEventConfig, BaseCalendarProps } from './BaseCalendar';
import BaseCalendarEvent from './BaseCalendarEvent';
import './BaseCalendarEventList.scss';
import { IBaseCalendarEventTypeGroup } from './BaseCalendarState';

interface BaseCalendarEventListProps<EventDataType = object> extends BaseCalendarProps<EventDataType> {
  events: BaseCalendarEventConfig<EventDataType>[],
  eventTypeGroups: IBaseCalendarEventTypeGroup<EventDataType>[],
  shouldShowEventEndTime?: boolean,
}

function BaseCalendarEventList<T extends object>(props: React.PropsWithChildren<BaseCalendarEventListProps<T>>) {
  const p = useProps(props);
  const s = useStore(() => ({
    get events() {
      return uniqById(p.events);
    },
    get eventsOrderedByTime() {
      const result = [...s.events].sort((a,b) => {
        if (!a.startTime) return -1;
        if (!b.startTime) return 1;
        const at = moment(a.startTime);
        const bt = moment(b.startTime);
        // @ts-ignore
        const diff = at.diff(bt);
        return diff;
      });
      return result;
    },
    get Renderer() {
      return p.eventRenderer || BaseCalendarEvent
    }
  }));
  return <Observer children={() => (
    <div className={joinClassName('BaseCalendarEventList', p.className)}>
      {s.eventsOrderedByTime.map((e, i) => <s.Renderer<T>
        key={e.id || e.startTime + '_' + i}
        calendarEvent={e}
        timezoneMode={p.timezoneMode}
        eventTypeGroups={p.eventTypeGroups}
        shouldShowEventEndTime={p.shouldShowEventEndTime}
        defaultOnEventClick={p.onEventClick}
      />)}
    </div>
  )} />
}

export default BaseCalendarEventList;