import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Timeframe } from '../../base/@types';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import './TimeframeQuickPickerButton.scss';

interface TimeframeQuickPickerButtonProps {
	form: Timeframe,
	timeframe: Timeframe,
	onClick: (timeframe: Timeframe) => void,
}

const TimeframeQuickPickerButton: React.FC<TimeframeQuickPickerButtonProps> = props => {
	const p = useProps(props);
	const s = useStore(() => ({
		get isSelected() { return p.form.startDate === p.timeframe.startDate && p.form.endDate === p.timeframe.endDate}
	}));
	return <Observer children={() => (
		<button className={
			joinClassName(
				'TimeframeQuickPickerButton',
				s.isSelected && 'selected'
			)
		} onClick={() => p.onClick(p.timeframe)}>
			{ props.children }
		</button>
	)} />
}

export default TimeframeQuickPickerButton;