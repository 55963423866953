import deepFreeze from 'deep-freeze';
import BaseSpacer from '../base/components/BaseSpacer/BaseSpacer';
import { ColorName } from '../base/constants/color.enum';
import { uniq } from '../base/utils/ramdaEquivalents.utils';
import { P_ } from "./permissions.alias";
import { AppPermission } from './permissions.constants';
import { AppStaffRole } from './staffRoles.constants';

/**
 * AppPermissionGroup:
 *
 * Groups of related permissions that together forms a higher level concept. They can related to traditional concept of roles, but they also represent groups of actions in certain types of tasks.
 * The groups here are mostly for UI purposes where related permissions are grouped together.
 * The structure is moore "composition" inspired, rather than inheritance.
 */

export type AppPermissionGroup<T extends P_ = P_> = Readonly<{
	name: string,
	description: string,
	Description?: JSX.Element,
	primaryColorName: ColorName,
	permissions: readonly T[],
}>

export type AppPermissionRole<T extends P_ = P_> = AppPermissionGroup<T>
	& { roleName: AppStaffRole };

export type PermissionAPIObject = {
	id: number,
	name: AppPermission,
}

export type RoleAPIObject = {
	id: number,
	name: AppStaffRole,
}

const clientManagementUserGroups: AppPermissionGroup = {
	name: 'Client User Groups',
	description: `Control which user groups that this user can interact with or provide services for.`,
	primaryColorName: ColorName.SkyBlue,
	permissions: [
		P_.workWithAdults,
		P_.workWithCouples,
		P_.workWithYoungPeople1214,
		P_.workWithYoungPeople1517,
		P_.workWithFrontLineWorkers,
	]
}

const clientManagementBasic: AppPermissionGroup = {
	name: 'Basic Client Management',
	description: 'Basic client management, including basic data read/write, password resets, starting instant chats and sent custom individual notifications.',
	primaryColorName: ColorName.Blue,
	permissions: [
		P_.accessAnyUsersPersonalInfo,
		P_.updateAnyUserPersonalInfo,
		P_.updateAnyUserPassword,
		P_.startChatWithStaff,
		P_.startChatWithClients,
		P_.sendIndividualNotificationsToAnyUser,
	]
}

const clientManagementAdvanced: AppPermissionGroup = {
	name: "Advanced Client Management",
	description: "Advanced client management, including dangerous operations such as muting, deactivating or deleting an user. This set of permissions are generally reserved for administrators only.",
	primaryColorName: ColorName.Red,
	permissions: [
		P_.muteAnyUser,
		P_.deleteAnyClient,
		P_.suspendAnyClient,
		// P.useVoipFeature,
	]
}

const counsellingServiceProvider: AppPermissionGroup = {
	name: 'Counselling Service Provider',
	description: 'Basic set of permissions for counsellors. Special counselling types such as young people and couples must be added separately.',
	primaryColorName: ColorName.brightPurple,
	permissions: [
		P_.provideCounselling,
		P_.startChatWithStaff,
		P_.startChatWithClients,
		P_.useVoipFeature,
		P_.billPaidCounsellingSessions,
		P_.billFreeCounsellingSessions,
		P_.enforceSessionPaymentCollection,
		P_.markSessionAsRefunded,
	]
}

const supportGroupFacilitation: AppPermissionGroup = {
	name: 'Support Group Facilitation',
	description: "Organize, facilitate and manage support groups. Management of group topics are by default not included.",
	primaryColorName: ColorName.BlueGreen,
	permissions: uniq([
		P_.facilitateSupportGroups,
		...clientManagementBasic.permissions,
		P_.useVoipFeature,
		P_.manageSupportGroupTopics,
	])
}

const surveyManagement: AppPermissionGroup = {
	name: 'Survey Management',
	description: "Manage non-clinical surveys submitted by clients.",
	primaryColorName: ColorName.Emerald,
	permissions: [
		P_.manageFeedback,
		P_.manageSatisfactionSurveys,
		P_.manageGeneralSurveys,
		P_.managePHQ9Surveys,
		P_.manageGAD7Surveys,
		P_.manageSupportGroupSatisfactionSurveys,
		P_.manageSupportGroupNonAttendanceSurveys,
	]
}

const customerSupport: AppPermissionGroup = {
	name: 'Customer Support',
	description: 'All basic permissions necessary for customer support. Does not include restricting permissions such as muting or deactivating an user.',
	primaryColorName: ColorName.Orange,
	permissions: uniq([
		P_.useVoipFeature,
		...clientManagementBasic.permissions,
		...surveyManagement.permissions,
		P_.manageContactForms,
	])
}

const userAndThoughtCatcherModeration: AppPermissionGroup = {
	name: 'Moderation: Users and Thought Catcher',
	description: "Moderates user content such as thoughts and comments. Has access to basic client management, plus ability to mute or deactivate clients (not staff).",
	primaryColorName: ColorName.SkyBlue,
	permissions: uniq([
		P_.moderateThoughtCatcher,
		// P_.accessAnyUsersClinicalData,
		P_.suspendAnyClient,
		...clientManagementBasic.permissions,
		P_.muteAnyUser,
		P_.useVoipFeature,
	])
}

const dataAnalysis: AppPermissionGroup = {
	name: 'Data Analysis',
	description: 'Access non-clinical surveys and statistics.',
	primaryColorName: ColorName.Navy,
	permissions: uniq([
		P_.accessStatsUsers,
		P_.accessStatsDemography,
		P_.accessStatsOnlineCounselling,
		P_.accessStatsSupportGroups,
		P_.accessStatsThoughtCatcher,
		P_.accessStatsDonations,
		P_.accessStatsCounsellors,
		P_.accessStatsSatisfactionSurveys,
	])
}

const payments: AppPermissionGroup = {
	name: 'Donations & Invoicing',
	description: "Manage donations, invoicing and other payment related features.",
	primaryColorName: ColorName.Green,
	permissions: [
		P_.manageDonations,
		P_.manageStaffInvoicing,
	]
}

const staffCoordination: AppPermissionGroup = {
	name: "Staff Coordination",
	description: 'Permissions to grant user the ability to manage all schedules of counselling, availabilities, support groups etc.',
	primaryColorName: ColorName.DeepPurple,
	permissions: [
		P_.manageStaff,
		P_.viewAnyChatHistory,
		P_.coordinateCounsellingApplications,
		P_.coordinateFreeCounsellingAvailabilities,
		P_.coordinatePaidCounsellingAvailabilities,
		P_.coordinateCounsellingSessions,
		P_.coordinateSupportGroups,
		P_.useVoipFeature,
	]
}


const generalAdministration: AppPermissionGroup = {
	name: 'General Administration',
	description: "Manage higher level service controls, coordinate staff, in general perform most tasks except for counselling, which needs to be manually enabled.",
	primaryColorName: ColorName.Steel,
	permissions: uniq([
		P_.manageSupportGroupTopics,
		P_.manageGlobalSettings,
		P_.manageServiceCountryAvailabilities,
		P_.manageAllUsers,
		P_.exportCounsellingApplications,
		P_.manageCompanies,
		...staffCoordination.permissions,
		...clientManagementBasic.permissions,
		...clientManagementAdvanced.permissions,
		...supportGroupFacilitation.permissions,
		...userAndThoughtCatcherModeration.permissions,
		...customerSupport.permissions,
		...payments.permissions,
	])
}

const all: AppPermissionGroup = {
	name: 'All Permissions',
	description: 'All permissions in the application.',
	primaryColorName: ColorName.SkyBlue,
	permissions: Object.values(P_),
}

export const staff: AppPermissionGroup = deepFreeze({
	name: "Staff",
	description: "Basic set of permissions for all staff members.",
	primaryColorName: ColorName.Emerald,
	permissions: [
		P_.startChatWithStaff,
	]
})

export const admin: Readonly<AppPermissionRole> = deepFreeze({
	name: "Administrator",
	roleName: AppStaffRole.admin,
	description: "The administrator has all roles and permissions available. In layman’s terms, they have access to everything in the application.",
	primaryColorName: ColorName.Firebrick,
	permissions: [
		...all.permissions,
	]
})

export const baseCounsellor: Readonly<AppPermissionRole> = deepFreeze({
	name: "Counsellor",
	roleName: AppStaffRole.counsellor,
	description: "The core purpose of this role is to provide and facilitate counselling for clients. Counsellors have access to client profiles, surveys, customer support, and their own staff payments. A counsellor has most permissions except content moderation, overall staff payment management, data analysis, and application management.",
	Description: <>
		<p>There are three types of counsellors:</p>
		<ul>
			<li>Accredited</li>
			<li>Pre-accredited</li>
			<li>Clinical Placement</li>
		</ul>
		<BaseSpacer size="sm" />
		<p>Accredited and Pre-accredited counsellors can only manage paid counselling, while clinical placement counsellors can only manage free counselling. All three types also differ in how billing and payment are handled.</p>
	</>,
	primaryColorName: ColorName.brightPurple,
	permissions: [
		...staff.permissions,
		// ...staffCoordination.permissions, // has manageStaff
		P_.viewAnyChatHistory,
		// P_.coordinateCounsellingApplications,
		// P_.coordinateCounsellingAvailabilities,
		// P_.coordinateCounsellingSessions,
		...clientManagementUserGroups.permissions,
		...clientManagementBasic.permissions,
		...clientManagementAdvanced.permissions,
		// ...counsellingServiceProvider.permissions,
		P_.provideCounselling,
		P_.billPaidCounsellingSessions,
		// ...supportGroupFacilitation.permissions, // has clientManagementBasic.perms
		P_.useVoipFeature,
	]
})

export const preAccreditedCounsellor: Readonly<AppPermissionRole> = deepFreeze({
	name: "Pre Accredited Counsellor",
	roleName: AppStaffRole.preAccreditedCounsellor,
	description: "Able to manage both paid and free counselling.",
	primaryColorName: ColorName.brightPurple,
	permissions: [
		...baseCounsellor.permissions,
		P_.coordinatePaidCounsellingAvailabilities,
	]
})

export const accreditedCounsellor: Readonly<AppPermissionRole> = deepFreeze({
	name: "Accredited Counsellor",
	roleName: AppStaffRole.accreditedCounsellor,
	description: "Able to manage both paid and free counselling.",
	primaryColorName: ColorName.brightPurple,
	permissions: [
		...preAccreditedCounsellor.permissions,
	]
})

export const placementCounsellor: Readonly<AppPermissionRole> = deepFreeze({
	name: "Clinical Placement Counsellor",
	roleName: AppStaffRole.placementCounsellor,
	description: "Able to manage free counselling.",
	primaryColorName: ColorName.brightPurple,
	permissions: [
		...baseCounsellor.permissions,
		P_.coordinateFreeCounsellingAvailabilities,
	]
})

export const coordinator: Readonly<AppPermissionRole> = deepFreeze({
	name: "Coordinator",
	roleName: AppStaffRole.coordinator,
	description: "Organises and facilitates counselling applications, availability, sessions and support groups. A coordinator is a Counsellor without access to the client data and the added control of scheduling applications/groups to the public. A coordinator has most permissions except content moderation, staff payments and data analysis.",
	primaryColorName: ColorName.DeepPurple,
	permissions: uniq([
		...staff.permissions,
		// ...staffCoordination.permissions, // has manageStaff
		P_.viewAnyChatHistory,
		P_.coordinateCounsellingApplications,
		P_.coordinateFreeCounsellingAvailabilities,
		P_.coordinatePaidCounsellingAvailabilities,
		P_.coordinateCounsellingSessions,
		P_.coordinateSupportGroups,
		...clientManagementUserGroups.permissions,
		...clientManagementBasic.permissions,
		...clientManagementAdvanced.permissions,
		// ...counsellingServiceProvider.permissions,
		P_.provideCounselling,
		P_.billPaidCounsellingSessions,
		// ...supportGroupFacilitation.permissions, // has clientManagementBasic.perms
		P_.facilitateSupportGroups,
		P_.useVoipFeature,
		P_.manageSupportGroupTopics,
		...surveyManagement.permissions,
		// ...customerSupport.permissions, // has clientManagementBasic.perms
		P_.manageContactForms,
	])
})

export const facilitator: Readonly<AppPermissionRole> = deepFreeze({
	name: "Facilitator",
	roleName: AppStaffRole.facilitator,
	description: "Assists counsellors or coordinators in support group facilitation. A facilitator has most permissions except client counselling, content moderation, staff coordination, staff payments and data analysis.",
	primaryColorName: ColorName.SkyBlue,
	permissions: uniq([
		...staff.permissions,
		...clientManagementUserGroups.permissions,
		...clientManagementBasic.permissions,
		...clientManagementAdvanced.permissions,
		// ...supportGroupFacilitation.permissions, // has clientManagementBasic.perms
		P_.coordinateSupportGroups,
		P_.facilitateSupportGroups,
		P_.manageSupportGroupTopics,
		P_.useVoipFeature,
		...surveyManagement.permissions,
		// ...customerSupport.permissions, // has clientManagementBasic.perms
		P_.manageContactForms,
	])
})

export const moderator: Readonly<AppPermissionRole> = deepFreeze({
	name: "Moderator",
	roleName: AppStaffRole.moderator,
	description: "Moderates user content such as thoughts and comments. Has access to essential client management, plus the ability to mute or deactivate clients (not staff).",
	primaryColorName: ColorName.BlueGreen,
	permissions: uniq([
		...staff.permissions,
		...clientManagementUserGroups.permissions,
		P_.manageGlobalSettings,
		P_.manageAllUsers,
		...clientManagementBasic.permissions,
		...clientManagementAdvanced.permissions,
		...userAndThoughtCatcherModeration.permissions,
	])
})

export const analyst: Readonly<AppPermissionRole> = deepFreeze({
	name: "Analyst",
	roleName: AppStaffRole.analyst,
	description: "Only has access to analyse non-clinical surveys and statistics.",
	primaryColorName: ColorName.Navy,
	permissions: uniq([
		...staff.permissions,
		...surveyManagement.permissions,
		...dataAnalysis.permissions
	])
})

export const financialAdministrator: Readonly<AppPermissionRole> = deepFreeze({
	name: "Financial Administrator",
	roleName: AppStaffRole.financialAdministrator,
	description: "A financial admin oversees the finance-related activities of the app. Has access to view staff and their profiles, view statistics, manage donations and manage to invoice.",
	primaryColorName: ColorName.Navy,
	permissions: uniq([
		...staff.permissions,
		P_.manageStaff,
		...payments.permissions,
		...dataAnalysis.permissions,
	])
})

export const AppPermissionRoles: ReadonlyArray<AppPermissionRole> = deepFreeze([
	admin,
	coordinator,
	facilitator,
	baseCounsellor,
	accreditedCounsellor,
	preAccreditedCounsellor,
	placementCounsellor,
	moderator,
	analyst,
	financialAdministrator,
])

export const AppPermissionGroups = deepFreeze({
	all,
	clientManagementUserGroups,
	clientManagementBasic,
	clientManagementAdvanced,
	counsellingServiceProvider,
	supportGroupFacilitation,
	surveyManagement,
	customerSupport,
	userAndThoughtCatcherModeration,
	dataAnalysis,
	payments,
	staffCoordination,
	generalAdministration,
})

/** @deprecated Use role decider by roles. This was previously used and tends to produce too many ColorTag roles for a user with many permissions. */
export const RoleDeciderByPermissions: { [k in AppStaffRole]: P_[] } = {
	[AppStaffRole.admin]: [
		P_.manageAllUsers,
	],
	[AppStaffRole.counsellor]: [
		P_.provideCounselling,
	],
	[AppStaffRole.facilitator]: [
		P_.facilitateSupportGroups,
	],
	[AppStaffRole.moderator]: [
		P_.moderateThoughtCatcher,
	],
	[AppStaffRole.coordinator]: [
		P_.coordinateCounsellingApplications,
		P_.coordinateFreeCounsellingAvailabilities,
		P_.coordinatePaidCounsellingAvailabilities,
		P_.coordinateCounsellingSessions,
		P_.coordinateSupportGroups,
	],
	[AppStaffRole.analyst]: [
		P_.accessStatsCounsellors,
		P_.accessStatsDemography,
		P_.accessStatsDonations,
		P_.accessStatsOnlineCounselling,
		P_.accessStatsSatisfactionSurveys,
		P_.accessStatsSupportGroups,
		P_.accessStatsThoughtCatcher,
		P_.accessStatsUsers,
	],
	[AppStaffRole.financialAdministrator]: [
		P_.manageStaffInvoicing,
		P_.manageDonations,
	],
	[AppStaffRole.accreditedCounsellor]: [
		P_.coordinatePaidCounsellingAvailabilities,
	],
	[AppStaffRole.preAccreditedCounsellor]: [
		P_.coordinatePaidCounsellingAvailabilities,
	],
	[AppStaffRole.placementCounsellor]: [
		P_.coordinateFreeCounsellingAvailabilities,
	],
}

export const RoleDeciderByRoles: { [k in AppStaffRole]: AppStaffRole[] } = {
	[AppStaffRole.admin]: [
		AppStaffRole.admin,
	],
	[AppStaffRole.counsellor]: [
		AppStaffRole.counsellor,
		AppStaffRole.accreditedCounsellor,
		AppStaffRole.preAccreditedCounsellor,
		AppStaffRole.placementCounsellor,
	],
	[AppStaffRole.accreditedCounsellor]: [
		AppStaffRole.accreditedCounsellor,
	],
	[AppStaffRole.preAccreditedCounsellor]: [
		AppStaffRole.preAccreditedCounsellor,
	],
	[AppStaffRole.placementCounsellor]: [
		AppStaffRole.placementCounsellor,
	],
	[AppStaffRole.facilitator]: [
		AppStaffRole.facilitator,
	],
	[AppStaffRole.moderator]: [
		AppStaffRole.moderator,
	],
	[AppStaffRole.coordinator]: [
		AppStaffRole.coordinator,
	],
	[AppStaffRole.analyst]: [
		AppStaffRole.analyst,
	],
	[AppStaffRole.financialAdministrator]: [
		AppStaffRole.financialAdministrator,
	],
}
