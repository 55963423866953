import { action } from "mobx";

export const makeDisposerController = () => {
  const disposers: Function[] = [];
  const s = {
    disposed: false,
    add: (fn: Function) => {
      if (s.disposed) {
        console.warn('A new function has been added to disposer controller after its disposal. The function will be executed immediately. If you need to restart using this disposerController, please call the reset() method to reset the state of the disposerController.');
        fn();
      }
      else disposers.push(fn);
    },
    disposer: action(() => {
      s.disposed = true;
      disposers.forEach(d => d());
    }),
    reset: action(() => {
      s.disposed = false;
    })
  }
  return s;
}