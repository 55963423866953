import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import './AdminGreetingWithDate.scss';

interface AdminGreetingWithDateProps {}

const AdminGreetingWithDate: React.FC<AdminGreetingWithDateProps> = props => {

  const now = moment();
  const todayWeekdayFormatted = now.format('dddd');
  const todayDateFormatted = now.format('D MMMM');

  return <Observer children={() => (
    <span className="AdminGreetingWithDate"><strong>{todayWeekdayFormatted}</strong>, <span>{todayDateFormatted}</span></span>
  )} />
}

export default AdminGreetingWithDate;