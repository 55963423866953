import { Observer } from 'mobx-react-lite';
import React from 'react';
import { BooleanTransformerOptionType, transformBoolean } from '../../utils/boolean.utils';
import joinClassName from '../../utils/className.utils';

interface BooleanRendererProps extends Omit<BooleanTransformerOptionType, 'wrapInTag'>{
  className?: string,
  value?: any,
}

const BooleanRenderer: React.FC<BooleanRendererProps> = props => {
  return <Observer children={() => (
    <span className={joinClassName('BooleanRenderer', props.className)} data-value={props.value}>
      { transformBoolean(props.value, props)}
    </span>
  )} />
}

export default BooleanRenderer;