import React from "react";
import { UIController } from "../controllers/ui.controller";
import { User } from "../models/makeUser.model";
import OverlayUserEditor from "../modules/Admin/_components/OverlayUserEditor/OverlayUserEditor";

export const editUserInOverlay = (
  UI: UIController,
  user?: User,
  userId?: string,
) => {
  UI.OVERLAY.present({
    name: `OverlayUserEditor#${user?.id || userId}`,
    component: <OverlayUserEditor user={user} userId={userId}/>,
    noBackdrop: true,
  })
}