import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { ColorName, ContextColor } from '../../constants/color.enum';
import { useControllers } from '../../hooks/useRootController.hook';
import { isString } from '../../utils/typeChecks.utils';
import { popUrl } from '../../utils/url.utils';
import { useGetUrlParams } from '../../utils/urlParams.utils';
import BaseIcon from '../BaseIcon/BaseIcon';
import { useTwoColumnViewContext } from '../TwoColumnView/TwoColumnView';
import './BackButton.scss';

interface BackButtonProps {
  destination?: 'back' | 'up' | string,
  primaryColorName?: ColorName | ContextColor,
}

const BackButton: React.FC<BackButtonProps> = props => {
  const { UI, NAVIGATOR } = useControllers();
  const { origin } = useGetUrlParams();
  const history = useHistory();
  const location = useLocation();
  const twoColumnViewContext = useTwoColumnViewContext();
  const goBack = () => {
    if (origin) NAVIGATOR.navigateTo(origin);
    if (twoColumnViewContext.state) {
      twoColumnViewContext.state.animateOutAndGoUp();
      return;
    }
    else if (props.destination === 'back') history.goBack();
    else if (props.destination === 'up') {
      NAVIGATOR.navigateTo(popUrl(location.pathname));
    }
    else if (isString(props.destination)) NAVIGATOR.navigateTo(props.destination);
  }
  return UI.fromTablet ? null : <button className="BackButton" onClick={goBack} data-primary-color={props.primaryColorName}>
    <BaseIcon name="back" variant="filled" size="1.6rem" />
  </button>
}

export default BackButton;