import { Observer } from 'mobx-react-lite';
import React from 'react';
import ChatWindowStack from '../../../components/ChatWindow/ChatWindowStack';
import VoipLiveCallToast from '../../../components/VoipLiveCallToast/VoipLiveCallToast';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useObservableRef } from '../../hooks/useObservableRef.hook';
import { useControllers, useRootController } from '../../hooks/useRootController.hook';
import { warnOutdatedBrowser } from '../../utils/browsers.utils';
import { immediateReaction, useStore } from '../../utils/mobx.utils';
import BrowserSeverelyOutdatedNotice from '../BrowserSeverelyOutdatedNotice/BrowserSeverelyOutdatedNotice';
import DeviceOfflineNotice from '../DeviceOfflineNotice/DeviceOfflineNotice';
import { DialogStack } from '../Dialog/DialogStack';
import LoadingBlocker from '../LoadingBlocker/LoadingBlocker';
import OverlayStack from '../Overlay/OverlayStack';
import RouterSwitch from '../RouterSwitch/RouterSwitch';
import { ToastStack } from '../ToastStack/ToastStack';
import './AppContainer.scss';

interface AppContainerProps {}

const AppContainer: React.FC<AppContainerProps> = React.memo(props => {

  const ROOT = useRootController();
  const { UI, NAVIGATOR, MESSENGER, AUTH } = useControllers();
  const appContainerRef = useObservableRef<HTMLDivElement>();
  const portalRef = useObservableRef<HTMLDivElement>();

  useOnMount(() => {
    UI.registerAppContainerRef(appContainerRef);
    UI.PORTAL.registerRef(portalRef);
    immediateReaction(
      () => UI.preferDarkTheme,
      () => {
        const html = document.documentElement;
        if (UI.preferDarkTheme) html.classList.add('dark-theme')
        else html.classList.remove('dark-theme');
      }
    )
    if (UI.browserIsSeverelyOutdated) {
      warnOutdatedBrowser(UI);
    }
  });

  const s = useStore(() => ({
    get isInAdminOrClientModule() {
      return NAVIGATOR.isInAdminArea || NAVIGATOR.isInClientArea
    },
  }))

  return <Observer children={() => (
    <div
      className='AppContainer'
      ref={appContainerRef}
      data-authenticated={AUTH.isAuthenticated}
    >
      {ROOT.ready
        ? <RouterSwitch routes={NAVIGATOR.routes} />
        : <LoadingBlocker />
      }
      <DialogStack />
      <ToastStack />
      <div className="AppPortal" ref={portalRef} />
      <OverlayStack />
      {
        s.isInAdminOrClientModule && UI.PORTAL.render(<>
          { UI.fromTablet && <ChatWindowStack
            mode='docked'
            chats={MESSENGER.dockedChats}
            focusedChats={MESSENGER.openedDockedChats}
          /> }
          <VoipLiveCallToast />
        </>)
      }
      <DeviceOfflineNotice />
      { UI.browserIsSeverelyOutdated && <BrowserSeverelyOutdatedNotice /> }
    </div>
  )} />
})

export default AppContainer;