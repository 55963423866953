import { Nillable } from "../@types";
import { isNil, last } from "./ramdaEquivalents.utils";

export function removeTrailingSlashes(url: string | undefined) {
  return url?.replace(/\/*$/, "");
} 

export const removeRepeatedSlashes = (url: string) => {
  const result = (url).replace(/\/+/g, '/')
  return result.length > 1 ? result.replace(/\/$/, '') : result;
}

/**
 * Join valid url snippets into one single string.
 * @param snippets levels of the url as an array of strings.
 */
export const makeUrl = (
  ...snippets: Nillable<string | false>[]
) => removeRepeatedSlashes(snippets.filter(s => !isNil(s) && s !== false).join('/')).replace(/https?:\//, 'https://');

/**
 * pop 🍾 the lowest level of URL path off the stack.
 * e.g. /admin/settings => /admin
 */
export const popUrl = (url: string) => {
  const urlSnippets = url.split('/');
  if (last(urlSnippets) === '') urlSnippets.pop();
  urlSnippets.pop();
  return urlSnippets.join('/'); 
}
