import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Alert } from '../../@types/alert.types';
import ToastCard from '../../base/components/ToastCard/ToastCard';
import Toast from '../../base/constructors/toast.constructor';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { makeToastConfigFromAlert } from '../../controllers/alert/makeToastConfigFromAlert';
import { IToast } from '../../controllers/ui/ui.controller.types';
import './AlertCard.scss';

interface AlertCardProps {
  alert: Alert;
  withColor?: boolean;
}

const AlertCard: React.FC<AlertCardProps> = props => {

  const { NAVIGATOR, ALERTS } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    toast: null as IToast<any> | null,
    handleDismiss: () => {
      ALERTS.dismiss(p.alert);
    },
    markAsRead: () => {
      ALERTS.markAsRead(p.alert);
    },
    init: flow(function * () {
      s.toast = new Toast(yield makeToastConfigFromAlert(p.alert, NAVIGATOR, ALERTS, props.withColor))
    })
  }));

  useOnMount(() => {
    s.init();
  })

  return <Observer children={() => (
    s.toast ? <div className="AlertCard" onClick={s.markAsRead} data-type={p.alert.type}>
      <ToastCard toast={s.toast} onDismiss={s.handleDismiss} />
    </div> : null
  )} />
}

export default AlertCard;