import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useCreateResizeQueryWithRef } from '../../../../../base/hooks/useCreateResizeQueryWithRef.hook';
import { useStore } from '../../../../../base/utils/mobx.utils';
import './StatDataItemGrid.scss';

interface StatDataItemGridProps {
  maxColumns?: 2 | 3 | 4 | 6,
}

const StatDataItemGrid: React.FC<StatDataItemGridProps> = p => {
  const { ref, query } = useCreateResizeQueryWithRef();
  const s = useStore(() => ({
    get maxColumnDueToWidth() {
      if (query.fromDesktop) return 6;
      if (query.fromPhoneLg) return 4;
      return 2;
    },
    get maxColumns() {
      return Math.min(s.maxColumnDueToWidth, p.maxColumns || 6);
    },
    get innerStyle() {
      return {
        gridTemplateColumns: `repeat(${s.maxColumns}, 1fr)`
      }
    }
  }))
  return <Observer children={() => (
    <div className="StatDataItemGrid" ref={ref} data-width={query.width}>
      <div className="StatDataItemGridInner" style={s.innerStyle}>
        {p.children}
      </div>
    </div>
  )} />
}

export default StatDataItemGrid;