import { Observer } from 'mobx-react-lite';
import React from 'react';
import ExportButton from '../../../components/ExportButton/ExportButton';
import { ActionConfig } from '../../../controllers/ui/ui.controller.types';
import { AnyObject, Nillable, Renderable, SortDirection, StringKeyOf } from '../../@types';
import joinClassName from '../../utils/className.utils';
import { DefaultDataRendererSetType } from '../../utils/dataRenderers.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import './BaseTable.scss';
import BaseTableBody from './BaseTableBody';
import BaseTableColGroup from './BaseTableColGroup';
import BaseTableHead from './BaseTableHead';

export type BaseTableCellDataType = 'string' | 'number' | (keyof DefaultDataRendererSetType & string);
export type BaseTableCellHorizontalAlignType = 'left' | 'center' | 'right';
export type BaseTableCellVerticalAlignType = 'top' | 'middle' | 'bottom';
export type BaseTableCellContentType = string | number | boolean | undefined | React.ReactElement;

export type BaseTableColumnConfig<DataType extends AnyObject> = {
  label?: Renderable,
  keyName?: StringKeyOf<DataType>,
  searchFilterLabel?: Nillable<string>,
  searchable?: boolean | (keyof DataType & string) | string,
  type?: BaseTableCellDataType,
  dataCy?: string,
  sortable?: boolean | (keyof DataType & string) | string,
  HeadCell?: (data?: DataType[]) => BaseTableCellContentType,
  BodyCell?: (data: DataType) => BaseTableCellContentType,
  headCellStyleFactory?: () => React.CSSProperties,
  bodyCellStyleFactory?: (data: DataType) => React.CSSProperties,
  hiddenFromTable?: boolean,
}

export type BaseTableExportConfig = {
  allowExport?: boolean,
  filename?: string,
  allowedHeaders: string[],
}

export interface BaseTableAppearanceOptions {
  showRowIndex?: boolean,
}

export interface BaseTableProps<DataType extends AnyObject = {}> {
  className?: string,
  exportConfig?: BaseTableExportConfig,
  columnConfigs: BaseTableColumnConfig<DataType>[],
  entries: DataType[],
  itemPrimaryKey?: (keyof DataType & string) | string,
  itemActions?: ActionConfig<(d: DataType) => any>[],
  tableFoot?: React.ReactNode,
  // tableFoot?: React.ReactChildren,
  appearanceOptions?: BaseTableAppearanceOptions,
  fillToNumberOfRows?: number;
  sortByKeyName?: (keyof DataType & string) | null;
  sortDirection?: Nillable<SortDirection>;
  emptyMessage?: string | React.ReactChild | React.FC | React.ComponentClass,
  bodyRowStyleFactory?: (data: DataType) => React.CSSProperties | undefined,
  onSort?: (
    sortByKeyName?: (keyof DataType & string) | null,
    sortDirection?: SortDirection
  ) => void,
}

export function getKeyNameFromColumnConfig<T extends AnyObject = {}>(c: BaseTableColumnConfig<T>) {
  return ((typeof c.sortable === 'string' && c.sortable) || c.keyName || c.type) + '';
}
export function getSearchByKeyNameFromColumnConfig<T extends AnyObject = {}>(c: BaseTableColumnConfig<T>) {
  return ((typeof c.searchable === 'string' && c.searchable) || c.keyName || c.type) + '';
}

const BaseTable = <T extends object = {}>(
  props: React.PropsWithChildren<BaseTableProps<T>>
) => {
  const p = useProps(props);
  const s = useStore(() => ({
    get columnConfigs() {
      return p.columnConfigs;
    },
    get tableFoot() {
      return p.tableFoot;
    },
    get itemPrimaryKey() {
      return p.itemPrimaryKey;
    },
  }))
  return <Observer children={() => (
    <div className={joinClassName(
      'BaseTable',
      p.className,
    )}>
      <div className='BaseTableControls'>
        {p.exportConfig?.allowExport && <ExportButton data={p.entries} {...p.exportConfig} />}
      </div>
      <table className='BaseTableTable'>
        <BaseTableColGroup<T> {...p} />
        <BaseTableHead<T> {...p} />
        <BaseTableBody<T> {...p} />
        {
          s.tableFoot && (
            <tfoot className="BaseTableFoot">
              {s.tableFoot}
            </tfoot>
          )
        }
      </table>
    </div>
  )} />
}

export default BaseTable;