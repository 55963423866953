import { Observer } from 'mobx-react-lite';
import React from 'react';
import joinClassName from '../../utils/className.utils';
import './BaseButtonGroup.scss';

interface BaseButtonGroupProps {
  className?: string,
  flex?: boolean;
}

const BaseButtonGroup: React.FC<BaseButtonGroupProps> = props => {
  return <Observer children={() => (
    <div className={joinClassName('BaseButtonGroup', props.className, props.flex && 'flex')}>
      { props.children}
    </div>
  )} />
}

export default BaseButtonGroup;