import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import InfoBanner from '../../base/components/InfoBanner/InfoBanner';
import LoadingIndicatorSection from '../../base/components/LoadingIndicatorSection/LoadingIndicatorSection';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { useCurrentUserIsAdult } from '../../hooks/useCurrentUserAge.hook';
import './MessengerPanel.scss';
import MessengerPanelSessionList from './MessengerPanelSessionList';
import MessengerPanelVoipCallList from './MessengerPanelVoipCallList';

interface MessengerPanelProps {
  prefix?: string,
}

function MessengerPanel(props: React.PropsWithChildren<MessengerPanelProps>) {

  const p = useProps(props);

  const { MESSENGER: M, AUTH } = useControllers();

  const s = useStore(() => {
    return {
      get messengerReady() {
        return Boolean(M.activeChatsRetrieved || M.errorRetrievingChats)
      },
      get isAdminOrCounsellor() {
        return AUTH.isStaff || AUTH.isCounsellor;
      },
      get canStartNewPrivateThread() {
        return AUTH.canFacilitateChat;
      },
      get shouldShowVoipSection() {
        return AUTH.can.useVoip;
      },
    }
  });

  const currentUserIsAdult = useCurrentUserIsAdult();

  return <Observer children={() => (
    AUTH.isAuthenticated ? (
      <section className='MessengerPanel'>
        <div className="MessengerPanelInner">
          <header className="MessengerPanelHeader">
            <h1 className="MessengerPanelTitle">Chats</h1>
            <div className="MessengerPanelHeaderControls">
              {s.canStartNewPrivateThread && <BaseButton dataCy="newChat" className="subtle" icon="plus" size="sm" onClick={() => M.createNewInstantChat()}>New Chat</BaseButton>}
            </div>
          </header>
          <div className="MessengerPanelContent">
            { s.shouldShowVoipSection && <MessengerPanelVoipCallList /> }
            { !s.messengerReady && <LoadingIndicatorSection /> }
            {
              M.errorRetrievingChats && <div className="MessengerPanelContentErrorContainer">
                <InfoBanner colorCodedState={ColorCodedState.error} icon="warning" heading="Error loading chats">
                  <ErrorRenderer error={M.errorRetrievingChats} />
                  <BaseButton onClick={M.getActiveThreads}>Retry</BaseButton>
                </InfoBanner>
              </div>
            }
            {
              (M.activeChatsRetrieved || M.chats.length > 0) && <>
                <MessengerPanelSessionList
                  title="Chat Sessions"
                  sessions={M.instantChats}
                  prefix={p.prefix}
                />
                {M.counsellingSessionChats.length > 0 && <MessengerPanelSessionList
                  title="Counselling Sessions"
                  sessions={M.counsellingSessionChats}
                  contextColorAlias="brightPurple"
                  prefix={p.prefix}
                />}
                {M.supportGroupChats.length > 0 && <MessengerPanelSessionList
                  title={currentUserIsAdult ? 'Support Groups' : 'Support Groups'}
                  sessions={M.supportGroupChats}
                  contextColorAlias="blueGreen"
                  prefix={p.prefix}
                />}
              </>
            }
          </div>
        </div>
      </section>
    ) : <div className="MessengerPanelPlaceholder" />
  )} />
}

export default MessengerPanel;