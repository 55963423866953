import { Observer } from 'mobx-react-lite';
import React from 'react';
import { StringKeyOf } from '../../@types';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { isNil } from '../../utils/ramdaEquivalents.utils';
import { getRandomNumericString } from '../../utils/random.utils';
import { BaseRadio } from '../BaseRadio/BaseRadio';
import './BaseRadioGroup.scss';

export type BaseRadioConfig = {
  name?: string,
  value: any,
  label: string | React.ReactElement,
  disabled?: boolean,
  color?: string,
}
export interface BaseRadioGroupProps<FormType = object> {
  className?: string;
  name?: string;
  options: BaseRadioConfig[];
  form: FormType;
  field: StringKeyOf<FormType>;
  inline?: any;
  disabled?: any;
  nullable?: any;
  dataCyPrefix?: string;
  readonly?: boolean,
}

const BaseRadioGroup = <T extends object = object>(props: React.PropsWithChildren<BaseRadioGroupProps<T>>) => {

  const p = useProps(props);

  const s = useStore(() => ({
    id: getRandomNumericString(6),
    get nullable() {
      return p.nullable ?? true;
    },
    get readonly() {
      return p.readonly ?? false;
    },
    get value() {
      return p.form[p.field];
    },
    set value(v: any) {
      if (s.readonly) return;
      if (isNil(v) && !s.nullable) return;
      p.form[p.field] = v;
    },
    get name(): string {
      return (p.name || p.field || `radio-${s.id}`).toString();
    }
  }));

  return <Observer children={() => {
    const inline = p.inline ?? true;
    return <div className={joinClassName(
      'BaseRadioGroup',
      p.className,
      inline && 'inline',
      p.disabled && 'disabled',
    )} data-value={p.form[p.field]}>
      {
        p.options.map((radio, i) => <BaseRadio
          form={s}
          field='value'
          label={radio.label}
          radioValue={radio.value}
          key={radio.value + '_' + i}
          disabled={p.disabled || radio.disabled}
          color={radio.color}
          readonly={s.readonly}
          nullable={p.nullable}
          name={s.name}
          dataCy={`${p.dataCyPrefix ?? s.name}-${radio.value}`}
        />)
      }
    </div>
  }} />
}

export default BaseRadioGroup;