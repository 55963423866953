import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { ColorCodedState } from '../../../../../base/@types';
import AppPage from '../../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../../base/components/AppPageHeader/AppPageHeader';
import BackButton from '../../../../../base/components/BackButton/BackButton';
import BaseButton from '../../../../../base/components/BaseButton/BaseButton';
import BaseSpacer from '../../../../../base/components/BaseSpacer/BaseSpacer';
import InfoBanner from '../../../../../base/components/InfoBanner/InfoBanner';
import UIBlock from '../../../../../base/components/UIBlock/UIBlock';
import { useControllers } from '../../../../../base/hooks/useRootController.hook';
import { makeForm } from '../../../../../base/mediators/form.mediator';
import { reportError } from '../../../../../base/utils/errors.utils';
import { useStore } from '../../../../../base/utils/mobx.utils';
import { copyWithJSON } from '../../../../../base/utils/object.utils';
import { validateEmail } from '../../../../../base/validators/email.validator';
import { validatePhone } from '../../../../../base/validators/phone.validator';
import UserInfoEditor from '../../../../../components/UserInfoEditor/UserInfoEditor';
import { saveCurrentUser } from '../../../../../requests/user.requests';
import { getAgeFromDateOfBirth } from '../../../../../utils/ageAndDateOfBirth.utils';

type AccountPageProfileEditorProps = {}

const AccountPageProfileEditor: React.FC<AccountPageProfileEditorProps> = props => {

	const { AUTH, API, UI } = useControllers();

	const s = useStore(() => ({
		originalCopy: copyWithJSON(AUTH.currentUser!.$getSnapshot()),
		form: makeForm(AUTH.currentUser!.$getSnapshot()),
		get hasValidEmail() {
			return validateEmail(s.form.value.email) === true;
		},
		get hasValidPhone() {
			return validatePhone(s.form.value.mobileNumber) === true;
		},
		get hasAtLeastValidEmailOrPhone() {
			return (s.form.value.email && s.hasValidEmail) || (s.form.value.mobileNumber && s.hasValidPhone);
		},
		get dobIsValid() {
			if (!s.form.value.dateOfBirth || s.form.value.dateOfBirth.length < 8) return false;
			const m = moment(s.form.value.dateOfBirth);
			if (!m.isValid()) return false;
			if (!s.age) return false;
			if (s.age > 150) return false;
			if (s.age < 0) return false;
			return true;
		},
		get age() {
			if (!s.form.value.dateOfBirth || s.form.value.dateOfBirth.length < 8) return null;
			return getAgeFromDateOfBirth(s.form.value.dateOfBirth, true);
		},
		get isUnder12() {
			return s.age && s.age < 12;
		},
		get formIsValid() {
			return s.form.value.username &&
				s.dobIsValid &&
				s.hasAtLeastValidEmailOrPhone &&
				(s.form.value.countryDetectedId || s.form.value.countryProvidedId);
		},
		get nameIsComplete() {
			return s.form.value.firstName && s.form.value.lastName;
		},
		get verifiedEmailHasChanged() {
			return AUTH.currentUser?.timeVerifiedEmail && (s.originalCopy.email !== s.form.value.email);
		},
		get verifiedPhoneHasChanged() {
			return AUTH.currentUser?.timeVerifiedMobileNumber && (s.originalCopy.mobileNumber !== s.form.value.mobileNumber);
		},
	}));

	const save = async () => {
		if (!s.dobIsValid) {
			UI.DIALOG.attention({
				heading: "Please double check the date of birth.",
			});
			return;
		}
		if (s.isUnder12) {
			UI.DIALOG.attention({
				heading: "turn2me users must be at least 12 years old.",
			});
			return;
		}
		const userSnapshot = s.form.value;
		if (s.verifiedEmailHasChanged && s.verifiedPhoneHasChanged) {
			const confirm = await UI.DIALOG.attention({
				heading: 'Updating your email and mobile number will require you to verify them again.',
				defaultActions: ['negative', 'positive'],
			})
			if (!confirm) return;
		} else if (s.verifiedEmailHasChanged) {
			const confirm = await UI.DIALOG.attention({
				heading: 'Updating your email will require you to verify it again.',
				defaultActions: ['negative', 'positive'],
			})
			if (!confirm) return;
		} else if (s.verifiedPhoneHasChanged) {
			const confirm = await UI.DIALOG.attention({
				heading: 'Updating your mobile number will require you to verify it again.',
				defaultActions: ['negative', 'positive'],
			})
			if (!confirm) return;
		}
    try {
			const savedUser = await saveCurrentUser(API, userSnapshot);
			if (!savedUser) {
				UI.DIALOG.attention({
					heading: `Something wasn't quite right...`,
					body: 'Your changes might not have been saved correctly; please refresh the page and try again.',
				})
				return;
			}
			s.form.reset(savedUser.$snapshot);
			s.originalCopy = copyWithJSON(savedUser.$snapshot);
			UI.TOAST.success('Your profile has been saved!');
		} catch(e) {
			reportError(e);
			UI.DIALOG.error({
				heading: "Failed to update your profile info...",
				error: e,
			})
		}
	}
	
	return <Observer children={() => (
		<AppPage 
			className="AccountPageProfileEditor"
			accommodateTitleBarSpace
		>
			<AppPageHeader
				title="Edit Profile"
				startSlot={<BackButton destination="up" />}
			/>
			<AppPageContent>
				<UIBlock padded spaceChildren>
					{AUTH.currentUser && <UserInfoEditor form={s.form} canEditColor showMoreOptionsSection />}
					{
						!s.hasAtLeastValidEmailOrPhone && <InfoBanner colorCodedState={ColorCodedState.attention}>Your turn2me account needs at least a valid email address or phone.</InfoBanner>
					}
					{
						!s.form.value.dateOfBirth && <InfoBanner colorCodedState={ColorCodedState.attention}>Date of Birth is required information as turn2me has separate services for adults and young people.</InfoBanner>
					}
					{
						!s.nameIsComplete && <InfoBanner colorCodedState={ColorCodedState.attention}>Your real names are optional for basic turn2me services, however you will be required to provide them if you access any clinical services. Only turn2me's internal staff will be able to see your real name; other users on the site can only see your username.</InfoBanner>
					}
					<BaseButton dataCy="profile-editor-save-profile-button" size="lg" fullWidth onClick={save} disabled={!s.formIsValid}>Save Profile</BaseButton>
				</UIBlock>
				<BaseSpacer />
			</AppPageContent>
		</AppPage>
	)} />
}

export default AccountPageProfileEditor;