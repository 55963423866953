export const ethnicityDescriptors = [
  {
    name: 'Prefer not to say',
    abbr: 'Not given',
    value: 'prefer-not-to-say',
  },
  {
    name: 'Irish',
    abbr: 'Irish',
    value: 'irish',
  },
  {
    name: 'English, Welsh, Scottish, Northern Irish or British',
    abbr: 'British',
    value: 'british',
  },
  {
    name: 'Travelling / Roma',
    abbr: 'Travelling / Roma',
    value: 'travelling-or-roma'
  },
  {
    name: 'Any Other White Background',
    abbr: 'Other White',
    value: 'other-white'
  },
  {
    name: 'White and Black Caribbean',
    abbr: 'White & Black Caribbean',
    value: 'white-and-black-carribean',
  },
  {
    name: 'White and Black African',
    abbr: 'White & Black African',
    value: 'white-and-black-african',
  },
  {
    name: 'White and Asian',
    abbr: 'White & Asian',
    value: 'white-and-asian',
  },
  {
    name: 'Any other Mixed or Multiple ethnic background',
    abbr: 'Other Mixed',
    value: 'any-other-mixed',
  },
  {
    name: 'Indian',
    abbr: 'Indian',
    value: 'indian',
  },
  {
    name: 'Pakistani',
    abbr: 'Pakistani',
    value: 'pakistani',
  },
  {
    name: 'Bangladeshi',
    abbr: 'Bangladeshi',
    value: 'bangladeshi',
  },
  {
    name: 'Chinese',
    abbr: 'Chinese',
    value: 'chinese',
  },
  {
    name: 'Any other Asian background',
    abbr: 'Other Asian',
    value: 'other-asian',
  },
  {
    name: 'African',
    abbr: 'African',
    value: 'african',
  },
  {
    name: 'Caribbean',
    abbr: 'Caribbean',
    value: 'caribbean',
  },
  {
    name: 'Arab',
    abbr: 'Arab',
    value: 'arab',
  },
  {
    name: 'Any Other Ethnic Group',
    abbr: 'Others',
    value: 'others'
  }
] as const