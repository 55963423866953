import { Storage } from '@capacitor/storage';
import { action, observable, reaction } from 'mobx';
import { Undefinable } from '../base/@types';
import { reportError } from '../base/utils/errors.utils';
import tick from '../base/utils/waiters.utils';
import { SHOULD_LOG } from '../env';
import { CommonController } from './common.controller';
import { makeControllerBase, makeRootControllerChildInitFn } from './_root.controller';


/**
 * Mediates the app's access to local storage, using Capacitor's Storage plugin.
 * The keys for accessing / storing storage items are supplied by app as either strings or arrays of strings,
 * in order to make it easier to namespace storage content.
 * For example,
 * In a web browser, data typically gets stored into LocalStorage. However, it will behave differently if packaged as hybrid apps. Please check out Capacitor's documentation for further details.
 */
export type StorageController = ReturnType<typeof makeStorageController>;

export type StorageStoreSetterOptions = {
  expireAfter?: string,
}

export const makeStorageController = (
  appInstanceId: string = '',
  appShortName: string = 'app',
) => {

  const makeKey = (...keys: (string | string[])[]) => {
    return [appInstanceId, appShortName, ...keys.flat()].join(':');
  }

  const makePublicKey = (...keys: (string | string[])[]) => {
    return [appInstanceId, appShortName, 'PUBLIC', ...keys.flat()].join(':');
  }

  const encode = (input: any) => JSON.stringify(input);

  const decode = (input: string | null) => {
    if (typeof input === 'string') {
      try {
        return JSON.parse(input)
      } catch (e) {
        return input;
      }
    }
    return null;
  };

  const getKeysByNamespace = async (...keys: (string | string[])[]) => {
    const keyPartial = makeKey(...keys);
    const allKeys = await Storage.keys();
    const matchingKeys = allKeys.keys.filter(k => k.match(new RegExp(keyPartial)));
    return matchingKeys;
  }

  const store = async (key: string, valueSource: any, options: StorageStoreSetterOptions = {}) => {
    try {
      const value = encode(valueSource);
      return await Storage.set({ key, value });
    } catch (e) {
      reportError(e);
      return null;
    }
  }

  const c = observable({

    ...makeControllerBase('STORAGE'),

    get COMMON(): Undefinable<CommonController> {
      return c.ROOT?.children.COMMON;
    },

    get: async (...keys: (string | string[])[]) => {
      const key = makeKey(...keys.flat());
      const snapshot = await Storage.get({ key });
      if (!localStorage) {
        console.warn(`localStorage is not available when StorageController is trying to retrieve key "${key}". Waiting for one second before trying...`);
        await tick(1000);
        if (!localStorage) {
          console.warn('localStorage is still not available after one second. This is likely a bug.');
        }
      }
      try {
        const value = decode(snapshot.value);
        if (key.includes('Auth')) {
          // console.log(key, value);
          // if (value === null) debugger;
          // debugger;
        }
        if (value !== null) return value;
        const publicKey = makePublicKey(...keys.flat());
        const publicSnapshot = await Storage.get({ key: publicKey });
        const publicValue = decode(publicSnapshot.value);
        return publicValue ?? value;
      } catch (e) {
        reportError(e);
        return null;
      }
    },

    getByNamespace: async (...keys: (string | string[])[]) => {
      const keysToGet = await getKeysByNamespace(...keys);
      try {
        const promises = keysToGet.map(key => ({
          key,
          promise: Storage.get({ key }),
        }));
        const snapshots = await Promise.all(promises.map(p => p.promise));
        return snapshots.map((snapshot, i) => ({
          [promises[i].key]: decode(snapshot.value),
        })).reduce((a, b) => ({ ...a, ...b }), {});
      } catch (e) {
        return [];
      }
    },

    set: async (keys: string[], valueSource: any, options: StorageStoreSetterOptions = {}) => {
      const key = makeKey(...keys);
      return store(key, valueSource, options);
    },

    setPublic: async (keys: string[], valueSource: any, options: StorageStoreSetterOptions = {}) => {
      const key = makePublicKey(...keys);
      return store(key, valueSource, options);
    },

    remove: async (...keys: (string | string[])[]) => {
      const key = makeKey(...keys);
      const publicKey = makePublicKey(...keys);
      await Promise.all([
        Storage.remove({ key }),
        Storage.remove({ key: publicKey }),
      ])
      return;
    },

    removeByNamespace: async (...keys: (string | string[])[]) => {
      const key = makeKey(...keys);
      return Storage.remove({ key });
    },

    clear: () => new Promise<void>(async (resolve, reject) => {
      await Storage.clear();
      resolve();
    }),

    syncObservableValueToStorage: <T extends null | any>(
      keys: string[],
      getter: () => T,
      setter: (v: T) => void,
      options?: { clearOnLogout?: boolean, initialValue?: T }) => {
      const dispose = reaction(
        () => getter(),
        value => c.set(keys, value),
      )
      const syncState = observable({
        async getStoredValue() {
          return (await c.get(keys)) ?? options?.initialValue as T;
        },
        dispose,
      });
      return syncState;
    },

    clearNonPublic: () => new Promise<void>(async (resolve, reject) => {
      SHOULD_LOG() && console.log('clearing sensitive data');
      const allKeys = await Storage.keys();
      const publicKeys = allKeys.keys.filter(key => key.split(':')[1] === 'PUBLIC');
      const allPublicKeyValues = await Promise.all(publicKeys.map(key => Storage.get({ key })));
      const allPublicKeyValuesDecoded = allPublicKeyValues.map(v => decode(v.value));
      await Storage.clear();
      await Promise.all(publicKeys.map((key, i) => Storage.set({
        key,
        value: encode(allPublicKeyValuesDecoded[i])
      })));
      resolve();
    }),

    reset: () => {
      c.clearNonPublic();
    }

  })

  const watchForStorageChanges = () => {
    const handler = (e: StorageEvent) => {
      if (e.key?.match(/_cap_[^:]*::auth/)) {
        console.log(e);
        console.warn('Auth related storage key changed externally');
        if (e.key.includes('logout') && e.newValue === 'true') {
          const AUTH = c.ROOT?.children.AUTH;
          if (AUTH?.isAuthenticated) {
            AUTH.logout();
            window.removeEventListener('storage', handler);
          }
        }
      }
    }
    window.addEventListener('storage', handler);
  }

  c.init = makeRootControllerChildInitFn(
    c,
    action(() => {
      watchForStorageChanges();
      c.ready = true;
    })
  )

  return c;

}
