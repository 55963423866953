import { Observer } from 'mobx-react-lite';
import React from 'react';
import AppPage from '../../base/components/AppPage/AppPage';
import AppPageContent from '../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../base/components/AppPageHeader/AppPageHeader';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import ColorTagLive from '../../base/components/ColorTag/ColorTagLive';
import CommandList from '../../base/components/CommandList/CommandList';
import { CommandListItem } from '../../base/components/CommandList/CommandListItem';
import HeadingAllcaps from '../../base/components/HeadingAllcaps/HeadingAllcaps';
import InfoDisplayItem from '../../base/components/InfoDisplayList/InfoDisplayItem';
import InfoDisplayList from '../../base/components/InfoDisplayList/InfoDisplayList';
import OverlayCloseButton from '../../base/components/OverlayCloseButton/OverlayCloseButton';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import UIBlock from '../../base/components/UIBlock/UIBlock';
import { DefaultCounsellingSessionIncludesForClient } from '../../base/endpoints/counsellingSession.endpoints';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { nth } from '../../base/utils/array.utils';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { makeUrl } from '../../base/utils/url.utils';
import { getUrlParams, removeUrlParam, setUrlParam, useSyncUrlParams } from '../../base/utils/urlParams.utils';
import tick from '../../base/utils/waiters.utils';
import { communicationTypeDescriptors } from '../../constants/communicationTypes.descriptors';
import { getCounsellingTypeColorName } from '../../constants/counsellingTypes.descriptors';
import { ModelName } from '../../constants/modelNames.enum';
import { CounsellingApplication } from '../../models/makeCounsellingApplication.model';
import { CounsellingSession } from '../../models/makeCounsellingSession.model';
import { useGetCounsellingSession } from '../../requests/useGetCounsellingSession.request';
import { getApplicationTypeDescriptor } from '../../utils/counsellingApplication.utils';
import SessionOrGroupTimeDisplay from '../SessionOrGroupTimeDisplay/SessionOrGroupTimeDisplay';
import SessionSummaryAssignmentList from '../SessionSummaryCard/SessionSummaryAssignmentList';
import UserFullNameRenderer from '../UserFullNameRenderer/UserFullNameRenderer';
import './OverlaySessionDetails.scss';

type OverlaySessionDetailsProps = {
  sessionId: string,
}

const OverlaySessionDetails: React.FC<OverlaySessionDetailsProps> = props => {

  const { LOCALDB, UI, NAVIGATOR } = useControllers();

  const p = useProps(props);
  const s = useStore(() => ({
    get session() {
      return LOCALDB.get<CounsellingSession>(ModelName.counsellingSessions, p.sessionId);
    },
    get application() {
      return s.session?.application ?? (s.session?.applicationId ? LOCALDB.get<CounsellingApplication>(ModelName.counsellingApplications, s.session?.applicationId) : null);
    },
    get isNext() {
      return s.session && s.session.application?.nextSession === s.session;
    },
    get nth() {
      return nth(s.application?.sortedSessions, s.session);
    },
    get hasStarted() {
      return !!s.session?.timeStarted;
    },
    get hasEnded() {
      return !!s.session?.timeEnded;
    },
    get isLive() {
      return s.session?.isLive;
    },
    get threadId() {
      return s.session?.threadIds[0];
    },
    get shouldShowMoreOptions() {
      const { applicationId } = getUrlParams();
      if (applicationId === s.application?.id) return false;
      return true;
    }
  }));

  useGetCounsellingSession({}, p.sessionId, { include: DefaultCounsellingSessionIncludesForClient });

  useSyncUrlParams('sessionId', p.sessionId);

  const openChat = () => {
    UI.OVERLAY.dismiss();
    if (!s.threadId) return;
    if (NAVIGATOR.isInAdminArea) {
      NAVIGATOR.navigateTo(makeUrl('/', NAVIGATOR.isInAdminArea ? 'admin' : 'app', 'chats', s.threadId))
    }
  }

  const viewCounsellingjourney = async () => {
    if (!s.application?.id) return;
    removeUrlParam('applicationId');
    await tick(100);
    UI.OVERLAY.dismiss();
    setUrlParam('applicationId', s.application.id, NAVIGATOR);
  }

  return <Observer children={() => (
    <AppPage className={
      joinClassName('OverlaySessionDetails', s.isNext && 'next')
    } color={getCounsellingTypeColorName(s.application?.type)}>
      <AppPageHeader 
        title="Session Details"
        afterTitle={<>
          { s.isLive && <ColorTagLive /> }
          <>{s.nth && s.application ? `${getApplicationTypeDescriptor(s.application.type).displayName} Application #${s.application.id}: Session No. ${s.nth}` : ''}</>
        </>}
        endSlot={<OverlayCloseButton />}
      />
      <AppPageContent>
        { s.session && s.application && <UIBlock padded spaceChildren>
          <ShadedBlock spaceChildren colorIntensity={s.isNext ? 'strong' : 'normal'}>
            <HeadingAllcaps>Scheduled Time</HeadingAllcaps>
            <SessionOrGroupTimeDisplay model={s.session} />
            { s.isLive && s.threadId && <BaseButton onClick={openChat}>Switch to Chat Window</BaseButton>}
          </ShadedBlock>
          <ShadedBlock spaceChildren>
            <HeadingAllcaps>At a glance</HeadingAllcaps>
            <InfoDisplayList>
              <InfoDisplayItem label="Counsellor">
                <UserFullNameRenderer user={s.application.counsellor} userId={s.application.counsellorId} />
              </InfoDisplayItem>
              <InfoDisplayItem label="Communication Type">
                {s.session.type ? communicationTypeDescriptors[s.session.type]?.name : ''}
              </InfoDisplayItem>
              <InfoDisplayItem label="Ref. No">
                #{s.session.id}
              </InfoDisplayItem>
            </InfoDisplayList>
          </ShadedBlock>
          <ShadedBlock spaceChildren>
            <HeadingAllcaps>Before the session...</HeadingAllcaps>
            <SessionSummaryAssignmentList
              session={s.session}
              application={s.application}
            />
          </ShadedBlock>
          {s.shouldShowMoreOptions && <ShadedBlock spaceChildren>
            <HeadingAllcaps>More Options</HeadingAllcaps>
            <CommandList withBackground>
              <CommandListItem icon="arrow" onClick={viewCounsellingjourney}>View Counselling Journey</CommandListItem>
            </CommandList>
          </ShadedBlock>}
        </UIBlock> }
      </AppPageContent>
    </AppPage>
  )} />
}

export default OverlaySessionDetails;