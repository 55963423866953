export enum Size {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  ml = 'ml',
  lg = 'lg',
  xl = 'xl',
}

export const { xs, sm, md, ml, lg, xl } = Size;

export function isSize(v: any): v is Size {
  return Object.values(Size).includes(v);
}

export type CommonSize = Size.sm | Size.md | Size.ml | Size.lg | Size.xl;

export function getSize(string: string) {
  return `var(--app-s-${string})`;
}