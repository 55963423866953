import { Address, AddressSnapshot } from "../../models/makeAddress.model";
import { Validator, ValidatorResult } from "../@types";

export const AddressValidator: Validator = (address: Address | AddressSnapshot) => {
  const result: ValidatorResult = {
    isValid: false,
    message: 'The address is invalid',
  }
  switch (true) {
    case (!address.lineOne): {
      result.message = "Please provide your address's line one.";
      break;
    }
    case (!address.city): {
      result.message = "Please enter your city or town.";
      break;
    }
    case (!Boolean(address.region)): {
      result.message = "Please enter your region or state.";
      break;
    }
    case (!Boolean(address.postcode)): {
      result.message = "Please enter your postcode.";
      break;
    }
    case (!Boolean(address.countryId)): {
      result.message = "Please select your country.";
      break;
    }
    default: {
      return true;
    }
  }
  return result;
}