import { CounsellingAvailability, CounsellingAvailabilityRelatedModels } from "../../models/makeCounsellingAvailability.model";
import { DefaultIndexParamSet } from "../utils/api.utils";
import { makeApiEndpointConfig, makeApiGetEndpointConfig } from "../utils/endpoints.utils";
import { makeUrl } from "../utils/url.utils";
import { prefixClient, prefixStaff } from "./namespacePrefixes";

export type CounsellingAvailabilityEndpointParams = DefaultIndexParamSet<CounsellingAvailability, CounsellingAvailabilityRelatedModels> & {
  include?: (keyof CounsellingAvailabilityRelatedModels | string)[],
  available?: boolean,
}

const clientUrlBase = prefixClient('counselling-availabilities');
const staffUrlBase = prefixStaff('counselling-availabilities');

export const CounsellingAvailabilityEndpoints = {
  client: {
    index: makeApiEndpointConfig<CounsellingAvailabilityEndpointParams>(clientUrlBase),
    get: makeApiGetEndpointConfig<CounsellingAvailabilityEndpointParams>(clientUrlBase),
  },
  staff: {
    index: makeApiEndpointConfig<CounsellingAvailabilityEndpointParams>(staffUrlBase),
    get: makeApiGetEndpointConfig<CounsellingAvailabilityEndpointParams>(staffUrlBase),
    create: () => staffUrlBase,
    update: (id: string) => makeUrl(staffUrlBase, id),
    delete: (id: string) => makeUrl(staffUrlBase, id),
  },
}