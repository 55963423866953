import { CompanyEndpoints } from "../base/endpoints/company.endpoints";
import { ModelName } from "../constants/modelNames.enum";
import { Company } from "../models/makeCompany.model";
import { useAutoGet, UseAutoGetFactoryOptions } from "../utils/useAutoGet.utils";

export const useGetCompanies = <T extends object>(
  options: UseAutoGetFactoryOptions<Company[], T>
) => {
  useAutoGet(
    {
      asIndex: true,
      allowCache: true,
      defaultValue: [] as Company[],
      ...options,
      modelName: ModelName.companies,
      url: CompanyEndpoints.staff.companySelectorIndex({ perPage: 9999 }),
    }
  )
}