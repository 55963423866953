import { flow, reaction, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState, Identifier, Nillable, Nullable } from '../../base/@types';
import AppPage from '../../base/components/AppPage/AppPage';
import AppPageContent from '../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../base/components/AppPageHeader/AppPageHeader';
import BaseSpacer from '../../base/components/BaseSpacer/BaseSpacer';
import LoadingIndicatorSection from '../../base/components/LoadingIndicatorSection/LoadingIndicatorSection';
import OverlayCloseButton from '../../base/components/OverlayCloseButton/OverlayCloseButton';
import UIBlock from '../../base/components/UIBlock/UIBlock';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers, useUIController } from '../../base/hooks/useRootController.hook';
import { makeDisposerController } from '../../base/utils/disposer.utils';
import { reportError } from '../../base/utils/errors.utils';
import { immediateReaction, useProps, useStore } from '../../base/utils/mobx.utils';
import { capitalizeSingleWord } from '../../base/utils/string.utils';
import { createUTCMoment } from '../../base/utils/time.utils';
import { useSyncUrlParams } from '../../base/utils/urlParams.utils';
import { ModelName } from '../../constants/modelNames.enum';
import { Thought } from '../../models/makeThought.model';
import { getThought } from '../../requests/getThought.request';
import ThoughtCard from '../ThoughtCard/ThoughtCard';
import ThoughtEditor from '../ThoughtEditor/ThoughtEditor';
import './OverlayThought.scss';

interface OverlayThoughtProps {
  thought?: Nullable<Thought>,
  thoughtId?: Nullable<Identifier>,
  action?: 'view' | 'edit',
  compact?: boolean,
}

const OverlayThought: React.FC<OverlayThoughtProps> = props => {

  const p = useProps(props);

  const { API, AUTH, LOCALDB, UI } = useControllers();
  const { DIALOG, OVERLAY } = useUIController();

  const s = useStore(() => ({
    get thought() {
      return p.thought || (s.thoughtId ? LOCALDB.get<Thought>(ModelName.thoughts, s.thoughtId) : null);
    },
    get thoughtId(): Nillable<Identifier> {
      return p.thoughtId || s.thought?.id;
    },
    get shouldBlockDisplay() {
      if (AUTH.isModerator) return false;
      return s.thought?.isPrivate && (!AUTH.currentUser?.id || s.thought?.userId !== AUTH.currentUser.id);
    },
    get action() {
      return p.action || 'view';
    },
    get overlayTitle() {
      return [
        capitalizeSingleWord(s.action),
        s.thought?.isPrivate ? 'Private Thought' : 'Thought',
      ].join(' ');
    },
    get thoughtIsOver5MinutesOld() {
      return createUTCMoment(s.thought?.timeCreated).add(5, 'minutes').isBefore();
    },
    get thoughtHasBeenEditedBefore() {
      return !!s.thought?.originalThought;
    },
    get disableEdit() {
      return !AUTH.isModerator && (s.thoughtHasBeenEditedBefore || s.thoughtIsOver5MinutesOld);
    },
    get content() {
      return <UIBlock className={p.compact ? 'OverlayThoughtCompact' : ''} padded={!p.compact} data-emotion={s.thought?.emotionId}>
        {!p.compact && <BaseSpacer />}
        {!s.thought && <LoadingIndicatorSection />}
        {s.thought && !s.shouldBlockDisplay && <>
          {s.action === 'view' && <ThoughtCard thought={s.thought} alwaysShowComments />}
          {s.action === 'edit' && <ThoughtEditor thought={s.thought} onSuccess={dismiss} onDiscardChanges={dismiss} disabled={s.disableEdit} standalone />}
        </>}
        {p.compact && <OverlayCloseButton />}
      </UIBlock>
    }
  }));

  const dismiss = () => OVERLAY.dismiss(`OverlayThought#${s.thoughtId}`);

  useOnMount(() => {
    const d = makeDisposerController();
    (async () => {

      if (p.action === 'edit' && !AUTH.isModerator) {
        await when(() => !!s.thought);
        if (s.thoughtHasBeenEditedBefore) {
          UI.DIALOG.present({
            heading: 'This thought has already been edited before.',
            body: 'Either you have edited this thought, or our moderator had adjusted your thought to make it safer for the public. You can no longer edit it.',
            colorCodedState: ColorCodedState.attention,
            actions: [{
              label: 'OK',
              action: () => dismiss(),
            }]
          })
          return;
        }
        if (s.thoughtIsOver5MinutesOld) {
          UI.DIALOG.present({
            heading: 'This thought was posted more than 5 minutes ago.',
            body: 'You can no longer edit this thought.',
            colorCodedState: ColorCodedState.attention,
            actions: [{
              label: 'OK',
              action: () => dismiss(),
            }]
          })
          return;
        }
      }

      d.add(immediateReaction(
        () => s.shouldBlockDisplay,
        shouldBlockDisplay => {
          if (shouldBlockDisplay) {
            dismiss();
            DIALOG.attention({
              heading: 'This thought is unavailable.',
            })
          }
        }
      ))
      if (!s.thought) d.add(reaction(
        () => !s.thought && s.thoughtId,
        flow(function* () {
          try {
            if (s.thoughtId) yield getThought(s.thoughtId, API);
          } catch (error) {
            reportError(error)
            DIALOG.error({
              heading: 'Unable to get the thought information',
              error: error
            });
          }
        }),
        { fireImmediately: true }
      ));
    })();

    return d.disposer

  })

  useSyncUrlParams(s.action === 'edit' ? 'editThoughtId' : 'thoughtId', s.thoughtId);

  return <Observer children={() => (
    p.compact ? s.content : <AppPage className="OverlayThought" data-emotion={s.thought?.emotionId} data-is-private={s.thought?.isPrivate}>
      <AppPageHeader title={s.overlayTitle} endSlot={<OverlayCloseButton />} transparent />
      <AppPageContent>
        { s.content }
      </AppPageContent>
    </AppPage>
  )} />

}

export default OverlayThought;