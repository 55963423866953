import { Observer } from 'mobx-react-lite';
import React from 'react';
import ExampleImage from '../../assets/images/pexels-photo-1311518.jpeg';
import tick from '../../base/utils/waiters.utils';
import { getWordPressLink } from '../../env';
import ServiceCard from '../ServiceCard/ServiceCard';
// import './ServiceCardOneToOneCounselling.scss';

interface ServiceCardOneToOneCounsellingProps {
  cardRatio?: number,
}

const ServiceCardOneToOneCounselling: React.FC<ServiceCardOneToOneCounsellingProps> = props => {

  const automaticallyRedirectToCounsellingApplicationPage = async () => {
    await tick(1000);
    document.querySelector<HTMLAnchorElement>('.AppNavTabInner[data-identifier="counselling"]')?.click();
  }

  return <Observer children={() => (
    <ServiceCard
      className="ServiceCardOneToOneCounselling"
      title={<>One-to-One<br />Counselling</>}
      stringTitle="One-to-One Counselling"
      sideABackgroundImage={`url(${ExampleImage})`}
      src={getWordPressLink('/services/one-to-one-counselling/')}
      listenForWindowMessage="one-to-one-counselling"
      onReceiveWindowMessage={automaticallyRedirectToCounsellingApplicationPage}
      cardRatio={props.cardRatio}
    />
  )} />
}

export default ServiceCardOneToOneCounselling;