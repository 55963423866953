import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { TimezoneMode } from '../../@types/time.types';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { createMomentFn } from '../../utils/time.utils';
import './TimeDisplayer.scss';

interface TimeDisplayerProps {
  className?: string,
  value?: string | Date | number | moment.Moment | null;
  timezoneMode?: TimezoneMode,
  format?: string,
}

const TimeDisplayer: React.FC<TimeDisplayerProps> = props => {

  const p = useProps(props);

  const _createMoment = createMomentFn(p.timezoneMode ?? 'auto');

  const s = useStore(() => ({
    get emptyDisplayValue() {
      switch (p.format) {
        case 'HH:mm:ss':
          return '--:--:--'
        case 'mm:ss':
        case 'HH:mm':
          return '--:--'
        default:
          return ''
      }
    },
    get machineTime() {
      return _createMoment(p.value || undefined).toISOString();
    },
    get displayTime() {
      return p.value ? _createMoment(p.value).local().format('HH:mm') : s.emptyDisplayValue;
    },
  }));

  return <Observer children={() => (
    <time className={
      joinClassName(
        'TimeDisplayer',
        p.className
      )
    } dateTime={s.machineTime}>
      {s.displayTime}
    </time>
  )} />
}

export default TimeDisplayer;