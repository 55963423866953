import React from "react";
import OverlaySessionDetails from "../components/OverlaySessionDetails/OverlaySessionDetails";
import { UIController } from "../controllers/ui.controller";

export const openCounsellingSessionDetails = (
  sessionId: string,
  UI: UIController,
) => {
  UI.OVERLAY.present({
    name: `CounsellingSessionDetails#${sessionId}`,
    component: <OverlaySessionDetails sessionId={sessionId} />,
    duplicateStrategy: 'abort',
    width: '45em',
  })
}