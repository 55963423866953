import React from "react";
import { TwoFAIconDef } from "./2FA.icon";
import { AccordionIconDef } from "./Accordion.icon";
import { ArrowIconDef } from "./Arrow.icon";
import { AvailabilityIconDef } from "./Availability.icon";
import { BackIconDef } from "./Back.icon";
import { BadgeCheckIconDef } from "./BadgeCheck";
import { BankCardIconDef } from "./BankCard.icon";
import { BlankIconDef } from "./Blank.icon";
import { BlocksIconDef } from "./Blocks.icon";
import { CalendarIconDef } from "./Calendar.icon";
import { ChatIconDef } from "./Chat.icon";
import { ChatPlusIconDef } from "./ChatPlus.icon";
import { ChatTopicIconDef } from "./ChatTopic.icon";
import { CheckIconDef } from "./Check.icon";
import { CheckCircleIconDef } from "./CheckCircle.icon";
import { CheckCircleDisplayIconDef } from "./CheckCircleDisplay.icon";
import { ChevronIconDefs } from "./Chevrons.icon";
import { CircleIconDef } from "./Circle.icon";
import { ClientNotebookIconDef } from "./ClientNotebook.icon";
import { CloseIconDef } from "./Close.icon";
import { CogIconDef } from "./Cog.icon";
import { ComposeMessageIconDef } from "./ComposeMessage.icon";
import { ContrastIconDef } from "./Contrast.icon";
import { CopyIconDef } from "./Copy.icon";
import { CounsellingIconDef } from "./Counselling.icon";
import { DeleteIconDef } from "./Delete.icon";
import { DocumentsIconDef } from "./Documents.icon";
import { DurationEuroIconDef } from "./DonationEuro.icon";
import { DropdownArrowIconDef } from "./DropdownArrow.icon";
import { EmojiIconDefs } from "./Emojis.icons";
import { EuroIconDef } from "./Euro.icon";
import { ExploreIconDef } from "./Explore.icon";
import { ExportCSVIconDef } from "./ExportCSV.icon";
import { ExportXLSXIconDef } from "./ExportXLSX.icon";
import { ExternalLinkIconDef } from "./ExternalLink.icon";
import { EyeIconDef } from "./Eye.icon";
import { FlagIconDef } from "./Flag.icon";
import { FlagCheckeredIconDef } from "./FlagCheckered.icon";
import { FlagWavingIconDef } from "./FlagWaving.icon";
import { ForbiddenIconDef } from "./Forbidden.icon";
import { GAD7IconDef } from "./GAD7.icon";
import { GridIconDef } from "./Grid.icon";
import { HamburgerMenuIconDef } from "./HamburgerMenu.icon";
import { HangUpIconDef } from "./HangUp.icon";
import { HeartIconDef } from "./Heart.icon";
import { HideKeyboardIconDef } from "./HideKeyboard.icon";
import { HomeIconDef } from "./Home.icon";
import { InfoIconDef } from "./Info.icon";
import { InvoicesIconDef } from "./Invoices.icons";
import { KeepInTrayIconDef } from "./KeepInTray.icon";
import { KiteIconDef } from "./Kite.icon";
import { LockIconDef } from "./Lock.icon";
import { MailIconDef } from "./Mail.icon";
import { MapPinIconDef } from "./MapPin.icon";
import { MicrophoneIconDefs } from "./Microphones.icons";
import { MinusIconDef } from "./Minus.icon";
import { MoreIconDef } from "./More.icon";
import { NestedViewIconDef } from "./NestedView.icon";
import { NewspaperIconDef } from "./Newspaper.icon";
import { NotebookIconDef } from "./Notebook.icon";
import { NotificationIconDef } from "./Notification.icon";
import { OpenOverlayIconDef } from "./OpenOverlay.icon";
import { PageStackIconDef } from "./PageStack.icon";
import { PencilIconDef } from "./Pencil.icon";
import { PeopleIconDef } from "./People.icon";
import { PersonCircleIconDef } from "./PersonCircle.icon";
import { PersonDeleteIconDef } from "./PersonDelete.icon";
import { PhoneIconDef } from "./Phone.icon";
import { PHQ9IconDef } from "./PHQ9.icon";
import { PlayIconDef } from "./Play.icon";
import { PlayCircleIconDef } from "./PlayCircle.icon";
import { PlusIconDef } from "./Plus.icon";
import { PowerIconDef } from "./Power.icon";
import { PrintIconDef } from "./Print.icon";
import { QuestionIconDef } from "./Question.icon";
import { RefreshIconDef } from "./Refresh.icon";
import { SearchIconDef } from "./Search.icon";
import { SendIconDef } from "./Send.icon";
import { SessionsIconDef } from "./Sessions.icon";
import { ShareIconDef } from "./Share.icon";
import { ShieldIconDef } from "./Shield.icon";
import { ShieldWarningIconDef } from "./ShieldWarning.icon";
import { SpeechBubbleIconDef } from "./SpeechBubble.icon";
import { StaffIconDef } from "./Staff.icon";
import { StarStackIconDef } from "./StarStack.icon";
import { StatsIconDef } from "./Stats.icon";
import { StopIconDef } from "./Stop.icon";
import { SuitcaseIconDef } from "./Suitcase.icon";
import { TaxonomyIconDef } from "./Taxonomy.icon";
import { TicketIconDef } from "./Ticket.icon";
import { TimerIconDef } from "./Timer.icon";
import { UnlockIconDef } from "./Unlock.icon";
import { UserCardStackIconDef } from "./UserCardStack.icon";
import { UserSurveyStackIconDef } from "./UserSurveyStack.icon";
import { VideoIconDef } from "./Video.icon";
import { WarningIconDef } from "./Warning.icon";
import { WordPressIconDef } from "./WordPress.icon";
import { ZoomIconDef } from "./Zoom.icon";
import { UkraineFlagDef } from "./UkraineFlag.icon";

export type IconDef = {
  regular: () => React.ReactElement,
  filled?: () => React.ReactElement,
  color?: () => React.ReactElement,
}

export type IconVariant = keyof IconDef;

export const IconDefs = {
  ...TwoFAIconDef,
  ...AccordionIconDef,
  ...ArrowIconDef,
  ...AvailabilityIconDef,
  ...BackIconDef,
  ...BadgeCheckIconDef,
  ...BankCardIconDef,
  ...BlankIconDef,
  ...BlocksIconDef,
  ...CalendarIconDef,
  ...ChatIconDef,
  ...ChatPlusIconDef,
  ...ChatTopicIconDef,
  ...CheckCircleIconDef,
  ...CheckCircleDisplayIconDef,
  ...CheckIconDef,
  ...ChevronIconDefs,
  ...CircleIconDef,
  ...ClientNotebookIconDef,
  ...CloseIconDef,
  ...CogIconDef,
  ...ComposeMessageIconDef,
  ...ContrastIconDef,
  ...CopyIconDef,
  ...CounsellingIconDef,
  ...DeleteIconDef,
  ...DocumentsIconDef,
  ...DropdownArrowIconDef,
  ...DurationEuroIconDef,
  ...EmojiIconDefs,
  ...EuroIconDef,
  ...ExploreIconDef,
  ...ExternalLinkIconDef,
  ...ExportCSVIconDef,
  ...ExportXLSXIconDef,
  ...EyeIconDef,
  ...FlagCheckeredIconDef,
  ...FlagIconDef,
  ...FlagWavingIconDef,
  ...ForbiddenIconDef,
  ...GAD7IconDef,
  ...GridIconDef,
  ...HamburgerMenuIconDef,
  ...HangUpIconDef,
  ...HeartIconDef,
  ...HideKeyboardIconDef,
  ...HomeIconDef,
  ...InfoIconDef,
  ...InvoicesIconDef,
  ...KeepInTrayIconDef,
  ...KiteIconDef,
  ...LockIconDef,
  ...MailIconDef,
  ...MapPinIconDef,
  ...MicrophoneIconDefs,
  ...MinusIconDef,
  ...MoreIconDef,
  ...NestedViewIconDef,
  ...NewspaperIconDef,
  ...NewspaperIconDef,
  ...NotebookIconDef,
  ...NotificationIconDef,
  ...OpenOverlayIconDef,
  ...PageStackIconDef,
  ...PencilIconDef,
  ...PeopleIconDef,
  ...PersonCircleIconDef,
  ...PersonDeleteIconDef,
  ...PhoneIconDef,
  ...PHQ9IconDef,
  ...PlayCircleIconDef,
  ...PlayIconDef,
  ...PlusIconDef,
  ...PowerIconDef,
  ...PrintIconDef,
  ...QuestionIconDef,
  ...RefreshIconDef,
  ...SearchIconDef,
  ...SendIconDef,
  ...SessionsIconDef,
  ...ShareIconDef,
  ...ShieldIconDef,
  ...ShieldWarningIconDef,
  ...SpeechBubbleIconDef,
  ...StaffIconDef,
  ...StarStackIconDef,
  ...StatsIconDef,
  ...StopIconDef,
  ...SuitcaseIconDef,
  ...TaxonomyIconDef,
  ...TicketIconDef,
  ...TimerIconDef,
  ...UnlockIconDef,
  ...UserCardStackIconDef,
  ...UserSurveyStackIconDef,
  ...VideoIconDef,
  ...WarningIconDef,
  ...WordPressIconDef,
  ...ZoomIconDef,
  ...UkraineFlagDef,
} as const

export type IconName = keyof typeof IconDefs;