import { action, flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject, ColorCodedState } from '../../../../base/@types';
import BaseButton from '../../../../base/components/BaseButton/BaseButton';
import BaseGrid from '../../../../base/components/BaseGrid/BaseGrid';
import BaseGridCell from '../../../../base/components/BaseGrid/BaseGridCell';
import BaseHeader from '../../../../base/components/BaseHeader/BaseHeader';
import BaseInput from '../../../../base/components/BaseInput/BaseInput';
import BaseSpacer from '../../../../base/components/BaseSpacer/BaseSpacer';
import ClickToCopy from '../../../../base/components/ClickToCopy/ClickToCopy';
import ErrorRenderer from '../../../../base/components/ErrorRenderer/ErrorRenderer';
import InfoBanner from '../../../../base/components/InfoBanner/InfoBanner';
import LoadingIndicatorSection from '../../../../base/components/LoadingIndicatorSection/LoadingIndicatorSection';
import ShadedBlock from '../../../../base/components/ShadedBlock/ShadedBlock';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import { makeForm } from '../../../../base/mediators/form.mediator';
import { joinTruthyWithSeparator } from '../../../../base/utils/array.utils';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import { setUrlParam } from '../../../../base/utils/urlParams.utils';
import UserInfoEditor from '../../../../components/UserInfoEditor/UserInfoEditor';
import { enableFrontlineWorkersFeature } from '../../../../env';
import { makeUserSnapshotBase, User } from '../../../../models/makeUser.model';
import { sendSaveUserRequest } from '../../../../requests/saveUser.request';
import { useGetUser } from '../../../../requests/useGetUser.request';
import { getAgeFromDateOfBirth, isAdultAge } from '../../../../utils/ageAndDateOfBirth.utils';
import './UserEditorForAdmin.scss';

interface UserEditorForAdminProps {
  user?: User,
  userId?: string,
  showAdminOnlyFields?: boolean,
  onSave?: (user: User) => unknown,
}

const UserEditorForAdmin: React.FC<UserEditorForAdminProps> = props => {

  const { API, UI, AUTH, NAVIGATOR } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    _user: null as User | null,
    get user() {
      return p.user || s._user;
    },
    get id() {
      return p.userId || p.user?.id;
    },
    get isNewUser() {
      return !s.id;
    },
    get canEditUsername() {
      return true;
    },
    form: makeForm(p.user?.$getSnapshot() ?? makeUserSnapshotBase(), {
      validators: {},
      editableFields: [
        // ...s.isNewUser ? ['username'] as (keyof UserSnapshot)[] : [],
        'username', 'firstName', 'lastName', 'email', 'mobileNumber', 'countryProvidedId', 'gender', 'dateOfBirth', 'color', 'iacpMembershipNumber', 'companyId',
      ],
    }),
    pwdForm: {
      password: '',
      password_confirmation: '',
    },
    get passwordMatches() {
      return !s.pwdForm.password || !s.pwdForm.password_confirmation || (s.pwdForm.password === s.pwdForm.password_confirmation);
    },
    get isAdminOrCounsellor() {
      return AUTH.isStaff || AUTH.isCounsellor;
    },
    get canEditOtherUsers() {
      return s.isAdminOrCounsellor || AUTH.isModerator;
    },
    get userIsAdult() {
      return isAdultAge(getAgeFromDateOfBirth(s.form.value.dateOfBirth));
    },
    get isEditingSelf() {
      return false; // use staff index.
      // return AUTH.currentUser?.id === s.id;
    },
    get canEditCountry() {
      // return (s.isAdminOrCounsellor || !s.form.fields.countryProvidedId.originalValue) && !AUTH.isModerator;
      return s.isAdminOrCounsellor || !s.form.fields.countryProvidedId.originalValue;
    },
    get canEditBirthDay() {
      return s.isAdminOrCounsellor || !s.form.fields.dateOfBirth.originalValue;
    },
    get canEditPassword() {
      return AUTH.isStaff || s.isEditingSelf;
    },
    get canEditNames() {
      return AUTH.isStaff || s.isEditingSelf;
    },
    get canEditContactInfo() {
      return AUTH.isStaff || s.isEditingSelf;
    },
    get canEditColor() {
      return AUTH.isStaff || s.isEditingSelf;
    },
    get canEditGenderAndSexuality() {
      return AUTH.isStaff || s.isEditingSelf;
    },
    get canEditCompany() {
      return AUTH.isStaff || s.isEditingSelf;
    },
    error: null as unknown,
    get shouldShowFrontLineOption() {
      return s.userIsAdult && enableFrontlineWorkersFeature;
    },
    get saveButtonLabel() {
      return s.isNewUser ? 'Create User' : 'Save Changes'
    },
    generatedPassword: '',
    generatePassword: action(() => {
      const pwdChars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!$%^&*()_-?.=+";
      const pwdLen = 12;
      const randomPassword = Array(pwdLen).fill(pwdChars).map(x => x[Math.floor(Math.random() * x.length)]).join('');
      s.generatedPassword = randomPassword;
      s.pwdForm.password = s.pwdForm.password_confirmation = s.generatedPassword;
    })
  }));

  useGetUser({
    observable: s,
    key: '_user',
    onDataFetch: user => {
      s.form.reset(user);
    },
    onError: action((e: Error) => {
      s.error = e;
    }),
  }, s.id);

  const saveUser = async () => new Promise<boolean>(flow(function* (resolve, reject) {
    if (!s.passwordMatches) return;
    if (!s.canEditOtherUsers && !s.isEditingSelf) {
      UI.DIALOG.error({
        heading: 'You are not authorized to update this user\'s profile.',
      })
      resolve(false);
    }
    const emailChanged = s.form.fields.email.originalValue && s.form.fields.email.value && s.form.fields.email.changed;
    const phoneChanged = s.form.fields.mobileNumber.originalValue && s.form.fields.mobileNumber.value && s.form.fields.mobileNumber.changed;
    if (emailChanged || phoneChanged) {
      const attrToUpdate = joinTruthyWithSeparator(' and ', emailChanged && 'email', phoneChanged && 'phone number');
      const confirm = yield UI.DIALOG.present({
        heading: 'Confirm update',
        body: `The new ${attrToUpdate} will need to be verified again after you submit the changes.`,
      })
      if (!confirm) {
        resolve(false);
        return;
      }
    }
    const payload: Partial<User> = {
      id: s.id,
      username: s.form.fields.username.value,
      email: s.form.fields.email.value,
      mobileNumber: s.form.fields.mobileNumber.value,
    }
    if (s.canEditNames) {
      payload['firstName'] = s.form.value.firstName;
      payload['lastName'] = s.form.value.lastName;
    }
    if (s.canEditContactInfo) {
      payload['email'] = s.form.value.email;
      payload['mobileNumber'] = s.form.value.mobileNumber;
    }
    if (s.canEditBirthDay) {
      payload['dateOfBirth'] = s.form.value.dateOfBirth;
    }
    if (s.canEditColor) {
      payload['color'] = s.form.value.color;
      payload['preferences'] = s.form.value.preferences || null;
    }
    if (s.canEditCountry) {
      payload['countryProvidedId'] = s.form.value.countryProvidedId;
    }
    if (s.canEditCompany) {
      payload['companyId'] = s.form.value.companyId;
    }
    if (s.canEditGenderAndSexuality) {
      payload['gender'] = s.form.value.gender;
      payload['sexuality'] = s.form.value.sexuality;
    }
    if (s.pwdForm.password) {
      (payload as AnyObject)['password'] = s.pwdForm.password;
      (payload as AnyObject)['password_confirmation'] = s.pwdForm.password_confirmation;
    }
    try {
      const savedUser: User = yield sendSaveUserRequest(API, payload, {
        isSelf: s.isEditingSelf
      });
      if (!s.id && savedUser.id) {
        setUrlParam('userId', savedUser.id, NAVIGATOR)
        UI.TOAST.success('User created.');
        p.onSave?.(savedUser);
        resolve(true);
        return;
      }
      p.user?.$patch(savedUser);
      s._user?.$patch(savedUser);
      s.form.reset(savedUser.$getSnapshot());
      p.onSave?.(savedUser);
      UI.TOAST.success('User information updated.');
      resolve(true);
    } catch (e) {
      reject(e);
      UI.DIALOG.error({
        heading: 'Failed to save user',
        body: <ErrorRenderer error={(e as any).response} />,
        defaultActions: ['positive']
      })
    }
  }))

  return <Observer children={() => (
    <div className="UserEditorForAdmin">
      {s.error && <ErrorRenderer error={s.error} />}
      {!s.user?.preferences && <LoadingIndicatorSection />}
      {!!s.user?.preferences && <div className="UserEditorForAdminInner">
        <UserInfoEditor form={s.form} canEditColor showMoreOptionsSection showCompanySelector />
        {
          p.showAdminOnlyFields && s.canEditPassword && <ShadedBlock>
            <BaseHeader
              level={3}
              heading={s.isNewUser ? 'Choose a Password' : 'Update Password'}
              endSlot={<BaseButton size="sm" className="subtle" label="Generate Password" dataCy="generatePassword" onClick={s.generatePassword}/>}
            />
            {!s.isNewUser && <p>If you need to update the password for this user, enter the new password in the fields below. Otherwise leave the fields blank.</p>}
            <BaseSpacer size="sm" />
            <BaseGrid columns={2}>
              <BaseInput form={s.pwdForm} field="password" type="password" label={s.isNewUser ? 'Password' : 'Update Password'} autoComplete="new-password" />
              <BaseInput form={s.pwdForm} field="password_confirmation" type="password" label="Confirm Password" autoComplete="new-password" />
              {s.passwordMatches ||
                <BaseGridCell columns="all">
                  <InfoBanner icon="warning" colorCodedState={ColorCodedState.attention}>
                    <p>The two password fields do not match.</p>
                  </InfoBanner>
                </BaseGridCell>
              }
            </BaseGrid>
            {
              s.generatedPassword && s.generatedPassword === s.pwdForm.password && <>
                <BaseSpacer size=".5em" />
                <ShadedBlock color="green">
                  <ClickToCopy text={s.generatedPassword}>
                    <code>{s.generatedPassword}</code>
                  </ClickToCopy>
                </ShadedBlock>
              </>
            }
          </ShadedBlock>
        }
        <BaseButton onClick={saveUser} size="lg" label={s.saveButtonLabel} fullWidth dataCy="saveUser"/>
      </div>}
    </div>
  )} />
}

export default UserEditorForAdmin;