import React from 'react';
import { BaseCalendarBaseChildProps } from './BaseCalendar';
import './BaseCalendarBody.scss';
import BaseCalendarBodyContent from './BaseCalendarBodyContent';

interface BaseCalendarBodyProps<T = object> extends BaseCalendarBaseChildProps<T> {}

function BaseCalendarBody<T extends object>(props: React.PropsWithChildren<BaseCalendarBodyProps<T>>){
  
  return <div className="BaseCalendarBody">
    <BaseCalendarBodyContent<T> {...props} />
  </div>
}

export default BaseCalendarBody;