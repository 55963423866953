import { flow, makeObservable, runInAction, when } from "mobx";
import React from "react";
import { ColorCodedState } from "../../base/@types";
import ClickOrTap from "../../base/components/ClickOrTap/ClickOrTap";
import { AuthEndpoints } from "../../base/endpoints/auth.endpoints";
import tick from "../../base/utils/waiters.utils";
import OverlayPhoneVerification from "../../modules/Client/_components/OverlayPhoneVerification/OverlayPhoneVerification";
import { AuthController } from "../auth.controller";
import { UIController } from "../ui.controller";

export const resendSMSVerificationCode = (AUTH: AuthController) => new Promise<boolean>(flow(function* (resolve, reject) {
  const { UI, API } = AUTH.ROOT!.children;
  const { DIALOG } = UI;
  if (!!AUTH.currentUser?.timeVerifiedMobileNumber) {
    DIALOG.present({
      heading: 'You have already verified your phone number.',
      defaultActions: ['positive'],
    })
  }
  try {
    const url = AuthEndpoints.verification.resend.sms();
    yield API.postRaw(url);
    resolve(true);
  } catch (e) {
    DIALOG.present({
      name: 'phone-verification-code-resend-failure',
      heading: 'Failed to resend code',
      body: `There was an error resending the code. If this keeps happening, please contact customer support.`,
      colorCodedState: ColorCodedState.alert,
      defaultActions: ['positive'],
    })
    reject(e);
  }
}))

export const presentPhoneVerificationOverlay = (UI: UIController) => {
  UI.OVERLAY.present(makeObservable({
    name: 'OverlayPhoneVerification',
    component: OverlayPhoneVerification,
    width: '48em',
  }, {
    component: false,
  }))
}

export const verifyMobileNumber = async (
  AUTH: AuthController,
  _status?: {
    mobileNumberVerified: boolean,
  },
  skipToast?: boolean,
) => {

  const { UI } = AUTH.ROOT!.children;
  const { TOAST, OVERLAY } = UI;

  if (!AUTH.isAuthenticated) return;

  const status = _status ?? {
    mobileNumberVerified: AUTH.currentUser?.hasVerifiedMobileNumber ?? false,
  }

  if (skipToast) {
    presentPhoneVerificationOverlay(UI);
  } else {
    if (TOAST.hasToast('mobileNumberVerifyPrompt')) return;
    if (AUTH.hasNotifiedAboutMobilePhoneVerification) return;
    if (window.location.pathname === '/app/explore') return;
    TOAST.present({
      name: 'mobileNumberVerifyPrompt',
      heading: 'Welcome to turn2me!',
      body: <><ClickOrTap capitalized/> here to verify your mobile number.</>,
      action: async () => {
        await tick(192);
        if (AUTH.currentUser?.timeVerifiedMobileNumber) {
          TOAST.success('Thank you! It seems like your mobile number had already been verified. You may continue to use the turn2me service.');
          return;
        }
        presentPhoneVerificationOverlay(UI);
      }
    });
    when(
      () => !!AUTH.currentUser?.hasVerifiedMobileNumber,
      () => TOAST.dismiss('mobileNumberVerifyPrompt'),
    )
    runInAction(() => {
      AUTH.hasNotifiedAboutMobilePhoneVerification = true;
    })
  }

  when(() => status.mobileNumberVerified, async () => {
    await tick(382);
  if (!AUTH.isAuthenticated) return;
    TOAST.dismiss('mobileNumberVerifyPrompt');
    OVERLAY.dismiss('OverlayPhoneVerification');
  })
}