import { DefaultThoughtIncludes, DefaultThoughtIncludesForAdmins, ThoughtEndpointParams, ThoughtEndpoints } from "../base/endpoints/thought.endpoints";
import { useControllers } from "../base/hooks/useRootController.hook";
import { ModelName } from "../constants/modelNames.enum";
import { Thought } from "../models/makeThought.model";
import { useAutoGet, UseAutoGetFactoryOptions } from "../utils/useAutoGet.utils";

export function useGetThought<ObservableType = object>(
  options: UseAutoGetFactoryOptions<Thought, ObservableType>,
  id?: string | null,
  params?: ThoughtEndpointParams,
) {
  const { AUTH } = useControllers();
  const canUseAdminEndpoint = AUTH.can.thoughtCatcher_.moderate_;
  const _params = params || {
    include: canUseAdminEndpoint ? DefaultThoughtIncludesForAdmins : DefaultThoughtIncludes,
  }
  useAutoGet<Thought, ObservableType>({
    ...options,
    url: id ? ThoughtEndpoints[canUseAdminEndpoint ? 'staff' : 'client'].get(id, _params) : undefined,
    modelName: ModelName.thoughts,
  });
}