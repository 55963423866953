import { flow, observable } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Nillable } from '../../base/@types';
import AppPage from '../../base/components/AppPage/AppPage';
import AppPageContent from '../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../base/components/AppPageHeader/AppPageHeader';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseButtonGroup from '../../base/components/BaseButtonGroup/BaseButtonGroup';
import BaseHeader from '../../base/components/BaseHeader/BaseHeader';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import LoadingIndicatorSection from '../../base/components/LoadingIndicatorSection/LoadingIndicatorSection';
import OverlayCloseButton from '../../base/components/OverlayCloseButton/OverlayCloseButton';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import UIBlock from '../../base/components/UIBlock/UIBlock';
import { SupportGroupEndpoints } from '../../base/endpoints/supportGroup.endpoints';
import { SupportGroupReservationEndpoints } from '../../base/endpoints/supportGroupReservation.endpoints';
import { useControllers } from '../../base/hooks/useRootController.hook';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { setUrlParam } from '../../base/utils/urlParams.utils';
import { ModelName } from '../../constants/modelNames.enum';
import { SHOULD_LOG } from '../../env';
import { Contact, ContactType, makeContact } from '../../models/makeContact.model';
import { SupportGroup } from '../../models/makeSupportGroup.model';
import { SupportGroupReservation } from '../../models/makeSupportGroupReservation.model';
import { saveContact } from '../../requests/saveContact.request';
import ContactEditor from '../ContactEditor/ContactEditor';
import UserInfoTable from '../UserInfoTable/UserInfoTable';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import RevokeReservationDialog from './RevokeReservationDialog';
// import './OverlaySupportGroupReservationControl.scss';

interface OverlaySupportGroupReservationControlProps {
  supportGroup?: SupportGroup;
  reservation: SupportGroupReservation,
  onRevoke?: (r: SupportGroupReservation) => unknown,
  disabled?: any,
}

const OverlaySupportGroupReservationControl: React.FC<OverlaySupportGroupReservationControlProps> = props => {

  const { UI, API, NAVIGATOR } = useControllers();

  const p = useProps(props);
  const s = useStore(() => ({
    get reservation() {
      return p.reservation;
    },
    get user() {
      return s.reservation.user;
    },
    get canRevokeReservation() {
      return !p.disabled && p.supportGroup && !p.supportGroup?.timeStarted && !s.isArchived && !p.reservation.isRevoked;
    },
    get isArchived() {
      return p.supportGroup?.timeArchived;
    },
    emptyContact: makeContact({
      type: ContactType.KeyWorker,
      ownerId: p.reservation.userId,
    }).$getSnapshot(),
    get keyWorker() {
      return s.reservation.keyWorker || s.emptyContact;
    }
  }));

  const dismiss = flow(function * () {
    if (s.keyWorker.name && ((s.keyWorker.phone && s.keyWorker.phone.length > 6) || s.keyWorker.email)) {
      const contact: Nillable<Contact> = yield saveContact(s.keyWorker, API);
      if (!contact) {
        UI.DIALOG.error({
          heading: "Failed to save changes",
        })
      }
      s.reservation.keyWorkerId = contact?.id;
      const url = SupportGroupReservationEndpoints.staff.update(s.reservation.id);
      const payload = { ...s.reservation.$snapshot };
      yield API.patch(url, ModelName.supportGroupReservations, payload);
    } else {
      SHOULD_LOG() && console.log('no keyworker to save.');
    }
    UI.OVERLAY.dismiss('OverlaySupportGroupReservationControl')
  });

  const revokeReservation = () => new Promise<boolean>(async (resolve, reject) => {
    if (!s.canRevokeReservation) return;
    const payload = observable({
      revokeReason: '',
    })
    const confirm = await UI.DIALOG.attention({
      name: 'RevokeReservationDialog',
      heading: 'Are you sure you want to revoke the reservation?',
      body: () => <RevokeReservationDialog form={payload} />,
      defaultActions: ['negative', 'positive']
    })
    if (!confirm) {
      resolve(false);
      return;
    }
    const groupId = p.supportGroup?.id;
    const resId = p.reservation.id;
    if (!groupId || !resId) return;
    const url = SupportGroupEndpoints.staff.revokeReservation(groupId, resId);
    try {
      await API.post(url, ModelName.supportGroupReservations, payload);
      p.onRevoke && p.onRevoke(p.reservation);
      UI.DIALOG.success({
        name: 'deregister-success',
        heading: 'The user has been deregistered from this group.',
      });
      resolve(true);
      dismiss();
    } catch(e) {
      reject(e);
      UI.DIALOG.error({
        heading: 'Failed to revoke the reservation',
        body: <ErrorRenderer error={(e as any).response} />,
      })
    }
  })

  const viewUserDetails = () => {
    setUrlParam('userId', s.user!.id, NAVIGATOR)
  }
  
  return <Observer children={() => (
    <AppPage className={
      joinClassName(
        'OverlaySupportGroupReservationControl', 
        s.reservation.isRevoked && 'revoked'
      )
    }>
      <AppPageHeader
        beforeTitle={s.reservation.isRevoked ? 'Revoked Reservation' : 'Reservation'}
        title={<UsernameRenderer user={s.user} />}
        afterTitle={<>
          {s.reservation.timeCreated && <>Joined group on <DateRenderer value={s.reservation.timeCreated} /></>}
          {s.reservation.isRevoked && <> • Reservation revoked on <DateRenderer value={s.reservation.timeRevoked} /></>}
        </>}
        endSlot={<OverlayCloseButton />}
      />
      <AppPageContent padSections>
        {
          !s.user && <LoadingIndicatorSection />
        }
        { 
          s.user && <>
            <UIBlock spaceChildren>
              {
                s.reservation.revokeReason && <ShadedBlock color="red" spaceChildren>
                  <h3>This reservation has been revoked for the following reasons:</h3>
                  <ShadedBlock>
                    {p.reservation.revokeReason ?? 'No reasons were given by the facilitator.'}
                  </ShadedBlock>
                </ShadedBlock>
              }
              <ShadedBlock spaceChildren>
                <BaseHeader
                  heading="User Details"
                  color="primary"
                  endSlot={<BaseButton className="subtle" size="xs" rounded onClick={viewUserDetails} label="Details"/>}
                />
                <UserInfoTable user={s.user} />
              </ShadedBlock>
              {
                p.supportGroup?.requiresKeyWorkerInformation && <ShadedBlock spaceChildren>
                  <h3>Key Worker Information</h3>
                  <ContactEditor contact={s.keyWorker} applyChangesImmediately />
                </ShadedBlock>
              }
              <BaseButtonGroup flex>
                <BaseButton onClick={dismiss} size="lg">OK</BaseButton>
                { s.canRevokeReservation && (
                  <BaseButton className="subtle" color="alert" size="lg" onClick={revokeReservation} dataCy="revokeReservation" >Revoke reservation</BaseButton>
                ) }
              </BaseButtonGroup>
            </UIBlock>
          </>
        }
      </AppPageContent>
    </AppPage>
  )} />
}

export default OverlaySupportGroupReservationControl;