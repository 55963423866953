import { Observer } from 'mobx-react-lite';
import React from 'react';
import ColorTagArchived from '../../base/components/ColorTag/ColorTagArchived';
import ColorTagCompleted from '../../base/components/ColorTag/ColorTagCompleted';
import ColorTagDNR from '../../base/components/ColorTag/ColorTagDNR';
import { useProps } from '../../base/utils/mobx.utils';
// import './CounsellingApplicationCompositeStatusColorTag.scss';
import { CounsellingApplication } from '../../models/makeCounsellingApplication.model';
import CounsellingApplicationStatusColorTag from '../CounsellingApplicationStatusColorTag/CounsellingApplicationStatusColorTag';

interface CounsellingApplicationCompositeStatusColorTagProps {
  application: CounsellingApplication,
}

const CounsellingApplicationCompositeStatusColorTag: React.FC<CounsellingApplicationCompositeStatusColorTagProps> = props => {
  const p = useProps(props);
  return <Observer children={() => (
    <span className="CounsellingApplicationCompositeStatusColorTag">
      {p.application.isCompleted ? <ColorTagCompleted /> : <>
        {p.application.isArchived ? <ColorTagArchived /> :
          <CounsellingApplicationStatusColorTag application={p.application} />}
        {p.application.isDnr && <ColorTagDNR />}
      </>}
    </span>
  )} />
}

export default CounsellingApplicationCompositeStatusColorTag;