import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import AppPage from '../../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../../base/components/AppPageHeader/AppPageHeader';
import BackButton from '../../../../../base/components/BackButton/BackButton';
import BaseButton from '../../../../../base/components/BaseButton/BaseButton';
import BaseSpacer from '../../../../../base/components/BaseSpacer/BaseSpacer';
import BaseToggle from '../../../../../base/components/BaseToggle/BaseToggle';
import ShadedBlock from '../../../../../base/components/ShadedBlock/ShadedBlock';
import UIBlock from '../../../../../base/components/UIBlock/UIBlock';
import { ColorName } from '../../../../../base/constants/color.enum';
import { useControllers } from '../../../../../base/hooks/useRootController.hook';
import { useStore } from '../../../../../base/utils/mobx.utils';
import tick from '../../../../../base/utils/waiters.utils';
import './AccountPagePrivacy.scss';

declare global {
  interface Window {
    GDPR_MANAGER?: {
      state: {
        google: boolean,
        facebook: boolean,
      },
      managePrivacySettings: () => void,
    }
  }
}

type AccountPagePrivacyProps = {}

const AccountPagePrivacy: React.FC<AccountPagePrivacyProps> = props => {

  const { AUTH, THOUGHT_CATCHER } = useControllers();
  const s = useStore(() => ({
    get user() {
      return AUTH.currentUser!;
    },
    showNotice: false,
    save: () => flow(function * () {
      yield AUTH.saveCurrentUser();
      s.showNotice = true;
      yield tick(2000);
      s.showNotice = false;
      if (THOUGHT_CATCHER.thoughtOfTheDay) THOUGHT_CATCHER.thoughtOfTheDay.isPrivate = s.user.preferences.preferredThoughtState === 'private';
    })(),
  }));

  return <Observer children={() => (
    <AppPage className="AccountPagePrivacy"
      accommodateTitleBarSpace
    >
      <AppPageHeader
        title="Privacy Settings"
        startSlot={<BackButton destination="up" />}
        endSlot={s.showNotice ? <span className="AccountPagePrivacySaveNotice">Changes saved!</span> : <span /> }
      />
      <AppPageContent>
        <UIBlock padded title="Thought Catcher">
          <ShadedBlock>
            <BaseToggle
              form={s.user.preferences}
              field="preferredThoughtState"
              falseValue="public"
              trueValue="private"
              label="Always post thoughts as private by default"
              onChange={s.save}
            />
          </ShadedBlock>
        </UIBlock>
        <BaseSpacer />
        <UIBlock padded title="Cookies &amp; Analytics" primaryColorName={ColorName.DeepPurple}>
          <ShadedBlock spaceChildren color="deepPurple">
            <p>turn2me uses cookies and similar technologies to personalise content, and to analyse traffic and app usage. Some are necessary for our website and application to work properly and can’t be switched off, and some are optional but helps us support the experience for you and help improve services for all clients.</p>
            <p>Note that cookies related settings are browser-specific. Every time you log out or switch to a different browser / device, you will be asked to confirm cookies settings for that specific environment.</p>
            <BaseButton label="Manage Cookies" onClick={window.GDPR_MANAGER?.managePrivacySettings} color="deepPurple" />
          </ShadedBlock>
        </UIBlock>
        <BaseSpacer />
        <UIBlock padded title="Contact Consent">
          <ShadedBlock>
            <BaseToggle
              form={s.user}
              field="hasOptedOutOfMarketingEmails"
              label="Opt-out to be contacted about turn2me services, campaigns and offers via email, posted letter or SMS through contact info that I have provided."
              onChange={s.save}
            />
          </ShadedBlock>
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
}

export default AccountPagePrivacy;