import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Form } from '../../mediators/form.mediator';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import DatePicker, { DatePickerProps } from '../DatePicker/DatePicker';
import { useFormContext } from '../FormForm/Form.context';
// import './FormDatePicker.scss';

interface FormDatePickerProps<T extends object = object> extends Omit<DatePickerProps<T>, 'form'> {
  form?: Form<T>,
}

function FormDatePicker<T extends object = object>(
  props: React.PropsWithChildren<FormDatePickerProps<T>>
) {
  
  const context = useFormContext<T>();
  const { form } = context;
  const p = useProps(props);
  const s = useStore(() => ({
    get form() { return p.form ?? form },
  }));
  
  return <Observer children={() => (
    <DatePicker {...p}
      className={joinClassName('FormDatePicker', p.className)}
      form={s.form.fields[p.field]}
      field="value"
      name={p.name ?? p.field}
      disabled={p.disabled || context.disabled}
    />
  )} />

}

export default FormDatePicker;