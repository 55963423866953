import { Observer } from "mobx-react-lite";
import React from "react";
import joinClassName from "../../utils/className.utils";
import { BaseTableProps } from "./BaseTable";
import './BaseTableHead.scss';
import BaseTableHeadCell from "./BaseTableHeadCell";

export interface BaseTableHeadProps<EntryType extends object = {}> extends BaseTableProps<EntryType> {
}

export function BaseTableHead<EntryType extends object = {}>(props: React.PropsWithChildren<BaseTableHeadProps<EntryType>>) {

  return <Observer children={() => <thead className={joinClassName(
    'BaseTableHead',
    props.className
  )}>
    <tr>
      {props.appearanceOptions?.showRowIndex && <th>No.</th>}
      {
        props.columnConfigs
          .filter(conf => !conf.hiddenFromTable)
          .map((th, i) => <BaseTableHeadCell {...props} key={(th.keyName + '' + i)} columnConfig={th} data={props.entries} />)
      }
      {props.itemActions && <th className="BaseTableActionColumnHeader"></th>}
    </tr>
  </thead>} />
}

export default BaseTableHead;