import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useObservableRef } from '../../base/hooks/useObservableRef.hook';
import { ChatMediator } from '../../base/mediators/chat.mediator';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { useMakeFocusable } from '../../hooks/useMakeFocusable.hook';
import ChatWindow from './ChatWindow';
import './ChatWindowStack.scss';

export type ChatWindowStackMode = 'docked' | 'standalone';

interface ChatWindowStackProps {
  mode?: ChatWindowStackMode,
  chats: ChatMediator[],
  focusedChats: ChatMediator[],
}

function ChatWindowStack(props: React.PropsWithChildren<ChatWindowStackProps>) {
  const p = useProps(props);
  const s = useStore(() => ({
    get mode() {
      return p.mode ?? 'standalone';
    },
    get hasMoreThanOneWindow() {
      return p.focusedChats.length > 1;
    },
  }));
  const ref = useObservableRef<HTMLDivElement>();
  useMakeFocusable(ref, '.ChatWindow');
  return <Observer children={() => (
    <div 
      className={
        joinClassName(
          'ChatWindowStack',
          s.hasMoreThanOneWindow && 'compact'
        )
      } 
      data-number-of-opened-windows={p.focusedChats.length > 5 ? 'many' : p.focusedChats.length}
      data-mode={s.mode}
      tabIndex={0} 
      ref={ref}
    >
      <div className="ChatWindowStackInner">
        {
          p.chats.map(c => <ChatWindow
            key={c.id}
            mode={p.mode}
            chat={c}
          />)
        }
      </div>
    </div>
  )} />
}

export default ChatWindowStack;