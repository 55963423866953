import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseButton from '../../../../../base/components/BaseButton/BaseButton';
import BaseIcon from '../../../../../base/components/BaseIcon/BaseIcon';
import BaseSpacer from '../../../../../base/components/BaseSpacer/BaseSpacer';
import SimpleCard from '../../../../../base/components/SimpleCard/SimpleCard';
import { CounsellingType } from '../../../../../constants/counsellingTypes.descriptors';
import { getWordPressLink } from '../../../../../env';
import { CounsellingApplicationStepProps } from '../applicationStepDescriptors';
import './CounsellingApplicationStepRiskAssessment.scss';

interface CounsellingApplicationStepRiskAssessmentProps extends CounsellingApplicationStepProps { }

const CounsellingApplicationStepRiskAssessment: React.FC<CounsellingApplicationStepRiskAssessmentProps> = p => {
  const isYPApplication = p.form.application.type === CounsellingType.YoungPeople1214 || p.form.application.type === CounsellingType.YoungPeople1517;
  return <Observer children={() => (
    <div className="CounsellingApplicationStepRiskAssessment">
      <blockquote>
        <p>Would you consider { isYPApplication ? 'that your child is' : 'yourself' } in severe distress and at immediate risk of self-harm?</p>
      </blockquote>
      <BaseSpacer />
      <div className="CounsellingApplicationStepRiskAssessmentOptionGroup">
        <SimpleCard className="CounsellingApplicationStepRiskAssessmentOption --true"
          data-selected={p.form.application.isAtRisk === true}
          onClick={action(() => p.form.application.isAtRisk = true)}
        >
          <span>Yes</span>
        </SimpleCard>
        <SimpleCard className="CounsellingApplicationStepRiskAssessmentOption --false"
          data-selected={p.form.application.isAtRisk === false}
          onClick={action(() => p.form.application.isAtRisk = false)}
        >
          <span>No</span>
        </SimpleCard>
      </div>
      <BaseSpacer />
      {
        p.form.application.isAtRisk && <div className="CounsellingApplicationStepRiskAssessmentWarning">
          <BaseIcon icon="warning" size="32" />
          <p>If {isYPApplication ? 'your child is' : 'you are'} at immediate risk of harming {isYPApplication ? 'themselves' : 'yourself'}, then Online Counselling may not be suitable at this time. We strongly suggest that you inform your local emergency services, health care provider or GP that {isYPApplication  ? 'your child might be about to harm themselves' : 'you may be about to harm yourself'}.</p>
          <BaseButton icon="question" href={getWordPressLink('/crisis-support')} target="_blank">Where do I get Help?</BaseButton>
        </div>
      }
    </div>
  )} />
}

export default CounsellingApplicationStepRiskAssessment;