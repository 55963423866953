import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject } from '../../@types';
import { Form, FormField } from '../../mediators/form.mediator';
import { useProps, useStore } from '../../utils/mobx.utils';
import BaseSelector, { BaseSelectorProps, DefaultOptionType, DefaultSingleValueType } from '../BaseSelector/BaseSelector';
import { useFormContext } from '../FormForm/Form.context';
// import './FormSelector.scss';

export interface FormSelectorProps<
  SourceType extends AnyObject,
  OptionType extends any = DefaultOptionType,
  SingleValueType extends DefaultSingleValueType = DefaultSingleValueType,
  ValueFieldType extends SingleValueType | string[] = SingleValueType,
> extends Omit<BaseSelectorProps<
  SourceType, 
  OptionType, 
  SingleValueType, 
  ValueFieldType
>, 'form'> {
  form: Form<SourceType>
}

function FormSelector<
  SourceType extends AnyObject,
  OptionType extends any = DefaultOptionType,
  SingleValueType extends DefaultSingleValueType = DefaultSingleValueType,
  ValueFieldType extends SingleValueType | string[] = SingleValueType,
  >(props: FormSelectorProps<SourceType, OptionType, SingleValueType, ValueFieldType>) {

  const context = useFormContext<Form<SourceType>>();
  const { form } = context;
  const p = useProps(props);
  const s = useStore(() => ({
    get form() { return p.form ?? form },
    get fieldForm() {
      return s.form.fields[props.field as any];
    }
  }));

  return <Observer children={() => (
    s.fieldForm ? <BaseSelector<FormField<SourceType>, OptionType, SingleValueType, ValueFieldType>
      {...props}
      form={s.fieldForm}
      field='value'
      name={p.name ?? p.field}
      disabled={p.disabled || context.disabled}
    /> : null
  )} />
}

export default FormSelector;