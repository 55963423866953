import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useStore } from '../../base/utils/mobx.utils';
import VoipCallEntry from '../VoipCallEntry/VoipCallEntry';
import VoipCallStarter from '../VoipCallStarter/VoipCallStarter';
import './MessengerPanelVoipCallList.scss';

interface MessengerPanelVoipCallListProps {}

const MessengerPanelVoipCallList: React.FC<MessengerPanelVoipCallListProps> = p => {

  const { VOIP } = useControllers();

  const s = useStore(() => ({
    get calls() {
      return VOIP.voipCalls;
    },
  }));

  return <Observer children={() => (
    VOIP.ready ? (
      <div className="MessengerPanelVoipCallList">
        <header>
          <h3>VOIP Calls</h3>
          {VOIP.deviceStatus === 'offline' ? <p><em className="MessengerPanelVoipErrorMessage">VOIP service seems to be offline...</em></p> : (
            <p>Start a call by searching for a user or enter a phone number.</p>
          )}
        </header>
        <VoipCallStarter color='orange' />
        {
          s.calls.map(c => <VoipCallEntry
            key={c.id}
            call={c}
            color='orange'
          />)
        }
      </div>
    ) : <div className="MessengerPanelVoipCallListPlaceholder"></div>
  )} />
}

export default MessengerPanelVoipCallList;