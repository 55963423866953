import { Observer } from 'mobx-react-lite';
import React from 'react';
import ColorTag from '../../base/components/ColorTag/ColorTag';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { capitalize } from '../../base/utils/string.utils';
// import './SessionPaymentStatus.scss';

type SessionPaymentStatusProps = {
  status: 'refunded' | 'paid' | 'donated' | 'free' | 'pending',
}

const SessionPaymentStatus: React.FC<SessionPaymentStatusProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get color() {
      switch (p.status) {
        case 'refunded':
          return 'red';
        case 'paid':
        case 'donated':
          return 'green';
        case 'free':
          return 'skyblue';
        case 'pending':
        default:
          return 'orange';
      }
    },
  }))
  return <Observer children={() => (
    <ColorTag className="SessionPaymentStatus" color={s.color}>{capitalize(p.status)}</ColorTag>
  )} />
}

export default SessionPaymentStatus;