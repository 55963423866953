import { isObservable, when } from 'mobx';
import React, { MutableRefObject } from 'react';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useControllers } from '../../hooks/useRootController.hook';
import joinClassName from '../../utils/className.utils';
import { copyElementContents } from '../../utils/dom.utils';
import BaseButton from '../BaseButton/BaseButton';
import BaseIcon from '../BaseIcon/BaseIcon';
import './DOMCopyButton.scss';

interface DOMCopyButtonProps {
	targetRef: MutableRefObject<HTMLElement | SVGElement | null>;
	showButtonBackground?: boolean,
	dataCy?: string;
}

const DOMCopyButton: React.FC<DOMCopyButtonProps> = p => {

	const { UI } = useControllers();
	const copyTarget = async () => {
		when(
			() => Boolean(p.targetRef.current),
			() => {
				try {
					copyElementContents(p.targetRef.current);
					UI.TOAST.success('Copied selection to clipboard!');
				} catch (e) {
					UI.TOAST.attention('Failed to copy; please try to copy the content manually.');
				}
			}
		)
	}

	useOnMount(() => {
		if (!isObservable(p.targetRef)) {
			console.warn('the targetRef passed to DOMCopyButton is not an observable and its value might be stale. Please make sure you have created the ref object with useObservableRef.');
		}
	})

	return <BaseButton
		className={joinClassName("DOMCopyButton subtle", p.showButtonBackground && 'showButtonBackground')}
		appearance="icon"
		onClick={copyTarget}
		data-cy={p.dataCy}
	>
		<BaseIcon name="copy" />
	</BaseButton>

}

export default DOMCopyButton;