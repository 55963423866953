import React from 'react';
import { Nillable } from '../../base/@types';
import { toSentenceCase } from '../../base/utils/string.utils';
import { genderDescriptors } from '../../constants/genders.constants';

interface GenderRendererProps {
  value?: Nillable<string>,
}

const GenderRenderer: React.FC<GenderRendererProps> = props => {
  const displayName = genderDescriptors.find(i => i.value === props.value)?.name || toSentenceCase(props.value);
  return <span className="GenderRenderer" data-gender={props.value}>
    {displayName}
  </span>
}

export default GenderRenderer;