import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import { AnyObject, Nullable } from '../../../base/@types';
import BaseButton from '../../../base/components/BaseButton/BaseButton';
import BaseInput from '../../../base/components/BaseInput/BaseInput';
import BaseSpacer from '../../../base/components/BaseSpacer/BaseSpacer';
import ErrorRenderer from '../../../base/components/ErrorRenderer/ErrorRenderer';
import HeadingAllcaps from '../../../base/components/HeadingAllcaps/HeadingAllcaps';
import LinkGroup from '../../../base/components/LinkGroup/LinkGroup';
import WindowTitle from '../../../base/components/WindowTitle/WindowTitle';
import { useObservableRef } from '../../../base/hooks/useObservableRef.hook';
import { useControllers } from '../../../base/hooks/useRootController.hook';
import { useUpdatePageBackground } from '../../../base/hooks/useSetPageBackground.hook';
import { useStore } from '../../../base/utils/mobx.utils';
import Turn2MeLogo from '../../../components/Turn2MeLogo/Turn2MeLogo';
import { isLocalhost } from '../../../env';
import AuthForm from '../_components/AuthForm/AuthForm';
import AuthPage from '../_components/AuthPage/AuthPage';
import './PageLogin.scss';

interface PageLoginProps {}

const PageLogin: React.FC<PageLoginProps> = props => {

  const { AUTH, UI } = useControllers();

  const buttonRef = useObservableRef<HTMLButtonElement | HTMLAnchorElement>();

  const shouldQuickFillFormForDev = false;

  const s = useStore(() => ({
    form: isLocalhost && shouldQuickFillFormForDev ? {
      username: 'saturn',
      password: 'saturn99',
      remember: true,
    } : {
      username: '',
      password: '',
      remember: false,
    },
    get formValid() {
      return Boolean(s.form.username && s.form.password);
    },
    awaitingResponse: false,
    succeeded: false,
    lastError: null as Nullable<Error>,
    login: () => flow(function * () {
      if (!s.form.username) {
        UI.DIALOG.attention({
          name: 'login-failure-no-username',
          heading: 'Please enter your username.'
        });
        return;
      }
      if (!s.form.password) {
        UI.DIALOG.attention({
          name: 'login-failure-no-password',
          heading: 'Please enter your password.'
        });
        return;
      }
      s.awaitingResponse = true;
      try {
        yield AUTH.login(s.form.username, s.form.password)
        s.succeeded = true;
      } catch(e) {
        UI.DIALOG.error({
          name: 'login-failure',
          heading: 'Failed to log in.',
          body: <ErrorRenderer error={(e as AnyObject).response} />
        })
        s.lastError = e as Error;
      } finally {
        s.awaitingResponse = false;
      }
      return;
    })(),
    proxyLogin: () => {
      buttonRef.current ? buttonRef.current.click() : s.login();
    }
  }));

  useUpdatePageBackground('#0151ca', 'radial-gradient(204.06% 99.27% at 51.45% 117.53%, rgba(55, 218, 255, 0.863) 1.04%, rgba(55, 218, 255, 0) 100%)');

  return <Observer children={() => (
    <AuthPage className="PageLogin">

      <header className="PageLoginHeader">
        <Link to="/"><Turn2MeLogo version="full" /></Link>
        <WindowTitle title="Log in" />
        <h1>Welcome back!</h1>
        <HeadingAllcaps>Log in to turn2me</HeadingAllcaps>
        <BaseSpacer size=".5em" />
      </header>

      <div className="PageLoginBody">
        <AuthForm>
          <BaseInput form={s.form} field="username" label="Username" onEnter={s.proxyLogin} disabled={s.awaitingResponse} autoCapitalize="off" autoCorrect="off" autoFocus/>
          <BaseInput form={s.form} field="password" type="password" label="Password" onEnter={s.proxyLogin} disabled={s.awaitingResponse}/>
          <BaseSpacer size=".5em" />
          {/* <BaseCheckbox form={s.form} field="remember">Keep me logged in</BaseCheckbox> */}
          <BaseButton className="AuthFormSubmit" onClick={s.login} size="lg" icon="arrow" name="login" circle innerRef={buttonRef} disabled={s.awaitingResponse}/>
        </AuthForm>
      </div>

      <footer>
        <LinkGroup allCaps noUnderscore direction="column">
          <Link to="/auth/register" title="Create free account">→ Create free account</Link>
          <Link to="/auth/forgot-password" title="Forgot Password">→ Forgot Password?</Link>
        </LinkGroup>
      </footer>

    </AuthPage>
  )} />

}

export default PageLogin;