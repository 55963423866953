import React from "react";
import { makeIconDef } from "./makeIconDef";

export const MailIconDef = {
  mail: makeIconDef('mail', {
    regular: () => <path fillRule="evenodd" clipRule="evenodd" d="M4 8.44098L4 6L20 6V8.44098L12 12.441L4 8.44098ZM4 9.55902V18H20V9.55902L12.2236 13.4472L12 13.559L11.7764 13.4472L4 9.55902ZM3 6V8.5V18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18V8.5V6C21 5.44772 20.5523 5 20 5H4C3.44772 5 3 5.44772 3 6Z" fill="currentColor" />,
    filled: () => <path fillRule="evenodd" clipRule="evenodd" d="M3 7.99989V6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6V7.99989C20.9247 7.99983 20.8483 8.01683 20.7764 8.05279L12 12.441L3.22361 8.05279C3.1517 8.01683 3.07527 7.99983 3 7.99989ZM3 9.05902V18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18V9.05902L12.2236 13.4472L12 13.559L11.7764 13.4472L3 9.05902Z" fill="currentColor" />,
    color: () => <>
      <path d="M3 8.5V6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6V8.5L12 13L3 8.5Z" fill="url(#WPF92N0_radial)" />
      <path d="M3 8.5V18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18V8.5L12 13L3 8.5Z" fill="url(#WPF92N1_radial)" />
      <defs>
        <radialGradient id="WPF92N0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.5263 14.6842) rotate(-145.726) scale(17.1966 14.1597)">
          <stop stopColor="#8BDF7D" />
          <stop offset="1" stopColor="#10A78C" />
        </radialGradient>
        <radialGradient id="WPF92N1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.5263 21.2105) rotate(-138.189) scale(19.0656 16.7627)">
          <stop stopColor="#66B259" />
          <stop offset="1" stopColor="#007B65" />
        </radialGradient>
      </defs>
    </>
  })
}