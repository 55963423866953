import { Fee, FeeRelatedModels } from "../../models/makeFee.model";
import { StringKeyOf } from "../@types";
import { DefaultIndexParamSet } from "../utils/api.utils";
import { makeApiEndpointConfig, makeApiGetEndpointConfig } from "../utils/endpoints.utils";
import { makeUrl } from "../utils/url.utils";
import { prefixStaff } from "./namespacePrefixes";

export type FeeEndpointParams = DefaultIndexParamSet<Fee, FeeRelatedModels> & {
  include?: (StringKeyOf<FeeRelatedModels> | string)[],
}

const staffUrlBase = prefixStaff('fees');

export const FeeEndpoints = {
  staff: {
    index: makeApiEndpointConfig<FeeEndpointParams>(staffUrlBase),
    get: makeApiGetEndpointConfig<FeeEndpointParams>(staffUrlBase),
    create: () => staffUrlBase,
    update: (id: string) => makeUrl(staffUrlBase, id),
    delete: (id: string) => makeUrl(staffUrlBase, id),
  },
}

export const defaultFeeEndpointIncludes: (StringKeyOf<FeeRelatedModels> | string)[] = ['user'];