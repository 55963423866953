import { Contact, ContactRelatedModels } from "../../models/makeContact.model";
import { DefaultIndexParamSet } from "../utils/api.utils";
import { makeApiEndpointConfig, makeApiGetEndpointConfig } from "../utils/endpoints.utils";
import { makeUrl } from "../utils/url.utils";
import { prefixOwn, prefixStaff } from "./namespacePrefixes";

export type ContactEndpointParams = DefaultIndexParamSet<Contact, ContactRelatedModels> & {
  include?: (keyof ContactRelatedModels | string)[],
}
const ownUrlBase = prefixOwn('contacts');
const staffUrlBase = prefixStaff('contacts');

export const ContactEndpoints = {
  own: {
    index: makeApiEndpointConfig<ContactEndpointParams>(ownUrlBase),
    get: makeApiGetEndpointConfig<ContactEndpointParams>(ownUrlBase),
    create: () => ownUrlBase,
    update: (id: string) => makeUrl(ownUrlBase, id),
    delete: (id: string) => makeUrl(ownUrlBase, id),
  },
  staff: {
    index: makeApiEndpointConfig<ContactEndpointParams>(staffUrlBase),
    get: makeApiGetEndpointConfig<ContactEndpointParams>(staffUrlBase),
    create: () => staffUrlBase,
    update: (id: string) => makeUrl(staffUrlBase, id),
    delete: (id: string) => makeUrl(staffUrlBase, id),
  },
}