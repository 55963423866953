import { reaction, runInAction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { BaseTableColumnConfig } from '../../../base/components/BaseTable/BaseTable';
import ColorTagCompany from '../../../base/components/ColorTag/ColorTagCompany';
import DateRenderer from '../../../base/components/DateRenderer/DateRenderer';
import IndexDirectory from '../../../base/components/IndexDirectory/IndexDirectory';
import IndexDirectoryState from '../../../base/components/IndexDirectory/IndexDirectoryState';
import { CounsellingApplicationEndpoints, DefaultCounsellingApplicationIncludes } from '../../../base/endpoints/counsellingApplication.endpoints';
import { useOnMount } from '../../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../../base/hooks/useRootController.hook';
import { makeLaravelIndexDataFetcher } from '../../../base/utils/api.utils';
import { useProps, useStore } from '../../../base/utils/mobx.utils';
import { YYYYMMDD } from '../../../base/utils/time.utils';
import { tick } from '../../../base/utils/waiters.utils';
import { ModelName } from '../../../constants/modelNames.enum';
import { Company } from '../../../models/makeCompany.model';
import { CounsellingApplication } from '../../../models/makeCounsellingApplication.model';
import { counsellingApplicationTypeCellRenderer } from '../../../modules/Admin/_configs/counsellingApplicationTableColumnConfigsForAdmin.config';
import CommunicationTypeRenderer from '../../CommunicationTypeRenderer/CommunicationTypeRenderer';
import CounsellingApplicationCompositeStatusColorTag from '../../CounsellingApplicationCompositeStatusColorTag/CounsellingApplicationCompositeStatusColorTag';
import SlotTypeRenderer from '../../SlotTypeRenderer/SlotTypeRenderer';
import UsernameRenderer from '../../UsernameRenderer/UsernameRenderer';
import './CompanyApplicationIndexDirectory.scss';

type CompanyApplicationIndexDirectoryProps = {
  company?: Company,
}

const CompanyApplicationIndexDirectory: React.FC<CompanyApplicationIndexDirectoryProps> = React.memo(props => {

  const { API } = useControllers();

  const p = useProps(props);
  const s = useStore(() => ({
    get company() { return p.company; },
    indexListState: new IndexDirectoryState({
      searchable: true,
      searchByKeyName: 'applicant.username',
      sortByKeyName: 'timeCreated',
      sortDirection: 'desc',
    }),
    shouldTriggerReload: false,
  }))

  useOnMount(() => {
    return reaction(
      () => s.shouldTriggerReload,
      async (v) => {
        if (v) {
          await tick(100);
          runInAction(() => {
            s.shouldTriggerReload = false;
          })
        }
      }
    )
  })

  const dataFetcher = makeLaravelIndexDataFetcher<CounsellingApplication>(
    API,
    ModelName.counsellingApplications,
    CounsellingApplicationEndpoints.staff.index,
    () => ({
      perPage: 10,
      include: DefaultCounsellingApplicationIncludes,
      filter: {
        'availability.allowedCompanyId': s.company?.id,
      },
    }),
  )

  const tableColumnConfigs: BaseTableColumnConfig<CounsellingApplication>[] = [
    {
      label: 'ID',
      keyName: 'id',
      sortable: true,
      searchable: true,
    },
    {
      label: 'Type',
      keyName: 'type',
      BodyCell: d => <><SlotTypeRenderer isPaid={d.isPaid} />{counsellingApplicationTypeCellRenderer(d)}</>,
      sortable: true,
      searchable: true,
    },
    {
      label: 'Applied on',
      keyName: 'timeCreated',
      type: 'timestamp',
      sortable: true,
      searchable: true,
      BodyCell: a => <DateRenderer value={a.timeCreated} format={YYYYMMDD} />
    },
    {
      label: 'Applicant',
      keyName: 'applicantId',
      searchable: 'applicant.username',
      searchFilterLabel: 'Applicant Username',
      sortable: true,
      BodyCell: a => <UsernameRenderer userId={a.applicantId} showColorLabel />,
      bodyCellStyleFactory: () => ({
        maxWidth: '200px',
      })
    },
    {
      label: 'Counsellor',
      keyName: 'counsellorId',
      sortable: true,
      searchFilterLabel: 'Counsellor Username',
      searchable: 'counsellor.username',
      BodyCell: d => d.counsellorId ? <UsernameRenderer user={d.counsellor} userId={d.counsellorId} showColorLabel /> : '',
    },
    {
      label: 'Company',
      keyName: 'availability',
      BodyCell: d => <ColorTagCompany name={d.availability?.allowedCompany?.name} code={d.availability?.allowedCompany?.code} />
    },
    {
      label: 'Status',
      dataCy: 'status',
      BodyCell: d => <CounsellingApplicationCompositeStatusColorTag application={d} />,
      searchable: false,
    },
    {
      label: 'Comm. Type',
      keyName: 'communicationTypePreference',
      BodyCell: d => <CommunicationTypeRenderer value={d.communicationTypePreference} textOnly />,
      sortable: true,
      searchFilterLabel: 'Communication Type',
    },
  ]

  return <Observer children={() => (
    <IndexDirectory<CounsellingApplication>
      className="CompanyApplicationIndexDirectory"
      state={s.indexListState}
      dataFetcher={dataFetcher}
      tablePropsGetter={() => ({
        columnConfigs: tableColumnConfigs,
      })}
      shouldTriggerReload={s.shouldTriggerReload}
    />
  )} />
})

export default CompanyApplicationIndexDirectory;