import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState } from '../../../../base/@types';
import BaseButton from '../../../../base/components/BaseButton/BaseButton';
import BaseButtonGroup from '../../../../base/components/BaseButtonGroup/BaseButtonGroup';
import BaseSpacer from '../../../../base/components/BaseSpacer/BaseSpacer';
import ClickToCopy from '../../../../base/components/ClickToCopy/ClickToCopy';
import EmailLinkRenderer from '../../../../base/components/EmailLinkRenderer/EmailLinkRenderer';
import ErrorRenderer from '../../../../base/components/ErrorRenderer/ErrorRenderer';
import InfoBanner from '../../../../base/components/InfoBanner/InfoBanner';
import { CounsellingApplicationEndpoints } from '../../../../base/endpoints/counsellingApplication.endpoints';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import { NoOp } from '../../../../base/utils/functions.utils';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import { equalByString, toSentenceCase } from '../../../../base/utils/string.utils';
import CounsellingTypeColorTag from '../../../../components/CounsellingTypeColorTag/CounsellingTypeColorTag';
import UsernameRenderer from '../../../../components/UsernameRenderer/UsernameRenderer';
import { CommunicationType } from '../../../../constants/communicationTypes.descriptors';
import { ModelName } from '../../../../constants/modelNames.enum';
import { CounsellingApplication } from '../../../../models/makeCounsellingApplication.model';
import { getInvitationLink } from '../../../../utils/counsellingApplication.utils';
import OverlayApplicationRejectionConfirm from '../OverlayApplicationRejectionConfirm/OverlayApplicationRejectionConfirm';
import './CounsellingApplicationActionsPanel.scss';

interface CounsellingApplicationActionsPanelProps {
  application: CounsellingApplication
}

const CounsellingApplicationActionsPanel: React.FC<CounsellingApplicationActionsPanelProps> = props => {

  const p = useProps(props);
  const { API, UI, AUTH } = useControllers();

  const s = useStore(() => ({
    get application() {
      return p.application;
    },
    get consentNeeded() {
      return s.application.type === 'couples' || s.application.type?.includes('young-people');
    },
    get consentGiven() {
      return Boolean(s.application.invitation?.timeAccepted);
    },
    get hasCounsellor() {
      return Boolean(s.application.counsellorId || s.application.counsellor);
    },
    get counsellorId() {
      return s.application.counsellor?.id || s.application.counsellorId;
    },
    get currentUserId() {
      return AUTH.currentUser?.id;
    },
    get currentUserIsTheAssignedCounsellor() {
      return equalByString(s.counsellorId, s.currentUserId);
    },
    get isEmail() {
      return s.application.communicationTypePreference === CommunicationType.Email;
    },
    get invitationLink() {
      return getInvitationLink(s.application.invitation?.uuid, s.application.type, s.application.applicant?.email);
    },
  }));

  const confirmApproveApplication = () => {
    UI.DIALOG.present({
      name: 'confirmApproveApplication',
      heading: 'Are you sure you want to approve this application?',
      body: () => <>
        { !s.currentUserIsTheAssignedCounsellor && s.hasCounsellor && <p><strong>Please note that this application is assigned to counsellor <UsernameRenderer showColorLabel user={s.application.counsellor} userId={s.application.counsellorId}/></strong>.</p>}
        <p>A notification will be sent to the client immediately about the approval of this application.</p>
        { s.isEmail && <p>As this is an <strong>email application</strong>, you can start the first session at a suitable time by sending the user an introductory email after approval.</p>}
      </>,
      defaultActions: ['negative'],
      actions: [
        {
          name: 'confirm',
          label: 'Confirm & Approve',
          action: approveApplication,
        }
      ]
    });
  }

  const approveApplication = () => new Promise(async (resolve, reject) => {
    try {
      const { id } = s.application;
      const url = CounsellingApplicationEndpoints.staff.approve(id);
      const response = await API.post<CounsellingApplication>(url, ModelName.counsellingApplications);
      response && s.application.$patch(response);
      UI.DIALOG.success({
        name: 'approveApplicationSuccess',
        heading: 'This application has been approved!',
        actions: [
          {
            name: 'positive',
            label: 'OK',
            action: NoOp,
          }
        ]
      })
      resolve(true);
    } catch(e) {
      reject(e);
      UI.DIALOG.error({
        name: 'approveApplicationFailed',
        heading: 'Failed to approve this application.',
        body: <ErrorRenderer error={(e as any).response} />,
        defaultActions: ['positive'],
      })
    }
  })

  const rejectApplication = () => {
    const onSuccess = flow(function* () {

    })
    UI.OVERLAY.present({
      component: <OverlayApplicationRejectionConfirm application={s.application} onSuccess={onSuccess} />,
      appearance: 'card',
    })
  }

  return <Observer children={() => (
    <>
      <div className="CounsellingApplicationActionsPanel">

        <p>Please review the application. When you approve the application, the client will be notified. You will then be able to schedule counselling sessions with the client.</p>

        <BaseButtonGroup>
          <BaseButton color="green" onClick={confirmApproveApplication} disabled={s.consentNeeded && !s.consentGiven} fullWidth={UI.onlyPhones} dataCy="approve-application">Approve Application</BaseButton>
          {UI.onlyPhones && <BaseSpacer size=".5em" />}
          <BaseButton className="subtle" color="red" onClick={rejectApplication} fullWidth={UI.onlyPhones} dataCy="reject-application">Reject Application…</BaseButton>
        </BaseButtonGroup>

      </div>

      {
        s.consentNeeded && !s.consentGiven && <InfoBanner className="CounsellingInvitationPendingNotice" colorCodedState={ColorCodedState.attention} icon="warning">
          <p>This is an application of type <CounsellingTypeColorTag value={s.application.type} />, and the invited user <EmailLinkRenderer value={s.application.invitation?.email} /> (the <strong>{toSentenceCase(s.application.invitation?.actingAs)})</strong> must accept the invitation and give consent before it can be approved.</p>
          {
            s.invitationLink && <>
              <p>If the applicant is having trouble finding or forwarding the invitation link, you can help them by sending this link to the invited email address:</p>
              <ClickToCopy text={s.invitationLink} buttonLabel="Copy Link" successMessageComposer={() => "Link copied!"}><code>{s.invitationLink}</code></ClickToCopy>
            </>
          }
        </InfoBanner>
      }

    </>
  )} />

}

export default CounsellingApplicationActionsPanel;