import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import joinClassName from '../../base/utils/className.utils';
import './SmallHeaderButton.scss';

interface SmallHeaderButtonProps {
  className?: string,
  color?: string,
  label?: string,
  to?: string,
  onClick?: () => void,
  disabled?: boolean,
}

const SmallHeaderButton: React.FC<SmallHeaderButtonProps> = p => {
  return <Observer children={() => (
    <BaseButton
      className={
        joinClassName('SmallHeaderButton subtle', p.className)
      }
      size="xs"
      label={p.label ?? (p.children ? undefined : 'Details')}
      rounded
      color={p.color}
      to={p.to}
      onClick={p.onClick}
      children={p.children}
      disabled={p.disabled}
    />
  )} />
}

export default SmallHeaderButton;