import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseArticle from '../../base/components/BaseArticle/BaseArticle';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import BaseInput from '../../base/components/BaseInput/BaseInput';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import { useProps } from '../../base/utils/mobx.utils';
import { SurveySupportGroupSatisfaction } from '../../models/makeSurveySupportGroupSatisfaction';
import SurveySelectorWithFourLevels from '../SurveySelectorWithFourLevels/SurveySelectorWithFourLevels';
import './SurveyEditorSupportGroupSatisfaction.scss';

type SurveyEditorSupportGroupSatisfactionProps = {
  survey: SurveySupportGroupSatisfaction,
  readonly?: boolean,
}

const SurveyEditorSupportGroupSatisfaction: React.FC<SurveyEditorSupportGroupSatisfactionProps> = props => {
  const p = useProps(props);
  return <Observer children={() => (
    <BaseArticle className="SurveyEditorSupportGroupSatisfaction">
      <div className="SurveyEditorSatisfactionQuestion">
        <BaseIcon icon="question" />
        <p>How would you rate your Support Group experience with turn2me?</p>
        <SurveySelectorWithFourLevels form={p.survey} field="ratingOfExperience" readonly={p.readonly} labelType="satisfaction" />
      </div>
      <div className="SurveyEditorSatisfactionQuestion">
        <BaseIcon icon="question" />
        <p>How would you rate your progress from using the support group? (If Applicable)</p>
        <SurveySelectorWithFourLevels form={p.survey} field="ratingSelfProgress" readonly={p.readonly} labelType="satisfaction" nullable />
      </div>
      <div className="SurveyEditorSatisfactionQuestion">
        <BaseIcon icon="question" />
        <p>How would you rate your relationship with other users in the support group?</p>
        <SurveySelectorWithFourLevels form={p.survey} field="ratingOfOtherUsersRelationship" readonly={p.readonly} labelType="satisfaction" />
      </div>
      <div className="SurveyEditorSatisfactionQuestion">
        <BaseIcon icon="question" />
        <p>How was your experience of using our website?</p>
        <SurveySelectorWithFourLevels form={p.survey} field="ratingOfWebsiteExperience" readonly={p.readonly} labelType="satisfaction" />
      </div>
      <div className="SurveyEditorSatisfactionQuestion">
        <BaseIcon icon="question" />
        <p><em>Optional (leave blank if not relevant):</em></p>
        <p>How would you rate your experience of Online support groups compared to Face-to-Face support groups?</p>
        <SurveySelectorWithFourLevels form={p.survey} field="ratingOfOnlineVsOffline" readonly={p.readonly} labelType="satisfaction" nullable />
      </div>
      {
        p.readonly ? <>
          <p><strong>Message</strong></p>
          <ShadedBlock>
            {p.survey.body || <em>The client didn't leave a message.</em>}
          </ShadedBlock>
        </> : <>
          <p><strong>Leave a message: (Optional)</strong></p>
          <BaseInput type="textarea" form={p.survey} field="body" rows="5" />
        </>
      }
    </BaseArticle>
  )} />
}

export default SurveyEditorSupportGroupSatisfaction;