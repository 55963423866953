import { always } from "../../../../../base/utils/ramdaEquivalents.utils";
import { makeObservableRouteDef } from "../../../../../base/utils/routes.utils";
import AccountPageCompany from "./AccountPageCompany.page";

export const AccountPageCompanyRouteDef = makeObservableRouteDef({
  identifier: 'account-company',
  urlFactory: always('company'),
  composeTitle: always('Company'),
  component: AccountPageCompany,
  icon: 'suitcase',
})