import { action, runInAction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { AnyObject } from '../../@types';
import joinClassName from '../../utils/className.utils';
import { checkIfShouldInvertStyle } from '../../utils/colors.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import './RatingInput.scss';
import RatingInputStar from './RatingInputStar';

export type ValidRatingInputValue = 0 | 1 | 2 | 3 | 4 | 5;

interface RatingInputProps<T extends AnyObject = AnyObject> {
  form: T,
  field: keyof T,
  color?: string,
}

const RatingInput: React.FC<RatingInputProps> = props => {

  const p = useProps(props);

  const ref = React.useRef(null);

  const s = useStore(() => ({
    validValues: [1, 2, 3, 4, 5] as ValidRatingInputValue[],
    innerValue: p.form[p.field] as ValidRatingInputValue,
    shouldInvert: false,
  }));

  const onStarMouseEnter = action((value: ValidRatingInputValue) => {
    const v = value < 0 ? 0 : value > 5 ? 5 : Math.round(value) as ValidRatingInputValue;
    s.innerValue = v;
  })
  const onStarClick = action((value: ValidRatingInputValue) => {
    const v = value < 0 ? 0 : value > 5 ? 5 : Math.round(value);
    p.form[p.field] = v;
  })
  const onInputMouseLeave = action(() => {
    s.innerValue = p.form[p.field];
  })

  useEffect(() => {
    runInAction(() => {
      s.shouldInvert = checkIfShouldInvertStyle(ref);
    })
  });

  return <Observer children={() => (
    <div ref={ref}
      className={
        joinClassName(
          "RatingInput",
          s.shouldInvert && 'inverted',
        )
      }
      data-inner-value={s.innerValue}
      data-value={p.form[p.field]}
      onMouseLeave={onInputMouseLeave}
    >
      {s.validValues.map(value => <RatingInputStar
        key={value}
        inputValue={s.innerValue}
        forValue={value}
        onMouseEnter={onStarMouseEnter}
        onClick={onStarClick}
      />)}
    </div>
  )} />
  
}

export default RatingInput;