export type CountryCode = "AD" | "AE" | "AF" | "AG" | "AI" | "AL" | "AM" | "AO" | "AQ" | "AR" | "AS" | "AT" | "AU" | "AW" | "AX" | "AZ" | "BA" | "BB" | "BD" | "BE" | "BF" | "BG" | "BH" | "BI" | "BJ" | "BL" | "BM" | "BN" | "BO" | "BQ" | "BR" | "BS" | "BT" | "BV" | "BW" | "BY" | "BZ" | "CA" | "CC" | "CD" | "CF" | "CG" | "CH" | "CI" | "CK" | "CL" | "CM" | "CN" | "CO" | "CR" | "CU" | "CV" | "CW" | "CX" | "CY" | "CZ" | "DE" | "DJ" | "DK" | "DM" | "DO" | "DZ" | "EC" | "EE" | "EG" | "EH" | "ER" | "ES" | "ET" | "FI" | "FJ" | "FK" | "FM" | "FO" | "FR" | "GA" | "GB" | "GD" | "GE" | "GF" | "GG" | "GH" | "GI" | "GL" | "GM" | "GN" | "GP" | "GQ" | "GR" | "GS" | "GT" | "GU" | "GW" | "GY" | "HK" | "HM" | "HN" | "HR" | "HT" | "HU" | "ID" | "IE" | "IL" | "IM" | "IN" | "IO" | "IQ" | "IR" | "IS" | "IT" | "JE" | "JM" | "JO" | "JP" | "KE" | "KG" | "KH" | "KI" | "KM" | "KN" | "KP" | "KR" | "KW" | "KY" | "KZ" | "LA" | "LB" | "LC" | "LI" | "LK" | "LR" | "LS" | "LT" | "LU" | "LV" | "LY" | "MA" | "MC" | "MD" | "ME" | "MF" | "MG" | "MH" | "MK" | "ML" | "MM" | "MN" | "MO" | "MP" | "MQ" | "MR" | "MS" | "MT" | "MU" | "MV" | "MW" | "MX" | "MY" | "MZ" | "NA" | "NC" | "NE" | "NF" | "NG" | "NI" | "NL" | "NO" | "NP" | "NR" | "NU" | "NZ" | "OM" | "PA" | "PE" | "PF" | "PG" | "PH" | "PK" | "PL" | "PM" | "PN" | "PR" | "PS" | "PT" | "PW" | "PY" | "QA" | "RE" | "RO" | "RS" | "RU" | "RW" | "SA" | "SB" | "SC" | "SD" | "SE" | "SG" | "SH" | "SI" | "SJ" | "SK" | "SL" | "SM" | "SN" | "SO" | "SR" | "SS" | "ST" | "SV" | "SX" | "SY" | "SZ" | "TC" | "TD" | "TF" | "TG" | "TH" | "TJ" | "TK" | "TL" | "TM" | "TN" | "TO" | "TR" | "TT" | "TV" | "TW" | "TZ" | "UA" | "UG" | "UM" | "US" | "UY" | "UZ" | "VA" | "VC" | "VE" | "VG" | "VI" | "VN" | "VU" | "WF" | "WS" | "XK" | "YE" | "YT" | "ZA" | "ZM" | "ZW";

export type CountryDescriptor = {
  cca2: CountryCode,
  name: string,
  dialCode: string | string[] | null,
  flag: string,
}

export const IrelandCountryDescriptor: CountryDescriptor = { cca2: "IE", name: "Ireland", dialCode: "353", flag: "🇮🇪" };
export const UKCountryDescriptor: CountryDescriptor = { cca2: "GB", name: "United Kingdom (UK)", dialCode: "44", flag: "🇬🇧" };

export const CountryDescriptors: Record<CountryCode, CountryDescriptor> = {
  AD: { cca2: "AD", name: "Andorra", dialCode: "376", flag: "🇦🇩" },
  AE: { cca2: "AE", name: "United Arab Emirates (UAE)", dialCode: "971", flag: "🇦🇪" },
  AF: { cca2: "AF", name: "Afghanistan", dialCode: "93", flag: "🇦🇫" },
  AG: { cca2: "AG", name: "Antigua and Barbuda", dialCode: "1-268", flag: "🇦🇬" },
  AI: { cca2: "AI", name: "Anguilla", dialCode: "1-264", flag: "🇦🇮" },
  AL: { cca2: "AL", name: "Albania", dialCode: "355", flag: "🇦🇱" },
  AM: { cca2: "AM", name: "Armenia", dialCode: "374", flag: "🇦🇲" },
  AO: { cca2: "AO", name: "Angola", dialCode: "244", flag: "🇦🇴" },
  AQ: { cca2: "AQ", name: "Antarctica", dialCode: null, flag: "🇦🇶" },
  AR: { cca2: "AR", name: "Argentina", dialCode: "54", flag: "🇦🇷" },
  AS: { cca2: "AS", name: "American Samoa", dialCode: "1-684", flag: "🇦🇸" },
  AT: { cca2: "AT", name: "Austria", dialCode: "43", flag: "🇦🇹" },
  AU: { cca2: "AU", name: "Australia", dialCode: "61", flag: "🇦🇺" },
  AW: { cca2: "AW", name: "Aruba", dialCode: "297", flag: "🇦🇼" },
  AX: { cca2: "AX", name: "Åland Islands", dialCode: "358-18", flag: "🇦🇽" },
  AZ: { cca2: "AZ", name: "Azerbaijan", dialCode: "994", flag: "🇦🇿" },
  BA: { cca2: "BA", name: "Bosnia and Herzegovina", dialCode: "387", flag: "🇧🇦" },
  BB: { cca2: "BB", name: "Barbados", dialCode: "1-246", flag: "🇧🇧" },
  BD: { cca2: "BD", name: "Bangladesh", dialCode: "880", flag: "🇧🇩" },
  BE: { cca2: "BE", name: "Belgium", dialCode: "32", flag: "🇧🇪" },
  BF: { cca2: "BF", name: "Burkina Faso", dialCode: "226", flag: "🇧🇫" },
  BG: { cca2: "BG", name: "Bulgaria", dialCode: "359", flag: "🇧🇬" },
  BH: { cca2: "BH", name: "Bahrain", dialCode: "973", flag: "🇧🇭" },
  BI: { cca2: "BI", name: "Burundi", dialCode: "257", flag: "🇧🇮" },
  BJ: { cca2: "BJ", name: "Benin", dialCode: "229", flag: "🇧🇯" },
  BL: { cca2: "BL", name: "Saint Barthélemy", dialCode: "590", flag: "🇧🇱" },
  BM: { cca2: "BM", name: "Bermuda", dialCode: "1-441", flag: "🇧🇲" },
  BN: { cca2: "BN", name: "Brunei", dialCode: "673", flag: "🇧🇳" },
  BO: { cca2: "BO", name: "Bolivia", dialCode: "591", flag: "🇧🇴" },
  BQ: { cca2: "BQ", name: "Caribbean Netherlands", dialCode: "599", flag: "" },
  BR: { cca2: "BR", name: "Brazil", dialCode: "55", flag: "🇧🇷" },
  BS: { cca2: "BS", name: "Bahamas", dialCode: "1-242", flag: "🇧🇸" },
  BT: { cca2: "BT", name: "Bhutan", dialCode: "975", flag: "🇧🇹" },
  BV: { cca2: "BV", name: "Bouvet Island", dialCode: null, flag: "🇧🇻" },
  BW: { cca2: "BW", name: "Botswana", dialCode: "267", flag: "🇧🇼" },
  BY: { cca2: "BY", name: "Belarus", dialCode: "375", flag: "🇧🇾" },
  BZ: { cca2: "BZ", name: "Belize", dialCode: "501", flag: "🇧🇿" },
  CA: { cca2: "CA", name: "Canada", dialCode: [   
    '1-204', // Manitoba
    '1-226', // Ontario
    '1-236', // British Columbia
    '1-249', // Ontario
    '1-250', // British Columbia
    '1-289', // Ontario
    '1-306', // Saskatchewan
    '1-343', // Ontario
    '1-365', // Ontario
    '1-403', // Alberta
    '1-416', // Toronto, Ontario
    '1-418', // Quebec
    '1-431', // Manitoba
    '1-437', // Ontario
    '1-438', // Quebec
    '1-450', // Quebec
    '1-506', // New Brunswick
    '1-514', // Montreal, Quebec
    '1-519', // Ontario
    '1-579', // Quebec
    '1-581', // Quebec
    '1-587', // Alberta
    '1-604', // British Columbia
    '1-613', // Ontario
    '1-639', // Saskatchewan
    '1-647', // Ontario
    '1-705', // Ontario
    '1-709', // Newfoundland
    '1-778', // British Columbia
    '1-780', // Alberta
    '1-807', // Ontario
    '1-819', // Quebec
    '1-867', // Northwest Territories / Nunavut / Yukon
    '1-873', // Quebec
    '1-902', // Nova Scotia / Prince Edward Island
    '1-905', // Ontario
  ] as string[], flag: "🇨🇦" },
  CC: { cca2: "CC", name: "Cocos (Keeling) Islands", dialCode: "61", flag: "🇨🇨" },
  CD: { cca2: "CD", name: "DR Congo", dialCode: "243", flag: "🇨🇩" },
  CF: { cca2: "CF", name: "Central African Republic", dialCode: "236", flag: "🇨🇫" },
  CG: { cca2: "CG", name: "Republic of the Congo", dialCode: "242", flag: "🇨🇬" },
  CH: { cca2: "CH", name: "Switzerland", dialCode: "41", flag: "🇨🇭" },
  CI: { cca2: "CI", name: "Ivory Coast", dialCode: "225", flag: "🇨🇮" },
  CK: { cca2: "CK", name: "Cook Islands", dialCode: "682", flag: "🇨🇰" },
  CL: { cca2: "CL", name: "Chile", dialCode: "56", flag: "🇨🇱" },
  CM: { cca2: "CM", name: "Cameroon", dialCode: "237", flag: "🇨🇲" },
  CN: { cca2: "CN", name: "China", dialCode: "86", flag: "🇨🇳" },
  CO: { cca2: "CO", name: "Colombia", dialCode: "57", flag: "🇨🇴" },
  CR: { cca2: "CR", name: "Costa Rica", dialCode: "506", flag: "🇨🇷" },
  CU: { cca2: "CU", name: "Cuba", dialCode: "53", flag: "🇨🇺" },
  CV: { cca2: "CV", name: "Cape Verde", dialCode: "238", flag: "🇨🇻" },
  CW: { cca2: "CW", name: "Curaçao", dialCode: "599", flag: "🇨🇼" },
  CX: { cca2: "CX", name: "Christmas Island", dialCode: "61", flag: "🇨🇽" },
  CY: { cca2: "CY", name: "Cyprus", dialCode: "357", flag: "🇨🇾" },
  CZ: { cca2: "CZ", name: "Czechia", dialCode: "420", flag: "🇨🇿" },
  DE: { cca2: "DE", name: "Germany", dialCode: "49", flag: "🇩🇪" },
  DJ: { cca2: "DJ", name: "Djibouti", dialCode: "253", flag: "🇩🇯" },
  DK: { cca2: "DK", name: "Denmark", dialCode: "45", flag: "🇩🇰" },
  DM: { cca2: "DM", name: "Dominica", dialCode: "1-767", flag: "🇩🇲" },
  DO: { cca2: "DO", name: "Dominican Republic", dialCode: ["1-809", "1-829"] as string[], flag: "🇩🇴" },
  DZ: { cca2: "DZ", name: "Algeria", dialCode: "213", flag: "🇩🇿" },
  EC: { cca2: "EC", name: "Ecuador", dialCode: "593", flag: "🇪🇨" },
  EE: { cca2: "EE", name: "Estonia", dialCode: "372", flag: "🇪🇪" },
  EG: { cca2: "EG", name: "Egypt", dialCode: "20", flag: "🇪🇬" },
  EH: { cca2: "EH", name: "Western Sahara", dialCode: "212", flag: "🇪🇭" },
  ER: { cca2: "ER", name: "Eritrea", dialCode: "291", flag: "🇪🇷" },
  ES: { cca2: "ES", name: "Spain", dialCode: "34", flag: "🇪🇸" },
  ET: { cca2: "ET", name: "Ethiopia", dialCode: "251", flag: "🇪🇹" },
  FI: { cca2: "FI", name: "Finland", dialCode: "358", flag: "🇫🇮" },
  FJ: { cca2: "FJ", name: "Fiji", dialCode: "679", flag: "🇫🇯" },
  FK: { cca2: "FK", name: "Falkland Islands", dialCode: "500", flag: "🇫🇰" },
  FM: { cca2: "FM", name: "Micronesia", dialCode: "691", flag: "🇫🇲" },
  FO: { cca2: "FO", name: "Faroe Islands", dialCode: "298", flag: "🇫🇴" },
  FR: { cca2: "FR", name: "France", dialCode: "33", flag: "🇫🇷" },
  GA: { cca2: "GA", name: "Gabon", dialCode: "241", flag: "🇬🇦" },
  GB: UKCountryDescriptor,
  GD: { cca2: "GD", name: "Grenada", dialCode: "1-473", flag: "🇬🇩" },
  GE: { cca2: "GE", name: "Georgia", dialCode: "995", flag: "🇬🇪" },
  GF: { cca2: "GF", name: "French Guiana", dialCode: "594", flag: "🇬🇫" },
  GG: { cca2: "GG", name: "Guernsey", dialCode: "44-1481", flag: "🇬🇬" },
  GH: { cca2: "GH", name: "Ghana", dialCode: "233", flag: "🇬🇭" },
  GI: { cca2: "GI", name: "Gibraltar", dialCode: "350", flag: "🇬🇮" },
  GL: { cca2: "GL", name: "Greenland", dialCode: "299", flag: "🇬🇱" },
  GM: { cca2: "GM", name: "Gambia", dialCode: "220", flag: "🇬🇲" },
  GN: { cca2: "GN", name: "Guinea", dialCode: "224", flag: "🇬🇳" },
  GP: { cca2: "GP", name: "Guadeloupe", dialCode: "590", flag: "🇬🇵" },
  GQ: { cca2: "GQ", name: "Equatorial Guinea", dialCode: "240", flag: "🇬🇶" },
  GR: { cca2: "GR", name: "Greece", dialCode: "30", flag: "🇬🇷" },
  GS: { cca2: "GS", name: "South Georgia", dialCode: null, flag: "🇬🇸" },
  GT: { cca2: "GT", name: "Guatemala", dialCode: "502", flag: "🇬🇹" },
  GU: { cca2: "GU", name: "Guam", dialCode: "1-671", flag: "🇬🇺" },
  GW: { cca2: "GW", name: "Guinea-Bissau", dialCode: "245", flag: "🇬🇼" },
  GY: { cca2: "GY", name: "Guyana", dialCode: "592", flag: "🇬🇾" },
  HK: { cca2: "HK", name: "Hong Kong", dialCode: "852", flag: "🇭🇰" },
  HM: { cca2: "HM", name: "Heard Island and McDonald Islands", dialCode: null, flag: "🇭🇲" },
  HN: { cca2: "HN", name: "Honduras", dialCode: "504", flag: "🇭🇳" },
  HR: { cca2: "HR", name: "Croatia", dialCode: "385", flag: "🇭🇷" },
  HT: { cca2: "HT", name: "Haiti", dialCode: "509", flag: "🇭🇹" },
  HU: { cca2: "HU", name: "Hungary", dialCode: "36", flag: "🇭🇺" },
  ID: { cca2: "ID", name: "Indonesia", dialCode: "62", flag: "🇮🇩" },
  IE: IrelandCountryDescriptor,
  IL: { cca2: "IL", name: "Israel", dialCode: "972", flag: "🇮🇱" },
  IM: { cca2: "IM", name: "Isle of Man", dialCode: "44-1624", flag: "🇮🇲" },
  IN: { cca2: "IN", name: "India", dialCode: "91", flag: "🇮🇳" },
  IO: { cca2: "IO", name: "British Indian Ocean Territory", dialCode: "246", flag: "🇮🇴" },
  IQ: { cca2: "IQ", name: "Iraq", dialCode: "964", flag: "🇮🇶" },
  IR: { cca2: "IR", name: "Iran", dialCode: "98", flag: "🇮🇷" },
  IS: { cca2: "IS", name: "Iceland", dialCode: "354", flag: "🇮🇸" },
  IT: { cca2: "IT", name: "Italy", dialCode: "39", flag: "🇮🇹" },
  JE: { cca2: "JE", name: "Jersey", dialCode: "44-1534", flag: "🇯🇪" },
  JM: { cca2: "JM", name: "Jamaica", dialCode: "1-876", flag: "🇯🇲" },
  JO: { cca2: "JO", name: "Jordan", dialCode: "962", flag: "🇯🇴" },
  JP: { cca2: "JP", name: "Japan", dialCode: "81", flag: "🇯🇵" },
  KE: { cca2: "KE", name: "Kenya", dialCode: "254", flag: "🇰🇪" },
  KG: { cca2: "KG", name: "Kyrgyzstan", dialCode: "996", flag: "🇰🇬" },
  KH: { cca2: "KH", name: "Cambodia", dialCode: "855", flag: "🇰🇭" },
  KI: { cca2: "KI", name: "Kiribati", dialCode: "686", flag: "🇰🇮" },
  KM: { cca2: "KM", name: "Comoros", dialCode: "269", flag: "🇰🇲" },
  KN: { cca2: "KN", name: "Saint Kitts and Nevis", dialCode: "1-869", flag: "🇰🇳" },
  KP: { cca2: "KP", name: "North Korea", dialCode: "850", flag: "🇰🇵" },
  KR: { cca2: "KR", name: "South Korea", dialCode: "82", flag: "🇰🇷" },
  KW: { cca2: "KW", name: "Kuwait", dialCode: "965", flag: "🇰🇼" },
  KY: { cca2: "KY", name: "Cayman Islands", dialCode: "1-345", flag: "🇰🇾" },
  KZ: { cca2: "KZ", name: "Kazakhstan", dialCode: "7", flag: "🇰🇿" },
  LA: { cca2: "LA", name: "Laos", dialCode: "856", flag: "🇱🇦" },
  LB: { cca2: "LB", name: "Lebanon", dialCode: "961", flag: "🇱🇧" },
  LC: { cca2: "LC", name: "Saint Lucia", dialCode: "1-758", flag: "🇱🇨" },
  LI: { cca2: "LI", name: "Liechtenstein", dialCode: "423", flag: "🇱🇮" },
  LK: { cca2: "LK", name: "Sri Lanka", dialCode: "94", flag: "🇱🇰" },
  LR: { cca2: "LR", name: "Liberia", dialCode: "231", flag: "🇱🇷" },
  LS: { cca2: "LS", name: "Lesotho", dialCode: "266", flag: "🇱🇸" },
  LT: { cca2: "LT", name: "Lithuania", dialCode: "370", flag: "🇱🇹" },
  LU: { cca2: "LU", name: "Luxembourg", dialCode: "352", flag: "🇱🇺" },
  LV: { cca2: "LV", name: "Latvia", dialCode: "371", flag: "🇱🇻" },
  LY: { cca2: "LY", name: "Libya", dialCode: "218", flag: "🇱🇾" },
  MA: { cca2: "MA", name: "Morocco", dialCode: "212", flag: "🇲🇦" },
  MC: { cca2: "MC", name: "Monaco", dialCode: "377", flag: "🇲🇨" },
  MD: { cca2: "MD", name: "Moldova", dialCode: "373", flag: "🇲🇩" },
  ME: { cca2: "ME", name: "Montenegro", dialCode: "382", flag: "🇲🇪" },
  MF: { cca2: "MF", name: "Saint Martin", dialCode: "590", flag: "🇲🇫" },
  MG: { cca2: "MG", name: "Madagascar", dialCode: "261", flag: "🇲🇬" },
  MH: { cca2: "MH", name: "Marshall Islands", dialCode: "692", flag: "🇲🇭" },
  MK: { cca2: "MK", name: "Macedonia", dialCode: "389", flag: "🇲🇰" },
  ML: { cca2: "ML", name: "Mali", dialCode: "223", flag: "🇲🇱" },
  MM: { cca2: "MM", name: "Myanmar", dialCode: "95", flag: "🇲🇲" },
  MN: { cca2: "MN", name: "Mongolia", dialCode: "976", flag: "🇲🇳" },
  MO: { cca2: "MO", name: "Macau", dialCode: "853", flag: "🇲🇴" },
  MP: { cca2: "MP", name: "Northern Mariana Islands", dialCode: "1-670", flag: "🇲🇵" },
  MQ: { cca2: "MQ", name: "Martinique", dialCode: "596", flag: "🇲🇶" },
  MR: { cca2: "MR", name: "Mauritania", dialCode: "222", flag: "🇲🇷" },
  MS: { cca2: "MS", name: "Montserrat", dialCode: "1-664", flag: "🇲🇸" },
  MT: { cca2: "MT", name: "Malta", dialCode: "356", flag: "🇲🇹" },
  MU: { cca2: "MU", name: "Mauritius", dialCode: "230", flag: "🇲🇺" },
  MV: { cca2: "MV", name: "Maldives", dialCode: "960", flag: "🇲🇻" },
  MW: { cca2: "MW", name: "Malawi", dialCode: "265", flag: "🇲🇼" },
  MX: { cca2: "MX", name: "Mexico", dialCode: "52", flag: "🇲🇽" },
  MY: { cca2: "MY", name: "Malaysia", dialCode: "60", flag: "🇲🇾" },
  MZ: { cca2: "MZ", name: "Mozambique", dialCode: "258", flag: "🇲🇿" },
  NA: { cca2: "NA", name: "Namibia", dialCode: "264", flag: "🇳🇦" },
  NC: { cca2: "NC", name: "New Caledonia", dialCode: "687", flag: "🇳🇨" },
  NE: { cca2: "NE", name: "Niger", dialCode: "227", flag: "🇳🇪" },
  NF: { cca2: "NF", name: "Norfolk Island", dialCode: "672", flag: "🇳🇫" },
  NG: { cca2: "NG", name: "Nigeria", dialCode: "234", flag: "🇳🇬" },
  NI: { cca2: "NI", name: "Nicaragua", dialCode: "505", flag: "🇳🇮" },
  NL: { cca2: "NL", name: "Netherlands", dialCode: "31", flag: "🇳🇱" },
  NO: { cca2: "NO", name: "Norway", dialCode: "47", flag: "🇳🇴" },
  NP: { cca2: "NP", name: "Nepal", dialCode: "977", flag: "🇳🇵" },
  NR: { cca2: "NR", name: "Nauru", dialCode: "674", flag: "🇳🇷" },
  NU: { cca2: "NU", name: "Niue", dialCode: "683", flag: "🇳🇺" },
  NZ: { cca2: "NZ", name: "New Zealand", dialCode: "64", flag: "🇳🇿" },
  OM: { cca2: "OM", name: "Oman", dialCode: "968", flag: "🇴🇲" },
  PA: { cca2: "PA", name: "Panama", dialCode: "507", flag: "🇵🇦" },
  PE: { cca2: "PE", name: "Peru", dialCode: "51", flag: "🇵🇪" },
  PF: { cca2: "PF", name: "French Polynesia", dialCode: "689", flag: "🇵🇫" },
  PG: { cca2: "PG", name: "Papua New Guinea", dialCode: "675", flag: "🇵🇬" },
  PH: { cca2: "PH", name: "Philippines", dialCode: "63", flag: "🇵🇭" },
  PK: { cca2: "PK", name: "Pakistan", dialCode: "92", flag: "🇵🇰" },
  PL: { cca2: "PL", name: "Poland", dialCode: "48", flag: "🇵🇱" },
  PM: { cca2: "PM", name: "Saint Pierre and Miquelon", dialCode: "508", flag: "🇵🇲" },
  PN: { cca2: "PN", name: "Pitcairn Islands", dialCode: "870", flag: "🇵🇳" },
  PR: { cca2: "PR", name: "Puerto Rico", dialCode: ["1-787", "1-939"] as string[], flag: "🇵🇷" },
  PS: { cca2: "PS", name: "Palestine", dialCode: "970", flag: "🇵🇸" },
  PT: { cca2: "PT", name: "Portugal", dialCode: "351", flag: "🇵🇹" },
  PW: { cca2: "PW", name: "Palau", dialCode: "680", flag: "🇵🇼" },
  PY: { cca2: "PY", name: "Paraguay", dialCode: "595", flag: "🇵🇾" },
  QA: { cca2: "QA", name: "Qatar", dialCode: "974", flag: "🇶🇦" },
  RE: { cca2: "RE", name: "Réunion", dialCode: "262", flag: "🇷🇪" },
  RO: { cca2: "RO", name: "Romania", dialCode: "40", flag: "🇷🇴" },
  RS: { cca2: "RS", name: "Serbia", dialCode: "381", flag: "🇷🇸" },
  RU: { cca2: "RU", name: "Russia", dialCode: "7", flag: "🇷🇺" },
  RW: { cca2: "RW", name: "Rwanda", dialCode: "250", flag: "🇷🇼" },
  SA: { cca2: "SA", name: "Saudi Arabia", dialCode: "966", flag: "🇸🇦" },
  SB: { cca2: "SB", name: "Solomon Islands", dialCode: "677", flag: "🇸🇧" },
  SC: { cca2: "SC", name: "Seychelles", dialCode: "248", flag: "🇸🇨" },
  SD: { cca2: "SD", name: "Sudan", dialCode: "249", flag: "🇸🇩" },
  SE: { cca2: "SE", name: "Sweden", dialCode: "46", flag: "🇸🇪" },
  SG: { cca2: "SG", name: "Singapore", dialCode: "65", flag: "🇸🇬" },
  SH: { cca2: "SH", name: "Saint Helena, Ascension and Tristan da Cunha", dialCode: "290", flag: "🇸🇭" },
  SI: { cca2: "SI", name: "Slovenia", dialCode: "386", flag: "🇸🇮" },
  SJ: { cca2: "SJ", name: "Svalbard and Jan Mayen", dialCode: "47", flag: "🇸🇯" },
  SK: { cca2: "SK", name: "Slovakia", dialCode: "421", flag: "🇸🇰" },
  SL: { cca2: "SL", name: "Sierra Leone", dialCode: "232", flag: "🇸🇱" },
  SM: { cca2: "SM", name: "San Marino", dialCode: "378", flag: "🇸🇲" },
  SN: { cca2: "SN", name: "Senegal", dialCode: "221", flag: "🇸🇳" },
  SO: { cca2: "SO", name: "Somalia", dialCode: "252", flag: "🇸🇴" },
  SR: { cca2: "SR", name: "Suriname", dialCode: "597", flag: "🇸🇷" },
  SS: { cca2: "SS", name: "South Sudan", dialCode: "211", flag: "🇸🇸" },
  ST: { cca2: "ST", name: "São Tomé and Príncipe", dialCode: "239", flag: "🇸🇹" },
  SV: { cca2: "SV", name: "El Salvador", dialCode: "503", flag: "🇸🇻" },
  SX: { cca2: "SX", name: "Sint Maarten", dialCode: "599", flag: "🇸🇽" },
  SY: { cca2: "SY", name: "Syria", dialCode: "963", flag: "🇸🇾" },
  SZ: { cca2: "SZ", name: "Eswatini", dialCode: "268", flag: "🇸🇿" },
  TC: { cca2: "TC", name: "Turks and Caicos Islands", dialCode: "1-649", flag: "🇹🇨" },
  TD: { cca2: "TD", name: "Chad", dialCode: "235", flag: "🇹🇩" },
  TF: { cca2: "TF", name: "French Southern and Antarctic Lands", dialCode: null, flag: "🇹🇫" },
  TG: { cca2: "TG", name: "Togo", dialCode: "228", flag: "🇹🇬" },
  TH: { cca2: "TH", name: "Thailand", dialCode: "66", flag: "🇹🇭" },
  TJ: { cca2: "TJ", name: "Tajikistan", dialCode: "992", flag: "🇹🇯" },
  TK: { cca2: "TK", name: "Tokelau", dialCode: "690", flag: "🇹🇰" },
  TL: { cca2: "TL", name: "Timor-Leste", dialCode: "670", flag: "🇹🇱" },
  TM: { cca2: "TM", name: "Turkmenistan", dialCode: "993", flag: "🇹🇲" },
  TN: { cca2: "TN", name: "Tunisia", dialCode: "216", flag: "🇹🇳" },
  TO: { cca2: "TO", name: "Tonga", dialCode: "676", flag: "🇹🇴" },
  TR: { cca2: "TR", name: "Turkey", dialCode: "90", flag: "🇹🇷" },
  TT: { cca2: "TT", name: "Trinidad and Tobago", dialCode: "1-868", flag: "🇹🇹" },
  TV: { cca2: "TV", name: "Tuvalu", dialCode: "688", flag: "🇹🇻" },
  TW: { cca2: "TW", name: "Taiwan", dialCode: "886", flag: "🇹🇼" },
  TZ: { cca2: "TZ", name: "Tanzania", dialCode: "255", flag: "🇹🇿" },
  UA: { cca2: "UA", name: "Ukraine", dialCode: "380", flag: "🇺🇦" },
  UG: { cca2: "UG", name: "Uganda", dialCode: "256", flag: "🇺🇬" },
  UM: { cca2: "UM", name: "United States Minor Outlying Islands", dialCode: "1", flag: "🇺🇲" },
  US: { cca2: "US", name: "United States (US)", dialCode: "1", flag: "🇺🇸" },
  UY: { cca2: "UY", name: "Uruguay", dialCode: "598", flag: "🇺🇾" },
  UZ: { cca2: "UZ", name: "Uzbekistan", dialCode: "998", flag: "🇺🇿" },
  VA: { cca2: "VA", name: "Vatican City", dialCode: "379", flag: "🇻🇦" },
  VC: { cca2: "VC", name: "Saint Vincent and the Grenadines", dialCode: "1-784", flag: "🇻🇨" },
  VE: { cca2: "VE", name: "Venezuela", dialCode: "58", flag: "🇻🇪" },
  VG: { cca2: "VG", name: "British Virgin Islands", dialCode: "1-284", flag: "🇻🇬" },
  VI: { cca2: "VI", name: "United States Virgin Islands", dialCode: "1-340", flag: "🇻🇮" },
  VN: { cca2: "VN", name: "Vietnam", dialCode: "84", flag: "🇻🇳" },
  VU: { cca2: "VU", name: "Vanuatu", dialCode: "678", flag: "🇻🇺" },
  WF: { cca2: "WF", name: "Wallis and Futuna", dialCode: "681", flag: "🇼🇫" },
  WS: { cca2: "WS", name: "Samoa", dialCode: "685", flag: "🇼🇸" },
  XK: { cca2: "XK", name: "Kosovo", dialCode: null, flag: "🇽🇰" },
  YE: { cca2: "YE", name: "Yemen", dialCode: "967", flag: "🇾🇪" },
  YT: { cca2: "YT", name: "Mayotte", dialCode: "262", flag: "🇾🇹" },
  ZA: { cca2: "ZA", name: "South Africa", dialCode: "27", flag: "🇿🇦" },
  ZM: { cca2: "ZM", name: "Zambia", dialCode: "260", flag: "🇿🇲" },
  ZW: { cca2: "ZW", name: "Zimbabwe", dialCode: "263", flag: "🇿🇼" }
} as const

export const CountryDescriptorArray = Object.values(CountryDescriptors);
