import { Identifier, Nullable, StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { CommunicationType } from "../constants/communicationTypes.descriptors";
import { CounsellingType } from "../constants/counsellingTypes.descriptors";
import { ModelName } from "../constants/modelNames.enum";
import { hasTimestamps } from "../traits/hasTimestamps.trait";
import { Company } from "./makeCompany.model";
import { CounsellingApplication } from "./makeCounsellingApplication.model";
import { User } from "./makeUser.model";

export type CounsellingAvailabilitySnapshot = ReturnType<typeof makeCounsellingAvailabilitySnapshot>;
export type CounsellingAvailabilityRelatedModels = {
  createdByUser?: User,
  counsellor?: User,
  allowedCompany?: Company,
  application?: CounsellingApplication,
}

export const makeCounsellingAvailabilitySnapshot = () => ({
  id: '' as Identifier,
  typesAllowed: [] as CounsellingType[],
  communicationTypesAllowed: [] as CommunicationType[],
  timeStart: '' as Nullable<string>,
  createdByUserId: '' as Nullable<Identifier>,
  counsellorId: '' as Nullable<Identifier>,
  allowedCompanyId: '' as Nullable<Identifier>,
  applicationId: '' as Nullable<Identifier>,
  ...hasTimestamps(),
})

export type CounsellingAvailabilityExtendedProperties = {
}

/**
 * Counselling Availability Time Slots, sometimes abbreviated as "slots" in the application code.
 */
export type CounsellingAvailability = StandardModel<
  CounsellingAvailabilitySnapshot,
  CounsellingAvailabilityRelatedModels,
  CounsellingAvailabilityExtendedProperties
>

export const makeCounsellingAvailability = createStandardModelFactory<CounsellingAvailability, CounsellingAvailabilityRelatedModels, CounsellingAvailabilityExtendedProperties>({
  name: ModelName.counsellingAvailabilities,
  snapshotFactory: makeCounsellingAvailabilitySnapshot,
  relationshipsSchema: {
    counsellor: ModelName.users,
    createdByUser: ModelName.users,
    allowedCompany: ModelName.companies,
    application: ModelName.counsellingApplications,
  }
})