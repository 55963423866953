import { makeIconDef } from "./makeIconDef";
import React from "react";

export const SendIconDef = {
  send: makeIconDef('send', {
    regular: () => <>
      <path d="M9.93904 16.78L12.3961 21.0358L21 7L5.0625 8.33362L7.51958 12.5894L14.5 10.5L10.5 14.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.0625 15.5C3.5625 17 3.0625 21 5.56249 23" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </>
  })
}