import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState, Nullable } from '../../base/@types';
import AppPage from '../../base/components/AppPage/AppPage';
import AppPageContent from '../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../base/components/AppPageHeader/AppPageHeader';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseButtonGroup from '../../base/components/BaseButtonGroup/BaseButtonGroup';
import BaseToggle from '../../base/components/BaseToggle/BaseToggle';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import InfoBanner from '../../base/components/InfoBanner/InfoBanner';
import OverlayCloseButton from '../../base/components/OverlayCloseButton/OverlayCloseButton';
import UIBlock from '../../base/components/UIBlock/UIBlock';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { AddressValidator } from '../../base/validators/address.validator';
import { Address, AddressSnapshot } from '../../models/makeAddress.model';
import { UserSnapshot } from '../../models/makeUser.model';
import { saveAddress } from '../../requests/saveAddress.request';
import { sendSaveUserRequest } from '../../requests/saveUser.request';
import AddressEditor from '../AddressEditor/AddressEditor';
// import './OverlayAddressEditor.scss';

type OverlayAddressEditorProps = {
  address: Address,
  forceDefaultToPrimaryAsTrue?: boolean
}

const OverlayAddressEditor: React.FC<OverlayAddressEditorProps> = props => {

  const { API, AUTH, UI } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    uneditedCopy: p.address.$getSnapshot(),
    get isNew() {
      return !Boolean(p.address.id);
    },
    setAsPrimaryAddressOnSave: props.forceDefaultToPrimaryAsTrue || false,
    savingAddress: false,
    error: null as Nullable<Error>,
    get canSetAsPrimary() {
      return Boolean(p.address.userId);
    },
    get isOwnAddress() {
      return p.address.userId === AUTH.currentUser?.id;
    },
    saveAddress: async () => await flow(function *() {
      if (!p.address) return;
      s.savingAddress = true;
      s.error = null;
      try {
        const payload: Partial<AddressSnapshot> = p.address.$snapshot;
        if (!payload.id) delete payload.id;
        const savedAddress = yield saveAddress(payload, API, { isStaff: !s.isOwnAddress ?? AUTH.isStaff });
        if (s.setAsPrimaryAddressOnSave) {
          const updatedUser: Partial<UserSnapshot> = {
            id: savedAddress.userId,
            primaryAddressId: savedAddress.id,
          }
          yield sendSaveUserRequest(API, updatedUser, {
            isSelf: s.isOwnAddress
          });
        }
        if (s.isOwnAddress) yield AUTH.fetchCurrentUserInfo();
        s.uneditedCopy = savedAddress.$getSnapshot();
        s.dismiss();
      } catch (e) {
        s.error = e as Error;
        reportError(e);
      } finally {
        s.savingAddress = false;
      }
    })(),
    get addressValidatorResult() {
      return AddressValidator(p.address);
    },
    dismiss: () => {
      UI.OVERLAY.dismiss();
    }
  }))
  return <Observer children={() => (
    <AppPage className="OverlayAddressEditor">
      <AppPageHeader
        title={s.isNew ? 'New Address' : 'Edit Address'}
        endSlot={<OverlayCloseButton />}
      />
      <AppPageContent>
        <UIBlock padded spaceChildren>
          <AddressEditor
            address={p.address}
            applyChangesImmediately
            disabled={s.savingAddress}
          />
          {s.canSetAsPrimary && <BaseToggle dataCy="set-as-primary-address-toggle" form={s} field="setAsPrimaryAddressOnSave" label="Set as Primary Address" disabled={props.forceDefaultToPrimaryAsTrue} />}
          <BaseButtonGroup>
            <BaseButton dataCy="save-address" onClick={s.saveAddress} disabled={s.addressValidatorResult !== true}>Save Address</BaseButton>
            <BaseButton dataCy="cancel" className="subtle" onClick={s.dismiss}>Cancel</BaseButton>
          </BaseButtonGroup>
          {
            s.error && <InfoBanner colorCodedState={ColorCodedState.error} icon="warning" heading="Error saving address">
              <ErrorRenderer error={s.error} />
            </InfoBanner>
          }
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
}

export default OverlayAddressEditor;