import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import AppPage from '../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../base/components/AppPageHeader/AppPageHeader';
import BaseButton from '../../../../base/components/BaseButton/BaseButton';
import BaseButtonGroup from '../../../../base/components/BaseButtonGroup/BaseButtonGroup';
import BaseInput from '../../../../base/components/BaseInput/BaseInput';
import BaseSelector from '../../../../base/components/BaseSelector/BaseSelector';
import OverlayCloseButton from '../../../../base/components/OverlayCloseButton/OverlayCloseButton';
import UIBlock from '../../../../base/components/UIBlock/UIBlock';
import { CounsellingApplicationEndpoints } from '../../../../base/endpoints/counsellingApplication.endpoints';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import { reportError } from '../../../../base/utils/errors.utils';
import { useStore } from '../../../../base/utils/mobx.utils';
import { ModelName } from '../../../../constants/modelNames.enum';
import { CounsellingApplication } from '../../../../models/makeCounsellingApplication.model';
import './OverlayApplicationRejectionConfirm.scss';

type OverlayApplicationRejectionConfirmProps = {
  application: CounsellingApplication,
  onSuccess: (a: CounsellingApplication) => void,
}

const defaultReasonTemplates = {
  'Repeated Applications': 'You have already submitted other applications and they are not yet completed. Please try again when all your other applications have completed.',
  'Invalid or Contradictory Information': 'The information provided in your application are either invalid or contradictory.',
  'Incomplete Information': 'The information provided in your application is incomplete.',
  'Ineligible Applicant': 'According to the information you have provided, you do not seem to be eligible to our clinical services.',
  'Ineligible Invitee': 'According to the information you have provided, the person you have invited does not seem to be eligible to our clinical services.',
  'Region Not In Service': 'We currently cannot provide service to your country of residence.',
  'Other Reasons…': '',
}

const OverlayApplicationRejectionConfirm: React.FC<OverlayApplicationRejectionConfirmProps> = props => {
  const { API, UI } = useControllers();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const s = useStore(() => ({
    reasonForRejection: null,
    get options() {
      return Object.entries(defaultReasonTemplates).map(([name, value]) => ({
        name,
        value
      }))
    },
  }))
  const confirm = flow(function * () {
    const url = CounsellingApplicationEndpoints.staff.reject(props.application.id);
    const payload = {
      reasonForRejection: s.reasonForRejection,
    };
    try {
      const savedApplication = yield API.post(url, ModelName.counsellingApplications, payload);
      props.application.reasonForRejection = savedApplication.reasonForRejection;
      props.application.timeRejected = savedApplication.timeRejected;
      props.application.timeArchived = savedApplication.timeArchived;
      dismiss();
    } catch(e) {
      reportError(e);
      UI.DIALOG.error({
        heading: 'Error rejecting application.',
        error: e,
      })
    }
    return;
  })
  const dismiss = () => {
    UI.OVERLAY.dismiss();
  }
  return <Observer children={() => (
    <AppPage className="OverlayApplicationRejectionConfirm">
      <AppPageHeader 
        title="Reject Application"
        endSlot={<OverlayCloseButton />}
      />
      <AppPageContent>
        <UIBlock padded spaceChildren>
          <p>Please select a template for a pre-composed reason for the rejection, or write your own. This will be archived with the application and gets notified to the applicant.</p>
          <BaseSelector appearance="system" form={s} field="reasonForRejection" options={s.options} placeholder="Select a template or enter custom…" />
          <BaseInput innerRef={textareaRef} autoFocus type="textarea" form={s} field="reasonForRejection" rows="6" placeholder="Enter custom reason…"/>
          <BaseButtonGroup>
            <BaseButton dataCy="confirm-rejection" onClick={confirm} disabled={!s.reasonForRejection}>Confirm</BaseButton>
            <BaseButton className="subtle" onClick={dismiss}>Cancel</BaseButton>
          </BaseButtonGroup>
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
}

export default OverlayApplicationRejectionConfirm;