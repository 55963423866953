import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Primary } from '../../constants/color.enum';
import joinClassName from '../../utils/className.utils';
import { getContextColorStyle, isHexCode } from '../../utils/colors.utils';
import { autoPluralize } from '../../utils/string.utils';
import BaseIcon from '../BaseIcon/BaseIcon';
import { IconName, IconVariant } from '../Symbols/iconDefs';
import './CountWithIcon.scss';

interface CountWithIconProps {
  className?: string,
  iconName: IconName,
  iconVariant?: IconVariant,
  color?: string,
  count?: number,
  textLabel?: string,
  onClick?: () => void,
}

const CountWithIcon: React.FC<CountWithIconProps> = props => {
  const style = isHexCode(props.color) ? {
    color: props.color,
  } : getContextColorStyle(Primary, props.color);
  return <Observer children={() => (
    <div className={
      joinClassName(
        'CountWithIcon',
        props.className,
        props.onClick && 'interactable',
      )
    } style={style} onClick={props.onClick}>
      <BaseIcon name={props.iconName} variant={props.iconVariant}/>
      {props.count && props.count > 0 ? <span className="CountWithIconCount">
        {props.textLabel ? autoPluralize(props.count, props.textLabel) : props.count}
      </span> : false}
    </div>
  )} />
}

export default CountWithIcon;