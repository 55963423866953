import CounsellingJourney from "../components/CounsellingJourney/CounsellingJourney";
import { UIController } from "../controllers/ui.controller";

export const openCounsellingJourneyOverlay = (
  applicationId: string,
  UI: UIController
) => {
  UI.OVERLAY.present({
    name: `CounsellingJourney#${applicationId}`,
    component: <CounsellingJourney applicationId={applicationId} />,
    duplicateStrategy: 'abort',
    width: '42em',
  })
}