import { isObservable, makeAutoObservable } from "mobx";
import { v4 as uuid } from 'uuid';
import { AnyObject, SortDirection } from "../../@types";
import BasePaginationState from "../BasePagination/BasePaginationState";
import { IndexDirectoryStateOptions, IndexDirectoryViewMode } from "./indexDirectory.types";

const makeDefaultIndexDirectoryState: () => IndexDirectoryStateOptions = () => ({
  name: uuid(),
  viewMode: 'table',
  searchQuery: '',
})


export default class IndexDirectoryState<EntryType = AnyObject> implements IndexDirectoryStateOptions<EntryType> {
  options: IndexDirectoryStateOptions<EntryType> = makeDefaultIndexDirectoryState();

  get name() { return this.options.name }
  set name(v: string) { this.options.name = v }

  get searchByKeyName() { return this.options.searchByKeyName }
  set searchByKeyName(v: string | undefined) { this.options.searchByKeyName = v }

  get searchQuery() { return this.options.searchQuery }
  set searchQuery(v: string | undefined) { this.options.searchQuery = v }

  get sortByKeyName() { return this.options.sortByKeyName }
  set sortByKeyName(v: keyof EntryType | null | undefined) { this.options.sortByKeyName = v }

  get sortDirection() { return this.options.sortDirection }
  set sortDirection(v: SortDirection | undefined) { this.options.sortDirection = v }

  get viewMode() { return this.options.viewMode }
  set viewMode(v: IndexDirectoryViewMode) { this.options.viewMode = v }

  get searchable() { return this.options.searchable || false }
  set searchable(v: boolean) { this.options.searchable = v }

  paginationState = new BasePaginationState();
  
  constructor(options?: Partial<IndexDirectoryStateOptions<EntryType>>) {
    if (!options) return;
    if (isObservable(options)) {
      Object.entries(this.options).forEach(e => {
        const key = e[0] as keyof IndexDirectoryStateOptions<EntryType> & string;
        if (!(key in options)) {
          options[key] = this.options[key] as any;
        }
      })
      this.options = options as IndexDirectoryStateOptions<EntryType>;
    } else {
      Object.assign(this.options, options);
    }
    if (options?.paginationState) {
      this.paginationState = new BasePaginationState(options.paginationState);
    }
    makeAutoObservable(this);
  }
}