import React from 'react';
import CountWithIcon from '../CountWithIcon/CountWithIcon';
// import './FlagCounter.scss';

interface FlagCounterProps {
  count?: number,
  showTextLabel?: boolean,
  onClick?: () => void,
}

const FlagCounter: React.FC<FlagCounterProps> = props => {
  return <CountWithIcon
    className="FlagCounter"
    count={props.count}
    iconName="flag"
    iconVariant="filled"
    color={props.count ? '#E1503C' : '#2D384538'}
    textLabel={props.showTextLabel ? 'flags' : undefined}
    onClick={props.onClick}
  />
}

export default FlagCounter;