import { action, flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Nillable, Nullable } from '../../base/@types';
import InfiniteFeedLoader from '../../base/components/InfiniteFeedLoader/InfiniteFeedLoader';
import { DefaultThoughtIncludes, DefaultThoughtIncludesForAdmins, ThoughtEndpointParams, ThoughtEndpoints } from '../../base/endpoints/thought.endpoints';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { isMultiplesOf } from '../../base/utils/numbers.utils';
import { ModelName } from '../../constants/modelNames.enum';
import { Priority } from '../../constants/priority.enum';
import { APIGetManyResponse } from '../../controllers/api.controller';
import { CLOCK } from '../../controllers/common/clock.controller';
import { SHOULD_LOG } from '../../env';
import { Thought } from '../../models/makeThought.model';
import { User } from '../../models/makeUser.model';
import { AgeGroupFilterType } from '../../utils/ageAndDateOfBirth.utils';
import ThoughtCard from '../ThoughtCard/ThoughtCard';
import './ThoughtFeed.scss';

export type ThoughtFeedInsert = { id: string, el: JSX.Element, priority?: Priority };

type ThoughtFeedProps = {
  user?: User,
  inserts?: ThoughtFeedInsert[],
  firstInsertAtIndex?: number,
  insertAfterEveryNthItem?: number,
}

const ThoughtFeed: React.FC<ThoughtFeedProps> = props => {

  const { AUTH, API, THOUGHT_CATCHER } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({

    currentPageOfFeed: 0,
    lastPageOfFeed: null as Nullable<number>,
    errorRetrievingFeed: null as Nullable<Error>,
    timeLastRetrievedFeed: null as Nullable<string>,
    feedRetrieving: false,
    feedRetrieved: false,

    get getOwn() {
      return AUTH.currentUser && (p.user?.id === AUTH.currentUser.id);
    },

    get hasReachedLastPageOfFeed(): boolean {
      return !s.errorRetrievingFeed && s.currentPageOfFeed === s.lastPageOfFeed;
    },

    get thoughtEndpointFactory(): (params?: Nillable<ThoughtEndpointParams>) => string {
      return ThoughtEndpoints[s.getOwn ? 'own' : AUTH.currentUser?.isModerator ? 'staff' : 'client'].index;
    },

    get feedAgeGroupFilters(): AgeGroupFilterType[] | undefined {
      if (AUTH.isModerator) return undefined;
      if (s.getOwn) return undefined;
      if (AUTH.currentUser?.isYoungPerson) return [AgeGroupFilterType.youngPeople1214, AgeGroupFilterType.youngPeople1517];
      return [AgeGroupFilterType.adults];
    },

    pollInterval: null as number | null,

    getFeedOfPage: (page: number = 1) => flow(function* () {
      SHOULD_LOG() && console.log('polling thought feed');
      s.errorRetrievingFeed = null;
      s.feedRetrieving = true;
      const params = {
        include: AUTH.isModerator ? DefaultThoughtIncludesForAdmins : DefaultThoughtIncludes,
        sort: AUTH.isModerator ? 'timeLastClientAction' : 'timeCreated',
        page,
        perPage: 15,
        filter: {
          userId: s.getOwn ? undefined : (p.user?.id ?? undefined),
          ageGroup: s.feedAgeGroupFilters,
        }
      }
      const url = s.thoughtEndpointFactory(params);
      yield s.getThoughtFeedByUrl(url);
    })(),

    getThoughtFeedByUrl: (url: string, options?: { isRefreshRequest?: boolean }) => flow(function* () {
      try {
        const { entries: refreshedThoughts, response } = (yield API.getMany(url, ModelName.thoughts)) as APIGetManyResponse<Thought>;
        s.feedRetrieved = true;
        s.timeLastRetrievedFeed = CLOCK.nowUtcTimestamp;
        if (!options?.isRefreshRequest) {
          s.currentPageOfFeed = response.data.current_page;
          s.lastPageOfFeed = response.data.last_page;
        }
        s.feedRetrieving = false;
        return refreshedThoughts;
      } catch (e) {
        s.errorRetrievingFeed = e as Error;
        s.pollInterval && clearInterval(s.pollInterval);
        s.feedRetrieving = false;
        throw e;
      }
    })(),
    makeFeedDataGetterGenerator: action(() => {
      s.feedDataGetterGeneratorLastCreated = CLOCK.nowUtcTimestamp;
      if (s.feed.length === 0) {
        s.currentPageOfFeed = 0;
        s.lastPageOfFeed = null;
      }
      s.getFeedDataGetterGenerator = () => {
        return (async function* () {
          while (!s.hasReachedLastPageOfFeed) {
            yield await s.getFeedOfPage(s.currentPageOfFeed + 1);
            s.initialFeedRetrieved = true;
          }
        })();
      }
    }),

    feedDataGetterGeneratorLastCreated: null as Nullable<string>,

    getFeedDataGetterGenerator: () => {
      return null as Nullable<AsyncGenerator<any>>;
    },

    get thoughtsSorted() {
      return p.user ? THOUGHT_CATCHER.thoughtsSorted.filter(t => t.userId === p.user!.id) : THOUGHT_CATCHER.thoughtsSorted;
    },

    get viewableThoughts(): Thought[] {
      return s.thoughtsSorted.filter(t => {
        if (!t.content) return false;
        if (AUTH.currentUser?.isModerator) return true;
        const isOwnThought = AUTH.currentUser?.id && (t.userId === AUTH.currentUser.id);
        if (isOwnThought) return !t.timeDeleted;
        if (THOUGHT_CATCHER.serviceStatusForCurrentUser === 'off') return false;
        const isSameAgeGroup = AUTH.currentUser?.isAdult ? t.user?.isAdult : AUTH.currentUser?.isYoungPerson ? t.user?.isYoungPerson : false;
        return isSameAgeGroup && !t.isPrivate && !t.timeHidden && !t.timeDeleted;
      })
    },

    get thoughtCards() {
      return s.viewableThoughts.map(t => <ThoughtCard thought={t} key={t.id} />);
    },

    get highPriorityInserts() {
      if (!p.inserts) return [];
      return p.inserts.filter(i => 'priority' in i ? i.priority === Priority.high : false);
    },
    get normalPriorityInserts() {
      if (!p.inserts) return [];
      return p.inserts.filter(i => 'priority' in i ? i.priority !== Priority.high : true);
    },

    makeInsert(key: string, insert: ThoughtFeedInsert) {
      return <Observer key={key} children={() => <div className="ThoughtFeedInsert">{'el' in insert ? insert.el : insert}</div>} />;
    },

    get feed() {
      const feed = [] as JSX.Element[];
      if (!p.inserts) return s.thoughtCards;
      let i = 0;
      feed.unshift(...s.highPriorityInserts.map(insert => <div key={`insert-high-priority-${insert.id}`}>
        {insert.el}
      </div>));
      if (s.initialFeedRetrieved && s.thoughtCards.length === 0) {
        return p.inserts.map((ins, i) => s.makeInsert(`insert-${i}`, ins));
      }
      for (let index in s.thoughtCards) {
        feed.push(s.thoughtCards[index]);
        if (i < s.normalPriorityInserts.length && isMultiplesOf((p.insertAfterEveryNthItem ?? 5), +index + (p.firstInsertAtIndex ?? 3))) {
          const insert = s.normalPriorityInserts[i];
          feed.push(s.makeInsert(`after-${index}-insert-normal-priority-${i}`, insert));
          i++;
        }
      }
      return feed;
    },

    initialFeedRetrieved: false,

    reset: action(() => {
      s.initialFeedRetrieved = false;
      s.feedRetrieving = false;
      s.feedRetrieved = false;
      s.timeLastRetrievedFeed = '';
      s.currentPageOfFeed = 0;
      s.lastPageOfFeed = null;
      s.errorRetrievingFeed = null;
      s.makeFeedDataGetterGenerator();
    }),

  }));

  useOnMount(() => {
    s.makeFeedDataGetterGenerator();
  })

  return <Observer children={() => (
    <div className="ThoughtFeed">
      {s.feed}
      {s.feedDataGetterGeneratorLastCreated && <InfiniteFeedLoader
        loaderGeneratorGetter={s.getFeedDataGetterGenerator}
        scrollContextGetter={() => document.querySelector('.RouterStackLayerContainer[data-identifier="explore"]')}
        offset={-300}
        onBeforeManualRetry={s.makeFeedDataGetterGenerator}
        doneMessage={p.user ? s.feed.length === 0 ? 'No thoughts here yet.' : 'You have reached the end.' : undefined}
      />}
    </div>
  )} />

}

export default ThoughtFeed;