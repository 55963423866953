import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import BaseCalendarEvent, { BaseCalendarEventProps } from '../../base/components/BaseCalendar/BaseCalendarEvent';
import ColorTag from '../../base/components/ColorTag/ColorTag';
import ColorTag18Plus from '../../base/components/ColorTag/ColorTag18Plus';
import ColorTagArchived from '../../base/components/ColorTag/ColorTagArchived';
import ColorTagCompany from '../../base/components/ColorTag/ColorTagCompany';
import ColorTagFrontLineWorkers from '../../base/components/ColorTag/ColorTagFrontLineWorkers';
import ColorTagSpecialistGroup from '../../base/components/ColorTag/ColorTagSpecialistGroup';
import ColorTagYoungPeople1214 from '../../base/components/ColorTag/ColorTagYoungPeople1214';
import ColorTagYoungPeople1517 from '../../base/components/ColorTag/ColorTagYoungPeople1517';
import TimeDisplayer from '../../base/components/TimeDisplayer/TimeDisplayer';
import { useControllers } from '../../base/hooks/useRootController.hook';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { useSupportGroupGetters } from '../../hooks/useSupportGroupGetters.hook';
import { SupportGroup } from '../../models/makeSupportGroup.model';
import { AgeGroupFilterType } from '../../utils/ageAndDateOfBirth.utils';
import SupportGroupJoinedCountDisplay from '../SupportGroupJoinedCountDisplay/SupportGroupJoinedCountDisplay';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import './SupportGroupCalendarEventRenderer.scss';

interface SupportGroupCalendarEventRendererProps extends BaseCalendarEventProps<SupportGroup>{
}

const SupportGroupCalendarEventRenderer: React.FC<SupportGroupCalendarEventRendererProps> = props => {

  const { AUTH, COMMON } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    get event() {
      return p.calendarEvent;
    },
    get supportGroup() {
      return s.event.data;
    },
    get canManage() {
      return AUTH.can.supportGroups_.manage_.groups;
    },
    get hasEnded() {
      return s.supportGroup?.timeEnded && moment(s.supportGroup?.timeEnded).isBefore(COMMON.CLOCK.localNowMoment)
    },
    get isArchived() {
      return s.supportGroup?.timeArchived;
    },
  }));

  const sg = useSupportGroupGetters(s.supportGroup);

  return <Observer children={() => (
    <BaseCalendarEvent<SupportGroup>
      {...p}
      className={joinClassName(
        "SupportGroupCalendarEventRenderer",
        sg.isInThePast && 'inThePast',
        sg.hasJoined && 'joined',
        sg.isLive && 'isLive',
        sg.isForFrontLineWorkers && 'for-front-line-workers',
        s.isArchived && 'archived',
        "support-group-summary",
      )}
      data-name="OK"
      color={s.supportGroup?.color}
    >
      <h3>{sg.title}</h3>
      <UsernameRenderer user={sg.supportGroup?.facilitator} />
      {s.supportGroup?.requiresKeyWorkerInformation && <strong className="SupportGroupCalendarEventRendererKeyWorker">Key Worker Info Required</strong>}
      <div>
        {
          s.canManage ? (
            <SupportGroupJoinedCountDisplay supportGroup={s.supportGroup} showActualCount />
          ) : (
              sg.hasAvailability ? <></> : <p><em>Full</em></p>
            )
        }
      </div>
      {sg.isForFrontLineWorkers && <ColorTagFrontLineWorkers />}
      {sg.hasJoined && <ColorTag color="positive">Registered</ColorTag>}
      {sg.isLive && <ColorTag color="red">Live</ColorTag>}
      {s.hasEnded && s.canManage && <span className="SupportGroupCalendarEventRendererEndedAt">Ended at <TimeDisplayer value={s.supportGroup?.timeEnded} /></span>}
      {s.isArchived && <ColorTagArchived />}
      {(s.supportGroup?.ageGroups.includes(AgeGroupFilterType.youngPeople1214) || s.supportGroup?.ageGroups.includes(AgeGroupFilterType.youngPeople1517)) && <>
        {s.supportGroup?.ageGroups.includes(AgeGroupFilterType.adults) && <ColorTag18Plus /> }
        {s.supportGroup?.ageGroups.includes(AgeGroupFilterType.youngPeople1214) && <ColorTagYoungPeople1214 useAbbr />}
        {s.supportGroup?.ageGroups.includes(AgeGroupFilterType.youngPeople1517) && <ColorTagYoungPeople1517 useAbbr />}
      </>}
      {s.supportGroup?.isSpecialistGroup && <ColorTagSpecialistGroup /> }
      {!!s.supportGroup?.allowedCompany && <div>
        <ColorTagCompany name={s.supportGroup?.allowedCompany?.name} code={s.supportGroup?.allowedCompany?.code} />
      </div>}
    </BaseCalendarEvent>
  )} />
}

export default SupportGroupCalendarEventRenderer;