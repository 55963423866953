import { Observer } from "mobx-react-lite";
import React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { IDialog } from "../../../controllers/ui/ui.controller.types";
import { ContextColor } from "../../constants/color.enum";
import { useContextColorStyle } from "../../hooks/useContextColor.hook";
import { useObservableRef } from "../../hooks/useObservableRef.hook";
import { useUIController } from "../../hooks/useRootController.hook";
import joinClassName from "../../utils/className.utils";
import { renderRenderable } from "../../utils/components.utils";
import { useProps, useStore } from "../../utils/mobx.utils";
import BaseButton from "../BaseButton/BaseButton";
import './Dialog.scss';

interface P {
  dialog: IDialog,
}

const Dialog: React.FC<P> = props => {

  const p = useProps(props);

  const { PORTAL } = useUIController();

  const s = useStore(() => ({
    dialog: p.dialog,
    config: p.dialog.config,
    get heading() {
      const { heading: Heading } = s.dialog.config;
      if (typeof Heading === 'function') return <Heading />
      else return Heading;
    },
    get body() {
      const { body: Body } = s.dialog.config;
      if (typeof Body === 'function') return <Body />
      else return Body;
    },
    get actions() {
      return s.dialog.actions;
    },
    get someWaiting() {
      return s.actions.some(a => a.waiting);
    },
  }));

  const style = useContextColorStyle(ContextColor.Primary, p.dialog.config.color);

  const ref = useObservableRef();

  useHotkeys('enter', () => {
    if (!ref.current) return;
    const positiveButton = ref.current.querySelector<HTMLButtonElement>(`[data-name="positive"]`) || 
      ref.current.querySelector<HTMLButtonElement>(`[title="OK"]`) ||
      ref.current.querySelector<HTMLButtonElement>(`[title="Confirm"]`) ||
      ref.current.querySelector<HTMLButtonElement>(`.DialogFooter .BaseButton:only-child`)
    if (positiveButton) positiveButton.click();
  })
  
  useHotkeys('escape', () => {
    if (!ref.current) return;
    const negativeButton = ref.current.querySelector<HTMLButtonElement>(`[data-name="negative"]`) || 
      ref.current.querySelector<HTMLButtonElement>(`[title="Cancel"]`) ||
      ref.current.querySelector<HTMLButtonElement>(`[title="No"]`) ||
      ref.current.querySelector<HTMLButtonElement>(`.DialogFooter .BaseButton:only-child`)
    if (negativeButton) negativeButton.click();
  })

  return <Observer children={() => (
    PORTAL.render(
      <section 
        className={joinClassName(
          "DialogLayer",
          s.dialog.status,
          s.dialog.config.colorCodedState && `state-${s.dialog.config.colorCodedState}`
        )}
        data-dialog-name={s.dialog.config.name}
        data-cy={s.dialog.config.dataCy}
        style={style}
        ref={ref}
      >
        <span className="DialogBackdrop" />
        <div className="Dialog" style={{maxWidth: s.config.width}}>
          {s.dialog.config.customComponent ? renderRenderable(s.dialog.config.customComponent) : <>
            {s.heading && <header className="DialogHeader">
              <h1 className="DialogHeading">{s.heading}</h1>
            </header>}
            {s.body && <div className="DialogBody">{s.body}</div>}
            <footer className="DialogFooter">
              {
                s.actions.map(a => (
                  <BaseButton
                    onClick={a.action}
                    key={a.id}
                    appearance={a.buttonAppearance}
                    loading={a.waiting}
                    disabled={a.disabled || s.someWaiting}
                    className={a.buttonClass}
                    color={a.color}
                    name={a.name || a.label}
                    title={a.label || a.name}
                    colorCodedState={a.colorCodedState}
                    dataCy={a.dataCy}
                    label={a.label}
                  />
                ))
              }
            </footer>
          </>}
        </div>
      </section>
    )
  )} />
}


export default Dialog;