import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject } from '../../@types';
import BaseTableActionCell from './BaseTableActionCell';
import { BaseTableBodyProps } from './BaseTableBody';
import BaseTableBodyCell from './BaseTableBodyCell';
import './BaseTableBodyRow.scss';

interface BaseTableBodyRowProps<EntryType extends AnyObject = {}> extends BaseTableBodyProps<EntryType> {
  row: EntryType,
  rowIndex: number,
}

function BaseTableBodyRow<EntryType extends AnyObject = {}>(
  props: React.PropsWithChildren<BaseTableBodyRowProps<EntryType>>
) {
  return <Observer children={() => {
    const style = props.bodyRowStyleFactory && props.bodyRowStyleFactory(props.row);
    return <tr className="BaseTableBodyRow" data-value={props.row.id} style={style}>
      {props.appearanceOptions?.showRowIndex && <td className="BaseTableBodyRowIndexCell"><code>{props.rowIndex + 1}</code></td>}
      {props.columnConfigs
        .filter(conf => !conf.hiddenFromTable)
        .map((columnConfig, columnIndex) => (
        <BaseTableBodyCell<EntryType>
          {...props}
          columnConfig={columnConfig}
          data={props.row}
          key={`${columnConfig.keyName}-${columnIndex}-${props.row.id}`}
        />
      ))}
      {props.itemActions && <BaseTableActionCell data={props.row} actions={props.itemActions} />}
    </tr>
  }} />
}

export default BaseTableBodyRow;