import { flow, reaction, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { editUserInOverlay } from '../../../actions/editUser.action';
import { manageSupportGroupTopic } from '../../../actions/manageSupportGroupTopic.aciton';
import { openCounsellingJourneyOverlay } from '../../../actions/openCounsellingJourneyOverlay.action';
import { openCounsellingSessionDetails } from '../../../actions/openCounsellingSessionDetails.action';
import { openOverlayApplicationManager } from '../../../actions/openOverlayApplicationManager';
import { openOverlayCounsellingSessionManager } from '../../../actions/openOverlayCounsellingSessionManager';
import { viewCompanyInOverlay } from '../../../actions/viewCompanyInOverlay.action';
import { viewThoughtDetails } from '../../../actions/viewThoughtDetails.action';
import { viewUserInOverlay } from '../../../actions/viewUserInOverlay.action';
import { viewInvoiceInOverlay } from '../../../utils/invoices.utils';
import { manageSupportGroup, viewSupportGroupInOverlay } from '../../../utils/supportGroup.helpers';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useControllers } from '../../hooks/useRootController.hook';
import { debounce } from '../../utils/debounce.utils';
import { useAutoSyncWithInitializer } from '../../utils/mobx.utils';
import { getUrlParams, KnownURLParams, removeUrlParam } from '../../utils/urlParams.utils';
import tick from '../../utils/waiters.utils';

interface URLParamWatcherProps {}

const URLParamWatcher: React.FC<URLParamWatcherProps> = p => {

  const { API, NAVIGATOR, UI, STAFF } = useControllers();

  const location = useLocation();
  const history = useHistory();

  const s = useAutoSyncWithInitializer(() => ({
    location,
    history,
    get search() {
      return s.location.search;
    },
    firstLoad: true,
    processParams: flow(function * (doAfterMilliseconds?: number, openImmediately?: boolean) {

      yield tick(doAfterMilliseconds);

      /** SEMI-HACK: the permission checkers are not always immediately ready on page load, so wait a little before checking if it's the first time */
      if (s.firstLoad) yield tick(500);
      yield when(() => !!STAFF.can);
      const params = getUrlParams();

      Object.entries(params).forEach(([key, value]) => {

        if (!value) return;

        switch (key as KnownURLParams) {
          case 'manageThoughtId':
            if (!STAFF.canModerate) { removeUrlParam('manageThoughtId'); return; }
            viewThoughtDetails({ thoughtId: value, API, openImmediately, action: 'manage' });
            return;
          case 'editThoughtId':
            viewThoughtDetails({ thoughtId: value, API, openImmediately, action: 'edit' });
            return;
          case 'thoughtId':
            viewThoughtDetails({ thoughtId: value, API, openImmediately });
            return;
          case 'applicationId':
            openCounsellingJourneyOverlay(value, UI);
            return;
          case 'manageApplicationId':
            if (!STAFF.canManageApplications) { removeUrlParam('manageApplicationId'); return; }
            openOverlayApplicationManager(UI, value);
            return;
          case 'sessionId':
            openCounsellingSessionDetails(value, UI);
            return;
          case 'manageSessionId':
            if (!STAFF.canManageApplications) { removeUrlParam('manageSessionId'); return; }
            openOverlayCounsellingSessionManager(UI, { sessionId: value });
            return;
          case 'supportGroupId':
            viewSupportGroupInOverlay(UI, value)
            return;
          case 'manageSupportGroupTopicId':
            if (!STAFF.can?.supportGroups_.manage_.topics) { removeUrlParam('manageSupportGroupTopicId'); return; }
            manageSupportGroupTopic(UI, undefined, value);
            return;
          case 'manageSupportGroupId':
            if (!STAFF.canManageSupportGroups) { removeUrlParam('manageSupportGroupId'); return; }
            manageSupportGroup(UI, value);
            return;
          case 'userId':
            viewUserInOverlay(UI, undefined, value);
            return;
          case 'editCompanyId':
            viewCompanyInOverlay(UI, undefined, value);
            return;
          case 'editUserId':
            if (!NAVIGATOR.isInAdminArea) {
              removeUrlParam('editUserId');
              return;
            }
            if (!STAFF.canManageClients) { removeUrlParam('editUserId'); return; }
            editUserInOverlay(UI, undefined, value);
            return;
          case 'invoiceId':
            if (!NAVIGATOR.isInAdminArea) {
              removeUrlParam('invoiceId');
              return;
            };
            viewInvoiceInOverlay(UI, value);
            return;
        }

      })

      s.firstLoad = false

    })
  }), { location, history });

  useOnMount(() => {
    s.processParams(500);
    return reaction(
      () => s.search,
      debounce(() => s.processParams(0, true), { timeout: 100 }),
    )
  })

  return <Observer children={() => <div className="URLParamWatcher" />} />
}

export default URLParamWatcher;