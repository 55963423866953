import { observable, runInAction } from "mobx";
import { Nillable, Nullable, Renderable, Undefinable } from "../../base/@types";
import { generateUuid } from "../../base/utils/id.utils";
import { recursiveMergeWithTypeCast } from "../../base/utils/object.utils";
import { getNowTimestampUtc } from "../../base/utils/time.utils";
import { isObject, isString } from "../../base/utils/typeChecks.utils";

export type BotChatMessageType = 'text' | 'podcast' | 'link' | 'action';

export const makeBotChatMessageSnapshotBase = () => ({
  id: '',
  body: '' as Renderable,
  type: '' as BotChatMessageType,
  link: null as Nillable<string>,
  openInNewTab: false as Nillable<boolean>,
  name: '',
  action: undefined as Undefinable<() => void>,
  disableWhen: null as Nullable<() => boolean>,
  participantId: '',
  timeCreated: '',
  timeUpdated: '' as Nullable<string>,
  timeDeleted: null as Nullable<string>,
  timeHidden: null as Nullable<string>,
  timeActioned: null as Nullable<string>,
  isAutomated: false,
  shouldPersist: true,
})

export type BotChatMessageSnapshot = ReturnType<typeof makeBotChatMessageSnapshotBase>;

export const makeBotChatMessage = (
  snapshot?: Partial<BotChatMessageSnapshot>
) => {
  const base = makeBotChatMessageSnapshotBase();
  const model = observable({
    ...recursiveMergeWithTypeCast(base, snapshot),
  })
  runInAction(() => {
    if (!model.type) {
      if (model.action) model.type = 'action';
      else if (model.link || model.openInNewTab) model.type = 'link';
      else model.type = 'text';
    }
  })
  return model;
}

export type BotChatMessage = ReturnType<typeof makeBotChatMessage>;

export const makeAutomatedMessage = (snapshot?: string | Partial<BotChatMessageSnapshot>) => {
  const base: Partial<BotChatMessageSnapshot> = {
    id: generateUuid(),
    isAutomated: true,
    timeCreated: getNowTimestampUtc(),
  }
  let s: BotChatMessage;
  if (isString(snapshot)) {
    s = makeBotChatMessage({ ...base, body: snapshot })
  } else if (isObject(snapshot)) {
    s = makeBotChatMessage({ ...base, ...snapshot });
  } else {
    s = makeBotChatMessage(base);
  }
  return s;
}