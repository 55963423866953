import { Observer } from 'mobx-react-lite';
import React from 'react';
import CountryRenderer from '../../base/components/CountryRenderer/CountryRenderer';
import joinClassName from '../../base/utils/className.utils';
import { AddressSnapshot } from '../../models/makeAddress.model';
import './AddressRenderer.scss';

interface AddressRendererProps {
  address?: AddressSnapshot,
  withLineBreaks?: boolean,
}

const AddressRenderer: React.FC<AddressRendererProps> = props => {
  return <Observer children={() => {
    const { address: a } = props;
    return <address className={
      joinClassName('AddressRenderer', props.withLineBreaks && 'withLineBreaks')
    }>
      {a ? <>
        <strong data-label="lineOne">{a.lineOne},{props.withLineBreaks ? <br /> : ' '}</strong>
        {a.lineTwo && <span data-label="lineTwo">{a.lineTwo},{props.withLineBreaks ? <br /> : ' '}</span>}
        <span data-label="cityAndPostCode">{[a.city, a.postcode].filter(i => i).join(' ')},{props.withLineBreaks ? <br /> : ' '}</span>
        {a.region && <span data-label="region">{a.region},{props.withLineBreaks ? ' ' : ' '}</span>}
        <span data-label="country"><CountryRenderer countryId={a.countryId} /></span>
      </> : ''}
    </address>
  }} />
}

export default AddressRenderer;