import { makeConstantPromise, resolveWhen } from "../../base/utils/promises.utils";
import { always } from "../../base/utils/ramdaEquivalents.utils";
import { makeObservableRouteDef } from "../../base/utils/routes.utils";
import RedirectToLogin from "../../components/RedirectToLogin/RedirectToLogin";
import { AuthController } from "../../contexts/context";
import { AccountModuleRouteDef } from "../shared/Account/Account.module.routes";
import { ChatModuleRouteDef } from "../shared/Chat/Chat.module.routes";
import ModuleClient from "./Client.module";
import { CounsellingModuleRouteDef } from "./Counselling/Counselling.module.routes";
import { ExploreModuleRouteDef } from "./Explore/Explore.module.routes";
import { NotificationsModuleRouteDef } from "./Notifications/Notifications.module.routes";
import { SupportGroupsModuleRouteDef } from "./SupportGroups/SupportGroups.module.routes";
import { PageInvitationRouteDef } from "./_pages/PageInvitation/PageInvitation.page.route";

export const ClientModuleChildRoutes = [
  ExploreModuleRouteDef,
  CounsellingModuleRouteDef,
  AccountModuleRouteDef,
  NotificationsModuleRouteDef,
  SupportGroupsModuleRouteDef,
  PageInvitationRouteDef,
  ChatModuleRouteDef,
]

export const ClientModuleRouteDef = makeObservableRouteDef({
  identifier: 'client',
  urlFactory: always('/app'),
  component: ModuleClient,
  composeTitle: always('Client'),
  children: ClientModuleChildRoutes,
  guards: {
    onRender: resolveWhen(
      () => AuthController.ready,
      () => AuthController.isAuthenticated,
    ),
    beforeLeave: makeConstantPromise(true),
  },
  fallback: RedirectToLogin
})
