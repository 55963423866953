import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { createElement, useRef } from 'react';
import { CLOCK } from '../../../controllers/common/clock.controller';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useCreateResizeQueryWithRef } from '../../hooks/useCreateResizeQueryWithRef.hook';
import joinClassName from '../../utils/className.utils';
import { setCSSCustomProperty } from '../../utils/css.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { createUTCMoment, YYYYMMDDHHmmss } from '../../utils/time.utils';
import BaseButton from '../BaseButton/BaseButton';
import { BaseCalendarBaseChildProps } from './BaseCalendar';
import './BaseCalendarDay.scss';
import BaseCalendarEventList from './BaseCalendarEventList';

interface BaseCalendarDayProps<EventDataType = object> extends BaseCalendarBaseChildProps<EventDataType> {
  day: string;
  index: number;
}

function BaseCalendarDay<EventDataType extends object>(props: React.PropsWithChildren<BaseCalendarDayProps<EventDataType>>) {

  const p = useProps(props)
  
  const { ref, query } = useCreateResizeQueryWithRef<HTMLDivElement>();
  const headerRef = useRef<HTMLDivElement>(null);

  const s = useStore(() => ({
    get nowMoment() {
      return CLOCK.localNowMoment
    },
    get calendar() {
      return p.state;
    },
    get calendarMode() {
      return s.calendar.mode;
    },
    get calendarEvents() {
      return [...p.internalCalendarEvents, ...p.events || []];
    },
    get startOfDayMoment() {
      return moment(p.day).startOf('day');
    },
    get endOfDayMoment() {
      return moment(p.day).endOf('day');
    },
    get midDayMoment() {
      return moment(p.day).hour(12);
    },
    get isToday() {
      return s.startOfDayMoment.isSame(s.nowMoment, 'day');
    },
    get dayOfWeek() {
      return s.startOfDayMoment.weekday();
    },
    get isBeforeTimeframe() {
      return s.midDayMoment.isBefore(s.calendar.startDateMoment);
    },
    get isAfterTimeframe() {
      return s.midDayMoment.isAfter(s.calendar.endDateMoment);
    },
    get isOutsideOfTimeframe() {
      return s.isBeforeTimeframe || s.isAfterTimeframe;
    },
    get isInsideOfTimeframe() {
      return !s.isOutsideOfTimeframe;
    },
    get eventsInThisDay() {
      return s.calendarEvents.filter(e => e.startTime && createUTCMoment(e.startTime).local().isSame(s.midDayMoment, 'day'));
    },
    get shouldShowEventEndTime() {
      return s.calendar.showEventEndTimes
    },
    getDefaultNewEventTime: () => {
      const momentToUse = (
        s.isToday ? moment(p.day).hour(CLOCK.localNowMoment.hour()).add(3, 'hours').startOf('hour')
          : moment(p.day).hour(9).startOf('hour')
      );
      while (s.eventsInThisDay.find(e => moment(e.startTime).isSame(momentToUse, 'hour'))) {
        momentToUse.add(1, 'hour');
      }
      while (!momentToUse.isSame(s.midDayMoment, 'date') || momentToUse.hour() > 21) {
        momentToUse.subtract(1, 'hour');
      }
      const result = momentToUse.utc().format(YYYYMMDDHHmmss);
      return result;
    },
    get createEvent() {
      return p.eventCreator ? () => {
        p.eventCreator && p.eventCreator(
          s.getDefaultNewEventTime(),
          p.internalCalendarEvents
        );
      } : null;
    },
    get shouldDisplayAsTimeline() {
      return p.state.mode === 'week' && p.state.shouldDisplayAsTimeline;
    },
    get shouldShowWeekday() {
      return s.shouldDisplayAsTimeline;
    }
  }));

  useOnMount(() => {
    if (p.index !== 0) return;
    const handler = () => {
      const height = headerRef.current?.clientHeight;
      setCSSCustomProperty('--BaseCalendarDayHeaderHeight', height + 'px', ref.current?.parentElement);
    }
    query.onResize(handler);
    handler();
  })

  return <Observer children={() => (
    createElement(
      s.shouldDisplayAsTimeline ? 'div' : 'td',
      {
        className: joinClassName(
          'BaseCalendarDay',
          s.isToday && 'today',
          s.isOutsideOfTimeframe && 'outsideOfTimeframe'
        ),
        'data-day-of-week': s.dayOfWeek,
        ref,
      },
      <div className="BaseCalendarDayInner">
        <header className="BaseCalendarDayHeader" ref={headerRef}>
          {s.shouldShowWeekday && <p className="BaseCalendarDayWeekday">{s.startOfDayMoment.local().format('dd')[0]}</p>}
          <h3 className="BaseCalendarDayTitle">{s.startOfDayMoment.local().format('D')}</h3>
        </header>
        <BaseCalendarEventList<EventDataType>
          {...p}
          events={s.eventsInThisDay}
          eventTypeGroups={p.state.eventTypeGroups}
          shouldShowEventEndTime={s.shouldShowEventEndTime}
        />
        {s.createEvent && <span className='BaseCalendarDayBackgroundAddButton' onClick={s.createEvent} />}
        {s.createEvent && <BaseButton className="BaseCalendarDayAddButton subtle" onClick={s.createEvent} size="sm">+ New</BaseButton>}
      </div>
    )
  )} />
}

export default BaseCalendarDay;