import { Identifier, Nullable } from "../base/@types";
import { CounsellingApplicationEndpointParams, CounsellingApplicationEndpoints, DefaultCounsellingApplicationIncludes } from "../base/endpoints/counsellingApplication.endpoints";
import { useControllers } from "../base/hooks/useRootController.hook";
import { ModelName } from "../constants/modelNames.enum";
import { CounsellingApplication } from "../models/makeCounsellingApplication.model";
import { useAutoGet, UseAutoGetFactoryOptions } from "../utils/useAutoGet.utils";

export function useGetCounsellingApplication<ObservableType extends object = object>(
  options: UseAutoGetFactoryOptions<CounsellingApplication, ObservableType>,
  id?: Nullable<Identifier>,
  params?: CounsellingApplicationEndpointParams,
) {
  const { AUTH } = useControllers();
  const canUseAdminEndpoint = AUTH.can.manage_.counsellingApplications;
  const _params = params || {
    include: DefaultCounsellingApplicationIncludes
  }
  useAutoGet<CounsellingApplication, ObservableType>({
    ...options,
    url: id ? CounsellingApplicationEndpoints[canUseAdminEndpoint ? 'staff' : 'own'].get(id, _params) : undefined,
    getFromLocalById: id,
    modelName: ModelName.counsellingApplications,
  });
}