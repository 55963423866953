import { ChatParticipantEndpoints } from "../base/endpoints/chatParticipant.endpoints";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { ChatParticipant } from "../models/makeChatParticipant.model";

export function inviteChatParticipant(API: APIController, threadId: string, userId: string) {
  return new Promise<ChatParticipant>(async (resolve, reject) => {
    try {
      const url = ChatParticipantEndpoints.staff.create();
      const payload = {
        threadId: threadId,
        userId: userId,
      }
      const response = await API.post<ChatParticipant>(url, ModelName.chatParticipants, payload);
      if (!response) {
        throw Error('Failed to invite chat participant');
      }
      return response;
    } catch (e) {
      reject(e);
    }
  })
}