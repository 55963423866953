import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseIcon from '../../../../base/components/BaseIcon/BaseIcon';
import joinClassName from '../../../../base/utils/className.utils';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import { CounsellingApplicationForm } from '../../../../models/makeCounsellingApplication.model';
import { ApplicationStepDescriptor } from '../CounsellingApplicationUI/applicationStepDescriptors';
import { CounsellingApplicationInterceptedPayFunctionType } from '../CounsellingApplicationUI/CounsellingApplicationUI';
// import './CounsellingApplicationUIStep.scss';

type CounsellingApplicationUIStepProps = {
  form: CounsellingApplicationForm,
  stepDescriptor: ApplicationStepDescriptor,
  // allStepDescriptors: ApplicationStepDescriptor[],
  // index: number,
  // currentStepId: string,
  enableStep: () => boolean,
  footerHeight: number,
  onInterceptedPay: CounsellingApplicationInterceptedPayFunctionType,
}

const CounsellingApplicationUIStep: React.FC<CounsellingApplicationUIStepProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get enabled() {
      return p.enableStep();
      // return p.allStepDescriptors.slice(0, p.index).every(step => step.validator(p.form));
    },
    // get hasFocus() {
    //   return p.stepDescriptor.id === p.currentStepId;
    // }
    get renderedComponent() {
      return <p.stepDescriptor.component
        form={p.form}
        // hasFocus={s.hasFocus}
        descriptor={p.stepDescriptor}
        enabled={s.enabled}
        onInterceptedPay={p.onInterceptedPay}
      />
    },
  }))
  return <Observer children={() => (
    <div
      className={joinClassName('CounsellingApplicationUIStep', !s.enabled && 'disabled')}
      key={p.stepDescriptor.id}
      style={{ paddingBottom: p.footerHeight }}
    >
      {s.renderedComponent}
      {!s.enabled && <div className="CounsellingApplicationUIStepDisabledScreen">
        <div>
          <BaseIcon icon="info" size="28" />
          <p><em>Please complete the previous steps first.</em></p>
        </div>
      </div>}
    </div>
  )} />
}

export default CounsellingApplicationUIStep;