import { Observer } from 'mobx-react-lite';
import React from 'react';
import CountryRenderer from '../../base/components/CountryRenderer/CountryRenderer';
import { getColorHexByName } from '../../base/utils/colors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { User } from '../../models/makeUser.model';
// import './UserCountryRenderer.scss';

type UserCountryRendererProps = {
  user: User,
  showDetectedCountry?: boolean,
  withLineBreaks?: boolean,
  showWarningIfMismatch?: boolean,
}

const UserCountryRenderer: React.FC<UserCountryRendererProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get detected() {
      return p.user.countryDetectedId;
    },
    get provided() {
      return p.user.countryProvidedId;
    }
  }))
  return <Observer children={() => (
    <div className="UserCountryRenderer">
      { s.detected && s.provided && s.detected === s.provided && (
        <CountryRenderer countryId={s.detected} showFlag/>
      ) }
      { s.detected && s.provided && s.detected !== s.provided && <>
        { p.showDetectedCountry && <><span>Detected from IP: <strong><CountryRenderer countryId={s.detected} /></strong>; </span>{p.withLineBreaks && <br />}</>}
        <span>In address: <strong><CountryRenderer countryId={s.provided} /></strong> </span>{p.withLineBreaks && <br />}
        {p.showWarningIfMismatch && <em style={{color: getColorHexByName('red'), marginTop: '.19em', display: 'inline-block'}}>* User's country from their detected IP differs from provided address.</em>}
      </> }
      { p.showDetectedCountry && s.detected && !s.provided && <>
        <span>Detected from IP: <strong><CountryRenderer countryId={s.detected} showFlag /></strong></span>
      </> }
      { !s.detected && s.provided && <>
        <span>In address: <strong><CountryRenderer countryId={s.provided} showFlag /></strong></span>
      </> }
      { !s.detected && !s.provided && <strong>Unknown Country</strong> }
    </div>
  )} />
}

export default UserCountryRenderer;