import { IconName } from "../base/components/Symbols/iconDefs"
import { ColorName } from "../base/constants/color.enum"
import { getColorHexByName } from "../base/utils/colors.utils"

export enum CommunicationType {
  'Text' = 'text',
  'Video' = 'video',
  'Email' = 'email',
  'VOIP' = 'voip',
};

export type CommunicationTypeDescriptor = {
  abbr: string,
  name: string,
  value: CommunicationType,
  icon: IconName,
  colorName: ColorName,
  color?: string,
  disabled?: boolean,
  hidden?: boolean,
}
export const communicationTypeDescriptors: Record<CommunicationType, CommunicationTypeDescriptor> = {
  [CommunicationType.Text]: {
    icon: 'chat',
    value: CommunicationType.Text,
    colorName: ColorName.SkyBlue,
    name: 'Text Chat',
    abbr: 'Text',
  },
  [CommunicationType.Video]: {
    icon: 'video',
    value: CommunicationType.Video,
    colorName: ColorName.Red,
    name: 'Video Chat',
    abbr: 'Video',
  },
  [CommunicationType.Email]: {
    icon: 'mail',
    value: CommunicationType.Email,
    colorName: ColorName.BlueGreen,
    name: 'Email',
    abbr: 'Email',
  },
  [CommunicationType.VOIP]: {
    icon: 'phone',
    value: CommunicationType.VOIP,
    colorName: ColorName.Orange,
    name: 'By Phone',
    abbr: 'Phone',
  },
}

export const makeCommunicationTypeList = (options?: {
  showEmail?: boolean,
  showVoip?: boolean,
  enableEmail?: boolean,
  enableVoip?: boolean,
}) => [
  { name: 'Unspecified', abbr: 'Unset', value: '', icon: 'help', colorName: ColorName.Gray, color: getColorHexByName(ColorName.Gray) },
  { name: 'Text Chat', abbr: 'Text', value: 'text', icon: 'chat', colorName: ColorName.SkyBlue, color: getColorHexByName(ColorName.SkyBlue) },
  { name: 'Video Chat', abbr: 'Video', value: 'video', icon: 'video', colorName: ColorName.Red, color: getColorHexByName(ColorName.Red) },
  ...options?.showEmail ? [{ name: `Emails${options?.enableEmail ? '' : ' (coming soon)'}`, abbr: 'Email', value: 'email', icon: 'mail', colorName: ColorName.BlueGreen, color: getColorHexByName(ColorName.BlueGreen), disabled: !options?.enableEmail, hidden: true }] : [],
  ...options?.showVoip ? [{ name: `By Phone${options?.enableVoip ? '' : ' (coming soon)'}`, abbr: 'VOIP', value: 'voip', icon: 'phone', colorName: ColorName.Orange, color: getColorHexByName(ColorName.Orange), disabled: !options?.enableVoip }] : [],
] as CommunicationTypeDescriptor[]