import moment from "moment";
import React from "react";
import { ColorCodedState, Nillable } from "../base/@types";
import InfoBanner from "../base/components/InfoBanner/InfoBanner";
import { getColorHexByName } from "../base/utils/colors.utils";
import { cond, isNil } from "../base/utils/ramdaEquivalents.utils";
import { User, UserSnapshot } from "../models/makeUser.model";
import { PercentageBarDataItem } from "../modules/Admin/AdminStatistics/_components/PercentageBar/PercentageBar";

export enum AgeGroupValue {
	'18+' = '18+',
	'12-14' = '12-14',
	'15-17' = '15-17',
}

export enum AgeGroupFilterType {
	'adults' = '18+',
	'youngPeople1214' = '12-14',
	'youngPeople1517' = '15-17',
	'notAvailable' = 'n/a',
};

export const AgeGroupFilterTypeSelectorOptions = [
	{
		value: AgeGroupFilterType.adults,
		label: AgeGroupFilterType.adults,
		color: getColorHexByName('brightPurple'),
	},
	{
		value: AgeGroupFilterType.youngPeople1214,
		label: AgeGroupFilterType.youngPeople1214,
		color: getColorHexByName('yellow'),
	},
	{
		value: AgeGroupFilterType.youngPeople1517,
		label: AgeGroupFilterType.youngPeople1517,
		color: getColorHexByName('orange'),
	},
	{
		value: AgeGroupFilterType.notAvailable,
		label: AgeGroupFilterType.notAvailable,
		color: getColorHexByName('steel'),
	},
]

export function getAgeFromDateOfBirth(
	dateOfBirth: string | Date | moment.Moment | undefined | null,
	exact: boolean = false
): number | undefined {
	if (!dateOfBirth) return undefined;
	const result = moment.utc().diff(dateOfBirth, 'years', exact);
	return result ? result : undefined;
}

export function getAgeFromAgeGroup(ageGroup: AgeGroupValue): number | undefined {
	if (!ageGroup) return undefined;
	switch (ageGroup) {
		case AgeGroupValue['15-17']:
			return 15;
		case AgeGroupValue["18+"]:
			return 18;
		case AgeGroupValue['12-14']:
		default:
			return 12;
	}
}

export const getUserAge = (user: UserSnapshot) => user.ageGroup ? getAgeFromAgeGroup(user.ageGroup) ?? null : null;
// export const getUserAge = (user: UserSnapshot) => user.dateOfBirth ? getAgeFromDateOfBirth(user.dateOfBirth) ?? null : null;

export function isValidAge(age?: number | null): age is number {
	if (isNil(age) || isNaN(+age)) return false;
	return age < 150 && age >= 0;
}
export function isUnder12Age(age?: number | null): age is number {
	return Boolean(isValidAge(age) && age < 12);
}
export function isYoungPeopleAge(age?: number | null): age is number {
	return Boolean(isValidAge(age) && age >= 12 && age < 18);
}
export function isYoungPeopleAge1214(age?: number | null): age is number {
	return Boolean(isValidAge(age) && age >= 12 && age < 15);
}
export function isYoungPeopleAge1517(age?: number | null): age is number {
	return Boolean(isValidAge(age) && age >= 15 && age < 18);
}
export function isChildAge(age?: number | null): age is number {
	return Boolean(isValidAge(age) && age < 12 && age >= 0);
}
export function isAdultAge(age?: number | null): age is number {
	return Boolean(isValidAge(age) && age >= 18);
}
export function isUserUnder12(user?: Nillable<User>) {
	if (!user) return false;
	const age = getUserAge(user);
	return isUnder12Age(age);
}
export function isUserYoungPerson(user?: Nillable<User>) {
	if (!user) return false;
	const age = getUserAge(user);
	return isYoungPeopleAge(age);
}
export function isUserAdult(user?: User | UserSnapshot | null) {
	if (!user) return false;
	const age = getUserAge(user);
	return isAdultAge(age);
}
export function isUserYoungPerson1214(user?: User | UserSnapshot | null) {
	if (!user) return false;
	const age = getUserAge(user);
	return isYoungPeopleAge1214(age);
}
export function isUserYoungPerson1517(user?: User | UserSnapshot | null) {
	if (!user) return false;
	const age = getUserAge(user);
	return isYoungPeopleAge1517(age);
}
export function isUserAgeGroupNotAvailable(user?: User | UserSnapshot | null) {
	if (!user) return false;
	return !(isUserAdult(user) || isUserYoungPerson1214(user) || isUserYoungPerson1517(user))
}

export const renderUserAgeNotice = (age?: number | null) => {
	return cond([
		[
			() => !isValidAge(age),
			() => <InfoBanner heading="Hmm.. this date of birth does not seem to be valid." icon="info" colorCodedState={ColorCodedState.attention} />
		],
		// [
		// 	() => isYoungPeopleAge(age),
		// 	() => <InfoBanner heading="Young People Account" icon="info" colorCodedState={ColorCodedState.positive}>
		// 		<p>Your account will be an Young People account (age 12-17).</p>
		// 	</InfoBanner>,
		// ],
		[
			() => isChildAge(age),
			() => <InfoBanner icon="info" colorCodedState={ColorCodedState.attention}>
				<p>Sorry! You must be over age 12 in order to use our services. If you need help, you can either ask a parent, a legal guardian or contact us directly.</p>
			</InfoBanner>
		]
	])()
}

const ageGroupColorMap = {
	[AgeGroupFilterType.adults]: getColorHexByName('brightPurple'),
	[AgeGroupFilterType.youngPeople1214]: getColorHexByName('yellow'),
	[AgeGroupFilterType.youngPeople1517]: getColorHexByName('orange'),
	[AgeGroupFilterType.notAvailable]: getColorHexByName('steel'),
}
export const getAgeGroupColor = (ag: AgeGroupFilterType) => {
	return ageGroupColorMap[ag];
}

export const makeAgeGroupStatCounterGroup = () => ({
	[AgeGroupFilterType.adults]: 0,
	[AgeGroupFilterType.youngPeople1214]: 0,
	[AgeGroupFilterType.youngPeople1517]: 0,
	[AgeGroupFilterType.notAvailable]: 0,
}) as Record<AgeGroupFilterType, number>;

export const makeAgeGroupPercentageBarDataItemSet = <T extends object>() => {
	const result = {} as Record<AgeGroupFilterType, PercentageBarDataItem<T>>;
	Object.values(AgeGroupFilterType).forEach(ag => {
		result[ag] = {
			color: getAgeGroupColor(ag),
			value: 0,
			details: '',
			entries: [],
		}
	})
	return result;
}

export const groupDataByAgeGroup = <T extends { user?: User | null }>(arr: T[]) => {
	const set = makeAgeGroupPercentageBarDataItemSet();
	arr.forEach(a => {
		// if (!a.user) return;
		if (isUserAdult(a.user)) set[AgeGroupFilterType.adults].value++;
		if (isUserYoungPerson1214(a.user)) set[AgeGroupFilterType.youngPeople1214].value++;
		if (isUserYoungPerson1517(a.user)) set[AgeGroupFilterType.youngPeople1517].value++;
		if (isUserAgeGroupNotAvailable(a.user)) set[AgeGroupFilterType.notAvailable].value++;
	})
	const result = Object.values(set);
	result.forEach(r => r.details = r.value);
	return result;
}
