import { runInAction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState, Nillable } from '../../base/@types';
import BaseLabel from '../../base/components/BaseLabel/BaseLabel';
import BaseToggle from '../../base/components/BaseToggle/BaseToggle';
import CurrencyRenderer from '../../base/components/CurrencyRenderer/CurrencyRenderer';
import InfoBanner from '../../base/components/InfoBanner/InfoBanner';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { isNil } from '../../base/utils/ramdaEquivalents.utils';
import { getNowTimestampUtc } from '../../base/utils/time.utils';
import { CounsellingSessionPaidFee, NUM_FREE_COUNSELLING_SESSIONS } from '../../constants/counsellingFeeTypes.constants';
import { P_ } from '../../constants/permissions.alias';
import { CounsellingApplication } from '../../models/makeCounsellingApplication.model';
import { CounsellingSession } from '../../models/makeCounsellingSession.model';
import { saveCounsellingSession } from '../../requests/saveCounsellingSession.request';
import CounsellingSessionFeeTypeSelector from '../CounsellingSessionFeeTypeSelector/CounsellingSessionFeeTypeSelector';
import SessionPaymentStatus from '../SessionPaymentStatus/SessionPaymentStatus';
import './CounsellingSessionPriceSetterForm.scss';

interface CounsellingSessionPriceSetterFormProps {
  session: CounsellingSession,
  application?: Nillable<CounsellingApplication>,
  disabled?: any,
}

const CounsellingSessionPriceSetterForm: React.FC<CounsellingSessionPriceSetterFormProps> = props => {

  const { AUTH, API, UI } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    form: {
      isRefunded: p.session.isRefunded,
      isPaymentEnforcedByCounsellor: p.session.isPaymentEnforcedManuallyByCounsellor,
    },
    get assignments() {
      return p.session.assignments;
    },
    get canEdit() {
      return s.assignments && !p.session.hasClientPaid;
    },
    get disableChangePrice() {
      return p.session.hasClientPaid || p.session.timeStarted || p.session.isCancelled;
    },
    get allowToggleRefund() {
      return p.session.isPaidSession && p.session.hasClientPaid && AUTH.currentUser!.can?.(P_.markSessionAsRefunded);
    },
    get isFreeApplication() { return p.session.isFreeApplication; },
    get isPaidApplication() { return p.session.isPaidApplication; },
    get showToggleEnforcePayment() {
      // return s.isFreeApplication;
      return true;
    },
    get allowToggleEnforcePayment() {
      // is free application and exceeded 6th session.
      const freeAppAndExceededFreeSessions = s.isFreeApplication && ((s.isNew && p.application?.isExceededFreeSessions) || p.session.isPaidSessionInFreeApplication);

      return s.showToggleEnforcePayment && (freeAppAndExceededFreeSessions || s.isPaidApplication);
    },
    get canEnforcePaymentCollection() {
      return AUTH.currentUser!.can?.(P_.enforceSessionPaymentCollection);
    },
    get isNew() {
      return !p.session.id;
    },
  }));

  const toggleIsRefunded = async () => {
    p.session.timeRefunded = p.session.isRefunded ? '' : getNowTimestampUtc();
    await saveCounsellingSession(API, p.session, UI);
  }
  const toggleIsPaymentEnforced: () => Promise<boolean> = async () => {
    if (!s.canEnforcePaymentCollection) {
      UI.DIALOG.alert({
        heading: 'You do not have the permission to modify session payment enforcement',
        body: 'Please contact your clinical manager to change payment enforcement for this session.'
      })
      return false;
    }
    const toggleAndSave = async () => {
      if (!s.disableChangePrice) p.session.amountPayable = p.session.isPaymentEnforcedManuallyByCounsellor ? 0 : CounsellingSessionPaidFee.value;
      p.session.timePaymentEnforced = p.session.isPaymentEnforcedManuallyByCounsellor ? '' : getNowTimestampUtc();
      if (!s.isNew) await saveCounsellingSession(API, p.session, UI);
    }
    await toggleAndSave();
    return true;

    // Prompts Dialog before toggling.
    // if (!s.form.isPaymentEnforcedByCounsellor) {
    //   return await UI.DIALOG.attention({
    //     heading: 'Enforce session payment collection',
    //     body: <>
    //       <p>This must only be used for paying sessions for clients who have completed their 6 free counselling sessions with turn2me.</p>
    //       <p><strong>This change will notify and prompt clients to pay for the session.</strong></p>
    //     </>,
    //     actions: [
    //       makeActionConfig('Cancel', () => false, { buttonClass: 'subtle' }),
    //       makeActionConfig('Enforce', async () => {
    //         await toggleAndSave();
    //         return true;
    //       }),
    //     ]
    //   })
    // } else {
    //   await toggleAndSave();
    //   return true;
    // }
  }

  useOnMount(() => {
    if (s.isNew && s.allowToggleEnforcePayment) {
      runInAction(() => {
        p.session.amountPayable = CounsellingSessionPaidFee.value;
        p.session.timePaymentEnforced = getNowTimestampUtc();
        s.form.isPaymentEnforcedByCounsellor = true;
      })
    }
  })

  return <Observer children={() => (
    <div className="CounsellingSessionPriceSetterForm">
      <ShadedBlock>
        {
          s.disableChangePrice
            ? <p className="CounsellingSessionPriceSetterFormPriceDisplay">
              <CurrencyRenderer value={p.session.amountPayable} emptyValue="Free" />
            </p>
            : (
              <CounsellingSessionFeeTypeSelector form={p.session} field="amountPayable" application={p.application} session={p.session} disabled={p.session.hasClientPaid || p.disabled} />
            )
        }
        {p.session.isFreeApplication && <InfoBanner icon="info" colorCodedState={ColorCodedState.positive}>
          {p.session.isFreeSession && <>
            {isNil(p.application?.clientAgreedDonationAmountPerSession)
              ? <p>The applicant did not indicate whether they are willing to make donation per session.</p>
              : (
                <p>In the application form, the applicant indicated that {
                  p.application!.clientAgreedDonationAmountPerSession === 0 ? 'they are not in a position to make donations.' : <>they are in a position to donate <CurrencyRenderer value={p.application!.clientAgreedDonationAmountPerSession} /> per session.</>
                }</p>
              )
            }
          </>}
          {((s.isNew && p.application!.isExceededFreeSessions) || p.session.isPaidSession) && <p>The client has exceeded their {NUM_FREE_COUNSELLING_SESSIONS} free sessions, and subsequent sessions will cost €{CounsellingSessionPaidFee.value}.</p>}
        </InfoBanner>}
      </ShadedBlock>
      {
        Boolean(p.session.id && p.session.amountPayable) && (
          <BaseLabel>{p.session.isPaidSession ? 'Payment' : 'Donation'} Status: <SessionPaymentStatus status={p.session.paymentStatus} /></BaseLabel>
        )
      }
      {
        s.allowToggleRefund && <ShadedBlock color="red" borderWidth="0" padding="xs" spaceChildren>
          <BaseToggle form={s.form} field="isRefunded" onChange={toggleIsRefunded} color="red" disabled={!s.allowToggleRefund}>
            <p><strong>Mark payment status as refunded</strong></p>
            <p><small>This doesn't produce a refund to the client. This must be manually handled by an admin in Stripe.</small></p>
          </BaseToggle>
        </ShadedBlock>
      }
      {
        (s.showToggleEnforcePayment && s.allowToggleEnforcePayment) && <ShadedBlock color="green" borderWidth="0" padding="xs" spaceChildren>
          <BaseToggle form={s.form} field="isPaymentEnforcedByCounsellor" color="green" disabled={!s.allowToggleEnforcePayment} beforeChange={toggleIsPaymentEnforced}>
            <p><strong>Enforce session payment collection</strong></p>
            <p><small>Only select this option for clients after their {NUM_FREE_COUNSELLING_SESSIONS} free sessions. This is to be used once clients complete a free counselling journey and would like to continue with paid counselling.</small></p>
          </BaseToggle>
        </ShadedBlock>
      }
    </div>
  )} />

}

export default CounsellingSessionPriceSetterForm;