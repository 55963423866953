import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useControllers } from '../../../../../base/hooks/useRootController.hook';
import { useStore } from '../../../../../base/utils/mobx.utils';
import { intersection } from '../../../../../base/utils/ramdaEquivalents.utils';
import { CommunicationType } from '../../../../../constants/communicationTypes.descriptors';
import { ModelName } from '../../../../../constants/modelNames.enum';
import { CounsellingAvailability } from '../../../../../models/makeCounsellingAvailability.model';
import { isOneToOneCounselling } from '../../../../../utils/counsellingApplication.utils';
import { CounsellingApplicationStepProps } from '../applicationStepDescriptors';
import CommunicationTypeSelectorOptionCard from '../components/CommunicationTypeSelectorOptionCard/CommunicationTypeSelectorOptionCard';
import './CounsellingApplicationStepCommunicationType.scss';

interface CounsellingApplicationStepCommunicationTypeProps extends CounsellingApplicationStepProps {}

const CounsellingApplicationStepCommunicationType: React.FC<CounsellingApplicationStepCommunicationTypeProps> = p => {
  const setValue = action((type: CommunicationType) => p.form.application.communicationTypePreference = type);
  const { LOCALDB } = useControllers();
  const s = useStore(() => ({
    get availability() {
      if (!p.form.application.availabilityId) return null;
      return LOCALDB.get<CounsellingAvailability>(ModelName.counsellingAvailabilities, p.form.application.availabilityId);
    },
    get availableOptionsToTypes() {
      switch (true) {
        case isOneToOneCounselling(p.form.selectedApplicationType):
          return [CommunicationType.Text, CommunicationType.Video];
        default:
          return [CommunicationType.Video];
      }
    },
    get availableOptions() {
      if (!s.availability) return [];
      if (!s.availability.communicationTypesAllowed) return s.availableOptionsToTypes;
      if (s.availability.communicationTypesAllowed.length === 0) return s.availableOptionsToTypes;
      return intersection(s.availableOptionsToTypes, s.availability.communicationTypesAllowed)
    },
  }))
  return <Observer children={() => (
    <div className="CounsellingApplicationStepCommunicationType">
      <blockquote>
        <p>How do you prefer to have your counselling sessions?</p>
      </blockquote>
      <div className="CommunicationTypeSelectorGrid">
        {
          s.availableOptions.map(d => <CommunicationTypeSelectorOptionCard
            key={d}
            forType={d}
            onClick={() => setValue(d)}
            isSelected={d === p.form.application.communicationTypePreference}
          />)
        }
      </div>
    </div>
  )} />
}

export default CounsellingApplicationStepCommunicationType;