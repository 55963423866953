import { Observer } from 'mobx-react-lite';
import React from 'react';
import ExploreThoughtCatcherComposerOverlayPrompt from '../ExploreThoughtCatcherComposerOverlayPrompt/ExploreThoughtCatcherComposerOverlayPrompt';
import './ExploreThoughtCatcher.scss';

interface ExploreThoughtCatcherProps {
}

const ExploreThoughtCatcher: React.FC<ExploreThoughtCatcherProps> = props => {

  // const { AUTH } = useControllers();

  return <Observer children={() => (
    <div className="ExploreThoughtCatcher">
      <ExploreThoughtCatcherComposerOverlayPrompt />
      {/* <ExploreThoughtCatcherTodayPrompt /> */}
    </div>
  )} />
}

export default ExploreThoughtCatcher;