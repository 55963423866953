import { Observer } from 'mobx-react-lite';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { ColorCodedState, RouteDef } from '../../../base/@types';
import BaseMenu from '../../../base/components/BaseMenu/BaseMenu';
import BaseMenuItem from '../../../base/components/BaseMenu/BaseMenuItem';
import BaseSeparator from '../../../base/components/BaseSeparator/BaseSeparator';
import BaseSpacer from '../../../base/components/BaseSpacer/BaseSpacer';
import TwoColumnView from '../../../base/components/TwoColumnView/TwoColumnView';
import TwoColumnViewSidebarItem from '../../../base/components/TwoColumnView/TwoColumnViewSidebarItem';
import { useControllers } from '../../../base/hooks/useRootController.hook';
import { useStore } from '../../../base/utils/mobx.utils';
import { makeUrl } from '../../../base/utils/url.utils';
import OverlayFeedbackForm from '../../../components/OverlayFeedbackForm/OverlayFeedbackForm';
import ProfileCard from '../../../components/ProfileCard/ProfileCard';
import { isDeveloper } from '../../../constants/permissions.constants';
import { verifyEmail } from '../../../controllers/auth/verifyEmail';
import { verifyMobileNumber } from '../../../controllers/auth/verifyMobileNumber';
import { getWordPressLink } from '../../../env';
import AdminTitleBar from '../../Admin/_components/AdminTitleBar/AdminTitleBar';
import ClientAppSubmodule from '../../Client/_components/ClientAppSubmodule/ClientAppSubmodule';
import ModuleSection from '../../_components/ModuleSection/ModuleSection';
import { AccountModuleRouteDef } from './Account.module.routes';
import './ModuleAccount.scss';
import { AccountPage2FARouteDef } from './pages/AccountPage2FA/AccountPage2FA.page.route';
import { AccountPageAddressManagerRouteDef } from './pages/AccountPageAddressManager/AccountPageAddressManager.page.route';
import { AccountPageCompanyRouteDef } from './pages/AccountPageCompany/AccountPageCompany.page.route';
import { AccountPageContactFormRouteDef } from './pages/AccountPageContactForm/AccountPageContactForm.page.route';
import { AccountPageDeleteAccountRouteDef } from './pages/AccountPageDeleteAccount/AccountPageDeleteAccount.page.route';
import { AccountPageDonationsRouteDef } from './pages/AccountPageDonations/AccountPageDonations.page.route';
import { AccountPageEmergencyContactsRouteDef } from './pages/AccountPageEmergencyContacts/AccountPageEmergencyContacts.page.route';
import { AccountPageManageDonationsRouteDef } from './pages/AccountPageManageDonations/AccountPageManageDonations.page.route';
import { AccountPagePaymentMethodsRouteDef } from './pages/AccountPagePaymentMethods/AccountPagePaymentMethods.page.route';
import { AccountPagePrivacyRouteDef } from './pages/AccountPagePrivacy/AccountPagePrivacy.page.route';
import { AccountPageProfileEditorRouteDef } from './pages/AccountPageProfileEditor/AccountPageProfileEditor.page.route';
import { AccountPageReceiptsRouteDef } from './pages/AccountPageReceipts/AccountPageReceipts.page.route';
import { AccountPageSupportGroupsRouteDef } from './pages/AccountPageSupportGroups/AccountPageSupportGroups.page.route';
import { AccountPageUpdatePasswordRouteDef } from './pages/AccountPageUpdatePassword/AccountPageUpdatePassword.page.route';
import OverlayProfileEditor from './_components/OverlayProfileEditor';

interface ModuleAccountProps { }

const ModuleAccount: React.FC<
  ModuleAccountProps
> = props => {

  const { AUTH, UI, NAVIGATOR } = useControllers();

  const s = useStore(() => ({
    get currentUser() {
      return AUTH.currentUser;
    },
    get shouldShowEmailPendingVerification() {
      return s.currentUser?.email && !s.currentUser?.timeVerifiedEmail;
    },
    get shouldShowMobileNumberPendingVerification() {
      return s.currentUser?.mobileNumber && !s.currentUser?.timeVerifiedMobileNumber;
    },
    get shouldShowPendingVerificationSection() {
      return s.shouldShowEmailPendingVerification || s.shouldShowMobileNumberPendingVerification;
    },
    logout: async () => {
      const confirm = await UI.DIALOG.present({
        heading: 'Are you sure you want to log out?',
      })
      if (!confirm) return;
      NAVIGATOR.navigateTo('/auth/logout');
    },
    get isInAdminModule() {
      return NAVIGATOR.isInAdminArea;
    },
    editProfile: () => {
      UI.OVERLAY.present({
        component: <OverlayProfileEditor />
      })
    },
    get verificationStatus() {
      return {
        emailVerified: !s.shouldShowMobileNumberPendingVerification,
        mobileNumberVerified: !s.shouldShowMobileNumberPendingVerification,
      }
    },
    verifyEmail: () => {
      verifyEmail(AUTH, true);
    },
    verifyMobileNumber: () => {
      verifyMobileNumber(AUTH, s.verificationStatus, true);
    },
    get childrenRoutePrefix() {
      return s.isInAdminModule ? '/admin/account/' : '/app/account/';
    },
    get nav() {
      return <nav className="ModuleAccountNav">

        <ModuleSection>
          {s.currentUser && <NavLink className="ModuleAccountNavProfileCardLink" to={makeUrl(s.childrenRoutePrefix, 'profile')}>
            <ProfileCard user={s.currentUser} />
          </NavLink>}
        </ModuleSection>

        <BaseSpacer size="1em" />

        <div className="ModuleAccountNavMenuList">

          {s.isInAdminModule && <BaseMenu><BaseMenuItem icon="explore" to="/app/explore" className="BaseMenuItemFrontEndPortal">Frontend Client Dashboard</BaseMenuItem></BaseMenu>}

          {((AUTH.can.access_.adminPanels || isDeveloper(AUTH.currentUser)) && !s.isInAdminModule) && <BaseMenu>
            <BaseMenuItem icon="cog" to="/admin/dashboard" className="BaseMenuItemAdminPanels">turn2me Administration Panel</BaseMenuItem>
          </BaseMenu>}

          {s.shouldShowPendingVerificationSection && <BaseMenu>
            {s.shouldShowEmailPendingVerification && <BaseMenuItem icon="mail" onClick={s.verifyEmail} colorCodedState={ColorCodedState.attention}>Verify Email</BaseMenuItem>}
            {s.shouldShowMobileNumberPendingVerification && <>
              {s.shouldShowEmailPendingVerification && <BaseSeparator />}
              <BaseMenuItem icon="phone" onClick={s.verifyMobileNumber} colorCodedState={ColorCodedState.attention}>Verify Mobile Number</BaseMenuItem>
            </>}
          </BaseMenu>}

          <BaseMenu>
            <TwoColumnViewSidebarItem prefix={s.childrenRoutePrefix} routeDef={AccountPageSupportGroupsRouteDef} />
            <TwoColumnViewSidebarItem prefix={s.childrenRoutePrefix} routeDef={AccountPageDonationsRouteDef} colorCodedState={ColorCodedState.alert} />
            <TwoColumnViewSidebarItem prefix={s.childrenRoutePrefix} routeDef={AccountPageManageDonationsRouteDef} />
            <TwoColumnViewSidebarItem prefix={s.childrenRoutePrefix} routeDef={AccountPageReceiptsRouteDef} />
            {UI.onlyPhones && <BaseMenuItem icon="phone" colorCodedState={ColorCodedState.attention} href={getWordPressLink('/crisis-support')} target="_blank">Crisis Support</BaseMenuItem>}
          </BaseMenu>

          <BaseMenu>
            <TwoColumnViewSidebarItem prefix={s.childrenRoutePrefix} routeDef={AccountPageProfileEditorRouteDef} />
            <TwoColumnViewSidebarItem prefix={s.childrenRoutePrefix} routeDef={AccountPageAddressManagerRouteDef} />
            <TwoColumnViewSidebarItem prefix={s.childrenRoutePrefix} routeDef={AccountPageEmergencyContactsRouteDef} />
            <TwoColumnViewSidebarItem prefix={s.childrenRoutePrefix} routeDef={AccountPageCompanyRouteDef} />
            <TwoColumnViewSidebarItem prefix={s.childrenRoutePrefix} routeDef={AccountPagePaymentMethodsRouteDef} />
            <TwoColumnViewSidebarItem prefix={s.childrenRoutePrefix} routeDef={AccountPagePrivacyRouteDef} />
            <TwoColumnViewSidebarItem prefix={s.childrenRoutePrefix} routeDef={AccountPageUpdatePasswordRouteDef} />
            {AUTH.currentUser?.isStaff && <TwoColumnViewSidebarItem prefix={s.childrenRoutePrefix} routeDef={AccountPage2FARouteDef} />}
          </BaseMenu>

          <BaseMenu>
            <TwoColumnViewSidebarItem prefix={s.childrenRoutePrefix} routeDef={AccountPageContactFormRouteDef} />
          </BaseMenu>

          <BaseMenu>
            <BaseMenuItem icon="speech-bubble" onClick={submitNewFeedback}>Provide Feedback...</BaseMenuItem>
          </BaseMenu>

          <BaseMenu>
            <TwoColumnViewSidebarItem prefix={s.childrenRoutePrefix} routeDef={AccountPageDeleteAccountRouteDef} />
          </BaseMenu>

          <BaseMenu>
            <BaseMenuItem icon="power" onClick={s.logout} colorCodedState={ColorCodedState.alert}>Log Out</BaseMenuItem>
          </BaseMenu>

        </div>

        <BaseSpacer />

      </nav>
    },
    get content() {
      return <Observer children={() => (
        <TwoColumnView
          routes={AccountModuleRouteDef.children as RouteDef[]}
          prefix={s.childrenRoutePrefix}
          aside={s.nav}
          nonMobileViewFallbackUrl={makeUrl(s.childrenRoutePrefix, 'profile')}
        />
      )} />
    }
  }));

  const submitNewFeedback = () => {
    UI.OVERLAY.present({
      component: <OverlayFeedbackForm />,
      appearance: 'card',
    })
  }

  return <Observer children={() => s.isInAdminModule ? (
    <div className="ModuleAccount">
      <AdminTitleBar title="Account" />
      {s.content}
    </div>
  ) : (
    <ClientAppSubmodule
      name="Account"
      title="Account"
      fullHeight
    >
      {UI.onlyPhones && <BaseSpacer size="1em" />}
      {s.content}
    </ClientAppSubmodule>
  )} />

}

export default ModuleAccount
