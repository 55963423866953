import { always } from "../../base/utils/ramdaEquivalents.utils";
import { makeObservableRouteDef } from "../../base/utils/routes.utils";
import { PageLandingRouteDef } from "./PageLanding/Landing.page.route";
import ModulePublic from "./Public.module";

export const PublicModuleChildRoutes = [
  PageLandingRouteDef
]

export const PublicModuleRouteDef = makeObservableRouteDef({
  identifier: 'Public',
  urlFactory: always(''),
  composeTitle: always('Welcome'),
  component: ModulePublic,
  children: PublicModuleChildRoutes,
})