import { action, flow, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import AppPage from '../../base/components/AppPage/AppPage';
import AppPageContent from '../../base/components/AppPageContent/AppPageContent';
import AppPageContentFooter from '../../base/components/AppPageContentFooter/AppPageContentFooter';
import AppPageHeader from '../../base/components/AppPageHeader/AppPageHeader';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseGrid from '../../base/components/BaseGrid/BaseGrid';
import BaseGridCell from '../../base/components/BaseGrid/BaseGridCell';
import BaseInput from '../../base/components/BaseInput/BaseInput';
import BaseLabel from '../../base/components/BaseLabel/BaseLabel';
import BaseRadioGroup from '../../base/components/BaseRadioGroup/BaseRadioGroup';
import BaseSelector from '../../base/components/BaseSelector/BaseSelector';
import BaseSpacer from '../../base/components/BaseSpacer/BaseSpacer';
import CountrySelector from '../../base/components/CountrySelector/CountrySelector';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import UIBlock from '../../base/components/UIBlock/UIBlock';
import { SurveyGeneralEndpoints } from '../../base/endpoints/survey.endpoints';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { makeDisposerController } from '../../base/utils/disposer.utils';
import { reportError } from '../../base/utils/errors.utils';
import { useStore } from '../../base/utils/mobx.utils';
import { ModelName } from '../../constants/modelNames.enum';
import { IS_PROD } from '../../env';
import { makeSurveyGeneralSnapshot } from '../../models/makeSurveyGeneral.model';
import { sendSaveUserRequest } from '../../requests/saveUser.request';
import { makePayloadWithoutId } from '../../utils/payloads.utils';
import EmploymentStatusSelector from '../EmploymentStatusSelector/EmploymentStatusSelector';
import { EthnicitySelector } from '../EthnicitySelector/EthnicitySelector';
import { GenderSelector } from '../GenderSelector/GenderSelector';
import IndustrySelector from '../IndustrySelector/IndustrySelector';
import IrelandCountySelector from '../IrelandCountySelector/IrelandCountySelector';
import { SexualitySelector } from '../SexualitySelector/SexualitySelector';
import { reaction } from 'mobx';
import Turn2MeLogo from '../Turn2MeLogo/Turn2MeLogo';
import './OverlaySurveyGeneral.scss';

interface OverlaySurveyGeneralProps {}

const maritalStatusRadioOptions = [
  { label: 'Single', value: 'Single' },
  { label: 'Married', value: 'Married' },
  { label: 'Separated', value: 'Separated' },
  { label: 'Divorced', value: 'Divorced' },
  { label: 'Remarried', value: 'Remarried' },
  { label: 'Engaged', value: 'Engaged' },
  { label: 'Widowed', value: 'Widowed' },
  { label: 'Co-habiting', value: 'Co-habiting' },
];
const symptomRadioOptions = [
  { label: 'Mild', value: 'Mild' },
  { label: 'Moderate', value: 'Moderate' },
  { label: 'High', value: 'High' },
];
const yesNoRadioOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];


const OverlaySurveyGeneral: React.FC<OverlaySurveyGeneralProps> = props => {

  const { API, UI, AUTH, ANALYTICS } = useControllers();

  const s = useStore(() => ({
    survey: makeSurveyGeneralSnapshot(),
    get isIreland() {
      return s.survey.countryId === 'IE';
    },
    _localForm: {
      otherReferenceInput: '',
      collegeReferenceInput : '',
      isInWaitingListOfAnotherOrganisation: null as boolean | null,
    },
    get shouldShowOtherReferenceInput() {
      return s.survey.referredBy.includes('Other');
    },
    get shouldShowCollegeReferenceInput() {
      return s.survey.referredBy.includes('College');
    },
    get formIsValid() {
      return true;
    },
  }));

  useOnMount(() => {
    const d = makeDisposerController();
    flow(function * () {
      if (!AUTH.currentUser) {
        dismiss();
        return;
      }
      s.survey.version = 2;
      s.survey.gender = AUTH.currentUser.gender;
      s.survey.sexuality = AUTH.currentUser.sexuality;
      s.survey.countryId = AUTH.currentUser.countryProvidedId;
      s.survey.region = AUTH.currentUser.primaryAddress?.region ?? null;
      s.survey.age = AUTH.currentUser.age;
      const countryCode = yield ANALYTICS.getUserCountryCode();
      if (countryCode && !s.survey.countryId) {
        s.survey.countryId = countryCode;
      }
    })();
    const countryReactionDisposer = reaction(
        () => s.survey.countryId,
        (newCountryId) => {
          if (newCountryId === "IE") {
            s.survey.region = "dublin";
          } else {
            s.survey.region = ""; // Clear the region for other countries
          }
        }
    );
    d.add(countryReactionDisposer);
    d.add(when(() => !AUTH.isAuthenticated, dismiss));
    return d.disposer;
  });

  const dismiss = () => {
    UI.OVERLAY.dismiss('OverlaySurveyGeneral');
  }

  const submit = flow(function * () {
    if (!s.survey.gender) {
      UI.DIALOG.attention({
        heading: 'Please provide your gender.'
      })
      return;
    }
    if (!s.survey.sexuality) {
      UI.DIALOG.attention({
        heading: 'Please provide your sexuality.'
      })
      return;
    }

    try {
      if (s._localForm.otherReferenceInput) s.survey.referredBy.push(s._localForm.otherReferenceInput);
      const url = SurveyGeneralEndpoints.own.create();
      const payload = makePayloadWithoutId(s.survey);
      yield API.post(url, ModelName.surveysGeneral, payload);
      UI.DIALOG.success({
        heading: 'Your survey was submitted successfully.',
        body: 'Thanks for your participation! The surveys will help us improve our services for all of our clients. You can now continue to use the service.',
      });
      sendSaveUserRequest(API, {
        preferences: {
          ...AUTH.currentUser?.preferences,
          generalInfoSurveyResponse: 'completed'
        }
      }, { isSelf: true })
      dismiss();
    } catch(e) {
      reportError(e);
      UI.DIALOG.error({
        heading: 'Sorry! Your form was not submitted successfully.',
        body: <ErrorRenderer error={(e as any).response} />,
      })
    }
  })

  const supportTypes = [
    { value: 'individual', label: 'Individual Support' },
    // { value: 'couples', label: 'Couples Support' },
    // { value: 'young-people', label: 'Support for my child' },
  ]

  const referenceList = [
    'GP/Doctor Referral',
    'Social Media',
    'Online Search',
    'Family',
    'Friend',
    'Ads',
    'LGBTQ',
    'Macra Na Feirme',
    'Text 50808',
    'Exchange House',
    'Chronic Pain Ireland',
    'ADHD Ireland',
    'College',
    'Other',
  ]

  useHotkeys('command+shift+1', action(() => {
    if (IS_PROD) return;
    s.survey.gender = 'non-binary';
    s.survey.sexuality = 'bisexual';
    s.survey.ethnicity = 'others';
    s.survey.region = 'dublin';
    s.survey.employmentStatus = 'not-applicable';
    s.survey.profession = 'Not Applicable';
    s.survey.age = 18;
  }))

  return <Observer children={() => (
    <AppPage className="OverlaySurveyGeneral">
      <AppPageHeader
        title="General Survey"
        endSlot={<Turn2MeLogo version="full" />}
        spaceChildren
      />
      <AppPageContent>
        <UIBlock padded>
          <ShadedBlock spaceChildren color="green">
            <p><strong>This data will be anonymised and is not connected to your account or traceable back to you in any way.</strong> Collecting this anonymised data will help us understand who is using turn2me services so that our services can be continuously improved.</p>
          </ShadedBlock>
          <BaseSpacer />
          <BaseGrid columns={1} gap="1em">
            <GenderSelector form={s.survey} field='gender' label="To which gender identity do you most identify?" appearance="system" />
            <SexualitySelector form={s.survey} field='sexuality' label="Sexual Orientation" appearance="system" />
            <EthnicitySelector form={s.survey} field='ethnicity' label="Please select an ethnicity or community." appearance="system" />
            <CountrySelector form={s.survey} field='countryId' label="Which country do you currently live in?" />
            {
              s.isIreland ? <IrelandCountySelector form={s.survey} field="region" label="Which County in Ireland?" /> : (
                <BaseInput form={s.survey} field="region" label="Which province/state/county do you live in?" />
              )
            }
            { AUTH.currentUser?.isAdult && <div>
              <BaseLabel>What is your marital status?</BaseLabel>
              <BaseSelector form={s.survey} field="maritalStatus" options={maritalStatusRadioOptions} appearance="system" />
            </div> }
            { AUTH.currentUser?.isAdult && <EmploymentStatusSelector form={s.survey} field="employmentStatus" label="What is your Employment Status?" /> }
            { AUTH.currentUser?.isAdult && <IndustrySelector form={s.survey} field="profession" label="Do any of the following professions apply to you?" /> }
            <BaseInput form={s.survey} field='age' type="number" step="1" min="0" label="What is your current age?" />
            <div>
              <BaseLabel>How would you measure your symptoms/feelings?</BaseLabel>
              <BaseRadioGroup form={s.survey} field="ownSymptomsOrFeelingsMeasurement" options={symptomRadioOptions} />
            </div>
            <div>
              <BaseLabel>Do you have private healthcare?</BaseLabel>
              <BaseRadioGroup form={s.survey} field="hasPrivateHealthCare" options={yesNoRadioOptions} />
            </div>
            <div>
              <BaseLabel>How did you know turn2me? Please select all that apply.</BaseLabel>
              <BaseSelector form={s.survey} field="referredBy" options={referenceList} appearance="checkboxGroup" checkboxGroupDirection="row" nullable />
            </div>
            {s.shouldShowOtherReferenceInput && <div>
              <BaseInput type="textarea" form={s._localForm} field="otherReferenceInput" placeholder="(Optional) Enter other ways that helped you find turn2me..." rows="3" />
            </div>}
            {s.shouldShowCollegeReferenceInput && <div>
              <BaseLabel>Which college?</BaseLabel>
              <BaseInput form={s.survey} field="collegeReferenceInput"  placeholder="(Optional) Please specify which college" />
            </div>}
            { AUTH.currentUser?.isAdult && <div>
              <BaseLabel>What types of support are you looking for? Please select all that apply.</BaseLabel>
              <BaseSelector form={s.survey} field="supportType" options={supportTypes} appearance="checkboxGroup" nullable />
            </div>}
            <div>
              <BaseLabel>Are you on the waiting list of another organisation?</BaseLabel>
              <BaseRadioGroup form={s._localForm} field="isInWaitingListOfAnotherOrganisation" options={yesNoRadioOptions} />
            </div>
            {
              s._localForm.isInWaitingListOfAnotherOrganisation && <div>
                <BaseLabel>Please let us know which organisation it is:</BaseLabel>
                <BaseInput form={s.survey} field="inWaitingListOfAnotherOrganisation" placeholder="Enter organisation name(s)..." autoFocus />
              </div>
            }
          </BaseGrid>
        </UIBlock>
        <AppPageContentFooter shaded padded="all">
          <BaseGrid columns={1} gap=".62em">
            <BaseGridCell>
              <BaseButton fullWidth={UI.onlyPhones} onClick={submit} disabled={!s.formIsValid}>Submit Anonymous Survey</BaseButton>
            </BaseGridCell>
          </BaseGrid>
        </AppPageContentFooter>
      </AppPageContent>
    </AppPage>
  )} />
}

export default OverlaySurveyGeneral;