import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { ColorCodedState } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseButtonGroup from '../../base/components/BaseButtonGroup/BaseButtonGroup';
import InfoBanner from '../../base/components/InfoBanner/InfoBanner';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import tick from '../../base/utils/waiters.utils';
import { ReasonCancelSession } from '../../constants/reasonCancelSession.enum';
import { CounsellingSession } from '../../models/makeCounsellingSession.model';
import { openOverlayNewSessionCreator } from '../../utils/counsellingSession.utils';
import { ID_OverlayCounsellingSessionManager } from '../OverlayCounsellingSessionManager/OverlayCounsellingSessionManager';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import './CounsellingSessionCancelledManager.scss';

type CounsellingSessionCancelledManagerProps = {
  session: CounsellingSession,
}

const CounsellingSessionCancelledManager: React.FC<CounsellingSessionCancelledManagerProps> = props => {
  const { UI, AUTH } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    get counsellorId() {
      return p.session.counsellorId;
    },
    get currentUserIsTheAssignedCounsellor() {
      return s.counsellorId === AUTH.currentUser?.id;
    },
    get scheduledTimeMoment() {
      return p.session.timeScheduled ? moment.utc(p.session.timeScheduled) : undefined;
    },
    get cancelledTimeMoment() {
      return p.session.timeCancelled ? moment.utc(p.session.timeCancelled) : undefined;
    },
    get cancelledTimeDiffScheduledTime() {
      return (s.scheduledTimeMoment && s.cancelledTimeMoment) ? s.cancelledTimeMoment.diff(s.scheduledTimeMoment, 'seconds') : -Infinity;
    },
    get moreThan48Hours() {
      return s.cancelledTimeDiffScheduledTime < -48 * 3600;
    },
    get within24To48Hours() {
      return s.cancelledTimeDiffScheduledTime >= -48 * 3600 && s.cancelledTimeDiffScheduledTime <= -24 * 3600;
    },
    get within24Hours() {
      return s.cancelledTimeDiffScheduledTime > -24 * 3600;
    },
    get cancelledAutomaticallyBySystem() {
      return p.session.cancelReason === ReasonCancelSession.clientDidNotPay;
    },
    get cancelledByClient() {
      return p.session.cancelReason === ReasonCancelSession.clientCancel;
    },
    get InfoBanner() {
      switch (true) {
        case s.cancelledAutomaticallyBySystem:
          return <InfoBanner colorCodedState={ColorCodedState.alert} icon="warning">
            <p>The session was cancelled automatically by the system because the user did not pay 24 hours before the session.</p>
          </InfoBanner>
        case s.moreThan48Hours:
          return <InfoBanner colorCodedState={ColorCodedState.attention} icon="warning">
            <p>The user has cancelled this session more than 48 hours prior to the session start. User is entitled to another free session if applicable or a rescheduled paid session. Paying counselling users are entitled to request a full refund.</p>
          </InfoBanner>
        case s.within24To48Hours:
          return <InfoBanner colorCodedState={ColorCodedState.attention} icon="warning">
            <p>The user has cancelled this session within the 24 - 48 hour cancellation window. User is entitled to another free session if applicable, or a rescheduled paid session. If €50 refund is requested, a €10 administration fee must be charged.</p>
          </InfoBanner>
        case s.within24Hours:
          return <InfoBanner colorCodedState={ColorCodedState.alert} icon="warning">
            <p>The user has cancelled this session less than 24 hours prior to the session start. Free users lose a free session and paying users aren't allowed free reschedule or a refund.</p>
          </InfoBanner>
        default:
          return null;
      }
    },
  }))
  // const voipUser = () => {
  //   if (!p.session.applicant) return;
  //   callViaVOIP(p.session.applicant, VOIP);
  // }
  // const messageUser = () => {
  //   const clientId = p.session.application?.applicantId ?? p.session.applicantId;
  //   const client = p.session.application?.applicant ?? p.session.applicant;
  //   if (!clientId) return;
  //   if (!client) return;
  //   startInstantChat(MESSENGER, NAVIGATOR, AUTH, UI, clientId, client);
  // }
  const scheduleNewSession = async () => {
    if (!p.session.application) return;
    await UI.OVERLAY.dismiss(ID_OverlayCounsellingSessionManager);
    await tick();
    await tick();
    await openOverlayNewSessionCreator(UI, p.session.application);
  }
  return <Observer children={() => (
    <div className="CounsellingSessionCancelledManager">
      {s.InfoBanner}
      <ShadedBlock color='red' spaceChildren>
        {
          s.currentUserIsTheAssignedCounsellor ? <>
            <h4>This session has been cancelled by the user</h4>
            <p>Get in touch with the user to reschedule another session. Please observe Terms and Conditions of the cancellation policy outlined above.</p>
          </> : <>
            <h4>This session is assigned to <UsernameRenderer userId={s.counsellorId} />.</h4>
          </>
        }
        <BaseButtonGroup>
          {/* <BaseButton onClick={voipUser} icon='phone' iconVariant="filled" dataCy="voipUser">VOIP user</BaseButton> */}
          {/* <BaseButton onClick={messageUser} icon='chat' iconVariant="filled" dataCy="messageUser">Message user</BaseButton> */}
          <BaseButton onClick={scheduleNewSession} icon='plus' iconVariant="filled" dataCy="scheduleNewSession">Schedule a new session</BaseButton>
        </BaseButtonGroup>
      </ShadedBlock>
    </div>
  )} />
}

export default CounsellingSessionCancelledManager;