import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { presentOverlayDonateFn } from '../../actions/donation.actions';
import { Nillable } from '../../base/@types';
import { ColorCodedState, Renderable } from '../../base/@types/ui.types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import IconWithCount from '../../base/components/IconWithCount/IconWithCount';
import MenuToggle from '../../base/components/MenuToggle/MenuToggle';
import { IconName } from '../../base/components/Symbols/iconDefs';
import VerticalSeparator from '../../base/components/VerticalSeparator/VerticalSeparator';
import { Primary } from '../../base/constants/color.enum';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useCreateResizeQueryWithRef } from '../../base/hooks/useCreateResizeQueryWithRef.hook';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { getContextColorStyle } from '../../base/utils/colors.utils';
import { renderRenderable } from '../../base/utils/components.utils';
import { removeCSSCustomProperty, setCSSCustomProperty } from '../../base/utils/css.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { makeUrl } from '../../base/utils/url.utils';
import { getWordPressLink } from '../../env';
import Turn2MeLogo from '../Turn2MeLogo/Turn2MeLogo';
import UserIcon from '../UserIcon/UserIcon';
import './AppTitleBar.scss';

interface AppTitleBarProps {
  title?: Renderable,
  stringTitle?: string,
  prefix?: string,
}

const AppTitleBar: React.FC<AppTitleBarProps> = props => {

  const { AUTH, ALERTS, UI, NAVIGATOR } = useControllers();
  const { ref, query } = useCreateResizeQueryWithRef<HTMLDivElement>();

  const p = useProps(props);

  const s = useStore(() => ({
    get renderedTitle() {
      return p.title ? renderRenderable(p.title) : p.stringTitle;
    },
    get unreadAlerts() {
      return ALERTS.unreadAlerts;
    },
    get prefix() {
      return p.prefix || 'app';
    },
    get notifButton() {
      return <IconWithCount
        count={s.unreadAlerts.length}
        icon="notification"
        href={`/${s.prefix}/notifications`}
      />
    },
    get title() {
      return (p.title || p.stringTitle) && UI.onlyPhones ? (
        <h1 className="AppTitleBarTitle" children={s.renderedTitle} />
      ) : <h1 className="Turn2MeLogoContainer"><NavLink to="/app/explore"><Turn2MeLogo version={UI.onlyPhones ? 'text' : 'full'} /></NavLink></h1>
    },
    get accountButtonContent() {
      return <div className="AppTitleBarAccountButton">
        {UI.fromTablet && <span className="AppTitleBarAccountButtonUsername">
          <span>{AUTH.currentUser?.username}</span>
        </span>}
        <UserIcon user={AUTH.currentUser} size={UI.onlyPhones ? '2.4rem' : '2.4em'} />
      </div>
    },
    get accountButton() {
      return UI.onlyPhones ? <Link to={makeUrl('/', s.prefix, 'account')} title="Your Account">
        { s.accountButtonContent }
      </Link> : <MenuToggle buttonContentComponent={s.accountButtonContent} alignToRight actions={[
        ...AUTH.can.access_.adminPanels ? [
          { icon: 'cog' as IconName, label: 'turn2me Administration Panel', action: () => NAVIGATOR.navigateTo('/admin/dashboard') }
        ] : [],
        { icon: 'person-circle', label: 'Your Account', action: () => NAVIGATOR.navigateTo('/app/account') },
        { icon: 'shield', label: 'Privacy Settings', action: () => NAVIGATOR.navigateTo('/app/account/privacy') },
        { icon: 'power', label: 'Log out', colorCodedState: ColorCodedState.alert, action: () => NAVIGATOR.navigateTo('/auth/logout') }
      ]}/>;
    }
  }));

  useOnMount(() => {
    let parent: Nillable<HTMLElement>;
    const resizeHandler = () => {
      const { height } = query;
      parent = ref.current?.parentElement?.parentElement;
      if (height === 0) {
        removeCSSCustomProperty('--TitleBarHeight', parent);
      } else {
        setCSSCustomProperty('--TitleBarHeight', height + 'px', parent);
      }
    };
    query.onResize(resizeHandler);
    resizeHandler();
    return () => {
      removeCSSCustomProperty('--TitleBarHeight', parent);
    }
  })

  return <Observer children={() => (
    <div className="AppTitleBar" data-prefix={s.prefix}>
      <div className="AppTitleBarInner" ref={ref}>
        <div className="AppTitleBarStart">
          { UI.onlyPhones && s.notifButton }
          { UI.fromTablet && s.title }
        </div>
        <div className="AppTitleBarCenter">
          { UI.onlyPhones && s.title }
        </div>
        <div className="AppTitleBarEnd">
          {UI.fromTablet && <BaseButton
            title="donate"
            className="subtle"
            rounded
            icon="heart"
            iconVariant="filled"
            size="sm"
            color="red"
            label="Donate"
            dataCy="DonateButton"
            onClick={presentOverlayDonateFn(UI)}
          /> }
          { UI.fromTablet && <BaseButton
            title="crisis support"
            className="subtle"
            rounded
            icon="phone"
            iconVariant="filled"
            size="sm"
            color="orange"
            label="Crisis Support"
            href={getWordPressLink('/crisis-support')}
            target="_blank"
          /> }
          {UI.fromDesktop && <BaseButton
            title="information centre"
            className="subtle"
            rounded
            icon="info"
            iconVariant="filled"
            size="sm"
            color="blueGreen"
            label="Information Centre"
            dataCy="InformationCentreButton"
            href={getWordPressLink('/information-centre')}
          />}
          { UI.onlyPhones && s.accountButton}
          { UI.fromTablet && (
            <div className="AppTitleBarAccountControls" style={getContextColorStyle(Primary, AUTH.currentUser?.color)}>
              {s.notifButton}
              <VerticalSeparator />
              {s.accountButton}
            </div>
          ) }
        </div>
      </div>
    </div>
  )} />

}

export default AppTitleBar;