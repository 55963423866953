import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import LoadingIndicator from '../../../base/components/LoadingIndicator/LoadingIndicator';
import { useOnMount } from '../../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../../base/hooks/useRootController.hook';
import { useUpdatePageBackground } from '../../../base/hooks/useSetPageBackground.hook';
import { useStore } from '../../../base/utils/mobx.utils';
import tick from '../../../base/utils/waiters.utils';
import { AuthTokenKey } from '../../../constants/storageKeys.constants';
import { isInCypressTestMode, SHOULD_LOG } from '../../../env';
import './PageLogout.scss';

interface PageLogoutProps {}

const PageLogout: React.FC<PageLogoutProps> = props => {

  const { AUTH, NAVIGATOR, STORAGE } = useControllers();

  const s = useStore(() => ({
    timeout: false,
    alive: true,
  }));

  const logout = async () => {
    await tick(isInCypressTestMode ? 10 : 1000);
    if (AUTH.isAuthenticated && !AUTH.loggingOut) await AUTH.logout();
    setTimeout(action(() => {
      if (!s.alive) return;
      s.timeout = true;
      AUTH.reset();
      NAVIGATOR.navigateTo('/auth/login');
    }), isInCypressTestMode ? 10 : 1000);
    STORAGE.remove(AuthTokenKey);
    return;
  }

  useUpdatePageBackground('#0151ca');
  
  useOnMount(() => {
    SHOULD_LOG() && console.log('logging out, reason: visited /auth/logout page');
    logout();
    return action(() => {
      s.alive = false;
    })
  })

  return <Observer children={() => (
    <div className="PageLogout">
      <LoadingIndicator />
      <p>Logging out...</p>
    </div>
  )} />
}

export default PageLogout;