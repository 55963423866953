import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Renderable } from '../../@types/ui.types';
import joinClassName from '../../utils/className.utils';
import { renderRenderable } from '../../utils/components.utils';
import './BaseLabel.scss';

interface P {
  inline?: boolean;
  required?: boolean;
  optional?: boolean;
  endSlot?: Renderable,
  dataCy?: string,
}

const BaseLabel: React.FC<P> = props => {
  return <Observer children={() => (
    <label className={joinClassName(
      'BaseLabel',
      props.inline && 'inline',
    )} data-cy={props.dataCy}>
      <div className="BaseLabelStartSlot">
        {props.children}
        {props.required && <em className="BaseInputRequiredMarker">* Required</em>}
        {props.optional && <em className="BaseInputOptionalMarker">(Optional)</em>}
      </div>
      {
        props.endSlot && <div className="BaseLabelEndSlot">
          {renderRenderable(props.endSlot)}
        </div>
      }
    </label>
  )} />
}

export default BaseLabel;