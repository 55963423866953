import { action, observable, runInAction, toJS } from "mobx";
import { GenericFunction, UIControllerDeviceInfo } from "../base/@types";
import { CountryCode, CountryDescriptors } from "../base/constants/countries.constants";
import { immediateReaction } from "../base/utils/mobx.utils";
import { isArray } from "../base/utils/typeChecks.utils";
import tick from "../base/utils/waiters.utils";
import { AxiosInstance } from "./api.controller";
import { AppInfoSet } from "./common/appInfo";
import { CLOCK } from "./common/clock.controller";
import { makeControllerBase, makeRootControllerChildInitFn } from "./_root.controller";

/**
 * The AnalyticsController controls the following aspects:
 * - detection of user IP, location, device & browser info,
 * - managing user analysis profile, used in generating
 */
export type AnalyticsController = ReturnType<typeof makeAnalyticsController>;

declare global {
	interface Window {
		gtag: (type: string, id: string, params: Object) => void;
	}
}

export interface IAnalyticsRecord {
	app: AppInfoSet,
	timestamp: string | Date,
	deviceInfo: UIControllerDeviceInfo,
	location: {
		ip?: string,
		city?: string,
		country?: string,
	},
	browserInfo: {
		userAgent: string,
		viewportWidth: number,
		viewportHeight: number,
		orientation: string,
	}
}

export type TraceResponse = {
	ip: string,
	colo: string,
	loc: string,
}

export const makeAnalyticsController = () => {

	const c = observable({

		...makeControllerBase('ANALYTICS'),

		detected: {
			countryCode: '' as CountryCode,
			city: '',
		},

		checkUserLocation: () => new Promise(async (resolve: (result: { city: string | null, countryCode: CountryCode | null }) => unknown, reject) => {
			try {
				const ipInfo = await c.getIpInfo();
				const city = ipInfo.colo;
				const country = ipInfo.loc as CountryCode;
				runInAction(() => {
					c.detected.city = city || 'UNKNOWN';
					c.detected.countryCode = country || 'UNKNOWN';
				})
				resolve({
					city: city || null,
					countryCode: country || null
				})
			} catch (e) {
				reject(e);
			}
		}),

		getIpInfo() {
			return new Promise<Partial<TraceResponse>>((resolve, reject) => {
				const url = 'https://www.cloudflare.com/cdn-cgi/trace';
				AxiosInstance.get(url).then(response => {
					if (!response) resolve({});
					const { data } = response;
					const keyValuePairsStringArray = (data as string).split("\n");
					const keyValuePairs = keyValuePairsStringArray.filter(i => i).map((s: string) => s.split('=')).filter(i => i.length === 2);
					const result = {} as Partial<TraceResponse>;
					keyValuePairs.forEach(p => result[p[0] as keyof TraceResponse] = p[1]);
					resolve(result);
				}).catch(e => {
					resolve({});
				})
			})
		},

		createRecord: () => new Promise(async (resolve: (record: IAnalyticsRecord) => unknown, reject) => {
			const timestamp = CLOCK.nowUtcTimestamp;
			const ipInfo: TraceResponse = {
				ip: 'UNKNOWN',
				colo: 'UNKNOWN',
				loc: 'UNKNOWN',
			};
			try {
				Object.assign(ipInfo, await c.getIpInfo());
			} catch (e) {
				console.error(e);
			}
			const { ip, colo, loc } = ipInfo;
			const { UI, COMMON } = c.ROOT!.children;
			const { appWidth, appHeight, orientation } = UI;
			const record: IAnalyticsRecord = {
				app: COMMON.app,
				timestamp,
				deviceInfo: toJS(UI.deviceInfo),
				location: {
					ip,
					city: colo,
					country: loc,
				},
				browserInfo: {
					userAgent: navigator.userAgent,
					viewportWidth: appWidth,
					viewportHeight: appHeight,
					orientation,
				},
			}
			console.log(record);
			resolve(record);
		}),

		getUserCountryCode() {
			return new Promise(async (resolve: (countryId: CountryCode | null) => unknown, reject) => {
				if (c.ROOT!.children.COMMON.countryCode) {
					resolve(c.ROOT!.children.COMMON.countryCode);
					return;
				} else {
					const userLocationInfo = await c.checkUserLocation();
					resolve(userLocationInfo.countryCode);
				}
			})
		},

		async getUserDialCode() {
			const countryCode = await c.getUserCountryCode();
			if (!countryCode) return null;
			let dialCodeDef = CountryDescriptors[countryCode]?.dialCode;
			let dialCode = dialCodeDef === null ? '' : isArray(dialCodeDef) ? dialCodeDef[0] : dialCodeDef;
			dialCode = [dialCode[0] === '+' ? '' : '+', dialCode].join('');
			return dialCode;
		},

		analyticsToolStates: {
			google: window.GDPR_MANAGER?.state.google,
			facebook: window.GDPR_MANAGER?.state.facebook,
		},

		reset: (invokedFromRoot?: boolean) => {

		}

	});

	c.init = makeRootControllerChildInitFn(
		c,
		action(() => {
			c.checkUserLocation();
			const disposers = [] as GenericFunction[];
			if (c.analyticsToolStates.google) {
				disposers.push(immediateReaction(
					() => c.ROOT?.children.NAVIGATOR.currentLocationPathnameAndSearch,
					async () => {
						const { NAVIGATOR } = c.ROOT!.children;
						const page_location = NAVIGATOR.currentLocationPathnameAndSearch;
						const page_path = NAVIGATOR.currentLocationPathname;
						await tick();
						if (!window.gtag) return;
						window.gtag('event', 'page_visit', {
							page_title: window.document.title,
							page_location,
							page_path,
						})
					}
				))
			}
			return () => disposers.forEach(d => d());
		})
	)

	return c;
}
