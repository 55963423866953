import { always } from "../../../base/utils/ramdaEquivalents.utils";
import { makeObservableRouteDef } from "../../../base/utils/routes.utils";
import PageResetPassword from "./ResetPassword.page";

export const ResetPasswordPageRoute = makeObservableRouteDef({
  identifier: 'reset-password',
  urlPattern: 'reset-password/:token',
  urlFactory: always('reset-password'),
  composeTitle: always('Reset Password'),
  component: PageResetPassword,
})