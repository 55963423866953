import { action, reaction } from "mobx";
import { ModelName } from "../../constants/modelNames.enum";
import { LocalDBController } from "../../controllers/localDB.controller";
import { SHOULD_LOG } from "../../env";
import { AnyObject, HasId, Nillable, RelationshipDescriptor, RelationshipsSchema, SnapshotOf, StandardModel } from "../@types";
import { addToArrayIfNew, mapToIds, removeFromArray } from "../utils/array.utils";
import { NoOp } from "../utils/functions.utils";
import { getValueOfKey, setValueOfKey } from "../utils/object.utils";
import { pluralize, singularize } from "../utils/string.utils";
import { isArray, isObject } from "../utils/typeChecks.utils";

export const setupRelationshipGetters = <Model extends StandardModel, RelationshipsType extends AnyObject>(
  observableModel: SnapshotOf<Model>,
  model: Model,
  schema?: RelationshipsSchema<SnapshotOf<Model>, RelationshipsType>,
  localDB?: LocalDBController,
  options?: { debug?: boolean },
) => {
  if (!schema) return;
  Object.keys(schema).forEach(k => {
    if ((schema as AnyObject)[k] === 'skip') return;
    const descriptor = isObject((schema as AnyObject)[k]) ? (schema as AnyObject)[k] as RelationshipDescriptor<SnapshotOf<Model>> : {
      modelName: (schema as AnyObject)[k] as ModelName
    };
    const modelName = descriptor.modelName ?? pluralize(k) as ModelName;
    const has = descriptor.has || 'one';
    if (options?.debug) {
      SHOULD_LOG() && console.log(model, schema, modelName, has)
    }
    if (has === 'one') {
      const identifierKeyName = descriptor.identifierKeyName || singularize(k) + 'Id';
      if (!((identifierKeyName as any) in observableModel)) {
        SHOULD_LOG() && console.error(`A has-one relationship for ${k} is defined on the model ${model.$modelName}, but an identifier key is not found. Expecting it to be ${identifierKeyName}`);
      }
      Object.defineProperty(model, k, {
        get() {
          return localDB?.get(modelName, getValueOfKey<any>(identifierKeyName, model));
        },
        configurable: true,
      });
    } else {
      const identifierKeyName = descriptor.identifierKeyName || singularize(k) + 'Ids';
      if (!((identifierKeyName as any) in observableModel)) {
        SHOULD_LOG() && console.error(`A has-many relationship for ${k} is defined on the model ${model.$modelName}, but an identifier key is not found. Expecting it to be ${identifierKeyName}`);
      }
      Object.defineProperty(model, k, {
        get() {
          return localDB?.getMany(modelName, getValueOfKey<any>(identifierKeyName, model)) || [];
        },
        configurable: true,
      });
    }
  })
}

export const retrieveRelationshipDescriptorByKeyName = <Model extends StandardModel, RelationshipsType extends AnyObject>(
  keyName: string,
  schema: RelationshipsSchema<SnapshotOf<Model>, RelationshipsType>
) => {
  const entry = Object.entries(schema).find(e => {
    const k = e[0];
    const d = e[1];
    if ((schema as AnyObject)[k] === 'skip') return undefined;
    if (isObject(d)) {
      if (d.identifierKeyName) return d.identifierKeyName === keyName;
      else if (d.has === 'many') return keyName === singularize(k) + 'Ids';
      else if (d.has === 'one' || d.has === undefined) return keyName === singularize(k) + 'Id';
      return undefined;
    } else { // modelName shorthand
      return keyName === singularize(d as string) + 'Id';
    }
  });
  return entry ? entry[1] : undefined;
}

export const syncModelWithRelatedModels = <Model extends StandardModel, RelationshipsType extends AnyObject>(
  model: Model,
  schema?: RelationshipsSchema<SnapshotOf<Model>, RelationshipsType>,
  localDB?: LocalDBController,
  options?: { debug?: boolean },
) => {

  if (!schema || !localDB) return NoOp;

  const keys = Object.keys(schema) as (keyof RelationshipsType)[];

  const synchroniser = action((oldValue: Nillable<HasId>, newValue: Nillable<HasId>, key: keyof Model) => {

    if (!keys.includes(key as any)) return;
    
    const { id } = model;
    
    const _descriptor: RelationshipDescriptor<Model> = getValueOfKey<any>(key as string, schema);
    if (!_descriptor || _descriptor === 'skip') return;
    const descriptor = isObject(_descriptor) ? _descriptor : { modelName: _descriptor };

    if (options?.debug) {
      SHOULD_LOG() && console.log(`sync model ${model.$modelName} at key ${key}:`, oldValue, newValue);
    }

    const {
      selfIdentifierKeyNameOnRemote,
      has = 'one',
      remoteHasSelf = has === 'one' ? 'many' : 'one'
    } = descriptor;

    if (!selfIdentifierKeyNameOnRemote || !remoteHasSelf) return;
    
    const { modelName } = descriptor;

    const remoteModelsOld = isArray(oldValue) ? localDB.getMany(modelName!, mapToIds((oldValue || []) as HasId[])) : [localDB.get(modelName!, oldValue?.id ? oldValue?.id + '' : '')];
    const remoteModelsNew = isArray(newValue) ? localDB.getMany(modelName!, mapToIds((newValue || []) as HasId[])) : [localDB.get(modelName!, newValue?.id ? newValue?.id + '' : '')];

    remoteModelsOld.forEach(m => {
      if (remoteHasSelf === 'one') {
        if (getValueOfKey<any>(selfIdentifierKeyNameOnRemote, m) === id)
          setValueOfKey<any>(m, selfIdentifierKeyNameOnRemote, null);
      } else {
        removeFromArray(getValueOfKey<any>(selfIdentifierKeyNameOnRemote!, m), id);
      }
    })

    remoteModelsNew.forEach(m => {
      if (remoteHasSelf === 'one') {
        if (getValueOfKey<any>(selfIdentifierKeyNameOnRemote, m) !== id)
          setValueOfKey<any>(m, selfIdentifierKeyNameOnRemote, id);
      } else {
        addToArrayIfNew(getValueOfKey<any>(selfIdentifierKeyNameOnRemote!, m), id);
      }
    })

  })

  // perform initial checks
  keys.forEach(key => {
    synchroniser(null, getValueOfKey<any>(key, model), key as keyof Model);
  })

  return synchroniser;

}

export const autoMaintainRelationships = <Model extends StandardModel, RelationshipsType extends AnyObject>(
  model: Model,
  schema?: RelationshipsSchema<SnapshotOf<Model>, RelationshipsType>,
  localDB?: LocalDBController,
  options?: { debug?: boolean },
) => {

  if (!schema || !localDB) return;

  const keys = Object.keys(schema) as (keyof Model)[];

  const updateHandler = syncModelWithRelatedModels(model, schema, localDB, options);

  keys.forEach(key => {
    reaction(
      () => model[key],
      (newV, oldV) => {
        if (options?.debug) SHOULD_LOG() && console.log(`${model.$modelName}#${model.id} @${key}:`, oldV, '→', newV);
        updateHandler(oldV as unknown as Nillable<HasId>, newV as unknown as Nillable<HasId>, key);
      },
      { fireImmediately: true }
    )
  });

}