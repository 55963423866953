import { CounsellingSessionEndpointParams, CounsellingSessionEndpoints, DefaultCounsellingSessionIncludesForStaff } from "../base/endpoints/counsellingSession.endpoints";
import { useControllers } from "../base/hooks/useRootController.hook";
import { ModelName } from "../constants/modelNames.enum";
import { CounsellingSession } from "../models/makeCounsellingSession.model";
import { useAutoGet, UseAutoGetFactoryOptions } from "../utils/useAutoGet.utils";

export function useGetCounsellingSessions<ObservableType extends object = object>(
  options: UseAutoGetFactoryOptions<CounsellingSession[], ObservableType>,
  params?: CounsellingSessionEndpointParams,
) {
  const { AUTH } = useControllers();
  useAutoGet<CounsellingSession[], ObservableType>({
    asIndex: true,
    allowCache: options?.allowCache ?? true,
    lazy: options?.lazy ?? false,
    ...options,
    url: params ? CounsellingSessionEndpoints[AUTH.can.manage_.counsellingSessions ? 'staff' : 'own'].index(params ?? DefaultCounsellingSessionIncludesForStaff) : undefined,
    modelName: ModelName.counsellingSessions,
  });
}