import { action, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { CSSProperties } from 'react';
import { RemoteParticipant } from 'twilio-video';
import { ColorCodedState } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import LoadingBlocker from '../../base/components/LoadingBlocker/LoadingBlocker';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useCreateResizeQueryWithRef } from '../../base/hooks/useCreateResizeQueryWithRef.hook';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { ChatMediator } from '../../base/mediators/chat.mediator';
import joinClassName from '../../base/utils/className.utils';
import { makeDisposerController } from '../../base/utils/disposer.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import tick from '../../base/utils/waiters.utils';
import { SHOULD_LOG } from '../../env';
import './ChatWindowVideoFrame.scss';
import ChatWindowVideoRoomParticipant from './ChatWindowVideoRoomParticipant';

interface ChatWindowVideoFrameProps {
  chat: ChatMediator
}

function ChatWindowVideoFrame(props: React.PropsWithChildren<ChatWindowVideoFrameProps>) {
  const p = useProps(props);
  const { ref, query } = useCreateResizeQueryWithRef<HTMLDivElement>();
  const { UI, VIDEO } = useControllers();
  const s = useStore(() => ({
    get isEdgeLegacy() {
      return UI.deviceInfo.browser.includes('edge-legacy');
    },
    preferToDisplaySelfInCorner: true,
    togglePreferToDisplaySelfInCorner: action(() => s.preferToDisplaySelfInCorner = !s.preferToDisplaySelfInCorner),
    get shouldDisplaySelfInCorner() {
      return s.preferToDisplaySelfInCorner && s.participantsArray.length > 0;
    },
    userOverrideFillViewport: null as null | boolean,
    toggleUserOverrideFillViewport: action(() => {
      s.userOverrideFillViewport = !s.userOverrideFillViewport
    }),
    get isLandscapePhone () {
      return UI.orientation === 'landscape' && UI.appWidth > 550 && UI.appHeight < 480 && UI.appWidth < 850;
    },
    get shouldFillViewport() {
      return p.chat.selfParticipant && !p.chat.selfParticipant.timeDeclinedVideoCall && (s.isLandscapePhone || s.userOverrideFillViewport);
    },
    get room() {
      return p.chat.videoChatRoom;
    },
    get shouldShowLoadingBlocker() {
      return !p.chat.hasEnded && p.chat.thread.timeVideoStarted && !s.room;
    },
    participantSet: new Set<RemoteParticipant>(),
    get participantsArray() {
      return Array.from(s.participantSet);
    },
    get totalNumberOfParticipantVideoStreamsInGrid() {
      return s.participantSet.size + (s.shouldDisplaySelfInCorner ? 0 : 1);
    },
    get childStyle() {
      if (s.totalNumberOfParticipantVideoStreamsInGrid === 1) return { flex: '1 1 100%' };
      if (s.totalNumberOfParticipantVideoStreamsInGrid === 2) return { flex: '1 1 50%' };
      if (query.fromDesktop && s.totalNumberOfParticipantVideoStreamsInGrid > 3) return { flex: '0 0 25%' };
      if (query.fromTablet) return { flex: '0 0 33.33%' };
      return { flex: '0 0 50%' };
    },
    get component() {
      return <Observer children={() => (
        <div
          className={joinClassName(
            'ChatWindowVideoFrame',
            s.shouldFillViewport && 'fill-viewport',
            s.shouldDisplaySelfInCorner && 'shouldDisplaySelfInCorner',
            ((s.shouldDisplaySelfInCorner && s.participantsArray.length === 1) || s.participantsArray.length === 0) && 'hasOnlyOneVideoStream',
          )}
          ref={ref}
          data-time-video-started={p.chat.thread.timeVideoStarted}
          data-time-video-ended={p.chat.thread.timeVideoEnded}
          data-remote-participant-length={s.participantsArray.length}
          data-declined={!!p.chat.selfParticipant?.timeDeclinedVideoCall}
          style={UI.cssFeatures.grid ? { '--VideoFrameContainerHeight': (query.height || 300) + 'px' } as CSSProperties : undefined}
        >
          {p.chat.selfParticipant && <>
            {p.chat.selfParticipant.timeDeclinedVideoCall ? <div className="ChatWindowVideoFrameCallDeclinedNotice">
              <div>
                <h3>Ongoing Video Call</h3>
                <p>You had declined this call.</p>
              </div>
              {/* CONSIDER: as an improvement, consider adding ability for user to rejoin a declined call. */}
            </div> : <>
              <div className="ChatWindowVideoFrameInner">
                {s.isEdgeLegacy ? (
                  <div className="ChatWindowVideoFrameLegacyBrowserWarning">
                    <div>
                      <h2>:(</h2>
                      <p>Your current browser is not supported.</p>
                      <p>To join this video chat, please download the <a href="https://www.microsoft.com/en-us/edge" title="Get latest Edge Browser" target="_blank" rel="noreferrer">latest Microsoft Edge</a> or switch to other modern browsers such as <a href="https://www.google.com/chrome/" title="Get Chrome Browser" target="_blank" rel="noreferrer">Google Chrome</a>, <a href="https://www.mozilla.org/en-US/firefox/new/" title="Get Firefox Browser" target="_blank" rel="noreferrer">Firefox</a>.</p>
                    </div>
                  </div>
                ) : <div className="ChatWindowVideoRoomParticipantList">
                  <div className="ChatWindowVideoRoomParticipantListInner">
                    {s.room?.localParticipant && <ChatWindowVideoRoomParticipant
                      style={s.childStyle}
                      chat={p.chat}
                      participant={s.room.localParticipant}
                      isSelf
                      onClick={s.togglePreferToDisplaySelfInCorner}
                    />}
                    {s.participantsArray.map(part => <ChatWindowVideoRoomParticipant style={s.childStyle} chat={p.chat} participant={part} key={part.sid} />)}
                  </div>
                </div>}
              </div>
              <footer className="ChatWindowVideoFrameFooter">
                <div className="ChatWindowVideoFrameFooterInner">
                  {!s.isLandscapePhone && <BaseButton
                    onClick={s.toggleUserOverrideFillViewport}
                    appearance="text"
                    iconVariant="filled"
                    icon={s.shouldFillViewport ? 'close' : 'zoom'}
                    label={s.shouldFillViewport ? 'Exit Fullscreen' : 'Fullscreen'}
                  />}
                  {VIDEO.allowAudioAndVideoToggles && <BaseButton
                    appearance="text"
                    onClick={p.chat.shouldEnableAudio ? p.chat.disableAudio : p.chat.enableAudio}
                    icon={p.chat.shouldEnableAudio ? 'microphone' : 'microphone-off'}
                    iconVariant="filled"
                    label={query.width > 333 ? p.chat.shouldEnableAudio ? 'Audio On' : 'Audio Off' : undefined}
                    color={p.chat.shouldEnableAudio ? 'orange' : 'median'}
                  />}
                  {VIDEO.allowAudioAndVideoToggles && <BaseButton
                    appearance="text"
                    onClick={p.chat.shouldEnableVideo ? p.chat.disableVideo : p.chat.enableVideo}
                    icon={p.chat.shouldEnableVideo ? 'video' : 'video-off'}
                    iconVariant="filled"
                    label={query.width > 333 ? p.chat.shouldEnableVideo ? 'Video On' : 'Video Off' : undefined}
                    color={p.chat.shouldEnableVideo ? 'red' : 'median'}
                  />}
                </div>
                <div className="ChatWindowVideoFrameFooterEndSlot">
                  {
                    s.participantsArray.length > 0 && (
                      <BaseButton
                        appearance="text"
                        onClick={s.togglePreferToDisplaySelfInCorner}
                        icon={s.preferToDisplaySelfInCorner ? 'nested-view' : 'grid'}
                        iconVariant="filled"
                        color="white"
                      />
                    )
                  }
                </div>
              </footer>
              {s.shouldShowLoadingBlocker && <LoadingBlocker />}
            </>}
          </>}
        </div>
      )} />
    }
  }));

  useOnMount(() => {
    const d = makeDisposerController();
    if (UI.deviceType === 'phone' && UI.orientation === 'portrait') {
      UI.TOAST.present({
        name: 'rotate-device-hint',
        body: 'Try rotating your phone to view the video frame full screen. If you are having problem using the video call functionality, try a different device with a larger screen.',
        colorCodedState: ColorCodedState.positive,
        timeout: 0,
      })
      d.add(when(
        () => UI.orientation === 'landscape',
        () => UI.TOAST.dismiss('rotate-device-hint'),
      ))
    }
    d.add(when(
      () => !!s.room,
      () => {
        s.room!.participants.forEach(action(participant => {
          SHOULD_LOG() && console.log(`Participant "${participant.identity}" is connected to the Room`);
          s.participantSet.add(participant);
        }));
        // Log new Participants as they connect to the Room
        s.room!.on('participantConnected', action(participant => {
          SHOULD_LOG() && console.log(`Participant "${participant.identity}" has connected to the Room`);
          s.participantSet.add(participant);
        }));
        // Log Participants as they disconnect from the Room
        s.room!.on('participantDisconnected', action(participant => {
          SHOULD_LOG() && console.log(`Participant "${participant.identity}" has disconnected from the Room`);
          s.participantSet.delete(participant);
        }));
      }
    ))
    const resizeHandler = async () => {
      await tick(1000);
      console.log('recalcing');
      query.recalc();
      console.log(query.height);
    }
    window.addEventListener('resize', resizeHandler);
    d.add(() => window.removeEventListener('resize', resizeHandler));
    return d.disposer;
  })

  return <Observer children={() => (
    s.shouldFillViewport ? UI.PORTAL.render(s.component) : s.component
  )} />
}

export default ChatWindowVideoFrame;