import { Nillable } from '../@types';
import { transformBoolean } from "./boolean.utils";
import { eqByFn, isNil } from "./ramdaEquivalents.utils";
import { isArray } from './typeChecks.utils';

const Pluralize = require('pluralize');

export type StringTransformerInput = string | number | null;

export function castToString(input?: any) {
  if (input === null) return 'null';
  if (input === void 0) return 'undefined';
  switch (typeof input) {
    case 'string': return input;
    case 'number': return input + '';
    case 'boolean': return transformBoolean(input, {
      trueValue: 'true',
      falseValue: 'false',
    });
    case 'object': return input.toString();
  }
  try {
    return input.toString();
  } catch (e) {
    return input + '';
  }
}
export function toKebabCase(input?: StringTransformerInput) {
  if (isNil(input)) return ''
  return (input || '').toString().replace(/[A-Z0-9]/g, $1 => "-" + $1.toLowerCase()).replace(/\s/g, '').replace(/^(?:-*)|(?:-*)$/, '');
}
export function toSnakeCase(input?: StringTransformerInput) {
  if (isNil(input)) return ''
  return (input || '').toString().replace(/[A-Z0-9]/g, $1 => "_" + $1.toLowerCase());
}
export function toCamelCase(input?: StringTransformerInput) {
  if (isNil(input)) return ''
  return (input || '').toString().replace(/(?:-|_)[a-zA-z0-9]/g, $1 => $1.substr(1).toUpperCase());
}
export function toSentenceCase(input?: StringTransformerInput) {
  return toKebabCase(input).replace(/-/g, ' ');
}
export function toUpperCase(input?: StringTransformerInput) {
  return toSentenceCase(input).toLocaleUpperCase();
}
export function capitalizeFirstLetter(input?: StringTransformerInput) {
  if (!input) return '';
  const string = '' + input || '';
  return string.replace(/^./, string[0].toUpperCase())
}
export function toTitleCase(input?: StringTransformerInput) {
  return toSentenceCase(('' + input)).toString().split(' ').map(t => (t[0] || '').toUpperCase() + t.substr(1)).join(' ');
}
export function normalizeString(input?: StringTransformerInput) {
  return (('' + input) || '').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
export function capitalizeSingleWord(input?: StringTransformerInput) {
  return input ? ('' + input)[0].toUpperCase() + ('' + input).substr(1) : input;
}
export function capitalize(input?: StringTransformerInput) {
  return ('' + input).split(' ').map(s => capitalizeSingleWord(s)).join(' ');
}

export function isPlural(input?: StringTransformerInput) {
  return Pluralize.isPlural(input);
}
export function pluralize(input?: StringTransformerInput) {
  if (!input) return '';
  return Pluralize.plural(input);
}

export function isSingular(input: string) {
  return Pluralize.isSingular(input);
}

export function singularize(input: string) {
  if (!input) return '';
  return Pluralize.singular(input);
}

export function autoPluralize(
  x: number | any[],
  singularString: string,
  zeroString?: string,
  pluralString?: string,
  doNotAutoDisplayNumber?: boolean
) {
  const amount = isArray(x) ? x.length : x;
  if (!amount && zeroString) return zeroString;
  if (amount === 1) {
    if (singularString.includes('%d')) {
      return singularString.replace(/%d/, '1');
    }
    if (doNotAutoDisplayNumber) return singularString;
    return '1 ' + singularString;
  } else {
    const _pluralString = pluralString || pluralize(singularString);
    if (_pluralString) {
      if (_pluralString.includes('%d')) {
        return _pluralString.replace(/%d/, amount + '');
      }
      if (doNotAutoDisplayNumber) return _pluralString;
      return amount + ' ' + _pluralString;
    }
  }
  return amount;
}

/** Returns "is" or "are" automatically based on given numerical input */
export function __are(number: number) {
  return autoPluralize(number, 'is', 'are', 'are', true);
}

/** Trims ID to 8 digits, useful for showing a snippet of an UUID in UI */
export function trimId(id?: string) {
  return id ? id.substr(0, 8) : 'EMPTY-ID';
}

export function hashString(string: string = '') {
  let hash = 0, i, chr;
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export const formatSearchString = (input: string) => {
  return normalizeString(input).toLowerCase().replace(/[\s\n]/gi, '');
}

export const stringOnlyContainsDigits = (input: string) => /^\d+$/.test(input);

export const equalByString = eqByFn(String);

export const toLowerCase = (s: string) => isNil(s) ? '' : s.toLowerCase();

export const equalByLowerCase = eqByFn(toLowerCase);

export function stringLiterals<T extends string>(...args: T[]): T[] { return args; }

export const appendPrefixFn = (prefix: string) => (string: string) => `${prefix}${string}`

export function getFirstSymbol(string: string = '') {
  try {
    for (const symbol of string) {
      if (symbol) return symbol;
    }
  } catch(e) {
    try {
      return string.charAt(0);
    } catch(e) {
      return string[0];
    }
  }
}

export const slugify = (text: string = '') => text
  .toString() // Cast to string
  .toLowerCase() // Convert the string to lowercase letters
  .normalize('NFD') // The normalize() method returns the Unicode Normalization Form of a given string.
  .trim() // Remove whitespace from both sides of a string
  .replace(/\s+/g, '-') // Replace spaces with -
  .replace(/[^\w-]+/g, '') // Remove all non-word chars
  .replace(/--+/g, '-') // Replace multiple - with single -
  .replace(/(-)+$/, "") // remove trailing dashes
  .replace(/^(-)+/, "") // remove dashes at the beginning of string

export const containsOnlyWhiteSpaceOrLineBreaks = (string: string) => {
  return !string.replace(/\r/g, '').replace(/\n/g, '').replace(/\s\s+/g, '');
}

export const trimString = (string?: Nillable<string>) => {
  return string?.replace(/^\n+/, '').replace(/\n+$/, '').trim() ?? '';
}

export const itemsArrayToFormattedString = (arr: string[], delimiter: string = ", ") => {
  if (arr.length === 0) return "";
  if (arr.length === 1) return arr[0];
  return arr.slice(0, -1).join(delimiter) + " and " + arr[arr.length - 1];
}