import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { LocalAudioTrack, LocalVideoTrack, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';
import BaseToggle from '../../base/components/BaseToggle/BaseToggle';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useObservableRef } from '../../base/hooks/useObservableRef.hook';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { ChatMediator } from '../../base/mediators/chat.mediator';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { IS_DEV } from '../../env';
import { isCounsellingSessionModel } from '../../models/makeCounsellingSession.model';
import { initTrackUI } from '../../utils/video.utils';
import './JoinVideoCallConfirmation.scss';

type JoinVideoCallConfirmationProps = {
  chat: ChatMediator,
}

const JoinVideoCallConfirmation: React.FC<JoinVideoCallConfirmationProps> = props => {

  const { VIDEO } = useControllers();

  const p = useProps(props);
  const s = useStore(() => ({
    get associatedModelName() {
      return isCounsellingSessionModel(p.chat.associatedModel) ? 'Video Counselling Session' : 'Video Call';
    },
    initiated: false,
    videoWidth: 800,
    videoHeight: 600,
    tracksRendered: [] as (RemoteAudioTrack | RemoteVideoTrack | LocalVideoTrack | LocalAudioTrack)[],
    get videoContainerStyle() {
      return {
        paddingTop: s.videoHeight / s.videoWidth * 100 + '%',
      }
    },
    get videoOn() { return VIDEO.shouldEnableVideo },
    set videoOn(v) { VIDEO.shouldEnableVideo = v },
    get audioOn() { return VIDEO.shouldEnableAudio },
    set audioOn(v) { VIDEO.shouldEnableAudio = v },
  }))

  const ref = useObservableRef();

  useOnMount(() => {
    flow(function * () {
      if (!VIDEO.localTracks.length) yield VIDEO.createLocalTracks();
      for (let track of VIDEO.localTracks) {
        yield initTrackUI(s, track, ref);
      }
      if (IS_DEV) s.audioOn = false;
      s.initiated = true;
    })();
  })

  return <Observer children={() => (
    <div className="JoinVideoCallConfirmation" data-audio-on={s.audioOn} data-video-on={s.videoOn}>
      <div className="JoinVideoCallConfirmationToggleList">
        <BaseToggle form={VIDEO} field="shouldEnableAudio">Join with audio</BaseToggle>
        <BaseToggle form={VIDEO} field="shouldEnableVideo">Join with video</BaseToggle>
      </div>
      <div className="LocalTrackPreviewWrapper">
        <div className="LocalTrackPreview" style={s.videoContainerStyle}>
          <div ref={ref}></div>
          { s.initiated && !s.videoOn && <p>Video will be turned off once you confirm</p> }
        </div>
      </div>
    </div>
  )} />
  
}

export default JoinVideoCallConfirmation;