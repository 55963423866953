import { reportError } from "../base/utils/errors.utils";
import { APIController } from "../controllers/api.controller";
import { AuthController } from "../controllers/auth.controller";
import { UIController } from "../controllers/ui.controller";
import { User } from "../models/makeUser.model";
import { sendSaveUserRequest } from './saveUser.request';

export const reset2FARequest = (
  user: User,
  AUTH: AuthController,
  UI: UIController,
  API: APIController,
  onSuccess?: () => void
) => {
  const isEditingSelf = AUTH.currentUser?.id === user.id;
  UI.DIALOG.attention({
    heading: `Are you sure you want to reset ${isEditingSelf ? "your" : "this user's"} 2FA?`,
    defaultActions: ['negative'],
    actions: [
      {
        label: 'Confirm',
        action: () => new Promise<boolean>(async (resolve, reject) => {
          try {
            const savedUser = await sendSaveUserRequest(
              API,
              {
                id: user.id,
                preferences: {
                  ...user.preferences,
                  has2FA: false,
                },
              },
              { isSelf: isEditingSelf }
            );
            if (savedUser) {
              user?.$patch(savedUser);
              onSuccess?.();
              UI.TOAST.success(`${isEditingSelf ? "Your" : "User's"} 2FA has been reset.`);
              resolve(true);
            }
          } catch (e) {
            reportError(e);
            UI.DIALOG.error({
              heading: 'An error occurred.',
              error: e
            })
          }
        })
      }
    ]
  })
}