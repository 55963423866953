import React from 'react';
import { User } from '../../models/makeUser.model';
import UserIcon from '../UserIcon/UserIcon';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import './UserCard.scss';

interface UserCardProps {
  user?: Partial<User>;
  iconSize?: string;
}

const UserCard: React.FC<UserCardProps> = props => {
  return <div className="UserCard">
    <UserIcon user={props.user} size={props.iconSize} />
    <header className="UserCardHeader">
      <h3><UsernameRenderer user={props.user} showColorLabel={false} /></h3>
    </header>
  </div>
}

export default UserCard;