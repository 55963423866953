import { makeObservable, observable } from "mobx"
import { AlwaysTrueFn } from "../../../../base/utils/ramdaEquivalents.utils"
import { AddressValidator } from "../../../../base/validators/address.validator"
import { ApplicationTypeOption } from "../../../../constants/counsellingTypes.descriptors"
import { CounsellingApplicationForm } from "../../../../models/makeCounsellingApplication.model"
import { isOneToOneCounselling, isPaidCounselling } from "../../../../utils/counsellingApplication.utils"
import { CounsellingApplicationInterceptedPayFunctionType } from "./CounsellingApplicationUI"
import CounsellingApplicationStepCommunicationType from "./steps/CounsellingApplicationStepCommunicationType"
import CounsellingApplicationStepFinancialAssessment from "./steps/CounsellingApplicationStepFinancialAssessment"
import CounsellingApplicationStepHealthcareInfo from "./steps/CounsellingApplicationStepHealthcareInfo"
import CounsellingApplicationStepImportantInfo from "./steps/CounsellingApplicationStepImportantInfo"
import CounsellingApplicationStepPayment from "./steps/CounsellingApplicationStepPayment"
import CounsellingApplicationStepReviewApplicantAddress from "./steps/CounsellingApplicationStepReviewApplicantAddress"
import CounsellingApplicationStepReviewPersonalInfo from "./steps/CounsellingApplicationStepReviewPersonalInfo"
import CounsellingApplicationStepRiskAssessment from "./steps/CounsellingApplicationStepRiskAssessment"
import CounsellingApplicationStepSummary from "./steps/CounsellingApplicationStepSummary"
import CounsellingApplicationStepTimeSelection from "./steps/CounsellingApplicationStepTimeSelection"
import CounsellingApplicationStepWelcome from "./steps/CounsellingApplicationStepWelcome"

export enum ApplicationStepId {
  welcome = '01_welcome',
  riskAssessment = '02_risk-assessment',
  financialAssessment = '03_financial-assessment',
  timeSelection = '04_time-selection',
  communicationType = '05_communication-type',
  reviewPersonalInfo = '06_review-applicant-info',
  applicantAddress = '07_applicant-address',
  healthcareInfo = '08_healthcare-info',
  summary = '09_summary',
  importantInfo = '10_important-info',
  payment = '11_payment',
}

export type ApplicationStepDescriptor = {
  id: ApplicationStepId,
  title: string,
  component: React.FC<CounsellingApplicationStepProps>,
  shouldDisplay: (form?: CounsellingApplicationForm) => boolean,
  validator: (form?: CounsellingApplicationForm) => boolean,
}
export type CounsellingApplicationStepProps = {
  form: CounsellingApplicationForm;
  // hasFocus: boolean,
  descriptor: ApplicationStepDescriptor,
  enabled: boolean,
  onInterceptedPay: CounsellingApplicationInterceptedPayFunctionType,
}
export const applicationStepDescriptors: ApplicationStepDescriptor[] = [
  {
    id: ApplicationStepId.welcome,
    title: 'Welcome',
    component: CounsellingApplicationStepWelcome,
    shouldDisplay: AlwaysTrueFn,
    validator: (f?: CounsellingApplicationForm) => (Boolean(f?.hasAgreedCantAfford) || (isPaidCounselling(f!.selectedApplicationType))),
  },
  {
    id: ApplicationStepId.riskAssessment,
    title: 'Risk Assessment',
    component: CounsellingApplicationStepRiskAssessment,
    shouldDisplay: AlwaysTrueFn,
    validator: (f?: CounsellingApplicationForm) => typeof f?.application.isAtRisk === "boolean",
    // validator: (f?: CounsellingApplicationForm) => f?.isAtRisk === false,
  },
  {
    id: ApplicationStepId.financialAssessment,
    title: 'Financial Assessment',
    component: CounsellingApplicationStepFinancialAssessment,
    shouldDisplay: (f?: CounsellingApplicationForm) => Boolean(f) && !isPaidCounselling(f!.selectedApplicationType),
    validator: (f?: CounsellingApplicationForm) => (Boolean(f?.ableToDonate) && (f?.application.clientAgreedDonationAmountPerSession && f?.application.clientAgreedDonationAmountPerSession >= 1)) || f?.ableToDonate === false,
  },
  {
    id: ApplicationStepId.timeSelection,
    title: 'Select a Time',
    component: CounsellingApplicationStepTimeSelection,
    shouldDisplay: AlwaysTrueFn,
    validator: (f?: CounsellingApplicationForm) => Boolean(f?.application.timePreferredForFirstSession),
  },
  {
    id: ApplicationStepId.communicationType,
    title: 'Communication Type',
    component: CounsellingApplicationStepCommunicationType,
    shouldDisplay: AlwaysTrueFn,
    // shouldDisplay: (f?: CounsellingApplicationForm) => Boolean(f) && !isPaidCounselling(f!.selectedApplicationType),
    validator: (f?: CounsellingApplicationForm) => Boolean(f?.application.communicationTypePreference)
  },
  {
    id: ApplicationStepId.reviewPersonalInfo,
    title: 'Personal Info',
    component: CounsellingApplicationStepReviewPersonalInfo,
    shouldDisplay: AlwaysTrueFn,
    validator: (f?: CounsellingApplicationForm) => {
      const applicationInfoIsValid = Boolean(
        f?.applicant.username
        && f?.applicant.firstName
        && f?.applicant.lastName
        && f?.applicant.dateOfBirth
        && f?.applicant.mobileNumber
        && f?.applicant.email
        && (f?.applicant.countryDetectedId || f?.applicant.countryProvidedId)
      );
      const emergencyContactInfoIsValid = Boolean(
        f?.applicantEmergencyContact.name
        && f?.applicantEmergencyContact.email
        && f?.applicantEmergencyContact.phone
        && f?.applicantEmergencyContact.phone.length >= 11
      );
      if (Boolean(f) && isOneToOneCounselling(f!.selectedApplicationType)) return applicationInfoIsValid && emergencyContactInfoIsValid;
      const inviteeInfoIsValid = Boolean(
        f?.invitation.name &&
        (f.selectedApplicationType === ApplicationTypeOption.YoungPeople ? f?.invitation.dateOfBirth : true)
      )
      return applicationInfoIsValid && emergencyContactInfoIsValid && inviteeInfoIsValid;
    },
  },
  {
    id: ApplicationStepId.applicantAddress,
    title: 'Applicant Address',
    component: CounsellingApplicationStepReviewApplicantAddress,
    shouldDisplay: AlwaysTrueFn,
    validator: (f?: CounsellingApplicationForm) => AddressValidator(f?.applicantAddress) === true,
  },
  {
    id: ApplicationStepId.healthcareInfo,
    title: 'Healthcare Info',
    component: CounsellingApplicationStepHealthcareInfo,
    shouldDisplay: AlwaysTrueFn,
    validator: (f?: CounsellingApplicationForm) => Boolean(
      f?.application.hasExternalCounselling !== null
      && f?.application.hasPrivateHealthCare !== null
      && f?.application.isInReceiptOfMedicalCard !== null
      && f?.application.hasAttemptedSuicide !== null
      && f?.application.healthcareProviderName
      && f?.application.healthcareProviderPhone && f?.application.healthcareProviderPhone.length >= 11
      && f?.application.ownSymptomsOrFeelingsMeasurement
    ),
  },
  {
    id: ApplicationStepId.summary,
    title: 'Summary',
    component: CounsellingApplicationStepSummary,
    shouldDisplay: AlwaysTrueFn,
    validator: (f?: CounsellingApplicationForm) => f?.hasAgreedToTerms === true
  },
  {
    id: ApplicationStepId.importantInfo,
    title: 'Important Info',
    component: CounsellingApplicationStepImportantInfo,
    shouldDisplay: AlwaysTrueFn,
    validator: (f?: CounsellingApplicationForm) => f?.hasReadImportantInformation === true
  },
  {
    id: ApplicationStepId.payment,
    title: 'Payment',
    component: CounsellingApplicationStepPayment,
    shouldDisplay: (f?: CounsellingApplicationForm) => Boolean(f) && isPaidCounselling(f!.selectedApplicationType),
    validator: AlwaysTrueFn,
  },
].map(d => makeObservable(d, {
  component: observable.ref,
}))