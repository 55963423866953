import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseSpacer from '../../../../../base/components/BaseSpacer/BaseSpacer';
import AddressEditor from '../../../../../components/AddressEditor/AddressEditor';
import { AddressSnapshot } from '../../../../../models/makeAddress.model';
import { CounsellingApplicationStepProps } from '../applicationStepDescriptors';
import './CounsellingApplicationStepReviewApplicantAddress.scss';

interface CounsellingApplicationStepReviewApplicantAddressProps extends CounsellingApplicationStepProps { }

const CounsellingApplicationStepReviewApplicantAddress: React.FC<CounsellingApplicationStepReviewApplicantAddressProps> = p => {
  const handleOnSave = action((a: AddressSnapshot) => {
    Object.assign(p.form.applicantAddress, a);
  })
  return <Observer children={() => (
    <div className="CounsellingApplicationStepReviewApplicantAddress">
      <blockquote>
        Please provide your address information.
      </blockquote>
      <BaseSpacer />
      <div className="formWrapper">
        <AddressEditor
          address={p.form.applicantAddress}
          saveChangesOnBlur
          onSave={handleOnSave}
        />
      </div>
    </div>
  )} />
}

export default CounsellingApplicationStepReviewApplicantAddress;