import { flow } from "mobx";
import { ModelName } from "../../constants/modelNames.enum";
import { APIController, APIGetManyResponse } from "../../controllers/api.controller";
import { AnyObject, SnapshotOf, StringKeyList, StringKeyOf } from "../@types";
import { Timeframe } from "../@types/time.types";
import { IndexDirectoryDataFetcher } from "../components/IndexDirectory/indexDirectory.types";
import IndexDirectoryState from "../components/IndexDirectory/IndexDirectoryState";
import { keepTruthy } from "./array.utils";
import { copyWithJSON } from "./object.utils";
import { isArray, isFunction } from "./typeChecks.utils";

export type DateRangeFilter<T extends object = object> = {
  key?: StringKeyOf<T> | null,
} & Timeframe & {shouldFilterUseArrayIndexForm?: boolean}

export const stringifyDateRangeFilterArrayIndexForm = (filterName: string, object: DateRangeFilter) => {
  const filterArr = keepTruthy([
    object.key,
    object.startDate ?? "0000-01-01",
    object.endDate,
  ])

  return object.key === null
    ? ""
    : object.shouldFilterUseArrayIndexForm
    ? filterArr.reduce(
        (prev: string, curr, index) =>
          prev.concat(`${index !== 0 ? "&" : ""}filter[${filterName}][${index}]=${curr}`),
        ""
      )
    : filterArr.join(",");
}

export const stringifyDateRangeFilter = (object: DateRangeFilter) => object.key === null ? "" : keepTruthy([
  object.key,
  object.startDate ?? '0000-01-01',
  object.endDate
]).join(',');

export const stringifyDateRangeFilterCommaDelimited = stringifyDateRangeFilter

export type BaseParamFilterType<T extends object> = Partial<Record<StringKeyOf<T>, string | string[] | null | number | boolean>> & {
  dateRange?: DateRangeFilter<T>
  whereNull?: StringKeyOf<T> | StringKeyList<T>,
  whereEquals?: {
    key: StringKeyOf<T>,
    values: (string | number | boolean)[],
  },
  trashed?: "only" | "with" | "onlyWithTrashed"
};

export type DefaultIndexParamSet<
  T extends AnyObject = {},
  RelationshipsType extends AnyObject = {},
  ExtraFilters extends AnyObject = {}
> = {
  page?: number;
  perPage?: number;
  sort?: string;
  include?: ((keyof RelationshipsType & string) | string)[];
  filter?: BaseParamFilterType<SnapshotOf<T>> & Partial<ExtraFilters>;
  append?: "statistics" | string;
};

export function makeLaravelIndexDataFetcher<
  EntryType extends AnyObject = AnyObject,
  ParamType extends AnyObject = DefaultIndexParamSet<EntryType> & AnyObject,
>(
  API: APIController,
  modelName: ModelName,
  urlFactory: (params?: DefaultIndexParamSet<EntryType>) => string,
  paramsFactory?: ParamType | (() => ParamType),
  options?: {
    onSuccess?: (data?: EntryType[]) => unknown,
    onError?: (error?: any) => unknown,
    localFilter?: (data: EntryType) => boolean,
  },
): IndexDirectoryDataFetcher<EntryType> {
  return (
    state: IndexDirectoryState<EntryType>
  ) => new Promise(flow(function * (resolve, reject) {
    const params = (isFunction(paramsFactory) ? paramsFactory() : copyWithJSON(paramsFactory)) || {} as DefaultIndexParamSet<EntryType>;
    try {
      const isInfinity = params.perPage === -1 || params.perPage === Infinity;
      if (state.searchQuery) {
        if (state.searchByKeyName && state.searchQuery) {
          if (!params.filter) params.filter = {};
          params.filter[state.searchByKeyName] = state.searchQuery;
        }
      }
      const stringifiedParamSet: AnyObject = {
        ...params,
        page: isInfinity ? 1 : state.paginationState.currentPage,
        perPage: isInfinity ? params.perPage : state.paginationState.perPage,
        filter: isArray(params.filter) ? `[${params!.filter.join(',')}]` : params.filter,
      }
      const sortOptions = state.sortByKeyName ? `${state.sortDirection === 'desc' ? '-' : ''}${state.sortByKeyName}` : '';
      if (sortOptions) {
        stringifiedParamSet['sort'] = sortOptions;
      }
      const url = urlFactory(stringifiedParamSet);
      const { entries, response } = (yield API.getMany(url, modelName)) as APIGetManyResponse<EntryType>;
      state.paginationState.currentPage = response.data.current_page;
      state.paginationState.totalItems = response.data.total;
      state.paginationState.perPage = response.data.per_page;
      options?.onSuccess && options.onSuccess(entries);
      let result = entries;
      if (options?.localFilter) result = result.filter(options.localFilter);
      resolve(result as EntryType[]);
    } catch (e) {
      options?.onError && options.onError(e);
      reject(e);
    }
  }))
}