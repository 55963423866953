import { SupportGroupTopic, SupportGroupTopicRelatedModels } from "../../models/makeSupportGroupTopic.model";
import { DefaultIndexParamSet } from "../utils/api.utils";
import { makeApiEndpointConfig, makeApiGetEndpointConfig } from "../utils/endpoints.utils";
import { makeUrl } from "../utils/url.utils";
import { prefixClient, prefixStaff } from "./namespacePrefixes";

export type SupportGroupTopicEndpointParams = DefaultIndexParamSet<SupportGroupTopic, SupportGroupTopicRelatedModels> & {
  include?: (keyof SupportGroupTopicRelatedModels | string)[],
  append?: 'statistics' | string,
}
const clientUrlBase = prefixClient('support-group-topics');
const staffUrlBase = prefixStaff('support-group-topics');

export const SupportGroupTopicEndpoints = {
  client: {
    index: makeApiEndpointConfig<SupportGroupTopicEndpointParams>(clientUrlBase),
    get: makeApiGetEndpointConfig<SupportGroupTopicEndpointParams>(clientUrlBase),
  },
  staff: {
    index: makeApiEndpointConfig<SupportGroupTopicEndpointParams>(staffUrlBase),
    get: makeApiGetEndpointConfig<SupportGroupTopicEndpointParams>(staffUrlBase),
    create: () => staffUrlBase,
    update: (id: string) => makeUrl(staffUrlBase, id),
    delete: (id: string) => makeUrl(staffUrlBase, id),
  },
}