import { flow } from "mobx";
import { ThoughtEndpoints } from "../base/endpoints/thought.endpoints";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { CLOCK } from "../controllers/common/clock.controller";
import { Thought } from "../models/makeThought.model";

export const deleteThought = flow(function * (thought: Thought, API: APIController) {
  const { AUTH } = API.ROOT!.children;
  const endpoint = ThoughtEndpoints[AUTH.currentUser?.id === thought.userId ? 'own' : 'staff'].delete;
  const url = endpoint(thought.id);
  yield API.delete(url, ModelName.thoughts);
  thought.timeDeleted = CLOCK.nowUtcTimestamp;
  return;
})