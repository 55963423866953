import { Nillable, Nullable, StandardModel } from "../base/@types";
import { Identifier } from "../base/@types/traits.types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { ApiModelName } from "../constants/ApiModels.enum";
import { ModelName } from "../constants/modelNames.enum";
import { HasTimestamps, hasTimestamps } from "../traits/hasTimestamps.trait";
import { FlaggableModeSnapshotPartial, isFlaggable } from "../traits/isFlaggable.trait";
import { Flag } from "./makeFlag.model";
import { User } from "./makeUser.model";

export type CommentSnapshot = {
  id: Identifier
  userId: Identifier
  thoughtId: Nullable<Identifier>,
  originalComment: Nullable<CommentSnapshot>,
  body: string,
} & FlaggableModeSnapshotPartial & HasTimestamps;

export type CommentRelatedModels = {
  user: Nillable<User>,
  flags: Flag[],
}
export type CommentExtendedProperties = {
  readonly isUnsafe?: boolean,
}

/**
 * Comments are found under thoughts only in V2.
 */
export type Comment = StandardModel<
  CommentSnapshot,
  CommentRelatedModels,
  CommentExtendedProperties
>

export type CommentableModelType = ApiModelName.THOUGHT 

export const makeCommentSnapshot = () => ({
  id: '',
  userId: '' ,
  thoughtId: null ,
  originalComment: null,
  body: '',
  ...isFlaggable(),
  ...hasTimestamps(),
})

export const makeComment = createStandardModelFactory<Comment, CommentRelatedModels, CommentExtendedProperties>({
  name: ModelName.comments,
  snapshotFactory: makeCommentSnapshot,
  relationshipsSchema: {
    user: ModelName.users,
    flags: { modelName: ModelName.flags, has: 'many' },
  },
  extendedPropertiesFactory: (m, $, localDB) => ({
    get isUnsafe() {
      return m.isSafe === false
    }
  })
})