import { Observer } from 'mobx-react-lite';
import React from 'react';
import { UnknownObject } from '../../base/@types';
import BaseSelector, { BaseSelectorProps } from '../../base/components/BaseSelector/BaseSelector';
import { useControllers } from '../../base/hooks/useRootController.hook';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { SupportGroupTopic } from '../../models/makeSupportGroupTopic.model';
// import './SupportGroupTopicSelector.scss';

export interface SupportGroupTopicSelectorProps extends Omit<BaseSelectorProps<UnknownObject, SupportGroupTopic, string | undefined>, 'options'>{
}

const SupportGroupTopicSelector: React.FC<SupportGroupTopicSelectorProps> = props => {

  const p = useProps(props);
  const { SUPPORT_GROUPS } = useControllers();

  const s = useStore(() => ({
    get topics() {
      return SUPPORT_GROUPS.sortedTopics;
    },
    get hasLoaded() {
      return SUPPORT_GROUPS.topicsLoaded
    },
    get loading() {
      return SUPPORT_GROUPS.topicsLoading;
    },
    get error() {
      return SUPPORT_GROUPS.errorLoadingTopics;
    },
    get disabled() {
      return p.disabled || s.loading || s.topics.length === 0;
    },
    get placeholder() {
      return s.loading ? 'Loading topics...' : (s.topics.length === 0 ? 'No topics found' : 'Select a topic');
    },
    get label() {
      return p.label ?? 'Select a group topic';
    },
  }));

  return <Observer children={() => (
    <BaseSelector<UnknownObject, SupportGroupTopic, string | undefined>
      {...p}
      className={joinClassName(
        'SupportGroupTopicSelector',
        p.className
      )}
      name={p.name ?? p.field}
      appearance="system" 
      options={s.topics}
      valueGetter={o => o?.id}
      valueEqualityChecker={(a, b) => a === b}
      label={s.label}
      emptyValue=""
      placeholder={s.placeholder}
      disabled={s.disabled}
      optionLabelRenderer={(t) => t.displayName}
    />
  )} />
}

export default SupportGroupTopicSelector;