import { always } from "../../../../../base/utils/ramdaEquivalents.utils";
import { makeObservableRouteDef } from "../../../../../base/utils/routes.utils";
import AccountPageReceipts from "./AccountPageReceipts.page";

export const AccountPageReceiptsRouteDef = makeObservableRouteDef({
  identifier: 'account-receipts',
  urlFactory: always('receipts'),
  composeTitle: always('Receipts'),
  component: AccountPageReceipts,
  icon: 'invoices',
})