import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useProps, useStore } from '../../utils/mobx.utils';
import { toTitleCase } from '../../utils/string.utils';
import { isString } from '../../utils/typeChecks.utils';
import BaseSelector from '../BaseSelector/BaseSelector';
import { BaseTableColumnConfig, getSearchByKeyNameFromColumnConfig } from '../BaseTable/BaseTable';
import SearchBar from '../SearchBar/SearchBar';
import './IndexDirectorySearchBar.scss';
import IndexDirectoryState from './IndexDirectoryState';

interface IndexDirectorySearchBarProps<EntryType extends object = {}> {
  indexDirectoryState: IndexDirectoryState<EntryType>,
  onChange: (
    keyName: string,
    query: string
  ) => unknown;
  columnConfigs?: BaseTableColumnConfig<EntryType>[],
  searchBarPlaceholder?: string,
}

function IndexDirectorySearchBar<EntryType extends object = {}>(
  props: React.PropsWithChildren<IndexDirectorySearchBarProps<EntryType>>
) {

  const p = useProps(props);

  const s = useStore(() => ({
    get searchByKeyName() {
      return p.indexDirectoryState.searchByKeyName;
    },
    set searchByKeyName(v) {
      p.indexDirectoryState.searchByKeyName = v;
    },
    get searchableKeyOptions() {
      return p.columnConfigs?.filter(c => (c.keyName && c.searchable === true) || isString(c.searchable))
        .map(c => ({
          label: c.searchFilterLabel ?? (isString(c.label) ? c.label : undefined) ?? toTitleCase(c.keyName + ''),
          value: getSearchByKeyNameFromColumnConfig(c),
        }))
    },
    userQuery: '',
  }));

  const handleSearchQueryChange = () => {
    p.onChange && p.onChange(s.searchByKeyName || '', s.userQuery);
  }
  const handleSearchKeyNameChange = action(() => {
    s.userQuery = '';
    handleSearchQueryChange();
  })

  return <Observer children={() => (
    <div className="IndexDirectorySearchBar">
      {
        s.searchableKeyOptions && <BaseSelector
          appearance="system"
          form={s} field="searchByKeyName"
          options={s.searchableKeyOptions}
          onChange={handleSearchKeyNameChange}
        />
      }
      <SearchBar form={s} field="userQuery" className="IndexDirectorySearchBarInput" onChange={handleSearchQueryChange} disabled={!s.searchByKeyName} />
    </div>
  )} />
}

export default IndexDirectorySearchBar;