import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseGrid from '../../base/components/BaseGrid/BaseGrid';
import FormDatePicker from '../../base/components/FormDatePicker/FormDatePicker';
import FormForm from '../../base/components/FormForm/FormForm';
import FormInput from '../../base/components/FormInput/FormInput';
import { Form } from '../../base/mediators/form.mediator';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { ApplicationTypeOption } from '../../constants/counsellingTypes.descriptors';
import { Invitation, InvitationSnapshot } from '../../models/makeInvitation.model';
import { GenderSelector } from '../GenderSelector/GenderSelector';
import { SexualitySelector } from '../SexualitySelector/SexualitySelector';
// import './InvitationInfoEditor.scss';

interface InvitationInfoEditorProps {
  forType: ApplicationTypeOption,
  form: Form<InvitationSnapshot>,
  onSave?: (u: InvitationSnapshot) => any,
  onBlur?: (u: InvitationSnapshot) => any,
  applyChangesOnBlur?: boolean,
  canEditColor?: boolean,
}

const InvitationInfoEditor: React.FC<InvitationInfoEditorProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    get form() {
      return p.form;
    },
    handleBlur: () => {
      p.onBlur && p.onBlur(s.form.value);
      if (p.applyChangesOnBlur) {
        s.form.markAsSaved();
      }
    },
  }));

  return <Observer children={() => (
    <FormForm
      className="InvitationInfoEditor"
      form={s.form}
      onBlur={s.handleBlur} 
      children={context => <BaseGrid columns={context.resizeQuery?.fromPhoneLg ? 2 : 1}>
        <FormInput<Invitation> field="name" label="Full Name" required/>
        <FormDatePicker<Invitation> field="dateOfBirth" label="Date of Birth" required shouldUseSystemUIOnMobile />
        <FormInput<Invitation> field="email" label="Email" type="email" optional />
        <GenderSelector form={s.form.fields.gender} field="value" label="Gender" appearance="system" optional />
        {props.forType === ApplicationTypeOption.YoungPeople && <SexualitySelector form={s.form.fields.sexuality} field="value" label="Sexuality" appearance="system" optional />}
      </BaseGrid>}
    />
  )} />

}

export default InvitationInfoEditor;