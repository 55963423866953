import { Observer } from 'mobx-react-lite';
import React from 'react';
import ColorTag18Plus from '../../base/components/ColorTag/ColorTag18Plus';
import ColorTagYoungPeople from '../../base/components/ColorTag/ColorTagYoungPeople';
import ColorTagYoungPeople1214 from '../../base/components/ColorTag/ColorTagYoungPeople1214';
import ColorTagYoungPeople1517 from '../../base/components/ColorTag/ColorTagYoungPeople1517';
import { PreviewSupportGroup, SupportGroup } from '../../models/makeSupportGroup.model';
import { AgeGroupFilterType } from '../../utils/ageAndDateOfBirth.utils';
// import './SupportGroupAgeGroupTagSet.scss';

type SupportGroupAgeGroupTagSetProps = {
  group: SupportGroup | PreviewSupportGroup,
}

const SupportGroupAgeGroupTagSet: React.FC<SupportGroupAgeGroupTagSetProps> = props => {
  return <Observer children={() => (
    <span className="SupportGroupAgeGroupTagSet">
      {(props.group.ageGroups.includes(AgeGroupFilterType.youngPeople1214) || props.group.ageGroups.includes(AgeGroupFilterType.youngPeople1517)) && <>
        {props.group.ageGroups.includes(AgeGroupFilterType.adults) && <><ColorTag18Plus /> </>}
        {props.group.ageGroups.includes(AgeGroupFilterType.youngPeople1214) && props.group.ageGroups.includes(AgeGroupFilterType.youngPeople1517) ? <>
          <ColorTagYoungPeople />
        </> : <>
            {props.group.ageGroups.includes(AgeGroupFilterType.youngPeople1214) && <><ColorTagYoungPeople1214 useAbbr /> </>}
            {props.group.ageGroups.includes(AgeGroupFilterType.youngPeople1517) && <><ColorTagYoungPeople1517 useAbbr /> </>}
          </>}
      </>}
    </span>
  )} />
}

export default SupportGroupAgeGroupTagSet;