import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { Nullable } from '../../../../base/@types';
import AppPage from '../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../base/components/AppPageHeader/AppPageHeader';
import OverlayCloseButton from '../../../../base/components/OverlayCloseButton/OverlayCloseButton';
import UIBlock from '../../../../base/components/UIBlock/UIBlock';
import { defaultInvoiceEndpointIncludes, InvoiceEndpoints } from '../../../../base/endpoints/invoices.endpoints';
import { useOnMount } from '../../../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import { getUrlParams, useSyncUrlParams } from '../../../../base/utils/urlParams.utils';
import { ModelName } from '../../../../constants/modelNames.enum';
import { Invoice } from '../../../../models/makeInvoice.model';
import InvoiceManager from '../InvoiceManager/InvoiceManager';
// import './OverlayInvoiceManager.scss';

type OverlayInvoiceManagerProps = {
  id?: string,
}

const OverlayInvoiceManager: React.FC<OverlayInvoiceManagerProps> = props => {

  const p = useProps(props);

  const { UI, API } = useControllers();

  const s = useStore(() => ({
    get id() {
      return p.id ?? getUrlParams().invoiceId;
    },
    invoice: null as Nullable<Invoice>,
  }))

  const dismiss = () => {
    if (s.id) UI.OVERLAY.dismiss(`OverlayInvoiceManager#${s.id}`);
    else UI.OVERLAY.dismiss();
  }

  useOnMount(() => {
    flow(function * () {
      if (!s.id) {
        dismiss();
        return;
      }
      const url = InvoiceEndpoints.staff.get(s.id, { include: defaultInvoiceEndpointIncludes });
      s.invoice = yield API.get<Invoice>(url, ModelName.invoices);
    })();
  })

  useSyncUrlParams('invoiceId', s.id);
  
  return <Observer children={() => (
    <AppPage className="OverlayInvoiceManager" color="green">
      <AppPageHeader
        title={`Invoice ${s.invoice ? moment(s.invoice.billingPeriodStartDate).format('MMMM YYYY') : ''}`}
        endSlot={<OverlayCloseButton />}
      />
      <AppPageContent>
        <UIBlock padded>
          { s.invoice && <InvoiceManager invoice={s.invoice} /> }
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
}

export default OverlayInvoiceManager;