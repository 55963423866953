import React from "react";
import { ActionConfig } from "../../../controllers/ui/ui.controller.types";
import BaseTableActionButton from "./BaseTableActionButton";

interface BaseTableActionCellProps<T extends object = {}> {
  data: T,
  actions: ActionConfig[],
}

export const BaseTableActionCell = <T extends object>(props: React.PropsWithChildren<BaseTableActionCellProps<T>>) => {
  return <td className="BaseTableActionCell">
    {props.actions.map(action => (
      <BaseTableActionButton<T>
        data={props.data}
        action={action}
        key={action.name || action.label}
      />
    ))}
  </td>
}

export default BaseTableActionCell;