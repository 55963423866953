import { action, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseGrid from '../../base/components/BaseGrid/BaseGrid';
import BaseGridCell from '../../base/components/BaseGrid/BaseGridCell';
import BaseSpacer from '../../base/components/BaseSpacer/BaseSpacer';
import FormCountrySelector from '../../base/components/FormCountrySelector/FormCountrySelector';
import FormForm from '../../base/components/FormForm/FormForm';
import FormInput from '../../base/components/FormInput/FormInput';
import { isStandardModel } from '../../base/factories/standardModel.factory';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { makeForm } from '../../base/mediators/form.mediator';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { getSnapshot } from '../../base/utils/snapshot.utils';
import { irelandCountyList } from '../../constants/IrelandCounties.constants';
import { Address, AddressSnapshot, makeAddressSnapshotBase } from '../../models/makeAddress.model';
import { getPostCodeFieldLabel, getRegionFieldLabel } from '../../utils/address.utils';
import IrelandCountySelector from '../IrelandCountySelector/IrelandCountySelector';
import './AddressEditor.scss';

interface AddressEditorProps {
  className?: string,
  address?: Address | AddressSnapshot
  onSave?: (addressSnapshot: AddressSnapshot) => void,
  saveChangesOnBlur?: boolean,
  applyChangesImmediately?: boolean,
  disabled?: boolean,
  required?: boolean,
  doNotUseDetectedCountryId?: boolean,
}

const AddressEditor: React.FC<AddressEditorProps> = props => {

  const { ANALYTICS } = useControllers();
  const p = useProps(props);

  const s = useStore(() => ({
    get required() {
      return typeof p.required === "boolean" ? p.required : true
    },
    form: makeForm(props.address ? getSnapshot<AddressSnapshot>(props.address) : makeAddressSnapshotBase()),
    handleSave: () => {
      p.onSave && p.onSave(s.form.value);
    },
    handleBlur: action(() => {
      if (props.address && p.applyChangesImmediately) {
        isStandardModel(p.address) ? p.address.$patch(s.form.value) : Object.assign(props.address, s.form.value);
      }
      if (p.saveChangesOnBlur) {
        s.handleSave();
        s.form.markAsSaved();
      }
    })
  }));

  useOnMount(() => {
    const snapshot = isStandardModel<Address>(p.address) ? p.address.$getSnapshot() : p.address;
    s.form.reset(snapshot);
    if (!s.form.value.countryId && !p.doNotUseDetectedCountryId) {
      ANALYTICS.getUserCountryCode().then(countryId => {
        if (s.form.value.countryId) return;
        if (countryId) s.form.set('countryId', countryId);
      })
    }
    // IrelandCountySelector's select county options are in lowercase.
    // This detects if countryId is changed to IE, then transforms the county to lowercase.
    // If not a county, remove the value.
    const disposer = reaction(
      () => s.form.value.countryId,
      action(() => {
        if (s.form.value.countryId === 'IE') {
          const newRegionValue = s.form.get('region').toLowerCase();
          if (irelandCountyList.map(c => c.toLowerCase()).includes(newRegionValue)) {
            s.form.set('region', newRegionValue);
            return;
          }
          s.form.set('region', '');
        }
      })
    )
    return () => {
      disposer();
    }
  })

  return <Observer children={() => (
    <FormForm
      className={joinClassName('AddressEditor', p.className)}
      form={s.form}
      onBlur={s.handleBlur}
      disabled={p.disabled}
      children={context => <BaseGrid columns={context.resizeQuery?.fromPhoneLg ? 2 : 1}>
        <BaseGridCell columns="all">
          <FormInput field="lineOne" placeholder={`Line One (${s.required ? "Required" : "Optional"})`} label="Address Lines" />
          <BaseSpacer size=".5em" />
          <FormInput field="lineTwo" placeholder="Line Two" />
        </BaseGridCell>
        <FormInput field="city" label="City/Town" required={s.required} />
        <FormInput field="postcode" label={getPostCodeFieldLabel(s.form.value.countryId)} required={s.required} />
        {s.form.value.countryId === 'IE' ? (
          <IrelandCountySelector form={s.form.fields.region} field="value" name="region" label="County" required={s.required} />
        ) : <FormInput field="region" label={getRegionFieldLabel(s.form.value.countryId)} required={s.required} />}
        <FormCountrySelector field="countryId" label="Country" required={s.required} />
      </BaseGrid>}
    />
  )} />

}

export default AddressEditor;