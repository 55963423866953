import { action, reaction } from "mobx";
import { Nillable } from "../base/@types";
import { useOnMount } from "../base/hooks/lifecycle.hooks";
import { useControllers } from "../base/hooks/useRootController.hook";
import { copyWithJSON, recursiveMergeWithTypeCast } from "../base/utils/object.utils";
import { toTitleCase } from "../base/utils/string.utils";
import { P_ } from "../constants/permissions.alias";
import { nonHeterosexualSexualities } from "../constants/sexualities.constants";
import { User, UserPreferencesAdmin, UserSnapshot } from "../models/makeUser.model";

export type UsernameTransformer = (s?: string | null) => string | null

export const useSyncAdminPreference = <T extends object>(
	key: keyof UserPreferencesAdmin,
	valueGetter: () => T,
	beforeFirstSync?: (value: Partial<T>) => void
) => {
	const { AUTH } = useControllers();
	useOnMount(action(() => {
		if (!AUTH.isAdmin) return;
		const currentValue = AUTH.currentUser?.preferences.admin?.[key] as object as Partial<T>;
		currentValue && beforeFirstSync?.(currentValue);
		currentValue && recursiveMergeWithTypeCast(valueGetter(), currentValue);
		return reaction(
			() => JSON.stringify(valueGetter()),
			() => {
				console.log(JSON.stringify(valueGetter()));
				AUTH.mergeIntoUserPreference({ admin: { [key]: copyWithJSON(valueGetter()) } });
			})
	}));
}

export const isCounsellor = (user?: Nillable<User>) => user?.isCounsellor;
export const isModerator = (user?: Nillable<User>) => user?.isModerator;
export const isFacilitator = (user?: Nillable<User>) => user?.isFacilitator;
export const isCoordinator = (user?: Nillable<User>) => user?.isCoordinator;

export const counsellorNameTransformer = (d?: string | null) => d ? d.replace(/turn2me(-|_)/i, '') : '';

export function renderUserNameWithRole(roleName: string, user?: User | string | null) {
	const displayRoleName = toTitleCase(roleName);
	if (!user) return `No ${displayRoleName}`;
	if (typeof user === 'string') {
		if (isNaN(user as any)) return user;
		else return `${displayRoleName} #${user}`;
	};
	const fullName = [user.firstName, user.lastName].filter(i => i).join(' ');
	return (
		fullName ? `${fullName} (${user.username})` : '' ||
			user.username ||
			`${displayRoleName} #${user.id}`
	);
}

export const getCounsellorDisplayName = (counsellor?: User | string | null) => {
	return renderUserNameWithRole('counsellor', counsellor);
}

export const getFacilitatorDisplayName = (facilitator?: User | string | null) => {
	return renderUserNameWithRole('facilitator', facilitator);
}

export const getCoordinatorDisplayName = (coordinator?: User | string | null) => {
	return renderUserNameWithRole('coordinator', coordinator);
}

export const userIsLGBTQ = (user: Nillable<User>) => {
	const { sexuality } = user ?? {};
	if (sexuality && nonHeterosexualSexualities.includes(sexuality)) return true;
	return false;
}

export const checkIfStringContainsWord_turn2me = (string?: Nillable<string>) => string ? /turn2me/i.test(string.replace(/(\.|-|_|\s)/g, '')) : false;

export const isCounsellorSnapshot = (user: UserSnapshot) => user.permissions.find(p => p.name === P_.provideCounselling);
export const isModeratorSnapshot = (user: UserSnapshot) => user.permissions.find(p => p.name === P_.moderateThoughtCatcher);
export const isFacilitatorSnapshot = (user: UserSnapshot) => user.permissions.find(p => p.name === P_.facilitateSupportGroups);
export const isCoordinatorSnapshot = (user: UserSnapshot) => user.permissions.find(p => [P_.coordinateCounsellingApplications, P_.coordinateFreeCounsellingAvailabilities, P_.coordinatePaidCounsellingAvailabilities, P_.coordinateCounsellingSessions, P_.coordinateSupportGroups].includes(p.name));
