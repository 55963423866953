import { Observer } from "mobx-react-lite";
import React from "react";
import { useControllers, useUIController } from "../../hooks/useRootController.hook";
import { useStore } from "../../utils/mobx.utils";
import ToastCard from "../ToastCard/ToastCard";

export const ToastStack: React.FC = props => {

  const { COMMON } = useControllers();
  const { TOAST } = useUIController();
  const s = useStore(() => ({
    get disableToasts() {
      return COMMON.preferences.disableToasts;
    },
    get lastToast() {
      return TOAST.toasts[TOAST.toasts.length - 1];
    }
  }));
  return <Observer children={() => (
    <div className="ToastStack">
      { s.lastToast && !s.disableToasts && <ToastCard
        toast={s.lastToast}
        key={s.lastToast.id}
        canSwipeUpToDismiss
      />}
    </div>
  )} />
}
