import { Nillable, StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { ModelName } from "../constants/modelNames.enum";
import { assignable } from "../traits/assignable.trait";
import { AssignmentGoalSheet } from "./makeAssignment.model";
import { User } from "./makeUser.model";

export const makeSurveyGoalSheetSnapshot = () => ({
	...assignable(),
	goalOne: '',
	goalTwo: '',
	goalThree: '',
	timeCompleted: '' as Nillable<string>,
	timeCreated: '' as Nillable<string>,
	timeUpdated: '' as Nillable<string>,
})

export type SurveyGoalSheetSnapshot = ReturnType<typeof makeSurveyGoalSheetSnapshot>;

export type SurveyGoalSheetRelatedModels = {
	assignment: AssignmentGoalSheet,
	assignedToUser: User,
	completedByUser: User,
}

export type SurveyGoalSheet = StandardModel<SurveyGoalSheetSnapshot, SurveyGoalSheetRelatedModels>;

export const makeSurveyGoalSheet = createStandardModelFactory<SurveyGoalSheet, SurveyGoalSheetRelatedModels>({
	name: ModelName.surveysGoalSheet,
	snapshotFactory: makeSurveyGoalSheetSnapshot,
	relationshipsSchema: {
		assignment: ModelName.assignments,
		assignedToUser: ModelName.users,
		completedByUser: ModelName.users,
	}
})