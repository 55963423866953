import { DefaultSupportGroupIncludes, DefaultSupportGroupIncludesForStaff, SupportGroupEndpointParams, SupportGroupEndpoints } from "../base/endpoints/supportGroup.endpoints";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { SupportGroup } from "../models/makeSupportGroup.model";

export function getSupportGroups(API: APIController, params: SupportGroupEndpointParams) {
  return new Promise<SupportGroup[]>(async (resolve, reject) => {
    try {
      const canManage = API.ROOT?.children.AUTH.can.supportGroups_.manage_.groups;
      const _params = {
        include: canManage ? DefaultSupportGroupIncludesForStaff : DefaultSupportGroupIncludes,
        ...params,
      }
      const url = canManage ? SupportGroupEndpoints.staff.index(_params) : SupportGroupEndpoints.client.index(_params);
      const { entries } = await API.getMany<SupportGroup>(url, ModelName.supportGroups) || [];
      resolve(entries);
    } catch(e) {
      reject(e);
    }
  })
}