import React from "react";
import { makeIconDef } from "./makeIconDef";

export const NotebookIconDef = {
	notebook: makeIconDef('notebook', {
		regular: () => <path fillRule="evenodd" clipRule="evenodd" d="M5 4H17V20H5V19H4V20V21H5H18C19.1046 21 20 20.1046 20 19V5C20 3.89543 19.1046 3 18 3H5H4V4V5C3.72386 5 3.5 5.22386 3.5 5.5C3.5 5.77614 3.72386 6 4 6H5C5.27614 6 5.5 5.77614 5.5 5.5C5.5 5.22386 5.27614 5 5 5V4ZM18 4V20C18.5523 20 19 19.5523 19 19V5C19 4.44772 18.5523 4 18 4ZM5 17V16H4V17C3.72386 17 3.5 17.2239 3.5 17.5C3.5 17.7761 3.72386 18 4 18H5C5.27614 18 5.5 17.7761 5.5 17.5C5.5 17.2239 5.27614 17 5 17ZM5 13V14C5.27614 14 5.5 14.2239 5.5 14.5C5.5 14.7761 5.27614 15 5 15H4C3.72386 15 3.5 14.7761 3.5 14.5C3.5 14.2239 3.72386 14 4 14V13H5ZM5 11V10H4V11C3.72386 11 3.5 11.2239 3.5 11.5C3.5 11.7761 3.72386 12 4 12H5C5.27614 12 5.5 11.7761 5.5 11.5C5.5 11.2239 5.27614 11 5 11ZM5 7V8C5.27614 8 5.5 8.22386 5.5 8.5C5.5 8.77614 5.27614 9 5 9H4C3.72386 9 3.5 8.77614 3.5 8.5C3.5 8.22386 3.72386 8 4 8V7H5ZM7.5 6C7.22386 6 7 6.22386 7 6.5V7.5C7 7.77614 7.22386 8 7.5 8H14.5C14.7761 8 15 7.77614 15 7.5V6.5C15 6.22386 14.7761 6 14.5 6H7.5ZM7 17.5C7 17.2239 7.22386 17 7.5 17H8.5C8.77614 17 9 17.2239 9 17.5C9 17.7761 8.77614 18 8.5 18H7.5C7.22386 18 7 17.7761 7 17.5ZM10.5 17C10.2239 17 10 17.2239 10 17.5C10 17.7761 10.2239 18 10.5 18H11.5C11.7761 18 12 17.7761 12 17.5C12 17.2239 11.7761 17 11.5 17H10.5ZM7 15.5C7 15.2239 7.22386 15 7.5 15H10.5C10.7761 15 11 15.2239 11 15.5C11 15.7761 10.7761 16 10.5 16H7.5C7.22386 16 7 15.7761 7 15.5Z" fill="currentColor" />,
		filled: () => <path fillRule="evenodd" clipRule="evenodd" d="M17 3H4V5C3.72386 5 3.5 5.22386 3.5 5.5C3.5 5.77614 3.72386 6 4 6H4.5C4.77614 6 5 6.22386 5 6.5C5 6.77614 4.77614 7 4.5 7H4V8C3.72386 8 3.5 8.22386 3.5 8.5C3.5 8.77614 3.72386 9 4 9H4.5C4.77614 9 5 9.22386 5 9.5C5 9.77614 4.77614 10 4.5 10H4V11C3.72386 11 3.5 11.2239 3.5 11.5C3.5 11.7761 3.72386 12 4 12H4.5C4.77614 12 5 12.2239 5 12.5C5 12.7761 4.77614 13 4.5 13H4V14C3.72386 14 3.5 14.2239 3.5 14.5C3.5 14.7761 3.72386 15 4 15H4.5C4.77614 15 5 15.2239 5 15.5C5 15.7761 4.77614 16 4.5 16H4V17C3.72386 17 3.5 17.2239 3.5 17.5C3.5 17.7761 3.72386 18 4 18H4.5C4.77614 18 5 18.2239 5 18.5C5 18.7761 4.77614 19 4.5 19H4V21H17V3ZM20 19C20 20.1046 19.1046 21 18 21V3C19.1046 3 20 3.89543 20 5V19ZM7 6.5C7 6.22386 7.22386 6 7.5 6H14.5C14.7761 6 15 6.22386 15 6.5V7.5C15 7.77614 14.7761 8 14.5 8H7.5C7.22386 8 7 7.77614 7 7.5V6.5ZM7.5 17C7.22386 17 7 17.2239 7 17.5C7 17.7761 7.22386 18 7.5 18H8.5C8.77614 18 9 17.7761 9 17.5C9 17.2239 8.77614 17 8.5 17H7.5ZM10 17.5C10 17.2239 10.2239 17 10.5 17H11.5C11.7761 17 12 17.2239 12 17.5C12 17.7761 11.7761 18 11.5 18H10.5C10.2239 18 10 17.7761 10 17.5ZM7.5 15C7.22386 15 7 15.2239 7 15.5C7 15.7761 7.22386 16 7.5 16H10.5C10.7761 16 11 15.7761 11 15.5C11 15.2239 10.7761 15 10.5 15H7.5Z" fill="currentColor" />,
		color: () => <>
			<path fillRule="evenodd" clipRule="evenodd" d="M4 3H18C19.1046 3 20 3.89543 20 5V19C20 20.1046 19.1046 21 18 21H4V19H4.5C4.77614 19 5 18.7761 5 18.5C5 18.2239 4.77614 18 4.5 18H4C3.72386 18 3.5 17.7761 3.5 17.5C3.5 17.2239 3.72386 17 4 17V16H4.5C4.77614 16 5 15.7761 5 15.5C5 15.2239 4.77614 15 4.5 15H4C3.72386 15 3.5 14.7761 3.5 14.5C3.5 14.2239 3.72386 14 4 14V13H4.5C4.77614 13 5 12.7761 5 12.5C5 12.2239 4.77614 12 4.5 12H4C3.72386 12 3.5 11.7761 3.5 11.5C3.5 11.2239 3.72386 11 4 11V10H4.5C4.77614 10 5 9.77614 5 9.5C5 9.22386 4.77614 9 4.5 9H4C3.72386 9 3.5 8.77614 3.5 8.5C3.5 8.22386 3.72386 8 4 8V7H4.5C4.77614 7 5 6.77614 5 6.5C5 6.22386 4.77614 6 4.5 6H4C3.72386 6 3.5 5.77614 3.5 5.5C3.5 5.22386 3.72386 5 4 5V3Z" fill="url(#C089H23FN)" />
			<line x1="17.5" y1="3" x2="17.5" y2="21" stroke="#FFC700" />
			<rect x="7" y="6" width="8" height="2" rx="0.5" fill="white" />
			<rect x="7" y="17" width="2" height="1" rx="0.5" fill="white" />
			<rect x="10" y="17" width="2" height="1" rx="0.5" fill="white" />
			<rect x="7" y="15" width="4" height="1" rx="0.5" fill="white" />
			<defs>
				<radialGradient id="C089H23FN" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20 24.5) rotate(-101.821) scale(21.9659)">
					<stop stopColor="#AAAAFF" />
					<stop offset="1" stopColor="#706DFF" />
				</radialGradient>
			</defs>
		</>
	})
}

