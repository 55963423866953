import { Observer } from 'mobx-react-lite';
import React from 'react';
import ColorTagFreeSession from '../../base/components/ColorTag/ColorTagFreeSession';
import ColorTagPaidSession from '../../base/components/ColorTag/ColorTagPaidSession';
// import './SlotTypeRenderer.scss';

type SlotTypeRendererProps = {
  isPaid?: boolean | null,
  value?: 'paid' | 'free' | null, // change to enum with 'paid' | 'free' in future.
}

const SlotTypeRenderer: React.FC<SlotTypeRendererProps> = props => {
  const p = props;
  return <Observer children={() => (
    <span className="SlotTypeRenderer">
      {(p.value === 'paid' || p.isPaid) && <ColorTagPaidSession />}
      {(p.value === 'free' || !p.isPaid) && <ColorTagFreeSession />}
    </span>
  )} />
}

export default SlotTypeRenderer;