import Axios, { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import { action, observable } from "mobx";
import { AnyObject, LaravelSuccessIndexGetResponse, Nillable, Nullable, SnapshotOf, Undefinable } from "../base/@types";
import { HasId } from '../base/@types/traits.types';
import { decodeString } from '../base/utils/encoder.utils';
import { immediateReaction } from '../base/utils/mobx.utils';
import { enforceStringIdOnSnapshot, getSnapshot } from '../base/utils/snapshot.utils';
import { singularize } from '../base/utils/string.utils';
import { getTrustToken } from '../base/utils/trust.utils';
import { isArray, isObject } from '../base/utils/typeChecks.utils';
import { ModelName } from '../constants/modelNames.enum';
import { AuthTokenKey } from '../constants/storageKeys.constants';
import { isInCypressTestMode, SHOULD_LOG } from '../env';
import { LocalDBSetOrMergeOptions } from './localDB.controller';
import { makeControllerBase, makeRootControllerChildInitFn } from "./_root.controller";

/**
 *
 * An abstraction around API REST endpoints.
 *
 * It works with the LOCALDB controller and for the normal REST with clearly defined model names,
 * will return a fully constructed local model for consumption. as such, the model names are required when using the API methods.
 *
 * To bypass this abstraction, the ApiController also provides `getRaw`, `postRaw` and `patchRaw` which returns the unmodified original response.
 *
 */
export type APIController = ReturnType<typeof makeAPIController>;

export type APIControllerOptions = {
  baseURL?: string
};

export type APIGetRequestOptions = {
  getFromLocalById?: Nullable<string>,
  replaceExisting?: boolean,
  headers?: AxiosRequestConfig,
}

export type APIGetManyRequestOptions = {
  allowCache?: boolean,
  replaceExisting?: boolean,
}

export type APIGetManyResponse<T extends object> = {
  entries: T[],
  response: AxiosResponse<LaravelSuccessIndexGetResponse<T>>
}

export const makeAuthHeaderObject = (token: string) => ({
  'Authorization': `Bearer ${token}`,
})
export const baseHeaderPartial = {
  'Accept': 'application/json',
  'common': { 'X-Requested-With': 'XMLHttpRequest' },
};

export const AxiosInstance = Axios.create();

export const makeAPIController = (options?: APIControllerOptions) => {

  immediateReaction(
    () => options?.baseURL,
    url => {
      if (!url) return;
      AxiosInstance.interceptors.request.use(
        async config => {
          config.baseURL = `${options?.baseURL}/api`;
          return config;
        },
        error => Promise.reject(error)
      );
    },
  )

  const getStoredToken = async () => {
    const t = await c.ROOT?.children.STORAGE.get(AuthTokenKey);
    // console.log('API trying to get stored token', t);
    return t ? decodeString(t) : undefined;
  }

  const makeAuthHeaderPartial = async () => {
    const token = await getStoredToken();
    if (!token) {
      SHOULD_LOG() && console.log('NO TOKEN FOUND, LAST TOKEN', _private.lastToken)
      if (_private.lastToken && !isInCypressTestMode) {
        SHOULD_LOG() && console.log('logging out, reason: no token found when composing request headers, but there was a token in the previous attempt to get headers.');
        c.ROOT?.children.AUTH.logout({ showWarning: true });
      }
      return false;
    }
    if (!_private.lastToken) _private.lastToken = token;
    else if (token !== _private.lastToken && _private.lastAuthenticatedUserId !== c.ROOT?.children.AUTH.currentUser?.id && !isInCypressTestMode) {
      SHOULD_LOG() && console.log('logging out, reason: token changed unexpectedly compared to the previous attempt to get headers.');
      c.ROOT?.children.AUTH.logout({ showWarning: true });
    }
    _private.lastAuthenticatedUserId = c.ROOT?.children.AUTH.currentUser?.id;
    return makeAuthHeaderObject(token);
  }

  const makeHeaders = async (trust: string, cancelToken?: CancelToken): Promise<AxiosRequestConfig> => {
    if (trust !== getTrustToken()) return {};
    const authHeaderPartial = await makeAuthHeaderPartial();

    const cancelTokenObj = cancelToken ? {cancelToken: cancelToken} : {}

    return {
      headers: { ...authHeaderPartial, ...baseHeaderPartial },
      ...cancelTokenObj
    }
  }

  const _private = observable({
    lastAuthenticatedUserId: null as Nillable<string>,
    lastToken: null as Nullable<string>,
    setLastToken: action((token: Nullable<string>) => _private.lastToken = token),
  })

  const processSnapshotBeforeReturn = <T = AnyObject>(
    snapshot: any,
    modelName?: ModelName,
    options?: LocalDBSetOrMergeOptions
  ) => {
    if (!isObject(snapshot)) return snapshot as T;
    const { LOCALDB } = c.ROOT!.children;
    const entry = enforceStringIdOnSnapshot(snapshot) as AnyObject;
    if (modelName) return LOCALDB.setOrMerge(modelName, entry as HasId, undefined, { trustToken: getTrustToken(), ...options }) as unknown as T;
    return entry as T;
  };

  const c = observable({

    ...makeControllerBase('API'),

    makeHeaders,

    /**
     * Gets a standard model.
     * It is required to pass in a standard modelName.
     * @returns a promise that resolves to a constructed model
     */
    get: <T extends AnyObject = AnyObject>(
      url: string,
      modelName: ModelName,
      options?: APIGetRequestOptions
    ) => new Promise<T | null>(async (resolve, reject) => {
      // console.log(options?.getFromLocalById);
      if (options?.getFromLocalById) {
        const { LOCALDB } = c.ROOT!.children;
        const entry = LOCALDB.get(modelName, options.getFromLocalById);
        if (entry) {
          resolve(entry as unknown as T);
        } else {
          resolve(processSnapshotBeforeReturn({ id: options.getFromLocalById }, modelName, { replaceExisting: options.replaceExisting }) as T);
        }
      }
      try {
        const response = await AxiosInstance.get(url, options?.headers ?? await makeHeaders(getTrustToken()));
        const { data } = response;
        if (!data) resolve(null);
        const id = url.match(/filter\[id\]=(\d+)/)?.[1];
        const entry = isArray(data?.data) ? (id ? data.data.find((d: HasId) => d.id + '' === id) : data.data[0]) : data;
        if (!entry) {
          reject(`Hmm, we weren't able to find this ${singularize(modelName)}.`);
          return;
        }
        resolve(processSnapshotBeforeReturn(entry, modelName, { replaceExisting: options?.replaceExisting }) as T);
      } catch(e) {
        reject(e);
      }
    }),

    /**
     * Gets a collection of standard model
     * It is required to pass in a standard modelName.
     * @returns a promise that resolves to { entries: T[], response: (original response from API) }
     * */
    getMany: async <T extends AnyObject = AnyObject>(
      url: string,
      modelName: ModelName,
      options?: APIGetManyRequestOptions
    ): Promise<APIGetManyResponse<T>> => {
      const { LOCALDB } = c.ROOT!.children;
      const response = await AxiosInstance.get(url, await makeHeaders(getTrustToken()));
      const isStandardEndpoint = isArray(response?.data?.data);
      if (!isStandardEndpoint) {
        SHOULD_LOG() && console.error('Non standard index endpoint response received:', url, response);
      }
      const data = isStandardEndpoint ? response.data.data : response.data;
      // console.log(data);
      if (!data) return { entries: [] as unknown as T[], response };
      const entries = data.map(enforceStringIdOnSnapshot);
      const result = LOCALDB.setOrMergeMany(modelName, entries, undefined, options);
      // console.log(LOCALDB.data[modelName]);
      // console.log(entries, result);
      return {
        entries: (result || entries) as unknown as T[],
        response,
      };
    },

    /**
     * Posts (creates) a standard model.
     * It is required to pass in a standard modelName.
     * @returns a promise that resolves to a constructed model.
     */
    post: async <T = object, P extends Undefinable<object> | Partial<SnapshotOf<T>> = object>(
      url: string,
      modelName: ModelName,
      payload?: P,
    ) => {
      const _payload = getSnapshot(payload);
      const { data } = await AxiosInstance.post(url, _payload, await makeHeaders(getTrustToken()));
      if (!data) return null;
      return processSnapshotBeforeReturn(data, modelName) as T;
    },

    /**
     * Patches (a full update) a standard model.
     * It is required to pass in a standard modelName.
     * @returns a promise that resolves to a constructed model.
     */
    patch: async <T extends AnyObject = AnyObject, P extends AnyObject = Partial<T> | Partial<SnapshotOf<T>>>(
      url: string,
      modelName: ModelName,
      payload: P,
    ) => {
      const _payload = getSnapshot(payload);
      const { data } = await AxiosInstance.patch(url, _payload, await makeHeaders(getTrustToken()));
      if (!data) return null;
      return processSnapshotBeforeReturn(data, modelName) as T;
    },

    /**
     * Puts (a partial update) a standard model.
     * It is required to pass in a standard modelName.
     * @returns a promise that resolves to a constructed model.
     */
    put: async <T extends AnyObject = AnyObject, P extends AnyObject = T | Partial<SnapshotOf<T>>>(
      url: string,
      modelName: ModelName,
      payload: P,
    ) => {
      const _payload = getSnapshot(payload);
      const { data } = await AxiosInstance.put(url, _payload, await makeHeaders(getTrustToken()));
      if (!data) return null;
      return processSnapshotBeforeReturn(data, modelName) as T;
    },

    /**
     * Deletes a standard model.
     * It is required to pass in a standard modelName.
     * @returns a promise that resolves to the original server response.
     */
    delete: async <T = void>(
      url: string,
      modelName: ModelName,
      recordOrRecordIdToDelete?: HasId | string,
    ) => {
      try {
        const response = await AxiosInstance.delete(url, await makeHeaders(getTrustToken()));
        const { LOCALDB } = c.ROOT!.children
        if (recordOrRecordIdToDelete && modelName) {
          LOCALDB.remove(modelName, recordOrRecordIdToDelete);
        }
        return response as AxiosResponse<T>;
      } catch(e) {
        throw e;
      }
    },

    /**
     * send a GET request to the specified URL, returns the original server response.
     */
    getRaw: async <T>(
      url: string,
      headers?: AxiosRequestConfig,
    ): Promise<AxiosResponse<T>> => {
      return await AxiosInstance.get(url, headers ?? await makeHeaders(getTrustToken()));
    },

    /**
     * send a GET request to the specified URL, returns the original server response. Additionally, for long get requests, the request can be cancelled.
     */
    getRawCanCancel: async <T>(
      url: string,
      cancelToken: CancelToken,
    ): Promise<AxiosResponse<T>> => {
      return await AxiosInstance.get(url, await makeHeaders(getTrustToken(), cancelToken));
    },

    /**
     * send a POST request to the specified URL, returns the original server response.
     */
    postRaw: async <R = unknown, P extends AnyObject = AnyObject>(
      url: string,
      payload?: P,
      headers?: AxiosRequestConfig,
    ): Promise<AxiosResponse<R>> => {
      return await AxiosInstance.post(url, payload, headers ?? await makeHeaders(getTrustToken()));
    },

    /**
     * send a PATCH request to the specified URL, returns the original server response.
     */
    patchRaw: async <R = unknown, P extends AnyObject = AnyObject>(
      url: string,
      payload?: P,
    ): Promise<AxiosResponse<R>> => {
      return await AxiosInstance.patch(url, payload, await makeHeaders(getTrustToken()));
    },

    configure: async (trust?: string) => {
      const { AUTH } = c.ROOT!.children;
      if (trust !== getTrustToken()) {
        _private.setLastToken(null);
        SHOULD_LOG() && console.log('logging out, reason: api.configure called without a trust token');
        AUTH.logout();
        return;
      }
      _private.lastAuthenticatedUserId = AUTH.currentUser?.id;
      const token = await getStoredToken();
      _private.setLastToken(token);
    },

    reset: () => {
      _private.setLastToken(null);
    }

  })


  c.init = makeRootControllerChildInitFn(
    c,
    action(() => {
      c.ready = true;
    })
  )

  return c;
}
