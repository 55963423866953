import { useControllers } from "../base/hooks/useRootController.hook";
import { getAgeFromDateOfBirth, isAdultAge } from "../utils/ageAndDateOfBirth.utils";

export function useCurrentUserAge() {
  const { AUTH } = useControllers();
  const currentUserDob = AUTH.currentUser?.dateOfBirth;
  const currentUserAge = currentUserDob ? getAgeFromDateOfBirth(currentUserDob) : undefined;
  return currentUserAge;
}

export function useCurrentUserIsAdult() {
  const currentUserAge = useCurrentUserAge();
  const currentUserIsAdult = !currentUserAge || isAdultAge(currentUserAge);
  return currentUserIsAdult;
}