import React from "react";
import { makeIconDef } from "./makeIconDef";

export const PersonCircleIconDef = {
  'person-circle': makeIconDef('person-circle', {
    regular: () => <path fillRule="evenodd" clipRule="evenodd" d="M17.9057 16.2405C18.1042 16.5516 18.5587 16.5967 18.7567 16.2853C19.544 15.0465 20 13.5765 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 13.5765 4.45599 15.0464 5.24326 16.2852C5.44121 16.5966 5.89572 16.5516 6.0942 16.2404C7.33709 14.2921 9.51763 13 12 13C14.4823 13 16.6629 14.2921 17.9057 16.2405ZM14.6167 20.6137C18.3111 19.4929 21 16.0604 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 14.3043 3.866 16.4064 5.29028 17.9984C5.29029 17.9984 5.29029 17.9985 5.29029 17.9985V17.9985C5.29029 17.9985 5.29029 17.9985 5.2903 17.9985C6.36225 19.1967 7.75044 20.1061 9.32368 20.5954C10.1691 20.8583 11.068 21 12 21C12.9101 21 13.7887 20.8649 14.6167 20.6137ZM17.4749 17.8332V17.8332C17.5289 17.7825 17.5456 17.7035 17.5164 17.6354C16.5984 15.4967 14.4729 14 12 14C9.60313 14 7.53266 15.406 6.57174 17.4398C6.48469 17.624 6.53123 17.8425 6.68343 17.9779C8.09676 19.2358 9.95914 20 12 20C12.1701 20 12.339 19.9947 12.5066 19.9842C14.378 19.8673 16.075 19.1069 17.379 17.9217C17.4112 17.8925 17.4432 17.863 17.4749 17.8332ZM12 11C13.1046 11 14 10.1046 14 9C14 7.89543 13.1046 7 12 7C10.8954 7 10 7.89543 10 9C10 10.1046 10.8954 11 12 11ZM12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z" fill="currentColor" />,
    filled: () => <>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.54477 18.6649C5.50149 15.4531 8.47723 13.1111 11.9999 13.1111C15.5227 13.1111 18.4985 15.4531 19.4552 18.665C21.0377 16.896 22 14.5604 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 14.5604 2.96222 16.896 4.54477 18.6649ZM15.3333 8.66668C15.3333 10.5076 13.8409 12 12 12C10.159 12 8.66666 10.5076 8.66666 8.66668C8.66666 6.82573 10.159 5.33334 12 5.33334C13.8409 5.33334 15.3333 6.82573 15.3333 8.66668Z" fill="currentColor" />
      <circle opacity="0.6" cx="12" cy="8.66668" r="3.33333" fill="currentColor" />
      <path opacity="0.6" fillRule="evenodd" clipRule="evenodd" d="M4.54477 18.6649C5.50149 15.4531 8.47722 13.1111 11.9999 13.1111C15.5227 13.1111 18.4985 15.4531 19.4551 18.665C17.624 20.7118 14.9625 22 12 22C9.0375 22 6.37589 20.7118 4.54477 18.6649Z" fill="currentColor" />
    </>,
    color: () => <>
      <circle cx="12" cy="12" r="10" fill="url(#PersonCircle0_radial)" />
      <circle cx="12" cy="12" r="10" fill="url(#PersonCircle1_radial)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12 12C13.8409 12 15.3333 10.5076 15.3333 8.66668C15.3333 6.82573 13.8409 5.33334 12 5.33334C10.159 5.33334 8.66666 6.82573 8.66666 8.66668C8.66666 10.5076 10.159 12 12 12ZM11.9999 13.1111C8.4772 13.1111 5.50146 15.4531 4.54475 18.665C6.37587 20.7118 9.03747 22 11.9999 22C14.9624 22 17.624 20.7118 19.4551 18.665C18.4984 15.4531 15.5227 13.1111 11.9999 13.1111Z" fill="url(#PersonCircle2_radial)" />
      <defs>
        <radialGradient id="PersonCircle0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(26.5 2) rotate(135.83) scale(24.3977 11.2936)">
          <stop stopColor="#009FE3" />
          <stop offset="1" stopColor="#6464B0" />
        </radialGradient>
        <radialGradient id="PersonCircle1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22 20.5) rotate(-84.8056) scale(16.568 45.4859)">
          <stop stopColor="#FDC430" />
          <stop offset="0.416667" stopColor="#07A78A" />
          <stop offset="1" stopColor="#07A78A" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="PersonCircle2_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12 22.5) rotate(-90) scale(27 46.1291)">
          <stop stopColor="#C7C7FF" stopOpacity="0.34" />
          <stop offset="1" stopColor="white" />
        </radialGradient>
      </defs>
    </>
  })
}