import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseCalendarEvent, { BaseCalendarEventProps } from '../../base/components/BaseCalendar/BaseCalendarEvent';
import ColorTag from '../../base/components/ColorTag/ColorTag';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { CounsellingSession } from '../../models/makeCounsellingSession.model';
import CommunicationTypeRenderer from '../CommunicationTypeRenderer/CommunicationTypeRenderer';

interface CounsellingSessionCalendarEventRendererProps extends BaseCalendarEventProps<CounsellingSession> { }

const CounsellingSessionCalendarEventRenderer: React.FC<CounsellingSessionCalendarEventRendererProps> = props => {
  const style = {};
  const p = useProps(props);
  const s = useStore(() => ({
    get session() {
      return p.calendarEvent.data;
    },
    get type() {
      return s.session?.type;
    }
  }))
  return <Observer children={() => (
    <BaseCalendarEvent
      className={joinClassName(
        'CounsellingSessionCalendarEventRenderer',
      )} {...p} style={style} color={s.session?.isPaidSession ? 'skyblue' : 'blueGreen'}>
      {s.type && <CommunicationTypeRenderer value={s.type} />}
      {s.session?.timeEnded && <ColorTag color="green">Ended</ColorTag>}
      {s.session?.isCancelled && <ColorTag color="red">Cancelled</ColorTag>}
      {s.session?.isRefunded && <ColorTag color="red">Refunded</ColorTag>}
    </BaseCalendarEvent>
  )} />
}

export default CounsellingSessionCalendarEventRenderer;