import { Nullable, StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { ModelName } from "../constants/modelNames.enum";
import { hasTimestamps } from "../traits/hasTimestamps.trait";

export type STATE_ON = 'on';
export type STATE_OFF = 'off';
export type STATE_READONLY = 'readonly';
export type TwoWayState = STATE_ON | STATE_OFF | null;
export type ThreeWayState = STATE_ON | STATE_READONLY | STATE_OFF | null;
export interface SiteConfigurationSet {
  COUNSELLING_EMAIL: TwoWayState;
  THOUGHT_CATCHER_ADULT: ThreeWayState;
  THOUGHT_CATCHER_YOUNG_PEOPLE: ThreeWayState;
  THOUGHT_CATCHER_DISABLED_WORDING_ADULT: string | null;
  THOUGHT_CATCHER_DISABLED_WORDING_YOUNG_PEOPLE: string | null;
  SERVICE_OPEN_IN_COUNTRY_CODES: string[];COUNSELLING_APPLICATION_SERVICE_OPEN_IN_COUNTRY_CODES: string[],
}

export type ConfigurationSnapshot = ReturnType<typeof makeConfigurationSnapshotBase>;

/**
 * Global site configurations
 */
export type Configuration = StandardModel<ConfigurationSnapshot, ConfigurationRelatedModels, ConfigurationExtendedProperties>;

export type ConfigurationRelatedModels = {
}
export type ConfigurationExtendedProperties = {
}
export const makeConfigurationSnapshotBase = () => ({
  id: '',
  key: null as Nullable<keyof SiteConfigurationSet>,
  value: null as Nullable<string>,
  ...hasTimestamps(),
})

export const makeConfiguration = createStandardModelFactory<Configuration, ConfigurationRelatedModels, ConfigurationExtendedProperties>({
  name: ModelName.configurations,
  snapshotFactory: makeConfigurationSnapshotBase,
})