import { Back, Power2, TweenLite } from 'gsap';
import { action, flow, reaction, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { CSSProperties, RefObject, useRef } from 'react';
import { isInCypressTestMode } from '../../../env';
import { AnyObject } from '../../@types';
import { Renderable } from '../../@types/ui.types';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useResizeQuery } from '../../hooks/useResizeQuery';
import { useControllers } from '../../hooks/useRootController.hook';
import joinClassName from '../../utils/className.utils';
import { renderRenderable } from '../../utils/components.utils';
import { makeDisposerController } from '../../utils/disposer.utils';
import { disableScroll, enableScroll } from '../../utils/document.utils';
import { generateUuid } from '../../utils/id.utils';
import { multiExpressionReaction, useAutoSyncWithInitializer, useProps } from '../../utils/mobx.utils';
import { getValueOfKey, setValueOfKey } from '../../utils/object.utils';
import { scrollElementTo } from '../../utils/scrollElementTo.utils';
import tick, { runAfter } from '../../utils/waiters.utils';
import BaseButton from '../BaseButton/BaseButton';
import './UICard.scss';

export interface UICardProps<T extends object = object> {
  name?: string,
  className?: string,
  // appearance?: 'rounded' | 'chat-bubble',
  sideAForegroundColor?: string,
  sideABackgroundColor?: string,
  sideABackgroundImage?: string,
  /** 
   * content to be rendered on the front side; 
   * if passed, the open action will be specifically defined in its onOpen handler.
   * */
  sideARenderable?: Renderable<UICardInnerProps & T>,
  sideBRenderable?: Renderable,
  innerProps?: T,
  animation?: 'expand' | 'flip',
  backdropBlur?: any,
  onBeforeOpen?: () => Promise<true>,
  onAfterOpen?: () => Promise<true>,
  onBeforeClose?: () => Promise<true>,
  onAfterClose?: () => Promise<true>,
  showDefaultCloseButton?: boolean,
  shouldClipContent?: boolean,
  frameless?: boolean,
  cardRatio?: number,
  autoOpen?: boolean,
  static?: boolean,
  shouldProgrammaticallyClose?: () => boolean,
  /** TODO */
  canInteractWithOutside?: boolean,
}

export type UICardInnerProps = {
  isOpening?: boolean,
  isOpen?: boolean,
  isClosing?: boolean,
  open?: () => Promise<void>,
  close?: () => Promise<void>,
  containerRef?: RefObject<HTMLDivElement>,
};

const UICard = <T extends object = object>(
  props: React.PropsWithChildren<UICardProps<T>>
) => {

  const { UI } = useControllers();

  const outerRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const sideARef = useRef<HTMLDivElement>(null);

  const outerResizeQuery = useResizeQuery(outerRef);

  const { sideARenderable, sideBRenderable, ...rest } = props;
  const p = useProps(rest);

  const s = useAutoSyncWithInitializer(() => ({
    hasSideARenderable: Boolean(props.sideARenderable),
    hasSideBRenderable: Boolean(props.sideBRenderable),
    id: generateUuid(),
    isOpening: false,
    isOpen: false,
    isClosing: false,
    get isClosed() {
      return (!s.isOpen && !s.isOpening) || s.isClosing;
    },
    open: async () => {
      if (!s.canOpen) return;
      UI.registerOpenedUICard(s.id);
      p.onBeforeOpen && await p.onBeforeOpen();
      await s.animateOpen();
      p.onAfterOpen && await p.onAfterOpen();
    },
    close: async () => {
      p.onBeforeClose && await p.onBeforeClose();
      UI.deregisterOpenedUICard(s.id);
      await s.animateClose();
      p.onAfterClose && await p.onAfterClose();
    },
    get shouldRenderInPortal() {
      return Boolean((s.isOpening || s.isOpen) && UI.PORTAL.ref?.current);
    },
    get innerClassName() {
      return joinClassName(
        'UICard',
        p.className,
        s.isOpening && 'opening open',
        s.isOpen && 'open',
        s.isClosing && 'closing',
        s.isClosed && 'closed',
      )
    },
    get clipContent() {
      return p.shouldClipContent ?? true;
    },
    get framed() {
      return !(p.frameless ?? false);
    },
    get parentDataAttributes() {
      const result: AnyObject = {};
      Object.keys(p).forEach(key => key.match(/^data-/) && setValueOfKey(result, key, getValueOfKey<any>(key, p)));
      return result;
    },
    get sideAClassName() {
      return joinClassName(
        'UICardSideA',
        s.clipContent === true && 'clipContent',
        s.framed && 'framed',
      )
    },
    originalSize: { top: 0, bottom: 0, left: 0, width: 0, height: 0 },
    get innerStyle() {
      const base = {
        '--UICardOpenedWidth': s.openedWidth + 'px',
        '--UICardOpenedHeight': s.openedHeight + 'px',
      } as CSSProperties
      return (s.isOpen || s.isOpening) ? {
        ...s.originalSize,
        ...base,
      } : base;
    },
    get sideABackgroundColor() {
      return p.sideABackgroundColor ?? (s.framed ? 'hsla( var(--app-c-background-hsl), 1 )' : undefined);
    },
    get sideAStyles() {
      return {
        color: p.sideAForegroundColor,
        '--backgroundColor': s.sideABackgroundColor,
        backgroundColor: s.sideABackgroundColor,
        backgroundImage: p.sideABackgroundImage,
        backdropFilter: p.backdropBlur ? 'blur(2em)' : undefined,
      }
    },
    get sideBInnerStyle() {
      return {
        width: s.openedWidth,
        height: s.openedHeight,
      }
    },
    get shouldShowSideB() {
      return s.isFlipCard && s.hasSideBRenderable && (s.isOpen || s.isOpening);
    },
    get innerProps() {
      return {
        ...p.innerProps,
        isOpening: s.isOpening || false,
        isOpen: s.isOpen || false,
        isClosing: s.isClosing || false,
        open: s.open || (async () => { }),
        close: s.close || (async () => { }),
        containerRef: sideARef,
      } as UICardInnerProps & T;
    },
    get canOpen() {
      return (!s.isFlipCard || s.hasSideBRenderable) && !s.isOpen && !s.isOpening;
    },
    get disableAnimation() {
      return isInCypressTestMode;
    },
    get isFlipCard() {
      return p.animation === 'flip';
    },
    get shouldShowDefaultCloseButton() {
      return s.isOpen && (p.showDefaultCloseButton ?? true);
    },
    get outerHeight() {
      return p.cardRatio ? outerResizeQuery.width / p.cardRatio : undefined;
    },

    get openedWidth() {
      if (UI.onlyPhones) return UI.appWidth;
      return Math.max(480, Math.min(UI.appWidth * .618, 719), s.originalSize.width);
    },
    get openedHeight() {
      if (UI.onlyPhones) return UI.appHeight;
      return Math.max(UI.appHeight * .875, s.originalSize.height);
    },
    get openedTop() {
      return UI.appHeight - s.openedHeight;
    },
    get openedleft() {
      return (UI.appWidth - s.openedWidth) / 2;
    },

    animateOpen: flow(function * () {
      if (!s.canOpen) return;
      /** get bounding box prior to transformation */
      const outerBoundingbox = outerRef.current?.getBoundingClientRect();
      if (!outerBoundingbox || !innerRef.current) return;
      const { top, bottom, left, width, height } = outerBoundingbox;
      s.originalSize.top = +top;
      s.originalSize.bottom = +bottom;
      s.originalSize.left = +left;
      s.originalSize.width = +width;
      s.originalSize.height = s.outerHeight ?? +height;
      /** switch to open, wait for React to render to portal */
      s.isOpening = true;
      yield tick();
      const onComplete = action(() => { 
        s.isOpening = false; s.isOpen = true; 
        disableScroll();
      })
      if (outerRef.current) outerRef.current.style.height = height + 'px';
      const from: gsap.TweenVars = {
        position: 'fixed', top, left, width, height,
        transition: 0,
      }
      const to: gsap.TweenVars = {
        top: s.openedTop, 
        left: s.openedleft,
        width: s.openedWidth, 
        height: s.openedHeight,
        transition: 0, rotationY: s.isFlipCard ? 180 : undefined,
        ease: s.isFlipCard ? Power2.easeOut : Back.easeInOut,
        onComplete,
        duration: s.disableAnimation ? 0.05 : (s.isFlipCard ? .62 : .5),
      }
      when(
        () => Boolean(innerRef.current),
        () => {
          TweenLite.fromTo(innerRef.current, from, to);
        }
      )
    }),

    animateClose: action(() => {
      if (!s.isOpen) return;
      s.isClosing = true;
      const innerBoundingBox = innerRef.current?.getBoundingClientRect();
      if (!innerBoundingBox) return;
      const { top, left, width, height } = innerBoundingBox;
      const outerBoundingBox = outerRef.current?.getBoundingClientRect();
      if (!outerBoundingBox || !innerRef.current) return;
      const { top: oTop, left: oLeft } = outerBoundingBox;
      const onComplete = () => {
        flow(function* () {
          TweenLite.set(innerRef.current, { position: 'relative' })
          s.isOpen = false; s.isClosing = false;
          scrollElementTo({ el: sideARef.current, top: 0, left: 0 });
          enableScroll();
          yield tick(100);
          if (outerRef.current) {
            if (s.outerHeight) outerRef.current.style.height = s.outerHeight + 'px';
            else outerRef.current.style.removeProperty('height');
          }
        })()
      }
      const from: gsap.TweenVars = { 
        position: 'fixed', 
        top, left, width, height, 
        transition: 0, 
        // rotationY: 180,
      }
      const to: gsap.TweenVars = {
        top: oTop, left: oLeft,
        width: s.originalSize.width, height: s.originalSize.height,
        transition: 0, rotationY: 0,
        ease: s.isFlipCard ? Power2.easeOut : Back.easeInOut, onComplete,
        duration: s.disableAnimation ? 0.05 : (s.isFlipCard ? .75 : .5),
      }
      when(
        () => Boolean(innerRef.current),
        () => TweenLite.fromTo(innerRef.current, from, to)
      )
    }),

    animateResize: function () {
      const to: gsap.TweenVars = {
        top: s.openedTop,
        left: s.openedleft,
        width: s.openedWidth, height: s.openedHeight,
        ease: Power2.easeOut,
        duration: s.disableAnimation ? 0 : .38,
      }
      when(
        () => Boolean(innerRef.current),
        () => {
          TweenLite.set(innerRef.current, { top: 'auto', bottom: 0 })
          TweenLite.to(innerRef.current, to)
        }
      )
    },

  }), {
    hasSideARenderable: Boolean(props.sideARenderable),
    hasSideBRenderable: Boolean(props.sideBRenderable),
  });

  useOnMount(() => {
    if (p.autoOpen) {
      runAfter(s.open, 1000);
    }
    const d = makeDisposerController();
    d.add(multiExpressionReaction(
      [
        () => UI.appWidth,
        () => UI.appHeight,
      ],
      () => {
        if (s.isOpen || s.isOpening) {
          s.animateResize();
        }
      }
    ))
    d.add(() => UI.deregisterOpenedUICard(s.id));
    if (p.shouldProgrammaticallyClose) {
      d.add(reaction(() => p.shouldProgrammaticallyClose?.(), value => {
        if (value && (s.isOpen || s.isOpening)) s.close();
      }))
    }
    return d.disposer
  });

  const handleElementClick = () => {
    if (p.static) return;
    if (!props.sideARenderable) s.animateOpen();
  }

  const UICardInner = () => <Observer children={() => (
    <div 
      {...s.parentDataAttributes}
      className={s.innerClassName} 
      data-class={p.className}
      style={s.innerStyle}
      data-animation={p.animation ?? 'expand'}
      ref={innerRef}
    >
      <div className={s.sideAClassName} style={s.sideAStyles} ref={sideARef}>
        <div className="UICardSideAInner">
          { props.children }
          { props.sideARenderable && renderRenderable(props.sideARenderable, s.innerProps) }
        </div>
      </div>
      {
        s.shouldShowSideB && <div className="UICardSideB">
          <div className="UICardSideBInner" style={s.sideBInnerStyle} >
            { props.sideBRenderable ? renderRenderable(props.sideBRenderable, s.innerProps) : <p>UI Card Inner (Empty)</p> }
          </div>
        </div>
      }
      { s.shouldShowDefaultCloseButton && <BaseButton className="UICardCloseButton subtle" icon="close" iconVariant="filled" size="sm" appearance="icon" rounded onClick={s.close} /> }
    </div>
  )} />

  return <Observer children={() => (
    <div
      className={joinClassName(
        'UICardOuter', 
        p.className ? p.className + 'Outer' : '',
        p.cardRatio && 'keepRatio'
      )}
      data-class={p.className}
      data-name={p.name ?? p.className}
      onClick={handleElementClick}
      ref={outerRef}
      style={{ height: s.outerHeight }}
    >
      { s.shouldRenderInPortal ? UI.PORTAL.render(<UICardInner />) : <UICardInner />}
    </div>
  )} />
}

export default UICard;
// export default React.memo(UICard) as <T extends object = object>(props: React.PropsWithChildren<UICardProps<T>>) => JSX.Element;