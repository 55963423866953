import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useControllers } from '../../hooks/useRootController.hook';
import { warnOutdatedBrowser } from '../../utils/browsers.utils';
import { useStore } from '../../utils/mobx.utils';
import BaseIcon from '../BaseIcon/BaseIcon';
import ClickOrTap from '../ClickOrTap/ClickOrTap';
import PseudoLink from '../PseudoLink/PseudoLink';
import './BrowserSeverelyOutdatedNotice.scss';

type BrowserSeverelyOutdatedNoticeProps = {}

const BrowserSeverelyOutdatedNotice: React.FC<BrowserSeverelyOutdatedNoticeProps> = props => {
  const { UI } = useControllers();
  const s = useStore(() => ({
    dismissed: false,
  }))
  const handleClick = () => {
    warnOutdatedBrowser(UI);
  }
  const dismiss = action(() => {
    s.dismissed = true;
  })
  return <Observer children={() => (
    s.dismissed ? null : <div className="BrowserSeverelyOutdatedNotice" onClick={handleClick}>
      <p><BaseIcon icon="warning" /> Your browser is a little outdated and your experience might be compromised. <PseudoLink inheritColor><ClickOrTap capitalized /> here to see our recommendations,</PseudoLink> or <PseudoLink onClick={dismiss} inheritColor><ClickOrTap /> here to dismiss this notice</PseudoLink>.</p>
    </div>
  )} />
}

export default BrowserSeverelyOutdatedNotice;