import { action, reaction, runInAction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useCreateResizeQueryWithRef } from '../../base/hooks/useCreateResizeQueryWithRef.hook';
import { ChatMediator } from '../../base/mediators/chat.mediator';
import { debounce } from '../../base/utils/debounce.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { scrollElementTo } from '../../base/utils/scrollElementTo.utils';
import { HHmm, isToday } from '../../base/utils/time.utils';
import ChatMessageList from './ChatMessageList';
import './ChatWindowContent.scss';
import ChatWindowTypingIndicator from './ChatWindowTypingIndicator';
import ChatWindowVideoFrame from './ChatWindowVideoFrame';

interface ChatWindowContentProps {
  chat: ChatMediator;
}

function ChatWindowContent(props: React.PropsWithChildren<ChatWindowContentProps>) {

  const { ref: containerRef, query } = useCreateResizeQueryWithRef();
  const innerRef = useRef<HTMLDivElement>(null);

  const p = useProps(props);

  const s = useStore(() => ({
    get chatHasEnded() {
      return !!p.chat.thread.timeEnded;
    },
    get messages() {
      return p.chat.messages;
    },
    get participants() {
      return p.chat.participants;
    },
    get messagesGroupedByParticipants() {
      return p.chat.messagesGroupedByParticipants.filter(g => !!g.participant);
    },
    scrollToY(value: number) {
      scrollElementTo({el: containerRef.current, top: value});
    },
    scrollToBottom() {
      const height = innerRef.current?.clientHeight;
      if (height) s.scrollToY(height);
    },
    get shouldShowVideoFrame() {
      return Boolean(p.chat.thread.timeVideoStarted && !s.chatHasEnded && p.chat.selfParticipant && !p.chat.selfParticipant.timeDeclinedVideoCall);
    },
    get shouldShowDeclinedCallWarning() {
      return Boolean(p.chat.thread.timeVideoStarted && !p.chat.thread.timeVideoEnded && !s.chatHasEnded && p.chat.selfParticipant && p.chat.selfParticipant.timeDeclinedVideoCall);
    },
    get startedToday() {
      return isToday(p.chat.timeStarted);
    },
    scrollHeight: query.scrollHeight,
    clientHeight: query.height,
    get scrolledNearBottom() {
      return Math.abs(s.clientHeight + s.scrollTop - s.scrollHeight) < 20;
    },
    scrollTop: 0,
  }));

  useOnMount(() => {
    s.scrollToBottom();
    runInAction(() => {
      s.scrollHeight = query.scrollHeight;
      s.clientHeight = query.height;
      s.scrollTop = containerRef.current?.scrollTop ?? 0;
    })
    query.onResize(debounce(
      action(() => {
        s.scrollToBottom();
        s.scrollHeight = query.scrollHeight
        s.clientHeight = query.height
      }
    ), { timeout: 300, fireImmediately: true }))
    return reaction(
      () => s.messages.length,
      () => s.scrollToBottom()
    )
  });

  const handleScroll = action((e: React.UIEvent<HTMLDivElement>) => {
    s.scrollTop = e.currentTarget.scrollTop;
  })

  return <Observer children={() => (
    <div className="ChatWindowContent">
      {
        s.shouldShowVideoFrame && <ChatWindowVideoFrame chat={p.chat} />
      }
      {
        s.shouldShowDeclinedCallWarning && <div className="ChatWindowContentDeclinedCallWarning">
          <p>There is a video call ongoing in this session, but you have previously declined this call.</p>
        </div>
      }
      <div className="ChatWindowContentScrollFrame" ref={containerRef} onScroll={handleScroll}>
        <div className="ChatWindowContentInner" ref={innerRef}>
          <p className="ChatWindowContentSeparator"><span>{p.chat.type === 'support-group' ? 'Group Session' : p.chat.type === 'counselling-session' ? 'Session' : 'Chat'} started {s.startedToday ? 'at' : 'on'} <DateRenderer value={p.chat.timeStarted} format={s.startedToday ? HHmm : 'LL'}/></span></p>
          {
            p.chat.videoChatRoom && <div className="ChatWindowContentTipBlock">
              <h3>Video Chat Tips</h3>
              <p>Welcome to a video chat session! Please note that you can only connect to the chat from one single browser/device.</p>
            </div>
          }
          {
            s.messagesGroupedByParticipants.map(group => {
              return <ChatMessageList key={group.id} chat={p.chat} author={group.participant} messages={group.messages} />
            })
          }
        </div>
      </div>
      {!p.chat.hasEnded && <ChatWindowTypingIndicator chat={p.chat} />}
    </div>
  )} />
}

export default ChatWindowContent;