import React from 'react';
import ColorTag from './ColorTag';

interface ColorTagYoungPeopleProps {
  useAbbr?: boolean
}

const ColorTagYoungPeople: React.FC<ColorTagYoungPeopleProps> = props => {
  return <ColorTag className="ColorTagYoungPeople" color="yellow">
    {props.useAbbr ? 'YP' : 'Young People'}
  </ColorTag>
}

export default ColorTagYoungPeople;