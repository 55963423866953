import { action, observable } from "mobx";
import moment from "moment-timezone";
import { CountryCode } from "../base/constants/countries.constants";
import { NoOp } from "../base/utils/functions.utils";
import { YYYYMMDDHHmmss } from "../base/utils/time.utils";
import { TopLevelModuleName } from "../constants/topLevelModuleName.enum";
import { AppInfo } from "./common/appInfo";
import { CLOCK } from "./common/clock.controller";
import { makeControllerBase, makeRootControllerChildInitFn } from "./_root.controller";

/**
 * "CommonController" includes shared basic information about the app, the location, the current time, online status and more.
 */
export type CommonController = ReturnType<typeof makeCommonController>;

export const makeCommonController = () => {

  const _timeAppLoadedLocal = moment().format(YYYYMMDDHHmmss);
  const _timeAppLoadedUTC = moment().utc().format(YYYYMMDDHHmmss);

  const c = observable({
    ...makeControllerBase('COMMON'),
    app: AppInfo,
    get countryCode(): CountryCode {
      return c.ROOT!.children.ANALYTICS.detected.countryCode;
    },
    get city(): string {
      return c.ROOT!.children.ANALYTICS.detected.city;
    },
    timezone: '',
    currency: 'EUR',
    locale: '',
    CLOCK,
    topLevelModuleName: '' as TopLevelModuleName || '',
    get currencySymbol(): string {
      return new Intl.NumberFormat(c.locale, { style: 'currency', currency: c.currency }).format(0).replace(/[\d|.]/g, '');
    },
    get now() {
      return CLOCK.localNow;
    },
    online: true,
    reset: NoOp,
    get timeAppLoadedLocal() {
      return _timeAppLoadedLocal;
    },
    get timeAppLoadedUtc() {
      return _timeAppLoadedUTC;
    },
    preferences: {
      disableToasts: false,
    },
    disableToasts: action(() => {
      c.preferences.disableToasts = true;
    }),
    enableToasts: action(() => {
      c.preferences.disableToasts = false;
    }),
  })

  const _initOnlineStatusWatcher = () => {
    window.addEventListener('online', action(() => {
      console.log('%cDEVICE ONLINE', 'color: #77F117');
      c.online = true;
    }))
    window.addEventListener('offline', action(() => {
      console.log('%cDEVICE OFFLINE', 'color: #F11717');
      c.online = false;
    }))
  };

  const guessTimezoneAndLocale = () => {
    c.timezone = moment.tz.guess();
    c.locale = moment.locale();
  }

  c.init = makeRootControllerChildInitFn(
    c,
    action(() => {
      _initOnlineStatusWatcher();
      guessTimezoneAndLocale();
      c.ready = true;
    })
  )

  return c;

}