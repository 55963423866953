import { Observer } from 'mobx-react-lite';
import React from 'react';
import joinClassName from '../../utils/className.utils';
import './PseudoLink.scss';

type PseudoLinkProps = {
  className?: string,
  onClick?: () => void,
  inheritColor?: boolean,
  name?: string
  title?: string
  dataCy?: string
}

const PseudoLink: React.FC<PseudoLinkProps> = props => {
  return <Observer children={() => (
    <button
      className={joinClassName('PseudoLink', props.className, props.inheritColor && 'inheritColor')} 
      onClick={props.onClick}
      title={props.title}
      name={props.name}
      data-cy={props.dataCy}
    >
      { props.children }
    </button>
  )} />
}

export default PseudoLink;