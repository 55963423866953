import React from 'react';
import { BaseTableProps } from './BaseTable';
// import './BaseTableColGroup.scss';
import BaseTableCol from './BaseTableCol';

interface BaseTableColGroupProps<EntryType extends object = {}> extends BaseTableProps<EntryType>{}

export function BaseTableColGroup<EntryType extends object = {} >(
  props: React.PropsWithChildren<BaseTableColGroupProps<EntryType>>
) {
  return <colgroup className="BaseTableColGroup">
    {props.appearanceOptions?.showRowIndex && <col></col>}
    {props.columnConfigs.map((col, i) => <BaseTableCol<EntryType>
      {...props}
      columnConfig={col}
      key={[col.keyName, col.label, i].join('_')}
    />)}
  </colgroup>
}

export default BaseTableColGroup;