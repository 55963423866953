import { flow } from "mobx";
import { ReactionEndpoints } from "../base/endpoints/reaction.endpoints";
import { getSnapshot } from "../base/utils/snapshot.utils";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { Reaction } from "../models/makeReaction.model";

export const saveReaction = flow(function * (reaction: Partial<Reaction>, API: APIController) {
  const endpoint = reaction.id ? ReactionEndpoints.own.update : ReactionEndpoints.own.create;
  const url = endpoint(reaction.id!);
  const payload = getSnapshot(reaction);
  const savedReaction = reaction.id ? (
    yield API.patch(url, ModelName.reactions, payload)
  ) : (
    yield API.post(url, ModelName.reactions, payload)
  );
  return savedReaction;
})