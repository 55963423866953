import { Observer } from 'mobx-react-lite';
import React from 'react';
import AppPage from '../../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../../base/components/AppPageHeader/AppPageHeader';
import BackButton from '../../../../../base/components/BackButton/BackButton';
import BaseButton from '../../../../../base/components/BaseButton/BaseButton';
import BaseSpacer from '../../../../../base/components/BaseSpacer/BaseSpacer';
import UIBlock from '../../../../../base/components/UIBlock/UIBlock';
import { useControllers } from '../../../../../base/hooks/useRootController.hook';
import SupportGroupsIndex from '../../../../../components/SupportGroupsIndex/SupportGroupsIndex';
import ClientSupportGroupFinder from '../../../../Client/_components/ClientSupportGroupFinder/ClientSupportGroupFinder';
import './AccountPageSupportGroups.scss';

type AccountPageSupportGroupsProps = {}

const AccountPageSupportGroups: React.FC<AccountPageSupportGroupsProps> = props => {

  const { UI } = useControllers();

  const findNewGroups = () => {
    UI.OVERLAY.present({
      component: <ClientSupportGroupFinder showCloseButton />,
    })
  }

  return <Observer children={() => (
    <AppPage className="AccountPageSupportGroups"
      accommodateTitleBarSpace
      color="blueGreen"
    >
      <AppPageHeader
        title="Support Group History"
        afterTitle="View all the support groups you have joined"
        startSlot={<BackButton destination="up" />}
      />
      <AppPageContent>
        <UIBlock padded>
          <BaseButton
            className="ExploreGroupRecommendationsFindMoreButton"
            icon="search"
            fullWidth
            size="lg"
            onClick={findNewGroups}
            backgroundImage="radial-gradient(100.09% 121.05% at 97.37% 121.05%, rgba(139, 209, 128, 0.7) 0%, #10A78C 100%)"
            label="Find More Support Groups"
            dataCy="FindNewSupportGroupButton"
          />
          <BaseSpacer size="sm"/>
          <SupportGroupsIndex
            defaultMode="list"
            allowedViewModes={['list']}
            own
          />
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
}

export default AccountPageSupportGroups;