import deepmerge from "deepmerge";
import { Nillable } from "../@types/utilities.types";
import { KnownURLParamSetInput, mapParamSetToString } from "./urlParams.utils";

/** 
 * construct GET endpoint factories with given url base and ability to accept URL params 
 * */
export function makeApiEndpointConfig<T extends Partial<KnownURLParamSetInput> = Partial<KnownURLParamSetInput>>(
  baseUrl: string = '/',
  defaultParams?: T,
  options?: {
    allowInfinity?: boolean,
  }
) {
  return (params?: Nillable<T>) => {
    const paramString = mapParamSetToString((params || {}) as T, defaultParams, options);
    return [baseUrl, paramString].filter(i=>i).join('?');
  }
}
/** 
 *  alias for normal getMany endpoints with support for params,
 *  automatically appends filter[id]=1 so it retrieves one single resource
 */
export const makeApiGetEndpointConfig = <T extends Partial<KnownURLParamSetInput> = Partial<KnownURLParamSetInput>>(
  baseUrl: string = '/',
  defaultParams?: T,
  options?: {
    allowInfinity?: boolean,
  }
) => (id: string, params: Partial<T> = {}) => makeApiEndpointConfig(
  baseUrl,
  defaultParams,
  options,
)(deepmerge(params, { filter: { id }}) as unknown as T);