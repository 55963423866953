import { Nullable, StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { AlwaysTrueFn, cond } from "../base/utils/ramdaEquivalents.utils";
import { ApiModelName, PseudoApiModelName } from "../constants/ApiModels.enum";
import { ModelName } from "../constants/modelNames.enum";
import { hasTimestamps } from "../traits/hasTimestamps.trait";
import { AssignmentPayment } from "./makeAssignment.model";
import { CounsellingSession } from "./makeCounsellingSession.model";
import { User } from "./makeUser.model";

export type PaymentSnapshot = ReturnType<typeof makePaymentSnapshotBase>;
export type PaymentTargetModel = CounsellingSession;
export type PaymentRelationships<T extends PaymentTargetModel | object = object> = {
  user?: User,
  model?: T,
  assignment?: AssignmentPayment
}

export type PayableModel = CounsellingSession;
export type PayableModelName = ApiModelName.COUNSELLING_SESSION | PseudoApiModelName.DONATION | ApiModelName.SUBSCRIPTION;
export type PaymentExtendedProperties = {};
export type Payment = StandardModel<PaymentSnapshot, PaymentRelationships, PaymentExtendedProperties>;

export const makePaymentSnapshotBase = () => ({
  id: '',
  amount: 0,
  email: '',
  stripeId: '',
  userId: '',
  assignmentId: '',
  modelType: '' as PayableModelName,
  modelId: '',
  message: '' as Nullable<string>,
  paymentMethodId: '',
  ...hasTimestamps(),
})

export const makePayment = createStandardModelFactory<Payment, PaymentRelationships, PaymentExtendedProperties>({
  name: ModelName.payments,
  snapshotFactory: makePaymentSnapshotBase,
  relationshipsSchemaFactory: s => ({
    user: ModelName.users,
    assignment: ModelName.assignments,
    model: cond([
      [() => s?.modelType === ApiModelName.COUNSELLING_SESSION, () => ModelName.counsellingSessions],
      [AlwaysTrueFn, () => 'skip']
    ])()
  }),
})