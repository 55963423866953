import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { AnyObject, FormControl, Nillable } from '../../@types';
import { ObservableRef } from '../../hooks/useObservableRef.hook';
import { useControllers } from '../../hooks/useRootController.hook';
import { useProps, useStore } from '../../utils/mobx.utils';
import { first } from '../../utils/ramdaEquivalents.utils';
import { countriesByDialCodes, CountryDialCodeDef } from '../../utils/tel.utils';
import BaseIcon from '../BaseIcon/BaseIcon';
import OverlayCloseButton from '../OverlayCloseButton/OverlayCloseButton';
import SearchBar from '../SearchBar/SearchBar';
import './CountryDialCodeSelector.scss';

type CountryPopupSelectorProps<T extends AnyObject> = FormControl<T> & {
  doNotShowFlag?: boolean,
  showCountryName?: boolean,
  showDialCode?: boolean,
  dataCy?: string,
  disabled?: boolean,
  onUserSelection?: (dialCode: string | null) => void;
  innerRef?: ObservableRef<HTMLButtonElement>
}

const CountryDialCodeSelector = <T extends AnyObject = AnyObject>(props: React.PropsWithChildren<CountryPopupSelectorProps<T>>) => {
  const { UI } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    get dialCode() {
      return p.form[p.field] as Nillable<string>;
    },
    get countryDef() {
      return s.dialCode ? countriesByDialCodes.find(c => c.dialCode === s.dialCode) : null;
    },
    query: '',
    get list() {
      if (s.query) return countriesByDialCodes.filter(c => c.searchString.includes(s.query));
      return countriesByDialCodes;
    },
    selectFirst: () => {
      if (p.disabled) return;
      const firstResult = first(s.list);
      const dialCode = firstResult?.dialCode;
      dialCode && p.onUserSelection?.(dialCode);
      UI.OVERLAY.dismiss();
    }
  }))
  const selectCountry = (c: CountryDialCodeDef) => action(() => {
    if (p.disabled) return;
    p.form[p.field] = c.dialCode as any;
    props.onUserSelection?.(c.dialCode);
    UI.OVERLAY.dismiss('CountryPicker');
  })
  const pickACountry = () => {
    if (p.disabled) return;
    UI.OVERLAY.present({
      name: "CountryPicker",
      component: <Observer children={() => <div className="CountryPopupSelectorDialogContent">
        <header>
          <div className="CountryPopupSelectorDialogContentHeaderInner">
            <SearchBar
              form={s}
              field="query"
              placeholder="Look for a country/region…"
              autoFocus
              onEnter={p.disabled ? undefined : s.selectFirst}
            />
          </div>
          <OverlayCloseButton />
        </header>
        <ul className="CountryPopupSelectorDialogCountryList">
          { s.list.map((c, i) => <li key={c.dialCode + '_' + i}>
            {c.countries.map(country => <button
              onClick={selectCountry(c)}
              className="CountryPopupSelectorDialogCountryButton"
              key={country.name}
            >
              <span>{country.flag}</span>
              <strong>+{c.dialCode}</strong>
              <span>{country.name}</span>
            </button>)}
          </li>)}
        </ul>
      </div>} />,
      appearance: 'card',
    })
  }

  useHotkeys('escape', () => {
    UI.OVERLAY.dismiss();
  });

  return <Observer children={() => <>
    <button 
      className="CountryDialCodeSelector"
      title="Select Country"
      name="country-selector"
      data-cy={p.dataCy}
      onClick={p.disabled ? undefined : pickACountry}
      ref={p.innerRef}
      disabled={p.disabled}
    >
      {!p.doNotShowFlag && <span className="CountryPopupSelectorFlag">{
        s.countryDef?.countries.length === 1 && s.countryDef?.countries[0]?.flag
      }</span>} 
      {p.showDialCode && s.countryDef?.dialCode && <span className="CountryPopupSelectorDialCode">+{s.countryDef.dialCode}</span>}
      {p.showCountryName && <span className="CountryPopupSelectorCountryName">{s.countryDef?.countries[0]?.name }</span>}
      <BaseIcon icon="dropdown-arrow" variant="filled" size="1.2rem" />
    </button>  
  </>} />
}

export default CountryDialCodeSelector;