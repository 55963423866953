import { Observer } from 'mobx-react-lite';
import React from 'react';
import AppPage from '../../../../../base/components/AppPage/AppPage';
import AppPageHeader from '../../../../../base/components/AppPageHeader/AppPageHeader';
import BackButton from '../../../../../base/components/BackButton/BackButton';
import { useControllers } from '../../../../../base/hooks/useRootController.hook';
import ContactFormEditor from '../../../../../components/ContactFormEditor/ContactFormEditor';

type AccountPageContactFormProps = {
}

const AccountPageContactForm: React.FC<AccountPageContactFormProps> = props => {

	const { AUTH } = useControllers();

	return <Observer children={() => (
		<AppPage
			className="AccountPageContactForm"
			accommodateTitleBarSpace
		>
			<AppPageHeader
				title="Contact Us"
				afterTitle="Fill in the form below to submit your enquiry and we will be in touch as soon as possible."
				startSlot={<BackButton destination="up" />}
			/>
			{AUTH.currentUser && <ContactFormEditor />}
		</AppPage>
	)} />
}

export default AccountPageContactForm;