import { runInAction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { ContextColor } from '../../constants/color.enum';
import { getSize, isSize, Size } from '../../constants/size.enum';
import joinClassName from '../../utils/className.utils';
import { checkIfShouldInvertStyle, getContextColorStyle } from '../../utils/colors.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import './ShadedBlock.scss';

interface ShadedBlockProps {
  className?: string,
  color?: string,
  spaceChildren?: boolean;
  borderWidth?: string | number;
  rounded?: boolean;
  colorIntensity?: 'normal' | 'strong',
  dataCy?: string;
  padding?: number | string | Size,
  onClick?: () => void;
}

const ShadedBlock: React.FC<ShadedBlockProps> = props => {

  const ref = useRef<HTMLDivElement>(null);

  const p = useProps(props);

  const s = useStore(() => ({
    shouldInvertStyle: false,
    get interactable() {
      return (p.onClick);
    },
    get colorStyle() {
      return getContextColorStyle(ContextColor.Primary, p.color);
    },
    get innerStyle() {
      return {
        borderWidth: p.borderWidth,
        padding: isSize(p.padding) ? getSize(p.padding) : p.padding,
      }
    },
  }));

  useEffect(() => {
    runInAction(() => {
      s.shouldInvertStyle = checkIfShouldInvertStyle(ref);
    })
  });

  return <Observer children={() => (
    <div
      className={
        joinClassName(
          'ShadedBlock',
          p.className,
          p.spaceChildren && 'spaceChildren',
          s.shouldInvertStyle && 'inverted',
          p.rounded && 'rounded',
          s.interactable && 'interactable',
          p.colorIntensity === 'strong' && 'intenseColor'
        )
      }
      style={s.colorStyle}
      ref={ref}
      data-cy={p.dataCy}
      onClick={p.onClick}
    >
      <div className="ShadedBlockInner" style={s.innerStyle}>
        {props.children}
      </div>
    </div>
  )} />
}

export default ShadedBlock;