import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Form } from '../../mediators/form.mediator';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import ContactTypeSelector, { ContactTypeSelectorProps } from '../ContactTypeSelector/ContactTypeSelector';
import { useFormContext } from '../FormForm/Form.context';
// import './FormContactTypeSelector.scss';

type FormContactTypeSelectorProps<T extends object = object> = Omit<ContactTypeSelectorProps<T>, 'form'> & {
  form?: Form<T>,
}

function FormContactTypeSelector<T extends object = object>(
  props: React.PropsWithChildren<FormContactTypeSelectorProps<T>>
) {

  const context = useFormContext<T>();
  const { form } = context;
  const p = useProps(props);
  const s = useStore(() => ({
    get form() {
      return p.form ?? form
    },
  }));

  return <Observer children={() => (
    <ContactTypeSelector {...p}
      className={joinClassName('FormContactTypeSelector', p.className)}
      form={s.form.fields[p.field]}
      field="value"
      name={p.name ?? p.field}
      disabled={p.disabled || context.disabled}
    />
  )} />
}

export default FormContactTypeSelector;