import { Observer } from 'mobx-react-lite';
import React from 'react';
import ExampleImage from '../../assets/images/pexels-photo-4557402.jpeg';
import tick from '../../base/utils/waiters.utils';
import { getWordPressLink } from '../../env';
import ServiceCard from '../ServiceCard/ServiceCard';
// import './ServiceCardCouplesCounselling.scss';

interface ServiceCardCouplesCounsellingProps {
  cardRatio?: number,
}

const ServiceCardCouplesCounselling: React.FC<ServiceCardCouplesCounsellingProps> = props => {

  const automaticallyRedirectToCounsellingApplicationPage = async () => {
    await tick(1000);
    document.querySelector<HTMLAnchorElement>('.AppNavTabInner[data-identifier="counselling"]')?.click();
  }

  return <Observer children={() => (
    <ServiceCard
      className="ServiceCardCouplesCounselling"
      title={<>Couples<br />Counselling</>}
      stringTitle="Couples Counselling"
      sideABackgroundColor="#DF9180"
      sideABackgroundImage={`url(${ExampleImage})`}
      src={getWordPressLink('/services/couples-counselling')}
      listenForWindowMessage="couples-counselling"
      onReceiveWindowMessage={automaticallyRedirectToCounsellingApplicationPage}
      cardRatio={props.cardRatio}
      // autoOpen
    />
  )} />
}

export default ServiceCardCouplesCounselling;