import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ChatMediator } from '../../base/mediators/chat.mediator';
import { useStore } from '../../base/utils/mobx.utils';
import { autoPluralize } from '../../base/utils/string.utils';
import { User } from '../../models/makeUser.model';
import './ChatWindowTypingIndicator.scss';

interface ChatWindowTypingIndicatorProps {
  chat: ChatMediator
}

function ChatWindowTypingIndicator(props: React.PropsWithChildren<ChatWindowTypingIndicatorProps>) {
  const s = useStore(() => ({
    get typingUserIds() {
      return props.chat.typingUserIds;
    },
    get typingUsers() {
      return props.chat.typingUserIds.map(id => props.chat.participants.find(p => p.userId === id)?.user).filter(i=>i) as User[];
    },
    get typingUserNames() {
      return s.typingUsers.map(u => u.username);
    },
    get displayText() {
      if (s.typingUserIds.length === 0) return '';
      if (s.typingUserIds.length < 4) {
        return [
          s.typingUserNames.map((n, i, a) => [n, i === a.length - 2 ? ' and ' : (i === a.length - 1 ? '' : ', ')]).flat(1).join(' '),
          s.typingUserIds.length === 1 ? 'is' : 'are',
          'typing...'
        ].join(' ');
      } else {
        return autoPluralize(s.typingUserIds.length, 'people', 'people', 'people') + ' are typing...';
      }
    }
  }));
  return <Observer children={() => (
    <div className="ChatWindowTypingIndicator" data-on={!!s.displayText}>
      { s.displayText}
    </div>
  )} />
}

export default ChatWindowTypingIndicator;