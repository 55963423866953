import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Nillable } from '../../../../base/@types';
import './DashBlockCountBadge.scss';

type DashBlockCountBadgeProps = {
  count: Nillable<number>,
}

const DashBlockCountBadge: React.FC<DashBlockCountBadgeProps> = props => {
  return <Observer children={() => (
    (props.count && props.count > 0) ? <div className="DashBlockCountBadge">
      { props.count }
    </div> : null
  )} />
}

export default DashBlockCountBadge;