import React from "react";
import { makeIconDef } from "./makeIconDef";

export const AvailabilityIconDef = {
	availability: makeIconDef('availability', {
		regular: () => <>
			<path fillRule="evenodd" clipRule="evenodd" d="M13.6493 19.8298C13.1171 19.9413 12.5654 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 12.5654 19.9413 13.1171 19.8298 13.6493C20.1458 13.8409 20.4363 14.0703 20.6951 14.3312C20.894 13.5877 21 12.8062 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C12.8062 21 13.5877 20.894 14.3312 20.6951C14.0703 20.4363 13.8409 20.1458 13.6493 19.8298Z" fill="currentColor" />
			<path d="M12 7.5V12L8 14.5" stroke="currentColor" strokeLinecap="round" />
			<path fillRule="evenodd" clipRule="evenodd" d="M17.5 22C19.9853 22 22 19.9853 22 17.5C22 15.0147 19.9853 13 17.5 13C15.0147 13 13 15.0147 13 17.5C13 19.9853 15.0147 22 17.5 22ZM17.2911 19.5411L20.6661 16.1661L19.9589 15.4589L16.9375 18.4804L15.6036 17.1464L14.8964 17.8536L16.5839 19.5411L16.9375 19.8946L17.2911 19.5411Z" fill="currentColor" />
			<path fillRule="evenodd" clipRule="evenodd" d="M12 3C12.0836 3 12.1669 3.00114 12.25 3.00341V5.64706C12.25 5.78513 12.1381 5.89706 12 5.89706C11.8619 5.89706 11.75 5.78513 11.75 5.64706V3.00341C11.8331 3.00114 11.9164 3 12 3ZM7.71736 4.08229C7.57059 4.16185 7.42628 4.24536 7.28458 4.3327L8.16628 5.85984C8.23531 5.97942 8.38821 6.02039 8.50778 5.95135C8.62736 5.88231 8.66833 5.72942 8.59929 5.60984L7.71736 4.08229ZM4.33268 7.28461C4.24535 7.42631 4.16183 7.57062 4.08228 7.71739L5.60983 8.59932C5.72941 8.66836 5.8823 8.62739 5.95134 8.50781C6.02038 8.38824 5.97941 8.23534 5.85983 8.16631L4.33268 7.28461ZM3 12C3 12.0836 3.00114 12.1669 3.00341 12.25H5.64706C5.78513 12.25 5.89706 12.1381 5.89706 12C5.89706 11.8619 5.78513 11.75 5.64706 11.75H3.00341C3.00114 11.8331 3 11.9164 3 12ZM4.08228 16.2826C4.16184 16.4294 4.24535 16.5737 4.33268 16.7154L5.85981 15.8337C5.97939 15.7647 6.02036 15.6118 5.95132 15.4922C5.88229 15.3726 5.72939 15.3317 5.60981 15.4007L4.08228 16.2826ZM7.2846 19.6673C7.4263 19.7547 7.57061 19.8382 7.71738 19.9177L8.59931 18.3902C8.66835 18.2706 8.62738 18.1177 8.5078 18.0487C8.38823 17.9796 8.23533 18.0206 8.1663 18.1402L7.2846 19.6673ZM12 21C12.0836 21 12.1669 20.9989 12.25 20.9966V18.3529C12.25 18.2149 12.1381 18.1029 12 18.1029C11.8619 18.1029 11.75 18.2149 11.75 18.3529V20.9966C11.8331 20.9989 11.9164 21 12 21ZM21 12C21 11.9164 20.9989 11.8331 20.9966 11.75H18.3529C18.2149 11.75 18.1029 11.8619 18.1029 12C18.1029 12.1381 18.2149 12.25 18.3529 12.25H20.9966C20.9989 12.1669 21 12.0836 21 12ZM19.9177 7.71738C19.8382 7.57061 19.7546 7.4263 19.6673 7.2846L18.1401 8.16631C18.0206 8.23534 17.9796 8.38824 18.0486 8.50781C18.1177 8.62739 18.2706 8.66836 18.3901 8.59932L19.9177 7.71738ZM16.7154 4.33269C16.5737 4.24535 16.4294 4.16184 16.2826 4.08228L15.4007 5.60984C15.3317 5.72942 15.3726 5.88231 15.4922 5.95135C15.6118 6.02039 15.7647 5.97942 15.8337 5.85984L16.7154 4.33269Z" fill="currentColor" />
		</>
	})
}