import { Observer } from 'mobx-react-lite';
import React from 'react';
import SwiperContainer from '../../../../../base/components/SwiperContainer/SwiperContainer';
import SwiperSlide from '../../../../../base/components/SwiperContainer/SwiperSlide';
import { useControllers } from '../../../../../base/hooks/useRootController.hook';
import { useStore } from '../../../../../base/utils/mobx.utils';
import ServiceCardCouplesCounselling from '../../../../../components/ServiceCardCouplesCounselling/ServiceCardCouplesCounselling';
import ServiceCardOneToOneCounselling from '../../../../../components/ServiceCardOneToOneCounselling/ServiceCardOneToOneCounselling';
import ServiceCardSupportGroups from '../../../../../components/ServiceCardSupportGroups/ServiceCardSupportGroups';
import ServiceCardYoungPeopleCounselling from '../../../../../components/ServiceCardYoungPeopleCounselling/ServiceCardYoungPeopleCounselling';
import { CounsellingType } from '../../../../../constants/counsellingTypes.descriptors';
import './ExploreServices.scss';

interface ExploreServicesProps {}

const ExploreServices: React.FC<ExploreServicesProps> = props => {

  const { UI, AUTH, COUNSELLING } = useControllers();
  const s = useStore(() => ({
    get userAge() {
      return AUTH.currentUser?.age ?? null;
    },
    get shouldShowOneToOne() {
      return s.userAge && s.userAge > 18 && !COUNSELLING.ongoingApplications.find(a => a.type === CounsellingType.OneToOne);
    },
    get shouldShowCouples() {
      return s.userAge && s.userAge > 28 && !COUNSELLING.ongoingApplications.find(a => a.type === CounsellingType.Couples);
    },
    get shouldShowYoungPeopleCounselling() {
      return s.userAge && (s.userAge < 18 || s.userAge > 38) && !COUNSELLING.ongoingApplications.find(a => a.type === CounsellingType.YoungPeople1214 || a.type === CounsellingType.YoungPeople1517);
    },
    get counsellingOptionsCount() {
      return [s.shouldShowOneToOne, s.shouldShowCouples, s.shouldShowYoungPeopleCounselling].filter(i => i).length;
    },
    get shouldShowSupportGroups() {
      return s.counsellingOptionsCount < 3;
    },
    get shouldHideAll() {
      return s.counsellingOptionsCount === 0;
    },
  }))

  return <Observer children={() => (
    s.shouldHideAll ? null : <div className="ExploreServices">
      {
        UI.onlyPhones && <SwiperContainer
          slidesPerView={2.38}
          gap={14}
          slides={<>
            {s.shouldShowOneToOne && <SwiperSlide>
              <ServiceCardOneToOneCounselling />
            </SwiperSlide>}
            {s.shouldShowCouples && <SwiperSlide>
              <ServiceCardCouplesCounselling />
            </SwiperSlide> }
            {s.shouldShowSupportGroups && <SwiperSlide>
              <ServiceCardSupportGroups />
            </SwiperSlide>}
            {s.shouldShowYoungPeopleCounselling && <SwiperSlide>
              <ServiceCardYoungPeopleCounselling />
            </SwiperSlide>}
          </>}
        />
      }
      {
        UI.fromTablet && <div className="ExploreServicesGrid">
          {s.shouldShowOneToOne && <ServiceCardOneToOneCounselling />}
          {s.shouldShowCouples && <ServiceCardCouplesCounselling />}
          {s.shouldShowSupportGroups && <ServiceCardSupportGroups />}
          {s.shouldShowYoungPeopleCounselling && <ServiceCardYoungPeopleCounselling />}
        </div>
      }
    </div>
  )} />
}

export default ExploreServices;