import { Nullable } from "../base/@types";
import { CommentEndpoints } from "../base/endpoints/comment.endpoints";
import { reportError } from "../base/utils/errors.utils";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { Comment } from "../models/makeComment.model";

export function saveComment(
  API: APIController, 
  comment: Partial<Comment>,
) {
  return new Promise<Comment>(async (resolve, reject) => {
    const { id, thoughtId } = comment;
    if (!thoughtId) throw Error('Expecting a thought ID when saving a comment.');
    const url = id ? CommentEndpoints.own.update(id) : CommentEndpoints.own.create();
    const { 
      user,
      timeCreated,
      ...payload
    } = comment;
    try {
      const savedComment: Nullable<Comment> = id ? await API.patch(url, ModelName.comments, payload) : await API.post(url, ModelName.comments, payload);
      if (!savedComment) throw Error('Server returned unexpected response while saving comment.');
      resolve(savedComment);
    } catch(e) {
      reportError(e);
      reject(e);
    }
  })
}