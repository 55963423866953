import { Observer } from 'mobx-react-lite';
import React from 'react';
import joinClassName from '../../utils/className.utils';
import './LinkGroup.scss';

interface LinkGroupProps {
  direction?: 'column' | 'row',
  allCaps?: boolean,
  noUnderscore?: boolean,
}

const LinkGroup: React.FC<LinkGroupProps> = props => {
  return <Observer children={() => (
    <div className={
      joinClassName(
        'LinkGroup',
        props.noUnderscore && 'noUnderscore',
        props.allCaps && 'allCaps',
        props.direction ?? 'row'
      )
    }>
      { props.children}
    </div>
  )} />
}

export default LinkGroup;