import React from "react";
import { makeIconDef } from "./makeIconDef";

export const PhoneIconDef = {
  phone: makeIconDef('phone', {
    regular: () => <path fillRule="evenodd" clipRule="evenodd" d="M20.0001 19.9711L20.0001 17.9346C20.0001 17.9344 20.0001 17.9348 20.0001 17.9346C19.9995 17.9335 19.9975 17.9291 19.9922 17.9237C19.9791 17.9103 19.9569 17.8982 19.9292 17.8962C18.7687 17.8127 17.6218 17.594 16.512 17.2443C16.4856 17.236 16.4604 17.2391 16.4434 17.2467C16.4365 17.2498 16.4331 17.2526 16.4322 17.2535C16.4322 17.2536 16.4323 17.2533 16.4322 17.2535L16.0382 18.2485C15.9084 18.5764 15.6966 18.8562 15.4336 19.0679C16.8773 19.568 18.4094 19.8789 20.0001 19.9711ZM8.83343 16.2656C11.808 18.9953 15.7034 20.7374 20.0001 20.9727C20.5516 21.0029 21.0001 20.5523 21.0001 20L21.0001 17.9346C21.0001 17.3823 20.5518 16.9384 20.0009 16.8988C18.9181 16.8209 17.848 16.6168 16.8125 16.2905C16.2858 16.1246 15.7055 16.3723 15.5022 16.8859L15.1085 17.8804C14.9051 18.394 14.3223 18.6481 13.8216 18.4146C11.8924 17.5146 10.16 16.2631 8.70705 14.7424C8.66186 14.6951 8.61694 14.6475 8.57229 14.5997C7.3556 13.2967 6.34141 11.8022 5.57987 10.1663C5.57886 10.1642 5.57786 10.162 5.57686 10.1598C5.34896 9.66051 5.60354 9.08202 6.11507 8.87995L7.10988 8.48697C7.62353 8.28406 7.87176 7.70401 7.70621 7.17712C7.38185 6.14487 7.17883 5.07832 7.10124 3.99909C7.06164 3.44823 6.61766 2.99994 6.06538 2.99994H3.11726C3.05253 2.99994 3.00001 3.05242 3.00043 3.11715C3.02973 7.71492 4.78284 11.9036 7.64595 15.0695C8.02299 15.4864 8.41928 15.8856 8.83343 16.2656ZM4.92793 8.55373C5.13979 8.29096 5.41973 8.07943 5.74766 7.94989L6.74247 7.55691C6.74267 7.55683 6.74234 7.55697 6.74247 7.55691C6.74333 7.556 6.74667 7.55246 6.74976 7.54554C6.75738 7.52849 6.76051 7.50337 6.75219 7.47689C6.40456 6.37056 6.18697 5.22747 6.10382 4.07079C6.10183 4.0431 6.08968 4.02089 6.07633 4.00783C6.07091 4.00253 6.06705 4.00048 6.06592 3.99995C6.06577 3.99994 6.06614 3.99995 6.06592 3.99995L4.02897 3.99994C4.12096 5.58598 4.43041 7.11371 4.92793 8.55373ZM4.00041 3.11064C4.00041 3.11059 4.00041 3.11068 4.00041 3.11064Z" fill="currentColor" />,
    filled: () => <path fillRule="evenodd" clipRule="evenodd" d="M3.1172 2.99994C3.05246 2.99994 2.99995 3.05242 3.00037 3.11715V3.11715C3.06124 12.6693 10.5626 20.456 20 20.9727C20.5515 21.0029 21 20.5523 21 20V19.5L21 17.9346C21 17.3823 20.5517 16.9384 20.0008 16.8988C18.918 16.8209 17.8479 16.6168 16.8125 16.2905C16.2857 16.1246 15.7055 16.3723 15.5022 16.8859L15.2925 17.4154C15.0892 17.929 14.5062 18.1832 14.006 17.9487C10.5094 16.3099 7.68191 13.4804 6.04579 9.98224C5.81176 9.48187 6.0664 8.89915 6.58016 8.6962L7.10981 8.48697C7.62347 8.28406 7.8717 7.70401 7.70614 7.17712C7.38179 6.14487 7.17876 5.07832 7.10118 3.99909C7.06158 3.44823 6.6176 2.99994 6.06532 2.99994H3.1172Z" fill="currentColor" />,
    color: () => <>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.11726 2.99994C3.05253 2.99994 3.00001 3.05242 3.00043 3.11715C3.0613 12.6693 10.5626 20.456 20.0001 20.9727C20.5516 21.0029 21.0001 20.5523 21.0001 20V19.5L21.0001 17.9346C21.0001 17.3823 20.5518 16.9384 20.0009 16.8988C18.9181 16.8209 17.848 16.6168 16.8125 16.2905C16.2858 16.1246 15.7055 16.3723 15.5022 16.8859L15.2926 17.4154C15.0892 17.929 14.5063 18.1832 14.0061 17.9487C10.5094 16.3099 7.68198 13.4804 6.04585 9.98224C5.81182 9.48187 6.06646 8.89915 6.58022 8.6962L7.10988 8.48697C7.62353 8.28406 7.87176 7.70401 7.70621 7.17712C7.38185 6.14487 7.17883 5.07832 7.10124 3.99909C7.06164 3.44823 6.61766 2.99994 6.06538 2.99994H3.11726Z" fill="url(#C02HOIF0_radial)" />
      <defs>
        <radialGradient id="C02HOIF0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(17 25.5) rotate(-122.33) scale(25.8563 25.8492)">
          <stop stopColor="#FFCA43" />
          <stop offset="1" stopColor="#F38A2B" />
        </radialGradient>
      </defs>
    </>
  })
}