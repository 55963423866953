import { Observer } from 'mobx-react-lite';
import React from 'react';
import AppPage from '../../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../../base/components/AppPageHeader/AppPageHeader';
import BackButton from '../../../../../base/components/BackButton/BackButton';
import BaseButton from '../../../../../base/components/BaseButton/BaseButton';
import BaseHeader from '../../../../../base/components/BaseHeader/BaseHeader';
import DateRenderer from '../../../../../base/components/DateRenderer/DateRenderer';
import UIBlock from '../../../../../base/components/UIBlock/UIBlock';
import { useControllers } from '../../../../../base/hooks/useRootController.hook';
import { makeUrl } from '../../../../../base/utils/url.utils';
import ThoughtFeed from '../../../../../components/ThoughtFeed/ThoughtFeed';
import './AccountPageProfile.scss';

type AccountPageProfileProps = {}

const AccountPageProfile: React.FC<AccountPageProfileProps> = props => {

  const { AUTH, NAVIGATOR } = useControllers();

  return <Observer children={() => (
    <AppPage 
      className="AccountPageProfile"
      accommodateTitleBarSpace
      color={AUTH.currentUser?.color}
    >
      <AppPageHeader
        beforeTitle="Profile"
        title={AUTH.currentUser?.username ?? 'Your Profile'}
        afterTitle={AUTH.currentUser ? <>Member since <DateRenderer value={AUTH.currentUser?.timeCreated} format="LL" /></> : null}
        startSlot={<BackButton destination="up" />}
        endSlot={<BaseButton className="subtle" icon="pencil" label="Edit" to={makeUrl('/', NAVIGATOR.isInAdminArea ? 'admin' : 'app', 'account', 'edit-profile')}/>}
      />
      <AppPageContent>
        <UIBlock padded="all">
          <BaseHeader heading="Your Thoughts" level={3}/>
          {AUTH.currentUser && <ThoughtFeed user={AUTH.currentUser} />}
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
}

export default AccountPageProfile;