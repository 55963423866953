import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseButton, { BaseButtonProps } from '../../base/components/BaseButton/BaseButton';
import { useControllers } from '../../base/hooks/useRootController.hook';
import tick from '../../base/utils/waiters.utils';
import './PrintButton.scss';

interface PrintButtonProps extends BaseButtonProps {
  onBeforePrint?: () => void,
}

const PrintButton: React.FC<PrintButtonProps> = props => {

  const { UI } = useControllers();

  const print = async () => {
    if (props.onBeforePrint) props.onBeforePrint();
    UI.handleBeforePrint();
    await tick(500);
    window.print();
  }

  return <Observer children={() => (
    <BaseButton
      icon="print"
      iconVariant="regular"
      color="deepPurple"
      className="PrintButton subtle"
      appearance={UI.displayMode === 'phone' ? 'icon' : undefined}
      size="sm"
      {...props}
      onClick={print}
      label={UI.displayMode === 'phone' ? undefined : 'Print'}
    />
  )} />
}

export default PrintButton;