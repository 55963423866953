import axios from "axios"
import { action, flow, observable, when } from "mobx"
import { WPPost, WPTerm } from "../base/@types/wordpress.types"
import { WordPressEndpoints } from "../base/endpoints/wordpress.endpoints"
import { addToOrUpdateExistingInArrayById } from "../base/utils/array.utils"
import { isInCypressTestMode } from "../env"
import { APIController } from "./api.controller"
import { AuthController } from "./auth.controller"
import { makeControllerBase, makeRootControllerChildInitFn } from "./_root.controller"

export const makeContentController = (
) => {
  const c = observable({
    ...makeControllerBase('CONTENT'),
    posts: [] as WPPost[],
    terms: [] as WPTerm[],
    get API(): APIController {
      return c.ROOT!.children.API;
    },
    get AUTH(): AuthController {
      return c.ROOT!.children.AUTH;
    },
    getContentDataFromWordPress: async () => await flow(function * () {
      const { data } = yield axios.get(WordPressEndpoints.getPostIndex());
      addToOrUpdateExistingInArrayById(c.posts, ...data);
    })(),
    reset: () => {},
  })

  c.init = makeRootControllerChildInitFn(
    c,
    action(() => {
      // to test blog related features in cypress, remove the conditional if
      if (!isInCypressTestMode) {
        when(
          () => c.AUTH.isAuthenticated,
          () => c.getContentDataFromWordPress(),
        )
      }
      c.ready = true;
    })
  )
  return c;
}

export type ContentController = ReturnType<typeof makeContentController>;