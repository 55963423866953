import { Observer } from 'mobx-react-lite';
import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import './ThoughtCatcherDisabledNoticeCard.scss';

type ThoughtCatcherDisabledNoticeCardProps = {
  isNotInServicedCountries?: boolean | 0 | null,
  isYoungPerson?: boolean | 0 | null,
}

const ThoughtCatcherDisabledNoticeCard: React.FC<ThoughtCatcherDisabledNoticeCardProps> = props => {

  const {CONFIGURATIONS} = useControllers();

  const defaultDisabledNotice = "<div>Apologies for the inconvenience: The Thought Catcher service has been disabled temporarily by a moderator or is currently unavailable.</div>"

  const p = useProps(props);

  const s = useStore(() => ({
    get noticeNotInServicedCountries() {
      return p.isNotInServicedCountries ?
        <>
          <p>Apologies for the inconvenience: Turn2Me's services are currently only available in the Republic of Ireland and United Kingdom. We look forward to making the service available in your country/region soon.</p>
        </> :
        ""
    },
    get noticeIsYoungPerson() {
      return !p.isNotInServicedCountries ?
        p.isYoungPerson ?
          <>
            <span dangerouslySetInnerHTML={{__html: sanitizeHtmlAllowMoreTags(!!CONFIGURATIONS.configurations.THOUGHT_CATCHER_DISABLED_WORDING_YOUNG_PEOPLE ? CONFIGURATIONS.configurations.THOUGHT_CATCHER_DISABLED_WORDING_YOUNG_PEOPLE : defaultDisabledNotice)}} />
          </>
          :
          <>
            <span dangerouslySetInnerHTML={{__html: sanitizeHtmlAllowMoreTags(!!CONFIGURATIONS.configurations.THOUGHT_CATCHER_DISABLED_WORDING_ADULT ? CONFIGURATIONS.configurations.THOUGHT_CATCHER_DISABLED_WORDING_ADULT : defaultDisabledNotice)}} />
          </> :
        ""
    }
  }))

  const sanitizeHtmlAllowMoreTags = (htmlString: string | null) => {
    return htmlString
      ? sanitizeHtml(htmlString, {
          allowedTags: ["b", "i", "em", "strong", "a", "del", "li", "ul", "div", "br", "p", "h1", "h2", "h3", "h4", "h5", "h6"],
          allowedAttributes: { a: ["href"] },
          selfClosing: ['br']
        })
      : "";
  };


  return <Observer children={() => (
    <div className="ThoughtCatcherDisabledNoticeCard">
      <div>
        <svg width="40" height="39" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M26.9077 13.2393C26.8558 12.9681 26.5939 12.7903 26.3226 12.8423C26.0514 12.8942 25.8737 13.1561 25.9256 13.4274L26.9077 13.2393ZM37.3175 34.5646C37.5258 34.7459 37.8416 34.7241 38.0229 34.5158C38.2043 34.3075 38.1824 33.9917 37.9741 33.8104L37.3175 34.5646ZM25.9256 13.4274C27.8194 23.319 33.4951 31.237 37.3175 34.5646L37.9741 33.8104C34.3105 30.6211 28.757 22.8984 26.9077 13.2393L25.9256 13.4274Z" fill="currentColor" />
          <path d="M1.55209 31.7812C7.16668 31.7812 9.57293 30.9792 12.7813 26.9688C10.375 33.3854 7.96876 36.5937 1.55209 36.5937" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
          <path fillRule="evenodd" clipRule="evenodd" d="M12.863 27.5941L11.9792 27.7708L12.1559 26.887L12.1916 26.7085L16.6738 4.2976L16.7917 3.70834H17.4988H35.0417H35.3345H36.0417V4.41545V4.70834V22.2512V22.9583L35.4524 23.0762L29.9853 24.1696L29.4575 23.2554L34.6026 22.2264L26.4167 14.0405L14.1378 26.3193L26.9296 23.7609L27.4575 24.6752L13.0415 27.5584L12.863 27.5941ZM25.7095 13.3333L13.4307 25.6122L17.5236 5.14744L25.7095 13.3333ZM26.4167 12.6262L18.4988 4.70834H34.3345L26.4167 12.6262ZM27.1238 13.3333L35.0417 5.41545V21.2512L27.1238 13.3333Z" fill="currentColor" />
        </svg>
        <h2>Thought Catcher</h2>
      </div>
      {s.noticeNotInServicedCountries}
      {s.noticeIsYoungPerson}
    </div>
  )} />
}

export default ThoughtCatcherDisabledNoticeCard;