import { action, observable } from "mobx";
import { Alert } from '../@types/alert.types';
import { ModelFactory, SnapshotOf, StandardModel, Undefinable, Untypable } from "../base/@types";
import { HasId, Identifier } from "../base/@types/traits.types";
import { isStandardModel } from "../base/factories/standardModel.factory";
import { keepTruthy } from "../base/utils/array.utils";
import { strictEqual } from "../base/utils/equality.utils";
import { RecordMap } from "../base/utils/map.utils";
import { getSnapshot } from "../base/utils/snapshot.utils";
import { singularize } from "../base/utils/string.utils";
import { isString } from "../base/utils/typeChecks.utils";
import { ModelName } from "../constants/modelNames.enum";
import { SHOULD_LOG } from "../env";
import { Address } from "../models/makeAddress.model";
import { Assignment } from "../models/makeAssignment.model";
import { ChatMessage } from "../models/makeChatMessage.model";
import { ChatParticipant } from "../models/makeChatParticipant.model";
import { ChatThread } from "../models/makeChatThread.model";
import { Comment } from "../models/makeComment.model";
import { Company } from "../models/makeCompany.model";
import { Configuration } from "../models/makeConfiguration.model";
import { Contact } from "../models/makeContact.model";
import { ContactForm } from "../models/makeContactForm.model";
import { CounsellingApplication } from "../models/makeCounsellingApplication.model";
import { CounsellingAvailability } from "../models/makeCounsellingAvailability.model";
import { CounsellingSession } from "../models/makeCounsellingSession.model";
import { Fee } from "../models/makeFee.model";
import { Feedback } from "../models/makeFeedback.model";
import { Flag } from "../models/makeFlag.model";
import { Invitation } from "../models/makeInvitation.model";
import { Invoice } from "../models/makeInvoice.model";
import { InvoiceItem } from "../models/makeInvoiceItem.models";
import { MediaItem } from "../models/makeMediaItem.model";
import { ModeratedTerm } from "../models/makeModeratedTerm.model";
import { Payment } from "../models/makePayment.model";
import { Reaction } from "../models/makeReaction.model";
import { Subscription } from "../models/makeSubscription.model";
import { SupportGroup } from "../models/makeSupportGroup.model";
import { SupportGroupReservation } from "../models/makeSupportGroupReservation.model";
import { SupportGroupTopic } from "../models/makeSupportGroupTopic.model";
import { SupportTicket } from "../models/makeSupportTicket.model";
import { SurveyGAD7 } from "../models/makeSurveyGAD7.model";
import { SurveyGeneral } from "../models/makeSurveyGeneral.model";
import { SurveyGoalSheet } from "../models/makeSurveyGoalSheet.model";
import { SurveyPHQ9 } from "../models/makeSurveyPHQ9.model";
import { SurveySatisfaction } from "../models/makeSurveySatisfaction.model";
import { SurveySupportGroupNonAttendance } from "../models/makeSurveySupportGroupNonAttendance";
import { SurveySupportGroupSatisfaction } from "../models/makeSurveySupportGroupSatisfaction";
import { Thought } from "../models/makeThought.model";
import { User } from "../models/makeUser.model";
import { makeDataSet } from "./localDB/makeDataset";
import { ModelFactoryMap } from "./localDB/modelFactoryMap";
import { ControllerBase } from "./_controller.types";
import { makeControllerBase, makeRootControllerChildInitFn } from "./_root.controller";

/**
 * LocalDB is an important central data store that serves as a "mini database".
 * All the models constructed with localDB supplied will be able to retrieve and maintain related models automatically if they have been loaded from the API.
 *
 * Model snapshots going into the LocalDB should always pass through the constructors.
 * When given a model name, the snapshot will automatically be built with the correct constructor(factory).
 *
 */
export type LocalDBController = ControllerBase & {
  data: LocalDBDataset,
  get: <T extends HasId>(type: ModelName, id: Identifier) => Undefinable<T>,
  getMany: <T extends HasId>(type: ModelName, ids: Identifier[]) => T[],
  setOrMerge: <T extends HasId>(type: ModelName, record: T | SnapshotOf<T>, factory?: ModelFactory<T>, options?: LocalDBSetOrMergeOptions) => T,
  setOrMergeMany: <T extends HasId>(type: ModelName, records: (T | SnapshotOf<T>)[], factory?: ModelFactory<T>, options?: LocalDBSetOrMergeOptions) => T[],
  has: (type: ModelName, s: Identifier | HasId) => boolean,
  remove: <T extends HasId>(type: ModelName, recordOrRecordId: T | string) => void,
  removeMany: <T extends HasId>(type: ModelName, records: T[]) => void,
  reset: () => void,
  fullReset: () => void,
}

export type LocalDBSetOrMergeOptions = {
  trustToken?: string,
  replaceExisting?: boolean,
}

export type LocalDBDataset = {
  [ModelName.addresses]: RecordMap<Address>,
  [ModelName.alerts]: RecordMap<Alert>,
  [ModelName.assignments]: RecordMap<Assignment>,
  [ModelName.companies]: RecordMap<Company>,
  [ModelName.chatMessages]: RecordMap<ChatMessage>,
  [ModelName.chatParticipants]: RecordMap<ChatParticipant>,
  [ModelName.chatThreads]: RecordMap<ChatThread>,
  [ModelName.comments]: RecordMap<Comment>,
  [ModelName.configurations]: RecordMap<Configuration>,
  [ModelName.counsellingApplications]: RecordMap<CounsellingApplication>,
  [ModelName.counsellingAvailabilities]: RecordMap<CounsellingAvailability>,
  [ModelName.counsellingSessions]: RecordMap<CounsellingSession>,
  [ModelName.contacts]: RecordMap<Contact>,
  [ModelName.contactForms]: RecordMap<ContactForm>,
  [ModelName.fees]: RecordMap<Fee>,
  [ModelName.feedback]: RecordMap<Feedback>,
  [ModelName.flags]: RecordMap<Flag>,
  [ModelName.invitations]: RecordMap<Invitation>,
  [ModelName.invoiceItems]: RecordMap<InvoiceItem>,
  [ModelName.invoices]: RecordMap<Invoice>,
  [ModelName.mediaItems]: RecordMap<MediaItem>,
  [ModelName.moderatedTerms]: RecordMap<ModeratedTerm>,
  [ModelName.payments]: RecordMap<Payment>,
  [ModelName.reactions]: RecordMap<Reaction>,
  [ModelName.subscriptions]: RecordMap<Subscription>,
  [ModelName.supportGroupReservations]: RecordMap<SupportGroupReservation>,
  [ModelName.supportGroups]: RecordMap<SupportGroup>,
  [ModelName.supportGroupTopics]: RecordMap<SupportGroupTopic>,
  [ModelName.supportTickets]: RecordMap<SupportTicket>,
  [ModelName.surveysGAD7]: RecordMap<SurveyGAD7>,
  [ModelName.surveysGeneral]: RecordMap<SurveyGeneral>,
  [ModelName.surveysGoalSheet]: RecordMap<SurveyGoalSheet>,
  [ModelName.surveysPHQ9]: RecordMap<SurveyPHQ9>,
  [ModelName.surveysSupportGroupNonAttendance]: RecordMap<SurveySupportGroupNonAttendance>,
  [ModelName.surveysSupportGroupSatisfaction]: RecordMap<SurveySupportGroupSatisfaction>,
  [ModelName.surveysSatisfaction]: RecordMap<SurveySatisfaction>,
  [ModelName.thoughts]: RecordMap<Thought>,
  [ModelName.users]: RecordMap<User>,
};

export const makeLocalDBController = () => {

  const LOCALDB = observable({

    ...makeControllerBase('LOCALDB'),

    data: makeDataSet(),

    get: <T extends HasId>(type: ModelName, id: Identifier) => {
      if (!id) return null;
      const record = LOCALDB.data[type]?.get(id + '');
      return record as unknown as StandardModel<T> | undefined;
    },
    getMany: <T extends HasId>(type: ModelName, ids: Identifier[]) => {
      return observable(keepTruthy((ids || []).map(id => LOCALDB.data[type]?.get(id + '') as any).filter(i=>i) as T[]));
    },

    setOrMerge: action(<T extends HasId>(type: ModelName, obj: T | SnapshotOf<T>, factory?: ModelFactory<T>, options?: LocalDBSetOrMergeOptions): T => {
      const snapshot = getSnapshot(obj);
      const { id } = snapshot;
      if (!id) {
        throw Error(`LocalDB received an [${singularize(type)}] object that does not have a valid ID. ("${snapshot.id}" given)`);
      }
      const factoryToUse = (factory || ModelFactoryMap[type]) as ModelFactory;
      const shouldUseFactory = Boolean(factoryToUse || !isStandardModel(obj));
      const existingRecord = LOCALDB.data[type]?.get(id);
      if (existingRecord && options?.replaceExisting) {
        LOCALDB.remove(type, existingRecord);
      }
      if (existingRecord && !options?.replaceExisting) {
        if (obj && strictEqual(existingRecord, obj)) return obj as T;
        else existingRecord.$patch(snapshot, { trustToken: options?.trustToken });
      }
      else {
        const record = shouldUseFactory ? factoryToUse(snapshot, LOCALDB) : obj;
        LOCALDB.data[type]?.set(id, record as Untypable);
      }
      const result = LOCALDB.get<T>(type, id)!;
      return result;
    }),

    setOrMergeMany: action(<T extends HasId>(type: ModelName, records: (T | SnapshotOf<T>)[], factory?: ModelFactory<T>, options?: LocalDBSetOrMergeOptions): T[] => {
      records.forEach(record => LOCALDB.setOrMerge(type, record, factory, options));
      return LOCALDB.getMany(type, records.map(r => r.id));
    }),

    has: (type: ModelName, s: Identifier | HasId) => {
      if (isString(s)) return LOCALDB.data[type].has(s);
      else return LOCALDB.data[type].has(s.id);
    },

    remove: <T extends HasId>(type: ModelName, recordOrRecordId: T | string) => {
      try {
        LOCALDB.data[type]?.delete(isString(recordOrRecordId) ? recordOrRecordId : recordOrRecordId.id);
      } catch(e) {
        SHOULD_LOG() && console.warn(e);
      }
    },
    removeMany: <T extends HasId>(type: ModelName, records: T[]) => {
      // @ts-ignore
      records.forEach(record => LOCALDB.remove(type, record));
    },

    reset: action(() => {
      const publicModels = [
        ModelName.configurations,
        ModelName.counsellingAvailabilities,
      ];
      Object.entries(LOCALDB.data).forEach(e => {
        if (publicModels.includes(e[0] as ModelName)) return;
        e[1].clear();
      });
    }),

    fullReset: action(() => Object.values(LOCALDB.data).forEach(v => v.clear()))

  }) as LocalDBController;

  LOCALDB.init = makeRootControllerChildInitFn(
    LOCALDB,
    () => {}
  )

  return LOCALDB;

}
