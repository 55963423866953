import { SupportGroup, SupportGroupRelatedModels, SupportGroupSnapshot } from "../../models/makeSupportGroup.model";
import { AgeGroupFilterType } from "../../utils/ageAndDateOfBirth.utils";
import { StringKeyOf } from "../@types";
import { BaseParamFilterType, DateRangeFilter, DefaultIndexParamSet } from "../utils/api.utils";
import { makeApiEndpointConfig, makeApiGetEndpointConfig } from "../utils/endpoints.utils";
import { makeUrl } from "../utils/url.utils";
import { prefixClient, prefixOwn, prefixPublic, prefixStaff } from "./namespacePrefixes";

export type SupportGroupEndpointFilterSet = BaseParamFilterType<SupportGroupSnapshot> & {
  ageGroup?: AgeGroupFilterType[],
  userJoined?: string[],
  dateRange?: DateRangeFilter<SupportGroupSnapshot>
}
export type SupportGroupEndpointParams = DefaultIndexParamSet<SupportGroup, SupportGroupRelatedModels> & {
  latest?: boolean,
  currentUserHasJoined?: boolean,
  include?: (keyof SupportGroupRelatedModels | string)[],
  filter?: SupportGroupEndpointFilterSet,
}
export type SupportGroupEndpointFilterSetForStaffIndex = SupportGroupEndpointFilterSet & {
  userIdParticipated?: string,
  whereNotNull?: StringKeyOf<SupportGroupSnapshot>,
}
export type SupportGroupEndpointParamsForStaffIndex = SupportGroupEndpointParams & {
  filter?: SupportGroupEndpointFilterSetForStaffIndex
}

const ownUrlBase = prefixOwn('support-groups');
const publicUrlBase = prefixPublic('support-groups');
const clientUrlBase = prefixClient('support-groups');
const staffUrlBase = prefixStaff('support-groups');

export const SupportGroupEndpoints = {
  public: {
    index: makeApiEndpointConfig<SupportGroupEndpointParams>(publicUrlBase),
    get: makeApiGetEndpointConfig<SupportGroupEndpointParams>(publicUrlBase),
  },
  /**
   * client endpoints also get to have some special attributes to help determine if one has joined it or not etc.
   */
  client: {
    index: makeApiEndpointConfig<SupportGroupEndpointParams>(clientUrlBase),
    get: makeApiGetEndpointConfig<SupportGroupEndpointParams>(clientUrlBase),
    register: (id: string) => makeUrl(clientUrlBase, id, 'register'),
    deregister: (id: string) => makeUrl(clientUrlBase, id, 'deregister'),
  },
  own: {
    /**
     * client can get a list of their own support groups
     */
    index: makeApiEndpointConfig<SupportGroupEndpointParams>(ownUrlBase),
    get: makeApiGetEndpointConfig<SupportGroupEndpointParams>(ownUrlBase),
  },
  staff: {
    index: makeApiEndpointConfig<SupportGroupEndpointParamsForStaffIndex>(staffUrlBase),
    get: makeApiGetEndpointConfig<SupportGroupEndpointParams>(staffUrlBase),
    create: () => staffUrlBase,
    update: (id: string) => makeUrl(staffUrlBase, id),
    delete: (id: string) => makeUrl(staffUrlBase, id),
    start: (id: string) => makeUrl(staffUrlBase, id, 'start'),
    end: (id: string) => makeUrl(staffUrlBase, id, 'end'),
    inviteUser: (id: string) => makeUrl(staffUrlBase, id, 'reservations'),
    revokeReservation: (groupId: string, reservationId: string) => makeUrl(staffUrlBase, groupId, 'reservations', reservationId, 'revoke'),
  },
}

export const DefaultSupportGroupIncludes = [
  'facilitator',
  'topic',
  'allowedCompany',
] as StringKeyOf<SupportGroupRelatedModels>[]

export const DefaultSupportGroupIncludesForStaff = [
  ...DefaultSupportGroupIncludes,
  'createdByUser.permissions',
  'createdByUser.roles',
  'facilitator.permissions',
  'facilitator.roles',
  'reservations.user.permissions',
  'reservations.user.roles',
  'reservations.keyWorker',
  'threads.participants.user',
  'invoiceItems.invoice',
] as StringKeyOf<SupportGroupRelatedModels>[]