import { LazyRouteDef } from "../../base/@types";
import { makeConstantPromise, resolveWhen } from "../../base/utils/promises.utils";
import { always } from "../../base/utils/ramdaEquivalents.utils";
import { makeObservableRouteDef } from "../../base/utils/routes.utils";
import RedirectToLogin from "../../components/RedirectToLogin/RedirectToLogin";
import { AuthController } from "../../contexts/context";
import ModuleAdmin from "./Admin.module";

export const AdminModuleRouteDef = makeObservableRouteDef<LazyRouteDef>({
  identifier: 'admin',
  urlFactory: always('/admin'),
  component: ModuleAdmin,
  composeTitle: always('Admin'),
  children: () => import(
    /* webpackChunkName: "admin-module-children" */
    './Admin.module.childRoutes'
  ),
  guards: {
    onRender: resolveWhen(
      () => AuthController.ready,
      () => AuthController.isAuthenticated && AuthController.hasAnyPermissions,
    ),
    beforeLeave: makeConstantPromise(true),
  },
  fallback: RedirectToLogin
});

