import { always } from "../../base/utils/ramdaEquivalents.utils";
import { makeObservableRouteDef } from "../../base/utils/routes.utils";
import ModuleAuth from "./Auth.module";
import { ForgotPasswordPageRoute } from "./PageForgotPassword/ForgotPassword.page.route";
import { LoginPageRoute } from "./PageLogin/Login.page.route";
import { LogoutPageRoute } from "./PageLogout/Logout.page.route";
import { RegisterPageRoute } from "./PageRegister/Register.page.route";
import { ResetPasswordPageRoute } from "./PageResetPassword/ResetPassword.page.route";
import { VerifyEmailPageRoute } from "./PageVerifyEmail/VerifyEmail.page.route";

export const AuthModuleChildRoutes = [
  LogoutPageRoute,
  LoginPageRoute,
  RegisterPageRoute,
  ForgotPasswordPageRoute,
  ResetPasswordPageRoute,
  VerifyEmailPageRoute,
]

export const AuthModuleRouteDef = makeObservableRouteDef({
  identifier: 'auth',
  urlFactory: always('/auth'),
  composeTitle: always('Auth'),
  component: ModuleAuth,
  children: AuthModuleChildRoutes,
  fallbackRedirectTo: LoginPageRoute
})