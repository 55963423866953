import { Observer } from 'mobx-react-lite';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { ColorCodedState, Renderable } from '../../@types';
import joinClassName from '../../utils/className.utils';
import { renderRenderable } from '../../utils/components.utils';
import { NoOp } from '../../utils/functions.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import BaseIcon from '../BaseIcon/BaseIcon';
import { IconName } from '../Symbols/iconDefs';
import './BaseMenuItem.scss';

interface BaseMenuItemProps {
  className?: string,
  icon?: IconName,
  label?: Renderable,
  onClick?: () => any,
  colorCodedState?: ColorCodedState,
  to?: string,
  href?: string,
  primaryColorName?: string,
  disabled?: boolean,
  target?: string,
}

const BaseMenuItem: React.FC<BaseMenuItemProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    handleClick: () => {
      p.onClick && p.onClick();
    },
    get interactable() {
      return Boolean(p.to || p.onClick) && p.onClick !== NoOp;
    },
    get disabled() {
      return !s.interactable || p.disabled;
    },
    get attr() {
      return {
        className: joinClassName(
          "BaseMenuItem",
          p.className,
          p.colorCodedState && `state-${p.colorCodedState}`,
          s.disabled && 'disabled'
        ),
        onClick: s.disabled ? NoOp : s.handleClick,
        'data-primary-color': p.primaryColorName,
        children: <>
          <BaseIcon icon={p.icon ?? 'blank'} />
          <div className="BaseMenuItemLabel">{renderRenderable(p.label)}{props.children}</div>
        </>,
        rel: p.target === '_blank' ? 'noreferrer' : undefined
      }
    }
  }));
  return <Observer children={() => p.to ? (
    <NavLink to={p.to} {...s.attr} />
  ) : p.href ? (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a href={p.href} {...s.attr} />
  ) : <button {...s.attr} />}/>
}

export default BaseMenuItem;