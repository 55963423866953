import { action, flow, observable, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { presentOverlayDonateFn } from '../../../../../actions/donation.actions';
import { ColorCodedState } from '../../../../../base/@types';
import AppPage from '../../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../../base/components/AppPageHeader/AppPageHeader';
import BackButton from '../../../../../base/components/BackButton/BackButton';
import BaseButton from '../../../../../base/components/BaseButton/BaseButton';
import BaseSpacer from '../../../../../base/components/BaseSpacer/BaseSpacer';
import IndexDirectory from '../../../../../base/components/IndexDirectory/IndexDirectory';
import { IndexDirectoryViewMode } from '../../../../../base/components/IndexDirectory/indexDirectory.types';
import IndexDirectoryState from '../../../../../base/components/IndexDirectory/IndexDirectoryState';
import InfoBanner from '../../../../../base/components/InfoBanner/InfoBanner';
import UIBlock from '../../../../../base/components/UIBlock/UIBlock';
import { PaymentEndpoints } from '../../../../../base/endpoints/payment.endpoints';
import { useOnMount } from '../../../../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../../../../base/hooks/useRootController.hook';
import { makeLaravelIndexDataFetcher } from '../../../../../base/utils/api.utils';
import { makeDisposerController } from '../../../../../base/utils/disposer.utils';
import { reportError } from '../../../../../base/utils/errors.utils';
import { useStore } from '../../../../../base/utils/mobx.utils';
import tick from '../../../../../base/utils/waiters.utils';
import PaymentEntry from '../../../../../components/PaymentEntry/PaymentEntry';
import { ApiModelName, PseudoApiModelName } from '../../../../../constants/ApiModels.enum';
import { ModelName } from '../../../../../constants/modelNames.enum';
import { Payment } from '../../../../../models/makePayment.model';
import './AccountPageDonations.scss';

type AccountPageDonationsProps = {}

const AccountPageDonations: React.FC<AccountPageDonationsProps> = props => {

  const { UI, API, LOCALDB } = useControllers();

  const s = useStore(() => ({
    indexDirectoryState: new IndexDirectoryState<Payment>(observable({
      viewMode: 'list' as IndexDirectoryViewMode,
      searchable: false,
    })),
    shouldGETData: false,
    isLoadingLogs: true,
  }));

  const getPaymentData = makeLaravelIndexDataFetcher<Payment>(
    API,
    ModelName.payments,
    PaymentEndpoints.own.index,
    {
      sort: '-timeCreated',
      include: ['model'],
      filter: {
        whereEquals: {
          key: 'modelType',
          values: [ApiModelName.SUBSCRIPTION, PseudoApiModelName.DONATION]
        }
      },
    },
    {
      onError: (e) => reportError(e),
      onSuccess: action(() => { s.isLoadingLogs = false }),
    },
  )

  useOnMount(() => {
    const d = makeDisposerController();
    d.add(reaction(
      () => LOCALDB.data.payments.size,
      flow(function* () {
        if (s.isLoadingLogs) return;
        s.shouldGETData = true;
        yield tick(500);
        if (s.shouldGETData) s.shouldGETData = false;
      }),
    ))
    return d.disposer;
  })

  return <Observer children={() => (
    <AppPage className="AccountPageDonations"
      accommodateTitleBarSpace
      color="red"
    >
      <AppPageHeader
        title="Donations"
        afterTitle="View your donation history, or make a new donation"
        startSlot={<BackButton destination="up" />}
      />
      <AppPageContent>
        <UIBlock padded>
          <BaseButton
            dataCy="DonateButton"
            onClick={presentOverlayDonateFn(UI)}
            backgroundImage="radial-gradient(140.89% 140.89% at 72.8% 1.2%, #F7472F 0%, #F29F94 100%)"
            label="Donate to turn2me"
            fullWidth
            size="lg"
            icon="heart"
            iconVariant="filled"
          />
          <InfoBanner colorCodedState={ColorCodedState.attention} icon="info">
            <p>This page only shows your donations made when logged in with this account. If you had donated anonymously before, those donations will not show up on this page.</p>
          </InfoBanner>
          <IndexDirectory<Payment>
            state={s.indexDirectoryState}
            dataFetcher={getPaymentData}
            listEntrySeparator={<BaseSpacer size=".5em"/>}
            listEntryRenderer={p => <PaymentEntry payment={p} />}
            shouldTriggerReload={s.shouldGETData}
          />
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
}

export default AccountPageDonations;