import { always } from "../../../../../base/utils/ramdaEquivalents.utils";
import { makeObservableRouteDef } from "../../../../../base/utils/routes.utils";
import AccountPageProfile from "./AccountPageProfile.page";

export const AccountPageProfileRouteDef = makeObservableRouteDef({
  identifier: 'account-profile',
  urlFactory: always('profile'),
  composeTitle: always('Profile'),
  component: AccountPageProfile,
  icon: 'lock',
})