import { Nullable } from "../base/@types";
import { CounsellingSessionEndpointParams, CounsellingSessionEndpoints } from "../base/endpoints/counsellingSession.endpoints";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { CounsellingSession } from "../models/makeCounsellingSession.model";

export const getCounsellingSession = (
  API: APIController,
  id: string,
  params?: CounsellingSessionEndpointParams,
  endpointType?: 'staff' | 'own'
) => new Promise<CounsellingSession>(async (resolve, reject) => {
  try {
    const url = CounsellingSessionEndpoints[endpointType ?? 'own'].get(id, params);
    const session: Nullable<CounsellingSession> = await API.get(url, ModelName.counsellingSessions);
    if (!session) {
      throw Error(`Failed to get session #${id}`);
    }
    resolve(session)
  } catch(e) {
    reject(e);
  }
})