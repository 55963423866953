import deepmerge from "deepmerge";
import { ContactEndpointParams, ContactEndpoints } from "../base/endpoints/contact.endpoints";
import { isFunction } from "../base/utils/typeChecks.utils";
import { ModelName } from "../constants/modelNames.enum";
import { Contact, ContactType } from "../models/makeContact.model";
import { useAutoGet, UseAutoGetFactoryOptions } from "../utils/useAutoGet.utils";

export function useGetContacts<ObservableType extends object = object>(
  options: UseAutoGetFactoryOptions<Contact[], ObservableType> & {
    own?: boolean,
    type?: ContactType,
  },
  params?: ContactEndpointParams | (() => ContactEndpointParams),
) {
  const { when = () => true } = options || {};
  useAutoGet<Contact[], ObservableType>({
    ...options,
    url: when() ? ContactEndpoints[options.own ? 'own' : 'staff'].index(
      deepmerge(
        isFunction(params) ? params() : params ?? {},
        options.type ? { filter: { type: options.type } } : {}
      )
    ) : undefined,
    asIndex: true,
    modelName: ModelName.contacts,
  });
}