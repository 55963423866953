import { StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { ModelName } from "../constants/modelNames.enum";
import { hasTimestamps } from "../traits/hasTimestamps.trait";

export type ModeratedTermSnapshot = ReturnType<typeof makeModeratedTermSnapshotBase>;
export type ModeratedTermRelatedModels = {}
export type ModeratedTermExtendedProperties = {};

export type ModeratedTerm = StandardModel<
	ModeratedTermSnapshot,
	ModeratedTermRelatedModels,
	ModeratedTermExtendedProperties
>
/**
 * A moderated term, if found inside a newly posted thought, will trigger an automatic flag to highlight it to moderators.
 * They do not result in the thought being hidden, unless the "isEscalated"
 */
export const makeModeratedTermSnapshotBase = () => ({
	id: '',
	/** the word/phrase that will trigger */
	term: '',
	/** If the term indicates more serious problems such as suicidal thoughts, set this to true to tell the application to automatically hide the thought and notify moderators immediately. */
	isEscalated: false,
	...hasTimestamps(),
})

export const makeModeratedTerm = createStandardModelFactory<ModeratedTerm, ModeratedTermRelatedModels, ModeratedTermExtendedProperties>({
	name: ModelName.moderatedTerms,
	snapshotFactory: makeModeratedTermSnapshotBase,
})
