import * as Sentry from '@sentry/react';
import { SHOULD_LOG } from '../../env';
import { User } from '../../models/makeUser.model';
import { Nillable } from '../@types';

export const shouldStartSentry = true;

export function setErrorHandlerContext(user?: Nillable<User>) {
  if (!shouldStartSentry) return;
  Sentry.setUser({
    id: user?.id ?? '',
  });
}

export function reportError(e: string | Error | unknown) {
  console.error(e);
  reportToSentry(e)
}

export function reportToSentry(e: string | Error | unknown) {
  const error = typeof e === 'string' ? new Error(e) : e;
  if (shouldStartSentry) Sentry.captureException(error);
}

export function runAndIgnoreError(fn?: Function) {
  try {
    fn && fn()
  } catch(e) {
    SHOULD_LOG() && console.warn('An error was ignored:', e);
  }
}

export const getErrorMessageFromRequest = (error: string | Error | null | any) => {
  const defaultMessage = 'An unknown error occurred.';
  switch (true) {
    case error instanceof Object && !!error && 'response' in error && !!error.response && 'data' in error.response:
      return error.response.data;
    case error:
      return error;
    default:
      return { message: defaultMessage };
  }
}