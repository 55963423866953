import { when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState, Undefinable } from '../../base/@types';
import AppPage from '../../base/components/AppPage/AppPage';
import AppPageContent from '../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../base/components/AppPageHeader/AppPageHeader';
import BaseMenu from '../../base/components/BaseMenu/BaseMenu';
import BaseMenuItem from '../../base/components/BaseMenu/BaseMenuItem';
import BaseSeparator from '../../base/components/BaseSeparator/BaseSeparator';
import ClickToCopy from '../../base/components/ClickToCopy/ClickToCopy';
import ColorTagCompleted from '../../base/components/ColorTag/ColorTagCompleted';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import EmailLinkRenderer from '../../base/components/EmailLinkRenderer/EmailLinkRenderer';
import InfoBanner from '../../base/components/InfoBanner/InfoBanner';
import InfoDisplayItem from '../../base/components/InfoDisplayList/InfoDisplayItem';
import InfoDisplayList from '../../base/components/InfoDisplayList/InfoDisplayList';
import OverlayCloseButton from '../../base/components/OverlayCloseButton/OverlayCloseButton';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import SwiperContainer from '../../base/components/SwiperContainer/SwiperContainer';
import SwiperSlide from '../../base/components/SwiperContainer/SwiperSlide';
import { ContactTypeEnum } from '../../base/constants/contactType.constants';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { sortByTimeScheduledOldestFirst } from '../../base/utils/array.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { useSyncUrlParams } from '../../base/utils/urlParams.utils';
import tick from '../../base/utils/waiters.utils';
import { openOverlayContactFormEditor } from '../../components/OverlayContactFormEditor/OverlayContactFormEditor';
import { ApiModelName } from '../../constants/ApiModels.enum';
import { communicationTypeDescriptors } from '../../constants/communicationTypes.descriptors';
import { CounsellingType, getCounsellingTypeColorHex, getCounsellingTypeName } from '../../constants/counsellingTypes.descriptors';
import { ModelName } from '../../constants/modelNames.enum';
import { AssignmentGoalSheet, AssignmentSatisfaction } from '../../models/makeAssignment.model';
import { CounsellingApplication } from '../../models/makeCounsellingApplication.model';
import { getCounsellingApplication } from '../../requests/getCounsellingApplication.request';
import { getInvitationLink, isOfCounsellingTypeYoungPeople, isPaidCounselling } from '../../utils/counsellingApplication.utils';
import { cancelSession } from '../../utils/counsellingSession.utils';
import GoalSheetWizard, { ID_GoalSheetWizard } from '../GoalSheetWizard/GoalSheetWizard';
import OverlayFeedbackForm from '../OverlayFeedbackForm/OverlayFeedbackForm';
import OverlayGoalSheetViewer from '../OverlayGoalSheetViewer/OverlayGoalSheetViewer';
import OverlaySurveySatisfaction from '../OverlaySurveySatisfaction/OverlaySurveySatisfaction';
import SessionSummaryCard from '../SessionSummaryCard/SessionSummaryCard';
import UserFullNameRenderer from '../UserFullNameRenderer/UserFullNameRenderer';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import './CounsellingJourney.scss';

interface CounsellingJourneyProps {
  applicationId?: string,
  application?: CounsellingApplication,
  autoOpen?: boolean
}

const CounsellingJourney: React.FC<CounsellingJourneyProps> = props => {

  const p = useProps(props);

  const { UI, AUTH, API, LOCALDB } = useControllers();
  const { DIALOG, OVERLAY } = UI;

  const s = useStore(() => ({
    get id() {
      return p.applicationId || p.application?.id;
    },
    get application() {
      return s.id ? LOCALDB.get<CounsellingApplication>(ModelName.counsellingApplications, s.id) : p.application;
    },
    get isPaid() {
      return s.application?.type ? isPaidCounselling(s.application?.type) : false;
    },
    get assignmentSatisfaction() {
      return s.application?.assignments?.find(a => a.targetTypeModelName === ModelName.surveysSatisfaction && a.assignedToUserId === AUTH.currentUser?.id) as Undefinable<AssignmentSatisfaction>;
    },
    get assignmentGoalSheet() {
      return s.application?.assignments?.find(a => a.targetTypeModelName === ModelName.surveysGoalSheet && a.assignedToUserId === AUTH.currentUser?.id) as Undefinable<AssignmentGoalSheet>;
    },
    get goalSheet() {
      return s.assignmentGoalSheet?.target
    },
    get sessions() {
      return sortByTimeScheduledOldestFirst(s.application?.sessions ?? []);
    },
    get initialSwiperSlideIndex() {
      return s.application?.nextSession ? s.application.sortedSessions.indexOf(s.application.nextSession) : 0;
    },
    get sessionSwiper() {
      return <Observer children={() => (
        <section className="CounsellingJourneyCardContentSwiperSection" data-session-count={s.application?.sessions?.length}>
          <h3 className="CounsellingJourneyCardContentHeading">Sessions</h3>
          <SwiperContainer
            centeredSlides={true}
            initialSlide={s.initialSwiperSlideIndex}
            gap={16}
            slidesPerView={UI.onlyPhones ? 1 : 1.62}
            slides={<>
              {s.application?.sortedSessions?.map(ses => <SwiperSlide key={ses.id}>
                <SessionSummaryCard application={s.application!} session={ses} />
              </SwiperSlide>)}
            </>}
          />
        </section>
      )} />
    },
    viewGoalSheet: () => {
      if (!s.assignmentGoalSheet) {
        DIALOG.attention({
          heading: `No goal sheet available to view.`,
        })
        return;
      }
      OVERLAY.present({
        component: <OverlayGoalSheetViewer assignment={s.assignmentGoalSheet} />
      })
    },
    cancelNextSession: () => {
      if (!s.application?.nextSession) return;
      const session = s.application.nextSession;
      cancelSession(session, AUTH, DIALOG, API);
    },
    openSatisfactionSurvey: async () => {
      if (!s.assignmentSatisfaction) return;
      if (!s.application) return;
      if (s.assignmentSatisfaction.isCompleted) return;
      await tick(190);
      OVERLAY.present({
        name: 'OverlaySurveySatisfaction',
        component: <OverlaySurveySatisfaction
          application={s.application}
          assignment={s.assignmentSatisfaction}
          // onComplete={props.onComplete}
        />,
      })
    },
    openGoalSheetWizard: async () => {
      // console.log('should open goal sheet wizard')
      if (!s.assignmentGoalSheet) return;
      if (!s.application) return;
      await tick(190);
      OVERLAY.present({
        name: ID_GoalSheetWizard,
        component: <GoalSheetWizard
          application={s.application}
          assignment={s.assignmentGoalSheet}
        />,
        width: '61.8em',
      })
    },
    reloadData: async () => {
      if (s.id) await getCounsellingApplication(s.id, API);
    },
    get completedSessions() {
      return s.application?.sessions.filter(ses => ses.isCompleted);
    },
    get currentUserIsInvitee() {
      return AUTH.currentUser?.id && (s.application?.invitee?.id === AUTH.currentUser.id);
    },
    get invitationLink() {
      return getInvitationLink(s.application?.invitation?.uuid, s.application?.type, s.application?.applicant?.email);
    }
  }));

  useOnMount(() => {
    s.reloadData();
    const disposers: Function[] = [];
    if (!s.application?.isCompleted && !s.application?.isRejected && !s.application?.isArchived) {
      disposers.push(when(() => Boolean(s.assignmentGoalSheet && !s.assignmentGoalSheet.isCompleted && !s.assignmentSatisfaction), s.openGoalSheetWizard));
    }
    if (!s.application?.isRejected && !s.application?.isArchived) {
      disposers.push(when(() => !!s.assignmentSatisfaction && !s.assignmentSatisfaction.isCompleted, s.openSatisfactionSurvey));
    }
    return () => disposers.forEach(d => d());
  });

  useSyncUrlParams('applicationId', s.id);

  const reportAProblem = () => {
    openOverlayContactFormEditor(UI, { type: ContactTypeEnum.Services }, 'Report a Problem', true);
  }
  // const requestRefundViaContactForm = (sessionId: string, sessionApplicationId: string) => {
  //   openOverlayContactFormEditor(UI, {
  //     type: ContactTypeEnum.Services, body: `Requesting refund for session ${sessionId}, application ${sessionApplicationId}:\n\n`
  //   }, 'Request Refund');
  // }

  const submitNewFeedback = () => {
    if (!s.application) return;
    UI.OVERLAY.present({
      component: <OverlayFeedbackForm forModelId={s.application.id} forModelType={ApiModelName.COUNSELLING_APPLICATION} />,
      appearance: 'card',
    })
  }

  return <Observer children={() => (
    <AppPage
      className="CounsellingJourney"
      data-id={s.id}
      color={s.application ? getCounsellingTypeColorHex(s.application.type) : undefined}
      data-type={s.application?.type}
    >

      <AppPageHeader
        beforeTitle="Your Counselling Journey"
        title={s.application ? getCounsellingTypeName(s.application.type, !AUTH.currentUser?.isAdmin) + ' Counselling' : 'Counselling Journey'}
        afterTitle={<div>
          {s.application?.isCompleted && <ColorTagCompleted />}
        </div>}
        endSlot={<OverlayCloseButton />}
      />

      <AppPageContent>

        <section className="CounsellingJourneyCardContent">

          {s.application?.isPending && <div className="CounsellingJourneyCardNoticeSection">
            <InfoBanner icon="info" colorCodedState={ColorCodedState.positive}>
              <p>We have received your application. You will be notified when your application is approved!</p>
              {s.application?.invitation?.uuid && !s.application.inviteeId && <>
                <p>Remember to send the following link to your co-applicant. We can only approve your application when they have accepted your invitation.</p>
                <ClickToCopy text={s.invitationLink} buttonLabel="Copy Link" successMessageComposer={() => "Link copied!"}><code>{s.invitationLink}</code></ClickToCopy>
              </>}
            </InfoBanner>
          </div>}


          {s.application && <div className="CounsellingJourneyCardNoticeSection">
            <h3 className="CounsellingJourneyCardContentHeading">At a glance</h3>
            <ShadedBlock>
              <InfoDisplayList>
                {
                  s.application.type === CounsellingType.Couples && <InfoDisplayItem label="Partner">
                    {s.application.invitee?.id ? (
                      s.currentUserIsInvitee ? <UsernameRenderer user={s.application.applicant} userId={s.application.applicant?.id} /> : <UsernameRenderer user={s.application.invitee} userId={s.application.invitee?.id} />
                    ) : <>{s.application.invitation?.name} (<EmailLinkRenderer value={s.application.invitation?.email} />)</>}
                  </InfoDisplayItem>
                }
                {
                  isOfCounsellingTypeYoungPeople(s.application.type) && <InfoDisplayItem label={s.application.invitee?.id ? (s.currentUserIsInvitee ? 'Parent' : 'Child') : 'Invitee'}>
                    {s.application.invitee?.id ? (
                      s.currentUserIsInvitee ? <UsernameRenderer user={s.application.applicant} userId={s.application.applicant?.id} /> : <UsernameRenderer user={s.application.invitee} userId={s.application.invitee?.id} />
                    ) : <>{s.application.invitation?.name} (<EmailLinkRenderer value={s.application.invitation?.email} />)</>}
                  </InfoDisplayItem>
                }
                <InfoDisplayItem label="Counsellor">
                  <UserFullNameRenderer user={s.application.counsellor} userId={s.application.counsellorId} />
                </InfoDisplayItem>
                <InfoDisplayItem label="Communication Type">
                  {s.application.communicationTypePreference ? communicationTypeDescriptors[s.application.communicationTypePreference]?.name : ''}
                </InfoDisplayItem>
                <InfoDisplayItem label="Preferred Session Time">
                  <DateRenderer value={s.application.availability?.timeStart} />
                </InfoDisplayItem>
                <InfoDisplayItem label="Applied on">
                  <DateRenderer value={s.application.timeCreated} />
                </InfoDisplayItem>
                <InfoDisplayItem label="Ref. No">
                  #{s.application.id}
                </InfoDisplayItem>
                {
                  s.application.isCompleted && <InfoDisplayItem label="Total Completed Sessions">
                    {s.completedSessions?.length}
                  </InfoDisplayItem>
                }
              </InfoDisplayList>
            </ShadedBlock>
          </div>}

          {s.application?.isApproved && (
            s.application.sessions?.length === 0 ? <div className="CounsellingJourneyCardNoticeSection">
              <InfoBanner icon="info" colorCodedState={ColorCodedState.positive}>
                <p>Your application has been approved! Your counsellor will soon arrange the first session with you.</p>
              </InfoBanner>
            </div> : <>
              {s.application.sessions?.length === 1 && !s.application.nextSession?.timeEnded && <div className="CounsellingJourneyCardNoticeSection">
                  <InfoBanner icon="info" colorCodedState={ColorCodedState.positive} heading="Congratulatons! Your counsellor has scheduled the first session with you.">
                  <p>Your counsellor will assign two pre-session clinical surveys for each session. Please make sure you fill them in before your session begins to help our counsellors better prepare for the session.</p>
                </InfoBanner>
              </div>}
              { s.application.isOngoing && s.sessionSwiper }
            </>
          )}

          <section className="CounsellingJourneyCardContentMenuSection">
            <h3 className="CounsellingJourneyCardContentHeading">More Options</h3>
            <BaseMenu>
              { s.goalSheet && <>
                <BaseMenuItem icon="flag-checkered" onClick={s.viewGoalSheet}>View Your Goals</BaseMenuItem>
                <BaseSeparator />
              </> }
              {
                s.application?.isOngoing && <>
                  {/* <BaseMenuItem icon="client-notebook">Update Your Contact Info</BaseMenuItem>
                  <BaseSeparator /> */}
                  {/* <BaseMenuItem icon="calendar">Change Next Session Date</BaseMenuItem>
                  <BaseSeparator /> */}
                  <BaseMenuItem icon="calendar" onClick={s.cancelNextSession} disabled={!(s.application.nextSession && !s.application.nextSession.timeStarted)}>Cancel Next Session</BaseMenuItem>
                  <BaseSeparator />
                </>
              }
              <BaseMenuItem icon="speech-bubble" onClick={submitNewFeedback} disabled={!s.application || !s.application.isApproved}>Provide Feedback</BaseMenuItem>
              <BaseSeparator />
              {
                s.assignmentSatisfaction && !s.assignmentSatisfaction.isCompleted && <>
                  <BaseMenuItem icon="pencil" onClick={s.assignmentSatisfaction ? s.openSatisfactionSurvey : undefined}>Complete Satisfaction Survey</BaseMenuItem>
                  <BaseSeparator />
                </>
              }
              <BaseMenuItem icon="warning" colorCodedState={ColorCodedState.alert} onClick={reportAProblem}>Report a Problem</BaseMenuItem>
            </BaseMenu>
          </section>

        </section>
      </AppPageContent>

    </AppPage>
  )} />
}

export default CounsellingJourney;