import { flow } from "mobx";
import { ThoughtEndpoints } from "../base/endpoints/thought.endpoints";
import { getSnapshot } from "../base/utils/snapshot.utils";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { Thought } from "../models/makeThought.model";

export const saveThought = flow(function * (thought: Partial<Thought>, API: APIController) {
  const endpoint = thought.id ? ThoughtEndpoints.own.update : ThoughtEndpoints.own.create;
  const url = endpoint(thought.id!);
  const payload = getSnapshot(thought);
  const savedThought = thought.id ? (
    yield API.patch(url, ModelName.thoughts, payload)
  ) : (
    yield API.post(url, ModelName.thoughts, payload)
  );
  return savedThought as Thought;
})