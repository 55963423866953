import { action, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { Nillable, Nullable, TimezoneMode } from '../../../../../../base/@types';
import BaseIcon from '../../../../../../base/components/BaseIcon/BaseIcon';
import TimeframePicker from '../../../../../../base/components/TimeframePicker/TimeframePicker';
import { useOnMount } from '../../../../../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../../../../../base/hooks/useRootController.hook';
import joinClassName from '../../../../../../base/utils/className.utils';
import { NoOp } from '../../../../../../base/utils/functions.utils';
import { convertRecordMapToArray } from '../../../../../../base/utils/map.utils';
import { useProps, useStore } from '../../../../../../base/utils/mobx.utils';
import { createLocalMoment, createMomentFn, createUTCMoment, HHmm, YYYYMMDD, YYYYMMDDHHmm } from '../../../../../../base/utils/time.utils';
import { CounsellingType } from '../../../../../../constants/counsellingTypes.descriptors';
import { ModelName } from '../../../../../../constants/modelNames.enum';
import { CounsellingAvailability } from '../../../../../../models/makeCounsellingAvailability.model';
import './CounsellingSessionTimePicker.scss';

interface CounsellingSessionTimePickerProps {
  selectedSlotId: Nillable<string>,
  onChange: (avail: Nullable<CounsellingAvailability>) => void;
  forCounsellingType: CounsellingType
  timezoneMode?: TimezoneMode,
}

const CounsellingSessionTimePicker: React.FC<CounsellingSessionTimePickerProps> = props => {

  const { COMMON, LOCALDB, AUTH } = useControllers();

  const internal = useStore(() => ({
    get selectedSlot() {
      return p.selectedSlotId ? LOCALDB.get<CounsellingAvailability>(ModelName.counsellingAvailabilities, p.selectedSlotId) : null;
    }
  }))

  const p = useProps(props);

  const _createMoment = createMomentFn(p.timezoneMode ?? 'auto');

  const s = useStore(() => ({
    isUkrainianRefugee(allowedCompanyId: string | null) {
      return AUTH.currentUser?.company?.code === 'UKR' && this.checkAllowedCompanyId(allowedCompanyId);
    },
    checkAllowedCompanyId(allowedCompanyId: string | null) {
      return AUTH.currentUser?.company?.id === allowedCompanyId;
    },
    get utcValue() {
      return internal.selectedSlot?.timeStart;
    },
    get availabilities() {
      return convertRecordMapToArray(LOCALDB.data.counsellingAvailabilities);
    },
    get slotsSuitableForSelectedType() {
      return s.availabilities.filter(a => !a.typesAllowed || a.typesAllowed.length === 0 || a.typesAllowed.includes(p.forCounsellingType))
    },
    get utcValueMoment() {
      return s.utcValue ? createUTCMoment(s.utcValue) : undefined;
    },
    timeframeLocalStartDate: createLocalMoment().startOf('week').format(YYYYMMDD),
    handleLocalTimeframeChange: action((newStartDate: string) => {
      s.timeframeLocalStartDate = newStartDate;
      s.selectedLocalDate = s.localDatesToRender.find(d => s.slotsSuitableForSelectedType.find(slot => createUTCMoment(slot.timeStart).local().isSame(d, 'date')))?.format(YYYYMMDD) ?? newStartDate;
    }),
    get localDatesToRender() {
      return Array(7).fill(null).map((n, i) => i).map(i => createLocalMoment(s.timeframeLocalStartDate).add(i, 'day'));
    },
    selectedLocalDate: internal.selectedSlot?.timeStart ? createUTCMoment(internal.selectedSlot?.timeStart).local().format(YYYYMMDD) : undefined,
    handleSelectLocalDate: (d: moment.Moment) => action(() => {
      s.selectedLocalDate = d.format(YYYYMMDD);
    }),
    get selectedLocalDayOfWeek() {
      return +(createLocalMoment(s.selectedLocalDate)?.format('d') || '0');
    },
    get selectedLocalTime() {
      return s.utcValueMoment?.local().format(HHmm);
    },
    get slotsInSelectedDay() {
      return s.getSlotsInDay(s.selectedLocalDate);
    },
    getSlotsInDay(day?: string | moment.Moment) {
      return day ? s.slotsSuitableForSelectedType.filter(a => createUTCMoment(a.timeStart).local().isSame(moment(day), 'day')) : [];
    },
    clearSelection: () => {
      p.onChange(null);
    },
    handleSelectSlot: (avail: Nullable<CounsellingAvailability>) => () => {
      p.onChange(avail);
    },
  }));

  useOnMount(() => {
    if (!s.selectedLocalDate) s.handleSelectLocalDate(moment().local())();
    return reaction(
      () => s.selectedLocalDate,
      () => s.clearSelection()
    )
  });

  return <Observer children={() => (
    <div className="CounsellingSessionTimePicker">
      <TimeframePicker
        startDate={s.timeframeLocalStartDate}
        unit="week"
        onNavigate={s.handleLocalTimeframeChange}
        iconVariant="filled"
        icon="chevron"
        canNotNavigateToPast={true}
      />
      <div className="CounsellingSessionTimePickerDateList">
        {
          s.localDatesToRender.map(d => {
            const inThePast = COMMON.CLOCK.localNowMoment.isAfter(d, 'date');
            const slots = inThePast ? [] : s.getSlotsInDay(d);
            const availableSlots = slots.filter(slot => createUTCMoment(slot.timeStart).isAfter() && !slot.applicationId);
            const selected = s.selectedLocalDate && d.isSame(s.selectedLocalDate, 'day');
            return <div
              key={d.format(YYYYMMDD)}
              onClick={inThePast ? NoOp : s.handleSelectLocalDate(d)}
            >
              <span className="CounsellingSessionTimePickerDateListItemWeekday">{d.format('dd')[0]}</span>
              <button
                className={joinClassName(
                  'CounsellingSessionTimePickerDateListItemDateButton',
                  inThePast && 'inThePast',
                  selected && 'selected'
                )}
                children={<>
                  {availableSlots.some(s => s.allowedCompanyId) && !selected && <BaseIcon size="1.6em" icon={(availableSlots.some(avail => s.isUkrainianRefugee(avail.allowedCompanyId)) ? 'ukraine-flag' : 'suitcase')}/>}
                  <span>{d.get('date')}</span>
                  {availableSlots.length > 0 && !selected && <strong>{availableSlots.length}</strong>}
                </>}
              />
            </div>;
          })
        }
      </div>
      {
        s.selectedLocalDate && <div className="CounsellingSessionTimePickerTimeListContainer">
          <div className="CounsellingSessionTimePickerTimeListContainerBackground">
            <span className="CounsellingSessionTimePickerTimeListContainerTrianglePointer"
              style={{ left: ((s.selectedLocalDayOfWeek || 0) + .5) / 7 * 100 + '%' }}
            />
          </div>
          <div className="CounsellingSessionTimePickerTimeList">
            {s.slotsInSelectedDay.length === 0 && <p className="CounsellingSessionTimePickerTimeListEmptyNotice"><em>No availabilities today...</em></p>}
            {s.slotsInSelectedDay.map(avail => {
              const value = _createMoment(avail.timeStart).local().format(HHmm);
              const inThePast = _createMoment(avail.timeStart).isBefore(COMMON.CLOCK.localNowMoment);
              const booked = !!avail.applicationId;
              return <button
                className={joinClassName(
                  inThePast && 'inThePast',
                  booked && 'booked',
                  avail.id === internal.selectedSlot?.id && 'selected',
                )}
                disabled={inThePast || booked}
                key={avail.id}
                data-availability-id={avail.id}
                data-timestamp={avail.timeStart}
                data-timestamp-local={createUTCMoment(avail.timeStart).local().format(YYYYMMDDHHmm)}
                children={<span>
                  {avail.allowedCompanyId && <BaseIcon size="1.4em" icon={ s.isUkrainianRefugee(avail.allowedCompanyId) ? 'ukraine-flag' : 'suitcase'} />} {value}
                </span>}
                onClick={(inThePast || booked) ? NoOp : s.handleSelectSlot(avail)}
              />
            })}
          </div>
        </div>
      }
    </div>
  )} />
}

export default CounsellingSessionTimePicker;