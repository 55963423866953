import { ChatParticipant, ChatParticipantRelatedModels } from "../../models/makeChatParticipant.model";
import { DefaultIndexParamSet } from "../utils/api.utils";
import { makeApiEndpointConfig, makeApiGetEndpointConfig } from "../utils/endpoints.utils";
import { makeUrl } from "../utils/url.utils";
import { prefixOwn, prefixStaff } from "./namespacePrefixes";

export type ChatParticipantEndpointParams = DefaultIndexParamSet<ChatParticipant, ChatParticipantRelatedModels> & {
  include?: (keyof ChatParticipantRelatedModels | string)[],
}

const ownUrlBase = prefixOwn('chat-participants');
const staffUrlBase = prefixStaff('chat-participants');

export const ChatParticipantEndpoints = {
  own: {
    index: makeApiEndpointConfig<ChatParticipantEndpointParams>(ownUrlBase),
    get: makeApiGetEndpointConfig<ChatParticipantEndpointParams>(ownUrlBase),
    update: (id: string) => makeUrl(ownUrlBase, id),
  },
  staff: {
    index: makeApiEndpointConfig<ChatParticipantEndpointParams>(staffUrlBase),
    get: makeApiGetEndpointConfig<ChatParticipantEndpointParams>(staffUrlBase),
    create: () => staffUrlBase,
    update: (id: string) => makeUrl(staffUrlBase, id),
    delete: (id: string) => makeUrl(staffUrlBase, id),
  },
}