import { action, autorun, flow, observable, reaction, when } from "mobx";
import moment from "moment";
import { AnyObject, ColorCodedState, FixMeAny, Nillable, Nullable, Undefinable } from "../base/@types";
import { ChatParticipantEndpoints } from "../base/endpoints/chatParticipant.endpoints";
import { ChatThreadEndpointDefaultInclude, ChatThreadEndpointParams, ChatThreadEndpoints } from "../base/endpoints/chatThread.endpoints";
import { ChatMediator, makeChatMediator } from "../base/mediators/chat.mediator";
import { sortArray } from "../base/utils/array.utils";
import { navigateToChatPage } from "../base/utils/chat.utils";
import { equalByString } from "../base/utils/equality.utils";
import { reportError } from "../base/utils/errors.utils";
import { take, uniq, uniqById } from "../base/utils/ramdaEquivalents.utils";
import { getNowTimestampUtc } from "../base/utils/time.utils";
import { makeUrl } from "../base/utils/url.utils";
import { ApiModelName } from "../constants/ApiModels.enum";
import { ModelName } from "../constants/modelNames.enum";
import { isInCypressTestMode, SHOULD_LOG } from "../env";
import { ChatParticipant } from "../models/makeChatParticipant.model";
import { ChatThread, ChatThreadSnapshot } from "../models/makeChatThread.model";
import { CounsellingSession } from "../models/makeCounsellingSession.model";
import { User } from "../models/makeUser.model";
import { getChatThread } from "../requests/getChatThread.request";
import { APIController, APIGetManyResponse } from "./api.controller";
import { AuthController } from "./auth.controller";
import { LocalDBController } from "./localDB.controller";
import { NavigatorController } from "./navigator.controller";
import { StorageController } from "./storage.controller";
import { TransceiverController } from "./transceiver.controller";
import { UIController } from "./ui.controller";
import { makeControllerBase, makeRootControllerChildInitFn } from "./_root.controller";

/**
 * Controls chat system and related UIs.
 * It relies on the "transceiver controller" to manage the necessary Pusher/Echo channels.
 * Note that "chat bots" are separated from the chat system. They are unrelated.
 */
export type MessengerController = ReturnType<typeof makeMessengerController>;

let _MESSENGER_INITIATED = false;

export const makeMessengerController = () => {

	const s = observable({
		...makeControllerBase('MESSENGER'),
		get API(): APIController { return s.ROOT!.children.API; },
		get AUTH(): Undefinable<AuthController> { return s.ROOT?.children.AUTH; },
		get LOCALDB(): LocalDBController { return s.ROOT!.children.LOCALDB; },
		get STORAGE(): StorageController { return s.ROOT!.children.STORAGE; },
		get TRANSCEIVER(): TransceiverController { return s.ROOT!.children.TRANSCEIVER; },
		get NAVIGATOR(): NavigatorController { return s.ROOT!.children.NAVIGATOR; },
		get UI(): UIController { return s.ROOT!.children.UI; },
		get currentUser(): Nillable<User> {
			return s.AUTH?.currentUser;
		},
		activeChatsRetrieved: false,
		errorRetrievingChats: null as Nullable<Error>,
		reset: action(() => {
			s.chats.forEach(chat => chat.dispose());
			s.chats.clear();
		}),
		get newMessages(): any[] {
			return [];
		},
		get numberOfActiveChats(): number {
			return s.activeChatsSortedByLatest.length;
		},
		chats: observable([] as ChatMediator[]),
		get chatsSortedByLatest(): ChatMediator[] {
			const sorted = sortArray(
				s.chats,
				{
					key: 'timeStarted',
					direction: 'desc',
					transformer: moment,
				}
			);
			if (s.AUTH?.isStaff) return uniqById(sorted);
			return uniqById(sorted.filter(chat => !chat.hasEnded));
		},
		get activeChatsSortedByLatest(): ChatMediator[] {
			return s.chatsSortedByLatest.filter(c => !c.hasEnded);
		},
		get instantChats(): ChatMediator[] {
			return s.chatsSortedByLatest.filter(t => t.type === 'default');
		},
		get counsellingSessionChats(): ChatMediator[] {
			return s.chatsSortedByLatest.filter(t => t.type === 'counselling-session');
		},
		get supportGroupChats(): ChatMediator[] {
			return s.chatsSortedByLatest.filter(t => t.type === 'support-group');
		},
		getChatById: async (id: string | undefined) => {
			if (!id) return null;
			const existingChat = s.chats.find(c => equalByString(c.id, id));
			return existingChat ?? await s.loadChatById(id);
		},
		get dockedChats(): ChatMediator[] {
			return uniqById(s.chats).filter(chat => chat.shouldKeepInDock);
		},
		get openedDockedChats(): ChatMediator[] {
			return s.dockedChats.filter(chat => chat.shouldOpenInDock);
		},
		get prefix() {
			const { area } = s.NAVIGATOR;
			if (area === 'admin') return 'admin';
			return 'app';
		},
		get isInChatModule(): boolean {
			const { currentLocationPathname } = s.NAVIGATOR;
			return /(app|admin)\/chats/.test(currentLocationPathname);
		},
		get shouldRenderChatsInDock(): boolean {
			return !s.isInChatModule && s.UI.fromTablet;
		},
		getActiveThreads: () => flow(function * () {
			try {
				s.errorRetrievingChats = null;
				yield when(() => s.AUTH?.fullCurrentUserInfoRetrieved || false);
				const params: ChatThreadEndpointParams = {
					own: true,
					perPage: -1,
					filterOutEmail: true,
					includeInstantThreads: true,
					include: ChatThreadEndpointDefaultInclude,
					filter: {
						whereNull: ['timeEnded'],
					}
				};
				const url = ChatThreadEndpoints.own.index(params);
				const { entries } = (yield s.API.getMany(url, ModelName.chatThreads)) as APIGetManyResponse<ChatThread>;
				const activeChats = entries.filter(thread => {
					const isThreadNotEnded = !thread.timeEnded;
					const isThreadModelNotEnded = thread.model ? !thread.model.timeEnded : true;
					if ((!isThreadModelNotEnded && isThreadNotEnded) || (isThreadModelNotEnded && !isThreadNotEnded)) {
						reportError(`REPORT: Chat thread ${thread.id} with timeEnded (${thread.timeEnded}) found with ${thread.modelType} model ${thread.model?.id ?? 'null model'} with timeEnded (${thread.model?.timeEnded ?? 'null model'}). Expected both to have ended or not ended.`);
					}
					return isThreadNotEnded && isThreadModelNotEnded;
				});
				const newChats = activeChats.filter((c: AnyObject) => !s.chats.find(chat => chat.id === c.id)) || [];
				s.patchSupportGroupParticipantsTimeJoined(newChats);
				const newControllers = newChats.map(c => makeChatMediator(s, c));
				s.chats.push(...newControllers);
				s.activeChatsRetrieved = true;
				if (s.chats.length >= 50) {
					s.UI.TOAST.present({
						body: 'You seem to have a lot of chat threads active. Please consider ending the chat sessions you no longer need. Some older chats might be hidden to prevent loading too much unnecessary data.',
						timeout: 0,
						colorCodedState: ColorCodedState.attention,
					});
				}
				openLatestTwo();
				return s.activeChatsSortedByLatest;
			} catch (e) {
				console.error('MessengerController failed to get chat threads.');
				s.errorRetrievingChats = e as Error;
				reportError(e);
			}
		})(),
		patchSupportGroupParticipantsTimeJoined: async (chatThreads: ChatThread[]) => {
			// PATCH SG participant timeJoined, needed by API to compute admin stats for num attendees in support groups.
			const sgChatThreads = chatThreads.filter(thread => thread.modelType === ApiModelName.SUPPORT_GROUP);
			const participantModels = sgChatThreads.reduce((accu, t) => ([...accu, ...t.participants]), [] as ChatParticipant[]);
			const userId = s.AUTH?.currentUser?.id ?? undefined;
			const userParticipantModels = participantModels.filter(p => ((p.userId === userId) ?? false));
			const falsyTimeJoinedParticipantModels = userParticipantModels.filter(p => !p.timeJoined);
			const timeJoined = getNowTimestampUtc();
			falsyTimeJoinedParticipantModels.forEach(p => {
				SHOULD_LOG() && console.log(`PATCHING participantId ${p.id} for userId ${userId} timeJoined as ${timeJoined}`);
				const url = ChatParticipantEndpoints.own.update(p.id);
				const payload = {timeJoined};
				s.API.patch(url, ModelName.chatParticipants, payload);
			});
		},
		getChatData: async (id: string) => {
			if (!s.AUTH?.isAuthenticated) {
				return null;
			}
			return await getChatThread(s.API, id, { include: ChatThreadEndpointDefaultInclude });
		},
		loadChatById: (id: string) => flow(function * () {
			console.log(`requested to load chat by id ${id}...`);
			console.log(s.activeChatsRetrieved);
			if (!s.AUTH?.isAuthenticated) {
				return null;
			}
			yield when(() => s.activeChatsRetrieved);
			try {
				console.log(`really loading chat by id ${id}...`);
				let thread: Nullable<ChatThread>;
				let chatMediator: ChatMediator;
				const existingChatMediator = s.chats.find(c => equalByString(c.id, id));
				if (!existingChatMediator) {
					console.log('existing chat mediator not found, loading chat id', id);
					thread = yield s.getChatData(id);
					console.log('chat thread returned:', thread);
					const currentUserIsParticipant = thread?.participants.find(part => part.userId === s.currentUser?.id);
					if (!thread || (thread?.timeEnded && !s.AUTH.isStaff) || (!currentUserIsParticipant && !s.AUTH.can.chat_.viewAnyChatHistory)) {
						s.UI.TOAST.attention('The chat session you tried to visit does not exist or is no longer live, or you do not have the permission to view its chat history.');
						if (s.UI.onlyPhones) {
							s.NAVIGATOR.navigateTo(s.NAVIGATOR.isInAdminArea ? '/admin/chats' : '/app/chats');
						}
						return null;
					}
					chatMediator = makeChatMediator(s, thread);
					s.chats.push(chatMediator);
				} else {
					chatMediator = existingChatMediator;
				}
				return chatMediator;
			} catch (e) {
				reportError(e);
				return null;
			}
		})(),
		createNewInstantChat: (userIdsToInvite?: string[]) => flow(function* () {
			console.log('creating new instant chat...');
			if (!s.AUTH?.canFacilitateChat) {
				s.UI.DIALOG.error({
					heading: 'Failed to start a new chat.',
					body: () => <>
						<p>It seems like you do not have the right permissions.</p>
						<p>Please contact an administrator if you believe this is an error.</p>
					</>,
				})
				return;
			}
			const userIds = [s.AUTH?.currentUser?.id, ...(userIdsToInvite || [])].filter(i => i) as string[];
			if (userIds.length === 0) {
				throw Error('At least one user required to start a chat');
			}
			try {
				const url = ChatThreadEndpoints.staff.create();
				const payload: Partial<ChatThreadSnapshot> = { participantIds: uniq(userIds).filter(i => i) }
				const chatThread: Nullable<ChatThread> = yield s.API.post<ChatThread>(url, ModelName.chatThreads, payload);
				if (!chatThread) {
					throw Error('Failed to create chat.');
				}
				const fullThread = yield s.API.get<ChatThread>(ChatThreadEndpoints.staff.get(chatThread.id, {
					include: ChatThreadEndpointDefaultInclude
				}), ModelName.chatThreads);
				const controller = makeChatMediator(s, fullThread);
				s.chats.push(controller);
				if (!controller) {
					throw Error('Failed to start a new chat; please try again later.');
				}
				if (s.UI.fromTablet) {
					controller.keepInDock();
				} else {
					s.NAVIGATOR.navigateTo(makeUrl('/', s.NAVIGATOR.isInAdminArea ? 'admin' : 'app', 'chats', chatThread.id));
				}
				return controller;
			} catch (e) {
				s.UI.DIALOG.error({
					heading: 'Failed to start a new chat; please try again later.',
					error: (e as AnyObject).response,
				})
			}
		})(),
	})

	function watchAuthChanges() {
		const disposeAuthStatusReaction = reaction(
			() => s.AUTH?.currentUser?.id,
			id => {
				if (id) onAuthenticate();
				else s.reset();
			},
			{ fireImmediately: true }
		);
		return disposeAuthStatusReaction;
	}

	const openLatestTwo = () => {
    if (s.UI.deviceType !== 'phone') {
			const chats = take(2, s.activeChatsSortedByLatest);
      chats.forEach(chat => {
				chat.keepInDock();
      })
    }
  }


	const onAuthenticate = () => {

		s.getActiveThreads();

		const initStatePersistenceSync = () => {
			const openedChatWindowsStorageId = ['messenger', 'openedChatThreadIds'];
			const focusedChatWindowsStorageId = ['messenger', 'focusedChatThreadIds'];
			let openedIds: string[] | undefined = undefined;
			let focusedIds: string[] | undefined = undefined;
			(async function getCache() {
				openedIds = await s.STORAGE.get(openedChatWindowsStorageId) || [];
				focusedIds = await s.STORAGE.get(focusedChatWindowsStorageId) || [];
			})();
			let disposeAutorun: Function;
			const disposeWhen = when(() => {
				return s.chatsSortedByLatest.length !== 0 && !!openedIds && !!focusedIds;
			}, () => {
				if (openedIds instanceof Array) {
					const chatsToOpen = s.chatsSortedByLatest.filter(c => openedIds!.includes(c.id));
					chatsToOpen.forEach(action(chat => chat.shouldKeepInDock = true));
				}
				if (focusedIds instanceof Array) {
					const chatsToFocus = s.chatsSortedByLatest.filter(c => focusedIds!.includes(c.id));
					chatsToFocus.forEach(action(chat => chat.shouldOpenInDock = true));
				}
				(function autoSave() {
					disposeAutorun = autorun(() => {
						s.STORAGE.set(openedChatWindowsStorageId, s.dockedChats.map(c => c.id));
						s.STORAGE.set(focusedChatWindowsStorageId, s.openedDockedChats.map(c => c.id));
					})
				})();
			});
			return () => {
				disposeAutorun && disposeAutorun();
				disposeWhen()
			}
		}

		const autoOpenLatestTwoChats = () => {
			openLatestTwo();
		}

		// always open chats for clients when there are more than one active chat on load
		when(() => s.activeChatsSortedByLatest.length > 0, autoOpenLatestTwoChats)

		let modeSpecificControlDisposer: Function | undefined = undefined;

		modeSpecificControlDisposer = initStatePersistenceSync();

		const listenToEventStartEvents = (channel: FixMeAny) => {
			channel?.instance.listen('.event.started', (e: FixMeAny) => {
				const { NAVIGATOR } = s.ROOT!.children;
				console.log('[UserPrivateChannel] Thread started event received', e);
				s.getActiveThreads();
				if (s.UI.onlyPhones && e.event?.id) {
					s.UI.TOAST.present({
						heading: 'A new chat session has started! Tap here to join.',
						colorCodedState: ColorCodedState.positive,
						action: () => {
							navigateToChatPage(NAVIGATOR, e.event?.id);
						}
					});
				} else {
					s.UI.TOAST.present({
						heading: 'A new chat session has started! Please check your chats tab for details.',
						colorCodedState: ColorCodedState.positive,
					});
				}
				openLatestTwo();
			});
		}

		const listenToCounsellingSessionStartEvents = (channel: FixMeAny) => {
			channel?.instance.listen('.session.started', async (e: { session: CounsellingSession }) => {
				console.log('[UserPrivateChannel] Counselling session started event received', e);
				if (e.session.type === 'email') {
					console.log('The new session is an email session. No notification needed.');
					return;
				}
				await s.getActiveThreads();
				if (!isInCypressTestMode) {
					s.UI.TOAST.present({
						heading: 'A new counselling session has started! Please see your Chats tab for details.',
						colorCodedState: ColorCodedState.positive,
					});
				}
				openLatestTwo();
			});
		}

		const listenToSupportGroupSessionStartEvents = (channel: FixMeAny) => {
			channel?.instance.listen('.supportgroup.started', async (e: FixMeAny) => {
				console.log('[UserPrivateChannel] Support group started event received', e);
				await s.getActiveThreads();
				s.UI.TOAST.positive('A new support group session has started! Please see your chat panel for details.');
				openLatestTwo();
			});
		}

		const listenToParticipantUpdateEvents = (channel: FixMeAny) => {
			channel?.instance.listen('.participant.update', async (e: FixMeAny) => {
				console.log('[UserPrivateChannel] Participant updated event received', e);
				if (s.LOCALDB.get(ModelName.chatParticipants, e.participant.id)) {
					console.log('participant update received, but this participant is not new. not refetching all chats. this update should be caught by the chat channel if it\'s still open.');
					return;
				}
				await s.getActiveThreads();
				const chat = s.chats.find(c => c.participants.find(p => equalByString(p.id, e.participant.id)))
				if (chat?.timeEnded) return;
				if (chat?.selfParticipant?.timeRemoved) return;
				if (chat?.selfParticipant?.user?.isStaff) return;
				const displayName = chat ? chat.type === 'support-group' ? 'support group session' : chat.type === 'counselling-session' ? 'counselling session' : 'chat session' : 'chat session';
				s.UI.DIALOG.positive({
					name: 'invited-to-new-chat',
					heading: `You have been invited to a new ${displayName}. Please see your chat panel for details.`,
					defaultActions: ['positive'],
				});
			});
		}

		const disposeUserPrivateChannelReaction = reaction(
			() => s.TRANSCEIVER.userPrivateChannel,
			channel => {
				if (!channel) return;
				try {
					listenToEventStartEvents(channel);
					listenToCounsellingSessionStartEvents(channel);
					listenToSupportGroupSessionStartEvents(channel);
					listenToParticipantUpdateEvents(channel);
				} catch (e) {
					reportError(e);
				}
			},
			{ fireImmediately: true }
		)

		return () => {
			modeSpecificControlDisposer && modeSpecificControlDisposer();
			disposeUserPrivateChannelReaction();
		}
	}

	s.init = makeRootControllerChildInitFn(
		s,
		action(() => {
			if (!s.TRANSCEIVER.ready) throw Error('Messenger requires Transceiver to be initiated first.');
			if (_MESSENGER_INITIATED) throw Error('Messenger had been initiated already.');
			watchAuthChanges();
			s.ready = true;
			_MESSENGER_INITIATED = true;
		})
	);

	return s;
}