import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState } from '../../../../../base/@types';
import AppPage from '../../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../../base/components/AppPageHeader/AppPageHeader';
import BackButton from '../../../../../base/components/BackButton/BackButton';
import BaseButton from '../../../../../base/components/BaseButton/BaseButton';
import BaseButtonGroup from '../../../../../base/components/BaseButtonGroup/BaseButtonGroup';
import BaseInput from '../../../../../base/components/BaseInput/BaseInput';
import InfoBanner from '../../../../../base/components/InfoBanner/InfoBanner';
import ShadedBlock from '../../../../../base/components/ShadedBlock/ShadedBlock';
import UIBlock from '../../../../../base/components/UIBlock/UIBlock';
import { AuthEndpoints } from '../../../../../base/endpoints/auth.endpoints';
import { useControllers } from '../../../../../base/hooks/useRootController.hook';
import { reportError } from '../../../../../base/utils/errors.utils';
import { useStore } from '../../../../../base/utils/mobx.utils';
import './AccountPageUpdatePassword.scss';

type AccountPageUpdatePasswordProps = {}

const AccountPageUpdatePassword: React.FC<AccountPageUpdatePasswordProps> = props => {

  const { API, UI, AUTH } = useControllers();

  const s = useStore(() => ({
    form: {
      previousPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    get newPasswordMatches() {
      return s.form.newPassword === s.form.confirmNewPassword;
    },
    get passwordIsValid() {
      return s.form.newPassword.length >= 8;
    },
    get formIsValid() {
      return s.form.previousPassword && s.passwordIsValid && s.newPasswordMatches;
    },
    awaitingResponse: false,
    save: async () => await flow(function * () {
      if (!s.newPasswordMatches) {
        UI.DIALOG.attention({
          heading: 'Please make sure that your new password fields match.',
        })
      }
      const url = AuthEndpoints.password.update();
      const payload = {
        previousPassword: s.form.previousPassword,
        newPassword: s.form.newPassword
      }
      try {
        s.awaitingResponse = true;
        yield API.patchRaw(url, payload);
        UI.DIALOG.success({
          heading: "Password Updated!",
        })
        s.form.previousPassword = '';
        s.form.newPassword = '';
        s.form.confirmNewPassword = '';
      } catch (e) {
        reportError(e);
        UI.DIALOG.error({
          heading: "Failed to update your password",
          error: e,
        })
      } finally {
        s.awaitingResponse = false;
        AUTH.fetchCurrentUserInfo();
      }
    })(),
  }))

  return <Observer children={() => (
    <AppPage className="AccountPageUpdatePassword"
      accommodateTitleBarSpace
    >
      <AppPageHeader
        title="Update Password"
        startSlot={<BackButton destination="up" />}
      />
      <AppPageContent>
        <UIBlock padded>
          <InfoBanner icon="info" colorCodedState={ColorCodedState.positive}>
            <p>To update your password, please enter your current password and then pick a new password that is <strong>at minimum 8 characters long</strong>.</p>
          </InfoBanner>
          <ShadedBlock spaceChildren>
            <BaseInput form={s.form} field="previousPassword" label="Current password" required type="password" disabled={s.awaitingResponse}/>
            <BaseInput form={s.form} field="newPassword" label="Enter a new password" required type="password" disabled={s.awaitingResponse}/>
            <BaseInput form={s.form} field="confirmNewPassword" label="Confirm password" required type="password" disabled={s.awaitingResponse}/>
            {
              s.form.confirmNewPassword && (s.form.newPassword !== s.form.confirmNewPassword) && s.passwordIsValid && <InfoBanner colorCodedState={ColorCodedState.attention} icon="info">
                <p>Please make sure that your new password fields match.</p>
              </InfoBanner>
            }
            {
              s.form.confirmNewPassword && (s.form.newPassword === s.form.confirmNewPassword) && !s.passwordIsValid && <InfoBanner colorCodedState={ColorCodedState.attention} icon="info">
                <p>Please make sure that your new password is at least 8 characters long.</p>
              </InfoBanner>
            }
            <BaseButtonGroup>
              <BaseButton
                label="Confirm Update Password"
                dataCy="confirm-update-password"
                color="green"
                onClick={s.save}
                disabled={!s.formIsValid}
              />
            </BaseButtonGroup>
          </ShadedBlock>
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
}

export default AccountPageUpdatePassword;