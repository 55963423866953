import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Alert } from '../../../@types/alert.types';
import LoadingIndicatorSection from '../../../base/components/LoadingIndicatorSection/LoadingIndicatorSection';
import { useControllers } from '../../../base/hooks/useRootController.hook';
import { useStore } from '../../../base/utils/mobx.utils';
import { isSameWeek, isToday, isYesterday } from '../../../base/utils/time.utils';
import AlertCardList from '../../../components/AlertCardList/AlertCardList';
import ClientAppSubmodule from '../_components/ClientAppSubmodule/ClientAppSubmodule';
import './ModuleNotifications.scss';

interface ModuleNotificationsProps {}

const ModuleNotifications: React.FC<
  ModuleNotificationsProps
> = props => {

  const { ALERTS } = useControllers();

  const s = useStore(() => ({
    get alerts() {
      return ALERTS.unreadAlerts
    },
    get groupedByTime() {
      const result: {heading: string, alerts: Alert[]}[] = [
        'Today',
        'Yesterday',
        'Earlier this week',
        'Earlier Notifications',
      ].map(i => ({heading: i, alerts: []}));
      s.alerts.forEach(n => {
        if (isToday(n.timeCreated)) result[0].alerts.push(n);
        else if (isYesterday(n.timeCreated)) result[1].alerts.push(n);
        else if (isSameWeek(n.timeCreated)) result[2].alerts.push(n);
        else result[3].alerts.push(n);
      })
      return result.filter(r => r.alerts.length > 0);
    },
  }));

  return <Observer children={() => (
    <ClientAppSubmodule name="Notifications" title="Notifications">
      <div className="ModuleNotificationsInner">
        {
          s.alerts.length === 0 ? (
            ALERTS.firstDataRetrieved ?
              <h3 className="ModuleNotificationsEmpty">No Notifications Yet!</h3>
              : <LoadingIndicatorSection />
          ) : (
              s.groupedByTime.map(g => <AlertCardList
                key={g.heading}
                heading={g.heading}
                alerts={g.alerts}
                withColor
              />)
            )
        }
      </div>
    </ClientAppSubmodule>
  )} />

}

export default ModuleNotifications
