import { action, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState, Nullable } from '../../../../../base/@types';
import BaseSpacer from '../../../../../base/components/BaseSpacer/BaseSpacer';
import ClickOrTap from '../../../../../base/components/ClickOrTap/ClickOrTap';
import ColorTagCompany from '../../../../../base/components/ColorTag/ColorTagCompany';
import DatePicker from '../../../../../base/components/DatePicker/DatePicker';
import InfoBanner from '../../../../../base/components/InfoBanner/InfoBanner';
import PseudoLink from '../../../../../base/components/PseudoLink/PseudoLink';
import ShadedBlock from '../../../../../base/components/ShadedBlock/ShadedBlock';
import { useOnMount } from '../../../../../base/hooks/lifecycle.hooks';
import { useObservableRef } from '../../../../../base/hooks/useObservableRef.hook';
import { useControllers } from '../../../../../base/hooks/useRootController.hook';
import { getScrollParent } from '../../../../../base/utils/dom.utils';
import { immediateReaction, useStore } from '../../../../../base/utils/mobx.utils';
import { scrollElementTo } from '../../../../../base/utils/scrollElementTo.utils';
import { createUTCMoment, ddddMMMMDoYYYYHHmm } from '../../../../../base/utils/time.utils';
import UserFullNameRenderer from '../../../../../components/UserFullNameRenderer/UserFullNameRenderer';
import { ApplicationTypeOption, CounsellingType } from '../../../../../constants/counsellingTypes.descriptors';
import { ModelName } from '../../../../../constants/modelNames.enum';
import { CounsellingAvailability } from '../../../../../models/makeCounsellingAvailability.model';
import { getAgeFromDateOfBirth, isYoungPeopleAge1214, isYoungPeopleAge1517 } from '../../../../../utils/ageAndDateOfBirth.utils';
import { isOfCounsellingTypeYoungPeople } from '../../../../../utils/counsellingApplication.utils';
import { CounsellingApplicationStepProps } from '../applicationStepDescriptors';
import CounsellingSessionTimePicker from '../components/CounsellingSessionTimePicker/CounsellingSessionTimePicker';
import './CounsellingApplicationStepTimeSelection.scss';

interface CounsellingApplicationStepTimeSelectionProps extends CounsellingApplicationStepProps {}

const CounsellingApplicationStepTimeSelection: React.FC<CounsellingApplicationStepTimeSelectionProps> = p => {

  const { COMMON, LOCALDB, COUNSELLING } = useControllers();

  const s = useStore(() => ({
    selectAvailability: action((avail: Nullable<CounsellingAvailability>) => {
      p.form.application.timePreferredForFirstSession = avail?.timeStart ?? null;
      p.form.application.counsellorId = avail?.counsellorId ?? null;
      p.form.application.availabilityId = avail?.id ?? null;
    }),
    get selectedAvailability() {
      return p.form.application.availabilityId ? LOCALDB.get<CounsellingAvailability>(ModelName.counsellingAvailabilities, p.form.application.availabilityId) : null;
    },
    get availableTypesFormatted() {
      if (!s.selectedAvailability?.communicationTypesAllowed) return '';
      if (s.selectedAvailability.communicationTypesAllowed.length > 0) return s.selectedAvailability?.communicationTypesAllowed.map(t => t).join(', ');
      return 'See next page for available types.';
    },
    get inviteeAge() {
      return getAgeFromDateOfBirth(p.form.invitation.dateOfBirth);
    },
    get inviteeIsTooYoung() {
      return s.inviteeAge && s.inviteeAge < 12;
    },
    get inviteeIsAdult() {
      return s.inviteeAge && s.inviteeAge >= 18;
    },
    get forCounsellingType() {
      return p.form.selectedApplicationType === ApplicationTypeOption.YoungPeople ? (
        isYoungPeopleAge1517(s.inviteeAge) ? CounsellingType.YoungPeople1517
          : isYoungPeopleAge1214(s.inviteeAge) ? CounsellingType.YoungPeople1214
          : null
      ) : p.form.selectedApplicationType as string as CounsellingType
    },
    get isYoungPeopleApplication() {
      return isOfCounsellingTypeYoungPeople(p.form.selectedApplicationType)
    },
    clearAvailability: action(() => {
      s.selectAvailability(null);
    })
  }))

  useOnMount(() => {
    const disposers: Function[] = []
    disposers.push(immediateReaction(
      () => s.forCounsellingType,
      () => {
        if (s.forCounsellingType) p.form.application.type = s.forCounsellingType;
        s.clearAvailability()
      }
    ));
    disposers.push(reaction(
      () => s.selectedAvailability?.id,
      () => {
        if (!ref.current) return;
        const parent = getScrollParent(ref.current);
        if (parent) {
          scrollElementTo({el: parent, top: parent.scrollHeight});
        }
      }
    ))
    return () => disposers.forEach(d => d());
  })

  const ref = useObservableRef();

  return <Observer children={() => (
    <div className="CounsellingApplicationStepTimeSelection" ref={ref}>
      {
        s.isYoungPeopleApplication && <>
          <ShadedBlock>
            <p><strong>Please enter the date of birth of your child, as the availabilities are age-specific.</strong></p>
            <br />
            <DatePicker form={p.form.invitation} field="dateOfBirth" shouldUseSystemUIOnMobile />
            {
              (s.inviteeIsTooYoung || s.inviteeIsAdult) && <InfoBanner colorCodedState={ColorCodedState.warning} icon="warning">
                <p>Your child must be between age 12 and 17 in order to be eligible for the service.</p>
              </InfoBanner>
            }
          </ShadedBlock>
        </>
      }
      {
        s.forCounsellingType && <>
          <BaseSpacer />
          <blockquote>
            <p>Please select preferred date and time for the first counselling session from the calendar below.</p>
            <p>The session usually lasts about 50 min to 1 hour.</p>
          </blockquote>
          <BaseSpacer size="1em" />
          <CounsellingSessionTimePicker
            selectedSlotId={(p.form.application.availabilityId)}
            onChange={s.selectAvailability}
            forCounsellingType={s.forCounsellingType}
          />
          <BaseSpacer />
          {
            p.form.application.timePreferredForFirstSession ? <div className="CounsellingApplicationStepTimeSelectionDisplay">
              <p><strong>You have selected:</strong></p>
              <time>{createUTCMoment(p.form.application.timePreferredForFirstSession).local().format(ddddMMMMDoYYYYHHmm)}</time>
              <h3>With Counsellor <strong><UserFullNameRenderer userId={s.selectedAvailability?.counsellorId} showColorLabel={false}/></strong></h3>
              <p>Timezone: {COMMON.timezone}</p>
              {s.availableTypesFormatted && <p>Available session types: {s.availableTypesFormatted}</p>}
              {s.selectedAvailability?.allowedCompany?.name && <p><ColorTagCompany name={s.selectedAvailability?.allowedCompany?.name} code={s.selectedAvailability?.allowedCompany?.code} /></p>}
            </div> : <div>
              {
                COUNSELLING.isLoadingCounsellingAvailabilities ? <p><em>Checking for new availabilities...</em></p> : (
                  <p>If you cannot find an available suitable time slot, you can <PseudoLink onClick={COUNSELLING.getCounsellingAvailabilities}><ClickOrTap /> here to check for updates now</PseudoLink> or come back later.</p>
                )
              }
            </div>
          }
        </>
      }
    </div>
  )} />

}

export default CounsellingApplicationStepTimeSelection;