import { action, autorun, flow, reaction, runInAction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { GenericFunction } from '../../@types';
import { TimezoneMode } from '../../@types/time.types';
import { HasId, HasInitiatorModel, HasIsDisabled, SchedulableTimedEvent } from '../../@types/traits.types';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useControllers } from '../../hooks/useRootController.hook';
import { pushToOrReplaceItemsInArray } from '../../utils/array.utils';
import joinClassName from '../../utils/className.utils';
import { reportError } from '../../utils/errors.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { YYYYMMDD } from '../../utils/time.utils';
import tick from '../../utils/waiters.utils';
import LoadingBlocker from '../LoadingBlocker/LoadingBlocker';
import { OptionsPanelSectionProps } from '../OptionsPanel/OptionsPanelSection';
import './BaseCalendar.scss';
import BaseCalendarBody from './BaseCalendarBody';
import { BaseCalendarEventProps } from './BaseCalendarEvent';
import BaseCalendarHeader from './BaseCalendarHeader';
import BaseCalendarOptionsPanel from './BaseCalendarOptionsPanel';
import { BaseCalendarState, IBaseCalendarStateOptions } from './BaseCalendarState';
import BaseCalendarWeekHeader from './BaseCalendarWeekHeader';

export type BaseCalendarBuiltInOptionSectionNames = 'detailLevel' | 'viewOptions' | 'eventTypeToggleGroups';
export type BaseCalendarEventConfig<EventDataType = object> =
  SchedulableTimedEvent &
  HasId &
  Partial<HasIsDisabled> &
  Partial<HasInitiatorModel> & {
  componentProps?: {
    className?: string,
    onClick?: (e?: BaseCalendarEventConfig) => void,
  },
  data?: EventDataType,
  hidden?: boolean,
  color?: string,
};

export type BaseCalendarStateSnapshot = Partial<IBaseCalendarStateOptions> & {
  inactiveEventTypesByGroup: {name: string, inactiveEventTypeNames: string[]}[],
}

export type BaseCalendarDataFetcher<EventDataType = object> = (startTime: string, endTime: string) => Promise<BaseCalendarEventConfig<EventDataType>[]>;
export type BaseCalendarEventCreator<EventDataType = object> = (startTime: string, eventHolderArray?: BaseCalendarEventConfig<EventDataType>[]) => Promise<BaseCalendarEventConfig<EventDataType>>;

export interface BaseCalendarBaseProps<EventDataType = object> {
  className?: string;
  timezoneMode?: TimezoneMode,
  state: BaseCalendarState<EventDataType>,
  events?: BaseCalendarEventConfig<EventDataType>[],
  onEventClick?: (e?: BaseCalendarEventConfig<EventDataType>) => void,
  storageKey?: string | string[],
  shouldPersistState?: boolean,
  eventRenderer?: React.ComponentClass<BaseCalendarEventProps<EventDataType>> | React.FC<BaseCalendarEventProps<EventDataType>>;
  eventBuilder?: (d: EventDataType) => BaseCalendarEventConfig<EventDataType>,
  eventCreator?: BaseCalendarEventCreator<EventDataType>,
  dataFetcher?: BaseCalendarDataFetcher<EventDataType>;
  onNavigate?: (newStartDate: string, calendarState: BaseCalendarState<EventDataType>) => void;
  showHeader?: boolean;
  onFirstDataLoad?: (d: EventDataType[]) => void,
  eventDataFilter?: (d: EventDataType) => boolean,
  dataFetcherTriggerString?: string,
}

export type BaseCalendarBaseChildProps<EventDataType = object> = BaseCalendarBaseProps<EventDataType> & {
  internalCalendarEvents: BaseCalendarEventConfig<EventDataType>[],
}

export interface BaseCalendarProps<EventDataType = object> extends BaseCalendarBaseProps<EventDataType> {
  optionSections?: (BaseCalendarBuiltInOptionSectionNames | OptionsPanelSectionProps)[],
}

const BaseCalendar = <EventDataType extends object>(props: React.PropsWithChildren<BaseCalendarProps<EventDataType>>) => {

  const { STORAGE, UI } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    get calendarState() {
      return p.state;
    },
    get storageKey(): string[] {
      return [...[p.storageKey].flat(), s.calendarState.name] as string[];
    },
    internalCalendarEvents: [] as BaseCalendarEventConfig<EventDataType>[],
    get calendarEvents() {
      const events = [...s.internalCalendarEvents, ...p.events || []];
      if (p.eventDataFilter) return events.filter(e => e.data ? p.eventDataFilter!(e.data) : true);
      return events;
    },
    hasReadFromStorage: true,
    dataFetcherState: {
      isLoading: false,
      error: null as Error | null,
    },
    firstDataLoaded: false,
  }));

  const onNavigate = (newStartDate: string) => {
    p.onNavigate && p.onNavigate(newStartDate, s.calendarState);
    runDataFetcher(newStartDate);
  }

  const runDataFetcher = flow(function * (startTime?: string) {
    try {
      // console.log('[BaseCalendar] runDataFetcher called', p.dataFetcher);
      if (!p.dataFetcher) return;
      const endTime = s.calendarState.endDateMoment.add(1, 'day').format(YYYYMMDD);
      s.dataFetcherState.isLoading = true;
      const newEvents: BaseCalendarEventConfig<EventDataType>[] = yield p.dataFetcher(startTime || s.calendarState.startDate, endTime);
      pushToOrReplaceItemsInArray<BaseCalendarEventConfig<EventDataType>>(s.internalCalendarEvents, newEvents, (e, arr) => {
        // @ts-ignore
        const { id } = e || {};
        if (!id) return -1;
        return arr.findIndex(a => a.id && (a.id + '' === id + ''));
      })
      if (!s.firstDataLoaded) {
        p.onFirstDataLoad?.(newEvents.map(e => e.data) as EventDataType[]);
        s.firstDataLoaded = true;
      }
    } catch(e) {
      s.dataFetcherState.error = e as Error;
      reportError(e);
      UI.TOAST.attention('The calendar failed to fetch data; please try again or try refreshing the page.', 10000);
    } finally {
      s.dataFetcherState.isLoading = false;
    }
  })

  function readCalendarStateFromStorage() {
    STORAGE.get(s.storageKey).then(action((snapshot: BaseCalendarStateSnapshot) => {
      if (snapshot) {
        try {
          // @ts-ignore
          Object.entries(snapshot).forEach(entry => (entry[0] in s.calendarState) && (s.calendarState[entry[0]] = entry[1]));
          snapshot.inactiveEventTypesByGroup?.forEach(group => {
            const eventTypeGroup = s.calendarState.eventTypeGroups.find(g => g.name === group.name);
            eventTypeGroup?.eventTypes.forEach(et => et.isActive = !group.inactiveEventTypeNames.includes(et.name));
          });
        }
        catch (e) { reportError(e) }
        finally {
          s.hasReadFromStorage = true;
        }
      }
      runDataFetcher();
    }))
  }

  const persistToStorage = () => {
    const { name, mode, fullHeight, fitInnerToAvailableHeight, showEventEndTimes, eventTypeGroups } = s.calendarState;
    const inactiveEventTypesByGroup = eventTypeGroups.map(g => ({
      name: g.name,
      inactiveEventTypeNames: g.eventTypes.filter(et => !et.isActive).map(et => et.name)
    }))
    const snapshot: BaseCalendarStateSnapshot = { name, mode, fullHeight, fitInnerToAvailableHeight, showEventEndTimes, inactiveEventTypesByGroup };
    STORAGE.set(s.storageKey, snapshot);
  }

  useOnMount(() => {
    const disposers = [] as GenericFunction[];
    (async () => {
      await tick(100);
      if (!p.shouldPersistState) {
        runInAction(() => s.hasReadFromStorage = true);
        runDataFetcher();
      } else {
        readCalendarStateFromStorage();
        disposers.push(autorun(persistToStorage));
      }
      disposers.push(reaction(
        () => s.calendarState.mode,
        () => {
          runDataFetcher();
        }
      ));
      disposers.push(reaction(
        () => `${p.dataFetcher}_${p.dataFetcherTriggerString}`,
        () => {
          s.internalCalendarEvents.splice(0);
          runDataFetcher();
        }
      ));
    })()
    return () => disposers.forEach(fn => fn());
  });

  return <Observer children={() => (
    <section className={
      joinClassName(
        'BaseCalendar',
        p.className,
        s.calendarState.fullHeight && 'fullHeight',
        s.calendarState.fitInnerToAvailableHeight && 'fitInnerToAvailableHeight',
        p.optionSections && 'hasOptionsPanel',
        // (s.calendarState.shouldDisplayAsTimeline) && 'shouldDisplayAsTimeline',
      )
    } data-view-mode={s.calendarState.mode} data-loading={s.dataFetcherState.isLoading}>
      { p.optionSections && <BaseCalendarOptionsPanel<EventDataType> {...props} events={s.calendarEvents} />}
      <div className="BaseCalendarInner">
        {p.showHeader && <BaseCalendarHeader<EventDataType> {...props} internalCalendarEvents={s.calendarEvents} onNavigate={onNavigate} />}
        {p.showHeader || <BaseCalendarWeekHeader format="singleChar" />}
        <BaseCalendarBody<EventDataType> {...props} internalCalendarEvents={s.calendarEvents} />
      </div>

      { s.dataFetcherState.isLoading && <LoadingBlocker /> }

    </section>
  )} />

}

export default React.memo(BaseCalendar) as <EventDataType extends object>(props: React.PropsWithChildren<BaseCalendarProps<EventDataType>>) => JSX.Element;