import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { SetupIntent, StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { AxiosError } from 'axios';
import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject, ColorCodedState } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseGrid from '../../base/components/BaseGrid/BaseGrid';
import BaseGridCell from '../../base/components/BaseGrid/BaseGridCell';
import BaseInput from '../../base/components/BaseInput/BaseInput';
import CurrencyRenderer from '../../base/components/CurrencyRenderer/CurrencyRenderer';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import InfoBanner from '../../base/components/InfoBanner/InfoBanner';
import { PaymentEndpoints } from '../../base/endpoints/payment.endpoints';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { autoPluralize } from '../../base/utils/string.utils';
import { asyncGetRecaptchaToken, checkErrorForRecaptchaFailure } from '../../utils/loadRecaptcha.utils';
import PaymentCardForm from '../PaymentCardForm/PaymentCardForm';
import './OnceOffVisitorPaymentForm.scss';

interface OnceOffVisitorPaymentFormProps {
  amount: number,
  onSuccess?: () => unknown,
  onError?: (e: AxiosError | unknown) => unknown,
  buttonLabelRenderer?: (amount?: number | null) => React.ReactElement,
  confirmationMessageRenderer?: (amount?: number | null) => React.ReactElement,
}

const OnceOffVisitorPaymentForm: React.FC<OnceOffVisitorPaymentFormProps> = props => {

  const p = useProps(props);

  const { API, AUTH, UI } = useControllers();

  const s = useStore(() => ({
    form: {
      cardHolderName: '',
      email: AUTH.currentUser?.email,
      message: '',
    },
    setupIntent: undefined as SetupIntent | undefined,
    lastEvent: undefined as StripeCardElementChangeEvent | undefined,
    get error() {
      return s.lastEvent?.error;
    },
    get completed() {
      return s.lastEvent?.complete;
    },
    get canSubmit() {
      return Boolean(
        p.amount >= 1 &&
        s.completed &&
        !s.error &&
        !!s.form.cardHolderName &&
        !s.messageTooLong
      );
    },
    hasSubmitted: false,
    get messageTooLong() {
      return s.form.message.length > 22;
    },
    recaptchaVerified: false,
  }));


  const onCardChange = action((e?: StripeCardElementChangeEvent) => {
    s.lastEvent = e;
  })

  const stripe = useStripe();
  const elements = useElements();

  const pay = () => new Promise<boolean>(async (resolve, reject) => {
    if (!p.amount || p.amount < 1) {
      reject('The payment total must be bigger than 1.');
      return;
    }
    const defaultConfirmationMessageRenderer = (a?: number | null) => <>Confirm payment of <CurrencyRenderer value={p.amount} /></>
    const confirmationMessageRenderer = p.confirmationMessageRenderer || defaultConfirmationMessageRenderer;

    const confirm = await UI.DIALOG.present({
      name: 'confirm-payment',
      heading: () => confirmationMessageRenderer(p.amount),
      body: () => <p>You will receive a receipt in an confirmation email.</p>
    })
    if (!confirm) return;
    try {
      if (!stripe) {
        reject('Stripe has not been not initiated.');
        return;
      }

      const card = elements?.getElement(CardElement);
      if (!card) {
        reject('No Stripe form found in the application.');
        return;
      }


      // let recaptchaToken = null as null | string;
      try {
        // recaptchaToken = await asyncGetRecaptchaToken('stripe_once_off_payment', UI);
        await asyncGetRecaptchaToken('stripe_once_off_payment', UI, API);
      } catch(e) {
        reject(e);
        checkErrorForRecaptchaFailure(e);
        UI.DIALOG.error({
          heading: 'You have not passed the Recaptcha test. Please try again.',
          body: <ErrorRenderer error={(e as AnyObject).response} />,
        })
        return;
      }

      const stripeCreateTokenResult = await stripe?.createToken(card);
      if (stripeCreateTokenResult.error) {
        reject(stripeCreateTokenResult.error.message)
        UI.DIALOG.error({
          heading: 'Error processing your card.',
          body: stripeCreateTokenResult.error.message,
        })
        return;
      }

      const stripeToken = stripeCreateTokenResult.token?.id;
      if (!stripeToken) {
        UI.DIALOG.error({ heading: 'Error processing your card.' })
        reject('Error processing your card.');
        return;
      }

      const clientSecret = (await API.postRaw(PaymentEndpoints.public.tokenise(), {
        donationAmount: props.amount * 100,
      }) as any)?.clientSecret;

      if (!clientSecret) {
        UI.DIALOG.error({ heading: 'Error connecting to payment service. Please try again later.' })
        reject('Error connecting to payment service. Please try again later.');
        return;
      }

      const response = await stripe.confirmCardPayment(clientSecret, {
        receipt_email: s.form.email!,
        payment_method: {
          card,
          billing_details: {
            name: s.form.cardHolderName,
            email: s.form.email ?? AUTH.currentUser?.email ?? undefined,
          }
        }
      })

      if (response.error) {
        UI.DIALOG.error({ heading: response.error.message })
        reject(response.error.message);
        return;
      }

      const payload = {
        amount: props.amount,
        cardHolderName: s.form.cardHolderName,
        email: s.form.email,
        message: s.form.message,
      }

      const url = PaymentEndpoints.public.reportSingleChargeSuccess();
      await API.postRaw(url, payload);

      s.hasSubmitted = true;
      p.onSuccess && p.onSuccess();
      resolve(true);

    } catch(e) {
      reject(e);
      p.onError?.(e);
    }

  });

  const defaultButtonLabelRenderer = (a?: number | null) => <>Pay <CurrencyRenderer value={p.amount} /></>
  const buttonLabelRenderer = p.buttonLabelRenderer || defaultButtonLabelRenderer;

  return <Observer children={() => (
    <div className="OnceOffVisitorPaymentForm">
      <BaseGrid gap="1em" columns={2}>
        <BaseInput label="Card Holder Name" form={s.form} field="cardHolderName" autoComplete="full-name" required />
        <BaseInput label="Email" form={s.form} field="email" autoComplete="email" type="email" optional />
        <BaseGridCell columns="all">
          <PaymentCardForm onChange={onCardChange} />
        </BaseGridCell>
        <BaseGridCell columns="all">
          <BaseInput label="Add a message" form={s.form} field="message" type="textarea" optional infoAfterInputField="Maximum 22 characters; must contain at least one alphabetical letter." />
          {
            s.messageTooLong && <InfoBanner colorCodedState={ColorCodedState.alert}>
              <p>The message is limited to 22 characters at most. You are <strong>{autoPluralize(s.form.message.length - 22, 'character')}</strong> over the limit.</p>
            </InfoBanner>
          }
        </BaseGridCell>
        <BaseGridCell columns="all" justifySelf="center">
          <BaseButton onClick={pay} size="lg" icon="arrow" rounded disabled={!s.canSubmit}>
            {buttonLabelRenderer(p.amount)}
          </BaseButton>
        </BaseGridCell>
      </BaseGrid>
    </div>
  )} />
}

export default OnceOffVisitorPaymentForm;