import { action, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { Timeframe } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseButtonGroup from '../../base/components/BaseButtonGroup/BaseButtonGroup';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useCreateResizeQueryWithRef } from '../../base/hooks/useCreateResizeQueryWithRef.hook';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { AlwaysTrueFn, cond } from '../../base/utils/ramdaEquivalents.utils';
import { createLocalMoment, YYYYMMDD } from '../../base/utils/time.utils';
import { removeUrlParams, setUrlParam } from '../../base/utils/urlParams.utils';
import OverlayTimeframeQuickPicker from '../OverlayTimeframeQuickPicker/OverlayTimeframeQuickPicker';
import './TimeframeSelectorSet.scss';

interface TimeframeSelectorSetProps {
  timeframe: Timeframe,
  onChange?: (timeframe: Timeframe) => void,
}

const TimeframeSelectorSet: React.FC<TimeframeSelectorSetProps> = props => {

  const { ref, query } = useCreateResizeQueryWithRef();

  const { UI } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    form: p.timeframe as Timeframe,
    get timeframeDisplay() {
      const sameYear = moment(p.timeframe.startDate).isSame(p.timeframe.endDate, 'year');
      return cond([
        [
          () => Boolean(p.timeframe.startDate && p.timeframe.endDate),
          () => <>From <DateRenderer value={p.timeframe.startDate} format={sameYear ? (query.onlyPhones ? 'MMM D' : 'MMMM D') : query.onlyPhones ? 'MMM D, YYYY' : 'MMMM D, YYYY'} /> to <DateRenderer value={p.timeframe.endDate} format={query.onlyPhones ? 'MMM D, YYYY' : 'MMMM D, YYYY'} markIfToday/></>
        ],
        [
          () => Boolean(p.timeframe.startDate),
          () => <>Since <DateRenderer value={p.timeframe.startDate} format="LL"/></>
        ],
        [
          () => Boolean(p.timeframe.endDate),
          () => <>All time earlier than <DateRenderer value={p.timeframe.endDate} format="LL"/></>
        ],
        [
          AlwaysTrueFn,
          () => <>All time</>
        ],
      ])()
    },
    get numberOfDaysWithinSelectedTimeframe() {
      return Math.abs(moment(p.timeframe.startDate).diff(moment(p.timeframe.endDate), 'days')) + 1;
    },
    lastWeek: {
      startDate: createLocalMoment().startOf('week').subtract(7, 'days').format(YYYYMMDD),
      endDate: createLocalMoment().startOf('week').subtract(1, 'days').format(YYYYMMDD),
    },
    getDisplayDateRange: (range: Timeframe) => {
      const start = moment(range.startDate);
      const end = moment(range.endDate);
      if (start.isSame(end, 'month')) {
        return `${start.format('MMM D')}–${end.format('D')}`;
      } else {
        return `${start.format('MMM D')}–${end.format('MMM D')}`;
      }
    },
    get canViewLastWeek() {
      return query.fromTablet && (s.form.startDate !== s.lastWeek.startDate || s.form.endDate !== s.lastWeek.endDate)
    }
  }));

  const edit = action(() => {
    s.form.startDate = p.timeframe.startDate;
    s.form.endDate = p.timeframe.endDate;
    UI.OVERLAY.present({
      name: 'OverlayTimeframeQuickPicker',
      component: <OverlayTimeframeQuickPicker form={s.form} onSave={applyChanges}/>,
      appearance: 'card',
      width: '33.33em',
    })
  });

  const applyChanges = action((form: Timeframe) => {
    s.form.startDate = form.startDate;
    s.form.endDate = form.endDate;
  })

  const prev = action(() => {
    applyChanges({
      startDate: moment(p.timeframe.startDate).subtract(s.numberOfDaysWithinSelectedTimeframe, 'days').format(YYYYMMDD),
      endDate: moment(p.timeframe.endDate).subtract(s.numberOfDaysWithinSelectedTimeframe, 'days').format(YYYYMMDD),
    })
  });
  const next = action(() => {
    applyChanges({
      startDate: moment(p.timeframe.startDate).add(s.numberOfDaysWithinSelectedTimeframe, 'days').format(YYYYMMDD),
      endDate: moment(p.timeframe.endDate).add(s.numberOfDaysWithinSelectedTimeframe, 'days').format(YYYYMMDD),
    })
  });
  const viewLastWeek = action(() => {
    applyChanges(s.lastWeek);
  })

  useOnMount(action(() => {
    const disposeFromReaction = reaction(
      () => s.form.startDate,
      () => setUrlParam('startDate', s.form.startDate),
      { fireImmediately: true }
    )
    const disposeToReaction = reaction(
      () => s.form.endDate,
      () => setUrlParam('endDate', s.form.endDate),
      { fireImmediately: true }
    )
    const disposeTimeframeReaction = reaction(
      () => s.form.startDate + '_' +  s.form.endDate,
      () => {
        p.onChange?.(p.timeframe);
      }
    )
    return () => {
      disposeFromReaction();
      disposeToReaction();
      disposeTimeframeReaction();
      removeUrlParams(['startDate', 'endDate']);
    }
  }));

  return <Observer children={() => (
    <div className="TimeframeSelectorSet" ref={ref} data-display-mode={query.displayMode}>
      <button className="TimeframeSelectorPaginationButton --prev" onClick={prev}><BaseIcon name="back" variant="filled" size="1.8rem"/></button>
      <button className="TimeframeSelectorPaginationButton --next" onClick={next}><BaseIcon name="arrow" variant="filled" size="1.8rem"/></button>
      <div className="TimeframeSelectorSetInner">
        {query.fromTablet && <h3>Data timeframe:</h3>}
        <p><data onClick={edit}>{s.timeframeDisplay}</data></p>
      </div>
      {query.fromTablet && <div className="TimeframeSelectorSetControls">
        <BaseButtonGroup>
          {s.canViewLastWeek && <BaseButton className="subtle" onClick={viewLastWeek} color="skyblue" size={query.fromDesktop ? 'md' : 'sm'}>Jump to Last Week ({s.getDisplayDateRange(s.lastWeek)})</BaseButton>}
          <BaseButton
            icon={query.onlyPhones ? 'pencil' : undefined}
            onClick={edit}
            color="skyblue"
            appearance={query.onlyPhones ? 'icon' : undefined}
            label={query.onlyPhones ? undefined : 'Change timeframe'}
            size={query.fromDesktop ? 'md' : 'sm'}
          />
        </BaseButtonGroup>
      </div>}
    </div>
  )} />
}

export default TimeframeSelectorSet;