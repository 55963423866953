import { makeIconDef } from "./makeIconDef";
import React from "react";

export const ExploreIconDef = {
  explore: makeIconDef('explore', {
    regular: () => <>
      <path fillRule="evenodd" clipRule="evenodd" d="M19 4H5C4.44772 4 4 4.44772 4 5V14C4 14.5523 4.44772 15 5 15H19C19.5523 15 20 14.5523 20 14V5C20 4.44772 19.5523 4 19 4ZM3 14C3 14.7532 3.41634 15.4091 4.03144 15.7502C4.01092 15.8301 4 15.9138 4 16V18C4 18.5523 4.44772 19 5 19V20C5 20.5523 5.44772 21 6 21H18C18.5523 21 19 20.5523 19 20V19C19.5523 19 20 18.5523 20 18V16C20 15.9138 19.9891 15.8301 19.9686 15.7502C20.5837 15.4091 21 14.7532 21 14V5C21 3.89543 20.1046 3 19 3H5C3.89543 3 3 3.89543 3 5V14ZM5 16H19V18H18H6H5L5 16ZM18 19H6V20H18V19ZM7 5C5.89543 5 5 5.89543 5 7C5 8.10457 5.89543 9 7 9C8.10457 9 9 8.10457 9 7C9 5.89543 8.10457 5 7 5Z" fill="currentColor" />
      <rect opacity="0.5" x="10" y="6" width="9" height="2" rx="1" fill="currentColor" />
      <path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M5 13C5 12.4477 5.44772 12 6 12H7C7.55228 12 8 12.4477 8 13C8 13.5523 7.55228 14 7 14H6C5.44772 14 5 13.5523 5 13ZM9 13C9 12.4477 9.44772 12 10 12H11C11.5523 12 12 12.4477 12 13C12 13.5523 11.5523 14 11 14H10C9.44772 14 9 13.5523 9 13ZM17 12C16.4477 12 16 12.4477 16 13C16 13.5523 16.4477 14 17 14H18C18.5523 14 19 13.5523 19 13C19 12.4477 18.5523 12 18 12H17Z" fill="currentColor" />
    </>,
    color: () => <>
      <path d="M5 19H19V20C19 20.5523 18.5523 21 18 21H6C5.44772 21 5 20.5523 5 20V19Z" fill="#07A78A" />
      <rect x="4" y="14" width="16" height="5" rx="1" fill="#FFC700" />
      <path fillRule="evenodd" clipRule="evenodd" d="M5 3C3.89543 3 3 3.89543 3 5V14C3 15.1046 3.89543 16 5 16H19C20.1046 16 21 15.1046 21 14V5C21 3.89543 20.1046 3 19 3H5ZM7 5C5.89543 5 5 5.89543 5 7C5 8.10457 5.89543 9 7 9C8.10457 9 9 8.10457 9 7C9 5.89543 8.10457 5 7 5ZM10 7C10 6.44772 10.4477 6 11 6H18C18.5523 6 19 6.44772 19 7C19 7.55228 18.5523 8 18 8H11C10.4477 8 10 7.55228 10 7ZM6 12C5.44772 12 5 12.4477 5 13C5 13.5523 5.44772 14 6 14H7C7.55228 14 8 13.5523 8 13C8 12.4477 7.55228 12 7 12H6ZM9 13C9 12.4477 9.44772 12 10 12H11C11.5523 12 12 12.4477 12 13C12 13.5523 11.5523 14 11 14H10C9.44772 14 9 13.5523 9 13ZM17 12C16.4477 12 16 12.4477 16 13C16 13.5523 16.4477 14 17 14H18C18.5523 14 19 13.5523 19 13C19 12.4477 18.5523 12 18 12H17Z" fill="url(#explRad1)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M5 3C3.89543 3 3 3.89543 3 5V14C3 15.1046 3.89543 16 5 16H19C20.1046 16 21 15.1046 21 14V5C21 3.89543 20.1046 3 19 3H5ZM7 5C5.89543 5 5 5.89543 5 7C5 8.10457 5.89543 9 7 9C8.10457 9 9 8.10457 9 7C9 5.89543 8.10457 5 7 5ZM10 7C10 6.44772 10.4477 6 11 6H18C18.5523 6 19 6.44772 19 7C19 7.55228 18.5523 8 18 8H11C10.4477 8 10 7.55228 10 7ZM6 12C5.44772 12 5 12.4477 5 13C5 13.5523 5.44772 14 6 14H7C7.55228 14 8 13.5523 8 13C8 12.4477 7.55228 12 7 12H6ZM9 13C9 12.4477 9.44772 12 10 12H11C11.5523 12 12 12.4477 12 13C12 13.5523 11.5523 14 11 14H10C9.44772 14 9 13.5523 9 13ZM17 12C16.4477 12 16 12.4477 16 13C16 13.5523 16.4477 14 17 14H18C18.5523 14 19 13.5523 19 13C19 12.4477 18.5523 12 18 12H17Z" fill="url(#explRad2)" />
      <rect opacity="0.4" x="10" y="6" width="9" height="2" rx="1" fill="#009FE3" />
      <defs>
        <radialGradient id="explRad1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.6216 0.463416) rotate(76.382) scale(18.596 11.6026)">
          <stop stopColor="#009FE3" />
          <stop offset="1" stopColor="#6464B0" />
        </radialGradient>
        <radialGradient id="explRad2" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(3.72973 16.6341) rotate(-54.4738) scale(19.6746 53.701)">
          <stop stopColor="#FDC430" />
          <stop offset="0.416667" stopColor="#07A78A" />
          <stop offset="1" stopColor="#07A78A" stopOpacity="0" />
        </radialGradient>
      </defs>
    </>
  })
}