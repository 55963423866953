import { Observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { Renderable } from "../../@types";
import joinClassName from "../../utils/className.utils";
import { renderRenderable } from "../../utils/components.utils";
import { setCSSCustomProperty } from "../../utils/css.utils";
import addListener from "../../utils/eventListener.utils";
import './AppPageHeader.scss';

interface AppPageHeaderProps {
  className?: string;
  beforeTitle?: Renderable;
  afterTitle?: Renderable;
  title?: Renderable;
  startSlot?: Renderable;
  endSlot?: Renderable;
  canGoBack?: boolean | string;
  spaceChildren?: boolean;
  cssPrimaryColor?: string;
  transparent?: boolean;
}

const AppPageHeader: React.FC<AppPageHeaderProps> = props => {
  const el = useRef<HTMLElement>(null);
  function checkSize() {
    const height = el.current ? el.current.clientHeight : 0;
    let parent = el.current?.parentElement;
    if (parent?.classList.contains('CustomScrollbar')) {
      parent = parent.parentElement;
    }
    setCSSCustomProperty( 
      '--AppPageHeaderHeight', 
      height + 'px', 
      parent ?? void 0
    );
  }
  useEffect(() => {
    function handleWindowResize() {
      checkSize();
    }
    handleWindowResize();
    const cleanUpListeners = addListener(window, 'resize focus blur', handleWindowResize);
    return () => {
      cleanUpListeners();
    }
  },[]);
  useEffect(() => {
    checkSize();
  });
  return <Observer children={() => (
    <header ref={el} className={joinClassName(
      'AppPageHeader', props.className, props.spaceChildren && 'spaceChildren',
      props.transparent && 'transparent'
    )} data-primary-color={props.cssPrimaryColor}>

      <div className="AppPageHeaderInner">

        <div className="AppPageHeaderInnerContent">
          {props.startSlot && (
            <span className="AppPageHeaderSlot start" children={renderRenderable(props.startSlot)} />
          )}
          <div className="AppPageHeaderSlotInner">
            {props.beforeTitle && <div className="AppPageBeforeTitle">{renderRenderable(props.beforeTitle)}</div>}
            {props.title && <h1 className="AppPageTitle">{renderRenderable(props.title)}</h1>}
            {props.afterTitle && <div className="AppPageAfterTitle">{renderRenderable(props.afterTitle)}</div>}
          </div>
        </div>

        {props.endSlot && (
          <span className="AppPageHeaderSlot topEnd">
            { renderRenderable(props.endSlot)}
          </span>
        )}
        {props.children && <span className="AppPageHeaderSlot default">
          {props.children}
        </span>}
      </div>
    </header>
  )} />
}

export default AppPageHeader;