import CryptoJS from 'crypto-js';
import FileSaver from 'file-saver';
import { action, flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import { CancellablePromise } from 'mobx/dist/api/flow';
import moment from 'moment';
import React, { useRef } from 'react';
import { utils as XLSXUtils, write as XLSXWrite } from 'xlsx';
import { AnyObject } from '../../base/@types';
import BaseButton, { BaseButtonProps } from '../../base/components/BaseButton/BaseButton';
import LoadingIndicator from '../../base/components/LoadingIndicator/LoadingIndicator';
import { CountryCode, CountryDescriptors } from '../../base/constants/countries.constants';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { YYYYMMDDHHmmss } from '../../base/utils/time.utils';
import tick from '../../base/utils/waiters.utils';
import { ethnicityDescriptors } from '../../constants/ethnicities.constants';
import './ExportButton.scss';

interface ExportButtonProps<EntryType = AnyObject> extends BaseButtonProps {
  dataFetcher?: () => CancellablePromise<EntryType[] | undefined>,
  data?: EntryType[],
  allowedHeaders?: string[],
  encryptValuesOfHeaders?: string[],
  filename?: string,
  processData?: (data: EntryType[]) => EntryType[],
}

function ExportButton<EntryType extends AnyObject>(props: React.PropsWithChildren<ExportButtonProps<EntryType>>) {

  const ref = useRef<any | null>(null);

  const { UI } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    currentDateTime: "" as string,
    isLoading: false,
    isReady: false,
    csvData: [] as string[][],

    exportData: flow(action(function* () {
      s.isLoading = true

      // get all data.
      let allData: EntryType[] = p.dataFetcher ? yield p.dataFetcher() : p.data ? p.data : [];
      if (!allData) return;
      if (p.processData) allData = p.processData(allData);

      const csvData = [] as string[][]

      // parse headers.
      const headers = [] as string[]
      const tempHeaders = [] as string[];

      const allowedHeaders = p.allowedHeaders ? p.allowedHeaders : [];
      allowedHeaders.forEach(h => h==="collegeReferenceInput" ? tempHeaders.push("referredByCollege") : tempHeaders.push(h));
      allowedHeaders.forEach(h => headers.push(h));
      csvData.push(tempHeaders);

      // parse data.
      allData.forEach(v => {
        const values = [] as string[]
        headers.forEach(h => {
          if (h) {
            if (h in v) {
              try {
                const d = p.encryptValuesOfHeaders?.includes(h) ? CryptoJS.SHA256('000000' + v[h]) : v[h];
                if (d === null || d === undefined) values.push("");
                else if (h === "countryId") values.push(CountryDescriptors[d as CountryCode]?.name);
                else if (h === "ethnicity") values.push(ethnicityDescriptors.find(ethnic => ethnic.value === d)?.name ?? "Prefer not to say");
                else values.push((d + '').replaceAll(/[\r\n|\n|\r|\t]/g, " "));
              } catch (e) {
                reportError(e);
              }
            }
            else values.push("")
          }
        })
        csvData.push(values)
      })

      s.currentDateTime = moment().format(YYYYMMDDHHmmss);
      s.csvData = csvData;

      // create excel spreadsheet.
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const ws = XLSXUtils.json_to_sheet(csvData, { skipHeader: true });
      const wb = { Sheets: { 'turn2meData': ws }, SheetNames: ['turn2meData'] };
      const excelBuffer = XLSXWrite(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      // download spreadsheet file.
      FileSaver.saveAs(data, s.filename);

      s.isReady = true
      yield tick(600); // wait for UI to update.
      ref.current?.link?.click();
      s.isReady = false
      s.isLoading = false
    })),

    get sheetName() {
      return p.filename ?? 'UnnamedData';
    },
    get filename() {
      return `turn2me-${s.sheetName}-${s.currentDateTime}.xlsx`;
    },
  }))

  return <Observer children={() => (
    <>
      <BaseButton
        icon="export-xlsx"
        iconVariant="regular"
        color="deepPurple"
        className="ExportButton subtle"
        appearance={UI.displayMode === 'phone' ? 'icon' : undefined}
        size="sm"
        {...props}
        onClick={s.exportData}
        label={UI.displayMode === 'phone' ? undefined : 'Export Data'}
        allowButtonClickPropagation
      >
        {s.isLoading && <LoadingIndicator />}
      </BaseButton>
      {/* {s.isReady && <CSVLink
        style={{ display: "none" }}
        data={s.csvData}
        filename={s.filename}
        ref={ref}
        separator={'\t'}
        enclosingCharacter='"'
      />} */}
    </>
  )} />
}

export default ExportButton;