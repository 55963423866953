import { action, flow, observable, reaction, runInAction, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import { CancellablePromise } from 'mobx/dist/internal';
import React, { CSSProperties, SyntheticEvent, useRef } from 'react';
import { Nullable } from '../../../../base/@types';
import BaseButton from '../../../../base/components/BaseButton/BaseButton';
import BaseSpacer from '../../../../base/components/BaseSpacer/BaseSpacer';
import LoadingIndicator from '../../../../base/components/LoadingIndicator/LoadingIndicator';
import OverlayCloseButton from '../../../../base/components/OverlayCloseButton/OverlayCloseButton';
import { UICardInnerProps } from '../../../../base/components/UICard/UICard';
import { useOnMount } from '../../../../base/hooks/lifecycle.hooks';
import { useResizeQuery } from '../../../../base/hooks/useResizeQuery';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import { addToArrayIfNew } from '../../../../base/utils/array.utils';
import joinClassName from '../../../../base/utils/className.utils';
import { reportError } from '../../../../base/utils/errors.utils';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import { scrollElementTo } from '../../../../base/utils/scrollElementTo.utils';
import { getNowTimestampUtc, seconds } from '../../../../base/utils/time.utils';
import tick, { doEvery } from '../../../../base/utils/waiters.utils';
import { PaymentFormPayFunctionType } from '../../../../components/MakePaymentForm/MakePaymentForm';
import { CommunicationType } from '../../../../constants/communicationTypes.descriptors';
import { CounsellingSessionPaidFee } from '../../../../constants/counsellingFeeTypes.constants';
import { ApplicationTypeOption, CounsellingType } from '../../../../constants/counsellingTypes.descriptors';
import { Gradients } from '../../../../constants/gradients';
import { isInCypressTestMode, IS_DEV, SHOULD_LOG } from '../../../../env';
import { Address } from '../../../../models/makeAddress.model';
import { Contact, ContactType } from '../../../../models/makeContact.model';
import { CounsellingApplication, CounsellingApplicationSnapshot, makeCounsellingApplicationForm } from '../../../../models/makeCounsellingApplication.model';
import { CounsellingAvailability } from '../../../../models/makeCounsellingAvailability.model';
import { InvitationSnapshot } from '../../../../models/makeInvitation.model';
import { User } from '../../../../models/makeUser.model';
import { saveAddress } from '../../../../requests/saveAddress.request';
import { saveContact } from '../../../../requests/saveContact.request';
import { saveCounsellingApplication } from '../../../../requests/saveCounsellingApplication.request';
import { useGetAddresses } from '../../../../requests/useGetAddresses.request';
import { useGetContacts } from '../../../../requests/useGetEmergencyContacts.request';
import { saveCurrentUser } from '../../../../requests/user.requests';
import { getApplicationTypeDescriptor, isOfCounsellingTypeYoungPeople, isPaidCounselling } from '../../../../utils/counsellingApplication.utils';
import { createNewSessionModelFromApplication } from '../../../../utils/counsellingSession.utils';
import { checkIfStringContainsWord_turn2me } from '../../../../utils/user.utils';
import CounsellingApplicationIcon from '../CounsellingApplicationIcon/CounsellingApplicationIcon';
import CounsellingApplicationUIStep from '../CounsellingApplicationUIStep/CounsellingApplicationUIStep';
import { applicationStepDescriptors, ApplicationStepId } from './applicationStepDescriptors';
import CounsellingApplicationSubmissionScreen from './components/CounsellingApplicationSubmissionScreen/CounsellingApplicationSubmissionScreen';
import CounsellingSessionTimePicker from './components/CounsellingSessionTimePicker/CounsellingSessionTimePicker';
import './CounsellingApplicationUI.scss';

export type CounsellingApplicationSubmitFunctionType = (args?: { doNotCreateApplication?: boolean }) => CancellablePromise<void>;

export type CounsellingApplicationInterceptedPayFunctionType = (pay: PaymentFormPayFunctionType) => Promise<void>;

interface CounsellingApplicationUIProps extends UICardInnerProps {
  showCloseButton?: boolean,
  type: ApplicationTypeOption
}

const enableDevPrefillForm = !isInCypressTestMode && IS_DEV && true;

const CounsellingApplicationUI: React.FC<CounsellingApplicationUIProps> = React.memo(props => {

  const p = useProps(props);

  const { UI, AUTH, API, COUNSELLING } = useControllers();

  const ref = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const resizeQuery = useResizeQuery(ref);
  const scrollContextRef = useRef<HTMLDivElement>(null);

  useOnMount(() => {
    if (enableDevPrefillForm) {
      runInAction(() => {
        s.form.hasAgreedCantAfford = true;
        s.form.hasReadImportantInformation = true;
        s.form.hasAgreedToTerms = true;
        s.form.ableToDonate = null;
        s.form.application.isAtRisk = false;
        s.form.application.communicationTypePreference = CommunicationType.Text;
        s.form.application.healthcareProviderName = "HC bryan";
        s.form.application.healthcareProviderPhone = "+353416977772";
        s.form.application.hasPrivateHealthCare = false;
        s.form.application.isInReceiptOfMedicalCard = false;
        s.form.application.hasExternalCounselling = false;
        s.form.application.ownSymptomsOrFeelingsMeasurement = 'High';
        s.form.application.hasAttemptedSuicide = false;
        // s.form.application.availabilityId = s.availabilities
      })
    }
  })

  const s = useStore(() => ({
    alive: false,
    ready: false,
    form: makeCounsellingApplicationForm({}),
    currentStepId: ApplicationStepId.welcome,
    currentScrolledAtStepIndex: 0,
    get currentScrolledAtStep() { return s.stepDescriptors[s.currentScrolledAtStepIndex] },
    get currentStepIndex() { return s.currentScrolledAtStepIndex },
    get isAtLastStep() { return s.currentScrolledAtStepIndex === s.stepDescriptors.length - 1 },
    get currentScrolledAtStepTitle() {
      return s.currentScrolledAtStep?.title ? `${s.currentScrolledAtStepIndex ? `Step  ${s.currentScrolledAtStepIndex}:` : ''} ${s.currentScrolledAtStep.title}` : 'Welcome to turn2me!';
    },
    get width() { return resizeQuery.width },
    get footerHeight() {  return UI.onlyPhones ? 97 : 138 },
    get stepDescriptors() {
      return applicationStepDescriptors.filter(d => d.shouldDisplay(s.form));
    },
    get selectedCounsellingTypeDescriptor() {
      return getApplicationTypeDescriptor(p.type);
    },
    get isPaid() { return isPaidCounselling(s.form.selectedApplicationType); },
    goToStepIndex: action((i: number) => {
      const step = s.stepDescriptors[i];
      if (!step) return;
      s.currentStepId = step.id;
      s.scrollToStepIndex(i);
    }),
    scrollToStepIndex: (i: number) => {
      scrollElementTo({
        el: scrollContextRef.current,
        left: i * s.width,
        duration: (isInCypressTestMode || enableDevPrefillForm) ? 10 : 620,
      })
    },
    enableStep: (stepIndex: number) => {
      const isPreviousStepsValid = s.stepDescriptors.slice(0, stepIndex).every(step => step.validator(s.form));
      if (!isPreviousStepsValid) return false;

      const isTargetedStepsValid = s.validateTargetedSteps(false, ">=");
      if (!isTargetedStepsValid && s.stepDescriptors[stepIndex].id > s.currentScrolledAtStep.id) {
        return false;
      }

      return isPreviousStepsValid && s.validateTargetedSteps(false, ">");
    },
    get canGoToNextStep() {
      return s.stepDescriptors.slice(0, s.currentScrolledAtStepIndex + 1).every(d => d.validator(s.form));
    },
    prevStep: () => s.goToStepIndex(s.currentStepIndex - 1),
    nextStep: async () => {
      const canContinue = s.validateTargetedSteps(true, ">=");
      if (!canContinue) return;
      if (s.canSubmit) await s.submit();
      else s.goToStepIndex(s.currentStepIndex + 1)
    },
    validateTargetedSteps: (showDialog?: boolean, indexComparator?: ">" | ">=" | "=") => {
      let isFillingPersonalInfo, isFillingHealthcareInfo;
      switch (indexComparator) {
        case '=':
          isFillingPersonalInfo = s.currentScrolledAtStep.id === ApplicationStepId.reviewPersonalInfo;
          isFillingHealthcareInfo = s.currentScrolledAtStep.id === ApplicationStepId.healthcareInfo;
          break;
        case '>':
          isFillingPersonalInfo = s.currentScrolledAtStep.id > ApplicationStepId.reviewPersonalInfo;
          isFillingHealthcareInfo = s.currentScrolledAtStep.id > ApplicationStepId.healthcareInfo;
          break;
        case '>=':
        default:
          isFillingPersonalInfo = s.currentScrolledAtStep.id >= ApplicationStepId.reviewPersonalInfo;
          isFillingHealthcareInfo = s.currentScrolledAtStep.id >= ApplicationStepId.healthcareInfo;
      }
      if (isFillingPersonalInfo && s.form.applicantEmergencyContact.email === s.form.applicant.email) {
        showDialog && UI.DIALOG.attention({
          heading: "Please ensure your emergency contact's email is not your email.",
        })
        return false;
      }
      if (isFillingPersonalInfo && s.form.applicantEmergencyContact.phone === s.form.applicant.mobileNumber) {
        showDialog && UI.DIALOG.attention({
          heading: "Please ensure your emergency contact's phone number is not your phone number.",
        })
        return false;
      }
      if (isFillingHealthcareInfo && s.form.application.healthcareProviderPhone === s.form.applicant.mobileNumber) {
        showDialog && UI.DIALOG.attention({
          heading: "Please ensure your healthcare provider's phone number is not your phone number.",
        })
        return false;
      }
      if (isFillingHealthcareInfo && s.form.application.healthcareProviderPhone === s.form.applicantEmergencyContact.phone) {
        showDialog && UI.DIALOG.attention({
          heading: "Please ensure your healthcare provider's phone number is not your emergency contact's phone number.",
        })
        return false;
      }
      return true;
    },
    handleScroll: action((e: SyntheticEvent) => {
      const target = e.target as HTMLDivElement;
      if (!target || !target.classList.contains('CounsellingApplicationUIBody')) return;
      const scrollLeft = target.scrollLeft + 25; // offset a little
      const stepIndex = Math.floor(scrollLeft / s.width);
      s.currentScrolledAtStepIndex = stepIndex;
    }),
    handleTouchMove: (e: SyntheticEvent<HTMLDivElement, TouchEvent>) => {
    },
    availabilities: [] as CounsellingAvailability[],
    get formIsValid() {
      return s.stepDescriptors.every(d => d.validator(s.form));
    },

    get canSubmit() { return s.formIsValid && s.isAtLastStep },
    isSubmitting: false,
    submitted: false,
    submittedApplicationId: null as Nullable<string>,

    get selectedAvailability() {
      return s.availabilities.find(a => a.id === s.form.application.availabilityId);
    },
    /**
     * beforeSubmit is used to validate forms and availability before payment.
     * submit is used after payment is finalized.
     *
     * paidCounselling: beforeSubmit -> payment -> submit
     * freeCounselling: beforeSubmit -> submit
     */
    checkAvailability: () => {
      return new Promise<boolean>(async (resolve, reject) => {
        try {
          if (!AUTH.isStaff && checkIfStringContainsWord_turn2me(s.form.applicant.username)) {
            UI.DIALOG.attention({
              heading: 'Your username should not contain the word "turn2me".',
              body: 'Please choose a different username before continuing.',
            })
            return;
          }
          const checkAvailabilityIsValid = () => new Promise<boolean>(async (resolve) => {
            const availabilities = await COUNSELLING.getCounsellingAvailabilities();
            runInAction(() => {
              s.availabilities = availabilities;
            })
            const availabilityStillAvailable = availabilities.find(a => a.id === s.form.application.availabilityId && (a.typesAllowed.length === 0 || a.typesAllowed.includes(s.form.application.type)));
            if (!availabilityStillAvailable) {
              runInAction(() => {
                s.form.application.availabilityId = '';
              })
              if (availabilities.length === 0) {
                UI.DIALOG.attention({
                  heading: "We are sorry but the time slot you have chosen is no longer available.",
                  body: "There are no other availabilities at the moment. Please check back later."
                });
                resolve(false);
              } else {
                UI.DIALOG.present({
                  heading: "We are sorry but the time slot you have chosen is no longer available.",
                  body: <Observer children={() => <div>
                    <p>Please select another suitable slot before continuing:</p>
                    <CounsellingSessionTimePicker
                      selectedSlotId={s.form.application.availabilityId}
                      onChange={action(avail => s.form.application.availabilityId = avail?.id ?? null)}
                      forCounsellingType={s.form.application.type}
                    />
                  </div>} />,
                  actions: [
                    {
                      name: 'negative',
                      label: 'Cancel',
                      buttonClass: 'subtle',
                      action: () => resolve(false),
                    },
                    observable({
                      name: 'positive',
                      label: 'Confirm',
                      get disabled() {
                        return !s.form.application.availabilityId;
                      },
                      // fancy recursive: check again the newly selected availability.
                      action: () => resolve(checkAvailabilityIsValid()),
                    }),
                  ]
                })
              }
            }
            else resolve(true);
          })
          resolve(await checkAvailabilityIsValid());
        } catch (e) {
          reportError(e);
          reject(e);
        }
      })
    },
    processApplication: () => flow(function* () {
      const { applicant, applicantAddress, applicantEmergencyContact } = s.form;
      const application: Partial<CounsellingApplicationSnapshot> & {
        invitation?: InvitationSnapshot
      } = s.form.application;
      const { type } = application;
      if (type === CounsellingType.Couples) {
        s.form.application.communicationTypePreference = CommunicationType.Video;
        s.form.invitation.actingAs = 'partner';
      }
      else if (isOfCounsellingTypeYoungPeople(type)) {
        s.form.application.communicationTypePreference = CommunicationType.Video;
        s.form.invitation.actingAs = 'dependent-child';
      }
      else {
        s.form.invitation.actingAs = null;
      }
      try {
        // save address
        const savedAddress: Address = yield saveAddress(applicantAddress, API);
        addToArrayIfNew(applicant.addressIds, savedAddress.id);
        Object.assign(s.form.applicantAddress, savedAddress.$snapshot);
        applicant.primaryAddressId = savedAddress.id;
        // save emergency contact
        const savedEmergencyContact: Contact = yield saveContact(applicantEmergencyContact, API);
        Object.assign(s.form.applicantEmergencyContact, savedEmergencyContact.$snapshot);
        addToArrayIfNew(applicant.emergencyContactIds, savedEmergencyContact.id);
        applicant.primaryEmergencyContactId = savedEmergencyContact.id;
        // save applicant (current user)
        const savedUser: User = yield saveCurrentUser(API, applicant);
        Object.assign(s.form.applicant, savedUser.$snapshot);
        // save application
        if (s.isPaid) {
          application.clientAgreedDonationAmountPerSession = CounsellingSessionPaidFee.value;
        }
        application.addressId = savedAddress.id;
        application.applicantId = AUTH.currentUser!.id;
        application.countryId = savedAddress.countryId;
        application.counsellorId = s.selectedAvailability?.counsellorId;
        if ((type as unknown as ApplicationTypeOption) !== ApplicationTypeOption.OneToOne && (type as unknown as ApplicationTypeOption) !== ApplicationTypeOption.PaidOneToOne) {
          application.invitation = s.form.invitation;
        }
        return application as Partial<CounsellingApplication>;
      } catch (e) {
        reportError(e);
        UI.DIALOG.error({
          heading: 'An error occurred while processing your application...',
          error: e,
        })
      }
    })(),
    /**
     * The SUBMIT function is here!
     */
    submit: (args?: { doNotCreateApplication?: boolean }) => flow(function* () {
      // paid counselling's checkAvailability is handled in CounsellingApplicationStepPayment.
      const shouldContinue = s.isPaid ? true : yield s.checkAvailability();
      if (!shouldContinue) return;
      s.isSubmitting = true;
      yield tick(2000);
      try {
        if (!args?.doNotCreateApplication) {
          const application = yield s.processApplication();
          const savedApplication = (yield saveCounsellingApplication(application, API)) as CounsellingApplication;
          s.submittedApplicationId = savedApplication.id;
        }

        yield COUNSELLING.getCurrentUserApplications();
        yield AUTH.fetchCurrentUserInfo();

        // if paid, report next session assignment as paid.
        // if (s.isPaid) {
        //   const url = PaymentEndpoints.own.reportSingleChargeSuccess();
        //   const payload = {
        //     amount: CounsellingSessionPaidFee.value,
        //     userId: AUTH.currentUser!.id,
        //     // email: AUTH.currentUser!.email,
        //     assignmentId: savedApplication.nextSession?.assignments,
        //   };
        //   const payment = yield API.post<Payment>(url, ModelName.payments, payload as unknown as PaymentSnapshot);
        //   SHOULD_LOG() && console.log("🚀 ~ file: CounsellingApplicationUI.tsx ~ line 258 ~ submit: ~ payment", payment)
        // }

        s.isSubmitting = false;
        // all pass, we are done!
        s.submitted = true;
      } catch(e) {
        reportError(e);
        s.isSubmitting = false;
        s.submitted = false;
        UI.DIALOG.error({
          heading: 'An error occurred while submitting your application...',
          error: e,
        })
      }
    })(),

    interceptedPay: async (pay: PaymentFormPayFunctionType) => {
      try {
        SHOULD_LOG() && console.log("--- Paying ---");
        const shouldContinue = await s.checkAvailability();
        if (!shouldContinue) return;
        SHOULD_LOG() && console.log("Done checkAvailability");
        const application = await s.processApplication();
        if (!application) return;
        SHOULD_LOG() && console.log("Done processing application");
        const paymentSuccess = await pay(undefined, {
          actionBeforePay: s.checkAvailability,
          doNotReportPaymentSuccessToAPI: true,
          isPaymentFinalizedByAPI: true,
          confirmPaymentPayload: {
            application,
            session: createNewSessionModelFromApplication({ application }, { timeScheduled: s.selectedAvailability?.timeStart, timePaymentEnforced: getNowTimestampUtc(), }).$getSnapshot(),
          },
          paymentDescription: 'PaidCounsellingApplication',
          doNotPresentErrorDialog: true,
        });
        if (!paymentSuccess) return;
        SHOULD_LOG() && console.log("Done pay");
        await s.submit({ doNotCreateApplication: true });
        SHOULD_LOG() && console.log("Done onSubmit");
        SHOULD_LOG() && console.log("--- Done paying ---");
      } catch (e) {
        reportError(e);
        UI.DIALOG.error({
          heading: 'An error occurred while processing payment for your application...',
          error: e,
        })
      }
    },

    get shouldShowSubmissionScreen() { return s.isSubmitting || s.submitted },
    _isClosing: false,
    get isClosing() {
      return p.isClosing || s._isClosing;
    },

    addressesRetrieved: false,
    emergencyContactsRetrieved: false,
    availabilitiesRetrieved: false,
    init: async () => await flow(function * () {
      yield when(() => Boolean(AUTH.currentUser?.id));
      yield when(() => s.addressesRetrieved);
      yield when(() => s.emergencyContactsRetrieved);
      yield when(() => s.availabilitiesRetrieved);
      yield when(() => !p.isOpening);
      s.form.selectedApplicationType = p.type;
      if (s.form.selectedApplicationType === ApplicationTypeOption.Couples || s.form.selectedApplicationType === ApplicationTypeOption.YoungPeople) {
        s.form.application.communicationTypePreference = CommunicationType.Video;
      }
      Object.assign(s.form.applicant, AUTH.currentUser?.$getSnapshot());
      Object.assign(s.form.applicantAddress, AUTH.currentUser?.primaryAddress?.$getSnapshot());
      Object.assign(s.form.applicantEmergencyContact, AUTH.currentUser?.primaryEmergencyContact?.$getSnapshot());
      s.ready = true;
    })(),

    get formBody() {
      return s.ready ? <div
        className="CounsellingApplicationUIBody"
        ref={scrollContextRef}
        onScroll={s.handleScroll}
        onTouchMove={s.handleTouchMove}
      >
        {s.stepDescriptors.map((stepDescriptor, i) => {
          return <CounsellingApplicationUIStep
            key={stepDescriptor.id}
            form={s.form}
            stepDescriptor={stepDescriptor}
            // currentStepId={s.currentStepId}
            // index={i}
            // allStepDescriptors={s.stepDescriptors}
            enableStep={() => s.enableStep(i)}
            footerHeight={s.footerHeight}
            onInterceptedPay={s.interceptedPay}
          />
        })}
      </div> : <div className="CounsellingApplicationUILoadingScreen">
        <div>
          <LoadingIndicator />
          <BaseSpacer size=".5em" />
          <p><em>Getting ready...</em></p>
        </div>
      </div>
    }

  }));

  useGetAddresses({
    own: true,
    when: () => Boolean(AUTH.currentUser?.id),
    finally: action(() => {s.addressesRetrieved = true}),
  });

  useGetContacts({
    own: true,
    type: ContactType.EmergencyContact,
    when: () => Boolean(AUTH.currentUser?.id),
    finally: action(() => {s.emergencyContactsRetrieved = true}),
  });

  const handleSubmissionScreenClose = action(() => {
    p.close?.();
    s._isClosing = true;
    UI.OVERLAY.dismiss('CounsellingApplicationOverlay');
  })

  useOnMount(() => {

    s.alive = true;

    s.init();

    const disposers = [
      reaction(
        () => s.form.application.isAtRisk,
        () => { if (s.form.application.isAtRisk === false) s.nextStep() }
      ),
      reaction(
        () => s.form.hasAgreedCantAfford,
        () => { if (s.form.hasAgreedCantAfford === true) s.nextStep() }
      ),
      doEvery(async () => {
        await COUNSELLING.getCounsellingAvailabilities();
        runInAction(() => {
          s.availabilitiesRetrieved = true;
        })
      }, seconds(15), {
        fireImmediately: true
      })
    ];

    const onWindowFocus = () => {
      COUNSELLING.getCounsellingAvailabilities();
    }
    window.addEventListener('focus', onWindowFocus);
    disposers.push(() => {
      window.removeEventListener('focus', onWindowFocus);
    })

    // // uncomment below to test the availability check before submission
    // runAfter(action(() => {
    //   s.form.application.availabilityId = '10';
    //   s.submit();
    // }), 1000);

    return () => {
      disposers.forEach(d => d());
      s.alive = false;
    };

  })

  return <Observer children={() => (
    <div
      className={joinClassName('CounsellingApplicationUI', s.canSubmit && 'canSubmit')}
      ref={ref}
      style={{
        '--CounsellingApplicationUIContainerWidth': resizeQuery.width + 'px',
        '--CounsellingApplicationUIScrollWidth': resizeQuery.width * s.stepDescriptors.length + 'px',
        backgroundImage: Gradients[s.selectedCounsellingTypeDescriptor?.colorName],
      } as CSSProperties}
      data-primary-color={s.selectedCounsellingTypeDescriptor?.colorName}
    >

      <header className="CounsellingApplicationUIHeader">
        <CounsellingApplicationIcon />
        <h1>Book {s.isPaid ? "Paid" : "Free"} Online Counselling / Psychotherapy</h1>
        {s.ready && <p className="CounsellingApplicationUIAfterTitle">{s.currentScrolledAtStepTitle}</p>}
        {p.showCloseButton && <OverlayCloseButton color="white"/>}
      </header>

      { s.formBody }

      <footer className="CounsellingApplicationUIFooter" ref={footerRef}>
        { s.currentStepIndex ? <BaseButton
          className="CounsellingApplicationUIPrevStepButton subtle"
          size="lg" icon="back" name="prev-step" circle iconVariant="filled"
          onClick={s.prevStep}
        /> : null }
        {!(s.isPaid && s.isAtLastStep) && <BaseButton
          className={joinClassName('CounsellingApplicationUINextStepButton', s.canSubmit ? 'submit' : '')}
          size="lg" icon={s.canSubmit ? 'check' : 'arrow'} name="next-step" circle iconVariant="filled"
          disabled={!s.canGoToNextStep}
          onClick={s.nextStep}
          label={s.isAtLastStep && s.canGoToNextStep ? 'Submit' : UI.fromTablet ? 'Next Step' : ' '}
        />}
      </footer>

      {
        s.shouldShowSubmissionScreen && UI.PORTAL.render(
          <CounsellingApplicationSubmissionScreen
            submitted={s.submitted}
            isClosing={s.isClosing}
            applicationId={s.submittedApplicationId}
            type={s.form.selectedApplicationType}
          >
            <BaseButton rounded onClick={handleSubmissionScreenClose} size="lg" color="white" className="subtle">Done</BaseButton>
          </CounsellingApplicationSubmissionScreen>
        )
      }

    </div>
  )} />
})

export default CounsellingApplicationUI;