import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseSelector, { BaseSelectorProps } from '../../base/components/BaseSelector/BaseSelector';
import { employmentStatusDescriptors } from '../../constants/employmentStatuses';

interface EmploymentStatusSelectorProps<FormType = object> extends Omit<BaseSelectorProps<FormType, object, string>, 'options'> { }

export function EmploymentStatusSelector<T = object>(props: React.PropsWithChildren<EmploymentStatusSelectorProps<T>>) {
  return <Observer children={() => (
    <BaseSelector className="EmploymentStatusSelector" {...props} options={[...employmentStatusDescriptors.filter(
        (descriptor) => descriptor.name !== 'Prefer not to say'
    )]} appearance="system" />
  )} />
}

export default EmploymentStatusSelector;