import { RouteDef } from "../../../base/@types";
import { always } from "../../../base/utils/ramdaEquivalents.utils";
import ModuleAccount from "./Account.module";
import { AccountPage2FARouteDef } from "./pages/AccountPage2FA/AccountPage2FA.page.route";
import { AccountPageAddressManagerRouteDef } from "./pages/AccountPageAddressManager/AccountPageAddressManager.page.route";
import { AccountPageCompanyRouteDef } from "./pages/AccountPageCompany/AccountPageCompany.page.route";
import { AccountPageContactFormRouteDef } from "./pages/AccountPageContactForm/AccountPageContactForm.page.route";
import { AccountPageDeleteAccountRouteDef } from "./pages/AccountPageDeleteAccount/AccountPageDeleteAccount.page.route";
import { AccountPageDonationsRouteDef } from "./pages/AccountPageDonations/AccountPageDonations.page.route";
import { AccountPageEmergencyContactsRouteDef } from "./pages/AccountPageEmergencyContacts/AccountPageEmergencyContacts.page.route";
import { AccountPageManageDonationsRouteDef } from "./pages/AccountPageManageDonations/AccountPageManageDonations.page.route";
import { AccountPagePaymentMethodsRouteDef } from "./pages/AccountPagePaymentMethods/AccountPagePaymentMethods.page.route";
import { AccountPagePrivacyRouteDef } from "./pages/AccountPagePrivacy/AccountPagePrivacy.page.route";
import { AccountPageProfileRouteDef } from "./pages/AccountPageProfile/AccountPageProfile.page.route";
import { AccountPageProfileEditorRouteDef } from "./pages/AccountPageProfileEditor/AccountPageProfileEditor.page.route";
import { AccountPageReceiptsRouteDef } from "./pages/AccountPageReceipts/AccountPageReceipts.page.route";
import { AccountPageSupportGroupsRouteDef } from "./pages/AccountPageSupportGroups/AccountPageSupportGroups.page.route";
import { AccountPageUpdatePasswordRouteDef } from "./pages/AccountPageUpdatePassword/AccountPageUpdatePassword.page.route";

export const AccountModuleRouteDef: RouteDef = {
  identifier: 'account',
  urlFactory: always('account'),
  composeTitle: always('Account'),
  component: ModuleAccount,
  children: [
    AccountPageProfileRouteDef,
    AccountPageProfileEditorRouteDef,
    AccountPageAddressManagerRouteDef,
    AccountPagePaymentMethodsRouteDef,
    AccountPageEmergencyContactsRouteDef,
    AccountPagePrivacyRouteDef,
    AccountPageUpdatePasswordRouteDef,
    AccountPageDonationsRouteDef,
    AccountPageManageDonationsRouteDef,
    AccountPageReceiptsRouteDef,
    AccountPageSupportGroupsRouteDef,
    AccountPageCompanyRouteDef,
    AccountPageDeleteAccountRouteDef,
    AccountPageContactFormRouteDef,
    AccountPage2FARouteDef,
  ]
}