import { AnyObject } from "../base/@types";
import { getSnapshot } from "../base/utils/snapshot.utils";

export const makePayloadWithoutId = <T extends AnyObject = AnyObject>(object: T) => {
  const {
    id,
    timeUpdated,
    timeCreated,
    ...partial
  } = object;
  const result = getSnapshot(partial);
  return result;
}

export const makePayloadWithId = <T extends AnyObject = AnyObject>(object: T) => {
  const {
    timeUpdated,
    timeCreated,
    ...partial
  } = object;
  const result = getSnapshot(partial);
  return result;
}