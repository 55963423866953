import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { Emotion, getEmotionName } from '../../constants/emotions.enum';
import './EmotionIcon.scss';

interface FeelingIconProps {
  emotion: Emotion,
  size?: number,
  onClick?: (emotion?: Emotion) => void,
  selected?: boolean,
  notSelected?: boolean,
  disabled?: boolean,
  dropShadow?: boolean,
  showTextLabel?: boolean,
}

const EmotionIcon: React.FC<FeelingIconProps> = props => {

  const p = useProps(props);

  const renderIcon = () => {
    switch (p.emotion) {
      case Emotion.great: {
        return <svg width={p.size ?? 38} height={p.size ?? 38} viewBox="0 0 38 38" fill="none">
          <circle cx="19" cy="19" r="19" fill="white" />
          <path d="M7.26453 10.0585L11.7351 12.5732L7.26453 15.0879" stroke="#F6791F" strokeWidth="1.07275" />
          <path d="M30.7351 15.0878L26.2645 12.5731L30.7351 10.0584" stroke="#F6791F" strokeWidth="1.07275" />
          <path d="M30.7354 17.8828C30.7354 19.4239 30.4318 20.9499 29.8421 22.3737C29.2523 23.7975 28.3879 25.0911 27.2982 26.1809C26.2084 27.2706 24.9148 28.135 23.491 28.7248C22.0672 29.3145 20.5412 29.6181 19.0001 29.6181C17.459 29.6181 15.933 29.3145 14.5092 28.7248C13.0854 28.135 11.7917 27.2706 10.702 26.1809C9.61224 25.0911 8.74782 23.7975 8.15807 22.3737C7.56831 20.9499 7.26477 19.4239 7.26477 17.8828L19.0001 17.8828H30.7354Z" fill="#F6791F" />
        </svg> 
      }
      case Emotion.good: {
        return <svg width={p.size ?? 38} height={p.size ?? 38} viewBox="0 0 39 38" fill="none">
          <circle cx="19.4734" cy="19" r="19" fill="white" />
          <ellipse cx="9.41487" cy="13.4119" rx="1.67647" ry="1.67647" fill="#07A78A" />
          <ellipse cx="29.5323" cy="13.4119" rx="1.67647" ry="1.67647" fill="#07A78A" />
          <path fillRule="evenodd" clipRule="evenodd" d="M9.41467 19.5596C9.53523 20.3949 9.75964 21.2138 10.084 21.997C10.5948 23.23 11.3434 24.3503 12.2871 25.294C13.2308 26.2377 14.3511 26.9863 15.5841 27.4971C16.8171 28.0078 18.1387 28.2707 19.4733 28.2707C20.8079 28.2707 22.1294 28.0078 23.3624 27.4971C24.5954 26.9863 25.7158 26.2377 26.6595 25.294C27.6032 24.3503 28.3518 23.23 28.8625 21.997C29.1869 21.2138 29.4113 20.3949 29.5319 19.5596H9.41467Z" fill="#07A78A" />
        </svg>
      }
      case Emotion.okay: {
        return <svg width={p.size ?? 38} height={p.size ?? 38} viewBox="0 0 39 38" fill="none">
          <circle cx="19.9468" cy="19" r="19" fill="white" />
          <ellipse cx="11.5644" cy="16.2061" rx="1.67647" ry="1.67647" fill="#009FE3" />
          <ellipse cx="28.3294" cy="16.2061" rx="1.67647" ry="1.67647" fill="#009FE3" />
          <path d="M25.2158 25.6929C23.7233 26.7773 21.9326 27.3747 20.0879 27.4037C18.2433 27.4327 16.4347 26.8918 14.9088 25.8548" stroke="#009FE3" strokeWidth="1.07275" strokeLinecap="round" />
        </svg>
      }
      case Emotion.bad: {
        return <svg width={p.size ?? 38} height={p.size ?? 38} viewBox="0 0 39 38" fill="none">
          <circle cx="19.4202" cy="19" r="19" fill="white" />
          <ellipse cx="11.0377" cy="18.4411" rx="1.67647" ry="1.67647" fill="#3D5EB5" />
          <circle cx="27.2435" cy="18.4411" r="1.67647" fill="#3D5EB5" />
          <path d="M14.1514 26.8365C15.6439 25.7522 17.4346 25.1547 19.2793 25.1257C21.1239 25.0968 22.9325 25.6377 24.4584 26.6746" stroke="#3D5EB5" strokeWidth="1.07275" strokeLinecap="round" />
        </svg>
      }
      case Emotion.terrible :{
        return <svg width={p.size ?? 38} height={p.size ?? 38} viewBox="0 0 39 38" fill="none">
          <circle cx="19.8936" cy="19" r="19" fill="white" />
          <path d="M16.2674 27.9799C17.2947 27.2335 18.5272 26.8223 19.7968 26.8024C21.0664 26.7824 22.3113 27.1547 23.3615 27.8684" stroke="#636375" strokeWidth="1.07275" strokeLinecap="round" />
          <circle cx="11.5112" cy="20.1174" r="1.40828" fill="#636375" stroke="#636375" strokeWidth="0.536373" />
          <path d="M29.684 20.1174C29.684 20.8952 29.0535 21.5257 28.2757 21.5257C27.4979 21.5257 26.8674 20.8952 26.8674 20.1174C26.8674 19.3396 27.4979 18.7091 28.2757 18.7091C29.0535 18.7091 29.684 19.3396 29.684 20.1174Z" fill="#636375" stroke="#636375" strokeWidth="0.536373" />
          <path d="M13.1878 16.7649C11.2155 18.4414 10.2294 19.0002 7.59961 20.1178" stroke="#636375" strokeWidth="0.80456" strokeLinecap="round" />
          <path d="M26.599 16.7646C28.5713 18.4411 29.5575 18.9999 32.1873 20.1176" stroke="#636375" strokeWidth="0.80456" strokeLinecap="round" />
          <path d="M29.9524 27.0142C29.9524 27.8348 29.327 28.5001 28.5554 28.5001C27.7838 28.5001 27.1583 27.8348 27.1583 27.0142C27.1583 26.1935 28.5554 23.4707 28.5554 23.4707C28.5554 23.4707 29.9524 26.1935 29.9524 27.0142Z" fill="#636375" />
        </svg>
      }
    }
  }

  const s = useStore(() => ({
    handleClick: () => {
      p.onClick && p.onClick(p.disabled ? undefined : p.emotion);
    },
  }));

  return <Observer children={() => (
    <div className="EmotionIcon"
      data-emotion={p.emotion}
      data-selected={p.selected}
      data-not-selected={p.notSelected}
      data-drop-shadow={p.dropShadow}
      data-interactable={Boolean(p.onClick)}
      onClick={s.handleClick}
    >
      <div className="EmotionIconIcon">
        { renderIcon() }
      </div>
      { p.showTextLabel && <div className="EmotionIconLabel">
        {getEmotionName(p.emotion)}
      </div> }
    </div>
  )} />

}

export default EmotionIcon;