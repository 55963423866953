import { HasId } from "../base/@types";
import { ChatParticipantEndpoints } from "../base/endpoints/chatParticipant.endpoints";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { ChatParticipant } from "../models/makeChatParticipant.model";

export function patchChatParticipant(API: APIController, payload: Partial<ChatParticipant> & HasId) {
  return new Promise<ChatParticipant>(async (resolve, reject) => {
    try {
      const { id } = payload;
      const url = ChatParticipantEndpoints.staff.update(id);
      const savedParticipant = await API.patch<ChatParticipant>(url, ModelName.chatParticipants, payload);
      if (!savedParticipant) throw Error ('Failed to update participant details');
      resolve(savedParticipant);
    } catch(e) {
      reject(e);
    }
  })
}