import { Observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { AnyObject } from '../../@types';
import { CountryCode, CountryDescriptor, CountryDescriptors } from '../../constants/countries.constants';
import { formatSearchString } from '../../utils/string.utils';
import BaseSelector, { BaseSelectorProps } from '../BaseSelector/BaseSelector';
// import './CountrySelector.scss';

export interface CountrySelectorProps<FormType = AnyObject> extends Omit<BaseSelectorProps<FormType, AnyObject, string>, 'options'> { 
  allowedCountryCodes?: string[],
}

export function CountrySelector<T = AnyObject>(p: React.PropsWithChildren<CountrySelectorProps<T>>) {

  const countriesOptionsList = useMemo(() => {
    const countriesArray = [
      ['IE', CountryDescriptors.IE], 
      ['GB', CountryDescriptors.GB], 
      ...Object.entries(CountryDescriptors).sort((a, b) => {
        const ast = formatSearchString(a[1].name);
        const bst = formatSearchString(b[1].name);
        return ast > bst ? 1 : ast < bst ? -1 : 0
      })
    ] as [CountryCode, CountryDescriptor][];
    const allowedCountries = p.allowedCountryCodes ? (
      p.allowedCountryCodes.map(aci => countriesArray.find(c => c[0] === aci)).filter(i=>i)
    ) : countriesArray;
    return allowedCountries.map(e => {
      const countryCode = e![0];
      const countryDef = e![1];
      return {
        label: countryDef.name,
        value: countryCode,
        flag: countryDef.flag,
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Observer children={() => (
    <BaseSelector<T>
      className="CountrySelector"
      {...p}
      options={countriesOptionsList}
      appearance="system" 
    />
  )} />
}

export default CountrySelector;