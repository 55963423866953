import { CompanyEndpointParams, CompanyEndpoints } from "../base/endpoints/company.endpoints";
import { useControllers } from "../base/hooks/useRootController.hook";
import { ModelName } from "../constants/modelNames.enum";
import { Company } from "../models/makeCompany.model";
import { useAutoGet, UseAutoGetFactoryOptions } from "../utils/useAutoGet.utils";

export function useGetCompany<ObservableType = object>(
  options: UseAutoGetFactoryOptions<Company, ObservableType>,
  id?: string | null,
  params?: CompanyEndpointParams,
) {
  const { AUTH } = useControllers();
  const canUseAdminEndpoint = AUTH.can.manage_.partnerships.companies;
  const _params = params || {
    include: ['']
  }
  useAutoGet<Company, ObservableType>({
    ...options,
    url: id ? CompanyEndpoints[canUseAdminEndpoint ? 'staff' : 'own'].get(id, _params) : undefined,
    getFromLocalById: id,
    modelName: ModelName.companies,
  });
}