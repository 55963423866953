import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Nillable } from '../../../../../base/@types';
import { getGreetingTimeOfDay } from '../../../../../base/bots/responses/greetings.botResponses';
import { useControllers } from '../../../../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../../../../base/utils/mobx.utils';
import EmotionSelector from '../../../../../components/EmotionSelector/EmotionSelector';
import OverlayThoughtComposer from '../../../../../components/OverlayThoughtComposer/OverlayThoughtComposer';
import { Emotion } from '../../../../../constants/emotions.enum';
import { Thought } from '../../../../../models/makeThought.model';
import './ExploreThoughtCatcherComposerOverlayPrompt.scss';

type ExploreThoughtCatcherComposerOverlayPromptProps = {
  thought?: Nillable<Thought>,
}

const ExploreThoughtCatcherComposerOverlayPrompt: React.FC<ExploreThoughtCatcherComposerOverlayPromptProps> = props => {

  const { THOUGHT_CATCHER, AUTH, UI } = useControllers();

  const p = useProps(props);
  
  const s = useStore(() => ({
    get thought() {
      return p.thought || THOUGHT_CATCHER.thoughtOfTheDay
    },
    get greeting() {
      return getGreetingTimeOfDay(AUTH.currentUser);
    },
  }))

  const handleSelect = action(function (e?: Nillable<Emotion>) {
    if (s.thought && e) s.thought.emotionId = e;
    UI.OVERLAY.present({
      name: 'OverlayThoughtComposer',
      component: <OverlayThoughtComposer thought={s.thought} />,
      appearance: 'card',
      width: '48em',
    })
  })

  return <Observer children={() => (
    <div className="ExploreThoughtCatcherComposerOverlayPrompt" data-emotion={s.thought?.emotionId}>
      <p>{s.greeting}</p>
      <h3>How are you feeling today?</h3>
      <EmotionSelector
        onSelect={handleSelect}
        selectedGetter={() => s.thought?.emotionId ?? null}
        disabled={!s.thought}
        canChangeAfterSelection
      />
    </div>
  )} />
  
}

export default ExploreThoughtCatcherComposerOverlayPrompt;