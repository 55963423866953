import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import './ThoughtTypeRenderer.scss';

interface ThoughtTypeRendererProps {
  private?: boolean
  showIcon?: boolean
  onClick?: () => void,
}

const ThoughtTypeRenderer: React.FC<ThoughtTypeRendererProps> = props => {
  return <Observer children={() => {
    const type = props.private ? 'private' : 'public';
    return <strong className="ThoughtTypeRenderer" data-type={type} onClick={props.onClick}>
      {props.private && props.showIcon !== false && <BaseIcon name="lock" />}
      <span className="ThoughtTypeRendererLabel">{type}</span>
    </strong>
  }} />
}

export default ThoughtTypeRenderer;