// import { RootRouteDef } from './root.route';
import { AdminModuleRouteDef } from "../modules/Admin/Admin.module.routes";
import { AuthModuleRouteDef } from "../modules/Auth/Auth.module.routes";
import { ClientModuleRouteDef } from "../modules/Client/Client.module.routes";
import { PublicModuleRouteDef } from "../modules/Public/Public.module.routes";

export const RouteIndex = [
  ClientModuleRouteDef,
  AdminModuleRouteDef,
  AuthModuleRouteDef,
  PublicModuleRouteDef,
];