import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { Comment, CommentableModelType } from '../../models/makeComment.model';
import { Thought } from '../../models/makeThought.model';
import CommentComposer from '../CommentComposer/CommentComposer';
import CommentList from '../CommentList/CommentList';
import './CommentsManager.scss';

interface CommentsManagerProps {
  comments: Comment[],
  thought: Thought,
  canComment?: boolean,
  showModeratorControls?: boolean,
  replyToModelType?: CommentableModelType,
  replyToModelId?: string,
  disableInteractions?: boolean
  autoFocus?: boolean
}

const CommentsManager: React.FC<CommentsManagerProps> = props => {

  const p = useProps(props);

  const { AUTH } = useControllers();

  const s = useStore(() => ({
    get currentUserCanModerate() {
      return AUTH.isModerator;
    },
    handleCommentPosted: action((c: Comment) => {
      p.comments.push(c);
    }),
    get shouldShowComposer() {
      return p.replyToModelType && p.replyToModelId && p.canComment && !p.thought.timeDeleted;
    }
  }));

  return <Observer children={() => (
    <div className="CommentsManager">
      { p.comments.length > 0 && <CommentList 
        comments={p.comments} 
        thought={p.thought}
        showModeratorControls={p.showModeratorControls}
        disableInteractions={p.disableInteractions}
      />}
      { s.shouldShowComposer && (
        <CommentComposer
          replyToModelType={p.replyToModelType!}
          replyToModelId={p.replyToModelId!}
          onCommentPosted={s.handleCommentPosted}
          autoFocus={p.autoFocus}
        />
      )}
    </div>
  )} />
}

export default CommentsManager;