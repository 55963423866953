import React from "react";
import { makeIconDef } from "./makeIconDef";

export const GridIconDef = {
  'grid': makeIconDef('grid', {
    regular: () => <>
      <rect x="4.5" y="4.5" width="6" height="6" stroke="currentColor" />
      <rect x="4.5" y="13.5" width="6" height="6" stroke="currentColor" />
      <rect x="13.5" y="4.5" width="6" height="6" stroke="currentColor" />
      <rect x="13.5" y="13.5" width="6" height="6" stroke="currentColor" />
    </>,
    filled: () => <>
      <rect x="4" y="4" width="7" height="7" fill="currentColor" />
      <rect x="4" y="13" width="7" height="7" fill="currentColor" />
      <rect x="13" y="4" width="7" height="7" fill="currentColor" />
      <rect x="13" y="13" width="7" height="7" fill="currentColor" />
    </>
  })
}