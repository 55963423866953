import React from "react";
import { makeIconDef } from "./makeIconDef";

export const TaxonomyIconDef = {
	taxonomy: makeIconDef('taxonomy', {
		regular: () => <>
			<path opacity="0.5" d="M14 5C14 4.44772 14.4477 4 15 4H16C17.1046 4 18 4.89543 18 6V7C18 7.55228 17.5523 8 17 8H15C14.4477 8 14 7.55228 14 7V5Z" fill="currentColor" />
			<path opacity="0.9" d="M10 5C10 4.44772 10.4477 4 11 4H13C13.5523 4 14 4.44772 14 5V7C14 7.55228 13.5523 8 13 8H11C10.4477 8 10 7.55228 10 7V5Z" fill="currentColor" />
			<path fillRule="evenodd" clipRule="evenodd" d="M9 7V8H10H19C19.5523 8 20 8.44771 20 9V18C20 18.5523 19.5523 19 19 19H5C4.44772 19 4 18.5523 4 18V9V7V6C4 5.44772 4.44772 5 5 5H9V7ZM19 7H11H10V6V5C10 4.44772 9.55228 4 9 4H5C3.89543 4 3 4.89543 3 6V7V9V18C3 19.1046 3.89543 20 5 20H19C20.1046 20 21 19.1046 21 18V9C21 7.89543 20.1046 7 19 7ZM6.92927 5.74363C6.83903 5.59253 6.676 5.5 6.5 5.5C6.324 5.5 6.16097 5.59253 6.07073 5.74363L4.72698 7.99363C4.58539 8.23071 4.6628 8.53768 4.89988 8.67927C5.13696 8.82086 5.44393 8.74345 5.58552 8.50637L5.59959 8.48281H6.28125C6.55739 8.48281 6.78125 8.25896 6.78125 7.98281C6.78125 7.70667 6.55739 7.48281 6.28125 7.48281H6.19681L6.5 6.97515L7.41448 8.50637C7.55607 8.74345 7.86304 8.82086 8.10012 8.67927C8.3372 8.53768 8.41461 8.23071 8.27302 7.99363L6.92927 5.74363ZM15.1553 17.3787C15.0764 17.6943 15.3151 18 15.6404 18C15.8698 18 16.0698 17.8439 16.1255 17.6213L16.5308 16H17.6096C17.839 16 18.039 15.8439 18.0947 15.6213C18.1736 15.3057 17.9349 15 17.6096 15H16.7808L17.2808 13H18.3596C18.589 13 18.789 12.8439 18.8447 12.6213C18.9236 12.3057 18.6849 12 18.3596 12H17.5308L17.8755 10.6213C17.9544 10.3057 17.7157 10 17.3904 10C17.161 10 16.961 10.1561 16.9053 10.3787L16.5 12H14.5308L14.8755 10.6213C14.9544 10.3057 14.7157 10 14.3904 10C14.161 10 13.961 10.1561 13.9053 10.3787L13.5 12H12.3904C12.161 12 11.961 12.1561 11.9053 12.3787C11.8264 12.6943 12.0651 13 12.3904 13H13.25L12.75 15H11.6404C11.411 15 11.211 15.1561 11.1553 15.3787C11.0764 15.6943 11.3151 16 11.6404 16H12.5L12.1553 17.3787C12.0764 17.6943 12.3151 18 12.6404 18C12.8698 18 13.0698 17.8439 13.1255 17.6213L13.5308 16H15.5L15.1553 17.3787ZM15.75 15L16.25 13H14.2808L13.7808 15H15.75Z" fill="currentColor" />
		</>
	})
}