import { action, runInAction, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Nillable, TimezoneMode } from '../../base/@types';
import BodyCopyRenderer from '../../base/components/BodyCopyRenderer/BodyCopyRenderer';
import PseudoLink from '../../base/components/PseudoLink/PseudoLink';
import TimeDisplayer from '../../base/components/TimeDisplayer/TimeDisplayer';
import { ContextColor } from '../../base/constants/color.enum';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { ChatMediator } from '../../base/mediators/chat.mediator';
import joinClassName from '../../base/utils/className.utils';
import { checkIfShouldInvertStyle, getContextColorStyle } from '../../base/utils/colors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { ChatMessage } from '../../models/makeChatMessage.model';
import ChatMessageComposer from '../ChatMessageComposer/ChatMessageComposer';
import './ChatBubble.scss';

interface ChatBubbleProps {
  chat?: ChatMediator;
  className?: string;
  id?: string;
  align?: 'left' | 'right';
  color?: string;
  size?: 'sm' | 'md';
  timestamp?: Nillable<string | Date | moment.Moment>;
  timezoneMode?: TimezoneMode,
  message?: ChatMessage,
  onToggleHide?: (id?: string) => void,
}

const ChatBubble = (props: React.PropsWithChildren<ChatBubbleProps>) => {

  const p = useProps(props);

  const { AUTH } = useControllers();

  const ref = React.useRef(null);

  const s = useStore(() => ({
    shouldInvert: false,
    showControls: false,
    editModeOn: false,
    get style() {
      return getContextColorStyle(ContextColor.Primary, p.color);
    },
    getBodyContent() {
      return p.message ? <BodyCopyRenderer source={p.message.body} /> : props.children
    },
    get body() {
      return <>
        {s.getBodyContent()}
        {(s.isEdited && s.canFacilitate && <em className="ChatBubbleEditedTag"> (edited)</em>)}
      </>;
    },
    get renderedContent() {
      return s.isHidden ? (
        s.canFacilitate ? (
          <>[HIDDEN] {s.body}</>
        ) : (
          'This message has been hidden.'
        )
      ) : s.body
    },
    get align() {
      return p.align || 'left';
    },
    get interactable() {
      return Boolean(p.onToggleHide);
    },
    get isHidden() {
      return Boolean(p.message?.timeHidden);
    },
    get hideButtonLabel() {
      return s.isHidden ? 'Unhide' : 'Hide';
    },
    get canFacilitate() {
      return AUTH.can.supportGroups_.facilitate_.someUserGroups;
    },
    get canEdit() {
      return s.canFacilitate && p.chat && p.message;
    },
    get isEdited() {
      return !!p.message?.original;
    },
    get chatHasEnded() {
      return Boolean(p.chat?.hasEnded);
    }
  }));
  
  useEffect(() => {
    runInAction(() => {
      s.shouldInvert = checkIfShouldInvertStyle(ref);
    })
  });

  const handleClick = action(() => {
    if (s.chatHasEnded) return;
    if (s.interactable && !s.editModeOn) s.showControls = !s.showControls;
  })

  const toggleHide = () => {
    p.onToggleHide && p.onToggleHide(p.id);
  }

  const editMessage = action(() => {
    if (!p.chat || !p.message) return;
    s.editModeOn = true;
  })
  const closeEditMode = action(() => {
    s.editModeOn = false;
  })
  const handleEditSuccess = action(() => {
    closeEditMode();
  })

  useOnMount(() => {
    const handler = () => {
      closeEditMode();
    }
    const disposeAutoCloseEditOnChatEnd = when(
      () => s.chatHasEnded,
      handler
    );
    return () => {
      disposeAutoCloseEditOnChatEnd();
    }
  })

  return <Observer children={() => (
    <article ref={ref}
      className={
        joinClassName(
          "ChatBubble",
          p.className,
          s.align,
          s.shouldInvert && 'inverted',
          p.size || 'sm',
          s.interactable && 'interactable',
          s.isHidden && 'isHidden',
          s.editModeOn && 'editing',
        )
      }
      style={s.style}
      data-message-id={p.message?.id}
    >
      <div className="ChatBubbleInner">
        <div className="ChatBubbleBubble" onClick={handleClick}>
          <div className="ChatBubbleBackgroundShape">
            {
              p.size !== 'sm' && (
                <svg className="ChatBubbleArrow" viewBox="0 0 33 29">
                  {s.align === 'left' && <path fillRule="evenodd" clipRule="evenodd" d="M18.5355 19.4645C19.4732 18.5268 20.745 18 22.0711 18H23H28H33V13V10V8V5V0H28H5H0V5V8V9.82903C0 11.2628 0.598655 12.6514 1.75849 13.4944C2.45465 14.0004 3.17949 14.4693 3.93009 14.8983C5.71473 15.9182 7 17.7212 7 19.7767V26.1716C7 27.9534 9.15429 28.8457 10.4142 27.5858L18.5355 19.4645Z" />}
                  {s.align === 'right' && <path fillRule="evenodd" clipRule="evenodd" d="M14.4645 19.4645C13.5268 18.5268 12.255 18 10.9289 18H10H5H0V13V10V8V5V0H5H28H33V5V8V9.82903C33 11.2628 32.4013 12.6514 31.2415 13.4944C30.5453 14.0004 29.8205 14.4693 29.0699 14.8983C27.2853 15.9182 26 17.7212 26 19.7767V26.1716C26 27.9534 23.8457 28.8457 22.5858 27.5858L14.4645 19.4645Z" />}
                </svg>
              )
            }
          </div>
          <div className="ChatBubbleContent">
            {s.editModeOn && p.chat && p.message ? (
              <ChatMessageComposer
                chat={p.chat}
                message={p.message}
                onSuccess={handleEditSuccess}
              />
            ) : s.renderedContent}
          </div>
        </div>
        <TimeDisplayer className="ChatBubbleTimestamp" value={p.timestamp} timezoneMode={p.timezoneMode} />
      </div>
      { s.showControls && <div className="ChatBubbleControls">
        {s.editModeOn ?
          <>
            <PseudoLink onClick={closeEditMode}>Cancel</PseudoLink>
          </>
          :
          <>
            <PseudoLink dataCy="hideMessage" onClick={toggleHide}>{s.isHidden ? 'Unhide' : 'Hide'}</PseudoLink>
            <PseudoLink dataCy="editMessage" onClick={editMessage}>Edit</PseudoLink>
          </>
        }
      </div>}
    </article>
  )} />
}

export default ChatBubble;