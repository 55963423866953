import { Observer } from 'mobx-react-lite';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Undefinable } from '../../base/@types';
import { Primary } from '../../base/constants/color.enum';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { ChatMediator } from '../../base/mediators/chat.mediator';
import { getContextColorStyle } from '../../base/utils/colors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { makeUrl } from '../../base/utils/url.utils';
import { SupportGroup } from '../../models/makeSupportGroup.model';
import ChatTitleDisplayer from '../ChatWindow/ChatTitleDisplayer';
import SupportGroupIcon from '../SupportGroupIcon/SupportGroupIcon';
import './MessengerPanelChatEntry.scss';

interface MessengerPanelChatEntryProps {
  chat: ChatMediator,
  prefix?: string,
}

const MessengerPanelChatEntry: React.FC<MessengerPanelChatEntryProps> = props => {
  const p = useProps(props);
  const { AUTH } = useControllers();
  const s = useStore(() => ({
    get participants() {
      return p.chat.participants || [];
    },
    get isAdminOrCounsellor() {
      return AUTH.isCounsellor || AUTH.isStaff;
    },
    get associatedModel() {
      return p.chat.associatedModel
    },
    get style() {
      if (p.chat.type === 'support-group') {
        return getContextColorStyle(Primary, (p.chat.associatedModel as Undefinable<SupportGroup>)?.color ?? 'blueGreen')
      }
      return {};
    }
  }));
  return <Observer children={() => {
    const children = <div className="MessengerPanelChatEntryInner">
      {
        p.chat.type === 'support-group' && <SupportGroupIcon supportGroup={p.chat.associatedModel as SupportGroup} size={32}/>
      }
      <div>
        <p className="MessengerPanelChatEntryPreTitle">
          { s.associatedModel ? <span>Session {s.isAdminOrCounsellor && `#${s.associatedModel.id}`}</span>
            : <span>Chat {s.isAdminOrCounsellor && `#${p.chat.id}`}</span>
          }
        </p>
        <h4><ChatTitleDisplayer chat={p.chat} /></h4>
      </div>
    </div>
    const commonAttr = {
      className: 'MessengerPanelChatEntry',
      children,
      style: s.style
    }
    return (
      <NavLink
        {...commonAttr}
        to={makeUrl(p.prefix, p.chat.id)}
      /> 
    )
  }} />
}

export default MessengerPanelChatEntry;