import { Nillable, StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { createUTCMoment, YYYYMMDD } from "../base/utils/time.utils";
import { ModelName } from "../constants/modelNames.enum";
import { StripePriceIdToSubscriptionFrequency, StripeSubscriptionPriceId, SubscriptionFrequency } from "../constants/subscription.enums";
import { User } from "./makeUser.model";

export const makeSubscriptionSnapshot = () => ({
  id: '',
  userId: null as Nillable<string>,
  created_at: '' as Nillable<string>,
  deleted_at: '' as Nillable<string>,
  updated_at: '' as Nillable<string>,
  ends_at: '' as Nillable<string>,
  stripe_status: '' as string,
  stripe_id: '' as string,
  stripe_price: '' as StripeSubscriptionPriceId,
  quantity: 0 as number,
  nextBillingDate: '' as Nillable<string>,
})

export type SubscriptionSnapshot = ReturnType<typeof makeSubscriptionSnapshot>;

export type SubscriptionRelatedModels = {
  user: User,
}

export type SubscriptionExtendedProperties = {
  readonly timeCreated: Nillable<string>,
  readonly timeUpdated: Nillable<string>,
  readonly timeDeleted: Nillable<string>,
  readonly timeCancelled: Nillable<string>,
  readonly nextDonationDate: Nillable<string>,
  readonly amount: number,
  readonly stripePriceId: StripeSubscriptionPriceId,
  readonly frequency: Exclude<SubscriptionFrequency, SubscriptionFrequency.oneTime>,
}

export type Subscription = StandardModel<SubscriptionSnapshot, SubscriptionRelatedModels, SubscriptionExtendedProperties>;

export const makeSubscription = createStandardModelFactory<Subscription, SubscriptionRelatedModels, SubscriptionExtendedProperties>({
  name: ModelName.subscriptions,
  snapshotFactory: makeSubscriptionSnapshot,
  relationshipsSchema: {
    user: ModelName.users,
  },
  extendedPropertiesFactory: (m, $, localDB) => ({
    get timeCreated() {
      return m.created_at;
    },
    get timeUpdated() {
      return m.updated_at;
    },
    get timeDeleted() {
      return m.deleted_at;
    },
    get timeCancelled() {
      return m.ends_at;
    },
    get amount() {
      return m.quantity / 100;
    },
    get stripePriceId() {
      return m.stripe_price;
    },
    get nextDonationDate() {
      if (m.stripe_status.toLowerCase() !== 'active') return null;
      var frequency = 'month' as 'month' | 'week';
      switch (m.frequency) {
        case SubscriptionFrequency.weekly:
          frequency = 'week';
          break;
        case SubscriptionFrequency.monthly:
        default:
          frequency = 'month';
      }
      return m.nextBillingDate || createUTCMoment(m.timeCreated).add(1, frequency).format(YYYYMMDD);
    },
    get frequency() {
      return StripePriceIdToSubscriptionFrequency[m.stripePriceId] as Exclude<SubscriptionFrequency, SubscriptionFrequency.oneTime>;
    },
  }),
})
