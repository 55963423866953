import { always } from "../../../../../base/utils/ramdaEquivalents.utils";
import { makeObservableRouteDef } from "../../../../../base/utils/routes.utils";
import AccountPageAddressManager from "./AccountPageAddressManager.page";

export const AccountPageAddressManagerRouteDef = makeObservableRouteDef({
	identifier: 'account-address-manager',
	urlFactory: always('addresses'),
	composeTitle: always('Manage Addresses'),
	component: AccountPageAddressManager,
	icon: 'map-pin',
})