import { reaction } from "mobx";
import { Nillable } from "../@types";

export function addClass(el: Element | null, className: string) {
  if (!el) return;
  el.classList.add(className);
}
export function removeClass(el: Element | null, className: string) {
  if (!el) return;
  el.classList.remove(className);
}
export function hasClass(el: Element | null, className: string) {
  if (!el) return;
  return el.classList.contains(className);
}
export function bindClass(
  el: Element | null, 
  condition: () => boolean,
  className: string, 
) {
  return reaction(condition, arg => {
    if (arg) addClass(el, className);
    else removeClass(el, className);
  }, { fireImmediately: true });
}

type getHeightOptions = {
  includePadding?: boolean,
};
export function getHeight(
  el: Element | null, 
  options?: getHeightOptions,
) {
  if (!el) return 0;
  return el.clientHeight;
}

export type ValidSelectorOrElement = string | 'root' | 'html' | 'body' | Element;

export function element(el: Nillable<ValidSelectorOrElement>) {
  let element: Element | null;
  if (el === 'root' || el === 'html' || !el) element = document.documentElement;
  else if (el === 'body') element = document.body;
  else element = typeof el === 'string' ? document.querySelector(el) : el;
  return {
    element,
    addClass: (className: string) => addClass(element, className),
    removeClass: (className: string) => removeClass(element, className),
    hasClass: (className: string) => hasClass(element, className),
    bindClass: (condition: () => boolean, className: string) => bindClass(element, condition, className),
    getHeight: (options?: getHeightOptions) => getHeight(element, options),
    click: () => (element as HTMLElement)?.click?.(),
  }
}