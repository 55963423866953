import { Observer } from 'mobx-react-lite';
import React from 'react';
import sanitize from 'sanitize-html';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { ChatMediator } from '../../base/mediators/chat.mediator';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { uniq } from '../../base/utils/ramdaEquivalents.utils';
import { equalByString } from '../../base/utils/string.utils';
import { ChatMessage } from '../../models/makeChatMessage.model';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import './CounsellingEmail.scss';

interface CounsellingEmailProps {
  chat: ChatMediator,
  message: ChatMessage,
  open?: boolean,
}

const CounsellingEmail: React.FC<CounsellingEmailProps> = props => {

  const p = useProps(props);
  const { AUTH } = useControllers();

  const s = useStore(() => ({
    get participant() {
      return p.chat.participants.find(participant => equalByString(participant.id, p.message.participantId));
    },
    get senderId() {
      return s.participant?.userId;
    },
    get receiverIds() {
      return uniq(p.chat.participants.filter(part => !equalByString(part.userId, s.senderId)).map(part => part.userId).filter(i=>i));
    },
    get sanitizedBody() {
      return sanitize(p.message.body)
    },
    get senderIsCurrentUser() {
      return equalByString(s.senderId, AUTH.currentUser?.id)
    }
  }));

  return <Observer children={() => (
    <div className={
      joinClassName(
        'CounsellingEmail',
        p.open && 'open',
        s.senderIsCurrentUser && 'own',
      )
    }>
      <header className="CounsellingEmailHeader">
        <DateRenderer value={p.message.timeCreated} format="LLL" />
        <h4>
          <strong>From: </strong>
          <UsernameRenderer userId={s.participant?.userId} />
        </h4>
        <p>
          <strong>To: </strong>
          { s.receiverIds.map(id => <UsernameRenderer userId={id} key={id} />) }
        </p>
      </header>
      <div className="CounsellingEmailBody">
        <div className="CounsellingEmailBodyInner" dangerouslySetInnerHTML={{  __html: s.sanitizedBody }} />
      </div>
    </div>
  )} />

}

export default CounsellingEmail;