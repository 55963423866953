import { Observer } from 'mobx-react-lite';
import React from 'react';
import AppPage from '../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../base/components/AppPageHeader/AppPageHeader';
import OverlayCloseButton from '../../../../base/components/OverlayCloseButton/OverlayCloseButton';
import ShadedBlock from '../../../../base/components/ShadedBlock/ShadedBlock';
import UIBlock from '../../../../base/components/UIBlock/UIBlock';
import { getColorHexByName } from '../../../../base/utils/colors.utils';
import UsernameRenderer from '../../../../components/UsernameRenderer/UsernameRenderer';
import { User } from '../../../../models/makeUser.model';
import UserFeesManager from '../UserFeesManager/UserFeesManager';
// import './OverlayUserFeesManager.scss';

type OverlayUserFeesManagerProps = {
  user: User,
}

const OverlayUserFeesManager: React.FC<OverlayUserFeesManagerProps> = props => {
  return <Observer children={() => (
    <AppPage className="OverlayUserFeesManager" color={getColorHexByName('brightPurple')}>
      <AppPageHeader 
        title={props.user.timeDeleted ? 'Service Fees' : 'Manage Service Fees'}
        afterTitle={<UsernameRenderer user={props.user} />}
        endSlot={<OverlayCloseButton />}
      />
      <AppPageContent>
        <UIBlock padded>
          <ShadedBlock>
            <UserFeesManager user={props.user} key={props.user.id} />
          </ShadedBlock>
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
}

export default OverlayUserFeesManager;