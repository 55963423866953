import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Nullable } from '../../base/@types';
import BaseInput from '../../base/components/BaseInput/BaseInput';
import BaseRadioGroup from '../../base/components/BaseRadioGroup/BaseRadioGroup';
import BaseSpacer from '../../base/components/BaseSpacer/BaseSpacer';
import CurrencyRenderer from '../../base/components/CurrencyRenderer/CurrencyRenderer';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { CounsellingSessionFeeTypes, CounsellingSessionPaidFee } from '../../constants/counsellingFeeTypes.constants';
import { CounsellingApplication } from '../../models/makeCounsellingApplication.model';
import { CounsellingSession } from '../../models/makeCounsellingSession.model';

interface CounsellingSessionFeeTypeSelectorProps<T extends object = object> {
  application?: Nullable<CounsellingApplication>,
  session?: Nullable<CounsellingSession>,
  form: T,
  field: keyof T & string,
  disabled?: any,
  hideCustomField?: boolean,
}

const CounsellingSessionFeeTypeSelector = <T extends object = object>(props: React.PropsWithChildren<CounsellingSessionFeeTypeSelectorProps<T>>) => {
  const p = useProps(props);
  const s = useStore(() => ({
    get defaultOptions() {
      return CounsellingSessionFeeTypes.map(type => ({
        label: <>{type.name} {type.value ? <span><CurrencyRenderer value={type.value} trimEmptyDecimals /></span> : ''}</>,
        value: type.value,
        name: type.name,
      }))
    },
    get userOption() {
      return p.application?.isFree && p.application?.clientAgreedDonationAmountPerSession ? {
        label: <>User preferred value <CurrencyRenderer value={p.application.clientAgreedDonationAmountPerSession} /></>,
        value: p.application.clientAgreedDonationAmountPerSession,
      } : undefined
    },
    get allOptions() {
      switch (true) {
        case p.session?.isPaidSession:
          return s.defaultOptions.filter(o => o.name === CounsellingSessionPaidFee.name);
        // eslint-disable-next-line no-fallthrough
        case p.session?.isFreeSession:
        default:
          return s.userOption ? [
            s.userOption,
            ...s.defaultOptions,
          ] : s.defaultOptions
      }
    },
    get showCustomField() {
      return !p.hideCustomField && p.session?.isFreeSession
    },
  }))
  return <Observer children={() => (
    <div className="CounsellingSessionFeeTypeSelector">
      <BaseRadioGroup
        form={p.form}
        field={p.field}
        inline
        options={s.allOptions}
        disabled={p.disabled || s.allOptions.length === 1}
      />
      {
        s.showCustomField && <>
          <BaseSpacer size="sm" />
          <BaseInput type="number" label="Or enter a value (EUR):" min={0} step={0.01}
            form={p.form} field={p.field} disabled={p.disabled}
          />
        </>
      }
    </div>
  )} />
}

export default CounsellingSessionFeeTypeSelector;