import { Observer } from 'mobx-react-lite';
import React from 'react';
import joinClassName from '../../utils/className.utils';
import './EmailLinkRenderer.scss';

interface EmailLinkRendererProps {
  className?: string,
  value?: string | null,
  emptyValue?: string | null,
}

const EmailLinkRenderer: React.FC<EmailLinkRendererProps> = props => {
  return <Observer children={() => (
    <a className={joinClassName('EmailLinkRenderer', props.className)}
      href={props.value ? `mailto:${props.value}` : undefined}
      title={!!props.value ? ('Email to ' + props.value) : ''}
    >
      {props.value || props.emptyValue}
    </a>
  )} />
}

export default EmailLinkRenderer;