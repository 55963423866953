import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject } from '../../@types';
import { Form } from '../../mediators/form.mediator';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { useFormContext } from '../FormForm/Form.context';
import TimeInput, { TimeInputProps } from '../TimeInput/TimeInput';
// import './FormTimeInput.scss';

type FormTimeInputProps<T extends AnyObject = AnyObject> = Omit<TimeInputProps<T>, 'form'> & {
  form?: Form<T>,
}

function FormTimeInput<T extends AnyObject = AnyObject>(
  props: React.PropsWithChildren<FormTimeInputProps<T>>
) {

  const context = useFormContext<T>();
  const { form } = context;
  const p = useProps(props);
  const s = useStore(() => ({
    get form() { return p.form ?? form },
  }));

  return <Observer children={() => (
    <TimeInput {...p}
      className={joinClassName('FormTimeInput', p.className)}
      form={s.form.fields[p.field]}
      field="value"
      name={p.name ?? p.field}
      disabled={p.disabled || context.disabled}
    />
  )} />
}

export default FormTimeInput;