import { action, flow, observable, toJS, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState } from '../../../../base/@types';
import AppPage from '../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../base/components/AppPageHeader/AppPageHeader';
import BaseButton from '../../../../base/components/BaseButton/BaseButton';
import BaseGrid from '../../../../base/components/BaseGrid/BaseGrid';
import BaseInput from '../../../../base/components/BaseInput/BaseInput';
import CommandList from '../../../../base/components/CommandList/CommandList';
import { CommandListItem } from '../../../../base/components/CommandList/CommandListItem';
import ErrorRenderer from '../../../../base/components/ErrorRenderer/ErrorRenderer';
import InfoBanner from '../../../../base/components/InfoBanner/InfoBanner';
import OverlayCloseButton from '../../../../base/components/OverlayCloseButton/OverlayCloseButton';
import TelInput from '../../../../base/components/TelInput/TelInput';
import UIBlock from '../../../../base/components/UIBlock/UIBlock';
import { AuthEndpoints } from '../../../../base/endpoints/auth.endpoints';
import { useOnMount } from '../../../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import { makeCancelAction } from '../../../../base/utils/actionConfig.utils';
import { reportError } from '../../../../base/utils/errors.utils';
import { useStore } from '../../../../base/utils/mobx.utils';
import tick from '../../../../base/utils/waiters.utils';
import { ModelName } from '../../../../constants/modelNames.enum';
import { resendSMSVerificationCode } from '../../../../controllers/auth/verifyMobileNumber';
import { prependPhoneAreaCode } from '../../../../hooks/useGetPhoneAreaCode';
import { User } from '../../../../models/makeUser.model';
import { saveCurrentUser } from '../../../../requests/user.requests';
import './OverlayPhoneVerification.scss';

interface OverlayPhoneVerificationProps {}

const OverlayPhoneVerification: React.FC<OverlayPhoneVerificationProps> = React.memo(props => {

  const { API, UI, AUTH } = useControllers();

  const { DIALOG } = UI;

  const s = useStore(() => ({
    form: {
      verificationCode: '',
    },
    get formIsValid() {
      return !!s.form.verificationCode;
    },
    get maskedUserPhone() {
      return '***' + AUTH.currentUser?.mobileNumber?.substring(AUTH.currentUser?.mobileNumber.length - 4);
    },
    state: {
      isSubmitting: false,
      submitted: false,
      isResendingCode: false,
      codeResent: false,
    },
    handleInputChange() {
      if (s.form.verificationCode.length === 6) sendVerifyRequest();
    },
  }));

  const appDisplayName = 'turn2me';

  const sendVerifyRequest = flow(function * () {
    if (!s.formIsValid) {
      DIALOG.error({
        heading: 'Please enter a valid code.'
      })
      return;
    }
    try {
      const url = AuthEndpoints.verification.sms();
      const payload = toJS(s.form);
      s.state.isSubmitting = true;
      const user: User = yield API.post<User>(url, ModelName.users, payload);
      if (!user.timeVerifiedMobileNumber) throw Error('Mobile not verified correctly.');
      s.state.submitted = true;
      AUTH.fetchCurrentUserInfo();
      yield DIALOG.positive({
        name: 'phone-verification-success',
        heading: 'Your phone number has been verified.',
        body: `Thanks for verifying your phone number. You can now continue to use ${appDisplayName} services.`,
      })
      closeSelf();
    } catch (e) {
      DIALOG.present({
        name: 'phone-verification-failure',
        heading: 'Verification failed',
        body: `Please recheck your code. If you are sure the code is correct, you might need to request a new code. If this keeps happening, please contact customer support.`,
        colorCodedState: ColorCodedState.alert,
        defaultActions: ['positive'],
      })
      reportError(e);
    } finally {
      s.state.isSubmitting = false;
    }
  })

  const resendCode = () => new Promise<boolean>(flow(function * (resolve, reject) {
    try {
      s.state.isResendingCode = true;
      yield resendSMSVerificationCode(AUTH);
      yield tick(3000);
      s.state.codeResent = true;
      s.form.verificationCode = '';
      resolve(true);
    } catch (e) {
      reject(e);
    } finally {
      s.state.isResendingCode = false;
    }
  }))

  const showPhoneQuickEditDialog = action(() => {
    if (!AUTH.currentUser) {
      throw Error('Trying to edit phone number without a valid current user');
    }

    const form = observable({
      mobileNumber: '',
    })

    AUTH.ROOT!.children.ANALYTICS.getUserDialCode().then(action((code: string | null) => {
      if (code) form.mobileNumber = prependPhoneAreaCode(code, form.mobileNumber);
    }))

    const handler = () => new Promise<boolean>(async (resolve, reject) => {
      try {
        AUTH.currentUser!.mobileNumber = form.mobileNumber;
        DIALOG.dismiss('phoneQuickEditDialog');
        await saveCurrentUser(API);
        resolve(true);
        DIALOG.positive({
          heading: 'Phone number updated',
          body: 'You should receive a new verification code shortly.',
          defaultActions: ['positive'],
        })
        resendCode();
      } catch(e) {
        reject(e);
        DIALOG.error({
          name: 'quick-edit-phone-fail',
          heading: 'Failed to update your phone number',
          body: <ErrorRenderer error={(e as any).response} />,
          defaultActions: ['positive'],
        })
      }
    })
    
    DIALOG.present({
      name: 'phoneQuickEditDialog',
      heading: 'Update your phone number',
      body: () => <BaseGrid columns={1}>
        <TelInput form={form} field="mobileNumber" label="Please enter a new phone number:" autoFocus onEnter={handler}/>
      </BaseGrid>,
      actions: [
        makeCancelAction(),
        {
          label: 'Save',
          action: handler,
        }
      ]
    })
  })

  const closeSelf = () => UI.OVERLAY.dismiss('OverlayPhoneVerification');

  useOnMount(() => when(() => !AUTH.isAuthenticated, closeSelf));

  return <Observer children={() => <AppPage className="OverlayPhoneVerification">
      <AppPageHeader title="Account Verification"
        endSlot={<OverlayCloseButton />}
      />
      <AppPageContent>
        <UIBlock padded spaceChildren>
          <h1><span>Verifying your</span> <span>mobile number</span></h1>
          { s.state.codeResent && <InfoBanner icon="info" colorCodedState={ColorCodedState.positive}>
            <p>A new verification code has been sent to your linked mobile number.</p>
          </InfoBanner> }
          <p>Before you start using {appDisplayName} services, you need to verify your phone number ({s.maskedUserPhone}). If you have received a <strong>6-digit code</strong> via SMS, please enter it below:</p>
          <BaseInput className="VerificationCodeField" type="text" form={s.form} field="verificationCode" pattern="[0-9]*" onChange={s.handleInputChange} placeholder="------" autoFocus autoComplete="off" autoCapitalize="off" autoCorrect="off"/>
          <BaseButton onClick={sendVerifyRequest} rounded size="lg" loading={s.state.isSubmitting} >Verify Now</BaseButton>
          <CommandList columnAppearance="simpleList">
            <CommandListItem icon="arrow" onClick={resendCode}>Resend verification code</CommandListItem>
            <CommandListItem icon="arrow" onClick={showPhoneQuickEditDialog}>Use a different phone number</CommandListItem>
          </CommandList>
        </UIBlock>
      </AppPageContent>
    </AppPage>
  } />
  
})

export default OverlayPhoneVerification;