import { action } from 'mobx';
import { ModelName } from '../../constants/modelNames.enum';
import { LocalDBController } from '../../controllers/localDB.controller';
import { SHOULD_LOG } from '../../env';
import { HasTimestamps } from '../../traits/hasTimestamps.trait';
import { AnyObject, HasId, Nillable, RelationshipDescriptor, RelationshipsSchema, SnapshotOf, StandardModel } from '../@types';
import { mapToIds } from '../utils/array.utils';
import { reportError } from '../utils/errors.utils';
import { copyWithJSON } from '../utils/object.utils';
import { getSnapshot } from '../utils/snapshot.utils';
import { pluralize, singularize } from '../utils/string.utils';
import { isArray, isNumberLike, isObject } from '../utils/typeChecks.utils';

export const enforceStringIdOnSnapshot = action(<T extends AnyObject = AnyObject>(object: T) => {
  const o = copyWithJSON(object);
  try {
    Object.keys(o).forEach(k => {
      if ((k === 'id' || k.match(/Id$/)) && isNumberLike(o[k])) 
        (o as any)[k] = o[k] + '';
      else if (k.match(/Ids$/)) 
        (o as any)[k] = (o[k] as any).map((id: any) => isNumberLike(id) ? id + '' : id);
      if (isArray(o[k])) 
        (o as any)[k] = o[k].map(enforceStringIdOnSnapshot);
      else if (isObject(o[k])) 
        (o as any)[k] = enforceStringIdOnSnapshot(o[k]);
    })
    return o;
  } catch (e) {
    reportError(e);
    return object;
  }
})

export const getStandardModelSnapshot = <T extends Nillable<StandardModel> = Nillable<StandardModel>>(obj: T) => getSnapshot(obj) as SnapshotOf<T>;
export const getSnapshotWithoutTimestamps = <T extends Nillable<HasTimestamps> = Nillable<HasTimestamps>>(obj: T) => {
  if (!obj) return obj;
  const snapshot = getSnapshot(obj) as HasTimestamps;
  const { timeCreated, timeDeleted, timeUpdated, ...rest } = snapshot;
  return rest;
}

export const setSnapshotRelationshipsInLocalDB = action(<T extends HasId, K extends AnyObject>(
  snapshot?: Partial<T>,
  schema?: RelationshipsSchema<T, K>,
  localDB?: LocalDBController,
  options?: { debug?: boolean },
) => {
  if (!snapshot || !schema || !localDB) return;
  Object.keys(schema).forEach(k => {
    const relationshipSnapshot = (snapshot as AnyObject)[k];
    if (options?.debug) {
      SHOULD_LOG() && console.log(snapshot, k, relationshipSnapshot);
    }
    if (!relationshipSnapshot) return;
    if (schema[k] === 'skip') return;
    const descriptor = isObject(schema[k]) ? schema[k] as RelationshipDescriptor<T> : {
      modelName: schema[k] as ModelName
    };
    const modelName = descriptor.modelName ?? pluralize(k) as ModelName;
    const has = descriptor.has || 'one';
    if (has === 'one') {
      localDB.setOrMerge(modelName, relationshipSnapshot);
      const identifierKeyName = descriptor.identifierKeyName || (singularize(k) + 'Id');
      (snapshot as any)[identifierKeyName] = relationshipSnapshot.id;
    } else {
      localDB.setOrMergeMany(modelName, relationshipSnapshot);
      const identifierKeyName = descriptor.identifierKeyName || (singularize(k) + 'Ids');
      (snapshot as any)[identifierKeyName] = mapToIds(relationshipSnapshot);
      if (options?.debug) {
        SHOULD_LOG() && console.log(snapshot, identifierKeyName, mapToIds(relationshipSnapshot));
        // debugger;
      }
    }
  })
})