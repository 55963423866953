import { action, observable } from "mobx";
import { isInCypressTestMode } from "../../env";
import tick from "../utils/waiters.utils";
import { useOnMount } from "./lifecycle.hooks";

export const useUpdatePageBackground = (
  color?: string,
  image?: string,
  transitionDuration?: number,
) => {
  useOnMount(() => {
    const rootStyle = document.documentElement.style;
    const state = observable({
      alive: true,
    });
    (async () => {
      await tick();
      if (!state.alive) return;
      if (color) rootStyle.backgroundColor = color;
      if (image) rootStyle.backgroundImage = image;
      if (transitionDuration !== undefined) rootStyle.transitionDuration = (isInCypressTestMode ? 100 : transitionDuration) + '';
    })();
    return action(() => {
      if (color) rootStyle.removeProperty('background-color');
      if (image) rootStyle.removeProperty('background-image');
      if (transitionDuration !== undefined) rootStyle.removeProperty('transition-duration');
      state.alive = false;
    })
  })
}

export const useClearPageBackground = () => useUpdatePageBackground('', '', 0);