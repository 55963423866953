import { Identifier, Nullable, StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { ModelName } from "../constants/modelNames.enum";
import { HasTimestamps, hasTimestamps } from "../traits/hasTimestamps.trait";
import { ChatParticipant } from "./makeChatParticipant.model";
import { ChatThread } from "./makeChatThread.model";

export type ChatMessageRelatedModels = {
  thread?: ChatThread,
  participant?: ChatParticipant,
}

export type ChatMessageSnapshot = {
  id: Identifier,
  threadId: Identifier,
  participantId: Identifier,
  original: ChatMessageSnapshot | null,
  originalId: Identifier,
  body: string,
  timeHidden: Nullable<string>,
  timeDeleted: Nullable<string>,
} & HasTimestamps

export enum ChatMessageType {
  ChatEventNotifier = 'ChatEventNotifier',
  Action__ClinicalSurveys = 'Action__ClinicalSurveys', 
  Action__OpenSupportGroup = 'Action__OpenSupportGroup', 
  Action__OpenBlog = 'Action__OpenBlog', 
  Action__OpenPodcast = 'Action__OpenPodcast',
}

export function makeChatMessageSnapshotBase() {
  return {
    id: '',
    threadId: '',
    participantId: '',
    original: null,
    originalId: '',
    body: '',
    timeHidden: '',
    type: null as Nullable<ChatMessageType>,
    ...hasTimestamps(),
  } as ChatMessageSnapshot
}
export type ChatMessageExtendedProperties = {
}

export function patchChatMessage(message?: ChatMessage, snapshot?: ChatMessageSnapshot) {
  const snapshotBase = makeChatMessageSnapshotBase();
  Object.keys(snapshotBase).forEach(key => {
    // @ts-ignore
    message[key] = snapshot[key];
  })
}

export type ChatMessage = StandardModel<ChatMessageSnapshot, ChatMessageRelatedModels, ChatMessageExtendedProperties>

export const makeChatMessage = createStandardModelFactory<ChatMessage, ChatMessageRelatedModels, ChatMessageExtendedProperties>({

  name: ModelName.chatMessages,

  snapshotFactory: makeChatMessageSnapshotBase,

  relationshipsSchema: {
    participant: ModelName.chatParticipants,
    thread: ModelName.chatThreads,
  },

})