import { Observer } from 'mobx-react-lite';
import React from 'react';
import UsernameRenderer, { UsernameRendererProps } from '../UsernameRenderer/UsernameRenderer';
import './UserFullNameRenderer.scss';

type UserFullNameRendererProps = Omit<UsernameRendererProps, 'useRealName' | 'showLastName'>;

const UserFullNameRenderer: React.FC<UserFullNameRendererProps> = props => {

  return <Observer children={() => <UsernameRenderer {...props} useRealName showLastName />} />

}

export default UserFullNameRenderer;