import { FourLevelNumber, Nillable, StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { always, cond } from "../base/utils/ramdaEquivalents.utils";
import { ModelName } from "../constants/modelNames.enum";
import { assignable } from "../traits/assignable.trait";
import { AssignmentGAD7 } from "./makeAssignment.model";
import { User } from "./makeUser.model";

export const makeSurveyGAD7Snapshot = () => ({
	...assignable(),
	levelOfAnxiety: '' as FourLevelNumber,
	levelOfWorryUncontrollability: '' as FourLevelNumber,
	levelOfWorryFrequency: '' as FourLevelNumber,
	levelOfInabilityToRelax: '' as FourLevelNumber,
	levelOfRestlessness: '' as FourLevelNumber,
	levelOfIrritability: '' as FourLevelNumber,
	levelOfFear: '' as FourLevelNumber,
	total: 0 as number,
	timeCreated: '' as Nillable<string>,
	timeUpdated: '' as Nillable<string>,
})

export type SurveyGAD7Snapshot = ReturnType<typeof makeSurveyGAD7Snapshot>;
export type SurveyGAD7RelatedModels = {
	assignment: AssignmentGAD7,
	assignedToUser: User,
	completedByUser: User,
}
export type SurveyGAD7 = StandardModel<SurveyGAD7Snapshot, SurveyGAD7RelatedModels>;

export const makeSurveyGAD7 = createStandardModelFactory<SurveyGAD7, SurveyGAD7RelatedModels>({
	name: ModelName.surveysGAD7,
	snapshotFactory: makeSurveyGAD7Snapshot,
	relationshipsSchema: {
		assignment: ModelName.assignments,
		assignedToUser: ModelName.users,
		completedByUser: ModelName.users,
	}
})


export const calculateGAD7SurveyScore = (s: SurveyGAD7) => {
	return (
		(+s.levelOfAnxiety) +
		(+s.levelOfWorryUncontrollability) +
		(+s.levelOfWorryFrequency) +
		(+s.levelOfInabilityToRelax) +
		(+s.levelOfRestlessness) +
		(+s.levelOfIrritability) +
		(+s.levelOfFear)
	)
};

export const getGAD7ScoreType = cond([
	[
		s => !s,
		always('No level of anxiety indicated')
	],
	[
		s => s > 0 && s < 5,
		always('Mild anxiety')
	],
	[
		s => s >= 5 && s < 10,
		always('Moderate anxiety')
	],
	[
		s => s >= 10 && s < 15,
		always('Moderately severe anxiety')
	],
	[
		s => s >= 15 && s <= 21,
		always('Severe anxiety')
	],
])
