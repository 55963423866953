import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { AlwaysTrueFn, cond } from '../../../../../base/utils/ramdaEquivalents.utils';
import './DateAndWeatherGreeting.scss';

interface DateAndWeatherGreetingProps {
  isFirstTimer?: boolean
}

const DateAndWeatherGreeting: React.FC<DateAndWeatherGreetingProps> = props => {

  const now = moment();
  const todayFormatted = now.format('D MMMM');

  const isChristmas = now.month() === 11 && [23,24,25,26].includes(now.date());
  const isNewYear = (now.month() === 0 && [1,2].includes(now.date())) || (now.month() === 11 && now.date() === 31);

  const message = cond([
    [
      () => isChristmas,
      () => '🎄 Merry Christmas! 🎄',
    ],
    [
      () => isNewYear,
      () => '🎊 Happy New Year! 🎊',
    ],
    [
      AlwaysTrueFn,
      () => props.isFirstTimer ? 'Welcome!' : 'Welcome Back!'
    ]
  ])();

  return <Observer children={() => (
    <div className="DateAndWeatherGreeting">
      <header>
        <h2>{todayFormatted}</h2>
        <p>{message}</p>
      </header>
      <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="19.4757" cy="23.2357" r="11.3291" fill="#FFC93E" />
        <circle cx="19.4746" cy="22.7945" r="10.7024" stroke="white" strokeWidth="1.25333" strokeLinecap="round" />
        <path d="M19.6833 9.17634V4.96985" stroke="white" strokeWidth="1.25333" strokeLinecap="round" strokeDasharray="3.13 3.13" />
        <path d="M19.6833 41.1249L19.6833 37.9379" stroke="white" strokeWidth="1.25333" strokeLinecap="round" />
        <path d="M8.95707 12.2963L6.67203 10.0112" stroke="white" strokeWidth="1.25333" strokeLinecap="round" />
        <path d="M29.3581 14.1616L32.5432 10.9766" stroke="white" strokeWidth="1.25333" strokeLinecap="round" />
        <path d="M33.1633 36.3432L29.9782 33.1581" stroke="white" strokeWidth="1.25333" strokeLinecap="round" />
        <path d="M5.78785 22.7415L1.28348 22.7415" stroke="white" strokeWidth="1.25333" strokeLinecap="round" />
        <path d="M38.3009 22.7415L33.7965 22.7415" stroke="white" strokeWidth="1.25333" strokeLinecap="round" />
        <path d="M10.6962 31.81L6.67203 35.8342" stroke="white" strokeWidth="1.25333" strokeLinecap="round" strokeDasharray="3.76 3.76" />
        <path fillRule="evenodd" clipRule="evenodd" d="M36.2521 44.3236C40.4333 44.3236 43.823 40.934 43.823 36.7527C43.823 32.5714 40.4333 29.1818 36.2521 29.1818C34.8014 29.1818 33.4461 29.5898 32.2945 30.2973C32.42 29.6981 32.486 29.077 32.486 28.4406C32.486 23.4623 28.4503 19.4266 23.4721 19.4266C18.6608 19.4266 14.7299 23.1961 14.4717 27.9429C12.1617 29.575 10.6533 32.2662 10.6533 35.3097C10.6533 40.1207 14.4223 44.0513 19.1687 44.3101V44.3236H19.6672H36.252L36.2521 44.3236Z" fill="url(#WeatherCloud_radial)" />
        <defs>
          <radialGradient id="WeatherCloud_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(38.8104 45.0229) rotate(-126.983) scale(31.7721 34.2673)">
            <stop stopColor="#12D4FF" />
            <stop offset="1" stopColor="#0096D6" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  )}/>
}

export default DateAndWeatherGreeting;