import { flow, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Nillable, Nullable } from '../../base/@types';
import ColorTagYoungPeople1214 from '../../base/components/ColorTag/ColorTagYoungPeople1214';
import ColorTagYoungPeople1517 from '../../base/components/ColorTag/ColorTagYoungPeople1517';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { joinTruthyWithSpace } from '../../base/utils/array.utils';
import { makeDisposerController } from '../../base/utils/disposer.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { ModelName } from '../../constants/modelNames.enum';
import { User, UserSnapshot } from '../../models/makeUser.model';
import { getUser } from '../../requests/user.requests';
import { UsernameTransformer } from '../../utils/user.utils';
import './UsernameRenderer.scss';

export interface UsernameRendererProps {
  user?: Nillable<Partial<User | UserSnapshot>>,
  userId?: Nillable<string>,
  useRealName?: boolean,
  showLastName?: boolean,
  showAsYouIfSelf?: boolean,
  showColorLabel?: boolean,
  usernameTransformer?: UsernameTransformer,
  onClick?: (u?: Nillable<Partial<User | UserSnapshot>>) => void,
}

const UsernameRenderer: React.FC<UsernameRendererProps> = props => {

  const p = useProps(props);

  const { API, AUTH, LOCALDB } = useControllers();

  const s = useStore(() => ({
    get currentUser() {
      return AUTH.currentUser
    },
    get isCurrentUser() {
      return s.userId === s.currentUser?.id;
    },
    get userId() {
      return p.userId || p.user?.id;
    },
    _user: null as Nullable<User>,
    get userFromLocalDB() {
      return p.userId ? LOCALDB.get<User>(ModelName.users, p.userId) : null;
    },
    get user() {
      return s.userFromLocalDB ?? p.user ?? s._user ?? (s.isCurrentUser ? s.currentUser : null);
    },
    get shouldShowAsYou() {
      return s.isCurrentUser && p.showAsYouIfSelf;
    },
    get displayResult() {
      if (s.user?.timeDeleted && !AUTH.isStaff) return '[Deactivated User]';
      const result = s.shouldShowAsYou ? 'You' : (p.usernameTransformer ?? (a => a))(
        p.useRealName ? (
          joinTruthyWithSpace(
            s.user?.firstName || '',
            p.showLastName && s.user?.lastName
          ) || s.user?.username
        ) : s.user?.username) || (
          s.userId ? `User #${s.userId}` : 'turn2me user'
        );
      return s.user?.timeDeleted ? result + ' [Deactivated]' : result;
    },
    get noNeedToFetch() {
      return s.user || s.shouldShowAsYou;
    },
    get shouldFetch() {
      return !s.noNeedToFetch;
    },
    get color() {
      return s.user?.color;
    },
    get handleClick() {
      return s.user ? () => p.onClick?.(s.user) : undefined
    },
  }));

  useOnMount(() => {
    const d = makeDisposerController();
    // if (s.shouldFetch) {
    //   d.add(
    //     when(
    //       () => Boolean(s.userId),
    //       flow(function* () {
    //         s._user = yield getUser(s.userId!, API, {
    //           getFromLocalById: s.userId
    //         })
    //       })
    //     )
    //   )
    // }
    d.add(
      reaction(
        () => s.userId,
        flow(function* () {
          s._user = yield getUser(s.userId!, API, {
            getFromLocalById: s.userId
          })
        })
      )
    )
    return d.disposer;
  })

  return <Observer children={() => (
    <span
      className="UsernameRenderer"
      data-user-id={s.userId}
      data-deactivated={!!s.user?.timeDeleted}
      onClick={s.handleClick}
    >
      {
        (p.showColorLabel === undefined || !!p.showColorLabel) && <><span className="UsernameRendererColorDot" style={{ backgroundColor: s.color ?? undefined }} />&nbsp;</>
      }<span className="UsernameRendererLabel">{ s.displayResult }</span>
      {
        AUTH.isStaff && <>
          {(s.user as User)?.isYoungPerson1214 && <span> <ColorTagYoungPeople1214 useAbbr /></span>}
          {(s.user as User)?.isYoungPerson1517 && <span> <ColorTagYoungPeople1517 useAbbr /></span>}
        </>
      }
    </span>
  )} />

}

export default UsernameRenderer;