import { action, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import { Timeframe } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { copyWithJSON } from '../../base/utils/object.utils';
import { AlwaysFalseFn } from '../../base/utils/ramdaEquivalents.utils';
import { createLocalMoment, createUTCMoment, YYYYMMDD } from '../../base/utils/time.utils';
import './OverlayTimeframeQuickPicker.scss';
import TimeframeQuickPickerButton from './TimeframeQuickPickerButton';

interface OverlayTimeframeQuickPickerProps {
	form: Timeframe,
	onSave: (form: Timeframe) => void,
}

const OverlayTimeframeQuickPicker: React.FC<OverlayTimeframeQuickPickerProps> = props => {

	const { UI } = useControllers();
	const p = useProps(props);

	const s = useStore(() => ({
		form: copyWithJSON(p.form),
		momentForm: { 
			startDate: createLocalMoment(p.form.startDate),
			endDate: createLocalMoment(p.form.endDate),
		},
		handleDatesChange: action((newValue: {startDate: moment.Moment | null, endDate: moment.Moment | null}) => {
			newValue.startDate && (s.form.startDate = newValue.startDate.format(YYYYMMDD));
			newValue.endDate && (s.form.endDate = newValue.endDate.format(YYYYMMDD));
			s.momentForm.startDate = createLocalMoment(s.form.startDate);
			s.momentForm.endDate = createLocalMoment(s.form.endDate);
		}),
		focusedInput: null as FocusedInputShape | null,
		handleFocusedInputChange: action((focusedInput: FocusedInputShape | null) => s.focusedInput = focusedInput),
		minDate: createUTCMoment('2015-10-31 00:00:00'),
		past7Days: {
			startDate: createLocalMoment().subtract(6, 'days').format(YYYYMMDD),
			endDate: createLocalMoment().endOf('day').format(YYYYMMDD),
		},
		lastWeek: {
			startDate: createLocalMoment().startOf('week').subtract(7, 'days').format(YYYYMMDD),
			endDate: createLocalMoment().startOf('week').subtract(1, 'days').format(YYYYMMDD),
		},
		thisMonth: {
			startDate: createLocalMoment().startOf('month').format(YYYYMMDD),
			endDate: createLocalMoment().endOf('day').format(YYYYMMDD),
		},
		lastMonth: {
			startDate: createLocalMoment().subtract(1, 'month').startOf('month').format(YYYYMMDD),
			endDate: createLocalMoment().subtract(1, 'month').endOf('month').format(YYYYMMDD),
		},
		thisYear: {
			startDate: createLocalMoment().startOf('year').format(YYYYMMDD),
			endDate: createLocalMoment().endOf('year').format(YYYYMMDD),
		},
		yearToDate: {
			startDate: createLocalMoment().startOf('year').format(YYYYMMDD),
			endDate: createLocalMoment().endOf('day').format(YYYYMMDD),
		},
		lastYear: {
			startDate: createLocalMoment().subtract(1, 'year').startOf('year').format(YYYYMMDD),
			endDate: createLocalMoment().subtract(1, 'year').endOf('year').format(YYYYMMDD),
		},
		get allTime() {
			return {
				startDate: s.minDate.format(YYYYMMDD),
				endDate: createLocalMoment().endOf('day').format(YYYYMMDD),
			}
		},
		applyRange: action((range: Timeframe) => {
			s.form.startDate = range.startDate;
			s.form.endDate = range.endDate;
		}),
		getDisplayDateRange: (range: Timeframe) => {
			const start = moment(range.startDate);
			const end = moment(range.endDate);
			if (start.isSame(end, 'month')) {
				return `${start.format('MMM D')}–${end.format('D')}`;
			} else {
				return `${start.format('MMM D')}–${end.format('MMM D')}`;
			}
		},
		getDisplayDateRangeWithYear: (range: Timeframe) => {
			const start = moment(range.startDate);
			const end = moment(range.endDate);
			if (start.isSame(end, 'year')) {
				if (start.isSame(end, 'month')) {
					return `${start.format('MMM D')}–${end.format('D')}, ${end.format('YYYY')}`;
				} else {
					return `${start.format('MMM D')}–${end.format('MMM D')}, ${end.format('YYYY')}`;
				}
			} else {
				return `${start.format('MMM D, YYYY')} – ${end.format('MMM D, YYYY')}`;
			}
		}
	}));

	const dismiss = () => UI.OVERLAY.dismiss('OverlayTimeframeQuickPicker');

	const save = () => {
		p.onSave(s.form);
		dismiss();
	}

	useOnMount(() => {
		const disposerOne = reaction(() => s.form.startDate, () => s.momentForm.startDate = createLocalMoment(s.form.startDate));
		const disposerTwo = reaction(() => s.form.endDate, () => s.momentForm.endDate = createLocalMoment(s.form.endDate));
		return () => {
			disposerOne(); disposerTwo();
		}
	})
	
	return <Observer children={() => (
		<div className="OverlayTimeframeQuickPicker">
			<header className="OverlayTimeframeQuickPickerHeader">
				<h2 className="OverlayTimeframeQuickPickerTitle">Select a Timeframe</h2>
			</header>
			<div className="OverlayTimeframeQuickPickerInner">
				<DateRangePicker
					startDate={s.momentForm.startDate}
					startDateId="timeframe-quick-picker-from"
					endDate={s.momentForm.endDate}
					endDateId="timeframe-quick-picker-to"
					onDatesChange={s.handleDatesChange}
					focusedInput={s.focusedInput}
					onFocusChange={s.handleFocusedInputChange}
					minDate={s.minDate}
					isOutsideRange={AlwaysFalseFn}
					enableOutsideDays={true}
					displayFormat="DD/MM/YYYY"
					numberOfMonths={UI.displayMode === 'phone' ? 1 : 2}
					required
				/>
				<div className="OverlayTimeframeQuickPickerButtonSet">
					<div className="OverlayTimeframeQuickPickerButtonSetInner">
						<TimeframeQuickPickerButton form={s.form} timeframe={s.past7Days} onClick={s.applyRange}>Past 7 days ({s.getDisplayDateRange(s.past7Days)})</TimeframeQuickPickerButton>
						<TimeframeQuickPickerButton form={s.form} timeframe={s.lastWeek} onClick={s.applyRange}>Last Week ({s.getDisplayDateRange(s.lastWeek)})</TimeframeQuickPickerButton>
						<TimeframeQuickPickerButton form={s.form} timeframe={s.thisMonth} onClick={s.applyRange}>This Month ({createLocalMoment(s.thisMonth.startDate).format('MMM')})</TimeframeQuickPickerButton>
						<TimeframeQuickPickerButton form={s.form} timeframe={s.lastMonth} onClick={s.applyRange}>Last Month ({createLocalMoment(s.lastMonth.startDate).format('MMM')})</TimeframeQuickPickerButton>
						<TimeframeQuickPickerButton form={s.form} timeframe={s.yearToDate} onClick={s.applyRange}>Year to Date ({s.getDisplayDateRangeWithYear(s.yearToDate)})</TimeframeQuickPickerButton>
						<TimeframeQuickPickerButton form={s.form} timeframe={s.thisYear} onClick={s.applyRange}>This Year ({createLocalMoment(s.thisYear.startDate).format('YYYY')})</TimeframeQuickPickerButton>
						<TimeframeQuickPickerButton form={s.form} timeframe={s.lastYear} onClick={s.applyRange}>Last Year ({createLocalMoment(s.lastYear.startDate).format('YYYY')})</TimeframeQuickPickerButton>
						<TimeframeQuickPickerButton form={s.form} timeframe={s.allTime} onClick={s.applyRange}>All Time ({s.getDisplayDateRangeWithYear(s.allTime)})</TimeframeQuickPickerButton>
					</div>
				</div>
			</div>
			<footer className="OverlayTimeframeQuickPickerFooter">
				<BaseButton className="subtle" onClick={dismiss}>Cancel</BaseButton>
				<BaseButton onClick={save}>Apply</BaseButton>
			</footer>
		</div>
	)} />
}

export default OverlayTimeframeQuickPicker;