import { always } from "../../../../../base/utils/ramdaEquivalents.utils";
import { makeObservableRouteDef } from "../../../../../base/utils/routes.utils";
import PageChatSingle from "./PageChatSingle.page";

export const makePageChatSingleRouteDef = (id: string) => makeObservableRouteDef({
  identifier: `chat-single-${id}`,
  urlFactory: always(id),
  composeTitle: always(`Chat ${id}`),
  component: PageChatSingle,
  icon: 'lock',
})