import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { ColorCodedState } from '../../../../base/@types';
import AppPage from '../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../base/components/AppPageHeader/AppPageHeader';
import BaseButton from '../../../../base/components/BaseButton/BaseButton';
import InfoBanner from '../../../../base/components/InfoBanner/InfoBanner';
import OverlayCloseButton from '../../../../base/components/OverlayCloseButton/OverlayCloseButton';
import UIBlock from '../../../../base/components/UIBlock/UIBlock';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import { makeForm } from '../../../../base/mediators/form.mediator';
import { reportError } from '../../../../base/utils/errors.utils';
import { useStore } from '../../../../base/utils/mobx.utils';
import { validateEmail } from '../../../../base/validators/email.validator';
import { validatePhone } from '../../../../base/validators/phone.validator';
import UserInfoEditor from '../../../../components/UserInfoEditor/UserInfoEditor';
import { saveCurrentUser } from '../../../../requests/user.requests';
import { getAgeFromDateOfBirth } from '../../../../utils/ageAndDateOfBirth.utils';

interface OverlayProfileEditorProps {
  mustComplete?: boolean,
}

const OverlayProfileEditor: React.FC<OverlayProfileEditorProps> = p => {

  const { AUTH, API, UI } = useControllers();

  const s = useStore(() => ({
    form: makeForm(AUTH.currentUser!.$getSnapshot()),
    get hasValidEmail() {
      return validateEmail(s.form.value.email) === true;
    },
    get hasValidPhone() {
      return validatePhone(s.form.value.mobileNumber) === true;
    },
    get hasAtLeastValidEmailOrPhone() {
      return (s.form.value.email && s.hasValidEmail) || (s.form.value.mobileNumber && s.hasValidPhone);
    },
    get dobIsValid() {
      if (!s.form.value.dateOfBirth || s.form.value.dateOfBirth.length < 8) return false;
      const m = moment(s.form.value.dateOfBirth);
      if (!m.isValid()) return false;
      if (!s.age) return false;
      if (s.age > 150) return false;
      if (s.age < 0) return false;
      return true;
    },
    get age() {
      if (!s.form.value.dateOfBirth || s.form.value.dateOfBirth.length < 8) return null;
      const m = moment(s.form.value.dateOfBirth);
      return getAgeFromDateOfBirth(m, true);
    },
    get isUnder12() {
      return s.age && s.age < 12;
    },
    get formIsValid() {
      return s.form.value.username && 
        s.dobIsValid && 
        s.hasAtLeastValidEmailOrPhone && 
        (s.form.value.countryDetectedId || s.form.value.countryProvidedId);
    },
    get nameIsComplete() {
      return s.form.value.firstName && s.form.value.lastName;
    }
  }));

  const dismiss = () => {
    UI.OVERLAY.dismiss();
  }

  const save = async () => {
    if (!s.dobIsValid) {
      UI.DIALOG.attention({
        heading: "Please double check the date of birth.",
      });
      return;
    }
    if (s.isUnder12) {
      UI.DIALOG.attention({
        heading: "turn2me users must be at least 12 years old.",
      });
      return;
    }
    const userSnapshot = s.form.value;
    try {
      await saveCurrentUser(API, userSnapshot);
    } catch(e) {
      reportError(e);
      UI.DIALOG.error({
        heading: "Failed to update your profile info...",
        error: e,
      })
    }
    dismiss();
  }
  
  return <Observer children={() => (
    <AppPage className="OverlayProfileEditor">
      <AppPageHeader
        title="Edit Profile"
        endSlot={!p.mustComplete && <OverlayCloseButton />}
      />
      <AppPageContent>
        <UIBlock padded spaceChildren>
          {AUTH.currentUser && <UserInfoEditor form={s.form} canEditColor showMoreOptionsSection/>}
          {
            !s.hasAtLeastValidEmailOrPhone && <InfoBanner colorCodedState={ColorCodedState.attention}>Your turn2me account needs at least a valid email address or phone.</InfoBanner>
          }
          {
            !s.form.value.dateOfBirth && <InfoBanner colorCodedState={ColorCodedState.attention}>Date of Birth is required information as turn2me has separate services for adults and young people.</InfoBanner>
          }
          {
            !s.nameIsComplete && <InfoBanner colorCodedState={ColorCodedState.attention}>Your real names are optional for basic turn2me services, however you will be required to provide them if you access any clinical services.</InfoBanner>
          }
          <BaseButton size="lg" fullWidth onClick={save} disabled={!s.formIsValid}>Save Profile</BaseButton>
          {
            !p.mustComplete && <BaseButton size="lg" className="subtle" fullWidth onClick={dismiss}>Cancel</BaseButton>
          }
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
}

export default OverlayProfileEditor;