import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import OverlayCloseButton from '../../base/components/OverlayCloseButton/OverlayCloseButton';
import PseudoLink from '../../base/components/PseudoLink/PseudoLink';
import { Primary } from '../../base/constants/color.enum';
import { useAppDisplayName } from '../../base/hooks/useAppDisplayName.hook';
import { useContextColorStyle } from '../../base/hooks/useContextColor.hook';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { getNowTimestampUtc } from '../../base/utils/time.utils';
import { ApiModelName, getApiModelDef } from '../../constants/ApiModels.enum';
import { FeedbackTargetModelNames, makeFeedback } from '../../models/makeFeedback.model';
import { submitFeedback } from '../../requests/submitFeedback.request';
import FeedbackForm from '../FeedbackForm/FeedbackForm';
import './OverlayFeedbackForm.scss';

interface OverlayFeedbackFormProps {
  forModelType?: FeedbackTargetModelNames,
  forModelId?: string,
  color?: string;
}

const OverlayFeedbackForm: React.FC<OverlayFeedbackFormProps> = props => {

  const { API, UI } = useControllers();

  const p = useProps(props);
  const s = useStore(() => ({
    feedback: makeFeedback({
      modelId: p.forModelId,
      modelType: p.forModelType,
      timeCreated: getNowTimestampUtc(),
    }),
    get isForSessionOrGroup() {
      return p.forModelType === ApiModelName.COUNSELLING_SESSION || p.forModelType === ApiModelName.SUPPORT_GROUP;
    },
    get isFormValid() {
      return !!s.feedback.rating;
    },
  }));

  const dismiss = () => {
    UI.OVERLAY.dismiss('OverlayFeedbackForm');
  }

  const modelDef = getApiModelDef(p.forModelType);
  const appDisplayName = useAppDisplayName();

  const defaultColor = modelDef?.color;
  const style = useContextColorStyle(Primary, p.color || defaultColor);

  const modelDisplayName = modelDef?.displayName === 'Support Group' ? 'Support Group Session' : modelDef?.displayName ?? 'session';

  const submit = () => new Promise<boolean>(async (resolve, reject) => {
    try {
      await submitFeedback(API, s.feedback);
      UI.DIALOG.success({
        heading: 'Feedback submitted',
        body: <>Thanks for your valuable feedback! {s.isForSessionOrGroup && 'We look forward to seeing you again.'}</>
      })
      resolve(true);
      dismiss();
    } catch(e) {
      reject(e);
      UI.DIALOG.error({
        heading: 'Unable to submit feedback',
        body: () => <>
          <p>We are sorry that the feedback has failed to submit; please try again.</p>
          <ErrorRenderer error={(e as any).response} />
        </>
      })
    }
  });

  return <Observer children={() => (
    <div className="OverlayFeedbackForm" style={style}>
      <OverlayCloseButton color="white"/>
      <BaseIcon className="OverlayFeedbackFormIcon" name="chat" variant="filled" size="38"/>
      {
        p.forModelType ? <>
          {
            p.forModelType === ApiModelName.COUNSELLING_APPLICATION ? <>
              <h3>How is your counselling experience so far?</h3>
            </> : <>
              <h3>How was your experience?</h3>
              {modelDisplayName && <p><strong>You have just finished a {modelDisplayName.toLowerCase()} with {appDisplayName}</strong>.</p>}
            </>
          }
        </> : <>
          <h3>How is your experience with us so far?</h3>
        </>
      }
      <p><strong>Click a star to rate your experience. (Optional)</strong></p>
      <FeedbackForm feedback={s.feedback} />
      <BaseButton onClick={submit} className="inverted" dataCy="submitFeedback" disabled={!s.isFormValid}>Submit Feedback</BaseButton>
      <footer>
        <p>Your feedback is important and helps us continuously improve our services. turn2me reserves the right to report on findings of these surveys for the purposes of research or service evaluation. For such purposes, your feedback will remain completely anonymous, unless you choose to include your personal information in the feedback. All feedback will be kept private and confidential.</p>
        {
          p.forModelType ? <>
            <p><PseudoLink onClick={dismiss} inheritColor>Click here to dismiss this form</PseudoLink> if you prefer not to give feedback.</p>
          </> : <>
            <p>Changed your mind? <PseudoLink onClick={dismiss} inheritColor>Click here to dismiss this form</PseudoLink>.</p>
          </>
        }
      </footer>
    </div>
  )} />
}

export default OverlayFeedbackForm;