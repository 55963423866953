import { Observer } from 'mobx-react-lite';
import React, { CSSProperties } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import joinClassName from '../../utils/className.utils';
import './CustomScrollbar.scss';

type CustomScrollbarProps = {
  className?: string,
  alwaysDisable?: boolean,
  style?: CSSProperties
}

const CustomScrollbar: React.FC<CustomScrollbarProps> = props => {
  return <Observer children={() => {
    const shouldEnable = false;
    if (shouldEnable) {
      document.documentElement.classList.add('custom-scrollbars');
    } else {
      document.documentElement.classList.remove('custom-scrollbars');
    }
    const className = joinClassName('CustomScrollbar', props.className);
    const commonAttr = {
      className,
      style: props.style,
      children: props.children
    }
    return (
      (shouldEnable && !props.alwaysDisable) ? <Scrollbar {...commonAttr} /> : <div {...commonAttr} />
    )
  }} />
}

export default CustomScrollbar;