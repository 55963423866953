import { Observer } from 'mobx-react-lite';
import React from 'react';
import ColorTag from '../../base/components/ColorTag/ColorTag';
import ColorTagUnder12 from '../../base/components/ColorTag/ColorTagUnder12';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useAppVersion } from '../../base/hooks/useAppDisplayName.hook';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { capitalizeFirstLetter } from '../../base/utils/string.utils';
import { isInCypressTestMode, isOfficialSite, IS_DEV } from '../../env';
import './InternalSiteMarker.scss';

interface InternalSiteMarkerProps {}

const InternalSiteMarker: React.FC<InternalSiteMarkerProps> = props => {

  const { AUTH } = useControllers();
  const { hostname } = window.location;
  const version = useAppVersion();

  useOnMount(() => {
    if (!isOfficialSite) {
      const titleTag = document.getElementsByTagName('title');
      if (!titleTag[0]) return;
      let hostnameDisplay = hostname;
      switch (hostname) {
        case 'preflight.turn2me.ie': hostnameDisplay = 'Preflight'; break;
        case 'beta.turn2me.ie': hostnameDisplay = 'Beta Preview'; break;
        default: hostnameDisplay = capitalizeFirstLetter(hostname); break;
      }
      titleTag[0].innerText = `${hostnameDisplay} • ` + titleTag[0].innerText + ` [${IS_DEV ? 'DEV:' : ''}${version}]`;
    }
  });
  return <Observer children={() => (
    <div className="InternalSiteMarker" data-hostname={hostname}>
      {
        (IS_DEV || !isOfficialSite) && (
          <div className="InternalSiteMarkerInner">
            {isInCypressTestMode ? <span>Cypress</span> : (
              IS_DEV && <span className="InternalSiteMarkerEnv">Dev</span>
            )}
            {isOfficialSite || <span className="InternalSiteMarkerHostname">{hostname}</span>}
            <span className="InternalSiteMarkerVersion">{version}</span>
            {AUTH.currentUser?.isYoungPerson && <>&nbsp;<ColorTag color="yellow">Youth</ColorTag></>}
            {AUTH.currentUser?.isUnder12 && <>&nbsp;<ColorTagUnder12 /></>}
          </div>
        )
      }
    </div>
  )} />
}

export default InternalSiteMarker;