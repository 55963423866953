import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseLabel from '../../../../base/components/BaseLabel/BaseLabel';
import BodyCopyRenderer from '../../../../base/components/BodyCopyRenderer/BodyCopyRenderer';
import ColorTagHidden from '../../../../base/components/ColorTag/ColorTagHidden';
import DateRenderer from '../../../../base/components/DateRenderer/DateRenderer';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import joinClassName from '../../../../base/utils/className.utils';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import CommentsManager from '../../../../components/CommentsManager/CommentsManager';
import EmotionIcon from '../../../../components/EmotionIcon/EmotionIcon';
import ThoughtControlsSet from '../../../../components/ThoughtControlsSet/ThoughtControlsSet';
import ThoughtTypeRenderer from '../../../../components/ThoughtTypeRenderer/ThoughtTypeRenderer';
import UserCountryRenderer from '../../../../components/UserCountryRenderer/UserCountryRenderer';
import UsernameRenderer from '../../../../components/UsernameRenderer/UsernameRenderer';
import { ApiModelName } from '../../../../constants/ApiModels.enum';
import { getEmotionName } from '../../../../constants/emotions.enum';
import { Thought } from '../../../../models/makeThought.model';
import './ThoughtEntryForAdmin.scss';

interface ThoughtEntryForAdminProps {
  thought: Thought,
}

const ThoughtEntryForAdmin: React.FC<ThoughtEntryForAdminProps> = props => {

  const { UI, THOUGHT_CATCHER } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    get thought() {
      return p.thought;
    },
    get flagsLength() {
      return s.thought.flags?.length || 0;
    },
    get isPrivate() {
      return s.thought.isPrivate;
    },
    get originalContent() {
      return p.thought.originalThought?.content;
    },
    get originalSituation() {
      return p.thought.originalThought?.situation;
    },
    get originalResponse() {
      return p.thought.originalThought?.response;
    },
    get shouldShowOriginalContent() {
      return s.originalContent && s.originalContent !== s.thought.content;
    },
    get shouldShowOriginalSituation() {
      return s.originalSituation && s.originalSituation !== s.thought.situation;
    },
    get shouldShowOriginalResponse() {
      return s.originalResponse && s.originalResponse !== s.thought.response;
    },
    get isLongThought() {
      return s.thought.content?.length && s.thought.content.length > 96;
    }
  }));

  const switchBetweenPublicAndPrivate = () => {
    UI.DIALOG.present({
      'heading': `This thought's visibility is set to ${s.thought.isPrivate ? 'private' : 'public'}`,
      body: `Do you want to change it to ${s.thought.isPrivate ? 'public' : 'private'}?`,
      defaultActions: ['negative'],
      actions: [{ label: `Switch to ${s.thought.isPrivate ? 'public' : 'private'}`, action: async () => {
        await THOUGHT_CATCHER.toggleThoughtPrivateState(p.thought);
      }}]
    })
  }

  return <Observer children={() => (
    <article 
      data-id={s.thought.id}
      className={
        joinClassName(
          'ThoughtEntryForAdmin',
          s.isPrivate && 'private',
          s.thought.timeHidden && 'hidden',
        )
      } 
      data-emotion={s.thought.emotionId}
    >
      <header className="ThoughtEntryForAdminHeader">
        <h3 className="ThoughtEntryForAdminTitle">
          {p.thought.emotionId && <EmotionIcon emotion={p.thought.emotionId} size={24}/> }
          <span>{ getEmotionName(p.thought.emotionId) }</span>
          <span className="ThoughtEntryForAdminMeta"><strong>Posted by <UsernameRenderer user={s.thought.user} userId={s.thought.userId} /></strong> on <DateRenderer value={s.thought.timeCreated} /></span>
          <strong>{p.thought.user && <UserCountryRenderer user={p.thought.user} />}</strong>
          {p.thought.timeHidden && <ColorTagHidden />}
        </h3>
        <div className="ThoughtEntryForAdminControls">
          {s.isPrivate && <ThoughtTypeRenderer private={s.thought.isPrivate} onClick={switchBetweenPublicAndPrivate} />}
        </div>
      </header>
      <div className="ThoughtEntryForAdminContent">
        <div className="ThoughtEntryForAdminContentBody" data-length={(s.isLongThought) ? 'long' : 'short'}>
          {/* <BaseLabel>Thought</BaseLabel> */}
          <BodyCopyRenderer source={s.thought.content || '[Empty]'} />
          {
            s.shouldShowOriginalContent && <div className="ThoughtEntryForAdminContentBodyOriginal">
              [Original Copy]: <BodyCopyRenderer source={s.originalContent || '[Empty]'} />
            </div>
          }
        </div>
        <div className="ThoughtEntryForAdminContentSituation">
          <BaseLabel>Situation</BaseLabel>
          { s.thought.situation ? <BodyCopyRenderer source={s.thought.situation} /> : <code>[Empty]</code>}
          {
            s.shouldShowOriginalSituation && <div className="ThoughtEntryForAdminContentBodyOriginal">
              [Original Copy]: <BodyCopyRenderer source={s.originalSituation || '[Empty]'} />
            </div>
          }
        </div>
        <div className="ThoughtEntryForAdminContentResponse">
          <BaseLabel>Response</BaseLabel>
          { s.thought.response ? <BodyCopyRenderer source={s.thought.response} /> : <code>[Empty]</code>}
          {
            s.shouldShowOriginalResponse && <div className="ThoughtEntryForAdminContentBodyOriginal">
              [Original Copy]: <BodyCopyRenderer source={s.originalResponse || '[Empty]'} />
            </div>
          }
        </div>
      </div>
      <footer className="ThoughtEntryForAdminFooter">
        <ThoughtControlsSet
          thought={s.thought}
          neverShowManageButton
        />
      </footer>
      <CommentsManager
        canComment
        thought={s.thought}
        showModeratorControls
        comments={s.thought.comments}
        replyToModelType={ApiModelName.THOUGHT}
        replyToModelId={p.thought.id}
      />
    </article>
  )} />
}

export default ThoughtEntryForAdmin;