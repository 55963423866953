import { Nillable } from "../base/@types";

export enum Emotion {
  'great' = '1',
  'good' = '2',
  'okay' = '3',
  'bad' = '4',
  'terrible' = '5',
}
export const EMOTIONS = [
  Emotion.great, 
  Emotion.good, 
  Emotion.okay, 
  Emotion.bad, 
  Emotion.terrible
] as const;

const emotionNameMap = {
  '1': 'Great',
  '2': 'Good',
  '3': 'Okay',
  '4': 'Bad',
  '5': 'Terrible',
} as const

export const getEmotionName = (emotion: Nillable<Emotion>) => {
  if (!emotion) return null;
  return emotionNameMap[emotion];
}