import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import './SupportGroupHasJoinedTag.scss';

type SupportGroupHasJoinedTagProps = {}

const SupportGroupHasJoinedTag: React.FC<SupportGroupHasJoinedTagProps> = props => {
  return <Observer children={() => (
    <div className="SupportGroupHasJoinedTag">
      <BaseIcon icon="check-circle" variant="filled" />
      <p>You have joined this group!</p>
    </div>
  )} />
}

export default SupportGroupHasJoinedTag;