import { Observer } from 'mobx-react-lite';
import React, { ReactText } from "react";
import { ColorName, ContextColor } from '../../constants/color.enum';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import './UIBlock.scss';

export interface UIBlockProps {
  className?: string;
  id?: string;
  title?: ReactText;
  headerContent?: string | React.ReactChild | React.FC | React.ComponentClass | false,
  headerEndSlot?: string | React.ReactChild | React.FC | React.ComponentClass | false,
  padded?: boolean | 'all';
  sticky?: 'top' | 'bottom' | 'left' | 'right' | 'top left' | 'top right' | 'bottom left' | 'bottom right';
  fullHeight?: boolean;
  scrollable?: boolean;
  spaceChildren?: boolean;
  style?: React.CSSProperties;
  verticalLayoutMode?: 'block' | 'flex' | 'grid';
  innerRef?: React.RefObject<HTMLDivElement>;
  primaryColorName?: ColorName | ContextColor,
  dataCy?: string;
}


const UIBlock: React.FC<UIBlockProps> = props => {

  const { children, ...propsToMakeObservable } = props;
  const p = useProps(propsToMakeObservable);

  const s = useStore(() => ({
    get shouldShowHeader() {
      return p.title || p.headerContent || p.headerEndSlot;
    },
    get headerContent() {
      return typeof p.headerContent === 'function' ? <p.headerContent /> : p.headerContent;
    },
    get headerEndSlot() {
      return typeof p.headerEndSlot === 'function' ? <p.headerEndSlot /> : p.headerEndSlot;
    },
  }));

  return <Observer children={() => {
    const { className, padded, sticky, title, fullHeight, scrollable, style, innerRef, spaceChildren } = p;
    return <div className={joinClassName(
      'UIBlock',
      className,
      padded && 'padded',
      padded === 'all' && 'padded-all',
      sticky && 'sticky',
      sticky,
      fullHeight && 'fullHeight',
      scrollable && 'scrollable',
      spaceChildren && 'spaceChildren',
    )} ref={innerRef} style={style}
      data-vertical-layout-mode={p.verticalLayoutMode}
      data-primary-color={p.primaryColorName}
      data-cy={p.dataCy}
    >
      {
        s.shouldShowHeader && <header className="UIBlockHeader">
          <div className="UIBlockHeaderContent">
            {title && <h3 className="UIBlockTitle">{title}</h3>}
            {s.headerContent}
          </div>
          {s.headerEndSlot && <div className="UIBlockHeaderEndSlot">{s.headerEndSlot}</div>}
        </header>
      }
      {props.children}
    </div>
  }} />
}

export default UIBlock;