import { action, flow, observable, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { ColorCodedState, GenericFunction, Nullable, TimezoneMode } from '../../base/@types';
import AppPage from '../../base/components/AppPage/AppPage';
import AppPageContent from '../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../base/components/AppPageHeader/AppPageHeader';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseButtonGroup from '../../base/components/BaseButtonGroup/BaseButtonGroup';
import BaseHeader from '../../base/components/BaseHeader/BaseHeader';
import BaseSeparator from '../../base/components/BaseSeparator/BaseSeparator';
import BaseSpacer from '../../base/components/BaseSpacer/BaseSpacer';
import BaseToggle from '../../base/components/BaseToggle/BaseToggle';
import ColorTag from '../../base/components/ColorTag/ColorTag';
import ColorTagArchived from '../../base/components/ColorTag/ColorTagArchived';
import ColorTagFrontLineWorkers from '../../base/components/ColorTag/ColorTagFrontLineWorkers';
import ColorTagSpecialistGroup from '../../base/components/ColorTag/ColorTagSpecialistGroup';
import CommandList from '../../base/components/CommandList/CommandList';
import { CommandListItem } from '../../base/components/CommandList/CommandListItem';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import DurationRenderer from '../../base/components/DurationRenderer/DurationRenderer';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import InfoBanner from '../../base/components/InfoBanner/InfoBanner';
import InfoDisplayItem from '../../base/components/InfoDisplayList/InfoDisplayItem';
import InfoDisplayList from '../../base/components/InfoDisplayList/InfoDisplayList';
import LoadingBlocker from '../../base/components/LoadingBlocker/LoadingBlocker';
import OverlayCloseButton from '../../base/components/OverlayCloseButton/OverlayCloseButton';
import PseudoLink from '../../base/components/PseudoLink/PseudoLink';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import UIBlock from '../../base/components/UIBlock/UIBlock';
import { DefaultSupportGroupIncludesForStaff, SupportGroupEndpoints } from '../../base/endpoints/supportGroup.endpoints';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { Form, makeForm } from '../../base/mediators/form.mediator';
import { keepTruthy } from '../../base/utils/array.utils';
import { navigateToChatPage } from '../../base/utils/chat.utils';
import joinClassName from '../../base/utils/className.utils';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { uniq } from '../../base/utils/ramdaEquivalents.utils';
import { getNowTimestampUtc, YYYYMMDDHHmmss } from '../../base/utils/time.utils';
import { setUrlParam, useSyncUrlParams } from '../../base/utils/urlParams.utils';
import { ModelName } from '../../constants/modelNames.enum';
import { useSupportGroupGetters } from '../../hooks/useSupportGroupGetters.hook';
import { Company } from '../../models/makeCompany.model';
import { makeSupportGroupSnapshotBase, PreviewSupportGroup, SupportGroup, SupportGroupSnapshot } from '../../models/makeSupportGroup.model';
import { SupportGroupTopic } from '../../models/makeSupportGroupTopic.model';
import { User } from '../../models/makeUser.model';
import { getSupportGroup } from '../../requests/getSupportGroup.request';
import { endSupportGroup } from '../../requests/startOrEndSupportGroup.request';
import SupportGroupAgeGroupTagSet from '../SupportGroupAgeGroupTagSet/SupportGroupAgeGroupTagSet';
import SupportGroupInfoTable from '../SupportGroupInfoTable/SupportGroupInfoTable';
import SupportGroupNotesEditor from '../SupportGroupNotesEditor/SupportGroupNotesEditor';
import SupportGroupReservationList from '../SupportGroupReservationList/SupportGroupReservationList';
import SupportGroupSetupEditor from '../SupportGroupSetupEditor/SupportGroupSetupEditor';
import SupportGroupSummaryCard from '../SupportGroupSummaryCard/SupportGroupSummaryCard';
import './OverlaySupportGroupManager.scss';

interface OverlaySupportGroupManagerProps {
  supportGroup?: SupportGroup
  supportGroupId?: string
  inputTimezoneMode?: TimezoneMode,
}

const OverlaySupportGroupManager: React.FC<OverlaySupportGroupManagerProps> = props => {

  const { UI, API, MESSENGER, AUTH, LOCALDB, NAVIGATOR } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
		get isFacilitator() {
			return AUTH.isFacilitator;
		},
    dataLoaded: false,
    get dataFromLocalDb(): Nullable<SupportGroup> {
      return s.id ? LOCALDB.get<SupportGroup>(ModelName.supportGroups, s.id) ?? null : null;
    },
    get supportGroup() {
      return s.dataFromLocalDb ?? p.supportGroup;
    },
    id: p.supportGroupId ?? p.supportGroup?.id,
    get isNew() {
      return !s.id;
    },
    get hasEnded() {
      return Boolean(s.supportGroup?.timeEnded);
    },
    get threads() {
      return s.supportGroup?.threads || [];
    },
    get canViewChatHistory() {
      return Boolean(s.threads.length > 0 && s.threads[0].id);
    },
    get afterTitle() {
      return <>
        <div>
          { sg.hasEnded && <ColorTag>Ended on <DateRenderer value={s.supportGroup?.timeEnded} /></ColorTag>}
          { s.supportGroup && <SupportGroupAgeGroupTagSet group={s.supportGroup} /> }
          { sg.isForFrontLineWorkers && <> <ColorTagFrontLineWorkers /></> }
          { s.supportGroup?.isSpecialistGroup && <ColorTagSpecialistGroup /> }
          { s.isArchived && <> <ColorTagArchived /></> }
        </div>
        { s.supportGroup?.requiresKeyWorkerInformation && <p className="SupportGroupManagerKeyWorkerInfo"><strong>Key worker information required for all participants.</strong></p>}
      </>
    },
    get currentUserIsTheAssignedCounsellor() {
      return AUTH.currentUser?.id && s.supportGroup?.facilitatorId === AUTH.currentUser.id;
    },
    get disableForm() {
      return !s.currentUserIsTheAssignedCounsellor && !AUTH.isStaff;
    },
    get canArchive() {
      return !s.supportGroup?.timeStarted && AUTH.isStaff && !s.isArchived;
    },
    get canUnarchive() {
      return AUTH.isStaff && s.isArchived;
    },
    get isArchived() {
      return s.supportGroup?.timeArchived;
    },
    get displayTopic() {
      return s.formValue?.topicId ? LOCALDB.get<SupportGroupTopic>(ModelName.supportGroupTopics, s.formValue?.topicId) : s.supportGroup?.topic;
    },
    get currentUserParticipant() {
      return AUTH.currentUser ? s.supportGroup?.staffParticipants.find(part => part.userId === AUTH.currentUser?.id) : null;
    },
    form: null as Nullable<Form<SupportGroupSnapshot>>,
    formValue: null as Nullable<SupportGroupSnapshot>,
    handleChange: action((g: SupportGroupSnapshot) => {
      s.formValue = g;
    }),
    previewGroup: null as Nullable<PreviewSupportGroup>,
    confirmClose: () => new Promise<boolean>(async (resolve, reject) => {
      if (!s.form?.hasChanges) {
        resolve(true);
        return;
      }
      const confirm = await UI.DIALOG.present({
        heading: 'Are you sure you want to discard your changes?',
        colorCodedState: ColorCodedState.attention,
      })
      if (confirm) resolve(true);
      else resolve(false);
    }),
    get thread() {
      return s.supportGroup?.threads[0];
    },
    isLoadingData: false,
    loadData: () => flow(function * () {
      if (!s.id) return;
      try {
        s.isLoadingData = true;
        yield getSupportGroup(API, s.id, 'staff', {
          include: DefaultSupportGroupIncludesForStaff
        });
        s.isLoadingData = false;
        s.dataLoaded = true;
      } catch(e) {
        reportError(e);
        s.isLoadingData = false;
        UI.DIALOG.error({
          heading: 'Failed to get Support Group data.',
          body: <ErrorRenderer error={e} />
        })
      }
    })(),
    handleSave: action((g: SupportGroup) => s.id = g.id),
  }));

  useOnMount(() => {
    if (!s.supportGroup) {
      s.loadData();
    }
    const disposers = [] as GenericFunction[];
    disposers.push(when(
      () => Boolean(s.supportGroup || s.isNew),
      () => {
        s.form = makeForm(s.supportGroup!.$snapshot, {
          editableFields: [
            'allowedCompanyId',
            'topicId',
            'facilitatorId',
            'title',
            'subtitle',
            'timeScheduled',
            'description',
            'ageGroups',
            'scheduledDurationInMinutes',
            'maxParticipants',
            'requiresKeyWorkerInformation',
            'tags'
          ]
        });
        s.formValue = s.form.value;
        s.previewGroup = observable({
          ...makeSupportGroupSnapshotBase(),
          isPreview: true,
          get topic() {
            return s.displayTopic
          },
          get title() { return s.formValue?.title || 'Support Group' },
          get subtitle() { return s.formValue?.subtitle || '' },
          get timeScheduled() { return s.formValue?.timeScheduled ?? null },
          get timeStarted() { return s.formValue?.timeStarted ?? null },
          get timeEnded() { return s.formValue?.timeEnded ?? null },
          get color() { return s.displayTopic?.color },
          get description() { return s.formValue?.description || '' },
          get scheduledDurationInMinutes() { return s.formValue?.scheduledDurationInMinutes ?? 60 },
          get ageGroups() { return s.formValue?.ageGroups || [] },
          get tags() { return s.formValue?.tags || [] },
          get maxParticipants() { return s.formValue?.maxParticipants ?? 20 },
          get facilitator() { return s.formValue?.facilitatorId ? LOCALDB.get<User>(ModelName.users, s.formValue.facilitatorId) ?? null : null },
          get allowedCompany() { return s.formValue?.allowedCompanyId ? LOCALDB.get<Company>(ModelName.companies, s.formValue.allowedCompanyId) ?? null : null },
          get reservationCount() { return Math.max(s.supportGroup?.reservations.length ?? 0, s.supportGroup?.reservationCount ?? 0) }
        })
      }
    ));
    return () => disposers.forEach(d => d());
  })

  const sg = useSupportGroupGetters(s.supportGroup, p.inputTimezoneMode);

  useSyncUrlParams('manageSupportGroupId', s.id);

  const markAsEnded = () => new Promise<boolean>(flow(function * (resolve, reject) {
    if (!s.id) return;
    const form = observable({
      isPayable: true,
    })
    const confirm = yield UI.DIALOG.attention({
      heading: 'Marking this group as ended',
      body: <div>
        <p>This is designed for rare instances where a support group is not automatically ended when the chat thread was ended. Are you sure you want to end this group?</p>
        <BaseSpacer size=".5em" />
        <ShadedBlock>
          <BaseToggle form={form} field="isPayable">Mark this group session as payable for all staff in the group</BaseToggle>
        </ShadedBlock>
      </div>,
      defaultActions: ['negative', 'positive'],
    })
    if (!confirm) return;
    const staffIds = uniq(keepTruthy((s.thread?.participants ?? []).map(part => part.user).filter(user => user?.isStaff) ?? [])).map(u => u!.id);
    yield endSupportGroup(API, s.id, form.isPayable ? staffIds : []);
    if (s.supportGroup) s.supportGroup.timeEnded = moment().utc().format(YYYYMMDDHHmmss);
  }))

  const viewChatHistory = async () => {
    const { id = undefined } = (s.supportGroup?.threads ? s.supportGroup.threads[0] || {} : {}) || {};
    if (!id) return;
    try {
      const chat = await MESSENGER.loadChatById(id);
      if (!chat) {
        UI.DIALOG.attention({
          heading: 'Hmm, we weren\'t able to load the chat thread.',
          body: 'If you believe this should not have happened, please contact the development team for assistence.'
        })
        return;
      }
      if (UI.onlyPhones) navigateToChatPage(NAVIGATOR, chat.id);
      else {
        chat.keepInDock();
        const stackEl = document.querySelector('.ChatWindowStack') as Nullable<HTMLElement>;
        UI.focusOnElementInPortal(stackEl);
      }
    } catch (e) {
      reportError(e);
      UI.DIALOG.error({
        heading: 'Failed to retrieve the chat thread.',
        error: e,
      })
    }
  }

  const archiveGroup = () => new Promise<boolean>(
    flow(function * (resolve, reject) {
      if (!s.id) {
        reject('No group loaded');
        return;
      }
      const confirm = yield UI.DIALOG.attention({
        heading: 'Archive this Support Group',
        body: () => <>
          <p>Are you sure you want to archive this support group?</p>
          {s.supportGroup?.reservations && s.supportGroup?.reservations.length > 0 && <p>All registered users will be notified of the group cancellation.</p>}
        </>,
        defaultActions: ['negative', 'positive'],
      })
      if (!confirm) {
        resolve(false);
        return;
      }
      const now = getNowTimestampUtc()
      const url = SupportGroupEndpoints.staff.update(s.id);
      const payload = {
        timeArchived: now,
      }
      try {
        const savedSupportGroup: SupportGroup = yield API.patch(url, ModelName.supportGroups, payload);
        UI.TOAST.success(`The Support Group ${s.supportGroup?.title || ''} has been archived.`);
        UI.OVERLAY.dismiss(`OverlaySupportGroupManager#${s.id}`);
        s.form?.reset(savedSupportGroup.$snapshot);
        resolve(true);
      } catch(e) {
        reject(e);
        reportError(e);
        UI.DIALOG.error({
          heading: 'Failed to archive Support Group',
          error: e,
        })
      }
    })
  )

  const unarchiveGroup = () => new Promise<boolean>(
    flow(function * (resolve, reject) {
      if (!s.id) {
        reject('No group loaded');
        return;
      }
      const confirm = yield UI.DIALOG.attention({
        heading: 'Archive this Support Group',
        body: () => <>
          <p>Are you sure you want to unarchive this support group?</p>
        </>,
        defaultActions: ['negative', 'positive'],
      })
      if (!confirm) {
        resolve(false);
        return;
      }
      const url = SupportGroupEndpoints.staff.update(s.id);
      const payload = {
        timeArchived: null,
      }
      try {
        const savedSupportGroup: SupportGroup = yield API.patch(url, ModelName.supportGroups, payload);
        UI.TOAST.positive(`The Support Group ${s.supportGroup?.title || ''} has been unarchived.`);
        s.form?.reset(savedSupportGroup.$snapshot);
        resolve(true);
      } catch(e) {
        reject(e);
        reportError(e);
        UI.DIALOG.error({
          heading: 'Failed to unarchive Support Group',
          error: e,
        })
      }
    })
  )

  const viewInFrontendViewer = () => {
    setUrlParam('supportGroupId', s.id, NAVIGATOR);
  }

  return <Observer children={() => (
    <AppPage className={
      joinClassName(
        'OverlaySupportGroupManager',
        s.supportGroup?.isForAllYoungPeople && 'young-people',
        sg.isForFrontLineWorkers && 'for-front-line-workers',
        s.isArchived && 'archived',
      )
    }
      data-support-group-id={s.id}
      color={s.displayTopic?.color}
    >
      <AppPageHeader
        beforeTitle='Support Group'
        title={s.isNew ? 'Create New Support Group' : sg.title}
        afterTitle={s.afterTitle}
        endSlot={<>
          {s.isNew && <>
            <BaseSpacer size="xs" inline />
          </>}
          <OverlayCloseButton onBeforeClose={s.confirmClose}/>
        </>}
      />
      <AppPageContent>
        <UIBlock padded="all">
          {s.supportGroup && s.form && <div className="OverlaySupportGroupManagerInner">
            <div className="OverlaySupportGroupManagerInnerContent">
              {(sg.hasStarted || s.canViewChatHistory) && <>
                <ShadedBlock color={sg.hasEnded ? 'skyblue' : 'green'} spaceChildren>
                  {sg.hasStarted && !sg.hasEnded && <h3>This support group is currently live.</h3>}
                  {sg.hasEnded && <h3>This support group has ended.</h3>}
                  {sg.hasStarted && <InfoDisplayList>
                    <InfoDisplayItem size="normal" label="Started at"><DateRenderer value={sg.startTime} timezoneMode={p.inputTimezoneMode} /></InfoDisplayItem>
                    {sg.endTime && <InfoDisplayItem size="normal" label="Ended at"><DateRenderer value={sg.endTime} timezoneMode={p.inputTimezoneMode} /></InfoDisplayItem>}
                    <InfoDisplayItem size="normal" label="Duration"><DurationRenderer startTime={sg.startTime} endTime={sg.endTime} timezoneMode={p.inputTimezoneMode} /></InfoDisplayItem>
                  </InfoDisplayList>}
                  {sg.isInThePast && sg.startTime && !sg.endTime && (
                    <p>
                      <PseudoLink onClick={markAsEnded}>Mark this support group as ended</PseudoLink>
                      <BaseSpacer size=".5em" />
                    </p>
                  )}
                  {(s.isFacilitator && s.canViewChatHistory) && <BaseButtonGroup>
                    <BaseButton icon="chat" onClick={viewChatHistory} dataCy="open-chat-window" label={s.hasEnded ? 'View Chat History' : 'Open Chat Window'} />
                  </BaseButtonGroup>}
                </ShadedBlock>
                <BaseSpacer size="sm" />
              </>}
              {
                (sg.hasStarted || !s.isFacilitator) ? <UIBlock spaceChildren>
                  <ShadedBlock spaceChildren>
                    <h4>Overview</h4>
                    <SupportGroupInfoTable supportGroup={s.supportGroup!} />
                  </ShadedBlock>
                </UIBlock>
                : <>
                  <SupportGroupSetupEditor supportGroup={s.supportGroup!} form={s.form} onChange={s.handleChange} onSave={s.handleSave}/>
                </>
              }
              {(s.isFacilitator && !s.isNew) && <>
                <BaseSpacer size="sm" />
                <BaseSeparator />
                <BaseSpacer size="sm" />
                <ShadedBlock spaceChildren>
                  <h4>Notes</h4>
                  <SupportGroupNotesEditor supportGroup={s.supportGroup!} />
                </ShadedBlock>
              </>}
            </div>
            <aside className="OverlaySupportGroupManagerInnerAside">
              {
                s.previewGroup && <>
                  <BaseHeader level={3}
                    heading="Preview"
                  />
                  <div className="OverlaySupportGroupManagerPreviewWrapper">
                    <SupportGroupSummaryCard supportGroup={s.previewGroup} />
                  </div>
                  <BaseSpacer size="1em"/>
                </>
              }
              {s.isNew ? <>
                <InfoBanner icon="info">
                  <p>More options will be available after you confirm to create this group.</p>
                </InfoBanner>
              </> : <>

                <BaseHeader level={3} heading="Billing" />

                { s.supportGroup.invoiceItems.length > 0 && <div>
                    <p>This support group is marked as billable.</p>
                </div>}

                {s.supportGroup.timeEnded && s.supportGroup.invoiceItems.length === 0 && <div>
                    <p>This support group is marked as <strong>not</strong> billable.</p>
                </div>}

                {s.isFacilitator && <>
                  <BaseSpacer size="1em"/>
                  <BaseHeader level={3} heading="More Actions" />

                  <CommandList withBackground>
                    <CommandListItem icon="refresh" color="green" onClick={s.loadData} key="refresh">Refresh</CommandListItem>
                    <CommandListItem icon="open-overlay" onClick={viewInFrontendViewer} >View in front end viewer</CommandListItem>
                    {s.canArchive && (
                      <CommandListItem icon="arrow" color="orange"
                        onClick={archiveGroup} key="archive"
                      >Archive this group</CommandListItem>
                    )}
                    {s.canUnarchive && (
                      <CommandListItem icon="arrow" color="orange"
                        onClick={unarchiveGroup} key="unarchive"
                      >Unarchive this group</CommandListItem>
                    )}
                  </CommandList>
                </>}

                {s.isFacilitator && <>
                  <BaseSpacer size="1em" />
                  <BaseHeader
                    level={3}
                    heading={`Reservations (${s.supportGroup?.reservations?.length ?? 0} / ${s.supportGroup?.maxParticipants ?? '?'})`}
                  />
                  <SupportGroupReservationList supportGroup={s.supportGroup!} disabled={s.disableForm} />
                </>}
              </>}
            </aside>
          </div>}
        </UIBlock>
        { s.isLoadingData && <LoadingBlocker />}
      </AppPageContent>
    </AppPage>
  )} />
}

export default OverlaySupportGroupManager;