import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState } from '../../base/@types';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import InfoBanner from '../../base/components/InfoBanner/InfoBanner';
import { useControllers } from '../../base/hooks/useRootController.hook';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { SupportGroup } from '../../models/makeSupportGroup.model';
import { SupportGroupReservation } from '../../models/makeSupportGroupReservation.model';
import { User } from '../../models/makeUser.model';
import { getAgeFromDateOfBirth } from '../../utils/ageAndDateOfBirth.utils';
import { checkIfUserIsWithinAgeGroupsOfSupportGroup } from '../../utils/supportGroup.helpers';
import UserComputedRoleList from '../UserComputedRoleList/UserComputedRoleList';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import OverlaySupportGroupReservationControl from './OverlaySupportGroupReservationControl';
import './SupportGroupReservationListItem.scss';

interface SupportGroupReservationListItemProps {
  supportGroup?: SupportGroup;
  reservation: SupportGroupReservation,
  onRevoke?: (r: SupportGroupReservation) => unknown,
  disabled?: any;
}

const SupportGroupReservationListItem: React.FC<SupportGroupReservationListItemProps> = props => {

  const { UI } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    get reservation() {
      return p.reservation;
    },
    _user: undefined as User | undefined,
    get user() {
      return s.reservation.user || s._user;
    },
    get userId() {
      return s.reservation.userId;
    },
    get userIsWithinAgeGroup() {
      return checkIfUserIsWithinAgeGroupsOfSupportGroup(s.user, p.supportGroup);
    }
  }));

  const showReservationControlOverlay = () => {
    UI.OVERLAY.present({
      name: 'OverlaySupportGroupReservationControl',
      component: () => <OverlaySupportGroupReservationControl 
        reservation={p.reservation}
        supportGroup={p.supportGroup}
        onRevoke={p.onRevoke}
        disabled={p.disabled}
      />,
      // appearance: 'card',
      width: '50em',
    })
  }

  return <Observer children={() => (
    <div  
      className={joinClassName('SupportGroupReservationListItem', s.reservation.isRevoked && 'revoked')} 
      onClick={showReservationControlOverlay}
    >
      <header className="SupportGroupReservationListItemHeader">
        <h3><UsernameRenderer user={s.user} userId={s.reservation.userId} /> • Age: {getAgeFromDateOfBirth(s.user?.dateOfBirth) || 'N/A'}</h3>
        <UserComputedRoleList user={s.user} />
        <p><small>Joined group on <DateRenderer value={s.reservation.timeCreated} emptyValue="(unknown time)" /></small></p>
        { p.supportGroup?.requiresKeyWorkerInformation && (
          s.reservation.keyWorker ? <p className="SupportGroupReservationListItemKeyworkerInfo">Key Worker: {s.reservation.keyWorker.name}</p> : <p className="SupportGroupReservationListItemKeyworkerInfo --error">No Key Worker Info Provided</p>
        )}
        { s.reservation.isRevoked && <p><em><small>[Reservation revoked on <DateRenderer value={s.reservation.timeRevoked} />]</small></em></p>}
        { !s.userIsWithinAgeGroup && <InfoBanner icon="warning" compact colorCodedState={ColorCodedState.alert}>This user is not within the defined age group of this support group.</InfoBanner>}
      </header>
      { !p.disabled && (
        <div className="SupportGroupReservationListItemControls">
          <BaseIcon name="more" />
        </div> 
      ) }
    </div>
  )} />
  
}

export default SupportGroupReservationListItem;