import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { useControllers } from '../../hooks/useRootController.hook';
import { useStore } from '../../utils/mobx.utils';
import { HHmm } from '../../utils/time.utils';
import './BaseCalendarTimelineAxis.scss';

type BaseCalendarTimelineAxisProps = {}

const BaseCalendarTimelineAxis: React.FC<BaseCalendarTimelineAxisProps> = props => {

	const { UI } = useControllers();

	const s = useStore(() => ({
		get hoursPerAxisStep() {
			return UI.displayMode === 'phone' ? 2 : 1;
		},
		get numberOfSteps() {
			return 24 / s.hoursPerAxisStep;
		},
		get axisSteps() {
			// 24 hours, 2 hours per step
			return Array(s.numberOfSteps).fill(null).map((n, i) => moment().startOf('day').add(s.hoursPerAxisStep * i, 'hours').format(HHmm));
		}
	}))

	const AxisStep = (props: { step: string }) => {
		return <div className="BaseCalendarTimelineAxisStep">{props.step}</div>;
	}
	return <Observer children={() => (
		<div className="BaseCalendarTimelineAxis">
			{ s.axisSteps.map((step, i) => <AxisStep step={step} key={step + i}/>) }
		</div>
	)} />
}

export default BaseCalendarTimelineAxis;