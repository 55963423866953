import { observable } from "mobx";
import ErrorRenderer from "../base/components/ErrorRenderer/ErrorRenderer";
import { CounsellingSessionEndpoints } from "../base/endpoints/counsellingSession.endpoints";
import { makeActionConfig } from "../base/utils/actionConfig.utils";
import { keepTruthy } from "../base/utils/array.utils";
import { reportError } from "../base/utils/errors.utils";
import { getNowTimestampUtc } from "../base/utils/time.utils";
import OverlayCounsellingSessionManager from "../components/OverlayCounsellingSessionManager/OverlayCounsellingSessionManager";
import { CommunicationType } from "../constants/communicationTypes.descriptors";
import { CounsellingType } from "../constants/counsellingTypes.descriptors";
import { ModelName } from "../constants/modelNames.enum";
import { ReasonCancelSession } from "../constants/reasonCancelSession.enum";
import { APIController } from "../controllers/api.controller";
import { AuthController } from "../controllers/auth.controller";
import { UIController } from "../controllers/ui.controller";
import { DialogController } from "../controllers/ui/dialog.controller";
import { getWordPressLink } from "../env";
import { CounsellingApplication } from "../models/makeCounsellingApplication.model";
import { CounsellingSession, makeCounsellingSession } from "../models/makeCounsellingSession.model";
import { User } from "../models/makeUser.model";

export const createOverlaySessionEditor = (UI: UIController, overlayOptions: {
  session?: CounsellingSession,
  sessionId?: string,
  application?: CounsellingApplication | null,
  applicationId?: string,
  client?: User,
  onSessionCreateEditDelete?: () => void,
  onAfterClose?: () => unknown,
}) => {
  const { session, sessionId, application, applicationId, client, onSessionCreateEditDelete, onAfterClose } = overlayOptions;
  UI.OVERLAY.present({
    id: `OverlayCounsellingSessionManager#${sessionId ?? session?.id}`,
    name: `OverlayCounsellingSessionManager`,
    component: <OverlayCounsellingSessionManager
      session={session}
      sessionId={sessionId}
      application={application}
      applicationId={applicationId}
      client={client}
      onSessionCreateEditDelete={onSessionCreateEditDelete}
    />,
    width: '66.67em',
    onAfterClose,
    duplicateStrategy: 'abort',
    noBackdrop: true,
  })
}

export const createNewSessionModelFromApplication = (applicationInfo: {
  application?: Partial<CounsellingApplication> | null,
  applicantId?: string,
  forApplicationId?: string,
}, toMerge?: object) => {
  const userIds = [] as string[];
  const applicantId = applicationInfo.applicantId || applicationInfo.application?.applicantId || '';
  const inviteeId = applicationInfo.application?.invitation?.userId || '';
  switch (applicationInfo.application?.type) {
    case CounsellingType.PaidOneToOne:
    case CounsellingType.OneToOne: {
      userIds.push(applicantId);
      break;
    }
    case CounsellingType.YoungPeople1214:
    case CounsellingType.YoungPeople1517: {
      const inviteeRole = applicationInfo.application?.invitation?.actingAs;
      if (inviteeRole === 'dependent-child') {
        userIds.push(inviteeId);
      } else {
        userIds.push(applicantId);
      }
      break;
    }
    case CounsellingType.Couples: {
      userIds.push(applicantId, inviteeId);
      break;
    }
  }
  // props.application?.type === 'one-to-one' && props.clientId ? [props.clientId]
  return observable(makeCounsellingSession({
    type: applicationInfo.application?.communicationTypePreference || CommunicationType.Text,
    applicationType: applicationInfo.application?.type,
    applicationId: applicationInfo.forApplicationId ?? applicationInfo.application?.id,
    counsellorId: applicationInfo.application?.counsellorId ?? null,
    applicantId: applicationInfo.applicantId ?? applicationInfo?.application?.applicantId,
    clientIds: keepTruthy(userIds),
    amountPayable: applicationInfo.application?.clientAgreedDonationAmountPerSession ?? undefined,
    ...toMerge,
  }));
}

export const openOverlayNewSessionCreator = (UI: UIController, application?: CounsellingApplication | null) => {
  return new Promise<CounsellingSession>(async (resolve, reject) => {
    const newSession = createNewSessionModelFromApplication({ application });
    createOverlaySessionEditor(UI, {
      session: newSession,
    });
  });
}

export const cancelSession = (session: CounsellingSession, AUTH: AuthController, DIALOG: DialogController, API: APIController, onBackButtonClick?: () => void, onCancelSession?: () => void) => {
  const sessionId = session.id;
  const sessionApplicationId = session.application?.id ?? session.applicationId;
  const sessionApplicantUsername = session.applicant?.username ?? session.application?.applicant?.username ?? AUTH.currentUser?.username ?? 'turn2me user';
  DIALOG.attention({
    heading: 'Are you sure you would like to cancel your appointment?',
    body: <>
      <p>By proceeding with the cancellation the session, your therapist will be notified.</p>
      {session.isPaidSession && session.hasClientPaid
        && <>
          <p>If applicable, you will be entitled to a refund as per our <a href={getWordPressLink('/terms-and-conditions/')} title="turn2me Terms and Conditions" target="_blank" rel="noreferrer">terms and conditions</a> or you will be able to use the amount you donated towards another session.</p>
          <p>To request a refund, please click here to email us at <a href={`mailto:refunds@turn2me.ie?subject=${sessionApplicantUsername} | Requesting refund for session ${sessionId}, application ${sessionApplicationId}`} title="turn2me Request Refund Email" target="_blank" rel="noreferrer">refunds@turn2me.ie</a>.</p>
          {/* or contact us using the <PseudoLink onClick={() => requestRefundViaContactForm(sessionId, sessionApplicationId)}>contact form</PseudoLink>.</p> */}
        </>
      }
    </>,
    actions: [
      makeActionConfig('Back', () => { onBackButtonClick?.() }, { buttonClass: 'subtle' }),
      {
        label: 'Cancel and Notify',
        action: async () => {
          try {
            const url = CounsellingSessionEndpoints.own.update(session.id);
            await API.patch<CounsellingSession>(url, ModelName.counsellingSessions, { timeCancelled: getNowTimestampUtc(), cancelReason: ReasonCancelSession.clientCancel });
            DIALOG.success({
              name: 'cancelSessionSuccess',
              heading: 'Session was cancelled successfully!',
              actions: [
                {
                  name: 'positive',
                  label: 'OK',
                  action: () => {
                    onCancelSession?.();
                  },
                }
              ]
            })
          } catch (e) {
            reportError(e);
            DIALOG.error({
              name: 'cancelSessionFailed',
              heading: 'Failed to cancel your next session.',
              body: <ErrorRenderer error={(e as any).response} />,
              defaultActions: ['positive'],
            })
          }
        },
      }
    ]
  })
}