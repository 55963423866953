import React from "react";
import { makeIconDef } from "./makeIconDef";

export const StatsIconDef = {
  'stats': makeIconDef('stats', {
    regular: () => <>
      <path fillRule="evenodd" clipRule="evenodd" d="M20 15.2916V19C20 20.6569 18.6569 22 17 22H5C3.34315 22 2 20.6569 2 19V11C2 9.34315 3.34315 8 5 8H6.25204C6.16819 8.32577 6.10435 8.65956 6.06189 9H5C3.89543 9 3 9.89543 3 11V19C3 19.2372 3.04129 19.4647 3.11707 19.6758L6.43934 16.3536C7.02513 15.7678 7.97487 15.7678 8.56066 16.3536L10.6464 18.4393C10.8417 18.6346 11.1583 18.6346 11.3536 18.4393L12.0358 17.7571C12.4273 17.8559 12.8304 17.9259 13.2425 17.9646L12.0607 19.1464C11.4749 19.7322 10.5251 19.7322 9.93934 19.1464L7.85355 17.0607C7.65829 16.8654 7.34171 16.8654 7.14645 17.0607L3.69311 20.514C4.04363 20.8168 4.50043 21 5 21H17C18.1046 21 19 20.1046 19 19V16.2454C19.3601 15.9567 19.6948 15.6375 20 15.2916Z" fill="currentColor" />
      <path d="M13.5 10V10.5H14H20C20.299 10.5 20.4621 10.7287 20.4338 10.9254C20.2961 11.8825 19.946 12.8008 19.4046 13.6112C18.6903 14.6801 17.6752 15.5132 16.4874 16.0052C15.2997 16.4972 13.9928 16.6259 12.7319 16.3751C11.471 16.1243 10.3128 15.5052 9.40381 14.5962C8.49476 13.6872 7.8757 12.529 7.6249 11.2681C7.37409 10.0072 7.50281 8.70028 7.99478 7.51256C8.48675 6.32484 9.31987 5.30968 10.3888 4.59545C11.1992 4.05396 12.1175 3.70387 13.0746 3.56621C13.2713 3.53791 13.5 3.70102 13.5 4V10Z" stroke="currentColor" />
      <path d="M21.0052 6.51256C21.2131 7.01445 21.3568 7.53926 21.4338 8.07458C21.4621 8.27133 21.299 8.5 21 8.5H15.5L15.5 3C15.5 2.70101 15.7287 2.53791 15.9254 2.56621C16.4607 2.64321 16.9856 2.78689 17.4874 2.99478C18.2761 3.32144 18.9926 3.80023 19.5962 4.40381C20.1998 5.00739 20.6786 5.72394 21.0052 6.51256Z" stroke="currentColor" />
    </>,
    filled: () => <path fillRule="evenodd" clipRule="evenodd" d="M21 9C21.5523 9 22.0073 8.55005 21.9287 8.00339C21.8458 7.4269 21.691 6.86171 21.4672 6.32122C21.1154 5.47194 20.5998 4.70027 19.9497 4.05026C19.2997 3.40025 18.5281 2.88463 17.6788 2.53285C17.1383 2.30897 16.5731 2.15423 15.9966 2.07131C15.45 1.99268 15 2.44772 15 3V9H21ZM20 10C20.5523 10 21.0073 10.45 20.9287 10.9966C20.7804 12.0273 20.4034 13.0163 19.8203 13.889C19.0511 15.0401 17.9579 15.9373 16.6788 16.4672C15.3997 16.997 13.9922 17.1356 12.6344 16.8655C11.2765 16.5954 10.0292 15.9287 9.05025 14.9498C8.07128 13.9708 7.4046 12.7235 7.1345 11.3656C6.86441 10.0078 7.00303 8.6003 7.53284 7.32122C8.06266 6.04214 8.95986 4.94889 10.111 4.17972C10.9837 3.59657 11.9727 3.21956 13.0034 3.07131C13.55 2.99268 14 3.44772 14 4V10H20ZM20 19V15.2917C19.6948 15.6375 19.3601 15.9567 19 16.2454V19C19 20.1046 18.1046 21 17 21H5C4.50043 21 4.04363 20.8168 3.69311 20.514L7.14645 17.0607C7.34171 16.8654 7.65829 16.8654 7.85355 17.0607L9.93934 19.1465C10.5251 19.7322 11.4749 19.7322 12.0607 19.1465L13.2425 17.9646C12.8304 17.9259 12.4273 17.8559 12.0358 17.7571L11.3536 18.4393C11.1583 18.6346 10.8417 18.6346 10.6464 18.4393L8.56066 16.3536C7.97487 15.7678 7.02513 15.7678 6.43934 16.3536L3.11707 19.6758C3.04129 19.4647 3 19.2372 3 19V11C3 9.89543 3.89543 9 5 9H6.06189C6.10435 8.65956 6.16819 8.32577 6.25204 8H5C3.34315 8 2 9.34315 2 11V19C2 20.6569 3.34315 22 5 22H17C18.6569 22 20 20.6569 20 19Z" fill="currentColor" />,
    color: () => <>
      <rect x="3" y="7" width="18" height="14" rx="3" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3.98474 20.2224C3.73545 19.9962 3.52438 19.7286 3.36237 19.4305L7.43933 15.3536C8.02511 14.7678 8.97486 14.7678 9.56065 15.3536L11.6464 17.4393C11.8417 17.6346 12.1583 17.6346 12.3535 17.4393L17.6464 12.1465L18.3535 12.8536L13.0606 18.1465C12.4749 18.7322 11.5251 18.7322 10.9393 18.1465L8.85354 16.0607C8.65828 15.8654 8.3417 15.8654 8.14644 16.0607L3.98474 20.2224Z" fill="hsla(var(--app-c-primary-hsl), 1)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15 7V10H21V13.6056C20.9425 13.7013 20.8826 13.7958 20.8203 13.889C20.0511 15.0401 18.9579 15.9373 17.6788 16.4672C16.3997 16.997 14.9922 17.1356 13.6344 16.8655C12.2765 16.5954 11.0292 15.9287 10.0503 14.9497C9.07129 13.9708 8.4046 12.7235 8.13451 11.3656C7.86441 10.0078 8.00303 8.6003 8.53285 7.32122C8.57776 7.21278 8.62532 7.10568 8.67545 7H15Z" fill="#4A4181" fillOpacity="0.38" />
      <path d="M21 9C21.5523 9 22.0073 9.44997 21.9287 9.99662C21.7804 11.0273 21.4034 12.0163 20.8203 12.889C20.0511 14.0401 18.9579 14.9373 17.6788 15.4672C16.3997 15.997 14.9922 16.1356 13.6344 15.8655C12.2765 15.5954 11.0292 14.9287 10.0503 13.9497C9.07128 12.9708 8.4046 11.7235 8.1345 10.3656C7.86441 9.00776 8.00303 7.6003 8.53284 6.32122C9.06266 5.04213 9.95986 3.94888 11.111 3.17971C11.9837 2.59657 12.9727 2.21956 14.0034 2.07131C14.55 1.99267 15 2.44772 15 3V9H21Z" fill="url(#OVIN0923H_1_radial)" />
      <path d="M21 8C21.5523 8 22.0085 7.54925 21.9169 7.00462C21.842 6.55939 21.7169 6.12298 21.5433 5.7039C21.2417 4.97595 20.7998 4.31451 20.2426 3.75736C19.6855 3.20021 19.0241 2.75825 18.2961 2.45672C17.877 2.28313 17.4406 2.15804 16.9954 2.08314C16.4508 1.99152 16 2.44772 16 3L16 8H21Z" fill="url(#OVIN0923H_2_radial)" />
      <defs>
        <linearGradient id="OVIN0923H_0_linear" x1="12" y1="7" x2="18.5" y2="22" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity=".4" />
        </linearGradient>
        <radialGradient id="OVIN0923H_1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19 17) rotate(-127) scale(24)">
          <stop stopColor="#FFC700" />
          <stop offset="1" stopColor="#FF5C00" />
        </radialGradient>
        <radialGradient id="OVIN0923H_2_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22 8) rotate(-133) scale(11 17)">
          <stop stopColor="#9696FC" stopOpacity=".7" />
          <stop offset="1" stopColor="#4E4BE0" />
        </radialGradient>
      </defs>
    </>
  })
}