import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject, Renderable } from '../../@types';
import joinClassName from '../../utils/className.utils';
import { renderRenderable } from '../../utils/components.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { formatSearchString } from '../../utils/string.utils';
import BaseButton from '../BaseButton/BaseButton';
import BaseInput from '../BaseInput/BaseInput';
import './SearchBar.scss';

export interface SearchBarProps<T = AnyObject> {
  form?: T,
  field?: keyof T,
  className?: string,
  placeholder?: string,
  onChange?: (query: string) => unknown,
  onEnter?: (query: string) => unknown,
  onFocus?: (query: string) => unknown,
  onBlur?: (query: string) => unknown,
  dataCy?: string;
  autoComplete?: string,
  autoCapitalize?: string,
  autoCorrect?: string,
  autoFocus?: boolean,
  submitButton?: Renderable
  disabled?: boolean,
}

const SearchBar: React.FC<SearchBarProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    _userInput: '',
    get userInput() {
      if (p.form && p.field) return p.form[p.field];
      return s._userInput;
    },
    set userInput(v: string) {
      if (p.form && p.field) p.form[p.field] = v;
      else s._userInput = v;
    },
    get formattedInput() {
      return formatSearchString(s.userInput);
    },
    handleChange() {
      p.onChange?.(s.formattedInput);
    },
    handleEnter() {
      p.onEnter?.(s.formattedInput);
    },
    handleFocus() {
      p.onFocus?.(s.formattedInput);
    },
    handleBlur() {
      p.onBlur?.(s.formattedInput);
    },
  }));

  return <Observer children={() => (
    <div className={joinClassName('SearchBar', p.className)} data-cy={p.dataCy}>
      <BaseInput
        form={s}
        field="userInput"
        type="search"
        onChange={s.handleChange}
        onFocus={s.handleFocus}
        onBlur={s.handleBlur}
        onEnter={s.handleEnter}
        placeholder={p.placeholder}
        autoComplete={p.autoComplete ?? 'off'}
        autoCorrect={p.autoCorrect ?? 'off'}
        autoCapitalize={p.autoCapitalize ?? 'off'}
        autoFocus={p.autoFocus}
        disabled={p.disabled}
      />
      <div className="SearchBarSubmitButton">
        {
          p.submitButton ? renderRenderable(p.submitButton) : <BaseButton
            appearance="icon"
            icon="search"
            onClick={s.handleChange}
            disabled={p.disabled}
          />
        }
      </div>
    </div>
  )} />

}

export default SearchBar;