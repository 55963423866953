import React from "react";
import { makeIconDef } from "./makeIconDef";

export const VideoIconDef = {
  video: makeIconDef('video', {
    regular: () => <path d="M14.5 10C14.5 10.1844 14.6015 10.3538 14.7641 10.4409C14.9267 10.5279 15.1239 10.5183 15.2774 10.416L19.7227 7.45251C20.0549 7.23099 20.5 7.46918 20.5 7.86853V17.1315C20.5 17.5308 20.0549 17.769 19.7226 17.5475L15.2774 14.584C15.1239 14.4817 14.9267 14.4722 14.7641 14.5592C14.6015 14.6462 14.5 14.8156 14.5 15V17C14.5 17.2762 14.2761 17.5 14 17.5H4C3.72386 17.5 3.5 17.2762 3.5 17V8.00002C3.5 7.72387 3.72386 7.50002 4 7.50002H14C14.2761 7.50002 14.5 7.72387 14.5 8.00002V10Z" stroke="currentColor" strokeLinejoin="round" />,
    filled: () => <path fillRule="evenodd" clipRule="evenodd" d="M19.4453 7.03648L15 10V8.00002C15 7.44773 14.5523 7.00002 14 7.00002H4C3.44772 7.00002 3 7.44773 3 8.00002V17C3 17.5523 3.44772 18 4 18H14C14.5523 18 15 17.5523 15 17V15L19.4453 17.9635C20.1099 18.4066 21 17.9302 21 17.1315V7.86853C21 7.06984 20.1099 6.59345 19.4453 7.03648Z" fill="currentColor" />,
    color: () => <>
      <path fillRule="evenodd" clipRule="evenodd" d="M19.4453 7.03648L15 10V8.00002C15 7.44773 14.5523 7.00002 14 7.00002H4C3.44772 7.00002 3 7.44773 3 8.00002V17C3 17.5523 3.44772 18 4 18H14C14.5523 18 15 17.5523 15 17V15L19.4453 17.9635C20.1099 18.4066 21 17.9302 21 17.1315V7.86853C21 7.06984 20.1099 6.59345 19.4453 7.03648Z" fill="url(#I02FON0_radial)" />
      <defs>
        <radialGradient id="I02FON0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(21 22.5) rotate(-134.215) scale(25.8118 41.2391)">
          <stop stopColor="#FD9D90" />
          <stop offset="1" stopColor="#E30202" />
        </radialGradient>
      </defs>
    </>
  }),
  'video-off': makeIconDef('video-off', {
    regular: () => <>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.4142 18H14C14.5523 18 15 17.5523 15 17V16.2019V15L16 15.6667L19.4453 17.9635C20.1099 18.4066 21 17.9302 21 17.1315V7.86853C21 7.06984 20.1099 6.59345 19.4453 7.03648L19.2426 7.17162L15.637 10.7772L20 7.86853L20 17.1315L15.5547 14.168C15.2478 13.9634 14.8533 13.9443 14.5281 14.1183C14.203 14.2924 14 14.6312 14 15V17H9.4142L8.4142 18ZM14 9.58579V8.00002H4V17H6.58577L5.58577 18H4C3.44772 18 3 17.5523 3 17V8.00002C3 7.44773 3.44772 7.00002 4 7.00002H14C14.5523 7.00002 15 7.44773 15 8.00002V8.58579L14 9.58579Z" fill="currentColor" />
      <path d="M20 4L4 20" stroke="currentColor" />
    </>,
    filled: () => <>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.94454 18H14C14.5523 18 15 17.5523 15 17V15L19.4453 17.9636C20.1099 18.4066 21 17.9302 21 17.1315V7.86855C21 7.30963 20.5641 6.90855 20.0747 6.86992L8.94454 18ZM15 8.05547V8.00003C15 7.44775 14.5523 7.00003 14 7.00003H4.00001C3.44773 7.00003 3.00001 7.44775 3.00001 8.00003V17C3.00001 17.5523 3.44773 18 4.00001 18H5.05545L15 8.05547Z" fill="currentColor" />
      <path d="M20 4L4 20" stroke="currentColor" strokeWidth="2" />
    </>
  })
}