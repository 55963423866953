import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { useProps, useStore } from '../../utils/mobx.utils';
import './BaseCalendarWeekHeader.scss';

interface BaseCalendarWeekHeaderProps {
  format: 'singleChar' | 'short' | 'long'
}

function getDaysOfWeek(formatter: (m: moment.Moment) => string) {
  const startOfWeek = moment().startOf('week');
  return Array(7).fill(null).map((n, i) => {
    const m = startOfWeek.add(Math.min(i, 1), 'day');
    return formatter(m);
  })
}

const BaseCalendarWeekHeader: React.FC<BaseCalendarWeekHeaderProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get formatter(): (m: moment.Moment) => string {
      switch (p.format) {
        case 'singleChar': return m => m.format('dd')[0];
        case 'long': return m => m.format('dddd');
        case 'short': default: return m => m.format('ddd');
      }
    },
    get daysOfWeek() {
      return getDaysOfWeek(s.formatter);
    }
  }));

  return <Observer children={() => (
    <table className="BaseCalendarWeekHeader">
      <thead>
        <tr className="BaseCalendarWeekHeaderInner">
          {s.daysOfWeek.map((d, i) => <th className="BaseCalendarWeekHeaderCell" key={d + i} data-day-of-week={i}><span>{d}</span></th>)}
        </tr>
      </thead>
    </table>
  )} />

}

export default BaseCalendarWeekHeader;