
import { AxiosError } from 'axios';
import { flow, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import AppPage from '../../base/components/AppPage/AppPage';
import AppPageContent from '../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../base/components/AppPageHeader/AppPageHeader';
import BaseHeader from '../../base/components/BaseHeader/BaseHeader';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import BaseInput from '../../base/components/BaseInput/BaseInput';
import BaseSelector from '../../base/components/BaseSelector/BaseSelector';
import BaseSpacer from '../../base/components/BaseSpacer/BaseSpacer';
import CurrencyRenderer from '../../base/components/CurrencyRenderer/CurrencyRenderer';
import DOMCopyButton from '../../base/components/DOMCopyButton/DOMCopyButton';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import InfoTable from '../../base/components/InfoTable/InfoTable';
import OverlayCloseButton from '../../base/components/OverlayCloseButton/OverlayCloseButton';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import UIBlock from '../../base/components/UIBlock/UIBlock';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useAppDisplayName } from '../../base/hooks/useAppDisplayName.hook';
import { useObservableRef } from '../../base/hooks/useObservableRef.hook';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { makeActionConfig } from '../../base/utils/actionConfig.utils';
import joinClassName from '../../base/utils/className.utils';
import { NoOp } from "../../base/utils/functions.utils";
import { useStore } from '../../base/utils/mobx.utils';
import { toLowerCase } from '../../base/utils/string.utils';
import { availableDonationFrequencies, SubscriptionFrequency } from '../../constants/subscription.enums';
import { FEATURE_FLAGS } from '../../env';
import { checkErrorForRecaptchaFailure } from '../../utils/loadRecaptcha.utils';
import MakePaymentForm from '../MakePaymentForm/MakePaymentForm';
import OnceOffVisitorPaymentForm from '../OnceOffVisitorPaymentForm/OnceOffVisitorPaymentForm';
import './OverlayDonate.scss';

export type OverlayDonateProps = {
  defaultFrequency?: SubscriptionFrequency,
}

const defaultOptions = [100, 50, 25, 10, 5];

const OverlayDonate: React.FC<OverlayDonateProps> = props => {

  const { AUTH, UI, NAVIGATOR } = useControllers();

  const s = useStore(() => ({
    amount: 50,
    donationFrequency: props.defaultFrequency ?? SubscriptionFrequency.oneTime,
    get heartIconScale() {
      const min = 1, max = 200, value = s.amount;
      return 1.0 + Math.max(Math.min((Math.min(value, max) - min) / (max - min), 1), 0);
    },
    get isSubscription() {
      return s.donationFrequency !== SubscriptionFrequency.oneTime;
    },
  }));

  const dismiss = () => {
    UI.OVERLAY.dismiss();
  };

  const onDonationSuccess = () => {
    UI.DIALOG.positive({
      name: 'donation-success',
      heading: 'Thank you for your generous donation.',
      body: 'Your contribution will help us keep providing professional services for every person in need. We will send you a donation confirmation via your preferred means of communication shortly.',
      actions: [
        makeActionConfig('Done', dismiss)
      ]
    })
  }

  const onDonationError = (e: AxiosError | unknown) => {
    checkErrorForRecaptchaFailure(e);
    UI.DIALOG.error({
      heading: 'Failed to complete your donation request',
      body: () => <>
        <p>If the request had failed due to a recaptcha error, please try submitting the form again. You will be asked a simple math question. Otherwise, please double check the form details.</p>
        <ErrorRenderer error={e} />
      </>
    })
  }

  useOnMount(() => {
    const d = reaction(
      () => s.donationFrequency,
      flow(function* (v) {
        if (v !== SubscriptionFrequency.oneTime) {
          if (AUTH.currentUser?.activeSubscription) {
            yield UI.DIALOG.attention({
              heading: 'You already have an active recurring donation',
              body: "To manage your recurring donation, you can visit your account's Manage Donations page",
              actions: [
                makeActionConfig(
                  "Manage Donation",
                  () => {
                    NAVIGATOR.navigateTo(`/${AUTH.isStaff ? 'admin' : 'app'}/account/manage-donations`)
                    dismiss();
                  },
                  { buttonClass: 'subtle' }
                ),
                makeActionConfig('OK', NoOp),
              ]
            });
            s.donationFrequency = SubscriptionFrequency.oneTime;
          }
        }
      }),
      { fireImmediately: true }
    )
    return d;
  })

  const appName = useAppDisplayName();
  const confirmationMessageRenderer = (a?: number | null) => <>Please confirm that you are donating <CurrencyRenderer value={a} /> to { appName}.</>;
  const buttonLabelRenderer = (a?: number | null) => <>Donate <CurrencyRenderer value={a} /> {s.donationFrequency !== SubscriptionFrequency.oneTime ? toLowerCase(s.donationFrequency) : null}</>;

  const bankInfoRef = useObservableRef<HTMLTableElement>();

  return <Observer children={() => (

    <AppPage className="OverlayDonate" color="red">

      <AppPageHeader
        title={<><span className="HeartIconWrapper" style={{ transform: `scale(${s.heartIconScale})` }}><BaseIcon className={joinClassName("HeartIcon", s.donationFrequency)} icon="heart" variant="filled" color="red" /></span> Donate to turn2me</>}
        endSlot={<OverlayCloseButton />}
      />

      <AppPageContent>

        <UIBlock padded spaceChildren>

          <ShadedBlock spaceChildren>
            <h3>Thank you for considering donating to turn2me!</h3>
            <p>turn2me relies on our users donations to help fund our operations. Any little bit helps and is greatly appreciated.</p>
            {/* <p>Your payment will be processed by <a href="https://stripe.com/" title="Stripe online payment gateway" target="_blank" rel="noreferrer noopenner">Stripe</a>.</p> */}
          </ShadedBlock>

          {FEATURE_FLAGS.DISABLE_DONATIONS
            ? <>
              <h3>We are currently experiencing technical issues with our payment processing.</h3>
              <p><strong>If you would like to make a much appreciated donation to Turn2Me, we would kindly request that you make a payment directly to our bank account, details below.</strong> Thank you for your patience and vital support.</p>
            </>
            : <>
              <ShadedBlock className="DonationAmountSelector" spaceChildren>
                <h3>Select your donation</h3>
                <BaseSelector
                  label="Frequency"
                  className="DonationFrequencySelector"
                  form={s}
                  field="donationFrequency"
                  appearance="inline"
                  options={availableDonationFrequencies}
                  required
                />
                <BaseSpacer size="0.5em" />
                <BaseSelector
                  label="Amount"
                  options={defaultOptions}
                  valueGetter={o => o}
                  optionLabelRenderer={o => <CurrencyRenderer value={o} />}
                  form={s}
                  field='amount'
                  required
                />
                <BaseInput label="Or enter a value (EUR):" form={s} field="amount" type="number" min={1} step={0.01} />
              </ShadedBlock>

              <ShadedBlock>
                {
                  AUTH.isAuthenticated ? (
                    <MakePaymentForm
                      amount={s.amount}
                      onComplete={onDonationSuccess}
                      onError={onDonationError}
                      buttonLabelRenderer={buttonLabelRenderer}
                      confirmationMessageRenderer={confirmationMessageRenderer}
                      title={<h3>Choose a way to donate</h3>}
                      isSubscription={s.isSubscription}
                      subscriptionFrequency={s.donationFrequency}
                    />
                  ) : (
                    <OnceOffVisitorPaymentForm
                      amount={s.amount}
                      onSuccess={onDonationSuccess}
                      onError={onDonationError}
                      buttonLabelRenderer={buttonLabelRenderer}
                      confirmationMessageRenderer={confirmationMessageRenderer}
                    />
                  )
                }
              </ShadedBlock>
            </>
          }

          <ShadedBlock>
            <BaseHeader
              heading="Donations can be sent directly to the following details."
              color="red"
              endSlot={<DOMCopyButton targetRef={bankInfoRef} showButtonBackground />}
            >
              <p>* You can click the copy button on the right to copy the following information.</p>
            </BaseHeader>
            <BaseSpacer size="sm" />
            <InfoTable tableRef={bankInfoRef}>
              <tbody>
                <tr><th>Bank Name:</th><td>Allied Irish Bank</td></tr>
                <tr><th>Name on Account:</th><td>Turn2me</td></tr>
                <tr><th>Bank Branch:</th><td>Main Street, Rathfarnham, Dublin 14, Ireland</td></tr>
                <tr><th>Bank Sort Code:</th><td>93-32-95</td></tr>
                <tr><th>Bank Account No.:</th><td>11098064</td></tr>
                <tr><th>IBAN:</th><td>IE37AIBK93329511098064</td></tr>
                <tr><th>BIC:</th><td>AIBKIE2DXXX</td></tr>
              </tbody>
            </InfoTable>
          </ShadedBlock>

        </UIBlock>

      </AppPageContent>

    </AppPage>

  )} />
}

export default OverlayDonate;