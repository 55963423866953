import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Nillable } from '../../base/@types';
import joinClassName from '../../base/utils/className.utils';
import { getContrastColorHex, isBrightColor } from '../../base/utils/colors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { getFirstSymbol } from '../../base/utils/string.utils';
import { User } from '../../models/makeUser.model';
import './UserIcon.scss';

interface UserIconProps {
  className?: string,
  user?: Nillable<Partial<User>>,
  size?: string | number,
  use?: 'username' | 'fullName'
}

const UserIcon: React.FC<UserIconProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    get label() {
      return (p.use === 'username' || !p.use) ? getFirstSymbol(p.user?.username) : p.user?.abbreviation || getFirstSymbol(p.user?.username);
    },
    get style() {
      return {
        width: p.size ?? '2.4rem',
        height: p.size ?? '2.4rem',
        backgroundColor: s.color,
        color: s.color ? getContrastColorHex(s.color) : undefined,
      }
    },
    get color() {
      return p.user?.color ?? undefined;
    },
    get isBrightColor() {
      return isBrightColor(s.color);
    }
  }));

  return <Observer children={() => <div 
    className={joinClassName('UserIcon', p.className, s.isBrightColor ? 'light' : 'dark' )} 
    style={s.style}
  >
    { s.label && <span>{s.label}</span> }
  </div>} />
} 

export default UserIcon;