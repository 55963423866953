import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ContextColor } from '../../constants/color.enum';
import { useColorCSSCustomPropertyStyle } from '../../hooks/useColorCSSCustomPropertyStyle.hook';
import { useContextColorStyle } from '../../hooks/useContextColor.hook';
import { capitalize } from '../../utils/string.utils';
import BaseSelector from '../BaseSelector/BaseSelector';
import BaseToggle from '../BaseToggle/BaseToggle';
import OptionsPanel from '../OptionsPanel/OptionsPanel';
import { OptionsPanelSectionProps } from '../OptionsPanel/OptionsPanelSection';
import { BaseCalendarProps } from './BaseCalendar';
import './BaseCalendarOptionsPanel.scss';
import { BaseCalendarState, IBaseCalendarEventType, IBaseCalendarEventTypeGroup } from './BaseCalendarState';

interface P<T = object> extends BaseCalendarProps<T> {}

function BaseCalendarDetailLevelSelector<EventDataType = object>(props: React.PropsWithChildren<{
  calendarState: BaseCalendarState<EventDataType>
}>) {
  const options = ['week', 'month'];
  return <Observer children={() => (
    <BaseSelector options={options} optionLabelRenderer={capitalize} form={props.calendarState} field="mode" />
  )} />
}

function BaseCalendarViewOptionsToggleSet<EventDataType = object>(props: React.PropsWithChildren<{
  calendarState: BaseCalendarState<EventDataType>
}>) {
  return <Observer children={() => (
    <>
      <BaseToggle form={props.calendarState} field="fitInnerToAvailableHeight">Fit Calendar in Window</BaseToggle>
    </>
  )} />
}


function OptionPanelToggleFromEventType<T = object>(props: React.PropsWithChildren<{
  eventType: IBaseCalendarEventType<T>,
}>) {
  const style = {
    ...useColorCSSCustomPropertyStyle(props.eventType),
    ...useContextColorStyle(ContextColor.Primary, props.eventType.color),
  };
  return <Observer children={() => (
    <BaseToggle form={props.eventType} field="isActive" style={style}>{props.eventType.name}</BaseToggle>
  )} />
}

function OptionPanelToggleGroupFromEventTypeGroup<EventDataType = object>(props: React.PropsWithChildren<{
  eventTypeGroup: IBaseCalendarEventTypeGroup<EventDataType>
}>) {
  return <Observer children={() => (
    <>
      { props.eventTypeGroup.eventTypes.map(et => (
        <OptionPanelToggleFromEventType eventType={et} key={et.name} />
      ))}
    </>
  )} />
}

function BaseCalendarOptionsPanel<T extends object>(props: React.PropsWithChildren<P<T>>) {

  const { optionSections = [] } = props;
  const optionSectionsWithDefaults = optionSections.map(s => {
    switch (typeof s) {
      case 'string': {
        switch (s) {
          case 'detailLevel': return ({
            name: 'detailLevel',
            title: 'Detail Level',
            children: <BaseCalendarDetailLevelSelector calendarState={props.state}/>
          } as OptionsPanelSectionProps);
          case 'viewOptions': return ({
            name: 'viewOptions',
            title: 'View Options',
            children: <BaseCalendarViewOptionsToggleSet calendarState={props.state} />
          } as OptionsPanelSectionProps);
          case 'eventTypeToggleGroups': return (
            props.state.eventTypeGroups.map(g => ({
              name: g.name,
              title: g.name,
              children: <OptionPanelToggleGroupFromEventTypeGroup eventTypeGroup={g} />,
            }))
          )
          default: break;
        }
        return void 0;
      }
      case 'object':
      default:
        return s as OptionsPanelSectionProps;
    } 
  }).filter(i=>i).flat();

  return <Observer children={() => (
    <OptionsPanel
      className="BaseCalendarOptionsPanel"
      title="Calendar Options"
      sections={optionSectionsWithDefaults as OptionsPanelSectionProps[]}
    />
  )} />

}

export default BaseCalendarOptionsPanel;