import { Observer } from 'mobx-react-lite';
import React from 'react';
import sanitize from 'sanitize-html';
import { useControllers } from '../../hooks/useRootController.hook';
import { copyString } from '../../utils/dom.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import BaseButton from '../BaseButton/BaseButton';
import BaseSpacer from '../BaseSpacer/BaseSpacer';
// import './ClickToCopy.scss';

interface ClickToCopyProps {
  text?: string,
  buttonLabel?: string,
  successMessageComposer?: (snippet?: string) => string,
}

const ClickToCopy: React.FC<ClickToCopyProps> = props => {

  const p = useProps(props);
  const { UI } = useControllers();

  const s = useStore(() => ({
    get sanitizedText() {
      return sanitize(p.text || "");
    },
    copyText: () => {
      if (!s.sanitizedText) return;
      try {
        copyString(s.sanitizedText);
        s.onSuccess();
      } catch(e) {
        s.onError();
      }
    },
    onSuccess: () => {
      const snippet = p.text!.length < 38 ? p.text : p.text!.substr(0, 38) + '...';
      UI.TOAST.success(p.successMessageComposer ? p.successMessageComposer(snippet) : `"${snippet}" has been copied to your clipboard.`, 3000);
    },
    onError: () => {
      UI.TOAST.attention('Sorry! The automatic copy command was not successful, please select and copy by hand.');
    }
  }));

  return <Observer children={() => (
    p.text ? <div className="ClickToCopy">
      <div className="ClickToCopyInner">{props.children ?? p.text}</div>
      <BaseSpacer size=".5em" />
      <BaseButton size="sm" icon="copy" onClick={s.copyText} label={p.buttonLabel ?? 'Copy'}/>
    </div> : null
  )} />
}

export default ClickToCopy;