import TweenLite, { Expo } from 'gsap';
import { action, flow, observable, reaction, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { MutableRefObject, PropsWithChildren, useContext, useRef } from 'react';
import { Renderable, RouteDef } from '../../@types';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useOnRouteFocus } from '../../hooks/navigator.hooks';
import { useControllers } from '../../hooks/useRootController.hook';
import { makePointerContactController } from '../../mediators/pointerContact.mediator';
import joinClassName from '../../utils/className.utils';
import { renderRenderable } from '../../utils/components.utils';
import { disableSelect, enableSelect } from '../../utils/document.utils';
import { delayedComputed, useProps, useStore } from '../../utils/mobx.utils';
import { popUrl } from '../../utils/url.utils';
import tick from '../../utils/waiters.utils';
import CustomScrollbar from '../CustomScrollbar/CustomScrollbar';
import RouterStack from '../RouterStack/RouterStack';
import './TwoColumnView.scss';

type TwoColumnViewProps = {
	className?: string,
	routes: RouteDef[],
	prefix?: string,
	aside: Renderable,
	asideWidth?: number,
	placeholderText?: string,
	nonMobileViewFallbackUrl?: string,
	debug?: string,
}

const useTwoColumnState = (
	props: PropsWithChildren<TwoColumnViewProps>,
	contentRef: MutableRefObject<HTMLDivElement | null>
) => {

	const { UI, NAVIGATOR } = useControllers();

	const { prefix = '/' } = props;

	const p = useProps({
		prefix,
		prefixRegex: new RegExp(`^${prefix.replace(/\/$/, '/?')}`),
		routes: props.routes,
	});

	const s = useStore(() => ({
		get pathname() {
			return NAVIGATOR.currentLocationPathname
		},
		get routeNameRegex() {
			return new RegExp(`^${p.prefix}/?([a-zA-Z0-9-]*)`, 'i');
		},
		get routePath() {
			return s.pathname.match(s.routeNameRegex)?.[1];
		},
		get activeRoute() {
			return s.routePath && p.routes.find(r => r.urlFactory() === s.routePath);
		},
		animateContentPanelIn: () => new Promise<void>(resolve => {
			disableSelect();
			const onCompleteFn = (resolve: Function) => () => {
				enableSelect();
				resolve();
			}
			if (s.hasSuspended.value) when(() => Boolean(contentRef.current), () => TweenLite.set(contentRef.current, {
				x: 0,
				onComplete: onCompleteFn(resolve)
			}))
			else {
				// console.log('animating in')
				when(() => Boolean(contentRef.current), () => TweenLite.to(contentRef.current, {
					duration: UI.fromTablet ? .05 : .62,
					ease: Expo.easeOut,
					x: 0,
					onComplete: onCompleteFn(resolve)
				}))
			}
		}),
		get hasVisibleContent() {
			return !!s.activeRoute;
		},
		animateContentPanelOut: () => new Promise<void>(resolve => {
			disableSelect();
			when(() => Boolean(contentRef.current), () => TweenLite.to(contentRef.current, {
				duration: UI.fromTablet ? .05 : .38,
				ease: Expo.easeOut,
				x: UI.fromTablet ? 0 : '100%',
				onComplete: () => {
					(async () => {
						resolve();
						await tick();
						s.history.push(s.pathname);
						enableSelect();
					})();
				}
			}))
		}),
		animateOutAndGoUp: () => flow(function* () {
			const shouldGoBack = UI.displayMode === 'phone'
				&& !s.shouldBlockInteraction
				&& s.alive && s.activeRoute;
			if (!shouldGoBack) return;
			s.pointerContactController.reset();
			s.shouldBlockInteraction = true;
			yield s.animateContentPanelOut();
			s.goUp();
			s.shouldBlockInteraction = false;
		})(),
		pointerContactController: makePointerContactController(),
		alive: true,
		shouldBlockInteraction: false,
		history: [] as string[],
		goUp: () => {
			const current = s.pathname;
			const poppedUrl = current ? popUrl(current) : p.prefix;
			NAVIGATOR.navigateTo(poppedUrl);
		},
		hasSuspended: delayedComputed<boolean>(
			() => {
				if (!p.prefix) return false;
				// console.log('hasSus', s.pathname, p.prefixRegex, s.pathname.match(p.prefixRegex));
				return !NAVIGATOR.currentLocationPathname.match(p.prefixRegex);
			},
			100),
	}));

	return s;
}
type TwoColumnViewState = ReturnType<typeof useTwoColumnState>;

const makeTwoColumnViewContext = (state?: TwoColumnViewState, contentRef?: MutableRefObject<HTMLDivElement | null>) => {
	const s = observable({
		state,
		contentRef,
	})
	return s;
}
const TwoColumnViewContext = React.createContext(makeTwoColumnViewContext());
export const useTwoColumnViewContext = () => useContext(TwoColumnViewContext);

const TwoColumnView: React.FC<TwoColumnViewProps> = props => {

	const { UI, NAVIGATOR } = useControllers();
	const contentRef = useRef<HTMLDivElement>(null);

	const s = useTwoColumnState(props, contentRef);

	const store = useStore(() => ({
		context: makeTwoColumnViewContext(s, contentRef),
	}))

	useOnMount(() => {
		TweenLite.set(contentRef.current, {
			x: s.activeRoute ? 0 : UI.fromTablet ? 0 : '100%',
		})
		const disposers = [] as Function[];
		disposers.push(reaction(
			() => s.activeRoute,
			() => {
				if (s.activeRoute) s.animateContentPanelIn()
				else s.animateContentPanelOut();
			},
		))
		s.pointerContactController.onSwipeRight(s.animateOutAndGoUp);
		return action(() => {
			s.alive = false;
			disposers.forEach(d => d());
		})
	})

	useOnRouteFocus(
		props.prefix,
		() => {
			if (!props.nonMobileViewFallbackUrl) return;
			if (UI.fromTablet && !store.context.state?.activeRoute) {
				props.nonMobileViewFallbackUrl && NAVIGATOR.navigateTo(props.nonMobileViewFallbackUrl);
			}
		},
		{ fireImmediately: true }
	)
	
	return <Observer children={() => (
		<TwoColumnViewContext.Provider value={store.context}>
			<div
				className={
					joinClassName(
						"TwoColumnView", 
						props.className, 
						s.hasVisibleContent ? 'hasVisibleContent' : 'empty'
					)
				}
				data-location-pathname={s.pathname}
				data-route-regex={s.routeNameRegex}
				data-route-path={s.routePath}
				data-has-suspended={s.hasSuspended.value}
				data-active-route={s.activeRoute}
			>
				<CustomScrollbar className="TwoColumnViewAside" style={{width: props.asideWidth}}>
					<div className="TwoColumnViewAsideInner">
						{ renderRenderable(props.aside) }
					</div>
				</CustomScrollbar>
				<div
					className='TwoColumnViewContentPanel'
					ref={contentRef}
				>	
					{UI.fromTablet && <div className="TwoColumnViewContentPanelEmptyViewNotice">
						<svg width="34" height="21" viewBox="0 0 34 21" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M0 2C0 0.89543 0.895431 0 2 0H3H4H12H13H32C33.1046 0 34 0.895431 34 2V19C34 20.1046 33.1046 21 32 21H13H12H4H3H2C0.895431 21 0 20.1046 0 19V2ZM12 16V13H4V16H12ZM12 20V17H4V20H12ZM13 17V20H32C32.5523 20 33 19.5523 33 19V2C33 1.44771 32.5523 1 32 1H13V4V8V9V12V13V16V17ZM12 1V4H4V1H12ZM4 12V9H12V12H4ZM10 6H5V7H10V6ZM18.5 10.5L22 7V9.1H27V11.9H22V14L18.5 10.5Z" fill="currentColor" />
						</svg>
						<p><em>{props.placeholderText ?? 'Select a section from the sidebar'}</em></p>
					</div>}
					<RouterStack
						routes={props.routes}
						prefix={props.prefix}
						debug={props.debug}
					/>
				</div>
			</div>
		</TwoColumnViewContext.Provider>
	)} />

}

export default React.memo(TwoColumnView);