import { action, flow, observable } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseGrid from '../../base/components/BaseGrid/BaseGrid';
import BaseGridCell from '../../base/components/BaseGrid/BaseGridCell';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import BaseInput from '../../base/components/BaseInput/BaseInput';
import BaseSpacer from '../../base/components/BaseSpacer/BaseSpacer';
import BaseToggle from '../../base/components/BaseToggle/BaseToggle';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import InfoBanner from '../../base/components/InfoBanner/InfoBanner';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import { CounsellingSessionEndpoints } from '../../base/endpoints/counsellingSession.endpoints';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { ChatMediator } from '../../base/mediators/chat.mediator';
import joinClassName from '../../base/utils/className.utils';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { copyWithJSON } from '../../base/utils/object.utils';
import { eqBy } from '../../base/utils/ramdaEquivalents.utils';
import { equalByString } from '../../base/utils/string.utils';
import { getNowTimestampUtc } from '../../base/utils/time.utils';
import { ModelName } from '../../constants/modelNames.enum';
import { CounsellingApplication } from '../../models/makeCounsellingApplication.model';
import { CounsellingSession } from '../../models/makeCounsellingSession.model';
import SurveyAssignmentListForAdmin from '../../modules/Admin/_components/SurveyAssignmentListForAdmin/SurveyAssignmentListForAdmin';
import { saveCounsellingSession } from '../../requests/saveCounsellingSession.request';
import { endCounsellingSession, startCounsellingSession } from '../../requests/startOrEndCounsellingSession.request';
import CounsellingEmail from '../CounsellingEmail/CounsellingEmail';
import CounsellingSessionPriceSetterForm from '../CounsellingSessionPriceSetterForm/CounsellingSessionPriceSetterForm';
import EmailComposer from '../EmailComposer/EmailComposer';
import IssueSelector from '../IssueSelector/IssueSelector';
import './EmailCounsellingSession.scss';

interface EmailCounsellingSessionProps {
  index: number,
  application: CounsellingApplication,
  session: CounsellingSession,
  open?: boolean,
  chat?: ChatMediator,
  onDelete?: (session: CounsellingSession) => void;
  onToggleOpen?: (session: CounsellingSession) => void;
  role: 'counsellor' | 'client',
  onSendEmail?: (session: CounsellingSession) => void;
  onStartSession?: (session: CounsellingSession) => Promise<CounsellingSession>;
}

const EmailCounsellingSession: React.FC<EmailCounsellingSessionProps> = props => {

  const p = useProps(props);
  
  const { API, UI } = useControllers();

  const s = useStore(() => ({
    originalSession: null as CounsellingSession | null,
    get application() {
      return p.application;
    },
    get session() {
      return p.session;
    },
    get applicantId() {
      return s.application.applicant?.id || s.application.applicantId;
    },
    get chat() {
      return p.chat
    },
    get sessionHasStarted() {
      return Boolean(p.session.timeStarted);
    },
    get sessionHasEnded() {
      return Boolean(p.session.timeEnded);
    },
    get sessionIsOngoing() {
      return s.sessionHasStarted && !s.sessionHasEnded;
    },
    get canDelete() {
      return s.asCounsellor && s.sessionHasEnded && (p.session.threads?.length === 0 || s.chat?.messages.length === 0);
    },
    get asCounsellor() {
      return p.role === 'counsellor';
    },
    get asClient() {
      return p.role === 'client';
    },
    get assignments() {
      return p.session.assignments || []
    },
    get applicationIsArchived() {
      return Boolean(p.application?.timeArchived)
    },
    get applicationIsCompleted() {
      return Boolean(p.application?.timeCompleted)
    },
    get applicationEnded() {
      return Boolean(p.application.timeArchived || p.application.timeCompleted);
    },
    get sessionHasChanges() {
      return !eqBy(ses => [
        ses?.amountPayable,
        ses?.primaryIssue,
        ses?.secondaryIssue,
        ses?.sessionNotes,
      ].join('_'), s.originalSession, s.session);
    },
    get clientParticipantId () {
      return p.chat?.participants.find(p => equalByString(p.userId, s.applicantId))?.id;
    },
    get clientHasReplied() {
      return p.chat?.messages.some(m => equalByString(s.clientParticipantId, m.participantId));
    },
    get shouldShowEmailComposer() {
      const ended = s.applicationEnded || s.sessionHasEnded;
      if (ended) return false;
      if (s.asCounsellor) return true;
      if (s.asClient) return Boolean(s.chat?.messages.length);
      return false;
    },
  }));

  const saveSessionSnapshot = action(() => s.originalSession = copyWithJSON(s.session));

  useOnMount(() => {
    saveSessionSnapshot();
  })

  const deleteSession = () => new Promise<boolean>(
    async (resolve, reject) => {
      const url = CounsellingSessionEndpoints.staff.delete(p.session.id);
      try {
        await p.chat?.endChatAsStaff();
        await API.delete(url, ModelName.counsellingSessions, p.session);
        p.onDelete && p.onDelete(p.session);
        resolve(true);
      } catch(e) {
        reject(e);
        reportError(e);
        UI.DIALOG.error({
          heading: 'Error deleting the session',
          body: <ErrorRenderer error={(e as any).response} />
        })
      }
    }
  )

  const startSession = () => new Promise<boolean>(
    flow( function * (resolve, reject) {
      try {
        const sessionId = p.session.id;
        yield startCounsellingSession(API, sessionId);
        p.session.timeStarted = getNowTimestampUtc();
        if (p.onStartSession) yield p.onStartSession(p.session);
        resolve(true);
      } catch(e) {
        reject(e);
        reportError(e);
        UI.DIALOG.error({
          heading: 'Failed to start session',
          body: <ErrorRenderer error={(e as any).response} />
        })
      }
    })
  )
  
  const endSession = () => new Promise<boolean>(
    flow(function * (resolve, reject) {
      try {
        const form = observable({
          isPayable: true,
        })
        const confirm = yield UI.DIALOG.attention({
          heading: 'Are you sure you want to end the session?',
          body: <div>
            <p>The email thread will be closed. You cannot undo this action.</p>
            <BaseSpacer size=".5em" />
            <ShadedBlock>
              <BaseToggle form={form} field="isPayable">Mark this session as payable for your invoicing</BaseToggle>
            </ShadedBlock>
          </div>,
          defaultActions: ['negative', 'positive']
        })
        if (!confirm) {
          resolve(false);
          return;
        }
        if (!p.chat?.hasEnded) yield p.chat?.endChatAsStaff();
        yield endCounsellingSession(API, p.session.id, form.isPayable, s.clientHasReplied);
        p.session.timeEnded = getNowTimestampUtc();
        resolve(true);
      } catch(e) {
        reject(e); reportError(e);
        UI.DIALOG.error({
          heading: 'Failed to end session',
          body: <ErrorRenderer error={(e as any).response} />
        })
      }
    })
  )

  const saveSession = () => new Promise<boolean>(
    flow(function * (resolve, reject) {
        yield saveCounsellingSession(API, s.session).catch(e => {
        reject(e); reportError(e);
        UI.DIALOG.error({
          heading: 'Error saving the session',
          body: <ErrorRenderer error={(e as any).response} />,
        })
      });
      saveSessionSnapshot();
      resolve(true);
    })
  )

  const sendEmail = (body: string) => new Promise<boolean>(
    flow(function * (resolve, reject): any {
      let hasRetried = false;
      yield saveSession();
      if (hasRetried) {
        reject('Failed to send email. There was a problem starting a thread. Please contact us to resolve this issuse.');
        return;
      }
      if (!s.chat) {
        yield startSession();
        hasRetried = true;
        yield sendEmail(body);
        resolve(true);
        return;
      }
      if (s.chat.hasEnded) {
        UI.DIALOG.error({
          heading: 'It seems that the session has ended.',
          body: 'Please keep a copy of your email on your device and reload the page. You can reply to your counsellor in the next session.',
        })
        reject('Session closed.');
        return;
      }
      try {
        yield s.chat.sendMessage(body);
        p.onSendEmail && p.onSendEmail(s.session);
        resolve(true);
      } catch (e) {
        reject(e);
        UI.DIALOG.error({
          heading: 'Error sending the email.',
          body: <ErrorRenderer error={(e as any).response} />
        })
      }
    })
  )

  const handleHeaderClick = action(() => {
    p.onToggleOpen && p.onToggleOpen(p.session);
  })

  return <Observer children={() => (
    <div className={
      joinClassName(
        'EmailCounsellingSession',
        p.open && 'open'
      )
    }>
      <header className="EmailCounsellingSessionHeader" onClick={handleHeaderClick}>
        <div className="EmailCounsellingSessionHeaderInner">
          <div className="EmailCounsellingSessionHeaderIcon">
            <BaseIcon name={p.open ? 'minus' : 'plus'} />
          </div>
          <h3>Session {p.index + 1}</h3>
        </div>
        {
          s.asCounsellor && <div className="EmailCounsellingSessionheaderControls">
            {!s.sessionHasStarted && <BaseButton size="sm" color="green" onClick={startSession}>Mark as started</BaseButton>}
            {s.sessionIsOngoing && <BaseButton size="sm" color="orange" onClick={endSession}>Mark as ended</BaseButton>}
            {s.sessionHasEnded && <span>Ended on <DateRenderer value={p.session.timeEnded} /></span>}
          </div>
        }
      </header>
      {
        p.open && <div className="EmailCounsellingSessionContent">
          {
            s.asCounsellor && <div className="EmailCounsellingSessionDetails">
              <BaseGrid columns={2}>
                <BaseGridCell>
                  <h4 className="EmailCounsellingSessionHeading">Suggest A Donation</h4>
                  <CounsellingSessionPriceSetterForm session={s.session} application={s.application} disabled={s.session.timeStarted || s.application?.timeCompleted || s.application?.timeArchived} />
                </BaseGridCell>
                <BaseGridCell>
                  <h4 className="EmailCounsellingSessionHeading">Questionnaires</h4>
                  <SurveyAssignmentListForAdmin
                    assignments={s.assignments}
                    application={p.application}
                  />
                </BaseGridCell>
                <BaseGridCell columns={2}>
                  <h4 className="EmailCounsellingSessionHeading">Session Notes</h4>
                  <BaseGrid columns={2}>
                    <IssueSelector form={p.session} field="primaryIssue" label="Primary Issue" disabled={s.applicationIsArchived} />
                    <IssueSelector form={p.session} field="secondaryIssue" label="Secondary Issue" disabled={s.applicationIsArchived} />
                  </BaseGrid>
                  <BaseSpacer size=".38em" />
                  <BaseInput type="textarea" label="Notes" form={p.session} field="sessionNotes" optional rows={4} disabled={s.applicationIsArchived} />
                </BaseGridCell>
                {
                  s.sessionHasChanges && <BaseGridCell columns={2}>
                    <BaseButton color="green" onClick={saveSession}>Save Changes</BaseButton>
                  </BaseGridCell>
                }
              </BaseGrid>
            </div>
          }
          <div className="CounsellingEmailList">
            <h4 className="EmailCounsellingSessionHeading">Email Thread</h4>
            {(!s.chat || s.chat.messages.length === 0) && (
              <p className="CounsellingEmailListEmptyNotice"><em>No emails in this session{!s.sessionHasEnded && ' yet'}. {s.asClient && !s.sessionHasEnded && 'Please wait for your counsellor to send the first email.'}</em></p>
            )}
            {s.chat?.messages.map(m => <CounsellingEmail chat={s.chat!} message={m} key={m.id} />)}
          </div>
          {
            s.canDelete && <InfoBanner icon="info" colorCodedState={ColorCodedState.attention}>
              <p>This session was ended on <DateRenderer value={p.session.timeEnded} />, but there were no email communications during this session. Would you like to delete this session?</p>
              <BaseButton icon="delete" onClick={deleteSession}>Delete Session</BaseButton>
            </InfoBanner>
          }

          {
            !s.applicationEnded && (
              s.shouldShowEmailComposer ? <div className="EmailCounsellingSessionComposerSection">
                <h4 className="EmailCounsellingSessionComposerHeading">Compose New Email</h4>
                <p><em>* For security reasons, images and potentially unsafe content are not allowed in the emails.</em></p>
                <BaseSpacer size=".5em" />
                <EmailComposer onSend={sendEmail} />
                {
                  !s.sessionHasStarted && s.asCounsellor && <InfoBanner icon="warning" colorCodedState={ColorCodedState.attention}>
                    <p>Once you send the first email, the session will be marked as started and you will no longer be able to change the donation suggestion. If this is a donation-required session, please confirm the price before sending your first email or starting the session.</p>
                  </InfoBanner>
                }
              </div> : (
                  s.asClient && s.sessionHasEnded && <InfoBanner colorCodedState={ColorCodedState.attention}>
                    <p>You will be able to reply to your counsellor when they open a new session.</p>
                  </InfoBanner>
                )
            )
          }

        </div>
      }
    </div>
  )} />
}

export default EmailCounsellingSession;