import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Form } from '../../mediators/form.mediator';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import CountrySelector, { CountrySelectorProps } from '../CountrySelector/CountrySelector';
import { useFormContext } from '../FormForm/Form.context';
// import './FormCountrySelector.scss';

type FormCountrySelectorProps<T extends object = object> = Omit<CountrySelectorProps<T>, 'form'> & {
  form?: Form<T>,
}

function FormCountrySelector<T extends object = object>(
  props: React.PropsWithChildren<FormCountrySelectorProps<T>>
) {

  const context = useFormContext<T>();
  const { form } = context;
  const p = useProps(props);
  const s = useStore(() => ({
    get form() { return p.form ?? form },
  }));

  return <Observer children={() => (
    <CountrySelector {...p}
      className={joinClassName('FormCountrySelector', p.className)}
      form={s.form.fields[p.field]}
      field="value"
      name={p.name ?? p.field}
      disabled={p.disabled || context.disabled}
    />
  )} />
}

export default FormCountrySelector;