import { action, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseArticle from '../../../../../base/components/BaseArticle/BaseArticle';
import BaseInput from '../../../../../base/components/BaseInput/BaseInput';
import BaseSelector from '../../../../../base/components/BaseSelector/BaseSelector';
import BaseSpacer from '../../../../../base/components/BaseSpacer/BaseSpacer';
import CurrencyRenderer from '../../../../../base/components/CurrencyRenderer/CurrencyRenderer';
import ShadedBlock from '../../../../../base/components/ShadedBlock/ShadedBlock';
import SimpleCard from '../../../../../base/components/SimpleCard/SimpleCard';
import { useOnMount } from '../../../../../base/hooks/lifecycle.hooks';
import { makeDisposerController } from '../../../../../base/utils/disposer.utils';
import { CounsellingApplicationStepProps } from '../applicationStepDescriptors';
import './CounsellingApplicationStepFinancialAssessment.scss';

interface CounsellingApplicationStepFinancialAssessmentProps extends CounsellingApplicationStepProps { }

const defaultDonationOptions = [40, 30, 20, 10];

const CounsellingApplicationStepFinancialAssessment: React.FC<CounsellingApplicationStepFinancialAssessmentProps> = p => {

  // const s = useStore(() => ({
  //   get isIrishApplicant() {
  //     return p.form.applicantAddress.countryId === 'IE';
  //   }
  // }))

  useOnMount(() => {
    const d = makeDisposerController();
    d.add(
      reaction(
        () => p.form.ableToDonate,
        (ableToDonate) => {
          if (ableToDonate && p.form.application.clientAgreedDonationAmountPerSession < 1) {
            p.form.application.clientAgreedDonationAmountPerSession = defaultDonationOptions[0];
          }
          if (!ableToDonate) {
            p.form.application.clientAgreedDonationAmountPerSession = 0;
          }
        }
      )
    )
    d.add(
      reaction(
        () => p.form.application.clientAgreedDonationAmountPerSession,
        (amount) => {
          if (amount >= 1) {
            p.form.ableToDonate = true;
          }
        }
      )
    )
    return d.disposer;
  })

  return <Observer children={() => (
    <div className="CounsellingApplicationStepFinancialAssessment">

      <blockquote>
        <p>Are you in a position to donate any amount to support the work of turn2me towards your counselling sessions?</p>
      </blockquote>
      <BaseSpacer />
      <div className="CounsellingApplicationStepFinancialAssessmentOptionGroup">
        <SimpleCard
          className="CounsellingApplicationStepFinancialAssessmentOption --true"
          data-selected={p.form.ableToDonate === true}
          onClick={action(() => p.form.ableToDonate = true)}
        >
          <span>Yes</span>
        </SimpleCard>
        <SimpleCard
          className="CounsellingApplicationStepFinancialAssessmentOption --false"
          data-selected={p.form.ableToDonate === false}
          onClick={action(() => p.form.ableToDonate = false)}
        >
          <span>No</span>
        </SimpleCard>
      </div>
      <BaseSpacer size="lg" />

      <BaseArticle>
        <p>If you are earning an income, please include a donation amount per session to turn2me so that we can continue to offer our services and if you are earning about €35000, please switch to our <a href="/">paid counselling service</a> that we can keep our limited free slots to those who cant afford them.</p>
        <p>turn2me is a charity organization (Charity No. CHY18803) registered in the Republic of Ireland and greatly relies on donations and support from our members to provide professional and confidential services for those who need it. Free sessions are reserved and limited for those in Ireland who cannot afford to pay for counselling services and could be in great need of professional support.</p>

        <ShadedBlock spaceChildren>
          <h3>Opt-in donations</h3>
          <p>
            {/* {
              s.isIrishApplicant ? 'Your address information indicates that you might be eligible for free counselling sessions. However, i' : 'I'
            } */}
            You are eligible for free counselling sessions as an Irish resident. However, if you are earning an income, please include a donation amount per session to turn2me so that we can continue to offer our services for those who need it the most and cannot afford it.
          </p>
          <p>Don't worry, if you need to change this in the future, just let your counsellor know.</p>
          <BaseSelector
            label="I can make a donation of the following amount per session..."
            options={defaultDonationOptions}
            valueGetter={o => o}
            optionLabelRenderer={o => <CurrencyRenderer value={o} />}
            form={p.form.application}
            field='clientAgreedDonationAmountPerSession'
          />
          <BaseInput label="Or enter a value (EUR):" form={p.form.application} field="clientAgreedDonationAmountPerSession" type="number" min={1} step={0.01} />
        </ShadedBlock>
      </BaseArticle>

      <BaseSpacer />

    </div>
  )} />

}

export default CounsellingApplicationStepFinancialAssessment;