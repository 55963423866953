import { Observer } from 'mobx-react-lite';
import React from 'react';
import RouterStack from '../../base/components/RouterStack/RouterStack';
import { useClearPageBackground } from '../../base/hooks/useSetPageBackground.hook';
import { TopLevelModuleName } from '../../constants/topLevelModuleName.enum';
import { useRegisterTopLevelModule } from '../../hooks/useRegisterTopLevelModule';
import './ModulePublic.scss';
import { PublicModuleChildRoutes, PublicModuleRouteDef } from './Public.module.routes';

interface ModulePublicProps {}

const ModulePublic: React.FC<ModulePublicProps> = props => {

  useClearPageBackground();
  useRegisterTopLevelModule(TopLevelModuleName.public)

  return <Observer children={() => (
    <div className="ModulePublic">
      <RouterStack routes={PublicModuleChildRoutes} prefix={PublicModuleRouteDef.urlFactory()} />
    </div>
  )} />

}

export default ModulePublic;