import { Observer } from 'mobx-react-lite';
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { RouteDef } from '../../@types';
import { isLazyComponent, renderRenderable } from '../../utils/components.utils';
import { makeUrl } from '../../utils/url.utils';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import RenderIf from '../RenderIf/RenderIf';

interface RouterSwitchProps {
  routes: RouteDef[],
  prefix?: string,
  fallbackRedirectTo?: RouteDef,
}

const RouteSuspenseFallback = () => <div className="RouteSuspense"><LoadingIndicator delay="1s" /></div>;

/**
 * Wrapper around <Switch /> but accepts our custom route definitions
 */
const RouterSwitch: React.FC<RouterSwitchProps> = React.memo(p => {

  return <Observer children={() => {

    const routes = p.routes.map(r => {
      const Component = r.component;
      const routeUrl = makeUrl(p.prefix, r.urlPattern ?? r.urlFactory());
      if (!Component) return null;
      return <Route path={routeUrl} key={r.identifier}>
        <ErrorBoundary>
          <RenderIf
            className={r.identifier}
            key={r.identifier}
            ifAsync={r.guards?.onRender}
            component={() => <>{isLazyComponent(Component) ? (
              <Suspense fallback={<RouteSuspenseFallback />}>
                {Component && <Component />}
              </Suspense>
            ) : renderRenderable(Component)}</>}
            renderWhileUndetermined={<RouteSuspenseFallback />}
            fallback={r.fallback || `Route Guard Failed (${r.identifier})`}
            errorFallback={() => `Error rendering route ${r.identifier}`}
          />
        </ErrorBoundary>
      </Route>
    })

    if (p.fallbackRedirectTo) {
      const fallbackRouteUrl = makeUrl(p.prefix, p.fallbackRedirectTo.urlFactory());
      routes.push(<Redirect key="FALLBACK_REDIRECT_ROUTE" to={fallbackRouteUrl} />);
    }

    return <Switch children={routes} />

  }} />

})

export default RouterSwitch;