import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useControllers } from '../../hooks/useRootController.hook';
import { makeCancelAction } from '../../utils/actionConfig.utils';
import joinClassName from '../../utils/className.utils';

interface TelLinkRendererProps {
  className?: string,
  value?: string | null,
}

const TelLinkRenderer: React.FC<TelLinkRendererProps> = props => {
  const { AUTH, UI, VOIP } = useControllers();
  const call = (e: React.MouseEvent) => {
    if (!props.value) return;
    if (!AUTH.can.useVoip) return;
    e.preventDefault();
    UI.DIALOG.present({
      heading: <>Do you want to call {props.value} with VOIP?</>,
      body: <p>Note that VOIP requires numbers to be correctly formatted with country prefixes such as +353. If a number is supplied in an unsupported format, the VOIP is likely going to fail. In that case, you might want to update the number before calling.</p>,
      actions: [
        makeCancelAction(),
        {
          label: UI.displayMode === 'phone' ? "Call with phone" : "Call with system app",
          action: function() {
            window.open(`tel:${props.value}`);
          },
          buttonClass: 'subtle',
        },
        {
          label: "Call with VOIP",
          action: function() {
            if (!AUTH.can.useVoip || !props.value) return;
            VOIP.startVoipCall(props.value);
          }
        }
      ],
      width: '45em',
    })
  }
  return <Observer children={() => (
    <a
      className={joinClassName('TelLinkRenderer', props.className)} 
      href={`tel:${props.value}`}
      title={props.value ? 'Call ' + props.value : ''}
      onClick={call}
    >
      {props.value}
    </a>
  )} />
}

export default TelLinkRenderer;