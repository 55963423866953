import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import FormWysiwyg from '../../base/components/FormWysiwyg/FormWysiwyg';
import { makeForm } from '../../base/mediators/form.mediator';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import tick from '../../base/utils/waiters.utils';
import './EmailComposer.scss';

interface EmailComposerProps {
  onSend: (body: string) => Promise<boolean>,
  sendButtonLabel?: string,
}

const makeEmailComposerForm = () => ({
  body: '',
})

const EmailComposer: React.FC<EmailComposerProps> = props => {

  const p = useProps(props);
  
  const s = useStore(() => ({
    form: makeForm(makeEmailComposerForm()),
    get bodyIsEmpty() {
      return !s.form.fields.body.value?.replace(/\r?\n|\r/g, '');
    },
    get canSend() {
      return !s.bodyIsEmpty;
    },
    renderWysiwyg: true,
  }));

  const resetWysiwyg = flow(function* () {
    s.renderWysiwyg = false;
    yield tick(100);
    s.renderWysiwyg = true;
  })

  const send = flow(function * () {
    if (!p.onSend) return;
    if (s.bodyIsEmpty) return;
    try {
      yield p.onSend(s.form.fields.body.value);
      s.form.clear();
      resetWysiwyg();
    } catch(e) {
      reportError(e);
    }
  })

  return <Observer children={() => (
    <div className="EmailComposer">
      { s.renderWysiwyg && <>
        <FormWysiwyg form={s.form} field="body" />
        <BaseButton icon="send" onClick={send} disabled={!s.canSend} label={p.sendButtonLabel ?? 'Confirm and Send'}/>
      </>}
    </div>
  )} />
}

export default EmailComposer;