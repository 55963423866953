import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Renderable } from '../../base/@types';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import ColorTagCompleted from '../../base/components/ColorTag/ColorTagCompleted';
import CurrencyRenderer from '../../base/components/CurrencyRenderer/CurrencyRenderer';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import OverlayMakePayment from '../../base/components/OverlayMakePayment/OverlayMakePayment';
import { useControllers, useUIController } from '../../base/hooks/useRootController.hook';
import joinClassName from '../../base/utils/className.utils';
import { getColorHexByName } from '../../base/utils/colors.utils';
import { renderRenderable } from '../../base/utils/components.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { ApiModelName } from '../../constants/ApiModels.enum';
import { AssignmentType } from '../../constants/assignmentTypes.enum';
import { ModelName } from '../../constants/modelNames.enum';
import { Assignment, AssignmentGAD7, AssignmentPayment, AssignmentPHQ9 } from '../../models/makeAssignment.model';
import { CounsellingSession } from '../../models/makeCounsellingSession.model';
import OverlaySurveyGAD7 from '../OverlaySurveyGAD7/OverlaySurveyGAD7';
import OverlaySurveyPHQ9 from '../OverlaySurveyPHQ9/OverlaySurveyPHQ9';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import './SessionAssignmentItem.scss';

interface SessionAssignmentItemProps {
  endSlot?: Renderable,
  backgroundColor?: string,
  foregroundColor?: string,
  assignmentType?: AssignmentType | ModelName,
  session: CounsellingSession,
  assignment: Assignment,
  disabled?: boolean,
}

const SessionAssignmentItem: React.FC<SessionAssignmentItemProps> = props => {

  const p = useProps(props);

  const { AUTH } = useControllers();
  const { OVERLAY, DIALOG } = useUIController();

  const s = useStore(() => ({
    get isOwnSession() {
      return AUTH.currentUser && p.session.clientIds.includes(AUTH.currentUser.id);
    },
    get title() {
      switch (p.assignmentType || p.assignment.targetType) {
        case AssignmentType.gad7:
        case ModelName.surveysGAD7:
        case ApiModelName.SURVEY_GAD7:
          return AUTH.isStaff ? 'GAD7' : 'Pre-session Survey 1';
        case AssignmentType.phq9:
        case ModelName.surveysPHQ9:
        case ApiModelName.SURVEY_PHQ9:
          return AUTH.isStaff ? 'PHQ9' : 'Pre-session Survey 2';
        case AssignmentType.payment:
        case ModelName.payments:
        case ApiModelName.PAYMENT:
          const isPaidSession = p.session.isPaidSession;
          return AUTH.isStaff
            ? isPaidSession
              ? 'Payment'
              : 'Donation'
            : <>{isPaidSession ? 'Payment' : 'Donate'} <CurrencyRenderer value={p.session.amountPayable} /></>;
        default: return `Unknown Assignment ${p.assignmentType}`;
      }
    },
    viewDetails: (e: React.MouseEvent) => {
      e.stopPropagation();
      if (p.disabled && !AUTH.can.provideCounsellingFor_.someUserGroups) {
        DIALOG.attention({
          heading: p.assignmentType === AssignmentType.payment ? 'Donation option is not available for this session yet.' : `You can't complete this survey yet. Please check back later. You can complete surveys within two days before or after a session.`,
        })
        return;
      }
      switch (p.assignmentType || p.assignment.targetType) {
        case AssignmentType.gad7:
        case ModelName.surveysGAD7:
        case ApiModelName.SURVEY_GAD7:
          OVERLAY.present({
            component: <OverlaySurveyGAD7 assignment={p.assignment as AssignmentGAD7} assignmentId={p.assignment.id} />
          });
          break;
        case AssignmentType.phq9:
        case ModelName.surveysPHQ9:
        case ApiModelName.SURVEY_PHQ9:
          OVERLAY.present({
            component: <OverlaySurveyPHQ9 assignment={p.assignment as AssignmentPHQ9} assignmentId={p.assignment.id}/>
          })
          break;
        case AssignmentType.payment:
        case ModelName.payments:
        case ApiModelName.PAYMENT:
          const completed = p.assignment.timeCompleted && p.assignment.target;
          if (s.isOwnSession) {
            if (completed) {
              DIALOG.present({
                heading: <>Thanks for your generous donation of <CurrencyRenderer value={p.session.amountPayable} />.</>,
                body: <div>
                  {completed && <p>You have successfully donated on <DateRenderer value={p.assignment.timeCompleted} />.</p>}
                </div>
              })
            } else {
              OVERLAY.present({
                component: <OverlayMakePayment assignment={p.assignment as AssignmentPayment} />
              })
            }
          } else {
            DIALOG.present({
              heading: <>This session has a suggested donation of <CurrencyRenderer value={p.session.amountPayable} />.</>,
              body: <div>
                { completed && <p>The client completed the donation on <DateRenderer value={p.assignment.timeCompleted} />.</p>}
              </div>
            })
          }
          break;
        default:
          return;
      }
    },
    get completed() {
      return p.assignment.isCompleted;
    },
    get isDonation() {
      switch (p.assignmentType || p.assignment.targetType) {
        case AssignmentType.payment:
        case ModelName.payments:
        case ApiModelName.PAYMENT:
          return true;
        default:
          return false;
      }
    },
  }))

  return <Observer children={() => (
    <div className={joinClassName('SessionAssignmentItem', p.disabled && 'disabled')} style={{
      backgroundColor: s.completed ? getColorHexByName('orange') : s.isDonation ? getColorHexByName('firebrick') : p.backgroundColor,
      color: s.completed || s.isDonation ? getColorHexByName('white') : p.foregroundColor,
    }} onClick={s.viewDetails}>
      <div className="SessionAssignmentItemInner">
        <header>
          <div>
            <h4>{s.title}</h4>
            { p.session.clientIds.length > 1 && !p.session.clientIds.includes(AUTH.currentUser?.id ?? '') && <p><UsernameRenderer user={p.assignment.assignedToUser} userId={p.assignment.assignedToUserId} /></p>}
          </div>
          <BaseIcon icon="arrow" variant="filled" size="1.4rem"/>
        </header>
        <footer>
          {renderRenderable(p.endSlot)}
          {s.completed && <ColorTagCompleted />}
        </footer>
      </div>
    </div>
  )} />

}

export default SessionAssignmentItem;