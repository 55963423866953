import { Range } from "../@types";
import { mean, median } from "./ramdaEquivalents.utils";

export function padZero(value: number | null = 0, length: number = 2) {
  let valueString = value?.toString() || '0';
  while (valueString.length < length) {
    valueString = '0' + valueString;
  }
  return valueString;
}

export function isNumber(value?: any): value is number {
  return !isNaN(value) && value !== '';
}

export function isInteger(value?: any): value is number {
  if (isNaN(value)) return false;
  return parseInt('' + value) + '' === value + '';
}

export function makeIsMultipleOf(x: number) {
  return (n: number) => n % x === 0;
}
export function makeIsNotMultipleOf(x: number) {
  return (n: number) => Math.abs(n % x) === 1;
}
export const containsOnlyDigits = (x: number | string) => {
  return /^\d+$/.test(x + '');
}

export const isEvenNumber = makeIsMultipleOf(2);
export const isOddNumber = makeIsNotMultipleOf(2);
export const isMultipleOfThree = makeIsMultipleOf(3);
export const isNotMultipleOfThree = makeIsNotMultipleOf(3);

export const percentage = (
  a: number,
  b: number,
  precision: number = 2,
) => {
  const value = a / b;
  return ((isNaN(value) ? 0 : value) * 100).toFixed(precision) + '%'
}

export const getAverage = (array: number[]) => {
  const value = mean(array);
  return isNaN(value) ? undefined : value;
};
export const getMedian = (array: number[]) => {
  const value = median(array);
  return isNaN(value) ? undefined : value;
};

export const getSumOfPropLength = (array: { length: number }[]) => array.reduce((a, b) => a + b.length, 0);
export const getSumOfPropTotal = (array: { total: number }[]) => array.reduce((a, b) => a + b.total, 0);

export const overlap = (a: Range, b: Range) => {
  const max = Math.max(a[0], b[0]);
  const min = Math.min(a[1], b[1]);
  return max < min;
}

