import { Observer } from 'mobx-react-lite';
import React from 'react';
import joinClassName from '../../utils/className.utils';
import { isFunction } from '../../utils/typeChecks.utils';
import './AppPageContentFooter.scss';

interface AppPageContentFooterProps {
  className?: string;
  sticky?: boolean;
  padded?: boolean | 'all';
  shaded?: boolean;
  endSlot?: string | number | boolean | React.FC | React.ComponentClass | React.ReactElement;
}

const AppPageContentFooter: React.FC<AppPageContentFooterProps> = props => {
  return <Observer children={() => (
    <footer className={
      joinClassName(
        'AppPageContentFooter',
        props.padded && 'padded',
        props.padded === 'all' ? 'padded-all' : '',
        props.sticky && 'sticky',
        props.shaded && 'shaded',
        props.endSlot && 'has-end-slot',
      )
    }>
      <div className="AppPageContentFooterInner">
        {props.children}
      </div>
      <div className="AppPageContentFooterEndSlot">
        {isFunction(props.endSlot) ? <props.endSlot /> : props.endSlot}
      </div>
    </footer>
  )} />
}

export default AppPageContentFooter;