import { getValueOfKey } from "../base/utils/object.utils";
import { ApiModelName } from "../constants/ApiModels.enum";
import { AssignmentAssociatedModelType, AssignmentTargetModelType } from "../models/makeAssignment.model";

const AssignmentTargetModelTypeDisplayValueMap: Partial<Record<AssignmentTargetModelType, string>> = {
  [ApiModelName.SURVEY_GOAL_SHEET]: 'Goal Sheet',
  [ApiModelName.SURVEY_PHQ9]: 'PHQ9',
  [ApiModelName.SURVEY_GAD7]: 'GAD7',
  [ApiModelName.SURVEY_SATISFACTION]: 'Satisfaction Survey',
  [ApiModelName.PAYMENT]: 'Payment',
}
export const getAssignmentTargetModelTypeDisplayName = (value?: AssignmentTargetModelType | null) => {
  return getValueOfKey<any>(value || '', AssignmentTargetModelTypeDisplayValueMap) || '';
}

const AssignmentAssociatedModelTypeDisplayValueMap: Partial<Record<AssignmentAssociatedModelType, string>> = {
  [ApiModelName.COUNSELLING_APPLICATION]: 'Counselling Application',
  [ApiModelName.COUNSELLING_SESSION]: 'Counselling Session',
  [ApiModelName.INVITATION]: 'Invitation',
}

export const getAssignmentAssociatedModelTypeDisplayName = (value?: AssignmentAssociatedModelType | null) => {
  return getValueOfKey<any>(value || '', AssignmentAssociatedModelTypeDisplayValueMap) || '';
}
