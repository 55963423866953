import React from "react";
import joinClassName from "../../utils/className.utils";
import BaseToggle, { BaseToggleProps } from "../BaseToggle/BaseToggle";

interface BaseCheckboxProps<T extends object> extends BaseToggleProps<T> { }

export const BaseCheckbox = <T extends object>(props: React.PropsWithChildren<BaseCheckboxProps<T>>) => {
  return <BaseToggle<T> {...props}
    className={joinClassName('BaseCheckbox', props.className)}
    appearance="checkbox"
  />
}

export default BaseCheckbox;