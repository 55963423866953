import moment from "moment"
import { Nullable } from "../base/@types"
import { padZero } from "../base/utils/math.utils"
import { createUTCMoment, YYYYMMDD } from "../base/utils/time.utils"
import { ApiModelName } from "../constants/ApiModels.enum"
import { CounsellingType } from "../constants/counsellingTypes.descriptors"
import { P_ } from "../constants/permissions.alias"
import { Tags } from "../constants/tags.constants"
import { AuthController } from "../controllers/auth.controller"
import { UIController } from "../controllers/ui.controller"
import { SHOULD_LOG } from "../env"
import { CounsellingSession } from "../models/makeCounsellingSession.model"
import { FeeType } from "../models/makeFee.model"
import { InvoiceItem } from "../models/makeInvoiceItem.models"
import { SupportGroup } from "../models/makeSupportGroup.model"
import OverlayInvoiceManager from "../modules/Admin/_components/OverlayInvoiceManager/OverlayInvoiceManager"
import { AgeGroupFilterType } from "./ageAndDateOfBirth.utils"
import { defaultFeeValues, getFeeTypeDisplayName } from "./fees.utils"

export type BillingPeriod = {
  start: string,
  end: string,
}

export const getBillingPeriodFromTimestamp = (utcTimestamp: string) => {
  return {
    start: createUTCMoment(utcTimestamp).startOf('month').format(YYYYMMDD),
    end: createUTCMoment(utcTimestamp).endOf('month').format(YYYYMMDD),
  }
}
export const getBillingPeriod = (year: number = moment().year(), month: number = moment().month()) => {
  const string = `${year}-${padZero(month + 1)}-01`;
  return {
    start: moment(string).startOf('month').format(YYYYMMDD),
    end: moment(string).endOf('month').format(YYYYMMDD),
  }
}

export const viewInvoiceInOverlay = (UI: UIController, id: string) => {
  UI.OVERLAY.present({
    id: `OverlayInvoiceManager#${id}`,
    component: OverlayInvoiceManager,
    noBackdrop: true,
    duplicateStrategy: 'abort',
    width: '62em',
  })
}

export const getInvoiceItemDisplayTitle = (i: InvoiceItem) => {
  let title = '';
  switch (i.modelType) {
    case ApiModelName.COUNSELLING_SESSION:
      const session = i.model as Nullable<CounsellingSession>;
      if (!session) { title = getFeeTypeDisplayName(FeeType.counsellingSession); break }
      if (session.applicationType) {
        switch (session.applicationType) {
          case CounsellingType.OneToOne: { title = getFeeTypeDisplayName(FeeType.counsellingSessionOneToOne); break; }
          case CounsellingType.Couples: { title = getFeeTypeDisplayName(FeeType.counsellingSessionCouples); break; }
          case CounsellingType.YoungPeople1214:
          case CounsellingType.YoungPeople1517: { title = getFeeTypeDisplayName(FeeType.counsellingSessionYoungPeople); break; }
          default: title = getFeeTypeDisplayName(FeeType.counsellingSession)
        }
      }
      title = getFeeTypeDisplayName(FeeType.counsellingSession);
      break;
    case ApiModelName.SUPPORT_GROUP:
      const group = i.model as Nullable<SupportGroup>;
      if (!group) {
        title = getFeeTypeDisplayName(FeeType.supportGroup); break;
      }
      if (group.tags?.includes(Tags.FrontLineWorkers)) {
        title = getFeeTypeDisplayName(FeeType.supportGroupFrontLineWorkers); break;
      }
      if (group.ageGroups.includes(AgeGroupFilterType.youngPeople1214) || group.ageGroups.includes(AgeGroupFilterType.youngPeople1517)) {
        title = getFeeTypeDisplayName(FeeType.supportGroupYoungPeople); break;
      }
      if (group.ageGroups.includes(AgeGroupFilterType.adults)) {
        title = getFeeTypeDisplayName(FeeType.supportGroupAdult); break;
      }
  }
  return `${title} #${i.modelId}`;
}

export const getSessionInvoiceItemFeeType = (session: CounsellingSession) => {
  const counsellor = session.counsellor!;
  switch (true) {
    case counsellor.isAccreditedCounsellor:
      return FeeType.counsellingSessionAccreditedCounsellor;
    case counsellor.isPreAccreditedCounsellor:
      return FeeType.counsellingSessionPreAccreditedCounsellor;
    case counsellor.isPlacementCounsellor:
      return FeeType.counsellingSessionPlacementCounsellor;
    default:
      return FeeType.counsellingSession;
  }
}

export const getSessionBillableAmount = (session: CounsellingSession) => {
  const counsellor = session.counsellor!;
  const canBillPaidSessions = session.isPaidSession && (counsellor.can?.(P_.billPaidCounsellingSessions) ?? false);
  const canBillFreeSessions = session.isFreeSession && (counsellor.can?.(P_.billFreeCounsellingSessions) ?? false);
  switch (true) {
    case counsellor.isAccreditedCounsellor:
      if (canBillPaidSessions || canBillFreeSessions) return defaultFeeValues[FeeType.counsellingSessionAccreditedCounsellor];
      return 0;
    case counsellor.isPreAccreditedCounsellor:
      if (canBillPaidSessions || canBillFreeSessions) return defaultFeeValues[FeeType.counsellingSessionPreAccreditedCounsellor];
      return 0;
    case counsellor.isPlacementCounsellor:
      if (canBillPaidSessions || canBillFreeSessions) return defaultFeeValues[FeeType.counsellingSessionPlacementCounsellor];
      return 0;
    default:
      return 0;
  }
}

export const canMarkAsBillable = (session: CounsellingSession, AUTH: AuthController) => {
  const hasValidBillableAmount = () => {
    if (getSessionBillableAmount(session) > 0) return true;
    SHOULD_LOG() && console.log('allowed billing, but billable amount is 0.');
    return false;
  }
  if (session.isPaidSession && AUTH.can.bill_.paidSessions) {
    SHOULD_LOG() && console.log('is paid session, and allowed billing');
    return hasValidBillableAmount();
  }
  if (session.isFreeSession && AUTH.can.bill_.freeSessions) {
    SHOULD_LOG() && console.log('is free session, and allowed billing');
    return hasValidBillableAmount();
  }
  return false;
}