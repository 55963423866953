import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import TwoColumnView from '../../../base/components/TwoColumnView/TwoColumnView';
import { useControllers } from '../../../base/hooks/useRootController.hook';
import { keepTruthy } from '../../../base/utils/array.utils';
import { useStore } from '../../../base/utils/mobx.utils';
import MessengerPanel from '../../../components/MessengerPanel/MessengerPanel';
import AdminTitleBar from '../../Admin/_components/AdminTitleBar/AdminTitleBar';
import ClientAppSubmodule from '../../Client/_components/ClientAppSubmodule/ClientAppSubmodule';
import './ModuleChat.scss';
import { makePageChatSingleRouteDef } from './pages/PageChatSingle/PageChatSingle.page.route';

interface ModuleAdminChatProps {}

const ModuleChat: React.FC<
  ModuleAdminChatProps
> = props => {

  const { MESSENGER, AUTH, NAVIGATOR } = useControllers();

  const s = useStore(() => ({
    get childrenRoutePrefix() {
      return NAVIGATOR.isInAdminArea ? '/admin/chats/' : '/app/chats/';
    },
    newChat: flow(function * () {
      if (!AUTH.currentUser) {
        throw Error(`Attempted to create a chat when there's no user logged in`);
      }
      yield MESSENGER.createNewInstantChat([AUTH.currentUser.id]);
    }),
    get idInParams() {
      return NAVIGATOR.currentLocationPathname.match(/\/chats\/([0-9]+)/)?.[1];
    },
    get subRoutes() {
      return keepTruthy([s.idInParams, ...MESSENGER.chats.map(c => c.id)]).map(id => makePageChatSingleRouteDef(id!));
    },
    get placeholderText() {
      if (MESSENGER.chats.length === 0) {
        if (AUTH.isStaff) return 'No active chats found.';
        return 'No active chats yet. When a turn2me counsellor starts a chat, a counselling session or a support group you have joined, you will see them listed here.';
      }
      return 'Select a chat or session';
    },
    get content() {
      return <TwoColumnView
        routes={s.subRoutes}
        prefix={s.childrenRoutePrefix}
        aside={<MessengerPanel prefix={s.childrenRoutePrefix} />}
        placeholderText={s.placeholderText}
        debug="chat"
      />
    }
  }))
  
  return <Observer children={() => NAVIGATOR.isInAdminArea ? (
    <div className="ModuleChat">
      <AdminTitleBar title="Chats" />
      { s.content }
    </div>) : <ClientAppSubmodule
      name="Chat"
      title="Chat"
      fullHeight
    >
      <div className="ModuleChatClientInterfaceContainer">
        { s.content }
      </div>
    </ClientAppSubmodule>
  } />

}

export default ModuleChat
