import { Emotion } from "../../../constants/emotions.enum";
import { getRandomItemFromArray } from "../../utils/random.utils";

const moodResponses: Record<Emotion, ((userName?: string) => string)[]> = {
  [Emotion.great]: [
    u => `Really happy that you are feeling great today${u ? ' ' + u : u}! 😊 What are you thinking at the moment?`
  ],
  [Emotion.good]: [
    u => `I'm happy that you are feeling good today${u ? ' ' + u : u}! What are you thinking at the moment?`,
  ],
  [Emotion.okay]: [
    u => `It's really great that you are keeping your inner peace. What are you thinking at the moment?`,
  ],
  [Emotion.bad]: [
    u => `We’re sorry you are not feeling the best! 😢 What are you thinking at the moment?`
  ],
  [Emotion.terrible]: [
    u => `😢 We’re sorry you are having a bad day :( What are you thinking at the moment?`
  ],
}
export const getMoodResponse = (emotion: Emotion, userName?: string) => {
  return getRandomItemFromArray(moodResponses[emotion])(userName);
}