import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Nullable } from '../../../base/@types';
import BaseSpacer from '../../../base/components/BaseSpacer/BaseSpacer';
import CommandList from '../../../base/components/CommandList/CommandList';
import { CommandListItem } from '../../../base/components/CommandList/CommandListItem';
import InfoBanner from '../../../base/components/InfoBanner/InfoBanner';
import LoadingIndicator from '../../../base/components/LoadingIndicator/LoadingIndicator';
import NavBarHeightSpacer from '../../../base/components/NavBarHeightSpacer/NavBarHeightSpacer';
import ShadedBlock from '../../../base/components/ShadedBlock/ShadedBlock';
import { CountryCode, CountryDescriptors } from '../../../base/constants/countries.constants';
import { useOnMount } from '../../../base/hooks/lifecycle.hooks';
import { useOnRouteFocus } from '../../../base/hooks/navigator.hooks';
import { useControllers } from '../../../base/hooks/useRootController.hook';
import joinClassName from '../../../base/utils/className.utils';
import { makeDisposerController } from '../../../base/utils/disposer.utils';
import { useStore } from '../../../base/utils/mobx.utils';
import { autoPluralize, itemsArrayToFormattedString } from '../../../base/utils/string.utils';
import { minutes } from '../../../base/utils/time.utils';
import { doEvery } from '../../../base/utils/waiters.utils';
import CounsellingJourneyEntry from '../../../components/CounsellingJourneyEntry/CounsellingJourneyEntry';
import { CounsellingSessionPaidFee, NUM_FREE_COUNSELLING_SESSIONS } from '../../../constants/counsellingFeeTypes.constants';
import { ApplicationTypeOption, getCounsellingTypeName } from '../../../constants/counsellingTypes.descriptors';
import { IS_DEV, SHOULD_LOG, getWordPressLink, isOfficialSite } from '../../../env';
import ClientAppSubmodule from '../_components/ClientAppSubmodule/ClientAppSubmodule';
import CounsellingApplicationUI from '../_components/CounsellingApplicationUI/CounsellingApplicationUI';
import './ModuleCounselling.scss';

interface ModuleCounsellingProps { }

const CounsellingJourneyCardList = React.memo(() => {
  const { COUNSELLING } = useControllers();
  return <Observer children={() => (
    <div className="CounsellingJourneyCardList">
      {COUNSELLING.activeApplications.map(a => <CounsellingJourneyEntry application={a} key={a.id} />)}
    </div>
  )} />
})

const ModuleCounselling: React.FC<
  ModuleCounsellingProps
> = props => {

  const { ANALYTICS, CONFIGURATIONS, COUNSELLING, UI, AUTH } = useControllers();

  const s = useStore(() => ({
    countryCode: null as Nullable<string>,
    get shouldShowApplicationServiceNotInCountryNotice() {
      // checks if detected user location is in serviced countries.
      const shouldDenyAccess = !!s.countryCode && !CONFIGURATIONS.configurations.COUNSELLING_APPLICATION_SERVICE_OPEN_IN_COUNTRY_CODES.includes(s.countryCode);
      if (shouldDenyAccess) {
        SHOULD_LOG() && console.log(`UNKNOWN_OR_UNSERVICEABLE_COUNTRY_ID_DETECTED (found countryCode: ${s.countryCode}, countries allowed: [${CONFIGURATIONS.configurations.COUNSELLING_APPLICATION_SERVICE_OPEN_IN_COUNTRY_CODES.join(",")}]): DENYING user access to view and apply for counselling applications.`);
      }
      return shouldDenyAccess;
    },
  }));

  const applyForNewCounsellingFn = (type: ApplicationTypeOption) => () => {
    let noSlots = false;
    let notAllowed = false;
    let hasExceededAllowedFreeSessions = false;
    let isCountryAllowed = true;
    const doChecksForFreeSessions = () => {
      const completedFreeSessionsThisYear = COUNSELLING.completedFreeSessions.filter(
        s => s.timeCreated?.substr(0,4) === String(new Date().getFullYear())
      ).length;

      if (isOfficialSite && !AUTH.isStaff && completedFreeSessionsThisYear >= NUM_FREE_COUNSELLING_SESSIONS) {
        hasExceededAllowedFreeSessions = true;
      }

      // OR TRY THIS:
      // if (isOfficialSite && !AUTH.isStaff && COUNSELLING.completedFreeSessionsThisYear.length >= NUM_FREE_COUNSELLING_SESSIONS) {
      //   hasExceededAllowedFreeSessions = true;
      // }

      if (s.shouldShowApplicationServiceNotInCountryNotice) {
        isCountryAllowed = false;
      }
    }
    switch (type) {
      case ApplicationTypeOption.PaidOneToOne:
        noSlots = COUNSELLING.availablePaidOneToOneSlots.length === 0;
        notAllowed = !COUNSELLING.currentUserCanApplyForTypes.includes(ApplicationTypeOption.PaidOneToOne);
        break;
      case ApplicationTypeOption.OneToOne:
        noSlots = COUNSELLING.availableOneToOneSlots.length === 0;
        notAllowed = !COUNSELLING.currentUserCanApplyForTypes.includes(ApplicationTypeOption.OneToOne);
        doChecksForFreeSessions();
        break;
      case ApplicationTypeOption.Couples:
        noSlots = COUNSELLING.availableCouplesSlots.length === 0;
        notAllowed = !COUNSELLING.currentUserCanApplyForTypes.includes(ApplicationTypeOption.Couples);
        doChecksForFreeSessions();
        break;
      case ApplicationTypeOption.YoungPeople:
        noSlots = COUNSELLING.availableYPSlots.length === 0;
        notAllowed = !COUNSELLING.currentUserCanApplyForTypes.includes(ApplicationTypeOption.YoungPeople);
        doChecksForFreeSessions();
        break;
      default:
        noSlots = true;
    }
    if (!isCountryAllowed) {
      showCountryNotInServiceNotice();
      return;
    }
    if (noSlots) {
      UI.DIALOG.attention({
        heading: 'Unfortunately there are no available sessions presently',
        body: <>
          <p>Well done for taking the first step in applying for counselling / psychotherapy. While there is no availability at this time, our therapists post their availability daily, so do make sure you check again later.</p>
          <p>If you consider yourself in severe distress and at immediate risk of self-harm, please visit our <a href={getWordPressLink('/crisis-support')} title="Crisis Support" target="_blank" rel="noreferrer">Crisis Support</a> Page.</p>
        </>,
      })
      return;
    }
    if (IS_DEV) notAllowed = false;
    if (notAllowed) {
      UI.DIALOG.attention({
        heading: `You can only have one ongoing ${getCounsellingTypeName(type)} Counselling at the same time.`,
        body: 'Thanks for your interest in applying for new counselling. If you intend to apply for someone else you know, please inform them that they should register for their own account.',
      })
      return;
    }
    if (hasExceededAllowedFreeSessions) {
      UI.DIALOG.attention({
        heading: `You have exceeded your free ${NUM_FREE_COUNSELLING_SESSIONS} sessions`,
        body: `Thanks for your interest in applying for new counselling. You can avail of additional counselling sessions at the cost of €${CounsellingSessionPaidFee.value} per session.`,
      })
      return;
    }
    UI.OVERLAY.present({
      name: 'CounsellingApplicationOverlay',
      component: <CounsellingApplicationUI showCloseButton type={type} />,
      width: '48em',
    })
  }

  useOnRouteFocus('/app/counselling', () => {
    COUNSELLING.getCounsellingAvailabilities();
  })

  const showCountryNotInServiceNotice = () => {
    const servicedCountryNames: string[] = CONFIGURATIONS.configurations.COUNSELLING_APPLICATION_SERVICE_OPEN_IN_COUNTRY_CODES.map((countryId) => countryId === "IE" ? "Republic of Ireland" : CountryDescriptors[countryId as CountryCode].name)
    UI.DIALOG.attention({
      heading: "Counselling services not available in your country",
      body: `Apologies for the inconvenience: turn2me's counselling applications are currently only available in the ${itemsArrayToFormattedString(servicedCountryNames)}. We look forward to making the service available in your country/region soon.`
    })
  }

  useOnMount(() => {
    const d = makeDisposerController();
    const handler = () => {
      COUNSELLING.getCounsellingAvailabilities();
    };
    window.addEventListener('focus', handler);
    d.add(doEvery(COUNSELLING.getCounsellingAvailabilities, minutes(3), { fireImmediately: true }));
    d.add(() => window.removeEventListener('focus', handler));
    const updateCountryCode = async () => {
      const countryCodeDetectedByAPI = AUTH.currentUser?.countryDetectedId;
      const countryCodeDetectedByCloudflare = await ANALYTICS.getUserCountryCode();
      const countryCode = countryCodeDetectedByAPI ?? countryCodeDetectedByCloudflare ?? null;

      switch (true) {
        case countryCodeDetectedByAPI === 'IE':
        case countryCodeDetectedByCloudflare === 'IE':
          s.countryCode = 'IE';
          break;
        default:
          s.countryCode = countryCode ?? 'UNKNOWN';
      }
    }
    updateCountryCode();
    return d.disposer;
  })

  return <Observer children={() => (
    <ClientAppSubmodule name="Counselling" title="Online Counselling / Psychotherapy">
      <section className={
        joinClassName('ModuleCounsellingContent', COUNSELLING.activeApplications.length === 0 && 'empty')
      }>
        <div className="ModuleCounsellingContentBody">
          {UI.fromTablet && <header className="ModuleCounsellingContentHeader">
            <h1>Online Counselling / Psychotherapy</h1>
            <p>Get professional support online</p>
          </header>}
          {COUNSELLING.errorGettingApplications && <ShadedBlock color="red">
            <h3>There was an error retrieving your counselling information.</h3>
            <p>This is likely a temporary issue; please try again later. If you keep having issues opening this page, please contact us and we will help you out.</p>
          </ShadedBlock>}
          {!COUNSELLING.firstDataLoaded && !COUNSELLING.errorGettingApplications && <div className="ModuleCounsellingLoadingNotice">
            <LoadingIndicator />
            <p><em>Loading your applications...</em></p>
          </div>}
          {COUNSELLING.firstDataLoaded && <>
            {COUNSELLING.activeApplications.length > 0 && <CounsellingJourneyCardList />}
            {/* {COUNSELLING.activeApplications.length === 0 && <ShadedBlock spaceChildren color={getColorHexByName(ColorName.brightPurple)}>
              <BaseIcon icon="counselling" size="32" />
              <h3>Welcome to turn2me Online Counselling / Psychotherapy!</h3>
              {AUTH.currentUser?.isYoungPerson ?
                <p>If you are looking to get counselling services online, please notify your parents or legal guardian to register an account and start an application.</p>
                : <>
                  <p>To ensure we reach as many users as possible, if you can afford to pay for therapy, please select our paid counselling / psychotherapy sessions. This will keep the free sessions available for those who cannot afford to pay.</p>
                </>
              }
              <div className="ModuleCounsellingLandingPageList">
                {AUTH.currentUser?.isAdult && <div><a href={getWordPressLink("/services/one-to-one-counselling")} target="_blank" rel="noreferrer" title="Read more about One to One Counselling"><BaseIcon icon="external-link" /> One to One Counselling</a></div>}
                {AUTH.currentUser?.isAdult && <div><a href={getWordPressLink("/services/couples-counselling")} target="_blank" rel="noreferrer" title="Read more about Couples Counselling"><BaseIcon icon="external-link" /> Couples Counselling</a></div>}
                <div><a href={getWordPressLink("/services/young-people-counselling")} target="_blank" rel="noreferrer" title="Read more about Young People Counselling"><BaseIcon icon="external-link" /> Young People Counselling</a></div>
              </div>
            </ShadedBlock>*/}

            {AUTH.currentUser?.isAdult && <>
              <BaseSpacer size="sm" />
              <h4>Paid Counselling / Psychotherapy (€50 per session)</h4>
              <p>By choosing this service you are not restricted in the number of sessions you can have. You can enjoy the many benefits of our psychotherapy service. You will be working with a fully qualified Therapist who is Full Accredited or working towards their Accreditation.</p>
              <p>We thank you for your support, you are allowing us to offer much needed assistance to those who cannot afford to pay for Mental Health Support.</p>
              <div className="ModuleCounsellingApplicationButtonSetPaid">
                <button
                  name="NewOneToOneApplicationForOneToOne"
                  className="NewApplicationButton --paidOneToOne"
                  data-cy={`NewApplicationButton__${ApplicationTypeOption.PaidOneToOne}`}
                  onClick={applyForNewCounsellingFn(ApplicationTypeOption.PaidOneToOne)}
                >
                  <span>Book Appointment</span>< hr /><strong>One to One Counselling</strong> <small>for 18+ adults</small>
                  <hr />
                  <span>{autoPluralize(COUNSELLING.availablePaidOneToOneSlots.length, '1 available slot', 'No availability', '%d available slots', true)}</span>
                </button>
              </div>
            </>}

            {AUTH.currentUser?.isAdult && <>
              <BaseSpacer size="sm" />
              <h4>Free Counselling Service (6 consecutive sessions)</h4>
              <p>This free service is available to those who cannot afford to pay. It is available to Irish Residents, up to {NUM_FREE_COUNSELLING_SESSIONS} consecutive sessions.</p>
              <p>If you can support this service, please make a donation. If you are receiving an income above €35,000 per annum, please switch to our paid service.</p>
              <div className="ModuleCounsellingApplicationButtonSetFree">
                <button
                  name="NewOneToOneApplicationForOneToOne"
                  className="NewApplicationButton --oneToOne"
                  data-cy={`NewApplicationButton__${ApplicationTypeOption.OneToOne}`}
                  onClick={applyForNewCounsellingFn(ApplicationTypeOption.OneToOne)}
                >
                  <span>Apply for</span>< hr /><strong>One to One Counselling</strong><small>6 Consecutive Sessions</small><small>for 18+ adults</small>
                  <hr />
                  <span>{autoPluralize(COUNSELLING.availableOneToOneSlots.length, '1 available slot', 'No availability', '%d available slots', true)}</span>
                </button>
                {/* <button
                  name="NewCouplesApplicationForCouples"
                  className="NewApplicationButton --couples"
                  data-cy={`NewApplicationButton__${ApplicationTypeOption.Couples}`}
                  onClick={applyForNewCounsellingFn(ApplicationTypeOption.Couples)}
                >
                  <span>Apply for</span>< hr /><strong>Couples Counselling</strong>
                  <hr />
                  <span>{autoPluralize(COUNSELLING.availableCouplesSlots.length, '1 available slot', 'No availability', '%d available slots', true)}</span>
                </button>
                <button
                  name="NewCouplesApplicationForYoungPeople"
                  className="NewApplicationButton --yp"
                  data-cy={`NewApplicationButton__${ApplicationTypeOption.YoungPeople}`}
                  onClick={applyForNewCounsellingFn(ApplicationTypeOption.YoungPeople)}
                >
                  <span>Apply for</span>< hr /><strong>Young People Counselling</strong> <small>for your children</small>
                  <hr />
                  <span>{autoPluralize(COUNSELLING.availableYPSlots.length, '1 available slot', 'No availability', '%d available slots', true)}</span>
                </button> */}
              </div>

              <InfoBanner icon="info" color="brightPurple" className="ModuleCounsellingTermsAndConditionsBanner">
                <p>We reserve the right to request proof for applications for free sessions. Read the full <a href={getWordPressLink('/terms-and-conditions')} title="Terms and Conditions" target="_blank" rel="noreferrer">Terms and conditions for free counselling sessions for Irish residents</a>.</p>
              </InfoBanner>
            </>}
          </>}
        </div>
        <aside className="ModuleCounsellingContentAside">
          <div className="ModuleCounsellingIntroCard">
            <h3>What's Online Counselling?</h3>
            <p>turn2me’s online counselling service offers support for whatever you are going through. This is a safe place to talk with a professional counsellor to help you understand your feelings and work together to develop the tools you need to get through tough times.</p>
          </div>
          <CommandList withBackground>
            <CommandListItem icon="arrow" to={getWordPressLink('/services/one-to-one-counselling')} openInNewTab>Know More</CommandListItem>
            <CommandListItem icon="arrow" to={getWordPressLink('/faq')} openInNewTab>FAQ</CommandListItem>
          </CommandList>
        </aside>
      </section>
      <NavBarHeightSpacer />
    </ClientAppSubmodule>
  )} />


}

export default ModuleCounselling
