import { flow } from "mobx";
import { InvoiceItemEndpoints } from "../base/endpoints/invoiceItems.endpoints";
import { getNowTimestampUtc } from "../base/utils/time.utils";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { FeeType } from "../models/makeFee.model";
import { Invoice, InvoiceSnapshot } from "../models/makeInvoice.model";
import { InvoiceItem, InvoiceItemModelType, InvoiceItemSnapshot } from "../models/makeInvoiceItem.models";

export const createInvoice = async (
  API: APIController,
  payeeId: string,
  amountPayable?: number
) => {
  const url = InvoiceItemEndpoints.staff.create();
  return await API.post<Invoice>(url, ModelName.invoiceItems, {
    amountPayable: amountPayable ?? 0,
    payeeId: payeeId,
  } as Partial<InvoiceSnapshot>)
}

export const createInvoiceItem = async (
  API: APIController,
  payeeId: string,
  modelType: InvoiceItemModelType,
  modelId: string,
  amountPayable?: number,
  feeType?: FeeType,
) => {
  const url = InvoiceItemEndpoints.staff.create();
  const { AUTH } = API.ROOT!.children;
  const canPostAmountPayable = AUTH.can.manage_.staffInvoicing || AUTH.can.provideCounsellingFor_.someUserGroups || AUTH.can.supportGroups_.manage_.groups;
  return await API.post<InvoiceItem>(url, ModelName.invoiceItems, {
    amountPayable: canPostAmountPayable ? amountPayable ?? 0 : undefined,
    payeeId,
    modelType,
    modelId,
    feeType,
  } as Partial<InvoiceItemSnapshot>)
}

export const markInvoiceAsPaid = flow(function * (
  API: APIController,
  invoice: Invoice,
) {
  const url = InvoiceItemEndpoints.staff.update(invoice.id);
  return yield API.patch<InvoiceItem>(url, ModelName.invoiceItems, {
    ...invoice.$snapshot,
    timePaid: getNowTimestampUtc(),
  } as Partial<InvoiceSnapshot>)
})
