import { Observer } from "mobx-react-lite";
import React, { SyntheticEvent, useRef, useState } from "react";
import { ContextColor } from "../../constants/color.enum";
import { useContextColorStyle } from "../../hooks/useContextColor.hook";
import { useControllers } from "../../hooks/useRootController.hook";
import joinClassName from "../../utils/className.utils";
import BaseIcon from "../BaseIcon/BaseIcon";
import { IconName } from "../Symbols/iconDefs";
import './CommandListItem.scss';

interface CommandListItemProps {
  onClick?: (e: SyntheticEvent) => unknown;
  className?: string;
  title?: string,
  color?: string;
  icon?: IconName;
  to?: string;
  disabled?: boolean;
  dataCy?: string,
  openInNewTab?: boolean,
  noPointer?: boolean,
}

export const CommandListItem: React.FC<CommandListItemProps> = props => {
  const ref = useRef(null);
  const { NAVIGATOR } = useControllers();
  const [ awaitingResponse, setAwaitingResponse ] = useState<boolean>();
  const handleComponentClick = async (e: SyntheticEvent) => {
    if (props.disabled) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      return;
    }
    if (props.onClick) {
      setAwaitingResponse(true);
      try {
        await props.onClick(e);
      } catch(error) {
        throw(error);
      } finally {
        setAwaitingResponse(false);
      }
    }
    if (props.to) {
      if (props.openInNewTab) {
        window.open(props.to, '_blank');
        return;
      }
      if (props.to.match(/^http/)) {
        window.location.href = props.to;
        return;
      }
      NAVIGATOR.navigateTo(props.to);
    }
  }
  const style = useContextColorStyle(ContextColor.Primary, props.color);
  return <Observer children={() => (
    <div className={joinClassName(
      'CommandListItem',
      props.className,
      props.disabled && 'disabled',
      (props.onClick || props.to) && 'interactable',
      awaitingResponse && 'awaitingResponse',
      props.noPointer && 'noPointer'
    )} ref={ref}
      onClick={handleComponentClick}
      style={style}
      data-cy={props.dataCy}
      title={props.title}
    >
      {props.icon && <BaseIcon name={props.icon} variant="filled" size="1.6rem" />}
      <div className="CommandListItem__inner">{props.children}</div>
    </div>
  )} />
}