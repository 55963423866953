import { Observer } from 'mobx-react-lite';
import React from 'react';
import ExampleImage from '../../assets/images/pexels-photo-1311518.jpeg';
import tick from '../../base/utils/waiters.utils';
import { getWordPressLink } from '../../env';
import ServiceCard from '../ServiceCard/ServiceCard';
// import './ServiceCardYoungPeopleCounselling.scss';

interface ServiceCardYoungPeopleCounsellingProps {
  cardRatio?: number,
}

const ServiceCardYoungPeopleCounselling: React.FC<ServiceCardYoungPeopleCounsellingProps> = props => {

  const automaticallyRedirectToCounsellingApplicationPage = async () => {
    await tick(1000);
    document.querySelector<HTMLAnchorElement>('.AppNavTabInner[data-identifier="counselling"]')?.click();
  }

  return <Observer children={() => (
    <ServiceCard
      className="ServiceCardYoungPeopleCounselling"
      title={<>Young People<br />Counselling</>}
      stringTitle="Young People Counselling"
      sideABackgroundImage={`url(${ExampleImage})`}
      src={getWordPressLink('/services/young-people-counselling/')}
      listenForWindowMessage="young-people-counselling"
      onReceiveWindowMessage={automaticallyRedirectToCounsellingApplicationPage}
      cardRatio={props.cardRatio}
    />
  )} />
}

export default ServiceCardYoungPeopleCounselling;