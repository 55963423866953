import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject, ColorCodedState } from '../../../../../base/@types';
import AppPage from '../../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../../base/components/AppPageHeader/AppPageHeader';
import BackButton from '../../../../../base/components/BackButton/BackButton';
import BaseButton from '../../../../../base/components/BaseButton/BaseButton';
import BaseInput from '../../../../../base/components/BaseInput/BaseInput';
import BaseSpacer from '../../../../../base/components/BaseSpacer/BaseSpacer';
import ErrorRenderer from '../../../../../base/components/ErrorRenderer/ErrorRenderer';
import LoadingIndicator from '../../../../../base/components/LoadingIndicator/LoadingIndicator';
import UIBlock from '../../../../../base/components/UIBlock/UIBlock';
import { CompanyEndpoints, DefaultCompanyIncludes } from '../../../../../base/endpoints/company.endpoints';
import { UserEndpoints } from '../../../../../base/endpoints/user.endpoints';
import { useOnMount } from '../../../../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../../../../base/hooks/useRootController.hook';
import { reportError } from "../../../../../base/utils/errors.utils";
import { useStore } from '../../../../../base/utils/mobx.utils';
import CompanyEditor from '../../../../../components/CompanyEditor/CompanyEditor';
import { ModelName } from '../../../../../constants/modelNames.enum';
import { Company } from '../../../../../models/makeCompany.model';
import { User } from '../../../../../models/makeUser.model';
import './AccountPageCompany.scss';

type AccountPageCompanyProps = {}

const AccountPageCompany: React.FC<AccountPageCompanyProps> = props => {

  const { AUTH, API, UI } = useControllers();

  const s = useStore(() => ({
    isLoading: true,
    form: {
      companyCode: '',
    },
    get companyId() {
      return AUTH.currentUser?.companyId;
    },
    get company() {
      return AUTH.currentUser?.company;
    },
    get canLink() {
      return Boolean(s.form.companyCode);
    },
    get LinkButton() {
      if (s.company) return <BaseButton className="subtle" colorCodedState={ColorCodedState.attention} onClick={unlink} fullWidth label="Unlink" dataCy="unlinkCompany" />;
      return <BaseButton className="subtle" colorCodedState={ColorCodedState.success} onClick={linkToCompany} fullWidth label="Link" disabled={!s.canLink} dataCy="linkCompany" />;
    },
  }));

  const linkToCompany = flow(function* () {
    try {
      const url = UserEndpoints.own.update();
      yield API.patch<User>(url, ModelName.users, {
        // @ts-expect-error companyCode is not part of model.
        companyCode: s.form.companyCode,
        email: AUTH.currentUser?.email,
        mobileNumber: AUTH.currentUser?.mobileNumber,
      });
      yield AUTH.fetchCurrentUserInfo();
      UI.TOAST.success('Linked with company successfully', 3000);
    } catch (e) {
      reportError(e);
      UI.DIALOG.error({
        heading: "Failed to link with company, please try again",
        body: <ErrorRenderer error={(e as AnyObject).response} />,
      })
    }
  });

  const unlink = flow(function* () {
    const confirm = yield UI.DIALOG.attention({
      heading: 'Are you sure you want to unlink with this company?',
      defaultActions: ['negative', 'positive'],
    })
    if (!confirm) return;
    try {
      const url = UserEndpoints.own.update();
      yield API.patch<User>(url, ModelName.users, {
        // @ts-expect-error companyCode is not part of model.
        companyCode: null,
        email: AUTH.currentUser?.email,
        mobileNumber: AUTH.currentUser?.mobileNumber,
      });
      UI.TOAST.success('Unlink with company successfully', 3000);
    } catch (e) {
      reportError(e);
      UI.DIALOG.error({
        heading: "Failed to unlink with company, please try again",
        body: <ErrorRenderer error={(e as AnyObject).response} />,
      })
    }
  });

  const getCompany = flow(function* () {
    if (!s.companyId) {
      s.isLoading = false;
      return;
    }
    try {
      const url = CompanyEndpoints.own.get(s.companyId, { include: DefaultCompanyIncludes });
      yield API.get<Company>(url, ModelName.companies);
    } catch (e) {
      reportError(e);
      UI.DIALOG.error({
        heading: "Failed to retrieve company, please try again",
        body: <ErrorRenderer error={(e as AnyObject).response} />,
      })
    }
    s.isLoading = false;
  });

  useOnMount(() => {
    getCompany();
  })

  return <Observer children={() => (
    <AppPage className="AccountPageCompany"
      accommodateTitleBarSpace
    >
      <AppPageHeader
        title="Company"
        afterTitle="View the company information your account is linked to"
        startSlot={<BackButton destination="up" />}
      />
      <AppPageContent>
        {s.isLoading
          ? <LoadingIndicator />
          : <UIBlock padded>
            {s.company
              ? <CompanyEditor company={s.company} />
              : <BaseInput form={s.form} field="companyCode" label="Company Code / Refugee Code" />
            }
            <BaseSpacer size="sm" />
            {s.LinkButton}
            <BaseSpacer />
          </UIBlock>
        }
      </AppPageContent>
    </AppPage>
  )} />
}

export default AccountPageCompany;