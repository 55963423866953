import { SupportGroupEndpoints } from "../base/endpoints/supportGroup.endpoints";
import { getSnapshot } from "../base/utils/snapshot.utils";
import { slugify } from "../base/utils/string.utils";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { SupportGroup } from "../models/makeSupportGroup.model";

export const saveSupportGroup = (API: APIController, supportGroup: Partial<SupportGroup>) => new Promise<SupportGroup>(
  async (resolve, reject) => {
    try {
      const { id } = supportGroup;
      const url = id ? SupportGroupEndpoints.staff.update(id) : SupportGroupEndpoints.staff.create();
      const payload = getSnapshot(supportGroup);
      if (payload.title) payload['slug'] = payload['slug'] || slugify(payload.title);
      const method = id ? API.patch : API.post;
      const savedSupportGroup = await method<SupportGroup>(url, ModelName.supportGroups, payload);
      if (!savedSupportGroup) {
        throw Error(`Something wasn't quite right while saving the support group...`);
      }
      resolve(savedSupportGroup);
    } catch(e) {
      reject(e);
    }
  }
)