import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Alert } from '../../@types/alert.types';
import { Renderable } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { renderRenderable } from '../../base/utils/components.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import AlertCard from '../AlertCard/AlertCard';
import './AlertCardList.scss';

interface AlertCardListProps {
  alerts: Alert[],
  heading?: string,
  emptyContent?: Renderable,
  withColor?: boolean,
  dropShadow?: boolean,
  counter?: () => number,
  endSlot?: Renderable
}

const AlertCardList: React.FC<AlertCardListProps> = props => {

  const { ALERTS } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    get alerts() {
      return p.alerts
    },
    get groupedAlerts() {
      return p.alerts
    },
    get length() {
      return p.counter ? p.counter?.() : p.alerts.length;
    },
    markListAsRead: async () => {
      await ALERTS.markManyAsRead(s.alerts);
    }
  }));

  return <Observer children={() => (
    <div className="AlertCardList" data-should-drop-shadow={p.dropShadow}>
      {p.heading && <header className="AlertCardListHeader">
        <h3>{p.heading} {s.length > 0 && <span className="AlertCardListCounter">{s.length}</span>}</h3>
        <div>
          <BaseButton onClick={s.markListAsRead} className="subtle" rounded size="xs">Clear</BaseButton>
        </div>
      </header>}
      {s.alerts.map(n => <AlertCard alert={n} key={n.id} withColor={p.withColor} />)}
      {s.alerts.length === 0 && (
        p.emptyContent ? renderRenderable(p.emptyContent) : <p><em>No notifications yet.</em></p>
      )}
      {p.endSlot && renderRenderable(p.endSlot)}
    </div>
  )} />
}

export default AlertCardList;