import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseSelector, { BaseSelectorProps } from '../../base/components/BaseSelector/BaseSelector';
import { irelandCountyList } from '../../constants/IrelandCounties.constants';

interface IrelandCountySelectorProps<FormType = object> extends Omit<BaseSelectorProps<FormType, {name: string, value: string}, string>, 'options'> { }

function IrelandCountySelector<T = object>(props: React.PropsWithChildren<IrelandCountySelectorProps<T>>) {
  const options = irelandCountyList.map(c => ({name: c, value: c.toLowerCase()}))
  return <Observer children={() => (
    <BaseSelector className="IrelandCountySelector" {...props} options={options} appearance="system" />
  )} />
}

export default IrelandCountySelector;