import { isOfficialSite } from "../env";

export enum SubscriptionFrequency {
  oneTime = 'oneTime',
  weekly = 'weekly',
  monthly = 'monthly',
}

export const makeStripeSubscriptionPriceId = () => isOfficialSite
  ? {
    weekly: 'price_1LZwKPKDEmPif3VtXRFiXZ4N', // weekly pricingId not created in prod Stripe.
    monthly: 'price_1LZwKPKDEmPif3VtXRFiXZ4N',
  } : {
    weekly: 'price_1OI6zuKDEmPif3VtfdshBFkH',
    monthly: 'price_1OI6zuKDEmPif3VtfdshBFkH',
  }
export type StripeSubscriptionPriceId = ReturnType<typeof makeStripeSubscriptionPriceId>[keyof ReturnType<typeof makeStripeSubscriptionPriceId>];
export const stripeSubscriptionPriceId = makeStripeSubscriptionPriceId();

export const StripePriceIdToSubscriptionFrequency = {
  [stripeSubscriptionPriceId.weekly]: SubscriptionFrequency.weekly,
  [stripeSubscriptionPriceId.monthly]: SubscriptionFrequency.monthly,
}
export const SubscriptionFrequencyToStripePriceId = {
  [SubscriptionFrequency.weekly]: stripeSubscriptionPriceId.weekly,
  [SubscriptionFrequency.monthly]: stripeSubscriptionPriceId.monthly,
}

export const recurringDonationFrequencies = [
  // {
  //   label: 'Weekly',
  //   value: SubscriptionFrequency.weekly,
  // },
  {
    label: 'Monthly',
    value: SubscriptionFrequency.monthly,
  },
]

export const availableDonationFrequencies = [
  {
    label: 'One-time',
    value: SubscriptionFrequency.oneTime,
  },
  ...recurringDonationFrequencies,
]