import { action, flow, when } from "mobx";
import { LocalAudioTrack, LocalTrackPublication, LocalVideoTrack, RemoteAudioTrack, RemoteTrackPublication, RemoteVideoTrack } from 'twilio-video';
import { ObservableRef } from "../base/hooks/useObservableRef.hook";
import tick, { doEvery } from "../base/utils/waiters.utils";
import { SHOULD_LOG } from "../env";

let circuitBreakerCount = 0;

export const initTrackUI = flow(function * (
  s: {
    videoOn: boolean,
    audioOn: boolean,
    videoWidth: number,
    videoHeight: number,
    tracksRendered: (RemoteAudioTrack | RemoteVideoTrack | LocalVideoTrack | LocalAudioTrack)[],
  }, 
  track: RemoteTrackPublication['track'] | LocalTrackPublication['track'],
  ref: ObservableRef,
) {
  if (track && 'attach' in track) {
    SHOULD_LOG() && console.log('init track', track, ref.current);
    if (s.tracksRendered.find(t => t === track)) {
      if (circuitBreakerCount > 10) debugger;
      SHOULD_LOG() && console.warn('The same track is being added to video frame multiple times.');
      circuitBreakerCount++;
      return () => {};
    }
    yield when(() => !!ref.current);
    ref.current!.appendChild(track.attach());
    s.tracksRendered.push(track);
    if (track.kind === 'video') {
      s.videoOn = track.isEnabled;
      if (track.dimensions.width) s.videoWidth = track.dimensions.width;
      if (track.dimensions.height) s.videoHeight = track.dimensions.height;
    }
    if (track.kind === 'audio') s.audioOn = track.isEnabled;
    const disposers: Function[] = [];
    const startVideoTrackDimensionsWatcher = () => {
      if (track.kind === 'video') {
        if (disposers.length > 0) return;
        disposers.push(doEvery(action(() => {
          if (track.dimensions.width) s.videoWidth = track.dimensions.width;
          if (track.dimensions.height) s.videoHeight = track.dimensions.height;
        }), 2000));
      }
    }
    if (track.kind === 'video') {
      startVideoTrackDimensionsWatcher();
    }
    const stopVideoTrackDimensionsWatcher = () => {
      while (disposers.length > 0) {
        disposers.shift()?.();
      }
    }
    const handleTrackDimensionsChanged = action(() => {
      SHOULD_LOG() && console.log('track dimensions changed, type: ', track.kind);
      if (track.kind === 'video') {
        if (track.dimensions.width) s.videoWidth = track.dimensions.width;
        if (track.dimensions.height) s.videoHeight = track.dimensions.height;
      }
    })
    const handleTrackDisable = action(() => {
      SHOULD_LOG() && console.log('track disabled, type: ', track.kind);
      if (track.kind === 'video') {
        s.videoOn = false;
        stopVideoTrackDimensionsWatcher();
      }
      if (track.kind === 'audio') s.audioOn = false;
      
    })
    const handleTrackEnable = action(() => {
      SHOULD_LOG() && console.log('track enabled, type: ', track.kind);
      if (track.kind === 'video') {
        s.videoOn = true;
        if (track.dimensions.width) s.videoWidth = track.dimensions.width;
        if (track.dimensions.height) s.videoHeight = track.dimensions.height;
        startVideoTrackDimensionsWatcher();
      }
      if (track.kind === 'audio') s.audioOn = true;
    })
    handleTrackEnable();
    track.on('enabled', handleTrackEnable);
    track.on('disabled', handleTrackDisable);
    track.on('dimensionsChanged', handleTrackDimensionsChanged);
    track.on('unsubscribed', handleTrackDisable);
    track.on('stop', handleTrackDisable);
    track.on('start', handleTrackEnable);
    yield tick();
    
    return () => {
      console.log('cleaning up track UI')
      stopVideoTrackDimensionsWatcher();
      track.off('dimensionsChanged', handleTrackDimensionsChanged);
      track.off('unsubscribed', handleTrackDisable);
      track.off('stop', handleTrackDisable);
      track.off('start', handleTrackEnable);
      track.off('disabled', handleTrackDisable);
      track.off('enabled', handleTrackEnable);
    }

  }

  return () => {};
  
})