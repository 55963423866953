import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Nillable } from '../../base/@types';
import InfoBanner from '../../base/components/InfoBanner/InfoBanner';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { Assignment } from '../../models/makeAssignment.model';
import { CounsellingApplication } from '../../models/makeCounsellingApplication.model';
import AssignmentEntryForAdmin from './AssignmentEntryForAdmin';
import './AssignmentListForAdmin.scss';

export interface AssignmentListForAdminProps {
  assignments?: Assignment[],
  application?: Nillable<CounsellingApplication>,
}

const AssignmentListForAdmin: React.FC<AssignmentListForAdminProps> = props => {

  const p = useProps(props);
  const s = useStore(() => ({
    get assignments() {
      return p.assignments || [];
    },
    get a() {
      return s.assignments;
    }
  }));

  return <Observer children={() => (
    <div className="AssignmentListForAdmin">
      { s.assignments.length === 0 && <InfoBanner>No items here yet. Applicable questionnaires will be created automatically when the session is created.</InfoBanner>}
      { s.assignments.map(a => <AssignmentEntryForAdmin assignment={a} key={a.id} application={p.application} />)}
    </div>
  )} />
}

export default AssignmentListForAdmin;