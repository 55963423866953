import Color from "color";
import { HasColor } from "../@types/traits.types";
import { getContrastColorHex } from "../utils/colors.utils";

export const useColorStyle = (
  input?: Partial<HasColor> | string,
  asProperty: 'backgroundColor' | 'color' = 'color',
  contrastProperty?: 'backgroundColor' | 'color',
  opacity: number = 1,
  contrastOpacity: number = 1,
): React.CSSProperties => {
  if (!input) return {};
  const hex = input instanceof Object ? input.color : input;
  if (!hex) return {};
  const style = {
    [asProperty]: opacity < 1 ? Color(hex).alpha(opacity) : hex,
  }
  let _contrastProperty = contrastProperty;
  if (!_contrastProperty) {
    switch (asProperty) {
      case 'color': _contrastProperty = 'backgroundColor'; break;
      case 'backgroundColor': _contrastProperty = 'color'; break;
      default: break;
    }
  }
  if (!_contrastProperty) return style;
  const contrastColor = Color(getContrastColorHex(hex));
  const contrastStyle = {
    [_contrastProperty]: contrastColor.alpha(contrastOpacity).hex(),
  }
  return {
    ...style,
    ...contrastStyle
  }
}