import { action, flow, observable, reaction } from "mobx"
import moment from "moment"
import { Nillable, Nullable } from "../base/@types"
import { DefaultSupportGroupIncludes, SupportGroupEndpoints } from "../base/endpoints/supportGroup.endpoints"
import { SupportGroupTopicEndpoints } from "../base/endpoints/supportGroupTopic.endpoints"
import { sortByPropDisplayName, sortByTimeScheduledOldestFirst } from "../base/utils/array.utils"
import { reportError } from "../base/utils/errors.utils"
import { convertRecordMapToArray } from "../base/utils/map.utils"
import { first } from "../base/utils/ramdaEquivalents.utils"
import { YYYYMMDDHHmmss } from "../base/utils/time.utils"
import { ModelName } from "../constants/modelNames.enum"
import { AlwaysSafeTopicsForRecommending, AlwaysSafeTopicsForRecommendingForAdults, AlwaysSafeTopicsForRecommendingForYoungPeople } from "../constants/supportGroupTopics.constants"
import { SupportGroup } from "../models/makeSupportGroup.model"
import { SupportGroupTopic } from "../models/makeSupportGroupTopic.model"
import { User } from "../models/makeUser.model"
import { getSupportGroups } from "../requests/getSupportGroups.request"
import { userIsLGBTQ } from "../utils/user.utils"
import { AuthController } from "./auth.controller"
import { LocalDBController } from "./localDB.controller"
import { makeControllerBase, makeRootControllerChildInitFn } from "./_root.controller"

/**
 * Top-level controller for support group and support group topic related features,
 * including recommendations in the user's feeds.
 */
export const makeSupportGroupsController = () => {

  const c = observable({
    ...makeControllerBase('SUPPORT_GROUPS'),
    get AUTH(): AuthController { return c.ROOT!.children.AUTH },
    get LOCALDB(): LocalDBController { return c.ROOT!.children.LOCALDB },
    get currentUser(): Nillable<User> { return c.AUTH.currentUser },
    get loadedGroups(): SupportGroup[] {
      return convertRecordMapToArray(c.LOCALDB.data.supportGroups)
    },
    get loadedGroupsSorted(): SupportGroup[] {
      const allGroups = sortByTimeScheduledOldestFirst(c.loadedGroups);
      if (c.AUTH.canFacilitate) return allGroups;
      const { currentUser } = c.AUTH;
      if (!currentUser) return [];
      if (currentUser?.isAdult) return allGroups.filter(g => g.isForAdults)
      if (currentUser?.isYoungPerson1214) return allGroups.filter(g => g.isFor1214)
      if (currentUser?.isYoungPerson1517) return allGroups.filter(g => g.isFor1517)
      return [];
    },
    get futureUpcomingOrLiveGroupsSorted(): SupportGroup[] {
      return c.loadedGroupsSorted.filter(g => g.isFuture || g.isLive);
    },
    get upcomingOrLiveGroupsSorted(): SupportGroup[] {
      return c.loadedGroupsSorted.filter(g => g.isUpcoming || g.isLive);
    },
    get recommendableGroups(): SupportGroup[] {
      return c.upcomingOrLiveGroupsSorted.filter(g => {
        if (g.isInThePast && !g.isLive) return false;
        if (g.currentUserHasRegistered) return false;
        return true;
      });
    },
    get ownGroupsLoadedSorted(): SupportGroup[] {
      return c.loadedGroupsSorted.filter(g => g.currentUserHasRegistered);
    },
    get ownUpcomingSorted(): SupportGroup[] {
      return c.ownGroupsLoadedSorted.filter(g => g.isUpcoming);
    },
    get lastOwnUpcomingGroup(): Nillable<SupportGroup> {
      return first(c.ownUpcomingSorted);
    },
    get topics(): SupportGroupTopic[] {
      return convertRecordMapToArray(c.LOCALDB.data.supportGroupTopics);
    },
    get sortedTopics(): SupportGroupTopic[] {
      return sortByPropDisplayName(c.topics);
    },
    topicsLoading: false,
    topicsLoaded: false,
    errorLoadingTopics: null as null | Error,
    getAllSupportGroupTopics: async () => await flow(function * () {
      try {
        c.errorLoadingTopics = null;
        c.topicsLoading = true;
        const canManageTopics = c.AUTH.can.supportGroups_.manage_.topics;
        const url = SupportGroupTopicEndpoints[canManageTopics ? 'staff' : 'client'].index({ perPage: Infinity });
        yield c.ROOT!.children.API.getMany(url, ModelName.supportGroupTopics);
        c.topicsLoading = false;
        c.topicsLoaded = true;
      } catch(e) {
        reportError(e);
        c.topicsLoading = false;
        c.errorLoadingTopics = e as Error;
      }
    })(),
    initialDataForRecommendationRetrieved: false,
    initialDataForRecommendationRetrievalError: null as Nullable<Error>,
    getSupportGroupsForComingWeek: () => flow(function * () {
      try {
        c.initialDataForRecommendationRetrievalError = null;
        if (!c.ROOT?.children.AUTH.isAuthenticated) return;
        yield getSupportGroups(c.ROOT!.children.API, {
          sort: 'timeScheduled',
          perPage: Infinity,
          filter: {
            dateRange: {
              key: 'timeScheduled',
              startDate: moment.utc().startOf('day').format(YYYYMMDDHHmmss),
              endDate: moment.utc().add(1, 'week').endOf('day').format(YYYYMMDDHHmmss),
            }
          }
        });
        if (!c.ROOT?.children.AUTH.isAuthenticated) return;
        c.initialDataForRecommendationRetrieved = true;
      } catch(e) {
        reportError(e);
        c.initialDataForRecommendationRetrievalError = e as Error;
      }
    })(),
    currentUserSupportGroupsRetrieved: false,
    currentUserSupportGroupsRetrievalError: null as Nullable<Error>,
    getCurrentUserSupportGroups: () => flow(function* () {
      c.currentUserSupportGroupsRetrievalError = null;
      const url = SupportGroupEndpoints.own.index({
        include: DefaultSupportGroupIncludes,
        perPage: Infinity,
        filter: {
          dateRange: {
            key: 'timeScheduled',
            startDate: moment.utc().subtract(1, 'week').startOf('day').format(YYYYMMDDHHmmss)
          }
        }
      });
      try {
        if (!c.ROOT?.children.AUTH.isAuthenticated) return;
        yield c.ROOT!.children.API.getMany(url, ModelName.supportGroups);
        if (!c.ROOT?.children.AUTH.isAuthenticated) return;
        c.currentUserSupportGroupsRetrieved = true;
      } catch (e) {
        c.currentUserSupportGroupsRetrievalError = e as Error;
        reportError(e);
      }
    })(),
    get safeTopicsForRecommending(): string[] {
      const { currentUser } = c.ROOT!.children.AUTH;
      if (currentUser?.isAdult) return AlwaysSafeTopicsForRecommendingForAdults;
      if (currentUser?.isYoungPerson) return AlwaysSafeTopicsForRecommendingForYoungPeople;
      return AlwaysSafeTopicsForRecommending;
    },
    get topicsForRecommending(): string[] {
      const topics = [...c.safeTopicsForRecommending];
      const { currentUser } = c.ROOT!.children.AUTH;
      if (userIsLGBTQ(currentUser)) topics.push('lgbtqi');
      return topics;
    },
    get recommendations(): SupportGroup[] {
      return c.recommendableGroups.filter(g => c.topicsForRecommending.includes(g.topic?.slug ?? g.slug));
    },
    initialDataLoaded: false,
    reset: action(() => {
      disposers.forEach(d => d());
      c.initialDataLoaded = false;
      c.currentUserSupportGroupsRetrieved = false;
      c.currentUserSupportGroupsRetrievalError = null;
      c.initialDataForRecommendationRetrieved = false;
      c.initialDataForRecommendationRetrievalError = null;
    }),
  })

  const disposers: Function[] = [];

  c.init = makeRootControllerChildInitFn(
    c,
    () => {
      reaction(
        () => c.currentUser?.id,
        flow(function * (id) {
          if (c.AUTH.isStaff) c.getAllSupportGroupTopics();
          if (id) {
            yield c.getCurrentUserSupportGroups();
            yield c.getSupportGroupsForComingWeek();
            c.initialDataLoaded = true;
          }
        }),
        { fireImmediately: true }
      );
    }
  );

  return c;

}

export type SupportGroupsController = ReturnType<typeof makeSupportGroupsController>;