import { Observer } from 'mobx-react-lite';
import React from 'react';
import { UnknownObject } from '../../base/@types';
import { useFormContext } from '../../base/components/FormForm/Form.context';
import { Form } from '../../base/mediators/form.mediator';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import SupportGroupTopicSelector, { SupportGroupTopicSelectorProps } from '../SupportGroupTopicSelector/SupportGroupTopicSelector';

type FormSupportGroupTopicSelectorProps<T extends UnknownObject = UnknownObject> = Omit<SupportGroupTopicSelectorProps, 'form'> & {
  form?: Form<T>,
}

function FormSupportGroupTopicSelector<T extends UnknownObject = UnknownObject>(
  props: React.PropsWithChildren<FormSupportGroupTopicSelectorProps<T>>
) {

  const context = useFormContext<T>();
  const { form } = context;
  const p = useProps(props);
  const s = useStore(() => ({
    get form() { return p.form ?? form },
  }));

  return <Observer children={() => (
    <SupportGroupTopicSelector {...p}
      className={joinClassName('FormSupportGroupTopicSelector', p.className)}
      form={s.form.fields[p.field]}
      field="value"
      name={p.name ?? p.field}
      disabled={p.disabled || context.disabled}
    />
  )} />
}

export default FormSupportGroupTopicSelector;