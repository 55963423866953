import { action, flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ActionConfig } from '../../../controllers/ui/ui.controller.types';
import { HasId } from '../../@types';
import { debounce } from '../../utils/debounce.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import BaseButton from '../BaseButton/BaseButton';
import BaseIcon from '../BaseIcon/BaseIcon';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import SearchBar, { SearchBarProps } from '../SearchBar/SearchBar';
import './AutosuggestSearchBar.scss';

export interface SearchBarWithAutosuggestProps<DataType extends Partial<HasId> = any> extends Omit<SearchBarProps, 'onEnter'> {
  dataFetcher: (query: string, perPage?: number) => Promise<DataType[]>,
  actions?: ActionConfig[],
  defaultAction: (d?: DataType, query?: string) => unknown,
  clearOnAction?: boolean,
  onEnter?: (firstResult?: DataType) => unknown,
  resultItemContentRenderer: (d: DataType) => React.ReactChild,
  listPosition?: 'absolute' | 'static',
  perPage?: number,
  actionPosition?: 'start' | 'end',
  resultFilter?: (d: DataType) => boolean,
  hideResultsIf?: (query?: string) => boolean,
  resultsArray?: DataType[],
  entryShouldDisableChecker?: (d: DataType) => boolean | any,
}

function AutosuggestSearchBar<DataType extends Partial<HasId> = any>(props: React.PropsWithChildren<SearchBarWithAutosuggestProps<DataType>>) {
  const p = useProps(props);
  const s = useStore(() => ({
    _userInput: '',
    get userInput() {
      if (p.form && p.field) return p.form[p.field];
      return s._userInput;
    },
    set userInput(v: string) {
      if (p.form && p.field) p.form[p.field] = v;
      else s._userInput = v;
    },
    results: props.resultsArray || [] as DataType[],
    get filteredResults() {
      return p.resultFilter ? s.results.filter(p.resultFilter) : s.results;
    },
    resultsLoadingForCurrentQuery: false,
    resultsLoadedForCurrentQuery: false,
    errorGettingResultsForCurrentQuery: null as Error | null,
    getResultsByQuery: flow(function * (q: string) {
      try {
        s.resultsLoadingForCurrentQuery = true;
        const results = yield p.dataFetcher(q, p.perPage || 50);
        s.results.splice(0);
        s.results.push(...results);
        s.resultsLoadedForCurrentQuery = true;
      } catch(e) {
        s.errorGettingResultsForCurrentQuery = e as Error;
      } finally {
        s.resultsLoadingForCurrentQuery = false;
      }
    }),
    handleSearchQueryChange: debounce(action((q: string) => {
      // console.log('autosuggest handleSearchQueryChange', q);
      s.resultsLoadedForCurrentQuery = false;
      if (!q) s.clear();
      else s.getResultsByQuery(q);
    }), { timeout: 250, fireImmediately: true }),
    handleOnEnter: action(() => {
      const shouldDisable = p.entryShouldDisableChecker?.(s.results[0]);
      if (shouldDisable) return;
      let shouldClear = p.clearOnAction;
      p.onEnter ? p.onEnter(s.results[0]) : (
        p.defaultAction ? p.defaultAction(s.results[0], s.userInput) : (
          shouldClear = false
        )
      );
      if (shouldClear) s.clear();
    }),
    getItemKey(d: DataType, i: number) {
      switch (typeof d) {
        case 'object': return d.id || i;
        case 'number':
        case 'string':
          return d + '-' + i;
        default: 
          return JSON.stringify(d) + '-' + i;
      }
    },
    handleItemClick: (d: DataType) => () => {
      const shouldDisable = p.entryShouldDisableChecker?.(d);
      if (shouldDisable) return;
      p.defaultAction(d, s.userInput);
      p.clearOnAction && s.clear();
    },
    handleButtonAction: (d: DataType, action: ActionConfig) => () => {
      const shouldDisable = p.entryShouldDisableChecker?.(d);
      if (shouldDisable) return;
      action?.action(d);
      p.clearOnAction && s.clear();
    },
    clear: action(() => {
      s.userInput = '';
      s.results.splice(0);
    }),
    get shouldHide() {
      return p.hideResultsIf?.(s.userInput) || !s.userInput;
    }
  }));
  return <Observer children={() => (
    <div className="AutosuggestSearchBar">
      <SearchBar onChange={s.handleSearchQueryChange} placeholder="Invite User..." {...p} form={s} field="userInput" onEnter={s.handleOnEnter} />
      {
        !s.shouldHide && <>
          {s.resultsLoadedForCurrentQuery && s.filteredResults.length === 0 && (
            <div className="AutosuggestSearchBarStateDisplay"><em>No results found.</em></div>
          )}
          {s.resultsLoadingForCurrentQuery && s.filteredResults.length === 0 && (
            <div className="AutosuggestSearchBarStateDisplay"><LoadingIndicator /> <em>Loading...</em></div>
          )}
          {s.errorGettingResultsForCurrentQuery && (
            <div className="AutosuggestSearchBarStateDisplay --error"><em><BaseIcon icon="warning" /> Error loading results</em></div>
          )}
          {s.filteredResults.length > 0 && (
            <ul className="AutosuggestSearchBarResultList" style={{ position: p.listPosition }}>
              { s.filteredResults.map((d, i) => <li
                className="AutosuggestSearchBarResultItem"
                key={s.getItemKey(d, i)}
                data-action-position={p.actionPosition}
                data-disabled={p.entryShouldDisableChecker?.(d)}
              >
                <div 
                  className="AutosuggestSearchBarResultItemInner"
                  onClick={s.handleItemClick(d)}
                  children={p.resultItemContentRenderer(d)}
                />
                <div className="AutosuggestSearchBarResultItemActions">{
                  p.actions?.map(a => <BaseButton
                    key={a.name ?? a.label ?? a.icon}
                    icon={a.icon}
                    size="xs"
                    className="subtle"
                    dataCy={a.dataCy}
                    onClick={s.handleButtonAction(d, a)}
                    label={a.label} 
                    disabled={p.entryShouldDisableChecker?.(d)}
                  />)
                }</div>
              </li>)}
            </ul>
          )}
        </>
      }
    </div>
  )} />
}

export default AutosuggestSearchBar;