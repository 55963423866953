import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState, Renderable } from '../../@types/ui.types';
import { Primary } from '../../constants/color.enum';
import joinClassName from '../../utils/className.utils';
import { getContextColorStyle } from '../../utils/colors.utils';
import { renderRenderable } from '../../utils/components.utils';
import BaseIcon from '../BaseIcon/BaseIcon';
import { IconName } from '../Symbols/iconDefs';
import './InfoBanner.scss';

interface InfoBannerProps {
  className?: string,
  heading?: Renderable,
  body?: Renderable,
  icon?: IconName,
  colorCodedState?: ColorCodedState,
  color?: string,
  colorIntensity?: 'default' | 'intense'
  compact?: boolean,
}

const InfoBanner: React.FC<InfoBannerProps> = props => {
  return <Observer children={() => (
    <div
      className={joinClassName(
        'InfoBanner',
        props.className,
        props.colorCodedState && `state-${props.colorCodedState}`,
        props.colorIntensity === 'intense' && 'intense',
        props.compact && 'compact'
      )}
      style={props.color ? getContextColorStyle(Primary, props.color) : undefined}
    >
      <div className="InfoBannerInner">
        { props.icon && <BaseIcon className="InfoBannerIcon" name={props.icon} /> }
        {
          props.heading && (
            <header className="InfoBannerHeader">
              <h4 className="InfoBannerHeading">{renderRenderable(props.heading)}</h4>
            </header>
          )
        }
        {
          (props.body || props.children) && (
            <div className="InfoBannerBody">
              {renderRenderable(props.body)}
              {props.children}
            </div>
          )
        }
      </div>
    </div>
  )} />
}

export default InfoBanner;