import React from "react";
import { makeIconDef } from "./makeIconDef";

export const CounsellingIconDef = {
  counselling: makeIconDef('counselling', {
    regular: () => <path fillRule="evenodd" clipRule="evenodd" d="M21 4H3C2.44772 4 2 4.44772 2 5V17H2.08392C2.15891 16.5564 2.28373 16.1215 2.45672 15.7039C2.75825 14.9759 3.20021 14.3145 3.75736 13.7574C4.31451 13.2002 4.97595 12.7583 5.7039 12.4567C6.43185 12.1552 7.21207 12 8 12C8.78793 12 9.56815 12.1552 10.2961 12.4567C11.0241 12.7583 11.6855 13.2002 12.2426 13.7574C12.7998 14.3145 13.2417 14.9759 13.5433 15.7039C13.7163 16.1215 13.8411 16.5564 13.9161 17H22V5C22 4.44771 21.5523 4 21 4ZM8 17H3.10102C3.1647 16.6881 3.25818 16.3821 3.3806 16.0866C3.63188 15.48 4.00017 14.9288 4.46447 14.4645C4.92876 14.0002 5.47995 13.6319 6.08658 13.3806C6.69321 13.1293 7.34339 13 8 13C8.65661 13 9.30679 13.1293 9.91342 13.3806C10.52 13.6319 11.0712 14.0002 11.5355 14.4645C11.9998 14.9288 12.3681 15.48 12.6194 16.0866C12.7418 16.3821 12.8353 16.6881 12.899 17H8ZM1 17C1 17.5523 1.44772 18 2 18H3H8H13H14H22C22.5523 18 23 17.5523 23 17V5C23 3.89543 22.1046 3 21 3H3C1.89543 3 1 3.89543 1 5V17ZM16.3493 11.3205L15.3498 12L15.3491 11H15.0421H14.5063C14.2267 11 14 10.7761 14 10.5V7.5C14 7.22386 14.2267 7 14.5063 7H18.4937C18.7733 7 19 7.22386 19 7.5V10.5C19 10.7761 18.7733 11 18.4937 11H16.8208L16.3493 11.3205ZM17.1285 12H18.4937C19.3139 12 20 11.3401 20 10.5V7.5C20 6.6599 19.3139 6 18.4937 6H14.5063C13.6861 6 13 6.6599 13 7.5V10.5C13 11.2861 13.6007 11.9144 14.3498 11.9919L14.3498 12.0007L14.3512 13.8881L15.912 12.827L17.1285 12ZM10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8ZM11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8ZM0.5 20C0.223858 20 0 20.2239 0 20.5C0 20.7761 0.223857 21 0.5 21H23.5C23.7761 21 24 20.7761 24 20.5C24 20.2239 23.7761 20 23.5 20H0.5Z" fill="currentColor"/>,
    filled: () => <>
      <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M8 10.5C9.38071 10.5 10.5 9.38071 10.5 8C10.5 6.61929 9.38071 5.5 8 5.5C6.61929 5.5 5.5 6.61929 5.5 8C5.5 9.38071 6.61929 10.5 8 10.5ZM13.5433 15.7039C13.8448 16.4319 14 17.2121 14 18H8H2C2 17.2121 2.15519 16.4319 2.45672 15.7039C2.75825 14.9759 3.20021 14.3145 3.75736 13.7574C4.31451 13.2002 4.97595 12.7583 5.7039 12.4567C6.43185 12.1552 7.21207 12 8 12C8.78793 12 9.56815 12.1552 10.2961 12.4567C11.0241 12.7583 11.6855 13.2002 12.2426 13.7574C12.7998 14.3145 13.2417 14.9759 13.5433 15.7039Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1 5C1 3.89543 1.89543 3 3 3H21C22.1046 3 23 3.89543 23 5V17C23 17.5523 22.5523 18 22 18H14C14 17.2121 13.8448 16.4319 13.5433 15.7039C13.2417 14.9759 12.7998 14.3145 12.2426 13.7574C11.6855 13.2002 11.0241 12.7583 10.2961 12.4567C9.56815 12.1552 8.78793 12 8 12C7.21207 12 6.43185 12.1552 5.7039 12.4567C4.97595 12.7583 4.31451 13.2002 3.75736 13.7574C3.20021 14.3145 2.75825 14.9759 2.45672 15.7039C2.15519 16.4319 2 17.2121 2 18C1.44772 18 1 17.5523 1 17V5ZM14 6.5C14 6.22386 14.2267 6 14.5063 6H19.4937C19.7733 6 20 6.22386 20 6.5V10.5C20 10.7761 19.7733 11 19.4937 11H17.5L15.3498 12.5L15.3491 11H14.5063C14.2267 11 14 10.7761 14 10.5V6.5ZM10.5 8C10.5 9.38071 9.38071 10.5 8 10.5C6.61929 10.5 5.5 9.38071 5.5 8C5.5 6.61929 6.61929 5.5 8 5.5C9.38071 5.5 10.5 6.61929 10.5 8Z" fill="currentColor" />
      <rect y="19" width="24" height="2" rx="1" fill="currentColor" />
    </>,
    color: () => <>
      <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M8 10.5C9.38071 10.5 10.5 9.38071 10.5 8C10.5 6.61929 9.38071 5.5 8 5.5C6.61929 5.5 5.5 6.61929 5.5 8C5.5 9.38071 6.61929 10.5 8 10.5ZM13.5433 15.7039C13.8448 16.4319 14 17.2121 14 18H8L2 18C2 17.2121 2.15519 16.4319 2.45672 15.7039C2.75825 14.9759 3.20021 14.3145 3.75736 13.7574C4.31451 13.2002 4.97595 12.7583 5.7039 12.4567C6.43185 12.1552 7.21207 12 8 12C8.78793 12 9.56815 12.1552 10.2961 12.4567C11.0241 12.7583 11.6855 13.2002 12.2426 13.7574C12.7998 14.3145 13.2417 14.9759 13.5433 15.7039Z" fill="#A98AEA" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1 5C1 3.89543 1.89543 3 3 3H21C22.1046 3 23 3.89543 23 5V17C23 17.5523 22.5523 18 22 18H14C14 17.2121 13.8448 16.4319 13.5433 15.7039C13.2417 14.9759 12.7998 14.3145 12.2426 13.7574C11.6855 13.2002 11.0241 12.7583 10.2961 12.4567C9.56815 12.1552 8.78793 12 8 12C7.21207 12 6.43185 12.1552 5.7039 12.4567C4.97595 12.7583 4.31451 13.2002 3.75736 13.7574C3.20021 14.3145 2.75825 14.9759 2.45672 15.7039C2.15519 16.4319 2 17.2121 2 18C1.44772 18 1 17.5523 1 17V5ZM14 6.5C14 6.22386 14.2267 6 14.5063 6H19.4937C19.7733 6 20 6.22386 20 6.5V10.5C20 10.7761 19.7733 11 19.4937 11H17.5L15.3498 12.5L15.3491 11H14.5063C14.2267 11 14 10.7761 14 10.5V6.5ZM10.5 8C10.5 9.38071 9.38071 10.5 8 10.5C6.61929 10.5 5.5 9.38071 5.5 8C5.5 6.61929 6.61929 5.5 8 5.5C9.38071 5.5 10.5 6.61929 10.5 8Z" fill="url(#VON2ONF0_radial)" />
      <rect y="19" width="24" height="2" rx="1" fill="url(#VON2ONF1_radial)" />
      <defs>
        <radialGradient id="VON2ONF0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23 20.5) rotate(-131.82) scale(25.4951 37.3928)">
          <stop stopColor="#A980DC" />
          <stop offset="1" stopColor="#7E48F2" />
        </radialGradient>
        <radialGradient id="VON2ONF1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(4.5 16) rotate(19.179) scale(24.3516 292.219)">
          <stop stopColor="#62329E" />
          <stop offset="1" stopColor="#2B065A" />
        </radialGradient>
      </defs>
    </>
  })
}