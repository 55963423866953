import moment from "moment";
import { Validator } from "../@types";

export const validateDate: Validator = (
  value?: string
) => {
  const isValid = value ? /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(value) && moment(value).isValid() : false;
  const message = value ? (
    `${value?.toString()} is not a valid date.`
  ) : (
    'Please provide a valid date.'
  );
  return isValid || {
    isValid,
    message
  }
}