import { Observer } from 'mobx-react-lite';
import React from 'react';
import ExampleImage from '../../assets/images/pexels-photo-3184398.jpeg';
import tick from '../../base/utils/waiters.utils';
import { getWordPressLink } from '../../env';
import ClientSupportGroupFinder from '../../modules/Client/_components/ClientSupportGroupFinder/ClientSupportGroupFinder';
import ServiceCard from '../ServiceCard/ServiceCard';
// import './ServiceCardSupportGroups.scss';

interface ServiceCardSupportGroupsProps {
  cardRatio?: number,
}

const ServiceCardSupportGroups: React.FC<ServiceCardSupportGroupsProps> = props => {

  const automaticallyRedirectToSupportGroupFinderPage = async () => {
    await tick(1000);
    document.querySelector<HTMLAnchorElement>('.AppNavTabInner[data-identifier="support-groups"]')?.click();
  }
  
  return <Observer children={() => (
    <ServiceCard
      className="ServiceCardSupportGroups"
      title={<>Support<br />Groups</>}
      stringTitle="Support Groups"
      sideABackgroundColor="#07A78A"
      sideABackgroundImage={`url(${ExampleImage})`}
      sideBRenderable={ClientSupportGroupFinder}
      src={getWordPressLink('/services/support-groups')}
      listenForWindowMessage="support-groups"
      onReceiveWindowMessage={automaticallyRedirectToSupportGroupFinderPage}
      cardRatio={props.cardRatio}
    />
  )} />
}

export default ServiceCardSupportGroups;