import { Observer } from 'mobx-react-lite';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Primary } from '../../constants/color.enum';
import joinClassName from '../../utils/className.utils';
import { getContextColorStyle } from '../../utils/colors.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import BaseIcon from '../BaseIcon/BaseIcon';
import ColorTag from '../ColorTag/ColorTag';
import { IconName } from '../Symbols/iconDefs';
import './TabGroupItem.scss';

export type TabConfig = {
  label: React.ReactChild,
  name?: string,
  title?: string,
  icon?: IconName,
  color?: string,
  countBubbleColor?: string,
  disabled?: boolean,
  active?: () => boolean,
  count?: number,
  onClick?: () => void,
  to?: string,
  href?: string,
  exact?: boolean,
}

type TabGroupItemProps = TabConfig;

const TabGroupItem: React.FC<TabGroupItemProps> = props => {

  const p = useProps(props);
  
  const s = useStore(() => ({
    get isActive() {
      return p.active?.();
    },
    get commonAttr() {
      return {
        className: joinClassName(
          'TabGroupItem',
          s.isActive && 'active',
          p.disabled && 'disabled',
        ),
        title: p.title || p.label + '',
        onClick: p.onClick,
        children: renderInner(),
        style: s.style,
      }
    },
    get style() {
      return getContextColorStyle(Primary, p.color);
    }
  }))

  const renderInner = () => <>
    {p.icon && <BaseIcon className="TabGroupItemLabelIcon" name={p.icon} variant={ s.isActive ? 'filled' : undefined }/>}
    <span className="TabGroupItemLabel">
      {p.label}
      <ColorTag color={p.countBubbleColor || 'red'}>{p.count}</ColorTag>
    </span>
  </>

  const renderOuter = () => {
    if (p.to) return <NavLink {...s.commonAttr} to={p.to!} exact={p.exact} />
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    if (p.href) return <a {...s.commonAttr} href={p.href} />
    return <div {...s.commonAttr} />
  }

  return <Observer children={() => renderOuter()} />

}

export default TabGroupItem;