import { CounsellingSession, CounsellingSessionRelatedModels } from "../../models/makeCounsellingSession.model";
import { DefaultIndexParamSet } from "../utils/api.utils";
import { makeApiEndpointConfig, makeApiGetEndpointConfig } from "../utils/endpoints.utils";
import { makeUrl } from "../utils/url.utils";
import { prefixOwn, prefixStaff } from "./namespacePrefixes";

export type CounsellingSessionEndpointParams = DefaultIndexParamSet<
  CounsellingSession,
  CounsellingSessionRelatedModels,
  {
    clientId?: string,
  }
> & {
  include?: (keyof CounsellingSessionRelatedModels | string)[],
  latest?: boolean,
}

export const DefaultCounsellingSessionIncludesForStaff: (keyof CounsellingSessionRelatedModels | string)[] = [
  'assignments.target',
  'threads',
  'clients',
  'invoiceItems.invoice',
  'counsellor',
  'counsellor.permissions',
  'counsellor.roles',
]
export const DefaultCounsellingSessionIncludesForClient: (keyof CounsellingSessionRelatedModels | string)[] = [
  'assignments.target',
  'clients',
]

const ownUrlBase = prefixOwn('counselling-sessions');
const staffUrlBase = prefixStaff('counselling-sessions');

export const CounsellingSessionEndpoints = {
  own: {
    index: makeApiEndpointConfig<CounsellingSessionEndpointParams>(ownUrlBase),
    get: makeApiGetEndpointConfig<CounsellingSessionEndpointParams>(ownUrlBase),
    update: (id: string) => makeUrl(ownUrlBase, id),
  },
  staff: {
    index: makeApiEndpointConfig<CounsellingSessionEndpointParams>(staffUrlBase),
    get: makeApiGetEndpointConfig<CounsellingSessionEndpointParams>(staffUrlBase),
    create: () => staffUrlBase,
    update: (id: string) => makeUrl(staffUrlBase, id),
    delete: (id: string) => makeUrl(staffUrlBase, id),
    start: (id: string) => makeUrl(staffUrlBase, id, 'start'),
    end: (id: string) => makeUrl(staffUrlBase, id, 'end'),
  },
}