import { UserEndpoints } from "../base/endpoints/user.endpoints";
import { ModelName } from "../constants/modelNames.enum";
import { User } from "../models/makeUser.model";
import { useAutoGet, UseAutoGetFactoryOptions } from "../utils/useAutoGet.utils";

export const useGetFacilitators = <T extends object>(
  options: UseAutoGetFactoryOptions<User[], T>
) => {
  useAutoGet(
    {
      asIndex: true,
      allowCache: true,
      defaultValue: [] as User[],
      ...options,
      modelName: ModelName.users,
      url: UserEndpoints.staff.facilitatorIndex({ perPage: 1000 }),
    }
  )
}