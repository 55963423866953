import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import RenderIf from '../../base/components/RenderIf/RenderIf';
import { ContextColor } from '../../base/constants/color.enum';
import { useContextColorStyle } from '../../base/hooks/useContextColor.hook';
import { ChatMediator } from '../../base/mediators/chat.mediator';
import { useStore } from '../../base/utils/mobx.utils';
import MessengerPanelChatEntry from './MessengerPanelChatEntry';
import './MessengerPanelSessionList.scss';

interface MessengerPanelSessionListProps {
  title?: string,
  sessions: ChatMediator[],
  contextColorAlias?: string,
  prefix?: string,
}

function MessengerPanelSessionList(p: React.PropsWithChildren<MessengerPanelSessionListProps>) {
  const style = useContextColorStyle(ContextColor.Primary, p.contextColorAlias)
  const s = useStore(() => ({
    open: true,
  }))
  const toggle = action(() => s.open = !s.open);
  return <Observer children={() => (
    <div className="MessengerPanelSessionList" style={style}>
      <header onClick={toggle}>
        <h3>{p.title || 'Chats'} ({p.sessions.length})</h3>
        <BaseIcon icon={s.open ? 'chevron-down' : 'chevron-up'} variant="filled" />
      </header>
      {
        s.open && <div>
          <RenderIf if={p.sessions.length > 0} fallback={<p className="MessengerPanelSessionListEmptyNotice">No active {(p.title || 'chats').toLowerCase()}.</p>} component={() => <>
            {p.sessions.map(t => <MessengerPanelChatEntry
              key={t.id}
              chat={t}
              prefix={p.prefix}
            />)}
          </>} />
        </div>
      }
    </div>
  )} />
}

export default MessengerPanelSessionList;