import { action, flow, observable, when } from "mobx";
import { EagerRouteDef, HistoryLocation, LocationChangeEventHandler, RouteDef } from "../base/@types";
import { Renderable } from "../base/@types/ui.types";
import { removeFromArray } from "../base/utils/array.utils";
import { NoOp } from "../base/utils/functions.utils";
import { last } from "../base/utils/ramdaEquivalents.utils";
import { KnownURLParams, removeUrlParam, setUrlParam } from "../base/utils/urlParams.utils";
import { tick } from "../base/utils/waiters.utils";
import { SHOULD_LOG } from "../env";
import { makeControllerBase, makeRootControllerChildInitFn } from "./_root.controller";

/**
 * Manages the window navigation and location history internally.
 * It has a few components to help maintaining the state and page favicon/titles:
 * URLParamWatcher NavigatorRedirector HistoryWatcher FaviconManager TitleManager RedirectRouteCollection
 * You can see those being included in App.tsx when the RootController is ready.
 */
export type NavigatorController = ReturnType<typeof makeNavigatorController>;

export const logAsNavigator = (...message: any[]) => {
  SHOULD_LOG() && console.log('%c[NAVIGATOR]', 'color: #3EE7C8', ...message);
}

export const makeNavigatorController = (routes?: RouteDef[]) => {

  const _routeChangeEventHandlers: LocationChangeEventHandler[] = [];
  const _pathnameChangeEventHandlers: LocationChangeEventHandler[] = [];

  const _handleRouteChange = async (current?: HistoryLocation, previous?: HistoryLocation) => {
    for (let handler of _routeChangeEventHandlers) {
      await handler(current, previous);
    }
  };
  const _handlePathnameChange = async (current?: HistoryLocation, previous?: HistoryLocation) => {
    for (let handler of _pathnameChangeEventHandlers) {
      await handler(current, previous);
    }
  };

  const c = observable({
    ...makeControllerBase('NAVIGATOR'),
    debug: false,
    routes: routes ?? [],
    shouldNavigateTo: undefined as string | undefined,
    navigateTo: async (path: string, bypassRelativePathWarning?: boolean) => await flow(function * () {
      const isPossiblyWrongRelativePath = path.match(/^(app|admin|auth)\//);
      if (isPossiblyWrongRelativePath && bypassRelativePathWarning !== true) {
        throw Error(`The path "${path}" you have passed to NAVIGATOR.navigateTo starts with "${isPossiblyWrongRelativePath[1]}/", which is a relative path to the current page. Do you mean to start with "/${isPossiblyWrongRelativePath[1]}" which starts from the root?`);
      }
      if (path.match(/^http(s)?:\/\//)) {
        window.location.href = path;
        return;
      }
      if (!c.ROOT?.ready) {
        yield when(() => !!c.ROOT?.ready);
        yield tick();
      }
      c.shouldNavigateTo = path;
      yield tick();
      c.shouldNavigateTo = undefined;
    })(),
    title: undefined as Renderable,
    setTitle: action((value: Renderable) => c.title = value),
    locationHistory: [] as HistoryLocation[],
    get area(): string | undefined {
      return c.currentLocationPathname.match(/^\/([^?/]+)/)?.[1];
    },
    get isInAdminArea(): boolean {
      return c.area === 'admin'
    },
    get isInClientArea(): boolean {
      return c.area === 'app'
    },
    recordLocationHistory: action((location: HistoryLocation) => {
      if (c.debug) logAsNavigator('To:', location.pathname + location.search);
      const pathnameHasChanged = location.pathname !== c.currentLocation?.pathname;
      if (
        location.pathname === c.currentLocation?.pathname &&
        location.search === c.currentLocation?.search &&
        location.hash === c.currentLocation?.hash
      ) return;
      const previous = c.currentLocation;
      c.locationHistory.push(location);
      _handleRouteChange(location, previous);
      if (pathnameHasChanged) _handlePathnameChange(location, previous);
    }),
    get appRoutes(): EagerRouteDef[] {
      return c.routes.find(r => r.identifier === 'app')?.children as EagerRouteDef[] || [];
    },
    get currentLocation(): HistoryLocation | undefined {
      return last(c.locationHistory)
    },
    onRouteChange(listener: LocationChangeEventHandler) {
      _routeChangeEventHandlers.push(listener);
      return () => {
        removeFromArray(_routeChangeEventHandlers, listener)
      };
    },
    onPathnameChange(listener: LocationChangeEventHandler) {
      _pathnameChangeEventHandlers.push(listener);
      return () => {
        removeFromArray(_pathnameChangeEventHandlers, listener)
      };
    },
    get currentLocationPathname(): string {
      return c.currentLocation?.pathname ?? '';
    },
    get currentLocationPathnameAndSearch(): string {
      return [c.currentLocation?.pathname, c.currentLocation?.search].filter(i=>i).join('');
    },
    get topLevelModuleName(): string {
      const urlPartials = c.currentLocationPathname.split('/').filter(i=>i);
      return urlPartials[0] || 'public';
    },
    setUrlParam: (paramName: KnownURLParams, value: string | number | boolean, triggerWatcher: boolean = true) => {
      setUrlParam(paramName, value, triggerWatcher ? c : undefined);
    },
    removeUrlParam: (paramName: KnownURLParams, triggerWatcher: boolean = true) => {
      removeUrlParam(paramName, triggerWatcher ? c : undefined);
    },
    titleStack: [] as { id: string, title: string }[],
    reset: NoOp,
  });

  c.init = makeRootControllerChildInitFn(
    c,
    flow(function* () {
    })
  );

  return c;

}