import { action } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { AnyObject, Nillable, SortDirection } from "../../@types";
import joinClassName from "../../utils/className.utils";
import { renderRenderable } from "../../utils/components.utils";
import { useProps, useStore } from "../../utils/mobx.utils";
import { sanitize } from "../../utils/sanitizer.utils";
import { isString } from "../../utils/typeChecks.utils";
import SortToggle from "../SortToggle/SortToggle";
import { BaseTableColumnConfig, BaseTableProps, getKeyNameFromColumnConfig } from "./BaseTable";
import './BaseTableHeadCell.scss';

interface BaseTableHeadCellProps<
  EntryType extends AnyObject = {}
> extends BaseTableProps<EntryType> {
  columnConfig: BaseTableColumnConfig<EntryType>,
  data: EntryType[],
}

export function BaseTableHeadCell<T extends AnyObject = {}>(
  props: React.PropsWithChildren<BaseTableHeadCellProps<T>>
) {
  const p = useProps(props);
  const s = useStore(() => ({
    get config() {
      return p.columnConfig;
    },
    get keyName() {
      return getKeyNameFromColumnConfig(s.config);
    },
    get label() {
      return s.config.label ? isString(s.config.label) ? s.config.label : renderRenderable(s.config.label) : s.config.keyName;
    },
    defaultRenderer: (data: T[]) => isString(s.label) ? <span dangerouslySetInnerHTML={{ __html: sanitize(s.label) }} /> : <span>{ s.label }</span>,
    get renderer() {
      return s.config.HeadCell || s.defaultRenderer;
    },
    get currentSortByKeyName() {
      return p.sortByKeyName;
    },
    get isSortingByCurrentColumn() {
      return s.currentSortByKeyName === s.keyName;
    },
    get currentSortDirection() {
      return p.sortDirection;
    },
    get type() {
      return s.config.type;
    },
    handleClick: action(() => {
      if (!s.keyName || !s.config.sortable) return;
      let newDirection: Nillable<SortDirection> = null;
      if (s.isSortingByCurrentColumn) {
        switch (s.currentSortDirection) {
          case 'asc': newDirection = 'desc'; break;
          case 'desc': newDirection = null; break;
          default: newDirection = 'asc'; break;
        }
      } else {
        newDirection = 'asc';
      }
      p.onSort && p.onSort(
        newDirection ? '' + s.keyName : null, 
        newDirection || undefined
      );
    }),
    get style() {
      return s.config.headCellStyleFactory && s.config.headCellStyleFactory();
    }
  }));
  return <Observer children={() => (
    <th className={joinClassName("BaseTableHeadCell", s.config.sortable && 'sortable')}
      data-type={s.config.type}
      data-sortable={!!s.config.sortable}
      data-sort-direction={s.isSortingByCurrentColumn ? s.currentSortDirection : 'false'}
      onClick={s.handleClick}
      style={s.style}
    >
      <div className="BaseTableHeadCellInner">
        {s.config.sortable && <SortToggle direction={s.isSortingByCurrentColumn ? s.currentSortDirection : undefined} />}
        <div className="BaseTableHeadCellInnerContent">{s.renderer(p.data)}</div>
      </div>
    </th>
  )} />
} 


export default BaseTableHeadCell;