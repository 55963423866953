import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState } from '../../base/@types';
import BaseGrid from '../../base/components/BaseGrid/BaseGrid';
import BaseGridCell from '../../base/components/BaseGrid/BaseGridCell';
import FormColorPicker from '../../base/components/FormColorPicker/FormColorPicker';
import FormCountrySelector from '../../base/components/FormCountrySelector/FormCountrySelector';
import FormDatePicker from '../../base/components/FormDatePicker/FormDatePicker';
import FormForm from '../../base/components/FormForm/FormForm';
import FormInput from '../../base/components/FormInput/FormInput';
import FormTelInput from '../../base/components/FormTelInput/FormTelInput';
import InfoBanner from '../../base/components/InfoBanner/InfoBanner';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { Form } from '../../base/mediators/form.mediator';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { ModelName } from '../../constants/modelNames.enum';
import { User, UserSnapshot } from '../../models/makeUser.model';
import { getAgeFromDateOfBirth } from '../../utils/ageAndDateOfBirth.utils';
import { checkIfStringContainsWord_turn2me } from '../../utils/user.utils';
import FormCompanySelector from '../FormCompanySelector/FormCompanySelector';
import { GenderSelector } from '../GenderSelector/GenderSelector';
import { SexualitySelector } from '../SexualitySelector/SexualitySelector';
// import './UserInfoEditor.scss';

interface UserInfoEditorProps {
  form: Form<UserSnapshot>,
  onSave?: (u: UserSnapshot) => any,
  onBlur?: (u: UserSnapshot) => any,
  applyChangesOnBlur?: boolean,
  canEditColor?: boolean,
  requireEmail?: boolean,
  requireMobileNumber?: boolean,
  mustUpdateDateOfBirth?: boolean,
  showMoreOptionsSection?: boolean,
  showCompanySelector?: boolean,
}

const UserInfoEditor: React.FC<UserInfoEditorProps> = props => {

  const { AUTH, LOCALDB } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    originalDateOfBirth: props.form.originalSource.dateOfBirth,
    get form() {
      return p.form;
    },
    handleBlur: () => {
      p.onBlur && p.onBlur(s.form.value);
      if (p.applyChangesOnBlur) {
        s.form.markAsSaved();
      }
    },
    get user() {
      return LOCALDB.get<User>(ModelName.users, p.form.value.id);
    },
    get isEditingSelf() {
      return AUTH.currentUser?.id && (s.form.value.id === AUTH.currentUser.id);
    },
    mustUpdateDateOfBirth: props.mustUpdateDateOfBirth,
    get usernameIncludesWord_turn2me() {
      return checkIfStringContainsWord_turn2me(s.form.value.username);
    },
    get age() {
      return s.form.fields.dateOfBirth.value ? getAgeFromDateOfBirth(s.form.fields.dateOfBirth.value) : null;
    },
    get isEditingCounsellor() {
      return s.user?.isCounsellor || s.user?.isAdmin;
    },
  }));

  useOnMount(action(() => {
    if (props.mustUpdateDateOfBirth) return;
    if (!s.form.value.dateOfBirth) {
      s.mustUpdateDateOfBirth = true;
    }
  }))

  return <Observer children={() => (
    <FormForm
      className="UserInfoEditor"
      form={s.form}
      onBlur={s.handleBlur}
      children={context => <BaseGrid columns={context.resizeQuery?.fromPhoneLg ? 2 : 1}>
        {
          s.isEditingSelf && !AUTH.isStaff && s.usernameIncludesWord_turn2me && <BaseGridCell columns="all">
            <InfoBanner colorCodedState={ColorCodedState.alert} icon="warning">
              Your username should not include the word "turn2me".
            </InfoBanner>
          </BaseGridCell>
        }
        {
          !s.isEditingSelf && s.user && !s.user.isStaff && s.usernameIncludesWord_turn2me && <BaseGridCell columns="all">
            <InfoBanner colorCodedState={ColorCodedState.alert} icon="warning">
              Non-staff user's username should not include the word "turn2me".
            </InfoBanner>
          </BaseGridCell>
        }
        <FormInput<User> field="username" label="Username" required dataCy="username" autoComplete="off" />
        <FormDatePicker<User>
          dataCy="dateOfBirth"
          field="dateOfBirth"
          label="Date of Birth"
          required
          onClick={action(() => s.mustUpdateDateOfBirth = false)}
          disabled={s.originalDateOfBirth && !AUTH.isStaff}
          shouldUseSystemUIOnMobile
          autoComplete="off"
        />
        {
          s.mustUpdateDateOfBirth && <BaseGridCell columns="all">
            <InfoBanner colorCodedState={ColorCodedState.alert} icon="warning">
              {s.isEditingSelf ? 'Please review your date of birth.' : 'Please review the date of birth.'}
            </InfoBanner>
          </BaseGridCell>
        }
        <FormInput<User> field="firstName" label="First Name" required dataCy="firstName" autoComplete="off" />
        <FormInput<User> field="lastName" label="Last Name" required dataCy="lastName" autoComplete="off" />
        <FormInput<User> field="email" label="Email" type="email" required={p.requireEmail} dataCy="email" autoComplete="off" />
        <FormTelInput<User> field="mobileNumber" label="Mobile Number" required={p.requireMobileNumber} dataCy="mobileNumber" autoComplete="off" />
        <GenderSelector form={s.form.fields.gender} field="value" name="gender" label="Gender" appearance="system" optional dataCy="gender" autoComplete="off" />
        <SexualitySelector form={s.form.fields.sexuality} field="value" name="sexuality" label="Sexuality" appearance="system" optional dataCy="sexuality" autoComplete="off" />
        <FormCountrySelector<User> field="countryProvidedId" label="Country of Residence" required dataCy="countryProvidedId" autoComplete="false" />
        {s.isEditingCounsellor && <FormInput<User> field="iacpMembershipNumber" label="IACP Membership Number" optional dataCy="iacpMembershipNumber" autoComplete="off" />}
        <BaseGridCell columns={s.isEditingCounsellor ? "all" : undefined}>
          {p.showCompanySelector && <FormCompanySelector form={s.form} field="companyId" label="Company" optional placeholder="" />}
        </BaseGridCell>
        {p.canEditColor && <BaseGridCell columns="all">
          <FormColorPicker form={s.form} field="color" label="User Icon Color" />
        </BaseGridCell> }
      </BaseGrid>}
    />
  )} />

}

export default UserInfoEditor;