import { Observer } from "mobx-react-lite";
import React from "react";
import { useMakeFocusable } from "../../../hooks/useMakeFocusable.hook";
import { useObservableRef } from "../../hooks/useObservableRef.hook";
import { useControllers, useUIController } from "../../hooks/useRootController.hook";
import Overlay from "./Overlay";

export const OverlayStack: React.FC = props => {
  const { UI } = useControllers();
  const { OVERLAY } = useUIController();
  const ref = useObservableRef();
  useMakeFocusable(ref, '.Overlay');
  return <Observer children={() => {
    return UI.PORTAL.render(
      <div className="OverlayStack" ref={ref}>
        {OVERLAY.overlays.map((o, i) => <Overlay overlay={o} key={o.id} index={i} />)}
      </div>
    )
  }} />
}

export default OverlayStack;