import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseArticle from '../../base/components/BaseArticle/BaseArticle';
import InfoTable from '../../base/components/InfoTable/InfoTable';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { calculatePHQ9SurveyScore, getPHQ9ScoreType, SurveyPHQ9 } from '../../models/makeSurveyPHQ9.model';
import SurveySelectorWithFourLevels from '../SurveySelectorWithFourLevels/SurveySelectorWithFourLevels';
import './SurveyEditorPHQ9.scss';

type SurveyEditorPHQ9Props = {
	survey: SurveyPHQ9,
	readonly?: any,
	showScore?: any,
}

const SurveyEditorPHQ9: React.FC<SurveyEditorPHQ9Props> = props => {

	const p = useProps(props);

	const s = useStore(() => ({
		get score() {
			return calculatePHQ9SurveyScore(p.survey) || 0;
		},
		get scoreType() {
			return getPHQ9ScoreType(s.score)
		}
	}));
	
	return <Observer children={() => (
		<BaseArticle className="SurveyEditorPHQ9">
			<h3>Over the last 2 weeks, how often have you been bothered by the following problems?</h3>
			<div className="SurveyEditorPHQ9Question">
				<p>Little interest or pleasure in doing things:</p>
				<SurveySelectorWithFourLevels readonly={p.readonly} form={p.survey} field="levelOfInterestDeficit" showScore={p.showScore} />
			</div>
			<div className="SurveyEditorPHQ9Question">
				<p>Feeling down, depressed, or hopeless:</p>
				<SurveySelectorWithFourLevels readonly={p.readonly} form={p.survey} field="levelOfDepression" showScore={p.showScore} />
			</div>
			<div className="SurveyEditorPHQ9Question">
				<p>Trouble falling or staying asleep, or sleeping too much:</p>
				<SurveySelectorWithFourLevels readonly={p.readonly} form={p.survey} field="levelOfSleepDisorder" showScore={p.showScore} />
			</div>
			<div className="SurveyEditorPHQ9Question">
				<p>Feeling tired or having little energy:</p>
				<SurveySelectorWithFourLevels readonly={p.readonly} form={p.survey} field="levelOfEnergyDeficit" showScore={p.showScore} />
			</div>
			<div className="SurveyEditorPHQ9Question">
				<p>Poor appetite or overeating:</p>
				<SurveySelectorWithFourLevels readonly={p.readonly} form={p.survey} field="levelOfAppetiteDeficit" showScore={p.showScore} />
			</div>
			<div className="SurveyEditorPHQ9Question">
				<p>Feeling bad about yourself - or that you are a failure or have let yourself or your family down:</p>
				<SurveySelectorWithFourLevels readonly={p.readonly} form={p.survey} field="levelOfSelfworthDeficit" showScore={p.showScore} />
			</div>
			<div className="SurveyEditorPHQ9Question">
				<p>Trouble concentrating on things, such as reading the newspaper or watching television:</p>
				<SurveySelectorWithFourLevels readonly={p.readonly} form={p.survey} field="levelOfConcentrationDeficit" showScore={p.showScore} />
			</div>
			<div className="SurveyEditorPHQ9Question">
				<p>Moving or speaking so slowly that other people could have noticed? Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual:</p>
				<SurveySelectorWithFourLevels readonly={p.readonly} form={p.survey} field="levelOfHypoOrHyperactivity" showScore={p.showScore} />
			</div>
			<div className="SurveyEditorPHQ9Question">
				<p>Thoughts that you would be better off dead or of hurting yourself in some way:</p>
				<SurveySelectorWithFourLevels readonly={p.readonly} form={p.survey} field="levelOfSelfHarmThoughtsFrequency" showScore={p.showScore} />
			</div>
			<div className="SurveyEditorPHQ9Question">
				<p>If you checked off any problems, how difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?</p>
				<SurveySelectorWithFourLevels readonly={p.readonly} form={p.survey} field="levelOfPsychologicalImpact" labelType="difficulty" nullable showScore={p.showScore} />
			</div>
			{p.showScore && <ShadedBlock>
				<h4>Total Score: {s.score} / 27, <br />{s.scoreType}</h4>
				<br />
				<p>Reference table: </p>
				<InfoTable>
					<thead>
						<tr><th>Total Score</th><th>Depression Severity</th></tr>
					</thead>
					<tbody>
						<tr><td>1–4</td><td>Minimal depression</td></tr>
						<tr><td>5–9</td><td>Mild depression</td></tr>
						<tr><td>10–14</td><td>Moderate depression</td></tr>
						<tr><td>15–19</td><td>Moderately severe depression</td></tr>
						<tr><td>20–27</td><td>Severe depression</td></tr>
					</tbody>
				</InfoTable>
			</ShadedBlock>}
		</BaseArticle>
	)} />
}

export default SurveyEditorPHQ9;