import { flow } from "mobx";
import { CounsellingApplicationEndpoints } from "../base/endpoints/counsellingApplication.endpoints";
import { getNowTimestampUtc } from "../base/utils/time.utils";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { CounsellingApplication, CounsellingApplicationSnapshot } from "../models/makeCounsellingApplication.model";

export const archiveCounsellingApplication = (
  application: CounsellingApplication,
  API: APIController
  ) => new Promise<CounsellingApplication>(
  flow(function * (resolve, reject) {
    const { id } = application;
    const url = CounsellingApplicationEndpoints.staff.update(id);
    const now = getNowTimestampUtc();
    const payload: Partial<CounsellingApplicationSnapshot> = {
      id,
      timeArchived: now,
    };
    try {
      yield API.patch(url, ModelName.counsellingApplications, payload);
      application.timeArchived = now;
      resolve(application);
    } catch (e) {
      reject(e);
    }
  })
)