import { InvoiceItem, InvoiceItemRelatedModels } from "../../models/makeInvoiceItem.models";
import { DefaultIndexParamSet } from "../utils/api.utils";
import { makeApiEndpointConfig, makeApiGetEndpointConfig } from "../utils/endpoints.utils";
import { makeUrl } from "../utils/url.utils";
import { prefixStaff } from "./namespacePrefixes";

export type InvoiceItemEndpointParams = DefaultIndexParamSet<InvoiceItem, InvoiceItemRelatedModels> & {
  include?: (keyof InvoiceItemRelatedModels | string)[],
}

const staffUrlBase = prefixStaff('invoice-items');

export const InvoiceItemEndpoints = {
  staff: {
    index: makeApiEndpointConfig<InvoiceItemEndpointParams>(staffUrlBase),
    get: makeApiGetEndpointConfig<InvoiceItemEndpointParams>(staffUrlBase),
    create: () => staffUrlBase,
    update: (id: string) => makeUrl(staffUrlBase, id),
    delete: (id: string) => makeUrl(staffUrlBase, id),
  },
  own: {
    index: makeApiEndpointConfig<InvoiceItemEndpointParams>(staffUrlBase),
    get: makeApiGetEndpointConfig<InvoiceItemEndpointParams>(staffUrlBase),
    create: () => staffUrlBase,
    update: (id: string) => makeUrl(staffUrlBase, id),
    delete: (id: string) => makeUrl(staffUrlBase, id),
  }
}
