import { Observer } from 'mobx-react-lite';
import React from 'react';
import AppPage from '../../base/components/AppPage/AppPage';
import AppPageContent from '../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../base/components/AppPageHeader/AppPageHeader';
import BaseGrid from '../../base/components/BaseGrid/BaseGrid';
import BaseHeading from '../../base/components/BaseHeading/BaseHeading';
import BaseSpacer from '../../base/components/BaseSpacer/BaseSpacer';
import BaseTable from '../../base/components/BaseTable/BaseTable';
import CurrencyRenderer from '../../base/components/CurrencyRenderer/CurrencyRenderer';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import InfoTable from '../../base/components/InfoTable/InfoTable';
import OverlayCloseButton from '../../base/components/OverlayCloseButton/OverlayCloseButton';
import PseudoLink from '../../base/components/PseudoLink/PseudoLink';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import UIBlock from '../../base/components/UIBlock/UIBlock';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { capitalize } from '../../base/utils/string.utils';
import { setUrlParam } from '../../base/utils/urlParams.utils';
import { ApiModelDefMap, ApiModelName, PseudoApiModelName } from '../../constants/ApiModels.enum';
import { UIController } from '../../controllers/ui.controller';
import { CounsellingSession } from '../../models/makeCounsellingSession.model';
import { Payment } from '../../models/makePayment.model';
import { User } from '../../models/makeUser.model';
import { useGetUser } from '../../requests/useGetUser.request';
import AddressRenderer from '../AddressRenderer/AddressRenderer';
import PrintButton from '../PrintButton/PrintButton';
import Turn2MeLogo from '../Turn2MeLogo/Turn2MeLogo';
import UserFullNameRenderer from '../UserFullNameRenderer/UserFullNameRenderer';
import './OverlayReceipt.scss';

export const openOverlayReceipt = (UI: UIController, payment: Payment) => {
  UI.OVERLAY.present({
    name: 'OverlayReceipt',
    component: <OverlayReceipt
      payment={payment}
    />,
    appearance: 'sheet',
  })
}

type OverlayReceiptProps = {
  payment: Payment,
}

const OverlayReceipt: React.FC<OverlayReceiptProps> = props => {
  const { NAVIGATOR } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    _user: null as User | null,
    get user() {
      return s._user;
    },
    _counsellor: null as User | null,
    get tableItems() {
      return [p.payment];
    },
  }))

  useGetUser({ observable: s, key: '_user' }, p.payment.userId)
  useGetUser({
    observable: s, key: '_counsellor', when: () => {
      // from modelType, if sessions,
      // then fetch session + counsellor info?
      switch (p.payment.modelType) {
        case ApiModelName.COUNSELLING_SESSION:
          return true;
        case PseudoApiModelName.DONATION:
        default:
          return false;
      }
    }
  }, (p.payment.model as CounsellingSession).counsellorId)

  const viewSession = () => {
    if (p.payment.modelType === ApiModelName.COUNSELLING_SESSION)
      setUrlParam('sessionId', p.payment.modelId, NAVIGATOR);
  }

  return <Observer children={() => (
    <AppPage className="OverlayReceipt">
      <AppPageHeader
        title={`Receipt #${p.payment.id}`}
        endSlot={<><PrintButton rounded /><BaseSpacer inline /><OverlayCloseButton /></>}
        spaceChildren
      />
      <AppPageContent padSections>
        <UIBlock padded>
          <div className="DocumentHeader">
            <BaseHeading level={2} color="skyblue">Receipt</BaseHeading>
            <Turn2MeLogo version="full" />
          </div>

          <span className="PrintSpacer"><BaseSpacer size="xl" /></span>

          <ShadedBlock>
            <BaseGrid columns={2}>
              <InfoTable>
                <tbody>
                  <tr>
                    <th>Receipt No.</th>
                    <td>{`#${p.payment.id}`}</td>
                  </tr>
                  <tr>
                    <th>Date:</th>
                    <td>
                      <DateRenderer value={p.payment.timeCreated} format="LLL" />
                    </td>
                  </tr>
                </tbody>
              </InfoTable>
              <InfoTable>
                <tbody>
                  <tr>
                    <th>Client</th>
                    <td>
                      <p>{s.user?.fullName}</p>
                      <AddressRenderer address={s.user?.addressIfAny} />
                      <BaseSpacer size="xs" />
                      <p>{s.user?.username} (ID: {s.user?.id})</p>
                    </td>
                  </tr>
                </tbody>
              </InfoTable>
            </BaseGrid>
          </ShadedBlock>

          <BaseSpacer size="lg" />

          <BaseTable<Payment>
            entries={s.tableItems}
            columnConfigs={[
              {
                keyName: 'paymentMethodId',
                label: 'Payment Method',
                BodyCell: d => !!d ? 'Card' : '',
                type: 'string',
              },
              {
                keyName: 'modelType',
                label: 'Job',
                BodyCell: d => capitalize(ApiModelDefMap[d.modelType].displayName),
                type: 'string',
              },
              {
                keyName: 'model',
                label: 'Counsellor',
                BodyCell: d =>
                  <UserFullNameRenderer user={(d.model as CounsellingSession).counsellor} userId={(d.model as CounsellingSession).counsellorId} />,
              },
              {
                keyName: 'model',
                label: 'Counsellor IACP Membership Number',
                BodyCell: d => ((d.model as CounsellingSession)?.counsellor?.iacpMembershipNumber) ?? '',
                type: 'string',
              },
            ]}
          />

          <BaseSpacer size="lg" />

          <BaseTable<Payment>
            entries={s.tableItems}
            columnConfigs={[
              {
                keyName: 'id',
                label: 'Quantity',
                BodyCell: () => '1',
                type: 'string',
              },
              {
                keyName: 'modelId',
                label: 'Session ID',
                BodyCell: d => <PseudoLink onClick={viewSession}>
                  {`#${d.modelId}`}
                </PseudoLink>,
              },
              {
                keyName: 'modelType',
                label: 'Description',
                BodyCell: d => capitalize(ApiModelDefMap[d.modelType].displayName),
                type: 'string',
              },
              {
                keyName: 'amount',
                label: 'Unit Price',
                BodyCell: d => <CurrencyRenderer value={d.amount} />,
              },
              {
                keyName: 'amount',
                label: 'Line Total',
                BodyCell: d => <CurrencyRenderer value={d.amount * 1.0} />,
              },
            ]}
            tableFoot={<>
              <tr className="BaseTableBodyRow">
                <td className="BaseTableBodyEmptyCell"></td>
                <td className="BaseTableBodyEmptyCell"></td>
                <td className="BaseTableBodyEmptyCell"></td>
                <td className="BaseTableBodyCell">Total</td>
                <td className="BaseTableBodyCell"><CurrencyRenderer value={s.tableItems.reduce((accu, curr) => accu + curr.amount * 1.0, 0)} /></td>
              </tr>
            </>}
          />

          <BaseSpacer size="xl" />

          <div className="ReceiptFooter">
            <BaseHeading level={4} color="skyblue">Thank you for supporting turn2me</BaseHeading>
            <p>Charity Number: CHY18803</p>
          </div>
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
}

export default OverlayReceipt;