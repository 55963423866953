import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { CLOCK } from '../../../controllers/common/clock.controller';
import { Nillable } from '../../@types';
import { createMomentFn } from '../../utils/time.utils';

type IsWasProps = {
  time?: Nillable<string | moment.Moment>,
}

const IsWas: React.FC<IsWasProps> = props => {
  return <Observer children={() => (
    <>{props.time ? (createMomentFn('auto')(props.time).isBefore(CLOCK.localNow) ? 'was' : 'is') : 'is'}</>
  )} />
}

export default IsWas;