import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState, Nullable } from '../../../../../base/@types';
import AppPage from '../../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../../base/components/AppPageHeader/AppPageHeader';
import BackButton from '../../../../../base/components/BackButton/BackButton';
import BaseButton from '../../../../../base/components/BaseButton/BaseButton';
import BaseSpacer from '../../../../../base/components/BaseSpacer/BaseSpacer';
import ErrorBanner from '../../../../../base/components/ErrorBanner/ErrorBanner';
import InfoBanner from '../../../../../base/components/InfoBanner/InfoBanner';
import LoadingIndicatorSection from '../../../../../base/components/LoadingIndicatorSection/LoadingIndicatorSection';
import UIBlock from '../../../../../base/components/UIBlock/UIBlock';
import { useControllers } from '../../../../../base/hooks/useRootController.hook';
import { convertRecordMapToArray } from '../../../../../base/utils/map.utils';
import { useStore } from '../../../../../base/utils/mobx.utils';
import ContactItem from '../../../../../components/ContactItem/ContactItem';
import OverlayContactEditor from '../../../../../components/OverlayContactEditor/OverlayContactEditor';
import { Contact, ContactType, makeContact } from '../../../../../models/makeContact.model';
import { useGetContacts } from '../../../../../requests/useGetEmergencyContacts.request';

type AccountPageEmergencyContactsProps = {}

const AccountPageEmergencyContacts: React.FC<AccountPageEmergencyContactsProps> = props => {

  const { AUTH, UI, LOCALDB } = useControllers();

  const s = useStore(() => ({
    _emergencyContacts: [] as Contact[],
    get ownEmergencyContacts() {
      return AUTH.currentUser?.id ? convertRecordMapToArray(LOCALDB.data.contacts).filter(a => a.ownerId === AUTH.currentUser?.id && a.type === ContactType.EmergencyContact) : [];
    },
    get nonPrimaryEmergencyContacts() {
      return s.ownEmergencyContacts.filter(a => a !== s.primaryEmergencyContact);
    },
    get primaryEmergencyContact() {
      return AUTH.currentUser?.primaryEmergencyContact;
    },
    error: null as Nullable<Error>,
    awaitingResponse: !AUTH.currentUser?.emergencyContacts.length,
    addNewEmergencyContact: action(() => {
      UI.OVERLAY.present({
        component: <OverlayContactEditor
          contact={makeContact({
            ownerId: AUTH.currentUser?.id,
          })}
          forceDefaultToPrimaryAsTrue={s.ownEmergencyContacts.length === 0 && !s.primaryEmergencyContact}
        />
      })
    }),
  }))

  useGetContacts({
    observable: s,
    key: '_emergencyContacts',
    type: ContactType.EmergencyContact,
    own: true,
    onError: action((e: Error) => {
      s.awaitingResponse = false;
      s.error = e;
    }),
    onDataFetch: action(() => {
      s.awaitingResponse = false;
    }),
    when: () => Boolean(AUTH.currentUser?.id),
  }, {
    filter: {
      ownerId: AUTH.currentUser?.id
    }
  });

  return <Observer children={() => (
    <AppPage
      className="AccountPageEmergencyContacts"
      accommodateTitleBarSpace
    >
      <AppPageHeader
        title="Emergency Contacts"
        startSlot={<BackButton destination="up" />}
      />
      <AppPageContent>
        <UIBlock padded spaceChildren>
          {
            s.awaitingResponse && <LoadingIndicatorSection />
          }
          {
            !s.awaitingResponse && s.ownEmergencyContacts.length === 0 && <InfoBanner icon="info">
              <p>You don't have any emergency contacts saved yet. To be eligible for the clinical services on turn2me, you are required to provide at least one valid emergency contact person.</p>
            </InfoBanner>
          }
          {
            s.error && <ErrorBanner heading="Error getting data" error={s.error} />
          }
          {
            s.primaryEmergencyContact && <ContactItem
              contact={s.primaryEmergencyContact}
              isPrimary
              layout={UI.onlyPhones ? 'portrait' : 'landscape'}
              editable
            />
          }
          {
            s.nonPrimaryEmergencyContacts.map(a => <ContactItem
              contact={a}
              key={a.id}
              deletable={s.ownEmergencyContacts.length > 1}
              layout={UI.onlyPhones ? 'portrait' : 'landscape'}
              editable
            />)
          }
          {
            !s.awaitingResponse && <BaseButton
              icon="plus"
              className="subtle"
              size="lg"
              fullWidth
              label="Add New"
              dataCy="add-new-emergency-contact"
              onClick={s.addNewEmergencyContact}
              colorCodedState={ColorCodedState.positive}
            />
          }
        </UIBlock>
        <BaseSpacer />
      </AppPageContent>
    </AppPage>
  )} />
}

export default AccountPageEmergencyContacts;