import { flow, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import AppPage from '../../base/components/AppPage/AppPage';
import AppPageContent from '../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../base/components/AppPageHeader/AppPageHeader';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseInput from '../../base/components/BaseInput/BaseInput';
import BaseSelector from '../../base/components/BaseSelector/BaseSelector';
import BaseSpacer from '../../base/components/BaseSpacer/BaseSpacer';
import OverlayCloseButton from '../../base/components/OverlayCloseButton/OverlayCloseButton';
import UIBlock from '../../base/components/UIBlock/UIBlock';
import { CommentEndpoints } from '../../base/endpoints/comment.endpoints';
import { FlagEndpoints } from '../../base/endpoints/flag.endpoints';
import { ThoughtEndpoints } from '../../base/endpoints/thought.endpoints';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { singularize, toTitleCase } from '../../base/utils/string.utils';
import { ApiModelName } from '../../constants/ApiModels.enum';
import { ModelName } from '../../constants/modelNames.enum';
import { Comment } from '../../models/makeComment.model';
import { FlaggableOriginType, makeFlag } from '../../models/makeFlag.model';
import { Thought } from '../../models/makeThought.model';
import CommentEntry from '../CommentEntry/CommentEntry';
import ThoughtCard from '../ThoughtCard/ThoughtCard';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import './OverlaySubmitFlag.scss';

const defaultReasons = [
  'Content is inappropriate',
  'Content is abusive',
  'I Think the user is in danger of harming themselves',
  'Other reasons…',
]

type OverlaySubmitFlagProps = {
  target: Thought | Comment,
  thought?: Thought,
}

const OverlaySubmitFlag: React.FC<OverlaySubmitFlagProps> = props => {
  const { API, AUTH, UI } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    get modelNamePlural () {
      return p.target.$modelName;
    },
    get modelNameSingular() {
      return singularize(s.modelNamePlural);
    },
    get apiModelName() {
      return s.modelNamePlural === ModelName.thoughts ? ApiModelName.THOUGHT : ApiModelName.COMMENT;
    },
    showInputForCustomReason: false,
    awaitingResponse: false,
    submit: async () => await flow(function * () {
      if (!ss.flag.reason) {
        UI.DIALOG.attention({
          heading: 'Please select a reason before submitting your request.'
        });
        return;
      }
      const url = FlagEndpoints.own.create();
      const payload = ss.flag;
      try {
        s.awaitingResponse = true;
        yield API.post(url, ModelName.flags, payload);
        UI.DIALOG.success({
          dataCy: 'flag-submission-success',
          heading: 'Flag request submitted',
          body: 'Thank you. Your anonymous flag request has been received. We will review this thought and take appropriate actions. Thanks for your cooperation.',
        })
        s.awaitingResponse = false;
        UI.OVERLAY.dismiss();
      } catch(e) {
        reportError(e);
        UI.DIALOG.error({
          heading: 'Error submitting flag request',
          error: e,
        })
      } finally {
        s.awaitingResponse = false;
        if (s.modelNamePlural === ModelName.thoughts) {
          yield API.get(ThoughtEndpoints.client.get(p.target.id), ModelName.thoughts);
        } else {
          yield API.get(CommentEndpoints.client.get(p.target.id), ModelName.comments);
        };
      }
    })()
  }))
  const ss = useStore(() => ({
    flag: makeFlag({
      modelType: s.apiModelName,
      modelId: p.target.id,
      // reporterComment: '',
      reporterId: AUTH.currentUser?.id,
      originType: FlaggableOriginType.community,
      reason: '',
    }).$getSnapshot()
  }))
  useOnMount(() => {
    return when(
      () => ss.flag.reason === 'Other reasons…',
      () => {
        ss.flag.reason = '';
        s.showInputForCustomReason = true;
      }
    )
  })
  return <Observer children={() => (
    <AppPage
      className="OverlaySubmitFlag"
      color="red"
    >
      <AppPageHeader 
        title={`Flag this ${s.modelNameSingular}`}
        afterTitle={<>{toTitleCase(s.modelNameSingular)} by <UsernameRenderer user={p.target?.user} /></>}
        endSlot={<OverlayCloseButton />}
      />
      <AppPageContent>
        <UIBlock padded="all">
          <BaseSpacer />
          {
            s.modelNamePlural === ModelName.thoughts ? (
              <ThoughtCard thought={p.target as Thought} disableInteractions />
            ) : <CommentEntry comment={p.target as Comment} thought={p.thought!} disableInteractions />
          }
        </UIBlock>
        <UIBlock padded>
          <h3 className="OverlaySubmitFlagConfirmHeading">Are you sure you want to flag this {s.modelNameSingular}?</h3>
          {AUTH.isModerator || <p>When you flag a {s.modelNameSingular}, our moderators will be notified to review.</p>}
          <p>Please select a reason for flagging this {s.modelNameSingular}:</p>
          <BaseSpacer size=".5em" />
          {
            s.showInputForCustomReason ? (
              <BaseInput form={ss.flag} field="reason" disabled={s.awaitingResponse} autoFocus autoSelect placeholder="Enter a reason…" onEnter={s.submit}/>
            ) : (
              <BaseSelector appearance="system" options={defaultReasons} form={ss.flag} field="reason" disabled={s.awaitingResponse}/>
            )
          }
          <BaseSpacer size=".5em" />
          <p><em>* The author of the original {s.modelNameSingular} above will not be aware of who submmited the flag requests.</em></p>
          <BaseSpacer size=".5em" />
          <BaseButton className="submit-flag" dataCy="submit-flag" label="Submit flag" onClick={s.submit} loading={s.awaitingResponse}/>
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
}

export default OverlaySubmitFlag;