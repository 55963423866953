import { action, flow, observable, onBecomeObserved, reaction, toJS, when } from "mobx";
import { Observer } from "mobx-react-lite";
import { CancellablePromise } from "mobx/dist/api/flow";
import React from "react";
import ExportButton from "../../../components/ExportButton/ExportButton";
import { isInCypressTestMode, SHOULD_LOG } from "../../../env";
import { AnyObject, Nullable, SortDirection } from "../../@types";
import { useOnMount } from "../../hooks/lifecycle.hooks";
import { useCreateResizeQueryWithRef } from "../../hooks/useCreateResizeQueryWithRef.hook";
import { useControllers } from "../../hooks/useRootController.hook";
import joinClassName from "../../utils/className.utils";
import { renderRenderable } from "../../utils/components.utils";
import { debounce } from "../../utils/debounce.utils";
import { reportError } from "../../utils/errors.utils";
import { useProps, useStore } from "../../utils/mobx.utils";
import { scrollScrollContextToTop } from "../../utils/scrollElementTo.utils";
import { capitalizeFirstLetter } from "../../utils/string.utils";
import { createUTCMoment, getNowTimestampUtc } from "../../utils/time.utils";
import { isFunction } from "../../utils/typeChecks.utils";
import tick from "../../utils/waiters.utils";
import BaseButton from "../BaseButton/BaseButton";
import BaseCalendar from "../BaseCalendar/BaseCalendar";
import { BaseCalendarState } from "../BaseCalendar/BaseCalendarState";
import BasePagination from "../BasePagination/BasePagination";
import BaseSelector from "../BaseSelector/BaseSelector";
import BaseTable from "../BaseTable/BaseTable";
import CommandList from "../CommandList/CommandList";
import { CommandListItem } from "../CommandList/CommandListItem";
import LoadingBlocker from "../LoadingBlocker/LoadingBlocker";
import './IndexDirectory.scss';
import { IndexDirectoryProps, IndexDirectoryViewMode } from "./indexDirectory.types";
import IndexDirectorySearchBar from "./IndexDirectorySearchBar";
import IndexDirectoryState from "./IndexDirectoryState";

function IndexDirectory<
  EntryType extends AnyObject = {},
>(
  props: React.PropsWithChildren<IndexDirectoryProps<EntryType>>,
) {

  const p = useProps(props);

  const { UI } = useControllers();

  const { ref, query } = useCreateResizeQueryWithRef<HTMLDivElement>();

  const s = useStore(() => {

    const _private = observable({
      state: observable(p.state || new IndexDirectoryState<EntryType>({
        viewMode: p.initInViewMode ?? p.allowedViewModes?.[0],
        searchable: p.searchable ?? !!p.searchBarPlaceholder ?? !!p.searchQueryMatcher ?? !!p.onSearchQueryChange,
      })),
      _internalEntries: new Set<EntryType>(),
      get entries() {
        return props.data ? new Set<EntryType>(isFunction(props.data) ? props.data() : props.data) : _private._internalEntries;
      },
      currentFetcher: null as CancellablePromise<void> | null,
    })

    const store = observable({
      get state() {
        return _private.state;
      },
      get entries() {
        return _private.entries;
      },
      get isLocalDataMode() {
        return !!p.data && !p.dataFetcher;
      },
      calendarState: new BaseCalendarState<EntryType>(p.calendarPropsGetter?.().state),
      get entriesArray() {
        const entriesArr = Array.from(_private.entries.values());
        if (p.entryFilter) return entriesArr.filter(p.entryFilter);
        return entriesArr;
      },
      get calendarEvents() {
        if (!!(p.calendarPropsGetter?.().eventBuilder)) {
          return s.entriesArray.map(e => p.calendarPropsGetter!().eventBuilder!(e));
        } else {
          return [];
        }
      },
      get viewMode(): IndexDirectoryViewMode {
        return _private.state.viewMode || 'table';
      },
      get allowedViewModes() {
        return p.allowedViewModes || ['table'];
      },
      get showAsTable() {
        return Boolean(_private.state.viewMode === 'table' && p.tablePropsGetter);
      },
      get showAsList() {
        return Boolean(_private.state.viewMode === 'list' && p.listEntryRenderer);
      },
      get showAsCalendar() {
        return Boolean(_private.state.viewMode === 'calendar' && p.calendarPropsGetter);
      },
      get filteredEntries() {
        return s.entriesArray;
      },
      get paginatedEntries() {
        console.log('s.isLocalDataMode', s.isLocalDataMode);
        if (!s.isLocalDataMode) return s.filteredEntries;
        const { currentPage, perPage } = s.state.paginationState;
        return s.entriesArray.slice(
          (currentPage - 1) * perPage,
          currentPage * perPage
        )
      },
      get shouldShowPagination() {
        return _private.state.paginationState && _private.state.viewMode !== 'calendar';
      },
      get perPage() {
        return _private.state.paginationState?.perPage;
      },
      get fillToNumberOfRows(): number {
        return Math.min(p.appearanceOptions?.fullHeight ? s.perPage : 0, 128);
      },
      get shouldShowSearch() {
        return _private.state.searchable && _private.state.viewMode !== 'calendar'
      },
      get awaitingResponse() {
        return Boolean(_private.currentFetcher);
      },
      firstDataLoaded: false,
      lastFetchedTimestamp: null as Nullable<string>,
      isLoading: false,
      runDataFetcher: flow(function * () {
        if (!_private.state) return;
        if (!p.dataFetcher) return;
        if (_private.state.viewMode === 'calendar' && !!p.calendarPropsGetter?.().dataFetcher) return;
        const fetcher = flow(function* () {
          try {
            yield tick(); // waits for possible cancellation from overlapping requests
            SHOULD_LOG() && console.log('data fetcher running');
            SHOULD_LOG() && console.log(s.state.searchQuery);
            s.isLoading = true;
            const newEntries: EntryType[] = yield p.dataFetcher!(_private.state);
            if (_private.currentFetcher && _private.currentFetcher !== fetcher) return;
            _private.entries.clear();
            newEntries.forEach(e => _private.entries.add(e))
            s.lastFetchedTimestamp = getNowTimestampUtc();
            if (!s.firstDataLoaded) {
              p.onFirstDataLoad?.(newEntries);
              s.firstDataLoaded = true;
            }
            s.isLoading = false;
          } catch(e) {
            s.isLoading = false;
            reportError(e);
            if (!isInCypressTestMode) {
              UI.DIALOG.error({
                heading: 'There was an error getting data. Please retry.',
                error: e,
              })
            }
          } finally {
            _private.currentFetcher = null;
          }
        })();
        _private.currentFetcher = fetcher;
        yield when(() => _private.currentFetcher === null);
      }),
      runExportCSVDataFetcher: flow(function * () {
        if (!_private.state) return;
        if (!p.dataFetcher) return;
        if (_private.state.viewMode === 'calendar' && !!p.calendarPropsGetter?.().dataFetcher) return;
        try {
          yield tick(); // waits for possible cancellation from overlapping requests
          SHOULD_LOG() && console.log('export csv data fetcher running');
          SHOULD_LOG() && console.log(s.state.searchQuery);
          const state = new IndexDirectoryState<EntryType>(toJS(_private.state));
          state.searchQuery = _private.state.searchQuery
          state.sortByKeyName = _private.state.sortByKeyName
          state.sortDirection = _private.state.sortDirection
          state.paginationState.perPage = 999999
          const newEntries: EntryType[] = yield (p.exportConfig?.dataFetcher?.(state) ?? p.dataFetcher!(state));
          return newEntries;
        } catch(e) {
          reportError(e);
          if (!isInCypressTestMode) {
            UI.DIALOG.error({
              heading: 'There was an error getting data. Please retry.',
              error: e,
            })
          }
        }
      }),
      handleSort: (
        keyName?: keyof EntryType | null,
        direction?: SortDirection,
      ) => {
        s.state.sortByKeyName = keyName;
        s.state.sortDirection = direction;
        s.runDataFetcher();
      },
      destroy: () => {
        destroy();
      },
      get listRendered() {
        return <div className="IndexDirectoryListWrapper">
          <ul className="IndexDirectoryList">
            {s.filteredEntries.length === 0 && <div className="IndexDirectoryListEmptyListNotice">{p.listEmptyNotice ?? 'No entries to show here'}</div>}
            {s.filteredEntries.map((e, i, arr) => <li className="IndexDirectoryListItem" key={e.id} >
              {ListEntryRenderer(e)}
              {p.listEntrySeparator && i !== arr.length - 1 && renderRenderable(p.listEntrySeparator)}
            </li>)}
          </ul>
        </div>;
      },
      get tableRendered() {
        return <BaseTable
          {...p.tablePropsGetter?.()}
          entries={s.paginatedEntries}
          columnConfigs={p.tablePropsGetter!().columnConfigs}
          appearanceOptions={p.appearanceOptions}
          fillToNumberOfRows={s.fillToNumberOfRows}
          onSort={s.handleSort}
          // @ts-ignore
          sortByKeyName={'' + s.state.sortByKeyName}
          sortDirection={s.state.sortDirection}
        />
      },
      get calendarRendered() {
        // calendarPropsGetter has events, only omitted by TypeScript's types.
        // to override calendarPropsGetter's events, events={s.calendarEvents}
        // must be placed after it.
        const calendarProps = {
          ...p.calendarPropsGetter?.(),
          events: s.calendarEvents,
        };
        return <BaseCalendar<EntryType>
          key={s.calendarReloadKey}
          state={s.calendarState}
          onFirstDataLoad={props.onFirstDataLoad}
          {...calendarProps}
          eventDataFilter={p.entryFilter}
        />
      },
      get content() {
        if (s.showAsList) return s.listRendered;
        if (s.showAsTable) return s.tableRendered;
        if (s.showAsCalendar) return s.calendarRendered;
        return null;
      },
      calendarReloadKey: 0,
      shouldReload: p.shouldTriggerReload,
      reload: async () => await flow(function * () {
        if (s.state.viewMode === 'calendar') {
          if (p.dataFetcher) yield s.runDataFetcher();
          s.calendarReloadKey++;
        } else {
          s.runDataFetcher();
        }
      })()
    });

    const disposerOne = onBecomeObserved(_private, 'entries', store.runDataFetcher);

    const disposerTwo = reaction(() => _private.state.viewMode, () => {
      store.runDataFetcher();
    })

    const disposerThree = reaction(
      () => store.shouldReload,
      action(() => {
        if (store.shouldReload) {
          store.reload()
        }
      })
    )

    const destroy = () => {
      disposerOne();
      disposerTwo();
      disposerThree();
    }

    return store;
  });

  useOnMount(() => {
    const disposer = reaction(
      () => p.dataFetcher,
      () => {
        s.runDataFetcher();
      }
    );
    return () => {
      disposer();
      s.destroy();
    }
  })

  const handleSearchQueryChange = debounce(action((
    keyName: string,
    query: string
  ) => {
    s.state.searchQuery = query;
    s.state.paginationState.currentPage = 1;
    s.runDataFetcher();
    p.onSearchQueryChange && p.onSearchQueryChange(query);
  }))

  const handlePaginationChange = action((number: number) => {
    s.runDataFetcher();
    p.onPaginationChange && p.onPaginationChange(number);
    when(() => !!ref.current, () => scrollScrollContextToTop(ref.current!));
  })

  const ListEntryRenderer = p.listEntryRenderer || ((e: EntryType) => e && e.id);

  return <Observer children={() => {

    const { className, tablePropsGetter } = p;
    const { paginationState } = s.state;

    return <div className={joinClassName(
      'IndexDirectory',
      className,
      s.shouldShowSearch && 'searchbarVisible',
      p.appearanceOptions?.fullHeight && 'fullHeight',
    )}
      data-mode={s.viewMode}
      ref={ref}
      data-loading={s.isLoading}
    >

      <div className="IndexDirectoryControls">
        {p.exportConfig?.allowExport && <div className="IndexDirectoryExportButtonWrapper">
          <ExportButton dataFetcher={s.runExportCSVDataFetcher} allowedHeaders={p.exportConfig.allowedHeaders} filename={p.exportConfig.filename} encryptValuesOfHeaders={p.exportConfig.encryptValuesOfHeaders} processData={p.exportConfig.processData} />
        </div>}
        {s.allowedViewModes.length > 1 && <CommandList
          direction="row"
          startSlot={<>
            <CommandListItem>
              <BaseSelector className="IndexDirectoryViewModeSelector" options={s.allowedViewModes} form={s.state} field="viewMode" optionLabelRenderer={capitalizeFirstLetter} nullable={false} />
            </CommandListItem>
          </>}
          endSlot={<>
            {p.EndCommandListItems && p.EndCommandListItems()}
            {p.dataFetcher && <BaseButton className="IndexDirectoryRefreshButton" appearance="icon" icon="refresh" size="sm" iconVariant="filled" onClick={s.reload} />}
            {p.entryCreator && (!p.doNotShowCreateButtonInOptions) && <CommandListItem icon="plus" onClick={p.entryCreator}>
              {p.createEntryButtonText || 'Add New'}
            </CommandListItem>}
          </>}
        />}
        {s.shouldShowSearch && <div className="IndexDirectorySearchBarAndControlsWrapper">
          <IndexDirectorySearchBar<EntryType>
            indexDirectoryState={s.state}
            onChange={handleSearchQueryChange}
            columnConfigs={tablePropsGetter?.().columnConfigs}
            searchBarPlaceholder={p.searchBarPlaceholder}
          />
          <div className="IndexDirectorySearchBarAndControlsLastUpdatedAndRefresher">
            {(UI.onlyPhones || query.fromTablet) && <span className="IndexDirectorySearchBarAndControlsLastUpdated">Last updated: {s.lastFetchedTimestamp ? createUTCMoment(s.lastFetchedTimestamp).fromNow() : 'Never' }</span>}
            <BaseButton name="IndexDirectoryRefreshButton" className="subtle" icon="refresh" color="green" onClick={s.runDataFetcher} loading={s.awaitingResponse} />
          </div>
        </div>}
      </div>

      <div className="IndexDirectoryContent">
        {s.content}
      </div>

      <div className="IndexDirectoryFooter">
        {s.shouldShowPagination && <BasePagination paginationState={paginationState} onChange={handlePaginationChange} />}
      </div>

      {!p.hideBlocker && s.isLoading && <LoadingBlocker />}

    </div>

  }} />
}

export default React.memo(IndexDirectory) as <
  EntryType extends object = {},
>(
  props: React.PropsWithChildren<IndexDirectoryProps<EntryType>>
) => JSX.Element