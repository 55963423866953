import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import { ColorCodedState } from '../../base/@types';
import AppPageContent from '../../base/components/AppPageContent/AppPageContent';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseGrid from '../../base/components/BaseGrid/BaseGrid';
import BaseGridCell from '../../base/components/BaseGrid/BaseGridCell';
import BaseSpacer from '../../base/components/BaseSpacer/BaseSpacer';
import FormContactTypeSelector from '../../base/components/FormContactTypeSelector/FormContactTypeSelector';
import FormForm from '../../base/components/FormForm/FormForm';
import FormInput from '../../base/components/FormInput/FormInput';
import FormTelInput from '../../base/components/FormTelInput/FormTelInput';
import InfoBanner from '../../base/components/InfoBanner/InfoBanner';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import UIBlock from '../../base/components/UIBlock/UIBlock';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { Form, makeForm } from '../../base/mediators/form.mediator';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { copyWithJSON } from '../../base/utils/object.utils';
import { getSnapshot } from '../../base/utils/snapshot.utils';
import { toLowerCase } from '../../base/utils/string.utils';
import { getNowTimestampUtc } from '../../base/utils/time.utils';
import { validateEmail } from '../../base/validators/email.validator';
import { validatePhone } from '../../base/validators/phone.validator';
import { getWordPressLink } from '../../env';
import { ContactFormSnapshot, makeContactFormSnapshot } from '../../models/makeContactForm.model';
import { submitContactForm } from '../../requests/submitContactForm.request';
import { ID_OverlayContactFormEditor } from '../OverlayContactFormEditor/OverlayContactFormEditor';
// import './ContactForm.scss';

interface ContactFormEditorProps {
  formSnapshot?: Partial<ContactFormSnapshot>,
  onSave?: (f: ContactFormSnapshot) => any,
  onBlur?: (f: ContactFormSnapshot) => any,
  applyChangesOnBlur?: boolean,
  doNotShowImportantInfo?: boolean,
  closeOnSubmit?: boolean,
}

const ContactFormEditor: React.FC<ContactFormEditorProps> = props => {

  const { AUTH, API, UI } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    form: makeForm({
      ...getSnapshot(makeContactFormSnapshot()),
      email: AUTH.currentUser?.email ?? "",
      phone: AUTH.currentUser?.mobileNumber ?? "",
      ...p.formSnapshot,
    }) as Form<ContactFormSnapshot>,
    handleBlur: () => {
      p.onBlur && p.onBlur(s.form.value);
      if (p.applyChangesOnBlur) {
        s.form.markAsSaved();
      }
    },
    get hasValidEmail() {
      return s.form.value.email && validateEmail(s.form.value.email) === true;
    },
    get hasValidPhone() {
      return (s.form.value.phone === "" || s.form.value.phone) && validatePhone(s.form.value.phone, true) === true;
    },
    get formIsValid() {
      return s.hasValidEmail && s.hasValidPhone && s.form.value.type && s.form.value.body;
    },
  }));

  const save = async () => {
    const contactFormSnapshot = copyWithJSON(s.form.value);
    contactFormSnapshot.type = toLowerCase(contactFormSnapshot.type);
    contactFormSnapshot.timeCreated = getNowTimestampUtc();
    contactFormSnapshot.timeUpdated = getNowTimestampUtc();
    const user = AUTH.currentUser;
    contactFormSnapshot.userId = user && (user.email === contactFormSnapshot.email || user.mobileNumber === contactFormSnapshot.phone) ? user.id : null
    try {
      const savedContactForm = await submitContactForm(API, contactFormSnapshot);
      if (!savedContactForm) {
        UI.DIALOG.attention({
          heading: `Something wasn't quite right...`,
          body: 'Your enquiry might have not been submitted correctly; please refresh the page and try again.',
        })
        return;
      }
      s.form.reset({ ...makeContactFormSnapshot(), phone: AUTH.currentUser?.mobileNumber, email: AUTH.currentUser?.email });
      UI.DIALOG.success({ name: "contact-form-submission-success", heading: 'Your enquiry has been submitted!' });
      if (p.closeOnSubmit) UI.OVERLAY.dismiss(ID_OverlayContactFormEditor);
    } catch (e) {
      reportError(e);
      UI.DIALOG.error({
        heading: "Failed to submit enquiry...",
        error: e,
      })
    }
  }

  return <Observer children={() => (<>
    <AppPageContent>
      <UIBlock padded spaceChildren>
        {!p.doNotShowImportantInfo && <InfoBanner icon="info" colorCodedState={ColorCodedState.positive}>
          <p>Important information: <strong>Support group counselling services are <u>NOT</u> provided through this form.</strong></p>
          <p>If you are looking for support of this type, please either sign up for a <Link to="/app/support-groups" title="Support Group">support group</Link> or a <Link to="/app/counselling" title="Counselling Application">counselling application</Link>, or go to the <a href={getWordPressLink('/crisis-support')} title="Crisis Support" target="_blank" rel="noreferrer">Crisis Support</a> page if you need immediate help.</p>
          <p>This contact form is meant for general enquiries related to topics such as services, donations, website functionalities, or business enquiries.</p>
        </InfoBanner>}
        <ShadedBlock spaceChildren>
          <FormForm
            className="ContactFormEditor"
            form={s.form}
            onBlur={s.handleBlur}
            children={context => <BaseGrid columns={context.resizeQuery?.fromPhoneLg ? 2 : 1}>
              <FormInput field="email" label="Email" type="email" required={true} dataCy="email" autoComplete="off" />
              <FormTelInput name="phone" field="phone" label="Phone" required={true} dataCy="phone" autoComplete="off" optional doNotUseAPIValidation doNotUseOnMountGetUserDialCode />
              <FormInput field="name" label="Your name" dataCy="name" autoComplete="off" optional />
              <FormContactTypeSelector field="type" label="Select the closest topic" dataCy="type" autoComplete="false" required />
              <BaseGridCell columns="all">
                <FormInput field="body" label="Enter a message" placeholder="Enter a message" type="textarea" rows="10" required />
              </BaseGridCell>
            </BaseGrid>}
          />
          {
            !s.formIsValid && <InfoBanner colorCodedState={ColorCodedState.attention}>Your enquiry needs a valid email address, contact topic and message.</InfoBanner>
          }
          <BaseButton dataCy="contact-form-editor-submit-contact-form-button" size="md" onClick={save} icon="arrow" iconVariant="filled" disabled={!s.formIsValid} color="green">Submit</BaseButton>
        </ShadedBlock>
      </UIBlock>
      <BaseSpacer />
    </AppPageContent>

  </>)} />

}

export default ContactFormEditor;