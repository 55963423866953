import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { Nillable, TimezoneMode } from '../../@types';
import { useControllers } from '../../hooks/useRootController.hook';
import joinClassName from '../../utils/className.utils';
import { padZero } from '../../utils/math.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { createMomentFn } from '../../utils/time.utils';
import './DurationRenderer.scss';

interface DurationRendererProps {
  duration?: number,
  startTime?: Nillable<string | Date | moment.Moment>,
  endTime?: Nillable<string | Date | moment.Moment>,
  format?: '--:--',
  timezoneMode?: TimezoneMode,
}

const DurationRenderer: React.FC<DurationRendererProps> = props => {
  const p = useProps(props);
  const _createMoment = createMomentFn(p.timezoneMode ?? 'auto');
  const { COMMON } = useControllers();
  const s = useStore(() => ({
    get now() {
      return COMMON.CLOCK.localNowMoment;
    },
    get nowSeconds() {
      return s.now.seconds
    },
    get startTimeMoment() {
      return p.startTime ? _createMoment(p.startTime) : undefined;
    },
    get endTimeMoment() {
      return p.endTime ? _createMoment(p.endTime) : COMMON.CLOCK.localNowMoment;
    },
    get durationInSeconds() {
      return p.duration ? p.duration / 1000 : (s.startTimeMoment ? Math.abs(s.endTimeMoment.diff(s.startTimeMoment, 'second')) : null);
    },
    get diffHours() {
      return typeof s.durationInSeconds === 'number' ? Math.floor(s.durationInSeconds / 60 / 60) : 0;
    },
    get diffMinutes() {
      return typeof s.durationInSeconds === 'number' ? Math.floor(s.durationInSeconds / 60 % 60) : null;
    },
    get diffSeconds() {
      return typeof s.durationInSeconds === 'number' ? Math.floor(s.durationInSeconds % 60) : null;
    },
    get diffHoursDisplay() {
      return s.diffHours !== null && s.diffHours > 0 ? s.diffHours + '' : null
    },
    get diffMinutesDisplay() {
      return padZero(s.diffMinutes === null ? 0 : s.diffMinutes);
    },
    get diffSecondsDisplay() {
      return padZero(s.diffSeconds);
    },
    get forMachine() {
      const arr = [] as string[];
      s.diffHours && arr.push(s.diffHours + 'h');
      s.diffMinutes && arr.push(s.diffMinutes + 'm');
      s.diffSeconds && arr.push(s.diffSeconds + 's');
      return arr.join(' ');
    },
    get forHuman() {
      switch (p.format) {
        case '--:--':
        default: {
          if (!p.startTime) return '--:--';
          return [
            s.diffHoursDisplay,
            s.diffMinutesDisplay,
            s.diffSecondsDisplay
          ].filter(i => i !== null).join(':');
        }
      }
    },
    get forHumanDisplay() {
      return s.forHuman;
    }
  }));
  return <Observer children={() => (
    <time className={
      joinClassName(
        "DurationRenderer",
        !!s.diffHoursDisplay && 'hasHour'
      )}
      data-start-time={p.startTime}
      data-end-time={p.endTime}
      data-diff={s.durationInSeconds}
      dateTime={s.forMachine}>
      {s.forHumanDisplay}
    </time>
  )} />
}

export default DurationRenderer;