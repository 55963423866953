import { Address, AddressRelatedModels } from "../../models/makeAddress.model";
import { DefaultIndexParamSet } from "../utils/api.utils";
import { makeApiEndpointConfig, makeApiGetEndpointConfig } from "../utils/endpoints.utils";
import { makeUrl } from "../utils/url.utils";
import { prefixOwn, prefixStaff } from "./namespacePrefixes";

export type AddressEndpointParams = DefaultIndexParamSet<Address, AddressRelatedModels> & {
  include?: (keyof AddressRelatedModels | string)[],
}
const ownUrlBase = prefixOwn('addresses');
const staffUrlBase = prefixStaff('addresses');

export const AddressEndpoints = {
  own: {
    index: makeApiEndpointConfig<AddressEndpointParams>(ownUrlBase),
    get: makeApiGetEndpointConfig<AddressEndpointParams>(ownUrlBase),
    create: () => ownUrlBase,
    update: (id: string) => makeUrl(ownUrlBase, id),
    delete: (id: string) => makeUrl(ownUrlBase, id),
  },
  staff: {
    index: makeApiEndpointConfig<AddressEndpointParams>(staffUrlBase),
    get: makeApiGetEndpointConfig<AddressEndpointParams>(staffUrlBase),
    create: () => prefixStaff('address'),
    update: (id: string) => makeUrl(prefixStaff('address'), id),
    delete: (id: string) => makeUrl(staffUrlBase, id),
  },
}