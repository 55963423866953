import moment from "moment";
import { User } from "../../../models/makeUser.model";
import { Nillable } from "../../@types";
import { getRandomItemFromArray } from "../../utils/random.utils";

export const GREETINGS: Record<
  'simple' | 'generic' | 'morning' | 'afternoon' | 'evening' | 'midnight',
  ((username?: Nillable<string>) => string)[]
> = {
  'simple': [
    u => u ? `Hi, ${u}!` : `Hi there!`,
    u => u ? `Hello, ${u}!` : `Hello!`,
    u => u ? `Nice to see you again, ${u}!` : `Oh, Nice to see you there!`,
  ],
  'generic': [
    u => u ? `Hi, ${u}!` : `Hi there!`,
    u => u ? `Hello, ${u}!` : `Hello!`,
  ],
  'morning': [
    u => u ? `Good morning, ${u}!` : `Good morning!`,
  ],
  'afternoon': [
    u => u ? `Good afternoon, ${u}!` : `Good afternoon!`,
  ],
  'evening': [
    u => u ? `Good evening, ${u}!` : `Good evening!`,
  ],
  'midnight': [
    u => u ? `Good evening, ${u}!` : `Good evening!`,
  ],
}

export const getGreetingFn = (type: keyof typeof GREETINGS, userName?: Nillable<string>) => () => {
  return getRandomItemFromArray(GREETINGS[type])(userName);
}

export const getGreeting = (type: keyof typeof GREETINGS, userName?: Nillable<string>) => getGreetingFn(type, userName)();

export const getGreetingTimeOfDay = (user?: Nillable<User>) => {

  const now = moment();

  const userName = user?.username || user?.firstName;

  if (now.isBefore(moment().set('hour', 4))) return getGreeting('midnight', userName);
  if (now.isBefore(moment().set('hour', 12))) return getGreeting('morning', userName);
  if (now.isBefore(moment().set('hour', 18))) return getGreeting('afternoon', userName);

  return getGreeting('evening', userName);

}