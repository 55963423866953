import { action, observable, reaction, runInAction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { SingleDatePicker } from 'react-dates';
import { AnyObject, GenericFunction, Nullable, TimezoneMode } from '../../@types';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useControllers } from '../../hooks/useRootController.hook';
import joinClassName from '../../utils/className.utils';
import { checkIfShouldInvertStyle } from '../../utils/colors.utils';
import { padZero } from '../../utils/math.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { setValueOfKey } from '../../utils/object.utils';
import { AlwaysFalseFn, isNil } from '../../utils/ramdaEquivalents.utils';
import { getRandomNumericString } from '../../utils/random.utils';
import { createMoment, createUTCMoment, YYYYMMDD, YYYYMMDDHHmmss } from '../../utils/time.utils';
import BaseInput, { BaseInputProps } from '../BaseInput/BaseInput';
import BaseLabel from '../BaseLabel/BaseLabel';
import './DatePicker.scss';

export type DatePickerProps<T = AnyObject> = Omit<BaseInputProps<T>, 'rows'> & {
  format?: typeof YYYYMMDD | typeof YYYYMMDDHHmmss,
  inputTimezoneMode?: TimezoneMode,
  outputTimezoneMode?: TimezoneMode,
  defaultHour?: number,
  defaultYear?: number,
  defaultMonthIndex?: number,
  defaultDay?: number,
  displayFormat?: string,
  isDayBlocked?: (d: moment.Moment | string | null) => boolean,
  isOutsideRange?: (d: moment.Moment | string | null) => boolean,
  onClick?: GenericFunction,
  dataCy?: string,
  shouldUseSystemUIOnMobile?: boolean,
  shouldAlwaysUseSystemUI?: boolean,
};

const DatePicker = <T extends AnyObject = AnyObject>(props: React.PropsWithChildren<DatePickerProps<T>>) => {

  const p = useProps(props);

  const { UI } = useControllers();

  const s = useStore(() => {
    const getDefaultValue = () => {
      const value = [
        p.defaultYear ?? moment().year(),
        padZero((p.defaultMonthIndex ?? moment().month()) + 1),
        padZero(p.defaultDay ?? moment().day()),
      ].join('-');
      if (moment(value).isValid()) return moment(value);
      return moment();
    };
    const initialValue = p.form[p.field] ? createUTCMoment(p.form[p.field]).local() : null;
    const store = observable({
      id: getRandomNumericString(8),
      value: initialValue as Nullable<moment.Moment>,
      setValue: action((m: moment.Moment | null | string, applyTimeFromNewValue?: boolean) => {
        if (typeof m === 'string') {
          return;
        }
        if (m === null) {
          store.value = null;
          return;
        }
        if (!store.value) store.value = createUTCMoment();
        isNil(m.year()) || store.value.set('year', m.year());
        isNil(m.month()) || store.value.set('month', m.month());
        isNil(m.date()) || store.value.set('date', m.date());
        if (applyTimeFromNewValue) {
          isNil(m.hour()) || store.value.set('hour', m.hour());
          isNil(m.minute()) || store.value.set('minute', m.minute());
          isNil(m.second()) || store.value.set('second', m.second());
        }
        const newValue = store.value.local().format(p.format ?? YYYYMMDDHHmmss) || null;
        setValueOfKey(p.form, p.field, newValue);
      }),
      focused: false,
      handleFocusChange: action((change: { focused: boolean }) => store.focused = change.focused),
      isInverted: false,
      get browsers() {
        return UI.NATIVE.deviceInfo.browser;
      },
      get shouldUseSystemUI() {
        if (p.shouldAlwaysUseSystemUI) return true;
        if (!p.shouldUseSystemUIOnMobile) return false;
        return UI.NATIVE.isIOS || UI.NATIVE.isAndroid || UI.deviceInfo.browser.includes('chrome');
      },
      get valueForSystemDatePicker() {
        return (p.form[p.field] ? p.form[p.field].match(/^(\d{4}-\d{1,2}-\d{1,2})/)?.[1] ?? p.form[p.field] : p.form[p.field]) ?? '';
      },
      set valueForSystemDatePicker(v: string) {
        Reflect.set(p.form, p.field, v);
      }
    });
    if (store.value && !store.value.isValid()) {
      runInAction(() => {
        const defaultValue = getDefaultValue();
        store.value = defaultValue;
        store.setValue(defaultValue, true);
      })
    }
    return store;
  });

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || ((e.which || e.keyCode) === 13)) {
      p.onEnter && p.onEnter(e);
    }
  }

  useOnMount(() => {
    return reaction(
      () => p.form[p.field],
      v => {
        if (s.shouldUseSystemUI) return;
        const m = createMoment(p.inputTimezoneMode, p.form[p.field]);
        s.setValue(m, true);
      }
    )
  });

  const invertedStyleCheckerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    runInAction(() => {
      s.isInverted = checkIfShouldInvertStyle(invertedStyleCheckerRef);
    })
  })

  const renderPicker = () => {
    if (s.shouldUseSystemUI) {
      const formatter = (v: string) => {
        const m = moment(v);
        if (m.isValid()) return m.format(p.format ?? YYYYMMDD);
        return v;
      }
      return <BaseInput
        type="date"
        form={s}
        field="valueForSystemDatePicker"
        applyOnlyIfValid
        placeholder="YYYY-MM-DD"
        formatter={formatter}
        disabled={Boolean(p.disabled)}
        name={p.name ?? p.field}
      />
    }
    return <SingleDatePicker
      id={`SingleDatePicker-${s.id}`}
      date={s.value}
      onDateChange={s.setValue}
      focused={s.focused}
      onFocusChange={s.handleFocusChange}
      numberOfMonths={1}
      noBorder
      displayFormat={p.displayFormat ?? 'YYYY-MM-DD'}
      isDayBlocked={p.isDayBlocked ?? AlwaysFalseFn}
      isOutsideRange={p.isOutsideRange ?? AlwaysFalseFn}
      disabled={Boolean(p.disabled)}
      placeholder="YYYY-MM-DD"
    />;
  }

  return <Observer children={() => {
    return <div
      className={joinClassName('DatePicker', s.isInverted && 'inverted', p.disabled && 'disabled')}
      onClick={p.onClick}
      data-name={p.name ?? p.field}
      data-cy={p.dataCy}
      onKeyUp={handleKeyUp}
    >
      <div ref={invertedStyleCheckerRef} />
      {p.label && <BaseLabel required={p.required} optional={p.optional}>{p.label}</BaseLabel>}
      <div className="DatePickerInner">
        { renderPicker() }
      </div>
      {p.infoAfterInputField && <p className="BaseInputInfoAfterInputField">{p.infoAfterInputField}</p>}
    </div>
  }} />
}

export default DatePicker;