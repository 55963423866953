import { reaction, runInAction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { BaseTableColumnConfig } from '../../../base/components/BaseTable/BaseTable';
import IndexDirectory from '../../../base/components/IndexDirectory/IndexDirectory';
import IndexDirectoryState from '../../../base/components/IndexDirectory/IndexDirectoryState';
import { SupportGroupEndpoints } from '../../../base/endpoints/supportGroup.endpoints';
import { useOnMount } from '../../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../../base/hooks/useRootController.hook';
import { makeLaravelIndexDataFetcher } from '../../../base/utils/api.utils';
import { useProps, useStore } from '../../../base/utils/mobx.utils';
import { tick } from '../../../base/utils/waiters.utils';
import { ModelName } from '../../../constants/modelNames.enum';
import { Company } from '../../../models/makeCompany.model';
import { SupportGroup } from '../../../models/makeSupportGroup.model';
import SupportGroupJoinedCountDisplay from '../../SupportGroupJoinedCountDisplay/SupportGroupJoinedCountDisplay';
import UsernameRenderer from '../../UsernameRenderer/UsernameRenderer';
import './CompanySupportGroupIndexDirectory.scss';

type CompanySupportGroupIndexDirectoryProps = {
  company?: Company,
}

const CompanySupportGroupIndexDirectory: React.FC<CompanySupportGroupIndexDirectoryProps> = React.memo(props => {

  const { API } = useControllers();

  const p = useProps(props);
  const s = useStore(() => ({
    get company() { return p.company; },
    indexListState: new IndexDirectoryState({
      sortByKeyName: 'timeScheduled',
      sortDirection: 'desc',
      searchable: true,
      searchByKeyName: 'title',
    }),
    shouldTriggerReload: false,
  }))

  useOnMount(() => {
    return reaction(
      () => s.shouldTriggerReload,
      async (v) => {
        if (v) {
          await tick(100);
          runInAction(() => {
            s.shouldTriggerReload = false;
          })
        }
      }
    )
  })

  const dataFetcher = makeLaravelIndexDataFetcher<SupportGroup>(
    API,
    ModelName.supportGroups,
    SupportGroupEndpoints.staff.index,
    () => ({
      perPage: 10,
      includes: ['facilitator', 'allowedCompany'],
      filter: {
        allowedCompanyId: s.company?.id,
      },
    }),
  )

  const tableColumnConfigs: BaseTableColumnConfig<SupportGroup>[] = [
    {
      label: 'ID',
      keyName: 'id',
      sortable: true,
      searchable: true,
    },
    {
      label: 'Title',
      keyName: 'title',
      type: 'longText',
      sortable: true,
      searchable: true,
    },
    {
      label: 'Topic',
      keyName: 'topicId',
      sortable: true,
      BodyCell: d => d.topic?.displayName,
    },
    {
      label: 'Subtitle',
      keyName: 'title',
      type: 'longText',
      sortable: false,
      searchable: false,
      hiddenFromTable: true,
    },
    {
      label: 'Event Time',
      keyName: 'timeScheduled',
      type: 'timestampFull',
      sortable: true,
      searchable: false,
    },
    {
      label: 'Company',
      keyName: 'allowedCompanyId',
      sortable: true,
      searchable: false,
      BodyCell: d => d.allowedCompany?.name ?? ""
    },
    {
      label: 'Availability',
      keyName: 'reservationCount',
      sortable: true,
      searchable: false,
      BodyCell: d => <SupportGroupJoinedCountDisplay supportGroup={d} />
    },
    {
      label: 'Facilitator',
      keyName: 'facilitatorId',
      sortable: true,
      searchable: 'facilitator.username',
      searchFilterLabel: "Facilitator Username",
      BodyCell: d => <UsernameRenderer user={d.facilitator} userId={d.facilitatorId} />
    },
    {
      label: 'Duration',
      keyName: 'scheduledDurationInMinutes',
      sortable: false,
      searchable: false,
      BodyCell: d => {
        const value = parseInt('' + d.scheduledDurationInMinutes) || 0;
        if (value) return <span>{value} min</span>
      }
    },
  ]

  return <Observer children={() => (
    <IndexDirectory<SupportGroup>
      className="CompanySupportGroupIndexDirectory"
      state={s.indexListState}
      dataFetcher={dataFetcher}
      tablePropsGetter={() => ({
        columnConfigs: tableColumnConfigs,
      })}
      shouldTriggerReload={s.shouldTriggerReload}
    />
  )} />
})

export default CompanySupportGroupIndexDirectory;