import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState, UnknownObject } from '../../base/@types';
import BaseLabel from '../../base/components/BaseLabel/BaseLabel';
import BaseRadioGroup from '../../base/components/BaseRadioGroup/BaseRadioGroup';
import InfoBanner from '../../base/components/InfoBanner/InfoBanner';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { CommunicationType } from '../../constants/communicationTypes.descriptors';
import { CounsellingType } from '../../constants/counsellingTypes.descriptors';
import { FEATURE_FLAGS } from '../../env';
import { CounsellingApplicationSnapshot } from '../../models/makeCounsellingApplication.model';
import CommunicationTypeRenderer from '../CommunicationTypeRenderer/CommunicationTypeRenderer';
import CounsellingTypeRenderer from '../CounsellingTypeRenderer/CounsellingTypeRenderer';

interface CommunicationTypeSelectorProps<T extends UnknownObject = UnknownObject> {
  application?: CounsellingApplicationSnapshot | null,
  form?: T,
  field?: keyof T | 'communicationTypePreference',
  disabled?: boolean,
  label?: string,
  onlyShowTypes?: CommunicationType[],
  hideDisabled?: boolean,
}

const CommunicationTypeSelector: React.FC<CommunicationTypeSelectorProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    get application() {
      return p.form || p.application;
    },
    get isOneToOne() {
      return !p.application || [CounsellingType.OneToOne, CounsellingType.PaidOneToOne, null].includes(p.application?.type || '');
    },
    get radios() {
      return [
        { label: <CommunicationTypeRenderer value={CommunicationType.Text} />, value: 'text', color: 'skyblue', disabled: p.disabled || !s.isOneToOne },
        { label: <CommunicationTypeRenderer value={CommunicationType.Video} />, value: 'video', color: 'red', disabled: p.disabled },
        ...FEATURE_FLAGS.ENABLE_VOIP ? [{ label: <CommunicationTypeRenderer value={CommunicationType.VOIP} />, value: 'voip', color: 'orange', disabled: true }] : [],
      ];
    },
    get filteredRadios() {
      return s.radios.filter(radio => (p.onlyShowTypes ? p.onlyShowTypes?.includes(radio.value as CommunicationType) : true) && (p.hideDisabled ? !radio.disabled : true));
    }
  }));

  return <Observer children={() => {
    return <div className="CommunicationTypeSelector">
      { p.label && <BaseLabel>{p.label}</BaseLabel>}
      {
        s.application && s.filteredRadios && <BaseRadioGroup
          form={s.application}
          options={s.filteredRadios}
          // @ts-ignore
          field={p.form ? p.field : 'communicationTypePreference'}
          nullable={false}
        />
      }
      { s.isOneToOne || <InfoBanner colorCodedState={ColorCodedState.attention} icon="warning">
        <p>Only Video Chat is available to the selected counselling type <strong><CounsellingTypeRenderer value={p.application?.type} /></strong>.</p>
      </InfoBanner>}
    </div>
  }} />

}

export default CommunicationTypeSelector;