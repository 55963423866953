import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Renderable } from '../../../../../base/@types';
import BaseButton from '../../../../../base/components/BaseButton/BaseButton';
import BaseButtonGroup from '../../../../../base/components/BaseButtonGroup/BaseButtonGroup';
import joinClassName from '../../../../../base/utils/className.utils';
import { renderRenderable } from '../../../../../base/utils/components.utils';
import { useProps, useStore } from '../../../../../base/utils/mobx.utils';
import './StatSection.scss';

interface StatSectionProps {
  className?: string,
  title: Renderable,
  subtitle?: Renderable,
  showContentByDefault?: boolean,
}

const StatSection: React.FC<StatSectionProps> = props => {

  const p = useProps(props);
  const s = useStore(() => ({
    shouldRender: p.showContentByDefault ?? false,
  }));

  const toggleRender = action(() => {
    s.shouldRender = !s.shouldRender;
  })

  return <Observer children={() => (
    <div className={
      joinClassName(
        'StatSection',
        p.className,
        s.shouldRender ? 'contentVisible' : 'contentHidden'
      )
    }>
      <header className="StatSectionHeader" onClick={toggleRender}>
        <div className="StatSectionHeaderInner">
          <h3 className="StatSectionTitle">{renderRenderable(p.title)}</h3>
          {p.subtitle && <div className="StatSectionSubtitle">{renderRenderable(p.subtitle)}</div>}
        </div>
        <div className="StatSectionHeaderControls">
          <BaseButtonGroup>
            <BaseButton
              className="subtle"
              appearance="icon"
              icon={s.shouldRender ? 'minus' : 'plus'}
              iconVariant="filled"
              size="sm"
            />
          </BaseButtonGroup>
        </div>
      </header>
      {
        s.shouldRender && <div className="StatSectionInner">
          {props.children}
        </div>
      }
    </div>
  )} />
}

export default StatSection;