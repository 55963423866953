
import { User } from '@sentry/react';
import { reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Nillable, Nullable } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import FormForm from '../../base/components/FormForm/FormForm';
import FormInput from '../../base/components/FormInput/FormInput';
import InfoBanner from '../../base/components/InfoBanner/InfoBanner';
import { ThoughtEndpoints } from '../../base/endpoints/thought.endpoints';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { makeForm } from '../../base/mediators/form.mediator';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { getNowTimestampUtc } from '../../base/utils/time.utils';
import { Emotion } from '../../constants/emotions.enum';
import { ModelName } from '../../constants/modelNames.enum';
import { Thought } from '../../models/makeThought.model';
import { useGetThought } from '../../requests/useGetThought.request';
import { useGetUser } from '../../requests/useGetUser.request';
import EmotionSelector from '../EmotionSelector/EmotionSelector';
import './ThoughtEditor.scss';

interface ThoughtEditorProps {
  thought: Thought,
  applyChangesImmediately?: boolean,
  onSuccess?: (thought: Thought) => void,
  onDiscardChanges?: () => void,
  disabled?: boolean,
  standalone?: boolean,
  showEmotionTextLabel?: boolean,
  canChangeEmotionAfterSelection?: boolean,
}

const ThoughtEditor: React.FC<ThoughtEditorProps> = props => {

  const p = useProps(props);

  const { API, AUTH, UI } = useControllers();

  const s = useStore(() => ({
    form: makeForm(p.thought.$getSnapshot()),
    author: null as Nullable<User>,
    get belongsToCurrentUser() {
      return !p.thought.id || (AUTH.currentUser?.id === p.thought.userId);
    },
    get canUseAdminEndpoint() {
      // can also check author's age
      return AUTH.can.thoughtCatcher_.moderate_.someAgeGroups || AUTH.can.provideCounsellingFor_.someUserGroups;
    },
    save: () => new Promise<boolean>(async (resolve, reject) => {
      if (p.disabled) return;
      if (!s.belongsToCurrentUser && !s.canUseAdminEndpoint) {
        UI.DIALOG.error({
          heading: "You do not seem to have the permission to update this thought.",
          // body: <>
          //   thoughtID {p.thought.id} currentUserId {AUTH.currentUser?.id}
          // </>
        })
        return;
      }
      try {
        const isNew = !p.thought.id;
        const endpointSet = ThoughtEndpoints[s.canUseAdminEndpoint && !s.belongsToCurrentUser ? 'staff' : 'own'];
        const url = p.thought.id ? endpointSet.update(p.thought.id) : endpointSet.create();
        const payload = s.form.value;
        if (isNew) {
          payload.timeCreated = payload.timeUpdated = getNowTimestampUtc();
        }
        if (!payload.emotionId) {
          UI.DIALOG.attention({
            heading: 'This thought does not seem to have a "mood" selected.',
            body: 'Please select an emoji in the form before saving it.',
          })
          return;
        }
        Reflect.deleteProperty(payload, 'originalThoughtId');
        Reflect.deleteProperty(payload, 'reactionIds');
        Reflect.deleteProperty(payload, 'commentIds');
        Reflect.deleteProperty(payload, 'flagIds');
        Reflect.deleteProperty(payload, 'userId');
        const apiMethod = payload.id ? API.patch : API.post;
        const savedThought = await apiMethod<Thought>(url, ModelName.thoughts, payload);
        if (!savedThought) {
          reject('Failed to saved thought, please try again later');
          return;
        }
        p.onSuccess && p.onSuccess(savedThought);
        if (isNew) UI.TOAST.success('Your thought has been posted!');
        else UI.TOAST.success('Your edits to the thought has been saved.');
        resolve(true);
      } catch (e) {
        reject(e);
        UI.DIALOG.error({
          heading: 'Unable to save thought',
          error: e,
        })
      }
    }),
    get saveButtonLabel() {
      return p.thought.id ? 'Save' : `Post ${s.form.fields.isPrivate.value ? 'Private' : 'Public'} Thought`;
    },
    discardChanges: () => {
      if (p.applyChangesImmediately && p.thought.id) {
        p.thought.$patch(s.form.originalSource);
      }
      p.onDiscardChanges && p.onDiscardChanges();
    }
  }));

  useGetUser({
    observable: s,
    key: 'author',
  }, p.thought.userId ?? p.thought.user?.id);

  const handleFormChange = () => {
    if (!p.applyChangesImmediately) return;
    p.thought.$patch(s.form.value);
  }

  const handleEmotionChange = (e?: Nillable<Emotion>) => {
    s.form.set('emotionId', e);
    if (p.applyChangesImmediately) p.thought.emotionId = e;
  }

  useOnMount(() => {
    return reaction(
      () => true,
      value => {
        if (value) s.form.set('isPrivate', true);
      },
      { fireImmediately: true }
    )
  });

  useGetThought({}, p.thought.id);

  return <Observer children={() => (
    <div className={joinClassName('ThoughtEditor', p.standalone && 'standalone')} data-emotion={s.form.value.emotionId} data-is-private={s.form.value.isPrivate}>
      <FormForm
        form={s.form}
        className="ThoughtEditorForm"
        disabled={p.disabled}
        onChange={handleFormChange}
      >
        <EmotionSelector
          selectedGetter={() => s.form.value.emotionId}
          onSelect={handleEmotionChange}
          disabled={p.disabled}
          canChangeAfterSelection={p.canChangeEmotionAfterSelection}
          showTextLabel={p.showEmotionTextLabel}
        />
        <div className="ThoughtEditorTextareaFieldSet">
          <FormInput<typeof s.form.fields> field="content" label="Thought Content" type="textarea" placeholder="What are you thinking?" rows={UI.onlyPhones ? 3 : 5} autoFocus={!p.thought.id} />
          <FormInput<typeof s.form.fields> field="situation" label="Situation" type="textarea" placeholder="What was the situation?" rows={UI.onlyPhones ? 3 : 4} />
          <FormInput<typeof s.form.fields> field="response" label="Response" type="textarea" placeholder="How did you respond to this situation?" rows={UI.onlyPhones ? 3 : 4} />
          <FormInput<typeof s.form.fields> field="isPrivate" label="" type="hidden" placeholder="How did you respond to this situation?" rows={UI.onlyPhones ? 3 : 4} />
        </div>
        <div className="ThoughtEditorPrivateToggleContainer">
          {s.form.value.isPrivate && <InfoBanner icon="info">
            <p>Private thoughts are only viewable by yourself, turn2me counsellors and moderators.</p>
            {!!p.thought.timeHidden && <p>Thoughts can only be private if it has been hidden by our system or our moderators. If you think this thought has been hidden by mistake, please let us know.</p>}
          </InfoBanner>}
        </div>
      </FormForm>
      <footer className="ThoughtEditorFooter">
        <div className="ThoughtEditorFooterButtonGroup">
          <BaseButton
            className="ThoughtEditorSaveButton"
            dataCy="save-thought-editor-form"
            onClick={s.save}
            rounded={UI.fromTablet}
            size="lg"
            disabled={p.disabled}
            label={s.saveButtonLabel}
          />
          <BaseButton
            className="ThoughtEditorCancelButton subtle"
            dataCy="cancel-thought-editor-form"
            onClick={s.discardChanges}
            rounded={UI.fromTablet}
            size="lg"
            disabled={p.disabled}
            label="Cancel"
            color={p.standalone ? undefined : 'white'}
          />
        </div>
      </footer>
    </div>
  )} />

}

export default ThoughtEditor;