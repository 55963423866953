import { Nillable, StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { ModelName } from "../constants/modelNames.enum";
import { hasTimestamps } from "../traits/hasTimestamps.trait";
import { Contact } from "./makeContact.model";
import { SupportGroup } from "./makeSupportGroup.model";
import { User } from "./makeUser.model";

export type SupportGroupReservationSnapshot = ReturnType<typeof makeSupportGroupReservationSnapshotBase>;

export type SupportGroupReservationRelatedModels = {
  user?: User,
  group?: SupportGroup,
  keyWorker?: Contact,
}

export type SupportGroupReservationExtendedProperties = {
  readonly isRevoked: boolean,
};

export type SupportGroupReservation = StandardModel<
  SupportGroupReservationSnapshot,
  SupportGroupReservationRelatedModels,
  SupportGroupReservationExtendedProperties
>

export const makeSupportGroupReservationSnapshotBase = () => ({
  id: '',
  groupId: '',
  userId: '',
  revokeReason: '',
  timeRevoked: '',
  keyWorkerId: '' as Nillable<string>,
  ...hasTimestamps(),
})

export const makeSupportGroupReservation = createStandardModelFactory < SupportGroupReservation, SupportGroupReservationRelatedModels, SupportGroupReservationExtendedProperties>({
  name: ModelName.supportGroupReservations,
  snapshotFactory: makeSupportGroupReservationSnapshotBase,
  relationshipsSchema: {
    user: ModelName.users,
    group: ModelName.supportGroups,
    keyWorker: ModelName.contacts,
  },
  extendedPropertiesFactory: m => ({
    get isRevoked() {
      return !!m.timeDeleted || !!m.revokeReason;
    }
  })
})