import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseButton from '../../../../base/components/BaseButton/BaseButton';
import BaseSpacer from '../../../../base/components/BaseSpacer/BaseSpacer';
import { ColorName } from '../../../../base/constants/color.enum';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import { AppPermissionRole } from '../../../../constants/permissionGroups.constants';
import { AppPermission } from '../../../../constants/permissions.constants';
import { AppStaffRole } from '../../../../constants/staffRoles.constants';
import './PermissionRolesManager.scss';
import RoleToggle from './RoleToggle';

type PermissionRolesManagerProps = {
  selectedStaffRoles: AppStaffRole[],
  selectedPermissions: AppPermission[],
  permissionRoles: ReadonlyArray<AppPermissionRole>,
  disabled?: boolean,
}

const PermissionRolesManager: React.FC<PermissionRolesManagerProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    hasSomeRolePermissions(role: AppPermissionRole) {
      return role.permissions.some(perm => p.selectedPermissions.includes(perm));
    },
    hasAllRolePermissions(role: AppPermissionRole) {
      // if all permissions in role is selected.
      return role.permissions.every(perm => p.selectedPermissions.includes(perm));
    },
    get hasPartialRoles() {
      return p.permissionRoles.some(role => s.hasAllRolePermissions(role))
    },
    get hasAllRoles() {
      return p.permissionRoles.every(role => s.hasAllRolePermissions(role))
    },
    get hasNoRoles() {
      return !s.hasPartialRoles
    },
    removeAllRolesAndPermissions() {
      p.selectedStaffRoles.splice(0)
      p.selectedPermissions.splice(0)
    },
    handleResetRoleClick() {
      s.removeAllRolesAndPermissions()
    },
  }))

  return <Observer children={() => (
    <div className="PermissionRolesManager" data-primary-color={ColorName.Orange} data-cy="Permission Roles Manager">
      <div className="PermissionRolesManagerInner">
        <header className="PermissionRolesManagerHeader">
          <div className="PermissionRolesManagerHeaderInner">
            <h3>Assign Staff Roles</h3>
            <p>Controls the roles inherited by a <strong>staff member</strong>. Many roles have overlapping permissions with other roles; a prime example is an Administrator, a collection of all roles. Individual permissions can be explicitly modified within advanced permissions, offering Ad Hoc role types. Note that advanced permissions will override role permissions.</p>
            {p.permissionRoles.filter(rolePerm => !(rolePerm.roleName === AppStaffRole.accreditedCounsellor || rolePerm.roleName === AppStaffRole.placementCounsellor || rolePerm.roleName === AppStaffRole.preAccreditedCounsellor)).map((role, ind) => <span key={`${role}-${ind}`}>
              <BaseSpacer size="sm" />
              <h4>{role.name}</h4>
              <p>{role.description}</p>
              {!!role.Description && <>
                <BaseSpacer size="sm" />
                {role.Description}
              </>}
            </span>)}
          </div>
          <div className="PermissionRolesManagerHeaderEndSlot">
            {!p.disabled && <BaseButton
              label="Reset"
              onClick={s.handleResetRoleClick}
              disabled={!s.hasPartialRoles}
            />}
          </div>
        </header>
        <div className="PermissionRolesManagerToggleList">
          {p.permissionRoles.filter(rolePerm => (rolePerm.roleName !== AppStaffRole.counsellor)).map((rolePerms, ind) => <RoleToggle
            key={`${rolePerms}-${ind}`}
            selectedPermissions={p.selectedPermissions}
            selectedRoles={p.selectedStaffRoles}
            thisRoleToggleName={rolePerms.roleName}
            forValue={rolePerms}
            disabled={p.disabled}
            removeAllRolesAndPermissions={s.removeAllRolesAndPermissions}
            // disabled={p.disabled || (p.selectedStaffRoles.length !== 0 && !p.selectedStaffRoles.includes(rolePerms.roleName))}
          />)}
        </div>
      </div>
    </div>
  )} />
}

export default PermissionRolesManager;