import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState } from '../../../../base/@types';
import BaseArticle from '../../../../base/components/BaseArticle/BaseArticle';
import BaseButton from '../../../../base/components/BaseButton/BaseButton';
import BaseButtonGroup from '../../../../base/components/BaseButtonGroup/BaseButtonGroup';
import BaseSpacer from '../../../../base/components/BaseSpacer/BaseSpacer';
import ClickToCopy from '../../../../base/components/ClickToCopy/ClickToCopy';
import ErrorRenderer from '../../../../base/components/ErrorRenderer/ErrorRenderer';
import NavBarHeightSpacer from '../../../../base/components/NavBarHeightSpacer/NavBarHeightSpacer';
import ShadedBlock from '../../../../base/components/ShadedBlock/ShadedBlock';
import { useOnMount } from '../../../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import { makeActionConfig } from '../../../../base/utils/actionConfig.utils';
import { reportError } from '../../../../base/utils/errors.utils';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import { AlwaysTrueFn, cond } from '../../../../base/utils/ramdaEquivalents.utils';
import { getUrlParams } from '../../../../base/utils/urlParams.utils';
import { validateEmail } from '../../../../base/validators/email.validator';
import OverlayAgeVerification from '../../../../components/OverlayAgeVerification/OverlayAgeVerification';
import { tempInvitationStorageKey } from '../../../../controllers/auth.controller';
import { getAgeFromDateOfBirth, isYoungPeopleAge } from '../../../../utils/ageAndDateOfBirth.utils';
import ClientAppSubmodule from '../../_components/ClientAppSubmodule/ClientAppSubmodule';
import SimplePageContentWrapper from '../../_components/SimplePageContentWrapper/SimplePageContentWrapper';
import './PageInvitation.scss';

type PageInvitationProps = {}

const PageInvitation: React.FC<PageInvitationProps> = props => {

  const { UI, API, AUTH, STORAGE, NAVIGATOR, COUNSELLING } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    uuid: '',
    inviterEmail: '',
    applicationType: '',
    decodeError: false,
    get hasNoDateOfBirth() {
      return AUTH.currentUser && !AUTH.currentUser.dateOfBirth;
    },
    get hasError() {
      if (s.decodeError) return s.decodeError;
      if (s.applicationType) {
        if (s.applicationType.includes('young-people')) {
          return !s.currentUserIsYP;
        }
        if (s.applicationType === 'couples') {
          return s.currentUserIsYP;
        }
      }
      return Boolean(s.uuid && s.inviterEmail);
    },
    get currentUserEmail() {
      return AUTH.currentUser?.email;
    },
    get inviterIsCurrentUser() {
      return s.currentUserEmail === s.inviterEmail
    },
    get currentUserAge() {
      return AUTH.currentUser?.dateOfBirth ? getAgeFromDateOfBirth(AUTH.currentUser?.dateOfBirth) : undefined;
    },
    get currentUserIsYP() {
      return isYoungPeopleAge(s.currentUserAge);
    },
  }));

  useOnMount(action(() => {
    const { invitationId: id, from, type } = getUrlParams();
    STORAGE.remove(tempInvitationStorageKey);
    if (!id || !from) {
      onDecodeError();
      return;
    }
    try {
      const inviterEmail = atob(decodeURIComponent(from));
      const applicationType = atob(decodeURIComponent(type || ''));
      if (applicationType && (
        applicationType !== 'couples' &&
        !applicationType.includes('young-people')
      )) {
        onDecodeError();
        return;
      }
      if (validateEmail(inviterEmail) !== true) {
        onDecodeError();
        return;
      }
      s.uuid = id;
      s.inviterEmail = inviterEmail;
      s.applicationType = applicationType;
    } catch (e) {
      reportError(e);
      onDecodeError();
    }
  }))

  const onDecodeError = action(() => {
    s.decodeError = true;
  })

  const performAction = (action: 'decline' | 'accept') => async () => {
    switch (action) {
      case 'decline': {
        const confirm = await UI.DIALOG.present({
          heading: 'Confirmation',
          body: () => <>Please confirm that you are declining the invitation from user <strong>{s.inviterEmail}</strong>.</>,
        })
        if (!confirm) return;
        break;
      }
      case 'accept': {
        const confirm = await UI.DIALOG.present({
          heading: 'Confirmation',
          body: () => <p>By accepting the invitation, you confirm that you have read and understand the information on this page.</p>,
        })
        if (!confirm) return;
        break;
      }
    }
    const url = `/client/invitations/${s.uuid}/accept`;
    try {
      await API.postRaw(url);
      switch (action) {
        case 'decline': {
          UI.DIALOG.success({
            heading: 'You have successfully declined the invitation.',
            actions: [
              makeActionConfig('Take me to dashboard', () => {
                NAVIGATOR.navigateTo('/app/explore')
              })
            ]
          })
          break;
        }
        case 'accept': {
          UI.DIALOG.success({
            heading: 'You have successfully accepted the invitation.',
            actions: [
              makeActionConfig('OK', () => {
                NAVIGATOR.navigateTo('/app/counselling')
              })
            ]
          })
          COUNSELLING.getCurrentUserApplications();
          break;
        }
      }
    } catch (e) {
      reportError(e);
      UI.DIALOG.error({
        heading: 'An error occurred',
        body: () => <>
          <p>Please try again, or contact us if this keeps occurring.</p>
          <ErrorRenderer error={(e as any).response} />
        </>
      })
    }
  }

  const verifyAge = () => UI.OVERLAY.present({
    name: 'OverlayAgeVerification',
    component: <OverlayAgeVerification />,
    appearance: 'card',
  })

  const renderMessage = cond([
    [
      () => s.inviterIsCurrentUser,
      () => <div className="PageInvitationMessageWrapper">
        <blockquote>
          <p>You have opened your own invitation link.</p>
        </blockquote>
        <p>Please forward the link you have opened to your intended invitee:</p>
        <BaseSpacer size=".5em" />
        <ClickToCopy text={window.location.href} buttonLabel="Copy Link" successMessageComposer={() => "Link copied!"}>
          <code>{window.location.href}</code>
        </ClickToCopy>
      </div>
    ],
    [
      () => s.hasNoDateOfBirth,
      () => <div className="PageInvitationMessageWrapper">
        <h3>Before viewing the invitation, please provide your date of birth to verify your age.</h3>
        <br />
        <BaseButton onClick={verifyAge} color="green" dataCy="verify-age">Verify Age</BaseButton>
      </div>
    ],
    [
      () => s.hasError,
      () => <ShadedBlock className="PageInvitationError" color="red">
        <h3>The invitation link seems invalid or you are not a qualified invitee.</h3>
        <p>Please check with the person you have received this invitation link from. Check if your date of birth is correct as some invitations might be age-specific.</p>
        <p>If you think this is an error, please contact us and we will help you out.</p>
      </ShadedBlock>
    ],
    [
      () => s.currentUserIsYP,
      () => <div className="PageInvitationMessageWrapper">
        <blockquote>
          <p>This is an invitation from user <strong>{s.inviterEmail}</strong>.</p>
        </blockquote>
        <BaseArticle>
          <p>If you do not know the user <strong>{s.inviterEmail}</strong>, or do not want to accept the invitation, simply ignore this message and close the page.</p>
          <p>To accept the invitation, please be aware that:</p>
          <ul>
            <li>This is an invitation for an application for <strong>counselling for young people</strong>.</li>
            <li>Your username, name and email will be disclosed to user <strong>{s.inviterEmail}</strong>.</li>
            <li>A turn2me counsellor will review this application once you accept.</li>
            <li>You might be requested to provide extra information if it is deemed necessary.</li>
          </ul>
        </BaseArticle>
        <BaseButtonGroup>
          <BaseButton dataCy="accept-invitation-yp" colorCodedState={ColorCodedState.positive} onClick={performAction('accept')}>Accept invitation</BaseButton>
        </BaseButtonGroup>
      </div>
    ],
    [
      AlwaysTrueFn, // couples
      () => <div className="PageInvitationMessageWrapper">
        <blockquote>
          <p>This is an invitation from user <strong>{s.inviterEmail}</strong>.</p>
        </blockquote>
        <BaseArticle>
          <p>If you do not know the user <strong>{s.inviterEmail}</strong>, or do not want to accept the invitation, simply ignore this message and close the page.</p>
          <p>To accept the invitation, please be aware that:</p>
          <ul>
            <li>This is an invitation for an application for <strong>couples counselling</strong>.</li>
            <li>Your username, name and email will be disclosed to user <strong>{s.inviterEmail}</strong>.</li>
            <li>By accepting the invitation, you will become a co-applicant of the counselling application.</li>
            <li>A turn2me counsellor will review this application once you accept.</li>
            <li>Once approved, you and your partner must attend each scheduled sessions. If you cannot attend a session, please contact your counsellor at least 24 hours in advance to cancel or reschedule it.</li>
            <li>You might be requested to provide extra information if it is deemed necessary.</li>
          </ul>
        </BaseArticle>
        <BaseButtonGroup>
          <BaseButton dataCy="accept-invitation-couples" colorCodedState={ColorCodedState.positive} onClick={performAction('accept')}>Accept invitation</BaseButton>
        </BaseButtonGroup>
      </div>
    ]
  ])


  return <Observer children={() => (
    <ClientAppSubmodule className="PageInvitation" name="Invitation" stringTitle="Invitation" title="Invitation">
      <BaseSpacer size="1em"/>
      <SimplePageContentWrapper>
        <h2>Invitation</h2>
        {renderMessage()}
        <NavBarHeightSpacer />
      </SimplePageContentWrapper>
    </ClientAppSubmodule>
  )} />
}

export default PageInvitation;