import { action, flow, observable } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseSpacer from '../../base/components/BaseSpacer/BaseSpacer';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import InfoBanner from '../../base/components/InfoBanner/InfoBanner';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { ChatMediator, makeChatMediator } from '../../base/mediators/chat.mediator';
import { removeFromArrayById } from '../../base/utils/array.utils';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { eqBy, last } from '../../base/utils/ramdaEquivalents.utils';
import { scrollElementTo } from '../../base/utils/scrollElementTo.utils';
import { equalByString } from '../../base/utils/string.utils';
import tick from '../../base/utils/waiters.utils';
import { CommunicationType } from '../../constants/communicationTypes.descriptors';
import { ChatThread } from '../../models/makeChatThread.model';
import { CounsellingApplication } from '../../models/makeCounsellingApplication.model';
import { CounsellingSession, makeCounsellingSession } from '../../models/makeCounsellingSession.model';
import { getCounsellingSession } from '../../requests/getCounsellingSession.request';
import { saveCounsellingSession } from '../../requests/saveCounsellingSession.request';
import { useGetCounsellingSessions } from '../../requests/useGetCounsellingSessions.request';
import EmailCounsellingSession from './EmailCounsellingSession';

interface EmailCounsellingManagerProps {
  application: CounsellingApplication,
  role: 'counsellor' | 'client',
  containerRef?: React.RefObject<HTMLElement>,
}

const EmailCounsellingManager: React.FC<EmailCounsellingManagerProps> = props => {

  const p = useProps(props);
  
  const { MESSENGER, UI, API, AUTH } = useControllers();

  const s = useStore(() => ({
    chatControllers: [] as ChatMediator[],
    sessions: [] as CounsellingSession[],
    get sections() {
      return s.sessions.map((session, i) => observable({
        id: session.id,
        session,
        get chatController() {
          return s.chatControllers.find(chat => equalByString(chat?.associatedModel?.id, session.id))
        },
      }))
    },
    openedSectionIds: new Set() as Set<string | number>,
    get latestSection() {
      return last(s.sections)
    },
    get counsellorId() {
      return p.application.counsellorId || p.application.counsellor?.id;
    },
    get applicantId() {
      return p.application.applicantId;
    },
    get currentUserIsAssignedCounsellor() {
      return equalByString(p.application.counsellorId, AUTH.currentUser?.id);
    },
    get asCounsellor() {
      return p.role === 'counsellor';
    },
    get asClient() {
      return p.role === 'client';
    },
    get lastMessageId() {
      return last(last(s.chatControllers)?.messages)?.id;
    },
    get lastSessionHasEnded() {
      return last(s.sessions)?.timeEnded;
    },
    get lastSessionHasStarted() {
      return last(s.sessions)?.timeStarted;
    },
    
    get applicationEnded() {
      return Boolean(p.application.timeArchived || p.application.timeCompleted);
    },
    get shouldShowAddNewSessionButton() {
      return s.asCounsellor && s.lastSessionHasEnded && !s.applicationEnded;
    },
    get shouldAutoScrollToBottomOnDataLoad() {
      return s.sessions.length > 1 || s.latestSection?.session.timeStarted;
    }
  }));

  const addChatThread = action((chatThread: ChatThread) => {
    const existingChat = s.chatControllers.find(c => c.id === chatThread.id);
    if (existingChat) {
      return existingChat;
    }
    const newChat = makeChatMediator(MESSENGER, chatThread);
    s.chatControllers.push(newChat);
    return newChat;
  })

  useGetCounsellingSessions({
    observable: s,
    key: 'sessions',
    onDataFetch: action(() => {
      if (s.sessions.length === 0) createSession();
      const reversedSessions = s.sessions.reverse();
      s.sessions.splice(0);
      s.sessions.push(...reversedSessions);
      const threads = s.sessions.map(session => session.threads!).flat();
      threads.forEach(addChatThread);
      viewLatestSession();
      if (s.shouldAutoScrollToBottomOnDataLoad) scrollToBottom();
    })
  }, {
    filter: { applicationId: p.application.id },
    perPage: -1,
    include: ['threads.model', 'assignments.target'],
    latest: true,
  })

  const expandSection = action((sessionId: string | number) => {
    s.openedSectionIds.add(sessionId + '');
  })

  const viewLatestSession = () => {
    const lastSessionId = last(s.sections)?.id;
    if (!lastSessionId) return;
    expandSection(lastSessionId);
  }

  const scrollToBottom = flow(function* () {
    if (!p.containerRef?.current) return;
    yield tick(1000);
    scrollElementTo({el: p.containerRef.current, top: p.containerRef.current.scrollHeight});
  })

  const createSession = () => new Promise<CounsellingSession>(
    flow(function * (resolve, reject) {
      try {
        // const now = getNowTimestampUtc();
        const newSession = makeCounsellingSession({
          type: CommunicationType.Email,
          // email sessions must be a one-to-one session
          applicationId: p.application.id,
          counsellorId: s.counsellorId,
          applicantId: s.applicantId,
          // timeScheduled: now,
          clientIds: [s.applicantId],
        })
        const savedSnapshot = yield saveCounsellingSession(API, newSession);
        const [ session ] = yield getSessionAndChatController(savedSnapshot.id);
        s.sessions.push(session);
        expandSection(session.id);
        resolve(session);
      } catch(e) {
        reportError(e);
        reject(e)
        UI.DIALOG.error({
          heading: 'Failed to initiate a new session',
          body: () => <>
            <p>Please try again; if this keeps happening please contact the development team for assistance.</p>
            <ErrorRenderer error={(e as any).response} />
          </>
        })
      }
    })
  )

  const getSessionAndChatController = (sessionId: string) => new Promise<[CounsellingSession, ChatMediator | null]>(
    flow(function * (resolve, reject) {
      const session: CounsellingSession = yield getCounsellingSession(API, sessionId, {
        include: ['assignments.target', 'threads.model'],
      }).catch(e => {
        reject(e);
        reportError(e);
      });
      const firstThread = session.threads![0]!;
      if (firstThread) {
        const chat = addChatThread(firstThread);
        resolve([session, chat]);
        return;
      }
      resolve([session, null]);
    })
  )

  const handleStartSession = (session: CounsellingSession) => new Promise<CounsellingSession>(
    flow(function * (resolve, reject) {
      try {
        const sessionId = session.id;
        const [ sessionFull ] = yield getSessionAndChatController(sessionId);
        const existingSession = s.sessions.find(ses => eqBy(String, ses.id, sessionId))
        existingSession?.$patch(sessionFull);
        resolve(existingSession!);
      } catch (e) {
        reject(e);
        reportError(e);
        UI.DIALOG.error({
          heading: 'Failed to get the full details of a session',
          body: () => <>
            <p>Please try again; if this keeps happening please contact the development team for assistance.</p>
            <ErrorRenderer error={(e as any).response} />
          </>
        })
      }
    })
  )

  const handleDeleteSession = action((session: CounsellingSession) => {
    removeFromArrayById(s.sessions, session);
    removeFromArrayById(s.sections, {id: session.id});
  })
  
  const toggleSectionOpen = (section: {id: string | number}) => action(() => {
    const id = section.id + '';
    s.openedSectionIds.has(id) ? s.openedSectionIds.delete(id) : s.openedSectionIds.add(id);
  });

  useOnMount(() => {
    return UI.onBeforePrint(action(() => {
      s.sessions.map(ses => ses.id + '').forEach(id => s.openedSectionIds.add(id));
    }))
  })

  return <Observer children={() => (
    <div className="EmailCounsellingManager">
      <div className="EmailCounsellingSessionsList">
        {
          s.sections.map((section, i) => <EmailCounsellingSession
            key={section.id}
            session={section.session}
            index={i}
            application={p.application}
            open={s.openedSectionIds.has(section.id + '')}
            chat={section.chatController}
            onToggleOpen={toggleSectionOpen(section)}
            onDelete={handleDeleteSession}
            role={p.role}
            onStartSession={handleStartSession}
          />)
        }
        {s.shouldShowAddNewSessionButton && (
          <BaseButton icon="plus" onClick={createSession} className="EmailCounsellingManagerCreateSessionButton subtle">
            Create a New Session
          </BaseButton>
        )}
      </div>
      {
        s.asCounsellor && !s.currentUserIsAssignedCounsellor && <><ShadedBlock color="attention" colorIntensity="strong">
          <h3>You are not the counsellor of this application.</h3>
          {
            AUTH.isStaff && <p>As an admin, you can still send an email if necessary, although make sure this is well communicated with the client.</p>
          }
        </ShadedBlock><BaseSpacer size=".5em" /></>
      }
      {
        s.applicationEnded && <InfoBanner colorCodedState={ColorCodedState.attention}>
          <p>This application has {p.application.timeCompleted ? 'completed' : p.application.timeArchived ? 'been archived' : 'ended'}.</p>
        </InfoBanner>
      }
    </div>
  )} />
  
}

export default EmailCounsellingManager;