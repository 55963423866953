import { PaymentMethod } from '@stripe/stripe-js';
import { AxiosError } from 'axios';
import { action, flow, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject, ColorCodedState } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import InfoBanner from '../../base/components/InfoBanner/InfoBanner';
import LoadingIndicatorSection from '../../base/components/LoadingIndicatorSection/LoadingIndicatorSection';
import { showCreatePaymentMethodOverlay } from '../../base/components/OverlayCreatePaymentMethodForm/OverlayCreatePaymentMethodForm';
import { PaymentEndpoints } from '../../base/endpoints/payment.endpoints';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { makeActionConfig } from '../../base/utils/actionConfig.utils';
import { removeFromArrayById } from '../../base/utils/array.utils';
import joinClassName from '../../base/utils/className.utils';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { isFunction } from '../../base/utils/typeChecks.utils';
import { ModelName } from '../../constants/modelNames.enum';
import CreatePaymentMethodForm from '../CreatePaymentMethodForm/CreatePaymentMethodForm';
import PaymentMethodItem from '../PaymentMethodItem/PaymentMethodItem';
import './PaymentMethodList.scss';

interface PaymentMethodListProps {
  selected?: PaymentMethod | null;
  onSelect?: (paymentMethod: PaymentMethod | null) => unknown;
  showAddCardButton?: boolean;
  showCardFormIfNoCard?: boolean;
}

const PaymentMethodList: React.FC<PaymentMethodListProps> = props => {

  const p = useProps(props);

  const { API, UI } = useControllers();

  const s = useStore(() => ({
    paymentMethods: [] as PaymentMethod[],
    isLoading: false,
    hasLoaded: false,
    get interactable() {
      return p.onSelect && isFunction(p.onSelect);
    },
    errorWhenLoadingMethods: null as AxiosError | null | Error,
    isTryingAgain: false,
  }))

  const getAllPaymentMethods = () => new Promise<boolean>(
    flow(function * (resolve, reject) {
      try {
        const url = PaymentEndpoints.own.paymentMethodIndex();
        s.isLoading = true;
        const response = yield API.getRaw(url);
        s.paymentMethods.splice(0);
        s.paymentMethods.push(...response.data);
        s.hasLoaded = true;
        if (p.onSelect) {
          s.paymentMethods.length > 0 && p.onSelect(s.paymentMethods[0]);
        }
        resolve(true);
        s.errorWhenLoadingMethods = null;
      } catch (e) {
        reportError(e);
        // @ts-ignore
        const shouldTryAgain = JSON.stringify(e?.response)?.includes('try again');
        if (shouldTryAgain) s.isTryingAgain = true;
        reject(e);
        s.errorWhenLoadingMethods = e as Error;
      } finally {
        s.isLoading = false;
      }
    })
  )

  useOnMount(() => {
    getAllPaymentMethods();
    return when(
      () => s.isTryingAgain,
      flow(function* () {
        yield getAllPaymentMethods();
        s.isTryingAgain = false;
      })
    )
  });

  const createPaymentMethod = () => {
    showCreatePaymentMethodOverlay(UI, getAllPaymentMethods, { width: '33.33em' })
  }

  const onDelete = (pm: PaymentMethod) => UI.DIALOG.attention({
    heading: "Are you sure you want to remove this payment method?",
    defaultActions: ['negative'],
    actions: [
      makeActionConfig(
        'Confirm & Delete',
        () => new Promise<boolean>(flow(function* (resolve, reject) {
          try {
            const url = PaymentEndpoints.own.deletePaymentMethod(pm.id);
            yield API.delete(url, ModelName.payments, pm);
            removeFromArrayById(s.paymentMethods, pm)
            select?.(null);
            UI.DIALOG.success({ heading: 'The payment method has been removed.' });
            resolve(true);
          } catch (e) {
            reject(e);
            reportError(e);
            UI.DIALOG.error({
              heading: 'There was a problem deleting this payment method',
              body: 'Please try again or contact customer service and we will help you out.',
            })
          }
        }))
      )
    ]
  })

  const select = s.interactable ? action((paymentMethod: PaymentMethod | null) => {
    p.onSelect && p.onSelect(paymentMethod);
  }) : undefined;

  return <Observer children={() => (
    <div className={joinClassName('PaymentMethodList', s.interactable && 'interactable')}>
      {
        s.isLoading && <LoadingIndicatorSection colored />
      }
      {
        s.paymentMethods.length === 0 && s.hasLoaded && !props.showCardFormIfNoCard && <InfoBanner icon="info">
          <p>You have not added any cards yet.</p>
          {/* { props.showAddCardButton !== false && <BaseButton className="subtle" dataCy="add-new-credit-card" onClick={createPaymentMethod} icon="plus" fullWidth>Add a new card</BaseButton> } */}
        </InfoBanner>
      }
      {
        s.paymentMethods.length === 0 && s.hasLoaded && props.showCardFormIfNoCard && <div>
          {props.showCardFormIfNoCard && <CreatePaymentMethodForm onSuccess={getAllPaymentMethods} />}
        </div>
      }
      {
        s.paymentMethods.map(pm => <PaymentMethodItem
          key={pm.id}
          paymentMethod={pm}
          isSelected={pm.id === p.selected?.id}
          onClick={select}
          onDelete={onDelete}
        />)
      }
      {
        (s.paymentMethods.length !== 0 && s.hasLoaded && props.showAddCardButton !== false) && <BaseButton className="subtle" dataCy="add-new-credit-card" onClick={createPaymentMethod} icon="plus" fullWidth>Add a new card</BaseButton>
      }
      {
        (!s.isTryingAgain && s.errorWhenLoadingMethods) && <InfoBanner colorCodedState={ColorCodedState.error} icon="warning">
          <p>Failed to load your payment methods. Please try again later.</p>
          <ErrorRenderer error={(s.errorWhenLoadingMethods as AnyObject)?.response} />
        </InfoBanner>
      }
    </div>
  )} />

}

export default PaymentMethodList;