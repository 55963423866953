import { when } from "mobx";
import React from "react";
import { Alert, AlertClient__ApplicationRejection } from "../../@types/alert.types";
import { autoNavigateToCounsellingJourneyOverlay } from "../../actions/autoNavigateToCounsellingJourneyOverlay";
import { showApplicationRejectionConfirm } from "../../actions/showApplicationRejectionConfirm";
import { ColorCodedState } from "../../base/@types";
import ClickOrTap from "../../base/components/ClickOrTap/ClickOrTap";
import DateRenderer from "../../base/components/DateRenderer/DateRenderer";
import ShadedBlock from "../../base/components/ShadedBlock/ShadedBlock";
import { defaultInvoiceEndpointIncludes, InvoiceEndpoints } from "../../base/endpoints/invoices.endpoints";
import { DefaultSupportGroupIncludesForStaff } from "../../base/endpoints/supportGroup.endpoints";
import { reportError } from "../../base/utils/errors.utils";
import { resolveWhen } from "../../base/utils/promises.utils";
import { singularize, toTitleCase } from "../../base/utils/string.utils";
import { createUTCMoment, HHmm } from "../../base/utils/time.utils";
import { setUrlParam } from "../../base/utils/urlParams.utils";
import CounsellingTypeRenderer from "../../components/CounsellingTypeRenderer/CounsellingTypeRenderer";
import ReactionIcon from "../../components/ReactionIcon/ReactionIcon";
import UserFullNameRenderer from "../../components/UserFullNameRenderer/UserFullNameRenderer";
import UsernameRenderer from "../../components/UsernameRenderer/UsernameRenderer";
import { AlertTypeName } from "../../constants/alertType.enum";
import { ApiModelName } from "../../constants/ApiModels.enum";
import { ModelName } from "../../constants/modelNames.enum";
import { ReactionType } from "../../constants/ReactionType.enum";
import { Invoice } from "../../models/makeInvoice.model";
import { User } from "../../models/makeUser.model";
import { getCounsellingApplication } from "../../requests/getCounsellingApplication.request";
import { getSupportGroup } from "../../requests/getSupportGroup.request";
import { getUser } from "../../requests/user.requests";
import { getApplicationTypeDescriptor } from "../../utils/counsellingApplication.utils";
import { getModelNameFromApiModelName } from "../../utils/models.utils";
import { AlertsController } from "../alerts.controller";
import { NavigatorController } from "../navigator.controller";
import { ToastConfig } from "../ui/ui.controller.types";

/** pick lighter colors, for use as background color of the toast UI */
const NotifColorMap = {
  neutral: '#E4F7FF',
  attention: '#FFDBBB',
  alert: '#FFD5CF',
  supportGroup: '#BDDFD9',
  success: '#DAEBDE',
}

export const makeToastConfigFromAlert = async (
  alert: Alert,
  NAVIGATOR: NavigatorController,
  ALERTS: AlertsController,
  withColor?: boolean,
) => {

  await when(() => Boolean(NAVIGATOR.ROOT?.children.UI));

  const { UI, API, AUTH } = NAVIGATOR.ROOT!.children;

  const toast = {
    name: [alert.type, alert.id].join('#'),
    timeCreated: alert.timeCreated,
    onBeforeDismiss: () => {
      ALERTS.markAsRead(alert);
    },
    dataCy: alert.type,
  } as ToastConfig;

  if (withColor) toast.color = NotifColorMap.neutral;

  switch (alert.type) {

    case AlertTypeName.Generic: {
      const { data: d } = alert;
      toast.heading = d.heading;
      toast.body = d.body;
      toast.actionUrl = d.actionUrl;
      break;
    }

    case AlertTypeName.Client__NewComment: {
      const { data: d } = alert;
      toast.heading = () => <>You received a new comment from <UsernameRenderer userId={d.fromUserId} showColorLabel />.</>;
      toast.action = () => setUrlParam('thoughtId', d.modelId, NAVIGATOR);
      toast.body = <><ClickOrTap capitalized /> here to view the thought.</>;
      break;
    }

    case AlertTypeName.Client__NewReactionReceived: {
      const { data: d } = alert;
      if (d.reactionType === ReactionType.Like) {
        toast.heading = () => <><UsernameRenderer userId={d.fromUserId} showColorLabel /> liked your thought</>;
      } else {
        toast.heading = () => <><UsernameRenderer userId={d.fromUserId} showColorLabel /> reacted to your thought: <ReactionIcon type={d.reactionType} /></>;
      }
      // toast.body = 'Tap here to view the thought.';
      toast.action = () => setUrlParam('thoughtId', d.modelId, NAVIGATOR);
      break;
    }

    case AlertTypeName.Client__ApplicationApproval: {
      const { data: d } = alert;
      try {
        await getCounsellingApplication(d.applicationId, API); // refresh application data
        toast.body = <><ClickOrTap capitalized /> here to view the application.</>;
      } catch (e) {
        reportError(e);
      }
      toast.heading = () => <>Your {getApplicationTypeDescriptor(d.applicationType)?.displayName ?? d.applicationType} application on <DateRenderer value={d.applicationDate} format="MMMM D, YYYY" /> has been approved!</>;
      toast.action = () => autoNavigateToCounsellingJourneyOverlay(NAVIGATOR, d.applicationId);
      toast.wait = resolveWhen(() => !UI.OVERLAY.hasOpenedOverlays && !UI.hasOpenedUICards);
      break;
    }

    case AlertTypeName.Client__ApplicationRejection: {
      const { data: d } = alert;
      try {
        await getCounsellingApplication(d.applicationId, API); // refresh application data
      } catch (e) {
        reportError(e);
      }
      toast.heading = () => <>Unfortunately, your {getApplicationTypeDescriptor(d.applicationType)?.displayName ?? d.applicationType} application on <DateRenderer value={d.applicationDate} format="MMMM D, YYYY" /> has been rejected.</>;
      toast.action = () => showApplicationRejectionConfirm(UI, alert as AlertClient__ApplicationRejection);
      toast.colorCodedState = ColorCodedState.attention;
      toast.color = NotifColorMap.attention;
      toast.wait = resolveWhen(() => !UI.OVERLAY.hasOpenedOverlays && !UI.hasOpenedUICards);
      break;
    }

    case AlertTypeName.Client__ApplicationJourneyCompletion: {
      const { data: d } = alert;
      try {
        await getCounsellingApplication(d.applicationId, API); // refresh application data
        toast.body = <><ClickOrTap capitalized /> here to view details.</>;
      } catch (e) {
        reportError(e);
      }
      toast.heading = () => <>{AUTH.currentUser?.username ? `Hello, ${AUTH.currentUser.username}!` : ''} You have completed your {getApplicationTypeDescriptor(d.applicationType).displayName} counselling.</>;
      toast.action = () => autoNavigateToCounsellingJourneyOverlay(NAVIGATOR, d.applicationId);
      toast.colorCodedState = ColorCodedState.success;
      toast.color = NotifColorMap.success;
      toast.wait = resolveWhen(() => !UI.OVERLAY.hasOpenedOverlays && !UI.hasOpenedUICards);
      break;
    }

    case AlertTypeName.Client__NewCounsellingSessionScheduled: {
      const { data: d } = alert;
      try {
        await getCounsellingApplication(d.applicationId, API); // refresh application data
        toast.body = <><ClickOrTap capitalized /> here to view details.</>;
      } catch (e) {
        reportError(e);
      }
      toast.heading = () => <>Your counsellor <UserFullNameRenderer userId={d.counsellorId} /> has scheduled a new <strong>{d.sessionCommunicationType} session</strong> for you on <DateRenderer value={d.sessionTimeScheduled} />.</>;
      toast.action = () => autoNavigateToCounsellingJourneyOverlay(NAVIGATOR, d.applicationId);
      toast.wait = resolveWhen(() => !UI.OVERLAY.hasOpenedOverlays && !UI.hasOpenedUICards);
      break;
    }

    case AlertTypeName.Client__CounsellingSessionRescheduled: {
      const { data: d } = alert;
      try {
        await getCounsellingApplication(d.applicationId, API); // refresh application data
        toast.body = <><ClickOrTap capitalized /> here to view details.</>;
      } catch (e) {
        reportError(e);
      }
      toast.heading = () => <>Your <CounsellingTypeRenderer value={d.applicationType} /> session with Counsellor <UserFullNameRenderer userId={d.counsellorId} /> has been rescheduled from <DateRenderer value={d.prevTimeScheduled} /> to <strong><DateRenderer value={d.newTimeScheduled} /></strong>.</>;
      toast.action = () => autoNavigateToCounsellingJourneyOverlay(NAVIGATOR, d.applicationId);
      toast.wait = resolveWhen(() => !UI.OVERLAY.hasOpenedOverlays && !UI.hasOpenedUICards);
      break;
    }

    case AlertTypeName.Staff__CounsellingSessionCancelledByClient: {
      const { data: d } = alert;
      try {
        await getCounsellingApplication(d.applicationId, API); // refresh application data
        toast.body = <><ClickOrTap capitalized /> here to view details.</>;
      } catch (e) {
        reportError(e);
      }
      toast.heading = () => <>Your client <UserFullNameRenderer userId={d.clientId} /> has cancelled the <strong>{d.sessionCommunicationType} session</strong> scheduled on <DateRenderer value={d.sessionTimeScheduled} />.</>;
      toast.action = () => setUrlParam('manageSessionId', d.sessionId, NAVIGATOR);
      break;
    }

    case AlertTypeName.Client__SupportGroupReminder: {
      const { data: d } = alert;
      try {
        await getSupportGroup(API, d.groupId); // refresh group data
      } catch (e) {
        reportError(e);
      }
      toast.heading = () => <>Your support group "{d.groupTitle}" is starting in {createUTCMoment(d.groupTimeScheduled).fromNow(undefined)}.</>;
      toast.body = <>The chat window will show up automatically when the group starts. <ClickOrTap capitalized /> here if you'd like to view the group details.</>;
      toast.action = () => setUrlParam('supportGroupId', d.groupId, NAVIGATOR);
      toast.color = NotifColorMap.supportGroup;
      break;
    }

    case AlertTypeName.Client__SupportGroupStarted: {
      const { data: d } = alert;
      try {
        await getSupportGroup(API, d.groupId); // refresh group data
      } catch (e) {
        reportError(e);
      }
      const isTodaysGroup = createUTCMoment(d.timeScheduled).isSame(undefined, 'day');
      toast.heading = () => <>Your support group "{d.groupTitle}" scheduled {isTodaysGroup ? 'on' : 'for'} <DateRenderer value={d.timeScheduled} format={isTodaysGroup ? HHmm : 'LL'} /> has started.</>;
      toast.body = <><ClickOrTap capitalized /> here if you'd like to view the group details.</>;
      toast.action = () => setUrlParam('supportGroupId', d.groupId, NAVIGATOR);
      toast.color = NotifColorMap.supportGroup;
      break;
    }

    case AlertTypeName.Client__SupportGroupReservationRevoked: {
      const { data: d } = alert;
      try {
        await getSupportGroup(API, d.groupId); // refresh group data
      } catch (e) {
        reportError(e);
      }
      toast.heading = () => <>We are sorry to inform you that your reservation for support group "{d.groupTitle}" on <DateRenderer value={d.groupTimeScheduled} format="D MMMM" /> has been revoked by a turn2me facilitator{d.revokeReason ? ' for the following reasons:' : '.'}</>;
      if (d.revokeReason) toast.body = <ShadedBlock children={d.revokeReason} />;
      toast.color = NotifColorMap.attention;
      break;
    }

    case AlertTypeName.Staff__NewApplicationReceived: {
      const { data: d } = alert;
      toast.heading = () => <>You have received a new {getApplicationTypeDescriptor(d.applicationType).displayName} application from client <UsernameRenderer userId={d.applicantId} />.</>;
      toast.body = <><ClickOrTap capitalized /> here to view details.</>;
      toast.action = () => setUrlParam('manageApplicationId', d.applicationId, NAVIGATOR);
      break;
    }

    case AlertTypeName.Staff__NewModerationFlagRaised: {
      const { data: d } = alert;
      toast.heading = () => <>A new flag has been submitted for {singularize(getModelNameFromApiModelName(d.flaggableType) ?? d.flaggableType)} ID {d.flaggableId}.</>;
      toast.body = d.flaggableType === ApiModelName.THOUGHT ? <><ClickOrTap capitalized /> here to view the thought.</> : d.flaggableType === ApiModelName.COMMENT ? <><ClickOrTap capitalized /> here to view the comment under the thought it commented to.</> : undefined;
      if (d.flaggableType === ApiModelName.THOUGHT || d.flaggableType === ApiModelName.COMMENT) {
        toast.action = () => setUrlParam('manageThoughtId', d.thoughtId, NAVIGATOR);
      }
      toast.colorCodedState = ColorCodedState.alert;
      toast.color = NotifColorMap.alert;
      break;
    }

    case AlertTypeName.Staff__NewSupportGroupReservation: {
      const { data: d } = alert;
      let client: User;
      try {
        await getSupportGroup(API, d.groupId, 'staff', { include: DefaultSupportGroupIncludesForStaff }); // refresh group data
        client = (await getUser(d.clientId, API))!;
        toast.body = <><ClickOrTap capitalized /> here to view details.</>;
      } catch (e) {
        reportError(e);
      }
      const isTodaysGroup = createUTCMoment(d.groupTimeScheduled).isSame(undefined, 'day');
      toast.heading = () => <><UsernameRenderer user={client} userId={d.clientId} /> has joined your support group "{d.groupTitle}" ({d.groupReservationCount}/{d.groupMaxParticipants}) scheduled {isTodaysGroup ? 'on' : 'for'} <DateRenderer value={d.groupTimeScheduled} format={isTodaysGroup ? HHmm : 'LL'} />.</>;
      toast.action = () => setUrlParam('manageSupportGroupId', d.groupId, NAVIGATOR);
      toast.color = NotifColorMap.supportGroup;
      break;
    }

    case AlertTypeName.Staff__InvoicePaid: {
      const { data: d } = alert;
      try {
        const url = InvoiceEndpoints.own.get(d.invoiceId, { include: defaultInvoiceEndpointIncludes });
        await API.get<Invoice>(url, ModelName.invoices);
        toast.heading = <>Your invoice for <DateRenderer value={d.invoiceBillingPeriodStartDate} format="MMMM, YYYY" /> has been approved.</>;
        toast.body = <><ClickOrTap capitalized /> here to view details.</>;
        toast.action = () => NAVIGATOR.setUrlParam('invoiceId', d.invoiceId)
      } catch (e) {
        reportError(e);
      }
      break;
    }

    case AlertTypeName.Staff__InvoiceRejected: {
      const { data: d } = alert;
      try {
        const url = InvoiceEndpoints.own.get(d.invoiceId, { include: defaultInvoiceEndpointIncludes });
        await API.get<Invoice>(url, ModelName.invoices);
        toast.heading = <>Unfortunately, your invoice for <DateRenderer value={d.invoiceBillingPeriodStartDate} format="MMMM, YYYY" /> has been rejected.</>;
        toast.body = <><ClickOrTap capitalized /> here to view details.</>;
        toast.action = () => NAVIGATOR.setUrlParam('invoiceId', d.invoiceId)
      } catch (e) {
        reportError(e);
      }
      break;
    }

    case AlertTypeName.Staff__InvoiceItemRemoved: {
      const { data: d } = alert;
      try {
        const url = InvoiceEndpoints.own.get(d.invoiceId, { include: defaultInvoiceEndpointIncludes });
        await API.get<Invoice>(url, ModelName.invoices);
        toast.heading = <>An item in your invoice for <DateRenderer value={d.invoiceBillingPeriodStartDate} format="MMMM, YYYY" /> has been removed by administrator.</>;
        toast.body = <>The item is for {toTitleCase(getModelNameFromApiModelName(d.removedInvoiceItemModelType))} #{d.removedInvoiceItemModelId}. <ClickOrTap capitalized /> here to view your invoice.</>;
        toast.action = () => NAVIGATOR.setUrlParam('invoiceId', d.invoiceId)
      } catch (e) {
        reportError(e);
      }
      break;
    }

    default:
    case AlertTypeName.Empty: {
      toast.heading = 'Unknown Type of Notification Received';
      toast.body = 'If you are seeing this, it is likely that your version of the turn2me web app is slightly out of date; please try refreshing the page.';
      break;
    }

  }

  return toast;

}