import { flow, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { isInCypressTestMode, IS_PROD } from '../../../env';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useControllers } from '../../hooks/useRootController.hook';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import tick from '../../utils/waiters.utils';
import BaseSpacer from '../BaseSpacer/BaseSpacer';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import './SplashScreen.scss';

type SplashScreenProps = {
	identifier?: string,
	className?: string,
	contentHasLoaded?: boolean;
	error?: Error;
}

declare global {
	interface Window {
		SplashScreenLoadMap: Map<string, boolean>;
	}
}

const SplashScreen: React.FC<SplashScreenProps> = props => {

	window.SplashScreenLoadMap = window.SplashScreenLoadMap || new Map<string, boolean>();

	const { UI } = useControllers();

	const p = useProps(props);

	const s = useStore(() => ({
		isEntering: true,
		isExiting: false,
		exited: false,
	}));

	useOnMount(() => {
		document.documentElement.classList.add('splashscreenVisible')
		return reaction(
			() => p.contentHasLoaded,
			flow(function * () {
				yield tick(IS_PROD ? 1000 : 0);
				s.isExiting = true;
				yield tick(UI.deviceIsHighPerformance ? 1000 : 200);
				document.documentElement.classList.remove('splashscreenVisible')
				s.isExiting = false;
				s.exited = true;
			})
		)
	});

	return <Observer children={() => (
		(s.exited || isInCypressTestMode) ? null : <div className={
			joinClassName(
				'SplashScreen',
				s.isEntering && 'isEntering',
				s.isExiting && 'isExiting',
			)
		}>
			<div className={
				joinClassName(
					"SplashScreenInner",
					p.className,
				)
			}>
				{props.children ? <>
					{props.children}
					<BaseSpacer size=".5em" />
				</> : null}
				<LoadingIndicator />
			</div>
		</div>
	)} />

}

export default SplashScreen;