
import { flow, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { openOverlayCounsellingSessionManager } from '../../../../actions/openOverlayCounsellingSessionManager';
import { ColorCodedState, Nillable } from '../../../../base/@types';
import BaseButton from '../../../../base/components/BaseButton/BaseButton';
import BaseButtonGroup from '../../../../base/components/BaseButtonGroup/BaseButtonGroup';
import BaseHeading from '../../../../base/components/BaseHeading/BaseHeading';
import BaseSeparator from '../../../../base/components/BaseSeparator/BaseSeparator';
import BaseSpacer from '../../../../base/components/BaseSpacer/BaseSpacer';
import BaseTable from '../../../../base/components/BaseTable/BaseTable';
import BooleanRenderer from '../../../../base/components/BooleanRenderer/BooleanRenderer';
import CurrencyRenderer from '../../../../base/components/CurrencyRenderer/CurrencyRenderer';
import DateRenderer from '../../../../base/components/DateRenderer/DateRenderer';
import DurationRenderer from '../../../../base/components/DurationRenderer/DurationRenderer';
import InfoBanner from '../../../../base/components/InfoBanner/InfoBanner';
import InfoDisplayItem from '../../../../base/components/InfoDisplayList/InfoDisplayItem';
import InfoDisplayList from '../../../../base/components/InfoDisplayList/InfoDisplayList';
import UIBlock from '../../../../base/components/UIBlock/UIBlock';
import { CounsellingSessionEndpoints, DefaultCounsellingSessionIncludesForStaff } from '../../../../base/endpoints/counsellingSession.endpoints';
import { InvoiceItemEndpoints } from '../../../../base/endpoints/invoiceItems.endpoints';
import { InvoiceEndpoints } from '../../../../base/endpoints/invoices.endpoints';
import { DefaultSupportGroupIncludesForStaff, SupportGroupEndpoints } from '../../../../base/endpoints/supportGroup.endpoints';
import { useOnMount } from '../../../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import { DateRangeFilter } from '../../../../base/utils/api.utils';
import { removeFromArrayById, replaceContents } from '../../../../base/utils/array.utils';
import { getColorHexByName } from '../../../../base/utils/colors.utils';
import { reportError } from '../../../../base/utils/errors.utils';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import { autoPluralize } from '../../../../base/utils/string.utils';
import { getDurationFromUTCTimeStamps, getNowTimestampUtc, millisecondsToMinutes } from '../../../../base/utils/time.utils';
import UserFullNameRenderer from '../../../../components/UserFullNameRenderer/UserFullNameRenderer';
import UsernameRenderer from '../../../../components/UsernameRenderer/UsernameRenderer';
import { ApiModelName } from '../../../../constants/ApiModels.enum';
import { ModelName } from '../../../../constants/modelNames.enum';
import { supportGroupTableColumnConfigs } from '../../../../constants/supportGroups.constants';
import { APIGetManyResponse } from '../../../../controllers/api.controller';
import { CounsellingSession, CounsellingSessionSnapshot, isCounsellingSessionModel } from '../../../../models/makeCounsellingSession.model';
import { Invoice, InvoiceSnapshot, InvoiceStatus } from '../../../../models/makeInvoice.model';
import { InvoiceItem } from '../../../../models/makeInvoiceItem.models';
import { isSupportGroupModel, SupportGroup, SupportGroupSnapshot } from '../../../../models/makeSupportGroup.model';
import { getInvoiceItemDisplayTitle } from '../../../../utils/invoices.utils';
import { manageSupportGroup } from '../../../../utils/supportGroup.helpers';
import { counsellingSessionTableColumnConfigsForAdmin } from '../../_configs/counsellingSessionTableColumnConfigsForAdmin.config';
import './InvoiceManager.scss';

type InvoiceManagerProps = {
  invoice: Invoice,
}

const InvoiceManager: React.FC<InvoiceManagerProps> = props => {

  const { UI, API, NAVIGATOR, AUTH } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    get currentYear() {
      return moment().year()
    },
    get currentMonth() {
      return moment().month()
    },
    get isCurrentPeriod() {
      return s.currentYear === s.year && s.currentMonth === s.month;
    },
    get year() {
      return p.invoice.year;
    },
    get month() {
      return p.invoice.month;
    },
    sessions: [] as CounsellingSession[],
    get billableSessions() {
      return s.sessions.filter(ses => ses.isBillable);
    },
    groups: [] as SupportGroup[],
    get billableGroups() {
      return s.groups.filter(g => g.invoiceItems.map(i => i.invoice).some(inv => inv?.payeeId === p.invoice.payeeId));
    },
    get filterDateRange(): DateRangeFilter<CounsellingSessionSnapshot | SupportGroupSnapshot> {
      return {
        key: 'timeStarted',
        startDate: p.invoice.billingPeriodStartDate,
        endDate: p.invoice.billingPeriodEndDate,
      }
    },
    get isOwnInvoice() {
      return AUTH.currentUser && p.invoice.payeeId === AUTH.currentUser.id;
    },
		get canViewPIISessions() {
			return AUTH.canViewPIIAsCounsellor;
		},
    get canViewPIISupportGroup() {
      return AUTH.canViewPIIAsFacilitator;
    },
  }));

  const confirmForPayment = flow(function * () {
    const confirm = yield UI.DIALOG.present({
      heading: "Confirming your invoice",
      body: <div>
        <p>You are confirming your invoice for the billing period of <strong><DateRenderer value={p.invoice.billingPeriodStartDate} format="MMMM YYYY" /></strong>.</p>
        <p>By continuing, you confirm that all the billable items listed on this page are correct, and you have removed all items that are not billable, such as sessions that had to be terminated early due to technical or other issues.</p>
        <p><strong>You cannot revert this confirmation. If you complete more sessions after confirmation, they will be added to the invoice for the next billing period.</strong></p>
      </div>,
    })
    if (!confirm) return;
    const url = InvoiceEndpoints.own.update(p.invoice.id);
    const payload: Partial<InvoiceSnapshot> = {
      timeConfirmedByPayee: getNowTimestampUtc(),
    }
    try {
      const savedInvoice: Nillable<Invoice> = yield API.patch(url, ModelName.invoices, payload);
      if (!savedInvoice) {
        UI.DIALOG.error({
          heading: "There seem to have been an issue confirming your invoice.",
          body: "Please refresh the page and try again shortly."
        })
        return;
      }
      UI.DIALOG.success({
        heading: <>You have confirmed your invoice for the billing period of <DateRenderer value={p.invoice.billingPeriodStartDate} format="MMMM YYYY"/>.</>
      });
    } catch(e) {
      reportError(e);
      UI.DIALOG.error({
        heading: "Failed to confirm the invoice. Please try again later.",
        error: e,
      })
    }
  })

  const getSessionsInBillingPeriod = flow(function * () {
    const url = CounsellingSessionEndpoints.staff.index({
      filter: {
        dateRange: s.filterDateRange,
        counsellorId: p.invoice.payeeId,
      },
      include: DefaultCounsellingSessionIncludesForStaff,
      perPage: Infinity,
    });
    const { entries } = (yield API.getMany(url, ModelName.counsellingSessions)) as APIGetManyResponse<CounsellingSession>;
    replaceContents(s.sessions, entries);
  })

  const getGroupsInBillingPeriod = flow(function * () {
    const url = SupportGroupEndpoints.staff.index({
      filter: {
        dateRange: s.filterDateRange,
        userIdParticipated: p.invoice.payeeId,
        whereNotNull: 'timeEnded',
      },
      include: DefaultSupportGroupIncludesForStaff,
      perPage: Infinity,
    });
    const { entries } = (yield API.getMany(url, ModelName.supportGroups)) as APIGetManyResponse<SupportGroup>;
    replaceContents(s.groups, entries.filter(group => !!group.timeEnded));
  })

  useOnMount(() => {
    return reaction(
      () => p.invoice.billingPeriodStartDate,
      () => {
        if (!p.invoice?.billingPeriodStartDate) return;
        getSessionsInBillingPeriod();
        getGroupsInBillingPeriod();
      },
      { fireImmediately: true }
    )
  })

  const viewModel = (m?: SupportGroup | CounsellingSession) => {
    if (!m) return;
    if (isSupportGroupModel(m)) NAVIGATOR.setUrlParam('manageSupportGroupId', m.id);
    if (isCounsellingSessionModel(m)) NAVIGATOR.setUrlParam('manageSessionId', m.id);
  }

  const removeInvoiceItem = flow(function * (item: InvoiceItem) {
    if (!s.isOwnInvoice && !AUTH.can.manage_.staffInvoicing) return;
    const confirm = yield UI.DIALOG.present({
      heading: 'Are you sure you want to remove this item from the invoice?',
      body: !s.isOwnInvoice && <p><UsernameRenderer user={p.invoice.payee} /> will be notified of this removal.</p>,
    })
    if (!confirm) return;
    const url = InvoiceItemEndpoints[s.isOwnInvoice ? 'own' : 'staff'].delete(item.id);
    try {
      yield API.delete<InvoiceItem>(url, ModelName.invoiceItems, item);
      item.timeDeleted = getNowTimestampUtc();
      removeFromArrayById(p.invoice.items, item);
      UI.TOAST.success('The item has been removed from the invoice.');
    } catch(e) {
      reportError(e);
      UI.DIALOG.error({
        heading: 'Failed to remove the item. The item might have been removed already.',
        error: e,
      })
    }
  })

  const markAsPaid = flow(function * () {
    if (!p.invoice.timeConfirmedByPayee) {
      UI.DIALOG.attention({
        heading: 'You can only process an invoice after the payee has confirmed the details.',
      })
      return;
    }
    const confirm = yield UI.DIALOG.present({
      heading: "Are you sure you want to mark this invoice as paid?",
      body: "A notification will be sent to the payee.",
    })
    if (!confirm) return;
    const url = InvoiceEndpoints.staff.markAsPaid(p.invoice.id);
    try {
      yield API.post<Invoice>(url, ModelName.invoices);
      UI.DIALOG.success({
        heading: "Successfully marked this invoice as paid.",
      })
    } catch(e) {
      reportError(e);
      UI.DIALOG.error({
        heading: "Unable to mark this invoice as paid.",
        error: e,
      })
    }
  })

  const rejectInvoice = flow(function * () {
    if (!p.invoice.timeConfirmedByPayee) {
      UI.DIALOG.attention({
        heading: 'You can only process an invoice after the payee has confirmed the details.',
      })
      return;
    }
    const confirm = yield UI.DIALOG.present({
      heading: "Are you sure you want to mark this invoice as rejected?",
      body: "A notification will be sent to the payee about this status update.",
    })
    if (!confirm) return;
    const url = InvoiceEndpoints.staff.markAsRejected(p.invoice.id);
    try {
      yield API.post<Invoice>(url, ModelName.invoices);
      UI.DIALOG.success({
        heading: "Successfully rejected this invoice.",
      })
    } catch (e) {
      reportError(e);
      UI.DIALOG.error({
        heading: "Unable to reject this invoice.",
        error: e,
      })
    }
  })

  const print = () => {
    const url = `/admin/print/invoices/${p.invoice.id}?redirectedFrom=${encodeURIComponent(window.location.href.replace(new RegExp('^' + window.location.origin), ''))}`;
    UI.OVERLAY.dismissAll();
    NAVIGATOR.navigateTo(url);
  }

  return <Observer children={() => (
    <div className="InvoiceManager" data-invoice-id={p.invoice.id} data-status={p.invoice.status}>
      <div className="InvoiceManagerHeader">
        <InfoDisplayList>
          <InfoDisplayItem label="Payee">
            <UserFullNameRenderer user={p.invoice.payee} showColorLabel={false}/>
          </InfoDisplayItem>
          <InfoDisplayItem label={s.isCurrentPeriod ? 'Current Period' : 'Billing Period'}>
            {moment(p.invoice.billingPeriodStartDate).format('MMMM YYYY')}
          </InfoDisplayItem>
          <InfoDisplayItem label='Total'>
            <CurrencyRenderer value={p.invoice.total} />
          </InfoDisplayItem>
          <InfoDisplayItem label="Confirmed for payment">
            <BooleanRenderer value={!!p.invoice.timeConfirmedByPayee} />
          </InfoDisplayItem>
          <InfoDisplayItem label="Status">
            <span style={{color: p.invoice.status === InvoiceStatus.Rejected ? getColorHexByName('red') : ''}}>{p.invoice.status}</span>
          </InfoDisplayItem>
          {p.invoice.processedByUser && AUTH.can.manage_.staffInvoicing && <InfoDisplayItem label="Processed by">
            <UserFullNameRenderer user={p.invoice.processedByUser} showColorLabel={false}/> (<UsernameRenderer user={p.invoice.processedByUser} showColorLabel={false}/>)
          </InfoDisplayItem>}
        </InfoDisplayList>
        { UI.onlyPhones && <BaseSpacer size=".5em" /> }
        <div className="InvoiceManagerHeaderControls">
          { s.isOwnInvoice && !p.invoice.timeConfirmedByPayee && <BaseButton onClick={confirmForPayment} label="Confirm invoice for payment" fullWidth={UI.onlyPhones} /> }
          { p.invoice.timeConfirmedByPayee && AUTH.can.manage_.staffInvoicing && !p.invoice.timePaid && !p.invoice.timeRejected && <BaseButtonGroup>
            <BaseButton onClick={markAsPaid} key="markAsPaid" colorCodedState={ColorCodedState.positive} label="Mark as paid" disabled={p.invoice.items.length === 0}/>
            <BaseButton onClick={rejectInvoice} key="rejectInvoice" colorCodedState={ColorCodedState.alert} label="Reject invoice" />
          </BaseButtonGroup>}
          { p.invoice.status === InvoiceStatus.Paid && <BaseButton icon="print" onClick={print}>Print Invoice</BaseButton>}
        </div>
      </div>
      <BaseSpacer size="1em"/>
      <UIBlock>
        <BaseHeading>Invoice Details</BaseHeading>
        {
          p.invoice.items.length === 0 && p.invoice.timeConfirmedByPayee && !p.invoice.timeRejected && <InfoBanner icon="warning" colorCodedState={ColorCodedState.alert}>
            <p>This invoice has no valid items and cannot be marked as paid. Since it has been confirmed by the payee, items were likely removed by invoice managers after the confirmation. You can simply ignore this empty invoice or reject it.</p>
          </InfoBanner>
        }
        { p.invoice.items && <BaseTable<InvoiceItem>
          entries={p.invoice.items}
          columnConfigs={[
            {
              keyName: 'modelType',
              label: 'Session',
              BodyCell: d => <div className="InvoiceItemTableEntryTitle" onClick={() => viewModel(d.model)}>
                <p><strong>{getInvoiceItemDisplayTitle(d)}</strong></p>
                <p>{isSupportGroupModel(d.model) ? d.model.title : isCounsellingSessionModel(d.model) ? <span>{autoPluralize(d.model.clients, 'Client', 'Clients', 'Clients', true)}: { d.model.clients.map(c => <UsernameRenderer user={c} key={c.id}/>) }</span> : ''}</p>
              </div>,
            },
            {
              keyName: 'timeCreated',
              label: "Creation Time (Local)",
              type: "timestampFull"
            },
            {
              label: 'Clients Attended',
              BodyCell: d => {
                if (d.modelType === ApiModelName.COUNSELLING_SESSION) {
                  return <BooleanRenderer value={(d.model as CounsellingSession)?.hasAnyClientAttended} />;
                }
              }
            },
            {
              label: "Session Duration",
              headCellStyleFactory: () => ({ textAlign: 'right' }),
              bodyCellStyleFactory: d => ({ textAlign: 'right' }),
              BodyCell: d => {
                const duration = getDurationFromUTCTimeStamps(d.model?.timeStarted, d.model?.timeEnded);
                const short = millisecondsToMinutes(duration) < 15;
                return d.model ? <div style={{ color: short ? 'red' : 'inherit' }} data-duration={duration}>
                  <p><DurationRenderer startTime={d.model.timeStarted} endTime={d.model.timeEnded} /></p>
                  { short && <p><em>Under 15 minutes</em></p> }
                </div> : ''
              },
            },
            {
              keyName: "amountPayable",
              label: "Amount",
              type: 'currency',
              bodyCellStyleFactory: d => ({ fontWeight: 700, width: '7em' }),
              BodyCell: d => {
                return <CurrencyRenderer value={d.amountPayable} />
              }
            },
          ]}
          itemActions={[
            ...s.canViewPIISessions ? [{ label: 'View', action: (d: InvoiceItem) => viewModel(d.model) }] : [],
            ...!p.invoice.processedByUser ? [
              { label: 'remove', action: removeInvoiceItem, colorCodedState: ColorCodedState.alert }
            ] : []
          ]}
          appearanceOptions={{
            showRowIndex: true
          }}
        /> }
      </UIBlock>
      <BaseSeparator />
      <UIBlock>
        <BaseHeading>Counselling Sessions</BaseHeading>
        <p>{autoPluralize(s.sessions, 'Counselling Session')}, {s.billableSessions.length} billable (for <UsernameRenderer user={p.invoice.payee} userId={p.invoice.payeeId} />)</p>
        <BaseSpacer size=".5em" />
        {s.sessions.length === 0 && <InfoBanner icon="info">No counselling sessions conducted in this billing period.</InfoBanner> }
        {s.sessions.length > 0 && <BaseTable
          entries={s.sessions}
          columnConfigs={counsellingSessionTableColumnConfigsForAdmin}
          itemActions={s.canViewPIISessions
            ? [
              { label: 'View', action: d => openOverlayCounsellingSessionManager(UI, { sessionId: d.id }) }
            ]
            : []
          }
        />}
      </UIBlock>
      <BaseSeparator />
      <UIBlock>
        <BaseHeading>Support Groups</BaseHeading>
        <p>{autoPluralize(s.groups, 'Support Groups')}, {s.billableGroups.length} billable (for <UsernameRenderer user={p.invoice.payee} userId={p.invoice.payeeId} />)</p>
        <BaseSpacer size=".5em" />
        {s.groups.length === 0 && <InfoBanner icon="info">No support groups faciliated in this billing period.</InfoBanner>}
        {s.groups.length > 0 && <BaseTable
          entries={s.groups}
          columnConfigs={supportGroupTableColumnConfigs}
          itemActions={s.canViewPIISupportGroup
            ? [
              { label: 'View', action: (d: SupportGroup) => manageSupportGroup(UI, d.id) }
            ]
            : []
          }
        />}
      </UIBlock>
    </div>
  )} />

}

export default InvoiceManager;