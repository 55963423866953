import { ContactFormEndpoints } from "../base/endpoints/contactForm.endpoints";
import { getSnapshot } from "../base/utils/snapshot.utils";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { ContactForm, ContactFormSnapshot } from "../models/makeContactForm.model";

export function submitContactForm(API: APIController, contactForm: ContactForm | ContactFormSnapshot) {
  return new Promise<ContactForm>(async (resolve, reject) => {
    try {
      const url = ContactFormEndpoints.own.create();
      const {id, ...payload} = getSnapshot(contactForm);
      const savedContactForm = await API.post<ContactForm>(url, ModelName.contactForms, payload);
      if (!savedContactForm) throw Error('Failed to submit contact form');
      resolve(savedContactForm);
    } catch(e) {
      reject(e);
    }
  })
}