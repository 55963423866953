import { Observer } from 'mobx-react-lite';
import React from 'react';
import ColorTag from '../../base/components/ColorTag/ColorTag';
import { toTitleCase } from '../../base/utils/string.utils';
import { ApplicationTypeOption, CounsellingType } from '../../constants/counsellingTypes.descriptors';
import { getApplicationTypeDescriptor } from '../../utils/counsellingApplication.utils';
// import './ColorTagCounsellingType.scss';

interface ColorTagCounsellingTypeProps {
  type: ApplicationTypeOption | CounsellingType
}

const ColorTagCounsellingType: React.FC<ColorTagCounsellingTypeProps> = p => {
  const descriptor = getApplicationTypeDescriptor(p.type);
  return <Observer children={() => (
    <ColorTag className="ColorTagCounsellingType" color={descriptor?.colorName}>
      {descriptor?.displayName ?? toTitleCase(p.type)}
    </ColorTag>
  )} />
}

export default ColorTagCounsellingType;