import { Observer } from 'mobx-react-lite';
import React from 'react';
import AppPage from '../../base/components/AppPage/AppPage';
import AppPageContent from '../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../base/components/AppPageHeader/AppPageHeader';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseButtonGroup from '../../base/components/BaseButtonGroup/BaseButtonGroup';
import BaseHeading from '../../base/components/BaseHeading/BaseHeading';
import BaseSpacer from '../../base/components/BaseSpacer/BaseSpacer';
import BodyCopyRenderer from '../../base/components/BodyCopyRenderer/BodyCopyRenderer';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import OverlayCloseButton from '../../base/components/OverlayCloseButton/OverlayCloseButton';
import UIBlock from '../../base/components/UIBlock/UIBlock';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps } from '../../base/utils/mobx.utils';
import { AssignmentGoalSheet } from '../../models/makeAssignment.model';
import './OverlayGoalSheetViewer.scss';

type OverlayGoalSheetViewerProps = {
  assignment: AssignmentGoalSheet
}

const OverlayGoalSheetViewer: React.FC<OverlayGoalSheetViewerProps> = props => {
  const { UI } = useControllers();
  const p = useProps(props);
  return <Observer children={() => (
    <AppPage className="OverlayGoalSheetViewer">
      <AppPageHeader
        title="Goal Sheet"
        afterTitle={<>Filled in on <DateRenderer value={p.assignment.timeCompleted} /></>}
        endSlot={<OverlayCloseButton />}
      />
      <AppPageContent>
        <UIBlock padded>
          <BaseHeading>Goal One</BaseHeading>
          <BodyCopyRenderer source={p.assignment.target?.goalOne}/>
          <BaseHeading>Goal Two</BaseHeading>
          <BodyCopyRenderer source={p.assignment.target?.goalTwo}/>
          <BaseHeading>Goal Three</BaseHeading>
          <BodyCopyRenderer source={p.assignment.target?.goalThree}/>
          <BaseSpacer />
          <BaseButtonGroup>
            <BaseButton onClick={() => UI.OVERLAY.dismiss()} color="green">Done</BaseButton>
          </BaseButtonGroup>
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
}

export default OverlayGoalSheetViewer;