import { CounsellingSessionEndpointParams, CounsellingSessionEndpoints, DefaultCounsellingSessionIncludesForStaff } from "../base/endpoints/counsellingSession.endpoints";
import { useControllers } from "../base/hooks/useRootController.hook";
import { ModelName } from "../constants/modelNames.enum";
import { CounsellingSession } from "../models/makeCounsellingSession.model";
import { useAutoGet, UseAutoGetFactoryOptions } from "../utils/useAutoGet.utils";

export function useGetCounsellingSession<ObservableType = object>(
  options: UseAutoGetFactoryOptions<CounsellingSession, ObservableType>,
  id?: string | null,
  params?: CounsellingSessionEndpointParams,
) {
  const { AUTH } = useControllers();
  const canUseAdminEndpoint = AUTH.can.manage_.counsellingSessions;
  const _params = params || {
    include: DefaultCounsellingSessionIncludesForStaff,
  }
  useAutoGet<CounsellingSession, ObservableType>({
    ...options,
    url: id ? CounsellingSessionEndpoints[canUseAdminEndpoint ? 'staff' : 'own'].get(id, _params) : undefined,
    modelName: ModelName.counsellingSessions,
  });
}