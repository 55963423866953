import { ColorName } from "../../../../../base/constants/color.enum";
import { always } from "../../../../../base/utils/ramdaEquivalents.utils";
import { makeObservableRouteDef } from "../../../../../base/utils/routes.utils";
import AccountPageDeleteAccount from "./AccountPageDeleteAccount.page";

export const AccountPageDeleteAccountRouteDef = makeObservableRouteDef({
  identifier: 'account-delete-account',
  urlFactory: always('delete-account'),
  composeTitle: always('Delete Your Account'),
  component: AccountPageDeleteAccount,
  icon: 'person-delete',
  primaryColorName: ColorName.Red
})