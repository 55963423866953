import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseButton from '../../../../../base/components/BaseButton/BaseButton';
import BaseIcon from '../../../../../base/components/BaseIcon/BaseIcon';
import LoadingIndicator from '../../../../../base/components/LoadingIndicator/LoadingIndicator';
import { useControllers } from '../../../../../base/hooks/useRootController.hook';
import { useStore } from '../../../../../base/utils/mobx.utils';
import { take } from '../../../../../base/utils/ramdaEquivalents.utils';
import SupportGroupSummaryCard from '../../../../../components/SupportGroupSummaryCard/SupportGroupSummaryCard';
import ClientSupportGroupFinder from '../../../_components/ClientSupportGroupFinder/ClientSupportGroupFinder';
import './ExploreGroupRecommendations.scss';

interface ExploreGroupRecommendationsProps {}

const ExploreGroupRecommendations: React.FC<ExploreGroupRecommendationsProps> = props => {

  const { SUPPORT_GROUPS, UI } = useControllers();

  const s = useStore(() => ({
    // get ownUpcoming() {
    //   return SUPPORT_GROUPS.ownUpcomingSorted;
    // },
    get upcomingGroupsSorted() {
      return take(5, SUPPORT_GROUPS.futureUpcomingOrLiveGroupsSorted);
    },
    get recommendations() {
      return s.upcomingGroupsSorted;
    },
    get empty() {
      return SUPPORT_GROUPS.initialDataLoaded && s.recommendations.length === 0;
    },
    get isLoading() {
      if (SUPPORT_GROUPS.initialDataForRecommendationRetrievalError !== null) return false;
      return Boolean(!SUPPORT_GROUPS.initialDataForRecommendationRetrieved);
    },
  }));

  const findNewGroups = () => {
    UI.OVERLAY.present({
      component: <ClientSupportGroupFinder showCloseButton/>,
    })
  }

  return <Observer children={() => (
    <div className="ExploreGroupRecommendations">
      {
        s.isLoading && <div className="ExploreGroupRecommendationsEmptyNotice --loading">
          <LoadingIndicator />
          <p>Loading recommendations...</p>
        </div>
      }
      {
        s.empty && !s.isLoading && <div className="ExploreGroupRecommendationsEmptyNotice">
          <BaseIcon name="info" />
          <p>No recommendations yet.</p>
        </div>
      }
      {
        s.recommendations.map(g => <SupportGroupSummaryCard supportGroup={g} key={g.id} />)
      }
      <BaseButton
        className="ExploreGroupRecommendationsFindMoreButton"
        icon="search"
        fullWidth
        size="lg"
        onClick={findNewGroups}
        backgroundImage="radial-gradient(100.09% 121.05% at 97.37% 121.05%, rgba(139, 209, 128, 0.7) 0%, #10A78C 100%)"
        label="Find More Support Groups"
        dataCy="FindNewSupportGroupButton"
      />
    </div>
  )} />
}

export default ExploreGroupRecommendations;