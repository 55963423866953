import { Observer } from 'mobx-react-lite';
import React from 'react';
import AppPage from '../../base/components/AppPage/AppPage';
import AppPageHeader from '../../base/components/AppPageHeader/AppPageHeader';
import OverlayCloseButton from '../../base/components/OverlayCloseButton/OverlayCloseButton';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps } from '../../base/utils/mobx.utils';
import { UIController } from '../../controllers/ui.controller';
import { ContactFormSnapshot } from '../../models/makeContactForm.model';
import ContactFormEditor from '../ContactFormEditor/ContactFormEditor';
import './OverlayContactFormEditor.scss';

export const ID_OverlayContactFormEditor = 'OverlayContactFormEditor';

export const openOverlayContactFormEditor = (UI: UIController, formSnapshot?: Partial<ContactFormSnapshot>, title?: string, closeOnSubmit?: boolean) => {
  UI.OVERLAY.present({
    name: ID_OverlayContactFormEditor,
    component: <OverlayContactFormEditor
      formSnapshot={formSnapshot}
      title={title}
      closeOnSubmit={closeOnSubmit}
    />,
    appearance: 'card',
  })
}

type OverlayContactFormEditorProps = {
  formSnapshot?: Partial<ContactFormSnapshot>,
  title?: string,
  closeOnSubmit?: boolean,
}

const OverlayContactFormEditor: React.FC<OverlayContactFormEditorProps> = props => {
  const { AUTH } = useControllers();
  const p = useProps(props);
  return <Observer children={() => (
    <AppPage
      className="OverlayContactFormEditor"
      accommodateTitleBarSpace
    >
      <AppPageHeader
        title={p.title ?? "Contact Us"}
        afterTitle="Fill in the form below to submit your enquiry and we will be in touch as soon as possible."
        endSlot={<OverlayCloseButton />}
      />
      {AUTH.currentUser && <ContactFormEditor
        formSnapshot={p.formSnapshot}
        closeOnSubmit={p.closeOnSubmit}
        doNotShowImportantInfo
      />}
    </AppPage>
  )} />
}

export default OverlayContactFormEditor;