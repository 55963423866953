import { Observer } from 'mobx-react-lite';
import React from 'react';
// import './LGBTQFlag.scss';

interface LGBTQFlagProps {}

const LGBTQFlag: React.FC<LGBTQFlagProps> = props => {
  return <Observer children={() => (
    <div className="LGBTQFlag">
      <svg viewBox="0 0 30 30" fill="none" style={{ display: 'block' }}>
        <path d="M30 0H0V4.99616H30V0Z" fill="#FF4545" />
        <path d="M30 4.99634H0V9.9925H30V4.99634Z" fill="#FFAC2F" />
        <path d="M30 10H0V14.9962H30V10Z" fill="#F9D51D" />
        <path d="M30 15H0V19.9962H30V15Z" fill="#28CA4B" />
        <path d="M30 20H0V24.9962H30V20Z" fill="#0585FB" />
        <path d="M30 25H0V29.9962H30V25Z" fill="#7F3FE9" />
      </svg>
    </div>
  )} />
}

export default LGBTQFlag;