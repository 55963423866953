import { action, observable } from "mobx";
import { getApiKey } from "../apiKeys";
import { RecaptchaEndpoints } from "../base/endpoints/recaptcha.endpoints";
import { sortRandomly } from "../base/utils/array.utils";
import { reportError } from "../base/utils/errors.utils";
import { loadScript } from "../base/utils/loadScript.util";
import { getRandomInteger } from "../base/utils/random.utils";
import { APIController } from "../controllers/api.controller";
import { UIController } from "../controllers/ui.controller";
import { IS_DEV, SHOULD_LOG } from "../env";

const anyWindow = window as any;
const apiKey = getApiKey('GOOGLE_RECAPTCHA');

const protectedHostnames = [
  ...IS_DEV ? ['localhost'] : [],
  'preflight.turn2me.ie',
  'turn2me.ie',
  'app.turn2me.ie',
  'turn2me.eu.ngrok.io',
  'qa-app.turn2me.ie',
  'uat-app.turn2me.ie'
]

const privateState = observable({
  currentHostnameIsProtected: protectedHostnames.includes(window.location.hostname),
  shouldByPassRecaptcha: false,
})

export const checkErrorForRecaptchaFailure = action((error: Error | string | unknown) => {
  const string = JSON.stringify(error);
  if (string.includes('Using fallback')) {
    privateState.shouldByPassRecaptcha = true;
    SHOULD_LOG() && console.log('Next submission will bypass recaptcha and use fallback');
  }
})

export const fallbackValidator = (UI: UIController) => new Promise<string>(async (resolve, reject) => {
  const a = getRandomInteger(1,6);
  const b = getRandomInteger(a,9);
  const sum = a + b;
  const checkAnswer = (value: number) => {
    if (value === sum) resolve('FALLBACK_RECAPTCHA_PASSED');
    else reject('FALLBACK_RECAPTCHA_FAILED')
  }
  const actions = sortRandomly([
    {
      buttonClass: "subtle",
      label: sum + '',
      action: () => checkAnswer(sum),
    },
    {
      buttonClass: "subtle",
      label: Math.round(sum * 2.3) + '',
      action: () => checkAnswer(Math.round(sum * 2.3)),
    },
    {
      buttonClass: "subtle",
      label: Math.round(sum / 1.5) + '',
      action: () => checkAnswer(Math.round(sum / 1.5)),
    },
  ])
  UI.DIALOG.present({
    heading: `Which number is the sum of ${a} and ${b}?`,
    actions,
  })
})

const loadRecaptchaScript = () => {
  return new Promise<string | null>(async (resolve, reject) => {
    try {
      await loadScript(`https://www.google.com/recaptcha/api.js?render=${apiKey}`);
      resolve("LOADED_GOOGLE_RECAPTCHA_SCRIPT");
    } catch (e) {
      reportError('Failed to load Google reCAPTCHA script. Using fallback.');
      reject('Failed to load Google reCAPTCHA script. Please try again.')
      return;
    }
  })
}
loadRecaptchaScript();

export function asyncGetRecaptchaToken(action: string = 'submit', UI: UIController, API: APIController) {
  return new Promise<string | null>(async (resolve, reject) => {
    if (!privateState.currentHostnameIsProtected) {
      SHOULD_LOG() && console.log('Current hostname is not protected by recaptcha.')
      privateState.shouldByPassRecaptcha = true;
    }
    if (privateState.shouldByPassRecaptcha) {
      try {
        const fallbackValidatorResult = await fallbackValidator(UI);
        resolve(fallbackValidatorResult);
      } catch (e) {
        reject(e);
      } finally {
        return;
      }
    }

    const { grecaptcha: hasRecaptcha } = anyWindow;
    if (!hasRecaptcha) {
      try {
        await loadRecaptchaScript();
      } catch (e) {
        reject('Failed to load Google reCAPTCHA script. Using fallback.');
      }
    }

    const { grecaptcha } = anyWindow;
    if (!grecaptcha) {
      reject('Failed to load Google reCAPTCHA script. Using fallback.');
    }

    SHOULD_LOG() && console.log('sending recaptcha request...');
    let timeout: any;
    try {
      grecaptcha.ready(() => {
        timeout = setTimeout(() => {
          reject('Google reCAPTCHA timed out. Using fallback.')
        }, 6180);
        try {
          grecaptcha.execute(
            apiKey,
            { action }
          ).then(async (token: string) => {
            SHOULD_LOG() && console.log('Google reCAPTCHA token received.');
            try {
              const url = RecaptchaEndpoints.public.verify;
              const payload = {
                recaptchaToken: token
              }
              const { success } = (await API.postRaw<{ success: boolean }>(url, payload)).data;
              if (success) {
                SHOULD_LOG() && console.log('Google reCAPTCHA request returns true.');
                resolve(token);
              } else {
                SHOULD_LOG() && console.log('Google reCAPTCHA request returns false.');
                reject('Google reCAPTCHA failed.');
              }
            } catch (e) {
              reportError(e);
              reject('Google reCAPTCHA failed.');
            }
            clearTimeout(timeout);
          })
        } catch(e) {
          reject(e);
          clearTimeout(timeout);
        }
      })
    } catch (e) {
      reject(e);
      clearTimeout(timeout);
    }
  })
}
