import { action, flow, observable, when } from 'mobx';
import { CancellablePromise } from 'mobx/dist/api/flow';
import { Nullable, Undefinable } from '../../base/@types';
import Overlay from '../../base/constructors/overlay.constructor';
import { last } from '../../base/utils/ramdaEquivalents.utils';
import tick from '../../base/utils/waiters.utils';
import { SHOULD_LOG } from '../../env';
import { UIController } from '../ui.controller';
import { IOverlay, OverlayConfig } from './ui.controller.types';

export const makeOverlayController = () => {
  
  const c: OverlayController = observable({

    UI: null as Nullable<UIController>,

    overlays: [] as IOverlay[],

    present: (
      o: OverlayConfig,
      options?: {
        waitForOtherOverlaysToClose?: boolean,
      }
    ) => flow(function * () {
      if (options?.waitForOtherOverlaysToClose) {
        yield when(() => c.overlays.length === 0);
      } else {
        const { id, name } = o;
        const existingOverlay = c.overlays.find(o => id ? o.id === id : o.name === name);
        if (existingOverlay) {
          const duplicateStrategy = o.duplicateStrategy || (o.duplicateStrategyReplaceDeterminer ? 'replace' : undefined);
          switch (duplicateStrategy) {
            case 'abort': 
              SHOULD_LOG() && console.info('Duplicate overlay detected, aborting according to specified strategy.', o);
              return;
            case 'throw': 
              SHOULD_LOG() && console.log(o);
              throw Error('Unexpected duplicate overlay detected.');
            case 'replace': 
              if (typeof o.duplicateStrategyReplaceDeterminer === 'function') {
                const shouldReplace = o.duplicateStrategyReplaceDeterminer(existingOverlay.config, o);
                if (shouldReplace) existingOverlay.close();
              } else existingOverlay.close();
              break;
            case 'continue': default: break;
          }
        }
      }
      return new Promise<IOverlay>(action((resolve, reject) => {
        const overlay = new Overlay(o, c);
        c.overlays.push(overlay);
        const stackEl = document.querySelector('.OverlayStack') as Nullable<HTMLElement>;
        c.UI?.focusOnElementInPortal(stackEl);
        resolve(overlay);
      }))
    })(),

    dismiss: async (o?: IOverlay | string) => await flow(function * () {
      const index = c.overlays.findIndex(overlay => typeof o === 'string' ? overlay.config.name === o : overlay === o);
      let overlay = c.overlays[index] || last(c.overlays);
      if (!overlay) return;
      if (overlay.status === 'closed') {
        c.overlays.splice(index,1);
      } else {
        yield overlay.close();
      }
    })(),

    dismissAll: async () => {
      while (c.overlays.length > 0) {
        const lastOverlay = last(c.overlays);
        c.dismiss(lastOverlay);
        await tick();
      }
    },
    
    getOverlay: (identifier: string) => {
      return c.overlays.find(o => o.id === identifier || o.name === identifier);
    },
    hasOverlay(query: string): boolean {
      return Boolean(c.overlays.find(o => o.id === query || o.name === query));
    },
    hasOverlayById(overlayId: string): boolean {
      return Boolean(c.overlays.find(o => o.id === overlayId));
    },
    hasOverlayByName(overlayName: string): boolean {
      return Boolean(c.overlays.find(o => o.name === overlayName));
    },
    get hasOpenedOverlays(): boolean {
      return c.overlays.filter(o => o.status === 'opened').length > 0;
    },

    reset: action(() => {
      c.overlays.forEach(o => o.close());
    })

  })

  return c;

}

export type OverlayController = {
  UI: Nullable<UIController>,
  overlays: IOverlay[],
  present: (o: OverlayConfig, options?: {
    waitForOtherOverlaysToClose?: boolean,
  }) => CancellablePromise<Promise<IOverlay> | undefined>,
  dismiss: (o?: IOverlay | string) => Promise<void>,
  dismissAll: () => Promise<void>,
  getOverlay: (identifier: string) => Undefinable<IOverlay>,
  hasOverlay: (query: string) => boolean,
  hasOverlayById: (overlayId: string) => boolean,
  hasOverlayByName: (overlayName: string) => boolean,
  hasOpenedOverlays: boolean,
  reset: () => void,
}
