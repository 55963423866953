import { action, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import BodyCopyRenderer from '../../base/components/BodyCopyRenderer/BodyCopyRenderer';
import ColorTagHidden from '../../base/components/ColorTag/ColorTagHidden';
import ColorTagSafe from '../../base/components/ColorTag/ColorTagSafe';
import ColorTagUnsafe from '../../base/components/ColorTag/ColorTagUnsafe';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import { UICardInnerProps } from '../../base/components/UICard/UICard';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { makeActionConfig } from '../../base/utils/actionConfig.utils';
import joinClassName from '../../base/utils/className.utils';
import { NoOp } from '../../base/utils/functions.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { ApiModelName } from '../../constants/ApiModels.enum';
import { getEmotionName } from '../../constants/emotions.enum';
import { Thought } from '../../models/makeThought.model';
import FlaggableItemManager from '../../modules/Admin/_components/FlaggableItemManager/FlaggableItemManager';
import CommentsManager from '../CommentsManager/CommentsManager';
import EmotionIcon from '../EmotionIcon/EmotionIcon';
import ThoughtControlsSet from '../ThoughtControlsSet/ThoughtControlsSet';
import ThoughtPrivateTag from '../ThoughtPrivateTag/ThoughtPrivateTag';
import UserCountryRenderer from '../UserCountryRenderer/UserCountryRenderer';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import './ThoughtCard.scss';

export type ThoughtCardProps = UICardInnerProps & {
  thought: Thought,
  alwaysShowComments?: boolean,
  disableInteractions?: boolean,
  neverShowManageButton?: boolean,
}

const ThoughtCard: React.FC<ThoughtCardProps> = props => {

  const { AUTH, UI, NAVIGATOR } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    get thought() { return p.thought },
    get comments() { return s.thought.comments },
    get content() { return s.thought.content || '' },
    get situation() { return s.thought.situation || '' },
    get response() { return s.thought.response || '' },
    get authorId() { return s.thought.userId },
    get isOwnThought() {
      return s.authorId === AUTH.currentUser?.id;
    },
    get length() {
      return s.thought.content?.length || 0;
    },
    get lengthName() {
      return s.length > 120 ? 'long' : s.length < 20 ? 'short' : 'medium';
    },
    get currentUserHadCommentedThisThought() {
      return s.thought.comments.some(c => c.userId === AUTH.currentUser?.id);
    },
    get showFlaggableItemManager() {
      return AUTH.isModerator && NAVIGATOR.isInAdminArea;
    },
    get commentsToShow() {
      if (AUTH.isModerator) return s.comments;
      return s.comments.filter(c => {
        const isHidden = c.timeHidden;
        const isDeleted = c.timeDeleted;
        return !isHidden && !isDeleted;
      });
    },
    _shouldShowCommentManager: AUTH.isModerator || Boolean(p.thought.comments.some(c => c.userId === AUTH.currentUser?.id)),
    get shouldShowCommentManager() {
      return (p.alwaysShowComments || s._shouldShowCommentManager) ;
    },
    get isFlagged() {
      return p.thought.flags.length > 0;
    },  
    get currentUserFirstReaction() {
      return AUTH.currentUser?.id && p.thought.reactions.find(r => r.userId === AUTH.currentUser?.id);
    },
    shouldAutoFocusOnCommentComposerInput: false,
    toggleCommentManager: action(() => {
      s._shouldShowCommentManager = !s._shouldShowCommentManager;
      if (s.shouldShowCommentManager) {
        s.shouldAutoFocusOnCommentComposerInput = true;
      }
    }),
    menuIsOpen: false,
    handleMenuOpen: action(() => s.menuIsOpen = true),
    handleMenuClose: action(() => s.menuIsOpen = false),
    promptAboutPrivateTag: () => {
      UI.DIALOG.present({
        heading: <>The <BaseIcon icon="lock" variant="filled" /> icon means that this thought is private.</>,
        body: <>
          <p>Private thoughts are only viewable to yourself and our counsellors and moderators. </p>
          { p.thought.timeHidden && <p>Since this thought was hidden by either our system or our moderators, it will remain private. If you think it has been hidden by mistake, please let us know.</p>}
        </>,
        actions: [
          makeActionConfig('OK', NoOp),
        ]
      })
    },
    explainHidden: () => {
      UI.DIALOG.present({
        heading: 'This thought has been hidden to the public.',
        body: 'This could have been automatically hidden by our system or after being reviewed by our moderators. If you think this was a mistake, please let us know.',
        defaultActions: ['positive'],
      })
    },
    get shouldShowOriginalContent() {
      return AUTH.isModerator && s.thought.originalThought && s.thought.originalThought.content !== s.thought.content;
    },
    get shouldShowOriginalSituation() {
      return AUTH.isModerator && s.thought.originalThought && s.thought.originalThought.situation !== s.thought.situation;
    },
    get shouldShowOriginalResponse() {
      return AUTH.isModerator && s.thought.originalThought && s.thought.originalThought.response !== s.thought.response;
    },
  }));

  useOnMount(() => {
    return when(
      () => s.thought.comments.length > 0, 
      () => {
        if (!s._shouldShowCommentManager) s._shouldShowCommentManager = s.currentUserHadCommentedThisThought
      }
    )
  })

  return <Observer children={() => (
    <div className={
      joinClassName('ThoughtCard',
        s.thought.isPrivate && 'private',
        s.menuIsOpen && 'menuOpen',
        s.isFlagged && AUTH.canModerate && 'flagged',
        s.thought.timeHidden && 'hidden',
        s.thought.timeDeleted && 'deleted',
        AUTH.canModerate && s.thought.isSafe && 'safe',
        AUTH.canModerate && s.thought.isUnsafe && 'unsafe',
      )}
      data-id={`thought#${s.thought.id}`}
      data-thought-id={s.thought.id}
      data-length={s.lengthName}
      data-emotion={s.thought.emotionId}
      data-author-id={s.thought.user?.id || s.thought.userId}
      data-current-user-has-reacted={Boolean(s.currentUserFirstReaction)}
      data-comments-visible={s.shouldShowCommentManager}
    >
      <div className="ThoughtCardInner">
        <header className="ThoughtCardHeader">
          <h3>
            {AUTH.canModerate && <span><code>#{s.thought.id}</code></span> }
            { s.thought.emotionId && <EmotionIcon emotion={s.thought.emotionId} size={24} /> }
            {AUTH.canModerate && <span className="ThoughtCardEmotionName">{getEmotionName(p.thought.emotionId)}</span>}
            <strong>
              <UsernameRenderer user={s.thought.user} userId={s.authorId} showColorLabel showAsYouIfSelf />
              {AUTH.canModerate && s.thought.user && <> • <UserCountryRenderer user={s.thought.user} /></> }
              &nbsp;•
            </strong>
            <span><DateRenderer value={s.thought.timeCreated} timeOnlyIfToday /></span>
            {AUTH.canModerate && <>
              {s.thought.isSafe && <ColorTagSafe /> }
              {s.thought.isUnsafe && <ColorTagUnsafe /> }
            </>}
            {s.thought.timeHidden && <span style={{ cursor: 'help' }}><ColorTagHidden onClick={s.explainHidden}/></span> }
          </h3>
          {s.thought.timeDeleted && <span className="ThoughtCardDeletedTag">Deleted on <DateRenderer value={s.thought.timeDeleted} /></span>}
          {s.thought.isPrivate && <ThoughtPrivateTag onClick={s.promptAboutPrivateTag}/>}
        </header>
        <div className="ThoughtCardContent">
          <div className="ThoughtCardContentBody">
            {s.content ? <BodyCopyRenderer className="ThoughtCardContentBodyNew" source={s.content}  /> : <code>[Empty]</code>}
            {s.shouldShowOriginalContent && <BodyCopyRenderer className="ThoughtCardOriginalCopy" source={'[Original Copy]: ' + (s.thought.originalThought!.content || '[Empty]')} />}
          </div>
        </div>
        {(s.situation || s.shouldShowOriginalSituation) && <div className="ThoughtCardSituation">
          <h4>Situation</h4>
          {s.situation ? <BodyCopyRenderer source={s.situation} className="ThoughtCardSituationBody" /> : <code>[Empty]</code>}
          {s.shouldShowOriginalSituation && <BodyCopyRenderer className="ThoughtCardOriginalCopy" source={'[Original Copy]: ' + (s.thought.originalThought!.situation || '[Empty]')} />}
        </div>}
        {(s.response || s.shouldShowOriginalResponse) && <div className="ThoughtCardResponse">
          <h4>Response</h4>
          {s.response ? <BodyCopyRenderer source={s.response} className="ThoughtCardResponseBody" /> : <code>[Empty]</code>}
          {s.shouldShowOriginalResponse && <BodyCopyRenderer className="ThoughtCardOriginalCopy" source={'[Original Copy]: ' + (s.thought.originalThought!.response || '[Empty]')} />}
        </div>}
        <footer className="ThoughtCardFooter">
          {s.showFlaggableItemManager && <FlaggableItemManager flaggable={s.thought!} modelType={ApiModelName.THOUGHT} compact />}
          <div className="ThoughtCardFooterInner">
            <ThoughtControlsSet
              thought={s.thought}
              onToggleCommentManager={s.toggleCommentManager}
              onMenuOpen={s.handleMenuOpen}
              onMenuClose={s.handleMenuClose}
              disableInteractions={p.disableInteractions}
              neverShowManageButton={p.neverShowManageButton}
            />
          </div>
        </footer>
      </div>

      { s.shouldShowCommentManager && <CommentsManager
        comments={s.commentsToShow}
        thought={s.thought}
        replyToModelType={ApiModelName.THOUGHT}
        replyToModelId={s.thought.id}
        disableInteractions={p.disableInteractions}
        canComment
        showModeratorControls={s.showFlaggableItemManager}
        autoFocus={s.shouldAutoFocusOnCommentComposerInput}
      /> }

    </div>
  )} />
}

export default ThoughtCard;