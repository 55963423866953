import { Observer } from 'mobx-react-lite';
import React from 'react';
import { VOIPCall } from '../../base/@types/voip.types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import ColorTag from '../../base/components/ColorTag/ColorTag';
import DurationRenderer from '../../base/components/DurationRenderer/DurationRenderer';
import TimeDisplayer from '../../base/components/TimeDisplayer/TimeDisplayer';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import './VoipCallEntry.scss';

interface VoipCallEntryProps {
  call: VOIPCall,
  color?: string,
}

const VoipCallEntry: React.FC<VoipCallEntryProps> = props => {

  const { VOIP: TWILIO } = useControllers();

  const p = useProps(props);
  
  const s = useStore(() => ({
    get call() {
      return p.call;
    },
    get name() {
      return s.call.user ? <UsernameRenderer user={s.call.user} /> : s.call.name;
    },
    get showPhoneIcon() {
      return !s.call.startTime || !s.call.endTime;
    },
    get phoneIcon() {
      if (!s.call.startTime) return 'phone';
      if (s.call.startTime && !s.call.endTime) return 'hang-up';
      return 'phone'
    },
    get phoneIconColor() {
      if (s.phoneIcon === 'phone') return 'green';
      if (s.phoneIcon === 'hang-up') return 'red';
      return 'median';
    },
    get phoneIconAction() {
      return () => {
        if (!s.call.startTime) s.call.start();
        if (!s.call.endTime) s.call.end();
      }
    },
    get phoneIconLoading() {
      return s.call.status === 'pending';
    },
    get showDeleteButton() {
      return s.call.status === 'closed';
    },
    clearSelf() {
      TWILIO.removeVoipCallInstance(s.call);
    },
  }));

  return <Observer children={() => (
    <div className="VoipCallEntry" data-status={s.call.status}>
      <header className="VoipCallEntryHeader">
        <h3>Call with {s.name}</h3>
        {s.call.user?.mobileNumber && <p>{s.call.user?.mobileNumber}</p>}
        <p className="VoipCallEntryMeta">
          {s.call.status === 'failed' && <ColorTag color="median">Failed</ColorTag>}
          <strong><DurationRenderer startTime={s.call.startTime} endTime={s.call.endTime} /></strong>
          {s.call.endTime && <span> (<TimeDisplayer value={s.call.startTime} timezoneMode="local" />–<TimeDisplayer value={s.call.endTime} timezoneMode="local" />)</span>}
        </p>
      </header>
      <div className="VoipCallEntryControls">
        { s.showPhoneIcon && (
          <BaseButton className="VoipCallEntryPhoneButton" icon={s.phoneIcon} iconVariant="filled" appearance="icon" color={s.phoneIconColor} onClick={s.phoneIconAction} loading={s.phoneIconLoading} />
        ) }
        { s.showDeleteButton && (
          <BaseButton className="VoipCallEntryDeleteButton" icon="close" iconVariant="filled" appearance="icon" onClick={s.clearSelf} />
        ) }
      </div>
    </div>
  )} />
}

export default VoipCallEntry;