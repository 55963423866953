import { action, flow, observable, reaction } from 'mobx';
import React from 'react';
import { ColorCodedState } from '../../base/@types';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import OverlayMakePayment from '../../base/components/OverlayMakePayment/OverlayMakePayment';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useSyncObservableValueToStorage } from '../../base/hooks/useSyncObservableValueToStorage.hook';
import { makeActionConfig, makeCancelAction } from '../../base/utils/actionConfig.utils';
import { makeDisposerController } from '../../base/utils/disposer.utils';
import { decodeString, encodeString } from '../../base/utils/encoder.utils';
import { useStore } from '../../base/utils/mobx.utils';
import { autoPluralize } from '../../base/utils/string.utils';
import {
  TimeTillState,
  createUTCMoment,
  getHoursTillDateTime,
  getTimeTillState,
  minutes,
} from "../../base/utils/time.utils";
import { isFunction } from '../../base/utils/typeChecks.utils';
import { setUrlParam } from '../../base/utils/urlParams.utils';
import tick, { doEvery } from '../../base/utils/waiters.utils';
import { ApiModelName } from '../../constants/ApiModels.enum';
import { FEATURE_FLAGS, IS_DEV, isInCypressTestMode } from "../../env";
import {
  Assignment,
  AssignmentGAD7,
  AssignmentPHQ9,
  AssignmentPayment,
} from "../../models/makeAssignment.model";
import { CounsellingSession } from '../../models/makeCounsellingSession.model';
import { cancelSession } from '../../utils/counsellingSession.utils';
import { ID_GoalSheetWizard } from '../GoalSheetWizard/GoalSheetWizard';
import OverlaySurveyGAD7 from '../OverlaySurveyGAD7/OverlaySurveyGAD7';
import OverlaySurveyPHQ9 from '../OverlaySurveyPHQ9/OverlaySurveyPHQ9';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
// import './PreSessionActionWatcher.scss';

type PreSessionActionWatcherProps = {}

const _ = observable({
  shouldDisablePreSessionSurveys: false,
})

if (window.Cypress) {
  Reflect.set(window, 'disablePreSessionSurveys', action(() => {
    _.shouldDisablePreSessionSurveys = true;
  }))
}

const FREE_SESSION_REQUIRED_ASSIGNMENT_TYPES = [ApiModelName.SURVEY_GAD7, ApiModelName.SURVEY_PHQ9];
const PAID_SESSION_REQUIRED_ASSIGNMENT_TYPES = [...FREE_SESSION_REQUIRED_ASSIGNMENT_TYPES, ApiModelName.PAYMENT];

const NUM_MAX_ALLOWED_DELAYS = 3;

enum PopupReminderType {
  paymentOnly = 'paymentOnly',
  surveysOnly = 'surveysOnly',
  all = 'surveysAndPayment',
}

const PreSessionActionWatcher: React.FC<PreSessionActionWatcherProps> = props => {
  const { AUTH, COUNSELLING, NAVIGATOR, MESSENGER, UI, API } = useControllers();
  const s = useStore(() => ({
    numTimesDelay: 0,
    isWaiting: false,
    setNotWaiting: action(() => {
      s.isWaiting = false;
    }),
    delay: () => {
      s.setNotWaiting();
      s.incrementTimesDelay();
    },
    resetDelay: action(() => {
      s.setNotWaiting();
      s.numTimesDelay = 0;
    }),
    incrementTimesDelay: action(() => {
      s.numTimesDelay += 1;
    }),
    get allowDelay() {
      return s.numTimesDelay < NUM_MAX_ALLOWED_DELAYS;
    },

    prevSessionId: null as string | null,
    decideWhetherToResetDelay: action((sessionId: string | null) => {
      if (!s.prevSessionId) {
        s.prevSessionId = sessionId;
        return;
      }
      if (s.prevSessionId !== sessionId) {
        s.prevSessionId = sessionId;
        s.numTimesDelay = 0;
      }
    }),
  }))

  useSyncObservableValueToStorage(
    ['PreSessionActionWatcher', 'delayCount'],
    () => encodeString(s.numTimesDelay.toString()),
    action(v => {
      if (v) {
        try {
          const parsedValue = parseInt(decodeString(v));
          if (isNaN(parsedValue) || parsedValue < 0) {
            s.numTimesDelay = NUM_MAX_ALLOWED_DELAYS;
            return;
          }
          s.numTimesDelay = parsedValue;
        } catch (e) {
          console.warn(`Failed to parse PreSessionActionWatcher::delayCount: ${decodeString(v)}`);
          s.numTimesDelay = NUM_MAX_ALLOWED_DELAYS;
        }
      }
    }),
  )

  const filterSessionsWithIncompleteSessionAssignments = (sessions: CounsellingSession[]): CounsellingSession[] => {
    return sessions.filter(sess => {
      const shouldEnforcePayment = sess.shouldEnforcePayment && !FEATURE_FLAGS.DISABLE_DONATIONS;
      const REQUIRED_ASSIGNMENT_TYPES = shouldEnforcePayment ? PAID_SESSION_REQUIRED_ASSIGNMENT_TYPES : FREE_SESSION_REQUIRED_ASSIGNMENT_TYPES;
      return sess.assignments.some(ass => !ass.isCompleted && ass.targetType ? REQUIRED_ASSIGNMENT_TYPES.includes(ass.targetType) : false);
    }).sort((a, b) => {
      const at = a.timeScheduled ?? a.timeCreated;
      const bt = b.timeScheduled ?? b.timeCreated;
      return createUTCMoment(at).diff(createUTCMoment(bt));
    })
  }

  const computePrioritySessionToAction = (sessions: CounsellingSession[]) => {
    let prioritySession = null as CounsellingSession | null;
    let incompleteAssignments = [] as Assignment[];
    let timeTillSessionState = null as TimeTillState | null;
    let popupReminderType = PopupReminderType.all as PopupReminderType;

    const updateOuterScopeVariables = (session: CounsellingSession) => {
      prioritySession = session;
    }

    for (const session of sessions) {
      timeTillSessionState = getTimeTillState({
        timeStarted: session.timeStarted,
        timeEnded: session.timeEnded,
        timeScheduled: session.timeScheduled,
      })

      const hasPaymentAssignment = (assignment: Assignment) => {
        return !assignment.isCompleted && assignment.targetType ? [ApiModelName.PAYMENT].includes(assignment.targetType) : false;
      }

      const canFillSurveys = timeTillSessionState.isToday || timeTillSessionState.isTomorrow;
      const shouldEnforcePayment = session.shouldEnforcePayment && !FEATURE_FLAGS.DISABLE_DONATIONS;
      if (shouldEnforcePayment && !canFillSurveys && timeTillSessionState.isTodayOrFuture) {
        incompleteAssignments = session.assignments.filter(hasPaymentAssignment);
        popupReminderType = PopupReminderType.paymentOnly;
        if (incompleteAssignments.length > 0) {
          updateOuterScopeVariables(session);
          break;
        }
      } else if (shouldEnforcePayment && canFillSurveys) {
        incompleteAssignments = session.assignments.filter(ass => !ass.isCompleted && ass.targetType ? PAID_SESSION_REQUIRED_ASSIGNMENT_TYPES.includes(ass.targetType) : false);
        // if have payment, prioritize only payment.
        // if not, show only surveys.
        const paymentAssignments = incompleteAssignments.filter(hasPaymentAssignment);
        if (paymentAssignments.length > 0) {
          const hoursTillTimeScheduled = getHoursTillDateTime(session.timeScheduled);

          // in case API queue hasnt auto cancelled session.
          const isPaidSessionAutoCancelled = session.isPaidSession && hoursTillTimeScheduled < 24;
          if (isPaidSessionAutoCancelled) continue;

          incompleteAssignments = paymentAssignments;
          popupReminderType = PopupReminderType.paymentOnly;
        } else {
          popupReminderType = PopupReminderType.surveysOnly;
        }
        updateOuterScopeVariables(session);
        break;
      } else if (canFillSurveys) {
        incompleteAssignments = session.assignments.filter(ass => !ass.isCompleted && ass.targetType ? FREE_SESSION_REQUIRED_ASSIGNMENT_TYPES.includes(ass.targetType) : false);
        popupReminderType = PopupReminderType.surveysOnly;
        updateOuterScopeVariables(session);
        break;
      }
    }
    return {
      session: prioritySession,
      sessionTimeStartingTag: timeTillSessionState?.startingTagContent,
      sessionIncompleteAssignments: incompleteAssignments.sort((a, b) => a.targetType === ApiModelName.PAYMENT ? -1 : 1),
      popupReminderType,
    };
  }

  const computeDialogHeading = (
    session: CounsellingSession,
    popupReminderType: PopupReminderType,
  ) => {
    switch (popupReminderType) {
      case PopupReminderType.paymentOnly:
        const hoursTillTimeScheduled = getHoursTillDateTime(session.timeScheduled);
        if (session.isPaidSession && hoursTillTimeScheduled < 24) return 'Session cancelled due to lack of payment';
        return `${session.isPaidSession ? 'Payment' : 'Donation'} required`;
      case PopupReminderType.all:
      case PopupReminderType.surveysOnly:
      default:
        return 'Action required for your next counselling session';
    }
  }

  const computeDialogBody = (
    session: CounsellingSession,
    sessionIncompleteAssignments: Assignment[],
    sessionTimeStartingTag: string | JSX.Element | undefined,
    popupReminderType: PopupReminderType,
  ) => {
    const TimeInfo = <><strong>{sessionTimeStartingTag}</strong> (<DateRenderer value={session.timeScheduled} format="LLL" />)</>;
    switch (popupReminderType) {
      case PopupReminderType.surveysOnly:
        return <>
          <p>Please complete the pre-session <strong>GAD7 and PHQ9 surveys</strong> before your counselling session.</p>
          <p>Your next session is {TimeInfo}.</p>
        </>;
      case PopupReminderType.paymentOnly:
        const hoursTillTimeScheduled = getHoursTillDateTime(session.timeScheduled);

        if (!session.isPaidSession) {
          return <>
            <p>Please complete your <strong>donation</strong> before your counselling session.</p>
            <p>Your next session is {TimeInfo}.</p>
          </>
        }

        if (hoursTillTimeScheduled >= 24 && hoursTillTimeScheduled <= 48) {
          return <>
            <p><strong>You have {autoPluralize(Math.round(hoursTillTimeScheduled - 24), 'hour')} left to complete the payment for your next session, or your booking will be cancelled.</strong></p>
            <p>Your next session is {TimeInfo}.</p>
          </>
        } else if (hoursTillTimeScheduled > 48) {
          return <>
            <p>In order to secure your booking, please complete the payment for your next session in advance of the 48 hours deadline ({autoPluralize(Math.round(hoursTillTimeScheduled - 48), 'hour')} left).</p>
            <p>Your next session is {TimeInfo}.</p>
          </>;
        } else {
          return <>
            <p>Your session scheduled for {TimeInfo} was cancelled due to lack of payment.</p>
            <p>Please get in touch through the contact form to book a new session with your counsellor, <UsernameRenderer user={session.counsellor} />.</p>
          </>
        }
      case PopupReminderType.all:
      default:
        return <>
          <p>Please complete the following item(s) before your counselling session {TimeInfo}:</p>
          <ul>
            {sessionIncompleteAssignments.map((ass) => {
              switch (ass.targetType) {
                case ApiModelName.SURVEY_GAD7:
                  return <li key={ass.id}>Fill pre-session survey 1 (GAD7 Survey)</li>;
                case ApiModelName.SURVEY_PHQ9:
                  return <li key={ass.id}>Fill pre-session survey 2 (PHQ9 Survey)</li>;
                case ApiModelName.PAYMENT:
                  return <li key={ass.id}>{session.isPaidSession ? 'Payment' : 'Donation'} for session</li>;
              }
              return null;
            })}
          </ul>
        </>;
    }
  }

  const computeDialogActionButtonTitle = (
    session: CounsellingSession,
    popupReminderType: PopupReminderType
  ) => {
    switch (popupReminderType) {
      case PopupReminderType.surveysOnly:
        return 'Complete surveys';
      case PopupReminderType.paymentOnly:
        return `Complete ${session.isPaidSession ? 'payment' : 'donation'}`;
      case PopupReminderType.all:
      default:
        return "Complete items";
    }
  }

  useOnMount(() => {
    const watcherAction = flow(function* () {
      if (_.shouldDisablePreSessionSurveys) return;
      if (!AUTH.currentUser) return;
      if (!AUTH.isAuthenticated) return;
      if (NAVIGATOR.currentLocationPathname.match(/^\/app\/(invitation)/)) return;
      if (MESSENGER.dockedChats.length) return;
      if (UI.OVERLAY.hasOverlayByName(ID_GoalSheetWizard)) return;
      if (s.isWaiting) return;
      s.isWaiting = true;
      yield tick(2000);

      const sessionsWithIncompleteAssignments = filterSessionsWithIncompleteSessionAssignments(COUNSELLING.futureSessions);

      const { session, sessionTimeStartingTag, sessionIncompleteAssignments, popupReminderType } = computePrioritySessionToAction(sessionsWithIncompleteAssignments);

      // console.log(session && getHoursTillDateTime(session.timeScheduled));

      const shouldShowDialog = !!session && sessionIncompleteAssignments.length > 0;
      if (shouldShowDialog) {
        s.decideWhetherToResetDelay(session.id);
        const isPaidSessionPendingPayment = session.isPaidSession && popupReminderType === PopupReminderType.paymentOnly;
        const dialogConfig = {
          heading: computeDialogHeading(session, popupReminderType),
          body: computeDialogBody(session, sessionIncompleteAssignments, sessionTimeStartingTag, popupReminderType),
          actions: [
            ...IS_DEV ? [makeCancelAction('X')] : [],
            ...(
              s.allowDelay&&
              !isPaidSessionPendingPayment && 
              (session && getHoursTillDateTime(session.timeScheduled) > 48)
              )? [ makeActionConfig(`Later (${NUM_MAX_ALLOWED_DELAYS-s.numTimesDelay})`, s.delay, { buttonClass: 'subtle' }) ]:[],
            ...isPaidSessionPendingPayment ? [makeActionConfig(`Cancel session`, () => cancelSession(session, AUTH, UI.DIALOG, API, () => UI.DIALOG.present(dialogConfig), s.resetDelay), { buttonClass: 'subtle' })] : [],
            makeActionConfig(computeDialogActionButtonTitle(session, popupReminderType), async () => {
              // open counselling journey overlay.
              await setUrlParam('applicationId', session.application?.id, NAVIGATOR);
              // open first survey that is not filled.
              await tick(1000);

              let shouldContinuePerformingAction = true;
              const cancelAction = () => {
                shouldContinuePerformingAction = false;
                s.delay();
              }
              const whenCompletedAllAction = () => {
                shouldContinuePerformingAction = false;
                s.resetDelay();
              }

              const actions = (function* actionGenerator() {
                for (const ass of sessionIncompleteAssignments) {
                  switch (ass.targetType) {
                    case ApiModelName.SURVEY_GAD7:
                      yield (onAfterClose: () => void) => UI.OVERLAY.present({
                        component: <OverlaySurveyGAD7 assignment={ass as AssignmentGAD7} assignmentId={ass.id} onCloseButton={cancelAction} hideCloseButton={true || !s.allowDelay} />,
                        onAfterClose,
                      });
                      break;
                    case ApiModelName.SURVEY_PHQ9:
                      yield (onAfterClose: () => void) => UI.OVERLAY.present({
                        component: <OverlaySurveyPHQ9 assignment={ass as AssignmentPHQ9} assignmentId={ass.id} onCloseButton={cancelAction} hideCloseButton={true || !s.allowDelay} />,
                        onAfterClose,
                      })
                      break;
                    case ApiModelName.PAYMENT:
                      yield (onAfterClose: () => void) => UI.OVERLAY.present({
                        component: <OverlayMakePayment assignment={ass as AssignmentPayment} assignmentId={ass.id} onCloseButton={cancelAction} hideCloseButton={!s.allowDelay} />,
                        onAfterClose,
                      })
                      break;
                  }
                }
                yield whenCompletedAllAction;
              })();

              const performAction = () => {
                if (shouldContinuePerformingAction) {
                  const action = actions.next();
                  if (action.value && isFunction(action.value)) action.value(performAction);
                }
              }
              performAction();
            }),
          ],
          colorCodedState: ColorCodedState.attention,
        }
        yield UI.DIALOG.present(dialogConfig);
      } else {
        s.resetDelay()
      };
    })

    const disposer = makeDisposerController();

    const ENABLE_WATCHER = !isInCypressTestMode;

    if (ENABLE_WATCHER) {
      disposer.add(doEvery(
        watcherAction,
        minutes(IS_DEV ? 10 : 3),
      ));
      disposer.add(reaction(
        () => computePrioritySessionToAction(filterSessionsWithIncompleteSessionAssignments(COUNSELLING.futureSessions)).session?.id,
        (prioritySessionId) => {
          if (!!prioritySessionId) watcherAction();
        },
        { fireImmediately: true }
      ))
    }

    return disposer.disposer;
  })
  return null;
}

export default PreSessionActionWatcher;