import { action, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import { Undefinable } from '../../../base/@types';
import BaseButton from '../../../base/components/BaseButton/BaseButton';
import BaseIcon from '../../../base/components/BaseIcon/BaseIcon';
import BaseSpacer from '../../../base/components/BaseSpacer/BaseSpacer';
import InfoDisplayItem from '../../../base/components/InfoDisplayList/InfoDisplayItem';
import InfoDisplayList from '../../../base/components/InfoDisplayList/InfoDisplayList';
import LoadingIndicatorSection from '../../../base/components/LoadingIndicatorSection/LoadingIndicatorSection';
import ShadedBlock from '../../../base/components/ShadedBlock/ShadedBlock';
import { useOnMount } from '../../../base/hooks/lifecycle.hooks';
import { useOnRouteFocus } from '../../../base/hooks/navigator.hooks';
import { useCreateResizeQueryWithRef } from '../../../base/hooks/useCreateResizeQueryWithRef.hook';
import { useObservableRef } from '../../../base/hooks/useObservableRef.hook';
import { useControllers } from '../../../base/hooks/useRootController.hook';
import { useStore } from '../../../base/utils/mobx.utils';
import { take } from '../../../base/utils/ramdaEquivalents.utils';
import AlertCardList from '../../../components/AlertCardList/AlertCardList';
import SmallHeaderButton from '../../../components/SmallHeaderButton/SmallHeaderButton';
import SupportGroupSummaryCard from '../../../components/SupportGroupSummaryCard/SupportGroupSummaryCard';
import AdminGreetingWithDate from '../_components/AdminGreetingWithDate/AdminGreetingWithDate';
import AdminModuleHeader from '../_components/AdminModuleHeader/AdminModuleHeader';
import AdminTitleBar from '../_components/AdminTitleBar/AdminTitleBar';
import AdminUpcomingSessionsAndGroups from '../_components/AdminUpcomingSessionsAndGroups/AdminUpcomingSessionsAndGroups';
import AllCaughtUpBlock from '../_components/AllCaughtUpBlock/AllCaughtUpBlock';
import DashBlock from '../_components/DashBlock/DashBlock';
import './ModuleAdminDashboard.scss';

interface AdminModuleDashboardProps {}

const AdminDashBlockUpcomingSessionsAndGroups: React.FC = () => {

  const { STAFF, AUTH } = useControllers();

  return <Observer children={() => (
    <DashBlock
      className="AdminDashBlockUpcomingSessionsAndGroups"
      paddingTop={0}
      headerEndAlign="end"
      clipContent
    >
      <AdminModuleHeader
        title={<AdminGreetingWithDate />}
        padding={0}
      />
      <BaseSpacer size=".19em" />
      {(STAFF.canManageApplications || STAFF.canCoordGroups) ? <AdminUpcomingSessionsAndGroups /> : <h3>Welcome, {AUTH.currentUser?.firstName || AUTH.currentUser?.username}!</h3>}
    </DashBlock>
  )} />
}

const AdminDashBlockAlertList: React.FC = () => {

  const { ALERTS } = useControllers();

  const s = useStore(() => ({
    get alerts() {
      return ALERTS.unreadAlerts
    },
    get alertsToDisplay() {
      return take(10, s.alerts);
    },
  }));

  return <Observer children={() => (
    <DashBlock
      className="AdminDashBlockAlertList"
      heading={<h3>Actions Required</h3>}
      count={s.alertsToDisplay.length}
    >
      <AlertCardList
        alerts={s.alertsToDisplay}
        counter={() => ALERTS.numberOfUnreadAlerts}
        withColor
        emptyContent={
          <div className="AdminDashActionsRequiredEmpty">
            {ALERTS.firstDataRetrieved ? <>
              <BaseIcon icon="check-circle-display" size="2.8rem" />
              <em>No actions required at the moment.</em>
            </> : <LoadingIndicatorSection />}
          </div>
        }
        endSlot={ALERTS.numberOfUnreadAlerts > s.alertsToDisplay.length ? <BaseButton className="subtle" label="View All" size="lg" to="/admin/notifications" fullWidth /> : null}
      />
    </DashBlock>
  )} />
}

const AdminDashBlockSupportGroups: React.FC = () => {
  const { STAFF } = useControllers();
  return <Observer children={() => {
    return <DashBlock
      className="AdminDashBlockSupportGroups"
      heading={<h3>All Support Groups</h3>}
      headerEnd={<SmallHeaderButton color="blueGreen" to="/admin/manage/support-groups">Manage</SmallHeaderButton>}
    >
      <ShadedBlock color="blueGreen">
        <InfoDisplayList>
          <InfoDisplayItem label="Groups Scheduled">
            {STAFF.allCurrentOrFutureGroups.length}
          </InfoDisplayItem>
          <InfoDisplayItem label="Groups Available (not full)">
            {STAFF.allCurrentOrFutureGroupsThatIsNotFull.length}
          </InfoDisplayItem>
        </InfoDisplayList>
      </ShadedBlock>
      <BaseSpacer size="1em" />
      <Link className="AdminDashSupportGroupListButton" to="/admin/manage/support-groups?action=create">
        <div className="AdminDashSupportGroupListButtonIcon"><BaseIcon icon="plus" /></div>
        <span>Schedule a New Group</span>
      </Link>
      {STAFF.allCurrentOrFutureGroups.length > 0 && <div className="AdminDashSupportGroupList">
        {take(5, STAFF.allCurrentOrFutureGroups).map(g => <SupportGroupSummaryCard key={g.id} supportGroup={g} />)}
        {STAFF.allCurrentOrFutureGroups.length > 5 && <Link className="AdminDashSupportGroupListButton" to="/admin/manage/support-groups">
          <div className="AdminDashSupportGroupListButtonIcon"><BaseIcon icon="arrow" /></div>
          <span>See All Upcoming Groups</span>
        </Link>}
      </div>}
    </DashBlock>
  }} />
}

const AdminModuleDashboard: React.FC<
  AdminModuleDashboardProps
> = p => {

  const { ALERTS, UI, STAFF } = useControllers();
  
  const { ref, query } = useCreateResizeQueryWithRef<HTMLDivElement>();
  const firstSessionCardRef = useObservableRef<HTMLDivElement>();

  const s = useStore(() => ({
    get alerts() {
      return ALERTS.unreadAlerts
    },
    get alertsToDisplay() {
      return take(10, s.alerts);
    },
    slideRatio: undefined as Undefinable<number>,
    updateSlideRatio: () => {
      when(
        () => Boolean(firstSessionCardRef.current),
        action(() => {
          const firstSessionCard = firstSessionCardRef.current;
          if (firstSessionCard) {
            const boundingBox = firstSessionCard.getBoundingClientRect();
            const { width, height } = boundingBox;
            const ratio = width / height;
            s.slideRatio = ratio;
          }
          else s.slideRatio = undefined;
        })
      )
    }
  }));

  useOnMount(() => {
    setTimeout(s.updateSlideRatio);
    return query.onResize(s.updateSlideRatio);
  })

  useOnRouteFocus('/admin/dashboard', s.updateSlideRatio);

  return <Observer children={() => (
    <div className="ModuleAdminDashboard" ref={ref}>
      
      <AdminTitleBar stringTitle="turn2me Administration Panel" title="Dashboard" />

      <div className="ModuleAdminDashboardGrid">

        <AdminDashBlockUpcomingSessionsAndGroups />

        <AdminDashBlockAlertList />

        { STAFF.canManageSupportGroups && <AdminDashBlockSupportGroups /> }

        { UI.onlyPhones && <DashBlock className="AdminDashAllCaughtUpBlock">
          <AllCaughtUpBlock
            buttonLabel={STAFF.canViewManageModule ? "Go to Manage tab" : undefined}
            buttonLink={STAFF.canViewManageModule ? "/admin/manage" : undefined}
          />
        </DashBlock> }

      </div>

    </div>
  )} />

}

export default AdminModuleDashboard
