import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import ClickOrTap from '../../base/components/ClickOrTap/ClickOrTap';
import ColorTagCompleted from '../../base/components/ColorTag/ColorTagCompleted';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import { Primary } from '../../base/constants/color.enum';
import { useControllers } from '../../base/hooks/useRootController.hook';
import joinClassName from '../../base/utils/className.utils';
import { getContextColorStyle } from '../../base/utils/colors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { autoPluralize } from '../../base/utils/string.utils';
import { setUrlParam } from '../../base/utils/urlParams.utils';
import { ApiModelName } from '../../constants/ApiModels.enum';
import { getCounsellingTypeColorHex, getCounsellingTypeName } from '../../constants/counsellingTypes.descriptors';
import { CounsellingApplication } from '../../models/makeCounsellingApplication.model';
import SessionOrGroupTimeDisplay from '../SessionOrGroupTimeDisplay/SessionOrGroupTimeDisplay';
import UserFullNameRenderer from '../UserFullNameRenderer/UserFullNameRenderer';
import './CounsellingJourneyEntry.scss';

type CounsellingJourneyEntryProps = {
  application: CounsellingApplication,
}

const CounsellingJourneyEntry: React.FC<CounsellingJourneyEntryProps> = props => {
  const p = useProps(props);
  const { NAVIGATOR, AUTH } = useControllers();
  const viewDetails = () => {
    setUrlParam('applicationId', p.application.id, NAVIGATOR);
  }
  const s = useStore(() => ({
    get goalSheetAssignment() {
      return p.application.assignments.find(ass => ass.targetType === ApiModelName.SURVEY_GOAL_SHEET && ass.assignedToUserId === AUTH.currentUser?.id);
    },
    get completedSessions() {
      return p.application.sessions.filter(ses => ses.isCompleted);
    },
    get hasPendingGoalSheet() {
      return p.application.isOngoing && s.goalSheetAssignment && !s.goalSheetAssignment?.isCompleted;
    },
    get style() {
      return getContextColorStyle(Primary, s.hasPendingGoalSheet ? 'green' : getCounsellingTypeColorHex(p.application.type));
    },
  }))
  return <Observer children={() => (
    <div
      className={joinClassName(
        'CounsellingJourneyEntry',
        p.application.isCompleted && 'completed',
        p.application.isOngoing && 'ongoing',
        p.application.isArchived && 'archived',
        s.hasPendingGoalSheet && 'hasPendingGoalSheet'
      )}
      data-application-id={p.application.id}
      data-type={p.application.type}
      onClick={viewDetails}
      style={s.style}
      tabIndex={0}
    >
      <header className="CounsellingJourneyEntryHeader">
        <div className="CounsellingJourneyEntryHeaderInner">
          <BaseIcon icon="counselling" variant="filled" size="28" />
          <h2 className="CounsellingJourneyEntryTitle">{getCounsellingTypeName(p.application.type, !AUTH.currentUser?.isAdmin)} Counselling</h2>
          <p className="CounsellingJourneyEntryMeta">Counsellor: <UserFullNameRenderer user={p.application.counsellor} /> </p>
        </div>
        {p.application?.isCompleted ? <ColorTagCompleted /> : <span className="CounsellingJourneyEntryTopRightTag">
          {p.application.isArchived ? 'Archived' : 'View Details'}
        </span> }
      </header>
      {
        p.application?.isPending && <ShadedBlock color="blueGreen">
          <h3>We have received your application.</h3>
          <p>You will be notified as soon as your application is approved!</p>
        </ShadedBlock>
      }
      {
        p.application?.isOngoing && <div className="CounsellingJourneyEntryOngoingContent">
          {s.goalSheetAssignment && !s.goalSheetAssignment?.isCompleted && <ShadedBlock color="green">
            <div className="CounsellingJourneyEntryOngoingContentPendingGoalSheetNotice">
              <BaseIcon icon="flag-checkered" size="36" />
              <div>
                <p><strong>Application Approved!</strong></p>
                <p><ClickOrTap capitalized /> here to fill in your Goal Sheet.</p>
              </div>
            </div>
          </ShadedBlock> }
          { s.goalSheetAssignment?.isCompleted && <>
            { p.application.nextSession ? <>
              <h3>Next Session:</h3>
              <SessionOrGroupTimeDisplay model={p.application.nextSession} />
            </> : <>
              <ShadedBlock color="green">
                <p>
                  { s.completedSessions.length > 0 && <strong>{ autoPluralize(s.completedSessions.length, 'session')} completed.</strong>}
                  { s.completedSessions.length === 0 && <strong>Your counsellor will schedule a session with you soon.</strong> }
                </p>
              </ShadedBlock>
            </> }
          </> }
        </div>
      }
      {
        p.application?.isCompleted && <ShadedBlock color="blueGreen">
          <h3>Application Completed!</h3>
        </ShadedBlock>
      }
    </div>
  )} />
}

export default CounsellingJourneyEntry;