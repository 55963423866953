import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Renderable, RouteDef } from '../../base/@types';
import { IconName } from '../../base/components/Symbols/iconDefs';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useCreateResizeQueryWithRef } from '../../base/hooks/useCreateResizeQueryWithRef.hook';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { renderRenderable } from '../../base/utils/components.utils';
import { setCSSCustomProperty } from '../../base/utils/css.utils';
import './AppNavigation.scss';
import AppNavTab from './AppNavTab';

export type NavTabConfig = { 
  def: RouteDef, 
  icon: IconName,
  label?: string,
  badgeNumberGetter?: () => number,
};

interface AppNavigationProps {
  tabConfigs: NavTabConfig[],
  prefix: string,
  header?: Renderable,
  controls?: Renderable,
}

const AppNavigation: React.FC<AppNavigationProps> = props => {

  const { ref, query } = useCreateResizeQueryWithRef<HTMLDivElement>();
  const { UI } = useControllers();

  useOnMount(() => {
    const handler = () => {
      const { width, height } = query;
      setCSSCustomProperty('--AppNavigationWidth', width + 'px');
      setCSSCustomProperty('--AppNavigationHeight', height + 'px');
    }
    query.onResize(handler);
    handler();
  })

  return <Observer children={() => (
    <nav className="AppNavigation" ref={ref} data-tab-length={props.tabConfigs.length}>
      {
        UI.fromTabletAndTall && props.header && <header className="AppNavigationHeader">
          { renderRenderable(props.header) }
        </header>
      }
      <div className="AppNavigationInner">
        {
          props.tabConfigs.map(r => <AppNavTab
            key={r.def.identifier}
            identifier={r.def.identifier}
            routeDef={r.def}
            label={r.label}
            icon={r.icon}
            prefix={props.prefix}
            badgeNumberGetter={r.badgeNumberGetter}
          />)
        }
      </div>
      {
        UI.fromTablet && props.controls && <footer className="AppNavigationControls">
          { renderRenderable(props.controls) }
        </footer>
      }
    </nav>
  )} />
  
}

export default AppNavigation;