import { action, flow, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject, ColorCodedState } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseInput, { TextInputKeyboardEvent } from '../../base/components/BaseInput/BaseInput';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import { useObservableRef } from '../../base/hooks/useObservableRef.hook';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { ChatMediator } from '../../base/mediators/chat.mediator';
import { debounce } from '../../base/utils/debounce.utils';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { containsOnlyWhiteSpaceOrLineBreaks, trimString } from '../../base/utils/string.utils';
import tick from '../../base/utils/waiters.utils';
import { SHOULD_LOG } from '../../env';
import { ChatMessage } from '../../models/makeChatMessage.model';
import './ChatMessageComposer.scss';

interface ChatMessageComposerProps {
  chat: ChatMediator;
  message?: ChatMessage;
  onSuccess?: () => void;
  onFocus?: () => void;
}

function ChatMessageComposer(props: React.PropsWithChildren<ChatMessageComposerProps>) {
  const { UI, COMMON, AUTH } = useControllers();
  const inputRef = useObservableRef<HTMLTextAreaElement>();
  const p = useProps(props);
  const s = useStore(() => ({
    get mode() {
      return p.message ? 'edit' : 'create';
    },
    messageBody: p.message ? p.message.body : '',
    clear: action(() => {
      s.messageBody = '';
    }),
    focus: async () => {
      await tick();
      when(
        () => !!inputRef.current,
        () => inputRef.current?.focus()
      )
    },
    get disabled() {
      return !COMMON.online || p.chat.hasEnded || !s.selfParticipant || !!s.selfParticipant.timeMuted || s.hasPendingOutgoingMessage;
    },
    get currentUser() {
      return AUTH.currentUser;
    },
    get selfParticipant() {
      return p.chat.selfParticipant;
    },
    get bodyTooLong() {
      return false;
    },
    hasPendingOutgoingMessage: false,
    updateTypingIndication: debounce(() => {
      if (s.mode === 'edit') return;
      p.chat.sendTypingIndication(false);
    }, {
      timeout: 1000,
      fireImmediately: () => p.chat.sendTypingIndication(true),
      resetAfter: 2000
    }),
    get sendButtonLabel() {
      if (s.bodyTooLong) {
        return `${s.messageBody.length} / 1200`
      }
      return 'Send';
    },
    handleFocus: async function () {
    },
    get isIOS() {
      return UI.NATIVE.deviceInfo.browser.includes('ios');
    }
  }));

  const handleEnter = (e?: TextInputKeyboardEvent) => {
    if (!s.isIOS && e?.shiftKey) return;
    e && e.preventDefault();
    send();
  }

  const send = flow(function* () {
    if (s.bodyTooLong) return;
    if (s.hasPendingOutgoingMessage) return;
    const outgoingMessage = trimString(s.messageBody) ?? '';
    if (containsOnlyWhiteSpaceOrLineBreaks(outgoingMessage)) {
      SHOULD_LOG() && console.log('empty message added, ignoring');
      s.clear();
      return;
    }
    s.hasPendingOutgoingMessage = true;
    p.chat.sendTypingIndication(false);
    try {
      s.clear();
      if (s.mode === 'edit' && p.message) {
        yield p.chat.editMessage(p.message!.id, outgoingMessage)
        SHOULD_LOG() && console.log('message edited');
      } else {
        yield p.chat.sendMessage(outgoingMessage)
        SHOULD_LOG() && console.log('message posted');
      }
      p.onSuccess?.();
    } catch (e) {
      reportError(e);
      s.messageBody = outgoingMessage + `\n` + s.messageBody;
      UI.DIALOG.error({
        name: 'message-send-failed',
        heading: 'Failed to send the message',
        body: <ErrorRenderer error={(e as AnyObject).response ?? e} />,
        defaultActions: ['positive'],
      })
    } finally {
      s.hasPendingOutgoingMessage = false;
    }
  })

  return <Observer children={() => (
    <div className="ChatMessageComposer">
      <BaseInput
        type="textarea"
        form={s}
        field='messageBody'
        enterKeyHint="send"
        onFocus={s.handleFocus}
        onKeyUp={s.updateTypingIndication}
        onEnter={handleEnter}
        placeholder="Enter your message..."
        colorCodedState={s.bodyTooLong ? ColorCodedState.alert : ''}
        innerRef={inputRef}
        rows={UI.onlyPhones ? 2 : 3}
        dataCy="ChatMessageComposerTextarea"
      />
      <BaseButton
        className={s.mode === 'edit' ? '' : 'subtle'}
        onClick={send}
        rounded
        iconSize={s.mode === 'edit' ? 18 : 24}
        loading={s.hasPendingOutgoingMessage}
        icon={s.mode === 'edit' ? 'check' : 'send'}
        iconVariant="filled"
        dataCy="ChatMessageComposerSendButton"
      />
    </div>
  )} />
}

export default ChatMessageComposer;