import { reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { RouteDef } from '../../base/@types';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import { IconName } from '../../base/components/Symbols/iconDefs';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useAutoSyncWithInitializer, useProps } from '../../base/utils/mobx.utils';
import './AppNavTab.scss';

interface AppNavTabProps {
  identifier: string,
  icon: IconName,
  routeDef: RouteDef,
  prefix: string,
  label?: string,
  badgeNumberGetter?: () => number,
}

const AppNavTab: React.FC<AppNavTabProps> = props => {

  const p = useProps(props);
  const { id } = useParams<{id: string}>();
  const { NAVIGATOR, UI } = useControllers();
  const location = useLocation();

  const { pathname } = location;
  const s = useAutoSyncWithInitializer(() => ({
    id,
    pathname,
    lastUrlInThisTab: '',
    get baseUrl() {
      return `${p.prefix}/` + p.routeDef.urlFactory(s.id);
    },
    get url() {
      return s.isActive ? s.baseUrl : s.lastUrlInThisTab || s.baseUrl;
    },
    get regex() {
      return new RegExp(`^${s.baseUrl}*`);
    },
    get title() {
      return p.label ?? (p.routeDef.composeTitle ? p.routeDef.composeTitle() : 'Unnamed Route');
    },
    get match() {
      return s.pathname.match(s.regex);
    },
    get isActive() {
      return !!s.match;
    },
    recordLastUrlInThisTab() {
      if (s.pathname.match(s.regex)) s.lastUrlInThisTab = s.pathname;
    },
    get badgeNumber() {
      return p.badgeNumberGetter?.();
    },
    activate() {
      if (UI.displayMode === 'phone') {
        NAVIGATOR.navigateTo(s.url);
      }
    }
  }), { id, pathname });

  useOnMount(() => {
    s.recordLastUrlInThisTab();
    return reaction(() => s.pathname, s.recordLastUrlInThisTab);
  })

  return <Observer children={() => {
    return <div className="AppNavTab" onMouseDown={s.activate}>
      <NavLink className="AppNavTabInner" to={s.url} data-identifier={p.identifier}>
        <div className="AppNavTabIconWrapper">
          <BaseIcon className="AppNavTabIcon" name={props.icon} variant={(s.isActive) ? 'color' : 'regular'} />
          {s.badgeNumber ? <strong className="AppNavTabBadge">{s.badgeNumber}</strong> : null}
        </div>
        <span className="AppNavTabTitle">{s.title}</span>
      </NavLink>
    </div>
  }}/>

}

export default AppNavTab;