import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState, RouteDef } from '../../@types';
import { makeUrl } from '../../utils/url.utils';
import BaseMenuItem from '../BaseMenu/BaseMenuItem';
import './TwoColumnViewSidebarItem.scss';

type TwoColumnViewSidebarItemProps = {
  prefix: string,
  routeDef: RouteDef,
  colorCodedState?: ColorCodedState,
}

const TwoColumnViewSidebarItem: React.FC<TwoColumnViewSidebarItemProps> = props => {

  const url = (partial: string) => makeUrl(props.prefix, partial);

  return <Observer children={() => (
    <BaseMenuItem
      className="TwoColumnViewSidebarItem"
      icon={props.routeDef.icon}
      to={url(props.routeDef.urlFactory())}
      children={props.routeDef.composeTitle()}
      primaryColorName={props.routeDef.primaryColorName}
      colorCodedState={props.colorCodedState}
    />
  )} />
}

export default TwoColumnViewSidebarItem;