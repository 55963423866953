import { CountryCode } from "../base/constants/countries.constants";

export const getPostCodeFieldLabel = (countryId: CountryCode) => {
  switch (countryId) {
    case 'IE':
      return "Eircode";
    case 'US':
      return "Zipcode";
    default: 
      return "Postcode";
  }
}

export const getRegionFieldLabel = (countryId: CountryCode) => {
  switch (countryId) {
    case 'IE':
    case 'GB':
      return 'County';
    case 'DE':
    case 'US':
      return 'State';
    default: 
      return 'Region';
  }
}
