import { ActionConfig } from "../../controllers/ui/ui.controller.types";
import { IconName } from "../components/Symbols/iconDefs";
import { NoOp } from "./functions.utils";
import { toCamelCase } from "./string.utils";

export function makeActionConfig<T extends Function = (...arg: any) => unknown>(
  label: string,
  action: T,
  options: Partial<ActionConfig<T>> = {},
): ActionConfig<T> {
  options.name = options.name ?? toCamelCase(label);
  options.label = label;
  options.action = action;
  return options as ActionConfig<T>;
}

export function makeTableActionConfig<T extends Function = (...arg: any) => unknown>(
  label: string,
  action: T,
  options?: {
    name?: string,
    icon?: IconName,
    color?: string,
  },
): ActionConfig<T> {
  return makeActionConfig(label, action, { ...options })
}

export const makeCancelAction = (label: string = 'Cancel') => makeActionConfig(label, NoOp, { buttonClass: 'subtle' });