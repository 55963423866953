import { action, flow, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { editUserInOverlay } from '../../../../actions/editUser.action';
import { ColorCodedState } from '../../../../base/@types';
import AppPage from '../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../base/components/AppPageHeader/AppPageHeader';
import BaseButton from '../../../../base/components/BaseButton/BaseButton';
import BaseSpacer from '../../../../base/components/BaseSpacer/BaseSpacer';
import { BaseTableColumnConfig } from '../../../../base/components/BaseTable/BaseTable';
import ColorTagArchived from '../../../../base/components/ColorTag/ColorTagArchived';
import ColorTagDeleted from '../../../../base/components/ColorTag/ColorTagDeleted';
import ColorTagYoungPeople from '../../../../base/components/ColorTag/ColorTagYoungPeople';
import DateRenderer from '../../../../base/components/DateRenderer/DateRenderer';
import IndexDirectory from '../../../../base/components/IndexDirectory/IndexDirectory';
import { IndexDirectoryDataFetcher } from '../../../../base/components/IndexDirectory/indexDirectory.types';
import IndexDirectoryState from '../../../../base/components/IndexDirectory/IndexDirectoryState';
import InfoBanner from '../../../../base/components/InfoBanner/InfoBanner';
import LoadingIndicatorSection from '../../../../base/components/LoadingIndicatorSection/LoadingIndicatorSection';
import OverlayCloseButton from '../../../../base/components/OverlayCloseButton/OverlayCloseButton';
import ShadedBlock from '../../../../base/components/ShadedBlock/ShadedBlock';
import UIBlock from '../../../../base/components/UIBlock/UIBlock';
import { CounsellingApplicationEndpoints } from '../../../../base/endpoints/counsellingApplication.endpoints';
import { SupportGroupEndpointParams, SupportGroupEndpoints } from '../../../../base/endpoints/supportGroup.endpoints';
import { useOnMount } from '../../../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import { makeForm } from '../../../../base/mediators/form.mediator';
import { makeActionConfig } from '../../../../base/utils/actionConfig.utils';
import { makeLaravelIndexDataFetcher } from '../../../../base/utils/api.utils';
import { keepTruthy } from '../../../../base/utils/array.utils';
import { navigateToChatPage } from '../../../../base/utils/chat.utils';
import joinClassName from '../../../../base/utils/className.utils';
import { getColorHexByName } from '../../../../base/utils/colors.utils';
import { makeDisposerController } from '../../../../base/utils/disposer.utils';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import { uniq } from '../../../../base/utils/ramdaEquivalents.utils';
import { autoPluralize } from '../../../../base/utils/string.utils';
import { isArray } from '../../../../base/utils/typeChecks.utils';
import { setUrlParam, useSyncUrlParams } from '../../../../base/utils/urlParams.utils';
import CommunicationTypeRenderer from '../../../../components/CommunicationTypeRenderer/CommunicationTypeRenderer';
import CounsellingApplicationStatusColorTag from '../../../../components/CounsellingApplicationStatusColorTag/CounsellingApplicationStatusColorTag';
import CounsellingSessionsIndex from '../../../../components/CounsellingSessionsIndex/CounsellingSessionsIndex';
import ThoughtFeed from '../../../../components/ThoughtFeed/ThoughtFeed';
import UserComputedRoleList from '../../../../components/UserComputedRoleList/UserComputedRoleList';
import UserInfoTable from '../../../../components/UserInfoTable/UserInfoTable';
import UsernameRenderer from '../../../../components/UsernameRenderer/UsernameRenderer';
import { ModelName } from '../../../../constants/modelNames.enum';
import { ActionConfig } from '../../../../controllers/ui/ui.controller.types';
import { CounsellingApplication } from '../../../../models/makeCounsellingApplication.model';
import { SupportGroup } from '../../../../models/makeSupportGroup.model';
import { makeUserSnapshotBase, User } from '../../../../models/makeUser.model';
import { useGetUser } from '../../../../requests/useGetUser.request';
import { getAgeFromDateOfBirth, isAdultAge, isYoungPeopleAge, isYoungPeopleAge1214, isYoungPeopleAge1517 } from '../../../../utils/ageAndDateOfBirth.utils';
import { checkIfStringContainsWord_turn2me } from '../../../../utils/user.utils';
import { counsellingApplicationTypeCellRenderer } from '../../_configs/counsellingApplicationTableColumnConfigsForAdmin.config';
import { counsellingSessionTableColumnConfigsForAdmin } from '../../_configs/counsellingSessionTableColumnConfigsForAdmin.config';
import CounsellorStatSection from '../CounsellorStatSection/CounsellorStatSection';
import OverlayUserFeesManager from '../OverlayUserFeesManager/OverlayUserFeesManager';
import UserDeletionManager from '../UserDeletionManager/UserDeletionManager';
import UserMFAManager from '../UserMFAManager/UserMFAManager';
import UserPermissionManager from '../UserPermissionManager/UserPermissionManager';
import UserSuspensionManager from '../UserSuspensionManager/UserSuspensionManager';
import './OverlayUserManager.scss';

type OverlayUserManagerProps = {
  user?: User,
  userId?: string,
}

const OverlayUserManager: React.FC<OverlayUserManagerProps> = props => {

  const p = useProps(props);

  const { API, UI, AUTH, MESSENGER, NAVIGATOR, VOIP } = useControllers();

  const s = useStore(() => ({
    _user: null as User | null,
    get userId() {
      return p.user?.id ?? p.userId
    },
    get username() {
      return s._user?.username;
    },
    get hasLoaded() {
      return Boolean(s.username);
    },
    get title() {
      const title = s.username || 'User Details';
      if (s.userIsCurrentUser) return title + ' (You)';
      if (s._user?.timeSuspended) return title + ' (Account Suspended)';
      return title;
    },
    get afterTitle() {
      return s._user ? <>
        <div>
          {s.userId && <><code>ID: {s.userId}</code> • </>}
          <>Age: {s._user.dateOfBirth ? getAgeFromDateOfBirth(s._user.dateOfBirth) : 'Unknown'} • </>
          {s.userIsYoungPerson && <><ColorTagYoungPeople /> • </>}
          {s._user.timeDeleted && <><ColorTagDeleted /> • </>}
          {s._user.timeCreated && <>Member since <DateRenderer value={s._user.timeCreated} /></>}
          {s._user.roleNames.length > 0 && <> • <UserComputedRoleList user={s._user} /></>}
        </div>
      </> : undefined
    },
    get userAge() {
      return s._user?.dateOfBirth ? getAgeFromDateOfBirth(s._user?.dateOfBirth) : null;
    },
    get userIsYoungPerson() {
      return isYoungPeopleAge(s.userAge)
    },
    get userIsCounsellor() {
      return s._user?.isCounsellor;
    },
    get userIsCurrentUser() {
      return AUTH.currentUser && AUTH.currentUser.id === s.userId;
    },
    applicationIndexDirectoryState: new IndexDirectoryState({
      name: 'admin-user-single-application-index',
      sortByKeyName: 'timeCreated',
      sortDirection: 'desc',
      viewMode: 'table',
      searchable: false,
    }),

    applications: [] as CounsellingApplication[],

    get applicationsIndexParams() {
      return {
        perPage: -1,
        include: ['counsellor', 'invitation', 'clients'],
        page: 1,
        sort: '-timeCreated',
        filter: {
          applicantId: s.userId,
          trashed: "with",
        } as object,
      }
    },

    applicationsDataFetcher: null as IndexDirectoryDataFetcher<CounsellingApplication> | null,

    get approvedApplications() {
      return s.applications.filter(a => !!a.timeApproved);
    },

    get ownApplications() {
      return s._user?.id ? s.approvedApplications.filter(a => a.applicantId === s._user!.id) : [];
    },
    get ownYoungPeopleApplications() {
      return s.ownApplications.filter(a => a.type?.includes('young-people') && !!a.invitation)
    },
    get ownApplicationsAppliedAsParent() {
      return s.ownYoungPeopleApplications.filter(a => isAdultAge(getAgeFromDateOfBirth(a.applicant?.dateOfBirth)))
    },
    get ownApplicationsAppliedAsYoungPeople() {
      return s.ownYoungPeopleApplications.filter(a => isYoungPeopleAge(getAgeFromDateOfBirth(a.applicant?.dateOfBirth)))
    },
    /* ! */ get childrenFromInvitees() {
      return s.ownApplicationsAppliedAsParent
        .map(a => a.invitation)
        .filter(i => i!.actingAs === 'dependent-child');
    },
    /* ! */ get parentsFromInvitees() {
      return s.ownApplicationsAppliedAsYoungPeople
        .map(a => a.invitation)
        .filter(i => i!.actingAs === 'legal-guardian');
    },
    /* ! */ get partnersFromInvitees() {
      return s.ownApplications
        .filter(a => a.type === 'couples' && !!a.invitation)
        .map(a => a.invitation)
        .filter(i => i!.actingAs === 'partner');
    },

    get invitedApplications() {
      return s._user?.id ? s.applications.filter(a => a.invitation?.userId && a.invitation?.userId === s._user?.id) : [];
    },
    get invitedYoungPeopleApplications() {
      return s.invitedApplications.filter(a => a.type?.includes('young-people'))
    },
    get applicationsWhereInvitedByYoungPeople() {
      return s.invitedYoungPeopleApplications.filter(a => isYoungPeopleAge(getAgeFromDateOfBirth(a.applicant?.dateOfBirth)));
    },
    get applicationsWhereInvitedAsYoungPeople() {
      return s.invitedYoungPeopleApplications.filter(a => a.invitation?.user?.dateOfBirth && isYoungPeopleAge(getAgeFromDateOfBirth(a.invitation.user?.dateOfBirth)));
    },
    /* ! */ get childrenFromApplicants() {
      return s.applicationsWhereInvitedByYoungPeople
    },
    /* ! */ get parentsFromApplicants() {
      return s.applicationsWhereInvitedAsYoungPeople
    },
    /* ! */ get partnersFromApplicants() {
      return s.invitedApplications.filter(a => a.type === 'couples')
    },

    get childrenIds() {
      return keepTruthy(uniq([
        ...s.childrenFromApplicants.map(a => a.applicantId),
        ...s.childrenFromInvitees.map(i => i?.userId)
      ]));
    },
    get parentIds() {
      return keepTruthy(uniq([
        ...s.parentsFromApplicants.map(a => a.applicantId),
        ...s.parentsFromInvitees.map(i => i?.userId)
      ]));
    },
    get partnerIds() {
      return keepTruthy(uniq([
        ...s.partnersFromApplicants.map(a => a.applicantId),
        ...s.partnersFromInvitees.map(i => i?.userId)
      ]));
    },

    supportGroupIndexDirectoryState: new IndexDirectoryState({
      name: 'admin-user-single-support-group-index',
      sortByKeyName: 'timeCreated',
      sortDirection: 'desc',
      viewMode: 'table',
      searchable: false,
    }),

    get supportGroupsDataFetcher() {
      return s.username ? makeLaravelIndexDataFetcher<SupportGroup, SupportGroupEndpointParams>(
        API,
        ModelName.supportGroups,
        SupportGroupEndpoints.staff.index,
        { perPage: 15, include: ['counsellor'] },
        {
          onSuccess: d => console.log(d)
        }
      ) : void 0;
    },
    form: makeForm(makeUserSnapshotBase(), {
      validators: {},
      editableFields: ['username'],
    }),
    get wasCounsellor() {
      return s._user?._wasCounsellor;
    },
    get isAdminOrCounsellor() {
      return AUTH.isAdmin || AUTH.isCounsellor;
    },
    get canEditUser() {
      if (s._user?.isStaff) {
        return (s.isAdminOrCounsellor || AUTH.isModerator || (AUTH.can.manage_.staff || AUTH.can.manage_.clients_.updatePersonalInfo)) && !s._user?.timeDeleted;
      }
      return (s.isAdminOrCounsellor || AUTH.isModerator || AUTH.can.manage_.clients_.updatePersonalInfo) && !s._user?.timeDeleted;
    },
    get usernameIncludesWord_turn2me() {
      return checkIfStringContainsWord_turn2me(s._user?.username);
    },
  }));

  useGetUser({
    observable: s, key: '_user',
    onDataFetch: u => s.form.reset(u),
  }, s.userId, {
    include: [
      'addresses', 'emergencyContacts',
    ],
    append: '_wasCounsellor',
  });

  useSyncUrlParams('userId', s.userId);

  const applicationTableColumnConfigs: BaseTableColumnConfig<CounsellingApplication>[] = [
    { label: 'ID', keyName: 'id', sortable: true, searchable: true, },
    {
      label: 'Applicant', keyName: 'applicantId', sortable: true, searchable: 'applicant.username',
      BodyCell: d => <UsernameRenderer showColorLabel userId={d.applicantId} />
    },
    { label: 'Applied on', keyName: 'timeCreated', sortable: true, searchable: false, },
    {
      label: 'Type', keyName: 'type', searchable: true,
      BodyCell: counsellingApplicationTypeCellRenderer,
    },
    {
      label: 'Counsellor', keyName: 'counsellorId', sortable: true, searchable: 'counsellor.username',
      BodyCell: d => d.counsellorId ? <UsernameRenderer showColorLabel user={d.counsellor} userId={d.counsellorId} /> : '',
    },
    {
      label: 'Invitee', keyName: 'inviteeId', sortable: true, searchable: false,
      BodyCell: d => d.invitation ? <UsernameRenderer showColorLabel userId={d.invitation.userId} /> : undefined
    },
    {
      label: 'Status',
      BodyCell: d => d.timeArchived ? <ColorTagArchived /> : <CounsellingApplicationStatusColorTag application={d} />,
    },
    {
      label: 'Communication Preference', keyName: 'communicationTypePreference', searchable: true,
      BodyCell: d => <CommunicationTypeRenderer value={d.communicationTypePreference} />
    }
  ]

  const goToApplicationPage = (d?: CounsellingApplication) => {
    setUrlParam('manageApplicationId', d?.id, NAVIGATOR);
  }

  const applicationTableColumnActions: ActionConfig[] = [makeActionConfig('View', goToApplicationPage)]

  const showEditorOverlay = () => {
    if (!s._user) return;
    editUserInOverlay(UI, s._user);
  }

  const startInstantChat = flow(function * () {
    if (!s.userId) return;
    const existingChat = MESSENGER.activeChatsSortedByLatest.find(c => c.type === 'default' && c.participants.length === 2 && c.participants.find(p => p.userId === s.userId) && c.participants.find(p => p.userId === AUTH.currentUser?.id));
    if (existingChat) {
      const confirm = yield UI.DIALOG.present({
        heading: <>You already have an active instant chat session with user <UsernameRenderer user={p.user} userId={s.userId} />.</>,
        body: 'Do you want to open the chat window?',
      })
      if (!confirm) return;
      navigateToChatPage(NAVIGATOR, existingChat.id);
    } else {
      const confirm = yield UI.DIALOG.present({
        heading: <>You will start an instant chat session with user <UsernameRenderer user={p.user} userId={s.userId} /> immediately.</>
      })
      if (!confirm) return;
      const newChat = yield MESSENGER.createNewInstantChat([s.userId]);
      if (/(admin|app)\/chats/.test(NAVIGATOR.currentLocationPathname)) {
        NAVIGATOR.navigateTo(`/${NAVIGATOR.isInAdminArea ? 'admin' : 'app'}/chats/` + newChat.id);
      }
    }
    UI.OVERLAY.dismiss();
  })

  const callViaVOIP = () => {
    if (s._user?.mobileNumber) VOIP.startVoipCall(s._user.mobileNumber, s._user);
  }

  const manageUserFees = () => {
    if (!s._user) return;
    UI.OVERLAY.present({
      component: <OverlayUserFeesManager user={s._user} />,
      width: '75rem',
    })
  }

  useOnMount(() => {
    const d = makeDisposerController();
    d.add(
      reaction(
        () => s.userId,
        () => {
          s.applicationsDataFetcher = s.userId ? makeLaravelIndexDataFetcher<CounsellingApplication>(
            API,
            ModelName.counsellingApplications,
            CounsellingApplicationEndpoints.staff.index,
            s.applicationsIndexParams, {
            onSuccess: action((d?: CounsellingApplication[]) => {
              if (d && isArray(d)) s.applications.push(...d);
            })
          }
          ) : null;
        },
        { fireImmediately: true }
      )
    );
    return d.disposer;
  })

  return <Observer children={() => (
    <AppPage className={
      joinClassName(
        'OverlayUserManager',
        s.userIsYoungPerson && 'young-people',
        isYoungPeopleAge1214(s.userAge) && 'young-people-12-14',
        isYoungPeopleAge1517(s.userAge) && 'young-people-15-17',
        s._user?.timeSuspended && 'suspended',
        s._user?.timeDeleted && 'deleted',
      )
    }>
      <AppPageHeader
        beforeTitle="User Details"
        title={s.title}
        afterTitle={s.afterTitle}
        endSlot={<OverlayCloseButton />}
      />
      <AppPageContent>

        <div className="OverlayUserManagerInner">

          <section className="OverlayUserManagerMainColumn">

            { !s.hasLoaded && <UIBlock><LoadingIndicatorSection /></UIBlock> }

            {
              s._user && !s._user.isStaff && s.usernameIncludesWord_turn2me && <UIBlock>
                <InfoBanner colorCodedState={ColorCodedState.alert} icon="warning">
                  Non-staff user's username should not include the word "turn2me".
                </InfoBanner>
              </UIBlock>
            }

            { s.hasLoaded && s._user && <>
              { s._user.timeDeleted && <UIBlock>
                <BaseSpacer size=".5em"/>
                <InfoBanner colorCodedState={ColorCodedState.alert} icon="warning">
                  <p><em>This user was deleted on <DateRenderer value={s._user.timeDeleted} />.{!AUTH.isAdmin && " Only the basic information of a deleted account is visible."}</em></p>
                </InfoBanner>
              </UIBlock> }

              <UIBlock title="Basic Information"
                headerEndSlot={(s.canEditUser) ? () => <BaseButton size="xs" onClick={showEditorOverlay} color="green">Edit user</BaseButton> : undefined}
              >
                {s._user ? <UserInfoTable user={s._user} canManualVerify /> : 'Loading...'}
              </UIBlock>

              {(!s._user.timeDeleted || s.wasCounsellor || AUTH.isAdmin) && <>

                {
                  (AUTH.isAdmin || s.userIsCounsellor || s.wasCounsellor) && AUTH.can.accessStats_.counsellors && s._user && <CounsellorStatSection counsellor={s._user!} key={s._user!.id} />
                }

                {(AUTH.isAdmin || s.childrenIds.length > 0) && <UIBlock title="Children">
                  {s.childrenIds.map(i => <UserInfoTable shaded key={i} userId={i} showViewDetailsForAdmins canManualVerify />)}
                </UIBlock>
                }
                {(AUTH.isAdmin || s.parentIds.length > 0) && <UIBlock title={s.parentIds.length > 1 ? autoPluralize(s.parentIds.length, 'Legal Guardian') : 'Legal Guardian'}>
                  {s.parentIds.map(i => <UserInfoTable shaded key={i} userId={i} showViewDetailsForAdmins canManualVerify />)}
                </UIBlock>
                }
                {(AUTH.isAdmin || s.partnerIds.length > 0) && <UIBlock title={s.partnerIds.length > 1 ? autoPluralize(s.partnerIds.length, 'Partner') : 'Partner'}>
                  {s.partnerIds.map(i => <UserInfoTable shaded key={i} userId={i} showViewDetailsForAdmins canManualVerify />)}
                </UIBlock>
                }

                { (s.isAdminOrCounsellor || s.wasCounsellor) && <>

                  <UIBlock title="Counselling Applications">
                    <IndexDirectory<CounsellingApplication>
                      state={s.applicationIndexDirectoryState}
                      dataFetcher={s.applicationsDataFetcher!}
                      tablePropsGetter={() => ({
                        columnConfigs: applicationTableColumnConfigs,
                        itemActions: applicationTableColumnActions,
                      })}
                    />
                  </UIBlock>

                  {
                    s._user.id && <UIBlock title="All Counselling Sessions">
                      <CounsellingSessionsIndex
                        forClientId={s._user.id}
                        defaultMode="table"
                        columnConfigs={counsellingSessionTableColumnConfigsForAdmin}
                        showViewChatHistoryAction
                        allowSessionEditor
                      />
                    </UIBlock>
                  }

                  {
                    s._user.id && <UIBlock title="Thoughts">
                      <ThoughtFeed user={s._user} />
                      <BaseSpacer />
                    </UIBlock>
                  }

                </> }

              </> }

            </> }

          </section>

          <aside className="OverlayUserManagerAside">

            {
              s.hasLoaded && s._user && <>
                {
                  AUTH.can.chat_.startChatWith_.someUser && s._user.id !== AUTH.currentUser?.id && !s._user.timeDeleted && <UIBlock title="Instant Chat">
                    <ShadedBlock>
                      <BaseButton onClick={startInstantChat} fullWidth icon="chat-plus" dataCy="startInstantChat">Start Instant Chat</BaseButton>
                    </ShadedBlock>
                  </UIBlock>
                }
                {
                  AUTH.can.useVoip && s._user.mobileNumber && !s._user.timeDeleted && <UIBlock title="VOIP">
                    <ShadedBlock spaceChildren color="orange">
                      <p>The user's mobile number on the record is <strong>{s._user.mobileNumber}</strong>.</p>
                      <BaseButton onClick={callViaVOIP} fullWidth icon="phone">Call user with VOIP</BaseButton>
                    </ShadedBlock>
                  </UIBlock>
                }
                {
                  AUTH.can.manage_.permissions && <UIBlock title="Permissions">
                    <UserPermissionManager user={s._user} />
                  </UIBlock>
                }
                { AUTH.can.manage_.staffInvoicing && (s._user.isCounsellor || s._user.isFacilitator || s._user.isModerator) && <UIBlock title="Service Fees">
                  <ShadedBlock color={getColorHexByName('brightPurple')}>
                    <BaseButton onClick={manageUserFees} label={s._user.timeDeleted ? 'View Services Fees' : 'Manage Service Fees'} icon="euro" fullWidth color="brightPurple"/>
                  </ShadedBlock>
                </UIBlock> }
                {
                  (AUTH.can.manage_.clients_.deactivateClient ||
                    AUTH.can.manage_.clients_.deleteClient
                  ) && (!s._user.timeDeleted || s._user.timeSuspended) && (
                    <UIBlock title="Advanced">
                      {(AUTH.isAdmin && s._user.isStaff) && <>
                        <UserMFAManager
                          user={s._user}
                          disabled={!AUTH.isAdmin}
                        />
                        <BaseSpacer size="1em" />
                      </>}
                      {AUTH.can.manage_.clients_.deactivateClient && <UserSuspensionManager user={s._user} disabled={!s.canEditUser} />}
                      {
                        !s._user.timeDeleted && AUTH.can.manage_.clients_.deleteClient && <>
                          <BaseSpacer size="1em" />
                          <UserDeletionManager user={s._user} onSuccess={() => UI.OVERLAY.dismiss()} disabled={!s.canEditUser} />
                        </>
                      }
                    </UIBlock>
                  )
                }
                <BaseSpacer />

              </>
            }

          </aside>

        </div>


      </AppPageContent>

    </AppPage>
  )} />
}

export default OverlayUserManager;