import { User } from '@sentry/react';
import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import EmailLinkRenderer from '../../base/components/EmailLinkRenderer/EmailLinkRenderer';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import TelLinkRenderer from '../../base/components/TelLinkRenderer/TelLinkRenderer';
import { useControllers } from '../../base/hooks/useRootController.hook';
import joinClassName from '../../base/utils/className.utils';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { Contact } from '../../models/makeContact.model';
import { UserSnapshot } from '../../models/makeUser.model';
import { deleteEmergencyContact } from '../../requests/deleteEmergencyContact.request';
import { sendSaveUserRequest } from '../../requests/saveUser.request';
import OverlayContactEditor from '../OverlayContactEditor/OverlayContactEditor';
import './ContactItem.scss';

type ContactItemProps = {
  contact: Contact,
  deletable?: boolean,
  isPrimary?: boolean,
  layout?: 'landscape' | 'portrait',
  belongsToUser?: User,
  editable?: boolean,
}

const ContactItem: React.FC<ContactItemProps> = props => {
  const { AUTH, API, UI } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    get belongsUserId() {
      return p.belongsToUser?.id ?? AUTH.currentUser?.id
    },
    get isOwn() {
      return Boolean(AUTH.currentUser?.id && s.belongsUserId === AUTH.currentUser.id);
    },
    makePrimary: async () => await flow(function * () {
      if (p.isPrimary) return;
      const payload: Partial<UserSnapshot> = {
        id: s.belongsUserId,
        primaryEmergencyContactId: p.contact.id,
      }
      yield sendSaveUserRequest(API, payload, {
        isSelf: s.isOwn,
      });
      yield AUTH.fetchCurrentUserInfo();
    })(),
    edit: () => {
      UI.OVERLAY.present({
        component: <OverlayContactEditor contact={p.contact} />
      })
    },
    deleteEmergencyContact: async () => {
      if (!p.deletable) {
        UI.DIALOG.attention({
          heading: 'You cannot delete this emergency contact.',
          body: 'For regulatory reasons you must keep at least one emergency contact person information to be eligible for our clinical services.',
        })
        return;
      }
      if (p.isPrimary) {
        UI.DIALOG.attention({
          heading: 'You cannot delete your primary emergency contact.',
          body: 'Please select a different emergency contact as primary before deleting this emergency contact.',
        })
        return;
      }
      const confirm = await UI.DIALOG.attention({
        heading: 'Are you sure you want to delete this emergency contact?',
        defaultActions: ['negative', 'positive'],
      })
      if (!confirm) return;
      try {
        await deleteEmergencyContact(p.contact, API);
      } catch(e) {
        reportError(e);
        UI.DIALOG.error({
          heading: 'Failed to delete the emergencyContact',
          error: <ErrorRenderer error={e} />
        })
      }
    }
  }))
  return <Observer children={() => (
    <div className={joinClassName('ContactItem', p.isPrimary && 'primary', p.layout)}>
      <div className="ContactItemContent">
        <h3 data-cy="EmergencyContactName">{p.contact.name}</h3>
        <p>Phone: {p.contact.phone ? <TelLinkRenderer value={p.contact.phone} /> : '–'}</p>
        <p>Email: {p.contact.email ? <EmailLinkRenderer value={p.contact.email} /> : '–'}</p>
      </div>
      {p.editable && <div className="ContactItemControls">
        <BaseButton dataCy="edit-emergency-contact" appearance="tab" onClick={s.edit} icon="pencil">Edit</BaseButton>
        <BaseButton dataCy="make-primary-emergency-contact" appearance="tab" icon={p.isPrimary ? 'check-circle' : 'circle'} onClick={s.makePrimary} label={p.isPrimary ? 'Primary' : 'Make Primary'} colorCodedState={ColorCodedState.positive} iconVariant={p.isPrimary ? 'filled' : 'regular'}/>
        <BaseButton dataCy="delete-emergency-contact" appearance="tab" onClick={s.deleteEmergencyContact} colorCodedState={ColorCodedState.alert} icon="delete">Delete</BaseButton>
      </div>}
    </div>
  )} />
}

export default ContactItem;