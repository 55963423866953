import { FourLevelNumber, Nillable, Nullable, StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { isNil } from "../base/utils/ramdaEquivalents.utils";
import { ModelName } from "../constants/modelNames.enum";
import { assignable } from "../traits/assignable.trait";
import { AssignmentSupportGroupSatisfaction } from "./makeAssignment.model";
import { User } from "./makeUser.model";

export const makeSurveySupportGroupSatisfactionSnapshot = () => {
  return {
    ...assignable(),
    groupId: '' as string,
    ratingOfExperience: '' as FourLevelNumber,
    ratingSelfProgress: '' as Nullable<FourLevelNumber>,
    ratingOfOtherUsersRelationship: '' as FourLevelNumber,
    ratingOfWebsiteExperience: '' as FourLevelNumber,
    ratingOfOnlineVsOffline: '' as Nullable<FourLevelNumber>,
    body: '' as Nullable<string>,
    timeCreated: '' as Nillable<string>,
    timeUpdated: '' as Nillable<string>,
  }
}

export type SurveySupportGroupSatisfactionSnapshot = ReturnType<typeof makeSurveySupportGroupSatisfactionSnapshot>;
export type SurveySupportGroupSatisfactionRelatedModels = {
  assignment: AssignmentSupportGroupSatisfaction,
  assignedToUser: User,
  completedByUser: User,
}
export type SurveySupportGroupSatisfactionExtendedProperties = {};

export type SurveySupportGroupSatisfaction = StandardModel<SurveySupportGroupSatisfactionSnapshot>;

export const makeSurveySupportGroupSatisfaction = createStandardModelFactory<SurveySupportGroupSatisfaction, SurveySupportGroupSatisfactionRelatedModels>({
  name: ModelName.surveysSupportGroupSatisfaction,
  snapshotFactory: makeSurveySupportGroupSatisfactionSnapshot
})

export const calculateSupportGroupSatisfactionSurveyScore = (s: SurveySupportGroupSatisfactionSnapshot) => {
  let numberOfQuestionsAnswered = 5;
  if (isNil(s.ratingSelfProgress) || s.ratingSelfProgress === '') numberOfQuestionsAnswered--;
  if (isNil(s.ratingOfOnlineVsOffline) || s.ratingOfOnlineVsOffline === '') numberOfQuestionsAnswered--;
  return (
    (+s.ratingOfExperience) +
    (+(s.ratingSelfProgress ?? 0)) +
    (+s.ratingOfOtherUsersRelationship) +
    (+s.ratingOfWebsiteExperience) +
    (+(s.ratingOfOnlineVsOffline ?? 0))
  ) / numberOfQuestionsAnswered
}

export const calculateSupportGroupSatisfactionSurveyScoreExcludeOptional = (s: SurveySupportGroupSatisfactionSnapshot) => {
  // ratingOfOnlineVsOffline is optional, so exclude.
  let numberOfQuestionsAnswered = 4;
  if (isNil(s.ratingSelfProgress) || s.ratingSelfProgress === '') numberOfQuestionsAnswered--;
  return (
    (+s.ratingOfExperience) +
    (+(s.ratingSelfProgress ?? 0)) +
    (+s.ratingOfOtherUsersRelationship) +
    (+s.ratingOfWebsiteExperience)
  ) / numberOfQuestionsAnswered
}