import { XYChart } from '@amcharts/amcharts4/charts';
import { reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { useOnMount } from '../../../../base/hooks/lifecycle.hooks';
import { debounce } from '../../../../base/utils/debounce.utils';
import { makeDisposerController } from '../../../../base/utils/disposer.utils';
import { reportError } from '../../../../base/utils/errors.utils';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import { ApiModelName } from '../../../../constants/ApiModels.enum';
import { Assignment } from '../../../../models/makeAssignment.model';
import { CounsellingApplicationSnapshot } from '../../../../models/makeCounsellingApplication.model';
import { CounsellingSession, CounsellingSessionSnapshot } from '../../../../models/makeCounsellingSession.model';
import { calculateGAD7SurveyScore, SurveyGAD7 } from '../../../../models/makeSurveyGAD7.model';
import { calculatePHQ9SurveyScore, SurveyPHQ9 } from '../../../../models/makeSurveyPHQ9.model';
import { renderMultipleValueAxisLineGraph } from '../../AdminStatistics/_components/chartRenderers/multipleValueAxisLineGraphRenderer';
import './CounsellingClientProgressGraph.scss';

interface CounsellingClientProgressGraphProps {
  application: CounsellingApplicationSnapshot,
  sessions: CounsellingSessionSnapshot[],
}

const CounsellingClientProgressGraph: React.FC<CounsellingClientProgressGraphProps> = props => {

  const ref = useRef<HTMLDivElement>(null);

  const p = useProps(props);

  const s = useStore(() => ({
    get sessions() {
      return p.sessions
    },
    get assignments() {
      return s.sessions.map(ses => (ses as CounsellingSession).assignments).flat() as Assignment[];
    },
    get dataset() {
      return s.assignments.filter(a => a.timeCompleted).map(ass => {
        const session = s.sessions.find(ses => (ses as CounsellingSession).assignments?.includes(ass));
        const point: {
          date: string,
          assignment?: Assignment,
          GAD7?: number | null,
          PHQ9?: number | null,
        } = {
          date: ((session ? (session.timeStarted || session.timeScheduled) : ass.timeCompleted) ?? ass.timeCompleted)!,
          assignment: ass,
        }
        switch (ass.targetType) {
          case ApiModelName.SURVEY_GAD7: {
            point.GAD7 = ass.target ? calculateGAD7SurveyScore(ass.target as SurveyGAD7) : null;
            break;
          }
          case ApiModelName.SURVEY_PHQ9: {
            point.PHQ9 = ass.target ? calculatePHQ9SurveyScore(ass.target as SurveyPHQ9) : null;
            break;
          }
        }
        return point;
      })
    }
  }));

  const chartRenderer = () => renderMultipleValueAxisLineGraph({
    ref,
    data: s.dataset,
    x: 'date',
    series: [
      {
        field: 'GAD7',
        label: 'GAD7 (Anxiety)',
        steps: [
          {value: 0, label: 'None(0)'},
          {value: 5, label: 'Mild(5)'},
          {value: 10, label: 'Moderate(10)'},
          {value: 15, label: 'Mod.Severe(15)'},
          {value: 20, label: 'Severe(20)'},
          {value: 27, label: 'Max(27)'},
        ],
        min: 0,
        max: 30,
      },
      {
        field: 'PHQ9',
        label: 'PHQ9 (Depression)',
        steps: [
          {value: 0, label: 'None(0)'},
          {value: 5, label: 'Mild(5)'},
          {value: 10, label: 'Moderate(10)'},
          {value: 15, label: 'Severe(15)'},
          {value: 21, label: 'Max(21)'},
        ],
        min: 0,
        max: 30,
        renderOnOppositeSide: true
      }
    ]
  });

  useOnMount(() => {
    const d = makeDisposerController();
    let chart: XYChart | undefined;
    const disposeChart = () => {
      try {
        if (chart) {
          chart.dispose();
        }
      } catch (e) {
        reportError(e);
      }
    }
    d.add(reaction(
      () => s.dataset,
      debounce(async () => {
        disposeChart();
        chart = await chartRenderer();
      }, { id: `CounsellingClientProgressGraph_chartRenderer` }),
      { fireImmediately: true }
    ));
    return () => {
      disposeChart();
      d.disposer();
    };
  });

  return <Observer children={() => (
    <div className="CounsellingClientProgressGraph">
      <div className="CounsellingClientProgressGraphContainer" ref={ref} />
    </div>
  )} />

}

export default CounsellingClientProgressGraph;