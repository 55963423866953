import { Observer } from "mobx-react-lite";
import React from "react";
import { Renderable } from "../../@types";
import { ContextColor } from "../../constants/color.enum";
import joinClassName from "../../utils/className.utils";
import { useProps } from "../../utils/mobx.utils";
import './BaseHeading.scss';

export interface BaseHeadingProps {
  level?: 1 | 2 | 3 | 4 | 5 | 6,
  className?: string,
  padded?: boolean | 'all',
  heading?: Renderable,
  color?: string | ContextColor,
}
const BaseHeading: React.FC<BaseHeadingProps> = props => {
  const { children, ...rest } = props;
  const p = useProps(rest);
  return <Observer children={() => {
    const HeadingTag = p.level ? `h${p.level}` : 'strong';
    return (
      React.createElement(
        HeadingTag,
        {
          className: joinClassName(
            'BaseHeading',
            p.className
          ),
          children: p.heading || props.children,
          ...p.color && {
            'data-primary-color': p.color === ContextColor.Primary ? '' : p.color
          }
        },
      )
    )
  }} />
}

export default BaseHeading