import { when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import AppPage from '../../base/components/AppPage/AppPage';
import AppPageContent from '../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../base/components/AppPageHeader/AppPageHeader';
import OverlayCloseButton from '../../base/components/OverlayCloseButton/OverlayCloseButton';
import UIBlock from '../../base/components/UIBlock/UIBlock';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { useSyncUrlParams } from '../../base/utils/urlParams.utils';
import { Company } from '../../models/makeCompany.model';
import { useGetCompany } from '../../requests/useGetCompany.request';
import CompanyEditor from '../CompanyEditor/CompanyEditor';
import './OverlayCompanyEditor.scss';

type OverlayCompanyEditorProps = {
  company?: Company,
  companyId?: string,
}

const OverlayCompanyEditor: React.FC<OverlayCompanyEditorProps> = props => {

  const { UI, AUTH, STAFF } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    _company: props.company,
    get company() {
      return s._company;
    },
    get companyId() {
      return s.company?.id ?? p.companyId;
    },
    get isNew() {
      return !s.companyId;
    },
    get currentUserCanManageCompanies() {
      return STAFF.canManageCompanies;
    },
    get canEdit() {
      return s.currentUserCanManageCompanies;
    }
  }))

  useGetCompany({
    observable: s,
    key: '_company',
    onError: e => {
      UI.DIALOG.error({
        heading: 'Unable to retrieve the company information. Please try again later.',
        error: e,
      });
      UI.OVERLAY.dismiss(`OverlayCompanyEditor#${s.companyId}`);
    },
  }, s.companyId);

  useSyncUrlParams('editCompanyId', s.companyId);

  useOnMount(() => {
    return when(
      () => AUTH.isAuthenticated && !s.canEdit,
      () => {
        UI.DIALOG.attention({
          heading: 'You do not seem to have the permission to edit company information.',
          body: 'If you think this is an error, please contact system administrator to update your account permissions.',
        })
        UI.OVERLAY.dismiss(`OverlayCompanyEditor#${s.companyId}`);
      }
    )
  })

  return <Observer children={() => (
    <AppPage className="OverlayCompanyEditor">
      <AppPageHeader
        title={s.isNew ? 'Create New Company' : 'Edit Company Information'}
        endSlot={<OverlayCloseButton />}
      />
      <AppPageContent>
        <UIBlock padded spaceChildren>
          <CompanyEditor
            company={s.company}
          />
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
}

export default OverlayCompanyEditor;