import { observable } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import AppPage from '../../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../../base/components/AppPageHeader/AppPageHeader';
import BackButton from '../../../../../base/components/BackButton/BackButton';
import BaseArticle from '../../../../../base/components/BaseArticle/BaseArticle';
import BaseButton from '../../../../../base/components/BaseButton/BaseButton';
import BaseInput from '../../../../../base/components/BaseInput/BaseInput';
import BaseSeparator from '../../../../../base/components/BaseSeparator/BaseSeparator';
import BaseSpacer from '../../../../../base/components/BaseSpacer/BaseSpacer';
import ShadedBlock from '../../../../../base/components/ShadedBlock/ShadedBlock';
import UIBlock from '../../../../../base/components/UIBlock/UIBlock';
import { AuthEndpoints } from '../../../../../base/endpoints/auth.endpoints';
import { useControllers } from '../../../../../base/hooks/useRootController.hook';
import { makeCancelAction } from '../../../../../base/utils/actionConfig.utils';
import { reportError } from '../../../../../base/utils/errors.utils';
import { isInCypressTestMode, SHOULD_LOG, WP_URL } from '../../../../../env';
import './AccountPageDeleteAccount.scss';

type AccountPageDeleteAccountProps = {}

const AccountPageDeleteAccount: React.FC<AccountPageDeleteAccountProps> = props => {

  const { API, UI, AUTH } = useControllers();
  
  const requestAccountDeletion = () => {
    const dialogName = 'delete-account-confirmation-dialog';
    const form = observable({
      password: '',
    })
    const deleteAccount = async () => {
      const url = AuthEndpoints.currentUser.verifyPassword();
      try {
        await API.postRaw(url, { password: form.password });
        const deletionUrl = AuthEndpoints.currentUser.requestAccountDeletion();
        try {
          await API.postRaw(deletionUrl);
          UI.DIALOG.dismiss('delete-account-confirmation-dialog');
          await UI.DIALOG.success({
            heading: 'Your account deletion request has been submitted.',
            body: `Thanks for using the turn2me service. We hope our services have been helpful, and we wish you all the best going forward. Good bye, ${AUTH.currentUser?.username}!`
          })
          SHOULD_LOG() && console.log('logging out, reason: account deletion requested');
          AUTH.logout({ redirectTo: isInCypressTestMode ? '/auth/login' : WP_URL });
        } catch (e) {
          reportError(e);
          UI.DIALOG.error({
            heading: 'Hmm, something unexpected happened...',
            body: <div>
              <p>We weren't able to submit your request. Please try again later. If the issue keeps happening, please contact us for help.</p>
            </div>
          })
        }
      } catch (e) {
        reportError(e);
        UI.DIALOG.error({
          heading: 'Unable to verify Password',
          error: e,
        })
      }
    }
    const handleEnter = () => {
      UI.DIALOG.dismiss(dialogName);
      deleteAccount();
    }
    UI.DIALOG.attention({
      name: dialogName,
      heading: 'Are you sure you want to delete your account?',
      body: <BaseArticle>
        <p>Please enter your password to continue:</p>
        <BaseInput form={form} field="password" type="password" placeholder="Enter your password..." autoFocus onEnter={handleEnter}/>
        <p><em>* If you do not remember your password, please reset it before continuing or contact us for help.</em></p>
      </BaseArticle>,
      actions: [
        makeCancelAction(),
        {
          label: 'Confirm & Submit',
          action: deleteAccount
        }
      ]
    })
  }

  return <Observer children={() => (
    <AppPage className="AccountPageDeleteAccount"
      accommodateTitleBarSpace
    >
      <AppPageHeader
        title="Delete Account"
        startSlot={<BackButton destination="up" />}
      />
      <AppPageContent>
        <UIBlock padded>
          <ShadedBlock color='red' spaceChildren>
            <BaseArticle>
              <h3>What happens when you delete your turn2me account</h3>
              <p>As per GDPR regulations, you can request a deletion of your turn2me account. Here is what happens when you decide to proceed:</p>
              <ul>
                <li>Your account will immediately be inaccessible.</li>
                <li>Any of your ongoing counselling services will terminate immediately.</li>
                <li>The personal information stored in your account will be removed from our database within a month.</li>
                <li>Your clinical information will remain in our system for upto 8 years. However, those information will be anonymised.</li>
              </ul>
              <p>By proceeding to submit your account deletion request, you agree that you have read and understood above information.</p>
            </BaseArticle>
            <BaseSpacer size=".5em" />
            <BaseSeparator />
            <BaseSpacer size=".5em" />
            <BaseButton dataCy="requestAccountDeletion" onClick={requestAccountDeletion}>Request Account Deletion</BaseButton>
          </ShadedBlock>
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
}

export default AccountPageDeleteAccount;