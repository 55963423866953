import { action, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Nillable } from '../../base/@types';
import BaseCheckbox from '../../base/components/BaseCheckbox/BaseCheckbox';
import BaseLabel from '../../base/components/BaseLabel/BaseLabel';
import BaseSpacer from '../../base/components/BaseSpacer/BaseSpacer';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { Address } from '../../models/makeAddress.model';
import { User } from '../../models/makeUser.model';
import AddressItem from '../AddressItem/AddressItem';
import UserAddressManager from '../UserAddressManager/UserAddressManager';
import './BillingAddressForm.scss';

type BillingAddressFormProps = {
  user: Nillable<User>,
  onChange: (address: Address) => void,
}

const BillingAddressForm: React.FC<BillingAddressFormProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    form: {
      useAccountBillingAddress: true,
    },
    get isPrimaryAddress() {
      return !!(p.user?.primaryAddress) ?? false;
    },
    get userAddressIfAny() {
      return p.user?.addressIfAny;
    },
    get billingAddress() {
      return s.form.useAccountBillingAddress ? s.userAddressIfAny : s.selectedAddress;
    },
    selectedAddress: null as Address | null,
    selectAddress: action((pm: Address | null) => {
      s.selectedAddress = pm;
    }),
  }));
  useOnMount(() => {
    return reaction(
      () => s.billingAddress,
      () => {
        p.onChange(s.billingAddress!);
      },
      { fireImmediately: true }
    )
  })
  return <Observer children={() => (
    <div className="BillingAddressForm">
      <BaseLabel required>Billing Address</BaseLabel>
      {s.userAddressIfAny && <BaseCheckbox form={s.form} field="useAccountBillingAddress">Use account address as billing address</BaseCheckbox>}
      <BaseSpacer size="xs" />
      {s.userAddressIfAny && s.form.useAccountBillingAddress
        ? <AddressItem address={s.userAddressIfAny} layout="landscape" isPrimary={s.isPrimaryAddress} isSelected />
        : <UserAddressManager user={p.user} onSelect={s.selectAddress} selected={s.selectedAddress} hidePrimaryButton />
      }
    </div>
  )} />
}

export default BillingAddressForm;