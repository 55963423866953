import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Nullable } from '../../base/@types';
import BaseArticle from '../../base/components/BaseArticle/BaseArticle';
import BaseInput from '../../base/components/BaseInput/BaseInput';
import BaseLabel from '../../base/components/BaseLabel/BaseLabel';
import BaseRadioGroup from '../../base/components/BaseRadioGroup/BaseRadioGroup';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { additionalReminderRadioOptions, reasonRadioOptions, wouldHelpRadioOptions } from '../../constants/survey.constants';
import { SurveySupportGroupNonAttendance } from '../../models/makeSurveySupportGroupNonAttendance';
import './SurveyEditorSupportGroupNonAttendance.scss';

type SurveyEditorSupportGroupNonAttendanceProps = {
  survey: SurveySupportGroupNonAttendance,
  _localForm: {
    otherReasonInput: Nullable<string>,
  },
  readonly?: boolean,
}

const SurveyEditorSupportGroupNonAttendance: React.FC<SurveyEditorSupportGroupNonAttendanceProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get shouldShowOtherReasonInput() {
      return Boolean(p.survey.reasonForNonAttendance?.startsWith('Other'));
    },
  }))
  return <Observer children={() => (
    <BaseArticle className="SurveyEditorSupportGroupNonAttendance">
      <div>
        <BaseLabel>Can you confirm the reason you didn't attend the support group you registered for?</BaseLabel>
        <BaseRadioGroup form={p.survey} field="reasonForNonAttendance" options={reasonRadioOptions} readonly={p.readonly} />
      </div>
      {s.shouldShowOtherReasonInput && <div>
        {p.readonly
          ? <ShadedBlock>
            {p._localForm.otherReasonInput}
          </ShadedBlock>
          : <BaseInput type="textarea" form={p._localForm} field="otherReasonInput" placeholder="Other reason for not attending the support group..." rows="3" />
        }
      </div>}
      <div>
        <BaseLabel>Do you think another reminder from turn2me would have helped?</BaseLabel>
        <BaseRadioGroup form={p.survey} field="thinksMoreReminderWouldHelp" options={wouldHelpRadioOptions} readonly={p.readonly} />
      </div>
      <div>
        <BaseLabel>When do you think an additional reminder for the groups should be sent?</BaseLabel>
        <BaseRadioGroup form={p.survey} field="sendAdditionalReminderBefore" options={additionalReminderRadioOptions} readonly={p.readonly} />
      </div>
      {
        p.readonly ? <>
          <p><strong>Message</strong></p>
          <ShadedBlock>
            {(!Boolean(p.survey.body) || p.survey.body === '-') ? <em>The client didn't leave a message.</em> : p.survey.body}
          </ShadedBlock>
        </> : <>
          <p><strong>Leave a message: (Optional)</strong></p>
          <BaseInput type="textarea" form={p.survey} field="body" rows="5" />
        </>
      }
    </BaseArticle>
  )} />
}

export default SurveyEditorSupportGroupNonAttendance;