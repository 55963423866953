export const NUM_FREE_COUNSELLING_SESSIONS = 6;

export const CounsellingSessionPaidFee = {
  value: 50,
  name: 'Full',
}
const free = [
  {
    value: 0,
    name: 'Free',
  },
]
const withFees = [
  ...[CounsellingSessionPaidFee],
  {
    value: 20,
    name: 'Subsidised',
  },
]

export const CounsellingSessionFeeTypes = [
  ...withFees,
  ...free,
]