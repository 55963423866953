import { FeeEndpoints } from "../base/endpoints/fees.endpoints";
import { getSnapshot } from "../base/utils/snapshot.utils";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { Fee, FeeType } from "../models/makeFee.model";

export const counsellingFeeTypes = [
  FeeType.counsellingSessionAccreditedCounsellor,
  FeeType.counsellingSessionPreAccreditedCounsellor,
  FeeType.counsellingSessionPlacementCounsellor,
]

export const feeTypeDisplayNameMap = Object.freeze({
  [FeeType.counsellingSession]: 'Counselling Session Online',
  [FeeType.counsellingSessionOneToOne]: 'Adult One-to-One Counselling Session Online',
  [FeeType.counsellingSessionCouples]: 'Couples Counselling Session Online',
  [FeeType.counsellingSessionYoungPeople]: 'Child & Adolescent Counselling Session Online',
  [FeeType.supportGroup]: 'Group Support Online',
  [FeeType.supportGroupAdult]: 'Group Support Online - Adults',
  [FeeType.supportGroupYoungPeople]: 'Group Support Online - Young People',
  [FeeType.supportGroupFrontLineWorkers]: 'Group Support Online - Front Line Workers',
  [FeeType.supportGroupSpecialistGroups]: 'Group Support Online - Specialist Groups',

  [FeeType.counsellingSessionAccreditedCounsellor]: 'Counselling Session Accredited Counsellor',
  [FeeType.counsellingSessionPreAccreditedCounsellor]: 'Counselling Session Pre Accredited Counsellor',
  [FeeType.counsellingSessionPlacementCounsellor]: 'Counselling Session Placement Counsellor',
} as const)

export const defaultFeeValues = Object.freeze({
  [FeeType.counsellingSession]: 35,
  [FeeType.counsellingSessionOneToOne]: 35,
  [FeeType.counsellingSessionCouples]: 45,
  [FeeType.counsellingSessionYoungPeople]: 45,
  [FeeType.supportGroup]: 35,
  [FeeType.supportGroupAdult]: 35,
  [FeeType.supportGroupYoungPeople]: 35,
  [FeeType.supportGroupFrontLineWorkers]: 35,
  [FeeType.supportGroupSpecialistGroups]: 35,

  [FeeType.counsellingSessionAccreditedCounsellor]: 35,
  [FeeType.counsellingSessionPreAccreditedCounsellor]: 30,
  [FeeType.counsellingSessionPlacementCounsellor]: 25,
} as const)

export type FeeValueMap = Record<FeeType, number>;

export const getFeeTypeDisplayName = (feeType: FeeType) => {
  return feeTypeDisplayNameMap[feeType];
}

export const getDefaultFeeValue = (feeType: FeeType) => defaultFeeValues[feeType];

export const saveFee = async (API: APIController, fee: Fee) => {
  const url = fee.id ? FeeEndpoints.staff.update(fee.id) : FeeEndpoints.staff.create();
  const payload = getSnapshot(fee);
  return fee.id ? await API.patch(url, ModelName.fees, payload) : await API.post(url, ModelName.fees, payload);
}
export const deleteFee = async (API: APIController, fee: Fee) => {
  const url = FeeEndpoints.staff.delete(fee.id);
  return await API.delete(url, ModelName.fees, fee);
}
