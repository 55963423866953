import { CounsellingSessionEndpoints } from "../base/endpoints/counsellingSession.endpoints";
import { ApiModelName } from "../constants/ApiModels.enum";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { CounsellingSession } from "../models/makeCounsellingSession.model";
import { getSessionBillableAmount, getSessionInvoiceItemFeeType } from "../utils/invoices.utils";
import { createInvoiceItem } from "./invoicing.requests";
import { saveCounsellingSession } from "./saveCounsellingSession.request";

export const startCounsellingSession = (API: APIController, counsellingSessionId: string, ) => {
  return new Promise<CounsellingSession>(
    async (resolve, reject) => {
      try {
        const url = CounsellingSessionEndpoints.staff.start(counsellingSessionId);
        const session = await API.post<CounsellingSession>(url, ModelName.counsellingSessions);
        if (!session) throw Error("Failed to start the session");
        resolve(session);
      } catch (e) {
        reject(e);
      }
    }
  )
}

export const endCounsellingSession = (API: APIController, counsellingSessionId: string, payable: boolean, hasAnyClientAttended?: boolean) => {
  return new Promise<CounsellingSession>(
    async (resolve, reject) => {
      try {
        const url = CounsellingSessionEndpoints.staff.end(counsellingSessionId);
        const session = await API.post<CounsellingSession>(url, ModelName.counsellingSessions);
        if (!session) throw Error("Failed to end the session");
        await saveCounsellingSession(API, {
          ...session.$snapshot,
          hasAnyClientAttended: hasAnyClientAttended ?? false
        });
        if (payable && session.counsellorId) {
          await createInvoiceItem(API, session.counsellorId, ApiModelName.COUNSELLING_SESSION, session?.id, getSessionBillableAmount(session), getSessionInvoiceItemFeeType(session));
        }
        resolve(session);
      } catch (e) {
        reject(e);
      }
    }
  )
}