import React from "react";
import { ActionConfig } from "../../../controllers/ui/ui.controller.types";
import BaseButton from "../BaseButton/BaseButton";
import './BaseTableActionButton.scss';

interface BaseTableActionButtonProps<T extends object> {
  action: ActionConfig<(d: T) => unknown>,
  data: T,
}

export const BaseTableActionButton = <T extends object>(props: React.PropsWithChildren<BaseTableActionButtonProps<T>>) => {
  const { icon, color, action, label, dataCy, colorCodedState } = props.action;
  return <BaseButton
    icon={icon}
    size="xs"
    className="BaseTableActionButton subtle"
    dataCy={dataCy}
    color={color}
    colorCodedState={colorCodedState}
    onClick={() => action(props.data)}
    label={label}
  />
}

export default BaseTableActionButton;