import { last } from "@amcharts/amcharts4/.internal/core/utils/Array";
import { action, flow, observable, reaction, when } from "mobx";
import moment from "moment";
import { Connection, Device } from "twilio-client";
import { VOIPCall, VOIPCallStatus } from "../base/@types/voip.types";
import { TwilioEndpoints } from "../base/endpoints/twilio.endpoints";
import { removeFromArray } from "../base/utils/array.utils";
import { reportError } from "../base/utils/errors.utils";
import { generateUuid } from "../base/utils/id.utils";
import { LOGGER } from "../base/utils/logger.utils";
import { formatAsIrishNumber, hasIrishCountryCode } from "../base/utils/phone.utils";
import { IS_DEV, SHOULD_LOG } from "../env";
import { User } from "../models/makeUser.model";
import { makeControllerBase, makeRootControllerChildInitFn } from "./_root.controller";

const _ = observable({
  shouldDisableVoip: false,
})

if (window.Cypress) {
  Reflect.set(window, 'disableVOIP', action(() => {
    _.shouldDisableVoip = true;
  }))
}

/**
 * Manages VOIP integrations (Twilio).
 */
export type VoipController = ReturnType<typeof makeVoipController>;

const logger = (color: string, message: string) => LOGGER.log({
  domain: 'VOIPController',
  message,
  color,
})

export const makeVoipController = () => {

  const DEVICE = new Device();

  DEVICE.on('ready', action((connection: Connection) => {
    c.deviceStatus = DEVICE.status();
  }));

  DEVICE.on('connect', action((connection: Connection) => {
    logger('green', 'Twilio VOIP received a connect event');
    SHOULD_LOG() && console.log(connection);
    const phoneNumber = connection.customParameters.get('phoneNumber');
    const call = getVoipCallByPhoneNumber(phoneNumber);
    c.deviceStatus = DEVICE.status();
    if (call) call.onConnect();
  }));

  DEVICE.on('disconnect', action((connection: Connection) => {
    logger('orange', 'Twilio VOIP received a disconnect event');
    SHOULD_LOG() && console.log(connection);
    c.deviceStatus = DEVICE.status();
    c.endAllVoipCalls();
  }));

  DEVICE.on('incoming', (connection: Connection) => {
    SHOULD_LOG() && console.log('Incoming connection from ' + connection.parameters.From);
    var archEnemyPhoneNumber = '+12093373517';

    if (connection.parameters.From === archEnemyPhoneNumber) {
      connection.reject();
      SHOULD_LOG() && console.log('It\'s your nemesis. Rejected call.');
    } else {
      connection.accept();
    }
  });

  DEVICE.on('error', action((e: Error) => {
    logger('red', 'Twilio VOIP Error');
    reportError(e);
    c.error = e as Error;
    c.deviceStatus = DEVICE.status();
  }))

  DEVICE.on('cancel', action((connection: Connection) => {
    c.deviceStatus = DEVICE.status();
  }))
  DEVICE.on('offline', action((connection: Connection) => {
    c.deviceStatus = DEVICE.status();
  }))


  const init = () => new Promise<boolean>(flow (function * (resolve, reject) {
    if (_.shouldDisableVoip) return;
    if (c.softDeviceReady) return;
    const { COMMON, API, AUTH } = c.ROOT!.children;
    if (!AUTH.currentUser) {
      SHOULD_LOG() && console.warn('VOIP init was called without a user in session, aborted. This is unexpected and is likely a bug.');
      return;
    }
    try {
      const url = TwilioEndpoints.voip.getToken();
      const response = yield API.getRaw<{data: {token: string}}>(url);
      if (!response) throw Error('No valid token returned from API for the VOIP integration; unable to start VOIP Service.')
      if (!AUTH.currentUser) {
        SHOULD_LOG() && console.warn('VOIP init aborted because the user session had expired.');
        return;
      }
      const { token } = response.data.data;
      DEVICE.setup(token, {
        appName: 'turn2me (V2)',
        appVersion: COMMON.app.version,
        callerUserId: AUTH.currentUser.id,
        callerUsername: AUTH.currentUser.username,
      });
      if (IS_DEV) Reflect.set(window, 'DEVICE', DEVICE)
      c.deviceStatus = DEVICE.status();
      when(() => c.softDeviceReady, () => resolve(true));
    } catch(e) {
      reject(e);
      c.ROOT!.children.UI.DIALOG.error({
        heading: 'Unable to start VOIP Service',
        error: e,
      })
    }
  }));

  const voipCalls: VOIPCall<Connection>[] = observable([]);
  const getVoipCallByPhoneNumber = (phone: any) => voipCalls.find(c => c.phone === phone);

  const createVoipCallInstance = (
    phone: string,
    user?: User,
    startFn?: () => Connection,
  ): VOIPCall<Connection> => {
    const { UI } = c.ROOT!.children;
    const call: VOIPCall<Connection> = observable({
      id: generateUuid(),
      phone,
      get name() {
        return user?.username || phone;
      },
      status: '' as VOIPCallStatus,
      instance: undefined,
      start: action(() => {
        SHOULD_LOG() && console.log(DEVICE);
        if (!startFn) {
          reportError('Cannot start a VOIP call because the application was not instructed how to start it.');
          call.status = 'failed';
          return;
        }
        try {
          call.instance = startFn();
          call.instance.on('ringing', () => {
            call.status = call.instance!.status();
          });
          call.instance.on('accept', call.onAccept);
          call.status = 'pending';
        } catch(e) {
          reportError(e);
          call.status = 'failed';
          call.startTime = call.endTime = moment().toISOString();
          UI.DIALOG.error({
            heading: 'Failed to start the VOIP call',
            error: e,
          });
        }
      }),
      onConnect: action(() => {
        call.status = call.instance!.status() as VOIPCallStatus;
      }),
      onAccept: action(() => {
        call.status = call.instance!.status() as VOIPCallStatus;
        call.startTime = moment().toISOString();
      }),
      onFail: () => {
      },
      onDisconnect: action(() => {
        call.status = call.instance?.status() || 'failed' as VOIPCallStatus;
        call.endTime = moment().toISOString();
      }),
      onReject: action(() => {
        call.onDisconnect();
      }),
      end: () => {
        call.status = call.instance?.status() || 'ended' as VOIPCallStatus;
        call.instance?.disconnect();
        call.onDisconnect();
      },
      startTime: undefined,
      endTime: undefined,
      user,
    })
    return call;
  }

  const startVoipCall = (userInputPhoneNumber: string, user?: User) => new Promise<boolean>(
    flow(function * (resolve, reject) {
      const { AUTH, ANALYTICS, COMMON } = c.ROOT!.children;
      if (!AUTH.currentUser) throw Error('No current user logged in. Cannot make VOIP calls. This is not expected behaviour, and is likely a bug or potential security breach.');
      if (!AUTH.can.useVoip) return;
      const isIrishNumber = hasIrishCountryCode(userInputPhoneNumber);
      let phone = userInputPhoneNumber;
      if (isIrishNumber) {
        phone = formatAsIrishNumber(userInputPhoneNumber);
      }
      logger('green', "Calling " + phone + "...");
      const callerAnalyticsRecord = yield ANALYTICS.createRecord();
      const params = {
        "To": phone,
        "callerUserId": AUTH.currentUser.id,
        "callerUsername": AUTH.currentUser.username,
        "callerDetectedIP": callerAnalyticsRecord.location.ip,
        "callerDetectedCity": callerAnalyticsRecord.location.city,
        "callerDetectedCountry": callerAnalyticsRecord.location.country,
        "callerDetectedDevice": callerAnalyticsRecord.deviceInfo,
        "callerDetectedBrowser": callerAnalyticsRecord.browserInfo.userAgent,
        "callerAppTimezone": COMMON.timezone,
        "callerAppName": 'turn2me V2',
        "callerAppVersion": COMMON.app.version,
        "callerAppLocalTime": COMMON.CLOCK.nowUtcTimestamp,
        "callerAppLoadedAtUTCTime": COMMON.timeAppLoadedUtc,
        "callerAppLoadedAtLocalTime": COMMON.timeAppLoadedLocal,
      };
      const startFn = () => DEVICE.connect(params);
      const call = createVoipCallInstance(phone, user, startFn);
      voipCalls.push(call);
      call.start();
      resolve(true);
    }
  ))

  const removeVoipCallInstance = (call: VOIPCall<Connection>) => {
    call.end();
    removeFromArray(voipCalls, call);
  }

  const endAllVoipCalls = () => new Promise<boolean>((resolve, reject) => {
    logger('green', "Ending all calls");
    voipCalls.forEach(c => c.end());
    resolve(true);
  });

  const reset = () => {
    endAllVoipCalls();
    if (c.softDeviceReady)  {
      DEVICE.destroy();
      c.deviceStatus = DEVICE.status();
    }
    voipCalls.splice(0);
  }

  window.addEventListener('beforeunload', endAllVoipCalls);

  const c = observable({
    ...makeControllerBase('VOIP'),
    get softDeviceReady(): boolean {
      return c.deviceStatus === 'ready';
    },
    voipCalls,
    get hasLiveVoipCalls(): boolean {
      return c.liveCalls.length > 0;
    },
    get liveCalls() {
      return voipCalls.filter(c => !c.endTime);
    },
    get lastLiveVoipCall(): VOIPCall | undefined {
      return last(c.liveCalls);
    },
    deviceStatus: null as Device.Status | null,
    createVoipCallInstance,
    startVoipCall,
    endAllVoipCalls,
    removeVoipCallInstance,
    error: null as Error | null,
    autoSetInputDevice: () => {
      const availableInputDevices = Array.from(DEVICE.audio?.availableInputDevices.entries() ?? []);
      while (!DEVICE.audio?.inputDevice && availableInputDevices.length) {
        const deviceConfig = availableInputDevices.shift();
        if (deviceConfig) DEVICE.audio?.setInputDevice(deviceConfig[0]);
      }
    },
    reset,
  })


  c.init = makeRootControllerChildInitFn(
    c,
    () => {
      reaction(
        () => c.ROOT!.children.AUTH.can.useVoip,
        canUseVoip => {
          if (canUseVoip) init();
          else reset();
        },
        { fireImmediately: true }
      )
    }
  );

  return c;

}
