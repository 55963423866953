import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState, Nullable } from '../../base/@types';
import AppPage from '../../base/components/AppPage/AppPage';
import AppPageContent from '../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../base/components/AppPageHeader/AppPageHeader';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseButtonGroup from '../../base/components/BaseButtonGroup/BaseButtonGroup';
import BaseToggle from '../../base/components/BaseToggle/BaseToggle';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import InfoBanner from '../../base/components/InfoBanner/InfoBanner';
import OverlayCloseButton from '../../base/components/OverlayCloseButton/OverlayCloseButton';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import UIBlock from '../../base/components/UIBlock/UIBlock';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { Contact, ContactSnapshot, ContactType } from '../../models/makeContact.model';
import { UserSnapshot } from '../../models/makeUser.model';
import { saveContact } from '../../requests/saveContact.request';
import { sendSaveUserRequest } from '../../requests/saveUser.request';
import ContactEditor from '../ContactEditor/ContactEditor';
// import './OverlayContactEditor.scss';

type OverlayContactEditorProps = {
  contact: Contact,
  forceDefaultToPrimaryAsTrue?: boolean
}

const OverlayContactEditor: React.FC<OverlayContactEditorProps> = props => {

  const { API, AUTH, UI } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    uneditedCopy: p.contact.$getSnapshot(),
    get isNew() {
      return !Boolean(p.contact.id);
    },
    get title() {
      switch (p.contact.type) {
        case ContactType.EmergencyContact:
          return s.isNew ? 'New Emergency Contact' : 'Edit Emergency Contact';
        case ContactType.KeyWorker:
          return s.isNew ? 'New Key Worker Contact' : 'Edit Key Worker Contact Info';
        default:
          return s.isNew ? 'New Contact' : 'Edit Contact';
      }
    },
    setAsPrimaryEmergencyContactOnSave: props.forceDefaultToPrimaryAsTrue || false,
    savingEmergencyContact: false,
    error: null as Nullable<Error>,
    get canSetAsPrimary() {
      return Boolean(p.contact.ownerId);
    },
    get isOwnEmergencyContact() {
      return p.contact.ownerId === AUTH.currentUser?.id;
    },
    saveEmergencyContact: async () => await flow(function *() {
      if (!p.contact) return;
      s.savingEmergencyContact = true;
      s.error = null;
      try {
        const payload: Partial<ContactSnapshot> = p.contact.$snapshot;
        if (!payload.id) delete payload.id;
        const savedEmergencyContact = yield saveContact(payload, API);
        if (s.setAsPrimaryEmergencyContactOnSave) {
          const updatedUser: Partial<UserSnapshot> = {
            id: savedEmergencyContact.userId,
            primaryEmergencyContactId: savedEmergencyContact.id,
          }
          yield sendSaveUserRequest(API, updatedUser, {
            isSelf: s.isOwnEmergencyContact,
          });
        }
        if (s.isOwnEmergencyContact) yield AUTH.fetchCurrentUserInfo();
        s.uneditedCopy = savedEmergencyContact.$getSnapshot();
        s.dismiss();
      } catch (e) {
        s.error = e as Error;
        reportError(e);
      } finally {
        s.savingEmergencyContact = false;
      }
    })(),
    get emergencyContactValidatorResult() {
      // return EmergencyContactValidator(p.emergencyContact);
      return Boolean(p.contact.name && p.contact.phone && (p.contact.email && /^.+@.+\..+$/.test(p.contact.email)))
    },
    dismiss: () => {
      UI.OVERLAY.dismiss();
    },
  }))
  return <Observer children={() => (
    <AppPage className="OverlayContactEditor">
      <AppPageHeader
        title={s.title}
        endSlot={<OverlayCloseButton />}
      />
      <AppPageContent>
        <UIBlock padded spaceChildren>
          <ShadedBlock spaceChildren>
            <ContactEditor
              contact={p.contact}
              applyChangesImmediately
              disabled={s.savingEmergencyContact}
              includeInputEmail
            />
            {s.canSetAsPrimary && <BaseToggle dataCy="set-as-primary-emergency-contact-toggle" form={s} field="setAsPrimaryEmergencyContactOnSave" label="Set as Primary Emergency Contact" disabled={props.forceDefaultToPrimaryAsTrue} />}
          </ShadedBlock>
          <BaseButtonGroup>
            <BaseButton dataCy="save-emergency-contact" onClick={s.saveEmergencyContact} disabled={s.emergencyContactValidatorResult !== true} label="Save Emergency Contact" />
            <BaseButton className="subtle" onClick={s.dismiss}>Cancel</BaseButton>
          </BaseButtonGroup>
          {
            s.error && <InfoBanner colorCodedState={ColorCodedState.error} icon="warning" heading="Error saving emergencyContact">
              <ErrorRenderer error={s.error} />
            </InfoBanner>
          }
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
}

export default OverlayContactEditor;