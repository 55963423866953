import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject, ColorCodedState, HasId, Nillable, TimezoneMode } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import ColorTagCoordinator from '../../base/components/ColorTag/ColorTagCoordinator';
import ColorTagCounsellor from '../../base/components/ColorTag/ColorTagCounsellor';
import ColorTagFacilitator from '../../base/components/ColorTag/ColorTagFacilitator';
import ColorTagModerator from '../../base/components/ColorTag/ColorTagModerator';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import { IconName } from '../../base/components/Symbols/iconDefs';
import { ChatParticipantEndpoints } from '../../base/endpoints/chatParticipant.endpoints';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { ChatMediator } from '../../base/mediators/chat.mediator';
import { makeActionConfig, makeCancelAction } from '../../base/utils/actionConfig.utils';
import joinClassName from '../../base/utils/className.utils';
import { NoOp } from '../../base/utils/functions.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { equalByString } from '../../base/utils/string.utils';
import { createMomentFn, YYYYMMDDHHmmss } from '../../base/utils/time.utils';
import { setUrlParam } from '../../base/utils/urlParams.utils';
import { ModelName } from '../../constants/modelNames.enum';
import { FEATURE_FLAGS } from '../../env';
import { ChatParticipant } from '../../models/makeChatParticipant.model';
import { User } from '../../models/makeUser.model';
import { patchChatParticipant } from '../../requests/patchChatParticipant.request';
import { getUser } from '../../requests/user.requests';
import { isCoordinator, isCounsellor, isFacilitator, isModerator } from '../../utils/user.utils';
import ColorLabelDot from '../ColorLabelDot/ColorLabelDot';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import './ChatWindowParticipantEntry.scss';

interface ChatWindowParticipantEntryProps {
  chat: ChatMediator,
  participant: ChatParticipant;
  isSelf?: boolean;
  isOnline?: boolean;
  showOptions?: boolean;
  timezoneMode?: TimezoneMode,
}

function ChatWindowParticipantEntry(props: React.PropsWithChildren<ChatWindowParticipantEntryProps>) {

  const p = useProps(props);

  const _createMoment = createMomentFn(p.timezoneMode ?? 'auto');

  const { API, UI, AUTH, MESSENGER, NAVIGATOR, VOIP } = useControllers();
  // const ROOT = useRootController();

  const s = useStore(() => ({
    get isCounsellor() {
      return isCounsellor(s.user);
    },
    get isModerator() {
      return isModerator(s.user);
    },
    get isFacilitator() {
      return isFacilitator(s.user);
    },
    get isCoordinator() {
      return isCoordinator(s.user);
    },
    get timeMuted() {
      return p.participant.timeMuted;
    },
    get timeRemoved() {
      return p.participant.timeRemoved;
    },
    get muteButtonIcon(): IconName {
      return s.timeMuted ? 'microphone-off' : 'microphone';
    },
    get currentUserIsCounsellorOrAdmin() {
      return AUTH.isCounsellor || AUTH.isStaff
    },
    get canMute() {
      return s.currentUserCanFacilitate && !p.chat.hasEnded && !s.timeRemoved;
    },
    get canCall() {
      return s.currentUserCanFacilitate && !p.chat.hasEnded && !s.timeRemoved
    },
    get canRemove() {
      return s.currentUserCanFacilitate && !p.chat.hasEnded && !s.timeRemoved
    },
    get user() {
      return p.participant.user;
    },
    get userId() {
      return p.participant.userId || s.user?.id;
    },
    get userPhone() {
      return s.user?.mobileNumber;
    },
    get currentUserCanFacilitate() {
      return AUTH.canFacilitate;
    },

  }));

  const patchMutedAt = (mute: boolean) => () => new Promise<boolean>(flow(function* (resolve, reject) {
    try {
      const payload: Partial<ChatParticipant> & HasId = {
        id: p.participant.id,
        timeMuted: mute ? _createMoment(undefined).utc().format(YYYYMMDDHHmmss) : '',
      }
      const responseData = yield patchChatParticipant(API, payload);
      p.participant.timeMuted = responseData.timeMuted;
      resolve(true);
    } catch (e) {
      reject(e);
      UI.DIALOG.error({
        heading: 'Failed to update the mute status, please try again',
        body: 'If this problem persists, please contact the development team.',
        defaultActions: ['positive'],
      })
    }
  }))

  const toggleMute = () => {
    if (!s.canMute) return;
    if (s.timeMuted) {
      UI.DIALOG.present({
        heading: () => <span>Are you sure you want to unmute <UsernameRenderer user={s.user} />?</span>,
        body: 'You can mute them again by clicking the mute icon.',
        actions: [
          makeCancelAction(),
          makeActionConfig('Unmute', patchMutedAt(false)),
        ]
      });
    } else {
      UI.DIALOG.attention({
        heading: () => <span>Are you sure you want to mute <UsernameRenderer user={p.participant.user} />?</span>,
        body: 'You can unmute them by clicking the mute icon again.',
        actions: [
          makeCancelAction(),
          makeActionConfig('Mute', patchMutedAt(true))
        ]
      });
    }
  }

  const callUser = flow(function * () {
    if (!s.userId) return;
    if (!s.userPhone) {
      const user: Nillable<User> = yield getUser(s.userId, API);
      if (!user) {
        UI.DIALOG.attention({
          heading: () => <>Unable to retrieve informaton of User <UsernameRenderer user={s.user} />.</>,
        })
        return;
      }
      if (p.participant.user) {
        p.participant.user.$patch(user);
      } else {
        p.participant.user = user;
      }
      if (!s.userPhone) {
        UI.DIALOG.attention({
          heading: () => <>User <UsernameRenderer user={s.user} /> does not have a phone number on record.</>,
        })
        return;
      }
    }
    const confirm = yield UI.DIALOG.present({
      heading: () => <>Call user <UsernameRenderer user={s.user} /> at {s.userPhone} via VOIP?</>
    })
    if (!confirm) return;
    VOIP?.startVoipCall(s.userPhone, s.user);
  })

  const removeUser = () => new Promise<boolean>(
    flow(function * (resolve, reject) {
      const userId = s.userId;
      if (equalByString(userId, AUTH.currentUser?.id)) {
        UI.DIALOG.attention({
          heading: 'You cannot remove yourself from the chat.',
        })
        resolve(false)
        return;
      }
      const confirm = yield UI.DIALOG.attention({
        heading: () => <>You are removing user <UsernameRenderer user={s.user} /> from the chat.</>,
        body: 'Note that you will not be able to add them back to the chat again.',
        defaultActions: ['negative', 'positive']
      })
      if (!confirm) {
        resolve(false)
        return;
      }
      const { id } = p.participant;
      const url = ChatParticipantEndpoints.staff.update(id);
      const now = _createMoment(undefined).utc().format(YYYYMMDDHHmmss);
      const payload: Partial<ChatParticipant> = {
        id, timeRemoved: now,
      }
      try {
        yield API.patch(url, ModelName.chatParticipants, payload);
        p.participant.timeRemoved = now;
        resolve(true);
      } catch(e) {
        reject(e);
        UI.DIALOG.error({
          heading: () => <>Failed to remove user.</>,
          body: <ErrorRenderer error={(e as AnyObject).response} />
        })
      }
    })
  )

  const handleUsernameClick = () => {
    if (!AUTH.canViewOtherUserDetails) return;
    setUrlParam('userId', s.userId, NAVIGATOR);
  }

  const newPrivateChat = async () => {
    if (!s.userId) return;
    const confirm = await UI.DIALOG.present({
      heading: <>Confirmation</>,
      body: <p>Start a new private chat with user <UsernameRenderer user={s.user} userId={s.userId} />?</p>
    })
    if (!confirm) return;
    MESSENGER.createNewInstantChat([s.userId]);
  }

  return <Observer children={() => (
    <tr className={
      joinClassName(
        'ChatWindowParticipantEntry',
        p.isSelf && 'isSelf',
        p.isOnline ? 'online' : 'offline',
        p.showOptions && 'showOptions',
        !!s.timeMuted && 'muted',
        !!s.timeRemoved && 'removed',
      )
    } data-is-counsellor={s.isCounsellor}>
      {
        p.showOptions && <td className="ChatWindowParticipantEntryOnlineOptionsCell">
          <div className="ChatWindowParticipantEntryOnlineOptionsCellInner">
            {<BaseButton iconSize="1.4em" iconVariant="filled" appearance="icon" icon={s.muteButtonIcon} title={s.timeMuted ? 'Unmute' : 'Mute'} onClick={s.canMute ? toggleMute : NoOp} colorCodedState={s.timeMuted ? ColorCodedState.alert : ''} disabled={!s.canMute} />}
            {s.currentUserCanFacilitate && !p.chat.thread.timeEnded && p.chat.participants.length > 2 && <BaseButton iconSize="1.4em" iconVariant="filled" appearance="icon" icon='chat-plus' title='Start new private chat' onClick={newPrivateChat} disabled={AUTH.currentUser && s.userId === AUTH.currentUser.id} />}
            {FEATURE_FLAGS.ENABLE_VOIP && s.currentUserCanFacilitate && !p.chat.thread.timeEnded && <BaseButton iconSize="1.4em" iconVariant="filled" appearance="icon" className="u-no-print" icon="phone" title="Call user" onClick={callUser} disabled={!s.canCall} />}
            {s.currentUserCanFacilitate && !p.chat.thread.timeEnded && <BaseButton iconSize="1.4em" iconVariant="filled" appearance="icon" className="u-no-print" icon="close" title="Remove user from chat" onClick={removeUser} disabled={!s.canRemove} />}
          </div>
        </td>
      }
      <th className={AUTH.canViewOtherUserDetails ? 'canViewUserDetails' : undefined} onClick={handleUsernameClick}>
        <div>
          <div>
            <UsernameRenderer user={p.participant.user} />
            {!!s.timeRemoved && <>(Removed)</>}
          </div>
          {s.isCounsellor && <div><ColorTagCounsellor user={s.user} /></div>}
          {s.isModerator && !s.isCounsellor && <div><ColorTagModerator /></div>}
          {s.isFacilitator && !s.isCounsellor && <div><ColorTagFacilitator /></div>}
          {s.isCoordinator && !s.isCounsellor && <div><ColorTagCoordinator /></div>}
        </div>
      </th>
      <td className="ChatWindowParticipantEntryOnlineStatusCell u-no-print">
        <ColorLabelDot color={p.isOnline ? '#47B720' : '#86838144'} />
      </td>
    </tr>
  )} />
}

export default ChatWindowParticipantEntry;