import { computed } from "mobx";
import { SHOULD_LOG } from "../../env";
import { NoOp } from "./functions.utils";

export type loggerMessageConfig = {
  domain: string | string[], 
  message: any | any[],
  color?: string,
  fontStyle?: '' | 'bold' | 'italic',
}

class Logger {

  private _env = process.env.NODE_ENV;
  @computed get isDev() {
    return this._env === 'development';
  }
  @computed get isProd() {
    return this._env === 'production';
  }
  isTrainingOrAlpha = ['training.turn2me.ie', 'alpha.turn2me.ie'].includes(window.location.hostname);
  init() {
    // if (this.isProd && !this.isTrainingOrAlpha && !SHOULD_LOG()) {
    //   // @ts-ignore
    //   window['console'] = this.stubLogger;
    // }
  }
  log(config: loggerMessageConfig | string, ...args: any[]) {
    if (this.isProd) return;
    if (typeof config === 'string' || !('domain' in config && 'message' in config)) {
      SHOULD_LOG() && console.log(config, ...args);
      return;
    }
    const { domain, message, color, fontStyle } = config;
    const domainStringified = `[${domain instanceof Array ? domain.join(':') : domain}]`;

    const stylingRules: string[] = [];
    if (color) stylingRules.push(`color: ${color}`);
    if (fontStyle) {
      switch (fontStyle) {
        case 'bold': stylingRules.push(`font-weight: 700`); break;
        case 'italic': stylingRules.push(`font-style: ${fontStyle}`); break;
        default: return;
      }
    }

    const array = [domainStringified];
    if (typeof message === 'string') array.push(message);
    else array.push(...message);

    const stylingString = stylingRules.join(';');
    if (!stylingString) {
      SHOULD_LOG() && console.log(...array);
      return;
    }

    // if styles detected, presume all messages can be stringified;
    SHOULD_LOG() && console.log(`%c${[...array].join(' ')}`, stylingString);

  }
  stubLogger = {
    log: NoOp,
    warn: NoOp,
    error: NoOp,
    dir: NoOp,
    count: NoOp,
    table: NoOp,
    time: NoOp,
    timeEnd: NoOp,
    group: NoOp,
    groupEnd: NoOp,
    info: NoOp,
  }

}

export const LOGGER = new Logger();
