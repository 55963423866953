import { always } from "../../../../../base/utils/ramdaEquivalents.utils";
import { makeObservableRouteDef } from "../../../../../base/utils/routes.utils";
import AccountPageUpdatePassword from "./AccountPageUpdatePassword.page";

export const AccountPageUpdatePasswordRouteDef = makeObservableRouteDef({
  identifier: 'account-update-password',
  urlFactory: always('update-password'),
  composeTitle: always('Update Password'),
  component: AccountPageUpdatePassword,
  icon: 'lock',
})