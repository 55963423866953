import { Observer } from 'mobx-react-lite';
import React from 'react';
import joinClassName from '../../../../base/utils/className.utils';
import './AuthForm.scss';

interface AuthFormProps {
  className?: string;
}

const AuthForm: React.FC<AuthFormProps> = props => {
  return <Observer children={() => (
    <div className={joinClassName('AuthForm', props.className)}>
      { props.children}
    </div>
  )} />
}

export default AuthForm;