import { reaction, when } from "mobx";
import React from "react";
import tick from "../../base/utils/waiters.utils";
import OverlayAgeVerification from "../../components/OverlayAgeVerification/OverlayAgeVerification";
import { AuthController } from "../auth.controller";
import { UIController } from "../ui.controller";

export function verifyAge(
	AUTH: AuthController
) {
	return new Promise<boolean>((resolve, reject) => {
		const { UI, NAVIGATOR } = AUTH.ROOT!.children;
		const disposer = reaction(
			() => Boolean(AUTH.currentUser?.dateOfBirth),
			() => {
				if (!AUTH.currentUser) return;
				const dob = AUTH.currentUser.dateOfBirth;
				if (!dob && !AUTH.currentUser.isOver18) {
					when(
						() => !UI.OVERLAY.hasOpenedOverlays,
						async () => {
							await tick(500);
							if (NAVIGATOR.currentLocationPathname.match(/^\/app\/(invitation)/)) {
								return;
							}
							openAgeVerificationOverlay(UI);
						}
					)
					resolve(false);
					return;
				}
				resolve(true);
			},
			{ fireImmediately: true }
		);
		when(
			() => !AUTH.currentUser,
			disposer
		);
	})
}

export const openAgeVerificationOverlay = (UI: UIController, dismissable?: boolean) => {
	UI.OVERLAY.present({
		name: 'OverlayAgeVerification',
		component: <OverlayAgeVerification dismissable={dismissable} />,
		appearance: 'card',
		width: '28em',
	})
}
