import { Observer } from 'mobx-react-lite';
import React from 'react';
import tick from '../../utils/waiters.utils';
import { IconVariant } from '../Symbols/iconDefs';
import { BaseSelectorInnerProps, DefaultOptionType, DefaultSingleValueType } from './BaseSelector';
import BaseSelectorInlineOption from './BaseSelectorInlineOption';
// import './BaseSelectorInlineInner.scss';

export function BaseSelectorInlineInner<
  FormType extends object = object, 
  OptionType extends any = DefaultOptionType,
  SingleValueType extends DefaultSingleValueType = DefaultSingleValueType,
  ValueFieldType extends SingleValueType | string[] = SingleValueType,
>(props: React.PropsWithChildren<BaseSelectorInnerProps<FormType, OptionType, SingleValueType, ValueFieldType>> & {
  iconVariant?: IconVariant
}) {

  const renderOptions = () => {
    return props.options.map((o, i) => {
      const value: SingleValueType = props.valueGetter!(o);
      const label = props.optionLabelRenderer!(o);
      const disabled = props.disabledOptionChecker!(o);
      const isSelected = props.isSelectedOption(o);
      return (
        <BaseSelectorInlineOption 
          key={value + '' + i}
          option={o}
          label={label} 
          value={value} 
          disabled={disabled} 
          onClick={() => handleOnChange(value)}
          isSelected={isSelected}
          iconVariant={props.iconVariant}
        />
      )
    })
  }

  const handleOnChange = async (v: SingleValueType) => {
    await tick();
    props.onChange && props.onChange(v);
  }

  const handleClick = (e: React.MouseEvent) => {
    props.onClick?.(e);
  }

  return <Observer children={() => (
    <div className="BaseSelectorInner BaseSelectorInlineInner" onClick={handleClick}>
      { renderOptions() }
    </div>
  )} />

}

export default BaseSelectorInlineInner;