import { flow, observable, reaction } from "mobx";
import { Nillable } from "../base/@types";
import { AssignmentEndpoints, AssignmentGetEndpointDefaultIncludes } from "../base/endpoints/assignment.endpoints";
import { NoOp } from "../base/utils/functions.utils";
import { convertRecordMapToArray, RecordMap } from "../base/utils/map.utils";
import { ModelName } from "../constants/modelNames.enum";
import { Assignment } from "../models/makeAssignment.model";
import { User } from "../models/makeUser.model";
import { APIController, APIGetManyResponse } from "./api.controller";
import { AuthController } from "./auth.controller";
import { LocalDBController } from "./localDB.controller";
import { makeControllerBase, makeRootControllerChildInitFn } from "./_root.controller";

/**
 * Assignments comes in many types, including all the clinical surveys (GAD7, PHQ9, Goal Sheets),
 * Support group surveys, Satisfactions surveys, and payments. Those are "assigned" to users, which they then are prompted to complete in different areas in UI.
 * This is a central place that manages the current user's assignments.
 */
export type AssignmentsController = ReturnType<typeof makeAssignmentsController>;

export const makeAssignmentsController = () => {
	const c = observable({
		...makeControllerBase('ASSIGNMENTS'),
		get API(): APIController {
			return c.ROOT!.children.API;
		},
		get AUTH(): AuthController {
			return c.ROOT!.children.AUTH;
		},
		get LOCALDB(): LocalDBController {
			return c.ROOT!.children.LOCALDB;
		},
		get currentUser(): Nillable<User> {
			return c.AUTH.currentUser;
		},
		get map(): RecordMap<Assignment> {
			return c.LOCALDB.data.assignments;
		},
		get array(): Assignment[] {
			return convertRecordMapToArray(c.map);
		},
		getAllDueAssignmentsOfCurrentUser: async () => await flow(function* () {
			const url = AssignmentEndpoints.own.index({
				include: AssignmentGetEndpointDefaultIncludes,
				perPage: -1,
			});
			(yield c.API.getMany(url, ModelName.assignments)) as APIGetManyResponse<Assignment>
		})(),
		reset: NoOp,
	})

	c.init = makeRootControllerChildInitFn(
		c,
		() => {
			reaction(
				() => c.currentUser?.id,
				id => {
					if (id) c.getAllDueAssignmentsOfCurrentUser();
				},
				{ fireImmediately: true }
			);
		}
	);

	return c;
}
