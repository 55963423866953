import { Reaction, ReactionRelatedModels } from "../../models/makeReaction.model";
import { DefaultIndexParamSet } from "../utils/api.utils";
import { makeApiEndpointConfig, makeApiGetEndpointConfig } from "../utils/endpoints.utils";
import { makeUrl } from "../utils/url.utils";
import { prefixClient, prefixOwn } from "./namespacePrefixes";

export type ReactionEndpointParams = DefaultIndexParamSet<Reaction, ReactionRelatedModels> & {
  include?: (keyof ReactionRelatedModels | string)[],
  latest?: boolean,
}

const ownUrlBase = prefixOwn('reactions');
const adminUrlBase = prefixClient('reactions');

export const ReactionEndpoints = {
  own: {
    index: makeApiEndpointConfig<ReactionEndpointParams>(ownUrlBase),
    get: makeApiGetEndpointConfig<ReactionEndpointParams>(ownUrlBase),
    create: () => ownUrlBase,
    update: (id: string) => makeUrl(ownUrlBase, id),
    delete: (id: string) => makeUrl(ownUrlBase, id),
  },
  admin: {
    index: makeApiEndpointConfig<ReactionEndpointParams>(adminUrlBase),
    get: makeApiGetEndpointConfig<ReactionEndpointParams>(adminUrlBase),
    create: () => adminUrlBase,
    update: (id: string) => makeUrl(adminUrlBase, id),
    delete: (id: string) => makeUrl(adminUrlBase, id),
  },
}