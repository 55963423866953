import { Nullable } from "../base/@types";
import { ChatMessage } from "../models/makeChatMessage.model";
import { ChatParticipant } from "../models/makeChatParticipant.model";

const createMessageGroup = (participant?: ChatParticipant) => ({
  id: '',
  participant,
  participantId: participant?.id,
  messages: [] as ChatMessage[],
})

export type MessageGroup = ReturnType<typeof createMessageGroup>;

export function groupMessagesByParticipants(allMessages: ChatMessage[], participants: ChatParticipant[]) {
  const groups = [] as MessageGroup[];
  let buffer = null as Nullable<MessageGroup>;
  for (let message of allMessages) {
    if (!buffer || buffer.participantId !== message.participantId) {
      buffer && groups.push(buffer);
      buffer = createMessageGroup(message.participant);
      buffer.id = `p${message.participantId}-m${message.id}`;
    }
    buffer.messages.push(message);
  }
  buffer && groups.push(buffer);
  return groups;
}