import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseInput from '../../base/components/BaseInput/BaseInput';
import RatingInput from '../../base/components/RatingInput/RatingInput';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { Feedback } from '../../models/makeFeedback.model';
import './FeedbackForm.scss';

export interface FeedbackFormProps {
  feedback: Feedback;
}

const FeedbackForm: React.FC<FeedbackFormProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    feedback: p.feedback,
  }));
  return <Observer children={() => (
    <div className="FeedbackForm">
      <RatingInput form={s.feedback} field="rating" />
      <BaseInput form={s.feedback} field="body" placeholder="Leave anonymous feedback... (Optional)" type="textarea" rows="3" />
    </div>
  )} />
}

export default FeedbackForm;