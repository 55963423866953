import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Nillable } from '../../base/@types';
import { toSentenceCase } from '../../base/utils/string.utils';
import { sexualityDescriptors } from '../../constants/sexualities.constants';

interface SexualityRendererProps {
  value?: Nillable<string>,
}

const SexualityRenderer: React.FC<SexualityRendererProps> = props => {
  const displayName = sexualityDescriptors.find(i => i.value === props.value)?.name || toSentenceCase(props.value);
  return <Observer children={() => (
    <span className="SexualityRenderer" data-value={props.value}>
      { displayName}
    </span>
  )} />
}

export default SexualityRenderer;