import React from 'react';
import { getColorHexByName } from '../../utils/colors.utils';
import ColorTag from './ColorTag';

interface ColorTag18PlusProps {}

const ColorTag18Plus: React.FC<ColorTag18PlusProps> = props => {
  return <ColorTag className="ColorTag18Plus" color={getColorHexByName('brightPurple')}>18+</ColorTag>
}

export default ColorTag18Plus;