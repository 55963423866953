import { action, flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState } from '../../../../base/@types';
import AppPage from '../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../base/components/AppPageHeader/AppPageHeader';
import BaseButton from '../../../../base/components/BaseButton/BaseButton';
import BaseButtonGroup from '../../../../base/components/BaseButtonGroup/BaseButtonGroup';
import BaseSpacer from '../../../../base/components/BaseSpacer/BaseSpacer';
import ColorTag from '../../../../base/components/ColorTag/ColorTag';
import ColorTagAdmin from '../../../../base/components/ColorTag/ColorTagAdmin';
import ColorTagAnalyst from '../../../../base/components/ColorTag/ColorTagAnalyst';
import ColorTagCoordinator from '../../../../base/components/ColorTag/ColorTagCoordinator';
import ColorTagCounsellor from '../../../../base/components/ColorTag/ColorTagCounsellor';
import ColorTagFacilitator from '../../../../base/components/ColorTag/ColorTagFacilitator';
import ColorTagFinancialAdministrator from '../../../../base/components/ColorTag/ColorTagFinancialAdministrator';
import ColorTagModerator from '../../../../base/components/ColorTag/ColorTagModerator';
import InfoBanner from '../../../../base/components/InfoBanner/InfoBanner';
import OverlayCloseButton from '../../../../base/components/OverlayCloseButton/OverlayCloseButton';
import ShadedBlock from '../../../../base/components/ShadedBlock/ShadedBlock';
import UIBlock from '../../../../base/components/UIBlock/UIBlock';
import { useOnMount } from '../../../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import { isPrimitiveArraysEqual, keepTruthy } from '../../../../base/utils/array.utils';
import { decodeString } from '../../../../base/utils/encoder.utils';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import { copyWithJSON } from '../../../../base/utils/object.utils';
import { autoPluralize } from '../../../../base/utils/string.utils';
import UsernameRenderer from '../../../../components/UsernameRenderer/UsernameRenderer';
import { AppPermissionGroups, AppPermissionRoles, RoleDeciderByRoles } from '../../../../constants/permissionGroups.constants';
import { AppPermission } from '../../../../constants/permissions.constants';
import { AppStaffRole, StaffRoleSet } from '../../../../constants/staffRoles.constants';
import { User } from '../../../../models/makeUser.model';
import { getUser } from '../../../../requests/user.requests';
import { getRolePermissions } from '../../../../utils/permission.utils';
import './OverlayUserPermissionManager.scss';
import PermissionGroupManager from './PermissionGroupManager';
import PermissionRolesManager from './PermissionRolesManager';

type OverlayUserPermissionManagerProps = {
  user: User,
}

const OverlayUserPermissionManager: React.FC<OverlayUserPermissionManagerProps> = props => {

  const { AUTH, UI, API } = useControllers();

  const p = useProps(props);

  const userRoles = keepTruthy(decodeString(p.user.roleString ?? '').split('_')).filter(role => role !== AppStaffRole.counsellor) as unknown as AppStaffRole[];
  const userPerms = keepTruthy(decodeString(p.user.permissionString ?? '').split('_')) as unknown as AppPermission[]

  const s = useStore(() => ({
    shouldShowAdvancedSettings: false,
    originalRoles: userRoles,
    selectedRoles: userRoles,
    originalPermissions: userPerms,
    selectedPermissions: userPerms,
    allPermissions: copyWithJSON(AppPermissionGroups.all.permissions),
    allRoles: copyWithJSON(StaffRoleSet).filter(role => role !== AppStaffRole.counsellor),
    get selectedRolePermissions() {
      return getRolePermissions(s.selectedRoles);
    },
    get isManagingSelf() {
      return Boolean(AUTH.currentUser?.id && p.user.id === AUTH.currentUser?.id);
    },
    doesSelectedPermissionsInclude(perm: AppPermission): boolean {
      return s.selectedPermissions.includes(perm);
    },
    doesSelectedRolesInclude(role: AppStaffRole): boolean {
      return s.selectedRoles.includes(role);
    },
    get allSelected() {
      return s.allPermissions.every(perm => s.doesSelectedPermissionsInclude(perm));
    },
    get someSelected() {
      return s.allPermissions.some(perm => s.doesSelectedPermissionsInclude(perm));
    },
    get noneSelected() {
      return !s.someSelected;
    },
    get isAdmin() {
      return RoleDeciderByRoles[AppStaffRole.admin].some(role => s.doesSelectedRolesInclude(role));
    },
    get isCounsellor() {
      return RoleDeciderByRoles[AppStaffRole.counsellor].some(role => s.doesSelectedRolesInclude(role));
    },
    get isModerator() {
      return RoleDeciderByRoles[AppStaffRole.moderator].some(role => s.doesSelectedRolesInclude(role));
    },
    get isFacilitator() {
      return RoleDeciderByRoles[AppStaffRole.facilitator].some(role => s.doesSelectedRolesInclude(role));
    },
    get isCoordinator() {
      return RoleDeciderByRoles[AppStaffRole.coordinator].some(role => s.doesSelectedRolesInclude(role));
    },
    get isAnalyst() {
      return RoleDeciderByRoles[AppStaffRole.analyst].some(role => s.doesSelectedRolesInclude(role));
    },
    get isFinancialAdministrator() {
      return RoleDeciderByRoles[AppStaffRole.financialAdministrator].some(role => s.doesSelectedRolesInclude(role));
    },
    get hasNoUserGroupsToWorkWith() {
      return s.selectedPermissions.length > 0 && !s.selectedPermissions.find(perm => AppPermissionGroups.clientManagementUserGroups.permissions.includes(perm));
    },
    get warningMessage() {
      return <InfoBanner icon="warning" colorCodedState={ColorCodedState.attention} colorIntensity="intense">
        <p>Warning: The user has some special permissions enabled, but has no user groups to work with.</p>
        <p>Please select applicable user groups in the first "Client User Groups" section.</p>
      </InfoBanner>
    },
    toggleShowAdvancedSettings: action(() => {
      s.shouldShowAdvancedSettings = !s.shouldShowAdvancedSettings;
    }),
    get haveUnsavedChanges() {
      return !(isPrimitiveArraysEqual(s.originalRoles, s.selectedRoles) && isPrimitiveArraysEqual(s.originalPermissions, s.selectedPermissions));
    },
    get isUserDeleted() {
      // return !!p.user.timeDeleted;
      /** Client wants us to allow managing permissions of deleted users */
      return false;
    },
  }))

  const toggleAll = () => {
    if (s.allSelected) {
      s.selectedPermissions.splice(0);
      s.selectedRoles.splice(0);
    } else {
      s.selectedPermissions.splice(0, Infinity, ...s.allPermissions);
      s.selectedRoles.splice(0, Infinity, ...s.allRoles);
    }
  }

  const saveChanges = flow(function* () {
    const permissionURL = `/staff/permissions/${p.user.id}`;
    const permissionPayload = {
      permissions: copyWithJSON(s.selectedPermissions.filter(p => !s.selectedRolePermissions.includes(p))),
    }
    const roleURL = `/staff/roles/${p.user.id}`;
    const rolePayload = {
      staffRoles: copyWithJSON(s.selectedRoles),
    }
    try {
      // NOTE: Sequence matters.
      // Update role first, then permissions.
      yield API.patchRaw(roleURL, rolePayload);
      yield API.patchRaw(permissionURL, permissionPayload);
      yield getUser(p.user.id, API);
      s.originalPermissions = copyWithJSON(s.selectedPermissions);
      s.originalRoles = copyWithJSON(s.selectedRoles);
      UI.DIALOG.success({
        name: 'roles-and-permissions-update-success',
        heading: 'Successfully updated user roles and permissions.',
      })
    } catch (e) {
      UI.DIALOG.error({
        heading: 'Failed to update roles and permissions',
        error: e,
      })
    }
  })

  const dismiss = () => {
    UI.OVERLAY.dismiss();
  }

  useOnMount(() => {
    if (!AUTH.can.manage_.permissions) {
      UI.DIALOG.error({
        heading: 'You do not seem to have the permission to manage user permissions',
      })
      return;
    }
  })

  return <Observer children={() => (
    <AppPage className="OverlayUserPermissionManager" color="green">
      <AppPageHeader
        title={s.isUserDeleted ? 'User Permissions' : 'Manage Permissions'}
        afterTitle={<Observer children={() => (
          <div>
            <p>For User <UsernameRenderer user={p.user} />. {autoPluralize(s.selectedPermissions, 'permissions')} selected.</p>
            <div>
              {s.isAdmin && <ColorTagAdmin />}
              {s.isCounsellor && <ColorTagCounsellor userRoles={s.selectedRoles} />}
              {s.isModerator && <ColorTagModerator />}
              {s.isFacilitator && <ColorTagFacilitator />}
              {s.isCoordinator && <ColorTagCoordinator />}
              {s.isAnalyst && <ColorTagAnalyst />}
              {s.isFinancialAdministrator && <ColorTagFinancialAdministrator />}
            </div>
          </div>
        )} />}
        endSlot={<div className="OverlayUserPermissionManagerEndSlotWrapper">
          {s.haveUnsavedChanges && <>
            <ColorTag className="UnsavedChangesColorTag" uppercase color='red' nowrap>
              Unsaved Changes
            </ColorTag>
            <BaseSpacer inline />
          </>}
          <OverlayCloseButton />
        </div>}
      />
      <AppPageContent>
        <UIBlock padded>
          {s.hasNoUserGroupsToWorkWith && s.warningMessage}
          {s.isUserDeleted ? <ShadedBlock color={ColorCodedState.alert}>
            <h3>Deleted User</h3>
            <p>You cannot edit the permissions of a deleted user.</p>
          </ShadedBlock> : <ShadedBlock>
            <h3>How to manage permissions</h3>
            <BaseSpacer size=".5em" />
            <p>Below you will see app permissions grouped by responsibilities, roles or workflows. Some of them will show up in multiple groups. Your selection will dynamically determine what type or role this user is displayed to other users, and yon can preview them above as color-coded tags.</p>
            <p>For security reasons, the user will have to reload the app to see the new permissions in action.</p>
            <BaseSpacer size=".5em" />
            <footer className="OverlayUserPermissionManagerGlobalControlSet">
              <BaseButtonGroup>
                <BaseButton dataCy="applyChanges" onClick={saveChanges}>Apply your changes</BaseButton>
                <BaseButton className="subtle" onClick={dismiss}>Discard changes</BaseButton>
              </BaseButtonGroup>
              <BaseButtonGroup>
                <BaseButton className="subtle" onClick={toggleAll} label={s.allSelected ? 'Disable all permissions' : 'Enable all permissions'} />
              </BaseButtonGroup>
            </footer>
          </ShadedBlock>}
          <BaseSpacer size="md" />
          <PermissionRolesManager selectedStaffRoles={s.selectedRoles} permissionRoles={AppPermissionRoles} selectedPermissions={s.selectedPermissions} disabled={!!s.isUserDeleted} />
          <BaseButton className="subtle" icon="arrow" iconVariant="filled" iconPosition="left" onClick={s.toggleShowAdvancedSettings} dataCy="user-permission-manager-show-advanced-settings">Show Advanced Permissions</BaseButton>
          {s.shouldShowAdvancedSettings && <BaseSpacer size="sm" />}
          {s.shouldShowAdvancedSettings && Object.values(copyWithJSON(AppPermissionGroups)).filter(g => g.name !== 'All Permissions')
            .map(g => <PermissionGroupManager
              key={g.name}
              group={g}
              selectedPermissions={s.selectedPermissions}
              selectedRolePermissions={s.selectedRolePermissions}
              disabled={!!s.isUserDeleted}
            />)
          }
          <BaseSpacer size="md" />
          <ShadedBlock>
            <h3>Finished Editing?</h3>
            <BaseSpacer size="sm" />
            <BaseButtonGroup>
              <BaseButton onClick={saveChanges}>Apply your changes</BaseButton>
              <BaseButton className="subtle" onClick={dismiss}>Discard changes</BaseButton>
            </BaseButtonGroup>
            {s.hasNoUserGroupsToWorkWith && s.warningMessage}
          </ShadedBlock>
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
}

export default OverlayUserPermissionManager;