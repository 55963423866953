import React from "react";
import { Nillable } from "../base/@types";
import OverlaySupportGroupTopicEditor from "../base/components/OverlaySupportGroupTopicEditor/OverlaySupportGroupTopicEditor";
import { UIController } from "../controllers/ui.controller";
import { SupportGroupTopic } from "../models/makeSupportGroupTopic.model";

export const manageSupportGroupTopic = (
  UI: UIController,
  topic?: Nillable<SupportGroupTopic>,
  topicId?: Nillable<string>,
) => {
  UI.OVERLAY.present({
    component: <OverlaySupportGroupTopicEditor topic={topic} topicId={topicId} />,
    width: '75em',
    noBackdrop: true,
  })
}