import { action, observable } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseSpacer from '../../../base/components/BaseSpacer/BaseSpacer';
import CommandList from '../../../base/components/CommandList/CommandList';
import { CommandListItem } from '../../../base/components/CommandList/CommandListItem';
import NavBarHeightSpacer from '../../../base/components/NavBarHeightSpacer/NavBarHeightSpacer';
import { IconName } from '../../../base/components/Symbols/iconDefs';
import TabGroup from '../../../base/components/TabGroup/TabGroup';
import { TabConfig } from '../../../base/components/TabGroup/TabGroupItem';
import { useControllers } from '../../../base/hooks/useRootController.hook';
import { useStore } from '../../../base/utils/mobx.utils';
import ClientSupportGroupSchedule from '../../../components/ClientSupportGroupSchedule/ClientSupportGroupSchedule';
import SupportGroupFeed from '../../../components/SupportGroupFeed/SupportGroupFeed';
import { getWordPressLink } from '../../../env';
import ClientAppSubmodule from '../_components/ClientAppSubmodule/ClientAppSubmodule';
import ClientSupportGroupFinder from '../_components/ClientSupportGroupFinder/ClientSupportGroupFinder';
import './ModuleSupportGroups.scss';

interface ModuleSupportGroupsProps {}

const ModuleSupportGroups: React.FC<
  ModuleSupportGroupsProps
> = props => {

  const { UI, AUTH } = useControllers();

  const s = useStore(() => ({
    mobileShow: 'finder' as 'finder' | 'own',
  }))

  const t = useStore(() => ({
    mobileShowOptions: [
      observable({
        label: 'Find a group',
        icon: 'search' as IconName,
        onClick: action(() => {
          s.mobileShow = 'finder';
        }),
        active: () => s.mobileShow === 'finder',
      }),
      observable({
        label: 'Your Groups',
        icon: 'people' as IconName,
        onClick: action(() => {
          s.mobileShow = 'own';
        }),
        active: () => s.mobileShow === 'own',
      })
    ] as TabConfig[]
  }))

  return <Observer children={() => (
    <ClientAppSubmodule name="SupportGroups" title="Support Groups">
      <section className="ModuleSupportGroupsContent">
        <div className="ModuleSupportGroupsContentBody">
          {UI.uptoTabletLg && <TabGroup tabs={t.mobileShowOptions}/>}
          {(UI.fromTabletLg || s.mobileShow === 'finder') && (
            <ClientSupportGroupFinder blendIntoContext={UI.fromTabletLg} />
          )}
          {UI.uptoTabletLg && s.mobileShow === 'own' && (
            <ClientSupportGroupSchedule />
          )}
        </div>
        {
          UI.fromTabletLg && <aside className="ModuleSupportGroupsContentAside">
            <h3>Your Upcoming Groups</h3>
            {AUTH.currentUser && <SupportGroupFeed user={AUTH.currentUser} filter={{
              whereNull: 'timeStarted',
            }} invisibleLoader emptyNotice="You don't have any upcoming groups yet."/>}
            <BaseSpacer />
            <CommandList withBackground>
              <CommandListItem icon="arrow" to={getWordPressLink('/services/support-groups')} openInNewTab>Know more about support&nbsp;groups</CommandListItem>
              <CommandListItem icon="arrow" to="/app/account/support-groups">View your full support group history</CommandListItem>
            </CommandList>
          </aside>
        }
      </section>
      <NavBarHeightSpacer />
    </ClientAppSubmodule>
  )} />

}

export default ModuleSupportGroups
