import { Thread } from "@sentry/react";
import { Nullable, StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { ModelName } from "../constants/modelNames.enum";
import { User } from "./makeUser.model";

export const makeSupportTicketSnapshot = () => ({
  id: '',
  amount: 0,
  userId: '' as Nullable<string>,
  threadId: '',
})

export type SupportTicketSnapshot = ReturnType<typeof makeSupportTicketSnapshot>;

export type SupportTicketRelatedModels = {
  user: Nullable<User>,
  thread: Nullable<Thread>,
}

export type SupportTicketExtendedProperties = {}

export type SupportTicket = StandardModel<SupportTicketSnapshot, SupportTicketRelatedModels, SupportTicketExtendedProperties>;

export const makeSupportTicket = createStandardModelFactory<SupportTicket, SupportTicketRelatedModels, SupportTicketExtendedProperties>({
  name: ModelName.supportTickets,
  snapshotFactory: makeSupportTicketSnapshot,
  relationshipsSchema: {
    user: ModelName.users,
    thread: ModelName.chatThreads,
  },
})