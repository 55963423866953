import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ContextColor } from '../../constants/color.enum';
import joinClassName from '../../utils/className.utils';
import { getContextColorStyle, getContrastColorHex, isHexCode, withOpacity } from '../../utils/colors.utils';
import './ColorTag.scss';

interface ColorTagProps {
  color?: string;
  className?: string;
  nowrap?: boolean;
  capitalize?: boolean;
  uppercase?: boolean;
  appearance?: 'solid' | 'translucent';
  onClick?: () => void;
}

const ColorTag: React.FC<ColorTagProps> = props => {
  const isSolid = props.appearance === 'solid';
  const style = isHexCode(props.color) ? {
    backgroundColor: isSolid ? props.color : withOpacity(props.color, .38),
    color: isSolid ? getContrastColorHex(props.color) : props.color,
  } : getContextColorStyle(ContextColor.Primary, props.color);
  return <Observer children={() => (
    props.children ? <strong className={
      joinClassName(
        'ColorTag',
        props.className,
        props.nowrap && 'nowrap',
        props.capitalize && 'capitalize',
        props.uppercase && 'uppercase',
      )
    } style={style} data-appearance={props.appearance} onClick={props.onClick}>
      {props.children}
    </strong> : <></>
  )} />
}

export default ColorTag;