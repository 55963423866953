import { action, flow, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorResult, TwitterPicker } from 'react-color';
import { AnyObject } from '../../@types';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { DefaultColorPalette } from '../../utils/colors.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import tick from '../../utils/waiters.utils';
import { BaseInputProps } from '../BaseInput/BaseInput';
import BaseLabel from '../BaseLabel/BaseLabel';
import './ColorPicker.scss';

export interface ColorPickerProps<T extends object = object> extends Omit<BaseInputProps<T>, 'type'> {
}

const pickerColors = [
  DefaultColorPalette.red,
  DefaultColorPalette.firebrick,
  DefaultColorPalette.crimson,
  DefaultColorPalette.redOrange,
  DefaultColorPalette.orange,
  DefaultColorPalette.yellow,
  DefaultColorPalette.gold,
  DefaultColorPalette.brown,
  DefaultColorPalette.grassGreen,
  DefaultColorPalette.matcha,
  DefaultColorPalette.green,
  DefaultColorPalette.blueGreen,
  DefaultColorPalette.emerald,
  DefaultColorPalette.teal,
  DefaultColorPalette.skyblue,
  DefaultColorPalette.persianBlue,
  DefaultColorPalette.blue,
  DefaultColorPalette.navy,
  DefaultColorPalette.brightPurple,
  DefaultColorPalette.purple,
  DefaultColorPalette.royalPurple,
  DefaultColorPalette.plum,
  DefaultColorPalette.pink,
  DefaultColorPalette.steel,
]

const ColorPicker = <T extends AnyObject = AnyObject>(props: ColorPickerProps<T>) => {
  const p = useProps(props);
  const s = useStore(() => ({
    get value() {
      return p.form[p.field];
    },
    set value(v: string) {
      p.form[p.field] = v as any
    },
    handleChange: action((v: ColorResult) => {
      s.value = v.hex;
    })
  }))
  useOnMount(() => {
    return reaction(
      () => !s.value,
      flow(function * () {
        yield tick();
        if (!s.value) s.value = '#0000';
      }),
      { fireImmediately: true }
    )
  })
  return <Observer children={() => (
    <div className="ColorPicker" data-cy={p.dataCy}>
      <BaseLabel required={p.required} optional={p.optional}>{p.label}</BaseLabel>
      <div className="ColorPickerInner">
        { s.value && <TwitterPicker
          color={s.value}
          width="100%"
          colors={pickerColors}
          onChange={s.handleChange}
        /> }
      </div>
    </div>
  )} />
}

export default ColorPicker;