import { action, flow, observable, reaction, toJS, when } from "mobx";
import moment from "moment";
import { ThoughtCatcherBotStateKey } from "../../constants/storageKeys.constants";
import { CLOCK } from "../../controllers/common/clock.controller";
import { RootController } from "../../controllers/_controller.types";
import { isInCypressTestMode, SHOULD_LOG } from "../../env";
import { BotChatMessage, BotChatMessageSnapshot, makeAutomatedMessage, makeBotChatMessage } from "../../models/bots/makeBotChatMessage.model";
import { User } from "../../models/makeUser.model";
import { Identifier, Nillable, Nullable, Undefinable } from "../@types";
import { replaceArrayContent } from "../utils/array.utils";
import { playNotificationSound } from "../utils/audio.utils";
import { isAsyncFunction } from "../utils/functions.utils";
import { generateUuid } from "../utils/id.utils";
import { last } from "../utils/ramdaEquivalents.utils";
import { getNowTimestampUtc } from "../utils/time.utils";
import tick from "../utils/waiters.utils";

export interface BotAnalyzer<BotAnalyzerStateSnapshot = object> {
  welcome: () => Promise<true>,
  init: (snapshot?: BotAnalyzerStateSnapshot) => Promise<void>,
  stateSnapshot: BotAnalyzerStateSnapshot,
  patchStateSnapshot: (snapshot: BotAnalyzerStateSnapshot) => void,
  die: () => Promise<void>,
};

export type BotAnalyzerFactory<T, R extends BotAnalyzer<T>> = (bot: Bot<T, R>) => R;

export const makeBot = <BotAnalyzerStateSnapshot extends object, BotAnalyzerType extends BotAnalyzer<BotAnalyzerStateSnapshot>>() => {

  const s = observable({
    id: generateUuid(),
    analyzer: null as Nullable<BotAnalyzerType>,
    get user(): Nullable<User> {
      return s.rootController?.children.AUTH.currentUser || null;
    },
    timeEnded: null as Nullable<Date>,
    rootController: null as Nullable<RootController>,

    _messages: [] as BotChatMessage[],
    deletedMessageIds: [] as string[],
    get now() {
      return CLOCK.localNow;
    },
    get messages(): BotChatMessage[] {
      return s._messages.filter(m => !s.deletedMessageIds.find(id => id === m.id));
    },

    get botMessages(): BotChatMessage[] {
      return s.messages.filter(m => m.isAutomated);
    },
    get userMessages(): BotChatMessage[] {
      return s.messages.filter(m => !m.isAutomated);
    },
    get lastBotMessage(): Nillable<BotChatMessage> {
      return last(s.botMessages);
    },
    get lastUserMessage(): Nillable<BotChatMessage> {
      return last(s.userMessages);
    },
    botIsTyping: false,
    userLastTyped: null as Nullable<Date>,
    
    get waitTime(): number {
      return s.userHasUnsentMessage ? 3 : 3;
    },
    get userIsTyping(): boolean {
      if (s.userHasUnsentMessage) return true;
      if (!s.userLastTyped) return false;
      return moment(s.now).diff(s.userLastTyped, "seconds") < s.waitTime;
    },
    get userIsIdle(): boolean {
      if (!s.userLastTyped) return false;
      return moment(s.now).diff(s.userLastTyped, "seconds") < 10;
    },
    userCanRespond: false,
    userUnsentMessage: '',
    get userHasUnsentMessage(): boolean {
      return Boolean(s.userUnsentMessage.replace(/\s/g, ''));
    },
    _waitForBotToType: flow(function * (wait?: number) {
      yield tick();
      s.botIsTyping = true;
      if (wait !== 0) yield tick(1000, 2000);
      s.botIsTyping = false;
    }),
    pushMessage: (message: BotChatMessage, wait?: number) => new Promise<BotChatMessage | false>((resolve, reject) => {
      if (!message.body) { resolve(false); return; }
      if (message.isAutomated) {
        when(
          () => s.botIsTyping === false,
          flow(function* () {
            if (wait && wait > 0) yield tick(100);
            if (message.type === 'text') yield s._waitForBotToType(wait);
            s._messages.push(message);
            resolve(message);
          })
        )
      } else {
        s._messages.push(message);
        resolve(message);
      }
    }),
    sendBotMessage: (message: string | Partial<BotChatMessageSnapshot>, wait?: number) => new Promise<BotChatMessage | false>(async (resolve, reject) => {
      if (wait) await tick(isInCypressTestMode ? 0 : wait);
      if (s.ended) { resolve(false); return; }
      const m = await s.pushMessage(makeAutomatedMessage(message))
      // await tick(isInCypressTestMode ? 0 : Math.min(0.1, Math.max((wait ?? 0) * .1, .38)));
      resolve(m);
    }),
    sendBotMessageWithId: (id: string, message: string) => new Promise<BotChatMessage | false>(async (resolve, reject) => {
      if (s.ended) { resolve(false); return; }
      const m = s.pushMessage(makeAutomatedMessage({
        id,
        body: message,
      }));
      resolve(m);
    }),
    sendBotMessageIfIdle: (message: string | Partial<BotChatMessageSnapshot>) => new Promise<BotChatMessage | false>(async (resolve, reject) => {
      if (s.ended) { resolve(false); return; }
      if (s.userIsTyping) { resolve(false); return; }
      s.sendBotMessage(message);
    }),
    revertBotMessage: flow(function * (id: string) {
      if (s.ended) return;
      const message = s.messages.find(m => m.id === id);
      if (message) {
        message.timeDeleted = getNowTimestampUtc();
      }
      yield tick(300);
      s.deletedMessageIds.push(id);
    }),
    enableUserInput: action(() => s.userCanRespond = true),
    disableUserInput: action(() => s.userCanRespond = false),
    sendUserMessage: async (message: string) => {
      if (s.ended) return;
      await s.pushMessage(makeBotChatMessage({
        id: generateUuid(),
        body: message,
      }))
    },
    functionsToRunWhenUserStopsTyping: new Set<Function>(),
    waitForUserToStopTyping: (fn: Function, after?: number) => flow(function * () {
      if (!s.userIsTyping) { fn(); return; }
      if (after) yield tick(after);
      s.functionsToRunWhenUserStopsTyping.add(fn);
    })(),
    flushFunctionsToRunWhenUserStopsTyping: async () => {
      for (let fn of Array.from(s.functionsToRunWhenUserStopsTyping)) {
        if (isAsyncFunction(fn)) await fn(); else fn();
        s.functionsToRunWhenUserStopsTyping.delete(fn);
      }
    },
    sendStandardMessage: {
      notSure: () => s.sendBotMessage("Sorry, I'm not sure what you mean!"),
    },

    initiated: false,
    init: (
      analyzerFactory: BotAnalyzerFactory<BotAnalyzerStateSnapshot, BotAnalyzerType>,
      rootController: RootController,
    ): Promise<Bot<BotAnalyzerStateSnapshot, BotAnalyzerType>> => flow(function * () {
      if (s.initiated) {
        SHOULD_LOG() && console.error('Attempted to initiate same bot twice:', s);
        return s;
      }
      s.initiated = true;
      s.rootController = rootController;
      s.analyzer = analyzerFactory(s);
      // const snapshot: Sometimes<BotStateSnapshot<BotAnalyzerStateSnapshot>> = yield s.rootController?.children.STORAGE.get(ThoughtCatcherBotStateKey);
      yield s.readStateFromLocalStorage();
      // console.log('init bot');
      s.analyzer.init && s.analyzer.init();
      return s;
    })(),
    
    end: async () => await flow(function * () {
      yield s.rootController?.children.STORAGE.remove(ThoughtCatcherBotStateKey);
      s.die();
    })(),
    alive: true,
    get ended(): boolean {
      return Boolean(s.timeEnded);
    },
    get stateSnapshot(): BotStateSnapshot<BotAnalyzerStateSnapshot> {
      const messages = toJS(s._messages).filter(m => m.shouldPersist);
      const lastUserMessage = s.lastUserMessage;
      const lastUserMessageIndex = messages.findIndex(m => m.id === lastUserMessage?.id);
      if (lastUserMessageIndex >= 0) messages.splice(lastUserMessageIndex + 1);
      return {
        userId: s.user?.id,
        messages,
        deletedMessageIds: toJS(s.deletedMessageIds),
        analyzerState: s.analyzer?.stateSnapshot,
      };
    },
    persistStateInLocalStorage: async () => {
      const snapshot = s.stateSnapshot;
      if (snapshot.messages.every(m => m.isAutomated)) return;
      await s.rootController?.children.STORAGE.set(ThoughtCatcherBotStateKey, snapshot);
    },
    readStateFromLocalStorage: () => flow(function * () {
      // console.log('readStateFromLocalStorage');
      const snapshot: Nillable<BotStateSnapshot<BotAnalyzerStateSnapshot>> = yield s.rootController?.children.STORAGE.get(ThoughtCatcherBotStateKey);
      // console.log('snapshot:', snapshot);
      if (!snapshot) return;
      if (s.user?.id !== snapshot.userId) return;
      replaceArrayContent(s._messages, snapshot.messages.map(m => makeBotChatMessage(m)));
      replaceArrayContent(s.deletedMessageIds, snapshot.deletedMessageIds);
      if (snapshot.analyzerState) s.analyzer?.patchStateSnapshot(snapshot.analyzerState);
      // console.log('finished reading');
    })(),
    die: async () => {
      if (!s.alive) return;
      s.timeEnded = new Date();
      s.alive = false;
      disposeReactionToUserTyping();
      disposeBotNotificationSoundWatcher();
      disposeAutoPersistState();
      await s.analyzer?.die();
    },
  });

  const disposeAutoPersistState = reaction(
    () => s.stateSnapshot,
    () => s.persistStateInLocalStorage(),
  )

  const disposeReactionToUserTyping = reaction(
    () => s.userIsTyping === false,
    async () => {
      if (!s.userIsTyping) s.flushFunctionsToRunWhenUserStopsTyping();
    }
  )

  const disposeBotNotificationSoundWatcher = reaction(
    () => s.lastBotMessage?.id,
    () => playNotificationSound()
  )

  return s as Bot<BotAnalyzerStateSnapshot, BotAnalyzerType>;

}

export type BotStateSnapshot<BotAnalyzerStateSnapshot = object> = {
  userId: Nillable<Identifier>,
  messages: BotChatMessage[],
  deletedMessageIds: Identifier[],
  analyzerState: Undefinable<BotAnalyzerStateSnapshot>,
};

export type Bot<BotAnalyzerStateSnapshot, BotAnalyzerType extends BotAnalyzer<BotAnalyzerStateSnapshot>> = {
  id: Identifier,
  analyzer: Nullable<BotAnalyzerType>,
  user: Nullable<User>,
  rootController: Nullable<RootController>,
  timeEnded: Nullable<Date>,
  messages: BotChatMessage[],
  botMessages: BotChatMessage[],
  userMessages: BotChatMessage[],
  botIsTyping: boolean,
  userIsTyping: boolean,
  userIsIdle: boolean,
  userCanRespond: boolean,
  userUnsentMessage: string,
  userLastTyped: Nillable<Date>,
  lastUserMessage: Nillable<BotChatMessage>,
  pushMessage: (message: BotChatMessage, wait?: number) => Promise<BotChatMessage | false>,
  sendBotMessage: (message: string | Partial<BotChatMessage>, wait?: number) => Promise<BotChatMessage | false>,
  sendBotMessageWithId: (id: string, message: string) => Promise<BotChatMessage | false>,
  sendBotMessageIfIdle: (message: string | Partial<BotChatMessage>) => Promise<BotChatMessage | false>,
  revertBotMessage: (id: string) => void;
  enableUserInput: () => void;
  disableUserInput: () => void;
  sendUserMessage: (message: string) => Promise<void>,
  waitForUserToStopTyping: (fn: Function, after?: number) => Promise<void>,
  initiated: boolean,
  init: (analyzerFactory: BotAnalyzerFactory<BotAnalyzerStateSnapshot, BotAnalyzerType>, rootController: RootController) => Promise<Bot<BotAnalyzerStateSnapshot, BotAnalyzerType>>,
  end: () => Promise<void>,
  alive: boolean,
  ended: boolean,
  stateSnapshot: BotStateSnapshot<BotAnalyzerStateSnapshot>,
  persistStateInLocalStorage: () => Promise<void>,
  readStateFromLocalStorage: () => Promise<void>,
  die: () => Promise<void>,
}