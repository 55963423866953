import { StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { ApiModelName } from "../constants/ApiModels.enum";
import { ModelName } from "../constants/modelNames.enum";
import { ReactionType } from "../constants/ReactionType.enum";
import { hasTimestamps } from "../traits/hasTimestamps.trait";
import { Thought } from "./makeThought.model";
import { User } from "./makeUser.model";

export type ReactionSnapshot = ReturnType<typeof makeReactionSnapshotBase>;
export type ReactableModelNames = ApiModelName.THOUGHT | ApiModelName.COMMENT;
export type ReactableModel = Thought | Comment;

export type ReactionRelatedModels = {
  user: User,
  model: ReactableModel,
}
export type ReactionExtendedProperties = {}

export const makeReactionSnapshotBase = () => ({
  id: '',
  type: ReactionType.Like,
  userId: '',
  modelType: '' as ReactableModelNames,
  modelId: '',
  ...hasTimestamps(),
})

/**
 * Reactions are found under thoughts.
 * @see ReactionType for available types.
 */
export type Reaction = StandardModel<
  ReactionSnapshot,
  ReactionRelatedModels,
  ReactionExtendedProperties
>

export const makeReaction = createStandardModelFactory<Reaction, ReactionRelatedModels, ReactionExtendedProperties>({
  name: ModelName.reactions,
  snapshotFactory: makeReactionSnapshotBase,
  relationshipsSchemaFactory: s => ({
    user: ModelName.users,
    model: s?.modelType === ApiModelName.THOUGHT ? ModelName.thoughts : ModelName.comments
  })
})