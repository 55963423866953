import { Observer } from "mobx-react-lite";
import React from "react";
import { AnyObject } from "../../@types";
import joinClassName from "../../utils/className.utils";
import { DefaultDataRenderers } from "../../utils/dataRenderers.utils";
import { useProps, useStore } from "../../utils/mobx.utils";
import { BaseTableColumnConfig, BaseTableProps, getKeyNameFromColumnConfig } from "./BaseTable";
import './BaseTableBodyCell.scss';

export interface BaseTableBodyCellProps<DataType extends AnyObject = {}> extends BaseTableProps<DataType> {
  columnConfig: BaseTableColumnConfig<DataType>,
  data: DataType,
}

function BaseTableBodyCell<
  DataType extends AnyObject = {}, 
>(
  props: React.PropsWithChildren<BaseTableBodyCellProps<DataType>>
) {
  const p = useProps(props);
  const s = useStore(() => ({
    get config() {
      return p.columnConfig;
    },
    get keyName() {
      return s.config.keyName;
    },
    get computedKeyName() {
      return getKeyNameFromColumnConfig(s.config);
    },
    get type() {
      return s.config.type;
    },
    get defaultCellRenderer(): (d: DataType) => React.ReactElement {
      return s.defaultCellRendererByType || s.defaultCellRendererByKey;
    },
    get defaultCellRendererByType(): (d: DataType) => React.ReactElement {
      return s.type ? (
        (s.type as string) in DefaultDataRenderers ? (
          // @ts-ignore
          DefaultDataRenderers[s.type]
          // @ts-ignore
        ) : undefined
      ) : undefined;
    },
    get defaultCellRendererByKey(): (d: DataType) => React.ReactElement {
      return s.keyName ? (
        (s.keyName as string) in DefaultDataRenderers ? (
          // @ts-ignore
          DefaultDataRenderers[s.keyName]
          // @ts-ignore
        ) : (d: DataType) => d
      ) : (d: DataType) => '';
    },
    get style() {
      return s.config.bodyCellStyleFactory && s.config.bodyCellStyleFactory(p.data);
    }
  }));

  return <Observer children={() => (
    <td
      className={joinClassName(
        "BaseTableBodyCell",
        s.config.sortable && 'sortable'
      )}
      data-type={s.type}
      style={s.style}
      data-key-name={s.computedKeyName}
      data-original-key-name={s.keyName}
      data-cy={s.config.dataCy}
    >
      {
        s.config.BodyCell ? s.config.BodyCell(p.data) : (
          s.defaultCellRenderer(s.keyName ? p.data[s.keyName] : p.data)
        )
      }
    </td>
  )} />
}

export default BaseTableBodyCell;