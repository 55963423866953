import { makeIconDef } from "./makeIconDef";

export const KeepInTrayIconDef = {
  'keep-in-tray': makeIconDef(
    'keep-in-tray', {
    regular: () => <>
      <path d="M4.5 19C4.22386 19 4 19.2239 4 19.5C4 19.7761 4.22386 20 4.5 20V19ZM21.5 20C21.7761 20 22 19.7761 22 19.5C22 19.2239 21.7761 19 21.5 19V20ZM4.5 20H7V19H4.5V20ZM7.5 19.5V16.5H6.5V19.5H7.5ZM9 15H17.5V14H9V15ZM7 20H19.5V19H7V20ZM19.5 20H21.5V19H19.5V20ZM19 16.5V19.5H20V16.5H19ZM17.5 15C18.3284 15 19 15.6716 19 16.5H20C20 15.1193 18.8807 14 17.5 14V15ZM7.5 16.5C7.5 15.6716 8.17157 15 9 15V14C7.61929 14 6.5 15.1193 6.5 16.5H7.5Z" fill="currentColor" />
      <path d="M7 4L14.5 11.5M14.5 11.5H8.5M14.5 11.5V5.5" stroke="currentColor" strokeLinecap="round" />
      <path d="M7 15.5H5.5C4.94772 15.5 4.5 15.9477 4.5 16.5V19.5" stroke="currentColor" />
    </>,
    filled: () => <>
      <path d="M4.5 19C4.22386 19 4 19.2239 4 19.5C4 19.7761 4.22386 20 4.5 20V19ZM21.5 20C21.7761 20 22 19.7761 22 19.5C22 19.2239 21.7761 19 21.5 19V20ZM4.5 20H7V19H4.5V20ZM7.5 19.5V16.5H6.5V19.5H7.5ZM9 15H17.5V14H9V15ZM7 20H19.5V19H7V20ZM19.5 20H21.5V19H19.5V20ZM19 16.5V19.5H20V16.5H19ZM17.5 15C18.3284 15 19 15.6716 19 16.5H20C20 15.1193 18.8807 14 17.5 14V15ZM7.5 16.5C7.5 15.6716 8.17157 15 9 15V14C7.61929 14 6.5 15.1193 6.5 16.5H7.5Z" fill="currentColor" />
      <path opacity="0.8" d="M7 16.5V19.5H19.5V16.5C19.5 15.3954 18.6046 14.5 17.5 14.5H9C7.89543 14.5 7 15.3954 7 16.5Z" fill="currentColor" />
      <path d="M7 4L14.5 11.5M14.5 11.5H8.5M14.5 11.5V5.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M7 15.5H5.5C4.94772 15.5 4.5 15.9477 4.5 16.5V19.5" stroke="currentColor" />
      <path opacity="0.5" d="M5.5 15.5H7V19.5H4.5V16.5C4.5 15.9477 4.94772 15.5 5.5 15.5Z" fill="currentColor" />
    </>,
  }
  )
}