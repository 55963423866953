import { Alert } from '../../@types/alert.types';
import { makeRecordMap } from "../../base/utils/map.utils";
import { ModelName } from "../../constants/modelNames.enum";
import { Address } from "../../models/makeAddress.model";
import { Assignment } from "../../models/makeAssignment.model";
import { ChatMessage } from "../../models/makeChatMessage.model";
import { ChatParticipant } from "../../models/makeChatParticipant.model";
import { ChatThread } from "../../models/makeChatThread.model";
import { Comment } from "../../models/makeComment.model";
import { Company } from '../../models/makeCompany.model';
import { Configuration } from "../../models/makeConfiguration.model";
import { Contact } from "../../models/makeContact.model";
import { ContactForm } from "../../models/makeContactForm.model";
import { CounsellingApplication } from "../../models/makeCounsellingApplication.model";
import { CounsellingAvailability } from "../../models/makeCounsellingAvailability.model";
import { CounsellingSession } from "../../models/makeCounsellingSession.model";
import { Fee } from '../../models/makeFee.model';
import { Feedback } from "../../models/makeFeedback.model";
import { Flag } from "../../models/makeFlag.model";
import { Invitation } from '../../models/makeInvitation.model';
import { Invoice } from '../../models/makeInvoice.model';
import { InvoiceItem } from '../../models/makeInvoiceItem.models';
import { MediaItem } from '../../models/makeMediaItem.model';
import { ModeratedTerm } from '../../models/makeModeratedTerm.model';
import { Payment } from "../../models/makePayment.model";
import { Reaction } from "../../models/makeReaction.model";
import { Subscription } from '../../models/makeSubscription.model';
import { SupportGroup } from "../../models/makeSupportGroup.model";
import { SupportGroupReservation } from "../../models/makeSupportGroupReservation.model";
import { SupportGroupTopic } from "../../models/makeSupportGroupTopic.model";
import { SupportTicket } from '../../models/makeSupportTicket.model';
import { SurveyGAD7 } from "../../models/makeSurveyGAD7.model";
import { SurveyGeneral } from "../../models/makeSurveyGeneral.model";
import { SurveyGoalSheet } from "../../models/makeSurveyGoalSheet.model";
import { SurveyPHQ9 } from "../../models/makeSurveyPHQ9.model";
import { SurveySatisfaction } from "../../models/makeSurveySatisfaction.model";
import { SurveySupportGroupNonAttendance } from '../../models/makeSurveySupportGroupNonAttendance';
import { SurveySupportGroupSatisfaction } from '../../models/makeSurveySupportGroupSatisfaction';
import { Thought } from "../../models/makeThought.model";
import { User } from "../../models/makeUser.model";
import { LocalDBDataset } from "../localDB.controller";

export const makeDataSet = (): LocalDBDataset => ({
	[ModelName.addresses]: makeRecordMap<Address>(),
	[ModelName.alerts]: makeRecordMap<Alert>(),
	[ModelName.assignments]: makeRecordMap<Assignment>(),
	[ModelName.companies]: makeRecordMap<Company>(),
	[ModelName.chatMessages]: makeRecordMap<ChatMessage>(),
	[ModelName.chatParticipants]: makeRecordMap<ChatParticipant>(),
	[ModelName.chatThreads]: makeRecordMap<ChatThread>(),
	[ModelName.comments]: makeRecordMap<Comment>(),
	[ModelName.configurations]: makeRecordMap<Configuration>(),
	[ModelName.counsellingApplications]: makeRecordMap<CounsellingApplication>(),
	[ModelName.counsellingAvailabilities]: makeRecordMap<CounsellingAvailability>(),
	[ModelName.counsellingSessions]: makeRecordMap<CounsellingSession>(),
	[ModelName.contacts]: makeRecordMap<Contact>(),
	[ModelName.contactForms]: makeRecordMap<ContactForm>(),
	[ModelName.fees]: makeRecordMap<Fee>(),
	[ModelName.feedback]: makeRecordMap<Feedback>(),
	[ModelName.flags]: makeRecordMap<Flag>(),
	[ModelName.invitations]: makeRecordMap<Invitation>(),
	[ModelName.invoiceItems]: makeRecordMap<InvoiceItem>(),
	[ModelName.invoices]: makeRecordMap<Invoice>(),
	[ModelName.mediaItems]: makeRecordMap<MediaItem>(),
	[ModelName.moderatedTerms]: makeRecordMap<ModeratedTerm>(),
	[ModelName.payments]: makeRecordMap<Payment>(),
	[ModelName.reactions]: makeRecordMap<Reaction>(),
	[ModelName.subscriptions]: makeRecordMap<Subscription>(),
	[ModelName.supportGroupReservations]: makeRecordMap<SupportGroupReservation>(),
	[ModelName.supportGroups]: makeRecordMap<SupportGroup>(),
	[ModelName.supportGroupTopics]: makeRecordMap<SupportGroupTopic>(),
	[ModelName.supportTickets]: makeRecordMap<SupportTicket>(),
	[ModelName.surveysGAD7]: makeRecordMap<SurveyGAD7>(),
	[ModelName.surveysGeneral]: makeRecordMap<SurveyGeneral>(),
	[ModelName.surveysGoalSheet]: makeRecordMap<SurveyGoalSheet>(),
	[ModelName.surveysPHQ9]: makeRecordMap<SurveyPHQ9>(),
	[ModelName.surveysSupportGroupNonAttendance]: makeRecordMap<SurveySupportGroupNonAttendance>(),
	[ModelName.surveysSupportGroupSatisfaction]: makeRecordMap<SurveySupportGroupSatisfaction>(),
	[ModelName.surveysSatisfaction]: makeRecordMap<SurveySatisfaction>(),
	[ModelName.thoughts]: makeRecordMap<Thought>(),
	[ModelName.users]: makeRecordMap<User>(),
})
