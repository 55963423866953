import { Observer } from 'mobx-react-lite';
import React from 'react';
import AppPage from '../../base/components/AppPage/AppPage';
import AppPageContent from '../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../base/components/AppPageHeader/AppPageHeader';
import UIBlock from '../../base/components/UIBlock/UIBlock';
import { useControllers } from '../../base/hooks/useRootController.hook';
import SupportGroupFeed from '../SupportGroupFeed/SupportGroupFeed';
import './ClientSupportGroupSchedule.scss';

interface ClientSupportGroupScheduleProps {
  showHeader?: boolean,
}

const ClientSupportGroupSchedule: React.FC<ClientSupportGroupScheduleProps> = p => {

  const { AUTH } = useControllers();

  return <Observer children={() => (
    <AppPage className="ClientSupportGroupSchedule" color="blueGreen">
      {
        p.showHeader && <AppPageHeader title="Your Support Groups" />
      }
      <AppPageContent>
        <UIBlock padded="all">
          {AUTH.currentUser && <SupportGroupFeed user={AUTH.currentUser} />}
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
}

export default ClientSupportGroupSchedule;