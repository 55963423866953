import { flow } from 'mobx';
import { CounsellingApplicationEndpoints } from "../base/endpoints/counsellingApplication.endpoints";
import { getSnapshot } from "../base/utils/snapshot.utils";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { CounsellingApplicationSnapshot } from "../models/makeCounsellingApplication.model";
import { InvitationSnapshot } from "../models/makeInvitation.model";

export const saveCounsellingApplication = flow(function* (
  application: Partial<CounsellingApplicationSnapshot> & {
    invitation?: InvitationSnapshot
  },
  API: APIController,
  options?: {
    isStaff: boolean,
  }
) {
  const { AUTH } = API.ROOT!.children;
  const { currentUser } = AUTH;
  const urlType = currentUser?.isStaff ? "staff" : "own";

  console.log('urlType', urlType);
  const endpoint = application.id ? CounsellingApplicationEndpoints[urlType].update : CounsellingApplicationEndpoints[urlType].create;
  const url = endpoint(application.id!);
  const savedCounsellingApplication = application.id ? (
    yield API.patch(url, ModelName.counsellingApplications, getSnapshot(application))
  ) : (
    yield API.post(url, ModelName.counsellingApplications, getSnapshot(application))
  );
  return savedCounsellingApplication;
})