import { AxiosError } from 'axios';
import { action, flow, observable } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState, Nullable } from '../../../../base/@types';
import AppPage from '../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../base/components/AppPageHeader/AppPageHeader';
import BaseButton from '../../../../base/components/BaseButton/BaseButton';
import BaseButtonGroup from '../../../../base/components/BaseButtonGroup/BaseButtonGroup';
import BaseSpacer from '../../../../base/components/BaseSpacer/BaseSpacer';
import BaseTable from '../../../../base/components/BaseTable/BaseTable';
import BaseToggle from '../../../../base/components/BaseToggle/BaseToggle';
import BodyCopyRenderer from '../../../../base/components/BodyCopyRenderer/BodyCopyRenderer';
import ColorTagArchived from '../../../../base/components/ColorTag/ColorTagArchived';
import ColorTagCompany from '../../../../base/components/ColorTag/ColorTagCompany';
import ColorTagCompleted from '../../../../base/components/ColorTag/ColorTagCompleted';
import ColorTagDNR from '../../../../base/components/ColorTag/ColorTagDNR';
import ColorTagParent from '../../../../base/components/ColorTag/ColorTagParent';
import ColorTagRejected from '../../../../base/components/ColorTag/ColorTagRejected';
import DateRenderer from '../../../../base/components/DateRenderer/DateRenderer';
import ErrorBanner from '../../../../base/components/ErrorBanner/ErrorBanner';
import ErrorRenderer from '../../../../base/components/ErrorRenderer/ErrorRenderer';
import InfoBanner from '../../../../base/components/InfoBanner/InfoBanner';
import LoadingBlocker from '../../../../base/components/LoadingBlocker/LoadingBlocker';
import LoadingIndicatorSection from '../../../../base/components/LoadingIndicatorSection/LoadingIndicatorSection';
import OverlayCloseButton from '../../../../base/components/OverlayCloseButton/OverlayCloseButton';
import ShadedBlock from '../../../../base/components/ShadedBlock/ShadedBlock';
import UIBlock from '../../../../base/components/UIBlock/UIBlock';
import { CounsellingApplicationEndpoints, DefaultCounsellingApplicationIncludesWithSessionDetails } from '../../../../base/endpoints/counsellingApplication.endpoints';
import { useObservableRef } from '../../../../base/hooks/useObservableRef.hook';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import joinClassName from '../../../../base/utils/className.utils';
import { reportError } from '../../../../base/utils/errors.utils';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import { copyWithJSON } from '../../../../base/utils/object.utils';
import { getSnapshot } from '../../../../base/utils/snapshot.utils';
import { autoPluralize } from '../../../../base/utils/string.utils';
import { createUTCMoment } from '../../../../base/utils/time.utils';
import { setUrlParam, useSyncUrlParams } from '../../../../base/utils/urlParams.utils';
import CommunicationTypeRenderer from '../../../../components/CommunicationTypeRenderer/CommunicationTypeRenderer';
import CounsellingApplicationInfoTable from '../../../../components/CounsellingApplicationInfoTable/CounsellingApplicationInfoTable';
import CounsellingApplicationStatusColorTag from '../../../../components/CounsellingApplicationStatusColorTag/CounsellingApplicationStatusColorTag';
import CounsellingSessionsIndex from '../../../../components/CounsellingSessionsIndex/CounsellingSessionsIndex';
import CounsellingTypeColorTag from '../../../../components/CounsellingTypeColorTag/CounsellingTypeColorTag';
import EmailCounsellingManager from '../../../../components/EmailCounsellingManager/EmailCounsellingManager';
import InviteeConsentStatusDisplayer from '../../../../components/InviteeConsentStatusDisplayer/InviteeConsentStatusDisplayer';
import IrishClientFreeSessionInfo from '../../../../components/IrishClientFreeSessionInfo/IrishClientFreeSessionInfo';
import SlotTypeRenderer from '../../../../components/SlotTypeRenderer/SlotTypeRenderer';
import UserInfoTable from '../../../../components/UserInfoTable/UserInfoTable';
import UsernameRenderer from '../../../../components/UsernameRenderer/UsernameRenderer';
import { ApiModelName } from '../../../../constants/ApiModels.enum';
import { ModelName } from '../../../../constants/modelNames.enum';
import { APIGetManyResponse } from '../../../../controllers/api.controller';
import { useCounsellingApplicationComputedSet } from '../../../../hooks/useCounsellingApplicationComputedSet.hook';
import { CounsellingApplication, CounsellingApplicationSnapshot } from '../../../../models/makeCounsellingApplication.model';
import { SurveySatisfaction } from '../../../../models/makeSurveySatisfaction.model';
import { User } from '../../../../models/makeUser.model';
import { saveContact } from '../../../../requests/saveContact.request';
import { saveCounsellingApplication } from '../../../../requests/saveCounsellingApplication.request';
import { saveCounsellingSession } from '../../../../requests/saveCounsellingSession.request';
import { useGetCounsellingApplication } from '../../../../requests/useGetCounsellingApplication.request';
import { useGetUser } from '../../../../requests/useGetUser.request';
import { getCounsellingApplicationReadableStatus, isOfCounsellingTypeYoungPeople } from '../../../../utils/counsellingApplication.utils';
import { makeSatisfactionSurveyTableItemActions, satisfactionSurveyTableColumnConfigs } from '../../../../utils/satisfactionSurveys.utils';
import CounsellingApplicationActionsPanel from '../CounsellingApplicationActionsPanel/CounsellingApplicationActionsPanel';
import CounsellingApplicationArchiver from '../CounsellingApplicationArchiver/CounsellingApplicationArchiver';
import CounsellingClientProgressGraph from '../CounsellingClientProgressGraph/CounsellingClientProgressGraph';
import './OverlayApplicationManager.scss';

interface OverlayApplicationManagerProps {
  application?: CounsellingApplication,
  applicationId?: string,
}

const OverlayApplicationManager: React.FC<OverlayApplicationManagerProps> = props => {

  const { API, UI, AUTH, NAVIGATOR } = useControllers();

  const p = useProps(props);

  const enableEmailCounselling = true; // always display email counselling content here

  const s = useStore(() => {
    return {
      uneditedApplication: undefined as CounsellingApplicationSnapshot | undefined,
      _application: undefined as CounsellingApplication | undefined,
      isLoading: true,
      get application() {
        return p.application ?? s._application;
      },
      _applicant: undefined as User | undefined,
      get applicant() {
        return s.application?.applicant ?? s._applicant;
      },
      get invitee() {
        return s.application?.invitee;
      },
      showInEditMode: false,
      get sessions() {
        return s.application?.sessions ?? [];
      },
      error: null as Nullable<Error>,
      get id() {
        return p.application?.id ?? p.applicationId;
      },
      get isEditable() {
        return !s.application?.isArchived && !s.application?.isCompleted;
      },
      enterEditMode: action(() => {
        if (s.application?.isArchived) s.showInEditMode = false;
        s.showInEditMode = !s.showInEditMode
      }),
      get applicantId() {
        return s.application?.applicant?.id || s.application?.applicantId;
      },
      get statusDef() {
        const def = s.application ? getCounsellingApplicationReadableStatus(s.application) : null;
        return def;
      },
      get isConfirmed() {
        return s.statusDef?.status === 'approved'
      },
      get canBeApproved() {
        return s.application?.timeCreated && s.statusDef?.status === 'pending' && !application.isArchived;
      },
      // get isEditable() {
      //   return s.statusDef.status === 'pending'
      // },
      get showSessionCalendar() {
        if (enableEmailCounselling) return s.isConfirmed && !s.isEmail;
        else return s.isConfirmed;
      },
      get isYoungPersonApplication() {
        return isOfCounsellingTypeYoungPeople(s.application?.type);
      },
      get isEmail() {
        return s.application?.communicationTypePreference === 'email';
      },
      get shouldShowClientProgressGraph() {
        return s.application && s.isConfirmed && s.sessions.length > 0;
      },
      existingApplications: [] as CounsellingApplication[],
      get ongoingApplications() {
        return s.existingApplications.filter(a => !a.timeArchived && (a.id !== s.application?.id));
      },
      get alreadyHasApplications() {
        return s.ongoingApplications.length > 0;
      },
      get satisfactionSurveyAssignments() {
        return s.application?.assignments?.filter(ass => ass.targetType === ApiModelName.SURVEY_SATISFACTION && ass.targetId) || [];
      },
      get shouldShowIrishClientFreeSessionInfo() {
        return application.isIrishApplicant && s.applicantId && s.application;
      },
      get futureSessions() {
        return s.sessions.filter(s => !s.timeStarted && createUTCMoment(s.timeScheduled).isAfter());
      },
      get afterTitle() {
        return <>
          {s.applicant ? <strong>Client: <UsernameRenderer user={s.applicant} /> • </strong> : void 0}
          <SlotTypeRenderer isPaid={s.application?.isPaid} />
          <CounsellingTypeColorTag value={s.application?.type} />
          {application.shouldShowSubmitterAsParent && <ColorTagParent />}
          <CounsellingApplicationStatusColorTag application={s.application} />
          <ColorTagCompany name={s.application?.availability?.allowedCompany?.name}  code={s.application?.availability?.allowedCompany?.code} />
          {s.application?.invitee?.email && <> • <InviteeConsentStatusDisplayer invitation={s.application.invitation} /></>}
          {s.application && <> • <CommunicationTypeRenderer value={s.application.communicationTypePreference} /></>}
        </>
      },
      viewApplicant: () => {
        setUrlParam('userId', s.applicantId, NAVIGATOR);
      },
      viewInvitee: () => {
        setUrlParam('userId', s.application?.invitee?.id, NAVIGATOR);
      },
      get sessionIndex() {
        return <CounsellingSessionsIndex
          forApplicationId={s.id}
          application={s.application}
          applicant={s.applicant}
          applicantId={s.application?.applicantId}
          allowEventCreation={!application.isArchived && !application.isCompleted}
          allowSessionEditor
          allowedViewModes={['table', 'calendar']}
          defaultMode={!application.isArchived && !application.isCompleted ? "calendar" : 'table'}
        />
      }
    }
  })

  useSyncUrlParams('manageApplicationId', s.id);

  const application = useCounsellingApplicationComputedSet(observable({
    get application() {
      return s.application
    }
  }));

  useGetCounsellingApplication({
    observable: s,
    key: '_application',
    onDataFetch: action((a) => {
      s.isLoading = false;
      s.uneditedApplication = a;
      getExistingApplications();
    }),
    onError: action((e: AxiosError) => {
      s.error = e;
      UI.DIALOG.error({
        heading: 'Failed to get the data this application.',
        body: () => <>
          <p>Either this application does not exist or there was an server error.</p>
          <ErrorRenderer error={(e as any).response} />
        </>
      })
    })
  }, s.id, {
    include: DefaultCounsellingApplicationIncludesWithSessionDetails
  });

  const getExistingApplications = flow(function * () {
    const url = CounsellingApplicationEndpoints.staff.index({
      include: DefaultCounsellingApplicationIncludesWithSessionDetails,
      filter: { applicantId: s.applicantId },
      perPage: Infinity,
    });
    s.existingApplications = ((yield API.getMany(url, ModelName.counsellingApplications)) as APIGetManyResponse<CounsellingApplication>).entries;
  })

  useGetUser({
    observable: s,
    key: '_applicant',
  }, s.applicantId);

  const saveChanges = () => new Promise<CounsellingApplication | false>(flow(function* (resolve, reject) {
    try {
      if (!s.id) {
        reject(new TypeError('No ID found in application single page.'));
        return;
      }
      if (!s.application) {
        reject(new TypeError('No application to save.'));
        return;
      }
      if (s.application.counsellorId !== s.uneditedApplication?.counsellorId && s.futureSessions.length > 0) {
        const confirm = yield UI.DIALOG.present({
          heading: 'Counsellor Reassignment',
          body: () => <>
            <p>You have reassigned this application from <UsernameRenderer showColorLabel userId={s.uneditedApplication?.counsellorId} /> to <strong><UsernameRenderer showColorLabel userId={s.application?.counsellorId} /></strong>.</p>
            <p>All following upcoming sessions in this application will be reassigned to this new counsellor:</p>
            <ul>
              {s.futureSessions.map(d => <li key={d.id}>Session on {createUTCMoment(d.timeScheduled).local().format('lll')}</li>)}
            </ul>
          </>
        })
        if (!confirm) {
          resolve(false); return;
        }
        const requests = s.futureSessions.map(ses => flow(function* () {
          yield saveCounsellingSession(API, {
            ...getSnapshot(ses),
            counsellorId: s.application!.counsellorId,
          });
          ses.counsellorId = s.application!.counsellorId;
        }))
        for (let req of requests) yield req();
      }
      const contact = s.application.applicant?.primaryEmergencyContact;
      if (contact) yield saveContact(contact, API);
      const application: CounsellingApplication = yield saveCounsellingApplication(s.application, API);
      s.uneditedApplication = copyWithJSON(application.$snapshot);
      s.showInEditMode = false;
      UI.TOAST.success('Application saved.');
      resolve(application);
    } catch (e) {
      reject(e);
      reportError(e);
      UI.DIALOG.error({
        name: 'save-application-failed',
        heading: 'Failed to save application.',
        body: <ErrorRenderer error={(e as any).response} />,
        defaultActions: ['positive'],
      });
    }
  }))

  const onUpdateDNR = async () => {
    saveChanges();
    // MAYBE TODO? Should we prompt them to archive an application?
    // if (s.application?.didNotRespond && !s.application.timeArchived) {
    //   const confirm = await UI.DIALOG.present({
    //     heading: 'Successfully marked as DNR (Did Not Respond).',
    //     body: 'Would you like to also archive this application?',
    //   })
    //   if (confirm) {
    //     try {
    //       // const archivedApplication = await archiveCounsellingApplication(s.application, API);
    //       // UI.DIALOG.success({
    //       //   heading: 'Application archived.',
    //       // })
    //       // s.application.$patch(archivedApplication);
    //     } catch (e) {
    //       UI.DIALOG.error({
    //         heading: 'Failed to archive this application',
    //         body: <ErrorRenderer error={(e as any).response} />,
    //       })
    //     }
    //   }
    // }
  }

  const emailCounsellingManagerContainerRef = useObservableRef<HTMLDivElement>();

  const discardChanges = action(() => {
    if (s.uneditedApplication) s.application?.$patch(s.uneditedApplication);
    s.showInEditMode = false;
  })

  return <Observer children={() => (
    <AppPage className={
      joinClassName(
        "OverlayApplicationManager",
        s.application?.type,
        s.application?.timeRejected && 'rejected',
        s.application?.timeCompleted && 'completed',
        s.application?.isArchived && 'archived'
      )
    }>
      <AppPageHeader
        title={<Observer children={() => (
          <>
            <>Application #{s.id} </>
            {s.application?.isRejected && <ColorTagRejected />}
            {s.application?.timeCompleted ? <ColorTagCompleted /> : <>
              {application.isArchived && <ColorTagArchived />}
              {application.application?.didNotRespond && <ColorTagDNR />}
            </>}
          </>
        )} />}
        afterTitle={s.afterTitle}
        endSlot={
          <BaseButtonGroup>
            {
              s.showInEditMode ? <>
                <BaseButton size="sm" appearance="text" className="subtle" onClick={discardChanges}>Discard Changes</BaseButton>
                <BaseSpacer size="1em" inline/>
                <BaseButton name="save-counselling-application" onClick={saveChanges}>Save Changes</BaseButton>
              </> : <>
                {/* <PrintButton /> */}
                <OverlayCloseButton />
              </>
            }
          </BaseButtonGroup>
        }
        canGoBack="/admin/counselling/applications"
      />

      <AppPageContent>

        {
          s.application?.timeCompleted && <UIBlock padded>
            <InfoBanner colorCodedState={ColorCodedState.positive} icon="check-circle">
              <p>This application has been completed.</p>
            </InfoBanner>
            <BaseSpacer size=".62em"/>
          </UIBlock>
        }

        {
          s.application?.isArchived && <UIBlock padded>
            <InfoBanner color='median' icon="info">
              <p>This application has been archived.</p>
            </InfoBanner>
            <BaseSpacer size=".62em"/>
          </UIBlock>
        }

        { !s.application && (
          s.error ? <UIBlock padded><ErrorBanner error={s.error} /></UIBlock> : <LoadingIndicatorSection />
        )}

        { s.application &&
          <div className={
            joinClassName(
              'OverlayApplicationManagerInner',
              enableEmailCounselling && s.isConfirmed && s.isEmail && 'split'
            )
          }>

            {
              enableEmailCounselling && s.isConfirmed && s.isEmail && <div className="OverlayApplicationManagerInnerFrame --email">
                <UIBlock fullHeight
                  title="Email Counselling"
                  headerContent={
                    <p>Compose, view and manage all emails in this counselling program.</p>
                  }
                  innerRef={emailCounsellingManagerContainerRef}
                >
                  <BaseSpacer size=".62em" />
                  {s.shouldShowIrishClientFreeSessionInfo && (
                    <IrishClientFreeSessionInfo clientId={s.applicantId!} application={s.application!} />
                  )}
                  <BaseSpacer size=".62em" />
                  {s.application && <EmailCounsellingManager
                    application={s.application}
                    role="counsellor"
                    containerRef={emailCounsellingManagerContainerRef}
                  />}
                </UIBlock>
              </div>
            }

            <div className="OverlayApplicationManagerInnerFrame">

              {s.showSessionCalendar && <UIBlock padded title="Counselling Sessions">
                {s.shouldShowIrishClientFreeSessionInfo && (
                  <IrishClientFreeSessionInfo clientId={s.applicantId!} application={s.application!} />
                )}
                {s.sessionIndex}
              </UIBlock>}

              {s.shouldShowClientProgressGraph && <UIBlock padded title="Client Progress Graph">
                <CounsellingClientProgressGraph application={s.application!} sessions={s.sessions} />
              </UIBlock>}

              {
              s.application.isRejected && <UIBlock padded>
                <BaseSpacer size=".19em" />
                <InfoBanner className="OverlayApplicationManagerRejectionNotice" colorCodedState={ColorCodedState.alert} icon="warning" heading="Application Rejected">
                  <p>This application has been rejected on <DateRenderer value={s.application.timeRejected} format="LLLL" />.</p>
                  {
                    s.application.reasonForRejection && <>
                      <p>The reason for rejection:</p>
                      <BodyCopyRenderer source={s.application.reasonForRejection} />
                    </>
                  }
                </InfoBanner>
              </UIBlock>
              }

              {s.canBeApproved && <UIBlock padded title="Application Actions">

                <CounsellingApplicationActionsPanel application={s.application!} />

                {
                  s.alreadyHasApplications && <InfoBanner colorCodedState={ColorCodedState.attention} icon="warning"
                    heading={<>This user currently have {autoPluralize(s.ongoingApplications.length, 'pending or ongoing counselling application')}.</>}
                    body={<>
                      <p>You should check out the client's existing applications before deciding if this application should be approved.</p>
                    </>}
                  />
                }

              </UIBlock>}

              {/* <UIBlock title="Default Session Options"><CounsellingSessionDefaultOptionsSelectorSet application={s.application!} /></UIBlock> */}

              <UIBlock padded title="Applicant Overview" headerEndSlot={<Observer children={() => <BaseButton onClick={s.viewApplicant} size="xs" className="subtle">View Details</BaseButton>} />}>
                {s.applicant ? <UserInfoTable user={s.applicant} columns={s.isEmail && s.isConfirmed ? 1 : 2} /> : 'Loading...'}
              </UIBlock>

              {s.invitee && <UIBlock padded title="Invitee Overview" headerEndSlot={<Observer children={() => <BaseButton onClick={s.viewInvitee} size="xs" className="subtle">View Details</BaseButton>} />}>
                {s.invitee ? <UserInfoTable user={s.invitee} columns={s.isEmail && s.isConfirmed ? 1 : 2} /> : 'Loading...'}
              </UIBlock>}

              {/* { s.isYoungPersonApplication && <UIBlock title="Uploaded Personal ID"><BaseGallery className="PageAdminUserSingleUploadedMediaGallery" mediaItems={s.application?.media} /></UIBlock> } */}

              <UIBlock padded
                title="Application Details"
                headerEndSlot={
                  s.isEditable && (!s.showInEditMode ? <BaseButton size="xs" className="subtle" onClick={s.enterEditMode} label="Edit" /> : undefined)
                }
              >
                {s.application && <CounsellingApplicationInfoTable
                  application={s.application}
                  applicationSnapshot={s.application}
                  applicantSnapshot={s.application.applicant}
                  editable={s.showInEditMode}
                  availability={s.application.availability}
                  applicantAddressSnapshot={s.application.address}
                  applicantEmergencyContactSnapshot={s.application.applicant?.primaryEmergencyContact}
                  inviteeSnapshot={s.application.invitee}
                  invitationSnapshot={s.application.invitation}
                  doNotShowApplicantInfo
                />}
              </UIBlock>

              {
                s.application && <UIBlock padded
                  title="More Options"
                >
                  {
                    (!s.application.timeCompleted || s.application.didNotRespond) && <>
                      <ShadedBlock color={s.application.didNotRespond ? 'red' : undefined}>
                        <h3>DNR (Did Not Respond) Application?</h3>
                        <BaseToggle form={s.application} field="didNotRespond" label="The applicant did not respond when contacted" onChange={onUpdateDNR} />
                      </ShadedBlock>
                      <BaseSpacer size=".5em"/>
                    </>
                  }
                  <CounsellingApplicationArchiver application={s.application} />
                </UIBlock>
              }

              {
                AUTH.can.coordinate_.counsellingApplications && s.satisfactionSurveyAssignments.length > 0 && <UIBlock padded title="Satisfaction Survey">
                  <BaseTable<SurveySatisfaction>
                    entries={s.satisfactionSurveyAssignments.map(ass => ass.target) as SurveySatisfaction[]}
                    columnConfigs={satisfactionSurveyTableColumnConfigs}
                    itemActions={makeSatisfactionSurveyTableItemActions(UI)}
                  />
                </UIBlock>
              }

            </div>

          </div>
        }

        {(s.isLoading || !s.application?.timeCreated) && <LoadingBlocker />}

      </AppPageContent>

    </AppPage>
  )} />
}

export default React.memo(OverlayApplicationManager);