import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { useControllers } from '../../hooks/useRootController.hook';
import { useStore } from '../../utils/mobx.utils';
// import './NavigatorRedirector.scss';

interface NavigatorRedirectorProps {
}

const NavigatorRedirector: React.FC<NavigatorRedirectorProps> = props => {

  const { NAVIGATOR } = useControllers();

  const s = useStore(() => ({
    get _to() {
      return NAVIGATOR.shouldNavigateTo;
    },
    get to() {
      return s._to && s._to[0] === '?' ? window.location.pathname + s._to : s._to;
    },
  }));

  return <Observer children={() => (
    <div className="NavigatorRedirector">
      { s.to && <Redirect to={s.to} />}
    </div>
  )} />

}

export default NavigatorRedirector;