import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useProps } from '../../base/utils/mobx.utils';
import './Turn2MeLogo.scss';

interface Turn2MeLogoProps {
  version?: 'text' | 'full';
  areaTag?: 'Admin';
}

const Turn2MeLogo: React.FC<Turn2MeLogoProps> = props => {

  const p = useProps(props);

  return <Observer children={() => (
    <div className="Turn2MeLogo" data-version={p.version ?? 'text'}>
      {p.version === 'full' ? <svg viewBox="0 0 200 54">
        <use xlinkHref="#turn2me-logo-full" />
      </svg> : <span>turn2me</span>}
      {p.areaTag && <span className="Turn2MeLogoAreaTag">&nbsp;{p.areaTag}</span>}
    </div>
  )} />

}

export default Turn2MeLogo;