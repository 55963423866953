import { Observer } from 'mobx-react-lite';
import React from 'react';
import { SortDirection } from '../../@types';
import './SortToggle.scss';

interface SortToggleProps {
  direction?: SortDirection | null;
}

const SortToggle: React.FC<SortToggleProps> = props => {
  return <Observer children={() => (
    <svg className="SortToggle" data-direction={props.direction || 'off'} viewBox="0 0 5 11" fill="currentColor">
      <path d="M2.5 0L0 4H5L2.5 0Z" className="SortToggleUpArrow" />
      <path d="M2.5 11L5 7L-1.27146e-07 7L2.5 11Z" className="SortToggleDownArrow" />
    </svg>
  )} />
}

export default SortToggle;