import { action, flow, observable, reaction } from 'mobx';
import moment from 'moment';
import React from 'react';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { getDiffFromTimeStamps, getNowTimestampUtc, YYYYMMDDHHmmss } from '../../base/utils/time.utils';
import { isNumberLike } from '../../base/utils/typeChecks.utils';
import tick from '../../base/utils/waiters.utils';
import { IS_DEV } from '../../env';
import OverlaySurveyGeneral from '../OverlaySurveyGeneral/OverlaySurveyGeneral';
// import './SurveyGeneralWatcher.scss';

type SurveyGeneralWatcherProps = {}

const _ = observable({
	shouldDisableGeneralSurvey: false,
})

if (window.Cypress) {
	Reflect.set(window, 'disableGeneralSurvey', action(() => {
		_.shouldDisableGeneralSurvey = true;
	}))
}

const SurveyGeneralWatcher: React.FC<SurveyGeneralWatcherProps> = props => {
	const { AUTH, COMMON, NAVIGATOR, MESSENGER, UI } = useControllers();
	useOnMount(() => {
		return reaction(
			() => (
				AUTH.currentUser?.preferences.generalInfoSurveyResponse
			),
			flow(function* (response) {
				if (_.shouldDisableGeneralSurvey) return;
				yield tick(2000);
				if (!AUTH.currentUser) return;
				if (response === 'completed' || response === 'dismissed') return;
				if (_.shouldDisableGeneralSurvey) return;
				if (isNumberLike(response?.[0])) {
					const isValid = moment.utc(response).isValid();
					if (!isValid) return;
					// presume it's a date when the user chose "ask me later". only prompt the user again after 10 minutes.
					const diff = moment().diff(moment.utc(response), 'minutes');
					if (diff < 10) return;
				}
				if (!AUTH.isAuthenticated) return;
				if (NAVIGATOR.currentLocationPathname.match(/^\/app\/(invitation)/)) return;
				if (MESSENGER.dockedChats.length) return;
				const before1Day = getDiffFromTimeStamps(AUTH.currentUser.timeCreated, COMMON.CLOCK.localNowMoment.format(YYYYMMDDHHmmss), 'day') < 1;
				const allowSkip = (typeof before1Day === 'boolean' ? before1Day : true);
				const confirm = yield UI.DIALOG.present({
					name: 'confirm-general-survey-participation',
					heading: 'Welcome to turn2me!',
					body: <>
						<p>Glad you have decided to join turn2me! We would really appreciate it if you could help us improve our services by participating in our mandatory user survey. It is fully anonymous, and should take you only a minute or two.</p>
					</>,
					actions: [
						...(allowSkip || IS_DEV || AUTH.isStaff ? [{
							name: 'delay',
							label: "Ask me later",
							buttonClass: 'subtle',
							action: () => false,
						}] : []),
						{
							name: 'continue',
							label: 'Sure!',
							action: () => true,
						}
					]
				})
				if (!confirm) {
					AUTH.currentUser.preferences.generalInfoSurveyResponse = getNowTimestampUtc();
					AUTH.saveCurrentUser();
					return;
				}
				UI.OVERLAY.present({
					name: 'OverlaySurveyGeneral',
					component: <OverlaySurveyGeneral />
				}, {
					waitForOtherOverlaysToClose: true
				})
			}),
			{ fireImmediately: true }
		)
	})
	return null;
}

export default SurveyGeneralWatcher;