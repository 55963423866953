import { isObservable, makeAutoObservable } from "mobx";

export interface IBasePaginationStateOptions {
  currentPage: number,
  perPage: number,
  totalItems: number,
  doNotSyncWithURL?: boolean,
}
export type IBasePaginationState = IBasePaginationStateOptions & {
  lastPage: number,
}
const makeDefaultPaginationState: () => IBasePaginationStateOptions = () => ({
  currentPage: 1,
  perPage: 15,
  totalItems: 0,
})

export default class BasePaginationState implements IBasePaginationState {

  options: IBasePaginationStateOptions = makeDefaultPaginationState();

  get currentPage() { return this.options.currentPage; }
  set currentPage(n: number) { this.options.currentPage = n; }

  get lastPage() { return this.totalItems ? Math.ceil(this.totalItems / this.perPage) : 1 }

  get perPage() { return this.options.perPage; }
  set perPage(n: number) { this.options.perPage = n; }

  get doNotSyncWithURL() { return this.options.doNotSyncWithURL ?? false; }
  set doNotSyncWithURL(v: boolean) { this.options.doNotSyncWithURL = v; }

  get totalItems() { return this.options.totalItems; }
  set totalItems(n: number) { this.options.totalItems = n; }

  constructor(options?: Partial<IBasePaginationStateOptions>) {
    if (options && isObservable(options)) {
      this.options = options as IBasePaginationStateOptions
    } else {
      Object.assign(this.options, options);
    }
    makeAutoObservable(this);
  }

}