import { reportError } from "./errors.utils";
import { NoOp } from "./functions.utils";

export default function addListener(element?: Window | HTMLElement | any, events?: string | string[], handler?: Function): () => void | undefined {
  const eventList = events instanceof Array ? events : (events || '').split(' ');
  try {
    eventList.forEach(ev => {
      // @ts-ignore
      element.addEventListener(ev, handler);
    })
    return () => {
      eventList.forEach(ev => {
      // @ts-ignore
        element.removeEventListener(ev, handler);
      })
    }
  } catch(e) {
    reportError(e);
  }
  return NoOp
}