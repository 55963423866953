import { action, autorun, flow, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { CSSProperties, ReactText } from 'react';
import ContentEditable from 'react-contenteditable';
import { AnyObject } from '../../@types';
import { ColorCodedState, Renderable } from '../../@types/ui.types';
import { Validator, ValidatorResult } from '../../@types/validators.types';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { ObservableRef, useObservableRef } from '../../hooks/useObservableRef.hook';
import joinClassName from '../../utils/className.utils';
import { checkIfShouldInvertStyle } from '../../utils/colors.utils';
import { makeDisposerController } from '../../utils/disposer.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { isString } from '../../utils/typeChecks.utils';
import tick from '../../utils/waiters.utils';
import { validateDate } from '../../validators/date.validator';
import BaseIcon from '../BaseIcon/BaseIcon';
import BaseLabel from '../BaseLabel/BaseLabel';
import './BaseInput.scss';

export type TextInputType = 'text' | 'hidden' | 'contentEditable' | 'number' | 'email' | 'tel' | 'url' | 'date' | 'time' | 'password' | 'textarea' | 'search' | 'color';
export type TextInputEvent = React.FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>;
export type TextInputKeyboardEvent = React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>;

export interface BaseInputProps<FormType extends AnyObject> {
  className?: string;
  name?: string;
  form: FormType;
  field: keyof FormType & string;
  label?: ReactText;
  applyOnBlur?: boolean;
  applyOnlyIfValid?: boolean;
  validators?: Validator[];
  formatter?: (v: any) => unknown;
  type?: TextInputType;
  min?: number | string;
  max?: number | string;
  step?: number | string;
  rows?: number | string;
  disabled?: any;
  placeholder?: string;
  canClear?: boolean;
  defaultEmptyValue?: any;
  onClick?: (e?: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => unknown;
  onEnter?: (e?: TextInputKeyboardEvent) => unknown;
  onFocus?: (e?: TextInputEvent) => unknown;
  onKeyUp?: (e?: TextInputEvent) => unknown;
  onBlur?: (e?: TextInputEvent) => unknown;
  onChange?: (value?: any) => unknown;
  showHideKeyboardIcon?: boolean;
  innerRef?: ObservableRef<HTMLInputElement | HTMLTextAreaElement>;
  colorCodedState?: ColorCodedState | '';
  required?: boolean;
  optional?: boolean;
  inlineLabel?: boolean;
  pattern?: RegExp | string;
  infoAfterInputField?: string | React.ReactElement;
  enterKeyHint?: 'enter' | 'done' | 'search' | 'go' | 'next' | 'previous' | 'send';
  autoSelect?: boolean,
  resize?: boolean | 'none' | 'both' | 'horizontal' | 'vertical' | 'initial' | 'inherit',
  autoFocus?: boolean,
  autoComplete?: string;
  autoCorrect?: string | 'off',
  autoCapitalize?: string | 'none';
  endOfLabel?: Renderable,
  dataCy?: string,
  inputStyle?: CSSProperties,
  hasError?: boolean,
}

export const BaseInput = <T extends object = object>(props: BaseInputProps<T>) => {

  const p = useProps(props);

  const componentRef = useObservableRef<HTMLDivElement>();
  const localRef = useObservableRef<HTMLInputElement | HTMLTextAreaElement>();

  const s = useStore(() => ({
    get model() {
      return p.form;
    },
    get target() {
      return p.field;
    },
    get type() {
      return p.type || 'text';
    },
    get name() {
      return p.name || `${p.field}`;
    },
    touched: false,
    value: '',
    get validationResults(): ValidatorResult[] {
      const inputValidators = [
        ...(p.validators || []),
        ...(p.type === 'date' ? [validateDate] : []),
      ];
      const result = inputValidators.map(v => v(s.value));
      return result;
    },
    get isValid(): boolean {
      return s.validationResults.every(i => i === true);
    },
    get hasError(): boolean {
      // force input[type='date'] with no value to not be an error.
      if (s.type === 'date' && !s.value) return false;
      return p.hasError || !s.isValid;
    },
    get placeholder(): string {
      if (p.placeholder) return p.placeholder;
      switch (s.type) {
        case 'search': return 'Search...';
        case 'date': return 'yyyy-mm-dd';
        case 'time': return '--:--';
        default: return '';
      }
    },
    get formatter() {
      const formatter = p.formatter ??
        (s.type === 'number' ? (v: number) => +v : (v: any) => v);
      return formatter;
    },
    get hasActions() {
      return p.canClear || p.showHideKeyboardIcon;
    },
    filterByPattern<T>(v: T): T {
      if (!v) return v;
      let pattern = p.pattern;
      if (!pattern) return v;
      if (pattern === '[0-9]*') pattern = /\d/;
      let result = '';
      if (typeof pattern === 'string') {
        result = (v + '').split('').filter(i => (pattern as string).includes(i)).join('');
      }
      else result = (v + '').split('').filter(i => i.match((pattern as RegExp))).join('')
      // console.log(result);
      switch (typeof v) {
        // @ts-ignore
        case 'string': return result;
        // @ts-ignore
        case 'number': return +result;
        default: return v;
      }
    },
    handleChange:  action((e: TextInputEvent) => {
      const newValueRaw = e.currentTarget.nodeName === 'DIV' ? e.currentTarget.innerText : (e.currentTarget as HTMLInputElement | HTMLTextAreaElement).value;
      const newValue = s.filterByPattern(newValueRaw);
      if (newValue === s.value) return;
      s.updateInnerValue(newValue);
      if (p.applyOnBlur) return;
      else s.applyValue(newValue);
    }),
    applyValue: action((v: any) => {
      let newValue = s.formatter(v);
      let shouldUpdate = p.applyOnlyIfValid ? s.isValid : true;
      if (shouldUpdate) {
        s.model && (s.model[s.target] = newValue as any);
        p.onChange?.(newValue);
      }
    }),
    updateInnerValue: action((v: any) => s.value = v),
    get defaultEmptyValue() {
      return p.defaultEmptyValue === void 0 ? null : p.defaultEmptyValue;
    },
    clearValue: action(() => {
      s.applyValue(s.defaultEmptyValue);
      s.focus();
    }),
    isInverted: false,
    hasFocus: false,
    handleFocus: action((e?: TextInputEvent) => {
      s.hasFocus = true;
      if (p.onFocus) p.onFocus(e);
      // yield tick(162);
      // UI.measureTemporaryWindowDimensionOverrides();
    }),
    handleBlur: flow(function * (e?: TextInputEvent) {
      e && e.preventDefault();
      if (p.applyOnBlur) s.applyValue(s.value);
      s.touched = true;
      if (p.onBlur) p.onBlur(e);
      yield tick(100);
      s.innerRef.current && s.innerRef.current.blur();
      s.hasFocus = false;
      // yield tick(162);
      // if (UI.onlyPhones) enableDocumentScroll();
      // UI.discardTemporaryWindowDimensionOverrides();
    }),
    focus: () => {
      s.innerRef.current && s.innerRef.current.focus();
      s.handleFocus();
    },
    blur: () => {
      s.innerRef.current && s.innerRef.current.blur();
      s.handleBlur();
    },
    handleKeyup: (e: TextInputKeyboardEvent) => {
      p.onKeyUp?.(e);
      if (e.key === 'Enter' || ((e.which || e.keyCode) === 13)) {
        p.onEnter && p.onEnter(e);
      }
    },
    get className() {
      return joinClassName(
        'BaseInput',
        p.className,
        (s.touched && p.colorCodedState) && `state-${p.colorCodedState}`,
        (s.touched && !s.isValid) && 'invalid',
        p.disabled && 'disabled',
        s.hasFocus && 'focus',
        s.hasActions && 'hasActions',
        s.isInverted && 'inverted',
        s.hasError && 'error'
      )
    },
    get actionButtons() {
      return [
        p.canClear && <BaseIcon key="clearButton" className="BaseInput__clearButton" name="close" onClick={s.clearValue} />,
        p.showHideKeyboardIcon && <BaseIcon key="hideKeyboardIcon" className="BaseInputHideKeyboardIcon" name="hide-keyboard" onClick={s.blur} />,
      ].filter(i => i);
    },
    get enterKeyHint() {
      let { enterKeyHint } = p;
      if (!enterKeyHint) {
        switch (s.type) {
          case 'search': enterKeyHint = 'search'; break;
          default: break;
        }
      }
      return enterKeyHint;
    },
    get commonAttributes() {
      return {
        'data-cy': p.dataCy,
        value: s.value,
        name: s.name,
        placeholder: p.placeholder,
        onClick: p.onClick,
        onChange: s.handleChange,
        onFocus: s.handleFocus,
        onBlur: s.handleBlur,
        onKeyUp: s.handleKeyup,
        disabled: p.disabled,
        autoComplete: p.autoComplete,
        required: p.required,
        enterKeyHint: s.enterKeyHint,
        autoFocus: p.autoFocus,
        pattern: p.pattern ? p.pattern + '' : undefined,
        autoCorrect: p.autoCorrect,
        autoCapitalize: p.autoCapitalize,
      }
    },
    get innerRef() {
      return p.innerRef || localRef;
    },
    get resize() {
      if (isString(p.resize)) return p.resize;
      return p.resize ? undefined : 'none';
    }
  }));

  useOnMount(action(() => {
    const d = makeDisposerController();
    s.isInverted = checkIfShouldInvertStyle(componentRef);
    if (p.autoSelect) {
      d.add(when(
        () => Boolean(s.innerRef.current),
        () => {
          s.innerRef.current!.focus()
          s.innerRef.current!.select()
        },
      ))
    }
    d.add(autorun(() => {
      s.updateInnerValue((s.model?.[s.target]) ?? '')
    }))
    return d.disposer;
  }))

  return <Observer children={() => (
    <div className={s.className} ref={componentRef} data-name={p.name || p.field}>

      { p.label && <BaseLabel
        children={p.label}
        inline={p.inlineLabel}
        required={p.required}
        optional={p.optional}
        endSlot={p.endOfLabel}
      />}

      <div className="BaseInputInner">
        {s.type === 'textarea' ? (
          <textarea ref={s.innerRef as ObservableRef<HTMLTextAreaElement>} className="BaseInputTextarea" rows={p.rows ? +p.rows : undefined} {...s.commonAttributes} style={{resize: s.resize}}/>
        ) : (
            s.type === 'contentEditable' ? (
              <ContentEditable className="BaseInputContentEditable" html={s.value} onChange={s.handleChange} disabled={p.disabled} data-cy={p.dataCy}/>
            ) : (
                <input
                  ref={s.innerRef as ObservableRef<HTMLInputElement>}
                  className="BaseInputInput"
                  type={s.type}
                  min={p.min}
                  max={p.max}
                  step={p.step}
                  style={{...p.inputStyle}}
                  {...s.commonAttributes}
                />
              )
          )}
        {s.actionButtons.length > 0 && <div className="BaseInputActionButtonGroup">{s.actionButtons}</div>}
      </div>

      {p.infoAfterInputField && <div className="BaseInputInfoAfterInputField">
        {p.infoAfterInputField}
      </div>}

    </div>
  )} />
}


export default BaseInput;