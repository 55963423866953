import { isObject } from "./typeChecks.utils";

const timers: Record<string, any> = {};

type DebouncerOptions = {
  scope?: Function,
  timeout?: number,
  fireImmediately?: boolean | Function,
  resetAfter?: number,
  id?: string,
}
export function debounce<T extends Function>(
  fn: T,
  options: number | DebouncerOptions = {},
): T {
  const {
    timeout = 500,
    // @ts-ignore
    scope = this,
    fireImmediately,
    resetAfter,
    id,
  } = isObject(options) ? options : { timeout: options } as DebouncerOptions;
  let timer: any;
  let hasFiredImmediately = false;
  let hasFiredResetTimer: any;
  const doAfterFireImmediately = () => {
    hasFiredImmediately = true;
    if (resetAfter) {
      hasFiredResetTimer && clearTimeout(hasFiredResetTimer);
      hasFiredResetTimer = setTimeout(() => hasFiredImmediately = false, resetAfter);
    }
  }
  // @ts-ignore
  return (...args) => {
    const fire = () => {
      if (id) delete timers[id];
      return fn.apply(scope, args);
    }
    if (fireImmediately && !hasFiredImmediately) {
      if (typeof fireImmediately === 'function') {
        fireImmediately();
      } else fire();
      doAfterFireImmediately();
    }
    if (id && id in timers) {
      timer = timers[id];
    }
    timer && clearTimeout(timer);
    timer = setTimeout(fire, timeout);
    if (id) timers[id] = timer;
  }
}