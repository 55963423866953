import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { ChatMediator } from '../../base/mediators/chat.mediator';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import ChatMessageComposer from '../ChatMessageComposer/ChatMessageComposer';
import './ChatWindowBody.scss';
import ChatWindowContent from './ChatWindowContent';
import ChatWindowParticipantList from './ChatWindowParticipantList';
import { ChatWindowStackMode } from './ChatWindowStack';

interface ChatWindowBodyProps {
  mode?: ChatWindowStackMode,
  chat: ChatMediator;
  showParticipantList?: boolean,
}

function ChatWindowBody(props: React.PropsWithChildren<ChatWindowBodyProps>) {

  const { COMMON, AUTH } = useControllers();
  const p = useProps(props);

  const s = useStore(() => ({
    get muted() {
      return !!p.chat.selfParticipant?.timeMuted;
    },
    get notAParticipant() {
      return p.chat.currentUserIsNotAParticipant;
    },
    get chatHasEnded() {
      return p.chat.hasEnded;
    },
    get appIsOffline() {
      return !COMMON.online
    },
    get currentUserIsCounsellorOrAdmin() {
      return AUTH.isCounsellor || AUTH.isStaff
    },
  }));

  return <Observer children={() => (
    <div className="ChatWindowBody" data-participant-list-visible={p.showParticipantList}>
      <div className="ChatWindowBodyInner">
        {
          p.showParticipantList && (
            <ChatWindowParticipantList mode={p.mode} chat={p.chat} />
          )
        }
        <ChatWindowContent chat={p.chat} />
      </div>
      <footer className="ChatWindowBodyFooter">
        {s.muted && <div className="ChatWindowBodyNotice ChatWindowBodyChatMutedNotice u-no-print">
          <BaseIcon icon="info" variant="filled"/>
          <p>You have been muted by the admin.</p>
        </div>}
        {s.notAParticipant && <div className="ChatWindowBodyNotice ChatWindowBodyChatNotParticipantNotice u-no-print">
          <BaseIcon icon="info" variant="filled"/>
          <p>You are not a participant of this chat.</p>
        </div>}
        {s.chatHasEnded && <div className="ChatWindowBodyNotice ChatWindowBodyChatOffNotice">
          <BaseIcon icon="info" variant="filled"/>
          <p>The session ended on <DateRenderer value={p.chat.thread.timeEnded} />.</p>
        </div>}
        {s.appIsOffline && <div className="ChatWindowBodyNotice ChatWindowBodyOfflineNotice u-no-print">
          <BaseIcon icon="info" variant="filled"/>
          <p>You are currently offline.</p>
        </div>}
        {!p.chat.hasEnded && <ChatMessageComposer chat={p.chat} />}
      </footer>
    </div>
  )} />
}

export default ChatWindowBody;