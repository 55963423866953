import { when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Renderable } from '../../../../base/@types';
import { useOnMount } from '../../../../base/hooks/lifecycle.hooks';
import { useCreateResizeQueryWithRef } from '../../../../base/hooks/useCreateResizeQueryWithRef.hook';
import { ObservableRef } from '../../../../base/hooks/useObservableRef.hook';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import { renderRenderable } from '../../../../base/utils/components.utils';
import { removeCSSCustomProperty, setCSSCustomProperty } from '../../../../base/utils/css.utils';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import { isString } from '../../../../base/utils/typeChecks.utils';
import tick from '../../../../base/utils/waiters.utils';
import Turn2MeLogo from '../../../../components/Turn2MeLogo/Turn2MeLogo';
import UserComputedRoleList from '../../../../components/UserComputedRoleList/UserComputedRoleList';
import AdminNavControlSet from '../AdminNavControlSet/AdminNavControlSet';
import './AdminTitleBar.scss';

interface AdminTitleBarProps {
	title?: Renderable,
	stringTitle?: string,
	setInnerElScrollHeight?: (height: number) => void,
	setInnerRef?: (ref: ObservableRef<HTMLDivElement>) => void,
}

const CSSCustomPropertyName = '--TitleBarHeight';

const AdminTitleBar: React.FC<AdminTitleBarProps> = props => {

	const p = useProps(props);

	const { UI, AUTH } = useControllers();

	const { ref, query } = useCreateResizeQueryWithRef<HTMLDivElement>();

	useOnMount(() => {
		when(
			() => Boolean(ref.current),
			() => {
				const resizeHandler = async () => {
					await tick();
					when(() => Boolean(ref.current), () => {
						p.setInnerRef?.(ref);
						p.setInnerElScrollHeight?.(query.scrollHeight);
						const parent = ref.current?.closest<HTMLElement>('[class*="Module"]');
						if (!parent) return;
						const height = UI.onlyPhones ? query.height : 0;
						if (height === 0) {
							if (ref.current) return;
							else removeCSSCustomProperty(CSSCustomPropertyName, parent);
						} else {
							setCSSCustomProperty(CSSCustomPropertyName, height + 'px', parent);
						}
					})
				};
				query.onResize(resizeHandler);
				resizeHandler();
			}
		)
	})

	const s = useStore(() => ({
		get prefix() {
			return 'admin'
		},
		get renderedTitle() {
			return p.title ? renderRenderable(p.title) : p.stringTitle;
		},
	}))

	return <Observer children={() => UI.fromTablet ? <div className="AdminTitleBarPlaceholder" ref={ref}></div> : (
		<div className="AdminTitleBar" data-prefix={s.prefix} ref={ref}>
			<div className="AdminTitleBarInner">
				<div className="AdminTitleBarStart">
					<span><Turn2MeLogo areaTag="Admin"/></span>
					<UserComputedRoleList user={AUTH.currentUser} />
				</div>
				<div className="AdminTitleBarCenter">
					{(p.title || p.stringTitle) ? (
						isString(s.renderedTitle) ?
							<h1 className="AdminTitleBarTitle" children={s.renderedTitle} />
						: <>
							<h1 className="AdminTitleBarTitle" children={p.stringTitle} />
							{s.renderedTitle}
						</>
					) : 'Welcome'}
				</div>
				<div className="AdminTitleBarEnd">
					<AdminNavControlSet />
				</div>
			</div>
			{p.children && p.children}
		</div>
	)} />
}

export default AdminTitleBar;