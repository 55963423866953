import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useControllers } from '../../hooks/useRootController.hook';
import { useProps, useStore } from '../../utils/mobx.utils';

interface CurrencyRendererProps {
  value?: string | number | null,
  // more accurately, falsyValueLabel / invalidValueLabel
  emptyValue?: string,
  trimEmptyDecimals?: boolean,
}

const CurrencyRenderer: React.FC<CurrencyRendererProps> = props => {

  const { COMMON } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    get formatter() {
      return new Intl.NumberFormat(COMMON.locale, {
        style: 'currency',
        currency: COMMON.currency,
      });
    },
    get isValid() {
      if (p.value === 0) return true;
      return p.value !== undefined && p.value !== null && !isNaN(
        typeof p.value === 'string' ? parseFloat(p.value) : p.value
      );
    },
    get formattedValue() {
      return s.isValid ? s.formatter.format(+p.value!) : (p.emptyValue ?? '--');
    },
    get formattedValueWithEmptyDecimalsHidden() {
      return s.formattedValue.replace(/\.00$/, '');
    }
  }));

  return <Observer children={() => (
    <span className="CurrencyRenderer" data-currency={COMMON.currency}>
      { p.trimEmptyDecimals ? s.formattedValueWithEmptyDecimalsHidden : s.formattedValue}
    </span>
  )} />

}

export default CurrencyRenderer;