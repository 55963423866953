import { Observer } from 'mobx-react-lite';
import React, { ReactText } from 'react';
import { Renderable } from '../../../../../base/@types';
import { ContextColor } from '../../../../../base/constants/color.enum';
import joinClassName from '../../../../../base/utils/className.utils';
import { getContextColorStyle } from '../../../../../base/utils/colors.utils';
import { renderRenderable } from '../../../../../base/utils/components.utils';
import { useProps, useStore } from '../../../../../base/utils/mobx.utils';
import { isNil } from '../../../../../base/utils/ramdaEquivalents.utils';
import { isString } from '../../../../../base/utils/typeChecks.utils';
import NumberDisplay from '../../../../../components/NumberDisplay/NumberDisplay';
import './StatDataItem.scss';

interface StatDataItemProps {
  title: ReactText,
  value?: Renderable,
  separator?: string,
  secondValue?: ReactText,
  endValue?: Renderable,
  wide?: boolean,
  halfWidth?: boolean,
  halfWidthWide?: boolean,
  description?: Renderable,
  href?: string,
  primaryColor?: string,
  secondaryColor?: string,
  backgroundIntensity?: 'normal' | 'stronger' | 'solid',
  minimumFractionDigits?: number
  maximumFractionDigits?: number
}

const StatDataItem: React.FC<StatDataItemProps> = props => {

  const p = useProps(props);
  const s = useStore(() => ({
    get secondValueIsNumber() {
      return !isNaN(p.secondValue as any);
    },
    get separator() {
      return p.separator ?? '/';
    },
    get className() {
      return joinClassName(
        'StatDataItem',
        p.wide && 'wide',
        p.halfWidth && 'halfWidth',
        p.halfWidthWide && 'halfWidthWide',
        p.href && 'interactable',
      )
    },
    get inner() {
      return <>
        <div className="StatDataItemData">
          <data className="Left">
            <NumberDisplay value={p.value} minimumFractionDigits={p.minimumFractionDigits} maximumFractionDigits={p.maximumFractionDigits} />
            {(p.secondValue === '0' || p.secondValue === 0 || !!p.secondValue) && !isNil(p.secondValue) && <small> {s.separator} <NumberDisplay value={p.secondValue} minimumFractionDigits={p.minimumFractionDigits} maximumFractionDigits={p.maximumFractionDigits} /></small>}
          </data>
          {p.endValue && <data className="Right">
            {p.endValue}
          </data>}
        </div>
        <p className="StatDataItemTitle">{p.title}</p>
        {p.description && <div className={joinClassName('StatDataItemExplanation', isString(p.description) ? 'plain-text' : '')}>{renderRenderable(p.description)}</div>}
      </>
    },
    get primaryColorStyle() {
      return p.primaryColor ? getContextColorStyle(ContextColor.Primary, p.primaryColor) : undefined;
    },
    get secondaryColorStyle() {
      return p.secondaryColor ? getContextColorStyle(ContextColor.Secondary, p.secondaryColor) : undefined;
    },
    get style() {
      return {
        ...s.primaryColorStyle,
        ...s.secondaryColorStyle
      }
    },
    get attr() {
      return {
        className: s.className,
        style: s.style,
        href: p.href,
        title: p.href ? p.title : undefined,
        'data-background-intensity': p.backgroundIntensity
      }
    },
    get el() {
      return p.href ? 'a' : 'div'
    }
  }));

  return <Observer children={() => React.createElement(
    s.el,
    s.attr,
    s.inner,
  )} />

}

export default StatDataItem;