import { Observer } from 'mobx-react-lite';
import React from 'react';
import ColorTag from '../../base/components/ColorTag/ColorTag';
import ColorTagUnder12 from '../../base/components/ColorTag/ColorTagUnder12';
import ColorTagYoungPeople from '../../base/components/ColorTag/ColorTagYoungPeople';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import { Primary } from '../../base/constants/color.enum';
import { useContextColorStyle } from '../../base/hooks/useContextColor.hook';
import { useControllers } from '../../base/hooks/useRootController.hook';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { User } from '../../models/makeUser.model';
import UserComputedRoleList from '../UserComputedRoleList/UserComputedRoleList';
import UserIcon from '../UserIcon/UserIcon';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import './ProfileCard.scss';

interface ProfileCardProps {
  user: User,
  onClick?: (u?: User) => any;
}

const ProfileCard: React.FC<ProfileCardProps> = props => {
  
  const { AUTH } = useControllers();
  
  const p = useProps(props);

  const handleComponentClick = () => {
    p.onClick && p.onClick(p.user);
  }

  const s = useStore(() => ({
    get isNarrow() {
      return false;
    }
  }))

  const style = useContextColorStyle(Primary, AUTH.currentUser?.color);

  return <Observer children={() => (
    <div className={
      joinClassName(
        'ProfileCard',
        s.isNarrow ? '--narrow' : '--spacious',
      )
    } onClick={handleComponentClick} style={style}>
      <div className="ProfileCardInner">
        {!s.isNarrow && <UserIcon user={p.user} size="4.8rem" />}
        <div className="ProfileCardLabel">
          <h3 className="ProfileCardUsername">
            <UsernameRenderer user={p.user} showColorLabel={s.isNarrow}/>
          </h3>
          <p className="ProfileCardFullname">{p.user.firstName} {p.user.lastName}</p>
          {s.isNarrow || <p className="ProfileCardMemberSince">Member since <DateRenderer value={p.user.timeCreated} format="LL" /></p>}
          <div>
            <UserComputedRoleList user={p.user} />
            {p.user.hasVerifiedEmailOrMobileNumber && <span className="ProfileCardMemberVerifiedBadge"><ColorTag color="green">Verified</ColorTag></span>}
            {AUTH.currentUser?.isYoungPerson && <span className="ProfileCardNonAdultAccountTypeTag">Account Type: <ColorTagYoungPeople /></span>}
            {AUTH.currentUser?.isUnder12 && <span className="ProfileCardNonAdultAccountTypeTag">Account Type: <ColorTagUnder12 /></span>}
          </div>
        </div>
      </div>
    </div>
  )} />
}

export default ProfileCard;