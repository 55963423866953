import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { createUTCMoment } from '../../base/utils/time.utils';
import { ModelName } from '../../constants/modelNames.enum';
import { CLOCK } from '../../controllers/common/clock.controller';
import { CounsellingApplication } from '../../models/makeCounsellingApplication.model';
import { CounsellingSession } from '../../models/makeCounsellingSession.model';
import SessionAssignmentItem from './SessionAssignmentItem';
// import './SessionSummaryAssignmentList.scss';

type SessionSummaryAssignmentListProps = {
  session: CounsellingSession,
  application?: CounsellingApplication,
}

const SessionSummaryAssignmentList: React.FC<SessionSummaryAssignmentListProps> = props => {
  const { AUTH, NAVIGATOR, LOCALDB } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    get isOwnSession() {
      return AUTH.currentUser?.id && p.session.clientIds.includes(AUTH.currentUser.id);
    },
    get isCounsellorOfSession() {
      return AUTH.currentUser?.id && (AUTH.currentUser.id === p.session.counsellorId);
    },
    get assignmentGad7ForCurrentUser() {
      return p.session.assignments?.find(a => a.targetTypeModelName === ModelName.surveysGAD7 && a.assignedToUserId === AUTH.currentUser?.id);
    },
    get assignmentPhq9ForCurrentUser() {
      return p.session.assignments?.find(a => a.targetTypeModelName === ModelName.surveysPHQ9 && a.assignedToUserId === AUTH.currentUser?.id);
    },
    get assignmentPayment() {
      return p.session.assignments?.find(a => a.targetTypeModelName === ModelName.payments);
    },
    get application() {
      return p.application ?? LOCALDB.get<CounsellingApplication>(ModelName.counsellingApplications, p.session.applicationId);
    },
    get nextSession() {
      return s.application?.nextSession;
    },
    get scheduledStartTimeMoment() {
      return createUTCMoment(p.session.timeScheduled).local();
    },
    get hasStarted() {
      return !!p.session.timeStarted;
    },
    get isCancelled() {
      return Boolean(p.session.timeArchived || p.session.timeDeleted);
    },
    get canFillInSurveys() {
      return (s.isUpcoming || s.isInThePast || s.hasStarted) && !s.isCancelled;
    },
    get isToday() {
      return s.scheduledStartTimeMoment.isSame(CLOCK.localNowMoment, 'day');
    },
    get isInThePast() {
      return s.scheduledStartTimeMoment.isBefore(CLOCK.localNowMoment);
    },
    get isUpcoming() {
      return (s.nextSession === p.session || (s.isToday && !p.session.timeEnded)) && p.session.isScheduledWithin48Hours;
    },
  }));
  return <Observer children={() => (
    <div className="SessionSummaryAssignmentList">
      {
        (NAVIGATOR.isInAdminArea || (s.isCounsellorOfSession && !s.isOwnSession)) ? <>
          { p.session.assignments.map(ass => <SessionAssignmentItem
            key={ass.id}
            session={p.session}
            assignment={ass}
          />)}
        </> : <>
          {s.assignmentGad7ForCurrentUser && <SessionAssignmentItem
            session={p.session}
            assignment={s.assignmentGad7ForCurrentUser}
            disabled={!s.canFillInSurveys}
          />}
          {s.assignmentPhq9ForCurrentUser && <SessionAssignmentItem
            session={p.session}
            assignment={s.assignmentPhq9ForCurrentUser}
            disabled={!s.canFillInSurveys}
          />}
          {s.assignmentPayment && <SessionAssignmentItem
            session={p.session}
            assignment={s.assignmentPayment}
          />}
        </>
      }
    </div>
  )} />
}

export default SessionSummaryAssignmentList;