import { Nillable } from "../../@types";
import { countWords, makeWordMatcher } from "../../utils/humanLanguage.utils";

export const matchYesNoCommand = (input: Nillable<string>) => {

  if (!input) return null;
  
  const wordCount = countWords(input);
  if (wordCount > 5) return;

  const definitelyNotYesNo = makeWordMatcher([
    ['feel', `I'm`, `I am`, `not too bad`, `not bad`, `I was`, 'my', 'his', 'him', 'her', 'she', 'he', 'they', 'them']
  ])(input);

  if (definitelyNotYesNo.matched) {
    return null;
  }

  const yes = makeWordMatcher([
    ['yes', 'sure', 'of course', 'no problem', 'ok', 'okay', 'yeah', 'yea', 'yes please', 'yep', 'ye'],
  ])(input);

  const no = makeWordMatcher([
    ['no', 'not really', 'nah', 'I prefer not to', 'I\'d rather not', 'nope']
  ])(input);

  if (yes.matched && no.matched) {
    return 'not-sure';
  } if (yes.matched) {
    return 'yes';
  } if (no.matched) {
    return 'no';
  }

  return null;

}