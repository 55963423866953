import { CardElement } from "@stripe/react-stripe-js";
import { loadStripe, PaymentMethod, SetupIntent, Stripe, StripeElements, StripeError } from "@stripe/stripe-js";
import { getApiKey } from "../apiKeys";
import { AnyObject } from "../base/@types";
import { PaymentEndpoints } from "../base/endpoints/payment.endpoints";
import { reportError } from "../base/utils/errors.utils";
import { ApiModelName } from "../constants/ApiModels.enum";
import { APIController } from "../controllers/api.controller";
import { SHOULD_LOG } from "../env";
import { Address } from "../models/makeAddress.model";
import { CounsellingSession } from "../models/makeCounsellingSession.model";
import { Payment } from "../models/makePayment.model";


export const initStripe = new Promise<Stripe | null>(async (resolve, reject) => {
  const key = getApiKey('STRIPE_KEY');
  if (!key) {
    const errorMessage = 'A valid API Key is not provided for Stripe, payment related components will not function.';
    if (process.env.NODE_ENV === 'development') {
      SHOULD_LOG() && console.warn(errorMessage);
    } else reject(errorMessage);
  }
  try {
    resolve(await loadStripe(key));
  } catch(e) {
    reportError(e);
  }
})


export const getSetupIntent = (API: APIController) => new Promise<SetupIntent>(async (resolve, reject) => {
  try {
    const url = PaymentEndpoints.public.getIntent();
    const response = await API.getRaw<SetupIntent>(url);
    resolve(response.data);
  } catch (e) {
    reject(e);
  }
});

export const getSetupIntentSecret = (API: APIController) => new Promise<string | null>(async (resolve, reject) => {
  try {
    const intent = await getSetupIntent(API);
    resolve(intent.client_secret);
  } catch (e) {
    reject(e);
  }
});

export const createOrGetStripeCustomerIdForCurrentUser = (API: APIController) => new Promise<string>(async (resolve, reject) => {
  try {
    const url = PaymentEndpoints.own.createOrGetCustomerId();
    const response = await API.getRaw<{id: string}>(url);
    resolve(response.data.id);
  } catch (e) {
    reportError(e);
    reject(e);
  }
})

export const getPaymentMethodIdFromStripe = (
  API: APIController,
  stripe: Stripe | null,
  elements: StripeElements | null,
  cardHolderName: string,
  cardHolderEmail: string,
  billingAddress: Address | null,
) => new Promise<string>(async (resolve, reject) => {
  const { AUTH } = API.ROOT!.children;
  if (!stripe || !elements) {
    reject(new Error('Stripe is not initiated'));
    return;
  }
  const cardElement = elements.getElement(CardElement);
  if (!cardElement) {
    reject(new Error('no cardElement mounted'));
    return;
  }
  let intentSecret: string | null = null;
  try {
    intentSecret = await getSetupIntentSecret(API);
  } catch (e) {
    reject(e);
    return;
  }
  if (!intentSecret) {
    reject(new Error('Failed to get intent secret...'));
    return;
  }
  if (AUTH.isAuthenticated) {
    try {
      await createOrGetStripeCustomerIdForCurrentUser(API);
    } catch (e) {
      reject(e);
    }
  }
  let confirmCardSetupResult: {
    setupIntent?: SetupIntent | undefined;
    error?: StripeError | undefined;
  } = {}
  try {
    confirmCardSetupResult = await stripe.confirmCardSetup(
      intentSecret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: cardHolderName,
            email: cardHolderEmail,
            ...!!billingAddress ? {
              address: {
                line1: billingAddress.lineOne,
                line2: billingAddress.lineTwo ?? undefined,
                city: billingAddress.city,
                state: billingAddress.region ?? undefined,
                postal_code: billingAddress.postcode ?? undefined,
                country: billingAddress.countryId,
              }
            } : {},
          }
        }
      }
    )
  } catch (e) {
    reject(e);
    return;
  }
  const { setupIntent, error } = confirmCardSetupResult;
  if (!setupIntent?.payment_method) {
    reject(error);
    return;
  }
  resolve(setupIntent.payment_method);
})

export const createPaymentMethod = (
  API: APIController,
  stripe: Stripe | null,
  elements: StripeElements | null,
  cardHolderName: string,
  cardHolderEmail: string,
  billingAddress: Address | null,
) => new Promise<PaymentMethod & { paymentMethodId: string }>(
  async (resolve, reject) => {
    const { AUTH } = API.ROOT!.children;
    const isLoggedIn = AUTH.isAuthenticated;
    try {
      const paymentMethodId = await getPaymentMethodIdFromStripe(API, stripe, elements, cardHolderName, cardHolderEmail, billingAddress);
      const url = PaymentEndpoints[isLoggedIn ? 'public' : 'public'].createPaymentMethod();
      const payload: AnyObject = {
        'paymentMethod': paymentMethodId,
        'isDefault': true,
      }
      if (AUTH.isAuthenticated) {
        payload.userId = AUTH.currentUser?.id;
      }
      const response = await API.postRaw<PaymentMethod>(url, payload);
      resolve({
        paymentMethodId: paymentMethodId,
        ...response.data
      })
    } catch (e) {
      reject(e);
    }
  }
);

export const getPaymentType = (payment: Payment, unknownText?: string) => {
  if (payment.modelType === ApiModelName.COUNSELLING_SESSION) {
    const session = payment.model as CounsellingSession;
    if (!session) return unknownText ?? "unknown";
    if (session.isPaidSession) return "payment";
  }
  return "donation";
}
