import { Observer } from 'mobx-react-lite';
import React from 'react';
import joinClassName from '../../base/utils/className.utils';
import { useSupportGroupGetters } from '../../hooks/useSupportGroupGetters.hook';
import { SupportGroup } from '../../models/makeSupportGroup.model';
import './SupportGroupJoinedCountDisplay.scss';

interface SupportGroupJoinedCountDisplayProps {
  supportGroup?: SupportGroup | undefined,
  showActualCount?: boolean,
}

const SupportGroupJoinedCountDisplay: React.FC<SupportGroupJoinedCountDisplayProps> = props => {
  const sg = useSupportGroupGetters(props.supportGroup);
  return <Observer children={() => (
    <span className={
      joinClassName(
        'SupportGroupJoinedCountDisplay',
        sg.numJoined === 0 && 'empty',
        !!sg.isFull && 'full'
      )
    }>
      {props.supportGroup ? (
        props.showActualCount ? <>
          <span>{sg.numJoined} / {sg.max}</span>
          { sg.isFull && <em> (Full)</em>}
        </> : <>
          {sg.sg?.currentUserHasBeenRevoked ? <span>Your reservation has been cancelled by our facilitator.</span> : (
            sg.sg?.currentUserHasRegistered ? <span>You have joined this group.</span> : (
              sg.isEmpty ? "Available to join" : (
                props.showActualCount ? `${sg.numAvailable} slots available` : 'Available to join'
              )
            )
          )} 
        </>
      ) : ''}
    </span>
  )} />
}

export default SupportGroupJoinedCountDisplay;