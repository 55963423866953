import { UserEndpoints } from "../base/endpoints/user.endpoints";
import { ModelName } from "../constants/modelNames.enum";
import { User } from "../models/makeUser.model";
import { useAutoGet, UseAutoGetFactoryOptions } from "../utils/useAutoGet.utils";

export const useGetCounsellors = <T extends object>(
  options: UseAutoGetFactoryOptions<User[], T>
) => {
  useAutoGet<User[], T>({
    asIndex: true,
    allowCache: true,
    defaultValue: [] as User[],
    ...options,
    modelName: ModelName.users,
    url: UserEndpoints.staff.counsellorIndex({ perPage: 1000 }),
  })
}