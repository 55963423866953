import { Observer } from 'mobx-react-lite';
import React from 'react';
import sanitize from 'sanitize-html';
import { WPPost } from '../../base/@types/wordpress.types';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import { IconName } from '../../base/components/Symbols/iconDefs';
import UICard from '../../base/components/UICard/UICard';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { replaceHostnameWithWordPressLinkInDevMode } from '../../env';
import IFrameView from '../IFrameView/IFrameView';
import './BlogPostCard.scss';
import PlaceholderImage from './turn2me-placeholder-image.png';

interface BlogPostCardProps {
  post: WPPost,
}

const BlogPostCard: React.FC<BlogPostCardProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get categoryIcon(): IconName | null {
      return 'newspaper';
    },
    get categoryLabel() {
      return 'turn2me Blog';
    },
    get terms() {
      return (p.post._embedded?.['wp:term']?.[1] ?? []).flat();
    },
    get tagsList() {
      return s.terms.map(term => `#${term.name}`).join(', ');
    },
    get afterTitle() {
      return <>
        <DateRenderer value={p.post.date_gmt} format="DD MMMM, YYYY"/>
        {' '}by{' '}
        { p.post._embedded?.author?.[0]?.name }
      </>;
    },
  }));

  const parsedPostTitle = (title: string) => {
    return sanitize(title, { allowedTags: [] });
  }

  return <Observer children={() => (
    <UICard className="BlogPostCard"
      sideABackgroundColor="#222235"
      sideABackgroundImage={`url(${p.post._embedded?.['wp:featuredmedia']?.[0]?.source_url ?? PlaceholderImage})`}
      sideBRenderable={() => <IFrameView title={parsedPostTitle(p.post.title.rendered) ?? ''} src={replaceHostnameWithWordPressLinkInDevMode(p.post.link)} delay={400}/>}
      animation="flip"
    >
      <div className="BlogPostCardTopTags">
        {!!(s.categoryIcon && s.categoryLabel) && <div className="BlogPostCardTag" data-type={s.categoryLabel}>
          {s.categoryIcon && <BaseIcon name={s.categoryIcon} />}
          <span>{s.categoryLabel ?? 'Blog'}</span>
        </div>}
      </div>
      <header className="BlogPostCardHeader">
        <div className="BlogPostCardHeaderInner">
          <h3 className="BlogPostCardTitle">{parsedPostTitle(p.post.title.rendered)}</h3>
          {s.tagsList && <p className="BlogPostCardTagsList">{s.tagsList}</p>}
          <p className="BlogPostCardAfterTitle">{s.afterTitle}</p>
        </div>
      </header>
    </UICard>
  )} />
}

export default BlogPostCard;