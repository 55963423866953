import { HasId } from "../base/@types";
import { ChatThreadEndpoints } from "../base/endpoints/chatThread.endpoints";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { ChatThread } from "../models/makeChatThread.model";

export function patchChatThread(API: APIController, payload: Partial<ChatThread> & HasId) {
  return new Promise<ChatThread>(async (resolve, reject) => {
    try {
      const { id } = payload;
      const url = ChatThreadEndpoints.staff.update(id);
      const response = await API.patch<ChatThread>(url, ModelName.chatThreads, payload);
      if (!response) {
        throw Error('Failed to patch a chat thread');
      }
      resolve(response);
    } catch(e) {
      reject(e);
    }
  })
}