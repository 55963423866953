import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState, Nillable, Nullable } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import ErrorBanner from '../../base/components/ErrorBanner/ErrorBanner';
import InfoBanner from '../../base/components/InfoBanner/InfoBanner';
import LoadingIndicatorSection from '../../base/components/LoadingIndicatorSection/LoadingIndicatorSection';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { convertRecordMapToArray } from '../../base/utils/map.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { Address, makeAddress } from '../../models/makeAddress.model';
import { User } from '../../models/makeUser.model';
import { useGetAddresses } from '../../requests/useGetAddresses.request';
import AddressItem from '../AddressItem/AddressItem';
import OverlayAddressEditor from '../OverlayAddressEditor/OverlayAddressEditor';
import './UserAddressManager.scss';

type UserAddressManagerProps = {
  user: Nillable<User>,
  onSelect?: (address: Address | null) => void,
  selected?: Address | null,
  hidePrimaryButton?: boolean,
}

const UserAddressManager: React.FC<UserAddressManagerProps> = props => {

  const { UI, LOCALDB } = useControllers();

  const p = useProps(props);
  const s = useStore(() => ({
    _addresses: [] as Address[],
    get ownAddresses() {
      return p.user?.id ? convertRecordMapToArray(LOCALDB.data.addresses).filter(a => a.userId === p.user?.id) : [];
    },
    get nonPrimaryAddresses() {
      return s.ownAddresses.filter(a => a !== s.primaryAddress);
    },
    get primaryAddress() {
      return p.user?.primaryAddress;
    },
    error: null as Nullable<Error>,
    awaitingResponse: !p.user?.addresses.length,
    addNewAddress: action(() => {
      UI.OVERLAY.present({
        component: <OverlayAddressEditor
          address={makeAddress({
            userId: p.user?.id,
          })}
          forceDefaultToPrimaryAsTrue={s.ownAddresses.length === 0}
        />
      })
    }),
  }))

  useGetAddresses({
    observable: s,
    key: '_addresses',
    own: true,
    onError: action((e: Error) => {
      s.awaitingResponse = false;
      s.error = e;
    }),
    onDataFetch: action(() => {
      s.awaitingResponse = false;
    }),
    when: () => Boolean(p.user?.id),
  }, {
    filter: {
      userId: p.user?.id
    }
  });
  return <Observer children={() => (
    <div className="UserAddressManager">
      {
        s.awaitingResponse && <LoadingIndicatorSection />
      }
      {
        !s.awaitingResponse && s.ownAddresses.length === 0 && !s.primaryAddress && <InfoBanner icon="info">
          <p>You don't have any addresses saved yet.</p>
        </InfoBanner>
      }
      {
        s.error && <ErrorBanner heading="Error getting addresses" error={s.error} />
      }
      {
        s.primaryAddress && <AddressItem
          address={s.primaryAddress}
          isPrimary
          layout={UI.onlyPhones ? 'portrait' : 'landscape'}
          isSelected={p.selected?.id === s.primaryAddress.id}
          onClick={p.onSelect}
          hidePrimaryButton={p.hidePrimaryButton}
        />
      }
      {
        s.nonPrimaryAddresses.map(a => <AddressItem
          address={a}
          key={a.id}
          deletable={s.ownAddresses.length > 1}
          layout={UI.onlyPhones ? 'portrait' : 'landscape'}
          isSelected={p.selected?.id === a.id}
          onClick={p.onSelect}
          hidePrimaryButton={p.hidePrimaryButton}
        />)
      }
      {
        !s.awaitingResponse && <BaseButton
          dataCy="add-new-address"
          icon="plus"
          className="subtle"
          size="lg"
          fullWidth
          label="Add New"
          onClick={s.addNewAddress}
          colorCodedState={ColorCodedState.positive}
        />
      }
    </div>
  )} />
}

export default UserAddressManager;