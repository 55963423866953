import { observable } from "mobx";
import moment from "moment";
import { CLOCK } from "../../controllers/common/clock.controller";
import { AnyObject, Nillable, Nullable } from "../@types";
import { DateUnit, TimezoneMode, ValidMomentInput } from "../@types/time.types";
import { HasEndTime, HasStartTime, SchedulableTimedEvent, TimedEvent } from "../@types/traits.types";
import DateRenderer from "../components/DateRenderer/DateRenderer";
import { autoPluralize } from "./string.utils";
import { isString } from "./typeChecks.utils";

declare global {
  interface Window {
    /** only available for dev builds */
    moment?: typeof moment;
  }
};

if (process.env.NODE_ENV === 'development') {
  window.moment = moment;
}

export const getTime = () => new Date().getTime();

export const YYYYMMDD = 'YYYY-MM-DD';
export const HHmm = 'HH:mm';
export const HHmmss = 'HH:mm:ss';
export const YYYYMMDDHHmm = `${YYYYMMDD} ${HHmm}`;
export const YYYYMMDDHHmmss = `${YYYYMMDD} ${HHmmss}`;
export const ddddMMMMDoYYYYHHmm = `dddd, MMMM Do YYYY, ${HHmm}`;

export const isYYYYMMDDHHmmss = (string: any) => isString(string) && string.match(/^\d{4}-\d{2}-\d{2}(?:\s\d{2}:\d{2}:\d{2})?$/);

export const formatDateOfBirth = (dob: string) => {
  return moment(dob).format(YYYYMMDD)
}

/**
 * create moment given the input value types.
 * in auto mode, if the format matches default laravel format of `YYYY-MM-DD HH:mm:ss`,
 * it will be treated as UTC by default.
 * @param inputTimezoneMode specify which type of timezone (utc/local) the timestamp is.
 */
export const createMoment = (inputTimezoneMode: TimezoneMode = 'auto', value?: ValidMomentInput, formatter?: string) => {
  switch (inputTimezoneMode) {
    case 'local': return moment(value);
    case 'utc': return moment.utc(value, formatter);
    case 'auto':
    default: {
      if (isYYYYMMDDHHmmss(value)) return moment.utc(value);
      return moment(value);
    }
  }
}
export const createMomentFn = (inputTimezoneMode: TimezoneMode) => (value?: ValidMomentInput, format?: string) => createMoment(inputTimezoneMode, value, format);

export const createAutoMoment = createMomentFn('auto');
export const createLocalMoment = createMomentFn('local');
export const createUTCMoment = createMomentFn('utc');

export function getNowInSeconds() {
  return new Date().getTime() / 1000;
}
export function isValidTimestamp(d: any) {
  if (typeof d === 'string' && (d[0] === '-' || d.match(/^0\d*/))) return false;
  return moment(d).isValid();
}
export function hasValidStartTime(obj: AnyObject): obj is HasStartTime {
  return 'startTime' in obj && typeof obj.startTime === 'string';
}
export function hasValidEndTime(obj: AnyObject): obj is HasEndTime {
  return 'endTime' in obj && typeof obj.endTime === 'string';
}
export function isTimedEvent(obj: AnyObject): obj is TimedEvent {
  return hasValidStartTime(obj) && hasValidEndTime(obj);
}
export function isSchedulableTimedEvent(obj: AnyObject): obj is SchedulableTimedEvent {
  return isTimedEvent(obj) && 'scheduledDurationValue' in obj && 'scheduledDurationUnit' in obj;
}
export function checkIfDateIsInRange(date: string | Date | moment.Moment, options: {
  floor: string | Date | moment.Moment,
  ceiling: string | Date | moment.Moment,
  granularity: DateUnit,
  timezoneMode?: TimezoneMode,
}) {
  const _createMoment = createMomentFn(options.timezoneMode ?? 'auto');
  const dateToCheck = _createMoment(date);
  let isAboveFloor = true, isBelowCeiling = true;
  const { floor, ceiling, granularity } = options;
  if (floor) {
    isAboveFloor = dateToCheck.isAfter(_createMoment(floor), granularity);
  }
  if (ceiling) {
    isBelowCeiling = dateToCheck.isBefore(_createMoment(ceiling), granularity);
  }
  return isAboveFloor && isBelowCeiling;
}

export const getNowTimestampUtc = () => moment.utc().format(YYYYMMDDHHmmss);

export const isToday = (t: ValidMomentInput) => createUTCMoment(t).isSame(undefined, 'day');
export const isYesterday = (t: ValidMomentInput) => createUTCMoment(t).add(1, 'day').isSame(undefined, 'day');
export const isTomorrow = (t: ValidMomentInput) => createUTCMoment(t).subtract(1, 'day').isSame(undefined, 'day');
export const isSameWeek = (t: ValidMomentInput) => createUTCMoment(t).isSame(undefined, 'week');
export const isThisYear = (t: ValidMomentInput) => createUTCMoment(t).isSame(undefined, 'year');

export const seconds = (seconds: number) => seconds * 1000;
export const minutes = (minutes: number) => minutes * 60 * 1000;
export const hours = (hours: number) => hours * 60 * 60 * 1000;
export const days = (days: number) => days * 24 * 60 * 60 * 1000;
export const millisecondsToHours = (ms: number) => ms / 60 / 60 / 1000;
export const millisecondsToMinutes = (ms: number) => ms / 60 / 1000;

/**
 * takes a duration and returns it a readable format such as '3 minutes', '12 hours' or '3 hr 24 min'.
 * */
export const humanizeDuration = (dur: number): string => {
  if (dur >= days(1)) {
    return moment.duration(dur).humanize() as string;
  } else {
    const hours = Math.abs(millisecondsToHours(dur));
    const roundedHours = Math.floor(hours);
    const minutes = Math.floor(Math.abs(millisecondsToMinutes(dur)) % 60);
    if (roundedHours === 0) return autoPluralize(minutes, 'minutes');
    if (minutes < 5) return autoPluralize(roundedHours, 'hour');
    return `${roundedHours} hr ${minutes} min`;
  }
}

export const getDurationFromUTCTimeStamps = (from?: Nillable<string>, to?: Nillable<string>) => {
  if (!from) return 0;
  const start = createUTCMoment(from);
  const end = createUTCMoment(to ?? CLOCK.localNowMoment);
  return end.diff(start, 'milliseconds');
}

export const getDiffFromTimeStamps = (from?: Nillable<string>, to?: Nillable<string>, unitOfTime?: moment.unitOfTime.Diff) => {
  if (!from) return 0;
  const start = createAutoMoment(from);
  const end = createAutoMoment(to);
  return end.diff(start, unitOfTime ?? 'milliseconds');
}

export type TimeTillState = ReturnType<typeof getTimeTillState>;
export const getTimeTillState = (p: { timeStarted: Nullable<string>, timeEnded: Nullable<string>, timeScheduled: Nullable<string> }) => {
  const s = observable({
    get now() {
      return CLOCK.localNowMoment;
    },
    get scheduledStartTimeMoment() {
      return createUTCMoment(p.timeScheduled).local();
    },
    get actualStartTimeMoment() {
      return createUTCMoment(p.timeStarted).local();
    },
    get scheduledStartTimeFormatted() {
      return s.scheduledStartTimeMoment.format(HHmm);
    },
    get isScheduledInThePast() {
      return s.scheduledStartTimeMoment.isBefore(s.now);
    },
    get isToday() {
      return s.scheduledStartTimeMoment.isSame(undefined, 'day');
    },
    get isTomorrow() {
      return createLocalMoment(s.scheduledStartTimeMoment).subtract(1, 'day').isSame(undefined, 'day');
    },
    get isTodayOrFuture() {
      return s.scheduledStartTimeMoment.isSameOrAfter(s.now, 'day');
    },
    isScheduledWithinHours: (hours: number) => {
      return Math.abs(createUTCMoment(p.timeScheduled).diff(s.now, 'days', true)) < hours;
    },
    get scheduledTimeDiffFromNow() {
      return s.scheduledStartTimeMoment.diff(s.now);
    },
    get scheduledTimeDiffFromNowFormatted() {
      return humanizeDuration(s.scheduledTimeDiffFromNow);
    },
    get actualStartTimeDiffFromNow() {
      return s.actualStartTimeMoment.diff(s.now);
    },
    get actualStartTimeDiffFromNowFormatted() {
      return humanizeDuration(s.actualStartTimeDiffFromNow);
    },
    get hasStarted() {
      return !!p.timeStarted;
    },
    get startingTagContent() {
      if (s.hasStarted) {
        if (!p.timeEnded) return `started ${s.actualStartTimeDiffFromNowFormatted} ago`;
        return <>Started on <DateRenderer value={p.timeStarted} format='LLL' /></>;
      }
      if (s.isTomorrow) return `tomorrow, ${s.scheduledStartTimeFormatted}`;
      if (s.isToday) return s.isScheduledInThePast ? `today, scheduled to start ${s.scheduledTimeDiffFromNowFormatted} ago` : `today, starting in ${s.scheduledTimeDiffFromNowFormatted}`;
      return undefined;
    },
  })
  return s;
}

export const getHoursTillDateTime = (datetime: Nullable<string>) => {
  return moment.duration(createUTCMoment(datetime).diff(CLOCK.localNowMoment)).asHours();
}