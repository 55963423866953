import { SupportGroupReservation, SupportGroupReservationRelatedModels } from "../../models/makeSupportGroupReservation.model";
import { DefaultIndexParamSet } from "../utils/api.utils";
import { makeApiEndpointConfig, makeApiGetEndpointConfig } from "../utils/endpoints.utils";
import { makeUrl } from "../utils/url.utils";
import { prefixStaff } from "./namespacePrefixes";

export type SupportGroupReservationEndpointParams = DefaultIndexParamSet<SupportGroupReservation, SupportGroupReservationRelatedModels> & {
  include?: (keyof SupportGroupReservationRelatedModels | string)[],
}
const staffUrlBase = prefixStaff('support-group-reservations');

export const SupportGroupReservationEndpoints = {
  staff: {
    index: makeApiEndpointConfig<SupportGroupReservationEndpointParams>(staffUrlBase),
    get: makeApiGetEndpointConfig<SupportGroupReservationEndpointParams>(staffUrlBase),
    create: () => staffUrlBase,
    update: (id: string) => makeUrl(staffUrlBase, id),
    delete: (id: string) => makeUrl(staffUrlBase, id),
  },
}