import { Observer } from 'mobx-react-lite';
import React from 'react';
import sanitize from 'sanitize-html';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import InfoTable from '../../base/components/InfoTable/InfoTable';
import { useProps } from '../../base/utils/mobx.utils';
import { autoPluralize } from '../../base/utils/string.utils';
import { useSupportGroupGetters } from '../../hooks/useSupportGroupGetters.hook';
import { SupportGroup } from '../../models/makeSupportGroup.model';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
// import './SupportGroupInfoTable.scss';

interface SupportGroupInfoTableProps {
  supportGroup: SupportGroup;
}

const SupportGroupInfoTable: React.FC<SupportGroupInfoTableProps> = props => {
  const p = useProps(props);
  const sg = useSupportGroupGetters(p.supportGroup);
  return <Observer children={() => (
    <div className="SupportGroupInfoTable">
      <InfoTable>
        <tbody>
          <tr><th>Topic</th><td>{sg.topic}</td></tr>
          <tr><th>Name</th><td><h4>{sg.title}</h4></td></tr>
          <tr><th>Facilitator</th><td><UsernameRenderer user={sg.supportGroup?.facilitator} userId={sg.facilitatorId} /></td></tr>
          <tr><th>Schedule</th><td><DateRenderer value={sg.scheduledStartTime} format="LLLL" /></td></tr>
          <tr><th>Scheduled Duration</th><td>{autoPluralize(sg.scheduledDuration, 'minute')}</td></tr>
          <tr><th>Participants</th><td>{sg.numJoined} / {sg.max} {sg.isFull && '(Full)'}</td></tr>
          <tr><th>Started at</th><td><DateRenderer value={sg.startTime} /></td></tr>
          <tr><th>Ended at</th><td><DateRenderer value={sg.endTime} /></td></tr>
          <tr><th>Description</th><td><div dangerouslySetInnerHTML={{ __html: sanitize(sg.description) }} /></td></tr>
        </tbody>
      </InfoTable>
    </div>
  )} />
}

export default SupportGroupInfoTable;