import { RouteDef } from "../../../base/@types";
import { always } from "../../../base/utils/ramdaEquivalents.utils";
import { makeObservableRouteDef } from "../../../base/utils/routes.utils";
import PageLanding from "./Landing.page";

export const PageLandingRouteDef: RouteDef = makeObservableRouteDef({
  identifier: 'landing',
  urlFactory: always('/'),
  composeTitle: always('Welcome'),
  component: PageLanding,
})