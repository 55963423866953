import { Observer } from 'mobx-react-lite';
import React, { SyntheticEvent } from 'react';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { ReactionType } from '../../constants/ReactionType.enum';
import './ReactionIcon.scss';

interface ReactionIconProps {
  className?: string,
  type: ReactionType,
  onClick?: (r: ReactionType) => void,
}

const ReactionIcon: React.FC<ReactionIconProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    handleMouseUp: (e: SyntheticEvent) => {
      p.onClick?.(p.type);
    },
  }));

  return <Observer children={() => (
    <div
      className={joinClassName('ReactionIcon', p.className)}
      onMouseUp={s.handleMouseUp}
      onTouchEnd={s.handleMouseUp}
      data-type={p.type}
    >
      <svg viewBox="0 0 42 42" fill="none">
        <use xlinkHref={`#icon-reaction-${p.type}`} />
      </svg>
    </div>
  )} />
}

export default ReactionIcon;