import { Nillable, Nullable, StandardModel } from "../base/@types";
import { Identifier } from "../base/@types/traits.types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { Emotion } from "../constants/emotions.enum";
import { ModelName } from "../constants/modelNames.enum";
import { HasTimestamps, hasTimestamps } from "../traits/hasTimestamps.trait";
import { FlaggableModeSnapshotPartial, isFlaggable } from "../traits/isFlaggable.trait";
import { Comment } from "./makeComment.model";
import { Flag } from "./makeFlag.model";
import { Reaction } from "./makeReaction.model";
import { User } from "./makeUser.model";

export type ThoughtSnapshot = {
  id: Identifier,
  emotionId: Nillable<Emotion>,
  content: Nullable<string>,
  situation: Nullable<string>,
  response: Nullable<string>,
  isPrivate: boolean,
  userId: Identifier,
  /** Original Thought is not treated as a model but as a simple snapshot object in app */
  originalThought: Nillable<ThoughtSnapshot>,
  commentIds: Identifier[],
  reactionIds: Identifier[],
  timeLastClientAction: Nullable<string>,
} & FlaggableModeSnapshotPartial & HasTimestamps;

export type ThoughtRelatedModels = {
  user: Nillable<User>,
  comments: Comment[],
  reactions: Reaction[],
  flags: Flag[],
}
export type ThoughtExtendedProperties = {
  readonly isPublic: boolean;
  readonly isUnsafe: boolean;
}

export type Thought = StandardModel<
  ThoughtSnapshot,
  ThoughtRelatedModels,
  ThoughtExtendedProperties
>

export const makeThoughtSnapshotBase = (): ThoughtSnapshot => ({
  id: '',
  emotionId: null,
  content: '',
  situation: '',
  response: '',
  isPrivate: true,
  userId: '',
  originalThought: null,
  commentIds: [],
  reactionIds: [],
  timeLastClientAction: null,
  ...isFlaggable(),
  ...hasTimestamps(),
})

export const makeThought = createStandardModelFactory<Thought, ThoughtRelatedModels, ThoughtExtendedProperties>({
  name: ModelName.thoughts,
  snapshotFactory: makeThoughtSnapshotBase,
  relationshipsSchema: {
    user: ModelName.users,
    flags: { modelName: ModelName.flags, has: 'many' },
    comments: { modelName: ModelName.comments, has: 'many' },
    reactions: { modelName: ModelName.reactions, has: 'many' },
  },
  accessorOverridesFactory: ($, m, localDB) => ({
    /** if thought is hidden, it will always be treated as private in the app */
    get isPrivate() {
      return $.timeHidden ? true : $.isPrivate;
    },
    set isPrivate(value) {
      $.isPrivate = value;
    }
  }),
  extendedPropertiesFactory: (m, $, localDB) => ({
    get isPublic() {
      return !m.isPrivate;
    },
    get isUnsafe() {
      return m.isSafe === false
    }
  })
})
