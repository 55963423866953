import { useStore } from "../base/utils/mobx.utils";
import { equalByString } from "../base/utils/string.utils";
import { CounsellingApplication } from "../models/makeCounsellingApplication.model";
import { getAgeFromDateOfBirth, isAdultAge, isYoungPeopleAge } from "../utils/ageAndDateOfBirth.utils";
import { isOfCounsellingTypeYoungPeople } from "../utils/counsellingApplication.utils";

export function useCounsellingApplicationComputedSet(src: { application?: CounsellingApplication }) {
  const s = useStore(() => ({
    get application() {
      return src.application
    },
    get applicantAge() {
      return s.application?.applicant?.dateOfBirth ? getAgeFromDateOfBirth(s.application.applicant?.dateOfBirth) : undefined;
    },
    get applicantIsAdult() {
      return isAdultAge(s.applicantAge);
    },
    get applicantIsYoungPerson() {
      return isYoungPeopleAge(s.applicantAge);
    },
    get shouldShowSubmitterAsParent() {
      return isOfCounsellingTypeYoungPeople(s.application?.type) && s.applicantIsAdult;
    },
    get isArchived() {
      return Boolean(s.application?.timeArchived);
    },
    get isCompleted() {
      return Boolean(s.application?.timeCompleted);
    },
    get isIrishApplicant() {
      return equalByString(s.application?.countryId, 'IE');
    },
    get isYoungPersonApplication() {
      return isOfCounsellingTypeYoungPeople(s.application?.type);
    },
    get appliedAsLegalGuardian() {
      return s.isYoungPersonApplication && isAdultAge(s.applicantAge);
    },
    get appliedAsYoungPerson() {
      return s.isYoungPersonApplication && isYoungPeopleAge(s.applicantAge);
    },
  }))
  return s;
}