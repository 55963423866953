import { Observer } from 'mobx-react-lite';
import React from 'react';
import joinClassName from '../../../base/utils/className.utils';
import './ModuleSection.scss';

interface ModuleSectionProps {
  className?: string,
  spaceChildren?: boolean,
}

const ModuleSection: React.FC<ModuleSectionProps> = p => {
  return <Observer children={() => (
    <section className={joinClassName('ModuleSection', p.className, p.spaceChildren && 'spaceChildren')}>
      {p.children}
    </section>
  )} />
}

export default ModuleSection;