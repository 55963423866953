import { Nullable } from "../base/@types";
import { scrollElementTo } from "../base/utils/scrollElementTo.utils";
import tick from "../base/utils/waiters.utils";
import { UIController } from "../controllers/ui.controller";
import { ModuleClientRouterStackName } from "../modules/Client/Client.module";
import { ExploreModuleRouteDef } from "../modules/Client/Explore/Explore.module.routes";

export const scrollExploreModuleToThoughtCard = async (UI: UIController, thoughtId: string) => {
  const cardElId = `thought#${thoughtId}`;
  const selector = `.ThoughtFeed .ThoughtCard[data-id="${cardElId}"]`;
  const el: Nullable<HTMLDivElement> = document.querySelector(selector);
  const container: Nullable<HTMLDivElement> = document.querySelector(`.RouterStack[data-name="${ModuleClientRouterStackName}"] .RouterStackLayerContainer[data-identifier="${ExploreModuleRouteDef.identifier}"]`)
  await tick(1000);
  if (el && container) {
    const rect = el.getBoundingClientRect();
    const top = rect.top - (UI.onlyPhones ? 80 : 100);
    scrollElementTo({
      el: container,
      top,
      callback: async () => {
        el.classList.add('highlight');
        await tick(2000);
        el.classList.remove('highlight');
      }
    });
  }
}