// export const ContactType = [
//   'Services',
//   'General',
//   'Business',
//   'Account',
//   'Website',
//   'Misc',
//   'Donations',
// ] as const

export enum ContactTypeEnum {
  'Services' = 'Services',
  'General' = 'General',
  'Business' = 'Business',
  'Account' = 'Account',
  'Website' = 'Website',
  'Misc' = 'Misc',
  'Donations' = 'Donations',
}

export const ContactType = Object.values(ContactTypeEnum);