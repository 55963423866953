import { action, runInAction } from "mobx";
import { Observer } from "mobx-react-lite";
import React, { ReactText, useEffect, useRef } from "react";
import { SHOULD_LOG } from "../../../env";
import { AnyObject } from "../../@types";
import { ColorCodedState } from "../../@types/ui.types";
import { addToArrayIfNew } from "../../utils/array.utils";
import joinClassName from "../../utils/className.utils";
import { checkIfShouldInvertStyle } from "../../utils/colors.utils";
import { useProps, useStore } from "../../utils/mobx.utils";
import { capitalizeFirstLetter } from "../../utils/string.utils";
import BaseLabel from "../BaseLabel/BaseLabel";
import { IconName, IconVariant } from "../Symbols/iconDefs";
import './BaseSelector.scss';
import BaseSelectorCheckboxGroupInner from "./BaseSelectorCheckboxGroupInner";
import BaseSelectorInlineInner from "./BaseSelectorInlineInner";
import BaseSelectorSystemInner from "./BaseSelectorSystemInner";

export type BaseSelectAppearance = 'inline' | 'system' | 'checkboxGroup';

export type DefaultSingleValueType = string | number | undefined;

export type DefaultOptionType = {
  value?: DefaultSingleValueType,
  label?: string | number,
  color?: string,
  icon?: IconName,
};

export interface BaseSelectorProps<
  FormType extends AnyObject = AnyObject,
  OptionType extends any = DefaultOptionType,
  SingleValueType extends DefaultSingleValueType = DefaultSingleValueType,
  ValueFieldType extends SingleValueType | string[] = SingleValueType,
> {
  className?: string,
  form: FormType,
  field: keyof FormType & string,
  label?: ReactText,
  name?: string,
  placeholder?: string,
  options: OptionType[],
  /** @deprecated does not work when p.form[p.field] is used. */
  defaultValue?: ValueFieldType,
  colorCodedState?: ColorCodedState | '',
  valueGetter?: (option: OptionType) => SingleValueType,
  optionEqualityChecker?: (a: OptionType, b: OptionType) => boolean,
  valueEqualityChecker?: (a: unknown, b: unknown) => boolean,
  optionLabelRenderer?: (option: OptionType) => React.ReactChild,
  disabledOptionChecker?: (option: OptionType) => boolean,
  disabled?: any,
  appearance?: BaseSelectAppearance,
  checkboxGroupDirection?: 'row' | 'column',
  readonly?: boolean,
  nullable?: boolean,
  emptyValue?: '' | undefined | 0 | SingleValueType,
  showEmptyValue?: boolean,
  required?: boolean,
  optional?: boolean,
  onClick?: (e?: React.MouseEvent) => unknown,
  onChange?: (newValue?: string | number | string[]) => unknown,
  onBlur?: (newValue?: string | number | string[]) => unknown,
  iconVariant?: IconVariant,
  infoAfterInputField?: string | React.ReactElement;
  dataCy?: string,
  autoFocus?: boolean,
  autoComplete?: HTMLInputElement['autocomplete'],
}

export interface BaseSelectorInnerProps<
  FormType extends object,
  OptionType extends any = DefaultOptionType,
  SingleValueType extends DefaultSingleValueType = DefaultSingleValueType,
  ValueFieldType extends SingleValueType | string[] = SingleValueType,
> extends Pick<BaseSelectorProps<FormType, OptionType, SingleValueType, ValueFieldType>,
  'valueGetter' |
  'options' |
  'colorCodedState' |
  'optionLabelRenderer' |
  'defaultValue' |
  'disabledOptionChecker' |
  'valueEqualityChecker' |
  'onChange' |
  'onBlur' |
  'onClick' |
  'disabled' |
  'required' |
  'emptyValue' |
  'showEmptyValue' |
  'placeholder' |
  'autoComplete'
> {
  name?: string,
  valueFieldRef: ValueFieldType,
  multiple: boolean,
  isSelectedOption: (o: OptionType) => boolean,
  // select: (v: SingleValueType) => unknown,
}


const BaseSelector = <
  FormType extends AnyObject,
  OptionType extends any = DefaultOptionType,
  SingleValueType extends DefaultSingleValueType = DefaultSingleValueType,
  ValueFieldType extends SingleValueType | string[] = SingleValueType,
>(props: BaseSelectorProps<FormType, OptionType, SingleValueType, ValueFieldType>) => {

  const p = useProps(props);

  const ref = useRef(null);

  const s = useStore(() => {

    const defaultOptionEqualityChecker = (a: any, b: any) => a === b;
    const defaultValueEqualityChecker = (a: any, b: any) => a === b;
    const defaultGetter = (a: any) => typeof a === 'string' ? a : a.value;
    const defaultLabelRenderer = (a: any) => a instanceof Object ? (a.label || a.name) : a;
    const defaultDisabledOptionChecker = (a: any) => a instanceof Object ? a.disabled : false;
    const defaultOnChange = (v?: ValueFieldType) => {};
    const defaultOnBlur = (v?: ValueFieldType) => {};

    function isMultipleMode(value: any): value is string[] {
      return value instanceof Array;
    }

    const store = {
      shouldInvertStyle: false,
      get valueFieldRef(): ValueFieldType {
        if (!p.form) {
          SHOULD_LOG() && console.error('BaseSelector was not provided with a valid form. Props:', props);
        }
        const value = p.form[p.field];
        return (value === null ? '' : value) as unknown as ValueFieldType;
      },
      set valueFieldRef(newValue: ValueFieldType) {
        if (newValue === undefined) {
          // @ts-ignore
          p.form[p.field] = store.emptyValue;
        }
        // @ts-ignore
        p.form[p.field] = newValue;
      },
      get isMultiple() {
        return isMultipleMode(store.valueFieldRef);
      },
      get appearance() {
        return p.appearance || 'inline';
      },
      get valueGetter() {
        return p.valueGetter || defaultGetter;
      },
      get optionLabelRenderer() {
        return p.optionLabelRenderer || defaultLabelRenderer;
      },
      get optionEqualityChecker() {
        return p.optionEqualityChecker || defaultOptionEqualityChecker;
      },
      get valueEqualityChecker() {
        return p.valueEqualityChecker || defaultValueEqualityChecker;
      },
      get disabledOptionChecker() {
        return p.disabledOptionChecker || defaultDisabledOptionChecker;
      },
      get optional() {
        return p.optional ?? true;
      },
      get required() {
        return p.required ?? false;
      },
      get disabled() {
        return p.disabled ?? false;
      },
      get nullable() {
        return p.nullable ?? true;
      },
      get inverted() {
        return checkIfShouldInvertStyle(ref);
      },
      get defaultValue() {
        if (store.valueFieldRef instanceof Array) {
          return (p.defaultValue ?? []) as ValueFieldType;
        } else {
          return (p.defaultValue ?? '') as ValueFieldType;
        }
      },
      get emptyValue() {
        return (p.emptyValue ?? '') as SingleValueType;
      },
      get onChange() {
        return p.onChange || defaultOnChange;
      },
      get onBlur() {
        return p.onBlur || defaultOnBlur;
      },
      handleChange: (suppliedValue?: string | number | string[]) => {
        const hasChanged = store.select(suppliedValue);
        hasChanged && p.onChange && p.onChange(store.valueFieldRef);
      },
      handleBlur: (suppliedValue?: string | number | string[]) => {
        p.onBlur && p.onBlur(store.valueFieldRef);
      },
      select: action((suppliedValue?: string | number | string[]) => {
        if (p.readonly) return;
        let toReturn;
        switch (true) {
          case (suppliedValue === undefined): {
            toReturn = s.clearSelection(); break;
          }
          case (isMultipleMode(store.valueFieldRef)): {
            toReturn = s.selectMultiple(suppliedValue); break;
          }
          default: {
            toReturn = s.selectSingle(suppliedValue); break;
          }
        }
        return toReturn;
      }),
      clearSelection: action(() => {
        let toReturn = true;
        if (isMultipleMode(store.valueFieldRef)) {
          toReturn = false;
        } else {
          if (p.nullable) {
            store.valueFieldRef = store.emptyValue as any;
            toReturn = true;
          } else {
            toReturn = false;
          }
        }
        return toReturn;
      }),
      selectMultiple: action((suppliedValue?: string | number | string[]) => {
        if (!(store.valueFieldRef instanceof Array)) return;
        if (suppliedValue instanceof Array) {
          store.valueFieldRef.splice(0);
          addToArrayIfNew(store.valueFieldRef, ...suppliedValue);
          return true;
        } else {
          const i = store.valueFieldRef.findIndex(a => store.valueEqualityChecker(a, suppliedValue));
          if (i >= 0) {
            if (!p.nullable && store.valueFieldRef.length === 1) {
              return false;
            }
            store.valueFieldRef.splice(i, 1);
          } else {
            addToArrayIfNew(store.valueFieldRef, '' + suppliedValue);
          }
          return true;
        }
      }),
      selectSingle: action((suppliedValue?: string | number | string[]) => {
        if (s.valueEqualityChecker(store.valueFieldRef, suppliedValue)) {
          if (p.nullable) {
            // @ts-ignore
            store.valueFieldRef = store.emptyValue;
            return true;
          } else {
            return false;
          }
        } else {
          // @ts-ignore
          store.valueFieldRef = suppliedValue;
        }
        return true;
      }),
      isSelectedOption(o: OptionType) {
        const value = s.valueGetter(o);
        return s.isSelectedValue(value);
      },
      isSelectedValue: (value: string | number) => {
        if (value === undefined) return false;
        if (isMultipleMode(s.valueFieldRef)) {
          const i = s.valueFieldRef.findIndex(a => store.valueEqualityChecker(a, value));
          return i >= 0;
        } else {
          return store.valueEqualityChecker(store.valueFieldRef, value);
        }
      },
      get showEmptyValue() {
        return p.showEmptyValue ?? true;
      },
    }

    return store;

  })

  const renderInner = () => {
    const {
      valueFieldRef,
      valueGetter,
      optionLabelRenderer,
      valueEqualityChecker,
      defaultValue,
      disabledOptionChecker,
      required,
      disabled,
      emptyValue,
      showEmptyValue,
      handleChange,
      handleBlur,
      isMultiple,
      isSelectedOption
    } = s;
    const relayedProps = {
      name: p.name ?? p.field,
      options: p.options,
      valueFieldRef,
      valueGetter,
      optionLabelRenderer,
      valueEqualityChecker,
      defaultValue,
      disabledOptionChecker,
      required,
      disabled,
      emptyValue,
      showEmptyValue,
      colorCodedState: p.colorCodedState,
      onChange: handleChange,
      onBlur: handleBlur,
      multiple: isMultiple,
      isSelectedOption,
      placeholder: p.placeholder,
      iconVariant: p.iconVariant,
      autoComplete: p.autoComplete,
    };
    switch (s.appearance) {
      case 'system': return <BaseSelectorSystemInner<FormType, OptionType, SingleValueType, ValueFieldType> {...relayedProps}/>;
      case 'inline': return <BaseSelectorInlineInner<FormType, OptionType, SingleValueType, ValueFieldType> {...relayedProps}/>;
      case 'checkboxGroup': return <BaseSelectorCheckboxGroupInner<FormType, OptionType, SingleValueType, ValueFieldType> {...relayedProps}/>;
      default: return <BaseSelectorSystemInner<FormType, OptionType, SingleValueType, ValueFieldType> {...relayedProps}/>;
    }
  }

  useEffect(() => {
    runInAction(() => {
      const shouldInvertStyle = checkIfShouldInvertStyle(ref);
      if (shouldInvertStyle !== s.shouldInvertStyle) s.shouldInvertStyle = shouldInvertStyle;
    })
  });

  return <Observer children={() => {
    const { className, label, optional, required, disabled, colorCodedState, appearance, checkboxGroupDirection = 'row', readonly } = p;
    const c = joinClassName(
      'BaseSelector',
      `BaseSelector${capitalizeFirstLetter(s.appearance)}`,
      className,
      colorCodedState && `state-${colorCodedState}`,
      disabled && 'disabled',
      readonly && 'readonly',
      s.shouldInvertStyle && 'inverted',
      appearance === 'checkboxGroup' && `checkboxGroupDirection${capitalizeFirstLetter(checkboxGroupDirection)}`,
    );
    const labelProps = { optional, required };
    return <div
      className={c}
      ref={ref}
      data-mode={s.isMultiple ? 'multiple' : 'single'}
      data-value={s.valueFieldRef}
      data-cy={p.dataCy}
    >
      {label && <BaseLabel {...labelProps}>{label}</BaseLabel>}
      {renderInner()}
      {p.infoAfterInputField && <div className="BaseSelectInfoAfterInputField">
        {p.infoAfterInputField}
      </div>}
    </div>
  }} />

}

export default BaseSelector;
