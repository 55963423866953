import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseButton from '../../../../base/components/BaseButton/BaseButton';
import ShadedBlock from '../../../../base/components/ShadedBlock/ShadedBlock';
import { UserEndpoints } from '../../../../base/endpoints/user.endpoints';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import { getNowTimestampUtc } from '../../../../base/utils/time.utils';
import { ModelName } from '../../../../constants/modelNames.enum';
import { User } from '../../../../models/makeUser.model';

interface UserDeletionManagerProps {
  user: User,
  onSuccess?: () => void,
  disabled?: boolean,
}

const UserDeletionManager: React.FC<UserDeletionManagerProps> = p => {

  const { UI, API } = useControllers();

  const confirmDelete = () => {
    if (p.disabled) return;
    UI.DIALOG.attention({
      heading: 'Are you sure you want to delete this user?',
      body: 'This action cannot be undone via admin panel UI. If you need to recover deleted account, please contact the development team for support. There is no guarantee that a deleted user can be recovered.',
      defaultActions: ['negative'],
      actions: [
        {
          label: 'Confirm and delete',
          action: () => new Promise<boolean>(async (resolve, reject) => {
            try {
              const url = UserEndpoints.staff.delete(p.user.id);
              await API.delete(url, ModelName.users, p.user);
              p.user.timeDeleted = getNowTimestampUtc();
              p.onSuccess?.();
              resolve(true);
            } catch(e) {
              reject(e);
              UI.DIALOG.error({
                heading: 'An error occurred while deleting the user.',
                error: e,
              })
            }
          })
        }
      ]
    })
  } 

  return <Observer children={() => (
    <ShadedBlock className="UserDeletionManager" color="red" spaceChildren>
      <h3>Delete Account</h3>
      <p>The user will lose access to the account immediately. All related user data will be marked for removal for the next scheduled cleanup. Note that before the next scheduled cleanup happens, some of the user's information will remain visible in the admin panels.</p>
      <BaseButton icon="delete" onClick={confirmDelete} fullWidth disabled={p.disabled} dataCy="deleteAccount">Delete Account</BaseButton>
    </ShadedBlock>
  )} />

}

export default UserDeletionManager;