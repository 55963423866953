import { UserEndpointParams, UserEndpoints } from "../base/endpoints/user.endpoints";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { User } from "../models/makeUser.model";

export const getUserIndex = (API: APIController, params?: UserEndpointParams) => {
  return new Promise<User[]>(async (resolve, reject) => {
    try {
      const url = UserEndpoints.staff.index(params);
      const { entries } = await API.getMany<User>(url, ModelName.users);
      if (!entries) return [];
      resolve(entries);
    } catch(e) {
      reject(e)
    }
  })
}