import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState, Nillable } from '../../base/@types';
import BackButton from '../../base/components/BackButton/BackButton';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseToggle from '../../base/components/BaseToggle/BaseToggle';
import BooleanRenderer from '../../base/components/BooleanRenderer/BooleanRenderer';
import ColorTag18Plus from '../../base/components/ColorTag/ColorTag18Plus';
import ColorTagFrontLineWorkers from '../../base/components/ColorTag/ColorTagFrontLineWorkers';
import ColorTagYoungPeople from '../../base/components/ColorTag/ColorTagYoungPeople';
import ColorTagYoungPeople1214 from '../../base/components/ColorTag/ColorTagYoungPeople1214';
import ColorTagYoungPeople1517 from '../../base/components/ColorTag/ColorTagYoungPeople1517';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import DurationRenderer from '../../base/components/DurationRenderer/DurationRenderer';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { navigateToChatPage } from '../../base/utils/chat.utils';
import joinClassName from '../../base/utils/className.utils';
import { NoOp } from '../../base/utils/functions.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { autoPluralize } from '../../base/utils/string.utils';
import { removeUrlParam } from '../../base/utils/urlParams.utils';
import { CommunicationType } from '../../constants/communicationTypes.descriptors';
import { IS_DEV, SHOULD_LOG } from '../../env';
import { isCounsellingSessionModel } from '../../models/makeCounsellingSession.model';
import { isSupportGroupModel, SupportGroup } from '../../models/makeSupportGroup.model';
import { AgeGroupFilterType } from '../../utils/ageAndDateOfBirth.utils';
import SupportGroupIcon from '../SupportGroupIcon/SupportGroupIcon';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import ChatTitleDisplayer from './ChatTitleDisplayer';
import { ChatWindowProps } from './ChatWindow';
import './ChatWindowHeader.scss';

interface ChatWindowHeaderProps extends ChatWindowProps {
  onToggleParticipantList?: Function,
}

function ChatWindowHeader(props: React.PropsWithChildren<ChatWindowHeaderProps>) {

  const p = useProps(props);

  const { AUTH, UI, NAVIGATOR } = useControllers();

  const s = useStore(() => ({

    get chat() {
      return p.chat;
    },
    get chatType() {
      return s.chat.type;
    },
    get chatHasEnded() {
      return !!s.chat.thread.timeEnded;
    },
    get videoOn() {
      return !!s.chat.videoChatRoom;
    },
    get preTitle() {
      const id = s.chat.associatedModel?.id;
      switch (s.chatType) {
        case 'counselling-session':
          return `Counselling Session${s.isCounsellorOrAdmin ? id ? ` #${id}` : '' : ''}`;
        case 'support-group': {
          const group = s.chat.associatedModel as Nillable<SupportGroup>;
          return <>
            <span>Support Group </span>
            {group?.isFrontLineWorkerGroup && <ColorTagFrontLineWorkers />}
            {(group?.ageGroups.includes(AgeGroupFilterType.youngPeople1214) || group?.ageGroups.includes(AgeGroupFilterType.youngPeople1517)) && <>
              {group?.ageGroups.includes(AgeGroupFilterType.adults) && <ColorTag18Plus />}
              {group?.ageGroups.includes(AgeGroupFilterType.youngPeople1214) && group?.ageGroups.includes(AgeGroupFilterType.youngPeople1517) ? <>
                <ColorTagYoungPeople />
              </> : <>
                  {group?.ageGroups.includes(AgeGroupFilterType.youngPeople1214) && <ColorTagYoungPeople1214 useAbbr />}
                  {group?.ageGroups.includes(AgeGroupFilterType.youngPeople1517) && <ColorTagYoungPeople1517 useAbbr />}
              </>}
            </>}
          </>
        }
        default: return 'Chat';
      }
    },
    get participants() {
      return s.chat.participants;
    },
    get currentUser() {
      return AUTH.currentUser;
    },
    get currentUserIsCounsellor() {
      return AUTH.isCounsellor
    },
    get currentUserCanFacilitateChat() {
      return AUTH.canFacilitateChat;
    },
    get canViewAssociatedModelDetails() {
      return p.chat.canViewAssociatedModelDetails;
    },
    get isCounsellorOrAdmin() {
      return AUTH.isCounsellor || AUTH.isStaff
    },
    get canStartVideo() {
      return s.currentUserCanFacilitateChat
    },
    get shouldShowVideoButton() {
      return s.currentUserCanFacilitateChat && s.chat.type !== 'support-group' && !s.chatHasEnded && (p.mode === 'docked' ? s.chat.shouldOpenInDock : true);
    },
    get toggleVideo() {
      return s.chat.toggleVideoChatAsStaff;
    },
    /** this is a confusing feature, let's always hide it for now */
    get shouldShowCloseButton() {
      return false;
      // return p.mode === 'docked';
    },
    get shouldShowBackButton() {
      return UI.displayMode === 'phone' && p.mode === 'standalone';
    },
    get routePrefix() {
      return NAVIGATOR.isInAdminArea ? 'admin' : 'app';
    },
    openInFullscreenMode: (action?: 'print') => {
      navigateToChatPage(NAVIGATOR, s.chat.id, action);
    },
    get alwaysShowButtonLabels() {
      return p.mode === 'standalone' && UI.displayMode !== 'phone';
    },
    get showButtonLabelsWhenTabOpen() {
      return (p.mode === 'docked' && p.chat.shouldOpenInDock) || s.alwaysShowButtonLabels
    },
    get canPrint() {
      return s.isCounsellorOrAdmin && p.chat.hasEnded;
    },
    get sessionHasAssignedCounsellorOrFacilitator() {
      return !!s.sessionCounsellorOrFacilitator;
    },
    get sessionCounsellorOrFacilitator() {
      if (isCounsellingSessionModel(s.chat.associatedModel)) {
        return s.chat.associatedModel.counsellor;
      } else if (isSupportGroupModel(s.chat.associatedModel)) {
        return s.chat.associatedModel.facilitator;
      }
      return undefined;
    },
    get buttonAppearance() {
      return p.mode === 'docked' ? 'text' : 'tab';
    },
    get buttonIconVariant() {
      return p.mode === 'docked' ? 'filled' : 'regular';
    },
    logToConsole: () => {
      if (!SHOULD_LOG()) return;
      console.log(`chat${s.chat.id}`, s.chat);
      Reflect.set(window, `chat${s.chat.id}`, s.chat);
    },
    closeChatTab: () => flow(function * () {
      if (!p.chat.hasEnded && !AUTH.currentUser?.preferences.doNotShowWarningBeforeClosingChatTabInDock) {
        if (AUTH.currentUser!.preferences.doNotShowWarningBeforeClosingChatTabInDock === undefined) {
          AUTH.currentUser!.preferences.doNotShowWarningBeforeClosingChatTabInDock = false;
        }
        const confirm = yield UI.DIALOG.present({
          heading: AUTH.isStaff ? 'Warning' : 'Closing Chat Tab',
          body: <>
            <p>Closing this chat tab does not automatically leave or end the session. As long as this session is still live, you can find it again in the Chats tab.</p>
            { AUTH.isStaff && <p>If you intend to end this session, please use the "<strong>End Session</strong>" button.</p>}
            <ShadedBlock>
              <BaseToggle
                form={AUTH.currentUser!.preferences}
                field="doNotShowWarningBeforeClosingChatTabInDock"
                label="Do not show this again"
              />
            </ShadedBlock>
          </>,
          defaultActions: ['negative', 'positive'],
          colorCodedState: AUTH.isStaff ? ColorCodedState.alert : undefined,
        })
        AUTH.saveCurrentUser();
        if (!confirm) return;
      }
      s.chat.removeFromDock();
    })(),

  }));

  useOnMount(() => {
    if (AUTH.canFacilitate && isCounsellingSessionModel(s.chat.associatedModel)) {
      if (s.chat.associatedModel.type === CommunicationType.Video && !s.chat.thread.timeVideoStarted) {
        s.chat.toggleVideoChatAsStaff();
      }
    }
  })

  const toggleParticipantList = () => {
    p.onToggleParticipantList && p.onToggleParticipantList()
  }

  const endThread = async () => {
    await s.chat.endChatAsStaff();
  }

  const viewSessionDetails = () => {
    p.chat.viewAssociatedModelDetails && p.chat.viewAssociatedModelDetails();
  }

  const handlePrintTranscriptButtonClick = () => {
    if (p.mode === 'docked') {
      s.openInFullscreenMode('print');
      return;
    } else {
      printTranscript();
    }
  }

  const printTranscript = () => {
    removeUrlParam('action');
    window.print();
  }

  return <Observer children={() => (
    <div className={joinClassName(
      "ChatWindowHeader",
      s.chat.hasEnded && 'ended',
    )} data-type={s.chat.type}>
      {((props.mode === 'docked' && s.chatHasEnded) || s.shouldShowCloseButton) && (
        <BaseButton
          appearance="text"
          icon="close"
          iconVariant="filled"
          className="ChatWindowHeaderButton"
          title="Close this window"
          onClick={s.closeChatTab}
        />
      )}
      { s.shouldShowCloseButton && <BaseButton
        appearance={s.buttonAppearance}
        iconVariant={s.buttonIconVariant}
        icon={s.chat.shouldOpenInDock ? 'chevron-down' : 'chevron-up'}
        className="ChatWindowHeaderButton"
        title={s.chat.shouldOpenInDock ? 'Collapse' : 'Open'}
        onClick={s.chat.toggleShouldOpenInDock}
      />}
        { s.shouldShowBackButton && <BackButton destination="up" /> }
        { !s.shouldShowBackButton && s.chat.type === 'support-group' && <SupportGroupIcon supportGroup={s.chat.associatedModel as SupportGroup} size={p.mode === 'docked' ? 28 : 38}/>}
        <section className="ChatWindowHeaderContent" onClick={p.mode === 'standalone' ? NoOp : p.chat.toggleShouldOpenInDock}>
          <p className="ChatWindowHeaderPreTitle">{s.preTitle} <span className="u-only-print"></span></p>
          <h3 className="ChatWindowHeaderTitle">
            <ChatTitleDisplayer chat={p.chat} />
          </h3>
          <div className="ChatWindowHeaderPrintInformation">
            <p><strong>Started at</strong> <span><DateRenderer value={s.chat.thread.timeStarted} /></span></p>
            <p><strong>Ended at</strong> <span>{s.chat.thread.timeEnded ? <DateRenderer value={s.chat.thread.timeEnded} /> : 'On-going'}</span></p>
            <p><strong>Duration</strong> <span><DurationRenderer startTime={s.chat.thread.timeStarted} endTime={s.chat.thread.timeEnded} /></span></p>
            {isCounsellingSessionModel(s.chat.associatedModel) && <p><strong>Counsellor</strong> <span><UsernameRenderer user={s.chat.associatedModel?.counsellor} userId={s.chat.associatedModel?.counsellorId} /></span></p> }
            {isSupportGroupModel(s.chat.associatedModel) && <p><strong>Facilitator</strong> <span><UsernameRenderer user={s.chat.associatedModel?.facilitator} userId={s.chat.associatedModel?.facilitatorId} /></span></p> }
            <p><strong>Participants</strong> <span>{s.participants.length}</span></p>
            <p><strong>Session is video chat</strong> <span><BooleanRenderer value={Boolean(s.chat.thread.timeVideoStarted)} /></span></p>
          </div>
        </section>
      <aside className="ChatWindowHeaderControls">
        { UI.fromTablet && props.mode !== 'docked' && <BaseButton
          appearance={s.buttonAppearance}
          iconVariant={s.buttonIconVariant}
          icon="keep-in-tray"
          className="ChatWindowHeaderButton"
          title="Dock chat"
          onClick={s.chat.keepInDock}
          label={ s.alwaysShowButtonLabels && 'Dock chat' }
        /> }
        { (props.mode !== 'docked' || s.chat.shouldOpenInDock) && <BaseButton
          appearance={s.buttonAppearance}
          iconVariant={s.buttonIconVariant}
          icon="people"
          className="ChatWindowHeaderButton"
          title="Toggle Participant List"
          onClick={toggleParticipantList}
          label={ s.alwaysShowButtonLabels ? autoPluralize(s.chat.participants.length, 'Participant') : s.chat.participants.length }
        /> }
        {s.canViewAssociatedModelDetails && (
          <BaseButton
            appearance={s.buttonAppearance}
            iconVariant={s.buttonIconVariant}
            icon='info'
            className="ChatWindowHeaderButton"
            title="Session Details"
            onClick={viewSessionDetails}
            label={ s.showButtonLabelsWhenTabOpen && 'Details'}
          />
        )}
        {s.canPrint && (props.mode !== 'docked' || s.chat.shouldOpenInDock) && (
          <BaseButton
            appearance={s.buttonAppearance}
            iconVariant={s.buttonIconVariant}
            icon='print'
            className="ChatWindowHeaderButton"
            title="Print Transcript"
            onClick={handlePrintTranscriptButtonClick}
            label={s.showButtonLabelsWhenTabOpen && 'Print'}
          />
        )}
        {
          !s.chat.hasEnded && <>
            {s.shouldShowVideoButton && (
              <BaseButton
                appearance={s.buttonAppearance}
                iconVariant={s.buttonIconVariant}
                icon="video"
                className="ChatWindowHeaderButton"
                title="Video Chat"
                onClick={s.toggleVideo}
                label={s.alwaysShowButtonLabels && (s.videoOn ? 'Disable Video' : 'Video Chat')}
              />
            )}
            {s.currentUserCanFacilitateChat && (
              <BaseButton
                appearance={s.buttonAppearance}
                iconVariant={s.buttonIconVariant}
                icon="power"
                className="ChatWindowHeaderButton"
                title="End session"
                onClick={endThread}
                label={s.showButtonLabelsWhenTabOpen && 'End Session'}
              />
            )}
            {
              IS_DEV && <BaseButton
                appearance={s.buttonAppearance}
                iconVariant={s.buttonIconVariant}
                icon="info"
                className="ChatWindowHeaderButton"
                title="Log"
                onClick={s.logToConsole}
              />
            }
          </>
        }
      </aside>
    </div>
  )} />
}

export default ChatWindowHeader;