import { Observer } from 'mobx-react-lite';
import React from 'react';
import './LongTextClamper.scss';

interface LongTextClipperProps {
  lines?: number | string,
  maxWidth?: string,
}

const LongTextClamper: React.FC<LongTextClipperProps> = props => {
  return <Observer children={() => (
    <div className="LongTextClamper" style={{
      WebkitLineClamp: parseInt('' + props.lines),
      maxWidth: props.maxWidth,
    }}>
      {props.children}
    </div>
  )} />
}

export default LongTextClamper;