import React from "react";
import OverlayAddressEditor from "../components/OverlayAddressEditor/OverlayAddressEditor";
import { UIController } from "../controllers/ui.controller";
import { Address } from "../models/makeAddress.model";

export const staffOpenOverlayAddressEditor = (
  UI: UIController,
  address?: Address,
) => {
  if (address) {
    UI.OVERLAY.present({
      component: <OverlayAddressEditor address={address} />
    })
  }
}