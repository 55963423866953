import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject, UnknownObject } from '../../base/@types';
import BaseSelector, { BaseSelectorProps } from '../../base/components/BaseSelector/BaseSelector';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { sortArray } from '../../base/utils/array.utils';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { User } from '../../models/makeUser.model';
import { useGetCounsellors } from '../../requests/useGetCounsellors.request';
import { getCounsellorDisplayName } from '../../utils/user.utils';

export interface CounsellorSelectorProps extends Omit<BaseSelectorProps<UnknownObject, User, string | undefined>, 'options'>{
}

const CounsellorSelector: React.FC<CounsellorSelectorProps> = props => {

  const p = useProps(props);
  const { AUTH } = useControllers();

  const s = useStore(() => ({
    counsellors: [] as User[],
    get sortedCounsellors() {
      const arr = sortArray(s.counsellors, {
        transformer: c => {
          const name = getCounsellorDisplayName(c);
          const isCurrentUser = AUTH.currentUser?.id === c.id;
          if (isCurrentUser) return name + ' (You)';
          else return name;
        }
      });
      return arr;
    },
    get loading() {
      return s.counsellors.length === 0;
    },
    get disabled() {
      return p.disabled ?? s.loading;
    },
    get placeholder() {
      return s.loading ? 'Loading counsellors...' : 'Select a counsellor';
    },
    get label() {
      return p.label ?? 'Assign a counsellor';
    }
  }));

  useGetCounsellors({observable: s, key: 'counsellors'});

  return <Observer children={() => (
    <BaseSelector<AnyObject, User, string | undefined>
      {...p}
      className={joinClassName(
        'CounsellorSelector',
        p.className
      )}
      name={p.name ?? p.field}
      appearance="system"
      options={s.sortedCounsellors}
      valueGetter={o => o?.id}
      valueEqualityChecker={(a, b) => a === b}
      label={s.label}
      emptyValue=""
      placeholder={s.placeholder}
      disabled={s.disabled}
      optionLabelRenderer={getCounsellorDisplayName}
    />
  )} />
}

export default CounsellorSelector;