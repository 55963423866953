
import { action } from "mobx";
import { SHOULD_LOG } from "../../env";
import { AnyObject, Nullable, StringKeyList, UnknownArray } from "../@types";
import { replaceContents } from "./array.utils";
import { equalByJsonSnapshot } from "./equality.utils";
import { reportError } from "./errors.utils";
import { uniq } from "./ramdaEquivalents.utils";
import { equalByString } from "./string.utils";
import { isArray, isObject, isString } from "./typeChecks.utils";

export const keys = <T extends object>(object: T) => Object.keys(object) as StringKeyList<T>;

export function getProp<T extends object>(key: string, object?: T) {
  return object ? object[key as keyof T] : undefined;
}

export function getValueOfKey<T extends object>(key: keyof T, object?: T) {
  return object ? object[key] : undefined;
}

export const setValueOfKey = <T extends object>(object: T, key: keyof T, newValue: any) => {
  object[key] = newValue;
}

export const setValueOfKeyFactory = <T extends object>(object: T) => (key: keyof T, newValue: any) => {
  object[key] = newValue;
}

export const setValueByPath = <T extends AnyObject, R>(object: T, path: string, value: R) => {
  const pathLevels = path.split('.');
  const lastLevel = pathLevels.pop();
  if (!lastLevel) return undefined;
  let target: AnyObject = object;
  pathLevels.forEach(level => target = target?.[level]);
  if (!target) return undefined;
  return target[lastLevel] = value;
}

export const forEachProperty = <T extends AnyObject>(object: T, fn: (key: keyof T, value: any, desc: PropertyDescriptor) => any) => {
  Object.entries(Object.getOwnPropertyDescriptors(object)).forEach(([key, desc]) => fn(key, object[key], desc));
}

export function copyWithJSON<T>(obj: T): T {
  if (obj === undefined || obj === null) return obj!;
  return JSON.parse(JSON.stringify(obj));
}

export const composeObject = <T extends object = {}>(...arr: T[]) => arr.reduce((a, b) => mergeIntoObjectByDescriptors(a, b), {}) as T;

export function mergeIntoObjectByDescriptors<A extends object, B extends object>(a: A, b: B) {
  Object.defineProperties(a, Object.getOwnPropertyDescriptors(b));
  return a;
}

export function convertObjectToArray<T extends unknown>(object: AnyObject) {
  const arr: UnknownArray = [];
  for (let key in object) {
    arr.push({ key: key, value: object[key] });
  }
  return arr as T[];
}
export function convertKeyValuePairArrayToObject<T extends AnyObject>(array: [string, any][]) {
  const result: AnyObject = {};
  for (let [key, value] of array) {
    result[key] = value;
  }
  return result as T;
}

export type TypeCastSchema<T> = Partial<Record<keyof T, 'string' | 'boolean' | 'number' | 'array' | 'object'>>;

export function recursiveMergeWithTypeCast<T extends AnyObject>(
  obj: T,
  _src?: Partial<T> | null,
  schema: TypeCastSchema<T> = {},
  skipUnknownKeys = true,
) {
  const objIsValid = obj instanceof Object && !isArray(obj) && Boolean(obj);
  if (!objIsValid) {
    SHOULD_LOG() && console.warn('The object supplied for recursiveMergeWithTypeCast is not an object, though this has been ignored and the application will attempt to proceed with merging.', obj);
  }
  const src = isString(_src) ? {} as Partial<T> : _src;
  if (!src) return obj;
  const keys = skipUnknownKeys ? Object.keys(obj) : uniq([...Object.keys(obj), ...Object.keys(src)]);
  keys.forEach((key: any) => {
    const setter = setValueOfKeyFactory(obj as any);
    if (src[key] !== undefined) {
      const typeofKey = schema[key] || typeof obj[key];
      switch (typeofKey) {
        case 'boolean': setter(key, src[key] === null ? src[key] : !!src[key]); break;
        case 'string': setter(key, src[key] === null ? src[key] : src[key] + ''); break;
        case 'number': setter(key, src[key] === null ? src[key] : parseFloat(src && src[key] as any)); break;
        default: {
          if (isArray(obj[key])) {
            replaceContents<any>(obj[key], ((src as any) ?? [])[key]);
          } else if (isObject(obj[key])) {
            recursiveMergeWithTypeCast(obj[key], src[key], undefined, false);
          } else {
            setter(key, src[key]);
          }
        }
      }
    }
  })
  return obj as T;
}

export const mergeIntoObjectWithDescriptor = action(
  <T extends object = object>(
    obj: T, src: Nullable<Partial<T>> = {},
    options?: { mergeInPlace?: boolean }
  ) => {
    if (!obj) {
      throw Error('mergeIntoObject requires there to be an object to merge into!');
    }
    const { mergeInPlace = true } = options || {}
    const o = mergeInPlace ? obj : copyWithJSON(obj);
    for (let key in src) {
      try {
        const descriptor = Reflect.getOwnPropertyDescriptor(o, key);
        const isWritable = descriptor?.writable || descriptor?.set;
        const hasChanged = !equalByJsonSnapshot(o[key], src[key]);
        if (isWritable && hasChanged) {
          // @ts-ignore
          o[key] = src[key];
        }
      } catch (e) {
        reportError(e);
        SHOULD_LOG() && console.error('An error occurred while merging objects.', e);
        SHOULD_LOG() && console.error('Error key: ', key);
      }
    }
    return o;
  }
)

export const someValuesTruthy = (obj: object): boolean => Object.values(obj).some(v => isObject(v) ? someValuesTruthy(v) : !!v);
export const allValuesTruthy = (obj: object): boolean => Object.values(obj).every(v => isObject(v) ? allValuesTruthy(v) : !!v);


export function compareShallowEqualBySelectedKeys<T extends AnyObject>(a: T, b: T, compareKeys?: (keyof T)[]) {
  const _compareKeys = compareKeys || Object.keys(a);
  return Object.keys(a).filter(
    k => _compareKeys.includes(k)
  ).every(key => {
    return equalByString(a[key], b[key]);
  });
}
