import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useProps, useStore } from '../../utils/mobx.utils';
import './TabGroup.scss';
import TabGroupItem, { TabConfig } from './TabGroupItem';

interface TabGroupProps {
  tabs: TabConfig[],
  offsetLeft?: string | number,
}

const TabGroup: React.FC<TabGroupProps> = props => {

  const p = useProps(props);
  const s = useStore(() => ({
    get style() {
      return ({
        paddingLeft: p.offsetLeft,
      })
    }
  }));

  return <Observer children={() => (
    <div className="TabGroup" style={s.style}>
      {
        p.tabs.map(tab => <TabGroupItem
          key={tab.name || tab.label + ''}
          { ...tab }
        />)
      }
    </div>
  )} />
  
}

export default TabGroup;