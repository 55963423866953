import { CounsellingApplication, CounsellingApplicationRelatedModels } from "../../models/makeCounsellingApplication.model";
import { DefaultIndexParamSet } from "../utils/api.utils";
import { makeApiEndpointConfig, makeApiGetEndpointConfig } from "../utils/endpoints.utils";
import { makeUrl } from "../utils/url.utils";
import { prefixOwn, prefixStaff } from "./namespacePrefixes";

export type CounsellingApplicationEndpointParams = DefaultIndexParamSet<
  CounsellingApplication,
  CounsellingApplicationRelatedModels
> & {
  include?: (keyof CounsellingApplicationRelatedModels | string)[];
  latest?: boolean;
  from?: string | null;
  to?: string | null;
};
export const DefaultCounsellingApplicationIncludes = [
  'applicant',
  'counsellor',
  'availability',
  'availability.allowedCompany',
  'assignments.target',
  'invitation',
  'invitee',
  'clients',
  'address',
  'applicant.emergencyContacts',
]
export const DefaultCounsellingApplicationIncludesWithSessionDetails = [
  ...DefaultCounsellingApplicationIncludes,
  'sessions.assignments.target',
  'sessions.clients',
]
const ownUrlBase = prefixOwn('counselling-applications');
const adminUrlBase = prefixStaff('counselling-applications');

export const CounsellingApplicationEndpoints = {
  own: {
    index: makeApiEndpointConfig<CounsellingApplicationEndpointParams>(ownUrlBase),
    get: makeApiGetEndpointConfig<CounsellingApplicationEndpointParams>(ownUrlBase),
    create: () => ownUrlBase,
    update: (id: string) => makeUrl(ownUrlBase, id),
  },
  staff: {
    index: makeApiEndpointConfig<CounsellingApplicationEndpointParams>(adminUrlBase),
    get: makeApiGetEndpointConfig<CounsellingApplicationEndpointParams>(adminUrlBase),
    create: () => adminUrlBase,
    update: (id: string) => makeUrl(adminUrlBase, id),
    delete: (id: string) => makeUrl(adminUrlBase, id),
    approve: (id: string) => makeUrl(adminUrlBase, id, 'approve'),
    reject: (id: string) => makeUrl(adminUrlBase, id, 'reject'),
    complete: (id: string) => makeUrl(adminUrlBase, id, 'complete'),
  },
}