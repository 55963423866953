import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState } from '../../base/@types';
import InfoBanner from '../../base/components/InfoBanner/InfoBanner';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { getProp } from '../../base/utils/object.utils';
import { groupBy } from '../../base/utils/ramdaEquivalents.utils';
import { autoPluralize, toSentenceCase } from '../../base/utils/string.utils';
import { NUM_FREE_COUNSELLING_SESSIONS } from '../../constants/counsellingFeeTypes.constants';
import { CounsellingApplication } from '../../models/makeCounsellingApplication.model';
import { CounsellingSession } from '../../models/makeCounsellingSession.model';
import { useGetCounsellingSessions } from '../../requests/useGetCounsellingSessions.request';
import { isOfCounsellingTypeYoungPeople } from '../../utils/counsellingApplication.utils';
// import './IrishClientFreeSessionInfo.scss';

interface IrishClientFreeSessionInfoProps {
  application: CounsellingApplication,
  session?: CounsellingSession,
  clientId: string,
}

export const IRISH_CLIENT_MAX_FREE_SESSION_PER_YEAR = {
  'one-to-one': NUM_FREE_COUNSELLING_SESSIONS,
  'young-people': 8,
  'couples': 8,
} as const;

const IrishClientFreeSessionInfo: React.FC<IrishClientFreeSessionInfoProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    allSessionsOfClient: [] as CounsellingSession[],
    get groupSessionsByApplications() {
      return groupBy(((ses: CounsellingSession) => ses.applicationId + ''), s.allSessionsOfClient);
    },
    get numberOfApplications() {
      return Object.keys(s.groupSessionsByApplications).length;
    },
    get sessionsOfThisApplication() {
      return s.allSessionsOfClient.filter(ses => ses.applicationId === p.application.id);
    },
    get freeSessionsOfThisApplication() {
      return s.sessionsOfThisApplication.filter(s => !s.isPaidSession);
      // return s.sessionsOfThisApplication.filter(s => !s.amountPayable || (parseFloat(s.amountPayable + '') === 0))
    },
    get totalNumberOfSessions() {
      return s.allSessionsOfClient.length;
    },
    get totalNumberOfSessionsTaken() {
      return s.allSessionsOfClient.filter(s => !!s.timeEnded).length;
    },
    get freeSessions() {
      return s.allSessionsOfClient.filter(s => !s.isPaidSession);
      // return s.allSessionsOfClient.filter(s => !s.amountPayable || (parseFloat(s.amountPayable + '') === 0));
    },
    get freeSessionsTaken() {
      return s.freeSessions.filter(s => !!s.timeEnded);
    },
    get applicationType() {
      return isOfCounsellingTypeYoungPeople(p.application?.type) ? 'young-people' : p.application?.type;
    },
    get maximum() {
      return s.applicationType ? getProp(s.applicationType, IRISH_CLIENT_MAX_FREE_SESSION_PER_YEAR) ?? NUM_FREE_COUNSELLING_SESSIONS : NUM_FREE_COUNSELLING_SESSIONS;
    },
    get maximumReached() {
      return s.freeSessions.length >= s.maximum;
    },
  }));

  useGetCounsellingSessions({
    observable: s,
    key: 'allSessionsOfClient',
    lazy: false,
  }, {
    filter: { clientId: p.clientId },
  })

  return <Observer children={() => (
    p.application.isPaid || p.session?.isPaidSession
      ? null
      : <InfoBanner className="IrishClientFreeSessionInfo" colorCodedState={ColorCodedState.positive} icon="info">
        <p>
          <span>Irish applicants are eligible to {s.maximum} free {toSentenceCase(s.applicationType)} sessions per application that is approved and valid.</span>
          {s.sessionsOfThisApplication.length > 0 && <span> This client has {autoPluralize(s.sessionsOfThisApplication.length, 'session')} in this application, and {s.freeSessionsOfThisApplication.length} of them are free. </span>}
        </p>
      </InfoBanner>
  )} />

}

export default IrishClientFreeSessionInfo;