import { action, flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { MutableRefObject, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import { ColorCodedState, Renderable } from '../../@types/ui.types';
import { ContextColor } from '../../constants/color.enum';
import joinClassName from '../../utils/className.utils';
import { getContextColorStyle } from '../../utils/colors.utils';
import { renderRenderable } from '../../utils/components.utils';
import { reportError } from '../../utils/errors.utils';
import { NoOp } from '../../utils/functions.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import BaseIcon from '../BaseIcon/BaseIcon';
import { useFormContext } from '../FormForm/Form.context';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { IconName, IconVariant } from '../Symbols/iconDefs';
import './BaseButton.scss';

export type BaseButtonAppearance = 'default' | 'text' | 'icon' | 'tab' | undefined;

export interface BaseButtonProps {
  className?: string;
  icon?: IconName;
  iconVariant?: IconVariant,
  iconPosition?: 'left' | 'right',
  href?: string;
  name?: string;
  title?: string;
  to?: string;
  colorCodedState?: ColorCodedState | '';
  backgroundImage?: string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => Promise<unknown> | unknown;
  appearance?: BaseButtonAppearance;
  fullWidth?: any;
  padded?: any;
  disabled?: any;
  loading?: any;
  rounded?: any;
  circle?: boolean;
  type?: 'button' | 'submit';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  target?: string;
  label?: Renderable;
  hoverIcon?: IconName,
  hoverLabel?: string | false | null;
  hoverColor?: string;
  minWidth?: string | number;
  innerRef?: MutableRefObject<HTMLButtonElement | HTMLAnchorElement | null>;
  iconSize?: string | number;
  dataCy?: string;
  allowButtonClickPropagation?: boolean;
}

const BaseButton: React.FC<BaseButtonProps> = props => {

  const formContext = useFormContext();

  const p = useProps(props);

  const s = useStore(() => ({
    get isLoading() {
      return p.loading || s.awaitingAction;
    },
    get noop() {
      return !!((p.onClick && p.onClick === NoOp) || (!p.onClick && !p.to && !p.href))
    },
    hovered: false,
    awaitingAction: false,
    handleButtonClick: flow(function * (e: SyntheticEvent) {
      try {
        if (p.disabled) return;
        if (p.loading) return;
        if (!p.allowButtonClickPropagation) e.stopPropagation();
        const onClickPromise = p.onClick && p.onClick();
        if (onClickPromise instanceof Promise) {
          s.awaitingAction = true;
          yield onClickPromise;
        }
      } catch (e) {
        reportError(e);
      } finally {
        s.awaitingAction = false;
      }
    }),
    handleHover: action(() => s.hovered = true),
    handleUnhover: action(() => s.hovered = false),
    get className() {
      const { name, className: propsClassName, appearance = 'default', colorCodedState: state, fullWidth, disabled, padded = false, size = 'md', rounded } = p;
      return joinClassName(
        'BaseButton',
        `BaseButton--${appearance}`,
        propsClassName,
        state && `state-${state}`,
        size,
        name,
        fullWidth && 'fullWidth',
        disabled && 'disabled',
        s.isLoading && 'isLoading',
        padded && 'padded',
        rounded && 'rounded',
        p.circle && 'circle',
        s.noop && 'noop',
        p.hoverLabel && 'hasHoverLabel'
      )
    },
    get iconVariant() {
      return p.iconVariant ?? (p.size === 'xs' || p.size === 'sm' ? 'filled' : 'regular');
    },
    get isIconLeftOfText() {
      return p.icon && (!p.iconPosition || p.iconPosition === 'left');
    },
    get isIconRightOfText() {
      return p.icon && (p.iconPosition && p.iconPosition === 'right')
    },
    get icon() {
      const { icon } = p;
      return (
        <BaseIcon name={icon} variant={s.iconVariant} size={p.iconSize}/>
      )
    },
    get children() {
      const { icon, children, label, hoverLabel, hoverIcon } = p;
      return <>
        <div className="BaseButtonInner">
          <div className="BaseButtonStaticLabel">
            {s.isIconLeftOfText && s.icon}
            {(children || label) && <div className="BaseButtonLabel">
              {renderRenderable(label)} {children}
            </div>}
            {s.isIconRightOfText && s.icon}
          </div>
          {hoverLabel && <div className="BaseButtonHoverLabel">
            {(hoverIcon || icon) && <BaseIcon name={hoverIcon ?? icon} variant={p.iconVariant}/>}
            <div className="BaseButtonLabel">{hoverLabel}</div>
          </div>}
        </div>
        {s.isLoading && <LoadingIndicator />}
      </>
    },
    get style() {
      const { hoverColor = p.color, color } = p;
      const colorStyle = getContextColorStyle(ContextColor.Primary, s.hovered ? hoverColor : color);
      return  {
        ...colorStyle,
        minWidth: p.minWidth,
        backgroundImage: p.backgroundImage,
      }
    },
    get commonAttr() {
      const { name, title, disabled } = p;
      return {
        'data-name': name,
        title: title || name,
        className: s.className,
        style: s.style,
        children: s.children,
        onClick: s.handleButtonClick,
        disabled: disabled || formContext?.disabled,
        onMouseEnter: s.handleHover,
        onMouseLeave: s.handleUnhover,
        ref: p.innerRef as MutableRefObject<any>,
        'data-cy': p.dataCy,
        rel: p.target === '_blank' ? 'noreferrer' : undefined,
      }
    }
  }));


  return <Observer children={() => {
    const { to, href, type, target } = p;
    switch (true) {
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      case (!!href): return <a href={href} {...s.commonAttr} target={target} />;
      case (!!to): return <Link to={to!} {...s.commonAttr} />;
      default: return <button type={type} {...s.commonAttr} />;
    }
  }} />

}

export default BaseButton;