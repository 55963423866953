import React from 'react';
import ColorTag from './ColorTag';

type ColorTagHiddenProps = {
	onClick?: () => void;
}

const ColorTagHidden: React.FC<ColorTagHiddenProps> = props => {
	return <ColorTag color="median" onClick={props.onClick}>Hidden</ColorTag>
}

export default ColorTagHidden;