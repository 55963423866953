export const binaryGenderDescriptors = [
  {
    name: 'Female',
    abbr: 'Female',
    value: 'female',
  },
  {
    name: 'Male',
    abbr: 'Male',
    value: 'male',
  },
] as const

export const genderDescriptors = [
  {
    name: 'Prefer not to say',
    abbr: 'Prefer not to say',
    // abbr: 'N/A',
    value: 'prefer-not-to-say',
  },
  ...binaryGenderDescriptors,
  {
    name: 'Non-Binary',
    abbr: 'Non-Binary',
    value: 'non-binary',
  },
  {
    name: 'Transgender',
    abbr: 'Transgender',
    value: 'transgender',
  },
  {
    name: 'Two-Spirit',
    abbr: 'Two-Spirit',
    value: 'two-spirit',
  },
  {
    /** ? */
    name: 'Cis Gender',
    abbr: 'Cis',
    value: 'Cis Gender',
  },
  {
    name: 'Genderqueer',
    abbr: 'Genderqueer',
    value: 'genderqueer',
  },
  {
    name: 'Gender expression',
    abbr: 'Gender expr.',
    value: 'Gender expression',
  },
  {
    name: 'Gender fluid',
    abbr: 'Gender fluid',
    value: 'Gender fluid',
  },
  {
    name: 'Gender neutral',
    abbr: 'Gender neutral',
    value: 'Gender neutral',
  },
] as const
