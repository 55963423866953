import { AxiosRequestConfig } from 'axios';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { FunctionOnOTPFail, FunctionOnOTPVerified } from '../../../../@types/mfa.types';
import { ColorCodedState } from '../../../../base/@types';
import AppPage from '../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../base/components/AppPageHeader/AppPageHeader';
import DateRenderer from '../../../../base/components/DateRenderer/DateRenderer';
import OverlayCloseButton from '../../../../base/components/OverlayCloseButton/OverlayCloseButton';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import { NoOp } from '../../../../base/utils/functions.utils';
import { useProps } from '../../../../base/utils/mobx.utils';
import { getDiffFromTimeStamps, YYYYMMDDHHmmss } from '../../../../base/utils/time.utils';
import tick from '../../../../base/utils/waiters.utils';
import OTPVerification from '../../../../components/OTPVerification/OTPVerification';
import { AuthController } from '../../../../controllers/auth.controller';
import { User } from '../../../../models/makeUser.model';
// import './OverlayOTPVerification.scss';

export const ID_OverlayOTPVerification = 'OverlayOTPVerification';

export const promptOTPVerification = async (
  AUTH: AuthController,
  user: User,
) => {
  await tick(2700);
  const { COMMON, UI, NAVIGATOR, MESSENGER } = AUTH.ROOT!.children;
  if (!AUTH.currentUser) return;
  if (!AUTH.isAuthenticated) return;
  if (NAVIGATOR.currentLocationPathname.match(/^\/app\/(invitation)/)) return;
  if (MESSENGER.dockedChats.length) return;
  const isNewStaffUserLessThanTwoWeek = getDiffFromTimeStamps(AUTH.currentUser.timeCreated, COMMON.CLOCK.localNowMoment.format(YYYYMMDDHHmmss), 'day') <= 14;
  const isBeforeDeadline = getDiffFromTimeStamps(COMMON.CLOCK.localNowMoment.format(YYYYMMDDHHmmss), '2022-04-23 00:00:00', 'day') > 0;
  UI.DIALOG.present({
    name: '2fa-setup-dialog',
    heading: 'Two-Factor Authentication Setup',
    colorCodedState: ColorCodedState.attention,
    body: () => <>
      <p>Hello! You do not seem to have Two-Factor Authentication (2FA) set up.</p>
      {isNewStaffUserLessThanTwoWeek
        ? <p><strong>2FA will be required on your next login</strong>.</p>
        : isBeforeDeadline && <p>2FA will be required from <strong><DateRenderer value="2022-04-23 00:00:00" format="MMMM D, YYYY" /></strong>.</p>
      }
      <p>Would you like to go through the setup process?</p>
    </>,
    actions: [
      ...(true || isNewStaffUserLessThanTwoWeek || isBeforeDeadline) ? [{
        name: 'remindLater',
        buttonClass: 'subtle',
        label: 'Remind me later',
        action: NoOp,
      }] : [],
      {
        name: 'positive',
        label: 'OK',
        action: () => {
          promptOTPVerificationOverlay(AUTH, user);
          return true;
        },
      }
    ]
  })
}

export const promptOTPVerificationOverlay = async (
  AUTH: AuthController,
  user: User,
  onOTPVerified?: FunctionOnOTPVerified,
  onOTPFail?: FunctionOnOTPFail,
  headers?: AxiosRequestConfig,
  doNotFetchUserOnVerified?: boolean,
) => {
  return new Promise<boolean>((resolve, reject) => {
    const { UI } = AUTH.ROOT!.children;
    UI.OVERLAY.present({
      name: ID_OverlayOTPVerification,
      component: (
        <OverlayOTPVerification
          user={user}
          customHeaders={headers}
          onOTPVerified={onOTPVerified}
          onOTPFail={onOTPFail}
          doNotFetchUserOnVerified={doNotFetchUserOnVerified}
        />
      ),
      width: "48em",
      appearance: user.preferences.has2FA ? "card" : "sheet",
    });
  });
};

type OverlayOTPVerificationProps = {
  user: User,
  onOTPVerified?: FunctionOnOTPVerified,
  onOTPFail?: FunctionOnOTPFail,
  customHeaders?: AxiosRequestConfig,
  doNotFetchUserOnVerified?: boolean,
}

const OverlayOTPVerification: React.FC<OverlayOTPVerificationProps> = React.memo(props => {

  const { UI } = useControllers();

  const p = useProps(props);

  const closeSelf = () => UI.OVERLAY.dismiss(ID_OverlayOTPVerification);

  const skip = async () => {
    closeSelf();
    await p.onOTPVerified?.(true);
  }

  return <Observer children={() => <AppPage className="OverlayOTPVerification">
    <AppPageHeader title="Two-Factor Authentication"
      endSlot={<OverlayCloseButton onAfterClose={p.onOTPFail} />}
    />
    <AppPageContent padSections>
      <OTPVerification
        user={p.user}
        onOTPVerified={skip}
        onOTPFail={p.onOTPFail}
        customHeaders={p.customHeaders}
        autoFocus
        doNotFetchUserOnVerified={p.doNotFetchUserOnVerified}
      />
    </AppPageContent>
  </AppPage>
  } />

})

export default OverlayOTPVerification;