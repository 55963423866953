import { AnyObject, Nillable } from "../@types";
import { HasId, Identifier } from "../@types/traits.types";

export type RecordMap<T = object> = Map<Identifier, T>; 

export const makeRecordMap = <T extends HasId>(items?: T[]): RecordMap<T> => new Map<Identifier, T>(items?.map(i => [i.id, i]));

export const convertSnapshotArrayToMap = <T extends HasId>(arr: T[]) => {
  const result = {} as AnyObject;
  arr.forEach(i => result[i.id] = i);
  return result;
}
export const convertRecordMapToArray = <T extends HasId>(m: RecordMap<T>) => Array.from(m.values());

export const retrieveFromRecordMap = <T extends HasId>(map?: RecordMap<T>, ...ids: Identifier[]) => {
  const collection = makeRecordMap<T>();
  if (!map) return collection;
  ids.forEach(id => {
    const value = map.get(id);
    if (value) collection.set(id, value);
  })
  return collection;
}

export const findInMapByKey = <KeyType = string, ValueType = any>(
  map: Map<KeyType, ValueType> | undefined,
  predicate: (key: KeyType, value?: ValueType) => any,
) => {
  if (!map) return undefined;
  const iterator = map.entries();
  let current = iterator.next();
  while (!current.done) {
    const entry = current.value;
    if (Boolean(predicate(entry[0], entry[1]))) return entry;
    current = iterator.next();
  }
}
export const findInMapByValue = <KeyType = string, ValueType = any>(
  map: Map<KeyType, ValueType> | undefined,
  predicate: (value: ValueType, key?: KeyType) => any,
) => {
  if (!map) return undefined;
  const iterator = map.entries();
  let current = iterator.next();
  while (!current.done) {
    const entry = current.value;
    if (Boolean(predicate(entry[1], entry[0]))) return entry;
    current = iterator.next();
  }
}

export const findAllInMapByValue = <KeyType = string, ValueType = any>(
  map: Nillable<Map<KeyType, ValueType>>,
  predicate: (value: ValueType, key?: KeyType) => any,
) => {
  if (!map) return new Map();
  const iterator = map.entries();
  let current = iterator.next();
  const result = new Map<KeyType, ValueType>();
  while (!current.done) {
    const entry = current.value;
    if (Boolean(predicate(entry[1], entry[0]))) result.set(entry[0], entry[1]);
    current = iterator.next();
  }
  return result;
}

export const getFirstValueInMap = <KeyType = string, ValueType = any> (
  map: Map<KeyType, ValueType>,
): ValueType | undefined => {
  const iterator = map.entries();
  const first = iterator.next().value;
  return first ? first[1] : undefined;
}

export const addRecordsToMap = <V extends HasId>(map: Map<Identifier, V>, ...items: V[]) => {
  items.forEach(i => map.set(i.id, i));
}
