import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseCheckbox from '../../../../../base/components/BaseCheckbox/BaseCheckbox';
import BaseIcon from '../../../../../base/components/BaseIcon/BaseIcon';
import BaseSpacer from '../../../../../base/components/BaseSpacer/BaseSpacer';
import CounsellingApplicationInfoTable from '../../../../../components/CounsellingApplicationInfoTable/CounsellingApplicationInfoTable';
import { getWordPressLink } from '../../../../../env';
import { CounsellingApplicationStepProps } from '../applicationStepDescriptors';
import './CounsellingApplicationStepSummary.scss';

interface CounsellingApplicationStepSummaryProps extends CounsellingApplicationStepProps { }

const CounsellingApplicationStepSummary: React.FC<CounsellingApplicationStepSummaryProps> = p => {

  return <Observer children={() => (
    <div className="CounsellingApplicationStepSummary">

      <CounsellingApplicationInfoTable
        applicationSnapshot={p.form.application}
        applicantSnapshot={p.form.applicant}
        applicantAddressSnapshot={p.form.applicantAddress}
        applicantEmergencyContactSnapshot={p.form.applicantEmergencyContact}
        invitationSnapshot={p.form.invitation}
      />

      <BaseSpacer />

      <div className="CounsellingApplicationStepSummaryFooter">
        <BaseCheckbox form={p.form} field="hasAgreedToTerms">
          I have read and agreed to the turn2me <a href={getWordPressLink('/terms-and-conditions/')} title="turn2me Terms and Conditions" target="_blank" rel="noreferrer">Terms and Conditions <BaseIcon icon="external-link" size="1.4rem" variant="filled" /></a> and <a href={getWordPressLink('/privacy/')} title="turn2me Privacy Policy" target="_blank" rel="noreferrer">Privacy Policy <BaseIcon icon="external-link" size="1.4rem" variant="filled" /></a>.
        </BaseCheckbox>
      </div>

    </div>
  )} />

}

export default CounsellingApplicationStepSummary;