import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Renderable } from '../../base/@types';
import { renderRenderable } from '../../base/utils/components.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { formatNumberToLocale } from '../../base/utils/numbers.utils';
import { isNil } from '../../base/utils/ramdaEquivalents.utils';
import { isString } from '../../base/utils/typeChecks.utils';
// import './NumberDisplay.scss';

interface NumberDisplayProps {
	value: Renderable
	minimumFractionDigits?: number
	maximumFractionDigits?: number
	renderFalsyAs?: string,
}

const NumberDisplay: React.FC<NumberDisplayProps> = props => {
	const p = useProps(props);
	const s = useStore(() => ({
		get valueIsNumber() {
			return !isNaN(p.value as any);
		},
		get displayValue() {
			if (isString(p.renderFalsyAs) && !p.value) return p.renderFalsyAs;
			else if (isNil(p.value)) return '-';
			return s.valueIsNumber ? formatNumberToLocale(+p.value, { maximumFractionDigits: p.maximumFractionDigits, minimumFractionDigits: p.minimumFractionDigits }) : typeof p.value === 'number' ? '-' : renderRenderable(p.value);
		},
	}));
	return <Observer children={() => <data className="NumberDisplay">
		{s.displayValue}
	</data>} />
} 

export default NumberDisplay;