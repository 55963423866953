import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseToggle from '../../../../base/components/BaseToggle/BaseToggle';
import { addToArrayIfNew, removeFromArray } from '../../../../base/utils/array.utils';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import { AppPermissionRole } from '../../../../constants/permissionGroups.constants';
import { AppPermission } from '../../../../constants/permissions.constants';
import { AppStaffRole } from '../../../../constants/staffRoles.constants';
import { getRolePermissions } from '../../../../utils/permission.utils';
import './RoleToggle.scss';

type RoleToggleProps = {
  selectedPermissions: AppPermission[],
  selectedRoles: AppStaffRole[],
  thisRoleToggleName: AppStaffRole,
  forValue: AppPermissionRole,
  disabled?: boolean,
  removeAllRolesAndPermissions: () => void;
}

const RoleToggle: React.FC<RoleToggleProps> = props => {

  const p = useProps(props);
  const s = useStore(() => ({
    role: p.forValue,
    hasAllRolePermissions() {
      // if all permissions in role is selected.
      return s.role.permissions.every(perm => p.selectedPermissions.includes(perm));
    },
    get value() {
      return p.selectedRoles.includes(p.thisRoleToggleName);
      // return p.selectedRoles.includes(p.thisRoleToggleName) || s.hasAllRolePermissions();
    },
    set value(v: boolean) {
      if (v) {
        // if no roles, remove all user's existing roles and permissions.
        if (p.selectedRoles.length === 0) p.removeAllRolesAndPermissions()

        s.role.permissions.forEach(perm => addToArrayIfNew(p.selectedPermissions, perm))
        addToArrayIfNew(p.selectedRoles, p.thisRoleToggleName)
      } else {
        removeFromArray(p.selectedRoles, p.thisRoleToggleName)

        // if permissions for removed role does not exist in other roles,
        // then remove it.
        const rolePermissions = getRolePermissions(p.selectedRoles);
        s.role.permissions.forEach(perm => {
          if (!rolePermissions.includes(perm))
            removeFromArray(p.selectedPermissions, perm)
        })
      }
    }
  }))

  return <Observer children={() => (
    <BaseToggle
      className="RoleToggle"
      form={s}
      field="value"
      title={s.role.description}
      label={s.role.name}
      disabled={p.disabled}
    />
  )} />
}

export default RoleToggle;