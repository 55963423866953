import deepmerge from "deepmerge";
import { UserEndpointParams, UserEndpoints } from "../base/endpoints/user.endpoints";
import { useControllers } from "../base/hooks/useRootController.hook";
import { ModelName } from "../constants/modelNames.enum";
import { User } from "../models/makeUser.model";
import { useAutoGet, UseAutoGetFactoryOptions } from "../utils/useAutoGet.utils";

export function useGetUser<ObservableType = object>(
  options: UseAutoGetFactoryOptions<User, ObservableType>,
  id?: string | null,
  params?: UserEndpointParams,
) {
  const { AUTH } = useControllers();
  const canUserAdminEndpoint = AUTH.can.manage_.clients_.accessPersonalInfo || AUTH.isCounsellor;
  const url = AUTH.currentUser?.id === id ? (
    UserEndpoints.own.get({
      include: [
        'permissions',
        'roles',
      ]
    })
  ) : (
    id ? UserEndpoints[canUserAdminEndpoint ? 'staff' : 'client'].get(
      id,
      deepmerge(params ?? {}, { include: ['permissions', 'roles']})
    ) : undefined
  )
  useAutoGet<User, ObservableType>(
    {
      url,
      ...options,
      modelName: ModelName.users,
      getFromLocalById: id,
    }
  );
}

export const useGetCurrentUser = <T = object>(
  options: UseAutoGetFactoryOptions<User, T>,
) => {
  const { AUTH } = useControllers();
  useGetUser(options, AUTH.currentUser?.id);
}