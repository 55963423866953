import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import BaseSpacer from '../../../../base/components/BaseSpacer/BaseSpacer';
import IconWithCount from '../../../../base/components/IconWithCount/IconWithCount';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import UserIcon from '../../../../components/UserIcon/UserIcon';
// import './AdminNavControlSet.scss';

type AdminNavControlSetProps = {}

const AdminNavControlSet: React.FC<AdminNavControlSetProps> = props => {
  const { AUTH, ALERTS, UI } = useControllers();
  
  return <Observer children={() => (
    <div className="AdminNavControlSet">
      <IconWithCount
        count={ALERTS.unreadAlerts.length}
        icon="notification"
        href={`/admin/notifications`}
        size="3.6rem"
        roundedWithBackground
      />
      <BaseSpacer inline={!UI.fromTabletAndTall} />
      <Link className="AdminTitleBarAccountButton" to={`/admin/account`}>
        <UserIcon user={AUTH.currentUser} size="3.6rem" />
      </Link>
    </div>
  )} />
}

export default AdminNavControlSet;