import { BaseTableColumnConfig } from "../../../base/components/BaseTable/BaseTable";
import ColorTagCompany from "../../../base/components/ColorTag/ColorTagCompany";
import DateRenderer from "../../../base/components/DateRenderer/DateRenderer";
import { YYYYMMDD } from "../../../base/utils/time.utils";
import CommunicationTypeRenderer from "../../../components/CommunicationTypeRenderer/CommunicationTypeRenderer";
import CounsellingApplicationCompositeStatusColorTag from "../../../components/CounsellingApplicationCompositeStatusColorTag/CounsellingApplicationCompositeStatusColorTag";
import CounsellingTypeColorTag from "../../../components/CounsellingTypeColorTag/CounsellingTypeColorTag";
import DateOfBirthRenderer from "../../../components/DateOfBirthRenderer/DateOfBirthRenderer";
import SlotTypeRenderer from "../../../components/SlotTypeRenderer/SlotTypeRenderer";
import UsernameRenderer from "../../../components/UsernameRenderer/UsernameRenderer";
import { CounsellingApplication } from "../../../models/makeCounsellingApplication.model";
import { getAgeFromDateOfBirth } from "../../../utils/ageAndDateOfBirth.utils";

export const counsellingApplicationTypeCellRenderer = (a: CounsellingApplication) => {
  return <CounsellingTypeColorTag value={a.type} />
}
export const counsellingApplicationTableColumnConfigsForAdmin = (showPII?: boolean): BaseTableColumnConfig<CounsellingApplication>[] => [
  {
    label: 'ID',
    keyName: 'id',
    sortable: true,
    searchable: true,
  },
  {
    label: 'Type',
    keyName: 'type',
    BodyCell: d => <><SlotTypeRenderer isPaid={d.isPaid} />{counsellingApplicationTypeCellRenderer(d)}</>,
    sortable: true,
    searchable: true,
  },
  ...showPII ? [
    {
      label: 'Applied on',
      keyName: 'timeCreated',
      type: 'timestamp',
      sortable: true,
      searchable: true,
      BodyCell: a => <DateRenderer value={a.timeCreated} format={YYYYMMDD} />
    },
    {
      label: 'Applicant',
      keyName: 'applicantId',
      searchable: 'applicant.username',
      searchFilterLabel: 'Applicant Username',
      sortable: true,
      BodyCell: a => <UsernameRenderer userId={a.applicantId} showColorLabel />,
      bodyCellStyleFactory: () => ({
        maxWidth: '200px',
      })
    },
    {
      label: 'DOB',
      type: 'dateOfBirth',
      searchable: false,
      BodyCell: a => <DateOfBirthRenderer value={a.applicant?.dateOfBirth} showAge={false} format={YYYYMMDD} />,
    },
    {
      label: 'Age',
      BodyCell: d => getAgeFromDateOfBirth(d.applicant?.dateOfBirth),
      type: 'number',
      searchable: false,
    },
    {
      label: 'Country',
      keyName: 'providedCountryId',
      type: 'country',
    },
    {
      label: 'Detected Country',
      keyName: 'detectedCountryId',
      type: 'country',
    },
    {
      label: 'Counsellor',
      keyName: 'counsellorId',
      sortable: true,
      searchFilterLabel: 'Counsellor Username',
      searchable: 'counsellor.username',
      BodyCell: d => d.counsellorId ? <UsernameRenderer user={d.counsellor} userId={d.counsellorId} showColorLabel /> : '',
    },
    // {
    //   label: 'Invitee',
    //   BodyCell: d => {
    //     const { invitation } = d;
    //     if (!invitation) return;
    //     const { name, username } = invitation;
    //     return <>
    //       {name && <p>{name}</p>}
    //       {username && <p>Username: {username}</p>}
    //       {(invitation.actingAs === 'partner' || invitation.actingAs === 'dependent-child') && <em><InviteeConsentStatusDisplayer invitation={invitation} /></em>}
    //     </>
    //   },
    //   searchable: false,
    // },
    {
      label: 'Company',
      keyName: 'availability',
      BodyCell: d => <ColorTagCompany name={d.availability?.allowedCompany?.name} code={d.availability?.allowedCompany?.code} />
    }
  ] as BaseTableColumnConfig<CounsellingApplication>[] : [],
  // {
  //   label: 'Country',
  //   keyName: 'countryId',
  //   type: 'country',
  //   sortable: true,
  // },
  {
    label: 'Status',
    dataCy: 'status',
    BodyCell: d => <CounsellingApplicationCompositeStatusColorTag application={d} />,
    searchable: false,
  },
  {
    label: 'Comm. Type',
    keyName: 'communicationTypePreference',
    BodyCell: d => <CommunicationTypeRenderer value={d.communicationTypePreference} textOnly/>,
    sortable: true,
    searchFilterLabel: 'Communication Type',
  },
]