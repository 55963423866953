import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Undefinable } from '../../base/@types';
import { getGreetingTimeOfDay } from '../../base/bots/responses/greetings.botResponses';
import AppPage from '../../base/components/AppPage/AppPage';
import AppPageContent from '../../base/components/AppPageContent/AppPageContent';
import AppPageContentFooter from '../../base/components/AppPageContentFooter/AppPageContentFooter';
import AppPageHeader from '../../base/components/AppPageHeader/AppPageHeader';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseSpacer from '../../base/components/BaseSpacer/BaseSpacer';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import OverlayCloseButton from '../../base/components/OverlayCloseButton/OverlayCloseButton';
import UIBlock from '../../base/components/UIBlock/UIBlock';
import { SurveySupportGroupNonAttendanceEndpoints } from '../../base/endpoints/survey.endpoints';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { getSnapshot } from '../../base/utils/snapshot.utils';
import { getNowTimestampUtc } from '../../base/utils/time.utils';
import { ModelName } from '../../constants/modelNames.enum';
import { Assignment, AssignmentSupportGroupNonAttendance } from '../../models/makeAssignment.model';
import { makeSurveySupportGroupNonAttendance, SurveySupportGroupNonAttendanceSnapshot } from '../../models/makeSurveySupportGroupNonAttendance';
import SupportGroupSummaryCard from '../SupportGroupSummaryCard/SupportGroupSummaryCard';
import SurveyEditorSupportGroupNonAttendance from '../SurveyEditorSupportGroupNonAttendance/SurveyEditorSupportGroupNonAttendance';
import Turn2MeLogo from '../Turn2MeLogo/Turn2MeLogo';
import './OverlaySurveySupportGroupNonAttendance.scss';

export const ID_OverlaySurveySupportGroupNonAttendance = 'OverlaySurveySupportGroupNonAttendance';

type OverlaySurveySupportGroupNonAttendanceProps = {
  assignment?: AssignmentSupportGroupNonAttendance,
  assignmentId?: string,
  onComplete?: (a?: AssignmentSupportGroupNonAttendance) => unknown,
  readonly?: boolean,
}

const otherReasonSeparator = ": ";

const OverlaySurveySupportGroupNonAttendance: React.FC<OverlaySurveySupportGroupNonAttendanceProps> = props => {
  const { API, AUTH, UI } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    _assignment: undefined as Undefinable<Assignment>,
    get assignment() {
      return p.assignment || s._assignment;
    },
    get assignmentId() {
      return s.assignment?.id || p.assignmentId || '';
    },
    survey: makeSurveySupportGroupNonAttendance({
      // ...p.assignment?.target, // ! If used, parsed reasonForNonAttendance does not override for some reason.
      reasonForNonAttendance: p.assignment?.target?.reasonForNonAttendance?.startsWith('Other') ? 'Other' : p.assignment?.target?.reasonForNonAttendance,
      thinksMoreReminderWouldHelp: p.assignment?.target?.thinksMoreReminderWouldHelp,
      sendAdditionalReminderBefore: p.assignment?.target?.sendAdditionalReminderBefore,
      body: p.assignment?.target?.body,
    }),
    _localForm: {
      otherReasonInput: p.assignment?.target?.reasonForNonAttendance?.startsWith('Other') ? p.assignment.target.reasonForNonAttendance.split(otherReasonSeparator)[1] ?? '' : '',
    },
    get formIsComplete() {
      return (!s.survey.reasonForNonAttendance?.startsWith('Other') || s._localForm.otherReasonInput) &&
        s.survey.thinksMoreReminderWouldHelp &&
        s.survey.sendAdditionalReminderBefore;
    },
    get supportGroup() {
      return s.assignment?.associatedTypeModelName === ModelName.supportGroups ? s.assignment.associated : undefined;
    },
  }))
  const submit = flow(function* () {
    dismiss();
    try {
      if (s.survey.reasonForNonAttendance?.startsWith('Other') && s._localForm.otherReasonInput) s.survey.reasonForNonAttendance = s.survey.reasonForNonAttendance.concat(`${otherReasonSeparator}${s._localForm.otherReasonInput}`);
      const url = SurveySupportGroupNonAttendanceEndpoints.own.create();
      const payload: SurveySupportGroupNonAttendanceSnapshot = getSnapshot(s.survey);
      payload.body = payload.body || "-";
      payload.assignmentId = s.assignment?.id;
      payload.assignedToUserId = s.assignment?.assignedToUserId;
      payload.completedByUserId = AUTH.currentUser?.id;
      payload.timeCreated = getNowTimestampUtc();
      delete payload.timeDeleted;
      delete payload.timeUpdated;
      yield API.post(url, ModelName.surveysSupportGroupNonAttendance, payload);
      UI.DIALOG.success({
        name: 'support-group-non-attendance-survey-submission-success',
        heading: 'Your survey was submitted successfully.',
        body: 'Thanks for your participation! The surveys will help us improve our services for all of our clients. You can now continue to use the service.',
      });
      if (p.assignment) {
        p.assignment.timeCompleted = getNowTimestampUtc();
      }
      p.onComplete && p.onComplete(p.assignment);
      dismiss();
    } catch (e) {
      reportError(e);
      UI.DIALOG.error({
        heading: 'Sorry! Your form was not submitted successfully.',
        body: <ErrorRenderer error={(e as any).response} />,
      })
    }
  })
  const dismiss = () => {
    UI.OVERLAY.dismiss(ID_OverlaySurveySupportGroupNonAttendance);
  }
  useOnMount(() => {
    if (!p.readonly) {
      s.survey.assignmentId = s.assignmentId;
    }
  });
  return <Observer children={() => (
    <AppPage className="OverlaySurveySupportGroupNonAttendance">
      <AppPageHeader
        title={<div className="OverlaySurveySupportGroupNonAttendanceTitle">Non Attendance <br />Survey</div>}
        endSlot={p.readonly ? <OverlayCloseButton /> : <Turn2MeLogo version="full" />}
        spaceChildren
      />
      <AppPageContent padSections>
        {
          !p.readonly && <UIBlock>
            <p>{getGreetingTimeOfDay()} It seems you were not present for a support group you registered. Please spare 1 minute to fill in this survey.</p>
            <BaseSpacer size="sm" />
            {s.supportGroup && <SupportGroupSummaryCard supportGroup={s.supportGroup} actionOnClick="view" />}
          </UIBlock>
        }
        <UIBlock>
          <SurveyEditorSupportGroupNonAttendance survey={s.survey} readonly={p.readonly} _localForm={s._localForm} />
        </UIBlock>
        <AppPageContentFooter padded="all">
          {
            p.readonly ? <BaseButton fullWidth size="lg" onClick={dismiss}>Dismiss</BaseButton> : (
              <BaseButton fullWidth size="lg" onClick={submit} disabled={!s.formIsComplete} color="green" dataCy="Submit Survey">Submit Survey</BaseButton>
            )
          }
        </AppPageContentFooter>
      </AppPageContent>
    </AppPage>
  )} />
}

export default OverlaySurveySupportGroupNonAttendance;