import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { createElement } from 'react';
import { useProps, useStore } from '../../utils/mobx.utils';
import { YYYYMMDD } from '../../utils/time.utils';
import { BaseCalendarBaseChildProps } from './BaseCalendar';
import BaseCalendarDay from './BaseCalendarDay';
import BaseCalendarTimelineAxis from './BaseCalendarTimelineAxis';
import './BaseCalendarWeek.scss';

interface BaseCalendarWeekProps<T = object> extends BaseCalendarBaseChildProps<T> {
  startDate?: string;
  style?: React.CSSProperties,
}

function BaseCalendarWeek<T extends object>(props: React.PropsWithChildren<BaseCalendarWeekProps<T>>) {
  const { style, ...rest } = props;
  const p = useProps({...rest});
  const s = useStore(() => ({
    get startDate() {
      return p.startDate || p.state.startDate;
    },
    get startOfDaysMoments() {
      return Array(7).fill(null).map((n,i) => moment(s.startDate).add(i, 'days'));
    },
    get startOfDays() {
      return s.startOfDaysMoments.map(m => m.format(YYYYMMDD));
    },
    get daysRendered() {
      return s.startOfDays.map((d, i) => <BaseCalendarDay<T> key={d} day={d} index={i} {...p} />);
    },
    get children() {
      return <>
        {p.state.shouldDisplayAsTimeline && <BaseCalendarTimelineAxis />}
        {s.daysRendered}
      </>
    }
  }));
  return <Observer children={() => (
    createElement(
      p.state.shouldDisplayAsTimeline ? 'div' : 'tr',
      {
        className: 'BaseCalendarWeek',
        style,
      },
      p.state.shouldDisplayAsTimeline ? (
        <div className="BaseCalendarWeekInner">
          {s.children}
        </div>
      ) : s.children
    )
  )} />
}

export default BaseCalendarWeek;