import { Observer } from 'mobx-react-lite';
import React from 'react';
import joinClassName from '../../utils/className.utils';
import './BaseArticle.scss';

interface BaseArticleProps {
  className?: string;
}

const BaseArticle: React.FC<BaseArticleProps> = p => {
  return <Observer children={() => (
    <article className={joinClassName('BaseArticle', p.className)}>
      {p.children}
    </article>
  )}/>
}

export default BaseArticle;