import React from "react"
import { BaseTableColumnConfig } from "../../../base/components/BaseTable/BaseTable"
import ColorTag from "../../../base/components/ColorTag/ColorTag"
import DateRenderer from "../../../base/components/DateRenderer/DateRenderer"
import DurationRenderer from "../../../base/components/DurationRenderer/DurationRenderer"
import { getColorHexByName } from "../../../base/utils/colors.utils"
import CommunicationTypeRenderer from "../../../components/CommunicationTypeRenderer/CommunicationTypeRenderer"
import SessionPaymentStatus from "../../../components/SessionPaymentStatus/SessionPaymentStatus"
import SlotTypeRenderer from "../../../components/SlotTypeRenderer/SlotTypeRenderer"
import UsernameRenderer from "../../../components/UsernameRenderer/UsernameRenderer"
import { CounsellingSession } from "../../../models/makeCounsellingSession.model"

export const counsellingSessionTableColumnConfigsForAdmin: BaseTableColumnConfig<CounsellingSession>[] = [
  {
    label: 'ID',
    keyName: 'id',
    sortable: true,
    searchable: true,
  },
  {
    label: 'Slot Type',
    keyName: 'isPaidSession',
    BodyCell: d => <SlotTypeRenderer isPaid={d.isPaidSession} />,
  },
  {
    label: 'Donation / Payment Status',
    keyName: 'paymentStatus',
    BodyCell: d => <SessionPaymentStatus status={d.paymentStatus} />,
  },
  {
    label: 'Comms. Type',
    keyName: 'type',
    sortable: true,
    searchable: true,
    BodyCell: d => <CommunicationTypeRenderer value={d.type} textOnly />
  },
  {
    label: 'Counsellor',
    searchable: 'counsellor.username',
    BodyCell: d => <UsernameRenderer userId={d.counsellorId} key={d.counsellorId} />
  },
  {
    label: 'Client(s)',
    searchable: 'clients.username',
    BodyCell: d => <>{d.clientIds.map(id => <React.Fragment key={id}><UsernameRenderer userId={id} /> </React.Fragment>)}</>
  },
  {
    label: 'Scheduled Time',
    keyName: 'timeScheduled',
    type: 'timestampYYYYMMDDHHmmss',
    sortable: true,
  },
  {
    label: 'Actual Session Start/End',
    keyName: 'timeStarted',
    BodyCell: d => <>
      {
        d.timeStarted && <p>
          <DateRenderer value={d.timeStarted} format="h:mm A" />
          {d.timeEnded ? <> – <DateRenderer value={d.timeEnded} format="h:mm A" /></> : <> <ColorTag color="red">Live</ColorTag></>}
        </p>
      }
    </>,
    sortable: true,
  },
  {
    label: 'Duration',
    BodyCell: d => <DurationRenderer startTime={d.timeStarted} endTime={d.timeEnded} />
  },
  {
    label: 'Billable',
    keyName: "isBillable",
    type: 'boolean',
    searchable: false,
    bodyCellStyleFactory: d => ({
      fontWeight: 700,
      color: d.isBillable ? getColorHexByName('green') : getColorHexByName('red')
    })
  }
]