import { flow } from "mobx";
import { AddressEndpoints } from "../base/endpoints/address.endpoints";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { Address } from "../models/makeAddress.model";

export const deleteAddress = flow(function * (address: Address, API: APIController) {
  const endpoint = AddressEndpoints.own.delete;
  const url = endpoint(address.id);
  yield API.delete(url, ModelName.addresses, address);
  return;
})