import React from "react";
import {makeIconDef} from "./makeIconDef";

export const UkraineFlagDef = {
    "ukraine-flag": makeIconDef('ukraine-flag', {
        regular: () =>
            <g>
                <path fill="#005BBB"
                      d="M 1.3173913,0 H 22.67826 c 0.726088,0 1.317392,0.85 1.317392,1.89375 V 22.1 c 0,1.04375 -0.595652,1.89375 -1.317392,1.89375 H 1.3173913 C 0.59565218,24 0,23.15 0,22.10625 V 1.89375 C 0,0.85 0.59565218,0 1.3173913,0 Z"/>
                <path fill="#FFD500"
                      d="M 0,12 H 24 V 22.10625 C 24,23.15 23.404348,24 22.682608,24 H 1.3173913 C 0.59565218,24 0,23.15 0,22.10625 Z"/>
            </g>
    })
}