import { Observer } from 'mobx-react-lite';
import React from 'react';
import joinClassName from '../../utils/className.utils';
import './OptionsPanel.scss';
import OptionsPanelSection, { OptionsPanelSectionProps } from './OptionsPanelSection';

export interface OptionsPanelProps {
  className?: string,
  title?: string,
  sections?: OptionsPanelSectionProps[];
}

const OptionsPanel: React.FC<OptionsPanelProps> = props => {
  return <Observer children={() => (
    <aside className={
      joinClassName(
        'OptionsPanel',
        props.className
      )
    }>
      <div className="OptionsPanelInner">
        {
          props.title && <header className="OptionsPanelHeader">
            <h3>{props.title}</h3>
          </header>
        }
        {
          props.sections && props.sections.map(s => <OptionsPanelSection key={s.name} {...s} />)
        }
      </div>
    </aside>
  )} />
}

export default OptionsPanel;