import { FourLevelNumber, Nillable, StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { always, cond } from "../base/utils/ramdaEquivalents.utils";
import { ModelName } from "../constants/modelNames.enum";
import { assignable } from "../traits/assignable.trait";
import { AssignmentPHQ9 } from "./makeAssignment.model";
import { User } from "./makeUser.model";

export const makeSurveyPHQ9Snapshot = () => ({
	...assignable(),
	levelOfInterestDeficit: '' as FourLevelNumber,
	levelOfDepression: '' as FourLevelNumber,
	levelOfSleepDisorder: '' as FourLevelNumber,
	levelOfEnergyDeficit: '' as FourLevelNumber,
	levelOfAppetiteDeficit: '' as FourLevelNumber,
	levelOfSelfworthDeficit: '' as FourLevelNumber,
	levelOfConcentrationDeficit: '' as FourLevelNumber,
	levelOfHypoOrHyperactivity: '' as FourLevelNumber,
	levelOfSelfHarmThoughtsFrequency: '' as FourLevelNumber,
	levelOfPsychologicalImpact: '' as FourLevelNumber,
	total: 0 as number,
	timeCreated: '' as Nillable<string>,
	timeUpdated: '' as Nillable<string>,
})

export type SurveyPHQ9Snapshot = ReturnType<typeof makeSurveyPHQ9Snapshot>;
export type SurveyPHQ9RelatedModels = {
	assignment: AssignmentPHQ9,
	assignedToUser: User,
	completedByUser: User,
}
export type SurveyPHQ9 = StandardModel<SurveyPHQ9Snapshot, SurveyPHQ9RelatedModels>;

export const makeSurveyPHQ9 = createStandardModelFactory<SurveyPHQ9, SurveyPHQ9RelatedModels>({
	name: ModelName.surveysPHQ9,
	snapshotFactory: makeSurveyPHQ9Snapshot,
	relationshipsSchema: {
		assignment: ModelName.assignments,
		assignedToUser: ModelName.users,
		completedByUser: ModelName.users,
	}
})

export const calculatePHQ9SurveyScore = (s: SurveyPHQ9) => {
	return (
		(+s.levelOfInterestDeficit) +
		(+s.levelOfDepression) +
		(+s.levelOfSleepDisorder) +
		(+s.levelOfEnergyDeficit) +
		(+s.levelOfAppetiteDeficit) +
		(+s.levelOfSelfworthDeficit) +
		(+s.levelOfConcentrationDeficit) +
		(+s.levelOfHypoOrHyperactivity) +
		(+s.levelOfSelfHarmThoughtsFrequency)
	)
};

export const getPHQ9ScoreType = cond([
	[
		s => !s,
		always('No level of depression indicated')
	],
	[
		s => s > 0 && s < 5,
		always('Minimal depression')
	],
	[
		s => s >= 5 && s < 10,
		always('Mild depression')
	],
	[
		s => s >= 10 && s < 15,
		always('Moderate depression')
	],
	[
		s => s >= 15 && s < 20,
		always('Moderately severe depression')
	],
	[
		s => s >= 20 && s <= 27,
		always('Severe depression')
	],
])
