import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseToggle from '../../../../base/components/BaseToggle/BaseToggle';
import { addToArrayIfNew, removeFromArray } from '../../../../base/utils/array.utils';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import { AppPermission, getPermissionDisplayName } from '../../../../constants/permissions.constants';
import './PermissionToggle.scss';

type PermissionToggleProps = {
  selectedPermissions: AppPermission[],
  selectedRolePermissions: AppPermission[],
  forValue: AppPermission,
  disabled?: boolean,
}

const PermissionToggle: React.FC<PermissionToggleProps> = props => {

  const p = useProps(props);
  const s = useStore(() => ({
    get value() {
      return p.selectedPermissions.includes(p.forValue);
    },
    set value(v: boolean) {
      if (v) addToArrayIfNew(p.selectedPermissions, p.forValue);
      else removeFromArray(p.selectedPermissions, p.forValue);
    },
    get disabled() {
      return p.disabled || p.selectedRolePermissions.includes(p.forValue);
    },
  }))
  return <Observer children={() => (
    <BaseToggle
      className="PermissionToggle"
      form={s}
      field="value"
      label={getPermissionDisplayName(p.forValue)}
      disabled={s.disabled}
    />
  )} />
}

export default PermissionToggle;