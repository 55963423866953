import { observable, when } from "mobx"
import React from "react"
import { ColorCodedState } from "../../base/@types"
import tick from "../../base/utils/waiters.utils"
import OverlayProfileEditor from "../../modules/shared/Account/_components/OverlayProfileEditor"
import { AuthController } from "../auth.controller"
import { verifyEmail } from "./verifyEmail"
import { verifyMobileNumber } from "./verifyMobileNumber"

export const checkUserVerification = (
  AUTH: AuthController,
) => {

  when(

    () => Boolean(
      AUTH.ROOT?.children.UI &&
      AUTH.currentUser?.id
    ),

    async () => {

      await tick(1000);

      const user = AUTH.currentUser;
      if (!user) return;
      const { TOAST, OVERLAY, DIALOG } = AUTH.ROOT!.children.UI;

      const pathname = window.location.pathname;
      const isOnVerifyEmailPage = pathname.match(/^\/auth\/(verify-email)/)

      if (!user.email && !user.mobileNumber && !document.querySelector('.OverlayProfileEditor') && !document.querySelector('[data-dialog-name="AccountInformationIncomplete"]')) {
        await DIALOG.attention({
          name: 'AccountInformationIncomplete',
          heading: 'Account information incomplete',
          body: <>
            <p>It looks like your account profile is incomplete. Please update your profile before continuing.</p>
            <p>This should not happen in usual situations; if this problem occurs again, please get in touch to see and we will help you out.</p>
          </>,
          defaultActions: ['positive']
        })
        OVERLAY.present({
          component: <OverlayProfileEditor mustComplete />
        })
        return;
      }

      const status = observable({
        emailVerified: user.hasVerifiedEmail,
        mobileNumberVerified: user.hasVerifiedMobileNumber,
      })

      const doNoShowToastButShowInFeedInstead = window.location.pathname === '/app/explore';

      if (doNoShowToastButShowInFeedInstead) {
        return;
      } 

      if (user.email && !status.emailVerified && !isOnVerifyEmailPage) {

        await tick(2000);

        if (!AUTH.isAuthenticated) return;
        await verifyEmail(AUTH);

        const handler = async () => {
          await AUTH.fetchCurrentUserInfo({ skipVerification: true });
          if (AUTH.currentUser?.email && AUTH.currentUser?.timeVerifiedEmail) {
            TOAST.present({
              heading: 'Email Verified!',
              body: 'Your email has been verified.',
              colorCodedState: ColorCodedState.success,
            });
          }
        };
        
        window.addEventListener('focus', handler, { once: true });
        window.addEventListener('blur', async () => {
          await tick(1000);
          TOAST.dismiss('email-verification-toast')
        }, { once: true });

      } else if (user.mobileNumber && !status.mobileNumberVerified) {

        await tick(2000);
        
        await verifyMobileNumber(AUTH, status);

      }

    }
  )
}