import { Nillable, StandardModel } from "../base/@types"
import { ContactTypeEnum } from "../base/constants/contactType.constants"
import { createStandardModelFactory } from "../base/factories/standardModel.factory"
import { ModelName } from "../constants/modelNames.enum"
import { hasTimestamps } from "../traits/hasTimestamps.trait"

export const makeContactFormSnapshot = () => ({
  id: '',
  userId: null as Nillable<string>,
  name: '' as Nillable<string>,
  email: '' as Nillable<string>,
  phone: '' as Nillable<string>,
  type: '' as ContactTypeEnum | string,
  body: '',
  timeRead: '' as Nillable<string>,
  ...hasTimestamps(),
})

export type ContactFormSnapshot = ReturnType<typeof makeContactFormSnapshot>;

export type ContactFormRelatedModels = {
  user: '',
}

export type ContactFormExtendedProperties = {}

export type ContactForm = StandardModel<ContactFormSnapshot, ContactFormRelatedModels, ContactFormExtendedProperties>;

export const makeContactForm = createStandardModelFactory<ContactForm, ContactFormRelatedModels, ContactFormExtendedProperties>({
  name: ModelName.contactForms,
  snapshotFactory: makeContactFormSnapshot,
  relationshipsSchema: {
    user: ModelName.users,
  },
})
