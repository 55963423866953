import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import AppPage from '../../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../../base/components/AppPageHeader/AppPageHeader';
import BackButton from '../../../../../base/components/BackButton/BackButton';
import BaseButton from '../../../../../base/components/BaseButton/BaseButton';
import BaseSpacer from '../../../../../base/components/BaseSpacer/BaseSpacer';
import { showCreatePaymentMethodOverlay } from '../../../../../base/components/OverlayCreatePaymentMethodForm/OverlayCreatePaymentMethodForm';
import UIBlock from '../../../../../base/components/UIBlock/UIBlock';
import { useControllers } from '../../../../../base/hooks/useRootController.hook';
import { useStore } from '../../../../../base/utils/mobx.utils';
import PaymentMethodList from '../../../../../components/PaymentMethodList/PaymentMethodList';

type AccountPagePaymentMethodsProps = {}

const AccountPagePaymentMethods: React.FC<AccountPagePaymentMethodsProps> = props => {

  const { UI } = useControllers();

  const s = useStore(() => ({
    remountKey: 0,
    remountPaymentMethodList: action(() => {
      s.remountKey++;
    }),
    createPaymentMethod: () => {
      showCreatePaymentMethodOverlay(UI, s.remountPaymentMethodList);
    },
  }))

  return <Observer children={() => (
    <AppPage
      className="AccountPagePaymentMethods"
      accommodateTitleBarSpace
    >
      <AppPageHeader
        title="Payment Methods"
        startSlot={<BackButton destination="up" />}
      />
      <AppPageContent>
        <UIBlock padded>
          <PaymentMethodList key={s.remountKey} showAddCardButton={false}/>
          <BaseSpacer size=".5em"/>
          <BaseButton
            icon="plus"
            className="subtle"
            fullWidth
            color="green"
            size="lg"
            onClick={s.createPaymentMethod}
            label="Add New Card"
            dataCy="add-new-credit-card"
          />
        </UIBlock>
        <BaseSpacer />
      </AppPageContent>
    </AppPage>
  )} />
}

export default AccountPagePaymentMethods;