import { Observer } from 'mobx-react-lite';
import React, { CSSProperties } from 'react';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useObservableRef } from '../../hooks/useObservableRef.hook';
import tick from '../../utils/waiters.utils';

interface ClickOutsideProps {
  className?: string,
  onClickOutside?: () => void;
  style?: CSSProperties;
}

const ClickOutside: React.FC<ClickOutsideProps> = props => {

  const ref = useObservableRef();

  const handleClickOutside = (e: Event) => {
    if (e.target && ref && !ref.current?.contains(e.target as Node)) {
      props.onClickOutside?.();
    }
  }

  const createListener = () => {
    (async () => {
      await tick(100);
      document.addEventListener('mousedown', handleClickOutside);
    })();
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }

  useOnMount(createListener);

  return <Observer children={() => (
    <div 
      className={props.className} 
      ref={ref} 
      style={props.style} 
      children={props.children}
    />
  )} />

}

export default ClickOutside;