import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import MakePaymentForm from '../../../components/MakePaymentForm/MakePaymentForm';
import { ApiModelName } from '../../../constants/ApiModels.enum';
import { getCounsellingTypeName } from '../../../constants/counsellingTypes.descriptors';
import { FEATURE_FLAGS } from '../../../env';
import { Assignment } from '../../../models/makeAssignment.model';
import { CounsellingSession } from '../../../models/makeCounsellingSession.model';
import { PayableModel, Payment } from '../../../models/makePayment.model';
import { getCounsellingSession } from '../../../requests/getCounsellingSession.request';
import { getAssignmentAssociatedModelTypeDisplayName } from '../../../utils/assignment.utils';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useObservableRef } from '../../hooks/useObservableRef.hook';
import { useControllers } from '../../hooks/useRootController.hook';
import { useProps, useStore } from '../../utils/mobx.utils';
import { toTitleCase } from '../../utils/string.utils';
import { getNowTimestampUtc } from '../../utils/time.utils';
import { useSyncUrlParams } from '../../utils/urlParams.utils';
import AppPage from '../AppPage/AppPage';
import AppPageContent from '../AppPageContent/AppPageContent';
import AppPageHeader from '../AppPageHeader/AppPageHeader';
import BaseHeader from '../BaseHeader/BaseHeader';
import BaseSpacer from '../BaseSpacer/BaseSpacer';
import CurrencyRenderer from '../CurrencyRenderer/CurrencyRenderer';
import DateRenderer from '../DateRenderer/DateRenderer';
import DOMCopyButton from '../DOMCopyButton/DOMCopyButton';
import InfoTable from '../InfoTable/InfoTable';
import OverlayCloseButton from '../OverlayCloseButton/OverlayCloseButton';
import ShadedBlock from '../ShadedBlock/ShadedBlock';
import UIBlock from '../UIBlock/UIBlock';
import './OverlayMakePayment.scss';

interface OverlayMakePaymentProps {
  assignment?: Assignment,
  assignmentId?: string,
  amount?: number,
  onComplete?: (a?: Assignment) => unknown,
  onCloseButton?: () => void;
  hideCloseButton?: boolean,
}

const OverlayMakePayment: React.FC<OverlayMakePaymentProps> = props => {

  const p = useProps(props);
  const { API, UI } = useControllers();

  const s = useStore(() => ({
    get assignment() {
      return p.assignment;
    },
    get amount() {
      return p.amount || s.payableModel?.amountPayable;
    },
    get associatedModelTypeDisplayName() {
      return getAssignmentAssociatedModelTypeDisplayName(s.assignment?.associatedType);
    },
    get isForSession() {
      return s.assignment?.associatedType === ApiModelName.COUNSELLING_SESSION;
    },
    payableModel: p.assignment?.associated as PayableModel | null,
    get paymentType() {
      return s.payableModel?.isPaidSession ? 'payment' : 'donation';
    },
    get paymentOrDonationText() {
      switch (s.paymentType) {
        case 'payment':
          return {
            noun: 'payment',
            verb: 'pay',
          };
        case 'donation':
        default:
          return {
            noun: 'donation',
            verb: 'donate',
          };
      }
    },
  }))

  useSyncUrlParams('assignmentId', p.assignment?.id);

  useOnMount(() => {
    flow(function* () {
      if (!s.assignment) return;
      const { targetType, associatedId, associatedType } = s.assignment;
      if (targetType !== ApiModelName.PAYMENT) {
        throw TypeError(`Assignment with target type ${targetType} is passed to OverlayStripeMakePayment. This is not a valid operation.`);
      }
      if (!associatedId) {
        throw Error('No associatedId is found on a payment assignment, this is unexpected.');
      }
      if (!s.payableModel) {
        switch (associatedType) {
          case ApiModelName.COUNSELLING_SESSION: {
            const session = yield getCounsellingSession(API, associatedId);
            s.payableModel = session;
            break;
          }
          default: {
            throw TypeError(`Unsupported associated type ${associatedType} is passed to OverlayStripeMakePayment.`);
          }
        }
      }
    })()
  })

  const dismiss = () => {
    UI.OVERLAY.dismiss('OverlayStripeMakePayment');
  }

  // const managePaymentMethods = () => {
  //   UI.OVERLAY.dismissAll();
  //   NAVIGATOR.navigateTo('/app/account/payment-methods');
  // }

  const handleComplete = (payment?: Payment) => {
    p.onComplete && p.onComplete(p.assignment);
    UI.DIALOG.success({
      heading: () => <>Thank you! We have received your {s.paymentOrDonationText.noun} of <CurrencyRenderer value={s.amount} />.</>,
    })
    if (s.assignment) {
      s.assignment.targetId = payment?.id;
      s.assignment.timeCompleted = getNowTimestampUtc();
    }
    dismiss();
  }

  const bankInfoRef = useObservableRef<HTMLTableElement>();

  return <Observer children={() => (
    <AppPage className="OverlayMakePayment" color="red">
      <AppPageHeader
        title={s.isForSession ? `Session ${toTitleCase(s.paymentOrDonationText.noun)}` : toTitleCase(s.paymentOrDonationText.verb)}
        endSlot={p.hideCloseButton ? null : <OverlayCloseButton onAfterClose={p.onCloseButton} />}
      />
      <AppPageContent>
        <UIBlock padded>
          <ShadedBlock spaceChildren>
            <h3>{toTitleCase(s.paymentOrDonationText.verb)} <u><CurrencyRenderer value={s.amount} /></u> for your {s.associatedModelTypeDisplayName}</h3>
            {s.payableModel?.timeScheduled && <p>This {s.paymentOrDonationText.noun} is as a support your <strong>{(s.isForSession && (s.assignment?.associated as CounsellingSession)?.application?.type) ? getCounsellingTypeName((s.assignment!.associated as CounsellingSession).application!.type!, true) + ' ' : ''}{s.associatedModelTypeDisplayName}</strong> on <strong><DateRenderer value={s.payableModel.timeScheduled} format="LLLL" /></strong>. Your {s.paymentOrDonationText.noun} will be securely handled with <a href="https://stripe.com/" target="_blank" rel="noreferrer">Stripe</a>.</p>}
          </ShadedBlock>
          <BaseSpacer size="sm" />
          {FEATURE_FLAGS.DISABLE_DONATIONS
            ? <>
              <h3>We are currently experiencing technical issues with our payment processing.</h3>
              <BaseSpacer size="sm" />
              <p><strong>If you would like to make a much appreciated donation to Turn2Me, we would kindly request that you make a payment directly to our bank account, details below.</strong> Thank you for your patience and vital support.</p>
              <BaseSpacer size="sm" />
              <ShadedBlock>
                <BaseHeader
                  heading="Donations can be sent directly to the following details."
                  color="red"
                  endSlot={<DOMCopyButton targetRef={bankInfoRef} showButtonBackground />}
                >
                  <p>* You can click the copy button on the right to copy the following information.</p>
                </BaseHeader>
                <BaseSpacer size="sm" />
                <InfoTable tableRef={bankInfoRef}>
                  <tbody>
                    <tr><th>Bank Name:</th><td>Allied Irish Bank</td></tr>
                    <tr><th>Name on Account:</th><td>Turn2me</td></tr>
                    <tr><th>Bank Branch:</th><td>Main Street, Rathfarnham, Dublin 14, Ireland</td></tr>
                    <tr><th>Bank Sort Code:</th><td>93-32-95</td></tr>
                    <tr><th>Bank Account No.:</th><td>11098064</td></tr>
                    <tr><th>IBAN:</th><td>IE37AIBK93329511098064</td></tr>
                    <tr><th>BIC:</th><td>AIBKIE2DXXX</td></tr>
                  </tbody>
                </InfoTable>
              </ShadedBlock>
            </>
            : <>
              <ShadedBlock>
                <MakePaymentForm assignment={p.assignment} amount={s.amount} onComplete={handleComplete} type={s.paymentType} />
              </ShadedBlock>
              {/* <BaseSpacer size="sm" />
              <CommandList withBackground>
                <CommandListItem icon="arrow" onClick={managePaymentMethods} title="Manage your bank cards">Manage your bank cards</CommandListItem>
              </CommandList> */}
            </>
          }
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />

}

export default OverlayMakePayment;