import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import './ThoughtPrivateTag.scss';

interface ThoughtPrivateTagProps {
  onClick?: () => void;
}

const ThoughtPrivateTag: React.FC<ThoughtPrivateTagProps> = p => {
  return <Observer children={() => (
    <span className="ThoughtPrivateTag" onClick={p.onClick}>
      <BaseIcon icon="lock" title="This thought is private" variant="filled" size="1.8rem" /><strong>&nbsp;Private</strong>
    </span>
  )} />
}

export default ThoughtPrivateTag;