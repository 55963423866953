import { Nillable, Nullable, StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { ModelName } from "../constants/modelNames.enum";
import { assignable } from "../traits/assignable.trait";
import { AssignmentSupportGroupNonAttendance } from "./makeAssignment.model";
import { User } from "./makeUser.model";

export const makeSurveySupportGroupNonAttendanceSnapshot = () => {
  return {
    ...assignable(),
    reasonForNonAttendance: '' as Nullable<string>,
    thinksMoreReminderWouldHelp: '' as Nullable<string>,
    sendAdditionalReminderBefore: '' as Nullable<string>,
    body: '' as Nullable<string>,
    timeCreated: '' as Nillable<string>,
    timeUpdated: '' as Nillable<string>,
  }
}

export type SurveySupportGroupNonAttendanceSnapshot = ReturnType<typeof makeSurveySupportGroupNonAttendanceSnapshot>;
export type SurveySupportGroupNonAttendanceRelatedModels = {
  assignment: AssignmentSupportGroupNonAttendance,
  assignedToUser: User,
  completedByUser: User,
}
export type SurveySupportGroupNonAttendanceExtendedProperties = {};

export type SurveySupportGroupNonAttendance = StandardModel<SurveySupportGroupNonAttendanceSnapshot>;

export const makeSurveySupportGroupNonAttendance = createStandardModelFactory<SurveySupportGroupNonAttendance, SurveySupportGroupNonAttendanceRelatedModels>({
  name: ModelName.surveysSupportGroupNonAttendance,
  snapshotFactory: makeSurveySupportGroupNonAttendanceSnapshot
})