import { Nullable, StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { cond } from "../base/utils/ramdaEquivalents.utils";
import { ApiModelName } from "../constants/ApiModels.enum";
import { ModelName } from "../constants/modelNames.enum";
import { hasTimestamps } from "../traits/hasTimestamps.trait";
import { Thought } from "./makeThought.model";
import { User } from "./makeUser.model";

export type FlagSnapshot = ReturnType<typeof makeFlagSnapshot>;
export type FlaggableModel = Thought | Comment;

export type FlagRelatedModels<T extends FlaggableModel | object = object> = {
  model?: T,
  reporter?: User,
}

export type FlaggableModelNames = ApiModelName.THOUGHT | ApiModelName.COMMENT

export enum FlaggableOriginType {
  'community' = 'community',
  'automated' = 'automated',
}

export type FlagExtendedProperties = {}

export type Flag<T extends FlaggableModel | object = object> = StandardModel<
  FlagSnapshot,
  FlagRelatedModels<T>,
  FlagExtendedProperties
>

export type FlaggableModelType = ApiModelName.THOUGHT 
// | API_MODEL.POST;

export const makeFlagSnapshot = () => ({
  id: '',
  modelType: null as Nullable<FlaggableModelNames>,
  modelId: null as Nullable<string>,
  reporterId: '',
  // reporterComment: '',
  word: '',
  originType: '' as FlaggableOriginType,
  reason: '',
  /** TODO: not sure what this means */
  status: '' as unknown,
  ...hasTimestamps(),
})

export const makeFlag = createStandardModelFactory<Flag, FlagRelatedModels, FlagExtendedProperties>({
  name: ModelName.flags,
  snapshotFactory: makeFlagSnapshot,
  relationshipsSchemaFactory: s => ({
    reporter: ModelName.users,
    model: cond([
      [() => {
        return s?.modelType === ApiModelName.THOUGHT
      }, () => ModelName.thoughts],
      [() => s?.modelType === ApiModelName.COMMENT, () => ModelName.comments],
    ])()
  })
})