import React from 'react';
import './TitleBarHeightSpacer.scss';

type TitleBarHeightSpacerProps = {
	offset?: string | number;
}

const TitleBarHeightSpacer: React.FC<TitleBarHeightSpacerProps> = props => {
	return <div className="TitleBarHeightSpacer" style={{height: 'var(--TitleBarHeight)', marginTop: props.offset}}/>
}

export default TitleBarHeightSpacer;