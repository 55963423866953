import axios, { AxiosError } from 'axios';
import { action, flow, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { isInCypressTestMode } from '../../../env';
import { Nullable, Validator, ValidatorResult } from '../../@types';
import { TwilioEndpoints } from '../../endpoints/twilio.endpoints';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useCreateResizeQueryWithRef } from '../../hooks/useCreateResizeQueryWithRef.hook';
import { useControllers } from '../../hooks/useRootController.hook';
import { debounce } from '../../utils/debounce.utils';
import { reportError } from '../../utils/errors.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { countriesByDialCodes, countriesSortedByDialCodeLength, CountryDialCodeDef, getCountryCodeFromString } from '../../utils/tel.utils';
import BaseInput, { BaseInputProps } from '../BaseInput/BaseInput';
import BaseLabel from '../BaseLabel/BaseLabel';
import CountryDialCodeSelector from '../CountryDialCodeSelector/CountryDialCodeSelector';
import './TelInput.scss';

export type TelInputProps<FormType extends object> = Omit<BaseInputProps<FormType>, 'type' | 'min' | 'max' | 'step' | 'rows' | 'pattern'> & {
  doNotUseAPIValidation?: boolean,
  doNotUseOnMountGetUserDialCode?: boolean,
  usePublicAPIValidation?: boolean,
  disabled?: boolean,
  required?: boolean,
  optional?: boolean,
  onValidate?: (isValid: ValidatorResult) => void;
}

const TelInput = <T extends object>(props: React.PropsWithChildren<TelInputProps<T>>) => {

  const { API, ANALYTICS } = useControllers();

  const { ref, query } = useCreateResizeQueryWithRef<HTMLButtonElement>();

  const p = useProps(props);

  const s = useStore(() => ({
    country: null as Nullable<CountryDialCodeDef>,
    get countryCode() {
      return s.country?.countries[0]?.cca2;
    },
    _dialCode: '' as string | null,
    get dialCode() {
      return s.country?.dialCode ?? '';
    },
    get number() {
      if (!s.dialCode) return s.value;
      const regex = s.country?.regex;
      if (!regex) return s.value;
      return s.value ? s.value.match(regex)?.[1] : s.value;
    },
    set number(v) {
      s.value = '+' + s.dialCode + v?.replace(/[\D]/g, "");
    },
    get value() {
      return p.form[p.field] as unknown as string
    },
    set value(v: string) {
      Reflect.set(p.form, p.field, v);
    },
    validationResult: true as ValidatorResult,
    get validator(): Validator {
      return (value: string) => s.validationResult;
    },
    get validators() {
      return [
        s.validator,
        ...p.validators || [],
      ];
    },
    get inputStyle() {
      return { paddingLeft: query.width + 14 }
    }
  }))

  const validatePhoneViaTwilio = debounce(flow(function* (value?: string) {
    s.validationResult = true;
    const error = s.validationResult = {
      isValid: false,
      message: `The phone number does not seem to be of a correct format. Please include your international dial code. An example Irish number format would be: +353831234567.`,
    }
    if (p.onValidate) p.onValidate(s.validationResult);
    if (!s.countryCode) return error;
    switch (s.countryCode) {
      case 'IE':
      case 'GB':
        if (s.value.length < 13) return error;
      break;
      default:
        if (s.value.length < 11) return error;
    }
    const url = p.usePublicAPIValidation ? TwilioEndpoints.publicValidatePhoneNumber() : TwilioEndpoints.validatePhoneNumber();
    const payload = {
      phoneNumber: s.value,
      countryCode: s.countryCode,
    };
    const setValidationResult = () => {
      s.validationResult = true;
      if (p.onValidate) p.onValidate(s.validationResult);
    }
    try {
      yield API.postRaw(url, payload);
      setValidationResult();
    } catch(e) {
      // fallback in case public API twilio validation fails: check if not expected validation errors.
      if (axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        const stringifiedResponse = JSON.stringify(axiosError.response);
        if (!stringifiedResponse?.includes('number provided is invalid')) {
          reportError(`Failed phone validation via API's Twilio: Expected 'invalid' phone message does not exist in response body. Received '${stringifiedResponse}'. As fallback, validation result is set to true.`);
          setValidationResult();
        }
        const axiosErrorMsg = axiosError.response?.data?.errors?.error?.[0];
        if (axiosErrorMsg) console.warn(axiosErrorMsg);
      }
      console.warn('Phone validation via twilio was not successful.');
      console.error(e);
      return error;
    }
  }), 300);

  const detectCountry = action((): Nullable<CountryDialCodeDef> => {
    const countryCode = getCountryCodeFromString(s.value);
    const def = countriesByDialCodes.find(c => c.countries[0]?.cca2 === countryCode && s.value.match(c.regex)) ?? null;
    s.country = def;
    s._dialCode = s.country?.dialCode ?? '';
    return def;
  })

  const handleBlur = action(() => {
    if (!p.doNotUseAPIValidation) validatePhoneViaTwilio();
    if (s.countryCode === 'IE') {
      s.value = s.value.replace(/^\+3530/, '+353');
    }
  })

  const handleDialCodeManualChange = action((dialCode: Nullable<string>) => {
    if (!dialCode) return;
    const def = countriesSortedByDialCodeLength.find(c => c.countries[0]?.dialCode === dialCode);
    if (!def) return;
    s.value = '+' + dialCode + s.number;
    s.country = def;
    s._dialCode = dialCode ?? '';
    if (!p.doNotUseAPIValidation) validatePhoneViaTwilio();
  })

  useOnMount(() => {
    if (!s.countryCode && !p.doNotUseOnMountGetUserDialCode && !isInCypressTestMode) {
      ANALYTICS.getUserDialCode().then(code => {
        if (!code) return;
        if (!s.value) s.value = code;
      })
    }
    return reaction(
      () => s.value,
      () => {
        detectCountry();
      }, {
        fireImmediately: true,
      }
    )
  })

  return <Observer children={() => {
    const { label, form, field, ...rest } = p;
    return <div className="TelInput" data-country={s.countryCode} data-field-name={p.field} data-cy={p.dataCy}>
      { label && <BaseLabel required={p.required}>{label}</BaseLabel> }
      <div className="TelInputInner">
        <CountryDialCodeSelector
          form={s}
          field="_dialCode"
          dataCy={`${p.dataCy}__country-select`}
          onUserSelection={handleDialCodeManualChange}
          showDialCode
          innerRef={ref}
          disabled={p.disabled}
        />
        <BaseInput
          {...rest}
          form={s}
          field="number"
          type="tel"
          onBlur={handleBlur}
          dataCy={`${p.dataCy}__tel-input`}
          inputStyle={s.inputStyle}
          hasError={s.validationResult !== true}
          onKeyUp={!p.doNotUseAPIValidation ? () => validatePhoneViaTwilio() : undefined}
          // formatter={(v) => v?.replace(/[\D]/g, "")}
        />
      </div>
    </div>
  }} />
}

export default TelInput;