
import { action, flow, observable, reaction } from "mobx";
import moment from "moment";
import { CounsellingSessionEndpoints, DefaultCounsellingSessionIncludesForStaff } from "../base/endpoints/counsellingSession.endpoints";
import { DefaultSupportGroupIncludesForStaff, SupportGroupEndpoints } from "../base/endpoints/supportGroup.endpoints";
import { UserEndpoints } from "../base/endpoints/user.endpoints";
import { equalById } from "../base/utils/equality.utils";
import { reportError } from "../base/utils/errors.utils";
import { convertRecordMapToArray } from "../base/utils/map.utils";
import { someValuesTruthy } from "../base/utils/object.utils";
import { createUTCMoment, minutes, YYYYMMDD } from "../base/utils/time.utils";
import { doEvery } from "../base/utils/waiters.utils";
import { ModelName } from "../constants/modelNames.enum";
import { CounsellingSession } from "../models/makeCounsellingSession.model";
import { Fee } from "../models/makeFee.model";
import { Invoice } from "../models/makeInvoice.model";
import { SupportGroup } from "../models/makeSupportGroup.model";
import { User, UserSnapshot } from "../models/makeUser.model";
import { APIController, APIGetManyResponse } from "./api.controller";
import { AuthController } from "./auth.controller";
import { PermissionLookupTree } from "./auth/makePermissionLookupTree";
import { CLOCK } from "./common/clock.controller";
import { LocalDBController } from "./localDB.controller";
import { makeControllerBase, makeRootControllerChildInitFn } from "./_root.controller";

export const makeStaffController = () => {

  const disposers: Function[] = [];

  const c = observable({

    ...makeControllerBase('STAFF'),

    firstDataLoaded: false,

    reset: action(() => {
      c.firstDataLoaded = false;
      c.staffList.splice(0);
      c.fees.splice(0);
      disposers.forEach(d => d());
    }),

    get API(): APIController | undefined { return c.ROOT?.children.API },
    get AUTH(): AuthController | undefined { return c.ROOT?.children.AUTH },
    get can(): PermissionLookupTree | undefined  { return c.AUTH?.can },
    get LOCALDB(): LocalDBController | undefined { return c.ROOT?.children.LOCALDB },

    get currentUserIsStaff(): boolean { return !!c.AUTH?.isStaff },
    get currentUserIsAdmin(): boolean { return !!c.AUTH?.isAdmin },
    get currentUserIsCounsellor(): boolean { return !!c.AUTH?.isCounsellor },
    get currentUserIsModerator(): boolean { return !!c.AUTH?.isModerator },
    get currentUserIsFacilitator(): boolean { return !!c.AUTH?.isFacilitator },
    get currentUserIsCoordinator(): boolean { return someValuesTruthy(c.can?.coordinate_ || {}) },
    get currentUserIsAnalyst(): boolean { return !!c.AUTH?.isAnalyst },
    get currentUserIsFinancialAdministrator(): boolean { return !!c.AUTH?.isFinancialAdministrator },
    get currentUserIsCounsellorOnly(): boolean { return c.currentUserIsCounsellor && !c.currentUserIsAdmin },

    get canViewOtherUserDetails(): boolean { return !!c.AUTH?.canViewOtherUserDetails },

    get canFacilitate(): boolean { return !!c.AUTH?.canFacilitate },
    get canModerate(): boolean { return !!c.AUTH?.canModerate },

    get canCoordAvailabilities(): boolean {  return !!c.can?.coordinate_.counsellingAvailabilities },
    get canCoordApplications(): boolean { return !!c.can?.coordinate_.counsellingApplications },
    get canCoordSessions(): boolean { return !!c.can?.coordinate_.counsellingSessions },
    get canCoordGroups(): boolean { return !!c.can?.coordinate_.supportGroups },


    get canManageAvailabilities(): boolean {
      return Boolean(c.AUTH?.isCounsellor || c.can?.manage_.counsellingAvailabilities || c.canCoordAvailabilities);
    },
    get canManageApplications(): boolean {
      return Boolean(c.AUTH?.isCounsellor || c.can?.manage_.counsellingApplications || c.canCoordApplications);
    },
    get canExportApplications(): boolean { return Boolean(c.can?.manage_.exportCounsellingApplications); },
    get canManageSessions(): boolean {
      return Boolean(c.AUTH?.isCounsellor || c.can?.manage_.counsellingSessions || c.canCoordSessions);
    },
    get canManageSupportGroups(): boolean {
      return Boolean(c.can?.supportGroups_.facilitate_.someUserGroups || c.can?.supportGroups_.manage_.groups)
    },
    get canManageSupportGroupsOrTopics(): boolean {
      return Boolean(c.canManageSupportGroups || c.can?.supportGroups_.manage_.topics)
    },
    get canManageClients(): boolean {
      return Boolean(c.can?.manage_.clients_.partialAdminManageClient) || c.currentUserIsCounsellorOnly;
      // return Boolean(c.can?.manage_.clients_.partial || c.currentUserIsCounsellor || c.currentUserIsModerator || c.currentUserIsFacilitator);
    },
    get canViewManageClientsTab(): boolean {
      return c.canManageAllUsers || c.currentUserIsCounsellorOnly;
    },
    get canManageAllUsers(): boolean {
      return Boolean(c.can?.manage_.clients_.all);
    },
    get canManageCompanies(): boolean {
      return Boolean(c.can?.manage_.partnerships.companies);
    },
    get canManageStaff(): boolean {
      return Boolean(c.can?.manage_.staff);
      // return Boolean(c.can?.manage_.staff || c.can?.manage_.clients_.all);
    },
    get canManageFeedbackOrSurveys(): boolean {
      return Boolean(c.can?.manage_.contactForms || c.can?.manage_.feedback || c.can?.manage_.satisfactionSurveys || c.can?.manage_.generalSurveys || c.can?.manage_.gad7Surveys || c.can?.manage_.phq9Surveys || c.can?.manage_.supportGroupNonAttendanceSurveys || c.can?.manage_.supportGroupSatisfactionSurveys);
    },
    get canViewCalendarModule(): boolean {
      return Boolean(c.currentUserIsCounsellor || c.currentUserIsFacilitator || c.currentUserIsCoordinator);
    },
    get canViewManageModule(): boolean {
      // return Boolean(c.currentUserIsCounsellor || c.currentUserIsFacilitator || c.currentUserIsModerator || c.currentUserIsCoordinator || c.canManageClients || c.canManageStaff || c.can?.manage_.donations || c.canManageFeedbackOrSurveys || c.currentUserIsFinancialAdministrator);
      return Boolean(c.currentUserIsCounsellor || c.currentUserIsFacilitator || c.currentUserIsModerator || c.currentUserIsCoordinator || c.canManageClients || c.canManageStaff || c.can?.manage_.donations || c.canManageFeedbackOrSurveys || c.currentUserIsAnalyst || c.currentUserIsFinancialAdministrator);
    },
    get canUseStatModule(): boolean {
      return Boolean(c.can?.accessStats_.someSections);
    },
    get canViewInvoices(): boolean {
      return c.can?.manage_.donations || c.can?.manage_.staffInvoicing || c.currentUserIsCounsellor || c.currentUserIsFacilitator;
    },
    get allLoadedSessions(): CounsellingSession[] {
      return Array.from(c.LOCALDB?.data.counsellingSessions.values() || []);
    },
    get allCurrentOrFutureSessions(): CounsellingSession[] {
      return c.allLoadedSessions.filter(ses => (
        !ses.application?.isArchived &&
        !ses.application?.isCompleted &&
        !ses.timeStarted &&
        !ses.isCancelled &&
        createUTCMoment(ses.timeScheduled).add(1, 'hour').isAfter()
      ));
    },
    get allOwnCurrentOrFutureSessions(): CounsellingSession[] {
      return c.allCurrentOrFutureSessions.filter(ses => c.AUTH?.currentUser?.id && (ses.counsellorId === c.AUTH?.currentUser.id));
    },

    get allLoadedGroups(): SupportGroup[] {
      return Array.from(c.LOCALDB?.data.supportGroups.values() || []);
    },
    get allCurrentOrFutureGroups(): SupportGroup[] {
      return c.allLoadedGroups.filter(g => !g.timeStarted && createUTCMoment(g.timeScheduled).add(1, 'hour').isAfter());
    },
    get allOwnCurrentOrFutureGroups(): SupportGroup[] {
      return c.allCurrentOrFutureGroups.filter(g => c.AUTH?.currentUser?.id && (g.facilitatorId === c.AUTH?.currentUser.id));
    },
    get allCurrentOrFutureGroupsThatIsNotFull(): SupportGroup[] {
      return c.allCurrentOrFutureGroups.filter(g => g.reservationCount < g.maxParticipants);
    },

    isLoading: false,
    getAllTodayAndFutureSessionsAndGroups: async () => await flow(function * () {
      // console.log('getAllTodayAndFutureSessionsAndGroups');
      c.isLoading = true;
      try {
        // if (c.canManageApplications) yield getAllOpenApplications();
        // TODO: REMOVE THIS, IF NOT NEEDED
        if (c.canManageSessions) yield getAllTodayAndFutureSessions();
        if (c.canManageSupportGroups) yield getAllTodayAndFutureGroups();
        c.isLoading = false;
        c.firstDataLoaded = true;
      } catch(e) {
        reportError(e);
        c.isLoading = false;
      }
    })(),

    staffList: [] as User[],

    get admins(): User[] {
      return c.staffList.filter(u => u.isAdmin);
    },
    get counsellors(): User[] {
      return c.staffList.filter(u => u.isCounsellor);
    },
    get accrCounsellors(): User[] {
      return c.staffList.filter(u => u.isAccreditedCounsellor);
    },
    get preAccrCounsellors(): User[] {
      return c.staffList.filter(u => u.isPreAccreditedCounsellor);
    },
    get placementCounsellors(): User[] {
      return c.staffList.filter(u => u.isPlacementCounsellor || (u.isCounsellor && !u.isAccreditedCounsellor && !u.isPreAccreditedCounsellor));
    },
    get moderators(): User[] {
      return c.staffList.filter(u => u.isModerator);
    },
    get facilitators(): User[] {
      return c.staffList.filter(u => u.isFacilitator);
    },
    get coordinators(): User[] {
      return c.staffList.filter(u => u.isCoordinator);
    },
    get analysts(): User[] {
      return c.staffList.filter(u => u.isAnalyst);
    },
    get financialAdministrators(): User[] {
      return c.staffList.filter(u => u.isFinancialAdministrator);
    },
    userIsStaff: (user?: UserSnapshot | null): boolean => {
      if (!user) return false;
      return !!c.staffList.find(u => equalById(u, user));
    },
    userIsCounsellor: (user?: UserSnapshot | null): boolean => {
      if (!user) return false;
      return !!c.staffList.find(u => equalById(u, user));
    },

    get loadedInvoices(): Invoice[] {
      return c.LOCALDB ? convertRecordMapToArray(c.LOCALDB.data.invoices) : [];
    },
    get ownInvoices(): Invoice[] {
      return c.AUTH?.currentUser ? c.loadedInvoices.filter(i => i.payeeId === c.AUTH?.currentUser?.id) : [];
    },
    get ownCurrentInvoices(): Invoice[] {
      return c.ownInvoices.filter(i => moment(i.billingPeriodStartDate).isSame(CLOCK.localNow, 'month'));
    },

    fees: [] as Fee[],

    getAllStaffUsers: async () => await flow(function* () {
      const url = UserEndpoints.staff.index({ filter: { hasAnyPermission: true }, append: '_wasCounsellor', perPage: Infinity, include: ['permissions', 'roles'] })
      const staffList = (yield c.API?.getMany<User>(url, ModelName.users)) as APIGetManyResponse<User>;
      // const staffList = (yield c.API?.getMany<User>(url, ModelName.users, { replaceExisting: true })) as APIGetManyResponse<User>;
      c.staffList = staffList.entries;
    })(),

  });

  // const getAllOpenApplications = flow(function * () {

  //   if (!c.AUTH?.currentUser) return;

  //   const url = CounsellingApplicationEndpoints.staff.index({
  //     perPage: Infinity,
  //     filter: {
  //       counsellorId: c.canCoordApplications ? undefined : c.AUTH?.currentUser.id,
  //       whereNull: ['timeCompleted', 'timeArchived', 'timeCancelled'],
  //     },
  //     include: DefaultCounsellingApplicationIncludes,
  //   });

  //   (yield c.API?.getMany(url, ModelName.counsellingApplications)) as APIGetManyResponse<CounsellingApplication>;

  // })

  const getAllTodayAndFutureSessions = flow(function * () {
    if (!c.AUTH?.currentUser) return;

    // if browser URL contains manageApplicationId then return;
    if (window.location.href.includes('manageApplicationId')) {
      console.log('getAllTodayAndFutureSessions: window.location.href.includes(manageApplicationId)');
      return;
    }

    const url = CounsellingSessionEndpoints.staff.index({
      perPage: Infinity,
      filter: {
        counsellorId: c.canCoordSessions ? undefined : c.AUTH?.currentUser.id,
        dateRange: {
          key: "timeScheduled",
          startDate: moment.utc().startOf("day").format(YYYYMMDD),
          endDate: moment.utc().add(1, "month").endOf("month").format(YYYYMMDD),
        },
      },
      include: DefaultCounsellingSessionIncludesForStaff,
    });

    (yield c.API?.getMany(
      url,
      ModelName.counsellingSessions
    )) as APIGetManyResponse<CounsellingSession>;
  })

  const getAllTodayAndFutureGroups = flow(function * () {

    if (!c.AUTH?.currentUser) return;

    const url = SupportGroupEndpoints.staff.index({
      perPage: Infinity,
      filter: {
        facilitatorId: c.canCoordGroups ? undefined : c.AUTH?.currentUser.id,
        dateRange: {
          key: 'timeScheduled',
          startDate: moment.utc().startOf('day').format(YYYYMMDD),
          endDate: moment.utc().add(1, 'month').endOf('month').format(YYYYMMDD),
        },
      },
      include: DefaultSupportGroupIncludesForStaff
    });

    (yield c.API?.getMany(url, ModelName.supportGroups)) as APIGetManyResponse<SupportGroup>;

  })

  c.init = makeRootControllerChildInitFn(
    c,
    async () => {
      reaction(
        () => c.currentUserIsStaff,
        () => {
          if (!c.currentUserIsStaff) return;
          disposers.push(
            doEvery(
              c.getAllTodayAndFutureSessionsAndGroups,
              minutes(3),
              { fireImmediately: true }
            )
          );
        },
        { fireImmediately: true }
      )
    }
  )

  return c

}

export type StaffController = ReturnType<typeof makeStaffController>;
