import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Nullable } from '../../../../../../base/@types';
import ClickToCopy from '../../../../../../base/components/ClickToCopy/ClickToCopy';
import LoadingIndicator from '../../../../../../base/components/LoadingIndicator/LoadingIndicator';
import { Primary } from '../../../../../../base/constants/color.enum';
import { useControllers, useUIController } from '../../../../../../base/hooks/useRootController.hook';
import joinClassName from '../../../../../../base/utils/className.utils';
import { getContextColorStyle } from '../../../../../../base/utils/colors.utils';
import { useProps, useStore } from '../../../../../../base/utils/mobx.utils';
import { ApplicationTypeOption, CounsellingType, getCounsellingTypeColorHex } from '../../../../../../constants/counsellingTypes.descriptors';
import { getInvitationLink, isOfCounsellingTypeYoungPeople, isPaidCounselling } from '../../../../../../utils/counsellingApplication.utils';
import './CounsellingApplicationSubmissionScreen.scss';

interface CounsellingApplicationSubmissionScreenProps {
  applicationId?: Nullable<string>,
  submitted?: boolean,
  isClosing?: boolean,
  type?: ApplicationTypeOption,
}

const CounsellingApplicationSubmissionScreen: React.FC<CounsellingApplicationSubmissionScreenProps> = props => {

  const { COUNSELLING } = useControllers();
  const { PORTAL } = useUIController();
  const p = useProps(props);
  const s = useStore(() => ({
    get application() {
      return COUNSELLING.applications.find(a => a.id === p.applicationId);
    },
    get invitation() {
      return s.application?.invitation;
    },
    get invitationUuid() {
      return s.invitation?.uuid;
    },
    get email() {
      return s.application?.applicant?.email
    },
    get invitationLink() {
      return s.invitation ? getInvitationLink(s.invitationUuid, s.application?.type, s.email) : null;
    },
    get inviteeName() {
      return s.application?.type === CounsellingType.Couples ? 'partner' : isOfCounsellingTypeYoungPeople(s.application?.type) ? 'child' : 'invitee';
    },
    get isPaid() {
      return (!!p.type && isPaidCounselling(p.type)) || (!!s.application && isPaidCounselling(s.application.type));
    },
    get title() {
      switch (true) {
        case s.isPaid:
          return "Counselling Appointment Booked!";
        default:
          return "Counselling Application Submitted!";
      }
    },
    get subtitle() {
      switch (true) {
        case s.isPaid:
          return "Your Online Counselling Booking has been successful! Prepare for the session by filling up your Goal Sheet and clinical surveys.";
        default:
          return "Your Online Counselling application has been submitted! Our dedicated counsellors will review your submission as soon as possible.";
      }
    },
  }))

  const style = getContextColorStyle(Primary, getCounsellingTypeColorHex(s.application?.type ?? p.type));

  return <Observer children={() => (
    PORTAL.render(<div
      className={joinClassName("CounsellingApplicationSubmissionScreen", p.isClosing && 'closing')}
      style={style}
    >
      {
        p.submitted ? <div className="CounsellingApplicationSubmissionScreenSuccessMessage">
          <svg width="77" height="77" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="38.5" cy="38.5" r="37.5" stroke='currentColor' strokeWidth="2" />
            <path d="M18 38.1786L31.8819 52L59 25" stroke='currentColor' strokeWidth="2" />
          </svg>
          <h1>{s.title}</h1>
          <p>{s.subtitle}</p>
          {s.invitationLink && <div className="CounsellingApplicationSubmissionScreenSuccessMessageInvitationInfo">
            <p>Please forward the link below to your {s.inviteeName} at the earliest time possible, as we can only approve your application when they accept.</p>
            <ClickToCopy text={s.invitationLink} buttonLabel="Copy Link" successMessageComposer={() => "Link copied!"}><code>{s.invitationLink}</code></ClickToCopy>
          </div>}
          {props.children}
        </div> : <div className="CounsellingApplicationSubmissionScreenLoadingMessage">
          <LoadingIndicator />
            <p>Submitting your {s.isPaid ? 'booking' : 'application'}...</p>
        </div>
      }
    </div>)
  )} />
}

export default CounsellingApplicationSubmissionScreen;