import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Primary } from '../../constants/color.enum';
import { getContextColorStyle } from '../../utils/colors.utils';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import './LoadingIndicatorSection.scss';

interface LoadingIndicatorSectionProps {
  colored?: boolean,
  color?: string,
  dataCy?: string,
}

const LoadingIndicatorSection: React.FC<LoadingIndicatorSectionProps> = p => {
  return <Observer children={() => (
    <div
      className="LoadingIndicatorSection"
      data-cy={p.dataCy}
      data-colored={p.colored ?? true}
      style={getContextColorStyle(Primary, p.color)}
    >
      <LoadingIndicator />
    </div>
  )} />
}

export default LoadingIndicatorSection;