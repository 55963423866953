import { action, observable, toJS } from "mobx";
import { ModelName } from "../../constants/modelNames.enum";
import { CLOCK } from "../../controllers/common/clock.controller";
import { LocalDBController } from "../../controllers/localDB.controller";
import { SHOULD_LOG } from "../../env";
import { AnyObject, RelationshipsSchema, SnapshotOf, StandardModel, StandardModelFactory, StandardModelPatchOptions } from "../@types";
import { copyWithJSON, mergeIntoObjectByDescriptors, recursiveMergeWithTypeCast, TypeCastSchema } from "../utils/object.utils";
import { enforceStringIdOnSnapshot } from "../utils/snapshot.utils";
import { getTrustToken } from "../utils/trust.utils";
import { isFunction } from "../utils/typeChecks.utils";
import { setupAccessorsToObservableSnapshot } from "./accessors.factoryUtils";
import { autoMaintainRelationships, setupRelationshipGetters, syncModelWithRelatedModels } from "./relationships.factoryUtils";
import { setSnapshotRelationshipsInLocalDB } from "./snapshot.factoryUtils";

export const createStandardModelFactory = <
  M extends StandardModel, 
  RelationshipsType extends AnyObject = {},
  ExtendedProperties extends AnyObject = {},
  PrivateDataType extends AnyObject = AnyObject,
>(o: {
  name: ModelName,
  snapshotFactory: () => SnapshotOf<M>,
  snapshotGenerator?: (m: M, $: SnapshotOf<M>) => SnapshotOf<M>,
  snapshotTypeCastSchema?: TypeCastSchema<SnapshotOf<M>>,
  privateDataFactory?: (snapshot: Partial<SnapshotOf<M>>, prev?: PrivateDataType) => PrivateDataType,
  accessorOverridesFactory?: ($: SnapshotOf<M>, m?: M, localDB?: LocalDBController, privateDataGetter?: () => PrivateDataType) => Partial<SnapshotOf<M>>,
  relationshipsSchema?: RelationshipsSchema<SnapshotOf<M>, RelationshipsType>,
  relationshipsSchemaFactory?: (snapshot?: Partial<SnapshotOf<M>>) => RelationshipsSchema<SnapshotOf<M>, RelationshipsType>,
  extendedPropertiesFactory?: (m: M, $: SnapshotOf<M>, localDB?: LocalDBController, privateDataGetter?: () => PrivateDataType) => ExtendedProperties,
  init?: (m: M, $: SnapshotOf<M>, localDB?: LocalDBController) => M,
  options?: { debug?: boolean },
}): StandardModelFactory<M> => {

  return (
    source?: Partial<SnapshotOf<M>> | M,
    localDB?: LocalDBController,
  ) => {

    const originalSource = copyWithJSON(source);

    if (o.options?.debug) {
      SHOULD_LOG() && console.log(source, localDB);
    }

    // create empty snapshot template
    const base = o.snapshotFactory();

    const snapshot: Partial<SnapshotOf<M>> = source ? isStandardModel(source) ? source.$getSnapshot() as Partial<SnapshotOf<M>> : source : {};
    const { id } = snapshot;

    const privateData = observable({
      value: o.privateDataFactory?.(snapshot) ?? {} as PrivateDataType
    })

    const relationshipsSchema = o.relationshipsSchemaFactory ? o.relationshipsSchemaFactory(snapshot as Partial<SnapshotOf<M>>) : o.relationshipsSchema;

    // if snapshot came with relationship data, move them to local DB and create ID references on the snapshot if missing
    const setupRelationships = (snapshot: Partial<SnapshotOf<M>>) => setSnapshotRelationshipsInLocalDB(
      snapshot,
      relationshipsSchema,
      localDB,
      o.options
    );
    if (relationshipsSchema && id) {
      setupRelationships(snapshot as Partial<SnapshotOf<M>>);
    }

    // create private observable snapshot as the 'state' of the model
    // this makes it easy to patch the snapshot when it is updated
    const $ = observable(recursiveMergeWithTypeCast(base, snapshot, o.snapshotTypeCastSchema));

    // creates the model object to be returned with standard model methods
    const model = {
      get $modelName() { return o.name },
      $getSnapshot: () => toJS(o.snapshotGenerator ? o.snapshotGenerator(model, $) : $),
      get $snapshot() { return model.$getSnapshot() },
      $patch: action((source: Partial<SnapshotOf<M>>, options?: StandardModelPatchOptions) => {
        if (o.options?.debug) {
          SHOULD_LOG() && console.log(model, source);
        };
        const snapshot = enforceStringIdOnSnapshot(toJS(isStandardModel(source) ? source.$getSnapshot() : source));
        if (options?.trustToken === getTrustToken()) {
          privateData.value = o.privateDataFactory?.(snapshot as Partial<SnapshotOf<M>>, privateData.value) ?? {} as PrivateDataType;
        }
        setupRelationships(snapshot as Partial<SnapshotOf<M>>);
        recursiveMergeWithTypeCast($, snapshot as any);
        model.$lastSource = copyWithJSON(snapshot);
        model.$timeLastPatched = CLOCK.nowUtcTimestamp;
      }),
      $isStandardModel: true,
      $lastSource: originalSource,
      $timeLastPatched: CLOCK.nowUtcTimestamp,
      __syncWithRelatedModels: () => syncModelWithRelatedModels(model, relationshipsSchema, localDB),
    } as unknown as M;

    // apply standard getters and setters of all attributes in the snapshot directly on the model
    setupAccessorsToObservableSnapshot(model, $, base, o.accessorOverridesFactory ? o.accessorOverridesFactory($, model, localDB, () => privateData.value) : undefined);

    // create auto getters of related models from localDB
    setupRelationshipGetters($, model, relationshipsSchema, localDB, o.options);

    // apply extended members (e.g. getters, methods, special attributes)
    if (o.extendedPropertiesFactory) {
      mergeIntoObjectByDescriptors(model, o.extendedPropertiesFactory(model, $, localDB, () => privateData.value));
    }
    
    // make the model observable
    const observableModel = observable(model);

    autoMaintainRelationships(observableModel, relationshipsSchema, localDB, o.options);

    // run the init function (anything to do before returning the model to the caller, such as setting up event listeners, reactions, applying repairers etc.)
    o.init && o.init(observableModel, $, localDB);

    if (o.options?.debug) {
      SHOULD_LOG() && console.log(observableModel);
    }

    return observableModel;

  }
}

export function isStandardModel<Model extends StandardModel>(
  model: Model | any
): model is Model {
  return model?.$isStandardModel === true && '$getSnapshot' in model && isFunction(model.$getSnapshot);
}
