import React from 'react';
import { AnyObject, Nullable } from '../base/@types';
import ErrorRenderer from '../base/components/ErrorRenderer/ErrorRenderer';
import { makeSurveyEndpointFactory, Survey, SurveyModelName, SurveySubtype } from '../base/endpoints/survey.endpoints';
import { capitalizeFirstLetter } from '../base/utils/string.utils';
import { getNowTimestampUtc } from '../base/utils/time.utils';
import { APIController } from '../controllers/api.controller';
import { UIController } from '../controllers/ui.controller';
import { Assignment } from "../models/makeAssignment.model";
import { SurveyGAD7Snapshot } from '../models/makeSurveyGAD7.model';
import { SurveyGoalSheetSnapshot } from '../models/makeSurveyGoalSheet.model';
import { SurveyPHQ9Snapshot } from '../models/makeSurveyPHQ9.model';

export function makeSubmitSurveyFn<T extends Survey = Survey>(options: {
  API: APIController, 
  UI: UIController, 
  surveySubType: SurveySubtype,
  surveyModelName: SurveyModelName,
  survey: T,
  surveyDisplayName: 'survey' | 'questionnaire' | 'goal sheet',
  assignment?: Assignment,
  isOwn?: boolean,
  onComplete?: (survey: T) => unknown,
}) {
  const {
    API, UI, surveySubType, surveyModelName, survey, 
    surveyDisplayName = 'survey',
    assignment, onComplete
  } = options;
  return () => new Promise<boolean>(async (resolve, reject) => {
    try {
      const { AUTH } = API.ROOT!.children;
      const isOwn = options.isOwn || assignment?.assignedToUserId === AUTH.currentUser?.id;
      const url = makeSurveyEndpointFactory(surveySubType)[isOwn ? 'own' : 'staff'].create();
      const payload = (survey as T).$getSnapshot() as SurveyGoalSheetSnapshot | SurveyGAD7Snapshot | SurveyPHQ9Snapshot;
      payload.assignmentId = assignment?.id;
      payload.assignedToUserId = assignment?.assignedToUserId;
      if (!payload.id) Reflect.deleteProperty(payload, 'id');
      payload.completedByUserId = AUTH.currentUser?.id;
      payload.timeCreated = getNowTimestampUtc();
      payload.timeUpdated = getNowTimestampUtc();
      const submittedSurvey: Nullable<T> = await API.post(url, surveyModelName, payload);
      if (!submittedSurvey) {
        throw Error("Failed to submit survey");
      }
      switch (surveySubType) {
        case 'gad7': {
          UI.OVERLAY.dismiss('OverlaySurveyGAD7');
          break;
        }
        case 'phq9': {
          UI.OVERLAY.dismiss('OverlaySurveyPHQ9');
          break;
        }
        case 'goal-sheet': {
          UI.OVERLAY.dismiss('OverlaySurveyGoalSheet');
          break;
        }
      }
      UI.DIALOG.positive({
        heading: `${capitalizeFirstLetter(surveyDisplayName)} submitted!`,
        body: isOwn ? 'Thanks for your cooperation. This helps us better prepare the counselling sessions.' : undefined,
        actions: [{
          name: 'positive',
          label: 'OK',
          action: () => {
            if (assignment) onComplete?.(submittedSurvey);
          }
        }],
      })
      resolve(true);
    } catch (e) {
      reject(e);
      UI.DIALOG.error({
        heading: `Failed to submit ${surveyDisplayName}`,
        body: () => <>
          <p>We are sorry but the {surveyDisplayName} failed to submit. Please try submitting again. If this keeps happening, please let us know and we will help you out.</p>
          <ErrorRenderer error={ (e as AnyObject).response } />
        </>,
        defaultActions: ['positive'],
      })
    }
  });
}