import { flow, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Nillable } from '../../../../base/@types';
import AppPage from '../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../base/components/AppPageHeader/AppPageHeader';
import BaseButton from '../../../../base/components/BaseButton/BaseButton';
import BaseHeader from '../../../../base/components/BaseHeader/BaseHeader';
import BaseHeading from '../../../../base/components/BaseHeading/BaseHeading';
import BaseSpacer from '../../../../base/components/BaseSpacer/BaseSpacer';
import ColorTagHidden from '../../../../base/components/ColorTag/ColorTagHidden';
import ColorTagSafe from '../../../../base/components/ColorTag/ColorTagSafe';
import ColorTagUnsafe from '../../../../base/components/ColorTag/ColorTagUnsafe';
import CommandList from '../../../../base/components/CommandList/CommandList';
import { CommandListItem } from '../../../../base/components/CommandList/CommandListItem';
import DateRenderer from '../../../../base/components/DateRenderer/DateRenderer';
import LoadingBlocker from '../../../../base/components/LoadingBlocker/LoadingBlocker';
import OverlayCloseButton from '../../../../base/components/OverlayCloseButton/OverlayCloseButton';
import UIBlock from '../../../../base/components/UIBlock/UIBlock';
import { DefaultThoughtIncludesForAdmins, ThoughtEndpoints } from '../../../../base/endpoints/thought.endpoints';
import { useOnMount } from '../../../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import joinClassName from '../../../../base/utils/className.utils';
import { reportError } from '../../../../base/utils/errors.utils';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import { setUrlParam, useSyncUrlParams } from '../../../../base/utils/urlParams.utils';
import ThoughtTypeRenderer from '../../../../components/ThoughtTypeRenderer/ThoughtTypeRenderer';
import UserInfoTable from '../../../../components/UserInfoTable/UserInfoTable';
import { ApiModelName } from '../../../../constants/ApiModels.enum';
import { ModelName } from '../../../../constants/modelNames.enum';
import { Thought } from '../../../../models/makeThought.model';
import FlaggableItemManager from '../FlaggableItemManager/FlaggableItemManager';
import ThoughtEntryForAdmin from '../ThoughtEntryForAdmin/ThoughtEntryForAdmin';
import './OverlayAdminSingleThoughtManager.scss';

interface OverlayAdminSingleThoughtManagerProps {
  thought?: Nillable<Thought>;
  thoughtId?: Nillable<string>;
}

const OverlayAdminSingleThoughtManager: React.FC<OverlayAdminSingleThoughtManagerProps> = props => {

  const { UI, AUTH, NAVIGATOR, API, LOCALDB } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    isLoading: false,
    get thought(): Nillable<Thought> {
      return p.thought ?? (s.id ? LOCALDB.get<Thought>(ModelName.thoughts, s.id) : null);
    },
    get id() {
      return p.thoughtId ?? s.thought?.id;
    },
    get title() {
      return `${s.thought?.isPrivate ? 'Private Thought' : 'Thought Entry'}${s.thought?.timeDeleted ? " (deleted)" : ''}`
    },
    viewUser: () => setUrlParam('userId', s.thought!.userId, NAVIGATOR),
  }));

  useSyncUrlParams('manageThoughtId', s.id);

  useOnMount(() => {
    loadData();
  })

  const loadData = () => when(
    () => Boolean(s.id),
    flow(function* () {
      const canUseAdminEndpoint = AUTH.can.thoughtCatcher_.moderate_;
      const params = {
        include: DefaultThoughtIncludesForAdmins,
      }
      try {
        const url = ThoughtEndpoints[canUseAdminEndpoint ? 'staff' : 'own'].get(s.id!, params);
        s.isLoading = true;
        yield API.get(url, ModelName.thoughts);
        s.isLoading = false;
      } catch (e) {
        s.isLoading = false;
        reportError(e);
        UI.DIALOG.error({
          heading: 'Error getting thought data',
          error: e,
        })
      }
    })
  )

  const editThought = () => {
    if (!s.thought) return;
    setUrlParam('editThoughtId', s.thought.id, NAVIGATOR);
  }

  return <Observer children={() => (
    <AppPage className={
      joinClassName(
        'OverlayAdminSingleThoughtManager',
        s.thought?.isPrivate && 'private',
        s.thought?.timeHidden && 'hidden',
        s.thought?.timeDeleted && 'deleted',
        s.thought?.isSafe && 'safe',
        s.thought?.isUnsafe && 'unsafe',
      )
    }>
      <AppPageHeader
        title={s.title}
        afterTitle={<>
          {s.thought?.timeHidden && <span><ColorTagHidden /> </span>}
          {s.thought?.isSafe && <span><ColorTagSafe /> </span>}
          {s.thought?.isUnsafe && <span><ColorTagUnsafe /> </span>}
          <ThoughtTypeRenderer private={s.thought?.isPrivate} /> Thought ID. <code>{s.id}</code>
          {s.thought?.timeDeleted && <span className="OverlayAdminSingleThoughtManagerDeletedTag"> • Deleted on <DateRenderer value={s.thought.timeDeleted} /> </span>}
        </>}
        endSlot={<OverlayCloseButton />}
      />
      <AppPageContent padSections>
        <UIBlock spaceChildren>
          {s.thought && <div className="OverlayAdminSingleThoughtManagerContent">
            <div className="OverlayAdminSingleThoughtManagerContentMain">
              {UI.fromTablet && <BaseHeading>Thought Content</BaseHeading>}
              <FlaggableItemManager flaggable={s.thought!} modelType={ApiModelName.THOUGHT} />
              <BaseSpacer size=".5em" />
              {s.thought && <ThoughtEntryForAdmin
                thought={s.thought}
              />}
            </div>
            <aside className="OverlayAdminSingleThoughtManagerContentAside">
              {UI.onlyPhones && <BaseSpacer />}
              <BaseHeader
                heading="User Information"
                endSlot={<BaseButton size="xs" onClick={s.viewUser} className="subtle" target="_blank">Details</BaseButton>}
              />
              <UserInfoTable user={s.thought!.user} columns={1} />
              {s.thought && !s.thought.timeDeleted && <>
                <BaseSpacer />
                <BaseHeader heading="More Options" />
                <CommandList withBackground>
                  <CommandListItem dataCy="edit-thought" icon="pencil" onClick={editThought}>Edit thought</CommandListItem>
                  <CommandListItem icon="refresh" onClick={loadData}>Reload thought data</CommandListItem>
                </CommandList>
              </> }
            </aside>
          </div>}
          {s.isLoading && <LoadingBlocker />}
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
}

export default OverlayAdminSingleThoughtManager;