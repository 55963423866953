import { Nillable } from "../@types";
import { HasId } from "../@types/traits.types";

export const equalByJsonSnapshot = (a: any, b: any) => {
  return JSON.stringify(a) === JSON.stringify(b);
}
export const equalByString = (a: any, b: any) => a + '' === b + '';

export const equalById = (a: Nillable<HasId>, b: Nillable<HasId>) => {
  if (!a && !b) return true;
  if (!a || !b) return false;
  return equalByString(a.id, b.id);
}

export const strictEqual = (a: any, b: any) => a === b;
