
export enum ColorName {

  White = 'white',
  Black = 'black',

  Light = 'light',
  Shade = 'shade',

  LightGray = 'lightGray',
  Gray = 'gray',
  DarkGray = 'darkGray',

  Red = 'red',
  Firebrick = 'firebrick',
  Orange = 'orange',
  Brown = 'brown',
  Yellow = 'yellow',
  BlueGreen = 'blueGreen',
  YellowGreen = 'yellowGreen',
  NeonGreen = 'neonGreen',
  Green = 'green',
  Forest = 'forest',
  Cyan = 'cyan',
  Teal = 'teal',
  Emerald = 'emerald',
  SkyBlue = 'skyblue',
  Blue = 'blue',
  Navy = 'navy',
  Mauve = 'mauve',
  brightPurple = 'brightPurple',
  DeepPurple = 'deepPurple',
  Violet = 'violet',
  Magenta = 'magenta',
  Pink = 'pink',
  Steel = 'steel',

}

export const {
  White, Black,
  Light, Shade,
  LightGray, Gray, DarkGray,
  Red, Firebrick, Orange, Brown, Yellow, YellowGreen,
  NeonGreen, Green, Forest, Cyan, Teal, Emerald,
  SkyBlue, Blue, Navy, 
  Mauve, brightPurple, DeepPurple,
  Violet, Magenta, Pink,
  Steel
} = ColorName;

export enum ContextColor {
  Foreground = 'foreground',
  Median = 'median',
  Background = 'background',
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Alert = 'alert',
  Attention = 'attention',
  Neutral = 'neutral',
  Positive = 'positive',
}

export const { 
  Foreground, Median, Background, 
  Primary, Secondary, Tertiary,
  Alert, Attention, Neutral, Positive 
} = ContextColor
