import { action, flow, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { Nillable, Nullable, Timeframe } from '../../base/@types';
import InfiniteFeedLoader from '../../base/components/InfiniteFeedLoader/InfiniteFeedLoader';
import { DefaultSupportGroupIncludes, DefaultSupportGroupIncludesForStaff, SupportGroupEndpointFilterSet, SupportGroupEndpointParams, SupportGroupEndpoints } from '../../base/endpoints/supportGroup.endpoints';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { isMultiplesOf } from '../../base/utils/numbers.utils';
import { createUTCMoment } from '../../base/utils/time.utils';
import { ModelName } from '../../constants/modelNames.enum';
import { APIGetManyResponse } from '../../controllers/api.controller';
import { CLOCK } from '../../controllers/common/clock.controller';
import { SHOULD_LOG } from '../../env';
import { SupportGroup, SupportGroupSnapshot } from '../../models/makeSupportGroup.model';
import { User } from '../../models/makeUser.model';
import { AgeGroupFilterType } from '../../utils/ageAndDateOfBirth.utils';
import SupportGroupSummaryCard from '../SupportGroupSummaryCard/SupportGroupSummaryCard';
import './SupportGroupFeed.scss';

type SupportGroupFeedProps = {
  user?: User,
  inserts?: (JSX.Element | { el: JSX.Element, priority?: 'normal' | 'high' })[],
  actionOnItemClick?: 'view' | 'manage',
  filterTimeframe?: Timeframe,
  filter?: SupportGroupEndpointFilterSet,
  invisibleLoader?: boolean,
  emptyNotice?: ReactNode,
}

const SupportGroupFeed: React.FC<SupportGroupFeedProps> = props => {

  const { AUTH, API, SUPPORT_GROUPS } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({

    currentPageOfFeed: 0,
    lastPageOfFeed: null as Nullable<number>,
    errorRetrievingFeed: null as Nullable<Error>,
    timeLastRetrievedFeed: null as Nullable<string>,
    feedRetrieving: false,
    feedRetrieved: false,

    get getOwn() {
      return AUTH.currentUser && (p.user?.id === AUTH.currentUser.id);
    },

    get hasReachedLastPageOfFeed(): boolean {
      return !!(!s.errorRetrievingFeed && s.lastPageOfFeed && (s.currentPageOfFeed >= s.lastPageOfFeed));
    },

    get endpointFactory(): (params?: Nillable<SupportGroupEndpointParams>) => string {
      return SupportGroupEndpoints[s.getOwn ? 'own' : (AUTH.isAuthenticated ? (AUTH.currentUser?.isModerator ? 'staff' : 'client') : 'public')].index;
    },

    get feedAgeGroupFilters(): AgeGroupFilterType[] | undefined {
      if (AUTH.isModerator) return undefined;
      if (s.getOwn) return undefined;
      if (AUTH.currentUser?.isYoungPerson) return [AgeGroupFilterType.youngPeople1214, AgeGroupFilterType.youngPeople1517];
      return [AgeGroupFilterType.adults];
    },

    pollInterval: null as number | null,

    getFeedOfPage: (page: number = 1) => flow(function* () {
      SHOULD_LOG() && console.log('polling support group feed');
      s.errorRetrievingFeed = null;
      s.feedRetrieving = true;
      const params = {
        include: AUTH.isModerator ? DefaultSupportGroupIncludesForStaff : DefaultSupportGroupIncludes,
        sort: 'timeScheduled',
        page,
        perPage: 15,
        filter: {
          userId: s.getOwn ? undefined : (p.user?.id ?? undefined),
          ageGroup: s.feedAgeGroupFilters,
          dateRange: {
            key: 'timeScheduled' as keyof SupportGroupSnapshot,
            ...p.filterTimeframe,
          },
          ...p.filter,
        }
      }
      const url = s.endpointFactory(params);
      yield s.getSupportGroupFeedByUrl(url);
    })(),

    getSupportGroupFeedByUrl: (url: string, options?: { isRefreshRequest?: boolean }) => flow(function* () {
      try {
        const { entries: refreshedThoughts, response } = (yield API.getMany(url, ModelName.supportGroups)) as APIGetManyResponse<SupportGroup>;
        s.feedRetrieved = true;
        s.timeLastRetrievedFeed = CLOCK.nowUtcTimestamp;
        s.lastPageOfFeed = response.data.last_page;
        s.currentPageOfFeed = response.data.current_page;
        s.feedRetrieving = false;
        return refreshedThoughts;
      } catch (e) {
        s.errorRetrievingFeed = e as Error;
        s.pollInterval && clearInterval(s.pollInterval);
        s.feedRetrieving = false;
        throw e;
      }
    })(),

    feedDataGetterGeneratorLastCreated: null as Nullable<string>,

    getFeedDataGetterGenerator: () => {
      return null as Nullable<AsyncGenerator<any>>;
    },

    makeFeedDataGetterGenerator: action(() => {
      // console.log('makeFeedDataGetterGenerator', CLOCK.nowUtcTimestamp);
      s.feedDataGetterGeneratorLastCreated = CLOCK.nowUtcTimestamp;
      if (s.feed.length === 0) {
        s.currentPageOfFeed = s.lastPageOfFeed ?? 0;
      }
      s.lastPageOfFeed = null;
      s.getFeedDataGetterGenerator = () => {
        return (async function* () {
          while (!s.hasReachedLastPageOfFeed) {
            // console.log('s.hasReachedLastPageOfFeed', s.hasReachedLastPageOfFeed);
            yield await s.getFeedOfPage(s.currentPageOfFeed + 1);
          }
        })();
      }
    }),

    get groupsSorted() {
      if (s.getOwn) return SUPPORT_GROUPS.ownGroupsLoadedSorted;
      return SUPPORT_GROUPS.loadedGroupsSorted;
    },

    get viewableGroups(): SupportGroup[] {
      return s.groupsSorted.filter(g => !g.notOpenForRsvp || g.isLive)
    },

    get viewableGroupsFilteredByGivenTimeframe() {
      if (!p.filterTimeframe) return s.viewableGroups;
      return s.viewableGroups.filter(g => {
        if (g.isLive) return true;
        const m = createUTCMoment(g.timeScheduled);
        const floorPass = p.filterTimeframe!.startDate ? m.isAfter(createUTCMoment(p.filterTimeframe!.startDate)) : true;
        const ceilPass = p.filterTimeframe!.endDate ? m.isBefore(createUTCMoment(p.filterTimeframe!.endDate)) : true;
        return floorPass && ceilPass;
      })
    },

    get groupCards() {
      return s.viewableGroupsFilteredByGivenTimeframe.map(t => <SupportGroupSummaryCard supportGroup={t} key={t.id} actionOnClick={p.actionOnItemClick}/>);
    },

    get feed() {
      const feed = [] as JSX.Element[];
      if (!p.inserts) return s.groupCards;
      let i = 0;
      for (let index in s.groupCards) {
        feed.push(s.groupCards[index]);
        if (i < p.inserts.length && isMultiplesOf(5, +index + 3)) {
          const insert = p.inserts[i];
          feed.push(<Observer key={`after-${index}-insert-${i}`} children={() => <div className="SupportGroupFeedInsert">{'el' in insert ? insert.el : insert}</div>} />);
          i++;
        }
      }
      return feed;
    },

    reset: action(() => {
      s.feedRetrieving = false;
      s.feedRetrieved = false;
      s.timeLastRetrievedFeed = '';
      s.currentPageOfFeed = 0;
      s.lastPageOfFeed = null;
      s.errorRetrievingFeed = null;
      s.makeFeedDataGetterGenerator();
    }),

  }));

  useOnMount(() => {
    s.makeFeedDataGetterGenerator();
    return reaction(
      () => [p.filterTimeframe?.startDate, p.filterTimeframe?.endDate].join('-'),
      () => {
        s.reset();
        s.getFeedOfPage();
      }
    )
  })

  return <Observer children={() => (
    <div className="SupportGroupFeed">
      { s.feed.length === 0 && props.emptyNotice }
      {s.feed}
      {s.feedDataGetterGeneratorLastCreated && <InfiniteFeedLoader
        loaderGeneratorGetter={s.getFeedDataGetterGenerator}
        scrollContextGetter={() => document.querySelector('.RouterStackLayerContainer[data-identifier="explore"]')}
        offset={-300}
        onBeforeManualRetry={s.makeFeedDataGetterGenerator}
        doneMessage={s.feed.length === 0 ? 'No Support Groups found.' : 'You have reached the end.'}
        invisible={p.invisibleLoader}
      />}
    </div>
  )} />

}

export default SupportGroupFeed;