import { Observer } from 'mobx-react-lite';
import React from 'react';
import { CLOCK } from '../../../controllers/common/clock.controller';
import { TimezoneMode } from '../../@types/time.types';
import { ContextColor } from '../../constants/color.enum';
import joinClassName from '../../utils/className.utils';
import { getContextColorStyle } from '../../utils/colors.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { createMomentFn } from '../../utils/time.utils';
import TimeDisplayer from '../TimeDisplayer/TimeDisplayer';
import { BaseCalendarEventConfig } from './BaseCalendar';
import './BaseCalendarEvent.scss';
import BaseCalendarEventDefaultTitleRenderer from './BaseCalendarEventDefaultTitleRenderer';
import { IBaseCalendarEventTypeGroup } from './BaseCalendarState';

export interface BaseCalendarEventTitleRendererProps<EventDataType = object> {
  calendarEvent: Partial<BaseCalendarEventConfig<EventDataType>>,
}
export interface BaseCalendarEventProps<EventDataType = object> {
  className?: string,
  timezoneMode?: TimezoneMode,
  calendarEvent: BaseCalendarEventConfig<EventDataType>,
  eventTypeGroups: IBaseCalendarEventTypeGroup<EventDataType>[],
  titleRenderer?: React.FC<BaseCalendarEventTitleRendererProps<EventDataType>>,
  color?: string,
  shouldShowEventEndTime?: boolean,
  showTimestamps?: boolean,
  defaultOnEventClick?: (e?: BaseCalendarEventConfig<EventDataType>) => void,
  style?: React.CSSProperties,
}

function BaseCalendarEvent<EventDataType extends object>(props: React.PropsWithChildren<BaseCalendarEventProps<EventDataType>>) {

  const p = useProps(props);

  const _createMoment = createMomentFn(p.timezoneMode ?? 'auto');

  const s = useStore(() => ({
    get nowMoment() {
      return CLOCK.localNowMoment;
    },
    get event() {
      return p.calendarEvent;
    },
    get TitleRenderer() {
      return p.titleRenderer || BaseCalendarEventDefaultTitleRenderer;
    },
    get title() {
      return <s.TitleRenderer calendarEvent={s.event}/>;
    },
    get startTime() {
      return s.event.startTime
    },
    get startTimeMoment() {
      return s.event.startTime ? _createMoment(s.event.startTime) : null;
    },
    get startTimeDisplay() {
      return s.startTimeMoment ? s.startTimeMoment.format('HH:mm') : '--:--';
    },
    get endTime() {
      return s.event.endTime
    },
    get endTimeMoment() {
      return s.event.endTime ? _createMoment(s.event.endTime) : null;
    },
    get endTimeDisplay() {
      return s.endTimeMoment ? s.endTimeMoment.format('HH:mm') : '--:--';
    },
    get isInThePast() {
      if (s.endTimeMoment) return s.endTimeMoment.isBefore(s.nowMoment);
      else if (s.startTimeMoment) return s.startTimeMoment.isBefore(s.nowMoment);
      return null;
    },
    get isDisabled() {
      return s.event.isDisabled;
    },
    get hasEventTypeGroupsDefined() {
      return p.eventTypeGroups.length > 0;
    },
    get belongsToEventTypeGroups() {
      return p.eventTypeGroups.filter(g => g.groupTypeChecker ? g.groupTypeChecker(s.event) : true);
    },
    get belongsToEventTypes() {
      return s.belongsToEventTypeGroups.map(g => g.eventTypes).flat().filter(et => et.typeChecker ? et.typeChecker(s.event) : true);
    },
    get firstActiveEventType() {
      return s.belongsToEventTypes.find(t => t.isActive);
    },
    get shouldRender() {
      return !s.event.hidden && (!s.hasEventTypeGroupsDefined || (s.firstActiveEventType || this.belongsToEventTypes.some(t => t.isActive)));
    },
    get shouldShowTimestamps() {
      return p.showTimestamps !== undefined ? p.showTimestamps : true;
    },
    handleComponentClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (s.isDisabled) return;
      typeof p.calendarEvent.componentProps?.onClick === 'function' && p.calendarEvent.componentProps.onClick();
      typeof p.defaultOnEventClick === 'function' && p.defaultOnEventClick(s.event);
    },
    get style() {
      return {
        ...p.style,
        // ...useColorCSSCustomPropertyStyle(s.firstActiveEventType),
        ...getContextColorStyle(ContextColor.Primary, p.color ?? s.event.color ?? s.firstActiveEventType?.color),
      } 
    }
  }));

  return <Observer children={() => {
    return <div
      className={
        joinClassName(
          'BaseCalendarEvent',
          p.className,
          p.calendarEvent.componentProps?.className,
          s.isDisabled && 'disabled',
          s.isInThePast && 'inThePast',
        )
      }
      data-event-id={p.calendarEvent.id}
      data-hidden={!s.shouldRender}
      style={s.style}
      onClick={s.handleComponentClick}
    >
      {
        s.shouldShowTimestamps && <div className="BaseCalendarEventTimeWrapper">
          <TimeDisplayer value={s.event.startTime} timezoneMode={p.timezoneMode} />
          {p.shouldShowEventEndTime && s.endTime && (
            <>
              <em>to</em>
              <TimeDisplayer value={s.event.endTime} timezoneMode={p.timezoneMode} />
            </>
          )}
        </div>
      }
      <div className="BaseCalendarEventContent">
        {!props.children && <header className="BaseCalendarEventHeader">
          <h3 className="BaseCalendarEventTitle">{s.title}</h3>
        </header>}
        {props.children && <div className="BaseCalendarEventBody">
          {props.children}
        </div>}
      </div>
    </div>
  }} />
}

export default BaseCalendarEvent;