import { ResolutionMap as RM } from '../constants/resolutions.constants';
import { CommonSize, lg, md, ml, sm, xl } from '../constants/size.enum';

export const ViewportSizeMap: Record<CommonSize, Partial<Record<CommonSize, number>>> = {
  [sm]: {
    [sm]: RM.iPhoneSE[0],
    [md]: RM.iPhone8[0],
    [lg]: 480,
    [xl]: 520,
  },
  [md]: {
    [sm]: RM.nHD[0],
    [md]: RM.iPad[0],
    [lg]: 920,
  },
  [ml]: {
    [sm]: RM.iPad[1],
    [md]: RM.WXGA[0],
    [ml]: RM.HD[0],
    [lg]: RM.WXGAPlus[0],
    [xl]: 1536,
  },
  [lg]: {
    [sm]: RM.WSXGAPlus[0],
    [md]: RM.MacBook16[0],
    [lg]: RM.FHD[0],
    [xl]: RM.QWXGA[0],
  },
  [xl]: {
    [sm]: RM.QHD[0],
    [md]: 2880,
    [lg]: RM.WQHD[0],
    [xl]: RM.UHD4K[0],
  }
}


