import { action, flow, observable } from 'mobx';
import React from 'react';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useStore } from '../../base/utils/mobx.utils';
import { minutes } from '../../base/utils/time.utils';
import tick, { doEvery } from '../../base/utils/waiters.utils';
import { ModelName } from '../../constants/modelNames.enum';
import { AssignmentSupportGroupNonAttendance } from '../../models/makeAssignment.model';
import OverlaySurveySupportGroupNonAttendance from '../OverlaySurveySupportGroupNonAttendance/OverlaySurveySupportGroupNonAttendance';
// import './SurveySupportGroupNonAttendanceWatcher.scss';

type SurveySupportGroupNonAttendanceWatcherProps = {}

const _ = observable({
  shouldDisableSupportGroupNonAttendanceSurvey: false,
})

if (window.Cypress) {
  Reflect.set(window, 'disableSupportGroupNonAttendanceSurvey', action(() => {
    _.shouldDisableSupportGroupNonAttendanceSurvey = true;
  }))
}

const SurveySupportGroupNonAttendanceWatcher: React.FC<SurveySupportGroupNonAttendanceWatcherProps> = props => {
  const { AUTH, NAVIGATOR, MESSENGER, UI, ASSIGNMENTS } = useControllers();
  const s = useStore(() => ({
    isWaiting: false,
    setNotWaiting: action(() => {
      s.isWaiting = false;
    })
  }))
  useOnMount(() => {
    return doEvery(
      flow(function* () {
        if (_.shouldDisableSupportGroupNonAttendanceSurvey) return;
        if (!AUTH.currentUser) return;
        if (!AUTH.isAuthenticated) return;
        if (NAVIGATOR.currentLocationPathname.match(/^\/app\/(invitation)/)) return;
        if (MESSENGER.dockedChats.length) return;
        if (s.isWaiting) return;
        s.isWaiting = true;
        yield tick(2500);

        // On support group end, API will create sg non attendance surveys for users whom registered, but did not attend.
        // This allows App to get all assignments, and filter those that are sg non attendance surveys.
        // If there are any, it means user did not attend some support groups.
        const nonAttendanceSurveyAssignments = ASSIGNMENTS.array.filter(ass => ass.targetTypeModelName === ModelName.surveysSupportGroupNonAttendance && !ass.timeCompleted);

        const shouldShowOverlay = nonAttendanceSurveyAssignments.length !== 0;
        if (shouldShowOverlay) {
          const assignment = nonAttendanceSurveyAssignments[0];
          UI.OVERLAY.present({
            component: <OverlaySurveySupportGroupNonAttendance assignment={assignment as AssignmentSupportGroupNonAttendance} />,
            onAfterClose: s.setNotWaiting,
          })
        } else s.setNotWaiting();
      }),
      minutes(4),
      { fireImmediately: true }
    );
  })
  return null;
}

export default SurveySupportGroupNonAttendanceWatcher;