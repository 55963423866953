import { action, flow, observable, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { presentOverlayDonateFn } from '../../../../../actions/donation.actions';
import AppPage from '../../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../../base/components/AppPageHeader/AppPageHeader';
import BackButton from '../../../../../base/components/BackButton/BackButton';
import BaseButton from '../../../../../base/components/BaseButton/BaseButton';
import BaseButtonGroup from '../../../../../base/components/BaseButtonGroup/BaseButtonGroup';
import BaseHeader from '../../../../../base/components/BaseHeader/BaseHeader';
import BaseInput from '../../../../../base/components/BaseInput/BaseInput';
import BaseSelector from '../../../../../base/components/BaseSelector/BaseSelector';
import BaseSpacer from '../../../../../base/components/BaseSpacer/BaseSpacer';
import ColorTag from '../../../../../base/components/ColorTag/ColorTag';
import DateRenderer from '../../../../../base/components/DateRenderer/DateRenderer';
import ErrorRenderer from '../../../../../base/components/ErrorRenderer/ErrorRenderer';
import IndexDirectory from '../../../../../base/components/IndexDirectory/IndexDirectory';
import { IndexDirectoryViewMode } from '../../../../../base/components/IndexDirectory/indexDirectory.types';
import IndexDirectoryState from '../../../../../base/components/IndexDirectory/IndexDirectoryState';
import InfoTable from '../../../../../base/components/InfoTable/InfoTable';
import ShadedBlock from '../../../../../base/components/ShadedBlock/ShadedBlock';
import UIBlock from '../../../../../base/components/UIBlock/UIBlock';
import { PaymentEndpoints } from '../../../../../base/endpoints/payment.endpoints';
import { DonationSubscriptions } from '../../../../../base/endpoints/subscription.endpoints';
import { UserEndpoints } from '../../../../../base/endpoints/user.endpoints';
import { useOnMount } from '../../../../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../../../../base/hooks/useRootController.hook';
import { makeActionConfig, makeCancelAction } from '../../../../../base/utils/actionConfig.utils';
import { makeLaravelIndexDataFetcher } from '../../../../../base/utils/api.utils';
import { makeDisposerController } from '../../../../../base/utils/disposer.utils';
import { reportError } from '../../../../../base/utils/errors.utils';
import { useStore } from '../../../../../base/utils/mobx.utils';
import tick from '../../../../../base/utils/waiters.utils';
import PaymentEntry from '../../../../../components/PaymentEntry/PaymentEntry';
import { ApiModelName } from '../../../../../constants/ApiModels.enum';
import { ModelName } from '../../../../../constants/modelNames.enum';
import { recurringDonationFrequencies, SubscriptionFrequency, SubscriptionFrequencyToStripePriceId } from '../../../../../constants/subscription.enums';
import { FEATURE_FLAGS } from '../../../../../env';
import { Payment } from '../../../../../models/makePayment.model';
import { Subscription } from '../../../../../models/makeSubscription.model';
import './AccountPageManageDonations.scss';

type AccountPageManageDonationsProps = {}

const AccountPageManageDonations: React.FC<AccountPageManageDonationsProps> = React.memo((props) => {

  const { AUTH, UI, API, LOCALDB, COMMON } = useControllers();

  const s = useStore(() => ({
    indexDirectoryState: new IndexDirectoryState<Payment>(observable({
      viewMode: 'list' as IndexDirectoryViewMode,
      searchable: false,
    })),
    shouldGETData: false,
    isLoadingLogs: true,

    isLoading: !AUTH.currentUser?.activeSubscription,
    subscription: AUTH.currentUser?.activeSubscription as Subscription | null,

    useNewSubscription: action((subscription: Subscription | null) => {
      s.subscription = subscription;
      s.resetForm(subscription);
    }),

    form: {
      amount: 0,
      donationFrequency: SubscriptionFrequency.weekly as Exclude<SubscriptionFrequency, SubscriptionFrequency.oneTime>,
    },
    resetForm: action((subscription: Subscription | null) => {
      s.form = {
        amount: subscription?.amount ?? 0,
        donationFrequency: subscription?.frequency ?? SubscriptionFrequency.weekly,
      };
    }),

    get hasChanges() {
      return s.subscription?.amount !== s.form.amount || s.subscription?.frequency !== s.form.donationFrequency;
    },

    get hasActiveSubscription() { return !!s.subscription; },


    get Logs() {
      return React.memo(() => (
        <IndexDirectory<Payment>
          state={s.indexDirectoryState}
          dataFetcher={getPaymentData}
          listEntrySeparator={<BaseSpacer size=".5em" />}
          listEntryRenderer={p => <PaymentEntry payment={p} />}
          shouldTriggerReload={s.shouldGETData}
        />
      ))
    },
  }));

  const getPaymentData = makeLaravelIndexDataFetcher<Payment>(
    API,
    ModelName.payments,
    PaymentEndpoints.own.index,
    {
      sort: '-timeCreated',
      include: ['model'],
      filter: {
        whereEquals: {
          key: 'modelType',
          values: [ApiModelName.SUBSCRIPTION]
        }
      },
    },
    {
      onError: (e) => reportError(e),
      onSuccess: action(() => { s.isLoadingLogs = false }),
    },
  )

  const update = async () => {
    const confirm = await UI.DIALOG.attention({
      name: 'update-recurring-donation',
      heading: 'Are you sure you want to update your recurring donation?',
      body: 'This change will take effect on your next donation date.',
      defaultActions: ['negative', 'positive'],
    })
    if (!confirm) return;
    try {
      const url = DonationSubscriptions.own.update();
      const payload = {
        quantity: s.form.amount * 100,
        pricingId: SubscriptionFrequencyToStripePriceId[s.form.donationFrequency],
      }
      const subscription = await API.patch<Subscription>(url, ModelName.subscriptions, payload);
      if (!!subscription) s.useNewSubscription(subscription);
      await UI.DIALOG.success({
        heading: "Your recurring donation has been updated.",
      })
    } catch (e) {
      reportError(e);
      UI.DIALOG.error({
        heading: 'Failed to update recurring donation',
        error: <ErrorRenderer error={e} />
      })
    }
  }

  const cancel = async () => {
    await UI.DIALOG.attention({
      name: 'cancel-recurring-donations',
      heading: 'Are you sure you want to cancel your recurring donation?',
      actions: [
        makeCancelAction('No'),
        makeActionConfig('Yes', async () => {
          try {
            const url = DonationSubscriptions.own.cancel();
            await API.postRaw(url);
            await tick();
            await API.get(UserEndpoints.own.get({ include: ['subscriptions'] }), ModelName.users);
            s.useNewSubscription(null);
          } catch (e) {
            reportError(e);
            UI.DIALOG.error({
              heading: 'Failed to cancel recurring donation',
              error: <ErrorRenderer error={e} />
            })
          }
        }),
      ]
    })
  }

  useOnMount(() => {
    const d = makeDisposerController();
    const triggerGETLog = flow(function* () {
      if (s.isLoadingLogs) return;
      s.shouldGETData = true;
      yield tick(500);
      if (s.shouldGETData) s.shouldGETData = false;
    })
    d.add(reaction(
      () => LOCALDB.data.payments.size,
      triggerGETLog,
    ))
    d.add(reaction(
      () => AUTH.currentUser?.activeSubscription,
      (newSub) => {
        s.useNewSubscription(newSub ?? null);
        triggerGETLog();
      },
      { fireImmediately: true }
    ))
    return d.disposer;
  })

  return <Observer children={() => (
    <AppPage className="AccountPageManageDonations"
      accommodateTitleBarSpace
      color="red"
    >
      <AppPageHeader
        title="Manage Recurring Donations"
        afterTitle="Manage and view your recurring donation history"
        startSlot={<BackButton destination="up" />}
      />
      <AppPageContent>
        <UIBlock padded>

          <ShadedBlock color={s.hasActiveSubscription ? "green" : "orange"} spaceChildren>
            {s.hasActiveSubscription
              ? <>
                <InfoTable>
                  <thead><tr><th colSpan={2}>
                    <BaseHeader
                      heading={<><span>Status: </span><ColorTag color="green">Active</ColorTag></>}
                      endSlot={
                        <BaseButton
                          dataCy="CancelButton"
                          label="Cancel Recurring Donation"
                          onClick={cancel}
                          color="red"
                          size="sm"
                        />}
                    />
                  </th></tr></thead>

                  <tbody>
                    <tr><th>Amount ({COMMON.currencySymbol})</th><td><BaseInput form={s.form} field="amount" type="number" min={1} step={0.01} /></td></tr>
                    <tr><th>Frequency</th><td><BaseSelector
                      form={s.form}
                      field="donationFrequency"
                      appearance="inline"
                      options={recurringDonationFrequencies}
                    /></td></tr>
                    <tr><th>Started at</th><td><DateRenderer value={s.subscription?.timeCreated} format="LLL" /></td></tr>
                    {FEATURE_FLAGS.SHOW_NEXT_RECURRING_DONATION_DATE && <tr><th>Next donation date</th><td><DateRenderer value={s.subscription?.nextDonationDate} format="LL" /></td></tr>}
                  </tbody>
                </InfoTable>

                <BaseSpacer size="sm" />

                <BaseButtonGroup flex>
                  <BaseButton className="subtle" label="Discard Changes" appearance="text" color="red" onClick={() => s.resetForm(s.subscription)} disabled={!s.hasChanges} />
                  <BaseButton color="green" label="Save Changes" onClick={update} disabled={!s.hasChanges} dataCy="saveChanges" />
                </BaseButtonGroup>
              </>
              : <>
                <h3>You currently do not have an active recurring donation.</h3>
                <BaseSpacer size="sm" />
                <BaseButton
                  dataCy="DonateButton"
                  onClick={presentOverlayDonateFn(UI, { defaultFrequency: SubscriptionFrequency.monthly })}
                  backgroundImage="radial-gradient(140.89% 140.89% at 72.8% 1.2%, #F7472F 0%, #F29F94 100%)"
                  label="Consider donating monthly to turn2me"
                  fullWidth
                  size="lg"
                  icon="heart"
                  iconVariant="filled"
                />
              </>
            }
          </ShadedBlock>

          {<s.Logs />}
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
})

export default AccountPageManageDonations;