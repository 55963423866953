import { flow, observable } from 'mobx';
import { AnyObject, RouteDef } from '../base/@types';
import { makeConstantPromise } from '../base/utils/promises.utils';
import { isInCypressTestMode, IS_DEV, SHOULD_LOG } from '../env';
import { makeAlertsController } from './alerts.controller';
import { makeAnalyticsController } from './analytics.controller';
import { makeAPIController } from './api.controller';
import { makeAssignmentsController } from './assignments.controller';
import { makeAuthController } from './auth.controller';
import { makeCommonController } from './common.controller';
import { makeConfigurationsController } from './configurations.controller';
import { makeContentController } from './content.controller';
import { makeCounsellingController } from './counselling.controller';
import { makeLocalDBController } from './localDB.controller';
import { makeMessengerController } from './messenger.controller';
import { makeNavigatorController } from './navigator.controller';
import { makeStaffController } from './staff.controller';
import { makeStorageController } from './storage.controller';
import { makeSupportGroupsController } from './supportGroups.controller';
import { makeSurveysController } from './surveys.controller';
import { makeThoughtCatcherController } from './thoughtCatcher.controller';
import { makeTransceiverController } from './transceiver.controller';
import { makeUIController } from "./ui.controller";
import { makeVideoController } from './video.controller';
import { makeVoipController } from './voip.controller';
import { Controller, RootController, RootControllerChildren } from './_controller.types';

/**
 * @see [Root Context](docs/application/root-context.md)
 */
export const makeRootController = (
  options: {
    appShortName?: string,
    appInstanceId?: string,
    routes: RouteDef[],
    api: {
      config: object,
    },
  }
) => {

  const appInstanceId = options.appInstanceId ?? '1';
  if (IS_DEV) Reflect.set(window, 'APP_INSTANCE_ID', appInstanceId);

  const UI = makeUIController();
  const NAVIGATOR = makeNavigatorController(options.routes);
  const STORAGE = makeStorageController(appInstanceId, options.appShortName);
  const LOCALDB = makeLocalDBController();
  const API = makeAPIController(options.api.config);
  const AUTH = makeAuthController();
  const CONFIGURATIONS = makeConfigurationsController();
  const COMMON = makeCommonController();
  const CONTENT = makeContentController();
  const ALERTS = makeAlertsController();
  const THOUGHT_CATCHER = makeThoughtCatcherController();
  const COUNSELLING = makeCounsellingController();
  const SUPPORT_GROUPS = makeSupportGroupsController();
  const SURVEYS = makeSurveysController();
  const ASSIGNMENTS = makeAssignmentsController();
  const TRANSCEIVER = makeTransceiverController();
  const MESSENGER = makeMessengerController();
  const VIDEO = makeVideoController();
  const VOIP = makeVoipController();
  const ANALYTICS = makeAnalyticsController();
  const STAFF = makeStaffController();

  const children = observable({
    get UI() { return UI },
    get NAVIGATOR() { return NAVIGATOR },
    get STORAGE() { return STORAGE },
    get LOCALDB() { return LOCALDB },
    get API() { return API },
    get AUTH() { return AUTH },
    get CONFIGURATIONS() { return CONFIGURATIONS },
    get COMMON() { return COMMON },
    get CONTENT() { return CONTENT },
    get ALERTS() { return ALERTS },
    get THOUGHT_CATCHER() { return THOUGHT_CATCHER },
    get COUNSELLING() { return COUNSELLING },
    get SUPPORT_GROUPS() { return SUPPORT_GROUPS },
    get SURVEYS() { return SURVEYS },
    get ASSIGNMENTS() { return ASSIGNMENTS },
    get TRANSCEIVER() { return TRANSCEIVER },
    get MESSENGER() { return MESSENGER },
    get VIDEO() { return VIDEO },
    get VOIP() { return VOIP },
    get ANALYTICS() { return ANALYTICS },
    get STAFF() { return STAFF },
  });

  const c: Required<Controller<{}, RootControllerChildren>> & {
    appInstanceId: string,
  } = observable({

    get name() { return 'ROOT' },
    get ROOT() { return c },
    get appInstanceId() { return appInstanceId },

    ready: false,

    init: () => new Promise<true>(flow(function * (resolve, reject) {

      /** There is a rough certain order to initiate the child stores due to interdependencies. Do not rewrite to a simple loop. */

      yield c.children.UI.init(c);
      yield c.children.NAVIGATOR.init(c);
      yield c.children.STORAGE.init(c);
      yield c.children.LOCALDB.init(c);
      yield c.children.API.init(c);
      yield c.children.AUTH.init(c);
      yield c.children.CONFIGURATIONS.init(c);
      yield c.children.COMMON.init(c);
      yield c.children.CONTENT.init(c);
      yield c.children.ALERTS.init(c);
      yield c.children.THOUGHT_CATCHER.init(c);
      yield c.children.COUNSELLING.init(c);
      yield c.children.SUPPORT_GROUPS.init(c);
      yield c.children.SURVEYS.init(c);
      yield c.children.ASSIGNMENTS.init(c);
      yield c.children.TRANSCEIVER.init(c);
      yield c.children.MESSENGER.init(c);
      yield c.children.VIDEO.init(c);
      yield c.children.VOIP.init(c);
      yield c.children.ANALYTICS.init(c);
      yield c.children.STAFF.init(c);

      if (IS_DEV || isInCypressTestMode) {
      // if (IS_DEV || isInCypressTestMode || SHOULD_LOG()) {
        Reflect.set(window, 'ROOT', c);
        Reflect.set(window, 'UI', children.UI);
        Reflect.set(window, 'NAVIGATOR', children.NAVIGATOR);
        Reflect.set(window, 'STORAGE', children.STORAGE);
        Reflect.set(window, 'LOCALDB', children.LOCALDB);
        Reflect.set(window, 'API', children.API);
        Reflect.set(window, 'AUTH', children.AUTH);
        Reflect.set(window, 'CONFIGURATIONS', children.CONFIGURATIONS);
        Reflect.set(window, 'COMMON', children.COMMON);
        Reflect.set(window, 'CONTENT', children.CONTENT);
        Reflect.set(window, 'ALERTS', children.ALERTS);
        Reflect.set(window, 'THOUGHT_CATCHER', children.THOUGHT_CATCHER);
        Reflect.set(window, 'COUNSELLING', children.COUNSELLING);
        Reflect.set(window, 'SUPPORT_GROUPS', children.SUPPORT_GROUPS);
        Reflect.set(window, 'SURVEYS', children.SURVEYS);
        Reflect.set(window, 'ASSIGNMENTS', children.ASSIGNMENTS);
        Reflect.set(window, 'TRANSCEIVER', children.TRANSCEIVER);
        Reflect.set(window, 'MESSENGER', children.MESSENGER);
        Reflect.set(window, 'VIDEO', children.VIDEO);
        Reflect.set(window, 'VOIP', children.VOIP);
        Reflect.set(window, 'ANALYTICS', children.ANALYTICS);
        Reflect.set(window, 'STAFF', children.STAFF);
      }
      c.ready = true;
      resolve(true);
    })),

    get children() { return children },

    get reset() {
      return async (invokedFromRoot?: boolean) => {
        // ! FIX Object.values(c.children) returns empty list for some reason.
        // for (let controller of Object.values(c.children)) {
        //   console.log('resetting controllers', controller);
        //   await controller?.reset(true);
        // }
        c.children.NAVIGATOR.reset();
        c.children.STORAGE.reset();
        c.children.LOCALDB.reset();
        c.children.API.reset();
        c.children.AUTH.reset();
        c.children.COMMON.reset();
        c.children.CONTENT.reset();
        c.children.ALERTS.reset();
        c.children.THOUGHT_CATCHER.reset();
        c.children.COUNSELLING.reset();
        c.children.SUPPORT_GROUPS.reset();
        c.children.SURVEYS.reset();
        c.children.ASSIGNMENTS.reset();
        c.children.TRANSCEIVER.reset();
        c.children.MESSENGER.reset();
        c.children.VIDEO.reset();
        c.children.VOIP.reset();
        c.children.ANALYTICS.reset();
        c.children.STAFF.reset();
      };
    }

  })

  if (IS_DEV) Reflect.set(window, 'ROOT', c);

  return c;

}

export const alwaysTrueInit = (name: string) => makeConstantPromise<true, RootController | undefined>(
  true,
  () => SHOULD_LOG() && console.log(`${name} init...`)
);

export const makeControllerBase = <T extends string = string>(name: T) => {
  const c = observable({
    ROOT: undefined as RootController | undefined,
    name,
    ready: false,
    init: alwaysTrueInit(name),
    children: {},
  })
  return c;
}

export const makeRootControllerChildInitFn = (
  controller: AnyObject,
  fn: Function,
  onError?: (e: Error | unknown) => void
) => {
  return (ROOT?: RootController) => new Promise<true>(
    flow(function * (resolve, reject) {
      try {
        // SHOULD_LOG() && console.log('init', controller.name);
        controller.ROOT = ROOT;
        fn && (yield fn());
        controller.ready = true;
        resolve(true);
      } catch(e) {
        reject(e);
        onError && onError(e);
      }
    }
  ))
}
