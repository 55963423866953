import { Observer } from 'mobx-react-lite';
import React from 'react';
import ClickOrTap from '../../../../../base/components/ClickOrTap/ClickOrTap';
import { useControllers } from '../../../../../base/hooks/useRootController.hook';
import { presentEmailVerificationDialog } from '../../../../../controllers/auth/verifyEmail';
import './VerifyEmailCard.scss';

type VerifyEmailCardProps = {}

const VerifyEmailCard: React.FC<VerifyEmailCardProps> = props => {
  const { UI, AUTH } = useControllers();
  const handler = () => presentEmailVerificationDialog(UI, AUTH);
  return <Observer children={() => (
    <button className="VerifyEmailCard" onClick={handler}>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="7" y="9" width="33" height="33" rx="7" fill="url(#DOI2BFUCVS0_linear)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M16 18H31C32.1046 18 33 18.8954 33 20V21.4086L32.733 21.5773L23.5 27.4086L14.267 21.5773L14 21.4086V20C14 18.8954 14.8954 18 16 18ZM14 22.5914V31C14 32.1046 14.8954 33 16 33H31C32.1046 33 33 32.1046 33 31V22.5914L23.767 28.4227C23.6039 28.5258 23.3961 28.5258 23.233 28.4227L14 22.5914ZM13 20C13 18.3431 14.3431 17 16 17H31C32.6569 17 34 18.3431 34 20V31C34 32.6569 32.6569 34 31 34H16C14.3431 34 13 32.6569 13 31V20Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M40 18C44.4183 18 48 14.4183 48 10C48 5.58172 44.4183 2 40 2C35.5817 2 32 5.58172 32 10C32 14.4183 35.5817 18 40 18ZM41.16 15H40.12V6.628L38.352 7.915L37.754 7.174L40.12 5.445H41.16V15Z" fill="white" />
        <defs>
          <linearGradient id="DOI2BFUCVS0_linear" x1="25.5" y1="2.5" x2="47.3859" y2="35.176" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.44" />
            <stop offset="1" stopColor="white" stopOpacity="0.12" />
          </linearGradient>
        </defs>
      </svg>
      <h3>Welcome to turn2me!</h3>
      <p>Don't forget to verify your email by clicking the confirmation link in your inbox. If you didn't get the email, or are unsure what to to, <ClickOrTap /> here for more information.</p>
    </button>
  )} />
}

export default VerifyEmailCard;