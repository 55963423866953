import { Nillable } from "../base/@types";
import { toTitleCase } from "../base/utils/string.utils";
import { isString } from "../base/utils/typeChecks.utils";
import { User } from "../models/makeUser.model";
import { AppStaffRole } from "./staffRoles.constants";

/**
 *
 * AppPermission:
 *
 * A permission system based on abstract abilities and exceptions.
 * All users should by default have no permissions,
 * and any users with additional permissions are staff members.
 *
 * A special set of 'workWith' permissions defines potentially overlapping subsets of users or
 * counselling / support group subtypes.
 * They are like whitelists, so if an user has "provideCounselling" but has no "workWith" defined,
 * they won't actually be able to perform any counselling services.
 *
 */

export enum AppPermission {

	// age groups
	'workWithAdults' = 'workWithAdults',
	'workWithYoungPeople1214' = 'workWithYoungPeople1214',
	'workWithYoungPeople1517' = 'workWithYoungPeople1517',

	// feature subtypes
	'workWithCouples' = 'workWithCouples',
	'workWithFrontLineWorkers' = 'workWithFrontLineWorkers',

	// counselling
	'provideCounselling' = 'provideCounselling',
	'enforceSessionPaymentCollection' = 'enforceSessionPaymentCollection',
	'markSessionAsRefunded' = 'markSessionAsRefunded',

	// billing related (counselling sessions, support group)
	'billPaidCounsellingSessions' = 'billPaidCounsellingSessions',
	'billFreeCounsellingSessions' = 'billFreeCounsellingSessions',

	// support groups
	'facilitateSupportGroups' = 'facilitateSupportGroups',
	'manageSupportGroupTopics' = 'manageSupportGroupTopics',

	// staff coordination
	'coordinatePaidCounsellingAvailabilities' = 'coordinatePaidCounsellingAvailabilities',
	'coordinateFreeCounsellingAvailabilities' = 'coordinateFreeCounsellingAvailabilities',
	'coordinateCounsellingApplications' = 'coordinateCounsellingApplications',
	'coordinateCounsellingSessions' = 'coordinateCounsellingSessions',
	'coordinateSupportGroups' = 'coordinateSupportGroups',

	// thought catcher moderation
	'moderateThoughtCatcher' = 'moderateThoughtCatcher',

	// user management
	'accessAnyUsersPersonalInfo' = 'accessAnyUsersPersonalInfo',
	'accessAnyUsersClinicalData' = 'accessAnyUsersClinicalData',
	'updateAnyUserPersonalInfo' = 'updateAnyUserPersonalInfo',
	'updateAnyUserPassword' = 'updateAnyUserPassword',
	'manageStaff' = 'manageStaff',
	'manageStaffInvoicing' = 'manageStaffInvoicing',

	// companies / partnerships management
	'manageCompanies' = 'manageCompanies',

	// user moderation & account access controls
	'muteAnyUser' = 'muteAnyUser',
	// "client" differs from "user" as "user" includes staff accounts.
	'suspendAnyClient' = 'suspendAnyClient',
	'deleteAnyClient' = 'deleteAnyClient',

	/**
	 * the catch-all permission that allows any user management
	 * including deactivating and deleting of staff accounts and
	 * permission management for any user
	 */
	'manageAllUsers' = 'manageAllUsers',

	// chat system
	'startChatWithStaff' = 'startChatWithStaff',
	'startChatWithClients' = 'startChatWithClients',
	'viewAnyChatHistory' = 'viewAnyChatHistory',
	'useVoipFeature' = 'useVoipFeature',

	// customer support & anonymized user surveys
	'manageContactForms' = 'manageContactForms',
	'manageFeedback' = 'manageFeedback',
	'manageSatisfactionSurveys' = 'manageSatisfactionSurveys',
	'manageGeneralSurveys' = 'manageGeneralSurveys',
	'managePHQ9Surveys' = 'managePHQ9Surveys',
	'manageGAD7Surveys' = 'manageGAD7Surveys',
	'manageSupportGroupSatisfactionSurveys' = 'manageSupportGroupSatisfactionSurveys',
	'manageSupportGroupNonAttendanceSurveys' = 'manageSupportGroupNonAttendanceSurveys',
	'exportCounsellingApplications' = 'exportCounsellingApplications',

	// data analysis
	'accessStatsUsers' = 'accessStatsUsers',
	'accessStatsDemography' = 'accessStatsDemography',
	'accessStatsOnlineCounselling' = 'accessStatsOnlineCounselling',
	'accessStatsSupportGroups' = 'accessStatsSupportGroups',
	'accessStatsThoughtCatcher' = 'accessStatsThoughtCatcher',
	'accessStatsDonations' = 'accessStatsDonations',
	'accessStatsCounsellors' = 'accessStatsCounsellors',
	'accessStatsSatisfactionSurveys' = 'accessStatsSatisfactionSurveys',

	// notifications and announcements
	'sendIndividualNotificationsToAnyUser' = 'sendIndividualNotificationsToAnyUser',

	// finance & donations
	'manageDonations' = 'manageDonations',

	// general service management
	'manageGlobalSettings' = 'manageGlobalSettings',
	'manageServiceCountryAvailabilities' = 'manageServiceCountryAvailabilities',

}

export const VisitorPermissionSet: AppPermission[] = []

export const DeveloperPermissionSet: AppPermission[] = Object.values(AppPermission);

export type PermissionChecker = (permissionName: AppPermission | RegExp) => boolean;
export type RoleChecker = (role: AppStaffRole) => boolean;

export const makePermissionChecker = (permissionSet: AppPermission[]) => (p: AppPermission | RegExp) => {
	if (isString(p)) return permissionSet.includes(p);
	else return permissionSet.some(perm => perm.match(p));
}
export const makeRoleChecker = (roleSet: AppStaffRole[]) => (role: AppStaffRole | RegExp) => {
	if (isString(role)) return roleSet.includes(role);
	else return roleSet.some(role => role.match(role));
}
export const VisitorPermissionChecker = makePermissionChecker(VisitorPermissionSet);

export const isDeveloper = (userOrUserId?: Nillable<User | string>) => {
	if (!userOrUserId) return false;
	const id = isString(userOrUserId) ? userOrUserId : userOrUserId?.id;
	return id === '1';
}

export const getPermissionDisplayName = (p: AppPermission) => {
	switch (p) {
		case AppPermission.workWithAdults:
			return "Work with Adults";
		case AppPermission.workWithCouples:
			return "Work with Couples";
		case AppPermission.workWithFrontLineWorkers:
			return "Work with Front Line Workers";
		case AppPermission.workWithYoungPeople1214:
			return "Work with Young People of age 12–14";
		case AppPermission.workWithYoungPeople1517:
			return "Work with Young People of age 15–17";
		case AppPermission.useVoipFeature:
			return 'Use VOIP Feature'
		default:
			return toTitleCase(p);
	}
}