import { action, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useLocation } from 'react-router';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useControllers } from '../../hooks/useRootController.hook';
import { makeDisposerController } from '../../utils/disposer.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { removeUrlParam, setUrlParam } from '../../utils/urlParams.utils';
import tick from '../../utils/waiters.utils';
import './BasePagination.scss';
import { IBasePaginationState, IBasePaginationStateOptions } from './BasePaginationState';

interface P {
  paginationState: IBasePaginationState,
  onChange: (n: number) => void;
}

type PaginationLink = number | '…';
type PaginationLinkArray = PaginationLink[];

const BasePagination: React.FC<P> = props => {

  const p = useProps(props);

  const { UI } = useControllers();
  const location = useLocation();

  const s = useStore(() => ({
    defaultPaginationState: {
      currentPage: 1,
      lastPage: 1,
      numberOfTotalItems: 0,
    },
    get paginationState() {
      return p.paginationState || s.defaultPaginationState;
    },
    get currentPage() {
      return s.paginationState.currentPage;
    },
    set currentPage(n: number) {
      s.paginationState.currentPage = n;
    },
    get lastPage() {
      return s.paginationState.lastPage;
    },
    set lastPage(n: number) {
      s.paginationState.lastPage = n;
    },
    get numberOfTotalItems() {
      return (s.paginationState as IBasePaginationStateOptions).totalItems;
    },
    set numberOfTotalItems(n: number) {
      (s.paginationState as IBasePaginationStateOptions).totalItems = n;
    },
    get shouldDisplaySelf() {
      return s.lastPage > 1;
    },
    get numberOfLinksPerSection() {
      switch (UI.displayMode) {
        case 'phone': return 3;
        case 'tablet': return 4;
        default: return 5;
      }
    },
    get visiblePageLinks(): PaginationLinkArray {
      if (s.lastPage < 15) {
        return Array(s.lastPage).fill(null).map((n,i) => i + 1);
      }
      const firstFewLinks = Array(s.numberOfLinksPerSection).fill(null).map((n,i) => i + 1);
      const lastFewLinks = Array(s.numberOfLinksPerSection).fill(null).map((n,i) => s.lastPage - i).reverse();
      const linksNearCurrentPage = Array(Math.floor(s.numberOfLinksPerSection / 2) * 2 + 1).fill(null).map((n,i,arr) => {
        const halfLength = arr.length / 2;
        if (i < halfLength) return s.currentPage - Math.floor(halfLength - i);
        if (i > halfLength) return s.currentPage + Math.ceil(i - halfLength);
        return s.currentPage;
      })
      const allLinks: number[] = Array.from(new Set(
        [...firstFewLinks, ...linksNearCurrentPage, ...lastFewLinks]
      )).filter(n => n > 0 && n <= s.lastPage).sort((a,b) => a - b);
      return allLinks.reduce((a, b, i, arr) => [
        ...a,
        b - arr[i-1] > 1 ? '…' : false, // insert '…' between non-consecutive numbers
        b
      ].filter(i => i) as PaginationLinkArray, [] as PaginationLinkArray);
    },
    goToPageNumber: action((n: PaginationLink) => {
      if (n === '…') return;
      s.currentPage = n;
      saveStateToUrl();
      p.onChange && p.onChange(n);
    }),
    get prevLabel() {
      switch (UI.displayMode) {
        case 'phone': return 'Prev';
        case 'tablet': return 'Previous';
        default: return 'Previous Page';
      }
    },
    get nextLabel() {
      switch (UI.displayMode) {
        case 'phone': return 'Next';
        case 'tablet': return 'Next';
        default: return 'Next Page';
      }
    },
    get canGoPrev() { return s.currentPage > 1; },
    get canGoNext() { return s.currentPage < s.lastPage; },
    goPrev: () => s.goToPageNumber(s.currentPage - 1),
    goNext: () => s.goToPageNumber(s.currentPage + 1),
    handleButtonClick: (n: PaginationLink) => () => s.goToPageNumber(n),
  }));

  function saveStateToUrl() {
    s.currentPage > 1 ? setUrlParam('page', s.currentPage) : removeUrlParam('page');
  }

  useOnMount(() => {
    const d = makeDisposerController();
    (async function readStateFromUrl() {
      await tick();
      if (s.paginationState.doNotSyncWithURL) return;
      const params = new URLSearchParams(location.search);
      const currentPageFromUrl = params.get('page');
      if (currentPageFromUrl) {
        s.goToPageNumber(parseInt(currentPageFromUrl));
      }
    })()
    d.add(() => {
      removeUrlParam('page');
    })
    d.add(reaction(
      () => s.paginationState.currentPage > s.paginationState.lastPage,
      value => {
        if (value) s.goToPageNumber(1);
      },
      { fireImmediately: true }
    ))
    return d.disposer;
  });

  return <Observer children={() => (
    s.shouldDisplaySelf ? <div className="BasePagination">
      <button className="BasePaginationPrevButton" onClick={s.goPrev} disabled={!s.canGoPrev}>{s.prevLabel}</button>
      <div className="BasePaginationPageList">
        {
          s.visiblePageLinks.map((n, i) => <button
            onClick={s.handleButtonClick(n)}
            data-for-page={n}
            key={'' + n + i}
            className={n === s.currentPage ? 'active' : undefined}
            disabled={n === '…'}
            children={n}
          />
          )
        }
      </div>
      <button className="BasePaginationNextButton" onClick={s.goNext} disabled={!s.canGoNext}>{s.nextLabel}</button>
    </div> : <></>
  )} />
}

export default BasePagination;