import moment from "moment";
import { Nullable, StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { sum } from "../base/utils/ramdaEquivalents.utils";
import { ModelName } from "../constants/modelNames.enum";
import { hasTimestamps } from "../traits/hasTimestamps.trait";
import { InvoiceItem } from "./makeInvoiceItem.models";
import { User } from "./makeUser.model";

export type InvoiceSnapshot = ReturnType<typeof makeInvoiceSnapshot>;

export const makeInvoiceSnapshot = () => ({
  id: '',
  total: 0,
  itemIds: [] as string[],
  payeeId: '',
  /** once timeConfirmedByPayee is added, the invoice is considered reviewed by the payee. Admin can then start reviewing the invoice. */
  timeConfirmedByPayee: '' as Nullable<string>,
  /** admin can mark the invoice as paid only if timeConfirmedByPayee is set  */
  timePaid: '' as Nullable<string>,
  /** admin can mark the invoice as rejected only if timeConfirmedByPayee is set  */
  timeRejected: '' as Nullable<string>,
  billingPeriodStartDate: '',
  billingPeriodEndDate: '',
  taxCode: '' as Nullable<string>,
  notes: '' as Nullable<string>,
  croNumber: '' as Nullable<string>,
  processedByUserId: '' as Nullable<string>,
  ...hasTimestamps(),
})

export type InvoiceRelatedModels = {
  payee?: User,
  processedByUser?: User,
  items: InvoiceItem[],
}

export type InvoiceExtendedProperties = {
  readonly total: number,
  readonly year: number,
  // /** 0-based indexing, January is month 0. */
  readonly month: number,
  readonly status: InvoiceStatus,
}

export enum InvoiceStatus {
  Rejected = "Rejected",
  Paid = "Paid",
  PendingProcess = "Confirmed by payee, to be processed",
  PendingPayeeConfirmation = "Pending payee confirmation",
}

export type Invoice = StandardModel<InvoiceSnapshot, InvoiceRelatedModels, InvoiceExtendedProperties>;

export const makeInvoice = createStandardModelFactory<Invoice, InvoiceRelatedModels, InvoiceExtendedProperties>({
  name: ModelName.invoices,
  snapshotFactory: makeInvoiceSnapshot,
  relationshipsSchema: {
    payee: ModelName.users,
    processedByUser: ModelName.users,
    items: { modelName: ModelName.invoiceItems, has: "many" },
  },
  extendedPropertiesFactory: (m, $, localdb) => ({
    get total() {
      return m.items ? sum(m.items.map(i => i.amountPayable)) : $.total;
    },
    get year() {
      return moment(m.billingPeriodEndDate).year();
    },
    get month() {
      return moment(m.billingPeriodEndDate).month();
    },
    get status() {
      if (m.timeRejected) return InvoiceStatus.Rejected;
      if (m.timePaid) return InvoiceStatus.Paid;
      if (m.timeConfirmedByPayee) return InvoiceStatus.PendingProcess;
      return InvoiceStatus.PendingPayeeConfirmation;
    }
  })
})