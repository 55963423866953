import { flow, isObservableObject, onBecomeObserved, when } from "mobx";
import { Nullable } from "../base/@types";
import { useOnMount } from "../base/hooks/lifecycle.hooks";
import { useControllers } from "../base/hooks/useRootController.hook";
import { reportError } from "../base/utils/errors.utils";
import { generateUuid } from "../base/utils/id.utils";
import { useProps, useStore } from "../base/utils/mobx.utils";
import { ModelName } from "../constants/modelNames.enum";

export type UseAutoGetOptions<DataType = object, ObservableType = object> = {
  observable?: ObservableType,
  key?: keyof ObservableType & string,
  modelName: ModelName,
  url?: string,
  asIndex?: boolean,
  allowCache?: boolean,
  getFromLocalById?: Nullable<string>,
  onDataFetch?: (result: DataType) => unknown,
  onError?: (e: any) => any,
  finally?: () => any,
  when?: () => any,
  defaultValue?: DataType,
  lazy?: boolean,
  replaceItemsInOriginalArray?: boolean,
  replaceExisting?: boolean,
}
export type UseAutoGetFactoryOptions<DataType = object, ObservableType = object> = Omit<UseAutoGetOptions<DataType,ObservableType>, 'modelName'>;

export function useAutoGet<
  DataType = object, 
  ObservableType = object, 
>(
  options: UseAutoGetOptions<DataType, ObservableType>,
) {

  const { API } = useControllers();
  const p = useProps({ url: options.url });

  const s = useStore(() => ({
    id: generateUuid(),
    data: (options.defaultValue ?? null) as Nullable<DataType>,
  }))

  useOnMount(() => {
    const { observable, key, modelName, onDataFetch, onError, replaceItemsInOriginalArray } = options;
    let { lazy } = options;
    if (lazy === undefined) lazy = false;
    const bootstrap = (fireImmediately?: boolean) => {
      const getModelData = flow(function* () {
        try {
          const asSingle = !options?.asIndex || !!options.getFromLocalById;
          const data: DataType = asSingle ?
            yield API.get(p.url!, modelName, { getFromLocalById: options.getFromLocalById, replaceExisting: options.replaceExisting })
            : ((yield API.getMany(p.url!, modelName, { replaceExisting: options.replaceExisting }))).entries;
          if (!data) return null;
          s.data = data;
          if (observable && key) {
            if (replaceItemsInOriginalArray && observable[key] instanceof Array && data instanceof Array) {
              (observable[key] as unknown as Array<any>).splice(0);
              (observable[key] as unknown as Array<any>).push(...data);
            } else {
              observable[key] = data as any;
            }
          }
          onDataFetch && onDataFetch(data);
        } catch (e) {
          reportError(e);
          if (onError) onError(e);
          else {
            throw e;
          }
        } finally {
          options.finally?.();
        }
      })
      if (fireImmediately || !isObservableObject(observable)) {
        getModelData();
      } else {
        if (observable && key) {
          onBecomeObserved(observable, key, getModelData);
        } else {
          getModelData();
        }
      }
    };
    if (options.when) {
      when(
        () => !!p.url && options.when!(),
        () => bootstrap(true)
      );
    } else {
      if (p.url) {
        bootstrap(!lazy);
      } else {
        when(() => !!p.url, () => bootstrap(true));
      }
    }
  })

}