import { action, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseHeading from '../../../../../base/components/BaseHeading/BaseHeading';
import BaseSpacer from '../../../../../base/components/BaseSpacer/BaseSpacer';
import { ContextColor } from '../../../../../base/constants/color.enum';
import { useOnMount } from '../../../../../base/hooks/lifecycle.hooks';
import { makeForm } from '../../../../../base/mediators/form.mediator';
import { useProps, useStore } from '../../../../../base/utils/mobx.utils';
import ContactEditor from '../../../../../components/ContactEditor/ContactEditor';
import InvitationInfoEditor from '../../../../../components/InvitationInfoEditor/InvitationInfoEditor';
import UserInfoEditor from '../../../../../components/UserInfoEditor/UserInfoEditor';
import { ApplicationTypeOption } from '../../../../../constants/counsellingTypes.descriptors';
import { ContactSnapshot } from '../../../../../models/makeContact.model';
import { InvitationSnapshot } from '../../../../../models/makeInvitation.model';
import { UserSnapshot } from '../../../../../models/makeUser.model';
import { isOfCounsellingTypeYoungPeople, isOneToOneCounselling } from '../../../../../utils/counsellingApplication.utils';
import { CounsellingApplicationStepProps } from '../applicationStepDescriptors';
import './CounsellingApplicationStepReviewPersonalInfo.scss';

interface CounsellingApplicationStepReviewPersonalInfoProps extends CounsellingApplicationStepProps { }

const CounsellingApplicationStepReviewPersonalInfo: React.FC<CounsellingApplicationStepReviewPersonalInfoProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    applicantForm: makeForm(p.form.applicant, {
      editableFields: ['firstName', 'lastName', 'gender', 'sexuality', 'countryProvidedId'],
    }),
    invitationForm: makeForm(p.form.invitation, {
      editableFields: ['dateOfBirth', 'gender', 'name', 'sexuality', 'email']
    }),
    emergencyContactForm: makeForm(p.form.applicantEmergencyContact, {
      editableFields: ['name', 'email', 'phone'],
      validators: {
        email: (v) => {
          if (v === s.applicantForm.get('email')) {
            return {
              isValid: false,
              message: "Please ensure your emergency contact's email is not your email.",
            }
          }
          return !!v ? true : {
            isValid: false,
            message: "Please fill in your emergency contact's email.",
          };
        },
        phone: (v) => {
          if (v === s.applicantForm.get('mobileNumber')) {
            return {
              isValid: false,
              message: "Please ensure your emergency contact's phone number is not your phone number.",
            }
          }
          return !!v ? true : {
            isValid: false,
            message: "Please fill in your emergency contact's phone.",
          };
        },
      }
    }),
    handleApplicationOnSave: action((applicant: UserSnapshot) => {
      Object.assign(p.form.applicant, applicant);
      if (!p.form.applicantAddress.countryId && p.form.applicant.countryProvidedId) {
        p.form.applicantAddress.countryId = p.form.applicant.countryProvidedId;
      }
    }),
    handleInvitationOnSave: action((invitation: InvitationSnapshot) => {
      const { dateOfBirth, gender, name, sexuality, email } = invitation;
      Object.assign(p.form.invitation, { dateOfBirth, gender, name, sexuality, email });
    }),
    handleEmergencyContactOnSave: action((ec: ContactSnapshot) => {
      const { name, email, phone } = ec;
      Object.assign(p.form.applicantEmergencyContact, { name, email, phone });
    }),
    get isYoungPeopleApplication() {
      return isOfCounsellingTypeYoungPeople(p.form.selectedApplicationType)
    },
    get isCouplesApplication() {
      return p.form.selectedApplicationType === ApplicationTypeOption.Couples;
    },
    get isOneToOneApplication() {
      return isOneToOneCounselling(p.form.selectedApplicationType);
    },
  }));

  useOnMount(() => {
    return reaction(
      () => p.form.invitation.dateOfBirth,
      value => s.invitationForm.fields.dateOfBirth.value = value
    )
  })

  return <Observer children={() => (
    <div className="CounsellingApplicationStepReviewPersonalInfo">
      <blockquote>
        Please review { s.isOneToOneApplication ? 'your' : 'the' } information below and update where necessary.
      </blockquote>
      <BaseSpacer />
      <BaseHeading color={ContextColor.Primary}>Applicant Information</BaseHeading>
      <div className="formWrapper">
        <UserInfoEditor
          form={s.applicantForm}
          applyChangesOnBlur
          onBlur={s.handleApplicationOnSave}
          onSave={s.handleApplicationOnSave}
          requireEmail
          requireMobileNumber
        />
      </div>
      <BaseSpacer />
      <BaseHeading color={ContextColor.Primary}>Emergency Contact Information</BaseHeading>
      <div className="formWrapper">
        <ContactEditor
          // form={s.emergencyContactForm as Form<ContactSnapshot>}
          contact={p.form.applicantEmergencyContact}
          applyChangesImmediately
          includeInputEmail
          // onSave={s.handleEmergencyContactOnSave}
        />
      </div>
      {
        !s.isOneToOneApplication && p.form.application.availabilityId && <>
          <BaseSpacer />
          <BaseHeading color={ContextColor.Primary}>
            { s.isCouplesApplication ? 'Partner' : s.isYoungPeopleApplication ? 'Child' : 'Invitee' } Information
          </BaseHeading>
          <div className="formWrapper">
            <InvitationInfoEditor
              form={s.invitationForm}
              applyChangesOnBlur
              onBlur={s.handleInvitationOnSave}
              onSave={s.handleInvitationOnSave}
              forType={p.form.selectedApplicationType}
            />
          </div>
        </>
      }
    </div>
  )} />
}

export default CounsellingApplicationStepReviewPersonalInfo;