import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useGetDataAttributes } from '../../hooks/useGetDataAttributes';
import joinClassName from '../../utils/className.utils';
import './SimpleCard.scss';

interface SimpleCardProps {
  className?: string;
  elevation?: number;
  padding?: number | string;
  background?: string;
  foreground?: string;
  primaryColorName?: string;
  onClick?: () => void;
  dataCy?: string;
}

const SimpleCard: React.FC<SimpleCardProps> = p => {
  const dataAttributes = useGetDataAttributes(p);
  return <Observer children={() => (
    <div
      className={joinClassName('SimpleCard', p.className)}
      style={{
        boxShadow: `0 ${.38 * (p.elevation ?? 1)}em ${1 * (p.elevation ?? 1)}em rgba(0,0,0,0.1)`,
        padding: p.padding,
        background: p.background,
        color: p.foreground
      }}
      {...dataAttributes}
      data-primary-color={p.primaryColorName}
      onClick={p.onClick}
      data-cy={p.dataCy}
    >
      {p.children}
    </div>
  )} />
}

export default SimpleCard;