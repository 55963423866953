import { Nillable, StandardModel } from "../base/@types";
import { CountryCode } from "../base/constants/countries.constants";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { ModelName } from "../constants/modelNames.enum";
import { hasTimestamps } from "../traits/hasTimestamps.trait";
import { User } from "./makeUser.model";

export type AddressSnapshot = ReturnType<typeof makeAddressSnapshotBase>;

export type Address = StandardModel<AddressSnapshot, AddressRelatedModels, AddressExtendedProperties>;

export type AddressRelatedModels = {
  user: User,
}
export type AddressExtendedProperties = {
  readonly fullAddress: string,
}
export const makeAddressSnapshotBase = () => ({
  id: '',
  lineOne: '',
  lineTwo: '' as Nillable<string>,
  city: '',
  region: '' as Nillable<string>, // equivalent of county, province, state etc.
  countryId: '' as CountryCode,
  postcode: '' as Nillable<string>,
  userId: '',
  ...hasTimestamps(),
})

export const makeAddress = createStandardModelFactory<Address, AddressRelatedModels, AddressExtendedProperties>({

  name: ModelName.addresses,

  snapshotFactory: makeAddressSnapshotBase,

  relationshipsSchema: {
    user: ModelName.users,
  },

  extendedPropertiesFactory: (s, $, localDB) => ({
    get fullAddress() {
      return [s.lineOne, s.lineTwo, s.city, s.region, s.postcode].filter(i => i).join(', ');
    },
  }),
  
})