import React from 'react';
import { Nillable } from '../../base/@types';
import { useColorStyle } from '../../base/hooks/useColorStyle.hook';
import joinClassName from '../../base/utils/className.utils';
import './ColorLabelDot.scss';

type P = Partial<{
  color?: Nillable<string>;
}>;

const ColorLabelDot: React.FC<P> = props => {
  const hasColor = !!props.color; 
  const style = useColorStyle(props, 'backgroundColor');
  return <span className={joinClassName("ColorLabelDot", !hasColor && 'noColor')} style={style}></span>
}

export default ColorLabelDot;