import { action, flow, observable, reaction, when } from "mobx";
import { PresenceChannel } from "pusher-js";
import { Room } from "twilio-video";
import { ChatWindowState } from "../../components/ChatWindow/ChatWindow";
import OverlayFeedbackForm from "../../components/OverlayFeedbackForm/OverlayFeedbackForm";
import OverlaySurveySupportGroupSatisfaction, { ID_OverlaySurveySupportGroupSatisfaction } from "../../components/OverlaySurveySupportGroupSatisfaction/OverlaySurveySupportGroupSatisfaction";
import UsernameRenderer from "../../components/UsernameRenderer/UsernameRenderer";
import { ApiModelName } from "../../constants/ApiModels.enum";
import { ModelName } from "../../constants/modelNames.enum";
import { AuthController } from "../../controllers/auth.controller";
import { CLOCK } from "../../controllers/common/clock.controller";
import { MessengerController } from "../../controllers/messenger.controller";
import { isInCypressTestMode, IS_DEV, SHOULD_LOG } from "../../env";
import { ChatMessage, ChatMessageSnapshot } from "../../models/makeChatMessage.model";
import { ChatParticipant, ChatParticipantSnapshot } from "../../models/makeChatParticipant.model";
import { ChatThread, ChatThreadAssociatedModel, getChatThreadType } from "../../models/makeChatThread.model";
import { CounsellingSession } from "../../models/makeCounsellingSession.model";
import { FeedbackTargetModelNames } from "../../models/makeFeedback.model";
import { User } from "../../models/makeUser.model";
import { endThread } from "../../requests/endThread.request";
import { getChatThread } from "../../requests/getChatThread.request";
import { inviteChatParticipant } from "../../requests/inviteChatParticipant.request";
import { patchChatThread } from "../../requests/patchChatThread.request";
import { sendOrEditMessageRequest } from "../../requests/sendOrEditMessage.request";
import { endCounsellingSession } from "../../requests/startOrEndCounsellingSession.request";
import { endSupportGroup } from "../../requests/startOrEndSupportGroup.request";
import { getUser } from "../../requests/user.requests";
import { groupMessagesByParticipants, MessageGroup } from "../../utils/chatMessage.utils";
import { canMarkAsBillable } from '../../utils/invoices.utils';
import { manageSupportGroup } from "../../utils/supportGroup.helpers";
import { AnyObject, ColorCodedState, FixMeAny, HasId, Nillable, Nullable, Undefinable } from "../@types";
import { ChannelInstance } from "../@types/channels.types";
import { ChatThreadPrivateChannelTemplate } from "../channels/chatThread.presenceChannel";
import BaseSpacer from "../components/BaseSpacer/BaseSpacer";
import BaseToggle from "../components/BaseToggle/BaseToggle";
import ErrorRenderer from "../components/ErrorRenderer/ErrorRenderer";
import ShadedBlock from "../components/ShadedBlock/ShadedBlock";
import { ChatParticipantEndpoints } from "../endpoints/chatParticipant.endpoints";
import { makeActionConfig } from "../utils/actionConfig.utils";
import { addToArrayIfNew, asyncForEach, keepTruthy, mergeIntoArray, removeFromArray } from "../utils/array.utils";
import { navigateToChatPage } from "../utils/chat.utils";
import { debounce } from "../utils/debounce.utils";
import { equalByString } from "../utils/equality.utils";
import { reportError } from "../utils/errors.utils";
import { LOGGER } from "../utils/logger.utils";
import { copyWithJSON, getProp, getValueOfKey } from "../utils/object.utils";
import { uniq } from "../utils/ramdaEquivalents.utils";
import { enforceStringIdOnSnapshot } from "../utils/snapshot.utils";
import { autoPluralize } from "../utils/string.utils";
import { getDurationFromUTCTimeStamps, getNowTimestampUtc, millisecondsToMinutes } from "../utils/time.utils";
import { getTrustToken } from "../utils/trust.utils";
import { setUrlParam } from "../utils/urlParams.utils";
import tick from "../utils/waiters.utils";

export type ChatType = 'counselling-session' | 'support-group' | 'default' | 'support';

/**
 * The controller that provides functionalities for chat threads.
 */
export interface ChatMediator {
  id: string,
  uuid: string,
  timeStarted: string,
  timeEnded: string | null,
  join: () => Promise<boolean>;
  type: ChatType,
  thread: ChatThread,
  associatedModel?: ChatThreadAssociatedModel,
  participants: ChatParticipant[],
  messages: ChatMessage[],
  messagesGroupedByParticipants: MessageGroup[],
  duration: number,
  onlineUserIds: string[],
  typingUserIds: string[],
  selfParticipant?: ChatParticipant,
  inviteParticipantByUserId: (userId: string) => Promise<boolean>,
  pushThreadUpdate: (thread: ChatThread) => unknown,
  pushParticipantUpdate: (participant: ChatParticipant) => unknown,
  pushMessageUpdate: (message: ChatMessage) => unknown,
  sendMessage: (body: string) => Promise<boolean>;
  editMessage: (messageId: string, newBody: string) => Promise<boolean>;
  sendTypingIndication: (value: boolean) => Promise<boolean>;
  saveParticipantIdentity: (identity: string) => Promise<void>,

  // video related
  toggleVideoChatAsStaff: () => Promise<boolean>,
  videoChatRoom: Nillable<Room>,
  shouldEnableAudio: boolean,
  disableAudio: () => void,
  enableAudio: () => void,
  shouldEnableVideo: boolean,
  disableVideo: () => void,
  enableVideo: () => void,

  currentUserIsNotAParticipant: boolean,
  viewAssociatedModelDetails?: () => void,
  canViewAssociatedModelDetails: any,
  endChatAsStaff: (options?: { force?: boolean }) => Promise<boolean>;
  hasEnded: boolean;
  chatWindowInstances: Set<ChatWindowState>,
  registerChatWindow: (state: ChatWindowState) => void,
  shouldKeepInDock: boolean,
  shouldOpenInDock: boolean,
  keepInDock: () => void,
  openInDock: () => void,
  removeFromDock: () => void,
  toggleShouldOpenInDock: () => void,

  refresh: () => Promise<ChatMediator>;
  dispose: () => Promise<boolean>;

}

export function makeChatMediator(
  MESSENGER: MessengerController,
  thread: ChatThread,
) {

  const rootStore = MESSENGER.ROOT;
  const { API, AUTH, COMMON, TRANSCEIVER, UI, LOCALDB, VIDEO, NAVIGATOR } = rootStore!.children;

  const logger = (
    forType: 'thread' | 'message' | 'participant' | 'generic' | 'error',
    ...message: string[]
  ) => {
    const colorMap = {
      generic: '',
      thread: '#009FE3',
      message: '#ED8E1E',
      participant: '#6868E2',
      error: 'red',
    }
    LOGGER.log({
      domain: ['ChatController', thread.id],
      message,
      color: colorMap[forType],
    });
  }

  const __ = observable((() => ({
    channel: null as ChannelInstance | null,
    thread,
    get type() {
      return getChatThreadType(thread);
    },
    get associatedModel() {
      return thread.model;
    },
    get participants() {
      return thread.participants || [];
    },
    get messages() {
      return thread.messages || [];
    },
    get messagesGroupedByParticipants() {
      return groupMessagesByParticipants(__.messages, __.participants)
    },
    get currentUser() {
      return AUTH.currentUser;
    },
    get selfParticipant() {
      return __.participants.find(p => equalByString(p.user?.id, __.currentUser?.id));
    },
    get currentUserIsFacilitatorOfAssociatedModel() {
      return __.associatedModel && __.currentUser && (
        equalByString(__.currentUser.id, getProp('facilitatorId', __.associatedModel))
        || equalByString(__.currentUser.id, getProp('counsellorId', __.associatedModel))
      );
    },
    get currentUserIsNotAParticipant() {
      return Boolean(AUTH.currentUser && __.participants.length > 0 && !__.selfParticipant);
    },
    get currentUserCanFacilitate() {
      return AUTH.can.supportGroups_.facilitate_.someUserGroups;
    },
    onlineUserIds: [] as string[],
    typingUserIds: [] as string[],
    get isEmailThread() {
      return getProp('type', __.associatedModel) === 'email';
    },
    get videoChatRoom() { return VIDEO.getRoomByChat(c) },
  }))())

  function join() {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        joinChatThreadChannel();
        await getChatData();
        resolve(true);
      } catch (e) {
        reject(e);
      }
    })
  }

  function getChatData() {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const threadSnapshot = await getChatThread(
          API,
          thread.id,
          { include: ['participants', 'messages', 'users', 'model'] }
        );
        if (!threadSnapshot) {
          throw Error(`Chat thread ${thread.id} not found.`);
        }
        resolve(true);
      } catch(e) {
        reportError(e);
        UI.TOAST.present({
          heading: `We were unable to retrieve latest chat session messages.`,
          body: 'It is likely due to a network problem. To make sure that you are seeing all the new messages, you might want to reload the app.',
          colorCodedState: ColorCodedState.attention,
          action: function () { window.location.reload() }
        });
      }
    })
  }

  const joinChatThreadChannel = flow(function * () {
    const channel = yield TRANSCEIVER.initChannel<PresenceChannel, HasId>(ChatThreadPrivateChannelTemplate, { id: thread.id });
    __.channel = channel;
    const { instance } = channel;
    // @ts-ignore
    instance.here(action(e => {
      logger('thread', 'Users present:');
      SHOULD_LOG() && console.log(e);
      mergeIntoArray(__.onlineUserIds, e.map((u: { id: string, username: string }) => u.id + ''));
    }))
    // @ts-ignore
    instance.joining(action(e => {
      logger('thread', 'User joined:');
      SHOULD_LOG() && console.log(e);
      mergeIntoArray(__.onlineUserIds, e.id + '');
    }))
    // @ts-ignore
    instance.leaving(action(e => {
      logger('thread', 'User left:');
      SHOULD_LOG() && console.log(e);
      removeFromArray(__.onlineUserIds, e.id + '');
    }))
    // @ts-ignore
    instance.listen('.thread.update', e => {
      SHOULD_LOG() && console.log('thread updated');
      pushThreadUpdate(e.thread);
    });
    // @ts-ignore
    instance.listen('.message.update', e => {
      pushMessageUpdate(e.message);
    });
    // @ts-ignore
    instance.listen('.participant.update', e => {
      SHOULD_LOG() && console.log('chat mediator received .participant.update', e);
      pushParticipantUpdate(e.participant);
    });
    // @ts-ignore
    instance.listenForWhisper('typing', action(e => {
      try {
        SHOULD_LOG() && console.log('received typing whisper', e);
        if (e.value) mergeIntoArray(__.typingUserIds, e.userId + '');
        else {
          removeFromArray(__.typingUserIds, e.userId + '');
        }
      } catch(e) {
        logger('error', 'Failed to parse typing indication signal.');
      }
    }))
  });

  const sendMessage = (body: string) => new Promise<boolean>(flow(
    function* (resolve, reject) {
      if (!__.selfParticipant) {
        reject('Trying to send a message when the current user is not in the participant list');
        return;
      }
      if (thread.timeEnded) {
        reject('The thread has been ended, it is not possible to send any more messages.');
        return;
      }
      if (__.selfParticipant.timeMuted) {
        reject('Muted participants are not allowed to send messages.');
        return;
      }
      if (__.selfParticipant.timeRemoved) {
        reject('Removed participants are not allowed to send messages.');
        return;
      }
      try  {
        const savedMessage = yield sendOrEditMessageRequest(API, {
          threadId: thread.id,
          participantId: __.selfParticipant.id,
          body
        });
        addToArrayIfNew(thread.messages, savedMessage);
        resolve(true);
      } catch(e) {
        reject(e);
      }
    }
  ));

  const editMessage = (messageId: string, newBody: string) => new Promise<boolean>(flow(
    function * (resolve, reject) {
      if (!__.currentUserCanFacilitate) {
        reject('Current user does not have permission to edit messages.');
        return;
      }
      if (thread.timeEnded) {
        reject('The thread has been ended, it is not possible to edit more messages.');
        return;
      }
      try {
        yield sendOrEditMessageRequest(API, {
          messageId,
          body: newBody
        });
        resolve(true);
      } catch(e) {
        reject(e);
      }
    }
  ))

  const toggleVideoChatAsStaff = () => new Promise<boolean>(flow(function * (resolve, reject) {
    if (!AUTH.isStaff) return;
    try {
      const payload = copyWithJSON(thread.$snapshot);
      const shouldEnable = !payload.timeVideoStarted || !!payload.timeVideoEnded;
      if (shouldEnable) {
        // should start
        const canStart = VIDEO.canStartVideoChat(c);
        if (!canStart) {
          UI.DIALOG.attention({
            heading: 'Only one active video chat is allowed.',
            body: <>
              <p>If you do need to have several chats at the same time, you can open a separate tab or use a different device.</p>
              <p>Otherwise, please stop other calls before starting this one.</p>
            </>,
          })
          return;
        }
        payload.timeVideoStarted = CLOCK.nowUtcTimestamp;
        yield patchChatThread(API, payload);
        // yield VIDEO.connectToChat(c);
      } else {
        const confirm = yield UI.DIALOG.present({
          heading: 'Are you sure you want to end this video chat?',
          body: 'The session itself will remain open. If you intend to end the session altogether, use the "End Session" button.',
        })
        if (!confirm) {
          resolve(false);
          return;
        }
        thread.timeVideoStarted = '';
        payload.timeVideoStarted = '';
        yield patchChatThread(API, payload);
        VIDEO.disconnectFromChat(c);
      }
      resolve(true);
    } catch(e) {
      reject(e);
    }
  }))

  const saveParticipantIdentity = (identity: string) => new Promise<void>(
    flow(function* (resolve, reject) {
      SHOULD_LOG() && console.log('saveParticipantIdentity: ', identity);
      if (!c.selfParticipant) {
        yield when(() => !!c.selfParticipant, { timeout: 3000, onError: e => {
          reject('Only participants can join the video chat.');
        }});
        return;
      }
      const url = ChatParticipantEndpoints.own.update(c.selfParticipant.id);
      c.selfParticipant.twilioIdentities.push(identity);
      const payload: Pick<ChatParticipantSnapshot, 'id' | 'twilioIdentities'> = {
        id: c.selfParticipant.id,
        twilioIdentities: uniq(c.selfParticipant.twilioIdentities),
      };
      yield API.patch(url, ModelName.chatParticipants, payload);
      resolve();
    })
  )

  const sendTypingIndication = (value: boolean) => new Promise<boolean>((resolve, reject) => {
    if (!__.selfParticipant) {
      throw Error('Trying to send a typing indication when the current user is not in the participant list');
    }
    try {
      logger('generic', 'sending typing indication');
      (__.channel?.instance as FixMeAny)?.whisper('typing', {
        userId: __.selfParticipant.userId,
        value,
      });
      resolve(true);
    } catch(e) {
      reject(e);
    }
  })

  function inviteParticipantByUserId(userId: string) {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const existingParticipant = thread.participants.find(part => part.userId === userId);
        if (existingParticipant) {
          resolve(true);
          return;
        }
        await inviteChatParticipant(API, thread.id, userId);
        resolve(true);
      } catch (error) {
        reject(error)
      }
    })
  }

  async function pushThreadUpdate(snapshot: AnyObject) {
    logger('thread', 'Thread update received')
    SHOULD_LOG() && console.log(snapshot);
    thread.$patch(enforceStringIdOnSnapshot(snapshot) as ChatThread);
  }

  const pushParticipantUpdate = action((_snapshot: AnyObject) => {
    logger('participant', 'Participant update received')
    SHOULD_LOG() && console.log(_snapshot);
    const snapshot = enforceStringIdOnSnapshot(_snapshot);
    const existingParticipant = thread.participants?.find(p => equalByString(p.id, snapshot.id));
    if (existingParticipant) {
      existingParticipant.$patch(snapshot);
    } else {
      const participantModel = LOCALDB.setOrMerge<ChatParticipant>(ModelName.chatParticipants, snapshot as ChatParticipantSnapshot);
      thread.participants?.unshift(participantModel);
      if (AUTH.isStaff || getValueOfKey<any>('isCounsellor', AUTH)) {
        if (snapshot.user || snapshot.userId) {
          UI.TOAST.toast(<><span>User <UsernameRenderer user={snapshot.user} userId={snapshot.userId} /> has joined chat session #{thread.id}.</span></>);
        } else {
          UI.TOAST.toast(<span>A new user has joined chat session #{thread.id}.</span>);
        }
      }
    }
    getChatData();
  })

  const pushMessageUpdate = action((_snapshot: AnyObject) => {
    IS_DEV && logger('message', 'Message update received')
    SHOULD_LOG() && console.log(_snapshot);
    const snapshot = enforceStringIdOnSnapshot(_snapshot);
    const existingMessage = thread.messages?.find(m => equalByString(m.id, snapshot.id));
    if (existingMessage) {
      SHOULD_LOG() && console.log('existing message found');
      existingMessage.$patch(snapshot);
    } else {
      SHOULD_LOG() && console.log('creating new message model');
      const message = LOCALDB.setOrMerge<ChatMessage>(ModelName.chatMessages, snapshot as ChatMessageSnapshot);
      SHOULD_LOG() && console.log(message);
      addToArrayIfNew(thread.messages, message);
    }
  });

  const disposeDeviceOnlineStatusWatcher = reaction(() => COMMON.online, onlineStatus => {
    if (onlineStatus) {
      IS_DEV && logger('generic','Device online, retrieving latest messages');
      getChatData();
    } else {
      IS_DEV && logger('generic','Device offline, disabling features');
    }
  })

  const showFeedbackFormOverlay = (
    modelType: FeedbackTargetModelNames,
    modelId: string,
  ) => {
    UI.OVERLAY.present({
      name: 'OverlayFeedbackForm',
      className: 'OverlayViewFeedbackForm',
      component: <OverlayFeedbackForm forModelType={modelType} forModelId={modelId} color={modelType === ApiModelName.COUNSELLING_SESSION ? 'skyblue' : 'blueGreen'}/>,
      appearance: UI.fromTablet ? 'card' : 'sheet',
    })
  }
  const showOverlaySurveySupportGroupSatisfaction = (groupId: string) => {
    UI.OVERLAY.present({
      name: ID_OverlaySurveySupportGroupSatisfaction,
      component: <OverlaySurveySupportGroupSatisfaction groupId={groupId} />,
    })
  }

  let disposerAutoExitWhenKicked = null as Nullable<Function>;

  if (!thread.timeEnded) {
    disposerAutoExitWhenKicked = reaction(
      () => !!__.selfParticipant?.timeRemoved,
      () => {
        UI.DIALOG.attention({
          heading: 'You have been removed from the chat session.',
        })
        c.removeFromDock();
        if (NAVIGATOR.isInAdminArea) {
          if (window.location.href.includes('/admin/chats/')) {
            NAVIGATOR.navigateTo('/admin/chats/')
          }
        } else {
          if (window.location.href.includes('/app/chats/')) {
            NAVIGATOR.navigateTo('/app/chats/')
          }
        }
        dispose();
      }
    )
    const disposeTimeEndedReaction = reaction(
      () => !!thread.timeEnded,
      debounce(async () => {
        const { modelId, modelType } = thread;
        SHOULD_LOG() && console.log({
          ...thread.$snapshot,
        })
        if (thread.model) {
          thread.model.timeEnded = getNowTimestampUtc();
        }
        const modelCanReceiveFeedback = [ApiModelName.COUNSELLING_SESSION].includes(modelType as ApiModelName);
        const canFacilitate = getProp('canFacilitate', AUTH);
        const isClient = !canFacilitate;
        if (isClient) {
          await UI.DIALOG.present({
            heading: modelType === ApiModelName.SUPPORT_GROUP ? "The support group has been ended by the facilitator." : (
              modelType === ApiModelName.COUNSELLING_SESSION ? "The counselling session has been ended by the counsellor." : (
                "The chat session has been ended."
              )
            )
          })
        }
        if (modelCanReceiveFeedback && isClient) {
          showFeedbackFormOverlay(modelType as ApiModelName.COUNSELLING_SESSION, modelId);
        }
        if (modelType === ApiModelName.SUPPORT_GROUP && isClient) {
          showOverlaySurveySupportGroupSatisfaction(modelId);
        }
        if (__.currentUserIsFacilitatorOfAssociatedModel) {
          promptCounsellorToAddNotesAtEndOfSession();
        }
        c.removeFromDock();
        if (UI.onlyPhones) {
          if (window.location.pathname.match(new RegExp(`^/app/chats/${c.id}`))) {
            NAVIGATOR.navigateTo('/app/chats');
          }
        }
        disposeTimeEndedReaction();
      }, { timeout: 1000, id: `chatMediator_disposeTimeEndedReaction_${thread.id}` })
    );
  }

  const promptCounsellorToAddNotesAtEndOfSession = () => {
    const { modelId, modelType } = thread;
    if (!modelId || !modelType) return;
    if (__.type === 'counselling-session' && __.isEmailThread) return;
    UI.DIALOG.present({
      heading: `The session has ended.`,
      body: 'Please update the session to include notes, issues, or any problems you or the client(s) have identified.',
      // body: 'Would you like to add notes to the session? If so, click "yes" to open the editor.' ,
      actions: [
        // makeCancelAction('No'),
        makeActionConfig('Open Editor', viewAssociatedModelDetails)
      ]
    })
  }

  const viewAssociatedModelDetails = () => {
    const { modelId, modelType } = thread;
    switch (modelType!) {
      case ApiModelName.COUNSELLING_SESSION: {
        editCounsellingSession(modelId!);
        break;
      }
      case ApiModelName.SUPPORT_GROUP: {
        editSupportGroup(modelId!);
        break;
      }
      default: {
        break;
      }
    }
  }

  const editCounsellingSession = (id: string) => (
    setUrlParam('manageSessionId', id, NAVIGATOR)
  );
  const editSupportGroup = (id: string) => (
    manageSupportGroup(UI, id)
  );

  const endAssociatedSession = (payeeIds: string[], hasAnyClientAttended?: boolean) => new Promise<boolean>(async (resolve, reject) => {
    try {
      const { modelId, modelType } = thread;
      switch (modelType) {
        case ApiModelName.COUNSELLING_SESSION: {
          endCounsellingSession(API, modelId, payeeIds.length > 0, hasAnyClientAttended ?? false);
          break;
        }
        case ApiModelName.SUPPORT_GROUP: {
          endSupportGroup(API, modelId, payeeIds);
          break;
        }
        default: {
          break;
        }
      }
      resolve(true);
    } catch(e) {
      reject(e);
    }
  })

  const endChatAsStaff = ( options?: { force?: boolean } ) => new Promise<boolean>(
    async (resolve, reject) => {
      const { modelType } = thread;
      const form = observable({
        hasAnyClientAttended: true,
        payees: [] as { user: User, isPayable: boolean, canTogglePayable: boolean }[],
      })
      if (c.type === 'counselling-session') {
        const session = c.associatedModel as CounsellingSession;
        if (
          c.messages.some(m => m.participant?.userId && session.clientIds.includes(m.participant.userId))
          || (c.thread.timeVideoStarted && millisecondsToMinutes(c.duration) > 15)
        ) {
          form.hasAnyClientAttended = true;
        }
        if (session?.counsellor) {
          form.payees.push({
            user: session.counsellor!,
            isPayable: canMarkAsBillable(session, AUTH),
            canTogglePayable: canMarkAsBillable(session, AUTH),
          })
        }
      } else if (c.type === 'support-group') {
        await asyncForEach(thread.participants.map(part => async () => await getUser(part.userId, API)));
        form.payees.push(...(uniq(keepTruthy((thread.participants ?? []).map(part => part.user).filter(user => user?.isStaff) ?? [])) as User[]).map(u => ({ user: u, isPayable: true, canTogglePayable: true })));
      }
      const confirm = (__.isEmailThread || options?.force || c.participants.length <= 1) ? true : await UI.DIALOG.present({
        name: 'end-thread-confirm',
        heading: 'Are you sure you want to end this session?',
        body: () => <>
          <p>All participants will leave this session immediately.</p>
          {modelType === ApiModelName.COUNSELLING_SESSION && <>
            <p>The {autoPluralize((c.associatedModel as Undefinable<CounsellingSession>)?.clients ?? [], 'client', 'clients', 'clients', true)} will be asked to fill in a quick feedback form.</p>
            <BaseSpacer size=".5em" />
            <ShadedBlock>
              <BaseToggle form={form} field="hasAnyClientAttended">The {autoPluralize((c.associatedModel as Undefinable<CounsellingSession>)?.clients ?? [], 'client', 'clients', 'clients', true)} has attended session</BaseToggle>
              {form.payees[0] && <BaseToggle form={form.payees[0]} field="isPayable" disabled={!form.payees[0].canTogglePayable}>Mark this session as billable for counsellor <UsernameRenderer user={form.payees[0].user} /></BaseToggle>}
            </ShadedBlock>
          </>}
          {modelType === ApiModelName.SUPPORT_GROUP && <>
            <p>They will also be asked to fill in a quick feedback form.</p>
            <BaseSpacer size=".5em" />
            <ShadedBlock spaceChildren>
              <p><strong>Mark this support group as payable for the following staff members in the group:</strong></p>
              <div>
                {form.payees.map(p => <BaseToggle key={p.user.id} form={p} field="isPayable" label={<UsernameRenderer user={p.user} />}/>) }
              </div>
            </ShadedBlock>
          </>}
        </>,
        colorCodedState: ColorCodedState.attention
      });
      if (!confirm) {
        resolve(false);
        return;
      }
      thread.timeEnded = getNowTimestampUtc();
      await endAssociatedSession(form.payees.filter(p => p.isPayable).map(p => p.user.id), form.hasAnyClientAttended).catch(e => {
        reject(e);
        UI.DIALOG.error({
          heading: 'Unable to end the session',
          body: () => <>
            <p>Please contact the development team for support.</p>
            <ErrorRenderer error={(e as any).response} />
          </>,
        })
      });
      await endThread(API, thread).catch(e => {
        reject(e);
      });
      dispose();
      resolve(true);
    }
  );

  const disposeAutoCloseWhenNotAParticipant = when(
    () => __.currentUserIsNotAParticipant,
    async () => {
      await tick(500);
      if (!__.currentUserIsNotAParticipant) return;
      if (AUTH.isStaff || (AUTH as AuthController).isCounsellor) {
        UI.TOAST.present({
          heading: 'You are viewing the chat records of a chat session that you are not a participant of.',
          colorCodedState: ColorCodedState.attention,
        })
      } else {
        c.dispose();
      }
    },
  )

  let disposeAutomaticVideoRoomDisconnectWhenNoWindowOpen = null as Nullable<Function>;

  const disconnectFromVideoChat = () => {
    if (c.videoChatRoom) VIDEO.disconnectFromChat(c);
  }

  let alreadyConnectingToVideo = false;
  const disposeAutoConnectVideo = reaction(
    () => [thread.timeVideoStarted, __.selfParticipant?.id, c.chatWindowInstances.size].join(' '),
    flow(function * () {
      if (alreadyConnectingToVideo) return;
      if (!c.videoChatRoom &&
        thread.timeVideoStarted &&
        !thread.timeVideoEnded &&
        !!__.selfParticipant &&
        !thread.timeEnded &&
        c.chatWindowInstances.size > 0 &&
        !c.selfParticipant?.timeDeclinedVideoCall
      ) {
        alreadyConnectingToVideo = true;
        yield VIDEO.connectToChat(c, getTrustToken());
        alreadyConnectingToVideo = false;
      }
    }),
    { fireImmediately: true }
  )

  const disposeAutoDisconnectVideo = reaction(
    () => [thread.timeEnded, thread.timeVideoStarted, thread.timeVideoEnded, __.selfParticipant?.id].join(' '),
    () => {
      SHOULD_LOG() && console.log('disconnecting video');
      UI.DIALOG.dismiss('JoinVideoCallConfirmation');
      if (c.videoChatRoom && (!thread.timeVideoStarted || thread.timeEnded || thread.timeVideoEnded || !__.selfParticipant)) {
        VIDEO.disconnectFromChat(c);
      }
    }
  )

  const disposeAutoAudioOffWhenRemotelyMuted = reaction(
    () => c.selfParticipant?.timeMuted,
    value => value && c.disableAudio(),
    { fireImmediately: true }
  )

  function dispose() {
    return new Promise<boolean>((resolve, reject) => {
      try {
        c.removeFromDock();
        __.channel?.disconnect();
        UI.DIALOG.dismiss('JoinVideoCallConfirmation');
        disconnectFromVideoChat();
        disposeDeviceOnlineStatusWatcher();
        disposeAutoCloseWhenNotAParticipant();
        disposeAutoConnectVideo();
        disposeAutoDisconnectVideo();
        disposeAutomaticVideoRoomDisconnectWhenNoWindowOpen?.();
        disposeAutoAudioOffWhenRemotelyMuted();
        disposerAutoExitWhenKicked?.();
        removeFromArray(MESSENGER.chats, c);
        VIDEO.cleanupLocalTrack();
        resolve(true);
      } catch (e) {
        reject(e);
      }
    })
  }

  const c: ChatMediator = observable({
    get id() { return thread.id },
    get uuid() { return thread.uuid },
    get timeStarted() { return thread.timeStarted },
    get timeEnded() { return thread.timeEnded },
    get duration() { return getDurationFromUTCTimeStamps(c.timeStarted, c.timeEnded)},
    get type() { return __.type; },
    get thread() { return thread; },
    get associatedModel() {
      return thread.model
    },
    get participants() { return __.participants; },
    get messages() { return __.messages; },
    get messagesGroupedByParticipants() { return __.messagesGroupedByParticipants; },
    get onlineUserIds() { return __.onlineUserIds },
    get typingUserIds() { return __.typingUserIds },
    get selfParticipant() { return __.selfParticipant },
    get currentUserIsNotAParticipant() {
      return __.currentUserIsNotAParticipant;
    },
    join,
    inviteParticipantByUserId,
    sendTypingIndication,

    pushThreadUpdate,
    pushParticipantUpdate,
    pushMessageUpdate,
    sendMessage,
    editMessage,

    // video related
    toggleVideoChatAsStaff,
    get videoChatRoom() { return __.videoChatRoom },
    saveParticipantIdentity,

    shouldKeepInDock: false,
    shouldOpenInDock: false,
    keepInDock: action(() => {
      c.shouldKeepInDock = true;
      c.shouldOpenInDock = true;
    }),
    openInDock: action(() => {
      if (!c.shouldKeepInDock) {
        c.keepInDock();
      } else {
        c.shouldOpenInDock = true;
      }
    }),
    removeFromDock: action(() => {
      c.shouldKeepInDock = false
      c.shouldOpenInDock = false;
    }),
    toggleShouldOpenInDock: action(() => {
      c.shouldOpenInDock = !c.shouldOpenInDock;
    }),

    get shouldEnableAudio() { return VIDEO.shouldEnableAudio },
    set shouldEnableAudio(v) { VIDEO.shouldEnableAudio = v },
    disableAudio: action(() => {
      SHOULD_LOG() && console.log('disabling audio')
      c.videoChatRoom?.localParticipant.audioTracks.forEach(publication => {
        if (publication.track.kind === 'audio') {
          publication.track.disable();
          if (VIDEO.shouldFullyCloseMicrophoneWhenDisableAudio) publication.track.stop();
        }
      });
      c.shouldEnableAudio = false;
    }),
    enableAudio: action(function () {
      if (c.selfParticipant?.timeMuted) return;
      SHOULD_LOG() && console.log('enabling audio');
      c.videoChatRoom?.localParticipant.audioTracks.forEach(publication => {
        if (!publication.track.isEnabled) {
          if (VIDEO.shouldFullyCloseMicrophoneWhenDisableAudio) publication.track.restart();
          publication.track.enable();
        }
      });
      c.shouldEnableAudio = true;
    }),
    get shouldEnableVideo() { return VIDEO.shouldEnableVideo },
    set shouldEnableVideo(v) { VIDEO.shouldEnableVideo = v },
    disableVideo: action(() => {
      SHOULD_LOG() && console.log('disabling video');
      c.videoChatRoom?.localParticipant.videoTracks.forEach(publication => {
        if (publication.track.kind === 'video') {
          publication.track.disable();
          publication.track.stop();
        }
      });
      VIDEO.shouldEnableVideo = false;
    }),
    enableVideo: action(function () {
      SHOULD_LOG() && console.log('enabling video');
      // if (!VIDEO.localTracks.find(t => t.kind === 'video' && t.isEnabled)) {
      //   const tracksCreated = yield VIDEO.createLocalTracks({ audio: false, video: true })
      //   c.videoChatRoom?.localParticipant.publishTracks(tracksCreated);
      // }
      c.videoChatRoom?.localParticipant.videoTracks.forEach(publication => {
        if (!publication.track.isEnabled) {
          publication.track.restart();
          publication.track.enable();
        }
      });
      VIDEO.shouldEnableVideo = true;
    }),

    viewAssociatedModelDetails,
    get canViewAssociatedModelDetails() {
      return thread.model && (__.currentUserIsFacilitatorOfAssociatedModel || AUTH.isStaff)
    },

    endChatAsStaff,

    get hasEnded() {
      return !!thread.timeEnded;
    },

    chatWindowInstances: new Set<ChatWindowState>(),
    registerChatWindow: action((state: ChatWindowState) => {
      if (c.chatWindowInstances.size === 0) {
        disposeAutomaticVideoRoomDisconnectWhenNoWindowOpen = reaction(
          () => c.chatWindowInstances.size === 0,
          size => {
            SHOULD_LOG() && console.log('No active window, disabling video chat');
            c.videoChatRoom?.disconnect();
          }
        )
      }
      c.chatWindowInstances.add(state);
      return () => c.chatWindowInstances.delete(state);
    }),

    refresh: async () => {
      MESSENGER.getChatData(c.id);
      return c;
    },
    dispose,

  })

  c.join();

  (async function() {
    await tick(2000);
    if (UI.onlyPhones && c.chatWindowInstances.size === 0 && !c.hasEnded && !isInCypressTestMode) {
      UI.TOAST.present({
        heading: <>You have a live {c.thread.timeVideoStarted ? 'video' : ''} chat session in the background. Tap here to open the chat.</>,
        action: () => {
          navigateToChatPage(NAVIGATOR, c.id);
        },
        timeout: 0,
      })
    }
  })();

  return c;

}
