import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { EagerRouteDef } from '../../base/@types';
import RouterStack from '../../base/components/RouterStack/RouterStack';
import SplashScreen from '../../base/components/SplashScreen/SplashScreen';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useClearPageBackground } from '../../base/hooks/useSetPageBackground.hook';
import { useStore } from '../../base/utils/mobx.utils';
import AppNavigation, { NavTabConfig } from '../../components/AppNavigation/AppNavigation';
import Turn2MeLogo from '../../components/Turn2MeLogo/Turn2MeLogo';
import { TopLevelModuleName } from '../../constants/topLevelModuleName.enum';
import { useRegisterTopLevelModule } from '../../hooks/useRegisterTopLevelModule';
import { AdminModuleRouteDef } from './Admin.module.routes';
import { AdminDashboardModuleRouteDef } from './AdminDashboard/AdminDashboard.module.routes';
import './ModuleAdmin.scss';
import AdminNavControlSet from './_components/AdminNavControlSet/AdminNavControlSet';
import AdminPanelIcon from './_components/AdminPanelIcon/AdminPanelIcon';

interface ModuleAdminProps {}

const ModuleAdmin: React.FC<ModuleAdminProps> = props => {

  const { NAVIGATOR, STAFF } = useControllers();

  useClearPageBackground();
  useRegisterTopLevelModule(TopLevelModuleName.admin)

  const s = useStore(() => ({
    children: [] as EagerRouteDef[],
    getTabConfig: (identifier: string) => s.children.find(r => r.identifier === identifier),
    get navTabs() {
      return [
        { def: s.getTabConfig('admin-dashboard'), icon: 'blocks' },
        ...(STAFF.canViewManageModule ? [{ def: s.getTabConfig('admin-manage'), icon: 'notebook' }] : []),
        {
          def: s.getTabConfig('chat'),
          icon: 'chat',
          // show unread message count
          // badgeNumberGetter: () => MESSENGER.numberOfActiveChats,
        },
        ...(STAFF.canUseStatModule ? [{ def: s.getTabConfig('admin-statistics'), icon: 'stats' }] : []),
      ] as NavTabConfig[]
    },

    get childrenLoaded() {
      return s.children.length > 0;
    },
    shouldShowSplashscreen: NAVIGATOR.locationHistory.length > 1,
  }))

  useOnMount(() => {
    flow(function* () {
      s.children = (yield AdminModuleRouteDef.children()).default;
    })();
  })

  return <Observer children={() => (
    <div className="ModuleAdmin">
      {s.childrenLoaded && <div className="ModuleAdminInner">
        <AppNavigation
          tabConfigs={s.navTabs}
          prefix={AdminModuleRouteDef.urlFactory()}
          header={
            <NavLink className="ModuleAdminHomeButton" to="/admin/dashboard">
              <AdminPanelIcon />
            </NavLink>
          }
          controls={AdminNavControlSet}
        />
        <RouterStack
          routes={s.children}
          prefix={AdminModuleRouteDef.urlFactory()}
          fallback={AdminDashboardModuleRouteDef}
        />
      </div>}
      {s.shouldShowSplashscreen && <SplashScreen identifier="ModuleAdmin" className="AdminSplashScreen" contentHasLoaded={s.childrenLoaded}>
        <Turn2MeLogo areaTag="Admin" />
      </SplashScreen>}
    </div>
  )} />

}

export default ModuleAdmin;