import { ModelName } from "../../constants/modelNames.enum";
import { SurveyGAD7 } from "../../models/makeSurveyGAD7.model";
import { SurveyGeneral } from "../../models/makeSurveyGeneral.model";
import { SurveyGoalSheet } from "../../models/makeSurveyGoalSheet.model";
import { SurveyPHQ9 } from "../../models/makeSurveyPHQ9.model";
import { SurveySatisfaction } from "../../models/makeSurveySatisfaction.model";
import { SurveySupportGroupNonAttendance } from "../../models/makeSurveySupportGroupNonAttendance";
import { SurveySupportGroupSatisfaction } from "../../models/makeSurveySupportGroupSatisfaction";
import { AnyObject } from "../@types";
import { DefaultIndexParamSet } from "../utils/api.utils";
import { makeApiEndpointConfig, makeApiGetEndpointConfig } from "../utils/endpoints.utils";
import { prefixOwn, prefixStaff } from "./namespacePrefixes";

export type SurveyEndpointParams<T extends Survey, R extends AnyObject> = DefaultIndexParamSet<T, R>;
export type SurveyModelName = ModelName.surveysGAD7 | ModelName.surveysPHQ9 | ModelName.surveysGoalSheet | ModelName.surveysSatisfaction | ModelName.surveysGeneral;
export type SurveySubtype = 'gad7' | 'phq9' | 'general' | 'goal-sheet' | 'satisfaction' | 'support-group-satisfaction' | 'support-group-non-attendance';
export type Survey = SurveyGAD7 | SurveyPHQ9 | SurveyGoalSheet | SurveySatisfaction | SurveyGeneral | SurveySupportGroupNonAttendance | SurveySupportGroupSatisfaction;

const urlBaseFactory = (subtype: SurveySubtype) => `surveys/${subtype}`;

export const SurveyEndpointsFactory = <T extends Survey, R extends AnyObject = {}>(subtype: SurveySubtype) => ({
  /**
   * currently it is not possible to edit surveys once they have been created
   */
  staff: {
    // @ts-ignore ! For indexing, API does not prefix endpoint with 'support-'.
    index: makeApiEndpointConfig<SurveyEndpointParams<T, R>>(prefixStaff(urlBaseFactory(subtype.replace('support-', '')))),
    get: makeApiGetEndpointConfig<SurveyEndpointParams<T, R>>(prefixStaff(urlBaseFactory(subtype))),
    create: () => prefixStaff(urlBaseFactory(subtype)),
  },
  own: {
    create: () => prefixOwn(urlBaseFactory(subtype)),
  }
})

export const makeSurveyEndpointFactory = <T extends Survey>(subtype: SurveySubtype) => SurveyEndpointsFactory<T>(subtype);

export const SurveyGAD7Endpoints = makeSurveyEndpointFactory<SurveyGAD7>('gad7');
export const SurveyPHQ9Endpoints = makeSurveyEndpointFactory<SurveyPHQ9>('phq9');
export const SurveyGeneralEndpoints = makeSurveyEndpointFactory<SurveyGeneral>('general');
export const SurveyGoalSheetEndpoints = makeSurveyEndpointFactory<SurveyGoalSheet>('goal-sheet');
export const SurveySatisfactionEndpoints = makeSurveyEndpointFactory<SurveySatisfaction>('satisfaction');
export const SurveySupportGroupNonAttendanceEndpoints = makeSurveyEndpointFactory<SurveySupportGroupNonAttendance>('support-group-non-attendance');
export const SurveySupportGroupSatisfactionEndpoints = makeSurveyEndpointFactory<SurveySupportGroupSatisfaction>('support-group-satisfaction');
