import { runInAction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseButton from '../../../../base/components/BaseButton/BaseButton';
import ErrorRenderer from '../../../../base/components/ErrorRenderer/ErrorRenderer';
import ShadedBlock from '../../../../base/components/ShadedBlock/ShadedBlock';
import { UserEndpoints } from '../../../../base/endpoints/user.endpoints';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import { reportError } from '../../../../base/utils/errors.utils';
import { getNowTimestampUtc } from '../../../../base/utils/time.utils';
import { ModelName } from '../../../../constants/modelNames.enum';
import { User } from '../../../../models/makeUser.model';

interface UserSuspensionManagerProps {
  user: User,
  onSuccess?: () => void,
  disabled?: boolean,
}

const UserSuspensionManager: React.FC<UserSuspensionManagerProps> = p => {

  const { UI, API } = useControllers();

  const confirmSuspense = () => {
    if (p.disabled) return;
    UI.DIALOG.attention({
      heading: 'Are you sure you want to suspend this user?',
      defaultActions: ['negative'],
      actions: [
        {
          label: 'Confirm',
          action: () => new Promise<boolean>(async (resolve, reject) => {
            try {
              const url = UserEndpoints.staff.update(p.user.id);
              const now = getNowTimestampUtc();
              const payload = {
                timeSuspended: now,
              }
              await API.patch(url, ModelName.users, payload);
              p.onSuccess && p.onSuccess();
              runInAction(() => {
                p.user.timeSuspended = now;
              })
              resolve(true);
            } catch(e) {
              reportError(e);
              UI.DIALOG.error({
                heading: 'An error occurred.',
                error: e
              })
            }
          })
        }
      ]
    })
  } 

  const unban = () => {
    if (p.disabled) return;
    UI.DIALOG.attention({
      heading: 'Are you sure you want to lift the account restrictions?',
      body: 'The user can resume using all turn2me services.',
      defaultActions: ['negative'],
      actions: [
        {
          label: 'Confirm',
          action: () => new Promise<boolean>(async (resolve, reject) => {
            try {
              const url = UserEndpoints.staff.update(p.user.id);
              const payload = {
                timeSuspended: null,
              }
              await API.patch(url, ModelName.users, payload);
              p.onSuccess && p.onSuccess();
              runInAction(() => {
                p.user.timeSuspended = null;
              })
              resolve(true);
            } catch (e) {
              reject(e);
              UI.DIALOG.error({
                heading: 'An error occurred.',
                body: <ErrorRenderer error={(e as any).response} />
              })
            }
          })
        }
      ]
    })
  }

  return <Observer children={() => (
    <ShadedBlock className="UserSuspensionManager" color="red" spaceChildren>
      {
        p.user.timeSuspended ? <>
          <h3>Account Suspended</h3>
          <p>This account has been suspended (banned).</p>
          <BaseButton icon="forbidden" fullWidth onClick={unban} disabled={p.disabled} dataCy="removeAccount">Remove restrictions</BaseButton>
        </> : <>
            <h3>Suspend account</h3>
            <p>Please use this feature only when the user is displaying disruptive / abusive / illegal behaviours. You might also want to report related incidences the administrator.</p>
            <BaseButton icon="forbidden" fullWidth onClick={confirmSuspense} disabled={p.disabled} dataCy="suspendAccount">Suspend account</BaseButton>
          </>
      }
    </ShadedBlock>
  )} />

}

export default UserSuspensionManager;