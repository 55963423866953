import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ValidatorResult } from '../../base/@types';
import BaseGrid from '../../base/components/BaseGrid/BaseGrid';
import FormForm from '../../base/components/FormForm/FormForm';
import FormInput from '../../base/components/FormInput/FormInput';
import FormTelInput from '../../base/components/FormTelInput/FormTelInput';
import { isStandardModel } from '../../base/factories/standardModel.factory';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { makeForm } from '../../base/mediators/form.mediator';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { getSnapshot } from '../../base/utils/snapshot.utils';
import { Contact, ContactSnapshot, ContactType, makeContactSnapshotBase } from '../../models/makeContact.model';
import './ContactEditor.scss';

interface ContactEditorProps {
  className?: string,
  // form?: Form<ContactSnapshot>,
  contact?: Contact | ContactSnapshot,
  onSave?: (contactSnapshot: ContactSnapshot) => void,
  saveChangesOnBlur?: boolean,
  applyChangesImmediately?: boolean,
  usePublicAPIValidation?: boolean,
  doNotUseAPIValidation?: boolean,
  doNotUseOnMountGetUserDialCode?: boolean,
  doNotUseDetectedDialCode?: boolean,
  disabled?: boolean,
  required?: boolean,
  includeInputEmail?: boolean,
  onValidatePhone?: (isValid: ValidatorResult) => void,
}

const ContactEditor: React.FC<ContactEditorProps> = React.memo(props => {

  const { ANALYTICS } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    get required() {
      return typeof p.required == "boolean" ? p.required : true
    },
    form: makeForm<ContactSnapshot>(p.contact ? getSnapshot(p.contact) : makeContactSnapshotBase()),
    // form: p.form ?? makeForm<ContactSnapshot>(p.contact ? getSnapshot(p.contact) : makeContactSnapshotBase()),
    handleSave: () => {
      p.onSave && p.onSave(s.form.value);
    },
    pushChanges: action(() => {
      isStandardModel(p.contact) ? p.contact.$patch(s.form.value) : Object.assign(p.contact, s.form.value);
    }),
    handleChange: () => {
      if (p.contact && p.applyChangesImmediately) {
        s.pushChanges();
      }
    },
    handleBlur: action(() => {
      if (p.saveChangesOnBlur) {
        s.handleSave();
        s.form.markAsSaved();
      }
    })
  }));

  useOnMount(() => {
    const snapshot = isStandardModel<Contact>(p.contact) ? p.contact.$getSnapshot() : p.contact;
    s.form.reset(snapshot);
    if (!s.form.value.id && !s.form.value.phone && !p.doNotUseDetectedDialCode) {
      ANALYTICS.getUserDialCode().then(code => {
        if (!s.form.value.phone) s.form.set('phone', code);
      })
    }
  })

  return <Observer children={() => (
    <FormForm
      className={joinClassName('ContactEditor', p.className)}
      form={s.form}
      onBlur={s.handleBlur}
      disabled={p.disabled}
      onChange={s.handleChange}
      children={context => <BaseGrid columns={context.resizeQuery?.fromPhoneLg ? 2 : 1}>
        <FormInput field="name" label="Full Name" name="contact-name" required={s.required} />
        <FormTelInput field="phone" label="Phone" name="contact-phone" required={s.required} usePublicAPIValidation={p.usePublicAPIValidation} doNotUseAPIValidation={p.doNotUseAPIValidation} doNotUseOnMountGetUserDialCode={p.doNotUseOnMountGetUserDialCode} onValidate={p.onValidatePhone} />
        {(p.includeInputEmail || p.contact?.type === ContactType.KeyWorker) && <FormInput field="email" label="Email" type="email" name="contact-email" required={s.required} />}
      </BaseGrid>}
    />
  )} />

})

export default ContactEditor;