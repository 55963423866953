import { StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { isObject } from "../base/utils/typeChecks.utils";
import { ModelName } from "../constants/modelNames.enum";
import { hasTimestamps } from "../traits/hasTimestamps.trait";

export type MediaItemSnapshot = ReturnType<typeof makeMediaItemSnapshot>;
export type MediaItemRelatedModels = {};
export type MediaItemExtendedProperties = {};

export type MediaCollectionName = 'images'
// | 'identity-verification';
export type MediaVisibility = 'private' | 'public';

export const makeMediaItemSnapshot = () => ({
  collection_name: '' as MediaCollectionName,
  conversions_disk: '',
  customProperties: [],
  disk: '',
  fileName: '',
  id: '',
  manipulations: [],
  mimeType: '',
  modelId: '' as string | null,
  modelType: '' as string | null,
  name: '',
  orderColumn: 0,
  responsiveImages: [],
  size: 0,
  uuid: '',
  fullUrl: '',
  temporary_url: '',
  ...hasTimestamps(),
})

/**
 * @deprecated
 * Currently not in use in V2.
 */
export type MediaItem = StandardModel<MediaItemSnapshot, MediaItemRelatedModels, MediaItemExtendedProperties>;

export const makeMediaItem = createStandardModelFactory<MediaItem, MediaItemRelatedModels, MediaItemExtendedProperties>({
  name: ModelName.mediaItems,
  snapshotFactory: makeMediaItemSnapshot,
})

export function isMediaItem(m: any): m is MediaItem {
  const hasKey = (key: string) => key in m;
  return m && isObject(m) && (
    hasKey('full_url')
    && hasKey('temporary_url')
    && hasKey('id')
    && hasKey('collection_name')
    // && hasKey('visibility')
  )
}