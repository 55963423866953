import { Observer } from 'mobx-react-lite';
import React, { Suspense } from 'react';
import { Form } from '../../mediators/form.mediator';
import joinClassName from '../../utils/className.utils';
import { useProps } from '../../utils/mobx.utils';
import { BaseWysiwygProps } from '../BaseWysiwyg/BaseWysiwyg';
import { useFormContext } from '../FormForm/Form.context';
// import './FormWysiwyg.scss';

const BaseWysiwyg = React.lazy(() => import('../BaseWysiwyg/BaseWysiwyg'));

type FormWysiwygProps<T extends object = object> = Omit<BaseWysiwygProps<T>, 'form'> & {
  form: Form<T>,
}

function FormWysiwyg<T extends object = object>(
  props: React.PropsWithChildren<FormWysiwygProps<T>>
) {
  const context = useFormContext<T>();
  const p = useProps(props);
  return <Observer children={() => (
    <Suspense fallback={<></>}>
      {
        <BaseWysiwyg {...p} 
          className={joinClassName(
          'FormWysiwyg',
          p.className,
        )}
          form={p.form.fields[p.field]}
          field="value"
          name={p.name ?? p.field}
          disabled={p.disabled || context.disabled}
        />
      }
    </Suspense>
  )} />
}

export default FormWysiwyg;