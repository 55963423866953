import { SupportGroupEndpoints } from "../base/endpoints/supportGroup.endpoints";
import { ApiModelName } from "../constants/ApiModels.enum";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { SupportGroup } from "../models/makeSupportGroup.model";
import { createInvoiceItem } from "./invoicing.requests";

export const startSupportGroup = (API: APIController, supportGroupId: string) => {
  return new Promise<SupportGroup>(
    async (resolve, reject) => {
      try {
        const url = SupportGroupEndpoints.staff.start(supportGroupId);
        const savedGroup = await API.post<SupportGroup>(url, ModelName.supportGroups);
        if (!savedGroup) throw Error('Failed to start support group.')
        resolve(savedGroup);
      } catch(e) {
        reject(e);
      }
    }
  )
}

export const endSupportGroup = (API: APIController, supportGroupId: string, payableStaffIds: string[]) => {
  return new Promise<SupportGroup>(
    async (resolve, reject) => {
      try {
        const url = SupportGroupEndpoints.staff.end(supportGroupId);
        const savedGroup = await API.post<SupportGroup>(url, ModelName.supportGroups);
        if (!savedGroup) throw Error('Failed to end support group.')
        if (payableStaffIds) {
          for (let staffId of payableStaffIds) {
            await createInvoiceItem(API, staffId, ApiModelName.SUPPORT_GROUP, savedGroup?.id);
          }
        }
        resolve(savedGroup);
      } catch(e) {
        reject(e);
      }
    }
  )
}