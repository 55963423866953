import OverlayCompanyEditor from "../components/OverlayCompanyEditor/OverlayCompanyEditor"
import { UIController } from "../controllers/ui.controller"
import { Company } from "../models/makeCompany.model"

export const viewCompanyInOverlay = (
  UI: UIController,
  company?: Company,
  companyId?: string,
) => {
  UI.OVERLAY.present({
    id: `OverlayCompanyEditor#${company?.id ?? companyId}`,
    name: 'OverlayCompanyEditor',
    component: <OverlayCompanyEditor company={company} companyId={companyId} />,
    duplicateStrategy: 'abort',
    width: '50em',
    noBackdrop: true,
  })
}