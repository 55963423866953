import {flow} from 'mobx';
import {Observer} from 'mobx-react-lite';
import React from 'react';
import {submitFlag} from '../../actions/submitFlag.action';
import {ColorCodedState} from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseSpacer from '../../base/components/BaseSpacer/BaseSpacer';
import MenuToggle from '../../base/components/MenuToggle/MenuToggle';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import {IconName} from '../../base/components/Symbols/iconDefs';
import {ThoughtEndpoints} from '../../base/endpoints/thought.endpoints';
import {useControllers, useUIController} from '../../base/hooks/useRootController.hook';
import {makeActionConfig} from '../../base/utils/actionConfig.utils';
import joinClassName from '../../base/utils/className.utils';
import {copyString} from '../../base/utils/dom.utils';
import {NoOp} from '../../base/utils/functions.utils';
import {useProps, useStore} from '../../base/utils/mobx.utils';
import {autoPluralize} from '../../base/utils/string.utils';
import {getUrlParams, setUrlParam, setUrlParams} from '../../base/utils/urlParams.utils';
import {ApiModelName} from '../../constants/ApiModels.enum';
import {ModelName} from '../../constants/modelNames.enum';
import {ActionConfig} from '../../controllers/ui/ui.controller.types';
import {Thought, ThoughtSnapshot} from '../../models/makeThought.model';
import InteractionCommentControlSet from '../InteractionCommentControlSet/InteractionCommentControlSet';
import InteractionReactionControlSet from '../InteractionReactionControlSet/InteractionReactionControlSet';
import './ThoughtControlsSet.scss';

interface ThoughtControlsSetProps {
    thought: Thought,
    onToggleCommentManager?: () => void;
    onMenuOpen?: () => void,
    onMenuClose?: () => void,
    disableInteractions?: boolean,
    neverShowManageButton?: boolean,
}

const ThoughtControlsSet: React.FC<ThoughtControlsSetProps> = props => {

    const p = useProps(props);

    const {AUTH, API, THOUGHT_CATCHER, NAVIGATOR, UI} = useControllers();
    const {DIALOG} = useUIController();

    const s = useStore(() => ({
        get isOwnThought() {
            return AUTH.currentUser?.id === p.thought.userId;
        },
        get canManage() {
            return AUTH.isModerator;
        },
        get isInManageOverlay() {
            return getUrlParams().manageThoughtId === p.thought.id;
        },
        manageThought: () => {
            if (!s.canManage) return;
            setUrlParam('manageThoughtId', p.thought.id, NAVIGATOR);
        },
        get canCopyLink() {
            return p.thought.isPublic || s.isOwnThought || s.canManage;
        },
        get menuActions(): ActionConfig[] {
            return [
                ...(s.canCopyLink && AUTH.isModerator) ? [
                    {
                        name: 'copy-link',
                        label: 'Copy Link',
                        icon: 'share' as IconName,
                        action: () => {
                            const link = `${window.location.origin}/app/explore?thoughtId=${p.thought.id}`;
                            try {
                                copyString(link);
                                UI.TOAST.success('Link copied!');
                            } catch (e) {
                                UI.DIALOG.present({
                                    heading: 'Thought Link',
                                    body: <>
                                        <p>We didn't seem to be able to copy the link automatically. You can copy the
                                            link manually below:</p>
                                        <ShadedBlock>
                                            <code><a href={link} title="Thought Link" target="_blank"
                                                     rel="noreferrer">{link}</a></code>
                                        </ShadedBlock>
                                    </>,
                                    defaultActions: ['positive'],
                                });
                            }
                        }
                    },
                ] : [],
                ...(s.isOwnThought || AUTH.isModerator) ? [
                    {
                        name: 'edit',
                        label: 'Edit Thought',
                        icon: 'pencil' as IconName,
                        action: () => {
                            setUrlParams({
                                'editThoughtId': p.thought.id,
                            }, NAVIGATOR);
                        }
                    }
                ] : [],
                ...(s.isOwnThought && !p.thought.isPrivate) ? [
                    {
                        name: 'toggle-state',
                        label: `Switch to Private`,
                        icon: 'lock' as IconName,
                        action: async () => {
                            try {
                                await THOUGHT_CATCHER.toggleThoughtPrivateState(p.thought, 'private');
                            } catch (e) {
                                UI.DIALOG.error({heading: 'Failed to change thought status', error: e});
                            }
                        }
                    }
                ] : [],
                ...(AUTH.isModerator) ? [
                    {
                        name: 'toggle-state',
                        label: `Switch to ${p.thought.isPrivate ? 'Public' : 'Private'}`,
                        icon: p.thought.isPrivate ? 'unlock' : 'lock' as IconName,
                        action: async () => {
                            if (p.thought.isPrivate && p.thought.timeHidden) {
                                if (AUTH.isModerator) {
                                    UI.DIALOG.present({
                                        heading: <>This thought was hidden.</>,
                                        body: <>
                                            <p>Either the thought was hidden by automated filters, or a moderator has
                                                chosen to hide it.</p>
                                            <p>If you wish to change it to public, you will also mark this thought as
                                                safe and viewable to the public. If so, it's a good idea to also check
                                                the comments before you make the switch.</p>
                                        </>,
                                        defaultActions: ['negative'],
                                        actions: [{
                                            label: 'Mark as safe & switch to public',
                                            action: () => {
                                                const payload: Partial<ThoughtSnapshot> = {
                                                    id: p.thought.id,
                                                    isSafe: true,
                                                    timeHidden: '',
                                                    isPrivate: false,
                                                }
                                                const url = ThoughtEndpoints.staff.update(p.thought.id);
                                                API.patch(url, ModelName.thoughts, payload);
                                            }
                                        }]
                                    })
                                } else {
                                    UI.DIALOG.present({
                                        heading: <>This thought cannot be switched to public.</>,
                                        body: <>
                                            <p>Private thoughts are only viewable to yourself and our counsellors and
                                                moderators.</p>
                                            {p.thought.timeHidden &&
                                                <p>Since this thought was hidden by either our system or our moderators,
                                                    it will remain private. If you think it has been hidden by mistake,
                                                    please let us know.</p>}
                                        </>,
                                        actions: [
                                            makeActionConfig('OK', NoOp),
                                        ]
                                    })
                                }
                                return;
                            }
                            try {
                                await THOUGHT_CATCHER.toggleThoughtPrivateState(p.thought);
                            } catch (e) {
                                UI.DIALOG.error({heading: 'Failed to change thought status', error: e});
                            }
                        }
                    }
                ] : [],
                ...(s.isOwnThought || AUTH.isModerator) ? [
                    {
                        name: 'delete',
                        label: 'Delete Thought',
                        icon: 'delete' as IconName,
                        colorCodedState: ColorCodedState.alert,
                        action: flow(function* () {
                            const confirm = yield DIALOG.attention({
                                heading: 'Are you sure you want to delete this thought?',
                                defaultActions: ['negative', 'positive']
                            })
                            if (!confirm) return;
                            try {
                                yield THOUGHT_CATCHER.deleteThought(p.thought);
                            } catch (e) {
                                UI.DIALOG.error({heading: 'Failed to delete thought :(', error: e});
                            }
                        })
                    }
                ] : [],
                ...(!s.isOwnThought && !p.thought.timeDeleted) ? [{
                    name: 'flag',
                    label: 'Flag...',
                    icon: 'flag' as IconName,
                    colorCodedState: ColorCodedState.alert,
                    action: () => {
                        submitFlag(p.thought, API);
                    }
                }] : [],
                ...(s.canManage && !s.isInManageOverlay) ? [
                    {
                        name: 'manage',
                        label: 'Manage',
                        icon: 'cog' as IconName,
                        action: () => {
                            s.manageThought();
                        }
                    }
                ] : [],
            ]
        },
    }));

    return <Observer children={() => (
        <div
            className={joinClassName("ThoughtControlsSet", (p.disableInteractions || p.thought.timeDeleted) && 'disableInteractions')}>
            <div>
                <InteractionReactionControlSet
                    model={p.thought}
                    replyToModelType={ApiModelName.THOUGHT}
                    replyToModelId={p.thought.id}
                />
                <BaseSpacer size=".62em"/>
                <InteractionCommentControlSet
                    comments={p.thought.comments}
                    onClick={p.onToggleCommentManager}
                />
                {
                    p.thought.flags.length > 0 && AUTH.canModerate && <>
                        <BaseSpacer size=".62em"/>
                        <strong
                            className="ThoughtControlsSetFlagCounter">Flagged {p.thought.flags.length === 1 ? 'once' : autoPluralize(p.thought.flags, 'times')}</strong>
                    </>
                }
            </div>
            {!p.disableInteractions && !p.thought.timeDeleted && <div className="ThoughtControlsSetIconButtonList">
                {
                    AUTH.isAuthenticated && (
                        <MenuToggle actions={s.menuActions} onMenuOpen={p.onMenuOpen} onMenuClose={p.onMenuClose}/>
                    )
                }
                {s.canManage && !p.neverShowManageButton &&
                    <BaseButton dataCy="ThoughtControlsMenu" appearance="icon" rounded color="foreground"
                                onClick={s.manageThought} icon="cog"/>}
            </div>}
        </div>
    )}/>
}

export default ThoughtControlsSet;