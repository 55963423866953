import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject } from '../../base/@types';
import { useFormContext } from '../../base/components/FormForm/Form.context';
import { Form } from '../../base/mediators/form.mediator';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import CompanySelector, { CompanySelectorProps } from '../CompanySelector/CompanySelector';
// import './FormCompanySelector.scss';

type FormCompanySelectorProps<T extends AnyObject = AnyObject> = Omit<CompanySelectorProps, 'form'> & {
  form?: Form<T>,
}

function FormCompanySelector<T extends AnyObject = AnyObject>(
  props: React.PropsWithChildren<FormCompanySelectorProps<T>>
) {

  const context = useFormContext<T>();
  const { form } = context;
  const p = useProps(props);
  const s = useStore(() => ({
    get form() { return p.form ?? form },
  }));

  return <Observer children={() => (
    <CompanySelector {...p}
      className={joinClassName('FormCompanySelector', p.className)}
      form={s.form.fields[p.field]}
      field="value"
      name={p.name ?? p.field}
      disabled={p.disabled || context.disabled}
    />
  )} />
}

export default FormCompanySelector;