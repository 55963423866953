import { first } from "@amcharts/amcharts4/.internal/core/utils/Array";
import { action, flow, observable, reaction } from "mobx";
import { Alert } from '../@types/alert.types';
import { GenericFunction } from "../base/@types";
import { AlertEndpoints } from "../base/endpoints/alert.endpoints";
import { sortByTimeCreatedLatestFirst } from "../base/utils/array.utils";
import { runAll } from "../base/utils/functions.utils";
import { immediateReaction } from "../base/utils/mobx.utils";
import { createUTCMoment, getNowTimestampUtc, minutes } from "../base/utils/time.utils";
import tick, { doEvery, runAfter } from "../base/utils/waiters.utils";
import { ModelName } from "../constants/modelNames.enum";
import { SHOULD_LOG } from "../env";
import { cleanUpStaleAlerts } from "./alert/cleanUpStaleAlerts";
import { makeToastConfigFromAlert } from "./alert/makeToastConfigFromAlert";
import { APIGetManyResponse } from "./api.controller";
import { CLOCK } from "./common/clock.controller";
import { makeControllerBase, makeRootControllerChildInitFn } from "./_root.controller";

/**
 * Manages alerts (notifications), displayed in UI as "toasts" (also name as snackbars, notification cards).
 */

export type AlertsController = ReturnType<typeof makeAlertsController>;

export const makeAlertsController = () => {

  const c = observable({
    ...makeControllerBase('ALERTS'),
    get alerts(): Alert[] {
      return Array.from(c.ROOT!.children.LOCALDB.data.alerts.values());
    },
    get totalLength(): number {
      return c.alerts.length;
    },
    get unreadAlerts(): Alert[] {
      return sortByTimeCreatedLatestFirst(c.alerts.filter(n => n.isUnread)) as Alert[];
    },
    get numberOfUnreadAlerts(): number {
      return c.unreadAlerts.length;
    },
    notifyViaToast: async (n: Alert) => {
      const { TOAST } = c.ROOT!.children.UI;
      TOAST.present(await makeToastConfigFromAlert(n, c.ROOT!.children.NAVIGATOR, c.ROOT!.children.ALERTS));
    },
    dismiss: action((n: Alert) => {
      c.ROOT!.children.LOCALDB.remove(ModelName.alerts, n);
      c.markAsRead(n);
    }),
    firstDataRetrieved: false,
    getLatestData: () => flow(function * () {
      if (!c.ROOT!.children.AUTH.isAuthenticated) return;
      const url = AlertEndpoints.own.index({ unread: true });
      (yield c.ROOT!.children.API.getMany(url, ModelName.alerts)) as APIGetManyResponse<Alert>;
      c.firstDataRetrieved = true;
      c.cleanUpStaleAlerts();
    })(),
    timeControllerLoaded: getNowTimestampUtc(),
    markAsRead: async (n: Alert) => {
      await tick(500);
      const url = AlertEndpoints.own.update(n.id);
      n.timeRead = getNowTimestampUtc();
      const payload: Partial<Alert> = { timeRead: n.timeRead };
      const savedNotif = await c.ROOT!.children.API.patch(url, ModelName.alerts, payload);
      SHOULD_LOG() && console.log(`savedNotif ${savedNotif?.id} marked as read.`);
    },
    markManyAsRead: async (ns: Alert[]) => {
      for (let n of ns) {
        await tick(50);
        c.markAsRead(n);
      }
    },
    markAllAsRead: async () => {
      const url = AlertEndpoints.own.markAllAsRead();
      c.alerts.forEach(a => a.timeRead = CLOCK.nowUtcTimestamp);
      await c.ROOT!.children.API.postRaw(url);
    },
    cleanUpStaleAlerts: () => {
      cleanUpStaleAlerts(c);
    },
    reset: flow(function* (invokedFromRoot?: boolean) {
      yield runAll(disposers);
      disposers.splice(0);
      c.firstDataRetrieved = false;
      c.timeControllerLoaded = getNowTimestampUtc();
    }),
  })

  const disposers = [] as GenericFunction[];

  c.init = makeRootControllerChildInitFn(
    c,
    () => {  
      immediateReaction(
        () => c.ROOT!.children.AUTH.isAuthenticated,
        value => {
          if (!value) return;
          runAfter(c.getLatestData, 3000);
        }
      )
      disposers.push(reaction(
        () => first(c.unreadAlerts),
        n => {
          SHOULD_LOG() && console.log('New unread Alert model constructed:', n);
          if (!n) return;
          if (createUTCMoment(n.timeCreated).isAfter(createUTCMoment(c.timeControllerLoaded))) {
            c.notifyViaToast(n)
          }
        }
      ))
      doEvery(c.cleanUpStaleAlerts, minutes(3));
      c.ready = true;
    }
  )

  return c;

}
