import React from "react";
import OverlaySubmitFlag from "../components/OverlaySubmitFlag/OverlaySubmitFlag";
import { APIController } from "../controllers/api.controller";
import { Comment } from "../models/makeComment.model";
import { Thought } from "../models/makeThought.model";

export const submitFlag = (
  target: Thought | Comment,
  API: APIController,
  thought?: Thought,
) => {
  const { UI } = API.ROOT!.children;
  UI.OVERLAY.present({
    component: <OverlaySubmitFlag target={target} thought={thought}/>
  })
}