import { action, observable, reaction } from "mobx";
import React from "react";
import BrowserRecommendationList from "../../components/BrowserRecommendationList/BrowserRecommendationList";
import { UIController } from "../../controllers/ui.controller";
import { SHOULD_LOG } from "../../env";
import { getColorHexByName } from "./colors.utils";

const userAgent = window ? window.navigator.userAgent ?? '' : '';
const platform = window ? window.navigator.platform ?? '' : '';
const vendor = window ? window.navigator.vendor ?? '' : '';

export const isIOS = ['iPad Simulator','iPhone Simulator','iPod Simulator','iPad','iPhone','iPod'].includes(platform);
export const isElectron = userAgent.indexOf('Electron/') >= 0;
export const isSafari = vendor.includes('Apple') && userAgent && !userAgent.includes('CriOS') && !userAgent.includes('FxiOS');
export const isAppleBrowser = isIOS || isSafari;
export const isAndroid = userAgent.includes('Android');
export const isFirefox = userAgent.includes('Firefox');
export const isOpera = userAgent.includes('OPR');
export const isChrome = !isOpera && /Chrome/.test(userAgent) && /Google Inc/.test(vendor);
export const isChromiumEdge = isChrome && /\sEdg\//.test(userAgent);
export const isEdgeLegacy = !isChromiumEdge && userAgent.includes("Edge");
export const isWebKit = (userAgent.indexOf('AppleWebKit') >= 0);

const toReturn: KnownBrowserName[] = observable([]);

export type KnownBrowserName = 
  | 'apple' 
  | 'ios' 
  | 'safari' 
  | 'chrome' 
  | 'firefox' 
  | 'opera' 
  | 'blink' 
  | 'edge-legacy' 
  | 'edge-chromium' 
  | 'samsung' 
  | 'android' 
  | 'webkit'
  | 'electron'

export const detectBrowser = action((): KnownBrowserName[] => {
  
  if (!window || toReturn.length > 0) return toReturn;

  reaction(
    () => toReturn.join(' '), 
    browserNameString => {
      document.documentElement.setAttribute('data-browser', browserNameString);
      toReturn.forEach(i => document.documentElement.classList.add(i));
    },
    { fireImmediately: true }
  )

  try {

    if (isIOS) toReturn.push('ios');
    if (isElectron) toReturn.push('electron');
    if (isSafari) toReturn.push('safari');
    if (isAppleBrowser) toReturn.push('apple');
    if (isWebKit) toReturn.push('webkit');
    if (isAndroid) toReturn.push('android');
    if (isFirefox) toReturn.push('firefox');
    if (isOpera) toReturn.push('opera');
    if (isChrome) toReturn.push('chrome');
    if (isChromiumEdge) toReturn.push('edge-chromium');
    if (isEdgeLegacy) toReturn.push('edge-legacy');

    return toReturn;

  } catch (e) {

    SHOULD_LOG() && console.log('browser checker was unable to run. the current environment might not be a browser.');

    return toReturn
  }

})

detectBrowser();

export const warnOutdatedBrowser = (UI: UIController) => {
  UI.DIALOG.present({
    heading: "Your browser is a little outdated",
    body: <div>
      <p>Please update your browser to one of the following reccomendations before accessing turn2me service. The application might not display or function correctly if you continue with your current browser.</p>
      <p><strong>Recommended browsers:</strong></p>
      <BrowserRecommendationList />
    </div>,
    color: getColorHexByName('orange'),
    defaultActions: ['positive'],
  })
}