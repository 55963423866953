import { Observer } from 'mobx-react-lite';
import React from 'react';
import { FourLevelNumber } from '../../base/@types';
import BaseSelector from '../../base/components/BaseSelector/BaseSelector';
import { equalByString } from '../../base/utils/string.utils';
import { difficultyLabels, frequencyLabels, satisfactionLabels } from '../../constants/survey.constants';
import './SurveySelectorWithFourLevels.scss';

type SurveySelectorWithFourLevelsProps<T extends object = object> = {
  form: T,
  field: keyof T & string,
  labelType?: 'frequency' | 'difficulty' | 'satisfaction',
  nullable?: any,
  readonly?: any,
  showScore?: any,
}

const SurveySelectorWithFourLevels = <T extends object = object>(props: React.PropsWithChildren<SurveySelectorWithFourLevelsProps<T>>) => {

  let options: {value: FourLevelNumber, label: string}[];

  switch (props.labelType) {
    case 'difficulty': {
      options = difficultyLabels.map((l, i) => ({ value: i as FourLevelNumber, label: l }))
      break;
    }
    case 'satisfaction': {
      options = satisfactionLabels.map((l, i) => ({ value: i as FourLevelNumber, label: l }))
      break;
    }
    case 'frequency':
    default: {
      options = frequencyLabels.map((l, i) => ({ value: i as FourLevelNumber, label: props.showScore ? l + ': ' + i : l }))
    }
  }

  return <Observer children={() => (
    <BaseSelector<T>
      className="SurveySelectorWithFourLevels"
      form={props.form}
      field={props.field}
      options={options}
      nullable={props.nullable}
      readonly={props.readonly}
      valueEqualityChecker={equalByString}
      appearance="inline"
    /> 
  )} />
}

export default SurveySelectorWithFourLevels;