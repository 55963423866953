import { Observer } from 'mobx-react-lite';
import React from 'react';
import IconWithCount from '../../base/components/IconWithCount/IconWithCount';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useStore } from '../../base/utils/mobx.utils';
import { Comment } from '../../models/makeComment.model';
import './InteractionCommentControlSet.scss';


interface InteractionCommentControlSetProps {
  comments: Comment[],
  onClick?: () => void,
}

const InteractionCommentControlSet: React.FC<InteractionCommentControlSetProps> = p => {

  const { AUTH, THOUGHT_CATCHER } = useControllers();

  const s = useStore(() => ({
    get serviceStatus() {
      return THOUGHT_CATCHER.serviceStatusForCurrentUser;
    },
    get commentsToShow() {
      if (AUTH.isModerator) return p.comments;
      return p.comments.filter(c => {
        const isHidden = c.timeHidden;
        const isDeleted = c.timeDeleted;
        return !isHidden && !isDeleted;
      });
    },
  }));

  return <Observer children = {() => (
    <span className="InteractionCommentControlSet">
      <button className="CommentSectionToggle" onClick={s.serviceStatus === 'on' ? p.onClick : undefined} disabled={s.serviceStatus !== 'on'}>
        <IconWithCount icon="speech-bubble" count={s.commentsToShow.length} notifStyle="number" />
      </button>
    </span>
  )} />
}

export default InteractionCommentControlSet;