import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { toTitleCase } from '../../base/utils/string.utils';
import { CounsellingType, CounsellingTypeDescriptors } from '../../constants/counsellingTypes.descriptors';

interface CounsellingTypeRendererRendererProps {
  value?: CounsellingType
}

const CounsellingTypeRenderer: React.FC<CounsellingTypeRendererRendererProps> = props => {

  const p = useProps(props);
  
  const s = useStore(() => ({
    get def() {
      return CounsellingTypeDescriptors.find(t => t.value === p.value);
    },
    get displayName() {
      return (s.def && s.def.name) || toTitleCase(p.value)
    }
  }))

  return <Observer children={() => (
    <span className="CounsellingTypeRenderer">
      { s.displayName}
    </span>
  )} />

}

export default CounsellingTypeRenderer;