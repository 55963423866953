import { Flag, FlagRelatedModels } from "../../models/makeFlag.model";
import { DefaultIndexParamSet } from "../utils/api.utils";
import { makeApiEndpointConfig, makeApiGetEndpointConfig } from "../utils/endpoints.utils";
import { makeUrl } from "../utils/url.utils";
import { prefixOwn, prefixStaff } from "./namespacePrefixes";

export type FlagEndpointParams = DefaultIndexParamSet<Flag, FlagRelatedModels> & {
  include?: (keyof FlagRelatedModels | string)[],
}
const ownUrlBase = prefixOwn('moderated-flags');
const staffUrlBase = prefixStaff('moderated-flags');

export const FlagEndpoints = {
  own: {
    create: () => ownUrlBase,
  },
  staff: {
    index: makeApiEndpointConfig<FlagEndpointParams>(staffUrlBase),
    get: makeApiGetEndpointConfig<FlagEndpointParams>(staffUrlBase),
    create: () => staffUrlBase,
    update: (id: string) => makeUrl(staffUrlBase, id),
    delete: (id: string) => makeUrl(staffUrlBase, id),
  },
}