import { runInAction } from "mobx";
import React from "react";
import { Link } from "react-router-dom";
import { ColorCodedState } from "../../base/@types";
import ClickOrTap from "../../base/components/ClickOrTap/ClickOrTap";
import PseudoLink from "../../base/components/PseudoLink/PseudoLink";
import { AuthEndpoints } from "../../base/endpoints/auth.endpoints";
import { NoOp } from "../../base/utils/functions.utils";
import OverlayProfileEditor from "../../modules/shared/Account/_components/OverlayProfileEditor";
import { AuthController } from "../auth.controller";
import { UIController } from "../ui.controller";

export const resendVerificationEmail = async (AUTH: AuthController) => {

  const { API, UI } = AUTH.ROOT!.children;
  const { DIALOG, OVERLAY } = UI;

  if (AUTH.currentUser?.timeVerifiedEmail) {
    DIALOG.present({
      heading: 'Thank you, you have already verified your email before.',
    })
    return;
  }

  const updateEmail = () => {
    DIALOG.dismiss('email-verification');
    OVERLAY.present({
      component: <OverlayProfileEditor />
    })
  };

  try {
    const url = AuthEndpoints.verification.resend.email();
    await API.postRaw(url);
    DIALOG.present({
      name: 'email-verification',
      heading: 'Verification Email Sent',
      body: () => <>
        <p>A new verification email has been sent to your email address. If you still don't see this email in a few minutes, check your junk emails or <Link to="/app/account?focus=email" title="Update Account Email" onClick={updateEmail}>use a different email address for your account</Link>.</p>
      </>,
      colorCodedState: ColorCodedState.success,
      defaultActions: ['positive'],
    })
  } catch (e) {
    DIALOG.error({
      heading: 'Failed to send verification email',
      body: 'We apologize for the inconvenience caused; please try again. If this problem persists, please contact our customer support.',
    })
  }
};

export const openProfileEditor = (UI: UIController) => {
  const { DIALOG, OVERLAY } = UI;
  DIALOG.dismiss('email-verification');
  OVERLAY.present({
    component: <OverlayProfileEditor />
  })
};

export const presentEmailVerificationDialog = (UI: UIController, AUTH: AuthController) => {
  UI.DIALOG.present({
    name: 'email-verification',
    heading: 'Email Verification',
    body: () => <>
      <p>To protect all turn2me clients, some features are not available before you confirm at least your email or phone number.</p>
      <p>If you did not receive a confirmation email, you can resend an email or <PseudoLink onClick={() => openProfileEditor(UI)}><ClickOrTap /> here to update your email address</PseudoLink>.</p>
    </>,
    actions: [
      {
        name: 'resendEmail',
        buttonClass: 'subtle',
        label: 'Resend Email',
        action: async () => await resendVerificationEmail(AUTH),
      },
      {
        name: 'positive',
        label: 'OK',
        action: NoOp
      }
    ]
  })
}



export const verifyEmail = async (AUTH: AuthController, skipToast?: boolean) => {

  const { UI } = AUTH.ROOT!.children;

  if (window.location.pathname === '/app/explore') return;

  if (skipToast) {
    presentEmailVerificationDialog(UI, AUTH);
  } else {
    if (UI.TOAST.hasToast('email-verification-toast')) return;
    if (AUTH.hasNotifiedAboutEmailVerification) return;
    UI.TOAST.present({
      name: 'email-verification-toast',
      heading: 'Welcome to turn2me!',
      body: 'Please verify your email by clicking the confirmation link in your inbox.',
      action: () => {
        presentEmailVerificationDialog(UI, AUTH);
      },
    });
    runInAction(() => {
      AUTH.hasNotifiedAboutEmailVerification = true;
    })
  }

}