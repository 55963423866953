import { Thought, ThoughtRelatedModels, ThoughtSnapshot } from "../../models/makeThought.model";
import { AgeGroupFilterType } from "../../utils/ageAndDateOfBirth.utils";
import { BaseParamFilterType, DefaultIndexParamSet } from "../utils/api.utils";
import { makeApiEndpointConfig, makeApiGetEndpointConfig } from "../utils/endpoints.utils";
import { makeUrl } from "../utils/url.utils";
import { prefixClient, prefixOwn, prefixStaff } from "./namespacePrefixes";

export type ThoughtEndpointParams = DefaultIndexParamSet<Thought, ThoughtRelatedModels> & {
  include?: (keyof ThoughtRelatedModels | string)[],
  latest?: boolean,
  filter?: BaseParamFilterType<ThoughtSnapshot> & {
    ageGroup?: AgeGroupFilterType[],
    withTrashed?: 'only' | 'with',
  }
}
export const DefaultThoughtIncludes: (keyof ThoughtRelatedModels | string)[] = [
  'comments.user',
  'reactions',
  'user',
]
export const DefaultThoughtIncludesForAdmins: (keyof ThoughtRelatedModels | string)[] = [
  'comments.user',
  'comments.flags',
  'comments.originalComment',
  'reactions',
  'user',
  'flags',
  'originalThought'
]

const clientUrlBase = prefixClient('thoughts');
const ownUrlBase = prefixOwn('thoughts');
const staffUrlBase = prefixStaff('thoughts');

export const ThoughtEndpoints = {
  client: {
    /**
     * basically the thought feed. Also includes client's own private thoughts
     */
    index: makeApiEndpointConfig<ThoughtEndpointParams>(clientUrlBase),
    get: makeApiGetEndpointConfig<ThoughtEndpointParams>(clientUrlBase),
  },
  own: {
    /**
     * own thoughts, including private thoughts; does not include anyone else's.
     */
    index: makeApiEndpointConfig<ThoughtEndpointParams>(ownUrlBase),
    get: makeApiGetEndpointConfig<ThoughtEndpointParams>(ownUrlBase),
    create: () => ownUrlBase,
    update: (id: string) => makeUrl(ownUrlBase, id),
    delete: (id: string) => makeUrl(ownUrlBase, id), // is this allowed?
  },
  staff: {
    index: makeApiEndpointConfig<ThoughtEndpointParams>(staffUrlBase),
    get: makeApiGetEndpointConfig<ThoughtEndpointParams>(staffUrlBase),
    create: () => staffUrlBase,
    update: (id: string) => makeUrl(staffUrlBase, id),
    delete: (id: string) => makeUrl(staffUrlBase, id),
  },
}