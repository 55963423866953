import { Nullable, StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { AlwaysTrueFn, cond } from "../base/utils/ramdaEquivalents.utils";
import { ApiModelName } from "../constants/ApiModels.enum";
import { ModelName } from "../constants/modelNames.enum";
import { hasTimestamps } from "../traits/hasTimestamps.trait";
import { CounsellingSession } from "./makeCounsellingSession.model";
import { Invoice } from "./makeInvoice.model";
import { SupportGroup } from "./makeSupportGroup.model";
import { User } from "./makeUser.model";

export type InvoiceItemSnapshot = ReturnType<typeof makeInvoiceItemSnapshot>;

export type InvoiceItemModelType = ApiModelName.COUNSELLING_SESSION | ApiModelName.SUPPORT_GROUP;

export const makeInvoiceItemSnapshot = () => ({
  id: '',
  amountPayable: 0,
  payeeId: '',
  invoiceId: '',
  modelId: '',
  modelType: null as Nullable<InvoiceItemModelType>,
  ...hasTimestamps(),
})

export type InvoiceItemRelatedModels<T extends InvoiceItemModel = InvoiceItemModel> = {
  payee?: User,
  model?: T,
  invoice?: Invoice,
}
export type InvoiceItemExtendedProperties = {};
  
export type InvoiceItemModel = CounsellingSession | SupportGroup;

export type InvoiceItemSupportGroup = StandardModel<InvoiceItemSnapshot, InvoiceItemRelatedModels<SupportGroup>, InvoiceItemExtendedProperties>
export type InvoiceItemCounsellingSession = StandardModel<InvoiceItemSnapshot, InvoiceItemRelatedModels<CounsellingSession>, InvoiceItemExtendedProperties>

export type InvoiceItem = InvoiceItemSupportGroup | InvoiceItemCounsellingSession;

export const makeInvoiceItem = createStandardModelFactory<InvoiceItem, InvoiceItemRelatedModels, InvoiceItemExtendedProperties>({
  name: ModelName.invoiceItems,
  snapshotFactory: makeInvoiceItemSnapshot,
  relationshipsSchemaFactory: s => ({
    payee: ModelName.users,
    invoice: ModelName.invoices,
    model: cond([
      [() => s?.modelType === ApiModelName.COUNSELLING_SESSION, () => ModelName.counsellingSessions],
      [() => s?.modelType === ApiModelName.SUPPORT_GROUP, () => ModelName.supportGroups],
      [AlwaysTrueFn, () => 'skip']
    ])()
  }),
})