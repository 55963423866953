import React from 'react';
import { ColorCodedState } from '../../@types';
import BaseButton from '../BaseButton/BaseButton';
import InfoBanner from '../InfoBanner/InfoBanner';

interface AppGenericErrorNoticeProps {}

const AppGenericErrorNotice: React.FC<AppGenericErrorNoticeProps> = () => {
  function reload() {
    window.location.reload();
  }
  return <InfoBanner className="AppGenericErrorNotice" colorCodedState={ColorCodedState.error}>
    <p><strong>The application had failed to load.</strong></p>
    <p>Please try refresh the page. If the problem persists, please contact us for support.</p>
    <BaseButton icon="refresh" onClick={reload}>Reload</BaseButton>
  </InfoBanner>
}

export default AppGenericErrorNotice;