import axios, { AxiosResponse, CancelToken } from "axios";
import deepmerge from "deepmerge";
import { action, flow } from "mobx";
import moment from "moment";
import React from "react";
import { AnyObject, GenericFunction, LaravelSuccessIndexGetResponse, Timeframe } from "../../../base/@types";
import { useOnMount } from "../../../base/hooks/lifecycle.hooks";
import { useControllers } from "../../../base/hooks/useRootController.hook";
import { DefaultIndexParamSet } from "../../../base/utils/api.utils";
import { reportError } from "../../../base/utils/errors.utils";
import { NoOp } from "../../../base/utils/functions.utils";
import { getAverage, percentage } from "../../../base/utils/math.utils";
import { immediateReaction, useStore } from "../../../base/utils/mobx.utils";
import { groupBy, sum, uniq } from "../../../base/utils/ramdaEquivalents.utils";
import { autoPluralize, toSentenceCase, toTitleCase } from "../../../base/utils/string.utils";
import { YYYYMMDD, YYYYMMDDHHmmss } from "../../../base/utils/time.utils";
import { useGetUrlParams } from "../../../base/utils/urlParams.utils";
import tick from "../../../base/utils/waiters.utils";
import { ApiModelName } from "../../../constants/ApiModels.enum";
import { ModelName } from "../../../constants/modelNames.enum";
import { APIController } from "../../../controllers/api.controller";
import { IS_DEV, SHOULD_LOG } from "../../../env";
import { Assignment, AssignmentPayment } from "../../../models/makeAssignment.model";
import { CounsellingApplicationSnapshot } from "../../../models/makeCounsellingApplication.model";
import { ExtendedCounsellingSessionSnapshot } from "../../../models/makeCounsellingSession.model";
import { SupportGroupSnapshot } from "../../../models/makeSupportGroup.model";
import { calculateSatisfactionSurveyScore, SurveySatisfaction, SurveySatisfactionSnapshot } from "../../../models/makeSurveySatisfaction.model";
import { UserSnapshot } from "../../../models/makeUser.model";
import { counsellorNameTransformer } from "../../../utils/user.utils";

export const makeStatDataGetter =
  <
    DataType extends AnyObject,
    RelationshipsType extends AnyObject,
    ParamsType extends AnyObject = DefaultIndexParamSet<
      DataType,
      RelationshipsType
    >
  >(
    endpointFactory: (
      params: AnyObject & DefaultIndexParamSet<DataType, RelationshipsType>
    ) => string,
    modelName?: ModelName,
    params?: ParamsType
  ) =>
  (
    API: APIController,
    defaultParams: Partial<DefaultIndexParamSet<DataType, RelationshipsType>>,
    cancelToken: CancelToken
  ) =>
    new Promise(async (resolve, reject) => {
      const mergedParams = deepmerge(defaultParams, params ?? {});
      const url = endpointFactory(mergedParams);
      try {
        const { data } = (await API.getRawCanCancel(
          url,
          cancelToken
        )) as AxiosResponse<LaravelSuccessIndexGetResponse<DataType>>;
        resolve(data);
      } catch (e) {
        if (axios.isCancel(e)) {
          return;
        } else {
          reportError(e);
          reject(e);
        }
      }
    });

export const makeStubStatDataGetter = () => (...args: any[]) => Promise.resolve([] as any[]);

export type GenericStatDataConfig = {
  data: any[],
  getter: ReturnType<typeof makeStatDataGetter>,
  postGetOperation?: string,
}

export type StatDataSetState = ReturnType<typeof useStatDataSetState>;

export const useStatDataSetState = <T extends GenericStatDataConfig = GenericStatDataConfig>(
  state: { data: Record<string, T> },
  defaultTimeframe?: Timeframe
) => {

  const { NAVIGATOR, API, UI, COMMON, STAFF } = useControllers();

  const params = useGetUrlParams();

  const s = useStore(() => ({
    alive: true,
    timeframe: defaultTimeframe || {
      startDate: params.startDate ? decodeURIComponent(params.startDate) : moment.utc().subtract(6, 'days').format(YYYYMMDD),
      endDate: params.endDate ? decodeURIComponent(params.endDate) : moment.utc().format(YYYYMMDD),
    } as Timeframe,
    get indexEndpointParams() {
      return {
        filter: {
          dateRange: {
            startDate: moment.utc(s.timeframe.startDate).startOf('day').format(YYYYMMDDHHmmss),
            endDate: moment.utc(s.timeframe.endDate).add(0, 'day').startOf('day').format(YYYYMMDDHHmmss),
          }
        },
        perPage: -1,
      } as Partial<DefaultIndexParamSet>
    },
    isLoading: true,
    statusText: 'Initialising...',
    erroredDataTypes: [] as string[],
    setStatus: action((t: string) => s.statusText = t),
    recordError: action((t: string, e: Error | unknown) => s.erroredDataTypes.push(t)),
    get hasPermission() {
      return STAFF.canUseStatModule;
    },

    cancelTokenSource: axios.CancelToken.source(),
    newCancelTokenSource: action(() => {
      s.cancelTokenSource = axios.CancelToken.source()
      return s.cancelTokenSource
    }),
    cancelRequest: action((cancelMsg?: string) => {
      s.cancelTokenSource.cancel(cancelMsg)
    }),
  }));

  const getData = flow(function * () {
    s.cancelRequest("New stats request incoming. Stopping previous GET requests...")
    s.isLoading = true;
    s.erroredDataTypes.splice(0);

    yield tick(100);

    const allEntries = Object.entries(state.data);

    SHOULD_LOG() && console.log("getting data: ", NAVIGATOR.currentLocationPathname);
    SHOULD_LOG() && console.time(`getStats ${NAVIGATOR.currentLocationPathname}`);

    for (let entry of allEntries) {
      const percentageDisplay = percentage(allEntries.indexOf(entry) + 1, allEntries.length, 0);
      const key = entry[0] as string;
      const { getter } = entry[1];
      if (UI.onlyPhones) {
        s.setStatus(percentageDisplay);
      } else {
        s.setStatus(`Loading ${toSentenceCase(key + '')} (${percentageDisplay})`);
      }
      try {
        const data = yield getter(API, s.indexEndpointParams, s.newCancelTokenSource().token);
        entry[1].data.splice(0);
        switch (entry[1].postGetOperation) {
          case "append":
            entry[1].data.push(data);
            break;
          case "appendDereference":
            entry[1].data.push(...data);
            break;
          default:
            entry[1].data.push(...data.data);
        }
      } catch(e) {
        if (axios.isCancel(e)) {
          return;
        } else {
          SHOULD_LOG() && console.log('error caught in getData', e);
          s.recordError(key, e);
        }
      }
    }

    SHOULD_LOG() && console.timeEnd(`getStats ${NAVIGATOR.currentLocationPathname}`);

    s.setStatus('Rendering...');
    yield tick(2000);

    s.isLoading = false;

    if (s.erroredDataTypes.length > 0) {
      s.setStatus('[!] Data Incomplete');
      s.erroredDataTypes.forEach(t => state.data[t]?.data.splice(0));
      UI.DIALOG.error({
        heading: `${autoPluralize(s.erroredDataTypes.length, 'error')} occurred while getting data.`,
        body: () => <>
          <p>The following {autoPluralize(s.erroredDataTypes.length, 'dataset')} did not load correctly:</p>
          <ul>{s.erroredDataTypes.map(t => <li key={t}>{toTitleCase(t)}</li>)}</ul>
          <p>Affected stats may not be accurate. Please try refreshing the page. If the errors persist, please contact the development team.</p>
          {COMMON.online && <p>The errors have been reported.</p>}
        </>
      })
    } else {
      s.setStatus('✓ All Data Loaded');
    }

  })

  useOnMount(() => {
    if (!s.hasPermission) {
      return;
    }
    let disposer: GenericFunction = NoOp;
    setTimeout(() => {
      if (!s.alive) return;
      disposer = immediateReaction(
        () => [s.timeframe.startDate, s.timeframe.endDate].join('-'),
        getData,
      );
    }, 100);
    return () => {
      s.alive = false;
      disposer();
    }
  })

  return s;
}

export type CounsellorStatSet = ReturnType<typeof getCounsellorStatSet>;

export const averageAbyB = (a: number, b: number) => {
  const value = a / b;
  return isNaN(value) ? '-' : value.toFixed(2).toLocaleString();
}
export function getCounsellorStatSet(data: {
  counsellor: UserSnapshot,
  applications: CounsellingApplicationSnapshot[],
  ongoingApplications: CounsellingApplicationSnapshot[],
  dnrApplications: CounsellingApplicationSnapshot[],
  archivedApplications: CounsellingApplicationSnapshot[],
  completedApplications: CounsellingApplicationSnapshot[],
  sessions: ExtendedCounsellingSessionSnapshot[],
  supportGroups: SupportGroupSnapshot[],
  createdAssignments: Assignment[],
  completedAssignments: Assignment[],
  satisfactionSurveys: SurveySatisfactionSnapshot[],
}) {

  const confirmedApplications = data.applications.filter(a => !!a.timeApproved);

  const sessionsGroupedByClients = groupBy((sess: ExtendedCounsellingSessionSnapshot) => {
    return sess.application?.applicantId;
  }, data.sessions);
  if (data.counsellor.id === '86' && IS_DEV) {
    console.log(sessionsGroupedByClients, data.sessions);
  }
  const uniqueClients = Object.keys(sessionsGroupedByClients);
  const performedSessions = data.sessions.filter(ses => !!ses.timeEnded)
  const completedSessions = performedSessions.filter(ses => ses.hasAnyClientAttended)
  const sessionsInThePast = data.sessions.filter(ses => !ses.timeScheduled || moment.utc(ses.timeScheduled).isBefore() || !!ses.timeEnded);
  const dnaSessions = sessionsInThePast.filter(ses => !ses.hasAnyClientAttended);

  const supportGroupsFacilitated = data.supportGroups.filter(group => !!group.timeEnded);

  const assignments = uniq([
    ...data.createdAssignments,
    ...data.completedAssignments,
  ]);

  const surveyAssignments = assignments.filter(a => ([
    ApiModelName.SURVEY_GAD7,
    ApiModelName.SURVEY_PHQ9,
    ApiModelName.SURVEY_SATISFACTION
  ] as string[]).includes(a?.targetType || ''));

  const paymentAssignments = data.completedAssignments.filter(a => a.targetType === ApiModelName.PAYMENT && a.target) as AssignmentPayment[];
  const paidPaymentAssignments = paymentAssignments.filter(a => !!a.timeCompleted);

  const gad7SurveyAssignments = surveyAssignments.filter(a => a?.targetType === ApiModelName.SURVEY_GAD7);
  const phq9SurveyAssignments = surveyAssignments.filter(a => a?.targetType === ApiModelName.SURVEY_PHQ9);
  const satisfactionSurveyAssignments = surveyAssignments.filter(a => a?.targetType === ApiModelName.SURVEY_SATISFACTION);
  const gad7SurveyAssignmentsCompleted = gad7SurveyAssignments.filter(a => !!a?.target);
  const phq9SurveyAssignmentsCompleted = phq9SurveyAssignments.filter(a => !!a?.target);
  const satisfactionSurveyAssignmentsCompleted = satisfactionSurveyAssignments.filter(a => !!a?.target);
  const satisfactionSurveys = data.satisfactionSurveys;
  const satisfactionSurveyScores = satisfactionSurveys.map(survey => calculateSatisfactionSurveyScore(survey as SurveySatisfaction));

  const totalDonationsSuggested = sum(paymentAssignments.map(a => a.associated?.amountPayable ?? 0));
  const totalDonationsCollected = sum(paidPaymentAssignments.map(a => a.target?.amount ?? 0));

  const d = {

    user: data.counsellor,
    username: counsellorNameTransformer(data.counsellor.username),

    numberOfUniqueClients: uniqueClients.length,

    numberOfAssignedApplications: data.applications.length,
    numberOfConfirmedApplications: confirmedApplications.length,
    numberOfOngoingApplications: data.ongoingApplications.length,
    numberOfArchivedApplications: data.archivedApplications.length,
    numberOfCompletedApplications: data.completedApplications.length,
    numberOfDNRApplications: data.dnrApplications.length,

    numberOfAssignedSessions: data.sessions.length,
    numberOfSessionsPerformed: performedSessions.length,
    avgNumberOfSessionsPerformedPerClient: averageAbyB(performedSessions.length, uniqueClients.length),
    numberOfSessionsCompleted: completedSessions.length,
    avgNumberOfSessionsCompletedPerClient: averageAbyB(completedSessions.length, uniqueClients.length),
    numberOfDnaSessions: dnaSessions.length,
    avgNumberOfDnaSessionsPerClient: averageAbyB(dnaSessions.length, uniqueClients.length),

    numberOfSupportGroupsScheduled: data.supportGroups.length,
    numberOfSupportGroupsFacilitated: supportGroupsFacilitated.length,

    numberOfGAD7Surveys: gad7SurveyAssignments.length,
    numberOfPHQ9Surveys: phq9SurveyAssignments.length,
    numberOfGAD7SurveysCompleted: gad7SurveyAssignmentsCompleted.length,
    numberOfPHQ9SurveysCompleted: phq9SurveyAssignmentsCompleted.length,
    numberOfSatisfactionSurveys: satisfactionSurveyAssignments.length,
    numberOfSatisfactionSurveysCompleted: satisfactionSurveyAssignmentsCompleted.length,
    satisfactionSurveys: satisfactionSurveys,
    satisfactionSurveyScoresAverage: getAverage(satisfactionSurveyScores)?.toFixed(2),

    totalDonationsSuggested,
    totalDonationsCollected,

  }

  return d;

}
