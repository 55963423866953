export enum ReactionType {

  None = 'None',
  Like = 'Like',
  
  Clap = 'Clap',

  Happy = 'Happy',
  Wow = 'Wow',
  Sad = 'Sad',
  Angry = 'Angry',

}

export const ReactionTypes = [
  ReactionType.Like,
  ReactionType.Clap,
  ReactionType.Happy,
  ReactionType.Wow,
  ReactionType.Sad,
  ReactionType.Angry,
] as const;