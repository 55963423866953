import { Observer } from 'mobx-react-lite';
import React from 'react';
import CreatePaymentMethodForm from '../../../components/CreatePaymentMethodForm/CreatePaymentMethodForm';
import { UIController } from '../../../controllers/ui.controller';
import { OverlayConfig } from '../../../controllers/ui/ui.controller.types';
import { GenericFunction } from '../../@types';
import { useControllers } from '../../hooks/useRootController.hook';
import AppPage from '../AppPage/AppPage';
import AppPageContent from '../AppPageContent/AppPageContent';
import AppPageHeader from '../AppPageHeader/AppPageHeader';
import OverlayCloseButton from '../OverlayCloseButton/OverlayCloseButton';
import UIBlock from '../UIBlock/UIBlock';
// import './OverlayCreatePaymentMethodForm.scss';

export const showCreatePaymentMethodOverlay = (UI: UIController, onSuccess: () => void, overlayConfig?: Partial<OverlayConfig>) => {
  UI.OVERLAY.present({
    name: 'OverlayStripeCreatePaymentMethodForm',
    component: <OverlayCreatePaymentMethodForm onSuccess={onSuccess} />,
    appearance: 'card',
    ...overlayConfig,
  })
}

interface OverlayCreatePaymentMethodFormProps {
  onSuccess?: GenericFunction;
}

const OverlayCreatePaymentMethodForm: React.FC<OverlayCreatePaymentMethodFormProps> = props => {

  const { UI } = useControllers();

  const dismiss = () => {
    UI.OVERLAY.dismiss('OverlayCreatePaymentMethodForm');
    props.onSuccess?.();
  }

  return <Observer children={() => (
    <AppPage className="OverlayCreatePaymentMethodForm" alwaysDisableCustomScrollbar>
      <AppPageHeader title="Add New Card" endSlot={<OverlayCloseButton />} />
      <AppPageContent padSections>
        <UIBlock>
          <CreatePaymentMethodForm onSuccess={dismiss} />
          {/* <BaseSpacer size="xl" /> */}
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />

}

export default OverlayCreatePaymentMethodForm;