import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseArticle from '../../../../../base/components/BaseArticle/BaseArticle';
import BaseCheckbox from '../../../../../base/components/BaseCheckbox/BaseCheckbox';
import BaseSpacer from '../../../../../base/components/BaseSpacer/BaseSpacer';
import BrowserRecommendationList from '../../../../../components/BrowserRecommendationList/BrowserRecommendationList';
import { CounsellingApplicationStepProps } from '../applicationStepDescriptors';
import './CounsellingApplicationStepImportantInfo.scss';

interface CounsellingApplicationStepImportantInfoProps extends CounsellingApplicationStepProps { }

const CounsellingApplicationStepImportantInfo: React.FC<CounsellingApplicationStepImportantInfoProps> = p => {

  return <Observer children={() => (
    <div className="CounsellingApplicationStepImportantInfo">

      <BaseArticle>

        <h3>Important Information</h3>
        <p>Your assigned counsellor will send you an introduction message before your first scheduled counselling session takes place.</p>
        <p>While you are waiting for your initial consultation, please feel free to browse our blogs and articles on a variety of mental health issues.</p>
        <p>At the time of your session please log into turn2me website and your session will open when the counsellor is ready. </p>

        <h3>Tech Requirements</h3>
        <p>Please access our services with either one of the following browsers on your device. If you are having trouble with certain features, please update your system or browser before retrying.</p>
        <BrowserRecommendationList />

        <h3>Fees/Donations</h3>
        <p>turn2me is a charity organization (Charity No. CHY18803) registered in the Republic of Ireland. The payment of your sessions goes partly towards the counsellor and to turn2me to help us fund sessions for those who cannot afford it.</p>

      </BaseArticle>

      <BaseSpacer />

      <div className="CounsellingApplicationStepImportantInfoFooter">
        <BaseCheckbox form={p.form} field="hasReadImportantInformation">
          I have read and understood the information above
        </BaseCheckbox>
      </div>

    </div>
  )} />

}

export default CounsellingApplicationStepImportantInfo;