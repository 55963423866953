import { SHOULD_LOG } from "../../env";

export function isValidVersionNumber(str?: string): str is string {
  return !!(str && str.split('').every(char => char.match(/[.0-9]/)) && str.split('').filter(char => char === '.').length <= 2);
}

export function versionAEqualsToB(a: string, b: string) {
  const bothValid = isValidVersionNumber(a) && isValidVersionNumber(b);
  if (!bothValid) {
    SHOULD_LOG() && console.warn(`The versions ${a} and ${b} both need to be strings in the format of e.g. 1.3.14.`);
    return false;
  }
  return a === b;
}

export function versionAHigherThanB(a: string, b: string) {
  const bothValid = isValidVersionNumber(a) && isValidVersionNumber(b);
  if (!bothValid) {
    SHOULD_LOG() && console.warn(`The versions ${a} and ${b} both need to be strings in the format of e.g. 1.3.14.`);
    return false;
  }
  const [ aMajor = 0, aMinor = 0, aPatch = 0 ] = a.split('.');
  const [ bMajor = 0, bMinor = 0, bPatch = 0 ] = b.split('.');
  if (+aMajor > +bMajor) return true;
  if (+aMajor < +bMajor) return false;
  if (+aMinor > +bMinor) return true;
  if (+aMinor < +bMinor) return false;
  if (+aPatch > +bPatch) return true;
  return false;
}

export const versionALowerThanB = (a: string, b: string) => !versionAHigherThanB(a,b) && !versionAEqualsToB(a,b);
