import { AnyObject, Nullable } from "../base/@types";
import { UserEndpoints } from "../base/endpoints/user.endpoints";
import { isStandardModel } from "../base/factories/standardModel.factory";
import { formatDateOfBirth } from "../base/utils/time.utils";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { User } from "../models/makeUser.model";
import { makePayloadWithoutId } from "../utils/payloads.utils";
import { saveCurrentUser } from "./user.requests";

export const sendSaveUserRequest = (
  API: APIController,
  userPartial: AnyObject,
  options?: {
    isSelf?: boolean,
  }
) => new Promise<User | undefined>(
  async (resolve, reject) => {
    try {
      const { id } = userPartial;
      if (options?.isSelf) {
        const user = await saveCurrentUser(API, userPartial);
        resolve(user);
      } else {
        const shouldUsePatch = !!id;
        const url = id ? UserEndpoints.staff.update(id) : UserEndpoints.staff.create();
        const payload: Partial<User> = makePayloadWithoutId(userPartial);
        if (!!payload.dateOfBirth) {
          payload.dateOfBirth = formatDateOfBirth(payload.dateOfBirth)
        }
        const method = shouldUsePatch ? API.patch : API.post;
        const savedUser: Nullable<User> = await method(url, ModelName.users, payload);
        if (!savedUser) {
          throw Error('Failed to save user');
        }
        if (!isStandardModel(userPartial)) {
          Object.assign(userPartial, savedUser);
        }
        resolve(savedUser);
      }
    } catch(e) {
      reject(e);
    }
  }
)