import React from "react";
import joinClassName from "../../utils/className.utils";
import BaseToggle, { BaseToggleProps } from "../BaseToggle/BaseToggle";

export interface BaseRadioProps<T = object> extends BaseToggleProps<T> {
  radioValue?: any,
  nullable?: any,
  readonly?: boolean,
  dataCy?: string,
}

export const BaseRadio = <T extends object>(props: React.PropsWithChildren<BaseRadioProps<T>>) => {
  return <BaseToggle<T> {...props}
    className={joinClassName('BaseRadio', props.className)}
    appearance="radio"
    trueValue={props.radioValue}
    falseValue={undefined}
    nullable={props.nullable}
    readonly={props.readonly}
    name={props.name}
    dataCy={props.dataCy}
  />
}
