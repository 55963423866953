import { Observer } from "mobx-react-lite";
import React, { SyntheticEvent, useRef } from "react";
import { ContextColor } from "../../constants/color.enum";
import joinClassName from "../../utils/className.utils";
import { checkIfShouldInvertStyle, getContextColorStyle } from "../../utils/colors.utils";
import { useProps, useStore } from "../../utils/mobx.utils";
import { isNil } from "../../utils/ramdaEquivalents.utils";
import { getRandomNumericString } from "../../utils/random.utils";
import { capitalizeFirstLetter } from "../../utils/string.utils";
import './BaseToggle.scss';

export interface BaseToggleProps<FormType = object> {
  className?: string;
  name?: string;
  form: FormType;
  field: keyof FormType & string;
  title?: string,
  label?: string | React.ReactElement;
  trueValue?: any,
  falseValue?: any,
  trueIconLabelValue?: string,
  falseIconLabelValue?: string,
  onClick?: (e?: SyntheticEvent) => unknown,
  beforeChange?: (newValue?: any) => Promise<boolean>,
  onChange?: (newValue?: any) => unknown,
  disabled?: any,
  readonly?: boolean,
  color?: string,
  style?: React.CSSProperties,
  appearance?: 'toggle' | 'checkbox' | 'radio',
  fullWidth?: boolean,
  nullable?: any,
  dataCy?: string,
}

const BaseToggle = <T extends object>(props: React.PropsWithChildren<BaseToggleProps<T>>) => {
  const p = useProps(props);
  const ref = useRef<HTMLDivElement | null>(null);
  const s = useStore(() => ({
    identifier: getRandomNumericString(6),
    get name() {
      return props.name;
    },
    get trueValue() {
      return p.trueValue === undefined ? true : p.trueValue;
    },
    get falseValue() {
      return p.falseValue === undefined ? false : p.falseValue;
    },
    get value() {
      const realValue = p.form[p.field];
      if (realValue === null) return '';
      return realValue;
    },
    get readonly() {
      return p.readonly ?? false;
    },
    get nullable() {
      return p.nullable ?? true;
    },
    set value(v: any) {
      if (isNil(v) && !s.nullable) return;
      p.form[p.field] = v;
    },
    get toggleIconLabel() {
      return s.isTrue ? (!p.trueIconLabelValue ? '' : p.trueIconLabelValue)
        : (!p.falseIconLabelValue ? '' : p.falseIconLabelValue);
    },
    toggleValue: async () => {
      if (p.readonly) return;
      if (p.disabled) return;
      const newValue = s.isTrue ? s.falseValue : s.trueValue;
      const shouldContinue = p.beforeChange ? await p.beforeChange() : true;
      if (!shouldContinue) return;
      s.value = newValue;
      p.onChange && p.onChange(s.value);
    },
    get isTrue() {
      return s.value === s.trueValue;
    },
    isInverted: false,
    checkComponentStyle() {
      s.isInverted = checkIfShouldInvertStyle(ref);
    },
    componentDidMount() {
      s.checkComponentStyle();
    },
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      s.toggleValue();
    }
  }))

  return <Observer children={() => {

    const { className, appearance = 'toggle', children, disabled, label, color, fullWidth, field: fieldName, name } = p;
    const id = `${name || fieldName.toString()}-${s.identifier}`;
    const commonInputAttributes = {
      id,
      name: name || fieldName.toString(),
      disabled,
      onChange: s.handleInputChange,
    }
    const style = {
      ...getContextColorStyle(ContextColor.Primary, color),
      ...p.style,
    }

    return <div className={joinClassName(
      'BaseToggle',
      `BaseToggle${capitalizeFirstLetter(appearance)}`,
      className,
      s.isTrue ? 'true' : 'false',
      s.isInverted && 'inverted',
      disabled && 'disabled',
      fullWidth && 'fullWidth',
      s.readonly && 'readonly',
    )} ref={ref} style={style} title={p.title} data-name={p.name} data-cy={p.dataCy}>
      {
        appearance === 'radio' ? <input type='radio' value={s.value} {...commonInputAttributes} /> : (
          <input type='checkbox' checked={s.value} {...commonInputAttributes} />
        )
      }
      <label className="BaseToggleInner" htmlFor={id} >
        <span className="BaseToggleBox">
          <span className="BaseToggleBoxMarker">{
            appearance === 'toggle' ? s.toggleIconLabel : (
              <svg className="BaseToggleBoxCheck" viewBox="0 0 14 10" fill="none">
                <path d="M1.125 3.96875L5.28125 8.125L12.3906 1.01562" stroke="currentColor" strokeWidth="2" />
              </svg>
            )
          }</span>
        </span>
        {(label || children) && (
          <span className="BaseToggleLabel">
            {label}
            {children}
          </span>
        )}
      </label>
    </div>
  }} />
}

export default BaseToggle;