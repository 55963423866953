import { flow, reaction, runInAction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject } from '../../../base/@types';
import { BaseTableColumnConfig } from '../../../base/components/BaseTable/BaseTable';
import ErrorRenderer from '../../../base/components/ErrorRenderer/ErrorRenderer';
import IndexDirectory from '../../../base/components/IndexDirectory/IndexDirectory';
import IndexDirectoryState from '../../../base/components/IndexDirectory/IndexDirectoryState';
import { CompanyEndpoints } from '../../../base/endpoints/company.endpoints';
import { UserEndpoints } from '../../../base/endpoints/user.endpoints';
import { useOnMount } from '../../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../../base/hooks/useRootController.hook';
import { makeActionConfig } from '../../../base/utils/actionConfig.utils';
import { makeLaravelIndexDataFetcher } from '../../../base/utils/api.utils';
import { reportError } from "../../../base/utils/errors.utils";
import { useProps, useStore } from '../../../base/utils/mobx.utils';
import { tick } from '../../../base/utils/waiters.utils';
import { ModelName } from '../../../constants/modelNames.enum';
import { ActionConfig } from '../../../controllers/ui/ui.controller.types';
import { Company } from '../../../models/makeCompany.model';
import { User } from '../../../models/makeUser.model';
import UsernameRenderer from '../../UsernameRenderer/UsernameRenderer';
import './CompanyUserIndexDirectory.scss';

type CompanyUserIndexDirectoryProps = {
  company?: Company,
}

const CompanyUserIndexDirectory: React.FC<CompanyUserIndexDirectoryProps> = React.memo(props => {

  const { UI, API } = useControllers();

  const p = useProps(props);
  const s = useStore(() => ({
    get company() { return p.company; },
    indexListState: new IndexDirectoryState({
      searchable: true,
      searchByKeyName: 'username',
      sortByKeyName: 'timeCreated',
      sortDirection: 'desc',
    }),
    shouldTriggerReload: false,
  }))

  useOnMount(() => {
    return reaction(
      () => s.shouldTriggerReload,
      async (v) => {
        if (v) {
          await tick(100);
          runInAction(() => {
            s.shouldTriggerReload = false;
          })
        }
      }
    )
  })

  const dataFetcher = makeLaravelIndexDataFetcher<User>(
    API,
    ModelName.users,
    UserEndpoints.staff.index,
    () => ({
      perPage: 10,
      filter: {
        companyId: s.company?.id,
      },
    }),
  )

  const tableColumnConfigs: BaseTableColumnConfig<User>[] = [
    {
      label: 'ID',
      keyName: 'id',
      sortable: true,
      searchable: true,
    },
    {
      label: 'Username',
      keyName: 'username',
      sortable: true,
      searchable: true,
      BodyCell: d => <UsernameRenderer user={d} />
    },
    {
      label: 'Name',
      keyName: 'firstName',
      sortable: true,
      searchable: true,
    },
    {
      label: 'Surname',
      keyName: 'lastName',
      sortable: true,
      searchable: true,
    },
    {
      label: 'Email',
      keyName: 'email',
      type: 'email',
      sortable: true,
      searchable: true,
    },
    {
      label: 'Country',
      keyName: 'countryProvidedId',
      sortable: true,
      searchable: false,
      type: 'country',
    },
  ]
  const userItemActions: ActionConfig<(d?: User) => unknown>[] = [
    makeActionConfig('Make Contact', d => makeContactUser(d), { dataCy: 'makeContactUser' }),
    makeActionConfig('Remove', d => removeFromCompany(d), { color: 'alert', dataCy: 'removeUser' }),
  ]

  const makeContactUser = flow(function* (user?: User) {
    try {
      const url = CompanyEndpoints.staff.update(s.company!.id);
      yield API.patch<Company>(url, ModelName.companies, {
        contactUserId: user?.id,
      });
      UI.TOAST.success(`${user?.username ?? user?.id} is now the contact user for ${s.company?.name ?? s.company?.id}`);
    } catch (e) {
      reportError(e);
      UI.DIALOG.error({
        heading: "Failed to make user a contact of the company, please try again",
        body: <ErrorRenderer error={(e as AnyObject).response} />,
      })
    }
  })

  const removeFromCompany = flow(function* (user?: User) {
    if (user?.id === s.company?.contactUserId) {
      UI.DIALOG.error({
        heading: "Failed to remove user from the company",
        body: "To remove a contact user from the company, assign a new user as contact, then try again.",
      })
      return;
    }
    try {
      const url = UserEndpoints.staff.update(user!.id);
      yield API.patch<User>(url, ModelName.companies, {
        companyId: null,
      });
      s.shouldTriggerReload = true;
      UI.TOAST.attention(`${user?.username ?? user?.id} has been removed from ${s.company?.name ?? s.company?.id}`);
    } catch (e) {
      reportError(e);
      UI.DIALOG.error({
        heading: "Failed to remove user from the company, please try again",
        body: <ErrorRenderer error={(e as AnyObject).response} />,
      })
    }
  })

  return <Observer children={() => (
    <IndexDirectory<User>
      className="CompanyUserIndexDirectory"
      state={s.indexListState}
      dataFetcher={dataFetcher}
      tablePropsGetter={() => ({
        columnConfigs: tableColumnConfigs,
        itemActions: userItemActions,
      })}
      shouldTriggerReload={s.shouldTriggerReload}
    />
  )} />
})

export default CompanyUserIndexDirectory;