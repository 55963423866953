import { when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ContextColor } from '../../constants/color.enum';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useObservableRef } from '../../hooks/useObservableRef.hook';
import { useResizeQuery } from '../../hooks/useResizeQuery';
import { useControllers } from '../../hooks/useRootController.hook';
import joinClassName from '../../utils/className.utils';
import { getContextColorStyle } from '../../utils/colors.utils';
import { debounce } from '../../utils/debounce.utils';
import { useProps } from '../../utils/mobx.utils';
import { convertKeyValuePairArrayToObject } from '../../utils/object.utils';
import tick, { doEvery } from '../../utils/waiters.utils';
import TitleBarHeightSpacer from '../TitleBarHeightSpacer/TitleBarHeightSpacer';
import './AppPage.scss';

interface AppPageProps {
  className?: string;
  fixedHeight?: boolean;
  color?: string | null;
  unloadGuard?: Promise<boolean>;
  pageContainerRef?: React.RefObject<HTMLDivElement>;
  accommodateTitleBarSpace?: boolean;
  alwaysDisableCustomScrollbar?: boolean;
  shouldDetectOwnDimensions?: boolean;
}

const AppPage: React.FC<AppPageProps> = props => {

  const p = useProps(props);
  const innerRef = useObservableRef<HTMLDivElement>();
  const ref = props.pageContainerRef ?? innerRef;
  const query = useResizeQuery(ref);
  const { NAVIGATOR } = useControllers();

  const resizeHandler = debounce(() => {
    when(() => Boolean(ref.current), async () => {
      if (query.width === 0 || query.height === 0) return;
      ref.current?.style.removeProperty('width');
      ref.current?.style.removeProperty('height');
      await tick(100);
      const { width, height } = query;
      ref.current?.style.setProperty('width', width + 'px');
      ref.current?.style.setProperty('height', height + 'px');
      ref.current?.style.setProperty('--AppPageWidth', width + 'px');
      ref.current?.style.setProperty('--AppPageHeight', height + 'px');
    })
  });

  useOnMount(() => {
    const disposers = [] as Function[];
    if (!p.shouldDetectOwnDimensions) return;
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
    query.onResize(resizeHandler);
    disposers.push(() => {
      window.removeEventListener('resize', resizeHandler);
    })
    if (p.shouldDetectOwnDimensions) {
      disposers.push(doEvery(resizeHandler, 5000));
    }
    disposers.push(NAVIGATOR.onPathnameChange(resizeHandler));
    return () => disposers.forEach(d => d());
  });

  return <Observer children={() => {
    const style = {
      ...getContextColorStyle(ContextColor.Primary, p.color),
    }
    const dataAttributes = convertKeyValuePairArrayToObject(Object.entries(p).filter(p => p[0].match('data-')));
    return (
      <div 
        // {...props}
        className={
          joinClassName(
            'AppPage',
            p.className,
            p.fixedHeight && 'fixedHeight',
            p.accommodateTitleBarSpace && 'accommodateTitlebarSpace',
          )
        } 
        ref={ref}
        style={style}
        data-display-mode={query?.displayMode}
        data-only-phones={query?.onlyPhones}
        data-from-tablet={query?.fromTablet}
        data-from-desktop={query?.fromDesktop}
        {...dataAttributes}
      >
        {/* <CustomScrollbar alwaysDisable={props.alwaysDisableCustomScrollbar}> */}
          {p.accommodateTitleBarSpace && <TitleBarHeightSpacer />}
          {props.children}
        {/* </CustomScrollbar> */}
      </div>
    )
  }} />
  
}

export default AppPage;
