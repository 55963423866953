import { AnyObject, Identifier, Nillable, RelationshipsSchema, SnapshotOf, StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { ApiModelName } from "../constants/ApiModels.enum";
import { ModelName } from "../constants/modelNames.enum";
import { LocalDBController } from "../controllers/localDB.controller";
import { HasTimestamps, hasTimestamps } from "../traits/hasTimestamps.trait";
import { getModelNameFromApiModelName } from "../utils/models.utils";
import { CounsellingApplication } from "./makeCounsellingApplication.model";
import { CounsellingSession } from "./makeCounsellingSession.model";
import { Payment } from "./makePayment.model";
import { SupportGroup } from "./makeSupportGroup.model";
import { SurveyGAD7 } from "./makeSurveyGAD7.model";
import { SurveyGoalSheet } from "./makeSurveyGoalSheet.model";
import { SurveyPHQ9 } from "./makeSurveyPHQ9.model";
import { SurveySatisfaction } from "./makeSurveySatisfaction.model";
import { SurveySupportGroupNonAttendance } from "./makeSurveySupportGroupNonAttendance";
import { SurveySupportGroupSatisfaction } from "./makeSurveySupportGroupSatisfaction";
import { User } from "./makeUser.model";

export type AssignmentSnapshot<
	TargetTypeName extends AssignmentTargetModelType,
	AssociatedTypeName extends AssignmentAssociatedModelType
> = {
	id: Identifier,
	title: string,
	description: Nillable<string>,
	body: Nillable<string>,
	targetType: Nillable<TargetTypeName>,
	targetId: Nillable<Identifier>,
	assignedToUserId: Nillable<Identifier>,
	assignedByUserId: Nillable<Identifier>,
	associatedType: AssociatedTypeName,
	associatedId: Nillable<Identifier>,
	timeCompleted: Nillable<string>,
	timeDue: Nillable<string>,
} & HasTimestamps;

export type AssignmentExtendedPropertyBase = {
	isCompleted: boolean,
}

export type AssignmentGAD7 = StandardModel<
	AssignmentSnapshot<ApiModelName.SURVEY_GAD7, ApiModelName.COUNSELLING_SESSION>,
	AssignmentRelatedModels<SurveyGAD7, CounsellingSession>,
	AssignmentExtendedPropertyBase & { targetTypeModelName: ModelName.surveysGAD7, associatedTypeModelName: ModelName.counsellingSessions }
>
export type AssignmentPHQ9 = StandardModel<
	AssignmentSnapshot<ApiModelName.SURVEY_PHQ9, ApiModelName.COUNSELLING_SESSION>,
	AssignmentRelatedModels<SurveyPHQ9, CounsellingSession>,
	AssignmentExtendedPropertyBase & { targetTypeModelName: ModelName.surveysPHQ9, associatedTypeModelName: ModelName.counsellingSessions }
>
export type AssignmentGoalSheet = StandardModel<
	AssignmentSnapshot<ApiModelName.SURVEY_GOAL_SHEET, ApiModelName.COUNSELLING_APPLICATION>,
	AssignmentRelatedModels<SurveyGoalSheet, CounsellingApplication>,
	AssignmentExtendedPropertyBase & { targetTypeModelName: ModelName.surveysGoalSheet, associatedTypeModelName: ModelName.counsellingApplications }
>
export type AssignmentSatisfaction = StandardModel<
	AssignmentSnapshot<ApiModelName.SURVEY_SATISFACTION, ApiModelName.COUNSELLING_APPLICATION>,
	AssignmentRelatedModels<SurveySatisfaction, CounsellingApplication>,
	AssignmentExtendedPropertyBase & { targetTypeModelName: ModelName.surveysSatisfaction, associatedTypeModelName: ModelName.counsellingApplications }
>
export type AssignmentSupportGroupNonAttendance = StandardModel<
	AssignmentSnapshot<ApiModelName.SURVEY_SUPPORT_GROUP_NON_ATTENDANCE, ApiModelName.SUPPORT_GROUP>,
	AssignmentRelatedModels<SurveySupportGroupNonAttendance, SupportGroup>,
	AssignmentExtendedPropertyBase & { targetTypeModelName: ModelName.surveysSupportGroupNonAttendance, associatedTypeModelName: ModelName.supportGroups }
>
export type AssignmentSupportGroupSatisfaction = StandardModel<
	AssignmentSnapshot<ApiModelName.SURVEY_SUPPORT_GROUP_SATISFACTION, ApiModelName.SUPPORT_GROUP>,
	AssignmentRelatedModels<SurveySupportGroupSatisfaction, SupportGroup>,
	AssignmentExtendedPropertyBase & { targetTypeModelName: ModelName.surveysSupportGroupSatisfaction, associatedTypeModelName: ModelName.supportGroups }
>
export type AssignmentPayment = StandardModel<
	AssignmentSnapshot<ApiModelName.PAYMENT, ApiModelName.COUNSELLING_SESSION>,
	AssignmentRelatedModels<Payment, CounsellingSession>,
	AssignmentExtendedPropertyBase & { targetTypeModelName: ModelName.payments, associatedTypeModelName: ModelName.counsellingSessions }
>

export type Assignment = AssignmentGAD7 | AssignmentPHQ9 | AssignmentGoalSheet | AssignmentSatisfaction | AssignmentSupportGroupNonAttendance | AssignmentSupportGroupSatisfaction | AssignmentPayment;
export type TargetTypeNameOf<AssType> = AssType extends StandardModel<AssignmentSnapshot<infer TargetTypeName, any>, any, any> ? TargetTypeName : never;
export type AssociatedTypeNameOf<AssType> = AssType extends StandardModel<AssignmentSnapshot<any, infer AssociatedTypeName>, any, any> ? AssociatedTypeName : never;
export type TargetTypeOf<AssType> = AssType extends StandardModel<any, AssignmentRelatedModels<infer TargetType, any>, any> ? TargetType : never;
export type AssociatedTypeOf<AssType> = AssType extends StandardModel<any, AssignmentRelatedModels<any, infer AssociatedType>, any> ? AssociatedType : never;

export type AssignmentTargetModelType =
	| ''
	| typeof ApiModelName.SURVEY_GAD7
	| typeof ApiModelName.SURVEY_PHQ9
	| typeof ApiModelName.SURVEY_GOAL_SHEET
	| typeof ApiModelName.SURVEY_SATISFACTION
	| typeof ApiModelName.SURVEY_SUPPORT_GROUP_NON_ATTENDANCE
	| typeof ApiModelName.SURVEY_SUPPORT_GROUP_SATISFACTION
	| typeof ApiModelName.PAYMENT
	| typeof ApiModelName.COUNSELLING_APPLICATION
;
export type AssignmentAssociatedModelType =
	| ''
	| typeof ApiModelName.COUNSELLING_APPLICATION
	| typeof ApiModelName.COUNSELLING_SESSION
	| typeof ApiModelName.INVITATION
	| typeof ApiModelName.SUPPORT_GROUP

export type AssignmentRelatedModels<TargetType extends AnyObject = AnyObject, AssociatedType extends AnyObject = AnyObject> = {
	target?: TargetType,
	associated?: AssociatedType,
	assignedToUser?: User,
	assignedByUser?: User,
}

export const makeAssignmentSnapshotBase = <AssignmentType extends Assignment>(
) => ({
	id: '',
	title: '',
	description: '',
	body: '',
	targetType: '' as AssignmentTargetModelType,
	targetId: '',
	assignedToUserId: '',
	assignedByUserId: '',
	associatedType: '' as AssignmentAssociatedModelType,
	associatedId: '',
	timeCompleted: '',
	timeDue: '',
	...hasTimestamps(),
	}) as SnapshotOf<AssignmentType>;

export const makeAssignment = <AssignmentType extends Assignment>(
	source?: Partial<SnapshotOf<AssignmentType>>,
	localDB?: LocalDBController,
): AssignmentType => createStandardModelFactory<AssignmentType>({
	name: ModelName.assignments,
	snapshotFactory: makeAssignmentSnapshotBase,
	relationshipsSchemaFactory: s => {
		const targetType = getModelNameFromApiModelName(s?.targetType);
		const associatedType = getModelNameFromApiModelName(s?.associatedType);
		if (!targetType || !associatedType) {
			throw Error(`An assignment must contain both a target type and an associated type. Given invalid assignment ID: ${source?.id}`)
		}
		return {
			target: targetType,
			associated: associatedType,
			assignedToUser: ModelName.users,
			assignedByUser: ModelName.users,
		} as RelationshipsSchema<SnapshotOf<AssignmentType>, AssignmentRelatedModels>
	},
	extendedPropertiesFactory: (m, $, localDB) => ({
		get targetType() {
			return $.targetType;
		},
		set targetType(value: any) {
			if (value !== m.targetType) {
				throw Error (`attempted to set assignment#${m.id}'s target type from ${m.targetType} to ${value}. This is seriously wrong.`);
			}
		},
		get targetTypeModelName() {
			return getModelNameFromApiModelName(m?.targetType);
		},
		get associatedTypeModelName() {
			return getModelNameFromApiModelName(m?.associatedType);
		},
		get isCompleted() {
			return Boolean(m.targetId) && m.timeCompleted;
		}
	}) as AssignmentExtendedPropertyBase
})(source, localDB);
