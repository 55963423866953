import { Observer } from "mobx-react-lite";
import React from "react";
import BaseSelector, { BaseSelectorProps } from "../../base/components/BaseSelector/BaseSelector";
import { useControllers } from "../../base/hooks/useRootController.hook";
import { sexualityDescriptors } from "../../constants/sexualities.constants";

interface SexualitySelectorProps<FormType = object> extends Omit<BaseSelectorProps<FormType, { name: string, value: string }, string>, 'options'> {}

export const SexualitySelector = <T extends object = object>(
  props: React.PropsWithChildren<SexualitySelectorProps<T>>
) => {
  const { UI } = useControllers();
  return <Observer children={() => (
    <BaseSelector<T, { name: string, value: string }>
      options={[...sexualityDescriptors]}
      appearance={props.appearance ?? (UI.onlyPhones ? 'system' : 'inline')}
      {...props}
      name={props.name ?? props.field}
    />
  )} />
}