import { Nullable } from "../../@types";
import { countWords, makeWordMatcher } from "../../utils/humanLanguage.utils";

export const matchChangeThoughtStateCommand = (input: string) => {

  let result = {
    confidence: 0,
    newState: null as Nullable<'private' | 'public'>,
  };

  const changeKeyword = makeWordMatcher([
    ['change', 'switch', 'set', 'make'],
  ])(input);
  const privateKeyword = makeWordMatcher([
    ['private', 'privat']
  ])(input);
  const publicKeyword = makeWordMatcher([
    ['public']
  ])(input);

  const wordCount = countWords(input);
  // a very long example
  // "would you mind changing the thought to private please?"
  if (wordCount > 10) return ;
  if (privateKeyword.matched || publicKeyword.matched) result.confidence = .3;
  result.newState = privateKeyword.matched ? 'private' : publicKeyword.matched ? 'public' : null;
  if (result.newState && changeKeyword.matched) result.confidence = .9;

  return result;

}