import { Observer } from 'mobx-react-lite';
import React, { MutableRefObject, PropsWithChildren, useRef } from 'react';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import joinClassName from '../../utils/className.utils';
import tick from '../../utils/waiters.utils';
import { useSwiperContext } from './SwiperContainer';
// import './SwiperSlide.scss';

export type SwiperSlideOnActiveHandlerParams = {
  index: number,
  name?: string | number,
  ref?: MutableRefObject<HTMLDivElement | null>
}
export type SwiperSlideOnActiveHandler = (args: SwiperSlideOnActiveHandlerParams) => void;

interface SwiperSlideProps {
  className?: string;
  fillHeight?: boolean;
} 
interface SwiperSlidePropsWithHandlers extends SwiperSlideProps {
  name?: string | number;
  index: number;
  onActivate?: SwiperSlideOnActiveHandler;
}

function SwiperSlide(props: PropsWithChildren<SwiperSlidePropsWithHandlers>): React.ReactElement;
function SwiperSlide(props: PropsWithChildren<SwiperSlideProps>): React.ReactElement;
function SwiperSlide(props: PropsWithChildren<SwiperSlidePropsWithHandlers | SwiperSlideProps>): React.ReactElement {
  const swiperContext = useSwiperContext();
  const ref = useRef<HTMLDivElement>(null);
  useOnMount(() => {
    (async () => {
      await tick();
      swiperContext.onInit(swiper => {
        swiper.on('slideChange', () => {
          if ('name' in props && swiper.activeIndex === props.index) {
            props.onActivate?.({ index: props.index, name: props.name, ref });
          }
        })
      })
    })()
  })
  return <Observer children={() => (
    <div className={
      joinClassName(
        'SwiperSlide swiper-slide', 
        props.className,
        props.fillHeight && 'fillHeight'
      )
    } ref={ref}>
      {props.children}
    </div>
  )} />
}

export default SwiperSlide;