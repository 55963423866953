import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseButton from '../../../../base/components/BaseButton/BaseButton';
import DateRenderer from '../../../../base/components/DateRenderer/DateRenderer';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import { useStore } from '../../../../base/utils/mobx.utils';
import { setUrlParam } from '../../../../base/utils/urlParams.utils';
import UsernameRenderer from '../../../../components/UsernameRenderer/UsernameRenderer';
import { CounsellingApplication, CounsellingApplicationSnapshot } from '../../../../models/makeCounsellingApplication.model';
import { CounsellingSession } from '../../../../models/makeCounsellingSession.model';
import CounsellingClientProgressGraph from '../CounsellingClientProgressGraph/CounsellingClientProgressGraph';
import './CounsellorApplicationsOverviewItem.scss';

interface CounsellorApplicationsOverviewItemProps {
  application: CounsellingApplicationSnapshot,
}

const CounsellorApplicationsOverviewItem: React.FC<CounsellorApplicationsOverviewItemProps> = props => {
  
  const s = useStore(() => ({
    sessions: [] as CounsellingSession[],
    get sessionsWithCompletedAssignments() {
      return s.sessions.filter(ses => ses.assignments?.some(ass => !!ass.target));
    },
  }));

  const { NAVIGATOR } = useControllers();

  const viewDetails = () => {
    setUrlParam('manageApplicationId', props.application.id, NAVIGATOR);
  }

  return <Observer children={() => {
    const a = props.application;
    return <div className="CounsellorApplicationsOverviewItem">
      <header className="CounsellorApplicationsOverviewItemHeader">
        <div className="CounsellorApplicationsOverviewItemHeaderInner">
          <h4>Application #{a.id} by <UsernameRenderer user={(a as CounsellingApplication).applicant} userId={a.applicantId} /> on <DateRenderer value={a.timeCreated} /></h4>
          <p>
            {a.timeArchived && <><strong>Archived on </strong> <DateRenderer value={a.timeArchived} /></>}
            {a.timeCompleted && <> | <strong>Completed on </strong> <DateRenderer value={a.timeCompleted} /></>}
          </p>
        </div>
        <div className="CounsellorApplicationsOverviewItemHeaderControls">
          <BaseButton onClick={viewDetails} size="sm" className="subtle">View Details</BaseButton>
        </div>
      </header>
      {s.sessionsWithCompletedAssignments.length > 0 ?
        <CounsellingClientProgressGraph application={a} sessions={s.sessions} />
        : (s.sessions.length === 0 ? <p><em>This application has no sessions scheduled.</em></p>
          : <p><em>This application has no completed surveys.</em></p>
        )
      }
    </div>
  }} />
}

export default CounsellorApplicationsOverviewItem;