import { Observer } from 'mobx-react-lite';
import React from 'react';
import { CountryCode, CountryDescriptors } from '../../constants/countries.constants';
// import './CountryRenderer.scss';

interface CountryRendererProps {
  countryId?: CountryCode | null;
  defaultValue?: string;
  showFlag?: boolean;
}

const CountryRenderer: React.FC<CountryRendererProps> = p => {
  const def = p.countryId ? CountryDescriptors[p.countryId] : undefined;
  return <Observer children={() => (
    p.countryId ? <span className="CountryRenderer">
      {def?.flag && p.showFlag && <span>{def.flag} </span>}
      <span>{def?.name || (p.defaultValue ?? `Unknown Country ${p.countryId}`)}</span>
    </span> : null
  )} />
}

export default CountryRenderer;