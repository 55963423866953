import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { ColorCodedState } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import DurationRenderer from '../../base/components/DurationRenderer/DurationRenderer';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import InfoBanner from '../../base/components/InfoBanner/InfoBanner';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import TelLinkRenderer from '../../base/components/TelLinkRenderer/TelLinkRenderer';
import { CounsellingSessionEndpoints } from '../../base/endpoints/counsellingSession.endpoints';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { makeActionConfig } from '../../base/utils/actionConfig.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { YYYYMMDDHHmmss } from '../../base/utils/time.utils';
import { ApiModelName } from '../../constants/ApiModels.enum';
import { ModelName } from '../../constants/modelNames.enum';
import { CounsellingApplication } from '../../models/makeCounsellingApplication.model';
import { CounsellingSession } from '../../models/makeCounsellingSession.model';
import { User } from '../../models/makeUser.model';
import BrowserRecommendationList from '../BrowserRecommendationList/BrowserRecommendationList';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import './CounsellingSessionStarter.scss';

interface CounsellingSessionStarterProps {
  application?: CounsellingApplication | null,
  session: CounsellingSession,
  client?: User,
}

const CounsellingSessionStarter: React.FC<CounsellingSessionStarterProps> = props => {

  const p = useProps(props);

  const { UI, API, AUTH, COMMON, VOIP } = useControllers();

  const s = useStore(() => ({
    get session() {
      return p.session
    },
    get client() {
      return p.client
    },
    get counsellorId() {
      return s.session.counsellorId || p.application?.counsellorId
    },
    get isNew() {
      return !s.session?.id;
    },
    get thisIsTheDay() {
      return s.session?.timeScheduled ? moment(s.session.timeScheduled).isSame(COMMON.CLOCK.localNowMoment) : false;
    },
    get canStartSession() {
      return !s.isNew &&
        !!s.session?.timeScheduled &&
        !s.session?.timeStarted
    },
    get isTextChat() {
      return s.session?.type === 'text';
    },
    get isVideo() {
      return s.session?.type === 'video';
    },
    get canStartChatSession() {
      return s.canStartSession && (s.isTextChat || s.isVideo);
    },
    get canStartVoipSession() {
      return s.canStartSession &&
        s.client &&
        s.session?.type === 'voip';
    },
    get currentUserIsTheAssignedCounsellor() {
      return s.counsellorId === AUTH.currentUser?.id;
    },
    get scheduledTimeMoment() {
      return s.session?.timeScheduled ? moment.utc(s.session?.timeScheduled) : undefined;
    },
    get scheduledTimeDiffFromNow() {
      return s.scheduledTimeMoment ? COMMON.CLOCK.localNowMoment.diff(s.scheduledTimeMoment, 'seconds') : -Infinity;
    },
    get tooEarly() {
      return s.scheduledTimeDiffFromNow <= -15 * 60;
    },
    get isWithin15MinutesBeforeScheduledTime() {
      return s.scheduledTimeDiffFromNow > -15 * 60 && s.scheduledTimeDiffFromNow < 0;
    },
    get isWithin15MinutesAfterScheduledTime() {
      return s.scheduledTimeDiffFromNow < 15 * 60 && s.scheduledTimeDiffFromNow >= 0;
    },
    get tooLate() {
      return s.scheduledTimeDiffFromNow >= 15 * 60;
    },
    get shouldDisableStartSessionButton() {
      return VOIP.hasLiveVoipCalls;
    },
    get assignments() {
      return s.session?.assignments;
    },
    get paymentAssignment() {
      return s.assignments && s.assignments.find(a => a.targetType === ApiModelName.PAYMENT);
    },
    get paymentId() {
      return s.paymentAssignment && s.paymentAssignment.targetId
    },
    get isPaid() {
      return !!s.paymentId;
    },
    get isPaymentEnforcedManuallyByCounsellor() {
      return s.session?.isPaymentEnforcedManuallyByCounsellor;
    },
  }));

  const makeConfirmButton = () => ({
    name: 'confirm',
    label: 'Start Session',
    action: () => startSession()
  })

  const finalConfirmDialog = (body: () => React.ReactElement) => {
    UI.DIALOG.positive({
      heading: 'Session start confirmation',
      body,
      defaultActions: ['negative'],
      actions: [makeConfirmButton()]
    })
  }

  const confirmStartChatSession = async () => {
    if (!(await confirmIfUnpaid())) return;
    if (!(await confirmStartIfTooEarly())) return;
    if (!(await confirmStartIfTooLate())) return;
    if (!(await confirmSetupCorrect())) return;
    if (s.currentUserIsTheAssignedCounsellor) {
      finalConfirmDialog(() => <>
        <p>Please note that the client will be notified immediately to join you.</p>
        <p>A chat window will show up when this session starts. You can also find the chat window in the "Chats" tab.</p>
      </>)
    } else {
      finalConfirmDialog(() => <>
        <p><strong>Are you sure you want to start this session? The session is assigned to counsellor <UsernameRenderer userId={s.counsellorId} />.</strong></p>
        <p>Instead of starting this session for the assigned counsellor, maybe you meant to update the counsellor to yourself first and then start the session?</p>
      </>)
    }
  }

  const partialConfirmDialog = (body: () => React.ReactElement) => new Promise<boolean>(async (resolve, reject) => {
    UI.DIALOG.attention({
      heading: 'Are you sure you want to start the session?',
      body,
      actions: [
        makeActionConfig('Cancel', () => resolve(false), { buttonClass: 'subtle' }),
        makeActionConfig('Proceed and start session', () => resolve(true))
      ]
    })
  });

  const confirmSetupCorrect = () => new Promise<boolean>(async (resolve, reject) => {
    return await UI.DIALOG.attention({
      heading: 'Are you set up correctly?',
      body: <>
        <p>Before you begin, please make sure you are set up correctly:</p>
        <ul>
          <li>
            You are using the latest browser version.
            <BrowserRecommendationList />
          </li>
          <li>
            You have allowed the app.turn2me.ie domain browser access to your camera and microphone.
          </li>
        </ul>
        <p>During the session, if you or your client(s) encountered technical issues, please try refreshing the page. The session will not end, and you will be reconnected to the ongoing session after the page refresh.</p>
      </>,
      actions: [
        makeActionConfig('Cancel', () => resolve(false), { buttonClass: 'subtle' }),
        makeActionConfig('Proceed and start session', () => resolve(true))
      ]
    })
  })

  const confirmIfUnpaid = () => new Promise<boolean>(async (resolve, reject) => {
    const { amountPayable } = s.session;
    if (!amountPayable) { resolve(true); return; }
    if (s.isPaid) { resolve(true); return; }
    resolve(await partialConfirmDialog(() => <>
      <p>The client has not {s.session.isPaidSession ? 'paid' : 'donated'} for the session. You can still proceed with the session if necessary.</p>
    </>))
  })

  const confirmStartIfTooEarly = () => new Promise<boolean>(async (resolve, reject) => {
    if (!s.tooEarly) { resolve(true); return; }
    resolve(await partialConfirmDialog(() => <>
      <p>It is still a little early. The scheduled time is <strong><DateRenderer value={s.scheduledTimeMoment} format="LLLL" /></strong>.</p>
      <p>If the client is aware of this, you can still proceed and start the session.</p>
    </>))
  })

  const confirmStartIfTooLate = () => new Promise<boolean>(async (resolve, reject) => {
    if (!s.tooLate) { resolve(true); return; }
    resolve(await partialConfirmDialog(() => <>
      <p>The scheduled time was on <strong><DateRenderer value={s.scheduledTimeMoment} format="LLLL" /></strong> and it has been a while since this time passed ({s.scheduledTimeMoment && s.scheduledTimeMoment.fromNow()}).</p>
      <p>If the client is aware of this, you can still proceed and start the session.</p>
    </>))
  })

  const startSession = () => new Promise<boolean>(async (resolve, reject) => {
    if (!s.session?.id) {
      throw new Error('An ID must be present in the unedited copy of the session in order to start.');
    }
    try {
      const url = CounsellingSessionEndpoints.staff.start(s.session.id);
      await API.post(url, ModelName.counsellingSessions);
      resolve(true);
      UI?.DIALOG.positive({
        name: 'session-started-notice',
        heading: 'The session has started.',
        body: <>You can find this session in the <strong>Chats</strong> panel.</>,
        actions: [
          makeActionConfig(
            'OK',
            action(() => {
              if (s.session) s.session.timeStarted = moment().utc().format(YYYYMMDDHHmmss);
              handleOverlayProgrammaticClose();
            })
          )
        ]
      })
    } catch (e) {
      UI?.DIALOG.error({
        heading: 'The session failed to start.',
        body: () => <>
          <p>Please try again. If this error persists, please contact the development team.</p>
          <ErrorRenderer error={(e as any).response} />
        </>
      })
      reject(e);
    }
  });

  const startVoipSession = async (phone?: string, user?: User) => {
    const phoneNumber = phone || p.application?.applicant?.mobileNumber;
    if (!phoneNumber) {
      throw Error('A phone number is required for VOIP to start');
    }
    if (!(await confirmIfUnpaid())) return;
    if (!(await confirmStartIfTooEarly())) return;
    if (!(await confirmStartIfTooLate())) return;
    VOIP.startVoipCall(phoneNumber, user);
  }

  const handleStartVoipSession = () => {
    startVoipSession(undefined, s.client);
  }

  const handleOverlayProgrammaticClose = () => {
    UI.OVERLAY.dismiss('OverlayCounsellingSessionManager');
  }

  return <Observer children={() => (
    <div className="CounsellingSessionStarter">

      {s.isPaymentEnforcedManuallyByCounsellor && (
        <InfoBanner colorCodedState={ColorCodedState.positive} icon="info">
          <p>You have chosen to enforce session payment collection for this session. The client will be prompted to pay for the session every few minutes.</p>
        </InfoBanner>
      )}

      { (s.canStartVoipSession || s.canStartChatSession) && <>
        {s.tooEarly && (
          <InfoBanner colorCodedState={ColorCodedState.attention} icon="warning">
            <p>You should only start a session within 15 minutes before the scheduled time or shortly after.</p>
            {
              s.thisIsTheDay && <p><strong>Time to scheduled time: <DurationRenderer startTime={COMMON.CLOCK.localNowMoment} endTime={s.scheduledTimeMoment} /></strong></p>
            }
          </InfoBanner>
        )}
        {s.tooLate && (
          <InfoBanner colorCodedState={ColorCodedState.attention} icon="warning">
            <p>It has been more than 15 minutes pass the scheduled time; you might want to check with the client before starting the session.</p>
            <p><strong>Scheduled time: <DateRenderer value={s.scheduledTimeMoment} format="LLLL" /> ({s.scheduledTimeMoment && s.scheduledTimeMoment.fromNow()})</strong></p>
          </InfoBanner>
        )}
      </>}

      {s.canStartChatSession && <>
        <ShadedBlock color={s.isVideo ? 'red' : 'skyblue'} spaceChildren>
          {
            s.currentUserIsTheAssignedCounsellor ? <>
              <h4>Start This {s.isVideo ? 'Video' : ''} Chat Session</h4>
              <p>If you are ready to start the session, click the button below. The client will be notified to join.</p>
            </> : <>
                <h4>This session is assigned to <UsernameRenderer userId={s.counsellorId} />.</h4>
              </>
          }
          <BaseButton onClick={confirmStartChatSession} icon={s.isVideo ? 'video' : 'chat'} dataCy="startSession">Start Session</BaseButton>
        </ShadedBlock>
      </>}

      {s.canStartVoipSession && <>
        <ShadedBlock color="orange" spaceChildren>
          {s.currentUserIsTheAssignedCounsellor ? <>
            <h4>Start This VOIP Session</h4>
            <p>The client's registered phone number on the application is <TelLinkRenderer value={p.application?.applicant?.mobileNumber} />.</p>
            <p>Click the button below to start a VOIP call with the client. If you need to use a different number, please start the session by entering a phone number in the Chats &amp; Sessions sidebar.</p>
          </> : <>
              <h4>This VOIP session is assigned to <UsernameRenderer userId={s.counsellorId} />.</h4>
              <p>If you'd like to start the session, you should reassign the counsellor to be yourself first in the form below and save your changes.</p>
            </>
          }
          <BaseButton onClick={handleStartVoipSession} icon="phone" color="green" disabled={s.shouldDisableStartSessionButton} dataCy="startSession">Start Session</BaseButton>
        </ShadedBlock>
      </>}

    </div>
  )} />

}

export default CounsellingSessionStarter;