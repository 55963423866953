import { SupportGroupEndpoints } from "../base/endpoints/supportGroup.endpoints";
import { APIController } from "../controllers/api.controller";
import { SupportGroupReservationSnapshot } from "../models/makeSupportGroupReservation.model";

export function registerSupportGroup(API: APIController, groupId: string, payload?: Partial<SupportGroupReservationSnapshot>) {
  return new Promise<object>(async (resolve, reject) => {
    try {
      const url = SupportGroupEndpoints.client.register(groupId);
      const response = await API.postRaw(url, payload);
      resolve(response);
    } catch(e) {
      reject(e);
    }
  })
}

export function deregisterFromSupportGroup(API: APIController, groupId: string) {
  return new Promise<object>(async (resolve, reject) => {
    try {
      const url = SupportGroupEndpoints.client.deregister(groupId);
      const response = await API.postRaw(url);
      resolve(response);
    } catch(e) {
      reject(e);
    }
  })
}