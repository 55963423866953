import React from 'react';
import ColorTag from './ColorTag';

interface ColorTagFreeSessionProps {
  useAbbr?: boolean
}

const ColorTagFreeSession: React.FC<ColorTagFreeSessionProps> = props => {
  return <ColorTag className="ColorTagFreeSession" color="blueGreen">
    Free
  </ColorTag>
}

export default ColorTagFreeSession;