import { Observer } from 'mobx-react-lite';
import React from 'react';
import RedirectToClientModule from '../../../components/RedirectToClientModule/RedirectToClientModule';
import './PageLanding.scss';

interface PageLandingProps {}

const PageLanding: React.FC<PageLandingProps> = p => {

  return <Observer children={() => (
    <div className="PageLanding">

      {/* {
        isDevelopment && <>
          <header>
            <Turn2MeLogo version="full" />
            <h1>This is a placeholder homepage.</h1>
            <h3>On production website, this page should automatically be handled by WordPress.</h3>
            <BaseButton to="/app/explore" size="lg">Go to app dashboard</BaseButton>
          </header>

          <footer>
            <p><strong>© 2021 turn2me.ie. All rights reserved.</strong></p>
          </footer>
        </>
      } */}

      <RedirectToClientModule />
      
    </div>
  )}/>
}


export default PageLanding;