import { useControllers } from "../base/hooks/useRootController.hook";
import { useStore } from "../base/utils/mobx.utils";

export const useCurrentUserEligibility = () => {
  const { COMMON, AUTH } = useControllers();

  const s = useStore(() => ({
    get ready() {
      return s.detectedCountryCode && AUTH.isAuthenticated;
    },
    get detectedCountryCode() {
      return COMMON.countryCode;
    },
    get user() {
      return AUTH.currentUser;
    },
    get currentUserCountryProvidedId() {
      return s.user?.countryProvidedId;
    },
    get currentUserCountryProvidedIdIsIEUK() {
      return s.currentUserCountryProvidedId === 'IE' || s.currentUserCountryProvidedId === 'GB';
    },
    get detectedCountryIsIEUK() {
      return s.detectedCountryCode === 'IE' || s.detectedCountryCode === 'GB';
    },
    get isEligible() {
      return s.currentUserCountryProvidedIdIsIEUK || s.detectedCountryIsIEUK
    },
    get hasVerifiedEmail() {
      return Boolean(s.user?.email && s.user?.timeVerifiedEmail);
    },
    get hasVerifiedPhone() {
      return Boolean(s.user?.mobileNumber && s.user?.timeVerifiedMobileNumber);
    },
    get hasVerified() {
      return s.hasVerifiedEmail || s.hasVerifiedPhone;
    }
  }))

  return s;

}