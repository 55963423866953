import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { ColorCodedState } from '../../../base/@types';
import BaseButton from '../../../base/components/BaseButton/BaseButton';
import BaseInput from '../../../base/components/BaseInput/BaseInput';
import BaseSpacer from '../../../base/components/BaseSpacer/BaseSpacer';
import ErrorRenderer from '../../../base/components/ErrorRenderer/ErrorRenderer';
import InfoBanner from '../../../base/components/InfoBanner/InfoBanner';
import LinkGroup from '../../../base/components/LinkGroup/LinkGroup';
import WindowTitle from '../../../base/components/WindowTitle/WindowTitle';
import { AuthEndpoints } from '../../../base/endpoints/auth.endpoints';
import { useOnMount } from '../../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../../base/hooks/useRootController.hook';
import { useUpdatePageBackground } from '../../../base/hooks/useSetPageBackground.hook';
import { makeActionConfig } from '../../../base/utils/actionConfig.utils';
import { useStore } from '../../../base/utils/mobx.utils';
import { getUrlParams } from '../../../base/utils/urlParams.utils';
import { makePasswordValidator } from '../../../base/validators/password.validator';
import Turn2MeLogo from '../../../components/Turn2MeLogo/Turn2MeLogo';
import AuthForm from '../_components/AuthForm/AuthForm';
import AuthPage from '../_components/AuthPage/AuthPage';
import './PageResetPassword.scss';

interface PageResetPasswordProps {}

const PageResetPassword: React.FC<PageResetPasswordProps> = props => {

  const { API, UI, NAVIGATOR } = useControllers();

  const match = useRouteMatch<{token: string}>();
  const token = match.params.token;

  const s = useStore(() => ({
    form: {
      token,
      username: '',
      password: '',
      password_confirmation: '',
    },
    params: getUrlParams(),
    passwordValidator: makePasswordValidator({ minLength: 8 }),
    get passwordValidatorResult() {
      return s.passwordValidator(s.form.password);
    },
    get passwordIsValid() {
      return s.passwordValidatorResult === true;
    },
    get passwordMatches() {
      return s.form.password === s.form.password_confirmation;
    },
    confirmationFieldTouched: false,
    get shouldShowPasswordMatchesError() {
      return s.passwordIsValid && !s.passwordMatches;
    },
    get username() {
      return s.params.username;
    },
    get formValid() {
      return Boolean(s.form.username && s.passwordIsValid && s.passwordMatches && s.form.token);
    },
    get linkInvalid() {
      return !token || !s.username;
    },
    submit: async () => {
      const url = AuthEndpoints.password.reset();
      try {
        await API.postRaw(url, s.form);
        UI.DIALOG.success({
          heading: 'Your password has been reset successfully.',
          actions: [
            makeActionConfig('Log in', () => NAVIGATOR.navigateTo('/auth/login'))
          ]
        })
      } catch(e) {
        UI.DIALOG.error({
          heading: 'Failed to reset password',
          body: <ErrorRenderer error={(e as any).response} />
        })
      }
      return;
    },
    handleConfirmationFieldChange: action(() => {
      if (s.form.password_confirmation.length > 4) s.confirmationFieldTouched = true;
    })
  }));

  useOnMount(() => {
    if (s.linkInvalid) {
      UI.DIALOG.error({
        heading: 'Hmm, the reset link seems invalid. Please acquire a new link and try again.',
      });
      NAVIGATOR.navigateTo('/auth/forgot-password');
    }
    if (s.username) {
      s.form.username = s.username;
    }
  })
  useUpdatePageBackground('#0151ca', 'radial-gradient(204.06% 99.27% at 51.45% 117.53%, rgba(55, 218, 255, 0.863) 1.04%, rgba(55, 218, 255, 0) 100%)');

  return <Observer children={() => (

    <AuthPage className="PageResetPassword">

      <header className="PageResetPasswordHeader">
        <Turn2MeLogo version="full" />
        <WindowTitle title="Reset Password" />
        <h1>Reset Password</h1>
      </header>

      <div className="PageResetPasswordContent">
        <AuthForm>
          <p>Enter your username and a new password to continue. The password must be at least 8 characters.</p>
          <BaseSpacer size="0.1em"/>
          <BaseInput form={s.form} field="username" label="Username" autoCapitalize="off" autoCorrect="off" autoFocus />
          <BaseInput form={s.form} type="password" field="password" label="New Password"/>
          <BaseInput form={s.form} type="password" field="password_confirmation" label="Confirm Password" onChange={s.handleConfirmationFieldChange}/>
          <BaseSpacer size="0.1em"/>
          {s.form.password.length > 0 && s.passwordValidatorResult !== true && <InfoBanner icon="warning" colorCodedState={ColorCodedState.warning}>{s.passwordValidatorResult.message}</InfoBanner>}
          {s.shouldShowPasswordMatchesError && <InfoBanner icon="warning" colorCodedState={ColorCodedState.warning}>The two password fields must match.</InfoBanner>}
          <BaseButton className="AuthFormSubmit" onClick={s.submit} size="lg" icon="arrow" name="get-reset-password-link" circle disabled={!s.formValid}/>
        </AuthForm>
      </div>

      <footer className="PageResetPasswordFooter">
        <LinkGroup allCaps noUnderscore direction="column">
          <Link to="/auth/login" title="Log in">→ Log in</Link>
          <Link to="/auth/register" title="Create free account">→ Create free account</Link>
        </LinkGroup>
      </footer>

    </AuthPage>

  )} />

}

export default PageResetPassword;