import React from 'react';
import {Nillable} from '../../@types';
import BaseIcon from '../BaseIcon/BaseIcon';
import ColorTag from '../ColorTag/ColorTag';
import './ColorTagCompany.scss';

interface ColorTagCompanyProps {
    name: Nillable<string>,
    code: Nillable<string>,
}

const ColorTagCompany: React.FC<ColorTagCompanyProps> = props => {

    return !!props.name
        ? <ColorTag className="ColorTagCompany breakWord" color="dark" appearance="translucent">
            <BaseIcon size="1.6em" icon={(props.code === 'UKR') ? 'ukraine-flag' : 'suitcase'}/> {props.name}
        </ColorTag>
        : null
}

export default ColorTagCompany;