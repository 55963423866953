import { action, flow, observable } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import FormFacilitatorSelector from '../../../components/FormFacilitatorSelector/FormFacilitatorSelector';
import SupportGroupSummaryCard from '../../../components/SupportGroupSummaryCard/SupportGroupSummaryCard';
import { ModelName } from '../../../constants/modelNames.enum';
import { makeSupportGroupSnapshotBase, PreviewSupportGroup } from '../../../models/makeSupportGroup.model';
import { SupportGroupTopic } from '../../../models/makeSupportGroupTopic.model';
import { User } from '../../../models/makeUser.model';
import { AgeGroupFilterTypeSelectorOptions } from '../../../utils/ageAndDateOfBirth.utils';
import { Nullable } from '../../@types';
import { SupportGroupTopicEndpoints } from '../../endpoints/supportGroupTopic.endpoints';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useControllers } from '../../hooks/useRootController.hook';
import { makeForm } from '../../mediators/form.mediator';
import { reportError } from '../../utils/errors.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { slugify } from '../../utils/string.utils';
import { createUTCMoment, YYYYMMDDHHmmss } from '../../utils/time.utils';
import BaseButton from '../BaseButton/BaseButton';
import BaseGrid from '../BaseGrid/BaseGrid';
import BaseGridCell from '../BaseGrid/BaseGridCell';
import BaseHeading from '../BaseHeading/BaseHeading';
import BaseLabel from '../BaseLabel/BaseLabel';
import BaseSpacer from '../BaseSpacer/BaseSpacer';
import FormColorPicker from '../FormColorPicker/FormColorPicker';
import FormForm from '../FormForm/FormForm';
import FormInput from '../FormInput/FormInput';
import FormSelector from '../FormSelector/FormSelector';
import './SupportGroupTopicEditor.scss';

type SupportGroupTopicEditorProps = {
  topic: SupportGroupTopic,
  onSave?: (topic: SupportGroupTopic) => void;
}

const SupportGroupTopicEditor: React.FC<SupportGroupTopicEditorProps> = props => {
  const p = useProps(props);
  const { UI, LOCALDB, AUTH, API } = useControllers();
  const s = useStore(() => ({
    form: makeForm(p.topic.$snapshot),
    generateSlug: () => {
      s.form.set('slug', slugify(s.form.value.displayName));
    },
    handleDisplayNameBlur: () => {
      if (!s.form.value.slug) s.generateSlug();
    },
    handleFormChange: action(() => {
    }),
    get isNew() {
      return !Boolean(s.form.value.id);
    },
    previewGroup: null as Nullable<PreviewSupportGroup>,
    get saveButtonLabel() {
      return s.isNew ? 'Create Topic' : 'Save Changes';
    },
    isSaving: false,
    errorSaving: null as Nullable<Error>,
    save: async () => await flow(function* () {
      try {
        s.isSaving = true;
        const url = SupportGroupTopicEndpoints.staff[s.isNew ? 'create' : 'update'](p.topic.id);
        const payload = s.form.value;
        const savedTopic: SupportGroupTopic = s.isNew ? yield API.post(url, ModelName.supportGroupTopics, payload) : yield API.patch(url, ModelName.supportGroupTopics, payload);
        s.isSaving = false;
        p.onSave?.(savedTopic);
        s.form.reset(savedTopic.$snapshot);
        UI.TOAST.success('Support group topic saved!');
      } catch(e) {
        reportError(e);
        s.errorSaving = e as Error;
        UI.DIALOG.error({
          heading: 'Failed to save support group topic',
          error: e,
        })
      }
    })(),
    viewAllGroups: () => {
      
    }
  }));
  useOnMount(action(() => {
    s.previewGroup = observable({
      ...makeSupportGroupSnapshotBase(),
      timeScheduled: createUTCMoment().add(1, 'hour').endOf('hour').format(YYYYMMDDHHmmss),
      isPreview: true,
      get title() { return s.form.value.defaultGroupTitle || s.form.value.displayName },
      get subtitle() { return s.form.value.defaultGroupSubtitle; },
      get color() { return s.form.value.color; },
      get topic() { return s.form.value; },
      get maxParticipants() { return s.form.value.defaultMaxParticipants; },
      get description() { return s.form.value.defaultPublicDescription; },
      get facilitator() { return s.form.value.defaultFacilitatorId ? LOCALDB.get<User>(ModelName.users, s.form.value.defaultFacilitatorId) : AUTH.currentUser!; },
      get ageGroups() { return s.form.value.defaultAgeGroups; }
    })
  }))
  return <Observer children={() => (
    <FormForm 
      className='SupportGroupTopicEditor'
      form={s.form} 
      color={s.form.value.color || 'blueGreen'}
      disabled={s.isSaving}
      children={context => <div className="SupportGroupTopicEditorInner">
        {UI.cssFeatures.grid && <aside className="SupportGroupTopicEditorPreviewWrapper">
          <BaseHeading>Preview</BaseHeading>
          {s.previewGroup && <SupportGroupSummaryCard supportGroup={s.previewGroup} />}
        </aside>}
        <BaseGrid
          className="SupportGroupTopicEditorForm"
          columns={context.resizeQuery?.onlyPhones ? 1 : 2}
        >
          <BaseGridCell columns="all">
            <BaseHeading>Topic Setup</BaseHeading>
          </BaseGridCell>
          <BaseGridCell columns="all">
            <FormInput form={s.form} field="displayName" label="Display Name" onBlur={s.handleDisplayNameBlur} required />
          </BaseGridCell>
          <BaseGridCell columns="all">
            <BaseLabel children="Identifier Name (URL Slug)" required />
            <div className="SupportGroupTopicEditorSlugFieldGroup">
              <FormInput form={s.form} field="slug" type="url" required />
              <BaseButton onClick={s.generateSlug} fullWidth={UI.onlyPhones} dataCy="Generate slug from display name">Generate slug from display name</BaseButton>
            </div>
          </BaseGridCell>
          <BaseGridCell columns="all">
            <FormColorPicker form={s.form} field="color" label="Accent Color" optional/>
          </BaseGridCell>
          <BaseGridCell columns="all">
            <FormInput form={s.form} field="internalDescription" type="textarea" label="Internal Topic Description" optional />
          </BaseGridCell>

          <BaseGridCell columns="all">
            <BaseSpacer size=".5em" />
            <BaseHeading>New group setup defaults</BaseHeading>
            <p>Groups in this topic will be created with the following default values, if provided. Facilitators can override them in each group instance.</p>
          </BaseGridCell>
          <BaseGridCell columns="all">
            <FormFacilitatorSelector form={s.form} field="defaultFacilitatorId" label="Assign to Facilitator" optional />
          </BaseGridCell>
          <FormInput form={s.form} field="defaultGroupTitle" label="Default Group Title" optional />
          <FormInput form={s.form} field="defaultGroupSubtitle" label="Default Group Subtitle" optional />
          <FormInput form={s.form} field="defaultMaxParticipants" type="number" step="1" min="0" label="Max No. of Participants" optional />
          <FormInput form={s.form} field="defaultScheduledDurationInMinutes" type="number" step="1" min="0" label="Duration (minutes)" optional />
          <BaseGridCell columns="all">
            <FormSelector
              form={s.form}
              field="defaultAgeGroups"
              options={AgeGroupFilterTypeSelectorOptions}
              label="Age Groups"
              optional
              infoAfterInputField="* Remember that age groups set here can be overridden in actual groups."
            />
          </BaseGridCell>
          <BaseGridCell columns="all">
            <FormInput type="textarea" rows="7" form={s.form} field="defaultPublicDescription" label="Description" optional />
          </BaseGridCell>
        </BaseGrid>
        <footer className="SupportGroupTopicEditorFooter">
          <BaseHeading>Controls</BaseHeading>
          <div className="SupportGroupTopicEditorFooterControls">
            <BaseButton dataCy="Create Topic" label={s.saveButtonLabel} onClick={s.save}/>
          </div>
        </footer>
      </div>
    } />
  )} />
}

export default SupportGroupTopicEditor;