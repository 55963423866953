import { observable } from "mobx";
import React, { MutableRefObject, useContext } from "react";
import { AnyObject, Nillable } from "../../@types";
import { useCreateResizeQueryWithRef } from "../../hooks/useCreateResizeQueryWithRef.hook";
import { ResizeQuery } from "../../hooks/useResizeQuery";
import { Form } from "../../mediators/form.mediator";
import { generateUuid } from "../../utils/id.utils";
import { useAutoSyncWithInitializer } from "../../utils/mobx.utils";
import { FormFormHandlers, FormFormProps } from "./FormForm";

export const createFormContext = <T extends AnyObject>(
  options: {
    id?: string,
    name?: string,
    form?: Form<T>,
    formRef?: Nillable<MutableRefObject<HTMLFormElement | null>>,
    resizeQuery?: Nillable<ResizeQuery>,
    disabled?: boolean,
  } & FormFormHandlers<T>,
) => {
  const s = observable({
    id: options.id || generateUuid(),
    get name(): string { return options.name || `Form-${s.id}` },
    get form() { return options.form },
    get formRef() { return options.formRef },
    get resizeQuery() { return options.resizeQuery },
    get commonUnits() {
      return {
        gap: options.resizeQuery?.fromTablet ? '1em' : '.5em',
        paddingX: options.resizeQuery?.fromTablet ? '2em' : '1em',
        paddingY: options.resizeQuery?.fromTablet ? '1.5em' : '1em',
      }
    },
    get disabled() { return options.disabled },
    get onFocus() { return options.onFocus && (() => options!.onFocus!(options!.form!)) },
    get onBlur() { return options.onBlur && (() => options!.onBlur!(options!.form!)) },
    get onChange() { return options.onChange && (() => options!.onChange!(options!.form!)) },
    get onSave() { return options.onSave && (() => options!.onSave!(options!.form!)) },
    get onReset() { return options.onReset && (() => options!.onReset!(options!.form!)) },
  });
  return s;
}

export const useCreateFormContextAndResizeQuery = <T extends AnyObject>(p: FormFormProps<T>) => {
  const { ref, query } = useCreateResizeQueryWithRef<HTMLFormElement>();
  const s = useAutoSyncWithInitializer(() => ({
    ...p,
    get formRef() { return ref },
    get resizeQuery() { return query },
  }), p);
  return {
    context: createFormContext<T>(s),
    ref,
    query
  };
}

export const DefaultFormContextContent = createFormContext({});

export type FormContextContent<T = AnyObject> = {
  id: string,
  name: string,
  form: Form<T>,
  formRef: Nillable<MutableRefObject<HTMLFormElement | null>>,
  resizeQuery?: Nillable<ResizeQuery>,
  commonUnits: {
    gap: string,
    paddingX: string,
    paddingY: string,
  },
  disabled?: boolean,
  onFocus?: () => void,
  onBlur?: () => void,
  onSave?: () => void,
  onReset?: () => void,
}

export const FormContext = React.createContext(DefaultFormContextContent);
export const useFormContext = <T extends AnyObject>() => useContext(FormContext) as FormContextContent<T>;
