import { AlertSnapshot, AlertSubtype } from "../@types/alert.types";
import { AnyObject, SnapshotOf } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { AlertTypeName } from "../constants/alertType.enum";
import { ModelName } from "../constants/modelNames.enum";
import { LocalDBController } from "../controllers/localDB.controller";

/**
 * NOTES:
 * chats and messages do not generate alerts.
 */

export const makeAlertSnapshotBase = <T extends AlertTypeName = AlertTypeName.Empty, K extends AnyObject = AnyObject>() => ({
  id: '',
  type: AlertTypeName.Empty,
  userId: '',
  data: {} as K,
  timeRead: '',
  timeCreated: '',
  timeUpdated: '',
} as AlertSnapshot<T>)

export const makeAlert = <SubType extends AlertSubtype = AlertSubtype>(
  snapshot?: Partial<SnapshotOf<SubType>>,
  localDB?: LocalDBController,
) => createStandardModelFactory({
  name: ModelName.alerts,
  snapshotFactory: makeAlertSnapshotBase,
  extendedPropertiesFactory: (n, $, localDB) => ({
    get isRead() {
      return Boolean((n as AlertSubtype).timeRead)
    },
    get isUnread() {
      return !Boolean((n as AlertSubtype).timeRead)
    },
  })
})(snapshot, localDB) as SubType;