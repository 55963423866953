import { when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import AppPage from '../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../base/components/AppPageHeader/AppPageHeader';
import OverlayCloseButton from '../../../../base/components/OverlayCloseButton/OverlayCloseButton';
import UIBlock from '../../../../base/components/UIBlock/UIBlock';
import { useOnMount } from '../../../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import { useSyncUrlParams } from '../../../../base/utils/urlParams.utils';
import { User } from '../../../../models/makeUser.model';
import { useGetUser } from '../../../../requests/useGetUser.request';
import UserEditorForAdmin from '../UserEditorForAdmin/UserEditorForAdmin';
// import './OverlayUserEditor.scss';

interface OverlayUserEditorProps {
  user?: User,
  userId?: string,
}

const OverlayUserEditor: React.FC<OverlayUserEditorProps> = props => {

  const { UI, AUTH, STAFF } = useControllers();

  const p = useProps(props);

  const handleSave = (user: User) => {
    props.user?.$patch(user);
    s._user?.$patch(user);
    UI.OVERLAY.dismiss(`OverlayUserEditor#${s.userId}`);
  }

  const s = useStore(() => ({
    _user: props.user,
    get user() {
      return props.user || s._user;
    },
    get userId() {
      return p.user?.id ?? p.userId;
    },
    get currentUserCanEditClient() {
      return STAFF.canManageClients;
    },
    get currentUserCanEditStaff() {
      return STAFF.canManageStaff;
    },
    get canEdit() {
      return s.user?.isStaff ? s.currentUserCanEditStaff : s.currentUserCanEditClient;
    }
  }));

  useGetUser({
    observable: s,
    key: '_user',
    onError: e => {
      UI.DIALOG.error({
        heading: 'Unable to retrieve the user information. Please try again later.',
        error: e,
      });
      UI.OVERLAY.dismiss(`OverlayUserEditor#${s.userId}`);
    },
  }, s.userId, {
    include: ['permissions', 'roles'],
  });

  useSyncUrlParams('editUserId', s.userId);

  useOnMount(() => {
    return when(
      () => AUTH.isAuthenticated && !s.canEdit,
      () => {
        UI.DIALOG.attention({
          heading: 'You do not seem to have the permission to edit user account information.',
          body: 'If you think this is an error, please contact system administrator to update your account permissions.',
        })
        UI.OVERLAY.dismiss(`OverlayUserEditor#${s.userId}`);
      }
    )
  })

  return <Observer children={() => (
    <AppPage className="OverlayUserEditor">
      <AppPageHeader
        title={s.userId ? 'Edit User Information' : 'Create New User'}
        endSlot={<OverlayCloseButton />}
      />
      <AppPageContent padSections>
        <UIBlock>
          {
            s._user && <UserEditorForAdmin
              user={s.user}
              showAdminOnlyFields={true}
              onSave={handleSave}
            />
          }
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
}

export default OverlayUserEditor;