import { flow } from "mobx";
import { HasId } from "../base/@types";
import ErrorRenderer from "../base/components/ErrorRenderer/ErrorRenderer";
import { CounsellingSessionEndpoints } from "../base/endpoints/counsellingSession.endpoints";
import { isStandardModel } from "../base/factories/standardModel.factory";
import { keepTruthy } from "../base/utils/array.utils";
import { reportError, reportToSentry } from '../base/utils/errors.utils';
import { copyWithJSON } from "../base/utils/object.utils";
import { uniq } from "../base/utils/ramdaEquivalents.utils";
import { getSnapshot } from "../base/utils/snapshot.utils";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { UIController } from "../controllers/ui.controller";
import { CounsellingSession, CounsellingSessionSnapshot } from "../models/makeCounsellingSession.model";

export const saveCounsellingSession = (
  API: APIController,
  session: Partial<CounsellingSessionSnapshot> & Partial<HasId>,
  UI?: UIController,
  options?: {
    allowUpdatingTimestamps?: boolean,
    allowModelPatch?: boolean,
  },
) => new Promise<CounsellingSession>(
  flow(function * (resolve, reject) {
    try {
      const mode = session.id ? 'edit' : 'create';
      const url = mode === 'edit' ? CounsellingSessionEndpoints.staff.update(session.id!) : CounsellingSessionEndpoints.staff.create();
      const ApiMethod = mode === 'edit' ? API.patch : API.post;
      const payload = getSnapshot(session);
      const { allowUpdatingTimestamps = false } = options || {};
      if (payload.primaryIssue === 'Enter custom...') payload.primaryIssue = '';
      if (payload.secondaryIssue === 'Enter custom...') payload.secondaryIssue = '';
      if (!allowUpdatingTimestamps) {
        delete payload.timeStarted;
        delete payload.timeEnded;
      }
      if (payload.clientIds) {
        payload.clientIds = uniq(keepTruthy(payload.clientIds).map(i=>i + ''))
      } else {
        const temp = copyWithJSON(payload.clientIds)
        payload.clientIds = [];
        reportToSentry(`COUNSELLING_SESSION_CLIENT_IDS_LIST_EMPTY (Expected none empty clientIds, received: ${temp}): ALLOWING ${mode} counselling session, ${payload}.`)
      }
      const savedSession: CounsellingSession = yield ApiMethod(url, ModelName.counsellingSessions, payload);
      if (options?.allowModelPatch ?? true) {
        if (isStandardModel(session)) session.$patch(savedSession);
      }
      UI?.TOAST.positive('Your changes to the session has been saved.');
      resolve(savedSession);
    } catch (e) {
      UI?.DIALOG.error({
        name: 'session-failed-to-update',
        heading: 'Failed to save the changes to the session.',
        body: <ErrorRenderer error={(e as any).response} />,
        defaultActions: ['positive'],
      })
      reportError(e);
      reject(e);
    }
  }
))