import { AnyObject } from "../../base/@types";

export class PointerDetector {
  valueArray: string[] = [];
  constructor(options: {
    debug?: boolean,
    onDetectingMouse?: Function,
    onDetectingTouch?: Function,
    onDetectingStylus?: Function,
  } = {}) {
    const { debug, onDetectingMouse, onDetectingTouch, onDetectingStylus } = options
    let lastMouseMove = performance.now();
    const mousemoveDetector = () => {
      const thisMouseMove = performance.now();
      if (lastMouseMove && thisMouseMove - lastMouseMove < 50) {
        this.valueArray.push('mouse');
        if (debug) {
          const el = document.createElement('pre');
          el.innerHTML = 'Mousemove detected with two consecutive events within 50ms. Presuming a mouse is available.';
          document.body.prepend(el);
        }
        document.documentElement.classList.add('mouse');
        onDetectingMouse && onDetectingMouse();
        window.removeEventListener('mousemove', mousemoveDetector);
      } else lastMouseMove = thisMouseMove;
    }
    const touchstartDetector = (e: TouchEvent) => {
      this.valueArray.push('touch');
      if (debug) {
        const el = document.createElement('pre');
        el.innerHTML = 'Touchstart event detected.';
        document.body.prepend(el);
      }
      if (e.touches[0] && (e.touches[0] as AnyObject).touchType === 'stylus') {
        document.documentElement.classList.add('stylus');
        onDetectingStylus && onDetectingStylus();
      } else {
        document.documentElement.classList.add('touch');
        onDetectingTouch && onDetectingTouch();
      }
      if (this.valueArray.includes('stylus') && this.valueArray.includes('touch')) {
        window.removeEventListener('touchstart', touchstartDetector);
      }
    }
    window.addEventListener('mousemove', mousemoveDetector);
    window.addEventListener('touchstart', touchstartDetector);
  }
}

export default PointerDetector;