import { Observer } from 'mobx-react-lite';
import React from 'react';
import RouterSwitch from '../../base/components/RouterSwitch/RouterSwitch';
import { generateUuid } from '../../base/utils/id.utils';
import { useStore } from '../../base/utils/mobx.utils';
import { TopLevelModuleName } from '../../constants/topLevelModuleName.enum';
import { useRegisterTopLevelModule } from '../../hooks/useRegisterTopLevelModule';
import { AuthModuleChildRoutes, AuthModuleRouteDef } from './Auth.module.routes';
import './AuthModule.scss';

interface ModuleAuthProps {}

const ModuleAuth: React.FC<ModuleAuthProps> = React.memo(props => {

  useRegisterTopLevelModule(TopLevelModuleName.auth)

  const s = useStore(() => ({
    id: generateUuid(),
    get routerSwitch() {
      return <RouterSwitch routes={AuthModuleChildRoutes} prefix={AuthModuleRouteDef.urlFactory()} fallbackRedirectTo={AuthModuleRouteDef.fallbackRedirectTo}/>
    }
  }))

  return <Observer children={() => (
    <div className="ModuleAuth" children={s.routerSwitch} />
  )} />

})

export default ModuleAuth;