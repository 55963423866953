import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseSelector, { BaseSelectorProps } from '../../base/components/BaseSelector/BaseSelector';
import { industriesList } from '../../constants/industries.constant';

interface IndustrySelectorProps<FormType = object> extends Omit<BaseSelectorProps<FormType, string, string>, 'options'> { }

function IndustrySelector<T = object>(props: React.PropsWithChildren<IndustrySelectorProps<T>>) {
  return <Observer children={() => (
    <BaseSelector className="IndustrySelector" {...props} options={[...industriesList]} appearance="system" />
  )} />
}

export default IndustrySelector;