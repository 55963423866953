import { Observer } from 'mobx-react-lite';
import React, { MutableRefObject } from 'react';
import joinClassName from '../../utils/className.utils';
import './InfoTable.scss';

interface InfoTableProps {
  className?: string,
  tableRef?: MutableRefObject<HTMLTableElement | null>
}

/**
 * Info table is a component wrapper just for the styles!
 */
const InfoTable: React.FC<InfoTableProps> = props => {
  return <Observer children={() => (
    <table className={joinClassName('InfoTable', props.className)} ref={props.tableRef}>
      { props.children}
    </table>
  )} />
}

export default InfoTable;