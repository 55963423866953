import { Observer } from "mobx-react-lite";
import React, { PropsWithChildren } from "react";
import { AnyObject } from "../../@types";
import { useProps, useStore } from "../../utils/mobx.utils";
import { BaseTableProps } from "./BaseTable";
import './BaseTableBody.scss';
import BaseTableBodyRow from "./BaseTableBodyRow";

export type BaseTableBodyProps<EntryType extends AnyObject = {}> = BaseTableProps<EntryType> & {
};

export const BaseTableBody = <EntryType extends AnyObject = {}>(
  props: PropsWithChildren<BaseTableBodyProps<EntryType>> 
) => {
  const p = useProps(props);
  const s = useStore(() => ({
    get itemPrimaryKey() {
      return p.itemPrimaryKey;
    },
    get rows() {
      return p.entries;
    },
    get fillToNumberOfRows() {
      return s.rows.length === 0 ? 0 : p.fillToNumberOfRows;
    },
    get numberOfFillerRows() {
      if (!s.fillToNumberOfRows) return 0;
      const diff = s.fillToNumberOfRows ? s.fillToNumberOfRows - s.rows.length : 0;
      return diff > 0 ? diff : 0;
    },
    get totalNumberOfColumns() {
      return p.columnConfigs.length + (p.itemActions ? 1 : 0);
    },
    get emptyMessage() {
      return (typeof p.emptyMessage === 'function' ? <p.emptyMessage /> : p.emptyMessage) ?? 'No data to show.';
    },
  }))
  return <Observer children={() => (
    <tbody className="BaseTableBody">
      {
        s.rows.map((row, rowIndex) => <BaseTableBodyRow<EntryType>
          {...p}
          row={row}
          rowIndex={rowIndex}
          key={(p.itemPrimaryKey ? row[p.itemPrimaryKey] : rowIndex) + ''}
        />
        )}
      {
        s.numberOfFillerRows > 0 && Array(s.numberOfFillerRows).fill(null).map((r, i) => <tr key={i} className="BaseTableBodyFillerRow"><td colSpan={s.totalNumberOfColumns}>&nbsp;</td></tr>)
      }
      {
        s.rows.length === 0 && <tr className="BaseTableBodyEmptyNotice">
          <td colSpan={s.totalNumberOfColumns + (p.appearanceOptions?.showRowIndex ? 1 : 0)}>
            {s.emptyMessage}
          </td>
        </tr>
      }
    </tbody>
  )} />
}

export default BaseTableBody;