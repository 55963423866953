import Echo from 'laravel-echo';
import { action, flow, observable, runInAction, when } from 'mobx';
import Pusher, { Channel } from 'pusher-js';
import PrivateChannel from 'pusher-js/types/src/core/channels/private_channel';
import { Alert } from '../@types/alert.types';
import { getApiKey } from '../apiKeys';
import { HasId } from '../base/@types';
import { ChannelInstance, ChannelInstanceTemplate, ValidChannelType } from '../base/@types/channels.types';
import { makeGlobalChannelTemplate, makeUserPrivateChannelTemplate } from '../base/channels/index.channels';
import { assertTruthy } from '../base/utils/assert.utils';
import { reportError } from '../base/utils/errors.utils';
import { immediateReaction } from '../base/utils/mobx.utils';
import { getTrustToken } from '../base/utils/trust.utils';
import tick from '../base/utils/waiters.utils';
import { ModelName } from '../constants/modelNames.enum';
import { API_HOST, SHOULD_LOG } from '../env';
import { makeControllerBase, makeRootControllerChildInitFn } from './_root.controller';

/**
 * Transceiver is a controller that manages Pusher/Echo channels.
 * Use in combination with messenger controller. Mostly used by the chat system & alerts.
 */
export type TransceiverController = ReturnType<typeof makeTransceiverController>;


let _EVNETBUS_INITIATED = false;

declare global {
  interface Window {
    Pusher: any;
  }
}

window.Pusher = Pusher;

export const makeTransceiverController = () => {

  const s = observable({
    echo: null as Echo | null,
    globalChannel: null as ChannelInstance<Channel> | null,
    userPrivateChannel: null as ChannelInstance<Channel> | null,
  })

  const channels: ChannelInstance[] = [];

  const initGlobalChannel = flow(function * () {
    s.globalChannel = yield initChannel<Channel, HasId>(makeGlobalChannelTemplate<Channel>());
    // @ts-expect-error
    (s.globalChannel?.instance as Channel).listen('.globalconfig.update', e => {
      const { CONFIGURATIONS } = c.ROOT!.children;
      const pusherData = e.data.data;
      SHOULD_LOG() && console.log('🔧 Config Update!', e.data);
      CONFIGURATIONS.setKey(pusherData.key, pusherData.value);
    })
  })

  const initUserPrivateChannel = flow(function * (userId: string) {
    s.userPrivateChannel = yield initChannel<Channel, HasId>(
      makeUserPrivateChannelTemplate<Channel>(), { id: userId }
    );
    // @ts-ignore
    (s.userPrivateChannel?.instance as PrivateChannel).listen('.alert.new', e => {
      const { LOCALDB, ALERTS } = c.ROOT!.children;
      SHOULD_LOG() && console.log('🔔 New Alert!', e.data);
      const alert = LOCALDB.setOrMerge<Alert>(ModelName.alerts, e.data);
      ALERTS.notifyViaToast(alert);
    });
  })

  function getExistingChannel<ChannelType extends ValidChannelType = ValidChannelType>(name: string) {
    const existingChannel = channels.find(c => c.name === name);
    if (existingChannel) {
      return existingChannel as ChannelInstance<ChannelType>;
    }
  }

  async function initChannel<ChannelType extends ValidChannelType, ParamSet extends object = {}>(
    template: ChannelInstanceTemplate<ChannelType, ParamSet>,
    params: ParamSet = {} as ParamSet,
  ) {
    if (!s.echo) {
      await when(() => !!s.echo, { timeout: 3000 });
    }
    assertTruthy(s.echo, 'Trying to initiate a channel before Echo is instantiated');
    const { onError } = template;
    try {
      const name = template.channelNameFactory(params);
      const { onConnect } = template;
      const existingChannel = getExistingChannel<ChannelType>(name);
      if (existingChannel) {
        SHOULD_LOG() && console.warn(`Channel ${name} already initiated, returning the same instance. This could be unintended.`);
        return existingChannel;
      }
      let { isPublic = false, isPresenceChannel = false } = template;
      if (isPresenceChannel) isPublic = false;
      const instance = (isPublic ? s.echo.channel(name) : (
        isPresenceChannel ? s.echo.join(name) : s.echo.private(name)
      )) as object as ChannelType;
      const disconnect = action(() => {
        try {
          if (!s.echo) {
            // SHOULD_LOG() && console.warn('Echo instance not available when leaving a channel');
            return;
          }
          SHOULD_LOG() && console.log('disconnecting channel', name);
          s.echo.leaveChannel(name);
          const { onDisconnect } = template;
          onDisconnect && onDisconnect(channel, c)
        } catch(e) {
          onError && onError(e);
          throw(e);
        }
      });
      const channel: ChannelInstance<ChannelType> = {
        name,
        isPublic,
        instance,
        disconnect
      }
      if (onConnect) onConnect(channel, c);
      runInAction(() => {
        channels.push(channel);
      })
      return channel;
    } catch(e) {
      if (onError) onError(e);
      throw e;
    }
  }

  function closeChannel<ChannelType extends ValidChannelType = ValidChannelType, ParamSet extends object = {}>(
    factory: ChannelInstanceTemplate<ChannelType, ParamSet>,
    params: ParamSet = {} as ParamSet,
  ): boolean {
    if (!s.echo) {
      throw Error('Trying to close a channel before Echo is instantiated');
    }
    try {
      const name = factory.channelNameFactory(params);
      const existingChannel = getExistingChannel(name);
      if (!existingChannel) {
        SHOULD_LOG() && console.warn('Trying to close a non-existent channel. This could be an error.');
        return true;
      }
      existingChannel.disconnect();
      runInAction(() => {
        channels.splice(channels.indexOf(existingChannel), 1);
      });
      return true;
    } catch(e) {
      reportError(e);
      return false;
    }
  }

  function stopAllPrivateChannels() {
    channels.forEach(c => c.disconnect());
  }

  const reset = () => {
    s.echo?.disconnect();
    s.echo = null;
    s.userPrivateChannel?.disconnect();
    s.userPrivateChannel = null;
    stopAllPrivateChannels();
  }

  const c = observable({
    ...makeControllerBase('TRANSCEIVER'),
    get globalChannel() {
      return s.globalChannel;
    },
    get userPrivateChannel() {
      return s.userPrivateChannel;
    },
    initChannel,
    closeChannel,
    reset
  })

  const getEchoOptions = async () => ({
    authEndpoint: `${API_HOST}/broadcasting/auth`,
    broadcaster: 'pusher',
    key: getApiKey('PUSHER_APP_KEY'),
    cluster: getApiKey('PUSHER_APP_CLUSTER'),
    forceTLS: true,
    // encrypted: true,
    auth: await c.ROOT!.children.API.makeHeaders(getTrustToken()),
  })

  function watchAuthStatusChange() {
    return immediateReaction(
      () => c.ROOT!.children.AUTH.isAuthenticated,
      flow(function * () {
        yield tick(1000);
        const id = c.ROOT!.children.AUTH.currentUser?.id;
        if (!id) reset();
        else {
          const options = yield getEchoOptions();
          // console.log(options);
          s.echo = new Echo(options);
          initGlobalChannel();
          initUserPrivateChannel(id);
        }
      }),
    )
  }

  c.init = makeRootControllerChildInitFn(
    c,
    action(() => {
      if (_EVNETBUS_INITIATED) throw Error('Transceiver had been initiated already.');
      watchAuthStatusChange();
      _EVNETBUS_INITIATED = true;
      c.ready = true;
    })
  );

  return c
}

