import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject } from '../../base/@types';
import BaseInput from '../../base/components/BaseInput/BaseInput';
import BaseSelector, { BaseSelectorProps } from '../../base/components/BaseSelector/BaseSelector';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { immediateReaction, useProps, useStore } from '../../base/utils/mobx.utils';
import { predefinedIssueList } from '../../constants/issues.constants';
// import './IssueSelector.scss';

interface IssueSelectorProps<FormType extends AnyObject = AnyObject> extends Omit<BaseSelectorProps<FormType, string, string>, 'options'> { }

const options = [
  ...predefinedIssueList,
  'Enter custom...'
];

const IssueSelector = function <T extends AnyObject = AnyObject>(props: React.PropsWithChildren<IssueSelectorProps<T>>) {

  const p = useProps(props);

  const s = useStore(() => ({
    get value() {
      return p.form[p.field];
    },
    set value(v) {
      p.form[p.field] = v;
    },
    get isPredefinedIssue() {
      return s.value && predefinedIssueList.includes(s.value);
    },
    showSelector: true,
    handleBlur: action(() => {
      if (!s.value) {
        s.showSelector = true;
      }
    })
  }));

  useOnMount(() => immediateReaction(
    () => s.value,
    () => {
      if (s.value && (s.value === 'Enter custom...' || !predefinedIssueList.includes(s.value))) {
        s.showSelector = false;
        if (s.value === 'Enter custom...') {
          s.value = '' as any;
        }
      }
    }
  ))

  return <Observer children={() => (
    <div className="IssueSelector">
      {s.showSelector ? <BaseSelector<T, string, string>
        {...p}
        options={options}
        appearance="system"
      /> : <BaseInput
          form={p.form}
          field={p.field}
          name={p.name ?? p.field}
          label={p.label}
          required={p.required}
          optional={p.optional}
          disabled={p.disabled}
          onBlur={s.handleBlur}
          autoFocus={p.autoFocus}
          canClear
          autoSelect
        />}
    </div>
  )} />

}

export default IssueSelector;