import anchorme from "anchorme";
import { Observer } from 'mobx-react-lite';
import React, { CSSProperties } from 'react';
import { Nillable } from "../../@types";
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from "../../utils/mobx.utils";
import { sanitize } from '../../utils/sanitizer.utils';
import { trimString } from "../../utils/string.utils";
import './BodyCopyRenderer.scss';

interface BodyCopyRendererProps {
  className?: string,
  source?: Nillable<string>,
  lineClamp?: number,
  dataCy?: string,
}

const BodyCopyRenderer: React.FC<BodyCopyRendererProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    get html() {
      return anchorme({
        input: trimString(p.source) ?? '',
        options: {
          attributes: {
            target: "_blank",
            ref: 'noreferrer',
          },
        },
      });
    },
    get sanitizedHtml() {
      return sanitize(s.html);
    },
    get style(): CSSProperties {
      return p.lineClamp ? {
        display: '-webkit-box',
        WebkitLineClamp: p.lineClamp,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      } : {}
    }
  }));

  return <Observer children={() => (
    <div
      className={joinClassName('BodyCopyRenderer', p.className)}
      dangerouslySetInnerHTML={{ __html: s.sanitizedHtml }}
      style={s.style}
      data-cy={p.dataCy}
    />
  )} />
}

export default BodyCopyRenderer;