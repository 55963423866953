import { Observer } from "mobx-react-lite"
import React from "react"
import joinClassName from "../../utils/className.utils"
import { useProps } from "../../utils/mobx.utils"
import { capitalizeFirstLetter } from "../../utils/string.utils"
import './CommandList.scss'

interface CommandListProps {
  className?: string;
  direction?: 'column' | 'row';
  columnAppearance?: 'fullWidth' | 'simpleList';
  startSlot?: React.ReactElement;
  endSlot?: React.ReactElement;
  withBackground?: boolean;
}

export const CommandList: React.FC<CommandListProps> = props => {

  const p = useProps(props);

  return <Observer children={() => {
    const { columnAppearance } = p;
    return <div className={joinClassName(
      'CommandList',
      p.className,
      'CommandList' + (capitalizeFirstLetter(p.direction || 'column')),
      columnAppearance,
      p.withBackground && 'withBackground',
    )}>
      <div className="CommandListInner">
        <div className="CommandListStartSlot">
          {p.startSlot}
          {props.children || (!props.children && !p.endSlot && <p className="CommandListEmptyNotice"><em>No actions available</em></p>)}
        </div>
        {p.endSlot && (
          <div className="CommandListEndSlot">
            {p.endSlot}
          </div>
        )}
      </div>
    </div>
  }} />
  
}

export default CommandList;