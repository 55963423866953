import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject } from '../../base/@types';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import { ContextColor } from '../../base/constants/color.enum';
import { ChatMessageEndpoints } from '../../base/endpoints/chatMessage.endpoints';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { ChatMediator } from '../../base/mediators/chat.mediator';
import { getContextColorStyle } from '../../base/utils/colors.utils';
import { equalByString } from '../../base/utils/equality.utils';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { castToString } from '../../base/utils/string.utils';
import { getNowTimestampUtc } from '../../base/utils/time.utils';
import { ModelName } from '../../constants/modelNames.enum';
import { ChatMessage } from '../../models/makeChatMessage.model';
import { ChatParticipant } from '../../models/makeChatParticipant.model';
import { isCounsellor, isFacilitator, isModerator } from '../../utils/user.utils';
import ChatBubble from '../ChatBubble/ChatBubble';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import './ChatMessageList.scss';

interface ChatMessageListProps {
  chat: ChatMediator;
  author?: ChatParticipant,
  messages: ChatMessage[],
}

const ChatMessageList = function (props: React.PropsWithChildren<ChatMessageListProps>) {

  const { AUTH, UI, API } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    get messages() {
      return p.messages || [];
    },
    get selfId() {
      return AUTH.currentUser?.id;
    },
    get user() {
      return p.author?.user;
    },
    get isCounsellor() {
      return isCounsellor(s.user);
    },
    get isModerator() {
      return isModerator(s.user);
    },
    get isFacilitator() {
      return isFacilitator(s.user);
    },
    get canFacilitate() {
      return AUTH.can.supportGroups_.facilitate_.someUserGroups;
    },
    get color() {
      return s.user?.color;
    },
    get align() {
      return equalByString(s.user?.id, s.selfId) ? 'right' : 'left';
    },
    get colorStyle() {
      return getContextColorStyle(ContextColor.Primary, s.color);
    },
    get style(): React.CSSProperties {
      return {
        ...s.colorStyle,
        textAlign: s.align,
      }
    },
    get canControlHide() {
      return s.canFacilitate && !p.chat.thread.timeEnded;
    },
  }));

  const handleMessageHide = flow(function * (id?: string) {
    if (!s.canControlHide || !id) return;
    const message = p.messages.find(m => castToString(m.id) === castToString(id));
    if (!message) return;
    const timeHidden = message?.timeHidden;
    try {
      const url = ChatMessageEndpoints.staff.update(id);
      const payload = {
        id,
        timeHidden: timeHidden ? null : getNowTimestampUtc(),
      }
      yield API.patch(url, ModelName.chatMessages, payload);
    } catch(e) {
      reportError(e);
      UI.DIALOG.error({
        heading: 'Error occurred while setting the hidden status',
        body: <ErrorRenderer error={(e as AnyObject).response} />,
      })
    }
  })

  return <Observer children={() => (
    <div className="ChatMessageList" style={s.style}
      data-is-counsellor={s.isCounsellor}
      data-is-moderator={s.isModerator && !s.isCounsellor}
      data-is-facilitator={s.isFacilitator && !s.isCounsellor}
    >
      <header>
        <UsernameRenderer user={s.user} />
        {s.isCounsellor && <small> • Counsellor</small>}
        {s.isModerator && !s.isCounsellor && <small> • Moderator</small>}
        {s.isFacilitator && !s.isCounsellor && <small> • Facilitator</small>}
      </header>
      {
        s.messages.map(m => <ChatBubble
          id={m.id}
          chat={p.chat}
          size="sm"
          key={m.id}
          align={s.align}
          timestamp={m.timeCreated}
          message={m}
          onToggleHide={s.canControlHide ? handleMessageHide : undefined}
        >{m.body}</ChatBubble>)
      }
    </div>
  )} />
}

export default ChatMessageList;