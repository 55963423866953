import { HasId, Identifier, Nullable } from "../base/@types";
import { Flag } from "../models/makeFlag.model";
import { HasTimestamps } from "./hasTimestamps.trait";

export const isFlaggable = () => ({
  flagIds: [] as string[],
  isSafe: null as Nullable<boolean>,
  timeHidden: null as Nullable<string>,
})

export type FlaggableModeSnapshotPartial = ReturnType<typeof isFlaggable> & HasId;

export type FlaggableModelTrait = FlaggableModeSnapshotPartial & {
  flags: Flag[],
  flagIds?: Identifier[],
} & HasTimestamps