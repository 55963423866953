import { Observer } from "mobx-react-lite";
import React from "react";
import BaseArticle from "../../base/components/BaseArticle/BaseArticle";
import ShadedBlock from "../../base/components/ShadedBlock/ShadedBlock";
import { useProps, useStore } from "../../base/utils/mobx.utils";
import { calculateGAD7SurveyScore, getGAD7ScoreType, SurveyGAD7 } from "../../models/makeSurveyGAD7.model";
import SurveySelectorWithFourLevels from "../SurveySelectorWithFourLevels/SurveySelectorWithFourLevels";
import './SurveyEditorGAD7.scss';

interface SurveyEditorGAD7Props {
  survey: SurveyGAD7,
  readonly?: any,
  showScore?: any,
}

const SurveyEditorGAD7: React.FC<SurveyEditorGAD7Props> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    get score () {
      return calculateGAD7SurveyScore(p.survey) || 0;
    },
    get scoreType() {
      return getGAD7ScoreType(s.score)
    }
  }), props);

  return <Observer children={() => (
    <BaseArticle className="SurveyEditorGAD7">
      <h3>Over the last 2 weeks, how often have you been bothered by the following problems?</h3>
      <div className="SurveyEditorGAD7Question">
        <p>Feeling nervous, anxious or on edge:</p>
        <SurveySelectorWithFourLevels readonly={props.readonly} form={props.survey} field="levelOfAnxiety" showScore={props.showScore} />
      </div>
      <div className="SurveyEditorGAD7Question">
        <p>Not being able to stop or control worrying:</p>
        <SurveySelectorWithFourLevels readonly={props.readonly} form={props.survey} field="levelOfWorryUncontrollability" showScore={props.showScore} />
      </div>
      <div className="SurveyEditorGAD7Question">
        <p>Worrying too much about different things:</p>
        <SurveySelectorWithFourLevels readonly={props.readonly} form={props.survey} field="levelOfWorryFrequency" showScore={props.showScore} />
      </div>
      <div className="SurveyEditorGAD7Question">
        <p>Trouble relaxing:</p>
        <SurveySelectorWithFourLevels readonly={props.readonly} form={props.survey} field="levelOfInabilityToRelax" showScore={props.showScore} />
      </div>
      <div className="SurveyEditorGAD7Question">
        <p>Being so restless that it is hard to sit still:</p>
        <SurveySelectorWithFourLevels readonly={props.readonly} form={props.survey} field="levelOfRestlessness" showScore={props.showScore} />
      </div>
      <div className="SurveyEditorGAD7Question">
        <p>Becoming easily annoyed or irritable:</p>
        <SurveySelectorWithFourLevels readonly={props.readonly} form={props.survey} field="levelOfIrritability" showScore={props.showScore} />
      </div>
      <div className="SurveyEditorGAD7Question">
        <p>Feeling afraid as if something awful might happen:</p>
        <SurveySelectorWithFourLevels readonly={props.readonly} form={props.survey} field="levelOfFear" showScore={props.showScore} />
      </div>
      {props.showScore && <ShadedBlock>
        <h4>Total Score: {s.score} / 21, <br />{s.scoreType}</h4>
        <p><em>Scores of 5, 10, and 15 are taken as the cut-off points for mild, moderate and severe anxiety, respectively.</em></p>
      </ShadedBlock>}
    </BaseArticle>
  )} />
}

export default SurveyEditorGAD7;