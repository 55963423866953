import React from "react";
import { makeIconDef } from "./makeIconDef";

export const KiteIconDef = {
  kite: makeIconDef('kite', {
    regular: () => <>
      <path d="M16.4911 7.90598C16.4392 7.63476 16.1772 7.45699 15.906 7.50892C15.6348 7.56084 15.457 7.8228 15.5089 8.09402L16.4911 7.90598ZM22.6717 21.3771C22.88 21.5584 23.1958 21.5366 23.3771 21.3283C23.5584 21.12 23.5366 20.8042 23.3283 20.6229L22.6717 21.3771ZM15.5089 8.09402C16.6978 14.304 20.259 19.2767 22.6717 21.3771L23.3283 20.6229C21.0744 18.6608 17.6355 13.8835 16.4911 7.90598L15.5089 8.09402Z" fill="currentColor" />
      <path d="M0.5 19.5C4 19.5 5.5 19 7.5 16.5C6 20.5 4.5 22.5 0.5 22.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.6488 15.0702L8.0623 16.7875L7.88388 16.8232L7 17L7.17678 16.1161L7.21246 15.9377L9.88215 2.58926L10 2H10.7071H21H21.2929H22V2.70711V3V13.2929V14L21.4107 14.1179L18.2246 14.7551L17.6968 13.8408L20.5609 13.268L16 8.70711L9.15864 15.5485L16.121 14.156L16.6488 15.0702ZM21 12.2929L16.7071 8L21 3.70711V12.2929ZM16 7.29289L20.2929 3H11.7071L16 7.29289ZM15.2929 8L10.732 3.43909L8.45153 14.8414L15.2929 8Z" fill="currentColor" />
    </>,
    filled: () => <>
      <path d="M0.5 19.5C4 19.5 5.5 19 7.5 16.5C6 20.5 4.5 22.5 0.5 22.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22 2H10L16 8L22 2Z" fill="currentColor" />
      <path opacity="0.6" d="M22 14L22 2L16 8L22 14Z" fill="currentColor" />
      <path d="M16.4911 7.90598C16.4392 7.63476 16.1772 7.45699 15.906 7.50892C15.6348 7.56084 15.457 7.8228 15.5089 8.09402L16.4911 7.90598ZM22.6717 21.3771C22.88 21.5584 23.1958 21.5366 23.3771 21.3283C23.5584 21.12 23.5366 20.8042 23.3283 20.6229L22.6717 21.3771ZM15.5089 8.09402C16.6978 14.304 20.259 19.2767 22.6717 21.3771L23.3283 20.6229C21.0744 18.6608 17.6355 13.8835 16.4911 7.90598L15.5089 8.09402Z" fill="currentColor" />
      <path opacity="0.8" d="M16 8L10 2L7 17L16 8Z" fill="currentColor" />
      <path opacity="0.4" d="M16 8L22 14L7 17L16 8Z" fill="currentColor" />
    </>,
    color: () => <>
      <path d="M0.5 19.5C4 19.5 5.5 19 7.5 16.5C6 20.5 4.5 22.5 0.5 22.5" stroke="#009FE3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22 2H10L16 8L22 2Z" fill="url(#KiteIcon0_linear)" />
      <path d="M22 14L22 2L16 8L22 14Z" fill="url(#KiteIcon1_linear)" />
      <path d="M16.4911 7.90598C16.4392 7.63476 16.1772 7.45699 15.906 7.50892C15.6348 7.56084 15.457 7.8228 15.5089 8.09402L16.4911 7.90598ZM22.6717 21.3771C22.88 21.5584 23.1958 21.5366 23.3771 21.3283C23.5584 21.12 23.5366 20.8042 23.3283 20.6229L22.6717 21.3771ZM15.5089 8.09402C16.6978 14.304 20.259 19.2767 22.6717 21.3771L23.3283 20.6229C21.0744 18.6608 17.6355 13.8835 16.4911 7.90598L15.5089 8.09402Z" fill="#009FE3" />
      <path d="M16 8L10 2L7 17L16 8Z" fill="url(#KiteIcon2_linear)" />
      <path opacity="0.6" d="M16 8L22 14L7 17L16 8Z" fill="url(#KiteIcon3_linear)" />
      <defs>
        <linearGradient id="KiteIcon0_linear" x1="14" y1="-2.42144e-07" x2="18.5" y2="10.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#009FE3" />
          <stop offset="1" stopColor="#009FE3" stopOpacity="0.13" />
        </linearGradient>
        <linearGradient id="KiteIcon1_linear" x1="20" y1="4.5" x2="25" y2="14" gradientUnits="userSpaceOnUse">
          <stop stopColor="#009FE3" />
          <stop offset="1" stopColor="#009FE3" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="KiteIcon2_linear" x1="3.5" y1="4.5" x2="17.0417" y2="3.62746" gradientUnits="userSpaceOnUse">
          <stop stopColor="#009FE3" />
          <stop offset="1" stopColor="#009FE3" stopOpacity="0.25" />
        </linearGradient>
        <linearGradient id="KiteIcon3_linear" x1="12.5" y1="11.5" x2="13.7089" y2="16.8395" gradientUnits="userSpaceOnUse">
          <stop stopColor="#009FE3" />
          <stop offset="1" stopColor="#009FE3" stopOpacity="0.17" />
        </linearGradient>
      </defs>
    </>
  })
}