import { action, IReactionDisposer, observable, reaction } from "mobx";
import { SHOULD_LOG } from "../../env";
import { Nillable, Nullable, Undefinable } from "../@types";
import { Point } from "../@types/geometry.types";
import { removeFromArray } from "../utils/array.utils";
import { generateUuid } from "../utils/id.utils";
import { first, last } from "../utils/ramdaEquivalents.utils";
import tick from "../utils/waiters.utils";

export type PointerContactListener = (r: PointerContactRecord) => any;
export type PointerContactRecord = {
  id: string;
  events: MouseEvent[];
  first: Nullable<MouseEvent>;
  last: Nullable<MouseEvent>;
  deltaX: number;
  deltaY: number;
  isRightClick: boolean;
  hasStarted: boolean;
  isCompleted: boolean;
  startTime: number;
  getEndTime: () => number;
  getDuration: () => number;
  duration: number;
  getVelocityX: () => number;
  getVelocityY: () => number;
  velocityX: number;
  velocityY: number;
  start: (e: MouseEvent) => void;
  recordEvent: (e: MouseEvent) => void;
  complete: (e?: MouseEvent) => void;
  startPoint: Point;
  endPoint: Point;
  isInteracting: boolean;
  onUpdate: (handler: PointerContactListener)  => void;
  removeUpdateListener: (listener: PointerContactListener) => void;
}

export const makePointerContactRecord = () => {

  const changeListeners = [] as PointerContactListener[];
  
  let disposeChangeReaction: IReactionDisposer;

  const s: PointerContactRecord = observable({
    id: generateUuid(),
    events: [] as MouseEvent[],
    get first() { return first(s.events) || null },
    get last() { return last(s.events) || null },
    get isRightClick(): boolean {
      return s.first?.which === 3 || s.first?.button === 2;
    },
    hasStarted: false,
    isCompleted: false,
    get startTime(): number {
      return s.first?.timeStamp ?? 0;
    },
    getEndTime() {
      return s.isCompleted ? s.last?.timeStamp ?? 0 : performance.now();
    },
    getDuration() {
      return s.getEndTime() - s.startTime;
    },
    get duration() {
      return s.getDuration();
    },
    start: action((e: MouseEvent) => {
      s.hasStarted = true;
      s.events.push(e);
    }),
    recordEvent: action((e: MouseEvent) => {
      if (!s.hasStarted) return;
      if (s.isCompleted) {
        SHOULD_LOG() && console.warn('Cannot record event in a completed contact event recorder');
        return;
      }
      s.events.push(e);
    }),
    complete: action((e?: MouseEvent) => {
      if (s.isCompleted) {
        SHOULD_LOG() && console.warn('Cannot re-complete event in a completed contact event recorder');
        return;
      }
      if (e) s.events.push(e);
      s.isCompleted = true;
      SHOULD_LOG() && console.log(`pointer contact #${s.id.substr(0, 8)}`, s);
      window.removeEventListener('blur', endHandler);
      disposeChangeReaction();
    }),
    get startPoint() {
      return { x: s.first?.clientX ?? null, y: s.first?.clientY ?? null }
    },
    get endPoint() {
      return { x: s.last?.clientX ?? null, y: s.last?.clientY ?? null }
    },
    get isInteracting() {
      return Boolean(s.hasStarted && !s.isCompleted);
    },
    get deltaX() {
      return (s.last?.x ?? s.first?.x ?? 0) - (s.first?.x ?? 0);
    },
    get deltaY() {
      return (s.last?.y ?? s.first?.y ?? 0) - (s.first?.y ?? 0);
    },
    getVelocityX() {
      return (s.deltaX / s.getDuration()) || 0;
    },
    getVelocityY() {
      return (s.deltaY / s.getDuration()) || 0;
    },
    get velocityX() {
      return s.getVelocityX();
    },
    get velocityY() {
      return s.getVelocityY();
    },
    onUpdate: fn => {
      changeListeners.push(fn);
    },
    removeUpdateListener: fn => removeFromArray(changeListeners, fn)
  })

  disposeChangeReaction = reaction(
    () => s.last,
    () => changeListeners.forEach(l => l(s))
  );

  const endHandler = async () => {
    await tick();
    s.complete();
  }

  window.addEventListener('mouseleave', endHandler, { once: true });
  
  return s;

}

type PointerEvent = MouseEvent | React.MouseEvent<HTMLElement | SVGElement>;
type PointerContactEventHandler = (current: PointerContactRecord, prev?: Nillable<PointerContactRecord>) => any;

export const makePointerContactController = (el?: HTMLElement | SVGElement) => {
  const handlers = observable({
    onBeforePointerContactStart: [] as PointerContactEventHandler[],
    onUpdate: [] as PointerContactEventHandler[],
    onSwipeRight: [] as PointerContactEventHandler[],
    onSwipeLeft: [] as PointerContactEventHandler[],
    onPointerContactEnd: [] as PointerContactEventHandler[],
  })
  const runHandlers = (arr: PointerContactEventHandler[]) => arr.forEach(fn => fn(s.current, s.prev));
  const s = observable({
    prev: null as Nullable<PointerContactRecord>,
    current: makePointerContactRecord(),
    reset: action(() => {
      const nullContact = makePointerContactRecord();
      nullContact.complete();
      s.current = nullContact;
    }),
    get isInteracting(): Undefinable<boolean> {
      return s.current.isInteracting;
    },
    mouseDownCaptureHandler: action((e: PointerEvent) => {
      SHOULD_LOG() && console.log('mousedown on pointer contact tracker')
      if (s.current.isCompleted) {
        s.prev = s.current;
        s.current = makePointerContactRecord();
      }
      runHandlers(handlers.onBeforePointerContactStart);
      s.current.start('nativeEvent' in e ? e.nativeEvent : e);
    }),
    mouseMoveCaptureHandler: action((e: PointerEvent) => {
      s.current.isInteracting && s.current.recordEvent('nativeEvent' in e ? e.nativeEvent : e);
    }),
    mouseUpCaptureHandler: (e: PointerEvent) => {
      runHandlers(handlers.onPointerContactEnd);
    },
    get lastContactIsCompleted() {
      return s.current.isCompleted;
    },
    onBeforePointerContactStart: (handler: PointerContactEventHandler) => {
      handlers.onBeforePointerContactStart.push(handler);
    },
    onUpdate: (handler: PointerContactEventHandler) => {
      handlers.onUpdate.push(handler);
    },
    onSwipeLeft: (handler: PointerContactEventHandler) => {
      handlers.onSwipeLeft.push(handler);
    },
    onSwipeRight: (handler: PointerContactEventHandler) => {
      handlers.onSwipeRight.push(handler);
    },
    onPointerContactEnd: (handler: PointerContactEventHandler) => {
      handlers.onPointerContactEnd.push(handler);
    },
    get isSwipeX() {
      return s.isSwipeRight || s.isSwipeLeft;
    },
    get isSwipeLeft() {
      return (s.current.velocityX < -.62 && s.current.deltaX < -25) || s.current.deltaX < -250;
    },
    get isSwipeRight() {
      return (s.current.velocityX > .62 && s.current.deltaX > 25) || s.current.deltaX > 250;
    },
    attachToElement: (el: HTMLElement | SVGElement) => {
      el.addEventListener('mousedown', e => {
        s.mouseDownCaptureHandler?.(e as PointerEvent);
        const handleEnd = () => {
          s.end();
          window.removeEventListener('mousemove', s.mouseMoveCaptureHandler)
          window.removeEventListener('mouseup', handleEnd);
          window.removeEventListener('mouseleave', handleEnd);
          window.removeEventListener('blur', handleEnd);
        }
        window.addEventListener('mousemove', s.mouseMoveCaptureHandler as EventListener );
        window.addEventListener('mouseup', handleEnd, { once: true });
        window.addEventListener('mouseleave', handleEnd, { once: true });
        window.addEventListener('blur', handleEnd, { once: true });
      }, { capture: true });
    },
    end: () => {
      s.current.complete();
    }
  })
  reaction(
    () => s.current.endPoint,
    () => {
      runHandlers(handlers.onUpdate);
      if (s.isSwipeLeft) runHandlers(handlers.onSwipeLeft);
      if (s.isSwipeRight) runHandlers(handlers.onSwipeRight);
    },
  )
  reaction(
    () => s.current.isCompleted,
    isCompleted => isCompleted && (
      runHandlers(handlers.onPointerContactEnd)
    )
  )
  if (el) {
    s.attachToElement(el);
  }
  return s;
}