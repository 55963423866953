import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Form } from '../../mediators/form.mediator';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import BaseToggle, { BaseToggleProps } from '../BaseToggle/BaseToggle';
import { useFormContext } from '../FormForm/Form.context';

type FormToggleProps<T extends object = object> = Omit<BaseToggleProps<T>, 'form'> & {
  form?: Form<T>,
}

function FormToggle<T extends object = object>(
  props: React.PropsWithChildren<FormToggleProps<T>>
) {

  const context = useFormContext<T>();
  const { form } = context;
  const p = useProps(props);
  const s = useStore(() => ({
    get form() { return p.form ?? form },
  }));

  return <Observer children={() => (
    <BaseToggle {...p}
      className={joinClassName('BaseToggle', p.className)}
      form={s.form.fields[p.field]}
      field="value"
      name={p.name ?? p.field}
      disabled={p.disabled || context.disabled}
    />
  )} />
}

export default FormToggle;