import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import AutosuggestSearchBar from '../../base/components/AutosuggestSearchBar/AutosuggestSearchBar';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { ChatMediator } from '../../base/mediators/chat.mediator';
import { sortArray } from '../../base/utils/array.utils';
import joinClassName from '../../base/utils/className.utils';
import { equalByString } from '../../base/utils/equality.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { ActionConfig } from '../../controllers/ui/ui.controller.types';
import { ChatParticipant } from '../../models/makeChatParticipant.model';
import { User } from '../../models/makeUser.model';
import { getUserIndex } from '../../requests/getUserIndex.request';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import ChatWindowParticipantEntry from './ChatWindowParticipantEntry';
import './ChatWindowParticipantList.scss';
import { ChatWindowStackMode } from './ChatWindowStack';

interface ChatWindowParticipantListProps {
  mode?: ChatWindowStackMode,
  chat: ChatMediator;
}

function ChatWindowParticipantList(props: React.PropsWithChildren<ChatWindowParticipantListProps>) {
  const p = useProps(props);
  const { AUTH, API } = useControllers();
  const s = useStore(() => ({
    get participants() {
      return p.chat.participants || [];
    },
    get self() {
      return s.participants.find(p => equalByString(p.user?.id, AUTH.currentUser?.id));
    },
    get otherPeople() {
      return sortArray(s.participants.filter(p => p.user?.id + '' !== AUTH.currentUser?.id + ''), {
        key: 'timeCreated',
        direction: 'desc',
        transformer: a => moment(a),
      });
    },
    get orderedParticipants() {
      return [s.self, ...s.otherPeople].filter(i=>!!i) as ChatParticipant[];
    },
    get isCounsellor() {
      return AUTH.isCounsellor
    },
    get isAdmin() {
      return AUTH.isStaff
    },
    get isModerator() {
      return AUTH.isModerator;
    },
    get canFacilitateChat() {
      return AUTH.canFacilitateChat
    },
    get canInviteNewParticipants() {
      return (s.canFacilitateChat) && !p.chat.hasEnded;
    },
    showOptions: true,
    query: '',
    autosuggestHasFocus: false,
    toggleAutoSuggestFocus: action(() => s.autosuggestHasFocus = !s.autosuggestHasFocus),
    autosuggestUserDataFetcher: (query: string, perPage?: number) => getUserIndex(API, {
      filter: { username: query },
      perPage: perPage ?? 50,
      include: ['permissions', 'roles']
    }),
    autosuggestActions: [
      {
        name: 'invite',
        label: 'Invite',
        action: u => u && p.chat.inviteParticipantByUserId(u.id),
      }
    ] as ActionConfig[],
    get shouldWidenWidth() {
      return s.query || s.autosuggestHasFocus;
    },
    toggleShowOptions: action(() => {
      s.showOptions = !s.showOptions;
    }),
  }));

  const defaultInviteAction = action((u?: User) => {
    s.query = '';
    if (u) p.chat.inviteParticipantByUserId(u.id);
  })

  const chatPermissionBuilder = (u: User) => {
    const isClient = !u.isStaff;
    const isStaff = u.isStaff;
    const allowInviteClient = AUTH.can.chat_.startChatWith_.client;
    const allowInviteStaff = AUTH.can.chat_.startChatWith_.staff;
    return {
      disableInviteClient: isClient && !allowInviteClient,
      disableInviteStaff: isStaff && !allowInviteStaff,
      canInviteClient: allowInviteClient,
      canInviteStaff: allowInviteStaff,
    }
  }

  const isUserAlreadyInChat = (u: User) => !!p.chat.participants.find(p => p.userId === u.id);

  const entryShouldDisableChecker = (u: User) => {
    const chatPerms = chatPermissionBuilder(u);
    return !!p.chat.participants.find(p => p.userId === u.id) || chatPerms.disableInviteClient || chatPerms.disableInviteStaff;
  }

  const suggestionRenderer = (u: User) => <Observer children={() => {
    const alreadyInChat = isUserAlreadyInChat(u);
    const chatPerms = chatPermissionBuilder(u);
    return <div className="ChatWindowParticipantSuggestionEntry" data-already-in-chat={alreadyInChat} data-disabled={entryShouldDisableChecker(u)}>
      <UsernameRenderer user={u} />
      { u.isStaff ? !chatPerms.canInviteStaff && <em> (staff)</em> : !chatPerms.canInviteClient && <em> (client)</em>}
      { alreadyInChat && <em> (Already Joined)</em> }
    </div>
  }} />

  return <Observer children={() => (
    <div className={joinClassName(
      'ChatWindowParticipantList',
      s.shouldWidenWidth && 'hasQuery',
    )}>
      <header className="ChatWindowParticipantListHeader">
        <h3>Participants {s.participants.length > 0 && <span>({s.participants.length})</span>}</h3>
      </header>
      <div className="ChatWindowParticipantListInner">
        {
          s.canInviteNewParticipants && (
            <AutosuggestSearchBar<User>
              form={s}
              field="query"
              placeholder="Invite User..."
              dataFetcher={s.autosuggestUserDataFetcher}
              resultItemContentRenderer={suggestionRenderer}
              clearOnAction={true}
              onFocus={s.toggleAutoSuggestFocus}
              onBlur={s.toggleAutoSuggestFocus}
              actions={s.autosuggestActions}
              defaultAction={defaultInviteAction}
              entryShouldDisableChecker={entryShouldDisableChecker}
              actionPosition="start"
              autoComplete="off"
            />
          )
        }
        <table>
          <thead>
            <tr>
              {s.showOptions && <th>{s.canFacilitateChat && !p.chat.hasEnded && <span className="u-no-print">Actions</span>}</th>}
              <th>Username</th>
              <th className="u-no-print">Online</th>
            </tr>
          </thead>
          <tbody>
            {
              s.orderedParticipants.map((part, i) => <ChatWindowParticipantEntry
                chat={p.chat}
                key={part.id + '-' + i}
                participant={part}
                isSelf={part === s.self}
                isOnline={p.chat.onlineUserIds.includes(part.user?.id + '')}
                showOptions={s.showOptions}
              />)
            }
          </tbody>
        </table>
      </div>
    </div>
  )} />
}

export default ChatWindowParticipantList;