import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useProps, useStore } from '../../utils/mobx.utils';
import { BaseTableColumnConfig, BaseTableProps, getKeyNameFromColumnConfig } from './BaseTable';
import './BaseTableCol.scss';

interface BaseTableColProps<EntryType extends object = {}> extends BaseTableProps<EntryType> {
  columnConfig: BaseTableColumnConfig<EntryType>;
}

function BaseTableCol<EntryType extends object = {}>(
  props: React.PropsWithChildren<BaseTableColProps<EntryType>>
) {
  const p = useProps(props);
  const s = useStore(() => ({
    get col() {
      return p.columnConfig;
    },
    get keyName() {
      return getKeyNameFromColumnConfig(s.col);
    },
    get sortByKeyName() {
      return p.sortByKeyName;
    },
    get isSortingByThisColumn() {
      return s.sortByKeyName === s.keyName;
    },
    get sortDirection() {
      return p.sortDirection;
    },
  }));
  return <Observer children={() => (
    <col className="BaseTableCol"
      data-type={s.col.type}
      data-key-name={s.col.keyName}
      data-label={s.col.label}
      data-sort-direction={s.isSortingByThisColumn && s.sortDirection}
    />
  )} />
}

export default BaseTableCol;