import {User,UserRelatedModels,UserSnapshot} from "../../models/makeUser.model";
import {StringKeyOf} from "../@types";
import {BaseParamFilterType,DefaultIndexParamSet} from "../utils/api.utils";
import {makeApiEndpointConfig,makeApiGetEndpointConfig} from "../utils/endpoints.utils";
import {makeUrl} from "../utils/url.utils";
import {CounsellingApplicationEndpointParams} from "./counsellingApplication.endpoints";
import {CounsellingSessionEndpointParams} from "./counsellingSession.endpoints";
import {prefixClient,prefixOwn,prefixStaff} from "./namespacePrefixes";

export type UserEndpointParams = DefaultIndexParamSet<User, UserRelatedModels> & {
  include?: (keyof UserRelatedModels | string)[],
  append?: string,
  filter?: BaseParamFilterType<UserSnapshot> & {
    hasPermission?: PermissionName[],
    hasAnyPermission?: boolean,
    whereNotNull?: StringKeyOf<UserSnapshot>[],
    withTrashed?: 'only' | 'with',
  },
}

const ownUrlBase = prefixOwn('user'); // note that this is singular
const clientUrlBase = prefixClient('users'); // and those are plural
const staffUrlBase = prefixStaff('users');

export const UserEndpoints = {
  own: {
    /** for users to get self. */
    get: makeApiEndpointConfig<UserEndpointParams>(ownUrlBase),
    /** for users to update self. */
    update: () => ownUrlBase,
  },
  client: {
    /**
     * useful for displaying other user's information such as user name and color. 
     * should not include any personal information. 
     * */
    get: makeApiGetEndpointConfig<UserEndpointParams>(clientUrlBase),
  },
  staff: {
    index: makeApiEndpointConfig<UserEndpointParams>(staffUrlBase),
    counsellorIndex: makeApiEndpointConfig<UserEndpointParams>(staffUrlBase + '/counsellors'),
    facilitatorIndex: makeApiEndpointConfig<UserEndpointParams>(staffUrlBase + '/facilitators'),
    create: () => staffUrlBase,
    get: makeApiGetEndpointConfig<UserEndpointParams>(staffUrlBase),
    update: (id: string) => makeUrl(staffUrlBase, id),
    delete: (id: string) => makeUrl(staffUrlBase, id),
    getCounsellingApplications: (id: string) => makeApiEndpointConfig<CounsellingApplicationEndpointParams>(makeUrl(staffUrlBase, id, 'counselling-applications')),
    getCounsellingSessions: (id: string) => makeApiEndpointConfig<CounsellingSessionEndpointParams>(makeUrl(staffUrlBase, id, 'counselling-sessions')),
  },
}