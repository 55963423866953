import { ArrayElementOf, Nullable } from "../@types";
import { CountryCode, CountryDescriptorArray, IrelandCountryDescriptor, UKCountryDescriptor } from "../constants/countries.constants";
import { uniq } from "./ramdaEquivalents.utils";
import { formatSearchString } from "./string.utils";
import { isArray } from "./typeChecks.utils";

export const createDialCodeRegex = (code: string) => new RegExp(`^\\+${code.replace('-', '\\-')}(.*)`);

export const allDialCodes = uniq([
  IrelandCountryDescriptor.dialCode,
  UKCountryDescriptor.dialCode,
  ...CountryDescriptorArray.map(d => d.dialCode).filter(d => !!d)
].flat()) as string[];

Reflect.set(window, 'dialCodes', allDialCodes);

export const countriesByDialCodes = allDialCodes.map(code => {
  const countries = CountryDescriptorArray.filter(country => country.dialCode ? (isArray(country.dialCode) ? country.dialCode.includes(code) : country.dialCode === code) : false)!;
  return {
    dialCode: code,
    regex: createDialCodeRegex(code),
    countries,
    searchString: formatSearchString(countries.map(country => [country.name, country.cca2, country.dialCode ? JSON.stringify(country.dialCode) : ''].join('_')).join('__')),
  }
})
Reflect.set(window, 'countriesByDialCodes', countriesByDialCodes);

export type CountryDialCodeDef = ArrayElementOf<typeof countriesByDialCodes>;

export const countriesSortedByDialCodeLength = [...countriesByDialCodes].sort((a, b) => b.dialCode.length - a.dialCode.length);

export const getCountryCodeFromString = (tel?: string): Nullable<CountryCode> => {
  if (!tel) return null;
  if (tel[0] !== '+') {
    if (tel.length === 12 || tel.length === 13) {
      if (tel.match(/^353/)) return 'IE';
      if (tel.match(/^44/)) return 'GB';
    }
  }
  for (let country of countriesSortedByDialCodeLength) {
    if (tel.match(country.regex)) return country.countries[0]?.cca2 ?? '';
  }
  return null;
}
