import { always } from "../../../../../base/utils/ramdaEquivalents.utils"
import { makeObservableRouteDef } from "../../../../../base/utils/routes.utils"
import AccountPageContactForm from "./AccountPageContactForm.page"

export const AccountPageContactFormRouteDef = makeObservableRouteDef({
  identifier: "account-contact-us",
  urlFactory: always("contact-us"),
  composeTitle: always("Contact Us"),
  component: AccountPageContactForm,
  icon: "mail",
})
