import deepmerge from "deepmerge";
import { autorun } from "mobx";
import { useLocation } from "react-router-dom";
import { NavigatorController } from "../../controllers/navigator.controller";
import { SurveyGAD7, SurveyGAD7RelatedModels } from "../../models/makeSurveyGAD7.model";
import { SurveyGeneral, SurveyGeneralRelatedModels } from "../../models/makeSurveyGeneral.model";
import { SurveyGoalSheet, SurveyGoalSheetRelatedModels } from "../../models/makeSurveyGoalSheet.model";
import { SurveyPHQ9, SurveyPHQ9RelatedModels } from "../../models/makeSurveyPHQ9.model";
import { SurveySatisfaction, SurveySatisfactionRelatedModels } from "../../models/makeSurveySatisfaction.model";
import { AnyObject, Nillable, StringKeyOf, Timeframe } from "../@types";
import { AddressEndpointParams } from "../endpoints/address.endpoints";
import { AssignmentEndpointParams } from "../endpoints/assignment.endpoints";
import { ChatMessageEndpointParams } from "../endpoints/chatMessage.endpoints";
import { ChatParticipantEndpointParams } from "../endpoints/chatParticipant.endpoints";
import { ChatThreadEndpointParams } from "../endpoints/chatThread.endpoints";
import { CommentEndpointParams } from "../endpoints/comment.endpoints";
import { CounsellingApplicationEndpointParams } from "../endpoints/counsellingApplication.endpoints";
import { CounsellingAvailabilityEndpointParams } from "../endpoints/counsellingAvailability.endpoints";
import { CounsellingSessionEndpointParams } from "../endpoints/counsellingSession.endpoints";
import { PaymentEndpointParams } from "../endpoints/payment.endpoints";
import { ReactionEndpointParams } from "../endpoints/reaction.endpoints";
import { SupportGroupEndpointParams } from "../endpoints/supportGroup.endpoints";
import { SurveyEndpointParams } from "../endpoints/survey.endpoints";
import { ThoughtEndpointParams } from "../endpoints/thought.endpoints";
import { UserEndpointParams } from "../endpoints/user.endpoints";
import { useOnMount } from "../hooks/lifecycle.hooks";
import { useControllers } from "../hooks/useRootController.hook";
import { DateRangeFilter, stringifyDateRangeFilterArrayIndexForm, stringifyDateRangeFilterCommaDelimited } from "./api.utils";
import { isNil } from "./ramdaEquivalents.utils";

export type KnownURLParams =

  | 'companyId'
  | 'editCompanyId'
  | 'manageCompanyId'

  | 'thoughtId'
  | 'editThoughtId'
  | 'manageThoughtId'

  | 'manageAvailabilityId'

  | 'applicationId'
  | 'manageApplicationId'

  | 'sessionId'
  | 'manageSessionId'

  | 'supportGroupId'
  | 'manageSupportGroupId'
  | 'manageSupportGroupTopicId'

  | 'userId'
  | 'editUserId'

  | 'invoiceId'

  | 'assignmentId' // see OverlayStripeMakePayment

  | 'overlay' // used to open overlay with a known name

  | 'origin' // origin is used in BackButton to determine where to go back to
  | 'redirectedFrom' // auth redirects after login
  | 'action' // this is used in chat window header for e.g. opening print dialog on load
  | 'editCommentId' // CommentEntry will automatically switch to edit mode if ID matches this
  | 'threadId' // deprecated, used in original "fullscreen" chat mode
  | 'tag' // support group tags, used in SupportGroupsIndex
  | 'code' // used in PageVerifyEmail to auto enter code from verification email
  | 'createSession' // used in CounsellingSessionsIndex to automatically open editor for creating a new session
  | 'username' // used in password reset page

  | 'invitationId'
  | 'type'
  | 'from'

  | StringKeyOf<Timeframe>

  | StringKeyOf<AddressEndpointParams>
  | StringKeyOf<AssignmentEndpointParams>
  | StringKeyOf<ChatMessageEndpointParams>
  | StringKeyOf<ChatParticipantEndpointParams>
  | StringKeyOf<ChatThreadEndpointParams>
  | StringKeyOf<CommentEndpointParams>
  | StringKeyOf<CounsellingApplicationEndpointParams>
  | StringKeyOf<CounsellingAvailabilityEndpointParams>
  | StringKeyOf<CounsellingSessionEndpointParams>
  | StringKeyOf<PaymentEndpointParams>
  | StringKeyOf<ReactionEndpointParams>
  | StringKeyOf<SupportGroupEndpointParams>
  | StringKeyOf<SurveyEndpointParams<SurveyGAD7, SurveyGAD7RelatedModels>>
  | StringKeyOf<SurveyEndpointParams<SurveyPHQ9, SurveyPHQ9RelatedModels>>
  | StringKeyOf<SurveyEndpointParams<SurveyGeneral, SurveyGeneralRelatedModels>>
  | StringKeyOf<SurveyEndpointParams<SurveyGoalSheet, SurveyGoalSheetRelatedModels>>
  | StringKeyOf<SurveyEndpointParams<SurveySatisfaction, SurveySatisfactionRelatedModels>>
  | StringKeyOf<ThoughtEndpointParams>
  | StringKeyOf<UserEndpointParams>
;

export type ParamValue = boolean | string | number | undefined | null | (string | number)[] | AnyObject;
export type KnownURLParamSetFromURL = Partial<Record<KnownURLParams, Nillable<string>>>;
export type KnownURLParamSetInput = Partial<Record<KnownURLParams, Nillable<ParamValue>>>;
export type KnownParamNameValueObject = { name: KnownURLParams, value: Nillable<string> };
export type KnownParamNameValueObjectSet = KnownParamNameValueObject[];

export const isValidParamValue = (v: any) => {
  return !!(v !== undefined && v !== null && v !== '' && v !== false);
}

export function getUrlParams() {
  const location = window.location;
  const paramPairs = location.search ? location.search.replace(/^\?/, '').split('&') : [];
  let params: AnyObject = {};
  paramPairs.forEach(pair => {
    const arr = pair.split('=');
    params[arr[0]] = arr[1];
  })
  return params as KnownURLParamSetFromURL;
}
export const useGetUrlParams = () => getUrlParams();

export const setUrlParam = (
  key: KnownURLParams,
  value?: any,
  NAVIGATOR?: NavigatorController,
  replace: boolean = false,
) => {
  if (getUrlParams()[key] === value) return;
  return setUrlParams({ [key]: value } as KnownURLParamSetInput, NAVIGATOR);
}

export const setUrlParams = (
  set: KnownURLParamSetInput,
  NAVIGATOR?: NavigatorController,
  replace?: boolean,
) => {

  const urlParams = {
    ...(replace ? {} : getUrlParams()),
    ...set,
  };

  const paramString = Object.entries(urlParams)
    .filter(([key, value]) => isValidParamValue(value))
    .map(([key, value]) => `${key}=${value}`)
    .join('&')

  const newUrl = [window.location.pathname, paramString].filter(i => i).join('?');

  if (NAVIGATOR) NAVIGATOR.navigateTo(newUrl);
  else window.history.replaceState(null, '', newUrl);

}

export function removeUrlParam(name?: KnownURLParams, NAVIGATOR?: NavigatorController) {
  if (!name) return;
  let params = getUrlParams();
  delete params[name];
  setUrlParams(params, NAVIGATOR, true);
}

export function removeUrlParams(name?: KnownURLParams | KnownURLParams[], NAVIGATOR?: NavigatorController) {
  if (!name) return;
  if (name instanceof Array) {
    name.forEach(n => removeUrlParam(n, NAVIGATOR));
  } else {
    removeUrlParam(name, NAVIGATOR);
  }
}

export function runIfParamExist(paramKey: KnownURLParams, callback: (paramKey: KnownURLParams, value: string) => unknown) {
  const params = getUrlParams();
  if (paramKey in params) {
    const value = params[paramKey];
    typeof callback === 'function' && !!value && callback(paramKey, value);
  }
}

// export type ParamSet = { [key: string]: ParamValue };

export function mapParamSetToString(params?: Partial<KnownURLParamSetInput>, defaultParams?: Partial<KnownURLParamSetInput>, options?: { allowInfinity?: boolean }) {
  let { allowInfinity } = options || {};
  if (allowInfinity !== false) allowInfinity = true;
  const paramsWithDefault = deepmerge<KnownURLParamSetInput>(defaultParams || {}, params || {});
  return Object.entries(paramsWithDefault).map(entry => {
    let key = entry[0];
    let value: ParamValue = entry[1];
    switch (typeof value) {
      case 'boolean': {
        return value ? key : false;
      }
      case 'string':
      case 'number': {
        if (value === Infinity || value === -1) {
          return `${key}=${9999}`;
        }
        return `${key}=${encodeURIComponent(value)}`;
      }
      case 'object': {
        if (value === null) return `${key}=`;
        if (value instanceof Array) {
          return value.filter(i => !isNil(i)).map(v => `${encodeURIComponent(key)}[]=${encodeURIComponent(v)}`).join('&');
        }
        if (key === 'objectFields') {
          return Object.entries(value)
            .filter(([objName,fieldsArray]) => objName !== undefined && fieldsArray !== undefined)
            .map(([objName,fieldsArray]) => {
              return `fields[${encodeURIComponent(objName)}]=${encodeURIComponent(fieldsArray.join(","))}`
            }).join("&")
        }
        return Object.entries(value)
          .filter(([k, v]) => v !== undefined)
          .map(([k, v]) => {
            if (k === 'whereEquals') {
              return `filter[whereEquals][0]=${v.key}&filter[whereEquals][1]=${v.values.join(',')}`
            }
            else if (k === 'dateRange') {
              if (v.shouldFilterUseArrayIndexForm) {
                return stringifyDateRangeFilterArrayIndexForm(k, v as DateRangeFilter)
              }
              return `${encodeURIComponent(key)}[${encodeURIComponent(k)}]=${encodeURIComponent(stringifyDateRangeFilterCommaDelimited(v as DateRangeFilter))}`
            }
            else return `${encodeURIComponent(key)}[${encodeURIComponent(k)}]=${
              v === null ? "" : encodeURIComponent(v)
            }`;
          }).join('&');
      }
      default: {
        return undefined;
      }
    }
  }).filter(i => i).join('&') || undefined;
}

export const useSyncUrlParams = (a: KnownURLParams, b?: any) => {
  const { NAVIGATOR } = useControllers();
  const location = useLocation();
  return useOnMount(() => {
    const disposer = autorun(() => {
      // setUrlParams without NAVIGATOR will not trigger the urlWacther to rerun again.
      // this is intential, as whatever component calling this is already mounted,
      // but in case this component has been called imperatively (instead of reactively),
      // or that in some cases the param is not already in the URL,
      // we attempt to set the url param anyways.
      !isNil(a) && !isNil(b) && setUrlParam(a, b,
        // NAVIGATOR // DON'T INCLUDE, otherwise would re-trigger the watcher to render the overlays
      );
      NAVIGATOR.recordLocationHistory(location);
    })
    return () => {
      disposer();
      removeUrlParams(a, NAVIGATOR);
    }
  })
}

export const useSyncOverlayURLParams = (name: string) => {
  return useSyncUrlParams('overlay', name);
}
