import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useUIController } from '../../hooks/useRootController.hook';
import { last } from '../../utils/ramdaEquivalents.utils';
// import './OverlayCloseButton.scss';
import BaseButton from '../BaseButton/BaseButton';

interface OverlayCloseButtonProps {
  onBeforeClose?: () => boolean | Promise<boolean>,
  onAfterClose?: Function,
  showLabel?: boolean,
  color?: string,
  overlayIdentifier?: string,
}

const OverlayCloseButton: React.FC<OverlayCloseButtonProps> = props => {

  const { OVERLAY } = useUIController();

  const handleClose = async () => {
    if (props.onBeforeClose) {
      const beforeCloseGuardResult = await props.onBeforeClose();
      if (!beforeCloseGuardResult) return;
    }
    const topmostOverlay = props.overlayIdentifier ? OVERLAY.getOverlay(props.overlayIdentifier) ?? last(OVERLAY.overlays) : last(OVERLAY.overlays);
    topmostOverlay?.close();
    props.onAfterClose && props.onAfterClose();
  }

  return <Observer children={() => (
    <BaseButton
      onClick={handleClose}
      className="OverlayCloseButton subtle"
      icon="close"
      iconVariant="filled"
      size="sm"
      circle={!props.showLabel}
      color={props.color}
      loading={false}
      dataCy="OverlayCloseButton"
      children={props.showLabel && (props.children ? props.children : 'Close')}
    />
  )} />

}

export default OverlayCloseButton;