import { Observer } from 'mobx-react-lite';
import React from 'react';
import './SimplePageContentWrapper.scss';

type SimplePageContentWrapperProps = {}

const SimplePageContentWrapper: React.FC<SimplePageContentWrapperProps> = props => {
  return <Observer children={() => (
    <div className="SimplePageContentWrapper">
      { props.children }
    </div>
  )} />
}

export default SimplePageContentWrapper;