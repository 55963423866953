import { FourLevelNumber, Nillable, Nullable, StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { isNil } from "../base/utils/ramdaEquivalents.utils";
import { ModelName } from "../constants/modelNames.enum";
import { assignable } from "../traits/assignable.trait";
import { AssignmentSatisfaction } from "./makeAssignment.model";
import { User } from "./makeUser.model";

export const makeSurveySatisfactionSnapshot = () => {
	return {
		...assignable(),
		body: '',
		ratingUx: '' as FourLevelNumber,
		ratingOfCounsellingExperience: '' as FourLevelNumber,
		ratingOfOnlineVsOfflineCounselling: '' as FourLevelNumber,
		ratingSelfProgress: '' as Nullable<FourLevelNumber>,
		ratingOfCounsellorRelationship: '' as FourLevelNumber,
		timeCreated: '' as Nillable<string>,
		timeUpdated: '' as Nillable<string>,
	}
}

export type SurveySatisfactionSnapshot = ReturnType<typeof makeSurveySatisfactionSnapshot>;
export type SurveySatisfactionRelatedModels = {
	assignment: AssignmentSatisfaction,
	assignedToUser: User,
	completedByUser: User,
}
export type SurveySatisfactionExtendedProperties = {};

export type SurveySatisfaction = StandardModel<SurveySatisfactionSnapshot>;

export const makeSurveySatisfaction = createStandardModelFactory<SurveySatisfaction, SurveySatisfactionRelatedModels>({
	name: ModelName.surveysSatisfaction,
	snapshotFactory: makeSurveySatisfactionSnapshot
})

export const calculateSatisfactionSurveyScore = (s: SurveySatisfactionSnapshot) => {
	let numberOfQuestionsAnswered = 5;
	if (isNil(s.ratingSelfProgress) || s.ratingSelfProgress === '') numberOfQuestionsAnswered--;
	if (isNil(s.ratingOfOnlineVsOfflineCounselling) || s.ratingOfOnlineVsOfflineCounselling === '') numberOfQuestionsAnswered--;
	return (
		(+s.ratingOfCounsellingExperience) +
		(+(s.ratingSelfProgress ?? 0)) +
		(+s.ratingOfCounsellorRelationship) +
		(+s.ratingUx) +
		(+s.ratingOfOnlineVsOfflineCounselling ?? 0)
	) / numberOfQuestionsAnswered
}

export const calculateSatisfactionSurveyScoreExcludeOptional = (s: SurveySatisfactionSnapshot) => {
	// ratingOfOnlineVsOfflineCounselling is optional, so exclude.
	let numberOfQuestionsAnswered = 4;
	if (isNil(s.ratingSelfProgress) || s.ratingSelfProgress === '') numberOfQuestionsAnswered--;
	return (
		(+s.ratingOfCounsellingExperience) +
		(+(s.ratingSelfProgress ?? 0)) +
		(+s.ratingOfCounsellorRelationship) +
		(+s.ratingUx)
	) / numberOfQuestionsAnswered
}
