import moment from "moment";
import { User } from "../../../models/makeUser.model";
import { Nillable } from "../../@types";
import { getRandomItemFromArray } from "../../utils/random.utils";

export const GOODBYES: Record<
  'simple' | 'morning' | 'afternoon' | 'evening' | 'midnight', 
  ((username?: Nillable<string>) => string)[]
> = {
  'simple': [
    u => u ? `Talk to you later, ${u}!` : `Talk to you later!`,
    u => u ? `See you later, ${u}!` : `See you later!`,
  ],
  'morning': [
    u => u ? `Have a good day, ${u}!` : `Have a good day!`,
  ],
  'afternoon': [
    u => u ? `Talk to you later, ${u}!` : `Talk to you later!`,
    u => u ? `See you later, ${u}!` : `See you later!`,
  ],
  'evening': [
    u => u ? `Enjoy the rest of your evening, ${u}!` : `Enjoy the rest of your evening!`,
  ],
  'midnight': [
    u => u ? `Good night, ${u}!` : `Good night!`,
  ],
}

export const getGoodbyeFn = (type: keyof typeof GOODBYES, userName: Nillable<string>) => () => {
  return getRandomItemFromArray(GOODBYES[type])(userName);
}

export const getGoodbye = (type: keyof typeof GOODBYES, userName: Nillable<string>) => getGoodbyeFn(type, userName)();

export const getGoodbyeTimeOfDay = (user?: Nillable<User>) => {

  const now = moment();
  const userName = user?.username || user?.firstName;

  if (now.isBefore(moment().set('hour', 4)) || now.isAfter(moment().set('hour', 23))) return getGoodbye('midnight', userName);
  if (now.isBefore(moment().set('hour', 12))) return getGoodbye('morning', userName);
  if (now.isBefore(moment().set('hour', 18))) return getGoodbye('afternoon', userName);

  return getGoodbye('evening', userName);

}