import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Nillable } from '../../base/@types';
import InfoTable from '../../base/components/InfoTable/InfoTable';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { Company } from '../../models/makeCompany.model';
import './CompanyInfoTable.scss';

type CompanyInfoTableProps = {
  company: Nillable<Company>,
}

const CompanyInfoTable: React.FC<CompanyInfoTableProps> = props => {
  const { AUTH } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    get currentUserIsContactUser() {
      return AUTH.currentUser?.id === p.company?.contactUserId;
    },
    get canShowCode() {
      return s.currentUserIsContactUser;
    },
  }))
  return <Observer children={() => (
    <div className="CompanyInfoTable">
      <InfoTable>
        <thead></thead>
        <tbody>
          {!!props.company?.name && <tr><th>Name</th><td>{props.company?.name}</td></tr>}
          {s.canShowCode && !!props.company?.code && <tr><th>Company Code / Refugee Code</th><td>{props.company?.code}</td></tr>}
        </tbody>
      </InfoTable>
    </div>
  )} />
}

export default CompanyInfoTable;