import moment from "moment";
import React from "react";
import DateOfBirthRenderer from "../../components/DateOfBirthRenderer/DateOfBirthRenderer";
import UsernameRenderer from "../../components/UsernameRenderer/UsernameRenderer";
import { User } from "../../models/makeUser.model";
import CountryRenderer from "../components/CountryRenderer/CountryRenderer";
import CurrencyRenderer from "../components/CurrencyRenderer/CurrencyRenderer";
import DateRenderer from "../components/DateRenderer/DateRenderer";
import LongTextClamper from "../components/LongTextClamper/LongTextClamper";
import { CountryCode } from "../constants/countries.constants";
import { transformBoolean } from "./boolean.utils";
import { YYYYMMDDHHmmss } from "./time.utils";

export type DataRendererSet = { [name: string]: (...args: any) => string | React.ReactElement | undefined | boolean | null | Element | number };

export type DefaultDataRendererSetType = typeof DefaultDataRenderers;

export const DefaultDataRenderers = {
  id: (id: string | number) => <code>{(id + '').substr(0, 8)}</code>,
  email: (d: string) => <a href={`mailto:${d}`} title={`Email ${d}`}>{d}</a>,
  username: (d: string) => <UsernameRenderer useRealName={false}>{d}</UsernameRenderer>,
  nametag: (d: User) => <UsernameRenderer user={d} useRealName={true} showLastName={true}/>,
  boolean: (d: boolean) => transformBoolean(d),
  title: (d: string) => <strong>{d}</strong>,
  timestamp: (d: string | Date | moment.Moment) => <DateRenderer value={d} format="LL" />,
  timestampFull: (d: string | Date | moment.Moment) => <DateRenderer value={d} format="LLL" />,
  timestampYYYYMMDDHHmmss: (d: string | Date | moment.Moment) => <DateRenderer value={d} format={YYYYMMDDHHmmss} />,
  country: (d: CountryCode) => <CountryRenderer countryId={d} />,
  currency: (d: number) => <CurrencyRenderer value={d} />,
  dateOfBirth: (d: { dateOfBirth?: string | Date }) => <DateOfBirthRenderer value={d.dateOfBirth } format="MMM DD YYYY" showAge={false}/>,
  longText: (d: string) => <LongTextClamper lines="1" maxWidth="300px">{d}</LongTextClamper>
}