import { action, flow, observable, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SHOULD_LOG } from '../../../env';
import { GenericFunction, RouteDef } from '../../@types';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useControllers } from '../../hooks/useRootController.hook';
import joinClassName from '../../utils/className.utils';
import { generateUuid } from '../../utils/id.utils';
import { useAutoSyncWithInitializer, useProps } from '../../utils/mobx.utils';
import { last } from '../../utils/ramdaEquivalents.utils';
import { seconds } from '../../utils/time.utils';
import { makeUrl } from '../../utils/url.utils';
import tick, { doEvery } from '../../utils/waiters.utils';
import WindowTitle from '../WindowTitle/WindowTitle';
import './RouterStack.scss';
import RouterStackLayer from './RouterStackLayer';

interface RouterStackProps {
  name?: string,
  className?: string,
  routes: RouteDef[],
  prefix?: string,
  fillHeight?: boolean,
  debug?: string,
  fallback?: RouteDef,
}

/**
 * A "Stack" of routes that does not discard rendered routes immediately after they are mounted.
 * Useful for "tab" like interfaces or pages that do not immediately goes stale when user leaves and might come back again.
 * By default, routes will be kept for around 1 minute and then disposed if not visited again.
 * The checking loop happens every 30 seconds.
 */
const RouterStack: React.FC<RouterStackProps> = React.memo(props => {

  const p = useProps(props);
  const s = useRouterStackState(p);

  useEffect(() => {
    if (p.debug) {
      SHOULD_LOG() && console.log(`RouterStack[${p.debug ?? p.className}] rerender`, p.routes);
    }
  })

  useOnMount(() => {
    const disposers = [] as GenericFunction[];
    disposers.push(reaction(
      () => s.pathname,
      () => {
        s.handleRouteChange()
      },
      { fireImmediately: true }
    ))
    disposers.push(reaction(
      () => p.routes.map(r => r.identifier).join(','),
      () => {
        s.handleRouteChange()
      },
    ));
    disposers.push(doEvery(() => {
      // automatically clean up routes after a given time to avoid taking up too much memory
      s.mountedRoutes.forEach(r => {
        if (r.isActive) return;
        if (r.routeDef.maxAgeInMinutes === Infinity) return;
        const maxAge = r.routeDef.maxAgeInMinutes ?? 1; // if a page is older than one minute, by default discard it, unless it's defined otherwise in its routeDef.
        const stale = moment(r.timeLastActive).add(maxAge, 'minutes').isBefore();
        if (stale) {
          SHOULD_LOG() && console.log(`disposed route ${r.routeDef.identifier} after ${maxAge} minutes`);
          r.pop()
        };
      })
    }, seconds(30)));
    return () => {
      s.mountedRoutes.forEach(r => r.dispose());
      disposers.forEach(d => d());
    };
  });

  return <Observer children={() => (
    <div className={joinClassName(
      'RouterStack',
      p.className,
      p.fillHeight && 'fillHeight',
    )} data-name={p.name ?? s.id}>
      {s.mountedRoutes.map(r => <div
        className={joinClassName(
          'RouterStackLayerContainer',
          r.shouldRender ? 'shouldRender' : 'hidden',
        )}
        key={r.routeDef.identifier}
        data-disposable={r.routeDef.disposable}
        data-identifier={r.routeDef.identifier}
      >
        <RouterStackLayer
          route={r.routeDef}
          prefix={p.prefix}
        />
      </div>)}
      {s.title && <WindowTitle title={s.title} />}
    </div>
  )} />

})

export const makeRouterStackLayerController = (
  routeDef: RouteDef,
  routerStackState: RouterStackState,
) => {
  const disposers = [] as Function[];
  const s: RouterStackLayerController = observable({
    routeDef,
    mounted: true,
    get routeUrl() {
      return routerStackState.getRouteUrl(s.routeDef);
    },
    get isActive(): boolean {
      return routerStackState.isActiveRoute(s.routeDef);
    },
    shouldRender: true,
    timeLastActive: moment(),
    pop: () => {
      s.mounted = false;
      routerStackState.popRoute(routeDef);
    },
    dispose() {
      disposers.forEach(d => d());
    }
  });
  disposers.push(
    reaction(
      () => s.isActive,
      () => s.timeLastActive = moment()
    )
  );
  return s;
}

export type RouterStackLayerController = {
  routeDef: RouteDef,
  routeUrl: string,
  mounted: boolean,
  isActive: boolean,
  shouldRender: boolean,
  timeLastActive: moment.Moment,
  pop: () => void;
  dispose: () => void;
}

const useRouterStackState = (p: RouterStackProps) => {
  const { NAVIGATOR } = useControllers();
  const location = useLocation();
  const s = useAutoSyncWithInitializer(() => ({
    id: generateUuid(),
    get debug() { return p.debug },
    get prefix() { return p.prefix },
    pathname: location.pathname,
    mountedRoutes: [] as RouterStackLayerController[],
    pushRoute: (route: RouteDef) => flow(function* () {
      const existingLayer = s.mountedRoutes.find(r => r.routeDef.identifier === route.identifier);
      if (!existingLayer) {
        s.mountedRoutes.push(makeRouterStackLayerController(route, s));
      } else {
        existingLayer.shouldRender = true;
      }
      yield tick(50);
      if (p.debug) console.log('router stack state:', s);
    })(),
    popRoute: action((route: RouteDef) => {
      const i = s.mountedRoutes.findIndex(r => r.routeDef.identifier === route.identifier);
      if (i >= 0) {
        const rs = s.mountedRoutes.splice(i, 1);
        rs.forEach(r => r.dispose());
      }
    }),
    getRouteUrl: (route: RouteDef) => makeUrl(s.prefix, route.urlPattern ?? route.urlFactory()),
    isActiveRoute: (route: RouteDef) => {
      const r = route;
      const url = s.getRouteUrl(route)
      const { pathname } = s;
      const result = r.exact ? url === pathname : pathname.match(new RegExp('^' + url))
      return Boolean(result);
    },
    get focusedRoute() {
      return last(s.mountedRoutes.filter(r => r.shouldRender));
    },
    get hasSuspended() {
      if (!p.prefix) return false;
      return !s.pathname.match(new RegExp(`^${p.prefix}`));
    },
    get title() {
      return s.focusedRoute?.routeDef.composeTitle() ?? '';
    },
    handleRouteChange: flow(function* () {
      yield tick(); // render components on the next tick
      for (let route of p.routes) {
        if (s.isActiveRoute(route)) {
          s.pushRoute(route);
        } else {
          if (p.debug) console.log('inactive. disposable?', route.disposable);
          if (route.disposable) s.popRoute(route);
          else {
            const existingLayer = s.mountedRoutes.find(r => r.routeDef.identifier === route.identifier);
            if (existingLayer) existingLayer.shouldRender = false;
          }
        }
      }
      if (s.mountedRoutes.length === 0 && p.fallback) {
        NAVIGATOR.navigateTo(makeUrl(p.prefix, p.fallback.urlFactory()));
      }
    }),
  }), { pathname: location.pathname });
  return s;
}
type RouterStackState = ReturnType<typeof useRouterStackState>;


export default RouterStack;