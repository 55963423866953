import { Observer } from 'mobx-react-lite';
import React from 'react';
import { getSize, isSize, Size } from '../../constants/size.enum';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { isNumberLike } from '../../utils/typeChecks.utils';
import './BaseGrid.scss';

interface P {
  className?: string;
  columns?: (string | number)[] | string | number;
  rows?: (string | number)[] | string | number;
  gap?: number | string | Size;
  areas?: (string | null)[][];
  alignItems?: string;
  justifyItems?: string;
}

export const BaseGrid: React.FC<P> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    get columns() {
      const input = p.columns;
      let content = '';
      if (input instanceof Array) content += input.map(n => typeof n === 'string' ? n : n + 'fr').join(' ');
      else if (typeof input === 'number') content += `repeat(${input}, minmax(0, 1fr))`;
      else if (typeof input === 'string') content += input;
      else content += 'minmax(0,1fr)';
      return `[grid-column-start] ${content} [grid-column-end]`
    },
    get rows() {
      const input = p.rows;
      if (input instanceof Array) return input.map(n => typeof n === 'string' ? n : n + 'fr').join(' ');
      else if (typeof input === 'number') return `repeat(${input}, minmax(0, 1fr))`;
      else if (typeof input === 'string') return input;
      return undefined;
    },
    get areas() {
      return p.areas ? p.areas.map(r => `"${r.map(c => c || '.').join(' ')}"`).join() : undefined;
    },
    get gap() {
      return isSize(p.gap) ? getSize(p.gap) : p.gap;
    },
    get style() {
      return {
        gridTemplateColumns: s.columns,
        gridTemplateRows: s.rows,
        gridGap: s.gap ?? '.5em',
        gridTemplateAreas: s.areas,
        alignItems: p.alignItems,
        justifyItems: p.justifyItems,
        '--BaseGridColumnFlexBasisFallback': p.columns ? isNumberLike(p.columns) ? 1 / (+p.columns ?? 1) * 100 - 5 + '%' : undefined : undefined,
      }
    },
  }))
  return <Observer children={() => (
    <div className={joinClassName("BaseGrid", p.className)} style={s.style} data-columns={p.columns}>
      { props.children }
    </div>
  )} />
}


export default BaseGrid;