import { flow, reaction } from 'mobx';
import { Observer, useLocalObservable } from 'mobx-react-lite';
import React, { CSSProperties } from 'react';
import { LocalAudioTrack, LocalParticipant, LocalVideoTrack, RemoteAudioTrack, RemoteParticipant, RemoteVideoTrack } from 'twilio-video';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import { Primary } from '../../base/constants/color.enum';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useObservableRef } from '../../base/hooks/useObservableRef.hook';
import { ChatMediator } from '../../base/mediators/chat.mediator';
import { getContextColorStyle } from '../../base/utils/colors.utils';
import { makeDisposerController } from '../../base/utils/disposer.utils';
import { useProps } from '../../base/utils/mobx.utils';
import tick from '../../base/utils/waiters.utils';
import { SHOULD_LOG } from '../../env';
import { initTrackUI } from '../../utils/video.utils';
import UserIcon from '../UserIcon/UserIcon';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import './ChatWindowVideoRoomParticipant.scss';

type ChatWindowVideoRoomParticipantProps = {
  chat: ChatMediator,
  participant: RemoteParticipant | LocalParticipant,
  isSelf?: boolean,
  style?: CSSProperties,
  onClick?: (participant?: RemoteParticipant | LocalParticipant) => void;
}

const ChatWindowVideoRoomParticipant: React.FC<ChatWindowVideoRoomParticipantProps> = props => {

  const ref = useObservableRef<HTMLDivElement>();

  const p = useProps(props);

  const s = useLocalObservable(() => ({
    hasMounted: false,
    tracksRendered: [] as (RemoteAudioTrack | RemoteVideoTrack | LocalVideoTrack | LocalAudioTrack)[],
    get chatParticipant() {
      return p.chat.participants.find(part => part.twilioIdentities.includes(p.participant.identity));
    },
    videoOn: true,
    videoWidth: 640,
    videoHeight: 480,
    audioOn: true,
  }))

  useOnMount(() => {
    if (s.hasMounted) {
      ref.current!.innerHTML = '';
    }
    const d = makeDisposerController();
    d.add(reaction(
      () => !!ref.current,
      flow(function * () {
        yield tick(1000);
        SHOULD_LOG() && console.log('initiating tracks');
        const tracksIterator = p.participant.tracks.values();
        let next = tracksIterator.next();
        while (!next.done) {
          const publication = next.value;
          if ('isSubscribed' in publication && !publication.isSubscribed) return;
          SHOULD_LOG() && console.count('loop: initing');
          d.add(yield initTrackUI(s, publication.track, ref));
          next = tracksIterator.next();
        }
        p.participant.on('trackSubscribed', async track => {
          SHOULD_LOG() && console.log('new track subscribed:', track);
          d.add(await initTrackUI(s, track, ref));
        });
        s.hasMounted = true;
      }),
      { fireImmediately: true }
    ))
    d.add(() => {
      s.tracksRendered.forEach(track => {
        const attachedElements = track.detach();
        attachedElements.forEach(element => {
          if (element.parentElement === ref.current) {
            element.remove()
          }
        });
      })
    })
    return d.disposer;
  })

  const handleClick = () => {
    p.onClick?.(p.participant);
  }

  return <Observer children={() => {
    return <div
      className="ChatWindowVideoRoomParticipant"
      data-is-self={p.isSelf}
      data-type={p.isSelf ? 'self' : 'remote'}
      data-id={p.participant.sid}
      style={{
        ...p.style,
        ...getContextColorStyle(Primary, s.chatParticipant?.user?.color),
        aspectRatio: (s.videoWidth / s.videoHeight) + "",
        '--AspectRatio': (s.videoHeight / s.videoWidth) * 100 + "%",
      } as CSSProperties}
      onClick={handleClick}
      data-video-on={s.videoOn}
      data-audio-on={s.audioOn}
      data-video-width={s.videoWidth}
      data-video-height={s.videoHeight}
      data-ratio={s.videoHeight / s.videoWidth * 100 + '%'}
      data-mounted={s.hasMounted}
    > 
      {!s.videoOn && <UserIcon user={s.chatParticipant?.user} size={48}/>}
      <div className="ChatWindowVideoRoomParticipantInner" ref={ref} />
      <footer className="ChatWindowVideoRoomParticipantFooter">
        {
          (!s.audioOn || !s.videoOn) && (
            <div className="ChatWindowVideoRoomParticipantStatusDisplay">
              {!s.audioOn && <BaseIcon icon="microphone-off" variant="filled" color="orange" size="1.4rem" />}
              {!s.videoOn && <BaseIcon icon="video-off" variant="filled" color="red" size="1.4rem" />}
            </div>
          )
        }
        <p className="ChatWindowVideoRoomParticipantNameTag">{p.isSelf ? 'You' : <UsernameRenderer user={s.chatParticipant?.user} userId={s.chatParticipant?.userId}/>}</p>
      </footer>
    </div>
  }} />
}

export default ChatWindowVideoRoomParticipant;