import { action, flow, observable, reaction, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { Nullable } from '../../base/@types';
import { BaseCalendarDataFetcher, BaseCalendarEventConfig } from '../../base/components/BaseCalendar/BaseCalendar';
import { BaseCalendarState } from '../../base/components/BaseCalendar/BaseCalendarState';
import BaseInput from '../../base/components/BaseInput/BaseInput';
import BaseSpacer from '../../base/components/BaseSpacer/BaseSpacer';
import { BaseTableAppearanceOptions, BaseTableColumnConfig } from '../../base/components/BaseTable/BaseTable';
import DatePicker from '../../base/components/DatePicker/DatePicker';
import IndexDirectory from '../../base/components/IndexDirectory/IndexDirectory';
import { IndexDirectoryAppearanceOptions, IndexDirectoryViewMode } from '../../base/components/IndexDirectory/indexDirectory.types';
import IndexDirectoryState from '../../base/components/IndexDirectory/IndexDirectoryState';
import InfoTable from '../../base/components/InfoTable/InfoTable';
import TimeInput from '../../base/components/TimeInput/TimeInput';
import { DefaultCounsellingApplicationIncludes } from '../../base/endpoints/counsellingApplication.endpoints';
import { CounsellingSessionEndpointParams, CounsellingSessionEndpoints, DefaultCounsellingSessionIncludesForClient, DefaultCounsellingSessionIncludesForStaff } from '../../base/endpoints/counsellingSession.endpoints';
import { UserEndpoints } from '../../base/endpoints/user.endpoints';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { makeCancelAction, makeTableActionConfig } from '../../base/utils/actionConfig.utils';
import { makeLaravelIndexDataFetcher } from '../../base/utils/api.utils';
import { addToArrayIfNew, keepTruthy } from '../../base/utils/array.utils';
import { navigateToChatPage } from '../../base/utils/chat.utils';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { createUTCMoment, YYYYMMDDHHmmss } from '../../base/utils/time.utils';
import { isObject, isString } from '../../base/utils/typeChecks.utils';
import { getUrlParams, removeUrlParam } from '../../base/utils/urlParams.utils';
import { CounsellingType } from '../../constants/counsellingTypes.descriptors';
import { ModelName } from '../../constants/modelNames.enum';
import { APIGetManyResponse } from '../../controllers/api.controller';
import { ActionConfig } from '../../controllers/ui/ui.controller.types';
import { SHOULD_LOG } from '../../env';
import { CounsellingApplication } from '../../models/makeCounsellingApplication.model';
import { CounsellingSession, CounsellingSessionSnapshot } from '../../models/makeCounsellingSession.model';
import { User } from '../../models/makeUser.model';
import { counsellingSessionTableColumnConfigsForAdmin } from '../../modules/Admin/_configs/counsellingSessionTableColumnConfigsForAdmin.config';
import { getCounsellingApplication } from '../../requests/getCounsellingApplication.request';
import { saveCounsellingSession } from '../../requests/saveCounsellingSession.request';
import { useGetUser } from '../../requests/useGetUser.request';
import { getCounsellingApplicationReadableStatus } from '../../utils/counsellingApplication.utils';
import { createNewSessionModelFromApplication, createOverlaySessionEditor } from '../../utils/counsellingSession.utils';
import CommunicationTypeSelector from '../CommunicationTypeSelector/CommunicationTypeSelector';
import CounsellingSessionFeeTypeSelector from '../CounsellingSessionFeeTypeSelector/CounsellingSessionFeeTypeSelector';
import CounsellorSelector from '../CounsellorSelector/CounsellorSelector';
import CounsellingSessionCalendarEventRenderer from './CounsellingSessionCalendarEventRenderer';

interface CounsellingSessionsIndexProps {
  /**
   * if application id or an application is supplied, show sessions of this application.
   * otherwise, all sessions will be fetched
   */
  forApplicationId?: string;
  forClientId?: string;
  application?: CounsellingApplication | null;
  applicant?: User;
  applicantId?: string;
  own?: boolean;
  defaultMode?: IndexDirectoryViewMode,
  allowedViewModes?: IndexDirectoryViewMode[],
  allowEventCreation?: boolean;
  allowSessionEditor?: boolean;
  filterByCounsellorId?: string,
  params?: CounsellingSessionEndpointParams,
  columnConfigs?: BaseTableColumnConfig<CounsellingSession>[],
  appearanceOptions?: BaseTableAppearanceOptions & IndexDirectoryAppearanceOptions,
  showViewChatHistoryAction?: boolean,
}
type EventType = BaseCalendarEventConfig<CounsellingSession>;

const CounsellingSessionsIndex: React.FC<CounsellingSessionsIndexProps> = React.memo(props => {

  const p = useProps(props);

  const { API, UI, AUTH, MESSENGER, NAVIGATOR } = useControllers();

  const calendarName = 'CounsellingSessionsCalendar';

  const eventBuilder = (session: CounsellingSession) => {
    return observable({
      get id() {
        return session.id;
      },
      get startTime() {
        // return session.timeScheduled;
        return session.timeStarted || session.timeScheduled;
      },
      name: 'Counselling Session',
      data: session,
    })
  }

  const createEditorOverlay = (session?: CounsellingSession, onAfterClose?: () => unknown, sessionId?: string) => {
    createOverlaySessionEditor(UI, {
      session,
      sessionId,
      application: p.application,
      applicationId: p.forApplicationId,
      client: p.applicant ?? p.application?.applicant,
      onSessionCreateEditDelete: s.triggerReload,
      onAfterClose,
    });
  }

  const createNewSessionModel = (toMerge?: object) => {
    return createNewSessionModelFromApplication(
      {
        application: p.application,
        applicantId: p.applicantId,
        forApplicationId: p.forApplicationId,
      },
      toMerge,
    );
  }

  const entryCreator = () => new Promise<CounsellingSession>(async (resolve, reject) => {
    const newSession = createNewSessionModel();
    createEditorOverlay(newSession, () => {
      resolve(newSession)
    })
  })

  const calendarEventCreator = (
    startTime: string,
    arr?: EventType[],
  ) => new Promise<EventType>(async (resolve, reject) => {
    const newSession = createNewSessionModel({ timeScheduled: startTime });
    createEditorOverlay(newSession, action(() => {
      const newEvent = eventBuilder(newSession);
      // console.log(newEvent);
      if (newEvent && newEvent.id) {
        arr?.push(newEvent);
      }
      resolve(newEvent);
    }))
  })

  const editSession = (d?: string | CounsellingSession) => {
    const session = isString(d) ? void 0 : d;
    const sessionId = isString(d) ? d : isObject(d) ? d.id : void 0;
    createEditorOverlay(session, void 0, sessionId);
  }

  const s = useStore(() => {

    const _private = observable({
      _application: null as CounsellingApplication | null,
    })

    const store = observable({
      alive: true,
      _user: undefined as User | undefined,
      _application: _private._application,
      state: new IndexDirectoryState<CounsellingSession>({
        viewMode: p.defaultMode || 'calendar',
        sortByKeyName: 'timeScheduled',
        sortDirection: p.params?.sort?.includes(',asc') ? 'asc' : 'desc',
      }),
      calendarState: new BaseCalendarState<CounsellingSession>({
        name: calendarName,
        mode: 'month',
      }),
      calendarEvents: [] as EventType[],
      get application() {
        return p.application || _private._application
      },
      get canCreate() {
        const applicationStatus = getCounsellingApplicationReadableStatus(p.application || _private._application);
        return p.allowEventCreation && applicationStatus.status === 'approved';
      },
      get isAdmin() {
        return AUTH.isStaff;
      },
      get isCounsellor() {
        return AUTH.isCounsellor;
      },
      autoCreateFirstSession: () => {
        if (!p.application) return;
        const defaultSessionSetup: Partial<CounsellingSessionSnapshot> = observable({
          timeScheduled: p.application.availability?.timeStart,
          type: p.application.communicationTypePreference,
          amountPayable: p.application.clientAgreedDonationAmountPerSession ?? (p.application.countryId === 'IE' ? 0 : 30),
          counsellorId: p.application.counsellorId,
          userIds: keepTruthy([
            p.application.applicant?.id ?? p.application.applicantId,
            p.application.invitee?.id ?? p.application.inviteeId,
          ])
        })
        const create = async () => await flow(function* () {
          try {
            const newSessionPayload = createNewSessionModel(defaultSessionSetup).$getSnapshot();
            const newSessionSaved = yield saveCounsellingSession(API, newSessionPayload);
            p.application?.sessions?.push(newSessionSaved);
            getCounsellingSessions();
          } catch (e) {
            reportError(e);
            UI.DIALOG.error({
              heading: 'Failed to create session, please try creating one manually.',
              error: e,
            })
          }
        })();
        if (!s.application?.isCompleted) {
          const applicationWasApprovedMoreThan5MinutesAgo = s.application?.timeApproved && createUTCMoment(s.application?.timeApproved).add(5, 'minutes').isBefore();
          if (applicationWasApprovedMoreThan5MinutesAgo) return;
          when(
            () => !UI.DIALOG.hasDialogs,
            () => {
              if (!s.alive) return;
              UI.DIALOG.present({
                heading: 'Would you like to create the first session automatically?',
                body: <>
                  <InfoTable>
                    <tbody>
                      <tr><th>Counsellor</th><td><CounsellorSelector form={defaultSessionSetup} field="counsellorId" label='' /></td></tr>
                      <tr><th>Date</th><td><DatePicker form={defaultSessionSetup} field='timeScheduled' /></td></tr>
                      <tr><th>Time</th><td><TimeInput form={defaultSessionSetup} field='timeScheduled' /></td></tr>
                      {p.application?.type === CounsellingType.OneToOne && <tr><th>Type</th><td><CommunicationTypeSelector form={defaultSessionSetup} field="type" hideDisabled /></td></tr>}
                      <tr><th>Suggested Donation (€)</th><td>
                        <CounsellingSessionFeeTypeSelector form={defaultSessionSetup} field="amountPayable" application={p.application} hideCustomField />
                        <BaseInput form={defaultSessionSetup} field="amountPayable" type="number" min={1} step={0.01} />
                      </td></tr>
                    </tbody>
                  </InfoTable>
                  <BaseSpacer size=".5em" />
                  <p>If you would like to change any other options, please click cancel and create a session manually.</p>
                </>,
                width: '38em',
                actions: [
                  makeCancelAction(),
                  { name: 'positive', label: 'OK', action: create }
                ]
              })
            }
          )
        }
      },
      firstDataLoaded: false,
      shouldTriggerReload: false,
      triggerReload() {
        s.shouldTriggerReload = true
      },
      destroy() {
        destroy()
      }
    })

    const disposer = reaction(
      () => store.shouldTriggerReload,
      action(() => {
        if (store.shouldTriggerReload) {
          store.shouldTriggerReload = false
        }
      }),
    )

    const destroy = () => {
      disposer();
    }

    return store;
  });

  useGetUser({observable: s, key: '_user'}, p.own ? AUTH.currentUser?.id : p.applicantId ?? p.applicant?.id);

  useOnMount(() => {
    const param = getUrlParams();
    const shouldCreateSession = param.createSession === 'true';
    if (shouldCreateSession) {
      removeUrlParam('createSession');
      calendarEventCreator(
        moment().utc().add(1, 'week').subtract(-1, 'hour').format(YYYYMMDDHHmmss),
        s.calendarEvents,
      );
    }
    if (!p.application && p.forApplicationId) {
      getCounsellingApplication(p.forApplicationId, API);
    }
    return () => {
      s.alive = false;
      s.destroy();
    }
  })

  const getCounsellingSessions = flow(function * (
    params?: object,
    returnOnly?: boolean,
  ) {
    const canUseAdminEndpoint = AUTH.can.provideCounsellingFor_.someUserGroups || AUTH.can.coordinate_.counsellingSessions;
    const paramsToSend: CounsellingSessionEndpointParams = {
      perPage: s.state.viewMode === "calendar" ? Infinity : 8,
      ...p.params,
      ...params,
      filter: {
        applicationId: p.forApplicationId,
        counsellorId: p.filterByCounsellorId,
        // clientId: p.forClientId,
        ...(p.forClientId ? { userIds: [p.forClientId] } : {}),
      },
      include: canUseAdminEndpoint
        ? DefaultCounsellingSessionIncludesForStaff
        : DefaultCounsellingSessionIncludesForClient,
    };
    const url = p.forClientId ? (
      UserEndpoints.staff.getCounsellingSessions(p.forClientId)(paramsToSend)
    ) : CounsellingSessionEndpoints[canUseAdminEndpoint ? 'staff' : 'own'].index(paramsToSend);
    const { entries } = (yield API.getMany(url, ModelName.counsellingSessions)) as APIGetManyResponse<CounsellingSession>;
    const events: EventType[] = entries.map(eventBuilder)
    if (!s.firstDataLoaded) {
      if (events.length === 0) {
        s.autoCreateFirstSession();
      }
      s.firstDataLoaded = true;
    }
    if (returnOnly) return events;
    addToArrayIfNew(s.calendarEvents, ...events);

    return events;
  })

  useOnMount(() => {
    if (p.forApplicationId) getCounsellingSessions();
  });

  const tableDataFetcher = makeLaravelIndexDataFetcher<CounsellingSession>(
    API,
    ModelName.counsellingSessions,
    p.forClientId ? UserEndpoints.staff.getCounsellingSessions(p.forClientId) : CounsellingSessionEndpoints.staff.index,
    () => ({
      perPage: 8,
      own: p.own,
      filter: {
        applicationId: p.forApplicationId,
        filterByCounsellorId: p.filterByCounsellorId,
        clientId: p.forClientId,
      },
      include: AUTH.isStaff ? DefaultCounsellingSessionIncludesForStaff : DefaultCounsellingApplicationIncludes,
      ...p.params,
    }),
  )

  const calendarDataFetcher: BaseCalendarDataFetcher<CounsellingSession> = (
    startDate: string,
    endDate: string,
  ) => new Promise(async (resolve, reject) => {
    try {
      const events = await getCounsellingSessions({
        startDate, endDate, perPage: -1, sort: '-timeScheduled',
        include: AUTH.isCounsellor ? DefaultCounsellingSessionIncludesForStaff : DefaultCounsellingSessionIncludesForClient,
        filter: {
          applicantId: p.forClientId,
        }
      }, true);
      resolve(events);
    } catch(e) {
      reject(e);
    }
  })

  const columnConfigs = counsellingSessionTableColumnConfigsForAdmin;

  const actionConfigView = makeTableActionConfig('View', editSession);
  const actionConfigViewChat = makeTableActionConfig('View Chat', (d?: CounsellingSession) => {
    console.log('view chat', d);
    if (!d) return;
    const hasStarted = Boolean(d.timeStarted || d.threads![0]?.timeStarted);
    if (!hasStarted) {
      UI.DIALOG.attention({
        heading: 'The session has not started yet, or there is no chat session history available.',
      })
      return;
    }
    const { id = undefined } = (d.threads ? d.threads[0] || {} : {}) || {};
    if (!id) return;
    viewChatHistory(id);
  })

  const viewChatHistory = async (id: string) => {
    try {
      const chat = await MESSENGER.loadChatById(id);
      if (!chat) {
        UI.DIALOG.attention({
          heading: "Hmm, we weren't able to load the chat thread.",
          body: "If you believe this should not have happened, please contact the development team for assistance.",
        });
        return;
      }
      if (UI.onlyPhones) navigateToChatPage(NAVIGATOR, chat.id);
      else {
        chat.keepInDock();
        const stackEl = document.querySelector(
          ".ChatWindowStack"
        ) as Nullable<HTMLElement>;
        UI.focusOnElementInPortal(stackEl);
      }
    } catch (e) {
      reportError(e);
      UI.DIALOG.error({
        heading: "Failed to retrieve the chat thread.",
        error: e,
      });
    }
  }

  const viewCalendarEvent = (e?: EventType) => {
    SHOULD_LOG() && console.log(e);
    editSession(e?.data);
  }

  const bodyRowStyleFactory = (d?: CounsellingSession) => {
    const isLive = d?.timeStarted && !d.timeEnded;
    return {
      backgroundColor: isLive ? '#E1503C22' : undefined,
    }
  }

  return <Observer children={() => {
    const itemActions = [] as ActionConfig<(d?: CounsellingSession) => any>[];
    if (p.allowSessionEditor) itemActions.push(actionConfigView);
    if (p.showViewChatHistoryAction) itemActions.push(actionConfigViewChat);
    return (
      <IndexDirectory<CounsellingSession>
        state={s.state}
        allowedViewModes={p.allowedViewModes}
        dataFetcher={tableDataFetcher}
        tablePropsGetter={() => ({
          columnConfigs: p.columnConfigs ?? columnConfigs,
          itemActions,
          bodyRowStyleFactory,
        })}
        entryCreator={s.canCreate ? entryCreator : undefined}
        appearanceOptions={p.appearanceOptions}
        calendarPropsGetter={() => ({
          state: s.calendarState,
          events: s.calendarEvents,
          eventCreator: s.canCreate ? calendarEventCreator : undefined,
          eventBuilder,
          showHeader: true,
          onEventClick: viewCalendarEvent,
          dataFetcher: p.forApplicationId ? undefined : calendarDataFetcher,
          eventRenderer: CounsellingSessionCalendarEventRenderer,
        })}
        shouldTriggerReload={s.shouldTriggerReload}
      />
    )
  }} />
})

export default CounsellingSessionsIndex;