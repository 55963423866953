import { Observer } from 'mobx-react-lite';
import React from 'react';
// import './BaseCalendarEventDefaultTitleRenderer.scss';
import { BaseCalendarEventConfig } from './BaseCalendar';

interface P<EventDataType = object> {
  calendarEvent: BaseCalendarEventConfig<EventDataType>,
}

function BaseCalendarEventDefaultTitleRenderer<T = object>(props: React.PropsWithChildren<P<T>>) {
  return <Observer children={() => (
    <>{props.calendarEvent.name}</>
  )} />
}

export default BaseCalendarEventDefaultTitleRenderer;