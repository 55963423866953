import { Invoice, InvoiceRelatedModels } from "../../models/makeInvoice.model";
import { StringKeyOf } from "../@types";
import { DefaultIndexParamSet } from "../utils/api.utils";
import { makeApiEndpointConfig, makeApiGetEndpointConfig } from "../utils/endpoints.utils";
import { makeUrl } from "../utils/url.utils";
import { prefixOwn, prefixStaff } from "./namespacePrefixes";

export type InvoiceEndpointParams = DefaultIndexParamSet<Invoice, InvoiceRelatedModels> & {
  include?: (StringKeyOf<InvoiceRelatedModels> | string)[],
}

const staffUrlBase = prefixStaff('invoices');
const ownUrlBase = prefixOwn('invoices');

export const InvoiceEndpoints = {
  staff: {
    index: makeApiEndpointConfig<InvoiceEndpointParams>(staffUrlBase),
    get: makeApiGetEndpointConfig<InvoiceEndpointParams>(staffUrlBase),
    create: () => staffUrlBase,
    update: (id: string) => makeUrl(staffUrlBase, id),
    markAsPaid: (id: string) => makeUrl(staffUrlBase, id, 'mark-as-paid'),
    markAsRejected: (id: string) => makeUrl(staffUrlBase, id, 'mark-as-rejected'),
    delete: (id: string) => makeUrl(staffUrlBase, id),
  },
  own: {
    index: makeApiEndpointConfig<InvoiceEndpointParams>(ownUrlBase),
    get: makeApiGetEndpointConfig<InvoiceEndpointParams>(ownUrlBase),
    create: () => ownUrlBase,
    update: (id: string) => makeUrl(ownUrlBase, id),
  },
}

export const defaultInvoiceEndpointIncludes: (StringKeyOf<InvoiceRelatedModels> | string)[] = ['items', 'payee'];