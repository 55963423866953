import { ChatThreadEndpointParams, ChatThreadEndpoints } from "../base/endpoints/chatThread.endpoints";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { ChatThread } from "../models/makeChatThread.model";

export function getChatThread(API: APIController, id: string, params?: ChatThreadEndpointParams) {
  return new Promise<ChatThread | null>(async (resolve, reject) => {
    try {
      const isStaff = API.ROOT!.children.AUTH.isStaff;
      const url = ChatThreadEndpoints[isStaff ? 'staff' : 'own'].get(id, params);
      const chatThread = await API.get<ChatThread>(url, ModelName.chatThreads);
      resolve(chatThread);
    } catch(e) {
      reject(e);
    }
  })
}