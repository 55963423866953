import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ValidatorResult } from '../../@types';
import { Form } from '../../mediators/form.mediator';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { useFormContext } from '../FormForm/Form.context';
import TelInput, { TelInputProps } from '../TelInput/TelInput';
// import './FormTelInput.scss';

type FormTelInputProps<T extends object = object> = Omit<TelInputProps<T>, 'form'> & {
  form?: Form<T>,
  usePublicAPIValidation?: boolean,
  doNotUseAPIValidation?: boolean,
  doNotUseOnMountGetUserDialCode?: boolean,
  onValidate?: (isValid: ValidatorResult) => void,
}

function FormTelInput<T extends object = object>(
  props: React.PropsWithChildren<FormTelInputProps<T>>
) {

  const context = useFormContext<T>();
  const { form } = context;
  const p = useProps(props);
  const s = useStore(() => ({
    get form() { return p.form ?? form },
  }));

  return <Observer children={() => (
    <TelInput {...p}
      className={joinClassName('FormTelInput', p.className)}
      form={s.form.fields[p.field]}
      field="value"
      name={p.name ?? p.field}
      disabled={p.disabled || context.disabled || !s.form.editableFieldNames.includes(p.field)}
      usePublicAPIValidation={p.usePublicAPIValidation}
      doNotUseAPIValidation={p.doNotUseAPIValidation}
      doNotUseOnMountGetUserDialCode={p.doNotUseOnMountGetUserDialCode}
      onValidate={p.onValidate}
    />
  )} />
}

export default FormTelInput;