import { action, flow, observable } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState } from '../../base/@types';
import AutosuggestSearchBar from '../../base/components/AutosuggestSearchBar/AutosuggestSearchBar';
import InfoBanner from '../../base/components/InfoBanner/InfoBanner';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import { SupportGroupEndpoints } from '../../base/endpoints/supportGroup.endpoints';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { makeCancelAction } from '../../base/utils/actionConfig.utils';
import { removeFromArrayById, sortByTimeCreatedLatestFirst } from '../../base/utils/array.utils';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { ModelName } from '../../constants/modelNames.enum';
import { ActionConfig } from '../../controllers/ui/ui.controller.types';
import { ContactType, makeContact } from '../../models/makeContact.model';
import { SupportGroup } from '../../models/makeSupportGroup.model';
import { SupportGroupReservation, SupportGroupReservationSnapshot } from '../../models/makeSupportGroupReservation.model';
import { User } from '../../models/makeUser.model';
import { getUserIndex } from '../../requests/getUserIndex.request';
import { saveContact } from '../../requests/saveContact.request';
import { getAgeFromDateOfBirth } from '../../utils/ageAndDateOfBirth.utils';
import { checkIfUserIsWithinAgeGroupsOfSupportGroup } from '../../utils/supportGroup.helpers';
import ContactEditor from '../ContactEditor/ContactEditor';
import UserComputedRoleList from '../UserComputedRoleList/UserComputedRoleList';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import './SupportGroupReservationList.scss';
import SupportGroupReservationListItem from './SupportGroupReservationListItem';

interface SupportGroupReservationListProps {
  supportGroup?: SupportGroup;
  // supportGroupId?: string | number;
  disabled?: any;
}

const SupportGroupReservationList: React.FC<SupportGroupReservationListProps> = props => {

  const p = useProps(props);
  const { API, AUTH, UI } = useControllers();

  const inviteUserToGroup = flow(function* (u?: User) {
    if (!u) return;
    if (!s.supportGroup?.id) return;
    const alreadyJoined = p.supportGroup?.reservations.find(r => r.userId === u.id);
    if (alreadyJoined) return;
    const requiresKeyWorkerInformation = p.supportGroup?.requiresKeyWorkerInformation;
    let keyworker = observable(makeContact({
      type: ContactType.KeyWorker,
      ownerId: u.id,
    }))
    if (requiresKeyWorkerInformation && !u.isStaff) {
      const confirm = yield UI.DIALOG.present({
        heading: 'This support group requires Key Worker information for all non-staff participants.',
        body: <div>
          <p>Please enter the key worker information for <strong><UsernameRenderer user={u} /></strong> before continuing. If you need to skip the form, continue by pressing the "Skip & Invite" button.</p>
          <ShadedBlock>
            <ContactEditor contact={keyworker} applyChangesImmediately/>
          </ShadedBlock>
        </div>,
        actions: [
          makeCancelAction(),
          observable({
            name: 'positive',
            get label() {
              return keyworker.name && keyworker.phone ? 'Confirm & Invite' : 'Skip & Invite';
            },
            action: flow(function * () {
              if (keyworker.name && keyworker.phone) {
                keyworker = yield saveContact(keyworker.$snapshot, API);
              }
              return 'positive'
            })
          })
        ]
      });
      if (!confirm) return;
      
    }
    const url = SupportGroupEndpoints.staff.inviteUser(s.supportGroup.id);
    const payload = {
      userId: u.id,
      keyWorkerId: keyworker.id || undefined,
    } as Partial<SupportGroupReservationSnapshot>;
    const reservation = yield API.post(url, ModelName.supportGroupReservations, payload).catch(e => {
      reportError(e);
      UI.DIALOG.error({
        heading: 'Failed to invite user.',
        error: e,
      })
    });
    if (p.supportGroup?.reservations.includes(reservation)) return;
    p.supportGroup?.reservations.unshift(reservation);
    p.supportGroup && p.supportGroup.reservationCount++;
  });

  const s = useStore(() => ({
    get supportGroup() {
      return p.supportGroup;
    },
    query: '',
    autosuggestHasFocus: false,
    toggleAutoSuggestFocus: action(() => s.autosuggestHasFocus = !s.autosuggestHasFocus),
    autosuggestUserDataFetcher: (query: string, perPage?: number) => getUserIndex(API, {
      filter: { username: query },
      perPage: perPage ?? 100,
      include: ['permissions', 'roles'],
    }),
    autosuggestActions: [
      {
        name: 'invite',
        label: 'Invite',
        action: inviteUserToGroup,
      }
    ] as ActionConfig[],
    get canInviteUser() {
      return !p.disabled && AUTH.canFacilitate && s.supportGroup?.id && !s.supportGroup?.timeEnded && !s.isArchived;
    },
    get isArchived() {
      return p.supportGroup?.timeArchived;
    },
    get sortedReservations() {
      return sortByTimeCreatedLatestFirst(p.supportGroup?.reservations.filter(r => !r.isRevoked));
    },
    get revokedReservations() {
      return p.supportGroup?.reservations.filter(r => r.isRevoked);
    }
  }));

  const autosuggestResultFilter = (d: User) => {
    const userAlreadyInGroup = s.supportGroup?.reservations?.find(res => res.id === d.id || res.id === d.id);
    if (userAlreadyInGroup) return false;
    const userCanFacilitate = d.isFacilitator;
    if (userCanFacilitate) return true;
    return true;
  };

  const autosuggestUserRenderer = (u: User) => {
    const alreadyJoined = p.supportGroup?.reservations.find(r => r.userId === u.id);
    const isWithinAgeGroup = checkIfUserIsWithinAgeGroupsOfSupportGroup(u, p.supportGroup);
    return <div className="SupportGroupReservationListSuggestionItem">
      <p><strong><UsernameRenderer user={u} /></strong> {alreadyJoined ? <em>(Already Joined)</em> : ''} </p>
      <p>Age: {getAgeFromDateOfBirth(u.dateOfBirth) || 'N/A'}</p>
      {!isWithinAgeGroup && <InfoBanner icon="warning" colorCodedState={ColorCodedState.alert} compact heading="Not within defined age group." />}
      <UserComputedRoleList user={u} />
    </div>
  }

  const handleReservationRevoke = action((r: SupportGroupReservation) => {
    removeFromArrayById(s.supportGroup?.reservations || [], r);
  });

  return <Observer children={() => (
    <div className="SupportGroupReservationList">
      {s.canInviteUser && <AutosuggestSearchBar<User>
        form={s}
        field="query"
        placeholder="Invite user or co-facilitator..."
        dataFetcher={s.autosuggestUserDataFetcher}
        resultItemContentRenderer={autosuggestUserRenderer}
        clearOnAction={true}
        onFocus={s.toggleAutoSuggestFocus}
        onBlur={s.toggleAutoSuggestFocus}
        actions={s.autosuggestActions}
        defaultAction={inviteUserToGroup}
        actionPosition="start"
        resultFilter={autosuggestResultFilter}
        entryShouldDisableChecker={d => p.supportGroup?.reservations.find(r => r.userId === d.id)}
      />}
      <div className="SupportGroupReservationListInner">
        {
          (!s.supportGroup?.reservations || s.supportGroup?.reservations.length === 0) && <InfoBanner icon="info" colorCodedState={ColorCodedState.neutral}>No reservations yet.</InfoBanner>
        }
        {
          s.sortedReservations.map(r => <SupportGroupReservationListItem
            key={r.id}
            reservation={r}
            supportGroup={p.supportGroup}
            onRevoke={handleReservationRevoke}
            disabled={p.disabled}
          />)
        }
        {
          s.revokedReservations?.map(r => <SupportGroupReservationListItem
            key={r.id}
            reservation={r}
            supportGroup={p.supportGroup}
          />)
        }
      </div>
    </div>
  )} />
}

export default SupportGroupReservationList;