import { CommentRelatedModels } from "../../models/makeComment.model";
import { Identifier } from "../@types";
import { DefaultIndexParamSet } from "../utils/api.utils";
import { makeApiEndpointConfig, makeApiGetEndpointConfig } from "../utils/endpoints.utils";
import { makeUrl } from "../utils/url.utils";
import { prefixClient, prefixOwn, prefixStaff } from "./namespacePrefixes";

export type CommentEndpointParams = DefaultIndexParamSet<Comment, CommentRelatedModels> & {
  include?: (keyof CommentRelatedModels | string)[],
  latest?: boolean,
}

const ownUrlBase = prefixOwn('comments');
const clientUrlBase = prefixClient('comments');
const staffUrlBase = prefixStaff('comments');

export const CommentEndpoints = {
  own: {
    index: makeApiEndpointConfig<CommentEndpointParams>(ownUrlBase),
    get: makeApiGetEndpointConfig<CommentEndpointParams>(ownUrlBase),
    /**
     * should not be able to post comments under private thoughts if not own
     */
    create: () => ownUrlBase,
    update: (id: Identifier) => makeUrl(ownUrlBase, id),
    delete: (id: Identifier) => makeUrl(ownUrlBase, id),
  },
  client: {
    /** 
     * e.g. getting a comment received to display in notifications.
     * shouldn't be able to get comments of private thoughts
     */
    get: makeApiGetEndpointConfig<CommentEndpointParams>(clientUrlBase),
  },
  staff: {
    index: makeApiEndpointConfig<CommentEndpointParams>(staffUrlBase),
    get: makeApiGetEndpointConfig<CommentEndpointParams>(staffUrlBase),
    create: (thoughtId: Identifier) => makeUrl(staffUrlBase, thoughtId, 'comments'),
    update: (id: Identifier) => makeUrl(staffUrlBase, id),
    delete: (id: Identifier) => makeUrl(staffUrlBase, id),
  }
}