import { flow, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { SHOULD_LOG } from '../../../env';
import { GenericFunction, Renderable } from '../../@types';
import { Nullable } from '../../@types/utilities.types';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { assertTruthy } from '../../utils/assert.utils';
import joinClassName from '../../utils/className.utils';
import { renderRenderable } from '../../utils/components.utils';
import { getScrollParent } from '../../utils/dom.utils';
import { reportError } from '../../utils/errors.utils';
import { immediateReaction, useProps, useStore } from '../../utils/mobx.utils';
import tick from '../../utils/waiters.utils';
import ClickOrTap from '../ClickOrTap/ClickOrTap';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import './InfiniteFeedLoader.scss';

type InfiniteFeedLoaderProps = {
  /** 
   * a function that returns the generator loader. 
   * MobX seems to be breaking generators when they are thrown into observables, perhaps treating them as flow()? 
   * Hence we pass in a function that returns the generator, which is then cached in a computed property in the state object.
   * this means when the getter function is replaced, so will the loader generator in this component.
   * */
  loaderGeneratorGetter?: () => Nullable<AsyncGenerator<any>>,
  /** the scrollable container that the loader should be watching. */
  scrollContextGetter?: () => Nullable<HTMLElement>;
  /** the loader displays a message when it finishes loading, and it can be customised here. */
  doneMessage?: Renderable;
  /** offset (Y) of the loader visibility detector. */
  offset?: number,
  /** added onBeforeManualRetry to allow a chance for the laoder getter to return a new loader generator */
  onBeforeManualRetry?: () => Promise<void> | void,
  dataCy?: string,
  invisible?: boolean,
}

/**
 * When the loader is visible, it calls the generator function supplied by a getter to retrieve data.
 * It will stop when the generator is done.
 * The generator can be resupplied, resetting the loader's state and start loading again.
 */
const InfiniteFeedLoader: React.FC<InfiniteFeedLoaderProps> = props => {

  const p = useProps(props);

  const ref = useRef<HTMLButtonElement>(null);

  const s = useStore(() => ({
    get loaderGenerator() {
      return p.loaderGeneratorGetter?.();
    },
    isLoading: false,
    errorWhileLoading: null as Nullable<Error>,
    timeLastLoaded: null as Nullable<string>,
    scrollParent: null as HTMLElement | null,
    loaderOffsetTopFromScrollParent: 0,
    scrollPosition: 0,
    done: !Boolean(p.loaderGeneratorGetter),
    get loaderElementVisible() {
      return (s.loaderOffsetTopFromScrollParent + (p.offset ?? 0)) < s.scrollPosition;
    },
    get shouldLoad() {
      return !s.done && s.loaderElementVisible && !s.isLoading && !s.errorWhileLoading;
    },
    updatingScrollPosition: false,
    updateScrollPosition: () => {
      if (s.updatingScrollPosition) return;
      when(
        () => Boolean(s.scrollParent),
        flow(function* () {
          assertTruthy(s.scrollParent);
          s.updatingScrollPosition = true;
          s.scrollParent.dispatchEvent(new Event('scroll')); // trigger scroll event to force offsetTop to get updated
          yield tick(382); // wait a bit for UI to catch up
          s.loaderOffsetTopFromScrollParent = ref.current?.offsetTop || 0;
          s.scrollPosition = s.scrollParent.scrollTop + s.scrollParent.clientHeight;
          s.updatingScrollPosition = false;
        }
      ))
    },
    loadData: () => flow(function* () {
      if (s.isLoading) return;
      try {
        SHOULD_LOG() && console.info('InfiniteFeedLoader loading');
        s.done = false;
        s.isLoading = true;
        s.errorWhileLoading = null;
        const next: IteratorResult<any> = yield s.loaderGenerator?.next();
        yield tick(500); // wait for UI updates after data load
        yield s.updateScrollPosition();
        yield tick(500);
        if (!next || next.done) {
          s.done = true;
          SHOULD_LOG() && console.info('InfiniteFeedLoader reached the end');
        }
        s.isLoading = false;
        SHOULD_LOG() && console.info('InfiniteFeedLoader finished loading');
      } catch (e) {
        s.errorWhileLoading = e as Error;
        SHOULD_LOG() && console.warn('InfiniteFeedLoader load error');
        reportError(e);
        s.isLoading = false;
      }
    })(),
    /** manually trigger loading by clicking on the loader UI */
    handleClick: async () => {
      if (s.isLoading) return;
      await p.onBeforeManualRetry?.(); // this call might introduce a new loader
      await tick(); // so we need to wait for the new loader to get updated in the state object before using it
      s.loadData();
    },
  }))

  useOnMount(() => {
    const disposers = [] as GenericFunction[];
    // when shouldLoad becomes true, load data
    disposers.push(immediateReaction(
      () => s.shouldLoad,
      value => {
        if (!value) return;
        s.loadData();
      }
    ))
    // maintain scroll listener on the correct scroll parent
    disposers.push(immediateReaction(
      () => p.scrollContextGetter?.(),
      contextEl => {
        const parent = contextEl ?? getScrollParent(ref.current);
        if (!parent) return;
        s.scrollParent?.removeEventListener('scroll', s.updateScrollPosition);
        s.scrollParent = parent as HTMLElement;
        s.scrollParent?.addEventListener('scroll', s.updateScrollPosition);
        s.updateScrollPosition();
      },
    ));
    return () => disposers.forEach(d => d());
  })

  return <Observer children={() => {
    const content = (function() {
      if (s.isLoading) return <LoadingIndicator delay={0} />
      if (s.errorWhileLoading) return <p>An error occurred... <ClickOrTap /> to retry.</p>
      if (s.done) return <>
        {p.doneMessage ? <div>{renderRenderable(p.doneMessage)}</div> : <div>
          <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.64811 3.4523C10.4738 1.69979 13.8636 1.09662 17.1203 1.76682C20.2428 2.40939 23.0154 4.17562 24.9168 6.7225L12.25 19.3894L6.13032 13.2697L5.06966 14.3304L11.7197 20.9803L12.25 21.5107L12.7803 20.9803L29.6803 4.08036L28.6196 3.0197L25.9874 5.6519C23.8713 2.90429 20.8342 0.99966 17.4227 0.297611C13.7901 -0.449928 10.0092 0.222841 6.85751 2.17756C3.70579 4.13229 1.42288 7.22036 0.478243 10.8067C-0.46639 14.3931 -0.000924338 18.205 1.77891 21.4587C3.55875 24.7124 6.51766 27.1604 10.0471 28.2993C13.5766 29.4382 17.4083 29.1815 20.7542 27.5818C24.1002 25.9822 26.706 23.1612 28.0358 19.6992C29.3656 16.2371 29.3183 12.3971 27.9036 8.96885L26.517 9.54104C27.7854 12.6146 27.8278 16.0574 26.6355 19.1613C25.4433 22.2652 23.1071 24.7943 20.1072 26.2285C17.1074 27.6627 13.6721 27.8929 10.5078 26.8718C7.34342 25.8507 4.69061 23.6559 3.09489 20.7388C1.49917 17.8218 1.08186 14.4041 1.92877 11.1888C2.77568 7.97342 4.82244 5.20481 7.64811 3.4523Z" fill="currentColor" />
          </svg>
          <p>You are all caught up!</p>
        </div>}
      </>
      return <p>Load more</p>
    })();
    return (
      <button
        className={joinClassName(
          'InfiniteFeedLoader',
          s.loaderGenerator && 'has-loader',
          s.isLoading && 'loading',
          s.errorWhileLoading && 'hasError',
          s.done && 'done',
          p.invisible && 'invisible'
        )}
        type="button"
        onClick={s.handleClick}
        ref={ref}
        data-cy={p.dataCy}
      >
        <div className="InfiniteFeedLoaderInner">
          { content }
        </div>
      </button>
    )
  }} />
}

export default InfiniteFeedLoader;