import { Observer } from 'mobx-react-lite';
import React from 'react';
import AppPage from '../../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../../base/components/AppPageHeader/AppPageHeader';
import BackButton from '../../../../../base/components/BackButton/BackButton';
import BaseSpacer from '../../../../../base/components/BaseSpacer/BaseSpacer';
import UIBlock from '../../../../../base/components/UIBlock/UIBlock';
import { useControllers } from '../../../../../base/hooks/useRootController.hook';
import UserAddressManager from '../../../../../components/UserAddressManager/UserAddressManager';
import './AccountPageAddressManager.scss';

type AccountPageAddressManagerProps = {}

const AccountPageAddressManager: React.FC<AccountPageAddressManagerProps> = props => {

  const { AUTH } = useControllers();

  return <Observer children={() => (
    <AppPage
      className="AccountPageAddressManager"
      accommodateTitleBarSpace
    >
      <AppPageHeader
        title="Manage Addresses"
        startSlot={<BackButton destination="up" />}
      />
      <AppPageContent>
        <UIBlock padded spaceChildren>
          <UserAddressManager user={AUTH.currentUser} />
        </UIBlock>
        <BaseSpacer />
      </AppPageContent>
    </AppPage>
  )} />
}

export default AccountPageAddressManager;