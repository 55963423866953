import { flow } from "mobx";
import { AddressEndpoints } from "../base/endpoints/address.endpoints";
import { getSnapshot } from "../base/utils/snapshot.utils";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { Address } from "../models/makeAddress.model";

export const saveAddress = flow(function* (
  address: Partial<Address>,
  API: APIController,
  options?: {
    isStaff: boolean,
  }
) {
  const urlType = options?.isStaff ? 'staff' : 'own';
  const endpoint = address.id ? AddressEndpoints[urlType].update : AddressEndpoints[urlType].create;
  const url = endpoint(address.id!);
  const savedAddress = address.id ? (
    yield API.patch(url, ModelName.addresses, getSnapshot(address))
  ) : (
    yield API.post(url, ModelName.addresses, getSnapshot(address))
  );
  return savedAddress;
})