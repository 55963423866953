import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Renderable } from '../../../../base/@types';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import joinClassName from '../../../../base/utils/className.utils';
import { useStore } from '../../../../base/utils/mobx.utils';
import { capitalizeFirstLetter } from '../../../../base/utils/string.utils';
import { NavTabConfig } from '../../../../components/AppNavigation/AppNavigation';
import AppNavTab from '../../../../components/AppNavigation/AppNavTab';
import AppTitleBar from '../../../../components/AppTitleBar/AppTitleBar';
import Turn2MeLogo from '../../../../components/Turn2MeLogo/Turn2MeLogo';
import { AccountModuleRouteDef } from '../../../shared/Account/Account.module.routes';
import { ChatModuleRouteDef } from '../../../shared/Chat/Chat.module.routes';
import { CounsellingModuleRouteDef } from '../../Counselling/Counselling.module.routes';
import { ExploreModuleRouteDef } from '../../Explore/Explore.module.routes';
import { SupportGroupsModuleRouteDef } from '../../SupportGroups/SupportGroups.module.routes';
import './ClientAppSubmodule.scss';

type ClientAppSubmoduleProps = {
  className?: string,
  name: string,
  title?: Renderable,
  stringTitle?: string,
  fullHeight?: boolean,
}

const ClientAppSubmodule: React.FC<ClientAppSubmoduleProps> = props => {

  const { UI } = useControllers();

  const s = useStore(() => ({
    navTabs: [
      { def: ExploreModuleRouteDef, icon: 'explore' },
      { def: CounsellingModuleRouteDef, icon: 'counselling' },
      { def: SupportGroupsModuleRouteDef, icon: 'people', label: 'Groups' },
      {
        def: ChatModuleRouteDef,
        icon: 'chat',
        // new messages count
        // badgeNumberGetter: () => MESSENGER.numberOfActiveChats,
      },
      { def: AccountModuleRouteDef, icon: 'person-circle', label: 'You' },
    ] as NavTabConfig[]
  }))

  return <Observer children={() => (
    <div
      className={
        joinClassName(
          'ClientAppSubmodule',
          `Module${capitalizeFirstLetter(props.name)}`,
          props.fullHeight && 'fullHeight'
        )
      }
    >
      <div className="ClientAppSubmoduleInner">
        <AppTitleBar stringTitle={props.stringTitle} title={props.title || <Turn2MeLogo />} />
        {
          UI.fromTablet && <aside className="ClientAppSubmoduleNavTabs">
            <nav className="ClientAppSubmoduleNav">
              {
                s.navTabs.map(r => <AppNavTab
                  key={r.def.identifier}
                  identifier={r.def.identifier}
                  routeDef={r.def}
                  icon={r.icon}
                  label={r.label}
                  prefix="/app"
                  badgeNumberGetter={r.badgeNumberGetter}
                />)
              }
            </nav>
          </aside>
        }
        <div className="ClientAppSubmoduleContent">
          {props.children}
        </div>
      </div>
    </div>
  )} />
}

export default ClientAppSubmodule;