import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { isNil } from "../../../../../base/utils/ramdaEquivalents.utils";
import tick from "../../../../../base/utils/waiters.utils";
import { SHOULD_LOG } from "../../../../../env";

type YAxisConfig<T> = {
  field: keyof T,
  label?: string,
  renderOnOppositeSide?: boolean,
  max?: number,
  min?: number,
  steps?: { value: number, label?: string }[],
}
export async function renderMultipleValueAxisLineGraph<T extends object = object>(options: {
  ref: React.RefObject<HTMLElement>,
  data: T[],
  x: string,
  series: YAxisConfig<T>[],
  name?: string,
}) {

  await tick(250);

  const {
    ref,
    data,
    x,
    series,
    name,
  } = options;

  SHOULD_LOG() && console.log(`Rendering multiple value axis line graph [${name ?? 'unnamed'}] (date-based) in ref`, ref);

  if (!ref.current) return;

  const chart = am4core.create(ref.current, am4charts.XYChart);

  chart.colors.step = 2;

  chart.data = data;

  const fontSize = window.innerWidth > 768 ? 14 : 12;

  let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
  dateAxis.renderer.minGridDistance = 7;
  dateAxis.fontSize = fontSize;

  function createAxisAndSeries(config: YAxisConfig<T>, index: number) {

    const {
      field,
      label = field,
      renderOnOppositeSide = false,
      max,
      min,
      steps
    } = config;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.fontSize = fontSize;

    valueAxis.syncWithAxis = chart.yAxes.getIndex(index) as any;

    if (steps) {
      valueAxis.renderer.grid.template.disabled = true;
      valueAxis.renderer.labels.template.disabled = true;
      steps.forEach(step => {
        let range = valueAxis.axisRanges.create();
        range.value = step.value;
        range.label.text = step.label ?? "{value}";
        range.label.fontSize = fontSize;
      })
    }

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = field as string;
    series.dataFields.dateX = x;
    series.strokeWidth = 2;
    series.yAxis = valueAxis;
    series.name = label as string;
    series.tooltipText = "{name}: [bold]{valueY}[/]";
    series.tensionX = 0.8;
    series.showOnInit = true;
    series.fontSize = fontSize;

    let interfaceColors = new am4core.InterfaceColorSet();

    let bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.stroke = interfaceColors.getFor("background");
    bullet.circle.strokeWidth = 2;
    bullet.fontSize = fontSize;

    valueAxis.renderer.line.strokeOpacity = 1;
    valueAxis.renderer.line.strokeWidth = 2;
    valueAxis.renderer.line.stroke = series.stroke;
    valueAxis.renderer.labels.template.fill = series.stroke;
    valueAxis.renderer.opposite = renderOnOppositeSide;

    if (!isNil(min)) valueAxis.min = min;
    if (!isNil(max)) valueAxis.max = max;

  }

  series.forEach(createAxisAndSeries);

  chart.legend = new am4charts.Legend();
  chart.legend.fontSize = fontSize;

  chart.cursor = new am4charts.XYCursor();

  return chart;

}