import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useStore } from '../../base/utils/mobx.utils';

type RedirectRouteCollectionProps = {}

const RedirectRouteCollection: React.FC<RedirectRouteCollectionProps> = props => {
  const { AUTH } = useControllers();
  const s = useStore(() => ({
    get DashboardRedirector() {
      return () => AUTH.hasAnyPermissions ? <Redirect to="/admin/dashboard" /> : AUTH.isAuthenticated ? <Redirect to="/app/explore" /> : <Redirect to={`/auth/login?redirectedFrom=${encodeURIComponent('/dashboard')}`} />;
    }
  }))
  return <Observer children={() => (
    <Switch>
      <Route exact path="/logout">
        <Redirect to="/auth/logout" />
      </Route>
      <Route exact path="/signout">
        <Redirect to="/auth/logout" />
      </Route>
      <Route exact path="/sign-out">
        <Redirect to="/auth/logout" />
      </Route>
      <Route exact path="/login">
        <Redirect to="/auth/login" />
      </Route>
      <Route exact path="/signin">
        <Redirect to="/auth/login" />
      </Route>
      <Route exact path="/sign-in">
        <Redirect to="/auth/login" />
      </Route>
      <Route exact path="/register">
        <Redirect to="/auth/register" />
      </Route>
      <Route exact path="/registration">
        <Redirect to="/auth/register" />
      </Route>
      <Route exact path="/signup">
        <Redirect to="/auth/register" />
      </Route>
      <Route exact path="/sign-up">
        <Redirect to="/auth/register" />
      </Route>
      {
        AUTH.ready && <>
          <Route exact path="/home">
            {AUTH.hasAnyPermissions ? <Redirect to="/admin/dashboard" /> : AUTH.isAuthenticated ? <Redirect to="/app/explore" /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/dashboard">
            <s.DashboardRedirector />
          </Route>
          {/* This typo is deliberate */}
          <Route exact path="/dashbaord">
            <s.DashboardRedirector />
          </Route>
          <Route exact path="/dash">
            <s.DashboardRedirector />
          </Route>
        </>
      }
      <Route exact path="/explore">
        <Redirect to="/app/explore" />
      </Route>
      <Route exact path="/notifications">
        <Redirect to="/app/notifications" />
      </Route>
      <Route exact path="/account">
        <Redirect to="/app/account" />
      </Route>
      <Route exact path="/admin">
        <Redirect to="/admin/dashboard" />
      </Route>
      <Route exact path="/staff">
        <Redirect to="/admin/dashboard" />
      </Route>
      <Route exact path="/">
        {AUTH.isAuthenticated ? <Redirect to="/app/explore" /> : <Redirect to="/auth/login" />}
      </Route>
    </Switch>
  )} />
}

export default RedirectRouteCollection;