import { useContext } from "react";
import { RootContext } from "../../contexts/context";

export function useRootController() {
  return useContext(RootContext);
}
export function useControllers() {
  return useRootController().children;
}
export function useUIController() {
  return useRootController().children.UI;
}
export function useDialogController() {
  return useRootController().children.UI.DIALOG;
}
export function useOverlayController() {
  return useRootController().children.UI.OVERLAY;
}
export function useToastController() {
  return useRootController().children.UI.TOAST;
}
export function useNativeController() {
  return useRootController().children.UI.NATIVE;
}
export function usePortalController() {
  return useRootController().children.UI.PORTAL;
}
