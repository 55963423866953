import { Observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { AnyObject } from '../../@types';
import { ContactType } from '../../constants/contactType.constants';
import BaseSelector, { BaseSelectorProps } from '../BaseSelector/BaseSelector';
// import './ContactTypeSelector.scss';

export interface ContactTypeSelectorProps<FormType = AnyObject> extends Omit<BaseSelectorProps<FormType, AnyObject, string>, 'options'> {
}

export function ContactTypeSelector<T = AnyObject>(p: React.PropsWithChildren<ContactTypeSelectorProps<T>>) {

  const contactTypeOptionsList = useMemo(() => {
    return ContactType.map(type => {
      return {
        label: type,
        value: type,
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Observer children={() => (
    <BaseSelector<T>
      className="ContactTypeSelector"
      {...p}
      options={contactTypeOptionsList}
      appearance="system"
    />
  )} />
}

export default ContactTypeSelector;