import { Payment, PaymentRelationships } from "../../models/makePayment.model";
import { StringKeyOf } from "../@types";
import { DefaultIndexParamSet } from "../utils/api.utils";
import { makeApiEndpointConfig, makeApiGetEndpointConfig } from "../utils/endpoints.utils";
import { prefixOwn, prefixPublic, prefixStaff } from "./namespacePrefixes";

const ownUrlBase = prefixOwn('payments');
const staffUrlBase = prefixStaff('payments');
const publicUrlBase = prefixPublic('payments');

export type PaymentEndpointParams = DefaultIndexParamSet<Payment, PaymentRelationships> & {
}

export const PaymentEndpoints = {
  staff: {
    index: makeApiEndpointConfig<PaymentEndpointParams>(
      staffUrlBase,
      { perPage: 15 },
      { allowInfinity: false }
    ),
    get: makeApiGetEndpointConfig<PaymentEndpointParams>(staffUrlBase),
  },
  own: {
    tokenise: () => `${ownUrlBase}/tokenise`,

    // used to finalize payments server-side,
    // recommended if atomicity of inventory is required, i.e. only one user is allowed to pay for a limited stock item.
    payServerSide: () => `${ownUrlBase}/pay-server-side`,

    paymentMethodIndex: () => `${ownUrlBase}/payment-methods`,
    deletePaymentMethod: (id: string) => `${ownUrlBase}/payment-method/${id}`,
    createOrGetCustomerId: () => `${ownUrlBase}/create-or-get-customer-id`,
    index: makeApiEndpointConfig<PaymentEndpointParams>(ownUrlBase),
    reportSingleChargeSuccess: () => `${ownUrlBase}/report-single-charge-success`,
  },
  public: {
    getIntent: () => `${publicUrlBase}/intent`,
    tokenise: () => `${publicUrlBase}/tokenise`,
    createPaymentMethod: () => `${publicUrlBase}/payment-method`,
    /** @deprecated in order to use stripe 3d payment, this endpoint is no longer in use. payment is made in JS directly to stripe. */
    singleCharge: () => `${publicUrlBase}/single-charge`,
    reportSingleChargeSuccess: () => `${publicUrlBase}/report-single-charge-success`,
  }
}

export const PaymentEndpointDefaultIncludesForAdmin: StringKeyOf<PaymentRelationships<any>>[] = [
  'model',
  'user',
]