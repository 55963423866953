import { GenericFunction } from "../@types";

export const NoOp = () => {};

export function isAsyncFunction(fn?: Function) {
  return fn?.constructor?.name === 'AsyncFunction'
}

export const runAll = async (fns: GenericFunction[]) => {
  for (let fn of fns) {
    await fn();
  }
}
export const runAllFn = async (fns: GenericFunction[]) => await runAll(fns);