
import { User } from '@sentry/react';
import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import { useControllers } from '../../base/hooks/useRootController.hook';
import joinClassName from '../../base/utils/className.utils';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { Address } from '../../models/makeAddress.model';
import { UserSnapshot } from '../../models/makeUser.model';
import { deleteAddress } from '../../requests/deleteAddress.request';
import { sendSaveUserRequest } from '../../requests/saveUser.request';
import AddressRenderer from '../AddressRenderer/AddressRenderer';
import OverlayAddressEditor from '../OverlayAddressEditor/OverlayAddressEditor';
import './AddressItem.scss';

type AddressItemProps = {
  address: Address,
  deletable?: boolean,
  isPrimary?: boolean,
  hidePrimaryButton?: boolean,
  layout?: 'landscape' | 'portrait',
  belongsToUser?: User,
  isSelected?: boolean;
  onClick?: (address: Address) => void;
}

const AddressItem: React.FC<AddressItemProps> = props => {
  const { AUTH, API, UI } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    get belongsUserId() {
      return p.belongsToUser?.id ?? AUTH.currentUser?.id
    },
    get isOwn() {
      return Boolean(AUTH.currentUser?.id && s.belongsUserId === AUTH.currentUser.id);
    },
    makePrimary: async () => await flow(function * () {
      if (p.isPrimary) return;
      const payload: Partial<UserSnapshot> = {
        id: s.belongsUserId,
        primaryAddressId: p.address.id,
      }
      yield sendSaveUserRequest(API, payload, {
        isSelf: s.isOwn
      });
      yield AUTH.fetchCurrentUserInfo();
    })(),
    edit: () => {
      UI.OVERLAY.present({
        component: <OverlayAddressEditor address={p.address} />
      })
    },
    deleteAddress: async () => {
      if (!p.deletable) {
        UI.DIALOG.attention({
          heading: 'You cannot delete this address.',
          body: 'For regulatory reasons you must keep at least one address to be eligible for our clinical services.',
        })
        return;
      }
      if (p.isPrimary) {
        UI.DIALOG.attention({
          heading: 'You cannot delete your primary address.',
          body: 'Please select a different address as primary before deleting this address.',
        })
        return;
      }
      const confirm = await UI.DIALOG.attention({
        heading: 'Are you sure you want to delete this address?',
        defaultActions: ['negative', 'positive'],
      })
      if (!confirm) return;
      try {
        await deleteAddress(p.address, API);
      } catch(e) {
        reportError(e);
        UI.DIALOG.error({
          heading: 'Failed to delete the address',
          error: <ErrorRenderer error={e} />
        })
      }
    }
  }))
  const handleClick = () => {
    p.onClick && p.onClick(p.address);
  }
  return <Observer children={() => (
    <div className={joinClassName(
      'AddressItem',
      !p.hidePrimaryButton && p.isPrimary && 'primary',
      p.layout,
      p.isSelected && 'selected',
    )}>
      <div className="AddressItemContent" onClick={handleClick}>
        <AddressRenderer address={p.address} withLineBreaks />
      </div>
      <div className="AddressItemControls">
        {
          p.isSelected && <div className="AddressItemSelectedMarker"><BaseIcon name="check" variant="filled" /></div>
        }
        <BaseButton dataCy="edit-address" appearance="tab" onClick={s.edit} icon="pencil">Edit</BaseButton>
        {!p.hidePrimaryButton && <BaseButton dataCy="set-primary-address" appearance="tab" icon="home" onClick={s.makePrimary} label={p.isPrimary ? 'Primary Address' : 'Make Primary'} colorCodedState={ColorCodedState.positive} iconVariant={p.isPrimary ? 'filled' : 'regular'} />}
        <BaseButton dataCy="delete-address" appearance="tab" onClick={s.deleteAddress} colorCodedState={ColorCodedState.alert} icon="delete">Delete</BaseButton>
      </div>
    </div>
  )} />
}

export default AddressItem;