import TweenLite from 'gsap';
import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import Swiper from 'swiper';
import { AnyObject, Nullable } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import BaseInput from '../../base/components/BaseInput/BaseInput';
import BaseLabel from '../../base/components/BaseLabel/BaseLabel';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import PseudoLink from '../../base/components/PseudoLink/PseudoLink';
import SwiperContainer from '../../base/components/SwiperContainer/SwiperContainer';
import SwiperSlide from '../../base/components/SwiperContainer/SwiperSlide';
import { SurveyGoalSheetEndpoints } from '../../base/endpoints/survey.endpoints';
import { useControllers, useOverlayController } from '../../base/hooks/useRootController.hook';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { getNowTimestampUtc } from '../../base/utils/time.utils';
import tick from '../../base/utils/waiters.utils';
import { ModelName } from '../../constants/modelNames.enum';
import { isLocalhost } from '../../env';
import { AssignmentGoalSheet } from '../../models/makeAssignment.model';
import { CounsellingApplication } from '../../models/makeCounsellingApplication.model';
import { makeSurveyGoalSheet, makeSurveyGoalSheetSnapshot, SurveyGoalSheet, SurveyGoalSheetSnapshot } from '../../models/makeSurveyGoalSheet.model';
import { makeSubmitSurveyFn } from '../../requests/makeSubmitSurveyFn.request';
import './GoalSheetWizard.scss';

export const ID_GoalSheetWizard = 'GoalSheetWizard';

type GoalSheetWizardProps = {
	application: CounsellingApplication,
	assignment: AssignmentGoalSheet,
}

const GoalSheetWizard: React.FC<GoalSheetWizardProps> = props => {

	const ref = useRef<HTMLDivElement>(null);
	const OVERLAY = useOverlayController();
	const { API, UI, AUTH } = useControllers();

	const p = useProps(props);

	const s = useStore(() => ({
		goalSheet: makeSurveyGoalSheetSnapshot(),
		gradientColors: { from: '#B6B6FA', to:'#4E4BE0' },
		get canSubmit() {
			return s.goalSheet.goalOne && s.goalSheet.goalTwo && s.goalSheet.goalThree;
		},
		isSubmitting: false,
		submitted: false,
		error: null as Nullable<Error>,
		submit: () => flow(function * () {
			s.isSubmitting = true;
			try {
				yield makeSubmitSurveyFn({
					API, UI,
					surveySubType: 'goal-sheet',
					surveyModelName: ModelName.surveysGoalSheet,
					survey: makeSurveyGoalSheet(s.goalSheet),
					surveyDisplayName: 'goal sheet',
					assignment: p.assignment,
					onComplete: survey => {
						if (p.assignment) {
							p.assignment.timeCompleted = getNowTimestampUtc();
							p.assignment.targetId = (survey as any).id;
						}
					}
				})();
				console.log(s.goalSheet);
				s.submitted = true;
			} catch (e) {
				s.error = e as Error;
				reportError(e);
				UI.DIALOG.error({
					heading: `Oh no, failed to submit the goal sheet!`,
					body: () => <>
						<p>We are sorry but the goal sheet failed to submit. Please try submitting again. If this keeps happening, please let us know and we will help you out.</p>
						<ErrorRenderer error={(e as AnyObject).response} />
					</>,
					defaultActions: ['positive'],
				})
			} finally {
				s.isSubmitting = false;
			}
		})(),
		dismiss: () => {
			OVERLAY.dismiss();
		},
		get formSlideUI() {
			return <>
				<div>
					<BaseLabel>Goal One</BaseLabel>
					<BaseInput type="textarea" dataCy="goalOne" form={s.goalSheet} field="goalOne" resize={false} disabled={s.isSubmitting} />
				</div>
				<div>
					<BaseLabel>Goal Two</BaseLabel>
					<BaseInput type="textarea" dataCy="goalTwo" form={s.goalSheet} field="goalTwo" resize={false} disabled={s.isSubmitting} />
				</div>
				<div>
					<BaseLabel>Goal Three</BaseLabel>
					<BaseInput type="textarea" dataCy="goalThree" form={s.goalSheet} field="goalThree" resize={false} disabled={s.isSubmitting} />
				</div>
				<p><em>Tip: Before you submit, also have a quick think “How will I track whether or not I am moving towards this goal / what will I use as my 'measure' or 'indication' of this?”</em></p>
				<BaseButton dataCy="submitGoal" size="lg" rounded color="white" className="subtle" onClick={s.submit} disabled={!s.canSubmit}>Submit Goals</BaseButton>
				{
					Boolean(s.error) && <div><PseudoLink className="GoalSheetWizardTryAgain" onClick={s.dismiss} inheritColor>Try later</PseudoLink></div>
				}
			</>
		},
		handleSlideChange: (index: number) => {
			const gradientColors = s.slides[index]?.gradientColors || ['#B6B6FA', '#4E4BE0'];
			TweenLite.to(s.gradientColors, {
				from: gradientColors[0],
				to: gradientColors[1],
				duration: 1,
				onUpdate: () => {
					const backgroundImage = `radial-gradient(864.78% 100% at 114.93% 119%, ${s.gradientColors.from} 0%, ${s.gradientColors.to} 100%)`;
					TweenLite.set(ref.current, { backgroundImage });
				}
			})
		},
		instance: null as Nullable<Swiper>,
		onInit: async (instance: Swiper) => {
			await tick(100);
			s.instance = instance;
			s.handleSlideChange(0);
		},
		goToNextSlide: () => {
			s.instance?.slideNext();
		},
		get nextButton() {
			return <BaseButton dataCy="goToNextSlide" color="white" className="subtle" rounded size="md" onClick={s.goToNextSlide}>Next →</BaseButton>
		},
		get slides() {
			return [
				{
					name: 'welcome',
					gradientColors: ['#B6B6FA', '#4E4BE0'],
					children: <div className="GoalSheetWizardStepInner" onClick={s.goToNextSlide}>
						<BaseIcon size="3.8rem" icon="counselling" />
						{p.application.isPaid
							? <>
								<h1>Appointment Confirmed!</h1>
								<p>Your appointment for Online Counselling has been confirmed!</p>
							</>
							: <>
								<h1>Counselling Application Approved!</h1>
								<p>Your Online Counselling application has been successfully approved!</p>
							</>
						}
						<p>To help our counsellors in understanding your situation and assist you better, please write down the goals you’d like to achieve and fill in the clinical surveys after this counselling journey.</p>
						{isLocalhost && <p><em>[DEVELOPER ONLY] Press "Escape" to dismiss this overlay.</em></p>}
						{
							(UI.canTouch || UI.onlyPhones) ? <p data-cy="goToNextSlide" className="GoalSheetWizardScrollHint">Swipe right to continue →</p> : s.nextButton
						}
					</div>,
				},
				{
					name: 'smart.s',
					gradientColors: ['#87E9FF', '#1C5DDC'],
					children: <div className="GoalSheetWizardStepInner">
						<BaseIcon size="3.8rem" icon="flag-checkered" />
						<h2>How to set your goals: <br />S.M.A.R.T</h2>
						<h3>“Specific”</h3>
						<p>Please choose goals which can be clearly explained to your therapist and are not overly vague, e.g. wanting to 'be more happy' as a goal is very understandable. However, it is very broad and would make tracking this goal quite difficult.</p>
						{s.nextButton}
					</div>
				},
				{
					name: 'smart.m',
					gradientColors: ['#7EE588', '#07A78A'],
					children: <div className="GoalSheetWizardStepInner">
						<BaseIcon size="3.8rem" icon="flag-checkered" />
						<h2>How to set your goals: <br />S.M.A.R.T</h2>
						<h3>“Measurable”</h3>
						<p>Please indicate below how you will actually track/gauge a therapy goal across sessions, e.g. if your goal concerned socialising more, you might keep track of how often you are going out and meeting others.</p>
						{s.nextButton}
					</div>
				},
				{
					name: 'smart.a',
					gradientColors: ['#FFC27B', '#D16E12'],
					children: <div className="GoalSheetWizardStepInner">
						<BaseIcon size="3.8rem" icon="flag-checkered" />
						<h2>How to set your goals: <br />S.M.A.R.T</h2>
						<h3>“Achievable”</h3>
						<p>Please only pick goals which you can work towards yourself, i.e. no goals which depend entirely on others' actions, etc.</p>
						{s.nextButton}
					</div>
				},
				{
					name: 'smart.r',
					gradientColors: ['#C9ACDF', '#474193'],
					children: <div className="GoalSheetWizardStepInner">
						<BaseIcon size="3.8rem" icon="flag-checkered" />
						<h2>How to set your goals: <br />S.M.A.R.T</h2>
						<h3>“Realistic”</h3>
						<p>Please choose goals for therapy which are realistic. Longer-term goals can be discussed with your therapist.</p>
						{s.nextButton}
					</div>
				},
				{
					name: 'smart.t',
					gradientColors: ['#FF7B7B', '#E44545'],
					children: <div className="GoalSheetWizardStepInner">
						<BaseIcon size="3.8rem" icon="flag-checkered" />
						<h2>How to set your goals: <br />S.M.A.R.T</h2>
						<h3>“Time-Framed”</h3>
						<p>Please choose goals which you can also achieve within a specific timeframe you can discuss this more with your therapist.</p>
						{s.nextButton}
					</div>
				},
				{
					name: 'form',
					gradientColors: ['#B6B6FA', '#4E4BE0'],
					children: <div className="GoalSheetWizardStepInner">
						<BaseIcon size="3.8rem" icon="flag-checkered" />
						<h2>Your Turn!</h2>
						<h3>By the end of my sessions at turn2me, I&nbsp;would&nbsp;like&nbsp;to...</h3>
						{ s.formSlideUI }
					</div>
				},
			]
		}
	}));

	/** dev helper to quickly fill in the goal sheet */
	useHotkeys('escape', () => {
		if (!isLocalhost) return;
		flow(function * () {
			const url = SurveyGoalSheetEndpoints.own.create();
			const payload: Partial<SurveyGoalSheetSnapshot> = {
				goalOne: "goal one example",
				goalTwo: "goal two example",
				goalThree: "goal three example",
				assignmentId: p.assignment.id,
				assignedToUserId: p.assignment.assignedToUserId,
				completedByUserId: AUTH.currentUser?.id,
				timeCompleted: getNowTimestampUtc(),
			}
			yield API.post<SurveyGoalSheet>(url, ModelName.surveysGoalSheet, payload);
			UI.TOAST.success('Automatically filled in goal sheet.');
		})();
		s.dismiss();
	})

	return <Observer children={() => (
		<div className="GoalSheetWizard">
			<SwiperContainer
				innerRef={ref}
				onInit={s.onInit}
				onSlideChange={s.handleSlideChange}
				paginationType='bullets'
				slides={
					<>
						{ s.slides.map((slide, i) => <SwiperSlide
							key={slide.name}
							children={
								<div className="GoalSheetWizardStep">{slide.children}</div>
							}
						/>)}
					</>
				}
			/>
			{
				s.submitted && <div className="GoalSheetWizardSuccess">
					<BaseIcon size="3.8rem" icon="flag-checkered" />
					<h2>Goals Submitted!</h2>
					<p>Thank you for spending your time to apply and fill out your goals. Our dedicated counsellors will be in touch with you very soon!</p>
					<BaseButton size="lg" rounded color="white" className="subtle" onClick={s.dismiss} disabled={!s.canSubmit}>Done</BaseButton>
				</div>
			}
		</div>
	)} />
}

export default GoalSheetWizard;