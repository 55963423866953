import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Nillable } from '../../base/@types';
import InfoTable from '../../base/components/InfoTable/InfoTable';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { User } from '../../models/makeUser.model';
import './CompanyContactUserInfoTable.scss';

type CompanyContactUserInfoTableProps = {
  user: Nillable<User>,
  hideEmail?: boolean,
  hidePhone?: boolean,
  hideGender?: boolean,
}

const CompanyContactUserInfoTable: React.FC<CompanyContactUserInfoTableProps> = props => {
  const { AUTH } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    get user() {
      return p.user;
    },
    get isYou() {
      return AUTH.currentUser?.id === p.user?.id;
    },
  }))
  return <Observer children={() => (
    <div className="CompanyContactUserInfoTable">
      <InfoTable>
        <thead><tr><th colSpan={2}>Contact Person {s.isYou && '(You)'}</th></tr></thead>
        <tbody>
          {!!s.user?.username && <tr><th>Username</th><td>{s.user?.username}</td></tr>}
          {!!s.user?.firstName && <tr><th>First Name</th><td>{s.user?.firstName}</td></tr>}
          {!!s.user?.lastName && <tr><th>Last Name</th><td>{s.user?.lastName}</td></tr>}
          {!p.hideEmail && !!s.user?.email && <tr><th>Email</th><td>{s.user?.email}</td></tr>}
          {!p.hidePhone && !!s.user?.mobileNumber && <tr><th>Mobile Number</th><td>{s.user?.mobileNumber}</td></tr>}
          {/* {!p.hideGender && !!s.user?.gender && <tr><th>Gender</th><td>{s.user?.gender}</td></tr>} */}
        </tbody>
      </InfoTable>
    </div>
  )} />
}

export default CompanyContactUserInfoTable;