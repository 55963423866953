/**
 * NOTES:
 * chats and messages do not generate alerts.
 */

export enum AlertTypeName {

  Empty = 'Empty', // app internal use
  Generic = 'Generic',

  Client__NewReactionReceived = 'Client__NewReactionReceived',
  Client__NewComment = 'Client__NewComment',

  Client__ApplicationApproval = 'Client__ApplicationApproval',
  Client__ApplicationRejection = 'Client__ApplicationRejection',
  Client__ApplicationJourneyCompletion = 'Client__ApplicationJourneyCompletion',

  Client__NewCounsellingSessionScheduled = 'Client__NewCounsellingSessionScheduled',
  Client__CounsellingSessionRescheduled = 'Client__CounsellingSessionRescheduled',
  Staff__CounsellingSessionCancelledByClient = 'Staff__CounsellingSessionCancelledByClient',

  Client__SupportGroupReminder = 'Client__SupportGroupReminder',
  Client__SupportGroupStarted = 'Client__SupportGroupStarted',
  Client__SupportGroupReservationRevoked = 'Client__SupportGroupReservationRevoked',

  Staff__NewApplicationReceived = 'Staff__NewApplicationReceived',
  Staff__NewModerationFlagRaised = 'Staff__NewModerationFlagRaised',
  Staff__NewSupportGroupReservation = 'Staff__NewSupportGroupReservation',

  Staff__InvoicePaid = 'Staff__InvoicePaid',
  Staff__InvoiceRejected = 'Staff__InvoiceRejected',
  Staff__InvoiceItemRemoved = 'Staff__InvoiceItemRemoved',

}