import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Renderable } from '../../../../base/@types';
import joinClassName from '../../../../base/utils/className.utils';
import { renderRenderable } from '../../../../base/utils/components.utils';
import './DashBlock.scss';
import DashBlockCountBadge from './DashBlockCountBadge';

type DashBlockProps = {
	className?: string,
	heading?: Renderable,
	headerEnd?: Renderable,
	headerEndAlign?: 'start' | 'end',
	paddingTop?: string | number,
	count?: number,
	clipContent?: boolean,
	fullWidthContent?: boolean,
}

const DashBlock: React.FC<DashBlockProps> = props => {
	return <Observer children={() => (
		<div className={
			joinClassName(
				'DashBlock',
				props.className,
				props.clipContent && 'clipContent',
				props.fullWidthContent && 'fullWidthContent',
				props.headerEndAlign && `headerEndAlign--${props.headerEndAlign}`
			)
		} style={{
			paddingTop: props.paddingTop,
		}}>
			{ props.heading && <header className="DashBlockHeader">
				<div className="DashBlockHeaderInner">
					<div className="DashBlockHeading">{renderRenderable(props.heading)}</div>
					<DashBlockCountBadge count={props.count} />
				</div>
				{ props.headerEnd && <div className="DashBlockHeaderEnd">
					{ renderRenderable(props.headerEnd) }
				</div>}
			</header> }
			{
				props.children && <div className="DashBlockContent">
					{ props.children }
				</div>
			}
		</div>
	)} />
}

export default DashBlock;