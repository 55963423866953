import React from 'react';
import ColorTag from './ColorTag';

interface ColorTagYoungPeople1214Props {
  useAbbr?: boolean
}

const ColorTagYoungPeople1214: React.FC<ColorTagYoungPeople1214Props> = props => {
  return <ColorTag className="ColorTagYoungPeople1214" color="yellow">
    {props.useAbbr ? '12-14' : 'Young People 12–14'}
  </ColorTag>
}

export default ColorTagYoungPeople1214;