import { always } from "../../../../../base/utils/ramdaEquivalents.utils";
import { makeObservableRouteDef } from "../../../../../base/utils/routes.utils";
import AccountPagePaymentMethods from "./AccountPagePaymentMethods.page";

export const AccountPagePaymentMethodsRouteDef = makeObservableRouteDef({
  identifier: 'account-payment-methods',
  urlFactory: always('payment-methods'),
  composeTitle: always('Payment Methods'),
  component: AccountPagePaymentMethods,
  icon: 'bank-card',
})