import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseArticle from '../../../../../base/components/BaseArticle/BaseArticle';
import BaseCheckbox from '../../../../../base/components/BaseCheckbox/BaseCheckbox';
import BaseSpacer from '../../../../../base/components/BaseSpacer/BaseSpacer';
import CurrencyRenderer from '../../../../../base/components/CurrencyRenderer/CurrencyRenderer';
import SimpleCard from '../../../../../base/components/SimpleCard/SimpleCard';
import { useStore } from '../../../../../base/utils/mobx.utils';
import { CounsellingSessionPaidFee } from '../../../../../constants/counsellingFeeTypes.constants';
import { getWordPressLink } from '../../../../../env';
import { isPaidCounselling } from '../../../../../utils/counsellingApplication.utils';
import { CounsellingApplicationStepProps } from '../applicationStepDescriptors';
import './CounsellingApplicationStepWelcome.scss';

interface CounsellingApplicationStepWelcomeProps extends CounsellingApplicationStepProps {}

const CounsellingApplicationStepWelcome: React.FC<CounsellingApplicationStepWelcomeProps> = p => {
  const s = useStore(() => ({
    get isPaid() { return isPaidCounselling(p.form.selectedApplicationType); }
  }))
  return <Observer children={() => (
    <div className="CounsellingApplicationStepWelcome">
      {/* <blockquote>
        <p>We provide private and confidential Online Counselling services to our members, provided in the format of primarily one to one sessions via video, text chats, emails or phone calls (coming soon).</p>
      </blockquote> */}
      <BaseSpacer />
      <SimpleCard padding="1em">
        <BaseArticle>
          <div className="CounsellingApplicationStepWelcomeHeader">
            <h3>One to one (individual) counselling / psychotherapy</h3>
            {s.isPaid && <h2><CurrencyRenderer value={CounsellingSessionPaidFee.value} trimEmptyDecimals /></h2>}
          </div>
          <p>We provide counselling / psychotherapy sessions on our secure turn2me platform, via instant chat messages, audio or video format.</p>
          <p>One-to-one counselling sessions are provided to adults (18+).</p>
          {/* <p>turn2me is a not-for-profit charity and greatly relies on everyone's donations to help fund our operations. Your support helps us provide professional services for people in need.</p> */}
        </BaseArticle>
        {/* <IrishFlag /> */}
      </SimpleCard>
      <BaseSpacer />
      {!s.isPaid &&
        <div className="CounsellingApplicationStepWelcomeTermsCheckbox">
          <BaseCheckbox form={p.form} field="hasAgreedCantAfford">
          <p><strong>I am an Irish resident and cannot afford to pay €{CounsellingSessionPaidFee.value} per counselling session.</strong></p>
          <p><strong>OR</strong></p>
          <p><strong>I am an Ukrainian National availing of safety in Ireland per counselling session.</strong></p>
            <BaseSpacer size=".5em" />
          <div className="SubInfo">
            <p>Irish residents who cannot afford counselling sessions may be entitled up to 6 free counselling sessions. We request clients who earn €35,000 per annum or more to use the paid counselling sessions. Please read the full <a href={getWordPressLink('/terms-and-conditions')} title="Terms and Conditions" target="_blank" rel="noreferrer">Terms and conditions for 6 free counselling sessions for Irish residents.</a></p>
            <BaseSpacer size=".5em" />
            <p>Ukrainian nationals fleeing their country due to the conflict may be entitled up to 6 free counselling sessions. Please read the full <a href={getWordPressLink('/terms-and-conditions')} title="Terms and Conditions" target="_blank" rel="noreferrer">Terms and conditions for 6 free counselling sessions for Ukrainian Nationals.</a></p>
          </div>
          </BaseCheckbox>
        </div>
      }
      <BaseSpacer />
    </div>
  )} />
}

export default CounsellingApplicationStepWelcome;