import { Observer } from 'mobx-react-lite';
import React from 'react';
import { BaseSelectorInnerProps, DefaultOptionType, DefaultSingleValueType } from './BaseSelector';
import BaseSelectorCheckboxGroupOption from './BaseSelectorCheckboxGroupOption';
// import './BaseSelectorCheckboxGroupInner.scss';

export function BaseSelectorCheckboxGroupInner<
  FormType extends object = object, 
  OptionType extends any = DefaultOptionType,
  SingleValueType extends DefaultSingleValueType = DefaultSingleValueType,
  ValueFieldType extends SingleValueType | string[] = SingleValueType,
>(props: React.PropsWithChildren<BaseSelectorInnerProps<FormType, OptionType, SingleValueType, ValueFieldType>>) {

  const renderOptions = () => {
    return props.options.map((o, i) => {
      const value: SingleValueType = props.valueGetter!(o);
      const label = props.optionLabelRenderer!(o);
      const disabled = props.disabledOptionChecker!(o);
      const isSelected = props.isSelectedOption(o);
      return (
        <BaseSelectorCheckboxGroupOption 
          key={value + '' + i}
          value={value}
          option={o}
          label={label} 
          disabled={disabled} 
          toggleSelect={handleOnChange}
          isSelected={isSelected}
        />
      )
    })
  }

  const handleOnChange = (v: SingleValueType) => {
    props.onChange && props.onChange(v);
  }

  const handleClick = (e: React.MouseEvent) => {
    props.onClick?.(e);
  }

  return <Observer children={() => (
    <div className="BaseSelectorInner BaseSelectorCheckboxGroupInner" onClick={handleClick}>
      { renderOptions() }
    </div>
  )} />
}

export default BaseSelectorCheckboxGroupInner;