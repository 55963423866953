import { Observer } from 'mobx-react-lite';
import React from 'react';
import ErrorRenderer from '../../../base/components/ErrorRenderer/ErrorRenderer';
import LoadingIndicator from '../../../base/components/LoadingIndicator/LoadingIndicator';
import { AuthEndpoints } from '../../../base/endpoints/auth.endpoints';
import { useOnMount } from '../../../base/hooks/lifecycle.hooks';
import { useControllers, useUIController } from '../../../base/hooks/useRootController.hook';
import { getUrlParams } from '../../../base/utils/urlParams.utils';
import tick from '../../../base/utils/waiters.utils';
import './PageVerifyEmail.scss';

interface PageVerifyEmailProps {}

const PageVerifyEmail: React.FC<PageVerifyEmailProps> = props => {

  const { API, NAVIGATOR, AUTH } = useControllers();
  const { DIALOG } = useUIController();

  const navigateToHomePage = () => {
    NAVIGATOR.navigateTo('/app/explore');
  }

  const verifyEmail = async () => {
    if (!AUTH.isAuthenticated) {
      const path = window.location.pathname + window.location.search + window.location.hash;
      if (path.includes('login')) return;
      NAVIGATOR.navigateTo(`/auth/login?redirectedFrom=${encodeURIComponent(path)}`);
      return;
    }
    await tick(1000);
    if (AUTH.currentUser?.email && AUTH.currentUser.timeVerifiedEmail) {
      DIALOG.positive({
        heading: 'Email Verified',
        body: 'You have already verified your email. 😊',
      })
      navigateToHomePage();
      return;
    }
    const { code } = getUrlParams();
    if (!code) {
      navigateToHomePage();
      DIALOG.error({
        heading: 'Email Verification',
        body: 'The email verification link is invalid. Please double check the email you have received.',
      })
      return;
    }
    const url = AuthEndpoints.verification.email();
    const payload = { verificationCode: code };
    API.postRaw(url, payload).then(() => {
      AUTH.fetchCurrentUserInfo();
      DIALOG.positive({
        heading: 'Email Verified',
        body: 'Thanks for verifying your email! You may now continue to access the turn2me services.',
      })
    }).catch(e => {
      DIALOG.error({
        heading: 'Failed to verify your email',
        body: () => <ErrorRenderer error={(e as any).response} />,
      });
    }).finally(() => {
      navigateToHomePage();
    })
  }

  useOnMount(() => {
    verifyEmail();
  })

  return <Observer children={() => (
    <div className="PageVerifyEmail">
      <div>
        <LoadingIndicator />
        <p><em>Verifying your email...</em></p>
      </div>
    </div>
  )} />
}

export default PageVerifyEmail;