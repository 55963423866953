import { logAsNavigator } from "../../controllers/navigator.controller";
import { LocationChangeEventHandler, RouteDef } from "../@types";
import { isRegex, isString } from "../utils/typeChecks.utils";
import { removeTrailingSlashes } from "../utils/url.utils";
import { useOnMount } from "./lifecycle.hooks";
import { useControllers } from "./useRootController.hook";

export const useOnRouteFocus = (
  route: RouteDef | string | RegExp | undefined,
  handler: LocationChangeEventHandler,
  options?: {
    fireImmediately?: boolean,
    fireWhenUrlParamUpdates?: boolean,
  }
) => {
  const { NAVIGATOR } = useControllers();
  useOnMount(() => {
    if (!route) return;
    const _route = (isString(route) || isRegex(route)) ? route : route.urlPattern ?? route.urlFactory();
    const urlRegex = new RegExp(isString(_route) ? removeTrailingSlashes(_route)! : _route);
    const _handler: LocationChangeEventHandler = location => {
      if (!location) return;
      if (urlRegex.test(location?.pathname)) {
        if (NAVIGATOR.debug) logAsNavigator(`Route [${_route}] focus event`);
        handler(location);
      }
    }
    if (options?.fireImmediately) _handler(NAVIGATOR.currentLocation);
    if (options?.fireWhenUrlParamUpdates) return NAVIGATOR.onRouteChange(_handler);
    return NAVIGATOR.onPathnameChange(_handler);
  })
}