import { AnyObject } from "../@types"
import { mergeIntoObjectByDescriptors } from "../utils/object.utils"

/**
 * Automatically generates getters and setters for a model. This does not make the object observable.
 * @param object an object that has not been applied with getters and setters of the attributes in the snapshot
 * @param snapshotBase an empty snapshot as a blueprint
 * @param overrides the overriding methods, applied after the standard getter/setters have been created
 */
export const setupAccessorsToObservableSnapshot = <T extends AnyObject>(
  object: AnyObject,
  observableSnapshot: Partial<T>,
  snapshotBase: Partial<T>,
  overrides?: Partial<T>,
): T => {
  Object.keys(snapshotBase).forEach(key => {
    Object.defineProperty(object, key, {
      configurable: true,
      get() { return observableSnapshot[key] },
      set(newValue: any) { (observableSnapshot as any)[key] = newValue },
    })
  })
  if (overrides) mergeIntoObjectByDescriptors(object, overrides);
  return object as T;
}
