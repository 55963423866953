import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import pluralize from 'pluralize';
import React from 'react';
import { AnyObject } from '../../../../base/@types';
import BaseButton from '../../../../base/components/BaseButton/BaseButton';
import BaseLabel from '../../../../base/components/BaseLabel/BaseLabel';
import BaseSelector from '../../../../base/components/BaseSelector/BaseSelector';
import ErrorRenderer from '../../../../base/components/ErrorRenderer/ErrorRenderer';
import { CommentEndpoints } from '../../../../base/endpoints/comment.endpoints';
import { ThoughtEndpoints } from '../../../../base/endpoints/thought.endpoints';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import { keepTruthy } from '../../../../base/utils/array.utils';
import joinClassName from '../../../../base/utils/className.utils';
import { reportError } from '../../../../base/utils/errors.utils';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import { uniq } from '../../../../base/utils/ramdaEquivalents.utils';
import { autoPluralize, toSentenceCase } from '../../../../base/utils/string.utils';
import { getNowTimestampUtc } from '../../../../base/utils/time.utils';
import { setUrlParam } from '../../../../base/utils/urlParams.utils';
import UsernameRenderer from '../../../../components/UsernameRenderer/UsernameRenderer';
import { ApiModelName, getApiModelDef } from '../../../../constants/ApiModels.enum';
import { FlaggableModelNames, FlaggableOriginType } from '../../../../models/makeFlag.model';
import { ThoughtSnapshot } from '../../../../models/makeThought.model';
import { FlaggableModelTrait } from '../../../../traits/isFlaggable.trait';
import './FlaggableItemManager.scss';

interface FlaggableItemManagerProps {
  flaggable: FlaggableModelTrait,
  modelType: FlaggableModelNames,
  compact?: boolean,
  disabled?: boolean,
}

const FlaggableItemManager: React.FC<FlaggableItemManagerProps> = props => {

  const { API, UI, AUTH, NAVIGATOR } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    get id() {
      return p.flaggable.id
    },
    get modelDef() {
      return getApiModelDef(p.modelType)!;
    },
    get modelDisplayName() {
      return s.modelDef.displayName || toSentenceCase(s.modelDef.name)
    },
    get flags(){
      return p.flaggable.flags || [];
    },
    get hasFlags() {
      return s.flags.length > 0;
    },
    get communityFlags() {
      return s.flags.filter(f => f.originType === FlaggableOriginType.community)
    },
    get automaticFlags() {
      return s.flags.filter(f => f.originType === FlaggableOriginType.automated)
    },
    get moderatedTermsFound() {
      return uniq(keepTruthy(s.automaticFlags.map(f => <span key={f.id}>{f.word}</span>)))
    },
    get markedAsSafe() {
      return p.flaggable.isSafe === true;
    },
    get markedAsUnsafe() {
      return p.flaggable.isSafe === false;
    },
    get isHidden() {
      return Boolean(p.flaggable.timeHidden);
    },
    get patchUrl() {
      switch (p.modelType) {
        case ApiModelName.THOUGHT:
          return ThoughtEndpoints.staff.update(s.id);
        case ApiModelName.COMMENT: 
          return CommentEndpoints.staff.update(s.id);
        default: 
          return '';
      }
    },
    get isPrivate(): boolean {
      return (p.flaggable as AnyObject).is_private;
    },
    viewUser: (id: string) => {
      setUrlParam('userId', id, NAVIGATOR);
    },
    get isDeleted() {
      return p.flaggable.timeDeleted || p.disabled;
    },
    isSafeInternal: p.flaggable.isSafe ?? 'unmarked',
    handleSafeStatusChange: () => {
      switch (s.isSafeInternal) {
        case 'unmarked':
          unmarkIsSafe();
          break;
        case true:
          markAsSafe();
          break;
        case false:
          markAsUnsafe();
          break;
        default: break;
      }
      return;
    }
  }));

  const markAsUnsafe = () => new Promise<boolean>(
    flow(function* (resolve, reject) {
      try {
        const now = getNowTimestampUtc();
        const url = s.patchUrl;
        if (!url || !s.modelDef.name) {
          reject('Unknown flaggable model supplied');
          return;
        }
        const payload: Partial<FlaggableModelTrait> = {
          id: s.id,
          isSafe: false,
          timeHidden: now,
        }
        if (p.modelType === ApiModelName.THOUGHT) {
          (payload as ThoughtSnapshot).isPrivate = true;
        }
        yield API.patch(url, s.modelDef.name, payload);
        resolve(true);
      } catch (e) {
        reportError(e);
        UI.DIALOG.error({
          heading: `Failed to mark the ${s.modelDisplayName} as unsafe`,
          body: <ErrorRenderer error={(e as any).response} />,
        })
        reject(e);
      }
    })
  )

  const markAsSafe = () => new Promise<boolean>(
    flow(function* (resolve, reject) {
      try {
        const url = s.patchUrl;
        if (!url || !s.modelDef.name) {
          reject('Unknown flaggable model supplied');
          return;
        }
        const payload: Partial<FlaggableModelTrait> = {
          id: s.id,
          isSafe: true,
        }
        yield API.patch(url, s.modelDef.name, payload);
        resolve(true);
      } catch (e) {
        reportError(e);
        UI.DIALOG.error({
          heading: `Failed to mark the thought as safe`,
          body: <ErrorRenderer error={(e as any).response} />,
        })
        reject(e);
      }
    })
  )

  const unmarkIsSafe = () => new Promise<boolean>(
    flow(function* (resolve, reject) {
      try {
        const url = s.patchUrl;
        if (!url || !s.modelDef.name) {
          reject('Unknown flaggable model supplied');
          return;
        }
        const payload: Partial<FlaggableModelTrait> = {
          id: s.id,
          isSafe: null,
        }
        yield API.patch(url, s.modelDef.name, payload);
        resolve(true);
      } catch (e) {
        reportError(e);
        reject(e);
        UI.DIALOG.error({
          heading: `Failed to unmark the safe status of the thought`,
          body: <ErrorRenderer error={(e as any).response} />,
        })
      }
    })
  )

  const hideFromPublic = () => new Promise<boolean>(
    flow(function * (resolve, reject) {
      try {
        const now = getNowTimestampUtc();
        const url = s.patchUrl;
        if (!url || !s.modelDef.name) {
          reject('Unknown flaggable model supplied');
          return;
        }
        const payload: Partial<FlaggableModelTrait> = {
          id: s.id,
          timeHidden: now,
        }
        if (p.modelType === ApiModelName.THOUGHT) {
          (payload as ThoughtSnapshot).isPrivate = true;
        }
        yield API.patch(url, s.modelDef.name, payload);
        p.flaggable.timeHidden = now;
        resolve(true);
      } catch(e) {
        reportError(e);
        reject(e);
        UI.DIALOG.error({
          heading: `Failed to hide the ${s.modelDisplayName} to public`,
          body: <ErrorRenderer error={(e as any).response} />,
        })
      }
    })
  )

  const unhideFromPublic = () => new Promise<boolean>(
    flow(function* (resolve, reject) {
      if (p.flaggable.isSafe === false) {
        UI.DIALOG.attention({ heading: `You cannot set an unsafe ${s.modelDisplayName} to be publicly viewable.`});
        resolve(false);
        return;
      }
      try {
        const url = s.patchUrl;
        if (!url || !s.modelDef.name) {
          reject('Unknown flaggable model supplied');
          return;
        }
        const payload: Partial<FlaggableModelTrait> = {
          id: s.id,
          isSafe: true,
          timeHidden: null,
        }
        yield API.patch(url, s.modelDef.name, payload);
        p.flaggable.isSafe = true;
        p.flaggable.timeHidden = null;
        resolve(true);
      } catch (e) {
        reportError(e);
        reject(e);
        UI.DIALOG.error({
          heading: `Failed to perform the action`,
          body: <ErrorRenderer error={(e as any).response} />,
        })
      }
    })
  )

  return <Observer children={() => (
    <div
      className={
        joinClassName(
          'FlaggableItemManager',
          p.flaggable.isSafe && 'safe',
          p.flaggable.isSafe === false && 'unsafe',
        )
      }
      data-compact={p.compact}
      data-is-unsafe={s.markedAsUnsafe}
    >
      <div className="FlaggableItemManagerInner">
        {
          s.hasFlags ? <>
            {s.markedAsSafe ? <>
              <h3>
                This flagged {s.modelDisplayName} is considered safe.
                {s.automaticFlags.length > 0 && <> It was automatically flagged by the system due to the keyword <code>{s.automaticFlags[0]?.word}</code>.</>}
                {s.communityFlags.length > 0 && <> It was flagged by {autoPluralize(s.communityFlags.length, 'user')}.</>}
              </h3>
            </> : <>
              {s.automaticFlags.length > 0 && <h3>The system had automatically flagged this {s.modelDisplayName} due to the {autoPluralize(s.moderatedTermsFound, 'moderated term', 'moderated term', '%d moderated terms', true)} found: {s.moderatedTermsFound.map((term, index, arr) => <span key={term + '-' + index}><code>{term}</code>{index < arr.length - 1 && ', '}</span>)}.</h3>}
              {s.communityFlags.length > 0 && <h3>This {s.modelDisplayName} has been flagged by the following {autoPluralize(s.communityFlags.length, 'user')}:</h3>}
            </>}
            {AUTH.canModerate && !s.isHidden && <p>Please review the content of the {s.modelDisplayName} and take appropriate actions.</p>}
          </> : (
            !p.compact && (
              s.isPrivate ? (
                <h3>This private {s.modelDisplayName} has not been flagged. Note that private {pluralize(s.modelDisplayName)} can only be viewed by its author, admins, counsellors or moderators.</h3>
              ) : (
                  <h3>This {s.modelDisplayName} { s.flags.length ? `has been flagged ${autoPluralize(s.flags.length, 'once', '0 times', '%d times', true)}` : 'has not been flagged'} and is presumed safe to be viewed by the public.</h3>
              )
            )
          )
        }
        { s.communityFlags.length > 0 && <ul className="FlagReporterUserNametagList">
          {s.communityFlags.filter(f => !!f.reporterId).map(f => <li key={f.id} onClick={() => s.viewUser(f.reporterId)}><UsernameRenderer user={f.reporter} userId={f.reporterId} />{f.reason && <>, reason: {f.reason}</>}</li>)}
        </ul> }
        {
          AUTH.canModerate && <div className="FlaggableItemManagerSelectorSet">

            <BaseSelector
              className="FlaggableItemManagerIsSafeSelector"
              form={s}
              field="isSafeInternal"
              disabled={s.isDeleted}
              label={p.compact ? undefined : `Is this ${s.modelDisplayName} safe?`}
              options={[
                {
                  value: true,
                  label: "Safe",
                  color: 'green',
                },
                {
                  value: false,
                  label: 'Unsafe',
                  color: 'red',
                }
              ]}
              onChange={s.handleSafeStatusChange}
            />

            <div>
              {!p.compact && <BaseLabel>Visibility</BaseLabel>}
              {
                s.isHidden ? (
                  <BaseButton
                    key='unhide-button'
                    size='sm'
                    color='median'
                    hoverColor='green'
                    icon='lock'
                    label={p.compact ? 'Hidden' : 'Hidden in front end'}
                    hoverIcon='check'
                    hoverLabel='Click to unhide'
                    onClick={unhideFromPublic}
                    minWidth="11em"
                    fullWidth={UI.onlyPhones}
                    disabled={s.isDeleted}
                    dataCy="unhide-button"
                  />
                ) : (
                  <BaseButton
                    key="hide-button"
                    size='sm'
                    color='green'
                    hoverColor='red'
                    icon='check'
                    hoverIcon='lock'
                    label={p.compact ? 'Viewable' : 'Viewable in front end'}
                    hoverLabel='Click to hide'
                    onClick={hideFromPublic}
                    minWidth="11em"
                    fullWidth={UI.onlyPhones}
                    disabled={s.isDeleted}
                    dataCy="hide-button"
                  />
                )
              }
            </div>

          </div>
        }

      </div>
    </div>
  )} />
}

export default FlaggableItemManager;