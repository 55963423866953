import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseGrid from '../../base/components/BaseGrid/BaseGrid';
import BaseInput from '../../base/components/BaseInput/BaseInput';
import BaseSelector from '../../base/components/BaseSelector/BaseSelector';

interface RevokeReservationDialogProps {
  form: {
    revokeReason: string,
  }
}

export const DefaultRevokeReasons = [
  {
    label: 'Client not suitable for this group',
    value: 'You might not be suitable for this support group.',
  },
  {
    label: 'Group cancelled',
    value: 'This group has been cancelled by the admin. You can find more groups with a similar topic on the support group calendar.'
  }
]

const RevokeReservationDialog: React.FC<RevokeReservationDialogProps> = props => {
  return <Observer children={() => (
    <BaseGrid className="RevokeReservationDialog">
      <p>The user will be notified immediately that they have been deregistered from the support group.</p>
      <p>It is optional to provide a reason for doing so. Though you can specify the reason so the client can be better informed:</p>
      <BaseSelector options={DefaultRevokeReasons} form={props.form} field="revokeReason" appearance="system" label="Select a predefined reason" />
      <BaseInput form={props.form} field="revokeReason" label="Preview and edit the reason" type="textarea" rows="3" />
    </BaseGrid>
  )} />
}

export default RevokeReservationDialog;