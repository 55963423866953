import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseHeader from '../../../../base/components/BaseHeader/BaseHeader';
import BaseIcon from '../../../../base/components/BaseIcon/BaseIcon';
import BaseSpacer from '../../../../base/components/BaseSpacer/BaseSpacer';
import LoadingIndicatorSection from '../../../../base/components/LoadingIndicatorSection/LoadingIndicatorSection';
import SwiperContainer from '../../../../base/components/SwiperContainer/SwiperContainer';
import SwiperSlide from '../../../../base/components/SwiperContainer/SwiperSlide';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import { sortByTimeScheduledOldestFirst } from '../../../../base/utils/array.utils';
import { useStore } from '../../../../base/utils/mobx.utils';
import SessionSummaryCard from '../../../../components/SessionSummaryCard/SessionSummaryCard';
import SmallHeaderButton from '../../../../components/SmallHeaderButton/SmallHeaderButton';
import SupportGroupSummaryCard from '../../../../components/SupportGroupSummaryCard/SupportGroupSummaryCard';
import { isCounsellingSessionModel } from '../../../../models/makeCounsellingSession.model';
import { isSupportGroupModel } from '../../../../models/makeSupportGroup.model';
import DashBlockCountBadge from '../DashBlock/DashBlockCountBadge';
import './AdminUpcomingSessionsAndGroups.scss';

type AdminUpcomingSessionsAndGroupsProps = {}

const AdminUpcomingSessionsAndGroups: React.FC<AdminUpcomingSessionsAndGroupsProps> = props => {

  const { STAFF, UI } = useControllers();

  const s = useStore(() => ({
    get entries() {
      return sortByTimeScheduledOldestFirst([
        ...STAFF.allOwnCurrentOrFutureSessions,
        ...STAFF.allOwnCurrentOrFutureGroups
      ])
    },
    get isLoading() {
      return !STAFF.firstDataLoaded;
    },
    get slidesPerView() {
      if (UI.fromDesktopLg) return 3.62;
      if (UI.fromDesktopMd) return 3.38;
      if (UI.fromDesktop) return 2.62;
      if (UI.fromTabletLg) return 2.38;
      if (UI.fromTablet) return 2.19;
      return 1.19;
    },
    get title() {
      if (STAFF.canManageApplications && !STAFF.canManageSupportGroups) return `Upcoming Counselling Sessions`;
      if (!STAFF.canManageApplications && STAFF.canManageSupportGroups) return `Upcoming Support Groups`;
      if (STAFF.canManageApplications && STAFF.canManageSupportGroups) return `Upcoming Sessions & Groups`;
      return 'Upcoming Sessions & Groups';
    }
  }))

  const refresh = STAFF.getAllTodayAndFutureSessionsAndGroups;

  return <Observer children={() => (
    <div className="AdminUpcomingSessionsAndGroups">
      <BaseHeader
        heading={<h3>{s.title} <DashBlockCountBadge count={s.entries.length} /></h3>}
        endSlot={<SmallHeaderButton onClick={refresh} label="Refresh" disabled={STAFF.isLoading} />}
      />
      <BaseSpacer size=".19em" />
      {
        s.isLoading && <LoadingIndicatorSection />
      }
      { !s.isLoading && s.entries.length === 0 && (
        <div className="UpcomingSessionsEmptyNotice">
          <BaseIcon icon="info" />
          <p><em>You have no upcoming sessions yet.</em></p>
        </div>
      ) }
      { !s.isLoading && s.entries.length > 0 && <SwiperContainer
        slidesPerView={s.slidesPerView} 
        shouldUpdate={() => s.entries.map(e => `${e.$modelName}#${e.id}`).join('_') }
        gap={16}
        slides={<>{
          s.entries.map(e => <SwiperSlide key={`${e.$modelName}#${e.id}`}>
            {isCounsellingSessionModel(e) && <SessionSummaryCard session={e} application={e.application} standalone/>}
            {isSupportGroupModel(e) && <SupportGroupSummaryCard supportGroup={e} actionOnClick="manage"/>}
          </SwiperSlide>)
        }</>} 
      />}
    </div>
  )} />

}

export default AdminUpcomingSessionsAndGroups;