import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseButton from '../../../../base/components/BaseButton/BaseButton';
import { useStore } from '../../../../base/utils/mobx.utils';
import { CounsellingApplicationSnapshot } from '../../../../models/makeCounsellingApplication.model';
import CounsellorApplicationsOverviewSectionItem from './CounsellorApplicationsOverviewItem';
import './CounsellorApplicationsOverviewSection.scss';

interface CounsellorApplicationsOverviewSectionProps {
  applications: CounsellingApplicationSnapshot[],
  title: string,
  description: string,
}

const CounsellorApplicationsOverviewSection: React.FC<CounsellorApplicationsOverviewSectionProps> = props => {

  const s = useStore(() => ({
    showDetails: false,
    toggleShowDetails: action(() => s.showDetails = !s.showDetails),
  }));

  return <Observer children={() => (
    <div className="CounsellorApplicationsOverviewSection">
      <header className="CounsellorApplicationsOverviewSectionHeader">
        <div className="CounsellorApplicationsOverviewSectionHeaderInner">
          <h3>{props.title} ({props.applications.length})</h3>
          <p>{props.description}</p>
        </div>
        { props.applications.length > 0 && <div className="CounsellorApplicationsOverviewSectionHeaderControls">
          <BaseButton icon={s.showDetails ? 'minus' : 'plus'} onClick={s.toggleShowDetails}>{s.showDetails ? 'Hide Details' : 'Show Details'}</BaseButton>
        </div> }
      </header>
      { s.showDetails && props.applications.map(a => <CounsellorApplicationsOverviewSectionItem key={a.id} application={a} />) }
    </div>
  )} />

}

export default CounsellorApplicationsOverviewSection;