import { action, flow, observable, reaction, when } from "mobx";
import React from "react";
import { AnyObject, Nillable, Nullable } from "../base/@types";
import { makeThoughtCatcherBotAnalyzerFactory, ThoughtCatcherBotAnalyzer, ThoughtCatcherBotAnalyzerStateSnapshot } from "../base/bots/thoughtCatcher/thoughtCatcher.botAnalyzer";
import ErrorRenderer from "../base/components/ErrorRenderer/ErrorRenderer";
import { DefaultThoughtIncludes, DefaultThoughtIncludesForAdmins, ThoughtEndpointParams, ThoughtEndpoints } from "../base/endpoints/thought.endpoints";
import { Bot, makeBot } from "../base/mediators/bot.mediator";
import { addToArrayIfNew, removeFromArray, sortByTimeCreatedLatestFirst } from "../base/utils/array.utils";
import { equalById } from "../base/utils/equality.utils";
import { reportError } from "../base/utils/errors.utils";
import { createUTCMoment, seconds } from "../base/utils/time.utils";
import { doEvery } from "../base/utils/waiters.utils";
import { ModelName } from "../constants/modelNames.enum";
import { LocalThoughtCacheKey } from "../constants/storageKeys.constants";
import { isLocalhost, IS_DEV, SHOULD_LOG } from "../env";
import { ThreeWayState } from "../models/makeConfiguration.model";
import { Reaction } from "../models/makeReaction.model";
import { makeThought, Thought, ThoughtSnapshot } from "../models/makeThought.model";
import { User } from "../models/makeUser.model";
import { deleteReaction } from "../requests/deleteReaction.request";
import { deleteThought } from "../requests/deleteThought.request";
import { saveReaction } from "../requests/saveReaction.request";
import { saveThought } from "../requests/saveThought.request";
import { AgeGroupFilterType } from "../utils/ageAndDateOfBirth.utils";
import { APIController, APIGetManyResponse } from "./api.controller";
import { CLOCK } from "./common/clock.controller";
import { ConfigurationsController } from "./configurations.controller";
import { LocalDBController } from "./localDB.controller";
import { UIController } from "./ui.controller";
import { makeControllerBase, makeRootControllerChildInitFn } from "./_root.controller";

/**
 * top level feature controller for Thought Catcher.
 * manages the chat bots, and some thought interactions such as reactions and comments.
 */
export type ThoughtCatcherController = ReturnType<typeof makeThoughtCatcherController>;

export const makeThoughtCatcherController = () => {

  const _private = observable({
    thoughtPollingErrorCounter: 0,
    pollInterval: null as number | null,
  })

  const c = observable({

    ...makeControllerBase('THOUGHT_CATCHER'),

    get API(): APIController { return c.ROOT!.children.API },
    get LOCALDB(): LocalDBController { return c.ROOT!.children.LOCALDB },
    get CONFIGURATIONS(): ConfigurationsController { return c.ROOT!.children.CONFIGURATIONS },
    get UI(): UIController { return c.ROOT!.children.UI },
    get user(): Nillable<User> { return c.ROOT?.children.AUTH.currentUser },

    thoughtOfTheDay: null as Nullable<Thought>,
    get serviceStatusForCurrentUser(): ThreeWayState {
      return c.currentUserIsYoungPeople ? c.CONFIGURATIONS.thoughtCatcherYP : c.CONFIGURATIONS.thoughtCatcherAdult;
    },

    bot: null as Nullable<Bot<ThoughtCatcherBotAnalyzerStateSnapshot, ThoughtCatcherBotAnalyzer>>,

    get uniqueThoughts(): Thought[] {
      return Array.from(c.LOCALDB.data.thoughts.values());
    },
    get thoughtsSorted(): Thought[] {
      if (c.currentUserCanModerate) return c.uniqueThoughts.sort((a, b) => createUTCMoment(b.timeLastClientAction).diff(a.timeLastClientAction));
      return sortByTimeCreatedLatestFirst(c.uniqueThoughts);
    },
    get feedIsEmpty(): boolean {
      return c.viewableThoughts.length === 0;
    },
    get viewableThoughts(): Thought[] {
      return c.uniqueThoughts.filter(t => {
        if (!t.content) return false;
        if (c.user?.isModerator) return true;
        const isOwnThought = c.user?.id && (t.userId === c.user.id);
        if (isOwnThought) return !t.timeDeleted;
        return !t.isPrivate && !t.timeHidden && !t.timeDeleted;
      })
    },
    get ownThoughts(): Thought[] {
      return c.uniqueThoughts.filter(t => t.userId === c.user?.id);
    },
    get ownNonEmptyThoughts(): Thought[] {
      return c.ownThoughts.filter(t => !!t.content);
    },
    get userDefaultThoughtStateIsPrivate(): boolean {
      return c.ROOT?.children.AUTH.currentUser?.preferences?.preferredThoughtState === 'private' ? true : false;
    },
    get currentUserIsModerator(): boolean {
      return c.ROOT?.children.AUTH.isModerator ?? false;
    },
    get currentUserIsAdult(): boolean | null {
      return c.ROOT?.children.AUTH.currentUser?.isAdult ?? null;
    },
    get currentUserIsYoungPeople(): boolean | null {
      return c.ROOT?.children.AUTH.currentUser?.isYoungPerson ?? null;
    },
    currentUserThoughtsFirstRetrieved: false,
    feedRetrieving: false,
    feedRetrieved: false,
    get currentUsersLastEmptyThought(): Nillable<Thought> {
      return c.ownThoughts.find(t => !t.emotionId || !t.content);
    },
    errorPostingLastThought: null as Nullable<Error>,
    timeLastRetrievedFeed: null as Nullable<string>,
    setup: (useBot: boolean = true) => flow(function * () {
      const { STORAGE } = c.ROOT!.children;
      let savedThoughtInStorage: Nillable<Thought> = yield STORAGE.get(LocalThoughtCacheKey);
      if (savedThoughtInStorage) {
        SHOULD_LOG() && console.log('savedThoughtInStorage', savedThoughtInStorage);
        // CONSIDER: better error recovery
        if (savedThoughtInStorage.content) {
          try {
            yield saveThought(savedThoughtInStorage, c.API);
            STORAGE.remove(LocalThoughtCacheKey);
            savedThoughtInStorage = null;
          } catch (e) {
            c.errorPostingLastThought = e as Error;
            reportError(e);
          }
        }
      }
      if (c.user) {
        const localThought = makeThought(savedThoughtInStorage ?? {
          isPrivate: c.userDefaultThoughtStateIsPrivate,
        });
        c.thoughtOfTheDay = localThought;
        if (useBot) c.bot = yield makeBot<ThoughtCatcherBotAnalyzerStateSnapshot, ThoughtCatcherBotAnalyzer>().init(makeThoughtCatcherBotAnalyzerFactory(localThought), c.ROOT!)
      } else {
        SHOULD_LOG() && console.log("anonymous user connected, creating a local thought");
        const newThought = makeThought(savedThoughtInStorage || {});
        c.thoughtOfTheDay = newThought;
        if (useBot) c.bot = yield makeBot<ThoughtCatcherBotAnalyzerStateSnapshot, ThoughtCatcherBotAnalyzer>().init(makeThoughtCatcherBotAnalyzerFactory(newThought), c.ROOT!)
      }
    })(),

    resetBot: action(() => {
      c.thoughtOfTheDay = null;
      c.bot?.die();
      c.setup();
    }),

    postThought: (snapshot: ThoughtSnapshot) => flow(function * () {
      if (!c.ROOT!.children.AUTH.currentUser) throw Error('Cannot post a thought from the controller without an authenticated user.');
      const { id, ...rest } = snapshot;
      rest.timeCreated = CLOCK.nowUtcTimestamp;
      rest.timeUpdated = CLOCK.nowUtcTimestamp;
      if (!rest.userId) rest.userId = c.ROOT!.children.AUTH.currentUser.id;
      return yield saveThought(rest, c.API);
    })(),

    deleteThought: (thought: Thought) => flow(function * () {
      try {
        yield deleteThought(thought, c.API)
        if (equalById(c.thoughtOfTheDay, thought)) c.thoughtOfTheDay = null;
        thought.timeDeleted = CLOCK.nowUtcTimestamp;
      } catch(e) {
        c.UI.DIALOG.error({
          heading: 'Failed to delete this thought...',
          body: <ErrorRenderer e={(e as AnyObject).response} />
        })
      }
    })(),

    get currentUserCanModerate(): boolean {
      return c.ROOT!.children.AUTH.can.thoughtCatcher_.moderate_.someAgeGroups;
    },

    saveReaction: (reaction: Reaction) => flow(function * () {
      let thought = c.viewableThoughts.find(t => t.id === reaction.modelId);
      if (!thought && !c.currentUserCanModerate) {
        throw Error('Thought Catcher does not have the thought in the feed, the user is not supposed to be able to react to a thought that is not loaded.');
      } else if (c.currentUserCanModerate) {
        thought = c.LOCALDB.get(ModelName.thoughts, reaction.modelId);
      }
      const savedReaction: Reaction = yield saveReaction(reaction, c.API);
      SHOULD_LOG() && console.log('saved Reaction', savedReaction);
      thought && addToArrayIfNew(thought.reactionIds, savedReaction.id);
    })(),

    removeReaction: (reaction: Reaction) => flow(function * () {
      let thought = c.viewableThoughts.find(t => t.id === reaction.modelId);
      if (!thought && !c.currentUserCanModerate) {
        throw Error('Thought Catcher does not have the thought in the feed, the user is not supposed to be able to change reaction to a thought that is not loaded.');
      } else if (c.currentUserCanModerate) {
        thought = c.LOCALDB.get(ModelName.thoughts, reaction.modelId);
      }
      yield deleteReaction(reaction, c.API);
      thought && removeFromArray(thought.reactionIds, reaction.id);
    })(),

    toggleThoughtPrivateState: (thought: Thought, newState?: 'private' | 'public') => flow(function * () {
      const { AUTH } = c.ROOT!.children;
      if (!AUTH.isModerator && AUTH.currentUser?.id !== thought.userId) return;
      const payload: Partial<Thought> = { id: thought.id, isPrivate: newState ? (newState === 'private' ? true : false) : !thought.isPrivate };
      yield saveThought(payload, c.API);
    })(),

    currentPageOfFeed: 0,
    lastPageOfFeed: null as Nullable<number>,
    get hasReachedLastPageOfFeed(): boolean {
      return !c.errorRetrievingFeed && c.currentPageOfFeed === c.lastPageOfFeed;
    },
    errorRetrievingFeed: null as Nullable<Error>,
    get thoughtEndpointFactory(): (params?: Nillable<ThoughtEndpointParams>) => string {
      return ThoughtEndpoints[c.user?.isModerator ? 'staff' : 'client'].index;
    },
    get feedAgeGroupFilters(): AgeGroupFilterType[] | undefined {
      if (c.currentUserIsModerator) return undefined;
      if (c.currentUserIsYoungPeople) return [AgeGroupFilterType.youngPeople1214, AgeGroupFilterType.youngPeople1517];
      return [AgeGroupFilterType.adults];
    },
    getFeedOfPage: (page: number = 1, url?: string) => flow(function* () {
      SHOULD_LOG() && console.log('polling thought feed');
      if (!c.API) throw Error('Attempting to get thought feed before API controller is ready');
      c.errorRetrievingFeed = null;
      c.feedRetrieving = true;
      const params = {
        include: c.user?.isModerator ? DefaultThoughtIncludesForAdmins : DefaultThoughtIncludes,
        sort: 'timeCreated',
        page,
        perPage: 15,
        filter: {
          ageGroup: c.feedAgeGroupFilters,
        }
      }
      const _url = url ?? c.thoughtEndpointFactory(params);
      yield c.getThoughtFeedByUrl(_url);
    })(),

    getThoughtFeedByUrl: (url: string, options?: { isRefreshRequest?: boolean }) => flow(function * () {
      try {
        const { entries: refreshedThoughts, response } = (yield c.API.getMany(url, ModelName.thoughts)) as APIGetManyResponse<Thought>;
        c.feedRetrieved = true;
        c.timeLastRetrievedFeed = CLOCK.nowUtcTimestamp;
        if (!options?.isRefreshRequest) {
          c.currentPageOfFeed = response.data.current_page;
          c.lastPageOfFeed = response.data.last_page;
        }
        c.feedRetrieving = false;
        return refreshedThoughts;
      } catch (e) {
        c.errorRetrievingFeed = e as Error;
        _private.pollInterval && clearInterval(_private.pollInterval);
        c.feedRetrieving = false;
        throw e;
      }
    })(),

    checkForThoughtUpdates: () => flow(function * () {
      if (!document.hasFocus()) return;
      if (c.uniqueThoughts.length === 0) return;
      if (c.UI.DIALOG.hasDialogs || c.UI.OVERLAY.hasOpenedOverlays) return;
      if (IS_DEV) console.log('checkForThoughtUpdates called');
      const url = c.thoughtEndpointFactory({
        include: DefaultThoughtIncludes,
        sort: 'timeCreated',
        page: 1,
        filter: {
          id: c.uniqueThoughts.map(t => t.id),
        } as object
      });
      yield c.getThoughtFeedByUrl(url, { isRefreshRequest: true });
    })(),

    reset: action(() => {
      c.resetBot();
      c.feedRetrieving = false;
      c.currentUserThoughtsFirstRetrieved = false;
      c.feedRetrieved = false;
      c.timeLastRetrievedFeed = '';
      c.currentPageOfFeed = 0;
      c.lastPageOfFeed = null;
      c.errorRetrievingFeed = null;
    }),

    getOwnRecentThoughts: () => flow(function * () {
      const url = ThoughtEndpoints.own.index();
      yield c.getFeedOfPage(0, url);
    })

  });

  c.init = makeRootControllerChildInitFn(
    c,
    action(() => {
      const disposers = [] as Function[];
      c.reset();
      when(
        () => c.API && c.currentUserThoughtsFirstRetrieved,
        () => reaction(
          () => c.user?.id,
          () => c.setup(),
          { fireImmediately: true }
        )
      );
      when(
        () => c.serviceStatusForCurrentUser === 'on',
        () => reaction(
          () => c.ROOT!.children.AUTH.isAuthenticated,
          value => {
            if (value) {
              c.getOwnRecentThoughts();
              disposers.push(doEvery(c.checkForThoughtUpdates, isLocalhost ? seconds(4) : seconds(15)));
            } else {
              disposers.forEach(fn => fn());
            }
          },
          { fireImmediately: true }
        )
      )
      c.ready = true;
    })
  )

  return c;

}
