import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import FormInput from '../../base/components/FormInput/FormInput';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { makeForm } from '../../base/mediators/form.mediator';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { SupportGroup } from '../../models/makeSupportGroup.model';
import { saveSupportGroup } from '../../requests/saveSupportGroup.request';
import './SupportGroupNotesEditor.scss';

interface SupportGroupNotesEditorProps {
  supportGroup: SupportGroup;
}

const SupportGroupNotesEditor: React.FC<SupportGroupNotesEditorProps> = props => {

  const p = useProps(props);
  const { API, UI } = useControllers();

  const s = useStore(() => ({
    form: makeForm(p.supportGroup.$snapshot),
    get hasChanges() {
      return s.form.hasChanges;
    }
  }))

  const save = () => new Promise<boolean>(async (resolve, reject) => {
    try {
      const payload = {
        id: s.form.get('id'),
        notes: s.form.get('notes'),
        technicalOrOtherIssues: s.form.get('technicalOrOtherIssues'),
      };
      const savedSupportGroup = await saveSupportGroup(API, payload);
      s.form.reset(savedSupportGroup);
      UI.TOAST.success("Support group notes saved.");
      resolve(true);
    } catch (e) {
      reject(e);
      UI.DIALOG.error({
        heading: 'Failed to save support group',
        error: e,
      })
    }
  })

  return <Observer children={() => (
    <div className="SupportGroupNotesEditor">
      <FormInput type="textarea" rows="8" form={s.form} field="notes" label="Support Group Session Notes"/>
      <FormInput type="textarea" rows="4" form={s.form} field="technicalOrOtherIssues" label="Did the group session experience any issues, either technical or other problems?"/>
      <BaseButton onClick={save} dataCy="saveNotes" label="Save Notes"/>
    </div>
  )} />
  
}

export default SupportGroupNotesEditor;