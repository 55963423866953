import { runInAction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseInput from '../../../../../base/components/BaseInput/BaseInput';
import BaseRadioGroup from '../../../../../base/components/BaseRadioGroup/BaseRadioGroup';
import TelInput from '../../../../../base/components/TelInput/TelInput';
import { useOnMount } from '../../../../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../../../../base/hooks/useRootController.hook';
import { useStore } from '../../../../../base/utils/mobx.utils';
import { ApplicationTypeOption } from '../../../../../constants/counsellingTypes.descriptors';
import { CounsellingApplicationStepProps } from '../applicationStepDescriptors';
import './CounsellingApplicationStepHealthcareInfo.scss';

interface CounsellingApplicationStepHealthcareInfoProps extends CounsellingApplicationStepProps { }

const yesNoRadioOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const symptomRadioOptions = [
  { label: 'Mild', value: 'Mild' },
  { label: 'Moderate', value: 'Moderate' },
  { label: 'High', value: 'High' },
];

const CounsellingApplicationStepHealthcareInfo: React.FC<CounsellingApplicationStepHealthcareInfoProps> = p => {

  const { ANALYTICS } = useControllers();

  const s = useStore(() => ({
    get isForYP() {
      return p.form.selectedApplicationType === ApplicationTypeOption.YoungPeople;
    }
  }))

  useOnMount(() => {
    ANALYTICS.getUserDialCode().then(code => {
      if (code && !p.form.application.healthcareProviderPhone) {
        runInAction(() => {
          p.form.application.healthcareProviderPhone = code;
        })
      }
    })
  })

  return <Observer children={() => (
    <div className="CounsellingApplicationStepHealthcareInfo">

      <p>{s.isForYP ? 'Is your child' : 'Are you'} currently receiving counselling or psychotherapy elsewhere?</p>
      <BaseRadioGroup form={p.form.application} field="hasExternalCounselling" dataCyPrefix="hasExternalCounselling" options={yesNoRadioOptions} />

      <p>Who is {s.isForYP ? `your child's` : 'your'} current GP / Healthcare Provider? <strong><em>(* Required)</em></strong></p>
      <BaseInput form={p.form.application} field="healthcareProviderName" />

      <p>Contact number for {s.isForYP ? `your child's` : 'your'} GP / Healthcare Provider <strong><em>(* Required)</em></strong></p>
      <TelInput form={p.form.application} field="healthcareProviderPhone" />

      <p>{s.isForYP ? 'Does your child' : 'Do you'} have private healthcare?</p>
      <BaseRadioGroup form={p.form.application} field="hasPrivateHealthCare" dataCyPrefix="hasPrivateHealthCare" options={yesNoRadioOptions} />

      <p>{s.isForYP ? 'Is your child' : 'Are you'} in receipt of a medical card?</p>
      <BaseRadioGroup form={p.form.application} field="isInReceiptOfMedicalCard" dataCyPrefix="isInReceiptOfMedicalCard" options={yesNoRadioOptions} />

      <p>{s.isForYP ? 'Does your child' : 'Do you'} have some previous counselling or psychotherapy experience? If so, please let us know:</p>
      <BaseInput form={p.form.application} field="previousCounsellingExperience" type="textarea" placeholder="Leave blank if not applicable"/>

      <p>{s.isForYP ? 'Does your child' : 'Do you'} have any previous mental health diagnosis? If so, please let us know:</p>
      <BaseInput form={p.form.application} field="previousDiagnosis" type="textarea" rows="5" placeholder="Leave blank if not applicable"/>

      <p>{s.isForYP ? 'Is your child' : 'Are you'} on any medication at the moment? If so, please let us know:</p>
      <BaseInput form={p.form.application} field="medication" type="textarea" placeholder="Leave blank if not applicable"/>

      <p>How would you measure your {s.isForYP ? 'child\'s' : ''} symptoms/feelings?</p>
      <BaseRadioGroup form={p.form.application} field="ownSymptomsOrFeelingsMeasurement" dataCyPrefix="ownSymptomsOrFeelingsMeasurement" options={symptomRadioOptions} />

      <p>{s.isForYP ? 'Has your child' : 'Have you'} ever attempted suicide?</p>
      <BaseRadioGroup form={p.form.application} field="hasAttemptedSuicide" dataCyPrefix="hasAttemptedSuicide" options={yesNoRadioOptions} />

    </div>
  )} />

}

export default CounsellingApplicationStepHealthcareInfo;