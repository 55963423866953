import { action, runInAction, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useOnMount } from '../../../../../base/hooks/lifecycle.hooks';
import { useOnRouteFocus } from '../../../../../base/hooks/navigator.hooks';
import { useControllers } from '../../../../../base/hooks/useRootController.hook';
import { ChatMediator } from '../../../../../base/mediators/chat.mediator';
import { useStore } from '../../../../../base/utils/mobx.utils';
import { getUrlParams, removeUrlParam } from '../../../../../base/utils/urlParams.utils';
import tick from '../../../../../base/utils/waiters.utils';
import ChatWindow from '../../../../../components/ChatWindow/ChatWindow';
import './PageChatSingle.scss';

type PageChatSingleProps = {}

const PageChatSingle: React.FC<PageChatSingleProps> = props => {

  const { MESSENGER, NAVIGATOR } = useControllers();
  const s = useStore(() => ({
    get id() {
      return NAVIGATOR.currentLocationPathname.match(/chats\/([0-9]+)/)?.[1]
    },
    _chat: null as ChatMediator | null,
    get chat() {
      return s._chat;
    },
  }))

  useOnRouteFocus(
    new RegExp(`(admin|app)/chats/${s.id}`),
    () => {
      when(
        () => Boolean(s.chat),
        action(() => {
          s.chat!.shouldOpenInDock = false;
          const { action } = getUrlParams();
          if (action === 'print') {
            when(
              () => !!s.chat,
              async () => {
                await tick();
                window.print();
                removeUrlParam('action');
              }
            )
          }
        }),
      )
    }, 
    { 
      fireImmediately: true, 
      fireWhenUrlParamUpdates: true 
    }
  )

  useOnMount(() => {
    (async () => {
      const c = await MESSENGER.getChatById(s.id);
      if (c) {
        runInAction(() => s._chat = c);
      }
    })();
  })

  return <Observer children={() => (
    <div className="PageChatSingle">
      {s.chat && <ChatWindow
        chat={s.chat}
        mode="standalone"
      />}
    </div>
  )} />

}

export default PageChatSingle;