import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Nillable } from '../../base/@types';
import ColorTag from '../../base/components/ColorTag/ColorTag';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { makeActionConfig } from '../../base/utils/actionConfig.utils';
import { keepTruthy } from '../../base/utils/array.utils';
import joinClassName from '../../base/utils/className.utils';
import { NoOp } from '../../base/utils/functions.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { ApiModelName } from '../../constants/ApiModels.enum';
import { ActionConfig } from '../../controllers/ui/ui.controller.types';
import { SHOULD_LOG } from '../../env';
import { Assignment, AssignmentGAD7, AssignmentPHQ9 } from '../../models/makeAssignment.model';
import { CounsellingApplication } from '../../models/makeCounsellingApplication.model';
import { getAssignmentAssociatedModelTypeDisplayName, getAssignmentTargetModelTypeDisplayName } from '../../utils/assignment.utils';
import OverlaySurveyGAD7 from '../OverlaySurveyGAD7/OverlaySurveyGAD7';
import OverlaySurveyPHQ9 from '../OverlaySurveyPHQ9/OverlaySurveyPHQ9';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import './AssignmentEntryForAdmin.scss';

interface AssignmentEntryForAdminProps {
  assignment: Assignment;
  application?: Nillable<CounsellingApplication>,
}

const AssignmentEntryForAdmin: React.FC<AssignmentEntryForAdminProps> = props => {

  const p = useProps(props);
  const { UI } = useControllers();

  const s = useStore(() => ({
    get assignment() {
      return p.assignment;
    },
    get targetModelTypeDisplayName() {
      return getAssignmentTargetModelTypeDisplayName(s.assignment.targetType);
    },
    get associatedModelTypeDisplayName() {
      return getAssignmentAssociatedModelTypeDisplayName(s.assignment.associatedType);
    },
    get title() {
      return s.targetModelTypeDisplayName
    },
    get status() {
      return p.assignment.timeCompleted ? {
        displayName: 'Completed',
        color: 'green',
      } : {
        displayName: 'Pending',
        color: 'orange',
      };
    },
    get readonly() {
      return p.application?.timeArchived || p.application?.timeCompleted;
    },
    get isCompleted() {
      return p.assignment.timeCompleted;
    }
  }));
  
  const handleComponentClick = () => {
    switch (s.assignment.targetType) {
      case ApiModelName.SURVEY_GAD7: {
        if (s.assignment.targetId) {
          UI.OVERLAY.present({
            component: <OverlaySurveyGAD7 assignment={s.assignment as AssignmentGAD7} readonly={true} />,
            width: '62em'
          })
        } else {
          UI.DIALOG.present({
            heading: s.readonly ? 'The client did not fill in the GAD7 questionnaire.' : 'The client has not filled in the GAD7 questionnaire yet.',
            actions: keepTruthy([
              !s.readonly && makeActionConfig(
                'Fill in for the client', 
                openGAD7SurveyOverlay, 
                { buttonClass: 'subtle' }
              ),
              makeActionConfig('OK', NoOp),
            ]) as ActionConfig[],
          })
        }
        break;
      }
      case ApiModelName.SURVEY_PHQ9: {
        if (s.assignment.targetId) {
          UI.OVERLAY.present({
            component: <OverlaySurveyPHQ9 assignment={s.assignment as AssignmentPHQ9} readonly={true} />,
            width: '62em'
          })
        } else {
          UI.DIALOG.present({
            heading: s.readonly ? 'The client did not fill in the PHQ9 questionnaire.' : 'The client has not filled in the PHQ9 questionnaire yet.',
            actions: keepTruthy([
              !s.readonly && makeActionConfig(
                'Fill in for the client', 
                openPHQ9SurveyOverlay, 
                { buttonClass: 'subtle' }
              ),
              makeActionConfig('OK', NoOp),
            ]) as ActionConfig[],
          })
        }
        break;
      }
      default: {
        SHOULD_LOG() && console.warn('AssignmentEntryForAdmin component currently do not have handler for target type ' + s.assignment.targetType);
      }
    }
  }

  const openGAD7SurveyOverlay = () => {
    UI.OVERLAY.present({
      name: 'OverlaySurveyGAD7',
      component: () => <OverlaySurveyGAD7 assignment={s.assignment as AssignmentGAD7} />,
      width: '50em',
    })
  }

  const openPHQ9SurveyOverlay = () => {
    UI.OVERLAY.present({
      name: 'OverlaySurveyPHQ9',
      component: () => <OverlaySurveyPHQ9 assignment={s.assignment as AssignmentPHQ9} />,
      width: '50em',
    })
  }
  
  return <Observer children={() => (
    <button className={joinClassName("AssignmentEntryForAdmin", s.isCompleted && 'completed')} onClick={handleComponentClick}>
      <ColorTag color={s.status.color}>{s.status.displayName}</ColorTag>
      <h3>{s.title} {s.status.displayName === 'Completed' && '(Click to view)'}</h3>
      <p>Assigned to: <UsernameRenderer userId={s.assignment.assignedToUserId} /></p>
    </button>
  )} />
}

export default AssignmentEntryForAdmin;