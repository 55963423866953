import { flow, observable, runInAction } from "mobx";
import { ColorCodedState } from "../base/@types";
import { ConfigurationEndpoints } from "../base/endpoints/configuration.endpoints";
import { reportError } from "../base/utils/errors.utils";
import { NoOp } from "../base/utils/functions.utils";
import { mergeIntoObjectByDescriptors } from "../base/utils/object.utils";
import { getNowTimestampUtc, minutes } from "../base/utils/time.utils";
import { getTrustToken } from "../base/utils/trust.utils";
import tick, { doEvery } from "../base/utils/waiters.utils";
import { ModelName } from "../constants/modelNames.enum";
import { isInCypressTestMode, IS_PROD } from "../env";
import { Configuration, makeConfiguration, SiteConfigurationSet, ThreeWayState } from "../models/makeConfiguration.model";
import { APIGetManyResponse } from "./api.controller";
import { makeControllerBase, makeRootControllerChildInitFn } from "./_root.controller";

/**
 * Site configuration central controller. Those options can be updated by administrators in the backend panel UI.
 */
export type ConfigurationsController = ReturnType<typeof makeConfigurationsController>;

const getFallbackConfigurationSet = (): SiteConfigurationSet => ({
	COUNSELLING_EMAIL: 'off',
	THOUGHT_CATCHER_ADULT: 'off',
	THOUGHT_CATCHER_YOUNG_PEOPLE: 'off',
	THOUGHT_CATCHER_DISABLED_WORDING_ADULT: null,
	THOUGHT_CATCHER_DISABLED_WORDING_YOUNG_PEOPLE: null,
	SERVICE_OPEN_IN_COUNTRY_CODES: ['IE', 'GB'],
	COUNSELLING_APPLICATION_SERVICE_OPEN_IN_COUNTRY_CODES: ['IE'],
})

export function makeConfigurationsController(
) {

	const fallback = Object.freeze(getFallbackConfigurationSet());

	const _private = observable({
		serverRecords: [] as Configuration[],
	})

	const configurations: SiteConfigurationSet = observable(getFallbackConfigurationSet());

	const c = observable({
		...makeControllerBase('CONFIGURATIONS'),
		configChanged: false,
		dataRetrieved: false,
		configurations: {
			get COUNSELLING_EMAIL() { return configurations.COUNSELLING_EMAIL },
			get THOUGHT_CATCHER_ADULT() { return configurations.THOUGHT_CATCHER_ADULT },
			get THOUGHT_CATCHER_YOUNG_PEOPLE() { return configurations.THOUGHT_CATCHER_YOUNG_PEOPLE },
			get THOUGHT_CATCHER_DISABLED_WORDING_ADULT() { return configurations.THOUGHT_CATCHER_DISABLED_WORDING_ADULT },
			get THOUGHT_CATCHER_DISABLED_WORDING_YOUNG_PEOPLE() { return configurations.THOUGHT_CATCHER_DISABLED_WORDING_YOUNG_PEOPLE },
			get SERVICE_OPEN_IN_COUNTRY_CODES() { return configurations.SERVICE_OPEN_IN_COUNTRY_CODES },
			// get SERVICE_OPEN_IN_COUNTRY_CODES() { return [...configurations.SERVICE_OPEN_IN_COUNTRY_CODES] },
			get COUNSELLING_APPLICATION_SERVICE_OPEN_IN_COUNTRY_CODES() { return configurations.COUNSELLING_APPLICATION_SERVICE_OPEN_IN_COUNTRY_CODES },
		},
		setKey: flow(function* (key: keyof SiteConfigurationSet, value: any) {
			configurations[key] = value ?? fallback[key];
			c.configChanged = true;
			yield tick();
			yield tick();
			yield tick();
			c.configChanged = false;
		}),
		saveKey(key: keyof SiteConfigurationSet, value: any, trust: string) {
			return new Promise<any>(flow(function* (resolve, reject) {
				if (trust !== getTrustToken() && !isInCypressTestMode && IS_PROD) {
					console.log('request to set configuration was refused');
					resolve(false);
					return;
				}
				const existingRecord = _private.serverRecords.find(r => r.key === key);
				if (existingRecord?.id && existingRecord?.value === value) {
					resolve(false);
					return;
				}
				const { id } = existingRecord || {};
				if (key === 'SERVICE_OPEN_IN_COUNTRY_CODES') {
					if (!c.ROOT?.children.STAFF.can?.manage_.serviceCountryAvailabilities) {
						resolve(false);
						return;
					}
				} else {
					if (!c.ROOT?.children.STAFF.can?.manage_.globalSettings) {
						resolve(false);
						return;
					}
				}
				const url = id ? ConfigurationEndpoints.staff.update(id) : ConfigurationEndpoints.staff.create();
				const payload = makeConfiguration({ id, key, value, timeUpdated: getNowTimestampUtc() }).$getSnapshot();
				try {
					const savedConfig: Configuration = id ? yield c.ROOT!.children.API.patch(url, ModelName.configurations, payload) : yield c.ROOT!.children.API.post(url, ModelName.configurations, payload);
					const value = savedConfig.value;
					c.setKey(key, value);
					const r = _private.serverRecords.find(r => r.id === savedConfig.id);
					if (r) r.value = value;
					else _private.serverRecords.push(savedConfig);
					resolve(value);
				} catch (e) {
					reportError(e);
					reject(e);
				}
			}))
		},
		get thoughtCatcherAdult(): ThreeWayState {
			return c.configurations.THOUGHT_CATCHER_ADULT;
		},
		get thoughtCatcherYP(): ThreeWayState {
			return c.configurations.THOUGHT_CATCHER_YOUNG_PEOPLE;
		},
		reset: NoOp,
		getConfigurations: () => flow(function* () {
			c.dataRetrieved = false;
			const url = ConfigurationEndpoints.public.index({ perPage: Infinity });
			const { entries } = (yield c.ROOT!.children.API.getMany(url, ModelName.configurations)) as APIGetManyResponse<Configuration>;
			_private.serverRecords.splice(0);
			_private.serverRecords.push(...entries);
			const getKeyFromResponseData = (key: keyof SiteConfigurationSet) => {
				return entries.find((c: Configuration) => c.key === key)?.value;
			}
			Object.keys(configurations).forEach((key) => {
				const value = getKeyFromResponseData(key as keyof SiteConfigurationSet);
				c.setKey(
					key as keyof SiteConfigurationSet,
					value
				)
			});
			c.dataRetrieved = true;
		})(),
	})

	c.init = makeRootControllerChildInitFn(
		c,
		flow(function* () {
			const getConfig = async () => {
				await c.getConfigurations().catch(e => {
					c.ROOT!.children.UI.TOAST.present({
						heading: 'Unable to retrieve site configurations...',
						body: 'Apologies, we are currently experiencing some technical issues. Your access to some services might be temporarily restricted.',
						colorCodedState: ColorCodedState.alert,
					})
					runInAction(() => {
						mergeIntoObjectByDescriptors(c.configurations, getFallbackConfigurationSet());
					})
				});
			}
			yield getConfig();
			doEvery(getConfig, minutes(5)); // fallback, in case global does not work. Remove in future.
		})
	);

	return c;

}
