import { StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { ModelName } from "../constants/modelNames.enum";
import { hasTimestamps } from "../traits/hasTimestamps.trait";
import { ChatThread } from "./makeChatThread.model";
import { User } from "./makeUser.model";

export type ChatParticipantSnapshot = ReturnType<typeof makeChatParticipantBase>;
export type ChatParticipantRelatedModels = {
  thread?: ChatThread,
  user?: User,
}

export type ChatParticipantExtendedProperties = {};

export function makeChatParticipantBase() {
  return {
    id: '',
    threadId: '',
    userId: '',
    twilioIdentities: [] as string[],
    timeMuted: null as string | null,
    timeRemoved: null as string | null,
    timeDeclinedVideoCall: null as string | null,
    timeJoined: null as string | null,
    ...hasTimestamps(),
  }
}

export type ChatParticipant = StandardModel<ChatParticipantSnapshot, ChatParticipantRelatedModels, ChatParticipantExtendedProperties>;

export const makeChatParticipant = createStandardModelFactory<ChatParticipant, ChatParticipantRelatedModels, ChatParticipantExtendedProperties>({
  name: ModelName.chatParticipants,
  snapshotFactory: makeChatParticipantBase,
  relationshipsSchema: {
    thread: ModelName.chatThreads,
    user: ModelName.users,
  }
})