import { Observer } from 'mobx-react-lite';
import React, { FormEvent, MutableRefObject } from 'react';
import { Primary } from '../../constants/color.enum';
import { Form } from '../../mediators/form.mediator';
import joinClassName from '../../utils/className.utils';
import { getContextColorStyle } from '../../utils/colors.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { isFunction } from '../../utils/typeChecks.utils';
import { FormContext, FormContextContent, useCreateFormContextAndResizeQuery } from './Form.context';

export type FormFormHandlers<T extends object> = {
  onFocus?: (f: Form<T>) => void,
  onBlur?: (f: Form<T>) => void,
  onSave?: (f: Form<T>) => void,
  onReset?: (f: Form<T>) => void,
  onChange?: (f: Form<T>) => void,
}
export type FormFormProps<T extends object> = {
  className?: string,
  id?: string,
  name?: string,
  form: Form<T>,
  innerRef?: MutableRefObject<HTMLFormElement>,
  children?: React.ReactNode | ((context: FormContextContent<T>) => React.ReactNode),
  disabled?: boolean,
  color?: string,
  enableBrowserValidation?: boolean
} & FormFormHandlers<T>;

const FormForm = <T extends object>(props: React.PropsWithChildren<FormFormProps<T>>) => {

  const p = useProps(props);

  const { context, ref } = useCreateFormContextAndResizeQuery<T>(p);

  const s = useStore(() => ({
    get form() {
      return p.form;
    },
    handleFocus: () => {
      context.onFocus?.();
    },
    handleChange: () => {
      context.onChange?.();
    },
    handleBlur: async () => {
      context.onBlur?.();
    },
    handleNativeSubmit: (e: FormEvent) => e.preventDefault(),
    get style() {
      return {
        ...getContextColorStyle(Primary, p.color),
      }
    }
  }));

  return <Observer children={() => (
    <FormContext.Provider value={context as any}>
      <form
        className={joinClassName('FormForm', p.className)}
        id={context.id}
        ref={ref}
        onFocus={s.handleFocus}
        onBlur={s.handleBlur}
        onChange={s.handleChange}
        onSubmit={s.handleNativeSubmit}
        style={s.style}
        data-display-mode={context.resizeQuery?.displayMode}
        data-only-phones={context.resizeQuery?.onlyPhones}
        data-from-tablet={context.resizeQuery?.fromTablet}
        data-from-desktop={context.resizeQuery?.fromDesktop}
        noValidate={!props.enableBrowserValidation}
      >
        {isFunction(props.children) ? props.children(context as FormContextContent<T>) : props.children }
      </form>
    </FormContext.Provider>
  )} />
}

export default FormForm;