import { action, observable } from "mobx";
import { SHOULD_LOG } from "../../env";

const loadedScripts = new Map<string, {promise: Promise<true>, loaded: boolean | null}>();

export function loadScript(src: string) {

  const existingScriptDef = loadedScripts.get(src);

  if (existingScriptDef) {

    switch (existingScriptDef.loaded) {
      case null: {
        return existingScriptDef.promise;
      }
      case true: {
        return new Promise(resolve => resolve(true));
      }
      case false: {
        break;
      }
      default: {
        break;
      }
    }
  }

  const state = observable({
    loaded: null as null | boolean,
  })

  const promise = new Promise<true>(async (resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = action(() => {
      state.loaded = true;
      resolve(true);
    })
    script.onerror = action((e: unknown) => {
      state.loaded = false;
      reject(e)
    })
    SHOULD_LOG() && console.log(`Loading script: ${script}`);
    document.head.appendChild(script);
  })

  loadedScripts.set(src, observable({
    promise,
    get loaded() {
      return state.loaded
    },
  }))

  return promise;
}