import { makeAutoObservable, observable, reaction } from "mobx";
import moment from "moment";
import { v4 as uuid } from "uuid";
import { AnyObject } from "../../@types";
import { DateUnitSingular } from "../../@types/time.types";
import { HasColor, HasIsActive, HasName } from "../../@types/traits.types";
import { YYYYMMDD } from "../../utils/time.utils";
import { BaseCalendarEventConfig } from "./BaseCalendar";

export type IBaseCalendarEventType<EventDataType = AnyObject> = HasName & Partial<HasColor> & HasIsActive & {
  /**
   * A function that checks if a given event belongs to this type.
   */
  typeChecker?: (e: BaseCalendarEventConfig<EventDataType>) => boolean;
  color?: string;
};
export type IBaseCalendarEventTypeGroup<EventDataType = AnyObject> = {
  name: string,
  groupTypeChecker?: (e: BaseCalendarEventConfig<EventDataType>) => boolean;
  eventTypes: IBaseCalendarEventType<EventDataType>[],
}

export type IBaseCalendarMode = DateUnitSingular;

export interface IBaseCalendarStateOptions<EventDataType = AnyObject> {
  name?: string,
  startDate?: string,
  mode?: IBaseCalendarMode,
  displayTimelinesInWeekMode?: boolean,
  fullHeight?: boolean,
  fitInnerToAvailableHeight?: boolean,
  showEventEndTimes?: boolean,
  eventTypeGroups?: IBaseCalendarEventTypeGroup<EventDataType>[],
}

const defaultBaseCalendarStateOptions: Required<IBaseCalendarStateOptions> = observable({
  name: uuid(),
  startDate: moment().startOf('month').format(YYYYMMDD),
  mode: 'month',
  displayTimelinesInWeekMode: true,
  fullHeight: true,
  fitInnerToAvailableHeight: false,
  showEventEndTimes: false,
  eventTypeGroups: [],
})

export class BaseCalendarState<EventDataType = AnyObject> implements IBaseCalendarStateOptions<EventDataType> {
  private _name: string = uuid();
  private _startDate: string = moment().startOf('month').format(YYYYMMDD);
  private _mode: DateUnitSingular = 'month';
  private _fullHeight = false;
  private _fitInnerToAvailableHeight = false;
  private _displayTimelinesInWeekMode = false;
  private _showEventEndTimes = false;
  private _eventTypeGroups = [];
  options: IBaseCalendarStateOptions<EventDataType> = defaultBaseCalendarStateOptions;
  getKey(key: keyof IBaseCalendarStateOptions<EventDataType>) {
    // @ts-ignore
    return this.options ? this.options[key] : this['_' + key];
  }
  setKey(key: keyof IBaseCalendarStateOptions<EventDataType>, value: any) {
    // @ts-ignore
    if (this.options) this.options[key] = value;
    // @ts-ignore
    else this['_' + key] = value;
  }

  get name() { return this.getKey('name') }
  set name(value: string) { this.setKey('name', value) }

  get startDate() { return this.getKey('startDate') || this._startDate }
  set startDate(value: string) { this.setKey('startDate', value) }

  get mode() { return this.getKey('mode') || 'month' }
  set mode(value: DateUnitSingular) { this.setKey('mode', value) }

  get fullHeight() { return this.getKey('fullHeight') || false }
  set fullHeight(value: boolean) { this.setKey('fullHeight', value) }

  get fitInnerToAvailableHeight() { return this.getKey('fitInnerToAvailableHeight') || false }
  set fitInnerToAvailableHeight(value: boolean) { this.setKey('fitInnerToAvailableHeight', value) }

  get displayTimelinesInWeekMode() { return this.getKey('displayTimelinesInWeekMode') || false }
  set displayTimelinesInWeekMode(value: boolean) { this.setKey('displayTimelinesInWeekMode', value) }

  get showEventEndTimes() { return this.getKey('showEventEndTimes') || false }
  set showEventEndTimes(value: boolean) { this.setKey('showEventEndTimes', value) }

  get eventTypeGroups() { return this.getKey('eventTypeGroups') || [] }
  set eventTypeGroups(value: IBaseCalendarEventTypeGroup<EventDataType>[]) { this.setKey('eventTypeGroups', value) }

  get shouldDisplayAsTimeline(): boolean {
    return this.mode === 'week' && this.displayTimelinesInWeekMode;
  }
  constructor(options?: IBaseCalendarStateOptions<EventDataType>) {
    options && (this.options = options);
    reaction(() => this.mode, () => {
      this.autoSetStartDate();
    })
    this.startDate = moment().startOf(this.mode).format(YYYYMMDD);
    makeAutoObservable(this);
  }
  autoSetStartDate() {
    this.startDate = this.startDateMoment.startOf(this.mode).format(YYYYMMDD);
  }
  get startDateMoment() {
    return moment(this.startDate);
  }
  get endDateMoment() {
    return moment(this.startDate).endOf(this.mode);
  }
}