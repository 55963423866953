import { reaction } from 'mobx';
import React from 'react';
import { useLocation } from 'react-router';
import { GenericFunction } from '../../@types';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useControllers } from '../../hooks/useRootController.hook';
import { useAutoSyncWithInitializer } from '../../utils/mobx.utils';

type HistoryWatcherProps = {}

const HistoryWatcher: React.FC<HistoryWatcherProps> = props => {

	const { NAVIGATOR } = useControllers();

	const location = useLocation();
	const s = useAutoSyncWithInitializer(() => ({
		location,
		recordHistory: () => {
			NAVIGATOR.recordLocationHistory(s.location);
		},
	}), { location })

	useOnMount(() => {
		const disposers = [] as GenericFunction[];
		disposers.push(reaction(
			() => s.location.pathname,
			s.recordHistory
		))
		disposers.push(reaction(
			() => s.location.search,
			s.recordHistory
		));
		s.recordHistory();
		return () => disposers.forEach(d => d());
	});

	return null;
}

export default HistoryWatcher;