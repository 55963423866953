import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject, TimezoneMode } from '../../@types';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { createMomentFn, YYYYMMDDHHmmss } from '../../utils/time.utils';
import BaseInput, { BaseInputProps } from '../BaseInput/BaseInput';

export type TimeInputProps<T = AnyObject> = Omit<BaseInputProps<T>, 'rows'> & {
  defaultHour?: number,
  inputTimezoneMode?: TimezoneMode,
  outputTimezoneMode?: TimezoneMode,
}

const TimeInput: React.FC<TimeInputProps> = props => {

  const p = useProps(props);
  const _createMomentForInput = createMomentFn(p.inputTimezoneMode ?? 'auto');

  const s = useStore(() => ({
    get defaultHour() {
      return p.defaultHour !== void 0 ? parseInt(p.defaultHour + '') : 9
    },
    get time() {
      return p.form[p.field] ? _createMomentForInput(p.form[p.field]).local().format('HH:mm:ss') : null;
    },
    set time(newLocalTime: string | null) {
      // retrieve input as local
      if (!newLocalTime) { p.form[p.field] = null; return; }
      const defaultTime = _createMomentForInput(undefined).local().hour(p.defaultHour || 9).utc();
      const dateMoment = p.form[p.field] ? _createMomentForInput(p.form[p.field]) : defaultTime;
      const [ hour, minute ] = newLocalTime.split(':');
      // convert existing time to local time before assigning
      dateMoment.local();
      dateMoment.hour(parseInt(hour));
      dateMoment.minute(parseInt(minute));
      // save as output timezone mode
      const result = (p.outputTimezoneMode === 'local' ? dateMoment : dateMoment.utc()).format(YYYYMMDDHHmmss);
      p.form[p.field] = result;
    },
  }));

  return <Observer children={() => (
    <BaseInput
      {...p}
      className={joinClassName('TimeInput', p.className)}
      type="time"
      form={s}
      field="time"
      applyOnBlur
    />
  )} />

}

export default TimeInput;