import { observable, reaction, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { MutableRefObject, useContext } from 'react';
import Swiper, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import { PaginationOptions } from 'swiper/types/components/pagination';
import { SHOULD_LOG } from '../../../env';
import { Nillable, Renderable } from '../../@types';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useObservableRef } from '../../hooks/useObservableRef.hook';
import joinClassName from '../../utils/className.utils';
import { renderRenderable } from '../../utils/components.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import tick from '../../utils/waiters.utils';
import './SwiperContainer.scss';
import SwiperPagination from './SwiperPagination';

Swiper.use([Pagination]);

interface SwiperContainerProps {
  className?: string,
  direction?: 'horizontal' | 'vertical',
  centeredSlides?: boolean,
  initialSlide?: number,
  slidesPerView?: number | 'auto',
  gap?: number,
  clipContent?: boolean,
  layoutMode?: 'block' | 'flex',
  innerRef?: MutableRefObject<HTMLDivElement | null>,
  slides?: Renderable,
  paginationType?: PaginationOptions['type']
  onInit?: SwiperContainerOnInitHandler;
  onSlideChange?: SwiperContainerOnSlideChangeHandler;
  shouldUpdate?: () => any;
}

export type SwiperContainerOnInitHandler = (instance: Swiper) => void;
export type SwiperContainerOnSlideChangeHandler = (index: number, instance: Swiper) => void;
const makeSwiperContainerContext = (swiperInstance?: Nillable<Swiper>) => {
  const handlers = {
    onInit: [] as SwiperContainerOnInitHandler[],
    onSlideChange: [] as SwiperContainerOnSlideChangeHandler[],
  }
  const s = observable({
    _instance: swiperInstance,
    get instance() {
      return s._instance;
    },
    set instance(instance: Nillable<Swiper>) {
      instance?.on('init', () => {
        handlers.onInit.forEach(fn => fn(s._instance!));
      })
      instance?.on('slideChange', () => {
        handlers.onSlideChange.forEach(fn => fn(s.instance!.activeIndex, s._instance!));
      })
      if (instance) s._instance = instance;
      else s._instance = null;
    },
    onInit: (fn: SwiperContainerOnInitHandler) => {
      s._instance && fn(s._instance);
      handlers.onInit.push(fn);
    },
    onSlideChange: (fn: SwiperContainerOnSlideChangeHandler) => {
      handlers.onSlideChange.push(fn);
    },
  })
  return s;
}
const DefaultSwiperContainerContext = makeSwiperContainerContext();
const SwiperContext = React.createContext(DefaultSwiperContainerContext);
export const useSwiperContext = () => useContext(SwiperContext);
export const useSwiperInstance = () => useSwiperContext().instance;

const SwiperContainer: React.FC<SwiperContainerProps> = React.memo(p => {

  const ref = useObservableRef<HTMLDivElement>();

  const { slidesPerView } = p;
  const observableProps = useProps({ slidesPerView });

  const s = useStore(() => ({
    context: makeSwiperContainerContext(),
    getRef() {
      return p.innerRef || ref;
    },
    update: async () => {
      await tick();
      SHOULD_LOG() && console.log('swiper container slides updated');
      s.context.instance?.update();
    },
  }))

  useOnMount(() => {
    const disposers = [] as Function[];
    disposers.push(when(
      () => Boolean(s.getRef().current),
      () => {
        s.context.instance = new Swiper(s.getRef().current!, {
          centeredSlides: p.centeredSlides ?? false,
          initialSlide: p.initialSlide ?? 0,
          direction: p.direction ?? 'horizontal',
          slidesPerView: p.slidesPerView ?? 1,
          spaceBetween: p.gap ?? 0,
          ...p.paginationType && {
            pagination: {
              el: '.SwiperPagination',
              type: p.paginationType,
            }
          },
          noSwipingSelector: 'button, input, textarea'
        });
        p.onInit && s.context.onInit(p.onInit);
        p.onSlideChange && s.context.onSlideChange(p.onSlideChange);
      }
    ));
    disposers.push(
      reaction(
        () => observableProps.slidesPerView,
        () => {
          SHOULD_LOG() && console.log('slidesPerView changed to', observableProps.slidesPerView);
          if (s.context.instance) {
            s.context.instance.params.slidesPerView = observableProps.slidesPerView;
            s.update();
          }
        }
      )
    )
    disposers.push(when(
      () => Boolean(p.shouldUpdate),
      () => disposers.push(reaction(p.shouldUpdate!, s.update))
    ))
    return () => disposers.forEach(d => d());
  });

  return <Observer children={() => (
    <SwiperContext.Provider value={s.context}>
      <div className={
        joinClassName(
          'SwiperContainer',
          'swiper-container',
          p.className,
          p.clipContent === false && 'unclip',
          p.layoutMode
        )
      } ref={s.getRef()}>
        <div className="swiper-wrapper">
          { renderRenderable(p.slides) }
        </div>
        { p.children }
        { p.paginationType && <SwiperPagination /> }
      </div>
    </SwiperContext.Provider>
  )} />
})

export default SwiperContainer;