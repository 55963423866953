import React from "react";
import { BaseTableColumnConfig } from "../base/components/BaseTable/BaseTable";
import { makeActionConfig } from "../base/utils/actionConfig.utils";
import OverlaySurveySatisfaction from "../components/OverlaySurveySatisfaction/OverlaySurveySatisfaction";
import { ApiModelName } from "../constants/ApiModels.enum";
import { renderSatisfactionSurveyQuestionScore } from "../constants/survey.constants";
import { UIController } from "../controllers/ui.controller";
import { ActionConfig } from "../controllers/ui/ui.controller.types";
import { AssignmentSatisfaction } from "../models/makeAssignment.model";
import { calculateSatisfactionSurveyScore, SurveySatisfaction } from "../models/makeSurveySatisfaction.model";

export const satisfactionSurveyTableColumnConfigs: BaseTableColumnConfig<SurveySatisfaction>[] = [
  {
    label: 'ID',
    keyName: 'id',
    sortable: true,
    searchable: true,
  },
  {
    label: 'Counselling Experience',
    keyName: 'ratingOfCounsellingExperience',
    sortable: true,
    BodyCell: d => renderSatisfactionSurveyQuestionScore(d.ratingOfCounsellingExperience)
  },
  {
    label: 'Own Progress',
    keyName: 'ratingSelfProgress',
    sortable: true,
    BodyCell: d => renderSatisfactionSurveyQuestionScore(d.ratingSelfProgress)
  },
  {
    label: 'Relationship with Counsellor',
    keyName: 'ratingOfCounsellorRelationship',
    sortable: true,
    BodyCell: d => renderSatisfactionSurveyQuestionScore(d.ratingOfCounsellorRelationship)
  },
  {
    label: 'UX',
    keyName: 'ratingUx',
    sortable: true,
    BodyCell: d => renderSatisfactionSurveyQuestionScore(d.ratingUx)
  },
  {
    label: 'Online v.s. Offline Counselling',
    keyName: 'ratingOfOnlineVsOfflineCounselling',
    sortable: true,
    BodyCell: d => renderSatisfactionSurveyQuestionScore(d.ratingOfOnlineVsOfflineCounselling)
  },
  {
    label: 'Avg. Score (Max 3)',
    BodyCell: d => calculateSatisfactionSurveyScore(d).toFixed(3),
  },
  {
    label: 'Message',
    keyName: 'body',
    sortable: true,
    searchable: true,
    type: 'longText',
  },
  {
    label: 'Date',
    keyName: 'timeCreated',
    type: 'timestamp',
    sortable: true,
  },
];

export const makeSatisfactionSurveyTableItemActions: (UI: UIController) => ActionConfig<(survey?: SurveySatisfaction) => unknown>[] = (UI: UIController) => [
  makeActionConfig('View', survey => UI.OVERLAY.present({
    name: 'OverlaySatisfactionSurvey',
    component: <OverlaySurveySatisfaction assignment={{
      target: survey,
      targetId: survey?.assignedToUserId,
      targetType: ApiModelName.SURVEY_SATISFACTION,
    } as AssignmentSatisfaction} readonly />
  }))
]
