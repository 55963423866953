import { always } from "../../../../../base/utils/ramdaEquivalents.utils";
import { makeObservableRouteDef } from "../../../../../base/utils/routes.utils";
import AccountPageProfileEditor from "./AccountPageProfileEditor.page";

export const AccountPageProfileEditorRouteDef = makeObservableRouteDef({
	identifier: 'account-profile-editor',
	urlFactory: always('edit-profile'),
	composeTitle: always('Edit Profile'),
	component: AccountPageProfileEditor,
	icon: 'pencil',
})