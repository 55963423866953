export const AlwaysSafeTopicsForRecommending = [
  'general-mental-health',
  'pop-up-groups-live-chats',
  'feeling-alone',
  'feeling-anxious',
  'depression-support',
  'feeling-stressed',
  'xmas-blues',
  'burnout',
  'friendships-relationships',
]
export const AlwaysSafeTopicsForRecommendingForAdults = [
  ...AlwaysSafeTopicsForRecommending,
  'work-worklife-balance',
]
export const AlwaysSafeTopicsForRecommendingForYoungPeople = [
  ...AlwaysSafeTopicsForRecommending,
]
