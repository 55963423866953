import { addToArrayIfNew } from "../base/utils/array.utils";
import { AppPermissionRoles } from "../constants/permissionGroups.constants";
import { AppPermission } from "../constants/permissions.constants";
import { AppStaffRole } from "../constants/staffRoles.constants";

export const getRolePermissions = (roles: AppStaffRole[]) => {
  const rolePerms = [] as AppPermission[];
  AppPermissionRoles.forEach((role) => {
    if (roles.includes(role.roleName))
      role.permissions.forEach((perm) => addToArrayIfNew(rolePerms, perm));
  });
  return rolePerms;
}