import { CounsellingApplicationEndpointParams, CounsellingApplicationEndpoints, DefaultCounsellingApplicationIncludesWithSessionDetails } from "../base/endpoints/counsellingApplication.endpoints";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";

export const getCounsellingApplication = async (
  id: string,
  API: APIController,
  params?: CounsellingApplicationEndpointParams,
  asStaff?: boolean,
) => {
  const url = CounsellingApplicationEndpoints[asStaff ? 'staff' : 'own'].get(id, {
    ...params,
    include: params?.include ?? DefaultCounsellingApplicationIncludesWithSessionDetails,
  });
  return await API.get(url, ModelName.counsellingApplications);
}