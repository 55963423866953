import { Configuration, ConfigurationRelatedModels } from "../../models/makeConfiguration.model";
import { DefaultIndexParamSet } from "../utils/api.utils";
import { makeApiEndpointConfig, makeApiGetEndpointConfig } from "../utils/endpoints.utils";
import { prefixPublic, prefixStaff } from "./namespacePrefixes";

export type ConfigurationEndpointParams = DefaultIndexParamSet<Configuration, ConfigurationRelatedModels> & {
  include?: (keyof ConfigurationRelatedModels | string)[],
}

const publicUrlBase = prefixPublic('configurations');
const staffUrlBase = prefixStaff('configurations');

export const ConfigurationEndpoints = {
  public: {
    index: makeApiEndpointConfig<ConfigurationEndpointParams>(publicUrlBase),
  },
  staff: {
    index: makeApiEndpointConfig<ConfigurationEndpointParams>(staffUrlBase),
    get: makeApiGetEndpointConfig<ConfigurationEndpointParams>(staffUrlBase),
    create: () => staffUrlBase,
    update: (id: string) => `${staffUrlBase}/${id}`,
  }
}