import { ChatThreadEndpoints } from "../base/endpoints/chatThread.endpoints";
import { getNowTimestampUtc } from "../base/utils/time.utils";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { ChatThread, ChatThreadSnapshot } from "../models/makeChatThread.model";

export const endThread = (API: APIController, thread: ChatThread) => new Promise<boolean>(async (resolve, reject) => {
  try {
    const { id } = thread
    const url = ChatThreadEndpoints.staff.update(id);
    const payload: Partial<ChatThreadSnapshot> = {
      id,
      timeCreated: thread.timeCreated,
      timeEnded: getNowTimestampUtc()
    }
    await API.patch(url, ModelName.chatThreads, payload);
    resolve(true);
  } catch(e) {
    reject(e);
  }
})