import { action, observable } from "mobx";
import moment from "moment";
import { YYYYMMDD, YYYYMMDDHHmmss } from "../../base/utils/time.utils";
import { IS_DEV } from "../../env";

const makeClockController = () => {

  const nowMoment = moment();

  const c = observable({
    localNowMoment: nowMoment,
    localNow: nowMoment.toDate().getTime(),
    get nowUtcTimestamp() {
      return moment(c.localNowMoment).utc().format(YYYYMMDDHHmmss);
    },
    get todayUtcDate() {
      return moment(c.localNowMoment).utc().format(YYYYMMDD);
    },
    get todayLocalDate() {
      return moment(c.localNowMoment).format(YYYYMMDD);
    },
  });

  setInterval(action(() => {
    c.localNowMoment = moment();
    c.localNow = c.localNowMoment.toDate().getTime();
  }), 1000);

  return c;

}

export const CLOCK = makeClockController();

if (IS_DEV) Reflect.set(window, 'CLOCK', CLOCK);