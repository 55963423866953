import { autorun, flow, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Nullable, Renderable } from '../../@types';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { renderRenderable } from '../../utils/components.utils';
import { generateUuid } from '../../utils/id.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { isFunction } from '../../utils/typeChecks.utils';
// import DefaultErrorFallback from '../DefaultErrorFallback/DefaultErrorFallback';
// import './RenderWhen.scss';

export interface RenderIfProps {
  className?: string;
  if?: any | (() => any);
  ifAsync?: () => Promise<any>;
  error?: any | (() => any);
  renderWhileUndetermined?: Renderable
  fallback?: Renderable
  errorFallback?: (e?: unknown) => (string | React.ReactElement)
  component?: React.FC | null,
  /** todo: reaction not working somehow */
  onShouldRenderChange?: (shouldRender: Nullable<boolean>) => void;
}

const RenderIf: React.FC<RenderIfProps> = props => {

  const { component, ...propsToMakeObservable } = props;
  const p = useProps(propsToMakeObservable);

  const s = useStore(() => ({
    id: generateUuid(),
    _asyncIfResult: p.ifAsync ? null : true,
    get conditionReturnValue() {
      return isFunction(p.if) ? p.if() : (p.if ?? true);
    },
    /**
     * if returned null, it's "undetermined". false means rejection.
     */
    get shouldRender() {
      return s.conditionReturnValue && s._asyncIfResult;
    },
    get renderWhenUndetermined(): Renderable {
      if (p.renderWhileUndetermined === 'loading') return <p children="Loading..." />
      return typeof p.renderWhileUndetermined === 'function' ? <p.renderWhileUndetermined /> : p.renderWhileUndetermined;
    },
    get fallback(): Renderable {
      if (p.fallback === 'loading') return <p children="Loading..." />
      return renderRenderable(p.fallback);
    },
    get errorFallback(): React.ReactElement {
      const fallback = p.errorFallback && p.errorFallback(p.error);
      return fallback ? <>{fallback}</> : <p>An error occurred here.</p>;
    },
  }));

  useOnMount(() => {
    const disposerOne = autorun(flow(function* () {
      if (p.ifAsync) {
        s._asyncIfResult = yield p.ifAsync();
      }
    }))
    const disposerTwo = reaction(
      () => s.shouldRender,
      shouldRender => {
        p.onShouldRenderChange?.(shouldRender);
      },
      { fireImmediately: true }
    )
    return () => { disposerOne(); disposerTwo(); };
  })

  return <Observer children={() => {
    if (p.error) return s.errorFallback;
    return <>
      {s.shouldRender ? <>
        {props.component && <props.component />}
        {props.children}
      </> : renderRenderable(
        s.shouldRender === null ? s.renderWhenUndetermined : s.fallback
      )}
    </>
  }} />
  
}

export default RenderIf;