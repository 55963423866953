import { action, flow, observable } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { MutableRefObject } from 'react';
import ReactDOM from 'react-dom';
import { Nillable, TimezoneMode } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import { BaseCalendarDataFetcher, BaseCalendarEventConfig } from '../../base/components/BaseCalendar/BaseCalendar';
import { BaseCalendarState, IBaseCalendarMode } from '../../base/components/BaseCalendar/BaseCalendarState';
import BaseLabel from '../../base/components/BaseLabel/BaseLabel';
import BaseSelector from '../../base/components/BaseSelector/BaseSelector';
import BaseSpacer from '../../base/components/BaseSpacer/BaseSpacer';
import { BaseTableAppearanceOptions } from '../../base/components/BaseTable/BaseTable';
import CommandList from '../../base/components/CommandList/CommandList';
import { CommandListItem } from '../../base/components/CommandList/CommandListItem';
import IndexDirectory from '../../base/components/IndexDirectory/IndexDirectory';
import { IndexDirectoryAppearanceOptions, IndexDirectoryViewMode } from '../../base/components/IndexDirectory/indexDirectory.types';
import IndexDirectoryState from '../../base/components/IndexDirectory/IndexDirectoryState';
import { DefaultSupportGroupIncludesForStaff, SupportGroupEndpointParams, SupportGroupEndpoints } from '../../base/endpoints/supportGroup.endpoints';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useSyncObservableValueToStorage } from '../../base/hooks/useSyncObservableValueToStorage.hook';
import { makeActionConfig } from '../../base/utils/actionConfig.utils';
import { makeLaravelIndexDataFetcher } from '../../base/utils/api.utils';
import { addToArrayByString, transformArray } from '../../base/utils/array.utils';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { uniq } from '../../base/utils/ramdaEquivalents.utils';
import { toTitleCase } from '../../base/utils/string.utils';
import { createUTCMoment, YYYYMMDDHHmmss } from '../../base/utils/time.utils';
import { isString } from '../../base/utils/typeChecks.utils';
import { getUrlParams } from '../../base/utils/urlParams.utils';
import { ModelName } from '../../constants/modelNames.enum';
import { supportGroupTableColumnConfigs } from '../../constants/supportGroups.constants';
import { Tags } from '../../constants/tags.constants';
import { useCurrentUserIsAdult } from '../../hooks/useCurrentUserAge.hook';
import { makeSupportGroup, SupportGroup, SupportGroupSnapshot } from '../../models/makeSupportGroup.model';
import { getSupportGroups } from '../../requests/getSupportGroups.request';
import { getAgeFromDateOfBirth } from '../../utils/ageAndDateOfBirth.utils';
import { checkIfUserIsWithinAgeGroupsOfSupportGroup, manageSupportGroup, viewSupportGroupInOverlay } from '../../utils/supportGroup.helpers';
import SupportGroupCalendarEventRenderer from '../SupportGroupCalendarEventRenderer/SupportGroupCalendarEventRenderer';
import SupportGroupSummaryCard from '../SupportGroupSummaryCard/SupportGroupSummaryCard';
import './SupportGroupsIndex.scss';

interface SupportGroupsIndexProps {
  defaultMode?: IndexDirectoryViewMode,
  defaultCalendarMode?: IBaseCalendarMode;
  allowedViewModes?: IndexDirectoryViewMode[],
  canEdit?: boolean,
  canCreate?: boolean,
  appearanceOptions?: BaseTableAppearanceOptions & IndexDirectoryAppearanceOptions,
  // renderAddNewButtonTo?: (button: React.ReactChild) => unknown,
  inputTimezoneMode?: TimezoneMode,
  outputTimezoneMode?: TimezoneMode,
  disabled?: boolean,
  filterableTags?: string[]
  addButtonRenderPortalRef?: MutableRefObject<HTMLDivElement | null>,
  forUserId?: string,
  own?: boolean,
}

type EventType = BaseCalendarEventConfig<SupportGroup>;

const SupportGroupsIndex: React.FC<SupportGroupsIndexProps> = props => {

  const { API, UI, AUTH, LOCALDB } = useControllers();

  const currentUserIsAdult = useCurrentUserIsAdult();
  const p = useProps(props);

  const s = useStore(() => ({
    supportGroups: [] as SupportGroup[],
    indexDirectoryState: new IndexDirectoryState<SupportGroup>({
      viewMode: p.defaultMode ?? 'calendar',
      sortByKeyName: 'timeScheduled',
      sortDirection: 'desc',
      searchable: true,
      searchByKeyName: 'title',
    }),
    calendarState: new BaseCalendarState<SupportGroup>({
      name: 'support-groups-index',
      mode: p.defaultCalendarMode || 'week',
      fullHeight: true,
      fitInnerToAvailableHeight: true,
      eventTypeGroups: [],
    }),
    calendarEvents: [] as BaseCalendarEventConfig<SupportGroup>[],
    get canCreate() { return p.canCreate },
    get fullHeight() { return s.calendarState.fullHeight },
    get currentUserAge() {
      return AUTH.currentUser?.dateOfBirth ? getAgeFromDateOfBirth(AUTH.currentUser.dateOfBirth) : null;
    },
    get titleFilter() {
      return (g: SupportGroup) => AUTH.can.supportGroups_.facilitate_.someUserGroups || !g.title.includes('Internal Test Group');
    },
    get ageFilter() {
      return (g: SupportGroup) => {
        if (AUTH.canFacilitate) return true;
        return checkIfUserIsWithinAgeGroupsOfSupportGroup(AUTH.currentUser, g);
      }
    },
    get archiveFilter() {
      return (g: SupportGroup) => AUTH.can.supportGroups_.facilitate_.someUserGroups || !g.timeArchived;
    },
    get tagFilter() {
      return (g: SupportGroup) => {
        if (!currentUserIsAdult) return true;
        if (!s.filterByTag || p.canEdit) return true;
        if (!g.tags) return true;
        if (s.filterByTag === 'adult') return g.tags.length === 0;
        return g.tags.includes(s.filterByTag as Tags);
      }
    },
    get compositeFilter() {
      return (g: SupportGroup) => s.ageFilter(g) && s.archiveFilter(g) && s.tagFilter(g) && s.titleFilter(g);
    },
    get shouldShowCommandList() {
      return currentUserIsAdult && Boolean(p.filterableTags?.length) && s.filterOptionsIncludingAll.length > 1 && !p.canEdit;
    },

    filterByTag: '' as string,
    _tagsFromParams: [] as string[],
    get filterableTags() {
      if (p.canEdit) return [];
      return uniq([...(p.filterableTags || []), ...s._tagsFromParams]);
    },
    get filterOptions() {
      return (s.filterableTags || []).map(t => ({
        label: toTitleCase(t),
        value: t,
        color: t === Tags.FrontLineWorkers ? 'aqua' : undefined,
      }))
    },
    get filterOptionsIncludingAll() {
      return [
        { label: 'All Groups', value: '' },
        { label: 'Adult Support Groups', value: 'adult' },
        ...s.filterOptions,
      ]
    },
    get currentUserCanFacilitate() {
      return AUTH.can.supportGroups_.facilitate_.someUserGroups;
    },
    shouldShowArchived: false,
  }));


  /** TODO: front line */
  const checkUrlParams = action( () => {
    const params = getUrlParams();
    if (params['tag'] && !p.canEdit) {
      const tag = params['tag'];
      if (!currentUserIsAdult && tag === Tags.FrontLineWorkers) {
        UI.DIALOG.present({
          heading: 'Hello there! Sorry, only 18+ accounts can view front line worker support groups.',
          body: 'If you are looking up support groups for someone you know, you can recommend them to join turn2me to view the calendar for appropriate age groups.',
          defaultActions: ['positive']
        })
        return;
      }
      addToArrayByString(s._tagsFromParams, tag);
      s.filterByTag = tag;
    } else if (currentUserIsAdult) {
      const { isFrontLineWorker } = AUTH.currentUser?.preferences || {};
      s.filterByTag = isFrontLineWorker ? Tags.FrontLineWorkers : '';
    }
  })

  useOnMount(() => {
    checkUrlParams();
  })

  const tableDataFetcher = makeLaravelIndexDataFetcher<SupportGroup, SupportGroupEndpointParams>(
    API,
    ModelName.supportGroups,
    p.own ? SupportGroupEndpoints.own.index : (
      s.currentUserCanFacilitate ? SupportGroupEndpoints.staff.index : SupportGroupEndpoints.client.index
    ),
    {
      perPage: 15,
      include: s.currentUserCanFacilitate ? ['facilitator', 'allowedCompany', 'reservations.user.permissions', 'createdByUser.permissions', 'threads'] : ['facilitator', 'allowedCompany'],
      filter: {
        ...(p.forUserId && !p.own) ? { userJoined: [p.forUserId] } : {},
      }
    },
    { localFilter: s.compositeFilter }
  )
  const columnConfigs = supportGroupTableColumnConfigs;

  const viewTableSupportGroupDetails = (g?: SupportGroup | string) => p.canEdit ? createEditorOverlay(g) : createViewerOverlay(g);

  const viewCalendarSupportGroupDetails = (e?: BaseCalendarEventConfig<SupportGroup> | string) => {
    if (!e) return;
    if (p.canEdit) createEditorOverlay(isString(e) ? e : e.data);
    else createViewerOverlay(isString(e) ? e : e.data);
  }


  const createViewerOverlay = (group?: SupportGroup | string, onAfterClose?: () => unknown) => (
    viewSupportGroupInOverlay(UI, group, onAfterClose)
  )

  const createEditorOverlay = (group?: SupportGroup | string, onAfterClose?: () => unknown) => (
    manageSupportGroup(UI, group, onAfterClose)
  )

  const itemActions = [ makeActionConfig('view', viewTableSupportGroupDetails) ]

  const createNewSupportGroupModel = (toMerge?: Partial<SupportGroupSnapshot>) => observable(makeSupportGroup({
    scheduledDurationInMinutes: 60,
    maxParticipants: 20,
    ageGroups: [],
    ...toMerge,
  }));

  function getDefaultStartTime() {
    const defaultStartTimeMoment = createUTCMoment().add(2, 'hours').startOf('hour');
    const defaultStartTime = (p.outputTimezoneMode === 'local' ? defaultStartTimeMoment.local() : defaultStartTimeMoment).format(YYYYMMDDHHmmss);
    return defaultStartTime;
  }

  const createNewSupportGroupWithStartTime = (startTime?: string) => createNewSupportGroupModel({
    facilitatorId: AUTH.can.provideCounsellingFor_.someUserGroups ? AUTH.currentUser?.id : '',
    timeScheduled: startTime || getDefaultStartTime(),
  });

  const calendarEventCreator = (startTime?: string, arr?: EventType[]) => {
    return new Promise<EventType>(async (resolve, reject) => {
      const newGroup = createNewSupportGroupWithStartTime(startTime);
      createEditorOverlay(newGroup, flow(function * () {
        const savedNewGroup: Nillable<SupportGroup> = yield LOCALDB.get(ModelName.supportGroups, newGroup.id);
        if (!savedNewGroup) return;
        const newEvent = eventBuilder(savedNewGroup);
        s.supportGroups.push(savedNewGroup);
        s.calendarEvents.push(newEvent);
        if (newEvent && newEvent.id) {
          arr?.push(newEvent);
        }
        resolve(newEvent);
      }));
    })
  }

  const supportGroupCreator = () => {
    return new Promise<SupportGroup>(async (resolve, reject) => {
      const newGroup = createNewSupportGroupWithStartTime();
      createEditorOverlay(newGroup, action(() => {
        resolve(newGroup);
        if (newGroup.id) s.supportGroups.push(newGroup);
      }));
    });
  }

  const eventBuilder = (g: SupportGroup) => {
    return observable({
      get id() { return g.id; },
      get startTime() { return g.timeScheduled || g.timeStarted; },
      get name() { return g.title },
      get hidden() { return !s.compositeFilter(g); },
      get color() { return g.color },
      data: g,
    })
  }


  const calendarDataFetcher: BaseCalendarDataFetcher<SupportGroup> = (
    startDate: string,
    endDate: string,
  ) => new Promise(async (resolve, reject) => {
    try {
      const params: SupportGroupEndpointParams = {
        filter: {
          dateRange: {
            key: 'timeScheduled',
            startDate,
            endDate,
          }
        },
        perPage: Infinity,
        latest: true,
        include: DefaultSupportGroupIncludesForStaff,
      };
      const supportGroups = await getSupportGroups(API, params);
      const events = transformArray(supportGroups, eventBuilder);
      resolve(events);
    } catch (e) { reject(e) }
  })

  const showArchivedFilter = (g: SupportGroup) => {
    if (s.shouldShowArchived) return true;
    return !Boolean(g.timeArchived);
  }
  const ViewArchivedToggler = () => {
    return <>
      <BaseButton size="sm" icon="eye" iconVariant="filled" title="View Archived" label={UI.onlyPhones ? "" : `${s.shouldShowArchived ? 'Hide' : "Show"} Archived`} onClick={action(() => s.shouldShowArchived = !s.shouldShowArchived)} />
      <BaseSpacer size="sm" inline />
    </>
  }
  useSyncObservableValueToStorage(
    ['SupportGroupIndex', 'shouldShowArchived'],
    () => s.shouldShowArchived,
    action(v => { s.shouldShowArchived = v }),
    { initialValue: s.shouldShowArchived }
  )

  return <Observer children={() => (
    <div className={joinClassName(
      'SupportGroupsIndex',
      s.fullHeight && 'fullHeight',
      p.disabled && 'disabled'
    )}>
      {
        s.shouldShowCommandList && <CommandList direction="row">
          <CommandListItem>
            <BaseLabel>Filter By</BaseLabel>
            <BaseSelector form={s} field="filterByTag" options={s.filterOptionsIncludingAll} />
          </CommandListItem>
        </CommandList>
      }
      <IndexDirectory<SupportGroup>
        state={s.indexDirectoryState}
        allowedViewModes={p.allowedViewModes || ['calendar', 'table']}
        dataFetcher={tableDataFetcher}
        appearanceOptions={p.appearanceOptions}
        entryCreator={s.canCreate ? supportGroupCreator : undefined}
        tablePropsGetter={() => ({ columnConfigs, itemActions })}
        doNotShowCreateButtonInOptions={Boolean(p.addButtonRenderPortalRef)}
        listEntryRenderer={g => <SupportGroupSummaryCard supportGroup={g} />}
        listEntrySeparator={<BaseSpacer size=".62em" />}
        calendarPropsGetter={() => ({
          state: s.calendarState,
          events: s.calendarEvents,
          eventCreator: s.canCreate ? calendarEventCreator : undefined,
          eventBuilder,
          showHeader: true,
          onEventClick: viewCalendarSupportGroupDetails,
          dataFetcher: calendarDataFetcher,
          eventRenderer: SupportGroupCalendarEventRenderer,
        })}
        entryFilter={showArchivedFilter}
        EndCommandListItems={ViewArchivedToggler}
      />
      {
        p.addButtonRenderPortalRef?.current && ReactDOM.createPortal(
          <BaseButton dataCy="create-support-group" className="subtle New-Support-Group" icon="plus" onClick={() => { calendarEventCreator() }} label={UI.onlyPhones ? 'New' : 'New Support Group'}/>,
          p.addButtonRenderPortalRef.current,
        )
      }
    </div>
  )} />
}

export default SupportGroupsIndex;