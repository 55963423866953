import { Observer } from "mobx-react-lite";
import React from "react";
import BaseSelector, { BaseSelectorProps } from "../../base/components/BaseSelector/BaseSelector";
import { useControllers } from "../../base/hooks/useRootController.hook";
import { useStore } from "../../base/utils/mobx.utils";
import { genderDescriptors } from "../../constants/genders.constants";

interface GenderSelectorProps<FormType = object> extends Omit<BaseSelectorProps<FormType, {name: string, value: string}, string>, 'options'>{
  doNotAllowPreferNotToSay?: boolean,
}

export const GenderSelector = <T extends object = object>(props: React.PropsWithChildren<GenderSelectorProps<T>>) => {
  const { UI } = useControllers();
  const s = useStore(() => ({
    get genderOptions() {
      return genderDescriptors.filter(
          (descriptor) => descriptor.name !== 'Prefer not to say'
      );
    },
  }))
  return <Observer children={() => (
    <BaseSelector<T, { name: string, value: string }, string>
      className="GenderSelector"
      options={[...s.genderOptions]}
      appearance={props.appearance ?? (UI.onlyPhones ? 'system' : 'inline')}
      {...props}
    />
  )} />
}