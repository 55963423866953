import { Observer } from 'mobx-react-lite';
import alipay from 'payment-icons/min/flat/alipay.svg';
import amex from 'payment-icons/min/flat/amex.svg';
import defaultIcon from 'payment-icons/min/flat/default.svg';
import diners from 'payment-icons/min/flat/diners.svg';
import discover from 'payment-icons/min/flat/discover.svg';
import elo from 'payment-icons/min/flat/elo.svg';
import hipercard from 'payment-icons/min/flat/hipercard.svg';
import jcb from 'payment-icons/min/flat/jcb.svg';
import maestro from 'payment-icons/min/flat/maestro.svg';
import mastercard from 'payment-icons/min/flat/mastercard.svg';
import paypal from 'payment-icons/min/flat/paypal.svg';
import unionpay from 'payment-icons/min/flat/unionpay.svg';
import verve from 'payment-icons/min/flat/verve.svg';
import visa from 'payment-icons/min/flat/visa.svg';
import React from 'react';
import { useProps, useStore } from '../../utils/mobx.utils';
import BaseIcon from '../BaseIcon/BaseIcon';
import './PaymentMethodIcon.scss';

interface PaymentMethodIconProps {
  value?: string;
}

const PaymentMethodIcon: React.FC<PaymentMethodIconProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get iconSrc() {
      switch (p.value) {
        case 'alipay': return alipay;
        case 'amex': return amex;
        case 'diners': return diners;
        case 'discover': return discover;
        case 'elo': return elo;
        case 'hipercard': return hipercard;
        case 'jcb': return jcb;
        case 'maestro': return maestro;
        case 'mastercard': return mastercard;
        case 'unionpay': return unionpay;
        case 'paypal': return paypal;
        case 'verve': return verve;
        case 'visa': return visa;
        default: return defaultIcon;
      }
    }
  }));
  return <Observer children={() => (
    <div className="PaymentMethodIcon">
      { s.iconSrc ? <img src={s.iconSrc} alt={`${p.value} logo`}/> : <BaseIcon name="bank-card" />}
    </div>
  )} />
}

export default PaymentMethodIcon;