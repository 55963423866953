import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useProps, useStore } from '../../utils/mobx.utils';
import { BaseCalendarBaseChildProps } from './BaseCalendar';
import './BaseCalendarBodyContent.scss';
import BaseCalendarMonth from './BaseCalendarMonth';
import BaseCalendarWeek from './BaseCalendarWeek';

interface BaseCalendarBodyContentProps<T = object> extends BaseCalendarBaseChildProps<T> { }

function BaseCalendarBodyContent<T extends object>(props: React.PropsWithChildren<BaseCalendarBodyContentProps<T>>) {

  const p = useProps(props);

  const s = useStore(() => ({
    get mode() {
      return p.state.mode;
    },
    get startDate() {
      return p.state.startDate;
    },
  }));

  return <Observer children={() => {
    const ContentRenderer = (() => {
      switch (s.mode) {
        case 'week': return () => <BaseCalendarWeek {...props} />;
        case 'month': return () => <BaseCalendarMonth {...props} />;
        default: return null;
      }
    })();
    return (
      p.state.shouldDisplayAsTimeline ? (
        <div className="BaseCalendarBodyContent">
          <div className="BaseCalendarBodyContentInner">
            {ContentRenderer && <ContentRenderer />}
          </div>
        </div>
      ) : (
          <table className="BaseCalendarBodyContent">
            <tbody className="BaseCalendarBodyContentInner">
              {ContentRenderer && <ContentRenderer />}
            </tbody>
          </table>
        )
    )
  }} />
  
}

export default BaseCalendarBodyContent;