import { first } from "@amcharts/amcharts4/.internal/core/utils/Array";
import { action, flow, observable, reaction, toJS, when } from "mobx";
import moment from "moment";
import { Emotion } from "../../../constants/emotions.enum";
import { LocalThoughtCacheKey } from "../../../constants/storageKeys.constants";
import { APIController } from "../../../controllers/api.controller";
import { verifyMobileNumber } from "../../../controllers/auth/verifyMobileNumber";
import { CLOCK } from "../../../controllers/common/clock.controller";
import { StorageController } from "../../../controllers/storage.controller";
import { SHOULD_LOG } from "../../../env";
import { BotChatMessage } from "../../../models/bots/makeBotChatMessage.model";
import { Thought, ThoughtSnapshot } from "../../../models/makeThought.model";
import { saveThought } from "../../../requests/saveThought.request";
import { GenericFunction, Nillable, Nullable } from "../../@types";
import { Bot, BotAnalyzer } from "../../mediators/bot.mediator";
import { Form, makeForm } from "../../mediators/form.mediator";
import { replaceArrayContent } from "../../utils/array.utils";
import { reportError } from "../../utils/errors.utils";
import { immediateReaction } from "../../utils/mobx.utils";
import { setValueOfKey } from "../../utils/object.utils";
import { analyzeSentiment } from "../../utils/sentiment.utils";
import { createUTCMoment, getNowTimestampUtc } from "../../utils/time.utils";
import { isString } from "../../utils/typeChecks.utils";
import tick from "../../utils/waiters.utils";
import { matchChangeThoughtStateCommand } from "../matchers/changeThoughtState.botMatcher";
import { matchYesNoCommand } from "../matchers/yesNo.botMatcher";
import { getGoodbyeTimeOfDay } from "../responses/goodbyes.botResponses";
import { getGreeting } from "../responses/greetings.botResponses";
import { getMoodResponse } from "../responses/mood.botResponses";

type ThoughtCatcherBotExpectedFieldName = 'content' | 'situation' | 'response';
type ThoughtCatcherBotExpectableActionsName = 'setDefaultState';
type ThoughtCatcherBotExpectations = ThoughtCatcherBotExpectedFieldName | ThoughtCatcherBotExpectableActionsName;
export type ThoughtCatcherBotAnalyzerStateSnapshot = {
  welcomeSent: boolean,
  memory: ThoughtCatcherBotAnalyzerMemory,
  expectation: Nullable<ThoughtCatcherBotExpectations>,
}

const makeThoughtCatcherBotAnalyzerMemory = () => ({
  emotion: {
    id: null as Nullable<string>,
    type: null as Nullable<Emotion>,
    response: null as Nullable<string>,
  },
  content: {
    userMessages: [] as BotChatMessage[],
  },
  situation: {
    userMessages: [] as BotChatMessage[],
  },
  response: {
    userMessages: [] as BotChatMessage[],
  },
  setDefaultState: {
    userMessages: [] as BotChatMessage[],
  }
})

export type ThoughtCatcherBotAnalyzerMemory = ReturnType<typeof makeThoughtCatcherBotAnalyzerMemory>;

export type ThoughtCatcherBotAnalyzer = BotAnalyzer<ThoughtCatcherBotAnalyzerStateSnapshot> & {
  form: Form<ThoughtSnapshot>,
  // lastMessage: Sometimes<ChatMessage>,
  // userMood: Sometimes<Emotion>,
}

export const makeThoughtCatcherBotAnalyzerFactory = (
  thought: Thought,
) => (
  bot: Bot<ThoughtCatcherBotAnalyzerStateSnapshot, ThoughtCatcherBotAnalyzer>,
) => {

  if (!bot.rootController) throw Error('Thought catcher bot is being initiated before root controller is available.');

  const disposers = [] as GenericFunction[];

  // if (!bot.user) throw Error('Thought catcher bot can only be started with a logged in user.');

  const welcome = () => new Promise<true>(flow(function * (resolve) {
    const { AUTH, THOUGHT_CATCHER, STORAGE } = bot.rootController!.children;
    SHOULD_LOG() && console.log('thought catcher bot welcome!');
    if (s.welcomeSent) return;
    if (s.user) {
      yield bot.sendBotMessage(getGreeting('simple', s.user.username || s.user.firstName));
      yield bot.sendBotMessage(`I am the turn2me chatbot to help you track your daily mood. I will ask you a few questions, and post a thought recording your answers.`);
    } else bot.sendBotMessage('Welcome! I am the turn2me chat bot to help you track your daily mood.');
    if (s.user) {
      if (s.user.hasVerifiedEmailOrMobileNumber) {
        immediateReaction(
          () => THOUGHT_CATCHER.errorPostingLastThought !== null,
          hasError => {
            // if (hasError) {
            //   bot.sendBotMessage({
            //     body: 'Apologise for the convenience, but there was an error posting one of your earlier thoughts. I\'ll try again soon; please check back later!',
            //     shouldPersist: false,
            //   })
            //   bot.end();
            // }
            STORAGE.remove(LocalThoughtCacheKey);
          },
        )
      } else {
        if (createUTCMoment(s.user.timeCreated).diff(moment(), 'days') < 7) {
          yield bot.sendBotMessage({
            body: 'First of all, welcome to turn2me!',
          });
        }
        if (!AUTH.currentUser?.hasVerifiedEmailOrMobileNumber) {
          bot.disableUserInput();
          if (AUTH.currentUser?.mobileNumber && !AUTH.currentUser?.hasVerifiedMobileNumber) {
            yield bot.sendBotMessage({
              body: 'Before continuing, please verify your phone first.',
            });
            yield bot.sendBotMessage({
              body: 'Verify your phone',
              action: () => verifyMobileNumber(AUTH, undefined, true),
              disableWhen: () => !!AUTH.currentUser?.hasVerifiedMobileNumber,
            });
            yield when(() => !!AUTH.currentUser?.hasVerifiedMobileNumber);
            yield bot.sendBotMessage('Thanks for verifying your phone number!');
          } else if (AUTH.currentUser?.email && !AUTH.currentUser.hasVerifiedEmail) {
            yield bot.sendBotMessage({
              body: 'Before continuing, please check your inbox for your confirmation email.',
            });
            yield when(() => !!AUTH.currentUser?.hasVerifiedEmail);
            yield bot.sendBotMessage('Thanks for verifying your email!');
          }
          bot.enableUserInput();
        }
      }
    }
    s.welcomeSent = true;
    yield tick(500);
    if (!s.form.value.emotionId) {
      bot.sendBotMessage({
        body: 'How are you feeling today? Please select an emoji from above to get started.',
        shouldPersist: false,
      });
    }
    resolve(true);
  }))

  const s = observable({
    get user() {
      return bot.user;
    },
    // thought,
    form: makeForm(thought.$getSnapshot()),
    get API(): APIController {
      return bot.rootController!.children.API!;
    },
    get STORAGE(): StorageController {
      return bot.rootController!.children.STORAGE!;
    },
    welcomeSent: false,
    welcome,
    get lastMessage(): Nillable<BotChatMessage> {
      return bot.lastUserMessage;
    },
    get userMood(): Nillable<Emotion> {
      return s.form.fields.emotionId.value;
    },
    set userMood(mood: Nillable<Emotion>) {
      s.form.set('emotionId', mood);
      s.saveLocally();
    },
    get userHasPositiveMood(): boolean {
      return s.userMood === Emotion.good || s.userMood === Emotion.great;
    },
    get userHasNegativeMood(): boolean {
      return s.userMood === Emotion.bad || s.userMood === Emotion.terrible;
    },
    memory: makeThoughtCatcherBotAnalyzerMemory(),
    /** @deprecated */
    get changeUpdateDetectionString(): string {
      const t = s.form.value;
      return [t.id, t.emotionId, t.content, t.timeCreated, t.isPrivate, t.response, t.situation].filter(i => i).join('_');
    },
    saveLocally: () => s.STORAGE.set(LocalThoughtCacheKey, s.form.value),
    saveToAPI: async () => {
      async function save() {
        s.form.set('timeCreated', CLOCK.nowUtcTimestamp);
        s.form.set('timeUpdated', CLOCK.nowUtcTimestamp);
        const savedThought = await saveThought(s.form.value, s.API);
        s.form.set('id', savedThought?.id);
        s.STORAGE.remove(LocalThoughtCacheKey);
      }
      await tick(500, 1000);
      let retryLimit = 3;
      while (retryLimit >= 0) {
        try {
          save();
          break;
        } catch(e) {
          if (retryLimit === 0) throw (e);
        } finally {
          retryLimit--;
        }
      }
    },
    expectation: 'content' as Nullable<ThoughtCatcherBotExpectations>,
    expect: action((e: Nullable<ThoughtCatcherBotExpectations>) => {
      s.expectation = e;
    }),
    init: async (snapshot: ThoughtCatcherBotAnalyzerStateSnapshot) => {
      // console.log(snapshot);
      if (snapshot) s.patchStateSnapshot(snapshot);
    },
    get stateSnapshot(): ThoughtCatcherBotAnalyzerStateSnapshot {
      return {
        welcomeSent: s.welcomeSent,
        memory: s.memory,
        expectation: s.expectation,
      }
    },
    patchStateSnapshot: action((snapshot: ThoughtCatcherBotAnalyzerStateSnapshot) => {
      s.welcomeSent = snapshot.welcomeSent;
      s.expectation = snapshot.expectation;
      Object.assign(s.memory.emotion, snapshot.memory.emotion);
      replaceArrayContent(s.memory.content.userMessages, snapshot.memory.content.userMessages);
      replaceArrayContent(s.memory.situation.userMessages, snapshot.memory.situation.userMessages);
      replaceArrayContent(s.memory.response.userMessages, snapshot.memory.response.userMessages);
      replaceArrayContent(s.memory.setDefaultState.userMessages, snapshot.memory.setDefaultState.userMessages);
      // analyzer(bot.lastUserMessage);
    }),
    die: async () => {
      disposers.forEach(d => d());
    }
  });

  const sendEmotionResponse = flow(function* (type: Emotion) {
    if (!s.user?.hasVerifiedEmailOrMobileNumber) return;
    const response = getMoodResponse(type, s.user?.username);
    if (s.memory.emotion.id) {
      bot.revertBotMessage(`emotion-response-for-${s.memory.emotion.id}`);
    }
    const id = `emotion-response-for-${s.memory.emotion.id}`;
    s.memory.emotion.type = type;
    s.memory.emotion.response = response;
    yield bot.sendBotMessageWithId(id, response);
    bot.enableUserInput();
  })

  const joinMessages = (messages: BotChatMessage[]): string => {
    if (messages.length === 1) return isString(first(messages)?.body) ? first(messages)!.body as string : '';
    return messages.map(m => {
      // if (lastInString(m.body) === '.') return m.body.slice(0, m.body.length - 1);
      return m.body
    // }).join('. ');
    }).join('\n').replace(/[\r\n]{2,}/g, "\n");
  }

  const subAnalyzers: Record<ThoughtCatcherBotExpectations, (m: BotChatMessage) => void> = {

    'content': flow(function * (m) {

      SHOULD_LOG() && console.log('bot received thought content; replying...');
      bot.disableUserInput();
      SHOULD_LOG() && console.log('all user messages:', toJS(s.memory.content.userMessages));
      const consolidatedMessages = joinMessages(s.memory.content.userMessages);
      const sentiment = analyzeSentiment(consolidatedMessages);
      SHOULD_LOG() && console.log(consolidatedMessages);
      s.form.set('content', consolidatedMessages);
      s.form.set('timeCreated', getNowTimestampUtc());
      SHOULD_LOG() && console.log(sentiment);
      if (s.userHasPositiveMood && sentiment.negative.length === 0) {
        yield bot.sendBotMessage("Thanks for sharing your thoughts. 😊");
      } else {
        yield bot.sendBotMessage('Thanks for sharing your thoughts.');
      }
      if (!s.form.value.isPrivate) {
        yield tick(190, 620);
        if (s.user) bot.sendBotMessage('Also, this thought will be posted publicly. If you change your mind, just tell me to switch it to private any time.');
      }
      yield tick(500, 1000);
      bot.sendBotMessage('Would you like to tell me a bit more about what happened? No worries if not, you can just reply no.');

      s.saveLocally();
      s.expect('situation');
      bot.enableUserInput();

    }),

    'situation': flow(function * (m) {
      bot.disableUserInput();
      SHOULD_LOG() && console.log('bot received content for situation field; replying...');
      SHOULD_LOG() && console.log('all user messages:', toJS(s.memory.situation.userMessages));
      const consolidatedMessages = joinMessages(s.memory.situation.userMessages);
      s.form.set('situation', consolidatedMessages);
      s.form.set('timeCreated', getNowTimestampUtc());
      if (Math.random() > .5) {
        yield bot.sendBotMessage('Thank you.');
      }
      yield tick(500, 1000);
      yield bot.sendBotMessage('What was your response to this situation?');

      yield s.saveLocally();
      s.expect('response');
      bot.enableUserInput();

    }),

    'response': flow(function * (m) {
      bot.disableUserInput();
      SHOULD_LOG() && console.log('bot received content for response field; replying...');
      SHOULD_LOG() && console.log('all user messages:', toJS(s.memory.response.userMessages));
      const consolidatedMessages = joinMessages(s.memory.response.userMessages);
      s.form.set('response',consolidatedMessages);
      s.form.set('timeCreated', getNowTimestampUtc());
      SHOULD_LOG() && console.log(s.form.value);
      yield s.saveLocally();
      yield bot.sendBotMessage('Thanks again for sharing your thoughts. One moment, I\'m just saving your thought to your Thought Catcher...');
      concludeConversation();
    }),

    'setDefaultState': flow(function * (m) {
      const { AUTH } = bot.rootController?.children || {};
      if (!AUTH?.currentUser) throw Error('No user found in AUTH, cannot update user thought state preference.');
      setValueOfKey(
        AUTH.currentUser.preferences,
        'preferredThoughtState',
        s.form.set('isPrivate', s.form.value.isPrivate ? true : false)
      );
      yield AUTH.saveCurrentUser();
    }),

  }

  const doYesNoAnalysis = flow(function * (m: BotChatMessage) {
    if (m.isAutomated) return null;
    const yesNo = matchYesNoCommand(m?.body as string);
    SHOULD_LOG() && console.log(yesNo);
    yield tick(500);
    if (yesNo === 'no') abort();
    if (yesNo === 'yes') yield bot.sendBotMessage("I'm listening.");
    return yesNo;
  });

  const doChangeThoughtStateCommandAnalysis = flow(function * (m: BotChatMessage) {

    const userWantsToChangeThoughtState = matchChangeThoughtStateCommand(m.body as string);
    SHOULD_LOG() && console.log(userWantsToChangeThoughtState);
    if (!userWantsToChangeThoughtState?.newState) return;
    const { newState } = userWantsToChangeThoughtState;
    const newStateBoolean = newState === 'private' ? true : false;
    if (newStateBoolean === s.form.value.isPrivate) return;
    s.form.value.isPrivate = newStateBoolean;

    yield tick(700);

    bot.sendBotMessage([
      `OK, I've changed your thought to ${newState}.`,
      s.form.value.isPrivate ? 'Your thought will only be visible to yourself and qualified turn2me counsellors.' : 'Your thought will be viewable by other turn2me users once posted.'
    ].join(' '));

    yield tick(1000);

    // if need to introduce option for user to default to private thoughts
    // yield bot.sendBotMessage(`Would you like me to always post thoughts as ${newState} in the future?`);
    // const lastUserMessageId = bot.lastUserMessage?.id;
    // yield when(() => Boolean(bot.lastUserMessage && lastUserMessageId !== bot.lastUserMessage.id));

    restateQuestion();

    return true;

  })

  const analyzer = flow(function * (m: Nillable<BotChatMessage>) {

    if (!m) return;
    SHOULD_LOG() && console.log('Thought Catcher bot analyzing user message: ', toJS(m));

    const thoughtStatusChanged = yield doChangeThoughtStateCommandAnalysis(m);
    if (thoughtStatusChanged) return;

    const userRepliedYesOrNo = yield doYesNoAnalysis(m);
    if (userRepliedYesOrNo) return;

    if (!s.expectation) {
      concludeConversation();
      return;
    }

    const subAnalyzer = subAnalyzers[s.expectation];
    if (!subAnalyzer) throw Error('Bot has ran into an unexpected error... no sub analyzer found to understand user message!');
    s.memory[s.expectation]?.userMessages?.push(m);
    bot.waitForUserToStopTyping(subAnalyzer);

  })

  const restateQuestion = () => {
    switch (s.expectation) {
      case 'content': {
        bot.sendBotMessage('So, what has been on your mind?'); break;
      }
      case 'situation': {
        bot.sendBotMessage('So would you like to tell me a little more about what happened?'); break;
      }
      case 'response': {
        bot.sendBotMessage('So how did you respond to this situation?'); break;
      }
    }
  }

  const concludeConversation = async () => {
    try {
      await s.saveToAPI();
      if (s.user) {
        if (!s.form.value.content) {
          await bot.sendBotMessage('I have saved your mood of the day.');
        } else {
          await bot.sendBotMessage('I have saved your thought in your journal so you can track your thoughts and mood changes though time.');
        }
        await tick(2000);
      } else {
        await bot.sendBotMessage('Once you sign up for a turn2me account from this device, you can review this thought and post it then so you can track your thoughts and mood changes though time.');
        bot.sendBotMessage('Whenever you are ready, create a new account on turn2me and I will see you again.')
      }
    } catch (e) {
      await bot.sendBotMessage('Hmm... I am having difficulty communicating with the server.');
      if (s.user) {
        await bot.sendBotMessage('I will continue to attempt saving the thought. As long as you stay logged in, the thought you have written will be safe and will be retried at a later time.');
      } else {
        await bot.sendBotMessage('I will continue to attempt saving the thought until this is resolved.');
        bot.sendBotMessage('Whenever you are ready, create a new account on turn2me and I will see you again.')
      }
      reportError(e);
    } finally {
      s.expect(null);
      goodbye();
    }
    // bot.enableUserInput();
  }

  const goodbye = async () => {
    bot.sendBotMessage(getGoodbyeTimeOfDay(s.user), 0);
    bot.end();
    await tick(3000);
  }

  const abort = flow(function * () {
    SHOULD_LOG() && console.log('should abort');
    bot.disableUserInput();
    if (s.userHasPositiveMood) {
      yield bot.sendBotMessage('No problem. Great that you are feeling happy!');
    } else if (s.userHasNegativeMood) {
      yield bot.sendBotMessage('No problem. Hope you feel better soon. If you need help at any time, you can always find a support group or talk to us.');
    } else {
      yield bot.sendBotMessage('No problem at all.');
    }
    concludeConversation();
  })

  disposers.push(reaction(
    () => s.user?.preferences?.preferredThoughtState,
    value => {
      if (value) {
        s.form.set('isPrivate', value === 'private');
      }
    }
  ))

  disposers.push(reaction(
    () => !bot.ended && bot.lastUserMessage,
    () => analyzer(bot.lastUserMessage),
    { fireImmediately: true }
  ))

  disposers.push(reaction(
    () => s.welcomeSent && s.userMood,
    flow(function * (e) {
      if (!e) return;
      yield tick(1000, 1500);
      if (!s.form.value.content) {
        sendEmotionResponse(e);
      }
    }),
    { fireImmediately: true }
  ))

  return s as ThoughtCatcherBotAnalyzer;

}
