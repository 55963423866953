
import { flow, runInAction, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { ColorCodedState } from '../../base/@types';
import AppPage from '../../base/components/AppPage/AppPage';
import AppPageContent from '../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../base/components/AppPageHeader/AppPageHeader';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseGrid from '../../base/components/BaseGrid/BaseGrid';
import BaseGridCell from '../../base/components/BaseGrid/BaseGridCell';
import CommandList from '../../base/components/CommandList/CommandList';
import { CommandListItem } from '../../base/components/CommandList/CommandListItem';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import LoadingBlocker from '../../base/components/LoadingBlocker/LoadingBlocker';
import OverlayCloseButton from '../../base/components/OverlayCloseButton/OverlayCloseButton';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import UIBlock from '../../base/components/UIBlock/UIBlock';
import { CounsellingSessionEndpoints, DefaultCounsellingSessionIncludesForStaff } from '../../base/endpoints/counsellingSession.endpoints';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers, useOverlayController } from '../../base/hooks/useRootController.hook';
import { makeCancelAction } from '../../base/utils/actionConfig.utils';
import { addToArrayIfNew, keepTruthy } from '../../base/utils/array.utils';
import { reportError } from '../../base/utils/errors.utils';
import { generateUuid } from '../../base/utils/id.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { compareShallowEqualBySelectedKeys, copyWithJSON } from '../../base/utils/object.utils';
import { uniqById } from '../../base/utils/ramdaEquivalents.utils';
import { autoPluralize } from '../../base/utils/string.utils';
import { createUTCMoment, getDurationFromUTCTimeStamps, getNowTimestampUtc, millisecondsToMinutes } from '../../base/utils/time.utils';
import { getUrlParams, setUrlParam, useGetUrlParams, useSyncUrlParams } from '../../base/utils/urlParams.utils';
import { ApiModelName } from '../../constants/ApiModels.enum';
import { CommunicationType } from '../../constants/communicationTypes.descriptors';
import { ModelName } from '../../constants/modelNames.enum';
import { CounsellingApplication } from '../../models/makeCounsellingApplication.model';
import { CounsellingSession, CounsellingSessionSnapshot } from '../../models/makeCounsellingSession.model';
import { Invoice } from '../../models/makeInvoice.model';
import { User } from '../../models/makeUser.model';
import SurveyAssignmentListForAdmin from '../../modules/Admin/_components/SurveyAssignmentListForAdmin/SurveyAssignmentListForAdmin';
import { getCounsellingApplication } from '../../requests/getCounsellingApplication.request';
import { getCounsellingSession } from '../../requests/getCounsellingSession.request';
import { createInvoiceItem } from '../../requests/invoicing.requests';
import { useGetUser } from '../../requests/useGetUser.request';
import { canMarkAsBillable, getBillingPeriod, getSessionBillableAmount, getSessionInvoiceItemFeeType } from '../../utils/invoices.utils';
import CounsellingSessionCancelledManager from '../CounsellingSessionCancelledManager/CounsellingSessionCancelledManager';
import CounsellingSessionEditor from '../CounsellingSessionEditor/CounsellingSessionEditor';
import CounsellingSessionPriceSetterForm from '../CounsellingSessionPriceSetterForm/CounsellingSessionPriceSetterForm';
import CounsellingSessionStarter from '../CounsellingSessionStarter/CounsellingSessionStarter';
import IrishClientFreeSessionInfo from '../IrishClientFreeSessionInfo/IrishClientFreeSessionInfo';
import SlotTypeRenderer from '../SlotTypeRenderer/SlotTypeRenderer';
import './OverlayCounsellingSessionManager.scss';

interface OverlayCounsellingSessionManagerProps {
  application?: CounsellingApplication | null,
  applicationId?: string,
  client?: User,
  userId?: string,
  session?: CounsellingSession,
  sessionId?: string,
  onSessionCreateEditDelete?: () => void,
}

export const ID_OverlayCounsellingSessionManager = "OverlayCounsellingSessionManager";

const OverlayCounsellingSessionManager: React.FC<OverlayCounsellingSessionManagerProps> = props => {

  const p = useProps(props);
  const { UI, API, NAVIGATOR, LOCALDB, AUTH, STAFF } = useControllers();
  const OVERLAY = useOverlayController();

  const { manageSessionId } = useGetUrlParams();

  const s = useStore(() => ({
		get isCounsellor() {
			return AUTH.isCounsellor;
		},
    id: generateUuid(),
    originalCopy: copyWithJSON(p.session?.$getSnapshot()) as CounsellingSessionSnapshot | undefined,
    _session: p.session as CounsellingSession | undefined,
    get session() {
      if (!p.session?.id && s._session?.id) return s._session;
      const shouldUsePropsSession = p.session && (p.session.threads || !p.session.id);
      return shouldUsePropsSession ? p.session : s._session;
    },
    _client: p.session?.applicant as User | undefined,
    get client() {
      return p.client || s._client;
    },
    get application() {
      return p.application ?? s.session?.application ?? (s.applicationId ? LOCALDB.get<CounsellingApplication>(ModelName.counsellingApplications, s.applicationId) : null);
    },
    get sessionId() {
      return p.sessionId || s.session?.id || manageSessionId;
    },
    get applicationId() {
      return p.applicationId || s.session?.applicationId;
    },
    get preTitle() {
      return <>
        {`${s.applicationId ? `Application #${s.applicationId} \\ ` : ''}${s.sessionId ? `Session #${s.sessionId}` : 'New Session'}`}
        {s.session && <>
          {" • "}
          <SlotTypeRenderer isPaid={s.session?.isPaidSession} />
        </>}
      </>;
    },
    get title() {
      return !!s.sessionId ? 'Counselling Session' : 'Schedule New Session';
    },
    get hasNoChanges() {
      return !s.session || (
        s.originalCopy && compareShallowEqualBySelectedKeys(
          s.originalCopy, s.session.$snapshot,
          ['counsellorId', 'timeScheduled', 'sessionNotes', 'type']
        )
      );
    },
    get currentUserIsAssignedCounsellor() {
      return AUTH.currentUser && s.session?.counsellorId === AUTH.currentUser.id;
    },
    get currentUserCanManageInvoice() {
      return STAFF.can?.manage_.staffInvoicing;
    },
    get isNew() {
      return !s.sessionId;
    },
    get canRefresh() {
      return !s.isNew;
    },
    /** FIXME */
    get hasChanges() {
      return false;
    },
    get sessionObjForPriceSetterForm() {
      return s._session || p.session;
    },
    get hasStarted() {
      return s.session?.timeStarted
    },
    get canDelete() {
      return s.session && s.sessionId && !s.hasStarted && !s.application?.timeArchived
    },
    deleteSession: () => new Promise<boolean>(
      flow(function* (resolve, reject) {
        if (!s.sessionId) {
          reject('Session ID not found. Has this session been created?')
          return;
        }
        const confirm = yield UI.DIALOG.attention({
          heading: "Are you sure you want to delete this session?",
          body: 'The client will be notified of this as a session cancellation.',
          defaultActions: ['negative', 'positive'],
        })
        if (!confirm) {
          resolve(false);
          return;
        }
        const url = CounsellingSessionEndpoints.staff.delete(s.sessionId);
        const now = getNowTimestampUtc();
        try {
          yield API.delete(url, ModelName.counsellingSessions, s.session);
          s.session && (s.session.timeDeleted = now);
          p.session && (p.session.timeDeleted = now);

          // update index directory.
          p.onSessionCreateEditDelete?.();
          resolve(true);
        } catch(e) {
          reject(e);
          UI.DIALOG.error({
            heading: 'Failed to delete the session.',
            body: <ErrorRenderer error={(e as any).response} />
          })
        }
      })
    ),
    get canViewApplication() {
      return s.sessionId && !OVERLAY.hasOverlayById(`OverlayApplicationManager#${s.applicationId}`);
    },
    viewApplication() {
      setUrlParam('manageApplicationId', s.applicationId, NAVIGATOR);
    },
    isFetchingData: Boolean(p.session?.id || p.sessionId),
    dataFetched: false,
    get currentBillingPeriod() {
      return getBillingPeriod()
    },
    get isInCurrentBillingPeriod() {
      return s.session?.timeEnded && createUTCMoment(s.session.timeEnded).isSame(createUTCMoment(s.currentBillingPeriod.start), 'month');
    },
    get invoices() {
      return uniqById(keepTruthy(s.session?.invoiceItems.map(i => i.invoice) ?? []) as Invoice[])
    },
    get paymentOrDonationText() {
      return s.session?.isPaidSession ? 'Payment' : 'Donation';
    },
    get canMarkAsBillable() {
      if (!s.session) return false;
      return canMarkAsBillable(s.session, AUTH);
    },
    get sidebarUI() {
      return <>
        <UIBlock title="Questionnaires">
          <SurveyAssignmentListForAdmin
            assignments={s.session?.assignments}
            application={s.application}
          />
        </UIBlock>

        {s.session && <UIBlock title={s.isNew ? `Suggest a ${s.paymentOrDonationText}` : `${s.paymentOrDonationText}`}>
          {s.session.applicantId && s.application && !s.application.isPaid && <IrishClientFreeSessionInfo application={s.application} session={s.session} clientId={s.session.applicantId} />}
          <CounsellingSessionPriceSetterForm session={s.session} application={s.application} disabled={s.session.timeStarted || s.application?.timeCompleted || s.application?.timeArchived} />
        </UIBlock>}

        { s.session?.timeEnded && (s.currentUserIsAssignedCounsellor || s.currentUserCanManageInvoice) && (
          <UIBlock title="Billing">
            { s.session.invoiceItems.length > 0 ? <ShadedBlock spaceChildren>
              <p>This session is marked as billable.</p>
              <BaseButton key="viewInvoice" onClick={viewInvoice} title="View invoice" fullWidth color="green" label={`View the ${ autoPluralize(s.invoices, 'invoice', 'invoices', 'invoices', true) }`} />
            </ShadedBlock> : <ShadedBlock spaceChildren>
              <p>This session was not marked as billable when the session ended.</p>
                <BaseButton key="markAsBillable" fullWidth color="green" onClick={markAsBillable} label="Mark as billable" disabled={!s.canMarkAsBillable} />
            </ShadedBlock>}
          </UIBlock>
        )}

        { (s.isCounsellor) && (s.canViewApplication || s.canDelete || s.canRefresh) && <UIBlock title="More Actions">
          <CommandList withBackground>
            {s.canRefresh && <CommandListItem icon="refresh" onClick={getSessionData}>Refresh</CommandListItem> }
            {s.canViewApplication && <CommandListItem icon="arrow" onClick={s.viewApplication}
            >View application</CommandListItem>}
            {s.canDelete && (
              <CommandListItem icon="delete" color="red"
                onClick={s.deleteSession}
              >Delete session</CommandListItem>
            )}
          </CommandList>
        </UIBlock>}
      </>
    }
  }));

  const getSessionData = flow(function * () {
    yield when(() => !!s.sessionId);
    try {
      const session = yield getCounsellingSession(API, s.sessionId!, {
        include: DefaultCounsellingSessionIncludesForStaff
      });
      s.originalCopy = copyWithJSON(session.$getSnapshot());
      s._session = session;
      s.dataFetched = true;
      s.isFetchingData = false;
    } catch(e) {
      reportError(e);
      s.dataFetched = false;
      s.isFetchingData = false;
    }
  })

  useGetUser({observable: s, key: '_client'}, s.application?.applicantId);

  useSyncUrlParams('manageSessionId', s.sessionId);

  const onBeforeClose = () => new Promise<boolean>((resolve, reject) => {
    // todo: check dirty
    resolve(true);
  })

  useOnMount(() => {
    const disposers: Function[] = [];
    if (!s.application) {
      disposers.push(when(() => Boolean(s.applicationId), () => {
        getCounsellingApplication(s.applicationId!, API, undefined, true);
      }))
    }
    disposers.push(when(
      () => Boolean(s.session?.timeDeleted),
      () => {
        UI.TOAST.success('The session has been deleted.');
        UI.OVERLAY.dismiss(`OverlayCounsellingSessionManager#${s.sessionId}`);
      }
    ))
    if (!s.isNew) {
      getSessionData();
      runInAction(() => {
        s.isFetchingData = false;
      })
    }
    return () => disposers.forEach(d => d());
  })

  const handleSaveSuccess = flow(function * (session: CounsellingSession) {
    if (s.isNew) {
      s._session = yield getCounsellingSession(API, session.id, { include: DefaultCounsellingSessionIncludesForStaff }, 'staff');

      // update index directory.
      p.onSessionCreateEditDelete?.();
    }
    s.originalCopy = s.session?.$getSnapshot();
  })

  const markAsBillable = async () => {
    if (!s.sessionId || !s.session?.counsellorId) return;
    if (s.session.type !== CommunicationType.Email) {
      const duration = getDurationFromUTCTimeStamps(s.session.timeStarted, s.session.timeEnded);
      if (millisecondsToMinutes(duration) < 15) {
        const confirm = await UI.DIALOG.present({
          heading: "This session is under 15 minutes",
          body: <p>This session appears to be quite short and is possibly not a valid session for payment. Are you sure you want to mark this session as billable?</p>,
          colorCodedState: ColorCodedState.attention,
        })
        if (!confirm) return;
      }
    }
    if (!s.session.hasAnyClientAttended) {
      const confirm = await UI.DIALOG.present({
        heading: 'Marking this session as billable',
        body: <div>
          <p>The "User attended session" checkbox is not ticked.</p>
          <p>To continue marking this session as billable, we will mark this session as "user has attended". Only valid sessions conducted with clients should be marked as billable.</p>
        </div>,
        colorCodedState: ColorCodedState.attention,
      })
      if (!confirm) return;
      const payload: Partial<CounsellingSessionSnapshot> = { hasAnyClientAttended: true };
      const url = CounsellingSessionEndpoints.staff.update(s.sessionId);
      try {
        await API.patch(url, ModelName.counsellingSessions, payload);
      } catch(e) {
        reportError(e);
        UI.DIALOG.present({
          heading: "Failed to update the session",
          body: <div>
            <p>Please try again later. Error details:</p>
            <ErrorRenderer error={e} />
          </div>,
          colorCodedState: ColorCodedState.error
        })
      }
    }
    const invoiceItem = await createInvoiceItem(API, s.session.counsellorId, ApiModelName.COUNSELLING_SESSION, s.session.id, getSessionBillableAmount(s.session), getSessionInvoiceItemFeeType(s.session));
    addToArrayIfNew(s.session.invoiceItems, invoiceItem);
  }

  const viewInvoice = async () => {
    if (s.invoices.length === 0) {
      UI.DIALOG.present({
        heading: "This session is not linked to any invoices."
      })
      return;
    }
    if (s.invoices.length === 1) {
      const { invoiceId } = getUrlParams();
      if (invoiceId === s.invoices[0].id) {
        await UI.OVERLAY.dismiss(`OverlayInvoiceManager#${s.invoices[0].id}`);
      }
      NAVIGATOR.setUrlParam('invoiceId', s.invoices[0].id);
      return;
    }
    UI.DIALOG.present({
      heading: "This session is linked to multiple invoices.",
      body: 'Select a billing period to view the invoice.',
      actions: [
        makeCancelAction(),
        ...s.invoices.map(inv => ({
          label: moment(inv.billingPeriodStartDate).format('MMM YYYY'),
          action: async () => {
            const { invoiceId } = getUrlParams();
            if (invoiceId === inv.id) {
              await UI.OVERLAY.dismiss(`OverlayInvoiceManager#${inv.id}`);
            }
            NAVIGATOR.setUrlParam('invoiceId', inv.id)
          }
        }))
      ]
    })
  }

  return <Observer children={() => (
    <AppPage className="OverlayCounsellingSessionManager" color="deepPurple" key={s.id}>
      <AppPageHeader
        beforeTitle={s.preTitle}
        title={s.title}
        afterTitle={<>
          {s.application?.timeArchived && 'This session is no longer editable as the application it belongs to have been archived.'}
        </>}
        endSlot={<OverlayCloseButton onBeforeClose={onBeforeClose} />}
      />
      <AppPageContent>

        <UIBlock padded>

          <BaseGrid columns={UI.fromTablet ? 3 : 1} gap="1em">

            <BaseGridCell columns={UI.fromTablet ? 2 : 1}>

              <UIBlock title="Session Details" spaceChildren>
                { s.hasChanges && <ShadedBlock color="attention" spaceChildren>
                  <h4>You have unsaved changes.</h4>
                </ShadedBlock>}
                {s.originalCopy && s.session && !s.session.isCancelled && !s.hasChanges && !s.application?.isArchived && !s.application?.isCompleted && <CounsellingSessionStarter application={s.application} session={s.session} client={s.client} />}
                {s.session?.isCancelled && <CounsellingSessionCancelledManager session={s.session} />}
                { UI.onlyPhones && s.sidebarUI }
                {s.session && s.application && <CounsellingSessionEditor session={s.session!} client={s.client} onSaveSuccess={handleSaveSuccess} application={s.application} />}
              </UIBlock>

            </BaseGridCell>

            { UI.fromTablet && <BaseGridCell columns={1}>
              { s.sidebarUI }
            </BaseGridCell> }

          </BaseGrid>

        </UIBlock>

        {
          s.isFetchingData && <LoadingBlocker />
        }

      </AppPageContent>
    </AppPage>
  )}/>
}

export default OverlayCounsellingSessionManager;