import { Observer } from 'mobx-react-lite';
import React from 'react';
import { getColorHexByName } from '../../base/utils/colors.utils';
import { Invitation } from '../../models/makeInvitation.model';
// import './InviteeConsentStatusDisplayer.scss';

interface InviteeConsentStatusDisplayerProps {
  invitation?: Invitation,
}

const map = {
  consented: {
    'dependent-child': 'Child accepted invitation',
    'legal-guardian': 'Legal guardian consented',
    'partner': 'Partner consented',
},
  notConsented: {
    'dependent-child': 'Pending child invitation',
    'legal-guardian': 'Awaiting legal guardian consent',
    'partner': 'Awaiting partner\'s consent',
  }
}

const InviteeConsentStatusDisplayer: React.FC<InviteeConsentStatusDisplayerProps> = props => {
  const { invitation } = props;
  const { actingAs, timeAccepted } = invitation || {};
  const consented = Boolean(timeAccepted);
  const color = consented ? getColorHexByName('blueGreen') : undefined;
  const opacity = consented ? 1 : .5;
  const consentNeeded = invitation?.email && (invitation.actingAs === 'partner' || invitation.actingAs === 'dependent-child');
  const label = consentNeeded ? actingAs ? map[consented ? 'consented' : 'notConsented'][actingAs] : '' : '';
  return <Observer children={() => (
    label ? <span className="InviteeConsentStatusDisplayer" style={{ color, opacity }}>
      {label}
    </span> : null
  )} />
}

export default InviteeConsentStatusDisplayer;