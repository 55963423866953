import { always } from "../../../../../base/utils/ramdaEquivalents.utils";
import { makeObservableRouteDef } from "../../../../../base/utils/routes.utils";
import AccountPageSupportGroups from "./AccountPageSupportGroups.page";

export const AccountPageSupportGroupsRouteDef = makeObservableRouteDef({
  identifier: 'account-support-groups',
  urlFactory: always('support-groups'),
  composeTitle: always('Support Group History'),
  component: AccountPageSupportGroups,
  icon: 'people',
  primaryColorName: 'blueGreen',
})