import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { TimezoneMode } from '../../@types/time.types';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { createMomentFn, isThisYear, isToday, isTomorrow, isYesterday } from '../../utils/time.utils';
import './DateRenderer.scss';

export interface DateRendererProps {
  className?: string,
  value?: string | Date | moment.Moment | null,
  format?: string,
  emptyValue?: string,
  markIfToday?: boolean,
  timeOnlyIfToday?: boolean,
  timeOnlyIfYesterday?: boolean,
  timeOnlyIfTomorrow?: boolean,
  markIfTomorrow?: boolean,
  timezoneMode?: TimezoneMode,
}

const DateRenderer: React.FC<DateRendererProps> = props => {

  const p = useProps(props);

  const _createMoment = createMomentFn(p.timezoneMode ?? 'auto');

  const s = useStore(() => ({
    get value() {
      return p.value;
    },
    get valueIsValid() {
      const dateStartWithNumber = (p.value + '')[0]?.match(/^\d/);
      const dateDoesNotStartWithZero = (p.value + '')[0] !== '0';
      return dateStartWithNumber && dateDoesNotStartWithZero;
    },
    get moment() {
      return p.value && s.valueIsValid ? _createMoment(p.value) : null;
    },
    get dateTime() {
      return s.moment ? s.moment.toISOString() : undefined;
    },
    get format() {
      return p.format || (isThisYear(s.value) ? 'MMM D, HH:mm' : 'MMM D YYYY, HH:mm');
    },
    get momentIsValid() {
      return s.moment && s.moment.isValid() && s.moment.year() !== 0;
    },
    get isToday() { return isToday(s.moment) },
    get isYesterday() { return isYesterday(s.moment) },
    get isTomorrow() { return isTomorrow(s.moment) },
    get formattedTime() { return moment(s.moment).local().format('HH:mm') },
    get displayValue() {
      if (p.value && !s.valueIsValid) return 'Invalid Date';
      if (p.timeOnlyIfToday && s.isToday) { return 'Today, ' +  s.formattedTime}
      if (p.timeOnlyIfYesterday && s.isYesterday) { return 'Yesterday, ' +  s.formattedTime}
      if (p.timeOnlyIfTomorrow && s.isTomorrow) { return 'Tomorrow, ' +  s.formattedTime}
      const value = s.moment ? (
        s.momentIsValid ? moment(s.moment).local().format(s.format) : p.value
      ) : (
        p.emptyValue || ''
      );
      return value === '0000-00-00' ? '' : value;
    },
    get showAsValid() {
      return !!((s.valueIsValid && s.momentIsValid) || !p.value);
    },
  }));

  return <Observer children={() => (
    <time className={joinClassName('DateRenderer', p.className)} dateTime={s.dateTime} data-valid={s.showAsValid} data-value={p.value}>
      { s.displayValue }
      { p.markIfToday && s.isToday && <em> (Today)</em>}
      { p.markIfTomorrow && s.isTomorrow && <em> (Tomorrow)</em>}
    </time>
  )} />
}

export default DateRenderer;