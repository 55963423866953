import Color from 'color';
import { Observer } from 'mobx-react-lite';
import React, { CSSProperties } from 'react';
import ColorTagLive from '../../base/components/ColorTag/ColorTagLive';
import { ContextColor } from '../../base/constants/color.enum';
import { useControllers } from '../../base/hooks/useRootController.hook';
import joinClassName from '../../base/utils/className.utils';
import { getContextColorStyle, getContrastColorHex, isHexCode } from '../../base/utils/colors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { createUTCMoment } from '../../base/utils/time.utils';
import { setUrlParam } from '../../base/utils/urlParams.utils';
import { getCounsellingTypeColorHex } from '../../constants/counsellingTypes.descriptors';
import { ModelName } from '../../constants/modelNames.enum';
import { CLOCK } from '../../controllers/common/clock.controller';
import { CounsellingApplication } from '../../models/makeCounsellingApplication.model';
import { CounsellingSession } from '../../models/makeCounsellingSession.model';
import SessionOrGroupTimeDisplay from '../SessionOrGroupTimeDisplay/SessionOrGroupTimeDisplay';
import SmallHeaderButton from '../SmallHeaderButton/SmallHeaderButton';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import SessionSummaryAssignmentList from './SessionSummaryAssignmentList';
import './SessionSummaryCard.scss';

interface SessionSummaryCardProps {
  session: CounsellingSession,
  application?: CounsellingApplication,
  color?: string,
  cardRatio?: number,
  standalone?: boolean,
}

const SessionSummaryCard: React.FC<SessionSummaryCardProps> = props => {

  const { AUTH, NAVIGATOR, LOCALDB } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    get nth() {
      return (s.application?.sortedSessions?.indexOf(p.session) ?? 0) + 1;
    },
    get application() {
      return p.application ?? LOCALDB.get<CounsellingApplication>(ModelName.counsellingApplications, p.session.applicationId);
    },
    get nextSession() {
      return s.application?.nextSession;
    },
    get scheduledStartTimeMoment() {
      return createUTCMoment(p.session.timeScheduled).local();
    },
    get isToday() {
      return s.scheduledStartTimeMoment.isSame(CLOCK.localNowMoment, 'day');
    },
    get isInThePast() {
      return s.scheduledStartTimeMoment.isBefore(CLOCK.localNowMoment);
    },
    get isUpcoming() {
      return s.nextSession === p.session || (s.isToday && !p.session.timeEnded);
    },
    get isCancelled() {
      return p.session.isCancelled;
    },
    get clientAccentColor() {
      return p.session.clients[0]?.color;
    },
    get color() {
      return p.color ?? (NAVIGATOR.isInAdminArea ? s.clientAccentColor : getCounsellingTypeColorHex(s.application?.type));
    },
    get innerStyle(): CSSProperties {
      return !s.isCancelled && s.isUpcoming ? isHexCode(s.color) ? {
        backgroundColor: s.color ?? undefined,
        backgroundImage: s.color ? `linear-gradient(133deg, ${Color(s.color).lighten(.1).hex()}, ${Color(s.color).alpha(1).rgb().toString()})` : undefined,
        color: getContrastColorHex(s.color) ?? undefined,
      } : getContextColorStyle(ContextColor.Primary, s.color) : {};
    },
    get isOwnSession() {
      return AUTH.currentUser?.id && p.session.clientIds.includes(AUTH.currentUser.id);
    },
    get shouldOpenInManageView() {
      return NAVIGATOR.isInAdminArea || (!s.isOwnSession && (
        AUTH.can.provideCounsellingFor_.someUserGroups ||
        AUTH.can.coordinate_.counsellingApplications ||
        AUTH.can.coordinate_.counsellingSessions
      ))
    },
  }));

  const openDetailsOverlay = () => {
    setUrlParam(s.shouldOpenInManageView ? 'manageSessionId' : 'sessionId', p.session.id, NAVIGATOR);
  }

  return <Observer children={() => (
    <div className={
      joinClassName(
        'SessionSummaryCard',
        !s.isCancelled && s.isUpcoming && 'upcoming',
        !s.isCancelled && s.isInThePast && 'inThePast',
        s.isCancelled && 'cancelled',
      )
    } onClick={openDetailsOverlay} tabIndex={0}>
      <div className="SessionSummaryCardInner" style={s.innerStyle}>
        <header className="SessionSummaryCardHeader">
          <div className="SessionSummaryCardHeaderInner">
            <h3 className="SessionSummaryCardTitle">{p.session.timeStarted && !p.session.timeEnded && <><ColorTagLive /> </>} {p.standalone ? <>Counselling Session</> : `Session ${s.nth}`}</h3>
            {p.session.clients.length > 0 && p.standalone && <div className="SessionSummaryCardClientDetails">
              With <UsernameRenderer user={p.session.clients[0]} showColorLabel={p.session.clients.length > 1} />
              {p.session.clients.length > 1 && <> and <UsernameRenderer user={p.session.clients[1]} /></>}
            </div>}
          </div>
          <SmallHeaderButton color={s.isUpcoming ? 'white' : 'brightPurple'} />
        </header>
        <SessionOrGroupTimeDisplay className="SessionSummaryCardTimeDisplay" model={p.session} />
        <SessionSummaryAssignmentList
          session={p.session}
          application={p.application}
        />
      </div>
    </div>
  )} />
}

export default SessionSummaryCard;