import React from "react"
import OverlayCounsellingSessionManager from "../components/OverlayCounsellingSessionManager/OverlayCounsellingSessionManager"
import { UIController } from "../controllers/ui.controller"
import { CounsellingApplication } from "../models/makeCounsellingApplication.model"
import { CounsellingSession } from "../models/makeCounsellingSession.model"
import { User } from "../models/makeUser.model"

export const openOverlayCounsellingSessionManager = (
  UI: UIController,
  options: {
    session?: CounsellingSession,
    sessionId?: string,
    application?: CounsellingApplication,
    applicationId?: string,
    client?: User,
    onAfterClose?: () => unknown,
  }
) => {
  UI.OVERLAY.present({
    id: `OverlayCounsellingSessionManager#${options.session?.id ?? options.sessionId}`,
    name: `OverlayCounsellingSessionManager`,
    component: <OverlayCounsellingSessionManager
      session={options.session}
      sessionId={options.sessionId}
      application={options.application}
      applicationId={options.applicationId}
      client={options.client}
    />,
    width: '66.67em',
    onAfterClose: options.onAfterClose,
    duplicateStrategy: 'abort',
    noBackdrop: true,
  })
}