import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { CLOCK } from '../../../controllers/common/clock.controller';
import { DateUnit, DateUnitPlural } from '../../@types/time.types';
import { useProps, useStore } from '../../utils/mobx.utils';
import { YYYYMMDD } from '../../utils/time.utils';
import BaseButton from '../BaseButton/BaseButton';
import { IconVariant } from '../Symbols/iconDefs';
import './TimeframePicker.scss';

interface P {
  startDate?: string;
  unitsPerView?: number;
  unit?: DateUnit | DateUnitPlural;
  onNavigate: (newStartDate: string) => void;
  iconVariant?: IconVariant;
  icon?: 'arrow' | 'chevron';
  canNotNavigateToPast?: boolean,
}

const TimeframePicker: React.FC<P> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    get startDateMoment() {
      return moment(p.startDate);
    },
    get endDateMoment() {
      return moment(p.startDate).add(s.unitsPerView - 1, s.unit).endOf(s.unit);
    },
    get monthTitle() {
      switch (p.unit) {
        case 'day': case 'days': return s.startDateMoment.format('MMMM D,');
        case 'week': case 'weeks': {
          if (s.startDateMoment.isSame(s.endDateMoment, 'month')) {
            return `${s.startDateMoment.format('MMMM D')}–${s.endDateMoment.format('D,')}`;
          } else {
            return `${s.startDateMoment.format('MMMM D')}–${s.endDateMoment.format('MMMM D,')}`;
          }
        }
        case 'month': case 'months': return s.startDateMoment.format('MMMM');
        default: return undefined;
      }
    },
    get year() {
      return s.startDateMoment.year();
    },
    get unitsPerView() {
      return p.unitsPerView || 1;
    },
    get unit() {
      return p.unit || 'month';
    },
    navigate: action((count: number = 1) => {
      const newStartDate = moment(p.startDate).add(count, p.unit || 'days').format(YYYYMMDD);
      p.onNavigate && p.onNavigate(newStartDate);
    }),
    get isCurrentWeek() {
      return s.startDateMoment.isSame(CLOCK.localNowMoment, 'week');
    }
  }));

  const goBack = () => s.navigate(s.unitsPerView * -1);
  const goForward = () => s.navigate(s.unitsPerView);

  return <Observer children={() => (
    <div className="TimeframePicker">
      <BaseButton
        className="TimeframePickerNavButton --back subtle"
        icon={p.icon === 'chevron' ? 'chevron-left' : 'back'}
        onClick={goBack}
        iconVariant={p.iconVariant}
        disabled={p.canNotNavigateToPast && s.isCurrentWeek}
      />
      <h2 className="TimeframePickerTitle">
        {s.monthTitle && <><strong>{s.monthTitle}</strong></>} <span>{s.year}</span>
      </h2>
      <BaseButton
        className="TimeframePickerNavButton --forward subtle"
        icon={p.icon === 'chevron' ? 'chevron-right' : 'arrow'}
        onClick={goForward}
        iconVariant={p.iconVariant}
      />
    </div>
  )} />
}

export default TimeframePicker;