import { flow } from "mobx";
import { ReactionEndpoints } from "../base/endpoints/reaction.endpoints";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { Reaction } from "../models/makeReaction.model";

export const deleteReaction = flow(function * (reaction: Reaction, API: APIController) {
  const endpoint = ReactionEndpoints.own.delete;
  const url = endpoint(reaction.id);
  yield API.delete(url, ModelName.reactions, reaction);
  return;
})