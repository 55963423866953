import { useElements, useStripe } from '@stripe/react-stripe-js';
import { SetupIntent, StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseInput from '../../base/components/BaseInput/BaseInput';
import BaseLabel from '../../base/components/BaseLabel/BaseLabel';
import BaseSpacer from '../../base/components/BaseSpacer/BaseSpacer';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { hasValidEmail } from '../../base/validators/email.validator';
import { Address } from '../../models/makeAddress.model';
import { asyncGetRecaptchaToken, checkErrorForRecaptchaFailure } from '../../utils/loadRecaptcha.utils';
import { createPaymentMethod } from '../../utils/payment.utils';
import BillingAddressForm from '../BillingAddressForm/BillingAddressForm';
import PaymentCardForm from '../PaymentCardForm/PaymentCardForm';
import './CreatePaymentMethodForm.scss';


interface CreatePaymentMethodFormProps {
  onSuccess?: () => unknown;
  requireBillingAddress?: boolean,
}

const CreatePaymentMethodForm: React.FC<CreatePaymentMethodFormProps> = props => {

  const p = useProps(props);

  const { AUTH, API, UI } = useControllers();

  const s = useStore(() => ({
    form: {
      cardHolderName: AUTH.currentUser?.fullName ?? '',
      cardHolderEmail: AUTH.currentUser?.email ?? '',
    },
    setupIntent: undefined as SetupIntent | undefined,
    lastEvent: undefined as StripeCardElementChangeEvent | undefined,
    selectedAddress: null as Address | null,
    get error() {
      return s.lastEvent?.error;
    },
    get completed() {
      return s.lastEvent?.complete;
    },
    get canSubmit() {
      return s.completed && !s.error && !!s.form.cardHolderName && hasValidEmail(s.form.cardHolderEmail) && (!p.requireBillingAddress || !!s.selectedAddress);
    },
  }));

  const onAddressChange = action((address: Address) => {
    s.selectedAddress = address;
  })

  const onCardChange = action((e?: StripeCardElementChangeEvent) => {
    s.lastEvent = e;
  } )

  const stripe = useStripe();
  const elements = useElements();

  const sendAddPaymentMethodRequest = () => new Promise<object>(async (resolve) => {
    resolve(createPaymentMethod(API, stripe, elements, s.form.cardHolderName, s.form.cardHolderEmail, s.selectedAddress));
  })
  const promptError = (e: Error | unknown) => {
    UI.DIALOG.error({
      heading: 'Failed to add card',
      body: 'We are unable to confirm this card. Please check the card details or try again with a different card.',
      error: e,
    })
  }

  const submit = () => new Promise<boolean>(async (resolve, reject) => {
    try {
      await asyncGetRecaptchaToken('stripe_add_payment_method', UI, API);
    } catch (e) {
      reject(e);
      checkErrorForRecaptchaFailure(e);
      UI.DIALOG.error({
        heading: 'You have not passed the Recaptcha test. Please try again.',
      })
      return;
    }
    try {
      const paymentMethod = await sendAddPaymentMethodRequest();
      if (paymentMethod) {
        resolve(true);
        p.onSuccess?.();
        UI.DIALOG.success({
          heading: `Successfully added your new card.`,
        })
      } else {
        promptError(Error('Failed to add the card, please try again later.'));
      }
    } catch(e) {
      reportError(e);
      reject(e);
      promptError(e);
    }
  })

  return <Observer children={() => (
    <div className="CreatePaymentMethodForm">
      <BaseInput label="Card Holder Name" form={s.form} field="cardHolderName" required />
      <BaseSpacer size='0.5em' />
      <BaseInput label="Card Holder Email" form={s.form} field="cardHolderEmail" required />
      <BaseSpacer size='0.5em' />
      {p.requireBillingAddress && <>
        <BillingAddressForm user={AUTH.currentUser} onChange={onAddressChange} />
        <BaseSpacer size='0.5em' />
      </>}
      <BaseLabel>Card Details</BaseLabel>
      <PaymentCardForm onChange={onCardChange} hideLabel />
      <BaseSpacer size='0.5em' />
      <BaseButton dataCy="add-card-submit-button" onClick={submit}
        disabled={!s.canSubmit}
        icon="plus"
        label="Add Card"
        color="green"
        fullWidth
      />
    </div>
  )} />
}

export default CreatePaymentMethodForm;