import { ChatThread, ChatThreadRelatedModels } from "../../models/makeChatThread.model";
import { DefaultIndexParamSet } from "../utils/api.utils";
import { makeApiEndpointConfig, makeApiGetEndpointConfig } from "../utils/endpoints.utils";
import { makeUrl } from "../utils/url.utils";
import { prefixOwn, prefixStaff } from "./namespacePrefixes";

export type ChatThreadEndpointParams = DefaultIndexParamSet<ChatThread, ChatThreadRelatedModels> & {
  latest?: boolean,
  own?: boolean,
  active?: boolean,
  include?: (keyof ChatThreadRelatedModels | string)[],
  filterOutEmail?: boolean,
  includeInstantThreads?: boolean,
}

const ownUrlBase = prefixOwn('chat-threads');
const staffUrlBase = prefixStaff('chat-threads');

export const ChatThreadEndpoints = {
  own: {
    index: makeApiEndpointConfig<ChatThreadEndpointParams>(ownUrlBase),
    get: makeApiGetEndpointConfig<ChatThreadEndpointParams>(ownUrlBase),
  },
  staff: {
    index: makeApiEndpointConfig<ChatThreadEndpointParams>(staffUrlBase),
    get: makeApiGetEndpointConfig<ChatThreadEndpointParams>(staffUrlBase),
    create: () => staffUrlBase,
    update: (id: string) => makeUrl(staffUrlBase, id),
    delete: (id: string) => makeUrl(staffUrlBase, id),
  },
}

export const ChatThreadEndpointDefaultInclude = ['participants.user', 'messages', 'users.permissions', 'model'];