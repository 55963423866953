import { useStripe } from '@stripe/react-stripe-js';
import { PaymentIntent, PaymentMethod } from '@stripe/stripe-js';
import { AxiosError } from 'axios';
import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject, Renderable } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseSpacer from '../../base/components/BaseSpacer/BaseSpacer';
import CurrencyRenderer from '../../base/components/CurrencyRenderer/CurrencyRenderer';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import { showCreatePaymentMethodOverlay } from '../../base/components/OverlayCreatePaymentMethodForm/OverlayCreatePaymentMethodForm';
import { PaymentEndpoints } from '../../base/endpoints/payment.endpoints';
import { DonationSubscriptions } from '../../base/endpoints/subscription.endpoints';
import { UserEndpoints } from '../../base/endpoints/user.endpoints';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { makeActionConfig, makeCancelAction } from '../../base/utils/actionConfig.utils';
import { renderRenderable } from '../../base/utils/components.utils';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { toTitleCase } from '../../base/utils/string.utils';
import { isString } from '../../base/utils/typeChecks.utils';
import tick from '../../base/utils/waiters.utils';
import { ModelName } from '../../constants/modelNames.enum';
import { stripeSubscriptionPriceId, SubscriptionFrequency } from '../../constants/subscription.enums';
import { Assignment } from '../../models/makeAssignment.model';
import { Payment, PaymentSnapshot } from '../../models/makePayment.model';
import { getAssignmentAssociatedModelTypeDisplayName } from '../../utils/assignment.utils';
import { asyncGetRecaptchaToken, checkErrorForRecaptchaFailure } from '../../utils/loadRecaptcha.utils';
import PaymentMethodList from '../PaymentMethodList/PaymentMethodList';
// import './MakePaymentForm.scss';

export type PaymentFormPayFunctionType = (e?: React.MouseEvent<HTMLButtonElement>, options?: {
  paymentDescription?: string,
  doNotReportPaymentSuccessToAPI?: boolean,
  isPaymentFinalizedByAPI?: boolean,
  actionBeforePay?: () => Promise<boolean>,
  confirmPaymentPayload?: AnyObject,
  doNotPresentErrorDialog?: boolean,
}) => Promise<boolean>;

export type PayableModel = {
  amountPayable: number | null,
  timeScheduled?: string,
}

interface MakePaymentFormProps {
  assignment?: Assignment,
  amount?: number | null,
  payableModel?: PayableModel | null,
  onInterceptPay?: (pay: PaymentFormPayFunctionType) => unknown,
  onComplete?: (payment?: Payment) => unknown,
  onError?: (e: AxiosError | unknown) => unknown,
  title?: Renderable,
  buttonLabelRenderer?: (amount?: number | null) => React.ReactElement,
  confirmationMessageRenderer?: (amount?: number | null) => React.ReactElement,
  type?: 'payment' | 'donation',
  isSubscription?: boolean,
  subscriptionFrequency?: SubscriptionFrequency,
}

const MakePaymentForm: React.FC<MakePaymentFormProps> = props => {

  const p = useProps(props);

  const { API, UI, AUTH } = useControllers();

  const stripe = useStripe();

  const s = useStore(() => ({
    rerenderKey: 0,
    get amount() {
      return p.amount;
    },
    selectedPaymentMethod: null as PaymentMethod | null,
    selectPaymentMethod: action((pm: PaymentMethod | null) => {
      s.selectedPaymentMethod = pm;
    }),
    get canPay() {
      return s.selectedPaymentMethod && s.amount;
    },
    get associatedModelTypeDisplayName() {
      return getAssignmentAssociatedModelTypeDisplayName(p.assignment?.associatedType);
    },
    rerenderPaymentList: action(() => {
      s.rerenderKey++;
    }),
    get paymentOrDonationText() {
      switch (p.type) {
        case 'payment':
          return {
            noun: 'payment',
            verb: 'pay',
          };
        case 'donation':
        default:
          return {
            noun: 'donation',
            verb: 'donate',
          };
      }
    }
  }));

  const pay: PaymentFormPayFunctionType = (e?: React.MouseEvent<HTMLButtonElement>, options?: {
    paymentDescription?: string,
    doNotReportPaymentSuccessToAPI?: boolean,
    isPaymentFinalizedByAPI?: boolean,
    actionBeforePay?: () => Promise<boolean>,
    confirmPaymentPayload?: AnyObject,
    doNotPresentErrorDialog?: boolean,
  }) => new Promise<boolean>(async (resolve, reject) => {

    if (!AUTH.currentUser) {
      reject('This payment form is designed for authenticated users only.');
      return;
    }

    if (!stripe) {
      reject('Stripe has not been not initiated.');
      return;
    }

    if (!s.amount) {
      reject('Cannot pay without a non-zero amount.');
      return;
    }

    if (!p.amount || p.amount < 1) {
      reject('The payment total must be bigger than 1.');
      return;
    }

    if (!s.selectedPaymentMethod) {
      UI.DIALOG.present({
        heading: 'You do not have any bank cards setup.',
        actions: [
          makeCancelAction(),
          makeActionConfig('Add a card', () => showCreatePaymentMethodOverlay(UI, s.rerenderPaymentList)),
        ]
      })
      return;
    }

    // let recaptchaToken = null as null | string;
    try {
      // recaptchaToken = await asyncGetRecaptchaToken('stripe_once_off_payment', UI);
      await asyncGetRecaptchaToken('stripe_select_card_payment', UI, API);
    } catch (e) {
      reject(e);
      checkErrorForRecaptchaFailure(e);
      UI.DIALOG.error({
        heading: 'You have not passed the Recaptcha test. Please try again.',
        // body: <ErrorRenderer error={(e as AnyObject).response} />,
      })
      return;
    }

    const defaultConfirmationMessageRenderer = (a?: number | null) => <>Confirm {s.paymentOrDonationText.noun} of <CurrencyRenderer value={a} /></>
    const confirmationMessageRenderer = p.confirmationMessageRenderer || defaultConfirmationMessageRenderer;

    const confirm = await UI.DIALOG.present({
      name: 'confirm-payment',
      heading: () => confirmationMessageRenderer(p.amount),
      body: () => <p>You will receive a receipt in an confirmation email.</p>
    });

    if (!confirm) {
      resolve(false);
      return;
    }

    try {
      if (options?.isPaymentFinalizedByAPI) {
        // This payment flow is to prevent a race condition during paid counselling payment, i.e. two users paying for the same limited availability.
        // So, we let API check atomically if counselling availability is available, then pay.
        // Reference: https://stripe.com/docs/payments/accept-a-payment-synchronously?platform=web#confirm-payment

        const shouldContinue = await options?.actionBeforePay?.();
        if (shouldContinue === false) {
          resolve(false);
          return;
        }

        const payload = {
          donationAmount: s.amount * 100,
          description: options?.paymentDescription ?? (p.assignment ? `assignment#${p.assignment.id} ${p.assignment.associatedTypeModelName}#${p.assignment.associatedId}` : 'Donation'),
          paymentMethodId: s.selectedPaymentMethod.id,
          ...options?.confirmPaymentPayload, // any object.
        }
        const { paymentIntentId, clientSecret, status } = (await API.postRaw(PaymentEndpoints.own.payServerSide(), payload))?.data as { paymentIntentId: string, clientSecret: string, status: PaymentIntent.Status };

        if (!clientSecret) {
          reject('Error connecting to payment service. Please try again later.');
          return;
        }

        if (status !== 'succeeded') {
          // 3DS or Card Auth handled by App.
          const response = await stripe.handleCardAction(clientSecret);

          if (response?.error) {
            reject(response?.error.message);
            return;
          }

          if (response.paymentIntent?.status === 'requires_confirmation') {
            const shouldContinue = await options?.actionBeforePay?.();
            if (shouldContinue === false) {
              resolve(false);
              return;
            }

            // reconfirms the payment intent with API.
            (await API.postRaw(PaymentEndpoints.own.payServerSide(), {
              paymentIntentId,
              ...options?.confirmPaymentPayload, // any object.
            }));
          }
        }
      } else {
        const clientSecret = (await API.postRaw(PaymentEndpoints.own.tokenise(), {
          donationAmount: s.amount * 100,
          description: options?.paymentDescription ?? (p.assignment ? `assignment#${p.assignment.id} ${p.assignment.associatedTypeModelName}#${p.assignment.associatedId}` : 'Donation'),
        }) as any)?.data?.clientSecret;

        if (!clientSecret) {
          reject('Error connecting to payment service. Please try again later.');
          return;
        }

        const shouldContinue = await options?.actionBeforePay?.();
        if (shouldContinue === false) {
          return;
        }

        const response = await stripe.confirmCardPayment(clientSecret, {
          receipt_email: AUTH.currentUser.email ?? undefined,
          payment_method: s.selectedPaymentMethod.id,
        })

        if (response.error) {
          UI.DIALOG.error({
            heading: response.error.message,
          })
          reject(response.error.message);
          return;
        }
      }

      const showSuccessDialog = async () => {
        await UI.DIALOG.success({
          heading: () => <>Thank you! We have received your {s.paymentOrDonationText.noun} of <CurrencyRenderer value={s.amount} />.</>
        })
      }
      if (!options?.doNotReportPaymentSuccessToAPI) {
        const url = PaymentEndpoints.own.reportSingleChargeSuccess();
        const payload = {
          paymentMethodId: s.selectedPaymentMethod?.id,
          amount: s.amount,
          userId: AUTH.currentUser.id,
          // email: AUTH.currentUser.email,
          assignmentId: p.assignment?.id,
        };
        const payment = await API.post<Payment>(url, ModelName.payments, payload as unknown as PaymentSnapshot);
        if (!payment) {
          reject(`Something wasn't quite right with the payment.`);
          return;
        }
        p.onComplete ? (await p.onComplete(payment)) : (await showSuccessDialog());
      } else {
        await showSuccessDialog();
      }
      resolve(true);
    } catch (e) {
      reject(e);
      reportError(e);
      if (!options?.doNotPresentErrorDialog) {
        p.onError ? p.onError(e) : UI.DIALOG.error({
          heading: 'Payment failed',
          body: () => <>
            <p>We have failed to process your {s.paymentOrDonationText.noun}. Please try again.</p>
            <ErrorRenderer error={(e as any).response} />
          </>
        })
      }
    }
  })

  const subscribe = async (
    e?: React.MouseEvent<HTMLButtonElement>,
  ) => new Promise<boolean>(async (resolve, reject) => {
    if (!AUTH.currentUser) {
      reject('This payment form is designed for authenticated users only.');
      return;
    }

    if (!stripe) {
      reject('Stripe has not been not initiated.');
      return;
    }

    if (!s.amount) {
      reject('Cannot pay without a non-zero amount.');
      return;
    }

    if (!p.amount || p.amount < 1) {
      reject('The payment total must be bigger than 1.');
      return;
    }

    if (!s.selectedPaymentMethod) {
      UI.DIALOG.present({
        heading: 'You do not have any bank cards setup.',
        actions: [
          makeCancelAction(),
          makeActionConfig('Add a card', () => showCreatePaymentMethodOverlay(UI, s.rerenderPaymentList)),
        ]
      })
      return;
    }

    // let recaptchaToken = null as null | string;
    try {
      // recaptchaToken = await asyncGetRecaptchaToken('stripe_once_off_payment', UI);
      await asyncGetRecaptchaToken('stripe_select_card_subscribe_payment', UI, API);
    } catch (e) {
      reject(e);
      checkErrorForRecaptchaFailure(e);
      UI.DIALOG.error({
        heading: 'You have not passed the Recaptcha test. Please try again.',
        // body: <ErrorRenderer error={(e as AnyObject).response} />,
      })
      return;
    }

    const isWeeklySubscription = p.subscriptionFrequency === SubscriptionFrequency.weekly;
    const frequencyText = isWeeklySubscription ? 'weekly' : 'monthly';

    const defaultConfirmationMessageRenderer = (a?: number | null) => <>Confirm {frequencyText} donation of <CurrencyRenderer value={a} /></>
    const confirmationMessageRenderer = p.confirmationMessageRenderer || defaultConfirmationMessageRenderer;

    const confirm = await UI.DIALOG.present({
      name: 'confirm-payment',
      heading: () => confirmationMessageRenderer(p.amount),
    });

    if (!confirm) {
      resolve(false);
      return;
    }

    try {
      const url = DonationSubscriptions.own.create();
      const payload = {
        pricingId: isWeeklySubscription ? stripeSubscriptionPriceId.weekly : stripeSubscriptionPriceId.monthly,
        name: `${frequencyText}_donation`,
        description: `${toTitleCase(frequencyText)} Donation Creation`,
        quantity: s.amount * 100,
        paymentMethodId: s.selectedPaymentMethod.id,
        paymentMethod: s.selectedPaymentMethod,
      }
      const { clientSecret } = (await API.postRaw(url, payload))?.data as { clientSecret: string };

      if (!clientSecret) {
        const msg = 'Error connecting to payment service. Please try again later.';
        UI.DIALOG.error({
          heading: msg,
          // body: <ErrorRenderer error={(e as AnyObject).response} />,
        })
        reject(msg);
        return;
      }

      const response = await stripe.confirmCardPayment(clientSecret, {
        receipt_email: AUTH.currentUser.email ?? undefined,
        payment_method: s.selectedPaymentMethod.id,
      })

      if (response.error) {
        UI.DIALOG.error({
          heading: response.error.message,
        })
        reject(response.error.message);
        return;
      }

      const showSuccessDialog = async () => await UI.DIALOG.success({
        heading: () => <>Thank you! We have received your {s.paymentOrDonationText.noun} of <CurrencyRenderer value={s.amount} /> and your {isWeeklySubscription ? 'weekly' : 'monthly'} donation has begun.</>
      })
      await tick(1000);
      await API.get(UserEndpoints.own.get({ include: ['subscriptions'] }), ModelName.users);
      p.onComplete ? (await p.onComplete()) : (await showSuccessDialog());
      resolve(true);
    } catch (e) {
      reject(e);
      reportError(e);
      p.onError ? p.onError(e) : UI.DIALOG.error({
        heading: 'Payment failed',
        body: () => <>
          <p>We have failed to process your recurring donation. Please try again.</p>
          <ErrorRenderer error={(e as any).response} />
        </>
      })
    }
  })

  const handlePay = () => {
    if (p.onInterceptPay) return () => p.onInterceptPay!(pay);
    if (p.isSubscription) return subscribe;
    return pay;
  }

  const defaultButtonLabelRenderer = (a?: number | null) => <>{toTitleCase(s.paymentOrDonationText.verb)} <CurrencyRenderer value={p.amount} /></>
  const buttonLabelRenderer = p.buttonLabelRenderer || defaultButtonLabelRenderer;

  return <Observer children={() => (
    <div className="MakePaymentForm">
      {p.title && !isString(p.title) ? renderRenderable(p.title) : <h3>{p.title ?? 'Payment Method'}</h3>}
      <BaseSpacer size="xs" />
      <PaymentMethodList key={s.rerenderKey} onSelect={s.selectPaymentMethod} selected={s.selectedPaymentMethod} showCardFormIfNoCard />
      {
        s.selectedPaymentMethod && <>
          <BaseSpacer size="sm" />
          <BaseButton
            size="lg"
            fullWidth
            title="Pay"
            dataCy="Pay"
            onClick={handlePay()}
            disabled={!s.canPay}
            label={buttonLabelRenderer(s.amount)}
          />
        </>
      }
    </div>
  )} />

}

export default MakePaymentForm;