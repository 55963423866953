import { Observer } from 'mobx-react-lite';
import React from 'react';
import joinClassName from '../../../../base/utils/className.utils';
import './AuthPage.scss';

type AuthPageProps = {
  className?: string,
}

const AuthPage: React.FC<AuthPageProps> = props => {

  return <Observer children={() => (
    <div className={joinClassName('AuthPage', props.className)}>
      <div className="AuthPageInner">
        { props.children }
      </div>
    </div>
  )} />

}

export default AuthPage;