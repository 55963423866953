import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { ArrayElementOf, Timeframe } from '../../../../base/@types';
import AppPage from '../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../base/components/AppPageHeader/AppPageHeader';
import BaseSelector from '../../../../base/components/BaseSelector/BaseSelector';
import BaseSpacer from '../../../../base/components/BaseSpacer/BaseSpacer';
import OverlayCloseButton from '../../../../base/components/OverlayCloseButton/OverlayCloseButton';
import ShadedBlock from '../../../../base/components/ShadedBlock/ShadedBlock';
import UIBlock from '../../../../base/components/UIBlock/UIBlock';
import { UICardInnerProps } from '../../../../base/components/UICard/UICard';
import { useSyncObservableValueToStorage } from '../../../../base/hooks/useSyncObservableValueToStorage.hook';
import joinClassName from '../../../../base/utils/className.utils';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import { toTitleCase } from '../../../../base/utils/string.utils';
import { YYYYMMDDHHmm } from '../../../../base/utils/time.utils';
import SupportGroupFeed from '../../../../components/SupportGroupFeed/SupportGroupFeed';
import SupportGroupIcon from '../../../../components/SupportGroupIcon/SupportGroupIcon';
import './ClientSupportGroupFinder.scss';

interface ClientSupportGroupFinderProps extends UICardInnerProps {
  showCloseButton?: boolean,
  blendIntoContext?: boolean,
}

const supportGroupFinderFilterTypeOptions = [
  'today', 
  'tomorrow', 
  'next-7-days', 
  'all'
] as const

export type SupportGroupFinderFilterType = ArrayElementOf<typeof supportGroupFinderFilterTypeOptions>;

const ClientSupportGroupFinder: React.FC<ClientSupportGroupFinderProps> = props => {

  const p = useProps(props);
  const s = useStore(() => ({
    filter: 'all' as SupportGroupFinderFilterType,
    get timeframe(): Timeframe {
      switch (s.filter) {
        case 'today': {
          return {
            startDate: moment().startOf('day').utc().format(YYYYMMDDHHmm),
            endDate: moment().endOf('day').utc().format(YYYYMMDDHHmm),
          }
        }
        case 'tomorrow': {
          return {
            startDate: moment().add(1, 'day').startOf('day').utc().format(YYYYMMDDHHmm),
            endDate: moment().add(1, 'day').endOf('day').utc().format(YYYYMMDDHHmm),
          }
        }
        case 'next-7-days': {
          return {
            startDate: moment().startOf('day').utc().format(YYYYMMDDHHmm),
            endDate: moment().add(7, 'days').endOf('day').utc().format(YYYYMMDDHHmm),
          }
        }
        case 'all':
        default: {
          return {
            startDate: moment().startOf('day').utc().format(YYYYMMDDHHmm),
          }
        }
      }
    }
  }))
  
  useSyncObservableValueToStorage(
    ['ClientSupportGroupFinder', 'filter'],
    () => s.filter,
    action(v => { if (v) s.filter = v }),
  )

  return <Observer children={() => (
    <AppPage className={
      joinClassName('ClientSupportGroupFinder', p.blendIntoContext && 'blendIntoContext')
    } color="blueGreen">
      <AppPageHeader 
        startSlot={<SupportGroupIcon />}
        title="Find a Support Group"
        afterTitle="Let's get talking!"
        endSlot={p.showCloseButton && <OverlayCloseButton color="white"/>}
      />
      <AppPageContent>
        <div className="ClientSupportGroupFinderFilterWrapper">
          <BaseSelector options={[...supportGroupFinderFilterTypeOptions]} form={s} field="filter" optionLabelRenderer={toTitleCase} />
        </div>
        <div className="ClientSupportGroupFinderIntro">
          <ShadedBlock spaceChildren>
            <p>Support Groups are provided for free on a variety of topics and each group occurs at the same time every week. To get the best out of group support for everyone it's important to only book support groups on the issues most relevant to you at the moment and to attend as regularly as you can.</p>
          </ShadedBlock>
        </div>
        { p.blendIntoContext && <BaseSpacer /> }
        <UIBlock padded={p.blendIntoContext ? undefined : 'all'}>
          <SupportGroupFeed
            key={[s.timeframe.startDate, s.timeframe.endDate].join('_')}
            filterTimeframe={s.timeframe}
          />
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
  
}

export default ClientSupportGroupFinder;