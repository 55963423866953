import { always } from "../../../base/utils/ramdaEquivalents.utils";
import { makeObservableRouteDef } from "../../../base/utils/routes.utils";
import ModuleExplore from "./Explore.module";

export const ExploreModuleRouteDef = makeObservableRouteDef({
  identifier: 'explore',
  urlFactory: always('explore'),
  composeTitle: always('Explore'),
  component: ModuleExplore,
  maxAgeInMinutes: Infinity,
})