export const employmentStatusDescriptors = [
    {
        value: 'prefer-not-to-say',
        name: 'Prefer not to say'
    },
    {
        value: 'self-employed',
        name: 'Self Employed',
    },
    {
        value: 'employed',
        name: 'Employed',
    },
    {
        value: 'unemployed',
        name: 'Unemployed',
    },
    {
        value: 'retired',
        name: 'Retired',
    },
    {
        value: 'student',
        name: 'Student',
    }, {
        value: 'refugee',
        name: 'Refugee',
    },
] as const