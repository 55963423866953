import { makeIconDef } from "./makeIconDef";
import React from "react";

export const FlagIconDef = {
  flag: makeIconDef('flag', {
    regular: () => <path d="M7.5 20V14.5M7.5 14.5V3.5L19.5 9L7.5 14.5ZM5 20H10" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />,
    filled: () => <>
      <path d="M19.5 9L7.5 3.5V14.5L19.5 9Z" fill="currentColor" />
      <path d="M7.5 20V14.5M7.5 14.5V3.5L19.5 9L7.5 14.5ZM5 20H10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </>
  })
}