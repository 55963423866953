import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import AppPage from '../../base/components/AppPage/AppPage';
import AppPageContent from '../../base/components/AppPageContent/AppPageContent';
import AppPageContentFooter from '../../base/components/AppPageContentFooter/AppPageContentFooter';
import AppPageHeader from '../../base/components/AppPageHeader/AppPageHeader';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import OverlayCloseButton from '../../base/components/OverlayCloseButton/OverlayCloseButton';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import UIBlock from '../../base/components/UIBlock/UIBlock';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { getNowTimestampUtc } from '../../base/utils/time.utils';
import { ModelName } from '../../constants/modelNames.enum';
import { AssignmentPHQ9 } from '../../models/makeAssignment.model';
import { makeSurveyPHQ9, SurveyPHQ9 } from '../../models/makeSurveyPHQ9.model';
import { makeSubmitSurveyFn } from '../../requests/makeSubmitSurveyFn.request';
import { getAssignmentAssociatedModelTypeDisplayName, getAssignmentTargetModelTypeDisplayName } from '../../utils/assignment.utils';
import SurveyEditorPHQ9 from '../SurveyEditorPHQ9/SurveyEditorPHQ9';
// import './OverlaySurveyPHQ9.scss';

type OverlaySurveyPHQ9Props = {
	assignment?: AssignmentPHQ9,
	assignmentId?: string,
	survey?: SurveyPHQ9,
	onComplete?: (a?: AssignmentPHQ9) => unknown,
	readonly?: any,
	onCloseButton?: () => void;
	hideCloseButton?: boolean,
}

const OverlaySurveyPHQ9: React.FC<OverlaySurveyPHQ9Props> = props => {

	const p = useProps(props);

	const { API, UI, AUTH } = useControllers();

	const s = useStore(() => ({
		_assignment: undefined as AssignmentPHQ9 | undefined,
		get assignment() {
			return p.assignment || s._assignment;
		},
		get assignmentId() {
			return s.assignment?.id || p.assignmentId;
		},
		survey: p.survey ?? makeSurveyPHQ9(p.assignment?.target),
		get formIsComplete() {
			return Object.entries(s.survey.$snapshot)
				.filter(([k, v]) => k.match(/^levelOf/))
				.every(([k, v]) => typeof v === 'number')
		},
		get targetModelTypeDisplayName() {
			return getAssignmentTargetModelTypeDisplayName(s.assignment?.targetType);
		},
		get associatedModelTypeDisplayName() {
			return getAssignmentAssociatedModelTypeDisplayName(s.assignment?.associatedType);
		},
		get currentUserIsAdminOrCounsellor() {
			return AUTH.isStaff && AUTH.isCounsellor;
		},
		get afterTitle() {
			if (s.assignment && s.currentUserIsAdminOrCounsellor) {
				return () => <>
					<p>Patient Health Questionnaire-9</p>
					<p>For {s.associatedModelTypeDisplayName} #{s.assignment?.associatedId}</p>
				</>
			}
			else {
				return 'Patient Health Questionnaire-9'
			}
		},
		get currentUserCanManage() {
			return AUTH.can.provideCounsellingFor_.someUserGroups;
		},
		get readonly() {
			return p.readonly || Boolean(s.assignment?.timeCompleted);
		},
	}));

	useOnMount(action(() => {
		if (s.assignmentId) {
			s.survey.assignmentId = s.assignmentId;
		}
		if (s.assignment?.target) {
			s.survey.$patch(s.assignment.target as SurveyPHQ9);
		}
	}));

	const submit = makeSubmitSurveyFn({
		API, UI,
		surveySubType: 'phq9',
		surveyModelName: ModelName.surveysPHQ9,
		survey: s.survey,
		surveyDisplayName: 'survey',
		assignment: s.assignment,
		onComplete: survey => {
			if (s.assignment) {
				s.assignment.timeCompleted = getNowTimestampUtc();
				s.assignment.targetId = (survey as any).id;
			}
			p.onComplete && p.onComplete(s.assignment);
		}
	});

	return <Observer children={() => (
		<AppPage className="OverlaySurveyPHQ9">
			<AppPageHeader
				title="PHQ9 Survey"
				afterTitle={s.afterTitle}
				endSlot={p.hideCloseButton ? null : <OverlayCloseButton onAfterClose={p.onCloseButton} />}
			/>
			<AppPageContent>
				<UIBlock padded spaceChildren>
					{
						!(p.survey || s.assignment?.target) && s.currentUserCanManage && <ShadedBlock color="orange">
							The user has not filled in the survey yet. You can fill it in for the user by completing the form below.
						</ShadedBlock>
					}
					<SurveyEditorPHQ9
						survey={s.survey}
						readonly={s.readonly}
						showScore={!!s.assignment?.target || (!s.assignment?.target && (AUTH.isStaff || AUTH.isCounsellor))}
					/>
				</UIBlock>
				<AppPageContentFooter padded="all">
					{s.readonly || (
						<UIBlock>
							<BaseButton onClick={submit} disabled={!s.formIsComplete} fullWidth size="lg" dataCy="Submit-OverlaySurveyPHQ9">Submit</BaseButton>
						</UIBlock>
					)}
				</AppPageContentFooter>
			</AppPageContent>
		</AppPage>
	)} />
}

export default OverlaySurveyPHQ9;