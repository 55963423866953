import scroll from 'scroll';
import { SHOULD_LOG } from '../../env';
import { getScrollParent } from './dom.utils';
import tick from './waiters.utils';

type ScrollToOptions = {
  useSystem?: boolean,
  ease?: (time: number) => number;
  duration?: number;
}
type ScrollToCallback = (error?: Error | null | unknown, value?: number) => void;

export const scrollDocumentTo = (o: ScrollToOptions & {
  top?: number, 
  left?: number,
  callback?: ScrollToCallback,
}) => scrollElementTo({...o, el: document.documentElement});

export async function scrollElementTo(o: ScrollToOptions & {
  el: HTMLElement | null, 
  top?: number, 
  left?: number,
  callback?: ScrollToCallback,
}) {
  const { el, top, left, useSystem, callback } = o;
  if (!el) {
    SHOULD_LOG() && console.warn('Attempted to scroll non-existent element.');
    return;
  }
  if (useSystem) {
    try {
      if (top !== undefined && left !== undefined) {
        el.scroll({ top, left });
      } else {
        top !== undefined && el.scrollTo({ top });
        left !== undefined && el.scrollTo({ left });
      }
      await tick(1000);
      callback && callback();
    } catch(e) {
      callback && callback(e);
    }
  } else {
    top !== undefined && scroll.top(el, top, o, callback);
    left !== undefined && scroll.left(el, left, o, callback);
  }
}

export async function scrollToTop(el: HTMLElement) {
  if (!el) return;
  await scrollElementTo({
    el,
    top: 0,
  })
}
export async function scrollScrollContextToTop(el: HTMLElement) {
  const scrollContext = getScrollParent(el) as HTMLElement;
  scrollContext && await scrollToTop(scrollContext);
}