import { action, flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject, ColorCodedState } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseInput, { TextInputKeyboardEvent } from '../../base/components/BaseInput/BaseInput';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { containsOnlyWhiteSpaceOrLineBreaks, trimString } from '../../base/utils/string.utils';
import { SHOULD_LOG } from '../../env';
import { Comment, CommentableModelType, CommentSnapshot } from '../../models/makeComment.model';
import { saveComment } from '../../requests/saveComment.request';
import './CommentComposer.scss';

interface CommentComposerProps {
  replyToModelType: CommentableModelType,
  replyToModelId: string,
  onCommentPosted?: (c: Comment) => void,
  autoFocus?: boolean,
}

const CommentComposer: React.FC<CommentComposerProps> = React.memo(props => {

  const p = useProps(props);

  const { COMMON, API, UI, AUTH, NAVIGATOR } = useControllers();

  const s = useStore(() => ({
    messageBody: '',
    clear: action(() => {
      s.messageBody = '';
    }),
    awaitingResponse: false,
    get bodyTooLong() {
      return false;
    },
    get sendButtonLabel() {
      if (s.bodyTooLong) {
        return `${s.messageBody.length} / 1200`
      }
      return 'Send';
    },
    get disabled() {
      return !COMMON.online || s.awaitingResponse;
    },
    handleComponentClickCapture: (e: React.MouseEvent) => {
      if (!AUTH.isAuthenticated) {
        e.preventDefault();
        e.stopPropagation();
        UI.DIALOG.present({
          heading: 'Please sign up or log in to join the conversation.',
          defaultActions: ['negative'],
          actions: [
            { label: 'Join Now', action: () => NAVIGATOR.navigateTo('/auth/register') },
          ]
        })
      }
    },
    get isIOS() {
      return UI.NATIVE.deviceInfo.browser.includes('ios');
    }
  }));

  const handleEnter = (e?: TextInputKeyboardEvent) => {
    if (!s.isIOS && e?.shiftKey) return;
    e && e.preventDefault();
    send();
  }

  const send = flow(function * () {
    if (s.messageBody.length === 0) return;
    const outgoingMessage = trimString(s.messageBody);
    if (containsOnlyWhiteSpaceOrLineBreaks(outgoingMessage)) {
      SHOULD_LOG() && console.log('empty message added, ignoring');
      s.messageBody = '';
      return;
    }
    const comment: Partial<CommentSnapshot> = { 
      body: outgoingMessage,
      thoughtId: p.replyToModelId,
    }
    s.awaitingResponse = true;
    try {
      const newComment = yield saveComment(API, comment);
      if (newComment) {
        s.clear();
        p.onCommentPosted?.(newComment);
      }
      if (newComment.timeHidden) {
        UI.DIALOG.present({
          heading: 'Comment Posted',
          body: <div>
            <p>However, our automated checker has noticed some content that might not be suitable to be viewed by the public.</p>
            <p>Our moderators will review the comment soon and publish it later if it turns out that our automated checker is too aggressive. Thank you for your understanding!</p>
          </div>,
          colorCodedState: ColorCodedState.attention
        })
      }
    } catch (e) {
      UI.DIALOG.error({
        heading: 'Failed to send comment',
        body: <>
          <p>The thought might no longer exist or there was a network error.</p>
          <ErrorRenderer error={(e as AnyObject).response} />
        </>
      })
    } finally {
      s.awaitingResponse = false;
    }
  })

  return <Observer children={() => (
    <div className="CommentComposer" onClickCapture={s.handleComponentClickCapture}>
      <BaseInput
        type="textarea"
        form={s}
        field='messageBody'
        enterKeyHint="send"
        onEnter={handleEnter}
        disabled={s.disabled}
        placeholder="Write a comment..."
        resize="vertical"
        colorCodedState={s.bodyTooLong ? ColorCodedState.alert : ''}
        dataCy="BotChatUserMessageComposer"
        autoFocus={p.autoFocus}
      />
      <BaseButton onClick={send} disabled={s.disabled} icon="send" />
    </div>
  )} />
})

export default CommentComposer;