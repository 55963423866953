import { prefixClient, prefixOwn, prefixPublic, prefixStaff } from "./namespacePrefixes";

const publicUrlBase = prefixPublic('twilio');
const ownUrlBase = prefixOwn('twilio');
const staffUrlBase = prefixStaff('twilio');
const clientUrlBase = prefixClient('twilio');

export const TwilioEndpoints = {
  publicValidatePhoneNumber: () => `${publicUrlBase}/validate-phone-number`,
  validatePhoneNumber: () => `${clientUrlBase}/validate-phone-number`,
  voip: {
    getToken: () => `${staffUrlBase}/token`,
    /** POST with payload of { phoneNumber } */
    makeCall: () => `${staffUrlBase}/call`,
  },
  videoRooms: {
    staff: {
      get: () => `${staffUrlBase}/rooms`,
      create: () => `${staffUrlBase}/rooms`,
      getToken: (roomUuid: string) => `${staffUrlBase}/rooms/${roomUuid}/join`,
    },
    own: {
      getToken: (roomUuid: string) => `${ownUrlBase}/rooms/${roomUuid}/join`,
    }
  }
}