import { Observer } from 'mobx-react-lite';
import React from 'react';
import ColorTag from '../../base/components/ColorTag/ColorTag';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { CounsellingApplicationSnapshot } from '../../models/makeCounsellingApplication.model';
import { getCounsellingApplicationReadableStatus } from '../../utils/counsellingApplication.utils';

interface CounsellingApplicationStatusColorTagProps {
  application?: CounsellingApplicationSnapshot | null
}

const CounsellingApplicationStatusColorTag: React.FC<CounsellingApplicationStatusColorTagProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get def() {
      return getCounsellingApplicationReadableStatus(p.application) || {};
    },
    get color() {
      return s.def.color
    },
    get status() {
      return s.def.status
    }
  }))
  return <Observer children={() => {
    return <ColorTag className="CounsellingApplicationStatusColorTag" color={s.color} capitalize>
      {s.status}
    </ColorTag>
  }} />
}

export default CounsellingApplicationStatusColorTag;