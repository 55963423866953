import {
  Feedback,
  FeedbackRelatedModels,
} from "../../models/makeFeedback.model"
import { DefaultIndexParamSet } from "../utils/api.utils"
import {
  makeApiEndpointConfig,
  makeApiGetEndpointConfig,
} from "../utils/endpoints.utils"
import { makeUrl } from "../utils/url.utils"
import { prefixOwn, prefixStaff } from "./namespacePrefixes"

export type FeedbackEndpointParams = DefaultIndexParamSet<
  Feedback,
  FeedbackRelatedModels
> & {
  include?: (keyof FeedbackRelatedModels | string)[]
}
const ownUrlBase = prefixOwn("feedback")
const staffUrlBase = prefixStaff("feedback")

export const FeedbackEndpoints = {
  own: {
    create: () => ownUrlBase,
  },
  staff: {
    index: makeApiEndpointConfig<FeedbackEndpointParams>(staffUrlBase),
    get: makeApiGetEndpointConfig<FeedbackEndpointParams>(staffUrlBase),
    create: () => staffUrlBase,
    update: (id: string) => makeUrl(staffUrlBase, id),
    delete: (id: string) => makeUrl(staffUrlBase, id),
  },
}
