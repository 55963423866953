export const predefinedIssueList = [
  'Anxiety',
  'Depression',
  'Stress',
  'Relationship Issues',
  'Sexuality',
  'Family Conflict',
  'Work Issues',
  'College Issues',
  'Financial Issues',
  'Trauma',
  'Suicidal Thoughts & Feelings',
  'Self-harm',
  'Eating Disorder',
  'Addiction',
  'Physical Health',
  'Bereavement',
  'Bullying',
  'Parenting Issues'
] as const

