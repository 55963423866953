import { Observer } from 'mobx-react-lite';
import React from 'react';
import ColorTag from '../../base/components/ColorTag/ColorTag';
import { CounsellingType } from '../../constants/counsellingTypes.descriptors';

interface CounsellingTypeColorTagProps {
  value?: CounsellingType | 'young-people',
  useAbbr?: boolean,
}

const CounsellingTypeColorTag: React.FC<CounsellingTypeColorTagProps> = props => {
  let color = '';
  let label = '';
  switch (props.value) {
    case CounsellingType.PaidOneToOne:
    case CounsellingType.OneToOne:
      label = props.useAbbr ? '1-to-1' : 'One to One';
      color = 'brightPurple';
      break;
    case CounsellingType.Couples: {
      label = props.useAbbr ? 'Couples' : 'Couples';
      color = 'firebrick';
      break;
    }
    case CounsellingType.YoungPeople1214: {
      label = props.useAbbr ? 'YP 12–14' : 'Young People 12–14';
      color = 'yellow';
      break;
    }
    case CounsellingType.YoungPeople1517: {
      label = props.useAbbr ? 'YP 15-17' : 'Young People 15–17';
      color = 'orange';
      break;
    }
    case 'young-people': {
      label = props.useAbbr ? 'YP' : 'Young People';
      color = 'yellow';
      break;
    }
    default: break;
  }
  return <Observer children={() => (
    <ColorTag className="CounsellingTypeColorTag" color={color} nowrap>
      { label }
    </ColorTag>
  )} />
}

export default CounsellingTypeColorTag;