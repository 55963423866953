import { flow, observable, when } from "mobx";
import React from "react";
import { Identifier, Nillable, Nullable } from "../base/@types";
import { reportError } from "../base/utils/errors.utils";
import OverlayThought from "../components/OverlayThought/OverlayThought";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { Thought } from "../models/makeThought.model";
import OverlayAdminSingleThoughtManager from "../modules/Admin/_components/OverlayAdminSingleThoughtManager/OverlayAdminSingleThoughtManager";
import { getThought } from "../requests/getThought.request";

export const viewThoughtDetails = async (options: {
  thought?: Thought,
  thoughtId?: Identifier, 
  API: APIController,
  /** Open the viewer without waiting for the thought data to be loaded */
  openImmediately?: boolean,
  action?: 'view' | 'edit' | 'manage',
}) => {

  const { UI, LOCALDB, AUTH } = options.API.ROOT!.children;
  const { DIALOG, OVERLAY } = UI;

  const { thought, thoughtId, openImmediately, action = 'view' } = options;

  // console.log(thought);
  // console.log(thoughtId);
  // console.log(thoughtId && LOCALDB.get(ModelName.thoughts, thoughtId));
  // debugger;

  const s = observable({
    thought: (thought || (thoughtId ? LOCALDB.get(ModelName.thoughts, thoughtId) : null)) as Nullable<Thought>,
    get thoughtId(): Nillable<Identifier> {
      return thoughtId || s.thought?.id;
    },
    get asStaff() {
      return AUTH.can.provideCounsellingFor_.someUserGroups || AUTH.can.thoughtCatcher_.moderate_.someAgeGroups
    }
  });

  when(
    () => Boolean(s.thoughtId),
    flow(function * () {
      try {
        s.thought = yield getThought(s.thoughtId!, options.API, s.asStaff);
      } catch(e) {
        DIALOG.error({
          heading: 'Failed to retrieve thought information',
          error: e
        })
      }
    }),
  )

  when(
    () => Boolean(openImmediately || s.thought),
    () => {
      switch (action) {
        case 'manage':
          OVERLAY.present({
            id: `OverlayAdminSingleThoughtManager#${s.thoughtId}`,
            name: `OverlayAdminSingleThoughtManager`,
            component: <OverlayAdminSingleThoughtManager thought={s.thought} thoughtId={s.thoughtId} />,
            // type: 'card',
            duplicateStrategy: 'abort',
            width: '75em',
            noBackdrop: true,
          })
          break;
        case 'edit':
        case 'view':
        default:
          OVERLAY.present({
            id: `OverlayThought#${s.thoughtId}`,
            name: `OverlayThought`,
            component: <OverlayThought thought={s.thought} thoughtId={s.thoughtId} action={action} />,
            // appearance: 'card',
            duplicateStrategy: 'abort',
          })
      }
    },
    { timeout: 5000, onError: error => {
      reportError(error);
      DIALOG.error({
        heading: 'Failed to retrieve thought information',
        error: "The thought you are looking for doens't exist or you do not have the permission to view it."
      })
    }}
  )
  
}