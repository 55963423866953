import { Observer } from "mobx-react-lite";
import React from "react";
import { Nillable } from "../base/@types";
import { DefaultColorName } from "../base/utils/colors.utils";
import { isNumber, isString } from "../base/utils/typeChecks.utils";
import OverlaySupportGroupManager from "../components/OverlaySupportGroupManager/OverlaySupportGroupManager";
import OverlaySupportGroupViewer from "../components/OverlaySupportGroupViewer/OverlaySupportGroupViewer";
import { Tags } from "../constants/tags.constants";
import { UIController } from "../controllers/ui.controller";
import { SupportGroup, SupportGroupSnapshot } from "../models/makeSupportGroup.model";
import { User } from "../models/makeUser.model";
import { getUserAge, isAdultAge, isYoungPeopleAge1214, isYoungPeopleAge1517 } from "./ageAndDateOfBirth.utils";


export type SupportGroupUserGroupType = '18+' | '15-17' | '12-14' | Tags.YoungPeoples | Tags.Unknown;
// export type SupportGroupUserGroupType = '18+' | '15-17' | '12-14' | Tags.FrontLineWorkers | Tags.AdultsAndYoungPeople | Tags.YoungPeoples | Tags.AdultAnd1214 | Tags.AdultAnd1517 | Tags.NA;
export type SupportGroupUserGroupTypeDef = {
  value: SupportGroupUserGroupType,
  name: string,
  abbr: string,
  color: DefaultColorName,
}

export const SupportGroupUserGroupTypeDefs: SupportGroupUserGroupTypeDef[] = [
  {
    value: '18+',
    name: 'Adults',
    abbr: 'Adults',
    color: 'brightPurple',
  },
  {
    value: '12-14',
    name: 'Young People 12–14',
    abbr: 'YP 12–14',
    color: 'yellow',
  },
  {
    value: '15-17',
    name: 'Young People 15–17',
    abbr: 'YP 15–17',
    color: 'orange',
  },
  // {
  //   value: Tags.FrontLineWorkers,
  //   name: 'Front Line Workers',
  //   abbr: 'Frontline',
  //   color: 'lightBlue',
  // },
]

export const ExtendedSupportGroupUserGroupTypeDefs: SupportGroupUserGroupTypeDef[] = SupportGroupUserGroupTypeDefs.concat([
  {
    value: Tags.YoungPeoples,
    name: 'Young Peoples 12-17',
    abbr: 'YPs 12-17',
    color: 'orange',
  },
  // {
  //   value: Tags.AdultAnd1214,
  //   name: 'Adults and Young People 12-14',
  //   abbr: 'Adults, YP 12-14',
  //   color: 'yellow',
  // },
  // {
  //   value: Tags.AdultAnd1517,
  //   name: 'Adults and Young People 15-17',
  //   abbr: 'Adults, YP 15-17',
  //   color: 'orange',
  // },
  // {
  //   value: Tags.AdultsAndYoungPeople,
  //   name: 'Adults and Young People 12-17',
  //   abbr: 'Adults, YP 12-14, YP 15-17',
  //   color: 'brightPurple',
  // },
  // {
  //   value: Tags.NA,
  //   name: 'N/A',
  //   abbr: 'N/A',
  //   color: 'steel',
  // },
  {
    value: Tags.Unknown,
    name: 'Unknown',
    abbr: 'Unknown',
    color: 'brown',
  },
])

export function makeSupportGroupViewerId(id?: string) {
  return `support-group-viewer-${id || 'unknown'}`;
}

export function supportGroupHasAvailability(sg?: SupportGroupSnapshot) {
  const { reservationCount, maxParticipants } = sg || {}
  return isNumber(reservationCount) && isNumber(maxParticipants) && (maxParticipants > reservationCount);
}

export function viewSupportGroupInOverlay(UI: UIController, group?: SupportGroup | string, onAfterClose?: () => unknown) {
  const id = isString(group) ? group : group?.id;
  UI.OVERLAY.present({
    name: `OverlaySupportGroupViewer#${id}`,
    color: 'blueGreen',
    component: <OverlaySupportGroupViewer
      supportGroup={isString(group) ? undefined : group}
      supportGroupId={id}
    />,
    duplicateStrategy: 'abort',
    onAfterClose,
    width: '45em',
  })
}

export function manageSupportGroup(
  UI: UIController,
  group?: SupportGroup | string,
  onAfterClose?: () => unknown
) {
  const id = isString(group) ? group : group?.id;
  UI.OVERLAY.present({
    name: `OverlaySupportGroupManager#${id}`,
    color: 'blueGreen',
    width: '75em',
    component: <Observer children={() => (
      <OverlaySupportGroupManager
        supportGroup={isString(group) ? undefined : group}
        supportGroupId={id}
      />
    )} />,
    duplicateStrategy: 'abort',
    onAfterClose,
    noBackdrop: true,
  })
}

export const checkIfUserIsWithinAgeGroupsOfSupportGroup = (user: Nillable<User>, group: Nillable<SupportGroup>) => {
  if (!user) return false;
  const age = getUserAge(user);
  if (!group?.ageGroups.length) return true;
  if (!age) return false;
  if (isYoungPeopleAge1214(age) && group?.isFor1214) return true;
  if (isYoungPeopleAge1517(age) && group?.isFor1517) return true;
  if (isAdultAge(age) && group?.isForAdults) return true;
  return false;
}