import { action, toJS } from 'mobx';
import pluralize from 'pluralize';
import { ModelName } from '../../constants/modelNames.enum';
import { LocalDBController } from '../../controllers/localDB.controller';
import { HasTimestamps } from '../../traits/hasTimestamps.trait';
import { AnyObject, HasId, Nillable, RelationshipDescriptor, RelationshipsSchema } from '../@types';
import { mapToIds } from './array.utils';
import { copyWithJSON, setValueOfKey } from './object.utils';
import { isNil } from './ramdaEquivalents.utils';
import { singularize } from './string.utils';
import { isArray, isNumber, isObject } from './typeChecks.utils';

export const enforceStringIdOnSnapshot = action(<T extends AnyObject = AnyObject>(object: T) => {
  if (isNil(object)) return object;
  const o = copyWithJSON(object);
  try {
    Object.keys(o).forEach(k => {
      if (!o[k]) return;
      if ((k === 'id' || k.match(/Id$/)) && isNumber(o[k])) Reflect.set(o, k, o[k] + '');
      else if (k.match(/Ids$/)) Reflect.set(o, k, (o[k] as any)?.map((id: any) => isNumber(id) ? id + '' : id));
      else if (isArray(o[k])) Reflect.set(o, k, o[k]?.map(enforceStringIdOnSnapshot));
      else if (isObject(o[k])) Reflect.set(o, k, enforceStringIdOnSnapshot(o[k]));
    })
    return o;
  } catch (e) {
    console.error(e);
    return object;
  }
})

export const getSnapshot = <T extends Nillable<AnyObject>>(obj: T): T => {
  if (!obj) return obj;
  const snapshot = obj.$getSnapshot?.() ?? obj;
  if (snapshot) return snapshot;
  if (obj.$) return toJS(obj.$);
  return enforceStringIdOnSnapshot(copyWithJSON(toJS(obj)) ?? {}) as T;
}

export const getSnapshotWithoutTimestamps = <T extends Nillable<HasTimestamps> = Nillable<HasTimestamps>>(obj: T) => {
  if (!obj) return obj;
  const snapshot = getSnapshot(obj) as HasTimestamps;
  const { timeCreated, timeDeleted, timeUpdated, ...rest } = snapshot;
  return rest;
}

export const setSnapshotLinkedModelsInLocalDB = <T extends HasId, K extends AnyObject>(
  snapshot?: Partial<T>,
  schema?: RelationshipsSchema<T, K>,
  localDB?: LocalDBController,
) => {
  if (!snapshot || !schema || !localDB) return;
  Object.keys(schema).forEach(k => {
    const linkedModelSnapshot = (snapshot as AnyObject)[k];
    if (!linkedModelSnapshot) return;
    const descriptor = isObject(schema[k]) ? schema[k] as RelationshipDescriptor<T> : {
      modelName: schema[k] as ModelName
    };
    const modelName = descriptor.modelName ?? pluralize(k) as ModelName;
    const has = descriptor.has || 'one';
    if (has === 'one') {
      localDB.setOrMerge(modelName, linkedModelSnapshot);
      const identifierKeyName = descriptor.identifierKeyName || singularize(k) + 'Id';
      setValueOfKey<any>(snapshot, identifierKeyName, linkedModelSnapshot.id);
    } else {
      localDB.setOrMergeMany(modelName, linkedModelSnapshot);
      const identifierKeyName = descriptor.identifierKeyName || singularize(k) + 'Ids';
      setValueOfKey<any>(snapshot, identifierKeyName, mapToIds(linkedModelSnapshot));
    }
  })
}