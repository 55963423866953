import { Identifier, Nillable, Nullable, StandardModel } from "../base/@types";
import { CountryCode } from "../base/constants/countries.constants";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { sortByTimeScheduledOldestFirst } from "../base/utils/array.utils";
import { createUTCMoment } from "../base/utils/time.utils";
import { CommunicationType } from "../constants/communicationTypes.descriptors";
import { NUM_FREE_COUNSELLING_SESSIONS } from "../constants/counsellingFeeTypes.constants";
import { ApplicationTypeOption, CounsellingType } from "../constants/counsellingTypes.descriptors";
import { ModelName } from "../constants/modelNames.enum";
import { hasTimestamps } from "../traits/hasTimestamps.trait";
import { isFreeCounselling, isPaidCounselling } from "../utils/counsellingApplication.utils";
import { Address, AddressSnapshot, makeAddressSnapshotBase } from "./makeAddress.model";
import { Assignment } from "./makeAssignment.model";
import { ContactSnapshot, ContactType, makeContactSnapshotBase } from "./makeContact.model";
import { CounsellingAvailability } from "./makeCounsellingAvailability.model";
import { CounsellingSession } from "./makeCounsellingSession.model";
import { Invitation, InvitationSnapshot, makeInvitationSnapshotBase } from "./makeInvitation.model";
import { makeUserSnapshotBase, User, UserSnapshot } from "./makeUser.model";

export type CounsellingApplicationSnapshot = ReturnType<typeof makeCounsellingApplicationSnapshotBase>;

export type CounsellingApplicationRelatedModels = {
  counsellor?: User,
  applicant?: User,
  invitee?: User,
  clients: User[],
  sessions: CounsellingSession[],
  assignments: Assignment[],
  invitation?: Invitation,
  availability?: CounsellingAvailability,
  address?: Address,
}
export type CounsellingApplicationExtendedProperties = {
  readonly countryId?: CountryCode,
  readonly detectedCountryId?: CountryCode,
  readonly providedCountryId?: CountryCode,
  readonly region?: Nillable<string>,
  readonly sortedSessions: CounsellingSession[],
  readonly isDnr: boolean,
  readonly isApproved: boolean,
  readonly isRejected: boolean,
  readonly isCompleted: boolean,
  readonly isArchived: boolean,
  readonly isPending: boolean,
  readonly isOngoing: boolean,
  readonly justCompleted: boolean,
  readonly nextSession?: CounsellingSession,
  readonly invitee?: User,
  readonly isPaid: boolean,
  readonly isFree: boolean,
  readonly isExceededFreeSessions: boolean,
}

export type CounsellingApplication = StandardModel<
  CounsellingApplicationSnapshot,
  CounsellingApplicationRelatedModels,
  CounsellingApplicationExtendedProperties
>

export type CounsellingApplicationReadableStatus = '' | 'approved' | 'rejected' | 'cancelled' | 'pending';
export type CounsellingApplicationApplicantType = 'dependent-child' | 'legal-guardian' | 'partner';

export const makeCounsellingApplicationSnapshotBase = () => {

  const baseFields = {

    id: '',

    applicantId: '' as Identifier,
    inviteeId: '' as Identifier,
    invitationId: '' as Identifier | null,
    counsellorId: '' as Identifier | null,
    availabilityId: '' as Identifier | null,
    addressId: '' as Identifier | null,

    assignmentIds: [] as Identifier[],
    sessionIds: [] as Identifier[],
    clientIds: [] as Identifier[],

    countryId: null as Nillable<CountryCode>,

    timeApproved: '' as string | null,
    timeRejected: '' as string | null,
    timeCancelled: '' as string | null,
    timeArchived: '' as string | null,
    timeCompleted: '' as string | null,

    reasonForRejection: '' as string | null,

    // new in V2 forms:

    hasPrivateHealthCare: null as boolean | null,
    isInReceiptOfMedicalCard: null as boolean | null,
    ownSymptomsOrFeelingsMeasurement: '' as string | null,
    hasAttemptedSuicide: null as boolean | null,

    ...hasTimestamps(),

  }

  const applicantFormFields = {

    type: CounsellingType.OneToOne,
    communicationTypePreference: '' as Nullable<CommunicationType>,

    hasExternalCounselling: null as Nullable<boolean>,
    previousDiagnosis: '',
    previousCounsellingExperience: '',
    healthcareProviderName: '',
    healthcareProviderPhone: '',
    medication: '',

    /** @deprecated now only used for local filling in a form */
    timePreferredForFirstSession: '' as Nullable<string>,

    didNotRespond: false,
    isConsenting: true,

    clientAgreedDonationAmountPerSession: 0,
    isAtRisk: null as Nullable<boolean>,

  };

  return { ...baseFields, ...applicantFormFields };
}

export const makeCounsellingApplication = createStandardModelFactory<CounsellingApplication, CounsellingApplicationRelatedModels, CounsellingApplicationExtendedProperties>({
  name: ModelName.counsellingApplications,
  snapshotFactory: makeCounsellingApplicationSnapshotBase,
  snapshotTypeCastSchema: {
    hasExternalCounselling: 'boolean',
  },
  relationshipsSchema: {
    counsellor: ModelName.users,
    applicant: ModelName.users,
    invitee: ModelName.users,
    invitation: ModelName.invitations,
    address: ModelName.addresses,
    availability: ModelName.counsellingAvailabilities,
    clients: { modelName: ModelName.users, has: 'many' },
    sessions: { modelName: ModelName.counsellingSessions, has: 'many' },
    assignments: { modelName: ModelName.assignments, has: 'many' },
  },
  extendedPropertiesFactory: (m, $, localDB) => ({
    get countryId() {
      return m.applicant?.countryDetectedId || m.applicant?.primaryAddress?.countryId || $.countryId || undefined;
    },
    get detectedCountryId() {
      return m.applicant?.countryDetectedId || undefined;
    },
    get providedCountryId() {
      return m.applicant?.primaryAddress?.countryId || $.countryId || undefined;
    },
    get region() {
      return m.applicant?.primaryAddress?.region;
    },
    get isDnr() {
      return m.didNotRespond;
    },
    get isApproved() {
      return !m.isDnr && !!m.timeApproved;
    },
    get isRejected() {
      return !!m.timeRejected;
    },
    get isCompleted() {
      return !!m.timeCompleted;
    },
    get isArchived() {
      return !!m.timeArchived;
    },
    get isPending() {
      return !m.isApproved && !m.isRejected && !m.isCompleted && !m.isArchived;
    },
    get isOngoing() {
      return m.isApproved && !m.isRejected && !m.isCompleted && !m.isArchived;
    },
    get sortedSessions() {
      return sortByTimeScheduledOldestFirst(m.sessions);
    },
    get nextSession() {
      return m.sortedSessions.find(ses => ses.isPending);
    },
    get invitee() {
      return m.clients?.find(c => c.id !== m.applicantId);
    },
    get inviteeId() {
      return m.invitee?.id;
    },
    get justCompleted() {
      return Boolean(m.timeCompleted && createUTCMoment(m.timeCompleted).add(1, 'week').isAfter());
    },
    get isPaid() {
      return isPaidCounselling(m.type);
    },
    get isFree() {
      return isFreeCounselling(m.type);
    },
    get isExceededFreeSessions() {
      return m.sessions.length >= NUM_FREE_COUNSELLING_SESSIONS;
    },
  })
})

export const makeCounsellingApplicationForm = (
  snapshot?: {
    hasAgreedCantAfford?: boolean,
    hasReadImportantInformation?: boolean,
    hasAgreedToTerms?: boolean,
    ableToDonate?: Nullable<boolean>,
    application?: Partial<CounsellingApplicationSnapshot>,
    applicant?: Partial<UserSnapshot>,
    invitation?: Partial<InvitationSnapshot>,
    applicantAddress?: Partial<AddressSnapshot>,
    applicantEmergencyContact?: Partial<ContactSnapshot>,
  }
) => {
  return {
    version: 2,
    selectedApplicationType: ApplicationTypeOption.OneToOne,
    hasAgreedCantAfford: snapshot?.hasAgreedCantAfford ?? false,
    hasReadImportantInformation: snapshot?.hasReadImportantInformation ?? false,
    hasAgreedToTerms: snapshot?.hasAgreedToTerms ?? false,
    ableToDonate: snapshot?.ableToDonate ?? null as Nullable<boolean>,
    application: {
      ...makeCounsellingApplicationSnapshotBase(),
      ...snapshot?.application
    } as CounsellingApplicationSnapshot,
    applicant: {
      ...makeUserSnapshotBase(),
      ...snapshot?.applicant
    },
    applicantAddress: {
      ...makeAddressSnapshotBase(),
      ...snapshot?.applicantAddress
    },
    applicantEmergencyContact: {
      ...makeContactSnapshotBase(),
      ...snapshot?.applicantEmergencyContact,
      type: ContactType.EmergencyContact,
    },
    invitation: {
      ...makeInvitationSnapshotBase(),
      ...snapshot?.invitation,
    },
  }
};

export type CounsellingApplicationForm = ReturnType<typeof makeCounsellingApplicationForm> & {
  type?: ApplicationTypeOption | CounsellingType,
  application?: Partial<CounsellingApplicationSnapshot> & {
    type?: ApplicationTypeOption | CounsellingType,
  }
};