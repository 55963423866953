export const sexualityDescriptors = [
  {
    name: 'Asexual',
    value: 'asexual',
  },
  {
    name: 'Bisexual',
    value: 'bisexual',
  },
  {
    name: 'Fluid',
    value: 'fluid',
  },
  {
    name: 'Heterosexual',
    value: 'heterosexual',
  },
  {
    name: 'Homosexual',
    value: 'homosexual',
  },
  {
    name: 'Pansexual',
    value: 'pansexual',
  },
  {
    name: 'Queer',
    value: 'queer',
  },
  {
    name: 'Questioning',
    value: 'questioning',
  },
  {
    name: 'Demisexual',
    value: 'demisexual',
  },
  {
    name: 'Anthrosexual',
    value: 'anthrosexual',
  },
  {
    name: 'Autosexuality',
    value: 'autosexuality',
  },
  {
    name: 'Graysexuality',
    value: 'graysexuality',
  },
  {
    name: 'Hyposexuality',
    value: 'hyposexuality',
  },
  {
    name: 'Lithsexuality',
    value: 'lithsexuality',
  },
  {
    name: 'Sapiosexuality',
    value: 'sapiosexuality',
  },
  {
    name: 'Skoliosexuality',
    value: 'skoliosexuality',
  },
  {
    name: 'Other',
    value: 'other',
  },
] as const

export const nonHeterosexualSexualities = [
  'asexual',
  'bisexual',
  'fluid',
  'homosexual',
  'pansexual',
  'queer',
  'questioning',
  'other'
];