import { AnyObject } from "../@types";

export function isNumber(v: any): v is number {
  return typeof v === 'number'
}
export function isNumberLike(v: any): v is number | string {
  return v !== null && (typeof v === 'number' || !isNaN(v));
}
export function isString(v: any): v is string {
  return typeof v === 'string';
}
export function isBoolean(v: any): v is boolean {
  return typeof v === 'boolean';
}
export function isUndefined(v: any): v is undefined {
  return v === undefined;
}
export function isNull(v: any): v is null {
  return v === null;
}

export function isFunction(v: any): v is Function {
  return typeof v === 'function';
}
export function isArray<T>(v: any): v is T[] {
  return v instanceof Array
}
export function isObject<T extends AnyObject>(v: any): v is T {
  return v instanceof Object
}
export function isPromise<T>(v: any): v is Promise<T> {
  return v instanceof Promise
}

export function isRegex(v: any): v is RegExp {
  return v instanceof RegExp;
}