import { flow } from "mobx";
import { ContactEndpoints } from "../base/endpoints/contact.endpoints";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { Contact } from "../models/makeContact.model";

export const deleteEmergencyContact = flow(function * (ec: Contact, API: APIController) {
  const endpoint = ContactEndpoints.own.delete;
  const url = endpoint(ec.id);
  yield API.delete(url, ModelName.contacts, ec);
  return;
})