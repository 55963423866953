import { Observer } from 'mobx-react-lite';
import React from 'react';
import LoadingIndicator from '../../../../../base/components/LoadingIndicator/LoadingIndicator';
import { StatDataSetState } from '../../statistics.helpers';
import './StatDataSetStateDisplayer.scss';

interface StatDataSetStateDisplayerProps {
  state: StatDataSetState,
}

const StatDataSetStateDisplayer: React.FC<StatDataSetStateDisplayerProps> = p => {
  return <Observer children={() => (
    <div className="StatDataSetStateDisplayer"
      data-status={p.state.isLoading ? 'loading' : 'loaded'}
      data-has-error={p.state.erroredDataTypes.length > 0}
    >
      {p.state.isLoading && <LoadingIndicator />}
      <span>{p.state.statusText}</span>
    </div>
  )} />
}

export default StatDataSetStateDisplayer;