import { action, runInAction } from "mobx";
import moment from "moment";
import { HasTimestamps } from "../../traits/hasTimestamps.trait";
import { HasId, HasName, Nillable, Nullable, SortDirection } from "../@types";
import { copyWithJSON } from "./object.utils";
import { isNil, uniq } from "./ramdaEquivalents.utils";
import { equalByString } from "./string.utils";
import { createUTCMoment } from "./time.utils";
import { isNumberLike, isString } from "./typeChecks.utils";

export const keepTruthy = <T>(arr: T[]): Exclude<T, false>[] => arr.filter(i => i) as Exclude<T, false>[];

export const addToArrayIfNew = <T>(
  arr?: T[],
  ...itemsToAdd: T[]
) => {
  if (!arr) return arr;
  for (let item of itemsToAdd) {
    if (arr.includes(item)) continue;
    runInAction(() => {
      arr.push(item);
    })
  }
  return arr;
}

export const addToOrUpdateExistingInArrayById = <T extends HasId>(
  arr?: T[],
  ...itemsToAdd: T[]
) => {
  if (!arr) return arr;
  for (let item of itemsToAdd) {
    const existingItems = arr.filter(a => a.id === item.id);
    if (existingItems.length > 0) {
      existingItems.forEach(ei => removeFromArray(arr, ei));
    }
    arr.push(item);
  }
  return arr;
}

export const removeFromArray = action(<T>(arr?: T[], ...itemsToRemove: any) => {
  if (!arr) return arr;
  for (let item of itemsToRemove) {
    let i = arr.indexOf(item);
    while (i >= 0) {
      arr.splice(i, 1);
      i = arr.indexOf(item);
    }
  }
  return arr as T[];
})

export const removeFromArrayById = action(<T extends HasId = HasId>(
  arr: T[],
  ...itemsToRemove: HasId[]
) => {
  for (let item of itemsToRemove) {
    let index = arr.findIndex(a => a.id + '' === item.id + '');
    while (index >= 0) {
      arr.splice(index, 1);
      index = arr.findIndex(a => a.id + '' === item.id + '');
    }
  }
  return arr;
})

type JoinerArgType = string | number | boolean | undefined | null;

export const joinTruthyWithSeparator = (separator: string, ...args: JoinerArgType[]) => {
  return args.filter((x: any) => !!x).join(separator);
}

export const joinTruthyFactory = (separator: string = ',') =>
  (...args: JoinerArgType[]) => joinTruthyWithSeparator(separator, ...args);

export const joinTruthyWithSpace = joinTruthyFactory(' ');

export const space = (...args: JoinerArgType[]) => args.join(' ');
export const comma = (...args: JoinerArgType[]) => args.join(',');

export const sortByLength = <T extends { length: number }>(arr: T[]) => {
  return arr.sort((a,b) => b.length - a.length);
}

export function pushToOrReplaceItemsInArray<T>(
  originalArray: T[],
  newItems: T[],
  matcher: (
    i: T,
    originalArray: T[]
  ) => number = (i, a) => a.indexOf(i)
) {
  newItems.forEach(e => {
    const existingItemIndex = matcher(e, originalArray);
    if (existingItemIndex >= 0) {
      originalArray.splice(existingItemIndex, 1);
    }
    originalArray.push(e);
  })
}

export const mapToIds = <T extends HasId>(arr: T[]) => arr.map(a => a.id + '');

export const replaceArrayContent = <T>(arr: T[], newItems: T[]) => {
  arr.splice(0);
  arr.push(...newItems);
}

export const sorterByCreatedAtLatestFirst = (a: { created_at: Nullable<string> }, b: { created_at: Nullable<string> }) => createUTCMoment(b.created_at).diff(a.created_at);
export const sorterByCreatedAtOldestFirst = (a: { created_at: Nullable<string> }, b: { created_at: Nullable<string> }) => createUTCMoment(a.created_at).diff(b.created_at);

export const sorterByTimeCreatedLatestFirst = (a: HasTimestamps, b: HasTimestamps) => createUTCMoment(b.timeCreated).diff(a.timeCreated);
export const sorterByTimeCreatedOldestFirst = (a: HasTimestamps, b: HasTimestamps) => createUTCMoment(a.timeCreated).diff(b.timeCreated);

export type HasTimeScheduled = { timeScheduled: Nullable<string> };
export const sorterByTimeScheduledLatestFirst = (a: HasTimeScheduled, b: HasTimeScheduled) => createUTCMoment(b.timeScheduled).diff(a.timeScheduled);
export const sorterByTimeScheduledOldestFirst = (a: HasTimeScheduled, b: HasTimeScheduled) => createUTCMoment(a.timeScheduled).diff(b.timeScheduled);

export const sorterByTimeUpdatedLatestFirst = (a: HasTimestamps, b: HasTimestamps) => createUTCMoment(b.timeUpdated).diff(a.timeUpdated);
export const sorterByTimeUpdatedOldestFirst = (a: HasTimestamps, b: HasTimestamps) => createUTCMoment(a.timeUpdated).diff(b.timeUpdated);

export const sortByTimeCreatedLatestFirst = <T extends HasTimestamps>(arr: Nillable<T[]>) => {
  return [...arr || []].sort(sorterByTimeCreatedLatestFirst);
}
export const sortByTimeScheduledOldestFirst = <T extends HasTimeScheduled>(arr: Nillable<T[]>) => {
  return [...arr || []].sort(sorterByTimeScheduledOldestFirst);
}
export const sortByTimeCreatedOldestFirst = <T extends HasTimestamps>(arr: Nillable<T[]>) => {
  return [...arr || []].sort(sorterByTimeCreatedOldestFirst);
}
export const sortByCreatedAtLatestFirst = <T extends { created_at: Nullable<string> }>(arr: Nillable<T[]>) => {
  return [...arr || []].sort(sorterByCreatedAtLatestFirst);
}
export const sortByTimeUpdatedLatestFirst = <T extends HasTimestamps>(arr: Nillable<T[]>) => {
  return [...arr || []].sort(sorterByTimeUpdatedLatestFirst);
}

export function swapInPlace<T>(arrI: T[], arrJ: T[], i: number, j: number, swapIf?: (a: T, b: T) => boolean) {
  if (arrI[i] === void 0 || arrJ[j] === void 0) return false;
  if (swapIf && !swapIf(arrI[i], arrJ[j])) return false;
  if (arrI === arrJ && i === j) return true;
  [arrI[i], arrJ[j]] = [arrJ[j], arrI[i]];
  return true;
}
export const swapInPlaceInArray = <T>(arr: T[], i: number, j: number) => {
  return swapInPlace(arr, arr, i, j);
}
export const moveToEndOfArray = <T>(arr: T[], a: T) => {
  removeFromArray(arr, a);
  arr.push(a);
  return arr;
}

export function addToArrayByString<T>(
  originalArray: T[],
  ...newItems: T[]
) {
  return pushToOrReplaceItemsInArray(
    originalArray,
    newItems,
    (i, arr) => arr.findIndex(a => equalByString(a, i))
  )
}



export function transformArray<TransformedItemType, OriginalItemType = any>(
  arr: OriginalItemType[],
  transformer: (item: OriginalItemType, index?: number, array?: OriginalItemType[]) => TransformedItemType,
  options: {
    transformInPlace?: boolean,
  } = {}
): TransformedItemType[] {
  const { transformInPlace = true } = options;
  const result = arr.map((x, i, a) => transformer(x, i, a));
  if (transformInPlace) {
    arr.splice(0);
    (arr as unknown as TransformedItemType[]).push(...result);
    return arr as unknown as TransformedItemType[];
  }
  return result;
}

export function mergeIntoArray(arrA: any[], arrB: any | any[], options?: {
  mergeInPlace?: boolean,
  comparator?: (a: any, b: any) => boolean,
  onDuplicate?: 'replace' | 'push' | 'merge' | ((a: any, b: any) => any),
}) {

  const {
    mergeInPlace = true,
    comparator = (a: any, b: any) => a === b,
    onDuplicate = 'replace'
  } = options || {}

  const resultA = mergeInPlace ? arrA : [...arrA];
  const mergeFrom = arrB instanceof Array ? arrB : [arrB];

  mergeFrom.forEach((b, i) => {
    const indexOfBInA = resultA.findIndex(a => comparator(a, b));
    if (indexOfBInA >= 0) {
      switch (onDuplicate) {
        case 'push': resultA.push(b); break;
        case 'replace': {
          resultA.splice(indexOfBInA, 1, b);
          break;
        }
        case 'merge': Object.assign(resultA[indexOfBInA], b); break;
        default: {
          if (typeof onDuplicate === 'function') {
            resultA[indexOfBInA] = onDuplicate(resultA[indexOfBInA], b);
          }
          break;
        }
      }
    } else {
      resultA.push(b);
    }
  })
  return resultA;

}

export function sortArray<T>(arr: T[], options?: {
  key?: Nillable<keyof T>,
  direction?: Nillable<SortDirection>,
  transformer?: (input: any) => any,
}) {
  const {
    key,
    direction = 'asc',
    transformer = (i: any) => i
  } = options || {};
  return arr.slice().sort((a, b) => {
    let at = transformer(key ? a[key] : a);
    let bt = transformer(key ? b[key] : b);
    // console.log(at, bt, moment.isMoment(at));
    if (moment.isMoment(at)) {
      if (direction === 'asc') {
        return at.diff(bt);
      } else {
        return bt.diff(at);
      }
    } else if (isNumberLike(at) && isNumberLike(bt)) {
      return direction === 'asc' ? (+at) - (+bt) : (+bt) - (+at);
    } else {
      at = at ? isString(at) ? (at + '').toLowerCase() : JSON.stringify(at) : undefined;
      bt = bt ? isString(bt) ? (bt + '').toLowerCase() : JSON.stringify(bt) : undefined;
      if (direction === 'asc') {
        return at?.localeCompare(bt);
      } else {
        return bt?.localeCompare(at);
      }
    }
  });
}
export const sortByPropName = <T extends HasName>(arr: T[]) => sortArray(arr, { key: 'name' });
export const sortByPropDisplayName = <T extends { displayName: Nillable<string> }>(arr: T[]) => sortArray(arr, { key: 'displayName' });

export const intersection = <A,B>(arrA: A[], arrB: B[]) => uniq(arrB.filter(b => arrA.includes(b as any))) as (A | B)[];
export const hasIntersection = <A, B>(arrA: A[], arrB: B[]) => intersection(arrA,arrB).length > 0;

export const sortRandomly = <T>(arr: T[]) => Array.from(arr).sort((a, b) => .5 - Math.random());

export const replaceContents = <T>(arr: T[], newContent: T[]) => arr.splice(0, arr.length, ...(newContent ?? []));

export const nth = <T>(arr?: T[] | null, item?: T) => {
  if (!item) return null;
  const index = arr?.indexOf(item);
  if (isNil(index)) return null;
  if (index === -1) return null;
  return index + 1;
}

export const asyncForEach = async <T extends () => Promise<R> | R, R>(arr: T[]) => {
  const results = [] as R[];
  for (let fn of arr) {
    results.push(await fn());
  }
  return results;
}

export const isPrimitiveArraysEqual = <T>(arrA: T[], arrB: T[]) => {
  return copyWithJSON(arrA).sort().toString() === copyWithJSON(arrB).sort().toString();
}

export const isArrayAndEmpty = <T>(arr: T[]) => {
  return arr?.length === 0;
}