import { Nullable } from "../base/@types";
import { ApplicationTypeOption, applicationTypeOptionDescriptors, CounsellingType, CounsellingTypeDescriptors } from "../constants/counsellingTypes.descriptors";
import { CounsellingApplicationReadableStatus, CounsellingApplicationSnapshot } from "../models/makeCounsellingApplication.model";

export const getCounsellingApplicationReadableStatus = (
  application?: CounsellingApplicationSnapshot | null
) => {
  let color = '';
  let status = '' as CounsellingApplicationReadableStatus;
  const isApproved = !!application?.timeApproved;
  const isRejected = !!application?.timeRejected
  const isCancelled = !!application?.timeCancelled;
  switch (true) {
    case isApproved:
      color = '#07A78A'; status = 'approved'; break;
    case isRejected:
      color = '#E1503C'; status = 'rejected'; break;
    case isCancelled:
      color = '#85A9BA'; status = 'cancelled'; break;
    default:
      color = '#93B858'; status = 'pending'; break;
  }
  return { color, status }
}

export function isOfCounsellingTypeYoungPeople(type?: CounsellingType | null | string) {
  return type?.includes('young-people');
}

export const getInvitationLink = (uuid?: Nullable<string>, type?: Nullable<CounsellingType>, email?: Nullable<string>) => {
  if (!uuid || !type || !email) return 'Invalid Invitation Link';
  return `${window.location.origin}/app/invitation?invitationId=${uuid}&type=${encodeURIComponent(btoa(type || ''))}&from=${encodeURIComponent(btoa(email || ''))}`;
}

export const getApplicationTypeDescriptor = (type: ApplicationTypeOption | CounsellingType) => applicationTypeOptionDescriptors[type as ApplicationTypeOption] || CounsellingTypeDescriptors.find(d => d.value === type as CounsellingType);

export const isPaidCounselling = (type: ApplicationTypeOption | CounsellingType) => {
  switch (type) {
    case ApplicationTypeOption.PaidOneToOne:
    case CounsellingType.PaidOneToOne:
      return true;
    default:
      return false;
  }
}
export const isFreeCounselling = (type: ApplicationTypeOption | CounsellingType) => {
  switch (type) {
    case ApplicationTypeOption.OneToOne:
    case CounsellingType.OneToOne:
    case ApplicationTypeOption.YoungPeople:
    case CounsellingType.YoungPeople1214:
    case CounsellingType.YoungPeople1517:
    case ApplicationTypeOption.Couples:
    case CounsellingType.Couples:
      return true;
    default:
      return false;
  }
}

export const isOneToOneCounselling = (type: ApplicationTypeOption | CounsellingType) => {
  switch (type) {
    case ApplicationTypeOption.PaidOneToOne:
    case CounsellingType.PaidOneToOne:
    case ApplicationTypeOption.OneToOne:
    case CounsellingType.OneToOne:
      return true;
    default:
      return false;
  }
}