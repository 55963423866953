import { Observer } from 'mobx-react-lite';
import React from 'react';
import joinClassName from '../../utils/className.utils';
import { capitalizeFirstLetter } from '../../utils/string.utils';
import BaseLabel from '../BaseLabel/BaseLabel';
import './InfoDisplayItem.scss';

interface InfoDisplayItemProps {
  label: string | React.ReactElement;
  defaultValue?: string | number | React.ReactElement;
  size?: 'normal' | 'large';
}

const InfoDisplayItem: React.FC<InfoDisplayItemProps> = props => {
  return <Observer children={() => (
    <li className={joinClassName(
      'InfoDisplayItem',
      'InfoDisplayItem' + capitalizeFirstLetter(props.size || 'large')
    )}>
      <BaseLabel>{props.label}</BaseLabel>
      <p className="InfoDisplayItemContent">{props.children === undefined ? props.defaultValue || '-' : props.children }</p>
    </li>
  )} />
}

export default InfoDisplayItem;