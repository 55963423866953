import { always } from "../../../base/utils/ramdaEquivalents.utils";
import { makeObservableRouteDef } from "../../../base/utils/routes.utils";
import ModuleChat from "./Chat.module";

export const ChatModuleRouteDef = makeObservableRouteDef({
  identifier: 'chat',
  urlFactory: always('chats'),
  composeTitle: always('Chats'),
  component: ModuleChat,
  children: [
  ]
})