import { action, extendObservable, flow, observable } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState, Nullable } from '../../../../base/@types';
import BaseButton from '../../../../base/components/BaseButton/BaseButton';
import BaseInput from '../../../../base/components/BaseInput/BaseInput';
import ErrorRenderer from '../../../../base/components/ErrorRenderer/ErrorRenderer';
import InfoBanner from '../../../../base/components/InfoBanner/InfoBanner';
import LoadingBlocker from '../../../../base/components/LoadingBlocker/LoadingBlocker';
import ShadedBlock from '../../../../base/components/ShadedBlock/ShadedBlock';
import { FeeEndpoints } from '../../../../base/endpoints/fees.endpoints';
import { useOnMount } from '../../../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import { asyncForEach, removeFromArray } from '../../../../base/utils/array.utils';
import { reportError } from '../../../../base/utils/errors.utils';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import { forEachProperty } from '../../../../base/utils/object.utils';
import { ModelName } from '../../../../constants/modelNames.enum';
import { APIGetManyResponse } from '../../../../controllers/api.controller';
import { Fee, FeeType, makeFee } from '../../../../models/makeFee.model';
import { User } from '../../../../models/makeUser.model';
import { counsellingFeeTypes, defaultFeeValues, deleteFee, FeeValueMap, getDefaultFeeValue, getFeeTypeDisplayName, saveFee } from '../../../../utils/fees.utils';
import './UserFeesManager.scss';

type UserFeesManagerProps = {
  user: User,
}

const UserFeesManager: React.FC<UserFeesManagerProps> = props => {

  const p = useProps(props);

  const { API, UI } = useControllers();

  const feeTypes = counsellingFeeTypes;
  // const feeTypes = Object.values(FeeType);

  const s = useStore(() => {
    const store = observable({
      isLoading: false,
      form: {} as FeeValueMap,
      _fees: [] as Fee[],
      originalValues: defaultFeeValues,
      errorGettingData: null as Nullable<Error>,
      get feesToUse() {
        return store._fees.filter(f => !store.toDelete.includes(f) && !f.timeDeleted);
      },
      toDelete: [] as Fee[],
      get hasChanges() {
        return feeTypes.some(type => s.originalValues[type] !== s.form[type]);
      },
    })

    const getFeeAmount = (feeType: FeeType) => {
      return s.feesToUse.find(f => f.type === feeType)?.amount
    }

    const setFeeAmount = action((feeType: FeeType, newAmount: number) => {
      const existingFee = s.feesToUse.find(f => f.type === feeType);
      if (existingFee) {
        existingFee.amount = newAmount;
      } else {
        const f = makeFee({ type: feeType, amount: newAmount });
        s._fees.push(f);
      }
    });

    feeTypes.forEach(type => {
      extendObservable(store.form, {
        get [type]() { return getFeeAmount(type) },
        set [type](v: number) { setFeeAmount(type, v) },
      })
    })

    return store;

  });

  const saveACopyToOriginalValues = action(() => {
    Object.entries(Object.getOwnPropertyDescriptors(s.form)).forEach(([key, desc]) => {
      Reflect.set(s.originalValues, key, s.form[key as keyof typeof s.form]);
    })
  })

  const getData = flow(function* (setIsLoading: boolean = true) {
    if (setIsLoading) s.isLoading = true;
    const url = FeeEndpoints.staff.index({
      filter: {
        userId: p.user.id,
      }
    });
    try {
      const { entries: fees } = (yield API.getMany(url, ModelName.fees)) as APIGetManyResponse<Fee>;
      s._fees.splice(0);
      s._fees.push(...fees);
      saveACopyToOriginalValues();
      s.errorGettingData = null;
    } catch (e) {
      s.errorGettingData = e as Error;
      reportError(e);
    } finally {
      s.isLoading = false;
    }
  })

  useOnMount(() => {
    getData();
  });

  const applyChanges = flow(function * () {

    const toAdd = [] as Fee[];
    const toUpdate = [] as Fee[];

    forEachProperty(s.form, (type, value, desc) => {

      const existingFee = s._fees.find(f => f.id && f.type === type);
      const originalValue = s.originalValues[type];
      const defaultValue = getDefaultFeeValue(type);

      const shouldAdd = value && !existingFee && defaultValue !== value;
      const shouldUpdate = existingFee && !s.toDelete.includes(existingFee) && defaultValue !== value && originalValue !== value;

      if (shouldAdd) {
        toAdd.push(
          makeFee({
            amount: value,
            type: type,
            userId: p.user.id
          })
        );
      }
      else if (shouldUpdate) toUpdate.push(existingFee!);

    })

    try {

      const savedAddedFees: Fee[] = yield asyncForEach(toAdd.map(f => async () => await saveFee(API, f)));

      yield asyncForEach(toUpdate.map(f => async () => await saveFee(API, f)));

      yield asyncForEach(s.toDelete.map(f => async () => await deleteFee(API, f)));

      removeFromArray(s._fees, s.toDelete);

      s.toDelete.splice(0);

      s._fees.push(...savedAddedFees);

      getData(false);
      // saveACopyToOriginalValues();

      UI.DIALOG.success({
        heading: 'All changes have been saved.',
      })
    } catch(e) {
      reportError(e);
      UI.DIALOG.error({
        heading: "An error occurred while updating the service fees. Some of your changes might not have been saved.",
        error: e,
      })
      getData();
    }

  });

  const moveFeeOverrideToToDelete = (type: FeeType) => action(() => {
    const toDelete = s.feesToUse.filter(f => f.type === type);
    s.toDelete.push(...toDelete);
  })

  const renderFeeEntryRow = (feeType: FeeType) => {
    return <Observer key={feeType} children={() => (<tr>
      <td className="u-align-right">€{ getDefaultFeeValue(feeType) }</td>
      <td className="u-align-right"><div>€&nbsp;<BaseInput form={s.form} disabled={!!s.errorGettingData || !!p.user.timeDeleted} field={feeType} type="number" step={1} min={0} /></div></td>
      <th>{getFeeTypeDisplayName(feeType)} {(feeType === FeeType.counsellingSession || feeType === FeeType.supportGroup) && <em>(Fallback value)</em>}</th>
      <td>{s.feesToUse.find(f => f.type === feeType) && <BaseButton className="FeeOverrideClearerButton" size="xs" appearance="text" icon="close" iconVariant="filled" onClick={moveFeeOverrideToToDelete(feeType)} label="Clear&nbsp;Override" />}</td>
    </tr>)} />
  }

  return <Observer children={() => (
    <div className="UserFeesManager">
      <p>Default fees are set for each type of sessions that appears in invoices. You can override each sub type with a new fee value for this staff member.</p>
      {s.errorGettingData && <InfoBanner icon="warning" colorCodedState={ColorCodedState.alert}>
        <p>An error occurred while retrieving fee information for this user. Please try again later.</p>
        <ShadedBlock><ErrorRenderer error={s.errorGettingData} /></ShadedBlock>
        <BaseButton onClick={() => getData()} icon="refresh">Retry</BaseButton>
      </InfoBanner>}
      <table>
        <thead>
          <tr>
            <th className="u-align-right">Default</th>
            <th className="u-align-right">Override</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {feeTypes.map(f => renderFeeEntryRow(f))}
        </tbody>
      </table>
      {!p.user.timeDeleted && <BaseButton onClick={applyChanges} fullWidth size="lg" disabled={!!s.errorGettingData || !s.hasChanges}>Apply Changes</BaseButton>}
      { s.isLoading && <LoadingBlocker />}
    </div>
  )} />
}

export default UserFeesManager;