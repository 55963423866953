import React from "react";
import { AlertClient__ApplicationRejection } from "../@types/alert.types";
import DateRenderer from "../base/components/DateRenderer/DateRenderer";
import ShadedBlock from "../base/components/ShadedBlock/ShadedBlock";
import { UIController } from "../controllers/ui.controller";
import { getApplicationTypeDescriptor } from "../utils/counsellingApplication.utils";

export const showApplicationRejectionConfirm = (UI: UIController, alert: AlertClient__ApplicationRejection) => {
  const { data: d } = alert;
  UI.DIALOG.attention({
    heading: 'Application Rejected',
    body: <>
      <p><strong>We have reviewed your {getApplicationTypeDescriptor(d.applicationType).displayName} application (ID: <code>{d.applicantId}</code>) on <DateRenderer value={d.applicationDate} format="MMMM D, YYYY" /> and had made the decision to not move forward with your application.</strong></p>
      {d.reasonForRejection && <>
        <p>The reason provided by our counsellor was:</p>
        <ShadedBlock>
          <p>{d.reasonForRejection}</p>
        </ShadedBlock>
      </>}
      <p>If you believe that this was a mistake, please get in touch with our customer service.</p>
    </>,
  })
}