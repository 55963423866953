import moment from "moment";
import { AnyObject, Identifier, Nillable, Nullable, StandardModel, Undefinable } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { addToArrayIfNew, removeFromArray } from "../base/utils/array.utils";
import { createUTCMoment } from "../base/utils/time.utils";
import { ModelName } from "../constants/modelNames.enum";
import { Tags } from "../constants/tags.constants";
import { CLOCK } from "../controllers/common/clock.controller";
import { hasTimestamps } from "../traits/hasTimestamps.trait";
import { AgeGroupFilterType } from "../utils/ageAndDateOfBirth.utils";
import { ChatParticipant } from "./makeChatParticipant.model";
import { ChatThread } from "./makeChatThread.model";
import { Company } from "./makeCompany.model";
import { InvoiceItem } from "./makeInvoiceItem.models";
import { SupportGroupReservation } from "./makeSupportGroupReservation.model";
import { SupportGroupTopic, SupportGroupTopicSnapshot } from "./makeSupportGroupTopic.model";
import { User } from "./makeUser.model";

export const makeSupportGroupSnapshotBase = () => ({

	id: '' as Identifier,

	title: '',
	subtitle: '',
	slug: '',
	description: '' as Nullable<string>,

	/** @deprecated counsellor report about this group. */
	report: '' as Nullable<string>,

	notes: '',
	scheduledDurationInMinutes: 60,
	ageGroups: [] as AgeGroupFilterType[],
	maxParticipants: 20,

	timeEnded: '' as Nullable<string>,
	timeScheduled: '' as Nullable<string>,
	timeStarted: '' as Nullable<string>,
	timeArchived: '' as Nullable<string>,

	allowedCountryIds: [] as Identifier[],
	/** The facilitator could also be a counsellor, not always an user with a facilitator role */
	facilitatorId: '' as Nillable<Identifier>,
	createdByUserId: '' as Nillable<Identifier>,

	allowedCompanyId: '' as Nillable<Identifier>,

	topicId: '',
	tags: [] as Tags[] | null,

	requiresKeyWorkerInformation: false,

	threadIds: [] as Identifier[],

	/** TODO: review this */
	currentUserHasRegistered: false as Undefinable<boolean>,
	/** TODO: review this */
	currentUserHasBeenRevoked: false as Undefinable<boolean>,
	/** TODO: review this */
	reservationCount: 0,

	reservationIds: [] as Identifier[],

	technicalOrOtherIssues: '',

	invoiceItemIds: [] as string[],

	isSpecialistGroup: false,

	...hasTimestamps(),
})

export type SupportGroupSnapshot = ReturnType<typeof makeSupportGroupSnapshotBase>;
export type SupportGroupRelatedModels = {
	/** The facilitator could also be a counsellor, not always an user with a facilitator role */
	facilitator: User,
	createdByUser: User,
	allowedCompany: Company,
	reservations: SupportGroupReservation[],
	threads: ChatThread[],
	topic?: SupportGroupTopic,
	invoiceItems: InvoiceItem[],
}
export type SupportGroupExtendedProperties = {
	readonly color?: string,
	readonly icon?: string,
	readonly isCompleted: boolean,
	readonly isFull: boolean,
	readonly isToday: boolean | null,
	readonly isTomorrow: boolean | null,
	readonly isTodayOrFuture: boolean | null,
	readonly isInThePast: boolean,
	readonly isUpcoming: boolean,
	readonly isUpcomingWithTomorrow: boolean,
	readonly isFuture: boolean,
	readonly isCancelled: boolean,
	readonly didNotStart: boolean,
	readonly isFor1214: boolean,
	readonly isFor1517: boolean,
	readonly isForAllYoungPeople: boolean,
	readonly isForAdults: boolean,
	readonly notOpenForRsvp: boolean,
	readonly isBillableForSomeStaff: boolean,
	readonly isBillableForAllStaff: boolean,
	readonly isLive?: boolean,
	readonly counsellorId?: Nillable<Identifier>,
	staffParticipants: ChatParticipant[],
	isFrontLineWorkerGroup: boolean,
};
export type SupportGroup = StandardModel<SupportGroupSnapshot, SupportGroupRelatedModels, SupportGroupExtendedProperties>;

export type PreviewSupportGroup = SupportGroupSnapshot & {
	isPreview: boolean,
	topic?: Nillable<SupportGroupTopicSnapshot>,
	facilitator?: Nillable<User>,
	allowedCompany?: Nillable<Company>,
};

export const makeSupportGroup = createStandardModelFactory<SupportGroup, SupportGroupRelatedModels, SupportGroupExtendedProperties>({
	name: ModelName.supportGroups,
	snapshotFactory: makeSupportGroupSnapshotBase,
	accessorOverridesFactory: ($, m, localDB) => ({
		get reservationCount() {
			return $.reservationCount ?? m?.reservations?.length;
		},
		set reservationCount(v) {
			$.reservationCount = v;
		}
	}),
	relationshipsSchema: {
		facilitator: ModelName.users,
		createdByUser: ModelName.users,
		allowedCompany: ModelName.companies,
		reservations: { modelName: ModelName.supportGroupReservations, has: 'many' },
		threads: { modelName: ModelName.chatThreads, has: 'many' },
		topic: ModelName.supportGroupTopics,
		invoiceItems: { modelName: ModelName.invoiceItems, has: 'many' },
	},
	extendedPropertiesFactory: (g, $, localDB) => ({
		get isCompleted() {
			return !!g.timeEnded;
		},
		get isEmpty() {
			return g.reservations.length === 0;
		},
		get isFull() {
			return Boolean(g.maxParticipants && (g.reservations.length >= g.maxParticipants));
		},
		get isLive() {
			return Boolean(!g.timeArchived && !g.timeDeleted && g.timeStarted && !g.timeEnded);
		},
		get isToday() {
			return g.timeScheduled ? createUTCMoment(g.timeScheduled).local().isSame(CLOCK.todayLocalDate, 'day') : false;
		},
		get isTomorrow() {
			const now = moment();
			return g.timeScheduled ? createUTCMoment(g.timeScheduled).local().isSame(now.add(1, 'day'), 'day') : false;
		},
		get isTodayOrFuture() {
			return g.timeScheduled ? createUTCMoment(g.timeScheduled).local().isSameOrAfter(CLOCK.todayLocalDate, 'day') : false;
		},
		get isInThePast() {
			return g.timeScheduled ? createUTCMoment(g.timeScheduled).local().isBefore(CLOCK.localNowMoment) : false;
		},
		get isUpcoming() {
			return Boolean(g.isToday && !g.timeStarted && !g.didNotStart && !g.timeEnded && !g.timeArchived && !g.timeDeleted);
		},
		get isUpcomingWithTomorrow() {
			return Boolean((g.isToday || g.isTomorrow) && !g.timeStarted && !g.didNotStart && !g.timeEnded && !g.timeArchived && !g.timeDeleted);
		},
		get isFuture() {
			return Boolean(g.isTodayOrFuture && !g.timeStarted && !g.didNotStart && !g.timeEnded && !g.timeArchived && !g.timeDeleted);
		},
		get isCancelled() {
			return Boolean(g.timeArchived || g.timeDeleted);
		},
		get isForAllYoungPeople() {
			return g.isFor1214 || g.isFor1517;
		},
		get isFor1214() {
			return g.ageGroups.includes(AgeGroupFilterType.youngPeople1214);
		},
		get isFor1517() {
			return g.ageGroups.includes(AgeGroupFilterType.youngPeople1517);
		},
		get isForAdults() {
			return g.ageGroups.includes(AgeGroupFilterType.adults);
		},
		get color() {
			return g.topic?.color;
		},
		get icon() {
			return g.topic?.icon;
		},
		get didNotStart() {
			return !g.timeStarted && createUTCMoment(g.timeScheduled).add(1, 'hour').isBefore();
		},
		get notOpenForRsvp() {
			return Boolean(g.didNotStart || g.timeStarted || g.timeEnded || g.timeDeleted || g.timeArchived);
		},
		get isFrontLineWorkerGroup() {
			return g.tags?.includes(Tags.FrontLineWorkers) ?? false;
		},
		set isFrontLineWorkerGroup(v) {
			if (!g.tags) g.tags = [];
			if (v) addToArrayIfNew(g.tags, Tags.FrontLineWorkers);
			else removeFromArray(g.tags, Tags.FrontLineWorkers);
		},
		get staffParticipants() {
			return (g.threads.length > 0 ? g.threads[0].participants : []) ?? [];
		},
		get isBillableForSomeStaff() {
			return g.invoiceItems.length > 0;
		},
		get isBillableForAllStaff() {
			return g.staffParticipants.every(part => g.invoiceItems.find(inv => inv.payeeId === part.userId));
		},
		get counsellorId() {
			return g.facilitatorId;
		}
	})
})


export const isSupportGroupModel = (g: Nillable<AnyObject>): g is SupportGroup => g?.$modelName === ModelName.supportGroups;
