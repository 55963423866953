import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Nillable } from '../../base/@types';
import ColorTagAdmin from '../../base/components/ColorTag/ColorTagAdmin';
import ColorTagAnalyst from '../../base/components/ColorTag/ColorTagAnalyst';
import ColorTagCoordinator from '../../base/components/ColorTag/ColorTagCoordinator';
import ColorTagCounsellor from '../../base/components/ColorTag/ColorTagCounsellor';
import ColorTagFacilitator from '../../base/components/ColorTag/ColorTagFacilitator';
import ColorTagFinancialAdministrator from '../../base/components/ColorTag/ColorTagFinancialAdministrator';
import ColorTagModerator from '../../base/components/ColorTag/ColorTagModerator';
import { useProps } from '../../base/utils/mobx.utils';
import { User } from '../../models/makeUser.model';
// import './UserComputedRoleList.scss';

type UserComputedRoleListProps = {
  user?: Nillable<User>
}

const UserComputedRoleList: React.FC<UserComputedRoleListProps> = props => {
  const p = useProps(props);
  return <Observer children={() => (
    <div className="UserComputedRoleList">
      {p.user?.isAdmin && <ColorTagAdmin />}
      {p.user?.isCounsellor && <ColorTagCounsellor user={p.user} />}
      {p.user?.isModerator && <ColorTagModerator />}
      {p.user?.isFacilitator && <ColorTagFacilitator />}
      {p.user?.isCoordinator && <ColorTagCoordinator />}
      {p.user?.isAnalyst && <ColorTagAnalyst />}
      {p.user?.isFinancialAdministrator && <ColorTagFinancialAdministrator />}
    </div>
  )} />
}

export default UserComputedRoleList;