import deepFreeze from "deep-freeze";
import { observable } from "mobx";
import { allValuesTruthy, someValuesTruthy } from "../../base/utils/object.utils";
import { P_ } from "../../constants/permissions.alias";
import { PermissionChecker } from "../../constants/permissions.constants";
import { FEATURE_FLAGS } from "../../env";

export const makePermissionLookupTree = (authControllerPrivateState: { hasPermission: PermissionChecker, hasAnyPermissions: boolean }) => {

	const s = observable({
		get can() {
			return authControllerPrivateState.hasPermission;
		}
	})

	const tree = observable({

		get access_() {
			return deepFreeze({
				adminPanels: authControllerPrivateState.hasAnyPermissions
			})
		},

		get workWith_() {
			const youngPeople = {
				youngPeople1214: s.can(P_.workWithYoungPeople1214),
				youngPeople1517: s.can(P_.workWithYoungPeople1517),
			}
			const ageGroups_ = {
				adults: s.can(P_.workWithAdults),
				youngPeople: allValuesTruthy(youngPeople),
				...youngPeople,
			}
			const all = {
				allAgeGroups: allValuesTruthy(ageGroups_),
				someAgeGroups: someValuesTruthy(ageGroups_),
				...ageGroups_,
				couples: s.can(P_.workWithCouples),
				frontline: s.can(P_.workWithFrontLineWorkers),
			}
			return deepFreeze({
				all: allValuesTruthy(all),
				some: someValuesTruthy(all),
				...all,
			})
		},

		get provideCounsellingFor_() {
			const youngPeople = {
				youngPeople1214: s.can(P_.provideCounselling) && s.can(P_.workWithYoungPeople1214),
				youngPeople1517: s.can(P_.provideCounselling) && s.can(P_.workWithYoungPeople1517),
			}
			const byUserGroups = {
				adult: s.can(P_.provideCounselling) && s.can(P_.workWithAdults),
				couples: s.can(P_.provideCounselling) && s.can(P_.workWithCouples),
				...youngPeople,
			}
			return deepFreeze({
				...byUserGroups,
				youngPeople: allValuesTruthy(youngPeople),
				someUserGroups: someValuesTruthy(byUserGroups),
				allUserGroups: allValuesTruthy(byUserGroups),
			})
		},

		get coordinate_() {
			return deepFreeze({
				counsellingApplications: s.can(P_.coordinateCounsellingApplications),
				counsellingAvailabilities: s.can(P_.coordinateFreeCounsellingAvailabilities) || s.can(P_.coordinatePaidCounsellingAvailabilities),
				counsellingFreeAvailabilities: s.can(P_.coordinateFreeCounsellingAvailabilities),
				counsellingPaidAvailabilities: s.can(P_.coordinatePaidCounsellingAvailabilities),

				counsellingSessions: s.can(P_.coordinateCounsellingSessions),
				supportGroups: s.can(P_.coordinateSupportGroups),
			})
		},

		get supportGroups_() {
			const youngPeople = {
				youngPeople1214: s.can(P_.facilitateSupportGroups) && s.can(P_.workWithYoungPeople1214),
				youngPeople1517: s.can(P_.facilitateSupportGroups) && s.can(P_.workWithYoungPeople1517),
			}
			const userGroups = {
				adult: s.can(P_.facilitateSupportGroups) && s.can(P_.workWithAdults),
				...youngPeople,
				frontline: s.can(P_.facilitateSupportGroups) && s.can(P_.workWithFrontLineWorkers),
			}
			return deepFreeze({
				facilitate_: {
					...userGroups,
					youngPeople: allValuesTruthy(youngPeople),
					someUserGroups: someValuesTruthy(userGroups),
					allUserGroups: allValuesTruthy(userGroups),
				},
				manage_: {
					groups: s.can(P_.facilitateSupportGroups) || s.can(P_.coordinateSupportGroups),
					topics: s.can(P_.manageSupportGroupTopics),
				},
			})
		},

		get useVoip() {
			return FEATURE_FLAGS.ENABLE_VOIP && s.can(P_.useVoipFeature);
		},

		get thoughtCatcher_() {
			const youngPeople = {
				youngPeople1214: s.can(P_.moderateThoughtCatcher) && s.can(P_.workWithYoungPeople1214),
				youngPeople1517: s.can(P_.moderateThoughtCatcher) && s.can(P_.workWithYoungPeople1517),
			}
			const ageGroups = {
				adults: s.can(P_.moderateThoughtCatcher) && s.can(P_.workWithAdults),
				...youngPeople,
			}
			return deepFreeze({
				moderate_: {
					...ageGroups,
					youngPeople: allValuesTruthy(youngPeople),
					someAgeGroups: someValuesTruthy(ageGroups),
					allAgeGroups: allValuesTruthy(ageGroups),
				}
			})
		},

		get chat_() {
			const youngPeople_ = {
				youngPeople1214: s.can(P_.startChatWithClients) && s.can(P_.workWithYoungPeople1214),
				youngPeople1517: s.can(P_.startChatWithClients) && s.can(P_.workWithYoungPeople1517),
			}
			const clients_ = {
				adults: s.can(P_.startChatWithClients) && s.can(P_.workWithAdults),
				...youngPeople_,
			}
			const allTypes_ = {
				staff: s.can(P_.startChatWithStaff),
				client: s.can(P_.startChatWithClients),
				...clients_,
			}
			return deepFreeze({
				startChatWith_: {
					...allTypes_,
					youngPeople: allValuesTruthy(youngPeople_),
					allClients: allValuesTruthy(clients_),
					someClients: someValuesTruthy(clients_),
					someUser: someValuesTruthy(allTypes_),
					allUsers: allValuesTruthy(allTypes_),
				},
				viewAnyChatHistory: s.can(P_.viewAnyChatHistory),
			})
		},

		get accessStats_() {
			const sections = {
				users: s.can(P_.accessStatsUsers),
				demography: s.can(P_.accessStatsDemography),
				onlineCounselling: s.can(P_.accessStatsOnlineCounselling),
				supportGroups: s.can(P_.accessStatsSupportGroups),
				thoughtCatcher: s.can(P_.accessStatsThoughtCatcher),
				donations: s.can(P_.accessStatsDonations),
				counsellors: s.can(P_.accessStatsCounsellors),
				// clinicalSurveys: s.can(P.accessStatsClinicalSurveys),
				satisfactionSurveys: s.can(P_.accessStatsSatisfactionSurveys),
			}
			return deepFreeze({
				...sections,
				someSections: someValuesTruthy(sections),
				allSections: allValuesTruthy(sections),
			})
		},

		get notify_() {
			return deepFreeze({
				anyIndividual: s.can(P_.sendIndividualNotificationsToAnyUser),
			})
		},


		get bill_() {
			return deepFreeze({
				paidSessions: s.can(P_.billPaidCounsellingSessions),
				freeSessions: s.can(P_.billFreeCounsellingSessions),
			})
		},

		get manage_() {

			const partialAdminManageClient = {
				accessPersonalInfo: s.can(P_.accessAnyUsersPersonalInfo),
				updatePersonalInfo: s.can(P_.updateAnyUserPersonalInfo),
				all: s.can(P_.manageAllUsers),
			}

			const clientManagement = {
				accessPersonalInfo: s.can(P_.accessAnyUsersPersonalInfo),
				accessClinicalData: s.can(P_.accessAnyUsersClinicalData), // seems not used.
				updatePersonalInfo: s.can(P_.updateAnyUserPersonalInfo),
				updatePassword: s.can(P_.updateAnyUserPassword),
				muteClient: s.can(P_.muteAnyUser),
				deactivateClient: s.can(P_.suspendAnyClient),
				deleteClient: s.can(P_.deleteAnyClient),
				all: s.can(P_.manageAllUsers),
			}
			return deepFreeze({

				clients_: {
					...clientManagement,
					partialAdminManageClient: someValuesTruthy(partialAdminManageClient),
					partial: someValuesTruthy(clientManagement),
				},

				partnerships: {
					companies: s.can(P_.manageCompanies),
				},

				staff: s.can(P_.manageStaff),
				staffInvoicing: s.can(P_.manageStaffInvoicing),

				counsellingAvailabilities: tree.provideCounsellingFor_.someUserGroups || tree.coordinate_.counsellingAvailabilities,
				counsellingApplications: tree.provideCounsellingFor_.someUserGroups || tree.coordinate_.counsellingApplications,
				counsellingSessions: tree.provideCounsellingFor_.someUserGroups || tree.coordinate_.counsellingSessions,

				permissions: s.can(P_.manageAllUsers),
				allUsers: s.can(P_.manageAllUsers),

				donations: s.can(P_.manageDonations),

				contactForms: s.can(P_.manageContactForms),
				feedback: s.can(P_.manageFeedback),
				satisfactionSurveys: s.can(P_.manageSatisfactionSurveys),
				generalSurveys: s.can(P_.manageGeneralSurveys),
				phq9Surveys: s.can(P_.managePHQ9Surveys),
				gad7Surveys: s.can(P_.manageGAD7Surveys),
				supportGroupSatisfactionSurveys: s.can(P_.manageSupportGroupSatisfactionSurveys),
				supportGroupNonAttendanceSurveys: s.can(P_.manageSupportGroupNonAttendanceSurveys),
				exportCounsellingApplications: s.can(P_.exportCounsellingApplications),

				globalSettings: s.can(P_.manageGlobalSettings),
				serviceCountryAvailabilities: s.can(P_.manageServiceCountryAvailabilities),

			})
		},

	})

	return tree;

}

export type PermissionLookupTree = ReturnType<typeof makePermissionLookupTree>;