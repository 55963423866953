import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useStore } from '../../base/utils/mobx.utils';
import VoipCallEntry from '../VoipCallEntry/VoipCallEntry';
import './VoipLiveCallToast.scss';

type VoipLiveCallToastProps = {}

const VoipLiveCallToast: React.FC<VoipLiveCallToastProps> = props => {

  const { VOIP, AUTH, NAVIGATOR } = useControllers();

  const s = useStore(() => ({
    get shouldRender() {
      return AUTH.can.useVoip && VOIP.lastLiveVoipCall;
    }
  }))

  const goToChatsPage = () => {
    NAVIGATOR.navigateTo('/admin/chats');
  }

  return <Observer children={() => (
    s.shouldRender ? <div className="VoipLiveCallToast" onClick={goToChatsPage}>
      { VOIP.lastLiveVoipCall && <div>
        <VoipCallEntry call={VOIP.lastLiveVoipCall} />
      </div>}
    </div> : null
  )} />
}

export default VoipLiveCallToast;