export const AuthEndpoints = {
  /** POST */
  login: () => '/auth/login',
  /** POST */
  logout: () => '/auth/logout',
  /** POST */
  register: () => '/auth/register',
  verification: {
    /** POST */
    sms: () => '/auth/verify-sms',
    /** POST */
    email: () => '/auth/verify-email',
    resend: {
      /** POST */
      sms: () => '/auth/resend-verification/sms',
      /** POST */
      email: () => '/auth/resend-verification/email',
    }
  },
  otp: {
    /** POST */
    verify: () => '/auth/2fa',
  },
  password: {
    getLink: () => '/auth/password-send-reset-link',
    reset: () => '/auth/password-reset',
    update: () => '/auth/update-password',
  },
  currentUser: {
    verifyPassword: () => '/auth/verify-current-user-password',
    requestAccountDeletion: () => '/auth/request-account-deletion',
  }
}