import React from "react";
import { makeIconDef } from "./makeIconDef";

export const ChatIconDef = {
  chat: makeIconDef('chat', {
    regular: () => <path fillRule="evenodd" clipRule="evenodd" d="M2.99994 13.2857H3V12.2857V5C3 4.44772 3.44772 4 4 4H17C17.5523 4 18 4.44772 18 5V6V7V13C18 13.5513 17.5517 14 16.9985 14H8H7H6.95827H6.54161L6.24821 14.2959L2.99994 17.5714V13.2857ZM7 15H6.95827L2.99994 18.9916L1.99994 20V18.5798V13.2857V12.2857H2V11.2857V5C2 3.89543 2.89543 3 4 3H17C18.1046 3 19 3.89543 19 5V6H20C21.1046 6 22 6.89543 22 8V15V16V21.5858V23L21 22L17 18H9C7.89543 18 7 17.1046 7 16V15ZM19 7V13C19 14.1056 18.1028 15 16.9992 15H16.9985H8V16C8 16.5523 8.44772 17 9 17H17H17.4142L17.7071 17.2929L21 20.5858V16V15V8C21 7.44772 20.5523 7 20 7H19ZM5 6H16V7H5V6ZM12 9H5V10H12V9Z" fill="currentColor" />,
    filled: () => <>
      <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M5.35999 15.9603V14.6002H16.7984C17.903 14.6002 18.8 13.7047 18.8 12.6002V5.3602H20.1601C21.2646 5.3602 22.1601 6.25563 22.1601 7.3602V15.44H22.1603V23.0001L17.1205 17.9603H7.35999C6.25542 17.9603 5.35999 17.0648 5.35999 15.9603Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.00005 2C2.89548 2 2.00005 2.89543 2.00005 4V12.0801H2V19.6402L7.0401 14.6001H16.7985C17.9031 14.6001 18.8001 13.7046 18.8001 12.6001V4C18.8001 2.89543 17.9047 2 16.8001 2H4.00005ZM5.35986 5.36011H15.4399V7.04012H5.35986V5.36011ZM12.9199 9.56006H5.35986V11.2401H12.9199V9.56006Z" fill="currentColor" />
    </>,
    color: () => <>
      <path opacity="0.9" fillRule="evenodd" clipRule="evenodd" d="M5.35999 15.9603V14.6002H16.7984C17.903 14.6002 18.8 13.7047 18.8 12.6002V5.3602H20.1601C21.2646 5.3602 22.1601 6.25563 22.1601 7.3602V15.44H22.1603V23.0001L17.1205 17.9603H7.35999C6.25542 17.9603 5.35999 17.0648 5.35999 15.9603Z" fill="url(#FNOI3BFE)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.00005 2C2.89548 2 2.00005 2.89543 2.00005 4V12.0801H2V19.6402L7.0401 14.6001H16.7985C17.9031 14.6001 18.8001 13.7046 18.8001 12.6001V4C18.8001 2.89543 17.9047 2 16.8001 2H4.00005Z" fill="url(#VPI3OPJF)" />
      <rect x="5.35986" y="5.36011" width="10.0801" height="1.68001" fill="white" />
      <rect x="5.35986" y="9.56006" width="7.56004" height="1.68001" fill="white" />
      <defs>
        <radialGradient id="FNOI3BFE" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19 29.5) rotate(-82.725) scale(23.6907 22.5631)">
          <stop stopColor="#FFCA43" />
          <stop offset="1" stopColor="#F38A2B" />
        </radialGradient>
        <radialGradient id="VPI3OPJF" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19 24.5) rotate(-119.168) scale(24.6221 18.9849)">
          <stop stopColor="#12D4FF" stopOpacity="0.7" />
          <stop offset="1" stopColor="#0096D6" />
        </radialGradient>
      </defs>
    </>
  })
}