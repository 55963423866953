import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseSelector from '../../base/components/BaseSelector/BaseSelector';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { toSentenceCase } from '../../base/utils/string.utils';
import { CounsellingApplication } from '../../models/makeCounsellingApplication.model';
import { CounsellingSession } from '../../models/makeCounsellingSession.model';
import UserInfoTable from '../UserInfoTable/UserInfoTable';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import './CounsellingSessionParticipantsSelector.scss';

interface CounsellingSessionParticipantsSelectorProps {
  session: CounsellingSession;
  application: CounsellingApplication;
}

const CounsellingSessionParticipantsSelector: React.FC<CounsellingSessionParticipantsSelectorProps> = props => {

  const p = useProps(props);
  const s = useStore(() => ({
    get shouldDisable() {
      return Boolean(p.application.timeArchived || p.session.timeStarted || p.application.isCompleted || p.application.isRejected || p.session.isCompleted || p.session.timeDeleted);
    }
  }));

  return <Observer children={() => (
    <ShadedBlock className="CounsellingSessionParticipantsSelector">
      {
        p.application.inviteeId ? <>
          <h4>Who should join this session?</h4>
          <BaseSelector
            appearance="checkboxGroup"
            form={p.session}
            field="clientIds"
            valueEqualityChecker={(a, b) => a === b}
            disabled={s.shouldDisable}
            options={[
              p.application.applicantId && {
                value: p.application.applicantId,
                label: <UsernameRenderer user={p.application.applicant} userId={p.application.applicantId} />
              },
              p.application.inviteeId && {
                value: p.application.inviteeId,
                label: <><UsernameRenderer user={p.application.invitee} userId={p.application.inviteeId} /> {p.application.invitation?.actingAs && `(${toSentenceCase(p.application.invitation?.actingAs)})`}</>
              },
            ]}
          />
        </> : <>
          <h4>Client Overview</h4>
          <UserInfoTable user={p.application.applicant} />
        </>
      }
    </ShadedBlock>
  )} />
}

export default CounsellingSessionParticipantsSelector;