import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseButton from '../../../../base/components/BaseButton/BaseButton';
import BaseSpacer from '../../../../base/components/BaseSpacer/BaseSpacer';
import ShadedBlock from '../../../../base/components/ShadedBlock/ShadedBlock';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import { keepTruthy } from '../../../../base/utils/array.utils';
import { decodeString } from '../../../../base/utils/encoder.utils';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import { autoPluralize } from '../../../../base/utils/string.utils';
import { AppPermission } from '../../../../constants/permissions.constants';
import { User } from '../../../../models/makeUser.model';
import OverlayUserPermissionManager from './OverlayUserPermissionManager';
import './UserPermissionManager.scss';

type UserPermissionManagerProps = {
  user: User
}

const UserPermissionManager: React.FC<UserPermissionManagerProps> = props => {

  const { UI, AUTH } = useControllers();
  
  const p = useProps(props);

  const s = useStore(() => ({
    get selectedPermissions() {
      return keepTruthy(decodeString(p.user.permissionString ?? '').split('_')) as unknown as AppPermission[];
    },
    manage: () => {
      if (!AUTH.can.manage_.permissions) {
        UI.DIALOG.error({
          heading: 'You do not seem to have the permission to manage user permissions',
        })
        return;
      }
      UI.OVERLAY.present({
        component: <OverlayUserPermissionManager user={p.user} />,
        width: '75em',
      })
    }
  }))

  return <Observer children={() => (
    <ShadedBlock className="UserPermissionManager" color="green">
      <p>This user has {autoPluralize(s.selectedPermissions, 'special permissions')}.</p>
      <BaseSpacer size=".5em" />
      <BaseButton icon={p.user.timeDeleted ? undefined : "cog"} fullWidth dataCy="managePermissions" onClick={s.manage}>
        { p.user.timeDeleted ? "View Permissions" : "Manage Permissions" }
      </BaseButton>
    </ShadedBlock>
  )} />
  
}

export default UserPermissionManager;