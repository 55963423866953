import { Observer } from 'mobx-react-lite';
import React from 'react';
import joinClassName from '../../utils/className.utils';
import './BaseSeparator.scss';

interface BaseSeparatorProps {
  className?: string,
  thickness?: number,
}

const BaseSeparator: React.FC<BaseSeparatorProps> = p => {
  return <Observer children={() => (
    <hr className={joinClassName('BaseSeparator', p.className)} style={{
      borderWidth: p.thickness,
    }}/>
  )} />
}

export default BaseSeparator;