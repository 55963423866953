import { Observer } from "mobx-react-lite";
import React from "react";
import BaseSelector, { BaseSelectorProps } from "../../base/components/BaseSelector/BaseSelector";
import { ethnicityDescriptors } from "../../constants/ethnicities.constants";

interface EthnicitySelectorProps<FormType = object> extends Omit<BaseSelectorProps<FormType, {name: string, value: string}, string>, 'options'> {}

export const EthnicitySelector = <T extends object = object>(
  props: React.PropsWithChildren<EthnicitySelectorProps<T>>
) => {
  return <Observer children={() => (
    <BaseSelector<T, { name: string, value: string }, string>
      options={[...ethnicityDescriptors]}
      {...props}
    />
  )} />
}