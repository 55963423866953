import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import BodyCopyRenderer from '../../base/components/BodyCopyRenderer/BodyCopyRenderer';
import ColorTagCompany from '../../base/components/ColorTag/ColorTagCompany';
import ColorTagFacilitator from '../../base/components/ColorTag/ColorTagFacilitator';
import ColorTagFrontLineWorkers from '../../base/components/ColorTag/ColorTagFrontLineWorkers';
import ColorTagLive from '../../base/components/ColorTag/ColorTagLive';
import { ContextColor } from '../../base/constants/color.enum';
import { useControllers } from '../../base/hooks/useRootController.hook';
import joinClassName from '../../base/utils/className.utils';
import { getContextColorStyle } from '../../base/utils/colors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { setUrlParam } from '../../base/utils/urlParams.utils';
import { Tags } from '../../constants/tags.constants';
import { PreviewSupportGroup, SupportGroup } from '../../models/makeSupportGroup.model';
import SessionOrGroupTimeDisplay from '../SessionOrGroupTimeDisplay/SessionOrGroupTimeDisplay';
import SmallHeaderButton from '../SmallHeaderButton/SmallHeaderButton';
import SupportGroupAgeGroupTagSet from '../SupportGroupAgeGroupTagSet/SupportGroupAgeGroupTagSet';
import SupportGroupHasJoinedTag from '../SupportGroupHasJoinedTag/SupportGroupHasJoinedTag';
import SupportGroupIcon from '../SupportGroupIcon/SupportGroupIcon';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import './SupportGroupSummaryCard.scss';

type SupportGroupSummaryCardProps = {
	supportGroup: SupportGroup | PreviewSupportGroup,
	color?: string,
	cardRatio?: number,
	actionOnClick?: 'view' | 'manage',
}

const SupportGroupSummaryCard: React.FC<SupportGroupSummaryCardProps> = React.memo(props => {

	const p = useProps(props);

	const { AUTH, NAVIGATOR } = useControllers();

	const s = useStore(() => ({
		get group() {
			return p.supportGroup
		},
		get isPreview() {
			return (p.supportGroup as PreviewSupportGroup).isPreview;
		},
		get color() {
			return (p.supportGroup as SupportGroup).color ?? p.color;
		},
		get isCancelled() {
			return (p.supportGroup as SupportGroup).isCancelled;
		},
		get colorStyle() {
			return getContextColorStyle(ContextColor.Primary, s.color);
		},
		get maxParticipants() {
			return s.group.maxParticipants;
		},
		get facilitatorId() {
			return s.group.facilitatorId || (s.isPreview ? AUTH.currentUser?.id : '');
		},
		get description() {
			return s.group.description || "";
		},
		handleClick: () => {
			if (s.isPreview) return;
			setUrlParam(p.actionOnClick === 'manage' ? 'manageSupportGroupId' : 'supportGroupId', p.supportGroup.id, NAVIGATOR);
		},
		get isForFrontLineWorkers() {
			return s.group.tags?.includes(Tags.FrontLineWorkers);
		},
		get content() {
			return <div className="SupportGroupSummaryCardInner">
				<header className="SupportGroupSummaryCardHeader">
					<div className="SupportGroupSummaryCardIconWrapper">
						<SupportGroupIcon supportGroup={p.supportGroup} />
					</div>
					<div className="SupportGroupSummaryCardHeaderInner">
						<p className="SupportGroupSummaryCardBeforeTitle">
							<span>
								{s.group.timeStarted && !s.group.timeEnded && <><ColorTagLive /> </>}
								{s.group && <SupportGroupAgeGroupTagSet group={s.group} />}
								Support Group
							</span>
							{!s.isPreview && <SmallHeaderButton color={s.color} />}
						</p>
						<h3 className="SupportGroupSummaryCardTitle">{s.group.title}</h3>
						{s.group.subtitle && <p className="SupportGroupSummaryCardSubtitle">{s.group.subtitle}</p>}
						{s.isForFrontLineWorkers && <ColorTagFrontLineWorkers />}
					</div>
				</header>
				<SessionOrGroupTimeDisplay model={p.supportGroup} />
				<div className="SupportGroupSummaryCardDetails">
					{s.group.currentUserHasRegistered && !s.group.currentUserHasBeenRevoked && <SupportGroupHasJoinedTag />}
					{s.description && <BodyCopyRenderer source={s.description} lineClamp={2} />}
					<footer>
						<footer>
							<div>
								<p><ColorTagFacilitator /> <UsernameRenderer user={s.group.facilitator} showColorLabel /></p>
							</div>
							{!!s.group.allowedCompany && <div>
								<ColorTagCompany name={s.group.allowedCompany?.name} code={s.group.allowedCompany?.code} />
							</div>}
						</footer>
						{AUTH.canFacilitate && <div className="SupportGroupSummaryCardParticipantCount">
							<BaseIcon icon="people" variant="filled" />
							<span>{Math.max(s.group.reservationCount, (s.group as SupportGroup).reservations?.length ?? 0)} / {s.maxParticipants}</span>
						</div>}
					</footer>
				</div>
			</div>
		}
	}));

	return <Observer children={() => (
		<div
			className={
				joinClassName(
					'SupportGroupSummaryCard',
					(s.group as SupportGroup).isUpcoming && 'upcoming',
          s.isPreview && 'preview',
					s.isForFrontLineWorkers && 'isForFrontLineWorkers',
					'support-group-summary',
				)
      }
			data-id={s.group.id}
			style={s.colorStyle}
			onClick={s.handleClick}
      data-cy="support-group-summary"
			tabIndex={0}
			children={s.content}
		/>
	)} />
})

export default SupportGroupSummaryCard;