import { Alert, AlertRelatedModels } from "../../@types/alert.types";
import { DefaultIndexParamSet } from "../utils/api.utils";
import { makeApiEndpointConfig, makeApiGetEndpointConfig } from "../utils/endpoints.utils";
import { makeUrl } from "../utils/url.utils";
import { prefixOwn } from "./namespacePrefixes";

export type AlertEndpointParams = DefaultIndexParamSet<Alert, AlertRelatedModels> & {
  include?: (keyof AlertRelatedModels | string)[],
  unread?: boolean,
}

const ownUrlBase = prefixOwn('alerts');

export const AlertEndpoints = {
  own: {
    index: makeApiEndpointConfig<AlertEndpointParams>(ownUrlBase),
    get: makeApiGetEndpointConfig<AlertEndpointParams>(ownUrlBase),
    create: () => ownUrlBase,
    update: (id: string) => makeUrl(ownUrlBase, id),
    markAllAsRead: () => makeUrl(ownUrlBase, 'mark-all-as-read'),
  },
}