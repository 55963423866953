import { ErrorBoundary } from "@sentry/react";
import { Observer } from "mobx-react-lite";
import React from "react";
import { IOverlay } from "../../../controllers/ui/ui.controller.types";
import { ContextColor } from "../../constants/color.enum";
import { useCreateResizeQueryWithRef } from "../../hooks/useCreateResizeQueryWithRef.hook";
import { useControllers } from "../../hooks/useRootController.hook";
import joinClassName from "../../utils/className.utils";
import { getContextColorStyle } from "../../utils/colors.utils";
import { renderRenderable } from "../../utils/components.utils";
import { useProps, useStore } from "../../utils/mobx.utils";
import './Overlay.scss';

interface P {
  overlay: IOverlay,
  index?: number,
}

const Overlay: React.FC<P> = props => {

  const { UI } = useControllers();

  const p = useProps(props);
  const { ref, query: overlayQuery } = useCreateResizeQueryWithRef();

  const s = useStore(() => ({
    overlay: p.overlay,
    config: p.overlay.config,
    get viewStyle() {
      return {
        ...getContextColorStyle(ContextColor.Primary, p.overlay.config.color),
        paddingTop: UI.fromTablet ? 64 + (p.index ?? 0) * 10 : undefined,
        '--OverlayHeight': Math.ceil(overlayQuery.height) + 1 + 'px', // add 1px to avoid unnecessary scrollbars
      }
    },
    get overlayStyle() {
      return {
        maxWidth: UI.fromTablet ? s.overlay.config.width : undefined,
      }
    }
  }));

  const renderComponent = () => {
    return renderRenderable(p.overlay.config.component);
  }

  return <Observer children={() => {
    return <section
      className={joinClassName(
        "OverlayView",
        s.overlay.status,
        s.config.className,
      )}
      style={s.viewStyle}
      data-name={s.config.name}
      data-id={s.overlay.id}
      data-appearance={s.config.appearance || 'sheet'}
      data-cy={s.overlay.config.dataCy}
      key={s.overlay.id}
    >
      {!s.overlay.noBackdrop && <span className="OverlayBackdrop" />}
      <div className="Overlay" style={s.overlayStyle} ref={ref}>
        <div className="OverlayInner">
          <ErrorBoundary>
            {renderComponent()}
          </ErrorBoundary>
        </div>
      </div>
    </section>
  }} />
}

export default Overlay;