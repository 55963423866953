import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseArticle from '../../../../../base/components/BaseArticle/BaseArticle';
import SimpleCard from '../../../../../base/components/SimpleCard/SimpleCard';
import { useProps } from '../../../../../base/utils/mobx.utils';
import MakePaymentForm from '../../../../../components/MakePaymentForm/MakePaymentForm';
import { CounsellingSessionPaidFee } from '../../../../../constants/counsellingFeeTypes.constants';
import { CounsellingApplicationStepProps } from '../applicationStepDescriptors';
// import './CounsellingApplicationStepPayment.scss';

interface CounsellingApplicationStepPaymentProps extends CounsellingApplicationStepProps { }

const CounsellingApplicationStepPayment: React.FC<CounsellingApplicationStepPaymentProps> = props => {
  const p = useProps(props);
  return <Observer children={() => (
    <div className="CounsellingApplicationStepPayment">
      <BaseArticle>
        <h3>Payment Information</h3>
        <SimpleCard padding="1em">
          <MakePaymentForm
            amount={CounsellingSessionPaidFee.value}
            onInterceptPay={p.onInterceptedPay}
            title={""}
            type={'payment'}
          />
        </SimpleCard>
      </BaseArticle>
    </div>
  )} />
}

export default CounsellingApplicationStepPayment;