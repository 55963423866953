import { flow, observable } from "mobx";
import React from "react";
import { connect, createLocalTracks, LocalAudioTrack, LocalDataTrack, LocalVideoTrack, Room } from "twilio-video";
import { AnyObject } from "../base/@types";
import { ChatParticipantEndpoints } from "../base/endpoints/chatParticipant.endpoints";
import { TwilioEndpoints } from "../base/endpoints/twilio.endpoints";
import { ChatMediator } from "../base/mediators/chat.mediator";
import { getColorHexByName } from "../base/utils/colors.utils";
import { NoOp } from "../base/utils/functions.utils";
import { isNil } from "../base/utils/ramdaEquivalents.utils";
import { getNowTimestampUtc } from "../base/utils/time.utils";
import { getTrustToken } from "../base/utils/trust.utils";
import tick from "../base/utils/waiters.utils";
import JoinVideoCallConfirmation from "../components/JoinVideoCallConfirmation/JoinVideoCallConfirmation";
import { ModelName } from "../constants/modelNames.enum";
import { SHOULD_LOG } from "../env";
import { ChatParticipant } from "../models/makeChatParticipant.model";
import { makeControllerBase, makeRootControllerChildInitFn } from "./_root.controller";

/**
 * Manages video call integrations (Twilio).
 */
export type VideoController = ReturnType<typeof makeVideoController>;

export const makeVideoController = () => {

  const s = observable({
    ...makeControllerBase('VIDEO'),
    shouldEnableAudio: false,
    shouldEnableVideo: false,
    get videoWidth(): number {
      return 480;
    },
    localTracks: [] as (LocalAudioTrack | LocalVideoTrack | LocalDataTrack)[],
    error: null as Error | null,
    // state: '',
    activeRoomsMap: new Map<string, Room>(),
    get shouldFullyCloseMicrophoneWhenDisableAudio() {
      const { UI } = s.ROOT!.children;
      if (UI.deviceInfo.browser.includes('ios')) return false;
      return true;
    },
    get allowAudioAndVideoToggles() {
      return true;
    },
    get activeRoomsArray(): Room[] {
      return Array.from(s.activeRoomsMap.values());
    },
    createLocalTracks: async (options?: { audio: boolean, video: boolean}) => await flow(function * () {
      try {
        const createdTracks = yield createLocalTracks({
          audio: options?.audio ?? true,
          video: options?.video || isNil(options?.video) ? { 
            width: s.videoWidth,
            frameRate: 24,
          } : false,
        });
        s.localTracks.push(...createdTracks);
        return createdTracks;
      } catch(e) {
        s.error = e as Error;
        SHOULD_LOG() && console.error(`Unable to connect to local tracks: ${(e as AnyObject).message}`);
      }
    })(),
    connectToChat: (chat: ChatMediator, trust?: string) => flow(function * () {
      if (chat.selfParticipant?.timeDeclinedVideoCall) return;
      if (trust !== getTrustToken()) {
        yield tick(5000);
        throw Error('Error connecting to chat');
      }
      if (!chat.thread.timeVideoStarted) {
        SHOULD_LOG() && console.warn('Connecting to a chat that does not have video enabled. Silently aborting.')
        return;
      }
      const token = yield _getTwilioVideoGrantToken(chat);
      const confirm = yield s.ROOT!.children.UI.DIALOG.present({
        name: 'JoinVideoCallConfirmation',
        heading: 'Confirm Join Video Call',
        body: <JoinVideoCallConfirmation chat={chat} />,
        actions: [
          {
            name: 'negative',
            label: 'Decline',
            color: getColorHexByName('red'),
            action: NoOp,
          },
          observable({
            name: 'positive',
            label: 'Confirm & Join',
            color: getColorHexByName('green'),
            get disabled() {
              return !s.shouldEnableAudio && !s.shouldEnableVideo;
            },
            action: () => {
              s.localTracks.forEach(track => {
                if (!s.shouldEnableAudio && track.kind === 'audio') {
                  track.disable();
                  if (s.shouldFullyCloseMicrophoneWhenDisableAudio) track.stop();
                }
                if (!s.shouldEnableVideo && track.kind === 'video') {
                  track.disable();
                  track.stop();
                }
              })
              return 'positive';
            }
          })
        ]
      })
      if (!confirm) {
        s.declineCall(chat);
        return;
      }
      if (!s.localTracks.length) yield s.createLocalTracks();
      try {
        const room: Room = yield connect(token, {
          name: chat.uuid,
          tracks: s.localTracks
        });
        SHOULD_LOG() && console.log(`Successfully joined a Room: ${room}`);
        yield chat.saveParticipantIdentity(room.localParticipant.identity);
        s.activeRoomsMap.set(chat.id, room);
        SHOULD_LOG() && console.log(room);
        room.on('participantConnected', participant => {
          SHOULD_LOG() && console.log(`A remote Participant connected: ${participant}`);
        });
        room.on('disconnected', (room: Room) => {
          // Detach the local media elements
          room.localParticipant.tracks.forEach(publication => {
            if ('detach' in publication.track) {
              const attachedElements = publication.track.detach();
              attachedElements.forEach(element => element.remove());
            }
          });
        });
      } catch (e) {
        SHOULD_LOG() && console.error(`Unable to connect to Room: ${(e as AnyObject).message}`);
      }
    })(),
    declineCall: async (chat: ChatMediator) => await flow(function * () {
      if (!chat.selfParticipant) return;
      SHOULD_LOG() && console.log(`Participant #${chat.selfParticipant.id} is declining call...`);
      const payload: Partial<ChatParticipant> = {
        timeDeclinedVideoCall: getNowTimestampUtc()
      }
      const url = ChatParticipantEndpoints.own.update(chat.selfParticipant.id);
      yield s.ROOT!.children.API.patch(url, ModelName.chatParticipants, payload);
      s.cleanupLocalTrack();
    })(),
    disconnectFromChat: flow(function * (chat: ChatMediator) {
      const room = s.activeRoomsMap.get(chat.id);
      if (!room) {
        SHOULD_LOG() && console.warn('Tried to end a video chat that was not active');
        return;
      }
      SHOULD_LOG() && console.log('disconnecting from chat room', room);
      room.disconnect();
      yield tick();
      s.activeRoomsMap.delete(chat.id);
      if (s.activeRoomsMap.size === 0) {
        SHOULD_LOG() && console.log('No rooms are connected at the moment. Disabling local tracks.');
        room.localParticipant.audioTracks.forEach(publication => {
          publication.track.stop();
          publication.unpublish();
        });
        room.localParticipant.videoTracks.forEach(publication => {
          publication.track.stop();
          publication.unpublish();
        })
        s.cleanupLocalTrack();
      }
    }),
    cleanupLocalTrack: () => {
      s.localTracks.forEach(track => {
        if ('stop' in track) {
          if (track.isEnabled) track.disable();
          if (track.isStarted) track.stop();
        }
      })
      s.localTracks.splice(0);
    },
    getRoomByChat: (chat: ChatMediator) => s.activeRoomsMap.get(chat.id),
    canStartVideoChat: (chat: ChatMediator) => {
      return s.ROOT!.children.AUTH.isStaff && s.activeRoomsArray.length === 0;
    },  
    reset: NoOp,
  });

  const _getTwilioVideoGrantToken = flow(function* (chat: ChatMediator) {
    const { UI, API } = s.ROOT!.children;
    const url = TwilioEndpoints.videoRooms.own.getToken(chat.uuid);
    const { accessToken } = (yield API.postRaw(url)).data;
    if (!accessToken) {
      UI.DIALOG.error({
        heading: 'Failed to join video chat room.',
      })
      return null;
    }
    return accessToken;
  })

  s.init = makeRootControllerChildInitFn(
    s,
    () => {
      window.addEventListener('beforeunload', () => {
        Array.from(s.activeRoomsMap.values()).forEach(room => {
          room.disconnect();
        })
      })
    }
  );

  return s;

}