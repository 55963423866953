import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Renderable } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import CurrencyRenderer from '../../base/components/CurrencyRenderer/CurrencyRenderer';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import InfoDisplayItem from '../../base/components/InfoDisplayList/InfoDisplayItem';
import InfoDisplayList from '../../base/components/InfoDisplayList/InfoDisplayList';
import PseudoLink from '../../base/components/PseudoLink/PseudoLink';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { toTitleCase } from '../../base/utils/string.utils';
import { setUrlParam } from '../../base/utils/urlParams.utils';
import { ApiModelName, PseudoApiModelName } from '../../constants/ApiModels.enum';
import { Payment } from '../../models/makePayment.model';
import { getPaymentType } from '../../utils/payment.utils';
import { openOverlayReceipt } from '../OverlayReceipt/OverlayReceipt';
import './PaymentEntry.scss';

type PaymentEntryProps = {
  payment: Payment,
  showSessionId?: boolean,
  showReceiptOverlayButton?: boolean,
}

const PaymentEntry: React.FC<PaymentEntryProps> = props => {
  const { UI, NAVIGATOR } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    get paymentType() { return getPaymentType(p.payment, "") },
    get paymentOrDonationText() {
      return toTitleCase(s.paymentType);
    },
    get PurposeColumn() {
      const label = p.showSessionId ? "Session Ref No." : "Purpose";
      let Children: Renderable = null;
      switch (p.payment.modelType) {
        case ApiModelName.COUNSELLING_SESSION:
          Children = <>
            <PseudoLink onClick={viewSession}>
              {p.showSessionId ? `#${p.payment.modelId}` : "Counselling Session"}
            </PseudoLink> {p.showSessionId ? "" : s.paymentOrDonationText}
          </>;
          break;
        case ApiModelName.SUBSCRIPTION:
          Children = 'Recurring donation';
          break;
        case PseudoApiModelName.DONATION:
          Children = 'One-time donation';
          break;
        default:
          Children = s.paymentOrDonationText;
      }
      return <InfoDisplayItem
        label={label}
        children={Children}
      />;
    },
  }))
  const viewSession = () => {
    if (p.payment.modelType === ApiModelName.COUNSELLING_SESSION)
    setUrlParam('sessionId', p.payment.modelId, NAVIGATOR);
  }
  return <Observer children={() => (
    <div className="PaymentEntry" data-id={p.payment.id}>
      <InfoDisplayList>
        <InfoDisplayItem
          label="Amount"
          children={<CurrencyRenderer value={p.payment.amount} />}
        />
        <InfoDisplayItem
          label="Time"
          children={<DateRenderer value={p.payment.timeCreated} />}
        />
        {s.PurposeColumn}
      </InfoDisplayList>
      {p.showReceiptOverlayButton && <BaseButton
        label="View"
        title="View Receipt"
        rounded
        size="xs"
        className="ViewReceiptButton subtle"
        onClick={() => openOverlayReceipt(UI, p.payment)}
      />}
    </div>
  )} />
}

export default PaymentEntry;