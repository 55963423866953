import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseButton from '../../../../base/components/BaseButton/BaseButton';
import './AllCaughtUpBlock.scss';

type AllCaughtUpBlockProps = {
	title?: string,
	buttonLink?: string,
	buttonLabel?: string,
}

const AllCaughtUpBlock: React.FC<AllCaughtUpBlockProps> = props => {
	return <Observer children={() => (
		<div className="AllCaughtUpBlock">
			<div className="AllCaughtUpBlock">
				<svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fillRule="evenodd" clipRule="evenodd" d="M20 38.75C30.3553 38.75 38.75 30.3553 38.75 20C38.75 17.9411 38.4181 15.9597 37.805 14.1063C37.728 13.8736 37.7853 13.6164 37.9586 13.4431C38.283 13.1186 38.8326 13.2351 38.9777 13.6704C39.6409 15.6596 40 17.7879 40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C26.3512 0 32.0109 2.96043 35.6746 7.57663C36.1626 8.19153 37.0847 8.28141 37.6398 7.72632L40.3661 5C40.6102 4.75592 41.0059 4.75592 41.25 5C41.4941 5.24408 41.4941 5.6398 41.25 5.88388L18.5669 28.5669C18.3229 28.811 17.9271 28.811 17.6831 28.5669L8.125 19.0089C7.88092 18.7648 7.88092 18.3691 8.125 18.125C8.36908 17.8809 8.76481 17.8809 9.00888 18.125L17.2411 26.3572C17.7293 26.8454 18.5207 26.8454 19.0089 26.3572L34.9479 10.4182C35.3674 9.9987 35.4378 9.33919 35.085 8.86217C31.6697 4.24425 26.1845 1.25 20 1.25C9.64466 1.25 1.25 9.64466 1.25 20C1.25 30.3553 9.64466 38.75 20 38.75Z" fill="#07A78A" />
				</svg>
				<p>{props.title ?? 'You are all caught up!'}</p>
				{props.buttonLink && <BaseButton color="blueGreen" className="subtle" to={props.buttonLink} label={props.buttonLabel}/>}
			</div>
		</div>
	)} />
}

export default AllCaughtUpBlock;