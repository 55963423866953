import React from "react";
import { makeIconDef } from "./makeIconDef";

export const BackIconDef = {
  back: makeIconDef('back', {
    regular: () => <g stroke="currentColor" strokeWidth="1" strokeLinecap="round">
      <path d="M12 2L2 12L12 22" stroke="currentColor" />
      <path d="M2 12H22" stroke="currentColor" />
    </g>,
    filled: () => <g stroke="currentColor" strokeWidth="2" strokeLinecap="round">
      <path d="M12 2L2 12L12 22" stroke="currentColor" />
      <path d="M2 12H22" stroke="currentColor" />
    </g>,
  })
}
