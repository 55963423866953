import { action, flow, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseButtonGroup from '../../base/components/BaseButtonGroup/BaseButtonGroup';
import BaseGrid from '../../base/components/BaseGrid/BaseGrid';
import BaseGridCell from '../../base/components/BaseGrid/BaseGridCell';
import BaseSpacer from '../../base/components/BaseSpacer/BaseSpacer';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import FormForm from '../../base/components/FormForm/FormForm';
import FormInput from '../../base/components/FormInput/FormInput';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import UIBlock from '../../base/components/UIBlock/UIBlock';
import { CompanyEndpoints } from '../../base/endpoints/company.endpoints';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { makeForm } from '../../base/mediators/form.mediator';
import joinClassName from '../../base/utils/className.utils';
import { reportError } from "../../base/utils/errors.utils";
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { getSnapshot } from '../../base/utils/snapshot.utils';
import { setUrlParam } from '../../base/utils/urlParams.utils';
import { ModelName } from '../../constants/modelNames.enum';
import { Company, CompanySnapshot, makeCompanySnapshot } from '../../models/makeCompany.model';
import CompanyContactUserInfoTable from '../CompanyContactUserInfoTable/CompanyContactUserInfoTable';
import CompanyInfoTable from '../CompanyInfoTable/CompanyInfoTable';
import './CompanyEditor.scss';
import CompanyApplicationIndexDirectory from './components/CompanyApplicationIndexDirectory';
import CompanySupportGroupIndexDirectory from './components/CompanySupportGroupIndexDirectory';
import CompanyUserIndexDirectory from './components/CompanyUserIndexDirectory';

type CompanyEditorProps = {
  className?: string,
  company?: Company,
  onSave?: (companySnapshot: CompanySnapshot) => void,
  saveChangesOnBlur?: boolean,
  applyChangesImmediately?: boolean,
  disabled?: boolean,
  required?: boolean,
}

const CompanyEditor: React.FC<CompanyEditorProps> = React.memo(props => {

  const { UI, STAFF, NAVIGATOR, API } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    form: makeForm(props.company ? getSnapshot<CompanySnapshot>(props.company) : makeCompanySnapshot()),
    get company() {
      return p.company;
    },
    get isNew() {
      return !s.company?.id;
    },
    handleSave: () => {
      if (s.isNew) createCompany();
      else saveCompany();
      p.onSave && p.onSave(s.form.value);
    },
    handleBlur: action(() => {
      // if (props.company && p.applyChangesImmediately) {
      //   isStandardModel(s.company) ? s.company.$patch(s.form.value) : Object.assign(props.company, s.form.value);
      // }
      if (p.saveChangesOnBlur) {
        s.handleSave();
        s.form.markAsSaved();
      }
    }),

    get currentUserCanManageCompanies() {
      return STAFF.canManageCompanies;
    },
    get canEdit() {
      return s.currentUserCanManageCompanies;
    },

    get canSave() {
      if (!s.canEdit) return false;
      if (s.isNew) return Boolean(s.form.get('name'));
      return Boolean(s.form.hasChanges && s.form.get('name') && s.form.get('code'));
    },
  }))

  useOnMount(() => {
    return when(
      () => !!s.company?.name,
      () => {
        // console.log('valid company, resetting form', p.company?.$getSnapshot());
        s.form.reset(p.company?.$getSnapshot());
      }
    )
  })

  const saveCompany = flow(function* () {
    try {
      const url = CompanyEndpoints.staff.update(s.company!.id);
      const savedCompany = yield API.patch<Company>(url, ModelName.companies, {
        name: s.form.get('name'),
        code: s.form.originalSource.code === s.form.get('code') ? undefined : s.form.get('code'),
      });
      s.form.reset(savedCompany);
      UI.TOAST.success('Company info has been updated.');
    } catch (e) {
      reportError(e);
      UI.DIALOG.error({
        heading: "Failed to save company, please try again",
        body: <ErrorRenderer error={(e as AnyObject).response} />,
      })
    }
  })

  const createCompany = flow(function* () {
    try {
      const url = CompanyEndpoints.staff.create();
      const savedCompany = yield API.post<Company>(url, ModelName.companies, {
        name: s.form.get('name'),
      });
      if (s.isNew && savedCompany.id) {
        UI.OVERLAY.dismiss();
        setUrlParam('editCompanyId', savedCompany.id, NAVIGATOR)
        UI.TOAST.success('Company created.');
        return;
      }
    } catch (e) {
      reportError(e);
      UI.DIALOG.error({
        heading: "Failed to create company, please try again",
        body: <ErrorRenderer error={(e as AnyObject).response} />,
      })
    }
  })

  return <Observer children={() => (
    <div
      className={joinClassName('CompanyEditor', p.className)}
    >
      <BaseGrid columns={1}>
        {s.canEdit
          ? <>
            <FormForm
              form={s.form}
              // onBlur={s.handleBlur}
              disabled={p.disabled || !s.canEdit}
              children={context => <>
                <BaseGridCell columns="all">
                  <FormInput field="name" label="Name" required={s.isNew} />
                </BaseGridCell>
                <BaseSpacer size="sm" />
                {!s.isNew && <BaseGridCell columns="all">
                  <FormInput field="code" label="Company Code / Refugee Code" required={s.isNew} />
                </BaseGridCell>}
              </>}
            />

            {s.isNew
              ? <BaseButton color="green" label="Create" onClick={s.handleSave} disabled={!s.canSave} dataCy="createCompany" />
              : <BaseButtonGroup flex>
                <BaseButton className="subtle" label="Discard Changes" color="red" onClick={() => s.form.reset()} disabled={!s.canSave} />
                <BaseButton color="green" label="Save Changes" onClick={s.handleSave} disabled={!s.canSave} />
              </BaseButtonGroup>
            }
          </>
          : <CompanyInfoTable company={s.company} />
        }

        {
          s.company?.contactUser && <BaseGridCell columns="all">
            <ShadedBlock>
              <CompanyContactUserInfoTable user={s.company?.contactUser} />
            </ShadedBlock>
          </BaseGridCell>
        }

        {!s.isNew && s.canEdit && <>
          <UIBlock title="Employees">
            <CompanyUserIndexDirectory company={s.company} />
          </UIBlock>
          <UIBlock title="Counselling Applications">
            <CompanyApplicationIndexDirectory company={s.company} />
          </UIBlock>
          <UIBlock title="Support Groups">
            <CompanySupportGroupIndexDirectory company={s.company} />
          </UIBlock>
        </>}

      </BaseGrid >
    </div>


  )} />
})

export default CompanyEditor;