import { Nullable, StandardModel, Undefinable } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { cond } from "../base/utils/ramdaEquivalents.utils";
import { ApiModelName } from "../constants/ApiModels.enum";
import { ModelName } from "../constants/modelNames.enum";
import { hasTimestamps } from "../traits/hasTimestamps.trait";
import { SupportGroup } from "./makeSupportGroup.model";
import { User } from "./makeUser.model";

export type FeedbackSnapshot = ReturnType<typeof makeFeedbackSnapshotBase>;
export type FeedbackRelatedModels<T extends FeedbackTargetModel | object = object> = {
	user?: User,
	model?: T,
}
export type FeedbackExtendedProperties = {};

export type FeedbackTargetModelNames = ApiModelName.COUNSELLING_SESSION | ApiModelName.SUPPORT_GROUP | ApiModelName.COUNSELLING_APPLICATION
export type FeedbackTargetModel = SupportGroup;

export type Feedback<T extends object = object> = StandardModel<
	FeedbackSnapshot,
	FeedbackRelatedModels<T>,
	FeedbackExtendedProperties
>

export const makeFeedbackSnapshotBase = () => {
	return {
		id: '',
		rating: null as Nullable<number>,
		body: '',
		userId: '',
		modelId: '',
		modelType: undefined as Undefinable<FeedbackTargetModelNames>,
		...hasTimestamps(),
	}
}
export const makeFeedback = createStandardModelFactory<Feedback, FeedbackRelatedModels, FeedbackExtendedProperties>({
	name: ModelName.feedback,
	snapshotFactory: makeFeedbackSnapshotBase,
	snapshotTypeCastSchema: {
		rating: 'number',
	},
	relationshipsSchemaFactory: s => ({
		user: ModelName.users,
		model: cond([
			[() => s?.modelType === ApiModelName.SUPPORT_GROUP, () => ModelName.supportGroups],
			[() => s?.modelType === ApiModelName.COUNSELLING_SESSION, () => ModelName.counsellingSessions],
			[() => s?.modelType === ApiModelName.COUNSELLING_APPLICATION, () => ModelName.counsellingApplications],
		])()
	})
})