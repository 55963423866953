import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState } from '../../../../base/@types';
import BaseButton from '../../../../base/components/BaseButton/BaseButton';
import BaseButtonGroup from '../../../../base/components/BaseButtonGroup/BaseButtonGroup';
import ErrorRenderer from '../../../../base/components/ErrorRenderer/ErrorRenderer';
import ShadedBlock from '../../../../base/components/ShadedBlock/ShadedBlock';
import { CounsellingApplicationEndpoints } from '../../../../base/endpoints/counsellingApplication.endpoints';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import { reportError } from '../../../../base/utils/errors.utils';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import { always, AlwaysTrueFn, cond } from '../../../../base/utils/ramdaEquivalents.utils';
import { ModelName } from '../../../../constants/modelNames.enum';
import { CounsellingApplication } from '../../../../models/makeCounsellingApplication.model';
import { archiveCounsellingApplication } from '../../../../requests/archiveCounsellingApplication.requests';

interface CounsellingApplicationArchiverProps {
  application: CounsellingApplication
}

const CounsellingApplicationArchiver: React.FC<CounsellingApplicationArchiverProps> = props => {

  const { API, UI } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    get timeApproved() {
      return p.application.timeApproved;
    },
    get timeArchived() {
      return p.application.timeArchived;
    },
    get timeCompleted() {
      return p.application.timeCompleted;
    },
    get sessionsCompleted() {
      return p.application.sessions.filter(ses => ses.isCompleted);
    }
  }));

  const archiveApplication = () => new Promise<boolean>(
    flow(function * (resolve, reject) {
      if (s.timeArchived) {
        reject('The application is already archived.');
        return;
      }
      const confirm = yield UI.DIALOG.attention({
        heading: 'Are you sure you want to archive this application?',
        defaultActions: ['negative', 'positive'],
      })
      if (!confirm) {
        resolve(false);
        return;
      }
      try {
        yield archiveCounsellingApplication(p.application, API);
        UI.DIALOG.success({
          heading: 'Application archived.',
        })
        resolve(true);
      } catch (e) {
        reportError(e);
        UI.DIALOG.error({
          heading: 'Failed to archive this application',
          error: e,
        })
      }
    })
  )
  const unarchiveApplication = () => new Promise<boolean>(
    flow(function * (resolve, reject) {
      if (!s.timeArchived) {
        reject('The application is not archived.');
        return;
      }
      const confirm = yield UI.DIALOG.attention({
        heading: 'Are you sure you want to unarchive this application?',
        defaultActions: ['negative', 'positive'],
      })
      if (!confirm) {
        resolve(false);
        return;
      }
      const { id } = p.application;
      const url = CounsellingApplicationEndpoints.staff.update(id);
      const payload = {
        id,
        timeArchived: null,
      }
      try {
        yield API.patch(url, ModelName.counsellingApplications, payload);
        resolve(true);
        UI.DIALOG.success({
          heading: 'Unarchived successfully.',
        })
      } catch(e) {
        reject(e);
        reportError(e);
        UI.DIALOG.error({
          heading: 'Failed to unarchive this application',
          error: e,
        })
      }
    })
  )

  const completeApplication = () => new Promise<boolean>(
    flow(function* (resolve, reject) {
      if (s.timeCompleted) {
        reject('The application was already completed.');
        return;
      }
      const confirm = yield UI.DIALOG.attention({
        heading: 'Confirmation',
        body: 'The client will be requested to complete a satisfaction survey.',
        defaultActions: ['negative', 'positive'],
      });
      if (!confirm) {
        resolve(false);
        return;
      }
      try {
        const url = CounsellingApplicationEndpoints.staff.complete(p.application.id);
        yield API.post(url, ModelName.counsellingApplications);
        resolve(true);
        UI.DIALOG.success({
          heading: 'Application completed successfully.',
          // body: 'You will be able to view the final clinal survey results when the client submits them.',
        })
      } catch(e) {
        reject(e);
        UI.DIALOG.error({
          heading: 'Failed to complete this application',
          body: <ErrorRenderer error={(e as any).response} />,
        })
      }
    })
  )

  const unsetCompletionState = () => new Promise<boolean>(
    flow(function* (resolve, reject) {
      if (!s.timeCompleted) {
        reject('The application was not completed, aborting');
        return;
      }
      const confirm = yield UI.DIALOG.attention({
        heading: 'Are you sure you want to unset this application\'s completion status?',
        body: 'The satisfaction survey already sent to the client will not be removed.',
        defaultActions: ['negative', 'positive'],
      });
      if (!confirm) {
        resolve(false);
        return;
      }
      try {
        const url = CounsellingApplicationEndpoints.staff.update(p.application.id);
        const payload = { timeCompleted: '', timeArchived: '' } as Partial<CounsellingApplication>;
        yield API.patch(url, ModelName.counsellingApplications, payload);
        resolve(true);
        UI.DIALOG.success({
          heading: 'Application completion status has been reset.',
        })
      } catch(e) {
        reject(e);
        UI.DIALOG.error({
          heading: 'An error occurred...',
          body: <ErrorRenderer error={(e as any).response} />,
        })
      }
    })
  )

  const color = cond([
    [
      () => s.timeCompleted,
      always('green'),
    ],
    [
      AlwaysTrueFn,
      always('median'),
    ],
    // [
    //   AlwaysTrueFn,
    //   always('skyblue'),
    // ],
  ])

  const renderInner = cond([
    [
      () => s.timeCompleted,
      () => <>
        <h3>This application is now marked as completed.</h3>
        <BaseButton
          onClick={unsetCompletionState}
          colorCodedState={ColorCodedState.neutral}
          key="application-completed"
          hoverLabel="Unset completion state"
          hoverColor="orange"
          color="green"
          dataCy="unsetCompletionState"
        >Application Completed</BaseButton>
      </>
    ],
    [
      () => s.timeArchived,
      () => <>
        <h3>This application has been archived.</h3>
        <BaseButton key="unarchive-application" onClick={unarchiveApplication} dataCy="unarchiveApplication">Unarchive Application</BaseButton>
      </>,
    ],
    [
      () => s.timeApproved && s.sessionsCompleted.length > 0,
      () => <>
        <h3>Application Completion</h3>
        <p>You can complete this application, which will mark this application as successfully completed and send out a final satisfaction survey to the client.</p>
        <p>If this application was not completed but still need a conclusion, you can archive it. No survey will be sent to the client(s).</p>
        <BaseButtonGroup>
          <BaseButton onClick={completeApplication} colorCodedState={ColorCodedState.positive} dataCy="completeApplication">Complete Application</BaseButton>
          <BaseButton onClick={archiveApplication} color="median" className="subtle" dataCy="archiveApplication">Archive Application</BaseButton>
        </BaseButtonGroup>
      </>
    ],
    [
      AlwaysTrueFn,
      () => <>
        <h3>Archive Application</h3>
        <BaseButton onClick={archiveApplication} color="median" className="subtle" dataCy="archiveApplication">Archive Application</BaseButton>
      </>
    ]
  ])

  return <Observer children={() => <ShadedBlock
    className="CounsellingApplicationArchiver"
    color={color()}
    spaceChildren
  >
    {renderInner()}
  </ShadedBlock>} />
}

export default CounsellingApplicationArchiver;