import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { createUTCMoment, HHmm, humanizeDuration } from '../../base/utils/time.utils';
import { CLOCK } from '../../controllers/common/clock.controller';
import { CounsellingSession, isCounsellingSessionModel } from '../../models/makeCounsellingSession.model';
import { isSupportGroupModel, PreviewSupportGroup, SupportGroup } from '../../models/makeSupportGroup.model';
import './SessionOrGroupTimeDisplay.scss';

type SessionOrGroupTimeDisplayProps = {
  className?: string,
  model: CounsellingSession | SupportGroup | PreviewSupportGroup
}

const SessionOrGroupTimeDisplay: React.FC<SessionOrGroupTimeDisplayProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    get now() {
      return CLOCK.localNowMoment;
    },
    get scheduledStartTimeMoment() {
      return createUTCMoment(p.model.timeScheduled).local();
    },
    get actualStartTimeMoment() {
      return createUTCMoment(p.model.timeStarted).local();
    },
    get scheduledStartTimeFormatted() {
      return s.scheduledStartTimeMoment.format(HHmm);
    },
    get isThisYear() {
      return s.scheduledStartTimeMoment.isSame(undefined, 'year');
    },
    get scheduledStartDateFormatted() {
      return s.scheduledStartTimeMoment.format('D MMMM');
    },
    get isToday() {
      return s.scheduledStartTimeMoment.isSame(undefined, 'day');
    },
    get isTomorrow() {
      return moment(s.scheduledStartTimeMoment).subtract(1, 'day').isSame(undefined, 'day');
    },
    get scheduledTimeDiffFromNow() {
      return s.scheduledStartTimeMoment.diff(s.now);
    },
    get scheduledTimeDiffFromNowFormatted() {
      return humanizeDuration(s.scheduledTimeDiffFromNow);
    },
    get actualStartTimeDiffFromNow() {
      return s.actualStartTimeMoment.diff(s.now);
    },
    get actualStartTimeDiffFromNowFormatted() {
      return humanizeDuration(s.actualStartTimeDiffFromNow);
    },
    get isScheduledInThePast() {
      return s.scheduledStartTimeMoment.isBefore(s.now);
    },
    get isScheduledInTheFuture() {
      return !s.isScheduledInThePast;
    },
    get hasStarted() {
      return !!p.model.timeStarted;
    },
    get isSessionCancelled() {
      if (isCounsellingSessionModel(p.model)) {
        return p.model.isCancelled;
      }
      return false;
    },
    get isUpcoming() {
      if (isCounsellingSessionModel(p.model)) {
        return p.model.application?.nextSession === p.model || (s.isToday && !p.model.timeEnded);
      } else if (isSupportGroupModel(p.model)) {
        return p.model.isUpcoming;
      }
      return undefined;
    },
    get startingTagContent() {
      if (s.isSessionCancelled && isCounsellingSessionModel(p.model)) {
        return <>Cancelled on <DateRenderer value={p.model.timeCancelled} format='LLL' /></>;
      }
      if (s.hasStarted) {
        if (!p.model.timeEnded) return `Started ${s.actualStartTimeDiffFromNowFormatted} ago`;
        return <>Started on <DateRenderer value={p.model.timeStarted} format='LLL' /></>;
      }
      if (s.isToday && s.isUpcoming) return s.isScheduledInThePast ? `Scheduled to start ${s.scheduledTimeDiffFromNowFormatted} ago` : `Starting in ${s.scheduledTimeDiffFromNowFormatted}`;
      return undefined;
    },
  }))

  return <Observer children={() => (
    <div className={joinClassName('SessionOrGroupTimeDisplay', p.className)}>
      <time className="SessionOrGroupTimeDisplayTime">{s.scheduledStartTimeFormatted}</time>
      <time className="SessionOrGroupTimeDisplayDate">
        {s.isSessionCancelled
          ? <>
            {s.scheduledStartDateFormatted}
            <div className="SessionOrGroupTimeDisplayCancelled">
              Cancelled on <DateRenderer value={isCounsellingSessionModel(p.model) ? p.model.timeCancelled : ''} format='LLL' />
            </div>
          </>
          : s.startingTagContent ? <>
            {s.scheduledStartDateFormatted}
            <div className="SessionOrGroupTimeDisplayDateStartingTag"><span>{s.startingTagContent}</span><br /></div>
          </> : <>
            {s.isToday && <>Today, <br /></>}
            {s.isTomorrow && <>Tomorrow, <br /></>}
            {s.scheduledStartDateFormatted}
          </>
        }
      </time>
    </div>
  )} />
}

export default SessionOrGroupTimeDisplay;