import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useUIController } from '../../hooks/useRootController.hook';
import { useProps, useStore } from '../../utils/mobx.utils';
import TimeframePicker from '../TimeframePicker/TimeframePicker';
import { BaseCalendarBaseChildProps } from './BaseCalendar';
import './BaseCalendarHeader.scss';
import BaseCalendarWeekHeader from './BaseCalendarWeekHeader';

interface BaseCalendarHeaderProps<T = object> extends BaseCalendarBaseChildProps<T> {
}

function BaseCalendarHeader<T extends object>(props: React.PropsWithChildren<BaseCalendarHeaderProps<T>>) {

  const p = useProps(props);

  const UI = useUIController();

  const s = useStore(() => ({
    get calendar() { return p.state },
    get mode() { return s.calendar.mode },
    get startDate() { return s.calendar.startDate },
    get shouldShowWeekHeader() {
      return !s.calendar.shouldDisplayAsTimeline && (s.mode === 'week' || s.mode === 'month');
    },
  }));

  const handleNavigate = action((newStartDate: string) => {
    s.calendar.startDate = newStartDate;
    p.onNavigate && p.onNavigate(newStartDate, s.calendar);
  });
  
  return <Observer children={() => (
    <header className="BaseCalendarHeader">
      <TimeframePicker
        startDate={s.startDate}
        onNavigate={handleNavigate}
        unit={s.mode} 
        icon="chevron"
        iconVariant="filled"
      />
      {s.shouldShowWeekHeader && <BaseCalendarWeekHeader format={UI.onlyPhones ? 'singleChar' : 'long'} />}
    </header>
  )} />

}

export default BaseCalendarHeader;