import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useControllers } from '../../hooks/useRootController.hook';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';

interface P {
  className?: string;
  columnStart?: number;
  columnEnd?: number;
  columns?: number | 'all';
  rowStart?: number;
  rowEnd?: number;
  rows?: number;
  area?: string;
  alignSelf?: string;
  justifySelf?: string;
  style?: React.CSSProperties;
}

const BaseGridCell: React.FC<P> = props => {

  const { UI } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    get style() {
      const { columns, rows, columnStart, columnEnd, rowStart, rowEnd, area, alignSelf, justifySelf } = p;
      return {
        gridArea: area,
        '--grid-column-end': typeof columns === 'number' ? 'span ' + columns : undefined,
        '--row-column-end': rows ? 'span ' + rows : undefined,
        gridColumnStart: columnStart || (columns === 'all' && 'grid-column-start'),
        gridColumnEnd: columnEnd || (columns === 'all' && 'grid-column-end'),
        gridRowStart: rowStart,
        gridRowEnd: rowEnd,
        alignSelf: alignSelf,
        justifySelf: justifySelf,
        flexBasis: UI.cssFeatures.grid ? undefined : columns === 'all' ? '100%' : columns ? 1 / columns * 100 + '%' : undefined,
      } as React.CSSProperties
    }
  }));

  return <Observer children={() => (
    <div
      className={joinClassName("BaseGridCell", p.className)}
      style={{ ...s.style, ...p.style }}
    >
      {props.children}
    </div>)} 
  />
};

export default BaseGridCell;