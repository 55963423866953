import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState } from '../../../../base/@types';
import BaseButton from '../../../../base/components/BaseButton/BaseButton';
import ShadedBlock from '../../../../base/components/ShadedBlock/ShadedBlock';
import { useControllers } from '../../../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import { User } from '../../../../models/makeUser.model';
import { reset2FARequest } from '../../../../requests/resetMFA.requests';
// import './UserMFAManager.scss';

type UserMFAManagerProps = {
  user: User,
  onSuccess?: () => void,
  disabled?: boolean,
}

const UserMFAManager: React.FC<UserMFAManagerProps> = props => {
  const { AUTH, UI, API } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    get has2FA() {
      return p.user.preferences?.has2FA;
    },
    get blockColor() {
      return s.has2FA ? "green" : "red";
    }
  }))
  const reset2FA = () => {
    if (p.disabled) return;
    reset2FARequest(p.user, AUTH, UI, API, p.onSuccess);
  }
  return <Observer children={() => (
    <ShadedBlock className="UserMFAManager" color={s.blockColor} spaceChildren>
      {
        s.has2FA ? <>
          <h3>2FA Active</h3>
          <p>During login, the user will be prompted to enter a code from the Google Authenticator app on their mobile devices.</p>
          <p>If the user lost access to their code or mobile device, resetting their 2FA will allow them to restart the setup process.</p>
          <BaseButton icon="2fa" fullWidth onClick={reset2FA} colorCodedState={ColorCodedState.attention} disabled={p.disabled} dataCy="reset2FA">Reset 2FA</BaseButton>
        </> : <>
          <h3>2FA Inactive</h3>
          <p>Please remind or inform the user to complete the 2FA setup process.</p>
          <p>New staff must complete the setup within two weeks of account creation.</p>
        </>
      }
    </ShadedBlock>
  )} />
}

export default UserMFAManager;