import { defineCustomElements } from '@ionic/pwa-elements/loader';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Elements } from '@stripe/react-stripe-js';
import { configure as configureMobX } from 'mobx';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import ReactDOM from 'react-dom';
import initReactFastClick from 'react-fastclick';
import App from './App';
import { shouldStartSentry } from './base/utils/errors.utils';
import { redirectToHttpsIfNot } from './base/utils/https.utils';
import { LOGGER } from './base/utils/logger.utils';
import { runPolyfills } from './base/utils/polyfills.utils';
import { RootContext, RootControllerInstance } from './contexts/context';
import { SENTRY_ENV } from "./env";
import * as serviceWorker from './serviceWorker';
import './style.scss';
import { initStripe } from './utils/payment.utils';

redirectToHttpsIfNot();

configureMobX({
  enforceActions: 'observed',
  useProxies: 'always'
});

if (shouldStartSentry) {
  Sentry.init({
    dsn: "https://b51d3fd60d5443e18a02dc1c3c7c555a@o4504882344165376.ingest.sentry.io/4505086605131776",
    integrations: [new Integrations.BrowserTracing()],
    environment: SENTRY_ENV,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  });
}

runPolyfills();

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

initReactFastClick();

LOGGER.init();

ReactDOM.render(
  <RootContext.Provider value={RootControllerInstance}>
    <Elements stripe={initStripe}>
      <App />
    </Elements>
  </RootContext.Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
