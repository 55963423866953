import React from "react";
import { makeIconDef } from "./makeIconDef";

export const PeopleIconDef = {
  people: makeIconDef('people', {
    regular: () => <path fillRule="evenodd" clipRule="evenodd" d="M11 5.5C11 6.88071 9.88071 8 8.5 8C7.11929 8 6 6.88071 6 5.5C6 4.11929 7.11929 3 8.5 3C9.88071 3 11 4.11929 11 5.5ZM11.0336 20H3.02799C3.17672 17.3578 3.90565 15.054 4.94479 13.4385C6.00038 11.7973 7.27335 11 8.5 11C9.72664 11 10.9996 11.7973 12.0552 13.4385C12.2737 13.7781 12.4785 14.1483 12.667 14.5455C12.6622 14.5531 12.6575 14.5608 12.6527 14.5684C12.8283 14.9406 12.9897 15.3365 13.1348 15.7533C13.1398 15.7435 13.1447 15.7337 13.1496 15.7238C13.5907 16.981 13.8837 18.4308 13.972 20H13.9469H12.036H11.0336ZM11 21H3C2.44771 21 1.99713 20.5513 2.02651 19.9998C2.3251 14.3934 5.10937 10 8.5 10C10.4181 10 12.1421 11.406 13.3319 13.6422C14.2275 12.6077 15.3204 12 16.5 12C19.331 12 21.6624 15.5 21.9664 20C21.9886 20.3283 22 20.662 22 21H21H14H12H11ZM14.9735 20H20.964C20.8248 18.0806 20.2916 16.3943 19.5358 15.1575C18.6408 13.6929 17.5386 13 16.5 13C15.5905 13 14.6322 13.5314 13.807 14.647C14.4513 16.1857 14.8679 18.0169 14.9735 19.9998C14.9735 19.9999 14.9735 19.9999 14.9735 20ZM8.5 9C10.433 9 12 7.433 12 5.5C12 3.567 10.433 2 8.5 2C6.567 2 5 3.567 5 5.5C5 7.433 6.567 9 8.5 9ZM16.4963 9.93476C17.7944 9.93476 18.8468 8.88239 18.8468 7.58423C18.8468 6.28607 17.7944 5.2337 16.4963 5.2337C15.1981 5.2337 14.1457 6.28607 14.1457 7.58423C14.1457 8.88239 15.1981 9.93476 16.4963 9.93476ZM19.8468 7.58423C19.8468 9.43468 18.3467 10.9348 16.4963 10.9348C14.6458 10.9348 13.1457 9.43468 13.1457 7.58423C13.1457 5.73379 14.6458 4.2337 16.4963 4.2337C18.3467 4.2337 19.8468 5.73379 19.8468 7.58423Z" fill="currentColor" />,
    filled: () => <path fillRule="evenodd" clipRule="evenodd" d="M12 5.5C12 7.433 10.433 9 8.5 9C6.567 9 5 7.433 5 5.5C5 3.567 6.567 2 8.5 2C10.433 2 12 3.567 12 5.5ZM8.5 10C5.10937 10 2.3251 14.3934 2.02651 19.9998C1.99713 20.5513 2.44771 21 3 21H14C14.5523 21 15.0029 20.5513 14.9735 19.9998C14.9028 18.6734 14.6931 17.4148 14.369 16.2663C14.1208 15.2951 13.7806 14.3987 13.3654 13.6042C13.3654 13.6041 13.3652 13.604 13.365 13.6042L13.365 13.6044C13.7774 14.3938 14.1159 15.2837 14.3637 16.2476C13.3159 12.5518 11.0841 10 8.5 10ZM19.8468 7.58423C19.8468 9.43468 18.3467 10.9348 16.4963 10.9348C14.6458 10.9348 13.1458 9.43468 13.1458 7.58423C13.1458 5.73379 14.6458 4.2337 16.4963 4.2337C18.3467 4.2337 19.8468 5.73379 19.8468 7.58423ZM16 20.0769C16 20.5867 16.4133 21 16.9231 21H21C21.5523 21 22.0037 20.5513 21.9664 20.0003C21.6625 15.5002 19.3311 12 16.5 12C15.9652 12 15.4483 12.1249 14.9595 12.3578C14.5026 12.5755 14.3989 13.1473 14.6181 13.6035C15.4965 15.4325 16 17.6767 16 20.0769Z" fill="currentColor" />,
    color: () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.47485 9C10.4078 9 11.9749 7.433 11.9749 5.5C11.9749 3.567 10.4078 2 8.47485 2C6.54185 2 4.97485 3.567 4.97485 5.5C4.97485 7.433 6.54185 9 8.47485 9ZM2.00136 19.9998C2.29995 14.3934 5.08423 10 8.47485 10C11.8655 10 14.6497 14.3934 14.9483 19.9998C14.9777 20.5513 14.5271 21 13.9749 21H2.97485C2.42257 21 1.97199 20.5513 2.00136 19.9998Z" fill="url(#paint0_radial)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.4711 10.9348C18.3216 10.9348 19.8217 9.43468 19.8217 7.58423C19.8217 5.73379 18.3216 4.2337 16.4711 4.2337C14.6207 4.2337 13.1206 5.73379 13.1206 7.58423C13.1206 9.43468 14.6207 10.9348 16.4711 10.9348ZM13.3399 13.6042L13.3399 13.6044C14.2579 15.3615 14.8097 17.617 14.8098 20.0767L14.81 20.0769L14.8102 20.0767C14.8102 19.7656 14.8014 19.4578 14.7841 19.1538C14.6648 17.0544 14.1423 15.1391 13.3403 13.6042C13.3402 13.6041 13.34 13.604 13.3399 13.6042ZM16.8979 21C16.3881 21 15.9748 20.5867 15.9748 20.0769C15.9748 17.6767 15.4714 15.4325 14.5929 13.6035C14.3738 13.1473 14.4774 12.5755 14.9343 12.3578C15.4231 12.1249 15.9401 12 16.4748 12C19.3059 12 21.6374 15.5002 21.9413 20.0003C21.9785 20.5513 21.5271 21 20.9748 21H16.8979Z" fill="url(#paint1_radial)" />
      <defs>
        <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7 5.5) rotate(69.444) scale(21.36 14.5582)">
          <stop stopColor="#009990" />
          <stop offset="1" stopColor="#06B6AB" />
        </radialGradient>
        <radialGradient id="paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(21.7113 24.5297) rotate(-108.942) scale(21.458 11.6571)">
          <stop stopColor="#8BDF7D" />
          <stop offset="1" stopColor="#10A78C" />
        </radialGradient>
      </defs>
    </svg>
  })
}