import { action, observable, reaction, runInAction } from 'mobx';
import { Observer, useLocalObservable } from 'mobx-react-lite';
import React, { Suspense } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import './App.scss';
import { ColorCodedState, Nullable } from './base/@types';
import AppContainer from './base/components/AppContainer/AppContainer';
import AppGenericErrorNotice from './base/components/AppGenericErrorNotice/AppGenericErrorNotice';
import BaseInput from './base/components/BaseInput/BaseInput';
import ErrorBoundary from './base/components/ErrorBoundary/ErrorBoundary';
import HistoryWatcher from './base/components/HistoryWatcher/HistoryWatcher';
import InfoBanner from './base/components/InfoBanner/InfoBanner';
import NavigatorRedirector from './base/components/NavigatorRedirector/NavigatorRedirector';
import Symbols from './base/components/Symbols/Symbols';
import TitleManager from './base/components/TitleManager/TitleManager';
import URLParamWatcher from './base/components/URLParamWatcher/URLParamWatcher';
import { useOnMount } from './base/hooks/lifecycle.hooks';
import { useControllers } from './base/hooks/useRootController.hook';
import { SHOULD_SHOW_LIVE_WARNING } from './base/utils/liveSiteWarning.utils';
import AppSymbols from './components/AppSymbols/AppSymbols';
import FaviconManager from './components/FaviconManager/FaviconManager';
import InternalSiteMarker from './components/InternalSiteMarker/InternalSiteMarker';
import LiveSiteWarningFrame from './components/LiveSiteWarningFrame/LiveSiteWarningFrame';
import RedirectRouteCollection from './components/RedirectRouteCollection/RedirectRouteCollection';
import Turn2MeLogo from './components/Turn2MeLogo/Turn2MeLogo';
import { isInCypressTestMode, isOfficialSite, IS_DEV } from './env';

const App: React.FC = React.memo(props => {

  const { NAVIGATOR, COMMON } = useControllers();

  const s = useLocalObservable(() => ({
    get correctPin() { return '315192' },
    pin: localStorage.getItem('PIN') || '',
    hasError: false,
    get canView() {
      return isOfficialSite || !s.correctPin || isInCypressTestMode || s.comparePIN(s.pin);
    },
    comparePIN: (pin: string) => pin + '' === s.correctPin,
    checkPIN: action(() => {
      const correct = s.comparePIN(s.pin);
      if (correct) s.onPINCorrect();
      else s.hasError = true;
    }),
    onPINCorrect: () => {
      console.log("PIN Correct, setting localStorage", 'PIN', s.pin);
      localStorage.setItem('PIN', s.pin);
      NAVIGATOR.navigateTo('/app/explore');
    },
    onChange: action(() => {
      s.hasError = false;
      localStorage.setItem('PIN', s.pin);
    }),
    DevControlPanel: null as Nullable<React.LazyExoticComponent<React.FC>>,
  }), {
    DevControlPanel: observable.ref,
  });

  useOnMount(() => {
    const disposers = [] as Function[];
    if (!isInCypressTestMode) {
      if (!s.comparePIN(s.pin)) {
        console.log('PIN in localStorage is incorrect.');
        s.pin = '';
      }
      document.getElementById('PreloadIndicatorContainer')?.remove();
      document.documentElement.setAttribute('data-app-version', COMMON.app.version);
      disposers.push(reaction(
        () => s.canView,
        s.onPINCorrect
      ))
    }
    if (IS_DEV) {
      runInAction(() => {
        s.DevControlPanel = React.lazy(() => import('./components/DevControlPanel/DevControlPanel'));
      })
    }
    return disposers.forEach(d => d());
  })
  
  return <Observer children={() => (
    <Router>
      <div className="App">
        <ErrorBoundary fallback={err => <AppGenericErrorNotice />}>
          <Symbols />
          <AppSymbols />

          {
            s.canView ? <AppContainer />
              : <div className="AccessScreen">
              <div className="AccessScreenInner">
                <Turn2MeLogo version="full" />
                <h3>turn2me V2 Demo Access</h3>
                <p><strong>- Restricted Area -</strong></p>
                <p>Please enter the PIN code to continue:</p>
                <BaseInput form={s} field="pin" onEnter={s.checkPIN} onChange={s.onChange} />
                {s.hasError && <InfoBanner colorCodedState={ColorCodedState.error}>
                  <p>The PIN you have entered was incorrect.</p>
                </InfoBanner>}
                <p>© { new Date().getFullYear() } Threadable</p>
              </div>
            </div>
          }

          <URLParamWatcher />
          <HistoryWatcher />
          <FaviconManager />
          <TitleManager />
          <RedirectRouteCollection />

        </ErrorBoundary>

        <NavigatorRedirector />

        { s.DevControlPanel && <Suspense fallback="">
          <s.DevControlPanel />
        </Suspense> }

        <InternalSiteMarker />

        { SHOULD_SHOW_LIVE_WARNING && <LiveSiteWarningFrame /> }
        
      </div>
    </Router>
  )} />

})
export default App;
