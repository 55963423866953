import { Observer } from 'mobx-react-lite';
import React from 'react';

interface CounsellingApplicationIconProps {}

const CounsellingApplicationIcon: React.FC<CounsellingApplicationIconProps> = p => {
  return <Observer children={() => (
    <div className="CounsellingApplicationIcon">
      <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M15.1667 19.3547C17.6981 19.3547 19.7501 17.3027 19.7501 14.7714C19.7501 12.24 17.6981 10.188 15.1667 10.188C12.6354 10.188 10.5834 12.24 10.5834 14.7714C10.5834 17.3027 12.6354 19.3547 15.1667 19.3547ZM25.3297 28.8952C25.8825 30.2297 26.167 31.6601 26.167 33.1047H15.167L4.16699 33.1047C4.16699 31.6601 4.45152 30.2297 5.00432 28.8952C5.55712 27.5606 6.36737 26.348 7.38882 25.3265C8.41026 24.3051 9.62289 23.4948 10.9575 22.942C12.2921 22.3892 13.7225 22.1047 15.167 22.1047C16.6115 22.1047 18.0419 22.3892 19.3765 22.942C20.7111 23.4948 21.9237 24.3051 22.9452 25.3265C23.9666 26.348 24.7769 27.5606 25.3297 28.8952Z" fill="url(#0C081HEK0_linear)" />
          <path fillRule="evenodd" clipRule="evenodd" d="M2.33325 9.93798C2.33325 7.54474 4.27335 5.60464 6.66659 5.60464H38.3333C40.7265 5.60464 42.6666 7.54474 42.6666 9.93798V30.938C42.6666 32.1346 41.6965 33.1046 40.4999 33.1046H26.1667C26.1667 31.6601 25.8822 30.2297 25.3294 28.8951C24.7766 27.5606 23.9664 26.3479 22.9449 25.3265C21.9235 24.305 20.7108 23.4948 19.3763 22.942C18.0417 22.3892 16.6113 22.1047 15.1667 22.1047C13.7222 22.1047 12.2918 22.3892 10.9572 22.942C9.62265 23.4948 8.41002 24.305 7.38857 25.3265C6.36713 26.3479 5.55687 27.5606 5.00407 28.8951C4.45452 30.2219 4.1701 31.6433 4.16678 33.0792C3.12832 32.9189 2.33325 32.0213 2.33325 30.938V9.93798ZM26.1666 12.0214C26.1666 11.5151 26.5821 11.1047 27.0947 11.1047H36.2385C36.7511 11.1047 37.1666 11.5151 37.1666 12.0214V19.3547C37.1666 19.861 36.7511 20.2714 36.2385 20.2714H32.5833L28.6412 23.0214L28.6399 20.2714H27.0947C26.5821 20.2714 26.1666 19.861 26.1666 19.3547V12.0214ZM19.7498 14.7713C19.7498 17.3026 17.6978 19.3547 15.1665 19.3547C12.6352 19.3547 10.5832 17.3026 10.5832 14.7713C10.5832 12.24 12.6352 10.188 15.1665 10.188C17.6978 10.188 19.7498 12.24 19.7498 14.7713Z" fill="url(#0C081HEK1_linear)" />
          <rect x="0.5" y="34.938" width="44" height="3.66666" rx="1.83333" fill="url(#0C081HEK2_linear)" />
        <defs>
          <linearGradient id="0C081HEK0_linear" x1="15.167" y1="10.188" x2="46.9286" y2="22.9957" gradientUnits="userSpaceOnUse">
            <stop stopColor="currentColor" />
            <stop offset="1" stopColor="currentColor" stopOpacity="0.5" />
          </linearGradient>
          <linearGradient id="0C081HEK1_linear" x1="22.4999" y1="5.60464" x2="71.573" y2="35.8368" gradientUnits="userSpaceOnUse">
            <stop stopColor="currentColor" />
            <stop offset="1" stopColor="currentColor" stopOpacity="0.5" />
          </linearGradient>
          <linearGradient id="0C081HEK2_linear" x1="22.5" y1="34.938" x2="25.2967" y2="49.0349" gradientUnits="userSpaceOnUse">
            <stop stopColor="currentColor" />
            <stop offset="1" stopColor="currentColor" stopOpacity="0.5" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )} />
}

export default CounsellingApplicationIcon;