import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import { Nillable } from '../../@types';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import BaseIcon from '../BaseIcon/BaseIcon';
import { IconName } from '../Symbols/iconDefs';
import './IconWithCount.scss';

interface IconWithCountProps {
  className?: string,
  count?: Nillable<number>,
  icon?: IconName,
  href?: string,
  onClick?: () => void,
  notifStyle?: 'dot' | 'number'
  size?: string | number,
  roundedWithBackground?: boolean,
}

const IconWithCount: React.FC<IconWithCountProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    get countDisplay() {
      if (!p.count) return '';
      return p.count > 99 ? '99+' : p.count === 0 ? '' : p.count;
    },
    get className() {
      return joinClassName('IconWithCount', p.className, p.roundedWithBackground && 'roundedWithBackground');
    },
    get commonAttr() {
      return {
        className: s.className,
        children: renderInner(),
        'data-cy' : 'link-to-notification-center',
        'data-appearance': p.notifStyle || 'dot',
        ...props.size && {
          style: {
            width: props.size,
            height: props.size,
          }
        }
      }
    }
  }));

  const renderInner = () => <>
    <BaseIcon name={p.icon} />
    <span>{s.countDisplay}</span>
  </>

  return <Observer children={() => (
    p.href ? <Link to={p.href} {...s.commonAttr} /> :
      <div onClick={p.onClick} {...s.commonAttr} />
  )} />

}

export default IconWithCount;