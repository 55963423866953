import { AnyObject } from "../base/@types";
import { ChatMessageEndpoints } from "../base/endpoints/chatMessage.endpoints";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { ChatMessage } from "../models/makeChatMessage.model";

export function sendOrEditMessageRequest(
  API: APIController,
  config: {
    threadId?: string,
    participantId?: string,
    messageId?: string,
    body: string,
  }
) {
  return new Promise<ChatMessage>(async (resolve, reject) => {

    const { threadId, participantId, messageId, body } = config;

    try {
      const url = messageId ? ChatMessageEndpoints.staff.update(messageId) : ChatMessageEndpoints.own.create();
      const sender = messageId ? API.patch : API.post
      const payload: AnyObject = {
        body: body,
      }
      if (threadId) payload['threadId'] = threadId;
      if (participantId) payload['participantId'] = participantId;
      if (messageId) payload['messageId'] = messageId;
      const savedMessage = await sender<ChatMessage>(url, ModelName.chatMessages, payload);
      if (!savedMessage) {
        throw Error('Unable to send or update a message');
      }
      resolve(savedMessage);
    } catch(e) {
      reject(e);
    }

  })
}