import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Renderable } from '../../base/@types/ui.types';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import UICard, { UICardProps } from '../../base/components/UICard/UICard';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import joinClassName from '../../base/utils/className.utils';
import { renderRenderable } from '../../base/utils/components.utils';
import { useStore } from '../../base/utils/mobx.utils';
import tick from '../../base/utils/waiters.utils';
import { IS_DEV } from '../../env';
import IFrameView from '../IFrameView/IFrameView';
import './ServiceCard.scss';

interface ServiceCardProps extends UICardProps {
  title: Renderable,
  stringTitle: string,
  description?: Renderable,
  illustration?: Renderable,
  autoOpen?: boolean,
  src: string,
  listenForWindowMessage?: string,
  onReceiveWindowMessage?: () => void,
}

const ServiceCard: React.FC<ServiceCardProps> = props => {

  const s = useStore(() => ({
    shouldProgrammaticallyClose: false,
    close: flow(function *() {
      s.shouldProgrammaticallyClose = true;
      yield tick();
      s.shouldProgrammaticallyClose = false;
    })
  }))

  useOnMount(() => {
    if (props.listenForWindowMessage) {
      const handler = (event: MessageEvent) => {
        if (event.data.source?.includes('react-devtools')) return;
        if (IS_DEV) console.log("Message received", event.data);
        if (event.data === props.listenForWindowMessage) {
          s.close(); 
          props.onReceiveWindowMessage?.();
        }
      }
      window.addEventListener("message", handler);
      return (() => {
        window.removeEventListener("message", handler);
      })
    }
  })

  return <Observer children={() => (
    <UICard className={
      joinClassName(
        'ServiceCard',
        props.className
      )
    }
      sideABackgroundColor={props.sideABackgroundColor}
      sideABackgroundImage={props.sideABackgroundImage}
      sideBRenderable={<IFrameView src={props.src} title={props.stringTitle} />}
      animation='flip'
      autoOpen={props.autoOpen}
      shouldProgrammaticallyClose={() => s.shouldProgrammaticallyClose}
    >
      <header>
        <h3>{renderRenderable(props.title)}</h3>
        <BaseIcon />
      </header>
      <figure>
        {props.illustration}
      </figure>
    </UICard>
  )} /> 
}

export default ServiceCard;