import { TimezoneMode } from "../base/@types";
import { useControllers } from "../base/hooks/useRootController.hook";
import { useProps, useStore } from "../base/utils/mobx.utils";
import { createMomentFn } from "../base/utils/time.utils";
import { isFunction } from "../base/utils/typeChecks.utils";
import { Tags } from "../constants/tags.constants";
import { SupportGroup } from "../models/makeSupportGroup.model";
import { supportGroupHasAvailability } from "../utils/supportGroup.helpers";

export function useSupportGroupGetters(
  supportGroup?: SupportGroup | null | (() => SupportGroup | undefined | null),
  timezoneMode?: TimezoneMode,
) {
  const { COMMON } = useControllers();
  const _createMoment = createMomentFn(timezoneMode ?? 'auto');
  const p = useProps({ supportGroup });
  const s = useStore(() => ({
    get supportGroup() {
      return isFunction(p.supportGroup) ? p.supportGroup() : p.supportGroup;
    },
    get sg() {
      return s.supportGroup;
    },
    get topic() {
      return s.sg?.topic?.displayName || 'No topic';
    },
    get title() {
      return s.sg?.title || 'Support Group';
    },
    get description() {
      return s.sg?.description || 'This Support Group has no description.';
    },
    get facilitatorId() {
      return s.sg?.facilitatorId || s.sg?.facilitator?.id;
    },
    get scheduledStartTime() {
      return s.sg?.timeScheduled;
    },
    get scheduledDuration() {
      return s.sg?.scheduledDurationInMinutes || 60
    },
    get scheduledEndTime() {
      return _createMoment(s.sg?.timeScheduled).add(s.scheduledDuration, 'minutes');
    },
    get startTime() {
      return s.sg?.timeStarted;
    },
    get endTime() {
      return s.sg?.timeEnded;
    },
    get isInThePast() {
      const time = s.scheduledStartTime;
      if (!time) return false;
      return _createMoment(time).isBefore(COMMON.CLOCK.localNowMoment);
    },
    get hasJoined() {
      return Boolean(s.sg?.currentUserHasRegistered);
    },
    get hasStarted() {
      return Boolean(s.startTime);
    },
    get hasEnded() {
      return Boolean(s.endTime);
    },
    get isLive() {
      return Boolean(s.hasStarted && !s.hasEnded);
    },
    get numJoined() {
      return s.sg?.reservationCount || s.sg?.reservations?.length || 0;
    },
    get max() {
      return s.sg?.maxParticipants;
    },
    get isEmpty() {
      return s.numJoined === 0;
    },
    get numAvailable() {
      return s.max ? (s.max - s.numJoined) : Infinity;
    },
    get hasAvailability() {
      return s.sg && supportGroupHasAvailability(s.sg);
    },
    get isFull() {
      return !s.hasAvailability;
    },
    get isArchived() {
      return Boolean(s.sg?.timeArchived);
    },
    get isForFrontLineWorkers() {
      return s.sg?.tags?.includes(Tags.FrontLineWorkers);
    }
  }));
  return s;
}