import React from "react";
import { UIController } from "../controllers/ui.controller";
import { User } from "../models/makeUser.model";
import OverlayUserManager from "../modules/Admin/_components/OverlayUserManager/OverlayUserManager";

export const viewUserInOverlay = (
  UI: UIController,
  user?: User,
  userId?: string,
) => {
  UI.OVERLAY.present({
    id: `OverlayUserManager#${user?.id ?? userId}`,
    name: `OverlayUserManager`,
    component: <OverlayUserManager user={user} userId={userId} />,
    duplicateStrategy: 'abort',
    width: '90em',
    noBackdrop: true,
  })
}