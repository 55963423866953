import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useControllers } from '../../hooks/useRootController.hook';
import './DeviceOfflineNotice.scss';

type DeviceOfflineNoticeProps = {}

const DeviceOfflineNotice: React.FC<DeviceOfflineNoticeProps> = props => {

  const { COMMON } = useControllers();

  return <Observer children={() => (
    COMMON.online ? null : <div className="DeviceOfflineNotice">
      Your device seems to be offline. Reconnecting to server...
    </div>
  )} />
  
}

export default DeviceOfflineNotice;