import { action, reaction, runInAction } from "mobx";
import { useEffect } from "react";
import { Nullable } from "../base/@types";
import { useOnMount } from "../base/hooks/lifecycle.hooks";
import { ObservableRef } from "../base/hooks/useObservableRef.hook";
import { useControllers } from "../base/hooks/useRootController.hook";
import { addToArrayIfNew, removeFromArray } from "../base/utils/array.utils";
import { useStore } from "../base/utils/mobx.utils";
import { last } from "../base/utils/ramdaEquivalents.utils";

export const useMakeFocusable = <T extends HTMLElement | SVGElement>(
  ref: ObservableRef<T>,
  handle?: string,
) => {
  const { UI } = useControllers();
  const s = useStore(() => ({
    lastElement: null as Nullable<T>,
    ref,
    get isLastFocused() {
      return last(UI.portalFocusableElementStack) === s.ref.current;
    },
    handleFocus: () => {
      UI.focusOnElementInPortal(s.ref.current);
    },
  }))
  useEffect(() => {
    runInAction(() => {
      s.ref = ref;
    })
  });
  useOnMount(() => {
    const disposers = [] as Function[];
    const documentListener = (e: MouseEvent) => {
      if (!e.target || !handle) return;
      const didClickedOnHandle = (e.target as HTMLElement | SVGElement).closest(handle);
      if (didClickedOnHandle) {
        s.handleFocus();
      }
    }
    if (handle) {
      document.addEventListener('click', documentListener);
      disposers.push(() => {
        document.removeEventListener('click', documentListener);
      })
    }
    disposers.push(
      reaction(
        () => s.ref.current, 
        action((current, prev) => {
          if (prev) {
            removeFromArray(UI.portalFocusableElementStack, prev);
            if (!handle) prev?.removeEventListener('focus', s.handleFocus);
          }
          if (current && current !== s.lastElement) {
            s.lastElement = current;
            addToArrayIfNew(UI.portalFocusableElementStack, current);
            if (!handle) current.addEventListener('focus', s.handleFocus, { capture: true });
          }
        }),
        { fireImmediately: true }
      )
    )
    disposers.push(
      reaction(
        () => s.isLastFocused,
        value => {
          if (value) s.ref.current?.style.setProperty('z-index', '10010');
          else s.ref.current?.style.removeProperty('z-index');
        },
        { fireImmediately: true }
      )
    )
    return () => {
      disposers.forEach(d => d());
      if (!handle) removeFromArray(UI.portalFocusableElementStack, s.lastElement);
    };
  })
  return s;
}