import { action, flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import Hammer from 'react-hammerjs';
import { Nullable } from '../../base/@types';
import { Identifier } from '../../base/@types/traits.types';
import ClickOutside from '../../base/components/ClickOutside/ClickOutside';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import { useControllers } from '../../base/hooks/useRootController.hook';
import joinClassName from '../../base/utils/className.utils';
import { disableSelect, enableSelect } from '../../base/utils/document.utils';
import { getScrollParent } from '../../base/utils/dom.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import tick from '../../base/utils/waiters.utils';
import { ReactionType, ReactionTypes } from '../../constants/ReactionType.enum';
import { makeReaction, ReactableModelNames, Reaction } from '../../models/makeReaction.model';
import { HasTimestamps } from '../../traits/hasTimestamps.trait';
import ReactionIcon from '../ReactionIcon/ReactionIcon';
import './InteractionReactionControlSet.scss';

interface InteractionReactionControlSetProps {
  model?: { reactions: Reaction[] } & HasTimestamps;
  replyToModelType?: ReactableModelNames
  replyToModelId?: Identifier
}

const InteractionReactionControlSet: React.FC<InteractionReactionControlSetProps> = props => {

  const p = useProps(props);

  const { UI, THOUGHT_CATCHER, AUTH, NAVIGATOR } = useControllers();
  const { PORTAL } = UI;
  
  const ref = useRef<HTMLDivElement>(null);

  const s = useStore(() => ({
    get serviceStatus() {
      return THOUGHT_CATCHER.serviceStatusForCurrentUser;
    },
    get reactions() {
      return p.model?.reactions || [];
    },
    get reactionsFromNonSelf() {
      return s.reactions.filter(r => r.userId !== AUTH.currentUser?.id);
    },
    _reaction: makeReaction({
      id: '',
      type: ReactionType.None,
      modelType: p.replyToModelType,
      modelId: p.replyToModelId,
    }),
    get reaction() {
      return s.reactions.find(r => r.userId === AUTH.currentUser?.id) || s._reaction;
    },
    get readonly() {
      return !p.replyToModelType || !p.replyToModelId || p.model?.timeDeleted;
    },
    shouldShowPicker: false,
    left: 0,
    top: 0,
    getPickerPositions: action(() => {
      const boundingBox = ref.current?.getBoundingClientRect();
      s.left = boundingBox?.left || 0;
      s.top = boundingBox?.top || 0;
      const parent = getScrollParent(ref.current);
      const parentBoundingBox = parent?.getBoundingClientRect();
      const parentTop = (parentBoundingBox?.top || 0) - 28;
      const parentBottom = (parentBoundingBox?.bottom || UI.appHeight) + 28;
      if (s.top < parentTop || s.top > parentBottom) {
        s.hidePicker();
      }
    }),
    showPicker: action(() => {
      if (s.isExiting) return;
      s.getPickerPositions();
      s.shouldShowPicker = true;
      s.watchScrollParent();
      // s.disableScroll();
    }),
    watchScrollParent: action(() => {
      if (!ref.current) return;
      const parent = getScrollParent(ref.current);
      if (!parent) return;
      const handler = () => {
        s.getPickerPositions();
      };
      parent.addEventListener('scroll', handler);
      s.disposeWatchScrollParent = () => parent.removeEventListener('scroll', handler);
    }),
    disposeWatchScrollParent: null as Nullable<Function>,
    isExiting: false,
    hidePicker: flow(function * () {
      // s.enableScroll();
      s.isExiting = true;
      yield tick(250);
      s.shouldShowPicker = false;
      yield tick();
      s.isExiting = false;
      s.disposeWatchScrollParent?.();
    }),
    saveReaction: async () => await flow(function * () {
      try {
        yield THOUGHT_CATCHER.saveReaction(s.reaction);
      } catch(e) {
        UI.DIALOG.error({
          heading: 'Failed to react to the thought',
          body: <>
            <p>The thought might no longer exist or there was a network error.</p>
            <ErrorRenderer error={(e as any).response} />
          </>,
        });
      }
    })(),
    handlePress: action((e: HammerInput) => {
      e.preventDefault();
      s.showPicker();
    }),
    get pickerStyle() {
      return {
        left: s.left + 'px',
        top: s.top + 'px',
      }
    },
    selectReactionType: action((t: ReactionType) => {
      if (t === s.reaction.type) {
        s.hidePicker();
        return;
      } else s.reaction.type = t;
      s.hidePicker();
      s.saveReaction();
    }),
    handleMouseDown: async () => {
      await tick();
      disableSelect();
    },
    handleMouseUp: () => {
      enableSelect();
    },
    handleMouseEnter: () => {
    },
    handleMouseLeave: () => {
      enableSelect();
    },
    scrollParentDisabled: null as Element | null,
    get allReactionsByType() {
      return ReactionTypes.map(t => ({
        type: t, reactions: s.reactions?.filter(r => r.type === t) || []
      }))
    },
    get allReactionsByTypeNotEmpty() {
      return s.allReactionsByType.filter(r => r.reactions.length > 0);
    },
    get userSelectedReactionType() {
      return s.reaction.type || ReactionType.None;
    },
    get userHasReacted() {
      return s.reaction.type !== ReactionType.None;
    },
    handleReactionIconClick: action(() => {
      if (s.readonly) return;
      if (!AUTH.isAuthenticated) {
        UI.DIALOG.present({
          heading: 'Please sign up or log in to react to this thought.',
          defaultActions: ['negative'],
          actions: [
            { label: 'Join Now', action: () => NAVIGATOR.navigateTo('/auth/register') },
          ]
        })
        return;
      }
      if (!s.userHasReacted) {
        if (s.shouldShowPicker) return;
        s.selectReactionType(ReactionType.Like);
      } else {
        s.showPicker();
      }
    }),
    get canRemoveReaction(): boolean {
      return Boolean(s.reaction.id && s.reaction.type)
    },
    removeReaction: () => flow(function * () {
      s._reaction.type = ReactionType.None;
      s.reaction.type = ReactionType.None;
      s.hidePicker();
      if (s.reaction.id) yield THOUGHT_CATCHER.removeReaction(s.reaction);
    })(),
    get reactionDisplayEl() {
      return <button 
        className="ReactionDisplay"
        disabled={s.serviceStatus !== 'on'}
      >
        <ReactionIcon
          className="ReactionSelected"
          type={s.userSelectedReactionType}
          onClick={s.serviceStatus === 'on' ? s.showPicker : undefined }
        />
        {s.reactions.length > 0 && <ul className="AllReactionsDisplay">
          {s.allReactionsByTypeNotEmpty.map(rt => <li key={rt.type}>
            <ReactionIcon type={rt.type} /><span>{rt.reactions.length}</span>
          </li>)}
        </ul>}
      </button>
    }
  }));

  return <Observer children={() => (

    <div
      className="InteractionReactionControlSet"
      ref={ref}
      onMouseDown={s.handleMouseDown}
      onMouseUp={s.handleMouseUp}
      onMouseLeave={s.handleMouseLeave}
      data-user-selected-type={s.userSelectedReactionType}
      data-readonly={s.readonly}
      onMouseEnter={s.handleMouseEnter}
    >

      {
        s.readonly ? s.reactionDisplayEl : <Hammer
          onPress={s.handlePress}
          children={s.reactionDisplayEl}
        />
      }

      {s.shouldShowPicker && s.serviceStatus === 'on' && PORTAL.render(
        <ClickOutside onClickOutside={s.hidePicker}>
          <div className={
            joinClassName('ReactionPicker', s.isExiting && 'exiting')
          } style={s.pickerStyle}>
            <div className="ReactionPickerList">
              {ReactionTypes.map(type => <ReactionIcon
                key={type} type={type}
                onClick={s.selectReactionType}
              />)}
            </div>
            {s.canRemoveReaction && <button
              className="ReactionRemoveButton"
              onClick={s.removeReaction}
            >Remove your reaction</button>}
          </div>
        </ClickOutside>
      )}

    </div>
    
  )} />
  
}

export default InteractionReactionControlSet;