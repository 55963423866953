import { Nullable, StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { ModelName } from "../constants/modelNames.enum";
import { hasTimestamps } from "../traits/hasTimestamps.trait";
import { User } from "./makeUser.model";

export enum FeeType {
  counsellingSession = 'counselling-session',
  counsellingSessionOneToOne = 'counselling-session-one-to-one',
  counsellingSessionCouples = 'counselling-session-couples',
  counsellingSessionYoungPeople = 'counselling-session-young-people',
  supportGroup = 'support-group',
  supportGroupAdult = 'support-group-adult',
  supportGroupYoungPeople = 'support-group-young-people',
  supportGroupFrontLineWorkers = 'support-group-front-line-workers',
  supportGroupSpecialistGroups = 'support-group-specialist-groups',

  counsellingSessionAccreditedCounsellor = 'counselling-session-accredited-counsellor',
  counsellingSessionPreAccreditedCounsellor = 'counselling-session-pre-accredited-counsellor',
  counsellingSessionPlacementCounsellor = 'counselling-session-placement-counsellor',
};

export const makeFeeSnapshot = () => ({
  id: '',
  amount: 0,
  userId: '' as Nullable<string>,
  type: null as Nullable<FeeType>,
  createdByUserId: null as Nullable<string>,
  ...hasTimestamps(),
})

export type FeeSnapshot = ReturnType<typeof makeFeeSnapshot>;

export type FeeRelatedModels = {
  user: Nullable<User>,
  createdByUser: Nullable<User>,
}

export type FeeExtendedProperties = {}

export type Fee = StandardModel<FeeSnapshot, FeeRelatedModels, FeeExtendedProperties>;

export const makeFee = createStandardModelFactory<Fee, FeeRelatedModels, FeeExtendedProperties>({
  name: ModelName.fees,
  snapshotFactory: makeFeeSnapshot,
  relationshipsSchema: {
    user: { modelName: ModelName.users, has: 'one' },
    createdByUser: { modelName: ModelName.users, has: 'one'},
  },
})