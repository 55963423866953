import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { staffOpenOverlayAddressEditor } from '../../actions/staffOpenOverlayAddressEditor';
import { Nillable } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseGrid from '../../base/components/BaseGrid/BaseGrid';
import CountryRenderer from '../../base/components/CountryRenderer/CountryRenderer';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import EmailLinkRenderer from '../../base/components/EmailLinkRenderer/EmailLinkRenderer';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import InfoTable from '../../base/components/InfoTable/InfoTable';
import PseudoLink from '../../base/components/PseudoLink/PseudoLink';
import TelLinkRenderer from '../../base/components/TelLinkRenderer/TelLinkRenderer';
import { useControllers } from '../../base/hooks/useRootController.hook';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { getNowTimestampUtc } from '../../base/utils/time.utils';
import { setUrlParam } from '../../base/utils/urlParams.utils';
import { User } from '../../models/makeUser.model';
import { sendSaveUserRequest } from '../../requests/saveUser.request';
import { useGetUser } from '../../requests/useGetUser.request';
import { getAgeFromDateOfBirth } from '../../utils/ageAndDateOfBirth.utils';
import AddressRenderer from '../AddressRenderer/AddressRenderer';
import DateOfBirthRenderer from '../DateOfBirthRenderer/DateOfBirthRenderer';
import GenderRenderer from '../GenderRenderer/GenderRenderer';
import SexualityRenderer from '../SexualityRenderer/SexualityRenderer';
import UserCard from '../UserCard/UserCard';
import UserCountryRenderer from '../UserCountryRenderer/UserCountryRenderer';
import './UserInfoTable.scss';

interface UserInfoTableProps {
  user?: Nillable<User>,
  userId?: string,
  columns?: 1 | 2,
  shaded?: boolean,
  showViewDetailsForAdmins?: boolean,
  canManualVerify?: boolean,
}

const UserInfoTable: React.FC<UserInfoTableProps> = props => {

  const p = useProps(props);
  const { STAFF, AUTH, UI, API, NAVIGATOR } = useControllers();

  const s = useStore(() => ({
    _user: p.user,
    get user() {
      return p.user || s._user;
    },
    get userId() {
      return p.userId || p.user?.id;
    },
    get canManualVerifyEmail() {
      return p.canManualVerify && s.user?.email && !s.user.timeVerifiedEmail;
    },
    get canManualVerifyPhone() {
      return p.canManualVerify && s.user?.mobileNumber && !s.user.timeVerifiedMobileNumber;
    },
		get canViewPII() {
      const hasCoreRoles = AUTH.isCounsellor || AUTH.isFacilitator || AUTH.isModerator;
      const hasSpecificPermissions = AUTH.can.manage_.clients_.accessPersonalInfo || AUTH.can.manage_.clients_.updatePersonalInfo;
      const allowStaffManageStaff = s.user?.isStaff && AUTH.can.manage_.staff;
			return hasCoreRoles || hasSpecificPermissions || allowStaffManageStaff;
		},
    get userAddress() {
      if (!s.user) return null;
      return s.user.primaryAddress ? s.user.primaryAddress : s.user.addresses && s.user.addresses.length > 0 ? s.user.addresses[0] : null;
    },
    get canEditAddress() {
      return Boolean(s.userAddress) && STAFF.currentUserIsCounsellor; // t2m specifically requests counsellors be able to edit client address.
    },
  }));

  const editApplicationAddress = () => {
    if (s.canEditAddress) {
      staffOpenOverlayAddressEditor(UI, s.userAddress!);
    }
  }

  useGetUser({
    observable: s,
    key: '_user',
    when: () => !s._user,
  }, s.userId);

  const showDetails = () => {
    setUrlParam('userId', s.userId, NAVIGATOR);
  }

  const verifyEmail = () => new Promise<boolean>(flow(function * (resolve, reject) {
    if (!s.user || !s.user.email) return;
    const confirm = yield UI.DIALOG.present({
      heading: `Are you sure you want to mark this user's email (${s.user.email}) as verified?`,
    })
    if (!confirm) {
      resolve(false);
      return;
    }
    try {
      const payload = {
        id: s.userId,
        timeVerifiedEmail: getNowTimestampUtc()
      };
      yield sendSaveUserRequest(API, payload);
      if (s.user) s.user.timeVerifiedEmail = payload.timeVerifiedEmail;
      if (p.user) p.user.timeVerifiedEmail = payload.timeVerifiedEmail;
      UI.DIALOG.success({ heading: 'User email marked as verified.' })
      resolve(true);
    } catch(e) {
      UI.DIALOG.error({
        heading: 'Error updating user information',
        body: <ErrorRenderer error={(e as any).response} />
      })
    }
  }))

  const verifyPhone = () => new Promise<boolean>(flow(function * (resolve, reject) {
    if (!s.user || !s.user.mobileNumber) return;
    const confirm = yield UI.DIALOG.attention({
      heading: `Are you sure you want to mark this user's phone number (${s.user.mobileNumber}) as verified?`,
      defaultActions: ['negative', 'positive'],
    })
    if (!confirm) {
      resolve(false);
      return;
    }
    try {
      const payload = {
        id: s.userId,
        timeVerifiedMobileNumber: getNowTimestampUtc()
      };
      yield sendSaveUserRequest(API, payload);
      if (s.user) s.user.timeVerifiedMobileNumber = payload.timeVerifiedMobileNumber;
      if (p.user) p.user.timeVerifiedMobileNumber = payload.timeVerifiedMobileNumber;
      UI.DIALOG.success({ heading: 'User phone marked as verified.' })
      resolve(true);
    } catch(e) {
      UI.DIALOG.error({
        heading: 'Error updating user information',
        body: <ErrorRenderer error={(e as any).response} />
      })
    }
  }))

  return <Observer children={() => {
    const {
      id,
      username, firstName, company, lastName, dateOfBirth,
      email, mobileNumber, timeCreated, timeDeleted,
      ipAddress, gender, sexuality,
      timeVerifiedEmail, timeVerifiedMobileNumber
    } = s.user || {};
    const columns = UI.fromTablet ? p.columns ?? 2 : 1;
    const gap = columns > 1 ? '.5em' : "0em";
    return (
      <div className={
        joinClassName(
          'UserInfoTable',
          p.shaded && 'shaded',
        )
      } data-user-id={id}>
        {
          s.user && (
            <BaseGrid columns={columns} gap={gap}>
              <InfoTable>
                <tbody>
                  <tr><th>Username</th><td>{username}</td></tr>
                  {s.canViewPII && <>
                    <tr><th>Name</th><td>{firstName} {lastName}</td></tr>
                    <tr><th>Date of Birth</th><td><DateOfBirthRenderer value={dateOfBirth} showAge={false} /> (Age: {dateOfBirth ? getAgeFromDateOfBirth(dateOfBirth) : '-'})</td></tr>
                    <tr><th>Email</th><td>{timeDeleted ? email : <EmailLinkRenderer value={email} />}</td></tr>
                    <tr><th>Phone</th><td>{timeDeleted ? mobileNumber : <TelLinkRenderer value={mobileNumber} />}</td></tr>
                    <tr><th>Gender</th><td><GenderRenderer value={gender ?? '-'} /></td></tr>
                    <tr><th>Sexuality</th><td><SexualityRenderer value={sexuality ?? '-'} /></td></tr>
                    <tr><th>Company</th><td>{company?.name ?? ''}</td></tr>
                  </>}
                  <tr><th>User Icon</th><td>{s.user.username && <UserCard user={s.user} iconSize="2em" />}</td></tr>
                </tbody>
              </InfoTable>
              <InfoTable>
                <tbody>
                  {s.canViewPII && <>
                    <tr><th>Member since</th><td><DateRenderer value={timeCreated} format="LL" /></td></tr>
                    <tr><th>Email&nbsp;verified&nbsp;at</th><td>{
                    timeVerifiedEmail ? <DateRenderer className="u-positive" value={timeVerifiedEmail} /> : !timeDeleted && <>
                      Not verified {s.canManualVerifyEmail && <PseudoLink onClick={verifyEmail}>(Mark as verified?)</PseudoLink>}
                    </>
                    }</td></tr>
                    <tr><th>Phone&nbsp;verified&nbsp;at</th><td>{
                    timeVerifiedMobileNumber ? <DateRenderer className="u-positive" value={timeVerifiedMobileNumber} /> : !timeDeleted && <>
                      Not verified {s.canManualVerifyPhone && <PseudoLink onClick={verifyPhone}>(Mark as verified?)</PseudoLink>}
                    </>
                    }</td></tr>
                    <tr><th>{s.user.primaryAddress ? 'Primary Address' : 'Address'}</th><td className='UserAddress'>{s.userAddress ? <AddressRenderer address={s.userAddress} /> : '-'}{s.canEditAddress && <BaseButton onClick={editApplicationAddress} size="xs" className="subtle">Edit</BaseButton>}</td></tr>
                    <tr><th>Last login IP:</th><td><code>{ipAddress} {ipAddress && ipAddress !== '127.0.0.1' && s.user.countryDetectedId && <>(<CountryRenderer countryId={s.user.countryDetectedId} />)</>}</code></td></tr>
                    <tr><th>Country</th><td><UserCountryRenderer user={s.user} withLineBreaks showWarningIfMismatch /></td></tr>
                    {timeDeleted && <tr><th>Deleted at:</th><td><DateRenderer value={timeDeleted} format="LL" /></td></tr>}
                  </>}
                  {p.showViewDetailsForAdmins ? <tr>
                    <th>View Details</th>
                    <td><PseudoLink onClick={showDetails}>View Details</PseudoLink></td>
                  </tr> : null}
                </tbody>
              </InfoTable>
            </BaseGrid>
          )
        }
      </div>
    )
  }} />
}

export default UserInfoTable;