import { ChatMessage, ChatMessageRelatedModels } from "../../models/makeChatMessage.model";
import { DefaultIndexParamSet } from "../utils/api.utils";
import { makeApiEndpointConfig, makeApiGetEndpointConfig } from "../utils/endpoints.utils";
import { makeUrl } from "../utils/url.utils";
import { prefixOwn, prefixStaff } from "./namespacePrefixes";

export type ChatMessageEndpointParams = DefaultIndexParamSet<ChatMessage, ChatMessageRelatedModels> & {
  include?: (keyof ChatMessageRelatedModels | string)[],
  latest?: boolean,
  cursor?: string | number,
}

const ownUrlBase = prefixOwn('chat-messages');
const staffUrlBase = prefixStaff('chat-messages');

export const ChatMessageEndpoints = {
  own: {
    index: makeApiEndpointConfig<ChatMessageEndpointParams>(ownUrlBase),
    get: makeApiGetEndpointConfig<ChatMessageEndpointParams>(ownUrlBase),
    create: () => ownUrlBase,
  },
  staff: {
    index: makeApiEndpointConfig<ChatMessageEndpointParams>(staffUrlBase),
    get: makeApiGetEndpointConfig<ChatMessageEndpointParams>(staffUrlBase),
    create: () => staffUrlBase,
    update: (id: string) => makeUrl(staffUrlBase, id),
    delete: (id: string) => makeUrl(staffUrlBase, id),
  },
}