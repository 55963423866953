import { defaultUserColors, isHexCode } from "../../base/utils/colors.utils";
import { getRandomItemFromArray } from "../../base/utils/random.utils";
import { User } from "../../models/makeUser.model";
import { AuthController } from "../auth.controller";

export const assignRandomColorIfNull = async (user: User, AUTH: AuthController) => {
  const color = user.color;
  if (!color || !isHexCode(color) || color === '#0000') {
    user.color = getRandomItemFromArray(defaultUserColors);
    await AUTH.saveCurrentUser();
  }
}