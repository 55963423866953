import { HasId } from "../@types";
import { ChannelInstanceTemplate, ValidChannelType } from "../@types/channels.types";

export function makeGlobalChannelTemplate<T extends ValidChannelType = ValidChannelType>(): ChannelInstanceTemplate<T, HasId> {
  return ({
    channelNameFactory: () => 'Global',
    isPublic: true,
  })
}

export function makeUserPrivateChannelTemplate<T extends ValidChannelType = ValidChannelType>(): ChannelInstanceTemplate<T, HasId> {
  return ({
    channelNameFactory: (params: HasId) => `App.User.${params.id}`,
    isPublic: false,
  })
}
