import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ModelName } from '../../../constants/modelNames.enum';
import { SupportGroupTopic } from '../../../models/makeSupportGroupTopic.model';
import { useGetSupportGroupTopic } from '../../../requests/useGetSupportGroupTopic.request';
import { Nillable } from '../../@types';
import { useControllers } from '../../hooks/useRootController.hook';
import { useProps, useStore } from '../../utils/mobx.utils';
import { getUrlParams, useSyncUrlParams } from '../../utils/urlParams.utils';
import AppPage from '../AppPage/AppPage';
import AppPageContent from '../AppPageContent/AppPageContent';
import AppPageHeader from '../AppPageHeader/AppPageHeader';
import LoadingIndicatorSection from '../LoadingIndicatorSection/LoadingIndicatorSection';
import OverlayCloseButton from '../OverlayCloseButton/OverlayCloseButton';
import SupportGroupTopicEditor from '../SupportGroupTopicEditor/SupportGroupTopicEditor';
import UIBlock from '../UIBlock/UIBlock';
// import './OverlaySupportGroupTopicEditor.scss';

type OverlaySupportGroupTopicEditorProps = {
  topic?: Nillable<SupportGroupTopic>,
  topicId?: Nillable<string>,
  onSave?: (topic: SupportGroupTopic) => void;
}

const OverlaySupportGroupTopicEditor: React.FC<OverlaySupportGroupTopicEditorProps> = props => {
  const p = useProps(props);
  const { LOCALDB } = useControllers();
  const s = useStore(() => ({
    idFromParam: getUrlParams().manageSupportGroupTopicId,
    get id() {
      return p.topic?.id || p.topicId || s.idFromParam;
    },
    get isNew() {
      return !Boolean(s.id);
    },
    get topic() {
      return p.topic || (s.id ? LOCALDB.get<SupportGroupTopic>(ModelName.supportGroupTopics, s.id) : undefined);
    },
    get title() {
      return s.isNew ? 'Create New Topic' : (s.topic?.displayName || 'Edit Topic');
    },
  }));
  useSyncUrlParams('manageSupportGroupTopicId', s.id);
  useGetSupportGroupTopic();
  return <Observer children={() => (
    <AppPage 
      className="OverlaySupportGroupTopicEditor"
      color={s.topic?.color || 'blueGreen'}
    >
      <AppPageHeader
        beforeTitle="Support Group Topic"
        title={s.title}
        endSlot={<OverlayCloseButton />}
      />
      <AppPageContent>
        <UIBlock padded>
          { !s.topic && <LoadingIndicatorSection /> }
          { s.topic && <SupportGroupTopicEditor topic={s.topic} /> }
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
}

export default OverlaySupportGroupTopicEditor;