import { Observer } from 'mobx-react-lite';
import React from 'react';
import DateRenderer, { DateRendererProps } from '../../base/components/DateRenderer/DateRenderer';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { autoPluralize } from '../../base/utils/string.utils';
import { createUTCMoment } from '../../base/utils/time.utils';
import { getAgeFromDateOfBirth } from '../../utils/ageAndDateOfBirth.utils';

interface DateOfBirthRendererProps extends DateRendererProps {
  showAge?: boolean,
}

const DateOfBirthRenderer: React.FC<DateOfBirthRendererProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    get moment() {
      return p.value ? createUTCMoment(p.value) : null;
    },
    get age() {
      return p.value ? getAgeFromDateOfBirth(p.value) : null;
    },
    get showAge() {
      return p.showAge === undefined ? true : p.showAge;
    }
  }));
  
  return <Observer children={() => {
    return <div className="DateOfBirthRenderer" data-value={p.value}>
      <DateRenderer value={p.value} format={p.format || 'LL'} timezoneMode="utc" /> {s.showAge && s.age && <em>({autoPluralize(s.age, 'year')} old)</em>}
    </div>
  }} />
}

export default DateOfBirthRenderer;