import { Identifier, Nillable, Nullable, StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { ModelName } from "../constants/modelNames.enum";
import { hasTimestamps } from "../traits/hasTimestamps.trait";
import { Address } from "./makeAddress.model";
import { User } from "./makeUser.model";

export type ContactSnapshot = ReturnType<typeof makeContactSnapshotBase>;

export type ContactRelatedModels = {
	owner: User,
	address?: Address,
}
export type ContactExtendedProperties = {};

export type Contact = StandardModel<
	ContactSnapshot,
	ContactRelatedModels,
	ContactExtendedProperties
>

export enum ContactType {
	EmergencyContact = 'emergency-contact',
	KeyWorker = 'key-worker',
}

export const makeContactSnapshotBase = () => ({
	id: '',
	name: '',
	phone: '' as Nillable<string>,
	email: '' as Nillable<string>,
	type: null as Nullable<ContactType>,
	ownerId: '' as Nullable<Identifier>,
	addressId: '' as Nullable<Identifier>,
	...hasTimestamps(),
})

export const makeContact = createStandardModelFactory<Contact, ContactRelatedModels, ContactExtendedProperties>({
	name: ModelName.contacts,
	snapshotFactory: makeContactSnapshotBase,
	relationshipsSchema: {
		owner: ModelName.users,
		address: ModelName.addresses,
	}
})