import { SupportGroupTopicEndpointParams, SupportGroupTopicEndpoints } from "../base/endpoints/supportGroupTopic.endpoints";
import { useControllers } from "../base/hooks/useRootController.hook";
import { ModelName } from "../constants/modelNames.enum";
import { SupportGroupTopic } from "../models/makeSupportGroupTopic.model";
import { useAutoGet, UseAutoGetFactoryOptions } from "../utils/useAutoGet.utils";

export function useGetSupportGroupTopic<ObservableType = object>(
  options?: UseAutoGetFactoryOptions<SupportGroupTopic, ObservableType>,
  id?: string | null,
  params?: SupportGroupTopicEndpointParams,
) {
  const { AUTH } = useControllers();
  const canUseAdminEndpoint = AUTH.can.supportGroups_.manage_.topics;
  const _params = params || {}
  useAutoGet<SupportGroupTopic, ObservableType>({
    ...options,
    url: id ? SupportGroupTopicEndpoints[canUseAdminEndpoint ? 'staff' : 'client'].get(id, _params) : undefined,
    modelName: ModelName.supportGroupTopics,
  });
}