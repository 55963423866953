import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState, Renderable } from '../../@types';
import ErrorRenderer from '../ErrorRenderer/ErrorRenderer';
import InfoBanner from '../InfoBanner/InfoBanner';
// import './ErrorBanner.scss';

type ErrorBannerProps = {
  heading?: Renderable,
  e?: string | Error | null | unknown | any,
  error?: string | Error | null | unknown | any,
}

const ErrorBanner: React.FC<ErrorBannerProps> = props => {
  return <Observer children={() => (
    <InfoBanner className="ErrorBanner" colorCodedState={ColorCodedState.error} icon="warning" heading={props.heading}>
      <ErrorRenderer error={props.e ?? props.error} />
    </InfoBanner>
  )} />
}

export default ErrorBanner;