import { Validator } from "../@types";

export const validatePhone: Validator = (
  value?: string | number,
  ignoreEmptyString?: boolean
) => {
  const isValid = (ignoreEmptyString && value?.toString() === "") || !!value?.toString();
  const message = value ? (
    `${value?.toString()} is not a valid phone number.`
    ) : (
      'Please provide a valid phone number.'
    );
  return isValid || {
    isValid,
    message
  }
}