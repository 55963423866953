import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import { toTitleCase } from '../../base/utils/string.utils';
import { CommunicationType, communicationTypeDescriptors } from '../../constants/communicationTypes.descriptors';
import './CommunicationTypeRenderer.scss';

interface CommunicationTypeRendererProps {
  value?: CommunicationType | null,
  textOnly?: boolean,
}

const CommunicationTypeRenderer: React.FC<CommunicationTypeRendererProps> = p => {
  const descriptor = p.value ? communicationTypeDescriptors[p.value] : undefined;
  return <Observer children={() => (
    p.value ? <div className="CommunicationTypeRenderer" data-primary-color={descriptor?.colorName} data-value={p.value}>
      {p.textOnly === true ? null : descriptor?.icon && <BaseIcon icon={descriptor?.icon} variant="color" />}
      <span>{descriptor?.name ?? toTitleCase(p.value)}</span>
    </div> : null
  )} />
}

export default CommunicationTypeRenderer;