import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Nillable } from '../../base/@types';
import { getGreetingTimeOfDay } from '../../base/bots/responses/greetings.botResponses';
import OverlayCloseButton from '../../base/components/OverlayCloseButton/OverlayCloseButton';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import tick from '../../base/utils/waiters.utils';
import { Thought } from '../../models/makeThought.model';
import { scrollExploreModuleToThoughtCard } from '../../utils/thought.utils';
import ThoughtEditor from '../ThoughtEditor/ThoughtEditor';
import './OverlayThoughtComposer.scss';

type OverlayThoughtComposerProps = {
  thought?: Nillable<Thought>,
}

const OverlayThoughtComposer: React.FC<OverlayThoughtComposerProps> = props => {

  const { THOUGHT_CATCHER, AUTH, UI } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    thought: p.thought || THOUGHT_CATCHER.thoughtOfTheDay,
    get greeting() {
      return getGreetingTimeOfDay(AUTH.currentUser);
    },
  }))

  const onDiscardChanges = () => {
    UI.OVERLAY.dismiss('OverlayThoughtComposer');
  }
  
  const onSuccess = async (thought: Thought) => {
    UI.OVERLAY.dismiss('OverlayThoughtComposer');
    scrollExploreModuleToThoughtCard(UI, thought.id);
    await tick(2000);
    THOUGHT_CATCHER.setup();
  }

  return <Observer children={() => (
    <div className="OverlayThoughtComposer" data-emotion={s.thought?.emotionId}>
      <OverlayCloseButton color="white" />
      <header className="OverlayThoughtComposerHeader">
        <p>{s.greeting}</p>
        <h3>How are you feeling today?</h3>
      </header>
      <div className="OverlayThoughtComposerContent">
        {s.thought && <ThoughtEditor
          thought={s.thought}
          applyChangesImmediately
          canChangeEmotionAfterSelection
          showEmotionTextLabel={UI.fromTablet}
          onDiscardChanges={onDiscardChanges}
          onSuccess={onSuccess}
        />}
      </div>
    </div>
  )} />
}

export default OverlayThoughtComposer;