import { always } from "../../../../../base/utils/ramdaEquivalents.utils";
import { makeObservableRouteDef } from "../../../../../base/utils/routes.utils";
import AccountPageEmergencyContacts from "./AccountPageEmergencyContacts.page";

export const AccountPageEmergencyContactsRouteDef = makeObservableRouteDef({
  identifier: 'account-emergency-contacts',
  urlFactory: always('emergency-contacts'),
  composeTitle: always('Emergency Contacts'),
  component: AccountPageEmergencyContacts,
  icon: 'warning',
})