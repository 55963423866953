import { Identifier, Nillable, StandardModel } from "../base/@types"
import { createStandardModelFactory } from "../base/factories/standardModel.factory"
import { ModelName } from "../constants/modelNames.enum"
import { hasTimestamps } from "../traits/hasTimestamps.trait"
import { CounsellingApplication } from "./makeCounsellingApplication.model"
import { CounsellingAvailability } from "./makeCounsellingAvailability.model"
import { SupportGroup } from "./makeSupportGroup.model"
import { User } from "./makeUser.model"

export const makeCompanySnapshot = () => ({
  id: '',
  name: '' as string,
  contactUserId: null as Nillable<string>,
  color: '' as Nillable<string>,
  code: '' as string,

  employeeIds: [] as Identifier[],
  applicationIds: [] as Identifier[],
  availabilityIds: [] as Identifier[],
  supportGroupIds: [] as Identifier[],

  ...hasTimestamps(),
})

export type CompanySnapshot = ReturnType<typeof makeCompanySnapshot>;

export type CompanyRelatedModels = {
  contactUser: User,
  employees: User[],
  applications?: CounsellingApplication[],
  availabilities?: CounsellingAvailability[],
  supportGroups?: SupportGroup[],
}

export type CompanyExtendedProperties = {}

export type Company = StandardModel<CompanySnapshot, CompanyRelatedModels, CompanyExtendedProperties>;

export const makeCompany = createStandardModelFactory<Company, CompanyRelatedModels, CompanyExtendedProperties>({
  name: ModelName.companies,
  snapshotFactory: makeCompanySnapshot,
  relationshipsSchema: {
    contactUser: ModelName.users,
    employees: { modelName: ModelName.users, has: 'many' },
    applications: { modelName: ModelName.counsellingApplications, has: 'many' },
    availabilities: { modelName: ModelName.counsellingAvailabilities, has: 'many' },
    supportGroups: { modelName: ModelName.supportGroups, has: 'many' },
  },
})
