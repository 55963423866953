import { always } from "../../../../../base/utils/ramdaEquivalents.utils";
import { makeObservableRouteDef } from "../../../../../base/utils/routes.utils";
import AccountPageManageDonations from "./AccountPageManageDonations.page";

export const AccountPageManageDonationsRouteDef = makeObservableRouteDef({
  identifier: 'account-manage-donations',
  urlFactory: always('manage-donations'),
  composeTitle: always('Manage Donations'),
  component: AccountPageManageDonations,
  icon: 'euro',
})