import { Subscription, SubscriptionRelatedModels } from "../../models/makeSubscription.model";
import { DefaultIndexParamSet } from "../utils/api.utils";
import { makeApiEndpointConfig, makeApiGetEndpointConfig } from "../utils/endpoints.utils";
import { prefixOwn, prefixStaff } from "./namespacePrefixes";

const ownUrlBase = prefixOwn('donation/subscription');
const staffUrlBase = prefixStaff('donation/subscription');

export type DonationSubscriptionParams = DefaultIndexParamSet<Subscription, SubscriptionRelatedModels> & {
}

export const DonationSubscriptions = {
  own: {
    create: () => `${ownUrlBase}`,
    get: () => `${ownUrlBase}`,
    status: () => `${ownUrlBase}/status`,
    update: () => `${ownUrlBase}`,
    cancel: () => `${ownUrlBase}/cancel`,
  },
  staff: {
    index: makeApiEndpointConfig<DonationSubscriptionParams>(
      staffUrlBase,
      { perPage: 15 },
      { allowInfinity: false }
    ),
    get: makeApiGetEndpointConfig<DonationSubscriptionParams>(staffUrlBase),
    update: (id: string) => `${staffUrlBase}/${id}`,
    cancel: (id: string) => `${staffUrlBase}/cancel/${id}`,
  },
}