import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import { IconName } from '../../base/components/Symbols/iconDefs';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { makeCancelAction } from '../../base/utils/actionConfig.utils';
import { reportError } from '../../base/utils/errors.utils';
import { useStore } from '../../base/utils/mobx.utils';
import { getWordPressLink } from '../../env';
import { saveCurrentUser } from '../../requests/user.requests';
import './GuideVideoCard.scss';

interface GuideVideoCardProps {
}

const GuideVideoCard: React.FC<GuideVideoCardProps> = props => {

  const { UI, API } = useControllers();

  let timer: any;

  const s = useStore(() => ({
    title: "Navigating the new turn2me website",
    hasUpdatedUserPreferences: false,
    shouldShowVideo: false,
    shouldFadeAway: false,
    isPaused: true,
    hasAutoplayed: false,
    get categoryIcon(): IconName | null {
      return 'video';
    },
    get categoryLabel() {
      return 'turn2me Getting Started Video';
    },
    askToUpdateUserPreference: () => {
      UI.DIALOG.present({
        heading: () => <span>Are you sure you want to dismiss the getting started video?</span>,
        body: <span>You can still find and watch this video in our <a href={getWordPressLink('/welcome-back-to-turn2me')} title="Welcome (back) to turn2me" target="_blank" rel="noreferrer">Welcome (back) to turn2me</a> blog post.</span>,
        colorCodedState: ColorCodedState.attention,
        actions: [
          makeCancelAction(),
          {
            name: 'dismissVideo',
            label: 'Dismiss Video',
            action: async () => await s.updateUserPreference(),
          },
        ]
      });
    },
    updateUserPreference: action(async () => {
      if (!s.hasUpdatedUserPreferences) {
        try {
          const savedUser = await saveCurrentUser(API, { preferences: { hasViewedGettingStartedVideo: true } });
          if (!savedUser) {
            UI.DIALOG.attention({
              heading: `Something wasn't quite right...`,
              body: 'Your preference to dismiss getting started video might not have been saved correctly; please refresh the page and try again.',
            })
            return;
          }
          s.hasUpdatedUserPreferences = true
          UI.TOAST.success('Your preference to dismiss getting started video has been saved!');
        } catch (e) {
          reportError(e);
          UI.DIALOG.error({
            heading: "Failed to update your preference to dismiss getting started video...",
            error: e,
          })
        }
      }
    }),
    showVideo: () => {
      s.shouldShowVideo = !s.shouldShowVideo
    },
    handleVideoPlayerClicked: action((delay: number = 1000) => {
      // delay add fade away animation to video title.
      if (!s.isPaused) {
        s.shouldFadeAway = false;
        timer && clearTimeout(timer);
        timer = setTimeout(action(() => {
          s.shouldFadeAway = true
        }), delay)
      }
    }),
    handleOnVideoPause: action(() => {
      s.isPaused = true;
    }),
    handleOnVideoPlay: action((delay: number = 0) => {
      s.isPaused = false

      // on initial autoplay, set longer delay for video title fade.
      if (!s.hasAutoplayed) {
        s.handleVideoPlayerClicked(1500)
        s.hasAutoplayed = true
        return;
      }
      s.handleVideoPlayerClicked(delay)
    }),
  }));

  return (
    <Observer
      children={() => (
        <div className='GuideVideoCardWrapper'>
          <div
            className={`GuideVideoCard ${
              s.shouldShowVideo && "removeBackgroundImg"
            }`}
          >
            <div className='GuideVideoCardInner'>
              <div className='GuideVideoCardDismissButtonContainer'>
                <div
                  className='GuideVideoCardDismissButton'
                  onClick={() => s.askToUpdateUserPreference()}
                >
                  {s.categoryIcon && (
                    <BaseIcon name={"close"} variant='filled' size='1em' />
                  )}
                  {UI.fromTablet && <span>Dismiss</span>}
                </div>
              </div>

              {s.shouldShowVideo ? (
                <video
                  onPlay={() => s.handleOnVideoPlay()}
                  onPause={() => s.handleOnVideoPause()}
                  controls
                  preload='metadata'
                  autoPlay
                >
                  <source
                    src={
                      "https://turn2me.ie/wp-content/uploads/2022/11/Turn2me-Services-run-through-video-.mp4"
                    }
                    type='video/mp4'
                  />
                  ''
                </video>
              ) : (
                <>
                  <div className='GuideVideoCardTopTags'>
                    {!!(s.categoryIcon && s.categoryLabel) && (
                      <div
                        className='GuideVideoCardLeftTag'
                        data-type={s.categoryLabel}
                      >
                        {s.categoryIcon && <BaseIcon name={s.categoryIcon} />}
                        <h3>{s.categoryLabel ?? "Guide Video"}</h3>
                      </div>
                    )}
                  </div>
                  <header className='GuideVideoCardHeader'>
                    <div className='GuideVideoCardHeaderInner'>
                      <h3 className='GuideVideoCardTitle'>{s.title}</h3>
                    </div>
                  </header>
                </>
              )}
            </div>

            {!s.shouldShowVideo && (
              <div className='GuideVideoCardPlayButtonCentering'>
                <BaseButton
                  icon='play'
                  iconVariant='filled'
                  color='emerald'
                  size='md'
                  title='Play Getting Started video'
                  onClick={s.showVideo}
                />
              </div>
            )}

            {s.shouldShowVideo && (
              <header
                className={`GuideVideoCardVideoTitleWrapper ${
                  !s.isPaused && s.shouldFadeAway
                    ? "GuideVideoCardVideoTitleTextAnimation"
                    : ""
                }`}
              >
                <div className='GuideVideoCardVideoTitleContainer'>
                  <div className='GuideVideoCardVideoTitleInner'>
                    <h3 className='GuideVideoCardVideoTitleText'>{s.title}</h3>
                  </div>
                </div>
              </header>
            )}
          </div>
        </div>
      )}
    />
  );
}

export default GuideVideoCard;