import { Observer } from 'mobx-react-lite';
import React from 'react';
import joinClassName from '../../utils/className.utils';
import './BaseMenu.scss';

interface BaseMenuProps {
  className?: string,
}

const BaseMenu: React.FC<BaseMenuProps> = p => {
  return <Observer children={() => (
    <div className={joinClassName('BaseMenu', p.className)}>
      {p.children}
    </div>
  )} />
}

export default BaseMenu;