import { AnyObject, Nillable, Nullable, StandardModel } from "../base/@types";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { isArrayAndEmpty, sortByTimeCreatedOldestFirst } from "../base/utils/array.utils";
import { createUTCMoment } from "../base/utils/time.utils";
import { ApiModelName } from "../constants/ApiModels.enum";
import { CommunicationType } from "../constants/communicationTypes.descriptors";
import { NUM_FREE_COUNSELLING_SESSIONS } from "../constants/counsellingFeeTypes.constants";
import { CounsellingType } from "../constants/counsellingTypes.descriptors";
import { ModelName } from "../constants/modelNames.enum";
import { ReasonCancelSession } from "../constants/reasonCancelSession.enum";
import { CLOCK } from "../controllers/common/clock.controller";
import { hasTimestamps } from "../traits/hasTimestamps.trait";
import { isFreeCounselling, isPaidCounselling } from "../utils/counsellingApplication.utils";
import { Assignment } from "./makeAssignment.model";
import { ChatThread } from "./makeChatThread.model";
import { CounsellingApplication } from "./makeCounsellingApplication.model";
import { InvoiceItem } from "./makeInvoiceItem.models";
import { User } from "./makeUser.model";

export type ExtendedCounsellingSessionSnapshot = CounsellingSessionSnapshot & {application: CounsellingApplication}

export type CounsellingSessionSnapshot = ReturnType<typeof makeCounsellingSessionSnapshot>;
export type CounsellingSessionRelatedModels = {
  counsellor?: User,
  applicant?: User,
  clients: User[],
  application?: CounsellingApplication,
  assignments: Assignment[],
  threads: ChatThread[],
  invoiceItems: InvoiceItem[],
}

export const makeCounsellingSessionSnapshot = () => ({
  id: '',
  type: CommunicationType.Text as Nillable<CommunicationType>,
  applicationId: '',
  counsellorId: '' as Nullable<string>,
  applicantId: '',
  timeScheduled: '' as Nullable<string>,
  timeStarted: '' as Nullable<string>,
  timeEnded: '' as Nullable<string>,
  timeArchived: '' as Nullable<string>,
  timeCancelled: '' as Nullable<string>,
  cancelReason: '' as Nullable<string | ReasonCancelSession>,
  timeRefunded: '' as Nullable<string>,
  timePaymentEnforced: '' as Nullable<string>,
  sessionNotes: '' as Nullable<string>,
  hasAnyClientAttended: false,
  primaryIssue: '',
  secondaryIssue: '',
  amountPayable: 0,
  clientIds: [] as string[],
  threadIds: [] as string[],
  assignmentIds: [] as string[],
  invoiceItemIds: [] as string[],
  technicalOrOtherIssues: '',
  applicationType: '' as Nillable<CounsellingType>,
  ...hasTimestamps(),
})

export type CounsellingSessionExtendedProperties = {
  readonly isPerformed: boolean,
  readonly isCompleted: boolean,
  readonly isCancelled: boolean,
  readonly isUnattended: boolean,
  readonly isPending: boolean,
  readonly isLive: boolean,
  readonly isBillable: boolean,
  readonly isRefunded: boolean,
  readonly isScheduledWithin48Hours: boolean,
  readonly applicationType: Nillable<CounsellingType>,
  readonly isPaidApplication: boolean,
  readonly isFreeApplication: boolean,
  readonly isPaidSessionInFreeApplication: boolean,
  readonly isPaidSession: boolean,
  readonly isFreeSession: boolean,
  readonly isPaymentEnforcedManuallyByCounsellor: boolean,
  readonly shouldEnforcePayment: boolean,
  readonly hasClientPaid: boolean,

  readonly paymentStatus: 'refunded' | 'paid' | 'donated' | 'free' | 'pending',
}

export type CounsellingSession = StandardModel<
  CounsellingSessionSnapshot,
  CounsellingSessionRelatedModels,
  CounsellingSessionExtendedProperties
>

export const makeCounsellingSession = createStandardModelFactory<CounsellingSession, CounsellingSessionRelatedModels, CounsellingSessionExtendedProperties>({
  name: ModelName.counsellingSessions,
  snapshotFactory: makeCounsellingSessionSnapshot,
  relationshipsSchema: {
    counsellor: ModelName.users,
    applicant: ModelName.users,
    application: ModelName.counsellingApplications,
    clients: { modelName: ModelName.users, has: 'many' },
    assignments: { modelName: ModelName.assignments, has: 'many' },
    threads: { modelName: ModelName.chatThreads, has: 'many' },
    invoiceItems: { modelName: ModelName.invoiceItems, has: 'many' },
  },
  extendedPropertiesFactory: (m, $, localDB) => ({
    get isPerformed() {
      return !!m.timeEnded;
    },
    get isCompleted() {
      return !!m.timeEnded && m.hasAnyClientAttended;
    },
    get isCancelled() {
      return !!m.timeCancelled;
    },
    get isRefunded() {
      return !!m.timeRefunded;
    },
    get isUnattended() {
      return !m.hasAnyClientAttended;
    },
    get isPending() {
      return !m.timeStarted && !m.isPerformed && !m.isCompleted && !m.timeArchived && !m.timeDeleted && !m.isCancelled;
    },
    get isLive() {
      return !m.timeArchived && !m.timeDeleted && !m.application?.isArchived && !m.application?.isCompleted && !!m.timeStarted && !m.timeEnded && m.threadIds.length > 0 && !m.isCancelled;
    },
    get isBillable() {
      return m.invoiceItems.length > 0 && m.invoiceItems.some(i => !i.timeDeleted);
    },
    get isScheduledWithin48Hours() {
      return Math.abs(createUTCMoment(m.timeScheduled).diff(CLOCK.localNowMoment, 'days', true)) < 2;
    },
    get applicationType() {
      return m.application?.type || $.applicationType;
    },
    get isPaymentEnforcedManuallyByCounsellor() {
      return !!m.timePaymentEnforced;
    },
    get shouldEnforcePayment() {
      const enforceForFreeSessionDonation = m.amountPayable > 0 && !m.isPaidSession;
      const enforceForPaidSessionPayment = m.amountPayable > 0 && (m.isPaymentEnforcedManuallyByCounsellor || m.isPaidSession);
      return enforceForFreeSessionDonation || enforceForPaidSessionPayment;
    },
    get isPaidApplication() {
      return (!!m.applicationType && isPaidCounselling(m.applicationType)) || (!!m.application?.isPaid);
    },
    get isFreeApplication() {
      return (!!m.applicationType && isFreeCounselling(m.applicationType)) || (!!m.application?.isFree);
    },
    get isPaidSessionInFreeApplication() {
      // is free application, and this session exceeded the num allowed free sessions.
      if (!m.application) return false;
      const sortedSessions = sortByTimeCreatedOldestFirst(m.application.sessions)
      const sessionIndex = sortedSessions.findIndex(sess => sess.id === m.id);
      const currTotalApplicationSessions = sortedSessions.length;
      const isSessionExceededFreeSessions = sessionIndex === -1 ? currTotalApplicationSessions >= NUM_FREE_COUNSELLING_SESSIONS : sessionIndex >= NUM_FREE_COUNSELLING_SESSIONS;
      if (m.isFreeApplication && isSessionExceededFreeSessions) return true;
      return false;
    },
    get isPaidSession() {
      return m.isPaymentEnforcedManuallyByCounsellor;
      // if (m.isPaidApplication) return true;
      // if (m.isFreeApplication && m.isPaymentEnforcedManuallyByCounsellor) return true;
      // // if (m.isPaidSessionInFreeApplication) return true;
      // return false;
    },
    get isFreeSession() {
      return !m.isPaidSession;
    },
    get hasClientPaid() {
      // has payment assignment, and that assignment is completed.
      if (!isArrayAndEmpty(m.assignments)) {
        const paymentAssignment = m.assignments.find(a => a.targetType === ApiModelName.PAYMENT);
        return Boolean(paymentAssignment?.isCompleted && paymentAssignment?.targetId);
      }
      return false;
    },
    get paymentStatus() {
      const paymentAssignment = m.assignments.find(a => a.targetType === ApiModelName.PAYMENT);
      const hasClientPaid = !!(paymentAssignment && paymentAssignment.targetId);

      switch (true) {
        case m.isRefunded:
          return 'refunded';
        case hasClientPaid:
          return m.isPaidSession ? 'paid' : 'donated';
        case !m.amountPayable:
          return 'free';
        default:
          return 'pending';
      }
    },
  })
})

export const isCounsellingSessionModel = (g: Nillable<AnyObject>): g is CounsellingSession => g?.$modelName === ModelName.counsellingSessions;
