import { always } from "../../../../../base/utils/ramdaEquivalents.utils";
import { makeObservableRouteDef } from "../../../../../base/utils/routes.utils";
import AccountPagePrivacy from "./AccountPagePrivacy.page";

export const AccountPagePrivacyRouteDef = makeObservableRouteDef({
  identifier: 'account-privacy',
  urlFactory: always('privacy'),
  composeTitle: always('Privacy Settings'),
  component: AccountPagePrivacy,
  icon: 'shield',
})