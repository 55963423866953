import { reaction } from 'mobx';
import React from 'react';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { useStore } from '../../base/utils/mobx.utils';

type FaviconManagerProps = {}

const FaviconManager: React.FC<FaviconManagerProps> = props => {
	const { NAVIGATOR } = useControllers();
	const s = useStore(() => ({
		updateFavicon: (type: 'client' | 'admin') => {
			const links: HTMLLinkElement[] = Array.from(document.querySelectorAll('[data-favicon-link]'));
			links.forEach(link => {
				link.href = link.href.replace(/favicons\/(client|admin)\//, `favicons/${type}/`);
			})
		}
	}))
	useOnMount(() => {
		reaction(
			() => NAVIGATOR.topLevelModuleName,
			name => {
				if (name === 'admin') s.updateFavicon('admin');
				else s.updateFavicon('client');
			},
			{ fireImmediately: true }
		)
	})
	return null;
}

export default FaviconManager;