import { when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import AppPage from '../../base/components/AppPage/AppPage';
import AppPageContent from '../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../base/components/AppPageHeader/AppPageHeader';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import DatePicker from '../../base/components/DatePicker/DatePicker';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import OverlayCloseButton from '../../base/components/OverlayCloseButton/OverlayCloseButton';
import UIBlock from '../../base/components/UIBlock/UIBlock';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { makeActionConfig } from '../../base/utils/actionConfig.utils';
import { reportError } from '../../base/utils/errors.utils';
import { useStore } from '../../base/utils/mobx.utils';
import { sendSaveUserRequest } from '../../requests/saveUser.request';
import { getAgeFromDateOfBirth, isAdultAge, isChildAge, isValidAge, isYoungPeopleAge, renderUserAgeNotice } from '../../utils/ageAndDateOfBirth.utils';
import './OverlayAgeVerification.scss';

interface OverlayAgeVerificationProps {
  dismissable?: boolean,
}

const OverlayAgeVerification: React.FC<OverlayAgeVerificationProps> = props => {

  const { UI, API, AUTH, THOUGHT_CATCHER } = useControllers();

  const s = useStore(() => ({
    form: {
      dateOfBirth: AUTH.currentUser?.dateOfBirth,
    },
    get age() {
      return getAgeFromDateOfBirth(s.form.dateOfBirth, true);
    },
    get isValidAge() {
      return isValidAge(s.age);
    },
    get isYoungPerson() {
      return isYoungPeopleAge(s.age);
    },
    get isChild() {
      return isChildAge(s.age);
    },
    get isAdult() {
      return isAdultAge(s.age);
    },
    get canSubmit() {
      return !!s.age;
    },
    submitting: false,
    submitted: false,
  }));

  const dismiss = () => {
    UI.OVERLAY.dismiss('OverlayAgeVerification');
  }

  const submit = () => new Promise<boolean>(async (resolve, reject) => {
    try {
      s.submitting = true;
      await patchUser();
      onSuccess();
      s.submitted = true;
      resolve(true);
    } catch(e) {
      s.submitting = false;
      reject(e);
      reportError(e);
    }
  })

  const patchUser = () => new Promise<boolean>(async (resolve, reject) => {
    try {
      const payload = {
        dateOfBirth: s.form.dateOfBirth
      }
      await sendSaveUserRequest(API, payload, {isSelf: true});
      resolve(true)
    } catch (e) {
      UI.DIALOG.error({
        heading: 'Failed to update your user information, please try again.',
        body: <ErrorRenderer error={(e as any).response} />,
      })
      reject(e);
    }
  })

  const onSuccess = () => {
    dismiss();
    UI.DIALOG.success({
      heading: 'Your information has been updated.',
      body: () => <>
        { s.isAdult && <p>You can now continue to use turn2me services.</p>}
      </>,
      actions: [
        makeActionConfig('OK', function() {
          THOUGHT_CATCHER.reset();
        })
      ]
    })
  }

  useOnMount(() => when(() => !AUTH.isAuthenticated, dismiss));

  return <Observer children={() => (
    <AppPage 
      className="OverlayAgeVerification"
      alwaysDisableCustomScrollbar
    >
      <AppPageHeader
        title="Welcome to turn2me!"
        endSlot={props.dismissable && <OverlayCloseButton color="white" />}
      />
      <AppPageContent padSections>
        <UIBlock spaceChildren>
          <p>Please enter your date of birth to verify your age:</p>
          <DatePicker form={s.form} field="dateOfBirth" onEnter={submit} shouldAlwaysUseSystemUI autoFocus />
          {s.age && renderUserAgeNotice(s.age)}
        </UIBlock>
        <UIBlock>
          <BaseButton
            className="subtle"
            dataCy="age-verification-overlay-submit-button"
            onClick={submit}
            color="white"
            disabled={!s.canSubmit}
            loading={s.submitting}
            label="Submit"
            size="lg"
            fullWidth
          />
        </UIBlock>
      </AppPageContent>
    </AppPage>
  )} />
}

export default OverlayAgeVerification;