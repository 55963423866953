import { flow } from "mobx";
import { Identifier } from "../base/@types";
import { DefaultThoughtIncludes, DefaultThoughtIncludesForAdmins, ThoughtEndpoints } from "../base/endpoints/thought.endpoints";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { SHOULD_LOG } from "../env";
import { Thought } from "../models/makeThought.model";

export const getThought = (
  id: Identifier, 
  API: APIController,
  asStaff?: boolean
) => new Promise<Thought>(
  flow(function * (resolve, reject) {
    const url = ThoughtEndpoints[asStaff ? 'staff' : 'own'].get(id, {
      include: asStaff ? DefaultThoughtIncludesForAdmins : DefaultThoughtIncludes,
    });
    const { LOCALDB } = API.ROOT!.children;
    try {
      const thought = yield API.get<Thought>(url, ModelName.thoughts);
      resolve(thought);
    } catch (e) {
      SHOULD_LOG() && console.warn('Failed to get thought, trying localDB', e);
      const thoughtFromLocalDB = LOCALDB.get<Thought>(ModelName.thoughts, id!);
      if (thoughtFromLocalDB) resolve(thoughtFromLocalDB)
      else throw e;
    }
  })
)