import { NavigatorController } from "../../controllers/navigator.controller";
import { makeUrl } from "./url.utils";
import { setUrlParam } from "./urlParams.utils";
import tick from "./waiters.utils";

export const navigateToChatPage = async (NAVIGATOR: NavigatorController, chatId: string, action?: string) => {
  const { UI } = NAVIGATOR.ROOT!.children;
  UI.OVERLAY.dismissAll();
  NAVIGATOR.navigateTo(makeUrl('/', NAVIGATOR.isInAdminArea ? 'admin' : 'app', 'chats'));
  await tick(UI.onlyPhones ? 620 : 0);
  NAVIGATOR.navigateTo(makeUrl('/', NAVIGATOR.isInAdminArea ? 'admin' : 'app', 'chats', chatId));
  if (action) setUrlParam('action', 'print', NAVIGATOR);
}