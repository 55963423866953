import { AxiosRequestConfig } from 'axios';
import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import { QRCodeSVG } from 'qrcode.react';
import React from 'react';
import { FunctionOnOTPFail, FunctionOnOTPVerified } from '../../@types/mfa.types';
import { ColorCodedState } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseInput from '../../base/components/BaseInput/BaseInput';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import UIBlock from '../../base/components/UIBlock/UIBlock';
import { AuthEndpoints } from '../../base/endpoints/auth.endpoints';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { User } from '../../models/makeUser.model';
import './OTPVerification.scss';

type OTPVerificationProps = {
  user: User,
  onOTPVerified?: FunctionOnOTPVerified,
  onOTPFail?: FunctionOnOTPFail,
  customHeaders?: AxiosRequestConfig,
  autoFocus?: boolean,
  doNotShowSkip?: boolean,
  doNotFetchUserOnVerified?: boolean,
}

const OTPVerification: React.FC<OTPVerificationProps> = props => {

  const { AUTH, API, UI } = useControllers();
  const { DIALOG } = UI;

  const p = useProps(props);
  const s = useStore(() => ({
    form: {
      otpCode: '',
    },
    get showSetup() { return !p.user?.preferences.has2FA ?? true; },
    get setupQRCodeURL() { return p.user?.preferences.QRCodeUrl; },
    get setupKey() { return '' },
    get formIsValid() {
      return !!s.form.otpCode;
    },
    get maskedEmail() {
      const delimiter = '***';
      if (!p.user?.email) return delimiter;
      const [localPart, domain] = p.user.email.split('@');
      const maskedLocalPart = localPart.length > 8 ? localPart.substring(0, 3) + '***' + localPart.substring(localPart.length - 2) + '@' : localPart.substring(0, 3) + '***@';
      const maskedDomain = domain.length > 8 ? domain.substring(0, 3) + '***' + domain.substring(domain.length - 2) : domain.substring(0, 3) + '***';
      return maskedLocalPart + maskedDomain;
    },
    state: {
      isSubmitting: false,
      submitted: false,
      isResendingCode: false,
      codeResent: false,
    },
    handleInputChange() {
      if (s.form.otpCode.length === 6) sendVerifyRequest();
    },
  }));

  const appDisplayName = 'turn2me';

  const sendVerifyRequest = flow(function* () {
    if (!s.formIsValid) {
      DIALOG.error({
        heading: 'Please enter a valid code.'
      })
      return;
    }
    try {
      const url = AuthEndpoints.otp.verify();
      const payload = {
        secret: s.form.otpCode,
      }
      s.state.isSubmitting = true;
      yield API.postRaw<{ matches: boolean }>(url, payload, p.customHeaders);
      s.state.submitted = true;
      yield DIALOG.positive({
        name: 'otp-verification-success',
        heading: '2FA code verification success',
        body: `You may now continue to use ${appDisplayName} services.`,
      })
      if (!p.doNotFetchUserOnVerified) AUTH.fetchCurrentUserInfo();
      yield p.onOTPVerified?.(true);
    } catch (e) {
      DIALOG.present({
        name: 'otp-verification-failure',
        heading: '2FA code verification failed',
        body: `Please recheck your code. If you are sure the code is correct and this keeps happening, please contact customer support.`,
        colorCodedState: ColorCodedState.alert,
        defaultActions: ['positive'],
      })
      reportError(e);
    } finally {
      s.state.isSubmitting = false;
    }
  })

  useOnMount(() => {
    if (!s.setupQRCodeURL) {
      DIALOG.error({
        heading: 'QR code URL not found on user',
        body: 'We apologize for the inconvenience caused; please try again. If this problem persists, please contact our customer support.',
      })
      reportError(`QR code URL not found on user id: ${p.user?.id}`);
    }
  })

  return <Observer children={() => (
    <UIBlock className="OTPVerification" padded spaceChildren>
      {s.showSetup && <ShadedBlock color='attention'>
        <h3><span>1. Authenticator Setup</span></h3>
        <ol>
          <li>Install Google Authenticator on your phone (<a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" rel="noreferrer" title="Google Authenticator Apple App Store Link">Apple App Store</a> or <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" rel="noreferrer" title="Google Authenticator Android Google Play Link">Android Google Play</a>).</li>
          <li>Open the Google Authenticator app.</li>
          <li>Tap on the 'Add' icon in the bottom right corner.</li>
          <li>Tap on 'Scan a QR code'.</li>
          <li>This will open your phone's camera. Scan the QR code below.</li>
          {s.setupKey && <li>Alternatively, you can tap on 'Enter a setup key', and copy the setup key below:</li>}
          <li>A six digit code will appear as an entry for the turn2me.ie domain.</li>
          <li>Enter this six digit code in step 2.</li>
          {s.setupQRCodeURL && <QRCodeSVG value={s.setupQRCodeURL} size={200} />}
          {s.setupKey && <>
            <p><strong>Setup Key:</strong></p>
            <ShadedBlock>
              {s.setupKey}
            </ShadedBlock>
          </>}
        </ol>
      </ShadedBlock>}

      <ShadedBlock spaceChildren>
        <h3><span>{s.showSetup && '2. '}Enter Code</span> <span>from Authenticator app</span></h3>
        <p>Before you proceed to {appDisplayName} services, please enter the six digit code from the Google Authenticator app on your phone:</p>
        <BaseInput className="VerificationCodeField" type="text" form={s.form} field="otpCode" pattern="[0-9]*" onChange={s.handleInputChange} placeholder="------" autoFocus={p.autoFocus} autoComplete="off" autoCapitalize="off" autoCorrect="off" />
      </ShadedBlock>

      <div className="ButtonBlock">
        <BaseButton onClick={sendVerifyRequest} rounded size="lg" loading={s.state.isSubmitting}>Verify</BaseButton>
        {(!p.doNotShowSkip && (UI.onlyPhones || s.showSetup)) && <BaseButton onClick={() => p.onOTPVerified?.()} color="neutral" className='subtle' size="md" loading={s.state.isSubmitting}>Remind me to setup later</BaseButton>}
      </div>
    </UIBlock>
  )} />
}

export default OTPVerification;