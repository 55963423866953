import { flow, IReactionOptions, IReactionPublic, observable, reaction, runInAction } from "mobx";
import { useLocalObservable } from "mobx-react-lite";
import { AnnotationsMap } from "mobx/dist/internal";
import { useEffect } from "react";
import { AnyObject } from "../@types";
import tick from "./waiters.utils";

export const immediateReaction = <T>(
  expression: (r: IReactionPublic) => T,
  effect: (arg: T, prev: T, r: IReactionPublic) => void
) => reaction(expression, effect, { fireImmediately: true })

export const multiExpressionReaction = (
  expressions: ((r: IReactionPublic) => any)[],
  effect: (arg: any, r: IReactionPublic) => void,
  options?: IReactionOptions<AnyObject>,
) => {
  const disposers = expressions.map(e => reaction(e, effect, options));
  return () => disposers.forEach(d => d());
}

export const delayedComputed = <T>(
  getter: () => T,
  delay?: number,
) => {
  const s = observable({
    value: getter(),
  });
  reaction(getter, flow(function* () {
    yield tick(delay);
    s.value = getter();
  }))
  return s;
}

export type AutoSyncHookOptions<T extends object> = { debug?: string | boolean, annotations?: AnnotationsMap<T, string | number | symbol> };


/**
 * Creates a store that automatically syncs with the source upon rerender.
 * @param initializer factory function that creates a store.
 * @param current the source of current values to be synced with properties of the same names in the store on rerender.
 */
export const useAutoSyncWithInitializer = <
  SyncSource extends object = object,
  StoreObject extends object & SyncSource = object & SyncSource,
  >(
    initializer: () => StoreObject,
    current?: SyncSource,
    options?: AutoSyncHookOptions<StoreObject>,
) => {
  const s = useLocalObservable(initializer, options?.annotations);
  useAutoSyncInternalEffect(s, current || {}, options);
  return s;
}

/**
 * Similar to useAutoSyncWithInitializer, but takes an object and create a factory with object spread, which looks like this: `() => ({...object})`.
 * @param current the object to be made into an initializer. Any changes to this on rerender will be automatically applied to the observable.
 * @param options currently used for adding debugger to the process.
 */
export const useAutoSyncWithStaticObject = <SyncSource extends object = object>(
  current: SyncSource,
  options?: AutoSyncHookOptions<SyncSource>,
) => {
  const s = useLocalObservable(() => ({ ...current }), options?.annotations);
  useAutoSyncInternalEffect(s, current || {}, options);
  return s;
}

/**
 * Internal helper hook to automatically update 'synthetic' observables.
 * Takes a source object, and apply changes to the current value automatically to it on each rerender.
 * @param source the source object.
 * @param current the updated current value of the object.
 * @param options currently used for adding debugger to the process.
 */
export const useAutoSyncInternalEffect = <TargetType extends object>(
  source: TargetType,
  current: TargetType,
  options?: AutoSyncHookOptions<TargetType>,
) => {
  useEffect(() => {
    runInAction(() => {
      // if (options?.debug) {
      //   console.info(`useAutoSyncObjects@${options.debug || 'unnamed'}`, {...source}, current);
      // } 
      Object.entries(current).forEach(e => {
        // if (options?.debug) {
          // console.log(source, e[0], source[e[0]], e[1], source[e[0]] === e[1] ? 'skipped' : 'updated');
          // debugger;
        // }
        if (!options?.annotations || options?.annotations[e[0]] !== false)
          source[e[0] as keyof TargetType] = e[1]
      });
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);
}

/**
 * Takes the props object and make it observable. On each rerender, prop changes will be applied into this observable automatically.
 */
export const useProps = <T extends object>(
  props: React.PropsWithChildren<T>,
  options?: AutoSyncHookOptions<T>,
) => {
  return useAutoSyncWithStaticObject(props, {
    debug: options?.debug,
    annotations: {
      children: false,
      ...options?.annotations
    },
  });
}

/**
 * Creates an observable store with a factory function. If you need to refer to props, please remember to use useProps to convert props into an obsrvable.
 */
export const useStore = useLocalObservable;
