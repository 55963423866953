import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import './LiveSiteWarningFrame.scss';

type LiveSiteWarningFrameProps = {}

const LiveSiteWarningFrame: React.FC<LiveSiteWarningFrameProps> = props => {
  return <Observer children={() => (
    <div className="LiveSiteWarningFrame">
      <span className="LiveSiteWarningTag"><BaseIcon name="warning" /> Live Site</span>
      <span className="LiveSiteWarningTag">Live Site</span>
      <span className="LiveSiteWarningTag">Live Site</span>
      <span className="LiveSiteWarningTag">Live Site</span>
      <span className="LiveSiteWarningTag"><BaseIcon name="warning" /> You are viewing the live site <BaseIcon name="warning" /></span>
    </div>
  )} />
}

export default LiveSiteWarningFrame;