import { Observer } from 'mobx-react-lite';
import React from 'react';
import AppPage from '../../../../../base/components/AppPage/AppPage';
import AppPageContent from '../../../../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../../../../base/components/AppPageHeader/AppPageHeader';
import BackButton from '../../../../../base/components/BackButton/BackButton';
import BaseSpacer from '../../../../../base/components/BaseSpacer/BaseSpacer';
import ShadedBlock from '../../../../../base/components/ShadedBlock/ShadedBlock';
import UIBlock from '../../../../../base/components/UIBlock/UIBlock';
import { useControllers } from '../../../../../base/hooks/useRootController.hook';
import { useStore } from '../../../../../base/utils/mobx.utils';
import OTPVerification from '../../../../../components/OTPVerification/OTPVerification';

type AccountPage2FAProps = {}

const AccountPage2FA: React.FC<AccountPage2FAProps> = props => {

  const { AUTH } = useControllers();

  const s = useStore(() => ({
    get user() { return AUTH.currentUser!; },
    get has2FA() { return s.user.preferences.has2FA; },
  }))

  // const reset2FA = () => {
  //   reset2FARequest(s.user, AUTH, UI, API);
  // }

  return <Observer children={() => (
    <AppPage className="AccountPage2FA"
      accommodateTitleBarSpace
    >
      <AppPageHeader
        title="Two-Factor Authentication"
        startSlot={<BackButton destination="up" />}
      />
      <AppPageContent>
        {s.has2FA
          ? <UIBlock padded>
            <ShadedBlock color="green" spaceChildren>
              <h3>2FA Active</h3>
              <p>Two-Factor Authentication (2FA) is setup and activated.</p>
              <p>You will be asked to enter the code from the Authenticator app on your phone on your next login.</p>
              <BaseSpacer size='1em' />
              <p>If you lost access to your code or mobile device, please contact customer support or a turn2me admin to reset your 2FA and restart the setup process.</p>
              <p></p>
              {/* <BaseButton icon="2fa" onClick={reset2FA} colorCodedState={ColorCodedState.attention}>Reset 2FA</BaseButton> */}
            </ShadedBlock>
          </UIBlock>
          : <OTPVerification
            user={s.user}
            doNotShowSkip
          />
        }
      </AppPageContent>
    </AppPage>
  )} />
}

export default AccountPage2FA;