import { AxiosResponse } from 'axios';
import { action, flow, observable } from 'mobx';
import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { AnyObject, Nullable, ValidatorResult } from '../../../base/@types';
import BaseButton from '../../../base/components/BaseButton/BaseButton';
import BaseCheckbox from '../../../base/components/BaseCheckbox/BaseCheckbox';
import BaseGrid from '../../../base/components/BaseGrid/BaseGrid';
import BaseHeading from '../../../base/components/BaseHeading/BaseHeading';
import BaseInput from '../../../base/components/BaseInput/BaseInput';
import BaseSpacer from '../../../base/components/BaseSpacer/BaseSpacer';
import ClickOrTap from '../../../base/components/ClickOrTap/ClickOrTap';
import DatePicker from '../../../base/components/DatePicker/DatePicker';
import ErrorRenderer from '../../../base/components/ErrorRenderer/ErrorRenderer';
import HeadingAllcaps from '../../../base/components/HeadingAllcaps/HeadingAllcaps';
import TelInput from '../../../base/components/TelInput/TelInput';
import WindowTitle from '../../../base/components/WindowTitle/WindowTitle';
import { AuthEndpoints } from '../../../base/endpoints/auth.endpoints';
import { useOnMount } from '../../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../../base/hooks/useRootController.hook';
import { useUpdatePageBackground } from '../../../base/hooks/useSetPageBackground.hook';
import { makeCancelAction } from '../../../base/utils/actionConfig.utils';
import { reportError } from '../../../base/utils/errors.utils';
import { useStore } from '../../../base/utils/mobx.utils';
import { YYYYMMDD } from '../../../base/utils/time.utils';
import { isString } from '../../../base/utils/typeChecks.utils';
import { hasValidEmail } from '../../../base/validators/email.validator';
import { makePasswordValidator } from '../../../base/validators/password.validator';
import AddressEditor from '../../../components/AddressEditor/AddressEditor';
import ContactEditor from '../../../components/ContactEditor/ContactEditor';
import { GenderSelector } from '../../../components/GenderSelector/GenderSelector';
import Turn2MeLogo from '../../../components/Turn2MeLogo/Turn2MeLogo';
import { getWordPressLink, isInCypressTestMode, IS_DEV } from '../../../env';
import { Address, AddressSnapshot, makeAddressSnapshotBase } from '../../../models/makeAddress.model';
import { Contact, ContactSnapshot, makeContactSnapshotBase } from '../../../models/makeContact.model';
import { User } from '../../../models/makeUser.model';
import { getAgeFromDateOfBirth } from '../../../utils/ageAndDateOfBirth.utils';
import { asyncGetRecaptchaToken, checkErrorForRecaptchaFailure } from '../../../utils/loadRecaptcha.utils';
import { checkIfStringContainsWord_turn2me } from '../../../utils/user.utils';
import AuthForm from '../_components/AuthForm/AuthForm';
import AuthPage from '../_components/AuthPage/AuthPage';
import './PageRegister.scss';

type RegistrationForm = Partial<User> & { password: string, confirmPassword: string, userEmergencyContact: Contact | ContactSnapshot, userAddress: Address | AddressSnapshot, companyCode: string };

interface PageRegisterProps { }

const PageRegister: React.FC<PageRegisterProps> = props => {

  const { API, UI, AUTH, NAVIGATOR, ANALYTICS } = useControllers();

  const s = useStore(() => ({
    form: IS_DEV && false
      ? {
        mobileNumber: '+353222222222',
        email: 'vb@viralbamboo.com',
        username: 'tester_vb',
        firstName: 'fname',
        lastName: 'lname',
        password: 'password',
        confirmPassword: 'password',
        gender: 'prefer-not-to-say',
        dateOfBirth: '2006-01-01',
        companyCode: '4321',
        userEmergencyContact: {
          ...makeContactSnapshotBase(),
          name: 'tester emergency contact',
          phone: '+353221111111',
          email: "l@viralbamboo.com"
        } as Contact | ContactSnapshot,
        userAddress: {
          ...makeAddressSnapshotBase(),
          lineOne: "viralbamboo",
          city: "dublin",
          region: "dublin",
          countryId: "IE",
          postcode: "vb",
        } as Address | AddressSnapshot,
      } as RegistrationForm
      : {
        mobileNumber: '',
        email: '',
        username: '',
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
        dateOfBirth: '',
        companyCode: '',
        userEmergencyContact: {
          ...makeContactSnapshotBase(),
        } as Contact | ContactSnapshot,
        userAddress: {
          ...makeAddressSnapshotBase(),
        } as Address | AddressSnapshot,
      } as RegistrationForm,
    passwordValidator: makePasswordValidator({ minLength: 8 }),
    get dateOfBirthIsValid() {
      return s.form.dateOfBirth && s.form.dateOfBirth?.length > 8 ? moment(s.form.dateOfBirth).isValid() : false;
    },
    get age() {
      return s.form.dateOfBirth ? getAgeFromDateOfBirth(s.form.dateOfBirth, true) : null;
    },
    get isUnderAge() {
      return s.age && s.age < 12;
    },
    get passwordValidatorResult() {
      return s.passwordValidator(s.form.password);
    },
    get passwordIsValid() {
      return s.passwordValidatorResult === true;
    },
    get hasValidEmail() {
      return hasValidEmail(s.form.email);
      // return s.form.email && validateEmail(s.form.email) === true && /^.+@.+\..+$/.test(s.form.email);
    },
    contactPhoneValidationResult: false as ValidatorResult,
    userPhoneValidationResult: false as ValidatorResult,
    get hasValidUserMobileNumber() {
      // return s.form.mobileNumber && validatePhone(s.form.mobileNumber) === true;
      return s.form.mobileNumber && s.userPhoneValidationResult === true;
    },
    get hasValidContactMobileNumber() {
      // return s.form.mobileNumber && validatePhone(s.form.mobileNumber) === true;
      return s.form.userEmergencyContact.phone && s.contactPhoneValidationResult === true;
    },
    get hasValidPhoneAndEmail() {
      return s.hasValidUserMobileNumber && s.hasValidEmail;
    },
    // get isFormValid() {
    //   return s.hasValidPhoneAndEmail && s.passwordIsValid && s.dateOfBirthIsValid;
    // },
    awaitingResponse: false,
    succeeded: false,
    lastError: null as Nullable<Error>,
    register: () => flow(function* () {
      if (!s.form.username) {
        UI.DIALOG.attention({
          heading: 'Please provide a username.'
        })
        return;
      }
      if (!s.form.firstName) {
        UI.DIALOG.attention({
          heading: 'Please enter your first name.'
        })
        return;
      }
      if (!s.form.lastName) {
        UI.DIALOG.attention({
          heading: 'Please enter your last name.'
        })
        return;
      }
      if (!s.dateOfBirthIsValid) {
        UI.DIALOG.attention({
          heading: 'Please provide a date of birth before continuing.'
        })
        return;
      }
      if (s.isUnderAge) {
        UI.DIALOG.attention({
          heading: 'We are sorry but you must be at least 12 years old to use the turn2me services.',
        })
        return;
      }
      if (!s.hasValidEmail) {
        UI.DIALOG.attention({
          heading: "Please provide a valid email address.",
        });
        return;
      }
      if (!s.hasValidUserMobileNumber) {
        UI.DIALOG.attention({
          heading: 'Please provide a valid phone number.',
        });
        return;
      }
      if (!s.form.gender) {
        UI.DIALOG.attention({
          heading: 'Please select your gender.',
        })
        return;
      }
      if (checkIfStringContainsWord_turn2me(s.form.username)) {
        UI.DIALOG.attention({
          heading: 'Your username should not include the word "turn2me".',
        });
        return;
      }
      if (!s.passwordIsValid) {
        UI.DIALOG.attention({
          heading: 'Your password must be at least 8 characters long.',
        })
        return;
      }
      if (!s.hasValidPhoneAndEmail) {
        UI.DIALOG.attention({
          heading: 'You must provide a valid email and mobile number before continuing.'
        })
        return;
      }
      if (s.form.password && (s.form.password !== s.form.confirmPassword)) {
        UI.DIALOG.attention({
          heading: 'The two password fields do not match.',
          body: 'Please double check your password.'
        })
        return;
      }
      if (!s.form.userAddress.lineOne) {
        UI.DIALOG.attention({
          heading: 'Please provide an address with a valid line one.',
        })
        return;
      }
      if (!s.form.userAddress.city) {
        UI.DIALOG.attention({
          heading: 'Please provide an address with a valid city/town.',
        })
        return;
      }
      if (!s.form.userAddress.postcode) {
        if (s.form.userAddress.countryId === 'IE') {
          UI.DIALOG.attention({
            heading: 'Please provide an address with a valid EIRCode.',
          })
        }
        else {
          UI.DIALOG.attention({
            heading: 'Please provide an address with a valid postcode.',
          })
        }
        return;
      }
      if (!s.form.userAddress.region) {
        UI.DIALOG.attention({
          heading: 'Please provide an address with a valid county/region.',
        })
        return;
      }
      if (!s.form.userAddress.countryId) {
        UI.DIALOG.attention({
          heading: 'Please provide an address with a valid country.',
        })
        return;
      }
      if (!s.form.userEmergencyContact.name) {
        UI.DIALOG.attention({
          heading: 'Please provide a valid name for your emergency contact.',
        })
        return;
      }
      if (!s.hasValidContactMobileNumber) {
        UI.DIALOG.attention({
          heading: 'Please provide a valid phone number for your emergency contact.',
        })
        return;
      }
      if ((!s.form.userEmergencyContact.email || !/^.+@.+\..+$/.test(s.form.userEmergencyContact.email))) {
        UI.DIALOG.attention({
          heading: 'Please provide a valid email for your emergency contact.',
        })
        return;
      }
      if (s.form.userEmergencyContact.email === s.form.email) {
        UI.DIALOG.attention({
          heading: "Please ensure your emergency contact's email is not your email.",
        })
        return;
      }
      if (s.form.userEmergencyContact.phone === s.form.mobileNumber) {
        UI.DIALOG.attention({
          heading: "Please ensure your emergency contact's phone number is not your phone number.",
        })
        return;
      }
      const consentForm = observable({
        hasReadAndAgreedToTerms: false,
        consentToBeContacted: false,
        consentToMarketingContact: false,
      })
      const confirm = yield UI.DIALOG.present({
        heading: 'Data Consent, Privacy and Terms of Use',
        body: <div>
          <p>To provide you with a mental health service, the data you enter can be considered special category data as it contains mental health and health information. If our Clinical and Support Team cannot see your data and the content you post on turn2me, they cannot help and support you.</p>
          <p>All content (Public and Private) posted to turn2me can be reviewed by our Clinical and Support Team who may contact you by Private chat on site, through Push and site notifications, SMS or through email to offer support or to suggest service and care pathways to you.</p>
          <BaseSpacer size="sm" />
          <BaseCheckbox form={consentForm} field="hasReadAndAgreedToTerms">I have read and agreed to the turn2me <a href={getWordPressLink(`/terms-of-use`)} title={`turn2me Terms of Use`} target="_blank" rel="noreferrer">Terms of Use</a> and <a href={getWordPressLink(`privacy`)} title={`turn2me Privacy Policy`} target="_blank" rel="noreferrer">Privacy Policy</a>.</BaseCheckbox>
          <BaseCheckbox form={consentForm} field="consentToBeContacted">I agree to be contacted about turn2me application updates via email, posted letter or SMS through contact info that I have provided.</BaseCheckbox>
          <BaseCheckbox form={consentForm} field="consentToMarketingContact">I would like to be notified about turn2me services, campaigns and offers via email, posted letter or SMS through contact info that I have provided.</BaseCheckbox>
        </div>,
        actions: [
          makeCancelAction(),
          observable({
            name: 'positive',
            label: 'Confirm & Register',
            get disabled() {
              return !consentForm.hasReadAndAgreedToTerms;
            },
            action: () => 'positive',
          })
        ]
      })
      if (!confirm) return;

      try {
        yield asyncGetRecaptchaToken('register_new_account', UI, API);
      } catch (e) {
        checkErrorForRecaptchaFailure(e);
        UI.DIALOG.error({
          heading: 'You have not passed the Recaptcha test. Please try again.',
        })
        return;
      }

      const url = AuthEndpoints.register();
      const payload: AnyObject = {
        firstName: s.form.firstName,
        lastName: s.form.lastName,
        username: s.form.username,
        password: s.form.password,
        dateOfBirth: s.form.dateOfBirth,
        mobileNumber: s.form.mobileNumber,
        companyCode: !!s.form.companyCode ? s.form.companyCode : undefined,
        gender: s.form.gender,
        email: s.form.email,
        hasOptedOutOfMarketingEmails: !consentForm.consentToMarketingContact,
        address: s.form.userAddress,
        contact: s.form.userEmergencyContact,
      }
      s.awaitingResponse = true;

      try {
        // register new user.
        const response: AxiosResponse<string> = yield API.postRaw<string>(url, payload);
        const { data: token } = response;
        if (!token) return;
        if (isString(token)) yield AUTH.runAfterTokenRetrieval(token);
        s.succeeded = true;
        const hasTempInvitationLink = yield AUTH.checkTempInvitationLink();
        if (!hasTempInvitationLink) NAVIGATOR.navigateTo('/app/explore');
      } catch (e) {
        const error = e as any;
        const stringifiedResponse = JSON.stringify(error.response);
        if (stringifiedResponse.includes('Invalid number')) {
          UI.DIALOG.error({
            heading: 'Failed to register :(',
            body: 'Please double check your phone number.',
          });
        } else if (stringifiedResponse.includes('The email must be a valid email address.')) {
          UI.DIALOG.error({
            heading: 'Failed to register :(',
            body: 'Please double check your email address.',
          });
          // } else if (stringifiedResponse.includes('is required when')) {
          //   UI.DIALOG.error({
          //     heading: 'Failed to register :(',
          //     body: 'Please provide a valid phone number and a valid email address.',
          //   });
        } else {
          reportError(error);
          UI.DIALOG.error({
            heading: 'Failed to register :(',
            body: <ErrorRenderer error={error.response} />
          });
        }
        s.lastError = error;
      } finally {
        s.awaitingResponse = false;
      }
    })(),
    autofillUserDialCode: flow(function* () {
      const dialCode: string | null = yield ANALYTICS.getUserDialCode();
      if (dialCode && !s.form.mobileNumber) {
        s.form.mobileNumber = dialCode;
      }
    })
  }))

  const handleOnUserAddressSave = action((a: AddressSnapshot) => {
    Object.assign(s.form.userAddress, a);
  })

  useUpdatePageBackground('#19976d', 'radial-gradient(204.06% 99.27% at 51.45% 117.53%, rgba(55, 218, 255, 0.863) 1.04%, rgba(55, 218, 255, 0) 100%)');

  useOnMount(() => {
    if (!isInCypressTestMode) s.autofillUserDialCode();
  })

  return (
    <Observer
      children={() => (
        <AuthPage className="PageRegister">
          <header className="PageRegisterHeader">
            <Link to="/">
              <Turn2MeLogo version="full" />
            </Link>
            <WindowTitle title="Create New Account" />
            <h1>Create free account</h1>
            <HeadingAllcaps>Welcome to turn2me!</HeadingAllcaps>
            <BaseSpacer size=".5em" />
          </header>

          <div className="PageRegisterBody">
            <AuthForm className="RegisterForm">
              <BaseHeading>Your Information</BaseHeading>
              <div className="formWrapper">
                <p>
                  To protect your anonymity please choose a username that cannot
                  be linked to, or mistaken for your real name or someone
                  else's.
                </p>
                <BaseSpacer />
                <BaseGrid columns={UI.onlyPhones ? 1 : 2}>
                  <BaseInput
                    form={s.form}
                    name="username"
                    field="username"
                    type="text"
                    label="Pick a Username"
                    disabled={s.awaitingResponse}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    autoFocus
                  />
                  <BaseInput
                    form={s.form}
                    name="email"
                    field="email"
                    type="email"
                    label="Your Email"
                    placeholder="example@email.com"
                    disabled={s.awaitingResponse}
                  />
                  <BaseInput
                    form={s.form}
                    field="password"
                    type="password"
                    label="Password"
                    disabled={s.awaitingResponse}
                  />
                  <BaseInput
                    form={s.form}
                    field="confirmPassword"
                    type="password"
                    label="Confirm Password"
                    disabled={s.awaitingResponse}
                  />
                  <BaseInput
                    form={s.form}
                    name="firstName"
                    field="firstName"
                    type="text"
                    label="First Name"
                    disabled={s.awaitingResponse}
                  />
                  <BaseInput
                    form={s.form}
                    name="lastName"
                    field="lastName"
                    type="text"
                    label="Last Name"
                    disabled={s.awaitingResponse}
                  />
                  <TelInput
                    name="mobileNumber"
                    form={s.form}
                    field="mobileNumber"
                    placeholder="Enter your mobile phone"
                    label="Mobile Number"
                    usePublicAPIValidation
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    disabled={s.awaitingResponse}
                    onValidate={(v) => (s.userPhoneValidationResult = v)}
                  />
                  <GenderSelector form={s.form} field="gender" label="Gender" appearance="system" />
                  <DatePicker
                    form={s.form}
                    format={YYYYMMDD}
                    field="dateOfBirth"
                    label="Date of Birth"
                    disabled={s.awaitingResponse}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    shouldAlwaysUseSystemUI
                    name="dateOfBirth"
                  />
                  <BaseInput
                    form={s.form}
                    name="companyCode"
                    field="companyCode"
                    label="Company Code / Refugee Code"
                    type="text"
                    disabled={s.awaitingResponse}
                    optional
                  />
                </BaseGrid>
              </div>

              <BaseSpacer />
              <>
                <BaseHeading>
                  Your Address Information
                </BaseHeading>
                <div className="formWrapper">
                  <AddressEditor
                    address={s.form.userAddress}
                    saveChangesOnBlur
                    onSave={handleOnUserAddressSave}
                    doNotUseDetectedCountryId
                    required
                  />
                </div>
              </>

              <BaseSpacer />
              <>
                <BaseHeading>
                  Your Emergency Contact Information
                </BaseHeading>
                <div className="formWrapper">
                  <ContactEditor
                    contact={s.form.userEmergencyContact}
                    applyChangesImmediately
                    doNotUseOnMountGetUserDialCode
                    doNotUseDetectedDialCode
                    usePublicAPIValidation
                    required
                    includeInputEmail
                    onValidatePhone={(v) => (s.contactPhoneValidationResult = v)}
                  />
                </div>
              </>

              <BaseButton
                className="AuthFormSubmit"
                onClick={s.register}
                size="lg"
                icon="arrow"
                name="register"
                circle
                disabled={s.awaitingResponse}
              />
            </AuthForm>
          </div>

          <footer className="PageRegisterFooter">
            <HeadingAllcaps>Already have an account?</HeadingAllcaps>
            <HeadingAllcaps>
              <Link to="/auth/login" title="Login">
                → <ClickOrTap /> here to login
              </Link>
              .
            </HeadingAllcaps>
            <BaseSpacer />
          </footer>
        </AuthPage>
      )}
    />
  );

}

export default PageRegister;