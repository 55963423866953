import { PaymentMethod } from '@stripe/stripe-js';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import PaymentMethodIcon from '../../base/components/PaymentMethodIcon/PaymentMethodIcon';
import PseudoLink from '../../base/components/PseudoLink/PseudoLink';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { capitalizeFirstLetter } from '../../base/utils/string.utils';
import { isFunction } from '../../base/utils/typeChecks.utils';
import './PaymentMethodItem.scss';

interface PaymentMethodItemProps {
  paymentMethod: PaymentMethod;
  isSelected: any;
  onClick?: (paymentMethod: PaymentMethod) => unknown;
  onDelete?: (paymentMethod: PaymentMethod) => unknown;
}

const PaymentMethodItem: React.FC<PaymentMethodItemProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    get interactable() {
      return p.onClick && isFunction(p.onClick);
    },
    get canDelete() {
      return !!p.onDelete;
    }
  }))

  const handleClick = () => {
    p.onClick && p.onClick(p.paymentMethod);
  }
  const handleDelete = () => {
    p.onDelete && p.onDelete(p.paymentMethod);
  }

  return <Observer children={() => {
    const pm = p.paymentMethod;
    return (
      <div className={
        joinClassName(
          'PaymentMethodItem',
          s.interactable && 'interactable',
          p.isSelected && 'selected',
        )
      } onClick={handleClick} >
        <PaymentMethodIcon value={pm.card?.brand} />
        <div className="PaymentMethodItemInner">
          <h3 className="PaymentMethodItemCardHolderName">{pm.billing_details.name}</h3>
          {pm.card && <p>{capitalizeFirstLetter(pm.card.brand)} ending in {pm.card.last4}</p>}
          {p.onDelete && <PseudoLink className="link delete-link" onClick={handleDelete}>Delete this card</PseudoLink>}
        </div>
        {
          p.isSelected && <div className="PaymentMethodItemSelectedMarker"><BaseIcon name="check" variant="filled"/></div>
        }
      </div>
    )
  }} />
}

export default PaymentMethodItem;