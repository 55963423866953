import { action, flow, observable } from 'mobx';
import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { ColorCodedState, Nullable, TimezoneMode } from '../../base/@types';
import AppPage from '../../base/components/AppPage/AppPage';
import AppPageContent from '../../base/components/AppPageContent/AppPageContent';
import AppPageHeader from '../../base/components/AppPageHeader/AppPageHeader';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseGrid from '../../base/components/BaseGrid/BaseGrid';
import BaseGridCell from '../../base/components/BaseGrid/BaseGridCell';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import BaseInput from '../../base/components/BaseInput/BaseInput';
import BaseSpacer from '../../base/components/BaseSpacer/BaseSpacer';
import BodyCopyRenderer from '../../base/components/BodyCopyRenderer/BodyCopyRenderer';
import ColorTag from '../../base/components/ColorTag/ColorTag';
import ColorTagArchived from '../../base/components/ColorTag/ColorTagArchived';
import ColorTagCompany from '../../base/components/ColorTag/ColorTagCompany';
import ColorTagFrontLineWorkers from '../../base/components/ColorTag/ColorTagFrontLineWorkers';
import CommandList from '../../base/components/CommandList/CommandList';
import { CommandListItem } from '../../base/components/CommandList/CommandListItem';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import HeadingAllcaps from '../../base/components/HeadingAllcaps/HeadingAllcaps';
import InfoBanner from '../../base/components/InfoBanner/InfoBanner';
import LoadingIndicatorSection from '../../base/components/LoadingIndicatorSection/LoadingIndicatorSection';
import OverlayCloseButton from '../../base/components/OverlayCloseButton/OverlayCloseButton';
import PseudoLink from '../../base/components/PseudoLink/PseudoLink';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import TelInput from '../../base/components/TelInput/TelInput';
import UIBlock from '../../base/components/UIBlock/UIBlock';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { makeActionConfig, makeCancelAction } from '../../base/utils/actionConfig.utils';
import joinClassName from '../../base/utils/className.utils';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { AlwaysTrueFn, cond } from '../../base/utils/ramdaEquivalents.utils';
import { createUTCMoment, HHmm } from '../../base/utils/time.utils';
import { setUrlParam, useSyncUrlParams } from '../../base/utils/urlParams.utils';
import tick from '../../base/utils/waiters.utils';
import { ModelName } from '../../constants/modelNames.enum';
import { openAgeVerificationOverlay } from '../../controllers/auth/verifyAge';
import { presentPhoneVerificationOverlay } from '../../controllers/auth/verifyMobileNumber';
import { CLOCK } from '../../controllers/common/clock.controller';
import { getWordPressLink } from '../../env';
import { useCurrentUserIsAdult } from '../../hooks/useCurrentUserAge.hook';
import { useCurrentUserEligibility } from '../../hooks/useCurrentUserEligibility.hook';
import { useSupportGroupGetters } from '../../hooks/useSupportGroupGetters.hook';
import { ContactSnapshot, ContactType, makeContact } from '../../models/makeContact.model';
import { SupportGroup } from '../../models/makeSupportGroup.model';
import { deregisterFromSupportGroup, registerSupportGroup } from '../../requests/registerOrDeregisterSupportGroup.request';
import { saveContact } from '../../requests/saveContact.request';
import { useGetSupportGroup } from '../../requests/useGetSupportGroup.request';
import { getAgeFromDateOfBirth } from '../../utils/ageAndDateOfBirth.utils';
import { checkIfUserIsWithinAgeGroupsOfSupportGroup } from '../../utils/supportGroup.helpers';
import SupportGroupAgeGroupTagSet from '../SupportGroupAgeGroupTagSet/SupportGroupAgeGroupTagSet';
import SupportGroupIcon from '../SupportGroupIcon/SupportGroupIcon';
import SupportGroupJoinedCountDisplay from '../SupportGroupJoinedCountDisplay/SupportGroupJoinedCountDisplay';
import './OverlaySupportGroupViewer.scss';

interface OverlaySupportGroupViewerProps {
  supportGroup?: SupportGroup,
  supportGroupId?: string,
  inputTimezoneMode?: TimezoneMode,
  outputTimezoneMode?: TimezoneMode,
}

const OverlaySupportGroupViewer: React.FC<OverlaySupportGroupViewerProps> = props => {

  const p = useProps(props);

  const { UI, API, AUTH, NAVIGATOR, LOCALDB, SUPPORT_GROUPS, ANALYTICS } = useControllers();

  const eligibility = useCurrentUserEligibility();

  const s = useStore(() => ({
    _supportGroup: p.supportGroupId ? LOCALDB.get<SupportGroup>(ModelName.supportGroups, p.supportGroupId) : p.supportGroup,
    get group() {
      return p.supportGroup || s._supportGroup;
    },
    get id() {
      return p.supportGroup?.id || p.supportGroupId;
    },
    get canRegister() {
      return !sg.isInThePast &&
      !s.group?.timeArchived &&
        !s.group?.timeStarted &&
        !s.group?.currentUserHasBeenRevoked &&
      (eligibility.hasVerified || s.isAdminOrCounsellorOrCounsellor) &&
      s.currentUserIsWithinAgeRange
      ;
    },
    get isAdminOrCounsellorOrCounsellor() {
      return AUTH.isStaff || AUTH.isCounsellor || AUTH.isFacilitator;
    },
    get canEdit() {
      return AUTH.isStaff || AUTH.currentUser?.id === sg.facilitatorId;
    },
    get userJoinedSupportGroups() {
      return SUPPORT_GROUPS.ownGroupsLoadedSorted;
    },
    get userJoinedSupportGroupsRetrieved() {
      return SUPPORT_GROUPS.currentUserSupportGroupsRetrieved;
    },
    get userJoinedSupportGroupsNotArchived() {
      return s.userJoinedSupportGroups.filter(g => !g.timeArchived);
    },
    get shouldShowRegisterBlock() {
      return s.group && s.group.maxParticipants > 0 && s.userJoinedSupportGroupsRetrieved && s.currentUserIsWithinAgeRange;
    },

    get currentUserAge() {
      return AUTH.currentUser?.dateOfBirth && getAgeFromDateOfBirth(AUTH.currentUser.dateOfBirth);
    },
    get currentUserIsWithinAgeRange() {
      return checkIfUserIsWithinAgeGroupsOfSupportGroup(AUTH.currentUser, s.group);
    },
    get currentUserIsFacilitatorOfGroup() {
      return AUTH.currentUser && (AUTH.currentUser?.id === s.group?.facilitatorId);
    },
    get afterTitle() {
      return <Observer children={() => <>
        <p className="OverlaySupportGroupViewerSubtitle"><strong>{s.group?.subtitle}</strong></p>
        <div>
          { sg.isArchived && <><ColorTagArchived /> </>}
          { sg.isForFrontLineWorkers && <><ColorTagFrontLineWorkers /> </>}
          {s.group && <SupportGroupAgeGroupTagSet group={s.group} />}
        </div>
      </>} />
    },
    get beforeTitle() {
      return s.startingTagContent
        ? <>
          <span className="SupportGroupViewerStartingTag">{s.startingTagContent}</span>
          <BaseSpacer size="xs" inline />
          <ColorTagCompany name={sg.sg?.allowedCompany?.name} code={sg.sg?.allowedCompany?.code} />
        </>
        : (
          currentUserIsAdult ? 'Support Group' : 'Support Group'
        );
    },
    get canJoinAfterLive() {
      return sg.isLive && !sg.hasJoined && !sg.isFull;
    },
    get now() {
      return CLOCK.localNowMoment;
    },
    get todayDateFormatted() {
      return s.now.format('D MMMM');
    },
    get scheduledStartTimeMoment() {
      return s.group ? createUTCMoment(s.group.timeScheduled).local() : undefined;
    },
    get actualStartTimeMoment() {
      return s.group ? createUTCMoment(s.group.timeStarted).local() : undefined;
    },
    get scheduledStartTimeFormatted() {
      return s.scheduledStartTimeMoment?.isValid() ? s.scheduledStartTimeMoment.format(HHmm) : '--:--';
    },
    get isThisYear() {
      return s.scheduledStartTimeMoment?.isSame(undefined, 'year');
    },
    get scheduledStartDateFormatted() {
      return s.scheduledStartTimeMoment?.isValid() ? s.scheduledStartTimeMoment.format('D MMMM') : '';
    },
    get isToday() {
      return s.scheduledStartTimeMoment?.isSame(undefined, 'day');
    },
    get isTomorrow() {
      return s.scheduledStartTimeMoment ? createUTCMoment(s.scheduledStartTimeMoment).subtract(1, 'day').isSame(undefined, 'day') : false;
    },
    get isCancelled() {
      return Boolean(s.group?.timeArchived || s.group?.timeDeleted);
    },
    get scheduledTimeDiffFromNow() {
      return s.scheduledStartTimeMoment?.diff(s.now);
    },
    get scheduledTimeDiffFromNowFormatted() {
      return s.scheduledStartTimeMoment?.isValid() ? moment.duration(s.scheduledTimeDiffFromNow).humanize() : '';
    },
    get actualStartTimeDiffFromNow() {
      return s.actualStartTimeMoment?.diff(s.now);
    },
    get actualStartTimeDiffFromNowFormatted() {
      return s.actualStartTimeMoment?.isValid() ? moment.duration(s.actualStartTimeDiffFromNow).humanize() : '';
    },
    get isScheduledInThePast() {
      return s.scheduledStartTimeMoment?.isBefore(s.now);
    },
    get isScheduledInTheFuture() {
      return !s.isScheduledInThePast;
    },
    get hasStarted() {
      return !!s.group?.timeStarted;
    },
    get isUpccoming() {
      return !s.isCancelled && !s.hasStarted && s.isToday;
    },
    get startingTagContent() {
      if (s.hasStarted) {
        if (!s.group?.timeEnded) return `Started ${s.actualStartTimeDiffFromNowFormatted} ago`;
        return <>Started on <DateRenderer value={s.group.timeStarted} format='LLL' /></>;
      }
      if (s.isUpccoming) return s.isScheduledInThePast ? `Scheduled to start ${s.scheduledTimeDiffFromNowFormatted} ago` : `Starting in ${s.scheduledTimeDiffFromNowFormatted}`;
      return undefined;
    },
    get editButtonLabel() {
      return (s.hasStarted || s.isCancelled || sg.isLive || sg.hasEnded) ? 'Manage Support Group' :  'Manage or Start Support Group';
    },
    errorGettingData: null as Nullable<Error>,
    get canDeregister() {
      return sg.hasJoined && !sg.isInThePast && !sg.hasStarted;
    },
    get infoBanner() {
      return cond([
        [
          () => sg.isArchived,
          () => <></>
        ],
        [
          () => sg.isLive,
          () => <InfoBanner colorCodedState={ColorCodedState.positive} heading={<><ColorTag color="red">Live</ColorTag> This support group is currently live.</>}>
            {s.canJoinAfterLive && <>
              <p>If you are interested in this group, you can still join the discussion by clicking the 'Join Group' button.</p>
              <BaseButton onClick={requestToJoinLiveSession}>Join Group</BaseButton>
            </>}
          </InfoBanner>
        ],
        [
          () => sg.hasJoined && !sg.isInThePast,
          () => null
        ],
        [
          () => sg.hasJoined && sg.isInThePast,
          () => <InfoBanner icon="info" colorCodedState={ColorCodedState.positive}>
            <p>You were registered for this group.</p>
          </InfoBanner>
        ],
        [
          () => sg.isInThePast,
          () => <InfoBanner icon="info" colorCodedState={ColorCodedState.attention}>
            <p>This group is in the past. If you would like to join groups of the same topic, try looking for them in the coming days. New events are constantly being scheduled, so check back often!</p>
          </InfoBanner>
        ],
        [
          () => sg.isFull,
          () => <InfoBanner icon="info" colorCodedState={ColorCodedState.attention}>
            <p>This group is full. If you would like to join groups of the same topic, try looking for them in the coming days. New events are constantly being scheduled, so check back often!</p>
          </InfoBanner>
        ]
      ])();
    },
    get availabilityNoticeText() {
      return cond([
        [
          () => s.group?.timeArchived,
          () => <>
            <h4>This group has been archived.</h4>
          </>
        ],
        [
          () => sg.isLive,
          () => <>
            {sg.hasJoined ? <p>If you had joined this group, you should be see the chat window now in the Chat &amp; Sessions panel.</p>
              : <></>}
          </>
        ],
        [
          () => s.isAdminOrCounsellorOrCounsellor,
          () => <>
            <h4><SupportGroupJoinedCountDisplay supportGroup={s.group} /></h4>
          </>,
        ],
        [
          () => !sg.hasAvailability,
          () => <h4>This group is full.</h4>,
        ],
        [
          () => Boolean(sg.isInThePast),
          () => <h4>This group is in the past.</h4>
        ],
        [
          () => Boolean(s.group?.currentUserHasBeenRevoked),
          () => <h4>This group is no longer available to join.</h4>
        ],
        [
          () => Boolean(s.group?.currentUserHasRegistered),
          () => <>
            <h4>You are registered!</h4>
            { s.canDeregister && (
              <p>If you can no longer attend, please <PseudoLink onClick={confirmDeregister}>click here to cancel your booking</PseudoLink> in advance so others can join.</p>
            ) }
          </>
        ],
        [
          AlwaysTrueFn, () => <>
            <h4><SupportGroupJoinedCountDisplay supportGroup={s.group} /></h4>
          </>
        ]
      ])();
    }
  }));

  const sg = useSupportGroupGetters(() => s.group);

  useSyncUrlParams('supportGroupId', s.id);
  useGetSupportGroup({observable: s, key: '_supportGroup'}, s.id);

  const confirmRegisterForGroup = () => new Promise<boolean>((resolve, reject) => {

    if (s.currentUserIsFacilitatorOfGroup) {
      UI.DIALOG.success({ heading: 'You are the facilitator of this group. There is no need to register for this group as you will automatically be added to the group on session start.' });
      resolve(false);
      return;
    }

    const form = observable({
      keyWorker: makeContact({
        type: ContactType.KeyWorker,
      }).$getSnapshot(),
    })
    ANALYTICS.getUserDialCode().then(action((code: string | null) => {
      if (code && !form.keyWorker.phone) {
        form.keyWorker.phone = code;
      }
    }))
    UI.DIALOG.present({
      heading: 'Support group registration',
      body: <div>
        <p>{ sg.isLive ? 'You will join the support group right away and other members of the group will be able to see that you have joined.' : (
          'Before registering, please keep in mind that if you cannot attend the group later on, remember to cancel your appointment in advance so that other people can join.'
        )}</p>
        { s._supportGroup?.requiresKeyWorkerInformation && <ShadedBlock spaceChildren>
          <h4>Key Worker Information</h4>
          <p>This support group requires all participants to provide their key worker information prior to joining.</p>
          <BaseGrid columns={UI.fromTablet ? 2 : 1}>
            <BaseGridCell columns="all">
              <BaseInput form={form.keyWorker} field="name" label="Key Worker Name" required/>
            </BaseGridCell>
            <TelInput form={form.keyWorker} field="phone" label="Key Worker Phone" required/>
            <BaseInput form={form.keyWorker} field="email" type="email" label="Key Worker Email" optional/>
          </BaseGrid>
        </ShadedBlock>}
      </div>,
      color: 'blueGreen',
      actions: [
        {
          name: 'negative',
          buttonClass: 'subtle',
          label: 'Cancel',
          action: () => reject()
        },
        observable({
          label: 'Confirm & Register',
          action: () => {
            resolve(registerForGroup(
              s._supportGroup?.requiresKeyWorkerInformation ? form.keyWorker : undefined
            ))
          },
          get disabled() {
            if (!s._supportGroup?.requiresKeyWorkerInformation) return false;
            return !form.keyWorker.name || !form.keyWorker.phone
          },
        })
      ],
      width: '38em',
    })
  })

  const registerForGroup = (keyWorker?: ContactSnapshot) => {
    if (!eligibility.hasVerified) {
      UI.DIALOG.error({ heading: 'You must verify either your phone or your email to access support group services.' });
      return false;
    }
    if (!s.currentUserIsWithinAgeRange) {
      UI.DIALOG.error({ heading: 'You are not eligible for this group.' });
      return false;
    }
    return new Promise<boolean>(flow(function* (resolve, reject) {
      if (!s.isAdminOrCounsellorOrCounsellor) {
        if (!AUTH.currentUser?.isFrontLineWorker && s.group?.isFrontLineWorkerGroup) {
          const isUserFrontLineWorker = yield UI.DIALOG.present({
            heading: 'This group is designed for front line workers.',
            body: <div>
              <p>Feel free to join if you identify yourself as a front line worker. However, if you are not a front line worker, we recommend that you join other support groups that are open to all users.</p>
              <BaseSpacer size=".5em" />
              <BaseButton className="subtle" href={getWordPressLink(('/services/front-line-workers'))} target="_blank" label={<>Know more <BaseIcon icon="external-link" size="1.4rem" variant="filled" /></>} />
            </div>,
            actions: [
              makeCancelAction(),
              {
                label: "I am a front line worker", action: flow(function* () {
                  if (!AUTH.currentUser) return;
                  AUTH.currentUser.preferences.isFrontLineWorker = true;
                  yield AUTH.saveCurrentUser();
                  return true;
                })
              }
            ]
          })
          if (!isUserFrontLineWorker) {
            reject(false);
            return false;
          }
        }
      }
      const { id } = s.group || {};
      if (!id) {
        reportError('trying to join a group without a group id.');
        reject(false);
        return;
      }
      try {
        const savedKeyWorker = keyWorker ? yield saveContact(keyWorker, API) : null;
        yield registerSupportGroup(API, id, keyWorker ? {
          keyWorkerId: savedKeyWorker.id,
        } : {});
        if (s.group) {
          s.group.reservationCount++;
          s.group.currentUserHasRegistered = true;
        }
        UI.TOAST.positive('You have been registered for the group successfully.');

        resolve(true);
      } catch (e) {
        reject(e);
        UI.DIALOG.error({
          heading: 'Failed to join the group',
          body: () => <>
            <p>Please try again shortly or try after refreshing the page. If this keeps happening, please contact us for customer support.</p>
            <ErrorRenderer error={(e as any).response} />
          </>,
        })
      }
    }))
  }

  const confirmDeregister = () => {
    UI.DIALOG.attention({
      heading: 'Are you sure you want to cancel your booking for this support group?',
      defaultActions: ['negative'],
      actions: [makeActionConfig('Confirm & Cancel', deregister)]
    })
  }

  const deregister = () => new Promise<boolean>(flow(function* (resolve, reject) {
    const { id } = s.group || {};
    if (!id) {
      reportError('trying to deregister from a group without a group id.');
      return;
    }
    try {
      const { support_group: supportGroup } = yield deregisterFromSupportGroup(API, id);
      patchLocalSupportGroup(supportGroup, false);
      UI.TOAST.positive('You have successfully cancelled your support group booking.');
      if (s.group) s.group.reservationCount--;
      resolve(true);
    } catch (e) {
      reject(e);
      UI.DIALOG.error({
        heading: 'Failed to cancel the booking',
        body: () => <>
          <p>Please try again shortly or try after refreshing the page. If this keeps happening, please contact us for customer support.</p>
          <ErrorRenderer error={(e as any).response} />
        </>,
      })
    }
  }))

  const patchLocalSupportGroup = (supportGroup: SupportGroup, registered: boolean) => {
    p.supportGroup?.$patch(supportGroup);
    s._supportGroup?.$patch(supportGroup);
    s.group && (s.group.currentUserHasRegistered = registered);
  }

  const requestToJoinLiveSession = async () => {
    if (!s.canJoinAfterLive) return;
    if (!AUTH.currentUser?.hasVerifiedEmailOrMobileNumber) {
      UI.DIALOG.present({
        heading: 'Please verify your account first.',
      })
      return;
    }
    await confirmRegisterForGroup();
  }

  const edit = async () => {
    if (!s.id) return;
    await UI.OVERLAY.dismiss(`OverlaySupportGroupManager#${s.id}`);
    await tick();
    setUrlParam('manageSupportGroupId', s.id, NAVIGATOR);
  }

  useOnMount(() => {
    (async () => {
      if (!AUTH.currentUser?.dateOfBirth) {
        await tick(380);
        openAgeVerificationOverlay(UI, true);
      }
    })()
  })

  const currentUserIsAdult = useCurrentUserIsAdult();

  useSyncUrlParams('supportGroupId', s.id);

  return <Observer children={() => (
    <AppPage
      className={
        joinClassName(
          'OverlaySupportGroupViewer',
          sg.isForFrontLineWorkers && 'for-front-line-workers',
          sg.isArchived && 'archived',
          sg.sg?.currentUserHasRegistered && 'current-user-has-registered'
        )
      }
      data-support-group-id={s.id}
      data-current-user-has-registered={sg.sg?.currentUserHasRegistered}
      color={s.group?.color}
    >

      <AppPageHeader
        startSlot={<SupportGroupIcon supportGroup={s.group} size={UI.onlyPhones ? 42 : 64}/>}
        beforeTitle={s.beforeTitle}
        title={<span data-cy="support-group-viewer-title">{sg.title}</span>}
        afterTitle={<span data-cy="support-group-viewer-subtitle">{s.afterTitle}</span>}
        endSlot={<OverlayCloseButton />}
      />

      <AppPageContent>

        {
          s.errorGettingData ? <ShadedBlock spaceChildren color={ColorCodedState.alert}>
            <h3>Error Getting Support Group Data</h3>
            <ErrorRenderer error={s.errorGettingData} />
          </ShadedBlock> : <UIBlock padded="all" spaceChildren>

              <ShadedBlock spaceChildren>
                <HeadingAllcaps>Scheduled Time</HeadingAllcaps>
                <div className="SupportGroupViewerTimeDisplay">
                  <time className="SupportGroupViewerTimeDisplayTime" data-value={s.group?.timeScheduled}>{s.scheduledStartTimeFormatted}</time>
                  <time className="SupportGroupViewerTimeDisplayDate" data-value={s.group?.timeScheduled}>
                    {s.isToday && <>Today, <br /></>}
                    {s.isTomorrow && <>Tomorrow, <br /></>}
                    {!s.isToday && !s.isTomorrow && s.group?.timeScheduled && <><DateRenderer value={s.group?.timeScheduled} format="dddd" />, <br /></>}
                    {s.scheduledStartDateFormatted}
                  </time>
                </div>
              </ShadedBlock>

              {!s._supportGroup && <LoadingIndicatorSection />}

              {
                AUTH.currentUser?.dateOfBirth ? (
                  s.currentUserIsWithinAgeRange || <ShadedBlock>
                    <h3>You are not eligible for this support group.</h3>
                  </ShadedBlock>
                ) : (
                  <ShadedBlock color="green" spaceChildren>
                    <h3>Please verify your age before using the support group services.</h3>
                    <p>Support groups are all designed to be age-specific. Before you can register for any groups, we need to know your date of birth.</p>
                    <BaseButton onClick={() => openAgeVerificationOverlay(UI, true)}>Verify your age</BaseButton>
                  </ShadedBlock>
                )
              }

              {
                !eligibility.hasVerified && <InfoBanner colorCodedState={ColorCodedState.positive} icon="warning"
                  heading="Account Verification Required">
                  <p>You must verify either your phone or your email before joining support groups.</p>
                  {AUTH.currentUser?.email && !AUTH.currentUser.hasVerifiedEmail && <p>Please check your inbox to complete your email verification.</p>}
                  {AUTH.currentUser?.mobileNumber && !AUTH.currentUser.hasVerifiedMobileNumber && <BaseButton onClick={() => presentPhoneVerificationOverlay(UI)}>Enter SMS Verification Code</BaseButton>}
                </InfoBanner>
              }

              {s.shouldShowRegisterBlock && <>
                <ShadedBlock className="SupportGroupViewerAvailabilityDisplay" spaceChildren>
                  <HeadingAllcaps>RSVP</HeadingAllcaps>
                  {s.infoBanner}
                  <div className="SupportGroupViewerAvailabilityDisplayInner">{s.availabilityNoticeText}</div>
                  <BaseSpacer size=".1em" />
                  {
                    s.canDeregister ? (
                      <BaseButton
                        key="deregister-button"
                        size="lg"
                        onClick={confirmDeregister}
                        fullWidth
                        icon="check"
                        label="Registered"
                        color="green"
                        hoverIcon="close"
                        hoverLabel="Cancel Booking"
                        hoverColor="orange"
                      />
                    ) : (
                      <BaseButton
                        key="join-button"
                        size="lg"
                        onClick={confirmRegisterForGroup}
                        fullWidth
                        label="Register"
                        disabled={sg.hasJoined || !s.canRegister}
                      />
                    )
                  }
                </ShadedBlock>
              </>}

              {s.group?.description && <ShadedBlock spaceChildren>
                <HeadingAllcaps>Group Description</HeadingAllcaps>
                <ShadedBlock>
                  <BodyCopyRenderer dataCy="support-group-viewer-description" source={sg.description} />
                </ShadedBlock>
              </ShadedBlock>}

              {s.canEdit && <ShadedBlock spaceChildren>
                <HeadingAllcaps>For Facilitators</HeadingAllcaps>
                <BaseButton
                  size="md"
                  icon="cog"
                  className="subtle"
                  onClick={edit}
                  fullWidth
                  label={s.editButtonLabel}
                />
                <InfoBanner icon="info">
                  <p><strong>If you are the facilitator assigned to this group, you do NOT need to register.</strong> You will automatically be added to the chat session once it begins.</p>
                </InfoBanner>
              </ShadedBlock>}

              {s.canDeregister && <ShadedBlock spaceChildren>
                <HeadingAllcaps>More Options</HeadingAllcaps>
                <CommandList withBackground>
                  <CommandListItem icon="arrow" onClick={confirmDeregister}>Cancel your booking</CommandListItem>
                </CommandList>
              </ShadedBlock>}

            </UIBlock>
        }

      </AppPageContent>

    </AppPage>
  )} />
}

export default OverlaySupportGroupViewer;