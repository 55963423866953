import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Undefinable } from '../../base/@types';
import { getGreetingFn } from '../../base/bots/responses/greetings.botResponses';
import AppPage from '../../base/components/AppPage/AppPage';
import AppPageContent from '../../base/components/AppPageContent/AppPageContent';
import AppPageContentFooter from '../../base/components/AppPageContentFooter/AppPageContentFooter';
import AppPageHeader from '../../base/components/AppPageHeader/AppPageHeader';
import BaseArticle from '../../base/components/BaseArticle/BaseArticle';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseIcon from '../../base/components/BaseIcon/BaseIcon';
import BaseInput from '../../base/components/BaseInput/BaseInput';
import ErrorRenderer from '../../base/components/ErrorRenderer/ErrorRenderer';
import OverlayCloseButton from '../../base/components/OverlayCloseButton/OverlayCloseButton';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import UIBlock from '../../base/components/UIBlock/UIBlock';
import { SurveySatisfactionEndpoints } from '../../base/endpoints/survey.endpoints';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { getSnapshot } from '../../base/utils/snapshot.utils';
import { getNowTimestampUtc } from '../../base/utils/time.utils';
import { isNumber } from '../../base/utils/typeChecks.utils';
import { getApiModelDef } from '../../constants/ApiModels.enum';
import { ModelName } from '../../constants/modelNames.enum';
import { Assignment, AssignmentSatisfaction } from '../../models/makeAssignment.model';
import { CounsellingApplication } from '../../models/makeCounsellingApplication.model';
import { makeSurveySatisfaction, SurveySatisfactionSnapshot } from '../../models/makeSurveySatisfaction.model';
import SurveySelectorWithFourLevels from '../SurveySelectorWithFourLevels/SurveySelectorWithFourLevels';
import Turn2MeLogo from '../Turn2MeLogo/Turn2MeLogo';
import './OverlaySurveySatisfaction.scss';

interface OverlaySurveySatisfactionProps {
	application?: CounsellingApplication,
  assignment?: AssignmentSatisfaction,
  assignmentId?: string,
  onComplete?: (a?: AssignmentSatisfaction) => unknown,
  readonly?: any,
}

const OverlaySurveySatisfaction: React.FC<OverlaySurveySatisfactionProps> = props => {

  const { API, UI, AUTH } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    _assignment: undefined as Undefinable<Assignment>,
    get assignment() {
      return p.assignment || s._assignment;
    },
    get assignmentId() {
      return s.assignment?.id || p.assignmentId || ''
    },
    survey: makeSurveySatisfaction(p.assignment?.target),
    get formIsComplete() {
      return isNumber(s.survey.ratingUx) &&
        isNumber(s.survey.ratingOfCounsellingExperience) &&
      isNumber(s.survey.ratingOfCounsellorRelationship)
    },
    get targetModelTypeDisplayName() {
      return getApiModelDef(s.assignment?.targetType)?.displayName;
    },
    get associatedModelTypeDisplayName() {
      return getApiModelDef(s.assignment?.associatedType)?.displayName;
    },
    _application: p.application as CounsellingApplication | undefined,
  }));

  const dismiss = () => {
    UI.OVERLAY.dismiss('OverlaySurveySatisfaction');
  }

  useOnMount(() => {
    if (!p.readonly) {
      s.survey.assignmentId = s.assignmentId;
    }
  });

  const submit = flow(function* () {
    UI.OVERLAY.dismiss();
    try {
      const url = SurveySatisfactionEndpoints.own.create();
      const payload: SurveySatisfactionSnapshot = getSnapshot(s.survey);
      payload.assignmentId = s.assignment?.id;
      payload.assignedToUserId = s.assignment?.assignedToUserId;
      payload.completedByUserId = AUTH.currentUser?.id;
      payload.timeCreated = getNowTimestampUtc();
      delete payload.timeDeleted;
      delete payload.timeUpdated;
      yield API.post(url, ModelName.surveysSatisfaction, payload);
      UI.DIALOG.success({
        name: 'satisfaction-survey-submission-success',
        heading: 'Your survey was submitted successfully.',
        body: 'Thanks for your participation! The surveys will help us improve our services for all of our clients. You can now continue to use the service.',
      });
      if (p.assignment) {
        p.assignment.timeCompleted = getNowTimestampUtc();
      }
      p.onComplete && p.onComplete(p.assignment);
      dismiss();
    } catch (e) {
      reportError(e);
      UI.DIALOG.error({
        heading: 'Sorry! Your form was not submitted successfully.',
        body: <ErrorRenderer error={(e as any).response} />,
      })
    }
  })

  return <Observer children={() => (
    <AppPage className="OverlaySurveySatisfaction">
      <AppPageHeader
        title={<div className="OverlaySurveySatisfactionTitle">Satisfaction <br />Survey</div>}
        endSlot={p.readonly ? <OverlayCloseButton /> : <Turn2MeLogo version="full"/>}
        spaceChildren
      />
      <AppPageContent padSections>
        {
          !p.readonly && <UIBlock>
            <p>{getGreetingFn('generic')} As you have just completed online counselling with us, please spare 2 minutes to rate your counselling experience with us.</p>
          </UIBlock>
        }
        <UIBlock>
          <BaseArticle className="SurveyEditorSatisfaction">
            <div className="SurveyEditorSatisfactionQuestion">
              <BaseIcon icon="question" />
              <p>How was your experience of Online Counselling with turn2me?</p>
              <SurveySelectorWithFourLevels form={s.survey} field="ratingOfCounsellingExperience" readonly={p.readonly} labelType="satisfaction" />
            </div>
            <div className="SurveyEditorSatisfactionQuestion">
              <BaseIcon icon="question" />
              <p>How would you rate your progress in counselling? (If Applicable)</p>
              <SurveySelectorWithFourLevels form={s.survey} field="ratingSelfProgress" readonly={p.readonly} labelType="satisfaction" nullable />
            </div>
            <div className="SurveyEditorSatisfactionQuestion">
              <BaseIcon icon="question" />
              <p>How would you describe your relationship with your Counsellor?</p>
              <SurveySelectorWithFourLevels form={s.survey} field="ratingOfCounsellorRelationship" readonly={p.readonly} labelType="satisfaction" />
            </div>
            <div className="SurveyEditorSatisfactionQuestion">
              <BaseIcon icon="question" />
              <p>How was your experience of using our website?</p>
              <SurveySelectorWithFourLevels form={s.survey} field="ratingUx" readonly={p.readonly} labelType="satisfaction" />
            </div>
            <div className="SurveyEditorSatisfactionQuestion">
              <BaseIcon icon="question" />
              <p><em>Optional (leave blank if not relevant):</em></p>
              <p>How would you rate your experience of Online Counselling with your experience of Face-to-Face Counselling?</p>
              <SurveySelectorWithFourLevels form={s.survey} field="ratingOfOnlineVsOfflineCounselling" readonly={p.readonly} labelType="satisfaction" nullable />
            </div>
            {
              p.readonly ? <>
                <p><strong>Message</strong></p>
                <ShadedBlock>
                  {s.survey.body || <em>The client didn't leave a message.</em>}
                </ShadedBlock>
              </> : <>
                <p><strong>Leave a message: (Optional)</strong></p>
                <BaseInput type="textarea" form={s.survey} field="body" rows="5" />
              </>
            }
          </BaseArticle>
        </UIBlock>
        <AppPageContentFooter padded="all">
          {
            p.readonly ? <BaseButton fullWidth size="lg" onClick={dismiss}>Dismiss</BaseButton> : (
              <BaseButton fullWidth size="lg" onClick={submit} disabled={!s.formIsComplete} color="green" dataCy="Submit Survey">Submit Survey</BaseButton>
            )
          }
        </AppPageContentFooter>
      </AppPageContent>
    </AppPage>
  )} />
}

export default OverlaySurveySatisfaction;