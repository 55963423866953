import { flow, observable } from "mobx";
import { Nillable } from "../base/@types";
import { sortByTimeCreatedLatestFirst } from "../base/utils/array.utils";
import { NoOp } from "../base/utils/functions.utils";
import { convertRecordMapToArray } from "../base/utils/map.utils";
import { last } from "../base/utils/ramdaEquivalents.utils";
import { SurveyGoalSheet } from "../models/makeSurveyGoalSheet.model";
import { User } from "../models/makeUser.model";
import { APIController } from "./api.controller";
import { AuthController } from "./auth.controller";
import { LocalDBController } from "./localDB.controller";
import { makeControllerBase, makeRootControllerChildInitFn } from "./_root.controller";

/**
 * Managing surveys. Since the V2 UI is more dynamic than V1, surveys are no longer centrally managed in the UI,
 * though this controller is still responsible for top level surveys.
 * Note that in client.module.tsx, <SurveyGeneralWatcher /> is included to provide automatic prompt of general surveys if it is a new user.
 */
export type SurveysController = ReturnType<typeof makeSurveysController>;

export const makeSurveysController = () => {
	const c = observable({
		...makeControllerBase('SURVEYS'),
		get API(): APIController {
			return c.ROOT!.children.API;
		},
		get AUTH(): AuthController {
			return c.ROOT!.children.AUTH;
		},
		get LOCALDB(): LocalDBController {
			return c.ROOT!.children.LOCALDB;
		},
		get currentUser(): Nillable<User> {
			return c.AUTH.currentUser;
		},
		get goalSheetsOfCurrentUser(): SurveyGoalSheet[] {
			return c.currentUser?.id ? convertRecordMapToArray<SurveyGoalSheet>(c.LOCALDB.data.surveysGoalSheet).filter(s => s.assignedToUserId === c.currentUser!.id) : [];
		},
		get pendingGoalSheets(): SurveyGoalSheet[] {
			return c.goalSheetsOfCurrentUser.filter(g => !g.timeCompleted);
		},
		get latestPendingGoalSheet(): Nillable<SurveyGoalSheet> {
			return last(sortByTimeCreatedLatestFirst(c.pendingGoalSheets))
		},
		reset: NoOp,
	})

	c.init = makeRootControllerChildInitFn(
		c,
		flow(function* () {
			
		})
	);

	return c;
}
