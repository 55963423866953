import { Nillable } from "../base/@types";
import { singularize, toKebabCase } from "../base/utils/string.utils";
import { SHOULD_LOG } from "../env";
import { ModelName } from "./modelNames.enum";

export enum ApiModelName {
  COUNSELLING_APPLICATION = 'App\\Models\\Counselling\\Application',
  COUNSELLING_SESSION = 'App\\Models\\Counselling\\CounsellingSession',
  SUPPORT_GROUP = 'App\\Models\\SupportGroup\\SupportGroup',
  SURVEY_GAD7 = 'App\\Models\\Surveys\\SurveyGAD7',
  SURVEY_PHQ9 = 'App\\Models\\Surveys\\SurveyPHQ9',
  SURVEY_GOAL_SHEET = 'App\\Models\\Surveys\\SurveyGoalSheet',
  SURVEY_SATISFACTION = 'App\\Models\\Surveys\\SurveySatisfaction',
  SURVEY_SUPPORT_GROUP_SATISFACTION = 'App\\Models\\Surveys\\SurveySupportGroupSatisfaction',
  SURVEY_SUPPORT_GROUP_NON_ATTENDANCE = 'App\\Models\\Surveys\\SurveySupportGroupNonAttendance',
  SURVEY_GENERAL = 'App\\Models\\Surveys\\SurveyGeneral',
  INVITATION = 'App\\Models\\Counselling\\CounsellingApplicationInvitation',
  USER = 'App\\Models\\User',
  THOUGHT = 'App\\Models\\ThoughtCatcher\\Thought',
  COMMENT = 'App\\Models\\ThoughtCatcher\\ThoughtComment',
  REACTION = 'App\\Models\\Reaction',
  PAYMENT = 'App\\Models\\Payment',
  SUBSCRIPTION = 'App\\Models\\Subscription',
}

export enum PseudoApiModelName {
  DONATION = 'App\\Models\\Donation'
}

export type ApiModelDef = {
  apiModelName?: ApiModelName | PseudoApiModelName,
  name?: ModelName,
  nameSingular?: string,
  nameSingularKebab?: string,
  displayName?: string,
  color?: string,
}

export const ApiModelDefMap: Record<ApiModelName | PseudoApiModelName, {
  modelName: string,
  displayName: string,
}> = {
  [ApiModelName.COUNSELLING_APPLICATION]: {
    modelName: 'counsellingApplications',
    displayName: 'counselling application',
  },
  [ApiModelName.COUNSELLING_SESSION]: {
    modelName: 'counsellingSessions',
    displayName: 'counselling session',
  },
  [ApiModelName.SUPPORT_GROUP]: {
    modelName: 'supportGroups',
    displayName: 'support group',
  },
  [ApiModelName.USER]: {
    modelName: 'users',
    displayName: 'user',
  },
  [ApiModelName.THOUGHT]: {
    modelName: 'thoughts',
    displayName: 'thought',
  },
  [ApiModelName.REACTION]: {
    modelName: 'reactions',
    displayName: 'reaction',
  },
  [ApiModelName.COMMENT]: {
    modelName: 'comments',
    displayName: 'comment',
  },
  [ApiModelName.SURVEY_GAD7]: {
    modelName: 'surveysGAD7',
    displayName: 'GAD7 survey',
  },
  [ApiModelName.SURVEY_PHQ9]: {
    modelName: 'surveysPHQ9',
    displayName: 'PHQ9 survey',
  },
  [ApiModelName.SURVEY_GOAL_SHEET]: {
    modelName: 'surveysGoalSheet',
    displayName: 'goal sheet',
  },
  [ApiModelName.SURVEY_SATISFACTION]: {
    modelName: 'surveysSatisfaction',
    displayName: 'satisfaction survey',
  },
  [ApiModelName.SURVEY_SUPPORT_GROUP_NON_ATTENDANCE]: {
    modelName: 'surveysSupportGroupNonAttendance',
    displayName: 'support group non attendance survey',
  },
  [ApiModelName.SURVEY_SUPPORT_GROUP_SATISFACTION]: {
    modelName: 'surveysSupportGroupSatisfaction',
    displayName: 'support group satisfaction survey',
  },
  [ApiModelName.SURVEY_GENERAL]: {
    modelName: 'surveysGeneral',
    displayName: 'general survey',
  },
  [ApiModelName.INVITATION]: {
    modelName: 'invitations',
    displayName: 'invitation',
  },
  [ApiModelName.PAYMENT]: {
    modelName: 'payments',
    displayName: 'payment',
  },
  [ApiModelName.SUBSCRIPTION]: {
    modelName: 'subscriptions',
    displayName: 'subscription',
  },
  [PseudoApiModelName.DONATION]: {
    modelName: 'donations',
    displayName: 'donation',
  },
}

export function getApiModelDef(apiModelName?: Nillable<ApiModelName | PseudoApiModelName> | '') {
  if (!apiModelName) return null;
  const partialDef = ApiModelDefMap[apiModelName] ?? ApiModelDefMap[apiModelName.replace(/\\\\/g, '\\') as ApiModelName & PseudoApiModelName];
  if (!partialDef) {
    SHOULD_LOG() && console.warn(`trying to get definition of model ${apiModelName} which was not defined in the application.`);
  }
  const name = partialDef?.modelName;
  const nameSingular = singularize(name || 'model');
  const def: ApiModelDef = {
    apiModelName: apiModelName,
    name: name as ModelName,
    nameSingular,
    nameSingularKebab: toKebabCase(nameSingular),
    displayName: partialDef.displayName,
    color: '',
  }
  return def;
}