import { BaseTableColumnConfig } from "../base/components/BaseTable/BaseTable";
import ColorTagCompany from "../base/components/ColorTag/ColorTagCompany";
import SupportGroupJoinedCountDisplay from "../components/SupportGroupJoinedCountDisplay/SupportGroupJoinedCountDisplay";
import UsernameRenderer from "../components/UsernameRenderer/UsernameRenderer";
import { SupportGroup } from "../models/makeSupportGroup.model";

export const supportGroupTableColumnConfigs: BaseTableColumnConfig<SupportGroup>[] = [
  {
    label: 'ID',
    keyName: 'id',
    sortable: true,
    searchable: true,
  },
  {
    label: 'Title',
    keyName: 'title',
    type: 'longText',
    sortable: true,
    searchable: true,
  },
  {
    label: 'Topic',
    keyName: 'topicId',
    sortable: true,
    BodyCell: d => d.topic?.displayName,
  },
  {
    label: 'Subtitle',
    keyName: 'title',
    type: 'longText',
    sortable: false,
    searchable: false,
    hiddenFromTable: true,
  },
  {
    label: 'Event Time',
    keyName: 'timeScheduled',
    type: 'timestampFull',
    sortable: true,
    searchable: false,
  },
  {
    label: 'Company',
    keyName: 'allowedCompanyId',
    sortable: true,
    searchable: false,
    BodyCell: d => <ColorTagCompany name={d.allowedCompany?.name} code={d.allowedCompany?.code}  />
  },
  {
    label: 'Availability',
    keyName: 'reservationCount',
    sortable: true,
    searchable: false,
    BodyCell: d => <SupportGroupJoinedCountDisplay supportGroup={d} />
  },
  {
    label: 'Facilitator',
    keyName: 'facilitatorId',
    sortable: true,
    searchable: 'facilitator.username',
    searchFilterLabel: "Facilitator Username",
    BodyCell: d => <UsernameRenderer user={d.facilitator} userId={d.facilitatorId} />
  },
  {
    label: 'Duration',
    keyName: 'scheduledDurationInMinutes',
    sortable: false,
    searchable: false,
    BodyCell: d => {
      const value = parseInt('' + d.scheduledDurationInMinutes) || 0;
      if (value) return <span>{value} min</span>
    }
  },
  {
    label: 'Billable',
    searchable: false,
    BodyCell: d => {
      if (d.isBillableForAllStaff) return 'Yes';
      if (d.isBillableForSomeStaff) return 'Only some staff in this group';
      return 'No';
    }
  }
]