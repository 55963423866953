import React from 'react';
import ColorTag from './ColorTag';

interface ColorTagYoungPeople1517Props {
  useAbbr?: boolean
}

const ColorTagYoungPeople1517: React.FC<ColorTagYoungPeople1517Props> = props => {
  return <ColorTag className="ColorTagYoungPeople1517" color="orange">
    {props.useAbbr ? '15-17' : 'Young People 15–17'}
  </ColorTag>
}

export default ColorTagYoungPeople1517;