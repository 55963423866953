import React from "react";
import { makeIconDef } from "./makeIconDef";

export const CalendarIconDef = {
  calendar: makeIconDef('calendar', {
    regular: () => <>
      <path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M8 12C8 11.1716 8.67157 10.5 9.5 10.5C10.3284 10.5 11 11.1716 11 12C11 12.8284 10.3284 13.5 9.5 13.5C8.67157 13.5 8 12.8284 8 12ZM4 16C4 15.1716 4.67157 14.5 5.5 14.5C6.32843 14.5 7 15.1716 7 16C7 16.8284 6.32843 17.5 5.5 17.5C4.67157 17.5 4 16.8284 4 16ZM9.5 14.5C8.67157 14.5 8 15.1716 8 16C8 16.8284 8.67157 17.5 9.5 17.5C10.3284 17.5 11 16.8284 11 16C11 15.1716 10.3284 14.5 9.5 14.5ZM12 12C12 11.1716 12.6716 10.5 13.5 10.5C14.3284 10.5 15 11.1716 15 12C15 12.8284 14.3284 13.5 13.5 13.5C12.6716 13.5 12 12.8284 12 12ZM13.5 14.5C12.6716 14.5 12 15.1716 12 16C12 16.8284 12.6716 17.5 13.5 17.5C14.3284 17.5 15 16.8284 15 16C15 15.1716 14.3284 14.5 13.5 14.5Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7 4.5C6.44772 4.5 6 4.05228 6 3.5C6 2.94772 6.44772 2.5 7 2.5C7.55228 2.5 8 2.94772 8 3.5C8 4.05228 7.55228 4.5 7 4.5ZM7 1.5C6.06808 1.5 5.28503 2.13739 5.06301 3H4C2.89543 3 2 3.89543 2 5V7V8V19C2 20.1046 2.89543 21 4 21H20C21.1046 21 22 20.1046 22 19V8V7V5C22 3.89543 21.1046 3 20 3H18.937C18.715 2.13739 17.9319 1.5 17 1.5C16.0681 1.5 15.285 2.13739 15.063 3H8.93699C8.71497 2.13739 7.93192 1.5 7 1.5ZM20 4H18.937C18.715 4.86261 17.9319 5.5 17 5.5C16.0681 5.5 15.285 4.86261 15.063 4H8.93699C8.71497 4.86261 7.93192 5.5 7 5.5C6.06808 5.5 5.28503 4.86261 5.06301 4H4C3.44772 4 3 4.44772 3 5V7H21V5C21 4.44772 20.5523 4 20 4ZM17 2.5C17.5523 2.5 18 2.94772 18 3.5C18 4.05228 17.5523 4.5 17 4.5C16.4477 4.5 16 4.05228 16 3.5C16 2.94772 16.4477 2.5 17 2.5ZM21 8H3V19C3 19.5523 3.44772 20 4 20H20C20.5523 20 21 19.5523 21 19V8ZM18 10C16.8954 10 16 10.8954 16 12C16 13.1046 16.8954 14 18 14C19.1046 14 20 13.1046 20 12C20 10.8954 19.1046 10 18 10Z" fill="currentColor" />
    </>,
    color: () => <>
      <path d="M2 5C2 3.89543 2.89543 3 4 3H20C21.1046 3 22 3.89543 22 5V7H2V5Z" fill="url(#CVOUBEERF)" />
      <path d="M2 7H22V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19V7Z" fill="url(#OIH2IHFOL)" />
      <rect x="16" y="10" width="4" height="4" rx="2" fill="#DC3F29" />
      <rect opacity="0.2" x="4" y="14.5" width="3" height="3" rx="1.5" fill="#4A4181" />
      <rect opacity="0.2" x="8" y="10.5" width="3" height="3" rx="1.5" fill="#4A4181" />
      <rect opacity="0.2" x="8" y="14.5" width="3" height="3" rx="1.5" fill="#4A4181" />
      <rect opacity="0.2" x="12" y="10.5" width="3" height="3" rx="1.5" fill="#4A4181" />
      <rect opacity="0.2" x="12" y="14.5" width="3" height="3" rx="1.5" fill="#4A4181" />
      <rect x="5" y="1.5" width="4" height="4" rx="2" fill="#FFFFFF" />
      <rect x="15" y="1.5" width="4" height="4" rx="2" fill="#FFFFFF" />
      <defs>
        <radialGradient id="CVOUBEERF" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24.5 14.5) rotate(-154.29) scale(29.9666 84.5886)">
          <stop stopColor="#DC3F29" />
          <stop offset="1" stopColor="#DC3F29" />
        </radialGradient>
        <radialGradient id="OIH2IHFOL" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.5 23.5) rotate(-110.225) scale(20.2485 24.4715)">
          <stop stopColor="white" stopOpacity=".5"/>
          <stop offset="1" stopColor="white" />
        </radialGradient>
      </defs>
    </>
  }),
}
