import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Nillable } from '../../base/@types';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { Emotion, EMOTIONS } from '../../constants/emotions.enum';
import EmotionIcon from '../EmotionIcon/EmotionIcon';
import './EmotionSelector.scss';

interface EmotionSelectorProps {
  onSelect?: (emotion?: Nillable<Emotion>) => void,
  selectedGetter?: () => Nillable<Emotion>,
  disabled?: boolean,
  canChangeAfterSelection?: boolean,
  showTextLabel?: boolean,
}

const EmotionSelector: React.FC<EmotionSelectorProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    handleClick: (emotion?: Emotion) => {
      if (p.disabled) return;
      if (p.selectedGetter?.() && !p.canChangeAfterSelection) return;
      p.onSelect?.(emotion);
    }
  }))

  return <Observer children={() => (
    <div className={
      joinClassName(
        'EmotionSelector',
        p.disabled && 'disabled',
        p.showTextLabel && 'showTextLabel',
        p.canChangeAfterSelection && p.onSelect && 'interactable',
      )
    } data-selected={p.selectedGetter?.()}>
      <div className="EmotionSelectorInner">
        {
          EMOTIONS.map((e) => <EmotionIcon
            key={e}
            emotion={e as Emotion}
            onClick={s.handleClick}
            notSelected={Boolean(p.selectedGetter?.() && p.selectedGetter?.() !== e)}
            selected={p.selectedGetter?.() === e}
            showTextLabel={p.showTextLabel}
          />)
        }
      </div>
    </div>
  )} />
}

export default EmotionSelector;