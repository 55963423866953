import React from 'react';
import './NavBarHeightSpacer.scss';

type NavBarHeightSpacerProps = {
	offset?: string | number;
}

const NavBarHeightSpacer: React.FC<NavBarHeightSpacerProps> = props => {
	return <div className="NavBarHeightSpacer" style={{height: 'var(--AppNavigationHeight)', marginTop: props.offset}}/>
}

export default NavBarHeightSpacer;