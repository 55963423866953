import { useStore } from "../utils/mobx.utils";
import { useOnMount } from "./lifecycle.hooks";
import { useControllers } from "./useRootController.hook";

export const useSyncObservableValueToStorage = <T>(
  keys: string[],
  getter: () => T,
  setter: (v: T) => void,
  options?: { clearOnLogout?: boolean, initialValue?: T }
) => {
  const { STORAGE } = useControllers();
  const s = useStore(() => ({
    state: STORAGE.syncObservableValueToStorage(keys, getter, setter, options)
  }))
  useOnMount(() => {
    (async () => {
      setter(await s.state.getStoredValue());
    })()
    return s.state.dispose
  })
  return s.state;
}