import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ColorCodedState } from '../../base/@types';
import AutosuggestSearchBar from '../../base/components/AutosuggestSearchBar/AutosuggestSearchBar';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import { ContextColor } from '../../base/constants/color.enum';
import { UserEndpoints } from '../../base/endpoints/user.endpoints';
import { useContextColorStyle } from '../../base/hooks/useContextColor.hook';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { NoOp } from '../../base/utils/functions.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { formatSearchString } from '../../base/utils/string.utils';
import { setUrlParam } from '../../base/utils/urlParams.utils';
import { ModelName } from '../../constants/modelNames.enum';
import { ActionConfig } from '../../controllers/ui/ui.controller.types';
import { User } from '../../models/makeUser.model';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
import './VoipCallStarter.scss';

interface VoipCallStarterProps {
  color?: string;
}

const VoipCallStarter: React.FC<VoipCallStarterProps> = props => {

  const p = useProps(props);

  const { UI, VOIP, API, NAVIGATOR } = useControllers();

  const s = useStore(() => ({
    query: '',
    areaCode: '',
    autoSuggestUsers: [] as User[],
    get placeholder() {
      return 'Start a call…';
    },
    get queryIsValidPhone() {
      return !s.queryIsUsername && s.query && s.query.length > 7;
    },
    get formattedQuery() {
      return formatSearchString(s.query)
    },
    get queryIsUsername() {
      return /[a-z]/.test(s.formattedQuery);
    },
    get queryIsNumber() {
      return !s.queryIsUsername;
    }
  }));

  const getDataFetcherFilter = (query: string) => {
    return {
      [s.queryIsUsername ? 'username' : 'mobileNumber']: query
    }
  }

  const dataFetcher = async (
    query: string,
    perPage?: number
  ) => {
    if (!s.query || (s.queryIsNumber && s.query.length <= 3)) return [] as User[];
    const url = UserEndpoints.staff.index({
      filter: getDataFetcherFilter(query),
      perPage: perPage ?? 50,
    });
    const { entries: users } = await API.getMany<User>(url, ModelName.users);
    return users;
  }

  const startCallWithNumber = flow(function * (number: string, autoEndExistingCall?: boolean) {
    if (VOIP.hasLiveVoipCalls && !autoEndExistingCall) {
      const confirm = yield UI.DIALOG.present({
        heading: 'There is currently one or more ongoing calls.',
        body: () => <p>Do you want to hang up on current calls and start a new call with <strong>{number}</strong>?</p>,
      })
      if (!confirm) return;
    }
    VOIP.startVoipCall(number);
    s.query = '';
  })

  const callUser = async (user: User) => {
    if (!user.mobileNumber) {
      UI.DIALOG.attention({
        heading: 'This user does not have a phone number on record.',
        actions: [
          { label: 'Edit user', buttonClass: 'subtle', action: () => setUrlParam('editUserId', user.id, NAVIGATOR) },
          { label: 'OK', action: NoOp }
        ]
      })
      return;
    }
    const confirm = await UI.DIALOG.present({
      heading: <>Call <UsernameRenderer user={user} /> ({user.mobileNumber}) via VOIP?</>,
      body: VOIP.hasLiveVoipCalls ? 'Your current call will be ended automatically.' : undefined,
      colorCodedState: ColorCodedState.positive,
    });
    if (!confirm) return;
    VOIP.startVoipCall(user.mobileNumber, user);
    s.query = '';
  }

  const handleAutoSuggestEnter = (user?: User, query?: string) => {
    if (user) callUser(user);
    else if (s.query && s.queryIsNumber) startCallWithNumber(s.query);
  }

  const renderSuggestion = (user: User) => {
    return <div className="VoipCallStarterSuggestionItem">
      <p><strong><UsernameRenderer user={user} /></strong></p>
      <p>{user.mobileNumber || 'No Number'}</p>
    </div>
  }

  const suggestionActions: ActionConfig[] = [
    {
      icon: 'phone',
      action: callUser
    }
  ]

  const style = useContextColorStyle(ContextColor.Primary, p.color);

  const handleCallButtonClick = () => handleAutoSuggestEnter(s.autoSuggestUsers[0]);

  return <Observer children={() => (
    <div className="VoipCallStarter" style={style}>
      <AutosuggestSearchBar
        form={s}
        field="query"
        placeholder={s.placeholder}
        dataFetcher={dataFetcher}
        resultItemContentRenderer={renderSuggestion}
        defaultAction={handleAutoSuggestEnter}
        hideResultsIf={() => s.queryIsNumber}
        actionPosition="end"
        actions={suggestionActions}
        autoComplete="off"
        resultsArray={s.autoSuggestUsers}
        submitButton={<Observer children={() => (
          <BaseButton
            className="VoipCallStarterCallButton"
            size="xs"
            color="green"
            icon="phone"
            onClick={handleCallButtonClick}
          />
        )} />}
      />
    </div>
  )} />

}

export default VoipCallStarter;