import { first } from "@amcharts/amcharts4/.internal/core/utils/Array";
import deepFreeze from "deep-freeze";
import { Identifier, Nillable, Nullable, StandardModel } from "../base/@types";
import { CountryCode } from "../base/constants/countries.constants";
import { createStandardModelFactory } from "../base/factories/standardModel.factory";
import { joinTruthyWithSeparator, joinTruthyWithSpace, keepTruthy, mergeIntoArray } from "../base/utils/array.utils";
import { encodeString } from "../base/utils/encoder.utils";
import { ModelName } from "../constants/modelNames.enum";
import { PermissionAPIObject, RoleAPIObject, RoleDeciderByRoles } from "../constants/permissionGroups.constants";
import { makePermissionChecker, makeRoleChecker, PermissionChecker, RoleChecker } from "../constants/permissions.constants";
import { AppStaffRole } from "../constants/staffRoles.constants";
import { SHOULD_LOG } from "../env";
import { AdminPageApplicationsManagerState } from "../modules/Admin/AdminManage/pages/AdminPageApplicationsManager/AdminPageApplicationsManager.page";
import { AdminPageAvailabilityManagerState } from "../modules/Admin/AdminManage/pages/AdminPageAvailabilityManager/AdminPageAvailabilityManager.page";
import { AdminPageClientManagerState } from "../modules/Admin/AdminManage/pages/AdminPageClientManager/AdminPageClientManager.page";
import { AdminPageThoughtCatcherManagerState } from "../modules/Admin/AdminManage/pages/AdminPageThoughtCatcherManager/AdminPageThoughtCatcherManager.page";
import { AdminPageUpcomingSessionsState } from "../modules/Admin/AdminManage/pages/AdminPageUpcomingSessions/AdminPageUpcomingSessions.page";
import { hasTimestamps } from "../traits/hasTimestamps.trait";
import { AgeGroupValue, getUserAge, isAdultAge, isUnder12Age, isYoungPeopleAge, isYoungPeopleAge1214, isYoungPeopleAge1517 } from "../utils/ageAndDateOfBirth.utils";
import { getRolePermissions } from "../utils/permission.utils";
import { Address } from "./makeAddress.model";
import { Company } from "./makeCompany.model";
import { Contact } from "./makeContact.model";
import { ContactForm } from "./makeContactForm.model";
import { Fee } from "./makeFee.model";
import { InvoiceItem } from "./makeInvoiceItem.models";
import { Subscription } from "./makeSubscription.model";
import { SupportTicket } from "./makeSupportTicket.model";

export type UserSnapshot = ReturnType<typeof makeUserSnapshotBase>;
export type UserRelatedModels = {
  company: Company,
  addresses: Address[],
  primaryAddress?: Nullable<Address>,
  emergencyContacts: Contact[],
  keyWorkers: Contact[],
  primaryEmergencyContact?: Nullable<Contact>,
  invoiceItems: InvoiceItem[],
  fees: Fee[],
  supportTickets: SupportTicket[],
  contactForms: ContactForm[],
  subscriptions: Subscription[],
}

export type UserPrivateData = {
  readonly roleDescriptor: Nullable<string>,
  readonly permissionDescriptor: Nullable<string>,
  readonly can: PermissionChecker,
  readonly hasRole: RoleChecker,
  readonly isStaff: boolean,
}

export type UserExtendedProperties = {
  readonly fullName: Nullable<string>,
  readonly abbreviation: Nullable<string>,
  readonly hasVerifiedEmail: boolean,
  readonly hasVerifiedMobileNumber: boolean,
  readonly hasVerifiedEmailOrMobileNumber: boolean,
  readonly permissionString: Nullable<string>,
  readonly roleString: Nullable<string>,
  readonly can: Nillable<PermissionChecker>,
  readonly isAdmin: boolean,
  readonly isPreAccreditedCounsellor: boolean,
  readonly isAccreditedCounsellor: boolean,
  readonly isPlacementCounsellor: boolean,
  readonly isCounsellor: boolean,
  readonly isModerator: boolean,
  readonly isFacilitator: boolean,
  readonly isCoordinator: boolean,
  readonly isAnalyst: boolean,
  readonly isFinancialAdministrator: boolean,
  readonly isStaff: boolean,
  readonly roleNames: AppStaffRole[],
  readonly age: number | null;
  readonly isAdult: boolean | null;
  readonly isYoungPerson: boolean | null;
  readonly isUnder12: boolean | null;
  readonly isOver18: boolean | null;
  readonly isYoungPerson1214: boolean | null;
  readonly isYoungPerson1517: boolean | null;
  readonly isFrontLineWorker: boolean,
  readonly addressIfAny: Address | undefined,
  readonly activeSubscription: Subscription | null,
}

export const makeUserPreferencesBase = (): Partial<UserPreferences> => ({
  preferredThoughtState: 'public',
  isFrontLineWorker: false,
  preferBotOrFormForThoughtCatcher: 'form',
  has2FA: false,
});

export type UserPreferencesAdmin = {
  upcomingSessions?: AdminPageUpcomingSessionsState,
  availabilityManager?: AdminPageAvailabilityManagerState,
  applicationsManager?: AdminPageApplicationsManagerState,
  thoughtCatcherManager?: AdminPageThoughtCatcherManagerState,
  clientManager?: AdminPageClientManagerState,
}

export type UserPreferences = {
  preferredThoughtState?: Nillable<'public' | 'private'>,
  generalInfoSurveyResponse?: Nillable<'completed' | 'dismissed' | string>,
  isFrontLineWorker?: boolean,
  admin?: UserPreferencesAdmin,
  doNotShowWarningBeforeClosingChatTabInDock?: boolean,
  preferBotOrFormForThoughtCatcher?: Nillable<'bot' | 'form'>,
  hasViewedGettingStartedVideo?: boolean,
  QRCodeUrl?: Nillable<string>,
  has2FA?: Nillable<boolean>,
};

export type User = StandardModel<
  UserSnapshot,
  UserRelatedModels,
  UserExtendedProperties
>

/** @deprecated */
export enum AccountStatus {
  deactivated = 0,
  muted = 1,
  banned = 2,
  risk = 3,
}

export const makeUserSnapshotBase = () => ({
  id: '',
  uuid: '',
  firstName: '' as Nullable<string>,
  lastName: '' as Nullable<string>,
  username: '',
  email: '' as Nullable<string>,
  mobileNumber: '' as Nullable<string>,
  ageGroup: '' as AgeGroupValue,
  dateOfBirth: '' as Nullable<string>,
  ipAddress: '' as Nullable<string>,
  gender: '' as Nullable<string>,
  sexuality: '' as Nullable<string>,
  /**
   * @deprecated
   */
  accountStatus: null as Nullable<AccountStatus>,
  timeVerifiedEmail: '' as Nullable<string>,
  timeVerifiedMobileNumber: '' as Nullable<string>,
  preferences: makeUserPreferencesBase() as UserPreferences,

  companyId: '' as Nullable<Identifier>,
  addressIds: [] as Identifier[],
  emergencyContactIds: [] as Identifier[],
  keyWorkerIds: [] as Identifier[],
  primaryAddressId: '' as Nullable<Identifier>,
  primaryEmergencyContactId: '' as Nullable<Identifier>,
  activeSubscriptionId: '' as Nullable<Identifier>,

  isTest: true as Nullable<boolean>,
  timeSuspended: '' as Nullable<string>,

  color: '' as Nullable<string>,

  hasOptedOutOfMarketingEmails: false as Nullable<boolean>,

  /** DIRECT ACCESS IS FORBIDDEN */
  roles: [] as RoleAPIObject[],
  permissions: [] as PermissionAPIObject[],

  invoiceItemIds: [] as string[],
  feeIds: [] as string[],
  supportTicketIds: [] as string[],
  contactFormIds: [] as string[],
  subscriptionIds: [] as string[],

  countryProvidedId: null as Nullable<CountryCode>,
  countryDetectedId: '' as Nullable<CountryCode>,

  _wasCounsellor: false as Nullable<boolean>,

  iacpMembershipNumber: null as Nullable<string>,

  ...hasTimestamps(),
})

export const makeUser = createStandardModelFactory<User, UserRelatedModels, UserExtendedProperties, UserPrivateData>({
  name: ModelName.users,
  snapshotFactory: makeUserSnapshotBase,
  relationshipsSchema: {
    company: ModelName.companies,
    addresses: { modelName: ModelName.addresses, has: 'many' },
    primaryAddress: ModelName.addresses,
    emergencyContacts: { modelName: ModelName.contacts, has: 'many' },
    keyWorkers: { modelName: ModelName.contacts, has: 'many' },
    primaryEmergencyContact: ModelName.contacts,
    invoiceItems: { modelName: ModelName.invoiceItems, has: 'many' },
    fees: { modelName: ModelName.fees, has: 'many' },
    supportTickets: { modelName: ModelName.supportTickets, has: 'many' },
    contactForms: { modelName: ModelName.contactForms, has: 'many' },
    subscriptions: { modelName: ModelName.subscriptions, has: 'many' },
  },
  accessorOverridesFactory: ($, m) => ({
    get roles() { return [] },
    set roles(v) { return },
    get permissions() { return [] },
    set permissions(v) { return },
    get color() { return $.color ?? '#0000' },
    set color(v) { $.color = v },
  }),
  privateDataFactory: (snapshot, prev) => {
    if ((!snapshot.roles || !snapshot.permissions) && prev) return prev;
    const roles = (snapshot.roles || []).map(p => p.name).sort();
    const roleDescriptor = encodeString(roles.join('_'));
    const rolePermissions = getRolePermissions(roles);
    const permissions = mergeIntoArray((snapshot.permissions || []).map(p => p.name), rolePermissions, { onDuplicate: 'replace' }).sort();
    const permDescriptor = encodeString(permissions.join('_'));
    if (permDescriptor === prev?.permissionDescriptor) return prev;
    return deepFreeze({
      roleDescriptor: roleDescriptor,
      permissionDescriptor: permDescriptor,
      can: makePermissionChecker(permissions),
      hasRole: makeRoleChecker(roles),
      isStaff: roles.length > 0 || permissions.length > 0,
    })
  },
  extendedPropertiesFactory: (u, $, localDB, _) => ({
    get fullName() {
      return joinTruthyWithSpace(u.firstName || '', u.lastName);
    },
    get abbreviation() {
      return joinTruthyWithSeparator('', u.firstName?.[0] || '', u.lastName?.[0]);
    },
    get hasVerifiedEmail() {
      return Boolean(u.email && u.timeVerifiedEmail);
    },
    get hasVerifiedMobileNumber() {
      return Boolean(u.mobileNumber && u.timeVerifiedMobileNumber);
    },
    get hasVerifiedEmailOrMobileNumber() {
      return u.hasVerifiedEmail || u.hasVerifiedMobileNumber;
    },
    get addressIfAny() {
      return u.primaryAddress || u.addresses?.[0];
    },
    get countryProvidedId() {
      return ($.countryProvidedId || (u.primaryAddress?.countryId || first(u.addresses)?.countryId)) ?? null;
    },
    set countryProvidedId(v) {
      $.countryProvidedId = v;
    },
    get roleString() {
      return _?.().roleDescriptor || null;
    },
    set roleString(s: Nullable<string>) {
      SHOULD_LOG() && console.warn('Not possible to override role string.');
    },
    get permissionString() {
      return _?.().permissionDescriptor || null;
    },
    set permissionString(s: Nullable<string>) {
      SHOULD_LOG() && console.warn('Not possible to override permission string.');
    },
    get can() {
      return _?.().can;
    },
    get isAdmin(): boolean {
      return RoleDeciderByRoles[AppStaffRole.admin].some(role => _?.().hasRole(role)) ?? false;
    },
    get isCounsellor(): boolean {
      return _ ? RoleDeciderByRoles[AppStaffRole.counsellor].some(role => _().hasRole(role)) : false;
    },
    get isPreAccreditedCounsellor(): boolean {
      return _ ? RoleDeciderByRoles[AppStaffRole.preAccreditedCounsellor].some(role => _().hasRole(role)) : false;
    },
    get isAccreditedCounsellor(): boolean {
      return _ ? RoleDeciderByRoles[AppStaffRole.accreditedCounsellor].some(role => _().hasRole(role)) : false;
    },
    get isPlacementCounsellor(): boolean {
      return _ ? RoleDeciderByRoles[AppStaffRole.placementCounsellor].some(role => _().hasRole(role)) : false;
    },
    get isModerator(): boolean {
      return _ ? RoleDeciderByRoles[AppStaffRole.moderator].some(role => _().hasRole(role)) : false;
    },
    get isFacilitator(): boolean {
      return _ ? RoleDeciderByRoles[AppStaffRole.facilitator].some(role => _().hasRole(role)) : false;
    },
    get isCoordinator(): boolean {
      return _ ? RoleDeciderByRoles[AppStaffRole.coordinator].some(role => _().hasRole(role)) : false;
    },
    get isAnalyst(): boolean {
      return _ ? RoleDeciderByRoles[AppStaffRole.analyst].some(role => _().hasRole(role)) : false;
    },
    get isFinancialAdministrator(): boolean {
      return _ ? RoleDeciderByRoles[AppStaffRole.financialAdministrator].some(role => _().hasRole(role)) : false;
    },
    get isStaff(): boolean {
      return _?.().isStaff ?? false;
    },
    get roleNames(): AppStaffRole[] {
      return keepTruthy([
        u.isAdmin && AppStaffRole.admin,
        u.isCounsellor && AppStaffRole.counsellor,
        u.isModerator && AppStaffRole.moderator,
        u.isFacilitator && AppStaffRole.facilitator,
        u.isCoordinator && AppStaffRole.coordinator,
        u.isAnalyst && AppStaffRole.analyst,
        u.isFinancialAdministrator && AppStaffRole.financialAdministrator,
      ])
    },
    get age() {
      return getUserAge(u); // gets age by ageGroup, e.g. 12, 15, 18.
    },
    get isOver18() {
      return u.isAdult;
    },
    get isAdult() {
      return u.age ? isAdultAge(u.age) ?? false : false;
    },
    get isYoungPerson() {
      return u.age ? isYoungPeopleAge(u.age) ?? false : false;
    },
    get isYoungPerson1214() {
      return u.age ? isYoungPeopleAge1214(u.age) ?? null : null
    },
    get isYoungPerson1517() {
      return u.age ? isYoungPeopleAge1517(u.age) ?? null : null
    },
    get isUnder12() {
      return Boolean(u.age && isUnder12Age(u.age));
    },
    get isFrontLineWorker() {
      return Boolean(u.preferences.isFrontLineWorker && u.isAdult);
    },
    get activeSubscription() {
      return u.subscriptions?.find(s => s.id === u.activeSubscriptionId) ?? null;
    },
  }),
})
