import { Nullable } from "../base/@types";
import { SupportGroupEndpointParams, SupportGroupEndpoints } from "../base/endpoints/supportGroup.endpoints";
import { ModelName } from "../constants/modelNames.enum";
import { APIController } from "../controllers/api.controller";
import { SupportGroup } from "../models/makeSupportGroup.model";

export const getSupportGroup = (
  API: APIController,
  id: string,
  endpointType?: 'staff' | 'own',
  params?: SupportGroupEndpointParams,
) => new Promise<SupportGroup>(async (resolve, reject) => {
  try {
    const url = SupportGroupEndpoints[endpointType ?? 'own'].get(id, params);
    const group: Nullable<SupportGroup> = await API.get(url, ModelName.supportGroups);
    if (!group) {
      throw Error(`Failed to get group #${id}`);
    }
    resolve(group)
  } catch (e) {
    reject(e);
  }
})