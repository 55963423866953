import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Renderable } from '../../../../base/@types';
import { renderRenderable } from '../../../../base/utils/components.utils';
import './AdminModuleHeader.scss';

type AdminModuleHeaderProps = {
	title?: Renderable,
	padding?: string | number,
	endSlot?: Renderable,
}

const AdminModuleHeader: React.FC<AdminModuleHeaderProps> = props => {
	return <Observer children={() => (
		<div className="AdminModuleHeader" style={{padding: props.padding}}>
			<div className="AdminModuleHeaderInner">
				<h1 className="AdminModuleTitle">{renderRenderable(props.title)}</h1>
				{ props.children }
			</div>
			{ props.endSlot && <div className="AdminModuleEndSlot" children={renderRenderable(props.endSlot)}/> }
		</div>
	)} />
}

export default AdminModuleHeader;