import { Observer } from 'mobx-react-lite';
import React from 'react';
import { RouteDef } from '../../@types';
import { generateUuid } from '../../utils/id.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { makeUrl } from '../../utils/url.utils';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import RenderIf from '../RenderIf/RenderIf';

interface RouterStackLayerProps {
  prefix?: string,
  route: RouteDef,
}

const RouterStackLayer: React.FC<RouterStackLayerProps> = React.memo(props => {

  const p = useProps(props);

  const s = useStore(() => ({
    id: generateUuid(),
    get route() {
      return p.route;
    },
    get identifier() {
      return s.route.identifier;
    },
    get routeUrl() {
      return makeUrl(p.prefix, s.route.urlPattern ?? s.route.urlFactory());
    },
  }));

  return <Observer children={() => <div 
    className="RouterStackLayer"
    id={`RouterStackLayer-${s.identifier}`}
  >
    <ErrorBoundary>
      <RenderIf
        key={s.identifier}
        ifAsync={s.route.guards?.onRender}
        component={props.route.component}
        renderWhileUndetermined={`Route Guard Waiting (${s.identifier})...`}
        fallback={<div>{s.route.fallback || `Should Render Route Guard Fallback (${s.identifier})`}</div>}
        errorFallback={() => `Error rendering route ${s.identifier}`}
      />
    </ErrorBoundary>
  </div>} />
})

export default RouterStackLayer;