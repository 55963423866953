import { flow, observable, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { ColorCodedState } from '../../base/@types';
import BaseButton from '../../base/components/BaseButton/BaseButton';
import BaseGrid from '../../base/components/BaseGrid/BaseGrid';
import BaseGridCell from '../../base/components/BaseGrid/BaseGridCell';
import BaseInput from '../../base/components/BaseInput/BaseInput';
import BaseSpacer from '../../base/components/BaseSpacer/BaseSpacer';
import BaseToggle from '../../base/components/BaseToggle/BaseToggle';
import DateRenderer from '../../base/components/DateRenderer/DateRenderer';
import FormDatePicker from '../../base/components/FormDatePicker/FormDatePicker';
import FormForm from '../../base/components/FormForm/FormForm';
import FormInput from '../../base/components/FormInput/FormInput';
import FormSelector from '../../base/components/FormSelector/FormSelector';
import FormTimeInput from '../../base/components/FormTimeInput/FormTimeInput';
import FormToggle from '../../base/components/FormToggle/FormToggle';
import InfoBanner from '../../base/components/InfoBanner/InfoBanner';
import IsWas from '../../base/components/IsWas/IsWas';
import ShadedBlock from '../../base/components/ShadedBlock/ShadedBlock';
import { useOnMount } from '../../base/hooks/lifecycle.hooks';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { Form, makeForm } from '../../base/mediators/form.mediator';
import { DefaultColorPalette } from '../../base/utils/colors.utils';
import { reportError } from '../../base/utils/errors.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { copyWithJSON } from '../../base/utils/object.utils';
import { autoPluralize } from '../../base/utils/string.utils';
import { YYYYMMDDHHmm } from '../../base/utils/time.utils';
import { setUrlParam } from '../../base/utils/urlParams.utils';
import { ModelName } from '../../constants/modelNames.enum';
import { Tags } from '../../constants/tags.constants';
import { CLOCK } from '../../controllers/common/clock.controller';
import { enableFrontlineWorkersFeature } from '../../env';
import { SupportGroup, SupportGroupSnapshot } from '../../models/makeSupportGroup.model';
import { SupportGroupTopic } from '../../models/makeSupportGroupTopic.model';
import { saveSupportGroup } from '../../requests/saveSupportGroup.request';
import { startSupportGroup } from '../../requests/startOrEndSupportGroup.request';
import { AgeGroupFilterType, AgeGroupFilterTypeSelectorOptions } from '../../utils/ageAndDateOfBirth.utils';
import { checkIfUserIsWithinAgeGroupsOfSupportGroup } from '../../utils/supportGroup.helpers';
import FormCompanySelector from '../FormCompanySelector/FormCompanySelector';
import FormFacilitatorSelector from '../FormFacilitatorSelector/FormFacilitatorSelector';
import FormSupportGroupTopicSelector from '../FormSupportGroupTopicSelector/FormSupportGroupTopicSelector';
import UsernameRenderer from '../UsernameRenderer/UsernameRenderer';
// import './SupportGroupSetupEditor.scss';

interface SupportGroupSetupEditorProps {
  supportGroup: SupportGroup,
  form?: Form<SupportGroupSnapshot>,
  onSave?: (group: SupportGroup) => void;
  onChange?: (groupSnapshot: SupportGroupSnapshot) => void;
}

const SupportGroupSetupEditor: React.FC<SupportGroupSetupEditorProps> = props => {

  const p = useProps(props);

  const { API, UI, AUTH, LOCALDB } = useControllers();

  const s = useStore(() => ({
    _form: makeForm(p.supportGroup.$snapshot),
    get form() { return p.form ?? s._form },
    get id() {
      return p.supportGroup.id;
    },
    get saveButtonLabel() {
      return s.id ? 'Save Changes' : 'Confirm & Create Support Group';
    },
    get isToday() {
      return CLOCK.localNowMoment.isSame(moment.utc(p.supportGroup.timeScheduled), 'date');
    },
    get canStart() {
      const g = p.supportGroup;
      return s.isToday && g.id && g.timeScheduled && !g.timeStarted && !s.isArchived;
    },
    get currentUserIsTheAssignedCounsellor() {
      return AUTH.currentUser?.id && p.supportGroup.facilitatorId === AUTH.currentUser.id;
    },
    get disableForm() {
      return (!s.currentUserIsTheAssignedCounsellor && !AUTH.isStaff) || s.isArchived;
    },
    get canSubmit() {
      const formValue = s.formValue;
      return !s.disableForm &&
        formValue.title &&
        formValue.timeScheduled &&
        formValue.maxParticipants &&
        formValue.facilitatorId
    },
    get isArchived() {
      return p.supportGroup.timeArchived;
    },
    get formValue() {
      return s.form.value;
    },
    get noAgeLimits() {
      return p.supportGroup.ageGroups.length === 0;
    },
    get adultsCanJoin() {
      return s.noAgeLimits || p.supportGroup.isForAdults;
    },
    get forFrontLineWorkers() {
      return Boolean(s.formValue.tags?.includes(Tags.FrontLineWorkers));
    },
    set forFrontLineWorkers(value: boolean) {
      if (!s.formValue.tags) return;
      if (value) {
        if (s.formValue.tags.includes(Tags.FrontLineWorkers)) return;
        s.formValue.tags.push(Tags.FrontLineWorkers);
      } else {
        s.formValue.tags.splice(s.formValue.tags.indexOf(Tags.FrontLineWorkers), 1);
      }
    },
    get showFrontlineWorkerTagOption() {
      return s.adultsCanJoin && enableFrontlineWorkersFeature;
    },
    get showYouthTagOption() {
      return p.supportGroup.isFor1214 || p.supportGroup.isFor1517;
    },
    get showParentalTagOption() {
      return s.adultsCanJoin;
    },
    handleChange: () => {
      p.onChange?.(s.form.value);
    },
    get topic() {
      return s.formValue?.topicId ? LOCALDB.get<SupportGroupTopic>(ModelName.supportGroupTopics, s.formValue.topicId) : p.supportGroup.topic;
    },
    get shouldDisableForm() {
      return !!p.supportGroup.timeEnded;
    },
    handleTopicChange: () => {
      flow(function* () {
        if (!s.topic || !s.form) return;
        s.form.set('title', s.topic.defaultGroupTitle ? s.topic.defaultGroupTitle : (s.topic.displayName.toLowerCase().includes('Group') ? s.topic.displayName : s.topic.displayName));
        s.form.set('subtitle', s.topic.defaultGroupSubtitle);
        s.topic.defaultFacilitatorId && s.form.set('facilitatorId', s.topic.defaultFacilitatorId);
        s.topic.defaultScheduledDurationInMinutes && s.form.set('scheduledDurationInMinutes', s.topic.defaultScheduledDurationInMinutes);
        s.topic.defaultMaxParticipants && s.form.set('maxParticipants', s.topic.defaultMaxParticipants);
        s.topic.defaultAgeGroups.length > 0 && s.form.set('ageGroups', s.topic.defaultAgeGroups);
        if (s.form.value.description && (s.topic.defaultPublicDescription !== s.form.value.description)) {
          const confirmUpdateDescription = yield UI.DIALOG.attention({
            heading: 'You already have some description written.',
            body: `Would you like to replace the description with the default description of the new topic ${s.topic.displayName}?`,
            defaultActions: ['negative', 'positive'],
          })
          if (!confirmUpdateDescription) return;
        }
        s.form.set('description', s.topic.defaultPublicDescription);
      })()
    },
    get nonStaffUserNotInAgeGroup() {
      return p.supportGroup.reservations.filter(r => !r.user?.isStaff && !checkIfUserIsWithinAgeGroupsOfSupportGroup(r.user, p.supportGroup));
    },
    get nonStaffUserThatDoNotHaveKeyWorkerInfo() {
      if (!p.supportGroup.requiresKeyWorkerInformation) return [];
      return p.supportGroup.reservations.filter(r => !r.user?.isStaff && !r.keyWorker);
    }
  }));

  const saveOrCreateGroup = (shouldPromptAutoRecurringGroupScheduler: boolean, scheduleAt?: string) => new Promise<boolean>(flow(function* (resolve, reject) {
    try {
      const isNew = !!scheduleAt || !s.id;
      if (!s.form.value.description) {
        UI.DIALOG.attention({
          heading: 'A description must be provided before a group can be scheduled.',
        })
        reject(false);
        return;
      }
      if (s.form.value.ageGroups.length === 0) {
        UI.DIALOG.attention({
          heading: 'Please select at least one age group before creating the group.',
        })
        reject(false);
        return;
      }
      const payload: Partial<SupportGroupSnapshot> = copyWithJSON(s.form.value);
      if (!s.adultsCanJoin) payload.tags = [];
      if (scheduleAt) {
        delete payload.id;
        payload.timeScheduled = scheduleAt;
      }
      const savedGroup: SupportGroup = yield saveSupportGroup(API, payload);
      if (!scheduleAt) {
        p.supportGroup.id = savedGroup.id;
        s.form.reset(savedGroup.$snapshot);
        s.form.set('id', savedGroup.id);
        setUrlParam('manageSupportGroupId', savedGroup.id);
        p.onSave?.(savedGroup);
      }
      if (shouldPromptAutoRecurringGroupScheduler && isNew && !!savedGroup.timeScheduled) {
        const state = observable({
          numberOfGroupsToCreate: 4,
          get dateMoments() {
            return Array(Math.min(4, state.numberOfGroupsToCreate)).fill(0).map((n, i) => moment.utc(savedGroup.timeScheduled).add(i + 1, 'week'));
          },
          get dateTimestamps() {
            return state.dateMoments.map(m => m.format(YYYYMMDDHHmm));
          },
        })
        UI.DIALOG.present({
          heading: 'Support Group created!',
          body: <Observer children={() => <>
            <p>The system can automatically create a series of groups on the same day every week. If you would like to do so, please enter the number of weeks (max 4) you'd like to schedule this group at the same time:</p>
            <BaseSpacer size=".5em" />
            <BaseInput form={state} field="numberOfGroupsToCreate" type="number" min={1} max={4} step={1} />
          </>} />,
          actions: [
            {
              name: 'negative',
              label: 'No Thanks',
              action: () => false,
              color: DefaultColorPalette.orange,
            },
            {
              name: 'positive',
              label: 'Create Groups',
              color: DefaultColorPalette.green,
              action: async () => {
                for (let date of state.dateTimestamps) {
                  await saveOrCreateGroup(false, date);
                }
                UI.TOAST.success(`All ${autoPluralize(Math.min(4, state.numberOfGroupsToCreate), 'support group')} have been created.`);
              },
            },
          ]
        })
      } else {
        const shouldSuppressSuccessMessage = !!scheduleAt;
        if (!shouldSuppressSuccessMessage) UI.TOAST.success(isNew ? 'Support group created!' : 'Support group saved!');
      }
      resolve(true);
    } catch (e) {
      reportError(e);
      UI.DIALOG.error({
        heading: 'Failed to save support group',
        error: e,
      })
      reject(e);
    }
  }));

  const confirmStartSession = () => {
    UI.DIALOG.positive({
      heading: 'Confirm start support group session',
      body: 'Please make sure you are ready to start the session. All registered users will be notified to join the session.',
      defaultActions: ['negative'],
      actions: [{
        label: 'Confirm',
        action: startSession
      }]
    })
  }

  const startSession = () => new Promise<boolean>(flow(
    function * (resolve, reject) {
      if (!s.id) {
        reject('Cannot start a support group session without an ID');
        return;
      }
      try {
        yield startSupportGroup(API, s.id);
        resolve(true);
        s.form.value.timeStarted = p.supportGroup.timeStarted = moment().toISOString();
        UI.DIALOG.positive({
          heading: 'Session started!',
          body: 'If you had joined this support group, or if you are the assigned counsellor, you should now see the chat group on the chat & sessions sidebar.',
          defaultActions: ['positive'],
        })
      } catch (e) {
        reject(e);
        UI.DIALOG.error({
          heading: 'Failed to start support group session',
          error: e,
          defaultActions: ['positive'],
        })
      }
    })
  );

  useOnMount(() => reaction(
    () => s.form.value.ageGroups.includes(AgeGroupFilterType.youngPeople1214) || s.form.value.ageGroups.includes(AgeGroupFilterType.youngPeople1517),
    isYPGroup => {
      if (isYPGroup) {
        s.form.value.tags?.splice(0);
      }
    }
  ))

  return <Observer children={() => (
    <FormForm
      form={s.form}
      onChange={s.handleChange}
      className="SupportGroupSetupEditor"
      disabled={s.shouldDisableForm}
      children={context => (
        <BaseGrid columns={context.resizeQuery?.fromPhoneLg ? 2 : 1}>
          {
            s.canStart && (
              <BaseGridCell columns="all">
                <ShadedBlock spaceChildren color="green">
                    {
                      s.currentUserIsTheAssignedCounsellor ? <>
                        <h3>Ready to start this support group session?</h3>
                        <p>This group <IsWas time={p.supportGroup.timeScheduled} /> scheduled on <strong><DateRenderer value={p.supportGroup.timeScheduled} />.</strong></p>
                        <p>When you are ready, press the start button to start the session and notify all users registered for this group.</p>
                      </> : <>
                        <h3>This support group is set to be facilitated by <UsernameRenderer user={p.supportGroup.facilitator} userId={p.supportGroup.facilitatorId} />.</h3>
                        <p>You can still start the session for the assigned facilitator, but you will not be in the chat unless you have joined the group.</p>
                      </>
                    }
                    {s.nonStaffUserNotInAgeGroup.length > 0 && <InfoBanner icon="warning" colorCodedState={ColorCodedState.alert} heading="Warning">
                      <p>The following {autoPluralize(s.nonStaffUserNotInAgeGroup.length, 'client is', 'clients are', '%d clients are', true)} not within the age group defined:</p>
                      <p><strong>{s.nonStaffUserNotInAgeGroup.map(u => <span key={u.user?.id ?? u.userId ?? u.id}><UsernameRenderer user={u.user} userId={u.user?.id ?? u.userId} /> </span>)}</strong></p>
                    </InfoBanner>}
                    {s.nonStaffUserThatDoNotHaveKeyWorkerInfo.length > 0 && <InfoBanner icon="warning" colorCodedState={ColorCodedState.alert} heading="Warning">
                      <p>The following {autoPluralize(s.nonStaffUserThatDoNotHaveKeyWorkerInfo.length, 'client has', 'clients have', '%d clients have', true)} not provided key worker info:</p>
                      <p><strong>{s.nonStaffUserThatDoNotHaveKeyWorkerInfo.map(u => <span key={u.user?.id ?? u.userId ?? u.id}><UsernameRenderer user={u.user} userId={u.user?.id ?? u.userId}/> </span>)}</strong></p>
                    </InfoBanner>}
                    <BaseButton onClick={confirmStartSession} dataCy="start-session">Start Session</BaseButton>
                </ShadedBlock>
                <BaseSpacer size=".5em" />
              </BaseGridCell>
            )
          }
          <FormSupportGroupTopicSelector form={s.form} field="topicId" required disabled={s.disableForm} onChange={s.handleTopicChange}/>
          <FormFacilitatorSelector form={s.form} field="facilitatorId" label="Assign a facilitator" required disabled={s.disableForm || !AUTH.isStaff} />
          <FormInput form={s.form} field="title" label="Title" required disabled={s.disableForm} />
          <FormInput form={s.form} field="subtitle" label="Subtitle" optional disabled={s.disableForm} />
          <FormDatePicker form={s.form} field="timeScheduled" label="Date" required disabled={s.disableForm} />
          <FormTimeInput form={s.form} field="timeScheduled" label="Time" required disabled={s.disableForm} />
          <FormInput form={s.form} field="scheduledDurationInMinutes" label="Scheduled duration (minutes)" type="number" min="0" step="1" disabled={s.disableForm} />
          <FormInput form={s.form} field="maxParticipants" label="Max. number of participants" type="number" min="0" step="1" required disabled={s.disableForm} />
          <BaseGridCell columns="all">
            <FormCompanySelector
              form={s.form}
              field="allowedCompanyId"
              label="Company"
              optional
              disabled={s.disableForm}
            />
          </BaseGridCell>
          <BaseGridCell columns="all">
            <FormSelector
              form={s.form}
              field="ageGroups"
              options={AgeGroupFilterTypeSelectorOptions}
              label="Age Groups"
            />
          </BaseGridCell>
          <BaseGridCell columns="all">
            <FormInput type="textarea" form={s.form} rows="7" field="description" label="Description" required disabled={s.disableForm} />
          </BaseGridCell>
          <BaseGridCell columns="all">
            <ShadedBlock>
              {
                s.showFrontlineWorkerTagOption && (
                  <BaseToggle form={s} field="forFrontLineWorkers" disabled>(Front line service has ended) This group is for front line workers and professionals</BaseToggle>
                )
              }
              <FormToggle form={s.form} field="requiresKeyWorkerInformation" label="This group requires clients to provide their Key Worker information" disabled={s.disableForm} />
              <FormToggle form={s.form} field="isSpecialistGroup" label="This group is a specialist group" disabled={s.disableForm} />
            </ShadedBlock>
          </BaseGridCell>
          <BaseGridCell columns="all">
            <BaseButton onClick={() => saveOrCreateGroup(!s.id)} disabled={!s.canSubmit} fullWidth size="lg" dataCy='saveOrCreateGroupBtn' label={s.saveButtonLabel} />
          </BaseGridCell>
        </BaseGrid>
      )}
    />
  )} />
}

export default SupportGroupSetupEditor;