import { always } from "../../../../../base/utils/ramdaEquivalents.utils";
import { makeObservableRouteDef } from "../../../../../base/utils/routes.utils";
import AccountPage2FA from "./AccountPage2FA.page";

export const AccountPage2FARouteDef = makeObservableRouteDef({
  identifier: 'account-manage-2fa',
  urlFactory: always('2fa'),
  composeTitle: always('Two-Factor Authentication'),
  component: AccountPage2FA,
  icon: '2fa',
})