import { makeObservable, observable } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseSpacer from '../../../../base/components/BaseSpacer/BaseSpacer';
import UIBlock from '../../../../base/components/UIBlock/UIBlock';
import { AssignmentEndpoints } from '../../../../base/endpoints/assignment.endpoints';
import { CounsellingApplicationEndpoints } from '../../../../base/endpoints/counsellingApplication.endpoints';
import { CounsellingSessionEndpoints } from '../../../../base/endpoints/counsellingSession.endpoints';
import { SupportGroupEndpoints } from '../../../../base/endpoints/supportGroup.endpoints';
import { SurveySatisfactionEndpoints } from '../../../../base/endpoints/survey.endpoints';
import { equalByString } from '../../../../base/utils/equality.utils';
import { useStore } from '../../../../base/utils/mobx.utils';
import TimeframeSelectorSet from '../../../../components/TimeframeSelectorSet/TimeframeSelectorSet';
import { ModelName } from '../../../../constants/modelNames.enum';
import { Assignment, AssignmentSnapshot } from '../../../../models/makeAssignment.model';
import { CounsellingApplication, CounsellingApplicationRelatedModels, CounsellingApplicationSnapshot } from '../../../../models/makeCounsellingApplication.model';
import { CounsellingSession, CounsellingSessionRelatedModels, ExtendedCounsellingSessionSnapshot } from '../../../../models/makeCounsellingSession.model';
import { SupportGroup, SupportGroupRelatedModels, SupportGroupSnapshot } from '../../../../models/makeSupportGroup.model';
import { SurveySatisfaction, SurveySatisfactionRelatedModels, SurveySatisfactionSnapshot } from '../../../../models/makeSurveySatisfaction.model';
import { User } from '../../../../models/makeUser.model';
import { getCounsellorStatSet, makeStatDataGetter, useStatDataSetState } from '../../AdminStatistics/statistics.helpers';
import StatDataItem from '../../AdminStatistics/_components/StatDataItem/StatDataItem';
import StatDataItemGrid from '../../AdminStatistics/_components/StatDataItemGrid/StatDataItemGrid';
import StatDataSetStateDisplayer from '../../AdminStatistics/_components/StatDataSetStateDisplayer/StatDataSetStateDisplayer';
import StatSectionSatisfactionSurveys from '../../AdminStatistics/_components/StatSectionSatisfactionSurveys/StatSectionSatisfactionSurveys';
import CounsellorApplicationsOverviewSection from '../CounsellorApplicationsOverviewSection/CounsellorApplicationsOverviewSection';
import './CounsellorStatSection.scss';

interface CounsellorStatSectionProps {
  counsellor: User,
}

export const applicationIncludesForCounsellorStatPurpose = ['applicant', 'address', 'assignments.target', 'sessions.assignments.target', 'sessions.applicant'];

const makeDatasetHolders = (counsellorId: string) => ({
  counsellingSessions: makeObservable({
    data: [] as ExtendedCounsellingSessionSnapshot[],
    getter: makeStatDataGetter<CounsellingSession, CounsellingSessionRelatedModels>(CounsellingSessionEndpoints.staff.index, ModelName.counsellingSessions, {
      filter: { counsellorId, dateRange: {key: 'timeScheduled' }},
      include: ['application', 'assignments.target', 'applicant', 'clients'],
    })
  }, { data: observable.shallow }),
  supportGroups: makeObservable({
    data: [] as SupportGroupSnapshot[],
    getter: makeStatDataGetter<SupportGroup, SupportGroupRelatedModels>(SupportGroupEndpoints.staff.index, ModelName.supportGroups, {
      filter: { facilitatorId: counsellorId, dateRange: {key: 'timeScheduled'}},
    })
  }, { data: observable.shallow }),
  counsellingApplications: makeObservable({
    data: [] as CounsellingApplicationSnapshot[],
    getter: makeStatDataGetter<CounsellingApplication, CounsellingApplicationRelatedModels>(CounsellingApplicationEndpoints.staff.index, ModelName.counsellingApplications, {
      filter: { counsellorId },
      include: applicationIncludesForCounsellorStatPurpose,
    })
  }, { data: observable.shallow }),
  ongoingApplications: makeObservable({
    data: [] as CounsellingApplicationSnapshot[],
    getter: makeStatDataGetter<CounsellingApplication, CounsellingApplicationRelatedModels>(CounsellingApplicationEndpoints.staff.index, ModelName.counsellingApplications, {
      filter: {
        counsellorId,
        whereNull: ['timeArchived', 'timeRejected', 'timeCancelled', 'timeCompleted'],
      },
      include: applicationIncludesForCounsellorStatPurpose,
    })
  }, { data: observable.shallow }),
  archivedApplications: makeObservable({
    data: [] as CounsellingApplicationSnapshot[],
    getter: makeStatDataGetter<CounsellingApplication, CounsellingApplicationRelatedModels>(CounsellingApplicationEndpoints.staff.index, ModelName.counsellingApplications, {
      filter: { counsellorId, dateRange: { key: 'timeArchived' }},
      include: applicationIncludesForCounsellorStatPurpose,
    })
  }, { data: observable.shallow }),
  dnrApplications: makeObservable({
    data: [] as CounsellingApplicationSnapshot[],
    getter: makeStatDataGetter<CounsellingApplication, CounsellingApplicationRelatedModels>(CounsellingApplicationEndpoints.staff.index, ModelName.counsellingApplications, {
      filter: {
        counsellorId,
        didNotRespond: 'true',
        trashed: 'with',
      },
      include: applicationIncludesForCounsellorStatPurpose,
    })
  }, { data: observable.shallow }),
  completedApplications: makeObservable({
    data: [] as CounsellingApplicationSnapshot[],
    getter: makeStatDataGetter<CounsellingApplication, CounsellingApplicationRelatedModels>(CounsellingApplicationEndpoints.staff.index, ModelName.counsellingApplications, {
      filter: { counsellorId, dateRange: { key: 'timeCompleted' }},
      include: applicationIncludesForCounsellorStatPurpose,
    })
  }, { data: observable.shallow }),
  createdAssignments: makeObservable({
    data: [] as AssignmentSnapshot<any, any>[],
    getter: makeStatDataGetter(AssignmentEndpoints.staff.index, ModelName.assignments, {
      filter: { dateRange: { key: 'timeCreated' }},
      include: ['associated', 'target']
    })
  }, { data: observable.shallow }),
  completedAssignments: makeObservable({
    data: [] as AssignmentSnapshot<any, any>[],
    getter: makeStatDataGetter(AssignmentEndpoints.staff.index, ModelName.assignments, {
      filter: {dateRange: { key: 'timeCompleted' }},
      include: ['associated', 'target']
    })
  }, { data: observable.shallow }),
  satisfactionSurveys: makeObservable({
    data: [] as SurveySatisfactionSnapshot[],
    getter: makeStatDataGetter<SurveySatisfaction, SurveySatisfactionRelatedModels>(SurveySatisfactionEndpoints.staff.index, ModelName.surveysSatisfaction, { filter: { dateRange: { key: 'timeCreated' } } })
  }, { data: observable.shallow }),
});

const CounsellorStatSection: React.FC<CounsellorStatSectionProps> = props => {

  const s = useStore(() => ({
    counsellor: props.counsellor as User | undefined,
    data: makeDatasetHolders(props.counsellor.id),
    get dataset() {
      return s.counsellor ? getCounsellorStatSet({
        counsellor: s.counsellor,
        sessions: s.data.counsellingSessions.data,
        applications: s.data.counsellingApplications.data,
        supportGroups: s.data.supportGroups.data,
        ongoingApplications: s.data.ongoingApplications.data,
        dnrApplications: s.data.dnrApplications.data,
        archivedApplications: s.data.archivedApplications.data,
        completedApplications: s.data.completedApplications.data,
        createdAssignments: s.data.createdAssignments.data.filter(a => equalByString((a as Assignment).associated?.counsellorId, props.counsellor.id)) as Assignment[],
        completedAssignments: s.data.completedAssignments.data.filter(a => equalByString((a as Assignment).associated?.counsellorId, props.counsellor.id)) as Assignment[],
        satisfactionSurveys: s.data.satisfactionSurveys.data.filter(a => equalByString(a.relatedCounsellorId, props.counsellor.id)),
      }) : undefined;
    },
    get archivedApplications() {
      return s.data.archivedApplications.data;
    },
    get completedApplications() {
      return s.data.completedApplications.data;
    },
    get ongoingApplications() {
      return s.data.ongoingApplications.data;
    }
  }));

  const t = useStatDataSetState(s);

  return <Observer children={() => (
    <UIBlock
      className="CounsellorStatSection"
      title="Counsellor Statistics"
      headerEndSlot={
        <StatDataSetStateDisplayer state={t} />
      }
    >
      <TimeframeSelectorSet timeframe={t.timeframe} />

      <StatDataItemGrid>
        <StatDataItem
          title="Unique Clients"
          value={s.dataset?.numberOfUniqueClients}
          description="Number of unique clients in the selected timeframe."
          backgroundIntensity="stronger"
        />
        <StatDataItem
          title="Assigned Applications"
          value={s.dataset?.numberOfAssignedApplications}
          description="Number of counselling applications assigned in the selected timeframe."
          backgroundIntensity="stronger"
        />
        <StatDataItem
          title="Ongoing Applications (all time)"
          value={s.dataset?.numberOfOngoingApplications}
          description="Number of counselling applications that has been confirmed and has not been archived. Not constrained by the selected timeframe."
          backgroundIntensity="stronger"
        />
        <StatDataItem
          title="Archived Applications"
          value={s.dataset?.numberOfArchivedApplications}
          description="Number of counselling applications archived in the selected timeframe. This number will include completed applications since they are automatically archived."
          backgroundIntensity="stronger"
        />
        <StatDataItem
          title="Completed Applications"
          value={s.dataset?.numberOfCompletedApplications}
          description="Number of counselling applications marked as completed in the selected timeframe."
          backgroundIntensity="stronger"
        />
        <StatDataItem
          title="DNR Applications"
          value={s.dataset?.numberOfDNRApplications}
          description="Number of counselling applications marked as client Did Not Respond."
          backgroundIntensity="stronger"
        />
        <StatDataItem
          title="Assigned Sessions"
          value={s.dataset?.numberOfAssignedSessions}
          description="Number of sessions assigned in the selected timeframe."
          backgroundIntensity="stronger"
        />
        <StatDataItem
          title="Performed Sessions"
          value={s.dataset?.numberOfSessionsPerformed}
          description="Number of sessions performed in the selected timeframe. Includes DNA sessions."
          backgroundIntensity="stronger"
        />
        <StatDataItem
          title="Completed Sessions"
          value={s.dataset?.numberOfSessionsCompleted}
          description="Number of sessions completed in the selected timeframe. Excludes DNA sessions."
          backgroundIntensity="stronger"
        />
        <StatDataItem
          title="DNA Sessions"
          value={s.dataset?.numberOfDnaSessions}
          description="Number of sessions in the selected timeframe where the client Did Not Attend (DNA)."
          backgroundIntensity="stronger"
        />
        <StatDataItem
          title="Avg. Performed Sessions Per Client"
          value={s.dataset?.avgNumberOfSessionsPerformedPerClient}
          description="Number of sessions performed per client in the selected timeframe. Includes DNA sessions."
          backgroundIntensity="stronger"
        />
        <StatDataItem
          title="Avg. Completed Sessions Per Client"
          value={s.dataset?.avgNumberOfSessionsCompletedPerClient}
          description="Number of sessions completed per client in the selected timeframe. Excludes DNA sessions."
          backgroundIntensity="stronger"
        />
        <StatDataItem
          title="Avg. DNA Sessions Per Client"
          value={s.dataset?.avgNumberOfDnaSessionsPerClient}
          description="Number of sessions per client in the selected timeframe where the client Did Not Attend (DNA), or if the session was scheduled in the past but never started."
          backgroundIntensity="stronger"
        />
        <StatDataItem
          title="Support Groups Facilitated"
          description="Number of support groups online facilitated within the selected timeframe."
          backgroundIntensity="stronger"
        />
        <StatDataItem
          title="GAD7 Surveys"
          value={s.dataset?.numberOfGAD7SurveysCompleted}
          secondValue={s.dataset?.numberOfGAD7Surveys}
          description="Number of GAD7 surveys issued: completed / total"
          backgroundIntensity="stronger"
        />
        <StatDataItem
          title="PHQ9 Surveys"
          value={s.dataset?.numberOfPHQ9SurveysCompleted}
          secondValue={s.dataset?.numberOfPHQ9Surveys}
          description="Number of PHQ9 surveys issued: completed / total"
          backgroundIntensity="stronger"
        />
        <StatDataItem
          title="Satisfaction Surveys"
          value={s.dataset?.numberOfSatisfactionSurveysCompleted}
          secondValue={s.dataset?.numberOfSatisfactionSurveys}
          description="Number of Satisfaction surveys issued: completed / total (Coming soon)"
          backgroundIntensity="stronger"
        />
        <StatDataItem
          title="Average Satisfaction Survey Total Score"
          value={s.dataset?.satisfactionSurveyScoresAverage}
          secondValue={3}
          description="Average Score of Satisfaction Surveys submitted. Highest score is 3."
          backgroundIntensity="stronger"
        />
      </StatDataItemGrid>

      <BaseSpacer size=".5em" />

      <CounsellorApplicationsOverviewSection
        title="Ongoing Applications"
        applications={s.ongoingApplications}
        description="Overview of all ongoing applications of this counsellor (Not constrained by the selected timeframe)."
      />

      <BaseSpacer size=".5em" />

      <CounsellorApplicationsOverviewSection
        title="Archived Applications"
        applications={s.data.archivedApplications.data}
        description="Overview of counselling applications archived within the selected timeframe. This includes all completed applications since they are automatically archived."
      />
      <BaseSpacer size=".5em" />

      <CounsellorApplicationsOverviewSection
        title="Completed Applications"
        applications={s.data.completedApplications.data}
        description="Overview of counselling applications completed within the selected timeframe."
      />

      <BaseSpacer size=".5em" />

      <StatSectionSatisfactionSurveys
        satisfactionSurveys={s.dataset?.satisfactionSurveys || []}
      />

    </UIBlock>
  )} />
}

export default CounsellorStatSection;