import React from "react";

export type BooleanTransformerOptionType = {
  wrapInTag?: boolean,
  trueValue?: string | number | null,
  falseValue?: string | number | null,
  nullValue?: string | number | null,
  undefinedValue?: string | number | null,
};
export function transformBoolean(
  value?: any,
  options?: BooleanTransformerOptionType
) {
  let text = '' as string | number | null | undefined;
  const {
    wrapInTag = true,
    trueValue = 'Yes',
    falseValue = 'No',
    nullValue,
    undefinedValue,
  } = options || {};
  if ([true, 1, '1', 'true'].includes(value as any)) {
    text = trueValue; 
  }
  else if ([false, 0, '0', 'false'].includes(value as any)) {
    text = falseValue; 
  }
  else if (value === null) {
    text = nullValue;
  }
  else if (value === undefined) {
    text = undefinedValue;
  }
  else {
    text = value.toString();
  }
  return text && wrapInTag ? <span className={`boolean-value-${value}`}>{text}</span> : text;
}