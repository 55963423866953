import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject } from '../../base/@types';
import BaseSelector, { BaseSelectorProps } from '../../base/components/BaseSelector/BaseSelector';
import { useControllers } from '../../base/hooks/useRootController.hook';
import { sortArray } from '../../base/utils/array.utils';
import joinClassName from '../../base/utils/className.utils';
import { useProps, useStore } from '../../base/utils/mobx.utils';
import { User } from '../../models/makeUser.model';
import { useGetFacilitators } from '../../requests/useGetFacilitators.request';
import { getFacilitatorDisplayName } from '../../utils/user.utils';

export interface FacilitatorSelectorProps extends Omit<BaseSelectorProps<AnyObject, User, string | undefined>, 'options'>{}

const FacilitatorSelector: React.FC<FacilitatorSelectorProps> = props => {

  const p = useProps(props);

  const { AUTH } = useControllers();

  const s = useStore(() => ({

    facilitators: [] as User[],

    get sortedFacilitators() {
      const arr = sortArray(s.facilitators, {
        transformer: c => {
          const name = getFacilitatorDisplayName(c);
          const isCurrentUser = AUTH.currentUser?.id === c.id;
          if (isCurrentUser) return name + ' (You)';
          else return name;
        }
      });
      return arr;
    },
    get loading() {
      return s.facilitators.length === 0;
    },
    get disabled() {
      return p.disabled ?? s.loading;
    },
    get placeholder() {
      return s.loading ? 'Loading facilitators...' : 'Select a facilitator';
    },
    get label() {
      return p.label ?? 'Assign a facilitator';
    }
  }));

  useGetFacilitators({observable: s, key: 'facilitators'});

  return <Observer children={() => (
    <BaseSelector<AnyObject, User, string | undefined>
      {...p}
      className={joinClassName(
        'FacilitatorSelector',
        p.className
      )}
      appearance="system"
      options={s.sortedFacilitators}
      valueGetter={o => o?.id}
      valueEqualityChecker={(a, b) => a === b}
      label={s.label}
      emptyValue=""
      placeholder={s.placeholder}
      disabled={s.disabled}
      optionLabelRenderer={getFacilitatorDisplayName}
    />
  )} />
}

export default FacilitatorSelector;