import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseButton from '../../../../base/components/BaseButton/BaseButton';
import { addToArrayIfNew, removeFromArray } from '../../../../base/utils/array.utils';
import { useProps, useStore } from '../../../../base/utils/mobx.utils';
import { AppPermissionGroup } from '../../../../constants/permissionGroups.constants';
import { AppPermission } from '../../../../constants/permissions.constants';
import './PermissionGroupManager.scss';
import PermissionToggle from './PermissionToggle';

type PermissionGroupManagerProps = {
  selectedPermissions: AppPermission[],
  selectedRolePermissions: AppPermission[],
  group: AppPermissionGroup,
  disabled?: boolean,
}

const PermissionGroupManager: React.FC<PermissionGroupManagerProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get partiallySelected() {
      return p.group.permissions.some(perm => p.selectedPermissions.includes(perm));
    },
    get allSelected() {
      return p.group.permissions.every(perm => p.selectedPermissions.includes(perm));
    },
    get noneSelected() {
      return !s.partiallySelected;
    },
    get groupTogglerButtonLabel() {
      return s.allSelected ? 'Disable All' : 'Enable All';
    },
    handleGroupTogglerButtonClick() {
      if (s.allSelected) {
        // should disable all
        p.group.permissions.forEach(perm => removeFromArray(p.selectedPermissions, perm));
      } else {
        p.group.permissions.forEach(perm => addToArrayIfNew(p.selectedPermissions, perm));
      }
    }
  }))
  return <Observer children={() => (
    <div className="PermissionGroupManager" data-primary-color={p.group.primaryColorName} data-cy={p.group.name}>
      <div className="PermissionGroupManagerInner">
        <header className="PermissionGroupManagerHeader">
          <div className="PermissionGroupManagerHeaderInner">
            <h3>{p.group.name}</h3>
            <p>{p.group.description}</p>
          </div>
          <div className="PermissionGroupManagerHeaderEndSlot">
            {!p.disabled && <BaseButton
              label={s.groupTogglerButtonLabel}
              onClick={s.handleGroupTogglerButtonClick}
            />}
          </div>
        </header>
        <div className="PermissionGroupManagerToggleList">
          {p.group.permissions.map(perm => <PermissionToggle
            key={perm}
            selectedPermissions={p.selectedPermissions}
            selectedRolePermissions={p.selectedRolePermissions}
            forValue={perm}
            disabled={p.disabled}
          />)}
        </div>
      </div>
    </div>
  )} />
}

export default PermissionGroupManager;