import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { reportError } from '../../utils/errors.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { YYYYMMDD } from '../../utils/time.utils';
import { BaseCalendarBaseChildProps } from './BaseCalendar';
import BaseCalendarWeek from './BaseCalendarWeek';

interface BaseCalendarMonthProps<T = object> extends BaseCalendarBaseChildProps<T> { }

function BaseCalendarMonth<T extends object>(props: React.PropsWithChildren<BaseCalendarMonthProps<T>>) {

  const p = useProps(props);

  const s = useStore(() => ({
    get calendar() {
      return p.state;
    },
    get startOfMonthMoment() {
      return moment(s.calendar.startDate);
    },
    get startOfCalendarMoment() {
      return moment(s.startOfMonthMoment).startOf('week');
    },
    get endOfMonthMoment() {
      return moment(s.calendar.startDate).endOf('month')
    },
    get endOfCalendarMoment() {
      return moment(s.endOfMonthMoment).endOf('week');
    },
    get numberOfDays() {
      return s.startOfMonthMoment.daysInMonth();
    },
    get numberOfWeeks() {
      return Math.ceil(s.startOfMonthMoment.daysInMonth() / 7);
    },
    get startOfWeeksMoments() {
      try {
        return Array(s.numberOfWeeks).fill(null).map((m, i) => moment(s.startOfCalendarMoment).add(i, 'week'));
      } catch (e) {
        reportError(e);
        return Array(4).fill(null).map((m, i) => moment(s.startOfCalendarMoment).add(i, 'week'));
      }
    },
    get startOfWeekDates() {
      return s.startOfWeeksMoments.map(w => w.format(YYYYMMDD));
    },
    get weekRowStyle() {
      return s.calendar.fitInnerToAvailableHeight ? {
        flexBasis: `${100 / s.numberOfWeeks}%`,
        height: `${100 / s.numberOfWeeks}%`,
      } : {}
    }
  }));

  return <Observer children={() => (
    <>
      {s.startOfWeekDates.map(w => <BaseCalendarWeek<T> key={w} startDate={w} style={s.weekRowStyle} {...p} />)}
    </>
  )} />
  
}

export default BaseCalendarMonth;