
import React from "react";
import { AnyObject } from "../base/@types";
import { getUrlParams } from "../base/utils/urlParams.utils";
import { RootController } from "../controllers/_controller.types";
import { makeRootController } from "../controllers/_root.controller";
import { API_HOST, IS_PROD } from "../env";
import { RouteIndex } from '../routes';

export const RootControllerInstance: RootController = (function () {
  const { appInstanceId = '0' } = getUrlParams() as AnyObject;
  return makeRootController({
    appShortName: 't2m',
    appInstanceId: IS_PROD ? '0' : appInstanceId,
    routes: RouteIndex,
    api: {
      config: {
        baseURL: API_HOST,
      },
    },
  });
})()

RootControllerInstance.init();

export const RootContext = React.createContext(RootControllerInstance);

export const AuthController = RootControllerInstance.children.AUTH;